import { render, staticRenderFns } from "./CustomerLogForm.vue?vue&type=template&id=676b94dd&scoped=true&"
import script from "./CustomerLogForm.vue?vue&type=script&lang=js&"
export * from "./CustomerLogForm.vue?vue&type=script&lang=js&"
import style0 from "./CustomerLogForm.vue?vue&type=style&index=0&id=676b94dd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "676b94dd",
  null
  
)

export default component.exports