import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir
export const PurchaseOrderService = {
    async post(endpoint,object) {
       
        return await Axios.post(`/api/purchase-order` + endpoint, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async store(object) {
       
        return await Axios.post(`/api/purchase-order`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async get(endpoint = '', params) {
        return await Axios.get(`/api/purchase-order${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/purchase-order`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/purchase-order`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async updateStatus(endpoint,obj) {
        return await Axios.put(`/api/purchase-order/` + endpoint, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}