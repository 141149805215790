
<template>
    <div class="customervendor-container">
        <ThatsTable title="Product list" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="checkRoleImportButton" :newBtn="checkRoleNewButton" @import="importProduct" @openImportQty="openImportQty" @openImportPrice="openImportPrice">
            <template #custom-culumns="{columns,handleClick}">
                <template v-for="col in columns">
                    <template v-if="checkGroup1(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>

                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>

                            </template>
                        </template>

                    </el-table-column>
                    </template>
                    <template v-if="col.field === 'image_default'">
                        <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                            <template slot-scope="scope">
                                    <a href="javascript:void(0)" @click="viewImage(scope.$index, scope.row)"><img :src="scope.row.default" class='img-thumbnail'/></a>
                        </template>
                        </el-table-column>
                    </template>
                    <template v-if="checkGroup2(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else-if="col.isAmount === true ">
                                {{scope.row[col.field] | amount}}
                            </template>
                            <template v-else>

                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                            </template>
                        </template>

                    </el-table-column>
                    </template>

                </template>

                <el-table-column :width="100" label="Sold to">
                    <template slot-scope="scope">
                        <template v-if="scope.row.i_all_invoice > 0">
                            <a href="javascript:void(0)" @click="viewSoldToHandler(scope.$index, scope.row)" v-html="`View`"></a>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </template>
                </el-table-column>

                <el-table-column :width="100" label="ETA">
                    <template slot-scope="scope">
                        <template v-if="scope.row.future_stock > 0">
                            <a href="javascript:void(0)" @click="viewEtaHandler(scope.$index, scope.row)" v-html="`View`"></a>
                        </template>
                        <template v-else>
                            -
                        </template>
                    </template>
                </el-table-column>
            </template>

            <template #filter="{dispatchFilter}">
                <ItemFilter @filter="dispatchFilter"></ItemFilter>
            </template>

            <template #tabs>
                <mdb-tabs class="table-tabs" :active="active" default :links="[ { text: 'Active' }, { text: 'New Arrival' }, { text: 'On Promos' }, { text: 'Discountinued' }, { text: 'Hidden' }, { text: 'All' }]"  @activeTab="getActiveTabIndex" />
            </template>

        </ThatsTable>
        <ItemForm ref="dialogForm" @success="refreshTable" @delete="formDelete" :group="3"></ItemForm>
        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="3"></FileUpload>
        <FileUpload @uploadSuccess="fileDataQtyUpdate" :endpoint="'item/import-qty'" ref="uploadQtyForm" :group="3"></FileUpload>
        <FileUpload @uploadSuccess="fileDataPriceUpdate" :endpoint="'item/import-price'" ref="uploadPriceForm" :group="3"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="3" :loading="importLoading"></TableImport>
        <DataTable ref="dataTable" :group="3">
            <template #custon-button>
                <mdb-btn color="elegant" size="sm" @click="fnUpdateQuantity">Update Qty</mdb-btn>
            </template>
        </DataTable>
        <TabTable ref="tabTable" :group="3">
            <template #custon-button>
                <template v-if="updateQuantityLoading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading data...
                        </mdb-btn>
                </template>
                <template v-else>
                        <template v-if="updateQuantityStatus">
                            <mdb-btn color="elegant" size="sm" tag="a" :href="updateQuantityDownloadFile" target="_blank">Download</mdb-btn>
                        </template>
                        <template v-else>
                            <mdb-btn color="elegant" size="sm" @click="fnUpdateQuantity">Update</mdb-btn>
                            <template v-if="toUpdate > 0">
                            </template>
                        </template>
                </template>

            </template>
        </TabTable>
        <TableError :data="errorData" ref="tableError" :group="3"></TableError>
        <ThatsCustomWindow :group="3" ref="etaWindow" title="ETA List" :width="900">
            <el-table ref="multipleTable" lazy class="custom-element-table" :data="etaData" :height="400" stripe>
            <template v-for="col in etaColumns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}

                        </template>
                    </el-table-column>
                </template>
                <template slot="empty">
                            <template v-if="viewEtaLoading">
                                <mdb-btn color="elegant" size="sm" rounded disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading data...
                                </mdb-btn>

                            </template>
                            <template v-else>
                                No Data
                            </template>
                    </template>
            </el-table>
        </ThatsCustomWindow>

        <ThatsCustomWindow :group="3" ref="soldToWindow" title="Sold To" :width="900">
            <el-table ref="multipleTable" lazy class="custom-element-table" :data="soldData" :height="400" stripe>

                <template v-for="col in soldColumns">

                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.field === 'transaction'">
                                <a href="javascript:void(0)" @click="handleSaleTransactionClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}

                            </template>
                        </template>
                    </el-table-column>

                </template>

                <template slot="empty">
                    <template v-if="viewTransactionLoading">
                        <mdb-btn color="elegant" size="sm" rounded disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading data...
                        </mdb-btn>

                    </template>
                    <template v-else>
                        No Data
                    </template>
            </template>


            </el-table>
        </ThatsCustomWindow>

     <!--    gallery


        <div class="list" v-for="(n, index) in gallery" :key="index">
        <img @click="open($event)" :src="n">
        </div>

        {{gallery}}
 -->

       <!--  <mdb-lightbox
                :visible="visibleGallery"
                :imgs="gallery"
                :index="indexGallery"
                :gallery="true"
                @hide="handleHideGallery">
                </mdb-lightbox>  -->
                <CoolLightBox
            :items="gallery"
            :index="index"
            :useZoomBar="true"
            @close="index = null">
            </CoolLightBox>
        <ThatsMediaPlayer :group="3" ref="galleryWindow" title="Gallery Manager" :data="galleryData"></ThatsMediaPlayer>
        <TransactionForm :group="4" ref="salesForm"></TransactionForm>


            <hsc-window-style-metal>
            <hsc-window title="Update Price" :resizable="false" :left="0" :top="0" :height="1024" style="z-index : 999!important;width:100%" class="export-window" :closeButton="true" :isOpen.sync="dialogIsOpenUpdatePrice">
                <div class="toolbar-container">

                    <button class="btn btn-sm btn-elegant" @click="persistItemPriceData" :disabled="isSave">Update Price</button>

                </div>
                <div class="window-export-container" :style="{'width' : '1300px'}">
                    <div ref="spreadsheetUpdatePrice" class="demo-x"></div>
                </div>
            </hsc-window>
        </hsc-window-style-metal>


    </div>
</template>
<script>
import jspreadsheet from '../../jsexcel/jexcel'
import { mapGetters, mapMutations,mapActions } from 'vuex';
import {mdbTabs, mdbBtn, mdbLightbox} from 'mdbvue'
import ItemForm from './ItemForm'
import ItemFilter from './ItemFilter'
import ThatsTable from './Table'
import DataTable from './DataTable'
import TabTable from './TabTable'
import ThatsMediaPlayer from '../../components/thats/MediaPlayer'
import ThatsCustomWindow from '../../components/thats/CustomWindow'
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from '../../components/filemanager/TableImport'
import TableError from '../../components/filemanager/TableError'
import TransactionForm from '../transaction/TransactionForm'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import Button from '../../components/thats/Button.vue';
/* import settings from 'accounting-js/lib/settings.js'

settings = { symbol: "", precision: 2, thousand: ",", decimal: "." } */
const formatMoneyConfig = { symbol: "", precision: 2, thousand: ",", decimal: "." }
export default {
    data(){
        return {
            isSave : false,
            dialogIsOpenUpdatePrice : false,
            spreadsheetUpdatePrice : null,
            spreadsheetUpdatePriceOptions : {
                allowDeleteColumn : false,
                allowRenameColumn : false,
                csvHeader:true,
                tableWidth : '1300px',
                tableHeight : 'calc(100vh - 92px)',
                tableOverflow : true,
                data:[[]],
                minDimensions:[2,35],
                onCopy : null,
                selectionCopy : false,
                allowCopy : true,
                columns: [
                    {
                        type: 'text',
                        width: '150px',
                        title: 'Series',
                        name: 'series'
                    },
                    {
                        type: 'numeric',
                        width: '200px',
                        title:'Price',
                        mask:'0', decimal:',',
                        name:'sale_price'
                    },
                ],
            },
            galleryKey : 0,
            visibleGallery : false,
            gallery : [
            "https://thatsliving.com/public/uploads/image-jpeg/full-c17bba023d61a48f792c8cb1ce90c41f-twinssag-agate.jpg",
            "https://thatsliving.com/public/uploads/image-jpeg/full-1fa41f5db8f8fa6cccb1170078df83ec-twinsnbl-bliss-03.jpg",
            "https://thatsliving.com/public/uploads/image-jpeg/full-1b5c8542075c98f3b4bd5c8eab19c87f-twinssag-agate-02.jpg"
            ],
            index : null,
            endpoint : 'item-v2?status=5',
            importData : {},
            errorData : {},
            viewTransactionLoading : false,
            viewEtaLoading : false,
            importLoading : false,
            updateQuantityLoading : false,
            updateQuantityStatus : false,
            updateQuantityDownloadFile : "",
            group1 : ["series","name"],
            group2 : ['category_name', 'dimension', 'sale_price', 'promo_price','off_price','remaining','future_stock', 'promo_price'],
            galleryData : [],
            etaData : [],

            etaColumns : [
                {
                    label: 'Eta',
                    field: 'eta',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Qty',
                    field: 'qty',
                },
                {
                    label: 'Remarks',
                    field: 'remarks',
                },
                {
                    label: 'PO No.',
                    field: 'series',
                },
                {
                    label: 'Vendor',
                    field: 'vendor_name',
                },
                {
                    label: 'Status',
                    field: 'status_label',
                },
            ],
            soldData : [],
            soldColumns : [
                {
                    label: 'Date',
                    field: 'date',
                },
                {
                    label: 'Sales Transactions',
                    field: 'transaction',
                },
                {
                    label: 'Customer',
                    field: 'customer',
                },
                {
                    label: 'Qty',
                    field: 'qty',
                    align: 'right',
                },
                {
                    label: 'Status',
                    field: 'status',
                    align: 'right',
                },
                /* {
                    label: 'Sold By',
                    field: 'soldby',
                }, */

            ],
            active : 5,
            warehouses : [],
            area : [],
            brands : [],
            category : [],
            collections : [],
            color : []
        }
    },
    components: {
        ItemFilter,
        ItemForm,
        ThatsTable,
        FileUpload,
        TableImport,
        TableError,
        ThatsCustomWindow,
        ThatsMediaPlayer,
        mdbTabs,
        mdbBtn,
        TransactionForm,
        mdbLightbox,
        DataTable,
        Button,
        TabTable,
    },


    provide(){
        return {
          /*   areaProvider: () => this.area,
            categoryProvider: () => this.category,
            collectionProvider: () => this.collections,
            colorProvider: () => this.color,
            brandProvider: () => this.brands, */
            warehousesProvider: () => this.warehouses,
        }
    },

    mounted(){
        const self = this;
        self.getWarehouses().then(response => self.warehouses = response)
        self.GetArea().then(response => self.area = response);
        self.GetBrandErp().then(response => {
            const brand = [];
            response.forEach(element => {
              brand.push({brand : element.label,code : element.value})
            });
            self.brands = brand
          });
        self.GetCategory().then(response => {
            const category = [];
            response.forEach(element => {
              category.push({category : element.label,code : element.value})
            });
            self.category = category
          });

          self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
            const collection = [];
                response.forEach(element => {
                    collection.push({collection : element.name,code : element.mdbID})
                });
                self.collections = collection
            })

            self.getColour({'show_in' : 'product'}).then(response => {

                    const c = [];

                    if(response.length){
                        response.forEach(e => {
                        c.push({
                            colour : e.label,
                            code : e.slug,
                            attributes : e.attributes,
                        })
                        })
                        self.color = c
                    }
                    })


    },

    methods: {
        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapActions('ItemModule',[ 'DeleteRecord','saveImport','getRecord','getTransactions','getEta','validateData','updateQuantity','updatePrice']),
        ...mapMutations('ItemModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetPage']),
        ...mapActions('TransactionModule',{
            salesGetRecord : 'getRecord'
        }),
        ...mapMutations('TransactionModule',{
            salesFormRecord : 'setFormRecord',
            salesFormStatus : 'setFormStatusUpdate'
        }),

        // ...mapActions('DataModule',[]),
        ...mapMutations('DataModule',{
            setToUpdateCount : 'setToUpdateCount',
            setTabData : 'setTabData',
            setData : 'setData',
            setDataRow : 'setDataRow',
            setDataColumn : 'setDataColumn',
            setTblKey : 'setTblKey'
        }),

        ...mapActions('SapWarehouseModule',[ 'getWarehouses' ]),
        ...mapActions('AreaModule',[ 'GetArea' ]),
        ...mapActions('ColourModule',[ 'getColour' ]),
        ...mapActions('BrandModule',[ 'GetBrandErp']),
        ...mapActions('CategoryModule',[ 'GetCategory']),
        ...mapActions('CollectionModule',[ 'GetCollections']),
        ...mapActions('ItemModule',[
        'GetBrandRelationship',
      ]),
        ...mapActions('NewItemModule',[
        'fetchBOMItemsByParentId',
      ]),
        checkGroup1(column){
           return this.group1.includes(column)
        },
        checkGroup2(column){
           return this.group2.includes(column)
        },

        /*

         showLightbox(indexGallery) {
            this.indexGallery = indexGallery;
            this.visibleGallery = true;
        },
        handleHideGallery() {
            this.visibleGallery = false;
        },
        */
        viewImage(index,row){
            const self = this;
            if(row.image.length > 0){
                self.gallery = row.image.map((i) => {
                    return i.lg
                });
                this.index = 0;
            }
        },

        viewEtaHandler(index,row){
            const self = this;
            this.viewEtaLoading = true
                const etaHanlderData = [];
            this.getEta(row).then((response) => {
                self.viewEtaLoading = false

                if(response.length > 0){
                    response.forEach((element) => {

                        etaHanlderData.push({
                            eta : element.po.eta,
                            description : element.description,
                            qty : element.qty,
                            remarks : element.remarks,
                            series : element.po.series,
                            vendor_name : element.po.vendor_name,
                            status_label : element.po.status_label,
                        })
                    })
                }


            });

             self.etaData = etaHanlderData;

            this.$refs.etaWindow.openDialog();
        },
        viewSoldToHandler(index,item){
            const self = this;
            const soldDataLocal = [];
            this.soldData = [];
            self.viewTransactionLoading = true
            self.getTransactions(item).then((response) => {
                // console.log(response);
                if(response.length > 0){


                    // const sortedActivities = response.sort((a, b) => b.date - a.date)

                        response.forEach(element => {
                            const total = unformat(element.transactions.total);
                            const paymentPaid = unformat(element.transactions.invoice.paid);
                            const balance = formatMoney((total - paymentPaid),formatMoneyConfig)
                            soldDataLocal.push({
                                // sort_date : element.transactions.invoice.id,
                                sort_date : new Date(element.transactions.date),
                                date : element.transactions.date_human,
                                customer : element.transactions.customer_mobile,
                                transaction : element.transactions.invoice.series,
                                qty : element.qty,
                                status : element.transactions.status,
                                token_id : element.transactions.token_id,
                                soldby : element.transactions.sold_by,
                                total : formatMoney(total,formatMoneyConfig),
                                balance : balance,
                            })
                        });

                        this.soldData = soldDataLocal.sort((a, b) => b.sort_date > a.sort_date ? 1: -1);
                    }
                self.viewTransactionLoading = false
            });


            /* if(transactions.length > 0) {
                transactions.forEach(element => {
                    const total = unformat(element.transactions.total);
                    const paymentPaid = unformat(element.transactions.invoice.paid);
                    const balance = formatMoney((total - paymentPaid),formatMoneyConfig)
                    soldDataLocal.push({
                        sort_date : element.transactions.date,
                        date : element.transactions.date_human,
                        customer : element.transactions.customer_mobile,
                        transaction : element.transactions.series_display,
                        qty : element.qty,
                        status : element.transactions.status_text,
                        token_id : element.transactions.token_id,
                        soldby : element.transactions.sold_by,
                        total : formatMoney(total,formatMoneyConfig),
                        balance : balance,
                    })
                });

            }

             */

            // this.soldData = soldDataLocal.sort((a, b) => new Date(b.sort_date) - new Date(a.sort_date));/

            console.log(soldDataLocal)

            this.$refs.soldToWindow.openDialog();
        },
        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

            const form  = {...obj.row,images : []}
            self.SetFormStatusUpdate()
            self.SetFormRecord(form)
            self.getRecord({token_id : obj.row.token_id})
            self.openWindow()
            const parentId = obj.row.id;

            const payload = {
                parentId: parentId,
            };
            console.log(payload)
            this.fetchBOMItemsByParentId(payload);

        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)

           this.$refs.tableImport.openDialog()
        },

        fileDataQtyUpdate(v){
        //    this.importData = v
            console.log(v)
            /*
           this.setDataRow(v.rows)
           this.setDataColumn(v.columns) */
           this.setTabData(v.tab_data)
           this.setToUpdateCount(v.to_update)
           this.updateQuantityLoading = false;
           this.updateQuantityStatus = false;
        //    this.$refs.tabTable.openDialog()
           this.$refs.tabTable.openDialog()
           /* self.updateQuantityLoading = false
               self.updateQuantityStatus = true */
        },
        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }

        },

        getActiveTabIndex(index){

            const tabs = [1,2,3,0,4,5]
            this.endpoint = 'item-v2?status='+tabs[index];
            this.SetPage(1)
            this.resetData('item-v2?status='+tabs[index]);

        },

        handleSaleTransactionClick(index, row){
             var self = this;



            const form  = {...row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}
            self.salesFormStatus()
            self.salesFormRecord(form)
            self.salesGetRecord({token_id : row.token_id})
            // self.openWindow()
            self.$refs.salesForm.openDialog()

        },

        handleHideGallery() {
            this.visibleGallery = false;
            // this.galleryKey++;

            self.gallery = [];
        },
        openImportQty(){
            this.$refs.uploadQtyForm.openDialog();
        },
        fnUpdateQuantity(){
            const self = this

            const forUpdate = this.tabData.filter((item) => {
                return item.slug === 'for-update';
            })[0];
            self.updateQuantityLoading = true

           this.updateQuantity({items :forUpdate.rows, tab_data : this.tabData}).then((response) =>{

               self.updateQuantityLoading = false
               self.updateQuantityStatus = true
                const existingTabData = self.tabData.filter((item) => item.slug === 'for-update')[0];
                existingTabData.rows = response.data;
                self.updateQuantityDownloadFile = response.download

                self.$ThatsNotify.success({
                        message : `Item was successfully update`,
                        timeOut : 10000
                    })

                /* setDataRow : 'setDataRow',
            setDataColumn : 'setDataColumn' */
           });
        },

        openImportPrice(){
            // this.$refs.uploadPriceForm.openDialog();
            this.dialogIsOpenUpdatePrice = true
        },

        fileDataPriceUpdate(){

            const self = this

            const forUpdate = this.tabData.filter((item) => {
                return item.status === true;
            })[0];
            self.updateQuantityLoading = true

            this.updatePrice({items :forUpdate.rows, tab_data : this.tabData}).then((response) =>{
                self.updateQuantityLoading = false
                self.updateQuantityStatus = true
                    const existingTabData = self.tabData.filter((item) => item.status === true)[0];
                    existingTabData.rows = response.data;
                    self.updateQuantityDownloadFile = response.download

                    self.$ThatsNotify.success({
                            message : `Item was successfully update`,
                            timeOut : 10000
                        })

                    /* setDataRow : 'setDataRow',
                setDataColumn : 'setDataColumn' */
            });

        },

        persistItemPriceData(){
            const self = this;
            const jsonData = this.spreadsheetUpdatePrice.getJson();

                // console.log(jsonData)

                const filterData = jsonData.filter(function(rowElement){
                        return (rowElement.series != "" && rowElement.sale_price != "");
                    })

                    if(filterData.length > 0){
                        filterData.forEach((el) => {

                            const i = jsonData.findIndex( (element) => element.series === el.series);
                            console.log("loading")
                            console.log(i)
                            self.spreadsheetUpdatePrice.setStyle(`A${i+1}`,'background-color','orange');
                            self.spreadsheetUpdatePrice.setStyle(`B${i+1}`,'background-color','orange');

                            self.updatePrice(el).then((response) =>{
                                console.log("response")
                                console.log(response)
                                    if(response.hasOwnProperty('series') && response.series != ""){
                                        // jsonData.filter()
                                        const ix = jsonData.findIndex( (element) => element.series === response.series);
                                        console.log("success")
                                        console.log(ix)
                                        self.spreadsheetUpdatePrice.setStyle(`A${ix+1}`,'background-color','green');
                                        self.spreadsheetUpdatePrice.setStyle(`B${ix+1}`,'background-color','green');


                                    }
                            });
                        })
                    }

              /*   this.storeRecord({"item" : filterData}).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response);
                              self.exporDataSaving = false;
                              self.isSave = true;
                              if(response.serial_id.length > 0){
                                  const idsToPrint = response.serial_id.map(e=> e.id);
                                  self.generated_serial = idsToPrint.join(',');

                              }
                            }).catch((error) => {
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       }); */
        },

        createPopupTable(exportTableOptions){
            this.spreadsheetUpdatePrice = jspreadsheet(this.$refs.spreadsheetUpdatePrice, exportTableOptions);
                Object.assign(this, this.spreadsheetUpdatePrice);
                this.spreadsheetUpdatePrice.setWidth(window.innerWidth)
            },

            async recreateSpreadsheet(){
                await this.spreadsheetUpdatePrice.destroy();
            },


    },
    computed: {
        ...mapGetters('DataModule',['tblKey','tabData','toUpdate']),
        ...mapGetters('ItemModule',[ 'total', 'length', 'columns', 'Loading', ]),
        checkRoleImportButton : function(){
                if(this.$helpers.isSalesExecutive()){
                    return false
                }
            return true;
        },
        checkRoleNewButton : function(){
            if(this.$helpers.isSalesExecutive()){
                return false
            }
            return true;
        },
        computedArea : function(){
            return this.area;
        }
    },

    watch : {

        dialogIsOpenUpdatePrice : function(v){
            if(v === false){
                this.spreadsheetUpdatePrice.destroy();
            }else{
                this.createPopupTable(this.spreadsheetUpdatePriceOptions)
            }
        }
    }
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>
