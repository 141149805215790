import { render, staticRenderFns } from "./ColourForm.vue?vue&type=template&id=63f22894&scoped=true&"
import script from "./ColourForm.vue?vue&type=script&lang=js&"
export * from "./ColourForm.vue?vue&type=script&lang=js&"
import style0 from "./ColourForm.vue?vue&type=style&index=0&id=63f22894&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63f22894",
  null
  
)

export default component.exports