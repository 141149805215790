<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :title="'Redeem Benefits'" :visible.sync="syncModal" :close-on-click-modal="false" width="60%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>

                  <div class="filter-dalog-body p-0">

                      <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="400" stripe>
                          <el-table-column type="index" width="35" label="#"> </el-table-column>
                          <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                              <template slot-scope="scope">
                                  <template v-if="col.field === 'name'">
                                      {{ scope.row.benefit.name}}
                                  </template>
                                  <template v-if="col.field === 'reference_code'">
                                      {{ scope.row.reference_code}}
                                  </template>
                                  <template v-if="col.field === 'description'">
                                      {{ scope.row.description}}
                                  </template>
                                  <template v-if="col.field === 'issue_date'">
                                      {{ scope.row.issue_date}}
                                  </template>
                                  <template v-if="col.field === 'redeem_date'">
                                      {{ scope.row.redeem_date}}
                                  </template>
                                  <template v-if="col.field === 'status'">
                                      {{ scope.row.status}}

                                      <template v-if="scope.row.status === 'issue'">
                                          <mdb-btn color="elegant" size="sm" @click="openRedeemedModal(scope.row)">Redeemed</mdb-btn>
                                      </template>
                                  </template>
                              </template>
                          </el-table-column>
                          <template slot="empty">
                              <template v-if="viewTransactionLoading">
                                  <mdb-btn color="elegant" size="sm" rounded disabled>
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Loading data...
                                  </mdb-btn>

                              </template>
                              <template v-else>
                                  No Data
                              </template>
                          </template>
                      </el-table>
<!--                      <el-pagination-->
<!--                          @size-change="handleSizeChange"-->
<!--                          @current-change="handleCurrentChange"-->
<!--                          :current-page.sync="current_page"-->
<!--                          :page-sizes="[10,25,50,100, 200, 300, 400]"-->
<!--                          :page-size="length"-->
<!--                          layout="total, sizes, prev, pager, next, jumper"-->
<!--                          :total="total">-->
<!--                      </el-pagination>-->

                  </div>

                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
<!--                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>-->
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>
              <CustomerServiceRedeemed ref="updateServiceBenefit" ></CustomerServiceRedeemed>
          </ValidationObserver>


  </div>
</template>
<script>
import { mdbBtn } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import CustomerServiceRedeemed from "./CustomerServiceRedeemed.vue";

export default {
    components: {
        CustomerServiceRedeemed,
        mdbBtn,
        ValidationObserver,
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
        endpoint : {
            type : String,
            default : `loyalty-issue-coupon`
        },
        columns : {
            type : Array,
            default : () => {
                return [
                    {
                        label: 'Name',
                        field: 'name',
                    },
                    {
                        label: 'Reference Code',
                        field: 'reference_code',
                    },
                    {
                        label: 'Description',
                        field: 'description',
                    },
                    {
                        label: 'Issue Date',
                        field: 'issue_date',
                    },
                    {
                        label: 'Redeem Date',
                        field: 'redeem_date',
                    },
                    {
                        label: 'Status',
                        field: 'status',
                    },
                ]
            }
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      formData : null,
      benefit_data : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        isWalkin : false,
      disabled : false,
      isModal : false,
      group2 : 3
    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();



    },
  mounted() {
      //

  },
  methods: {

      ...mapActions('LoyaltyRedeemServiceModule',[
          'asyncGetData',
          'resetData',
      ]),
      ...mapMutations('LoyaltyRedeemServiceModule',[
          'SetTotal',
          'SetLength',
          'SetPage',
          'SetTerm',
          'SetSelection',
          'SetEmptyData',
          'SetFilters',
      ]),


      formVar(){
        return {
            customer_id : "",
            ref_code : "",
            ref_store : "",
            expiry : "",
          showroom : "",
            coupons : [],
            attr:{},
            validity_date: new Date().toISOString().substr(0, 10),
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },
    openNewDialog(data, formData) {

        console.log(data.benefit_id)
           this.benefit_data = data;
           this.formData = formData;
      this.syncModal = true;
        this.fetchDataFromApi()
      this.form = {
        customer_id: formData.customer_id,
        benefit_id: data.benefit_id,
      };
    },


    /* end */
        openDialog(){
            this.syncModal = true


        },

      async fetchDataFromApi() {
          const endpoint = `loyalty-program/loyalty-service-benefit/redeemed-service/${this.formData.series}/${this.benefit_data.benefit_id}`;

          try {
              const responseData = await this.asyncGetData(endpoint);
              console.log(responseData);
          } catch (error) {
              console.error(error);
          }
      },

      handleSizeChange(val) {
          this.SetLength(val)

          this.dispatchHandler()
      },
      handleCurrentChange(val) {
          this.dispatchHandler()
      },
      handleSearchTerm() {
          this.SetTotal(0);
          this.dispatchHandler()
      },
      handleSelectionChange(val){
          this.SetSelection(val)
      },
      refreshTable(){
          this.SetFilters(this.filterObj)
          this.resetData(this.endpoint);
      },
        closeDialog(){
            this.syncModal = false
          this.FormResetter();
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
        },

      openRedeemedModal(row) {
          // Handle logic to open modal or trigger an action
          console.log('Redeemed button clicked for row:', row);
          // You can emit an event to be handled by a parent component
          this.$refs.updateServiceBenefit.openNewDialog(row, this.formData);
          // this.$emit('redeemed-button-clicked', row);
      },


      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false;

                              if (error.response) {
                                  if (error.response.status === 404) {
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  } else if (error.response.status === 422) {
                                      // Handle coupon not found error
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  }
                              } else if (error.request) {
                                  error.response.data.message = error.request;
                              } else {
                                  error.response.data.message = error.message;
                              }

                              return;
                          });

                      }

                      if(self.FormStatus == 'update'){

                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false;

                              if (error.response) {
                                  if (error.response.status === 404) {
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  } else if (error.response.status === 422) {
                                      // Handle coupon not found error
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  }
                              } else if (error.request) {
                                  error.response.data.message = error.request;
                              } else {
                                  error.response.data.message = error.message;
                              }

                              return;
                          });
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        // this.SetFormStatusCreate();
        this.showError = false

      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {

      ...mapGetters('LoyaltyMemberServiceModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
        ...mapGetters('LoyaltyRedeemServiceModule',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',
            'tableLoading',
            'Selection',
            'viewTransactionLoading',
        ]),
      ...mapGetters('AuthModule',[ 'user', ]),

      enableEditButton : function(){
        return !["AMTM","AMDFC","TLKD","ECDFC","MPDFC",null,"null"].includes(this.form.showroom.prefix)
      },
        current_page : {
            get : function(){
                return this.page
            },
            set : function(val){
                this.SetPage (val)
            }
        },

    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){



          const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            this.form = {...this.form,...frm}
          this.getContact({mdbID : frm.customers.mdbID}).then((response)=>{
            self.activeCustomer = response
          })
            this.form.attr.name = frm.customers.name;

          const couponResponse = frm.coupons;

          if (couponResponse && couponResponse.length > 0) {
            // Transform coupons

            const transformedCoupons = couponResponse.map((coupon, index) => ({
              index,
              text: coupon.code,
              slug: coupon.code,
            }));

            // Assign the transformed coupons to this.form.coupons
            this.form.coupons = transformedCoupons;
          }
          this.form.ref_code = frm.reference_code;
          this.form.ref_store = frm.store_reference;
          this.form.expiry = frm.expiry_month_difference;
          this.form.id = frm.id;

          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.custom-z-index {
  z-index: 0 !important; /* Set the desired z-index */
}
</style>
