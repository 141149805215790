<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">

        <div class="form-group input-group-sm row thats-form">
            <label class="col-form-label" :class="labelClass"></label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-form-inline tag="div" class="mb-2">
                <mdb-input type="radio" id="option5-1" class="thats-status-radio" name="groupOfMaterialRadios2" radioValue="true" v-model="inputValue" label="Active" />
                <mdb-input type="radio" id="option5-2" class="thats-status-radio" name="groupOfMaterialRadios2" radioValue="false" v-model="inputValue" label="Inactive" />
                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
                </mdb-form-inline>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline } from "mdbvue";
export default {
    name : 'ThatsStatus',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: String,
            default : 'true'
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        }

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
    },

    data : ()=>({
        inputValue : 'true'
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
            
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>

<style scoped>

</style>