import { ItemService, ApiService } from '../../services'
import {Unauthorized} from '../../helpers/unauthorized.handler'
export const BarcodeFabricModule = {
    namespaced: true,
    state: {
        loading: false,
        viewLoading: false,
        Deleteloading: false,
        data : {
            columns: [

                {
                    label: 'SKU/Code',
                    field: 'series',
                    sort: 'asc',
                    width : '150',
                
                },
                
                {
                    label: 'Brand/Supplier',
                    field: 'brand_name',
                    sort: 'asc',
                    clickable: true,
                    width: '250',
                   
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                    width: '250',
                   
                },
                {
                    label: 'Image',
                    field: 'thumb',
                    image: true,  
                    sort: 'asc',  
                    width: '120',                 
                },

                {
                    label: 'Description',
                    field: 'description',
                    width: '200',   
                },

             
                {
                    label: 'Price',
                    field: 'formatted_price',
                    align : 'right',
                    width: '100',
                   
                },
                {
                    label: 'Qty',
                    field: 'qty',
                    align : 'right',
                    width: '100',
                   
                },

            ],
            rows: [],
        },
        length : 25,
        page : 0,
        total : 1,
        searchTerm : null,
        filters : null,

        formStatus : 'create',
        formStatusCopy: 'create',
        formRecord : {},
        formRecordCopy : {},

        area : [],
        brands : [],
        category : [],
        collections : [],
        colour : [],
        materials : [],

    },
    actions : {
        async getEta({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-eta/${params.token_id}`).then((response) => {
                        // commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getTransactions({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-transactions/${params.token_id}`).then((response) => {
                        // commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getRecord({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-item/${params.token_id}`, params).then((response) => {
                        commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        GetBrandRelationship({ dispatch },params) {
            return new Promise((resolve) => {
                ItemService.get(`/${params.endpoint}`, params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async GetNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                    ItemService.get('/code', {}).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        Unauthorized(dispatch, error)
                    })
            })
        },

        async asyncGetData({ rootState, commit,dispatch }) {
            const { loading, length, page, filters, searchTerm} = rootState. ItemModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ItemService.get('/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch,error)
                    })
                }
            })
        },
        
        ReplicateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState,commit},payload){
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ItemService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.saveImport(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    mutations: {
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        SetCode: (state, payload)=>{
            state.form.item_code = payload
        },
        
        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },
        SetFormCopyStatusCreate: (state)=>{
            state.formStatusCopy = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormCopyStatusUpdate: (state) => {
            state.formStatusCopy = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        SetFormRecordCopy: (state,payload) => {
            state.formRecordCopy = payload
        },
        setArea: (state,payload) => {
            state.area = payload
        },
        setBrands: (state,payload) => {
            state.brands = payload
        },
        setCategory: (state,payload) => {
            state.category = payload
        },
        setCollections: (state,payload) => {
            state.collections = payload
        },
        setColour: (state,payload) => {
            state.colour = payload
        },
        setMaterials: (state,payload) => {
            state.colour = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        formStatusCopy: state => state.formStatusCopy,
        FormRecord: state => state.formRecord,
        formRecordCopy: state => state.formRecordCopy,
        getBrands: state => state.brands,
        getArea: state => state.area,
        getCategory: state => state.category,
        getCollections: state => state.collections,
        getColour: state => state.colour,
        getMaterials: state => state.materials,

    }
}


