<template>
    <div>
        <mdb-card wide class="bg-white">
            <div v-html="signature" ref="signature"></div>
        </mdb-card>
        <mdbBtn @click="copySignature" type="button" color="elegant" size="sm" class="w-100 m-0 mt-2">Copy</mdbBtn>
    </div>
</template>

<script>
import {mdbCard, mdbBtn} from 'mdbvue'
import { Message } from 'element-ui';
export default {
    components : {
        mdbCard,
        mdbBtn
    },
    data() {
        return {
            syncDialog: false,
            showClose: true,
            getLoading: false,
            signature : null,
            signatureData : {
                name: '',
                designation: '',
                email: '',
                phone: '',
            }
        }
    },
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    designation: '',
                    email: '',
                    phone: '',
                }
            
            }
        }
    },

    mounted(){
        this.generateSignature();
    },
    methods: {

        fnClose(){
            this.$emit('close');
        },
        
        fnSelect(location){
            this.$emit('select', location);
            this.$nextTick(() => {
                this.syncDialog = false;
            })
        },
        
        
        openDialog(){
            this.syncDialog = true;
        },
        closeDialog(){
            this.syncDialog = false;
            this.$nextTick(() => {
                this.syncDialog = false;
            })
        },

        copySignature(){
            const range = document.createRange();
            const selection = window.getSelection();
            selection.removeAllRanges();

            range.selectNodeContents(this.$refs.signature);
            selection.addRange(range);
            document.execCommand('copy');
            Message.success({
                message: "Copied!"
                });
        },
         copyText(text) {
            var dummy = document.createElement("div");
              document.body.appendChild(dummy);
              dummy.setAttribute("id", "dummy_id");
              document.getElementById("dummy_id").value=text;
              dummy.select();
              document.execCommand("copy");
              document.body.removeChild(dummy);
                Message.success({
                message: "Copied!"
                });
        },

        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            },
        generateSignature(){
            //background:#fff url('https://media.thatsliving.com/public/uploads/image-png/full-147203669f2e07b137928a8c2d739939-background.png') no-repeat;background-size: 446px;background-position-x: right;
           // <img src="https://media.thatsliving.com/public/uploads/image-png/full-147203669f2e07b137928a8c2d739939-background.png" style="height: 200px;right: 0;position: absolute; z-index: 0;"/>
            const x = `
            <div style="background:#fffff!important;background-color:#fffff!important;height: 210px;">
            
            <table style="border: none;width:916px;background:#fffff!important;background-color:#fffff!important;border-collapse: collapse;">
                    <tbody>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;text-align:center;width:275px;background:#fffff!important;background-color:#fffff!important;">
                                <a href="https://www.thatsliving.com/" target="_blank" style="display: block;"><img width="234" src="https://thatsliving.com/cdn/shop/files/TL_design_destrict_logo3.jpg?v=1693142386&width=300" alt="Logo" style="margin:20px;margin-bottom:10px"></a>
                            
                                <a href="https://cdn.shopify.com/s/files/1/0788/3990/6604/files/THAT_S_LIVING_COMPANY_PROFILE_2023.pdf?v=1695562793" style="display: block;height: 30px;" target="_blank"><img style="width: 160px;margin: 0 auto;position: relative;" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/company-profile-btn.png" alt="Company Profile" style="width: 110px;margin: 0 auto;marbgin-bottom:10px"></a>
                                    <table style="border: none;margin:0 auto;">
                                            <tbody>
                                                
                                                <tr>
                                                    <td style="vertical-align: bottom;text-align:center">
                                                        <a href="https://www.instagram.com/thatsliving.qatar/" target="_blank"><img width="28" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/insta-icon.png" alt="Instagram"></a>
                                                        <a href="https://www.facebook.com/thatslivingqatar/" target="_blank"><img width="28" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/fb-icon.png" alt="Facebook"></a>
                                                        <a href="https://www.tiktok.com/@thatsliving.qatar/" target="_blank"><img width="28" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/tiktok-icon.png" alt="Tiktok"></a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                            </td>
                            <td style="width: 2px;padding: 0in;vertical-align: center;background:#fffff!important;background-color:#fffff!important;">
                            <img src="https://media.thatsliving.com/public/uploads/image-png/full-ca84373d7de1c554c2c6c235d6d5c3c5-vertical-strong-line.png" style="height:160px;width: 3px;">

                            </td>
                            <td style="padding: 0in;vertical-align: center;padding-left:20px; width: 320px;background:#fffff!important;background-color:#fffff!important;">

                                    <h4 style="color:#453a32;font-family:'Arial',sans-serif;margin: 0;font-size: 24px;margin-top:20px;"><span style='font-weight:bolder'>${this.signatureData.name}</span></h4>
                                    <p style="color:#453a32;font-family: 'Aptos',sans-serif;margin: 0;font-size: 18px;"><span >${this.signatureData.designation}</span></p>

                                <table style="border: none;margin-top:15px;margin-bottom:15px;border-collapse:collapse;mso-yfti-tbllook:1184;heigth:100px;line">
                                    <tbody>
                                        <tr>
                                            <td style="width:32px;line-height:12px;">
                                                <img width="24" style="margin:0px" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/phone-icon.png" alt="Mobile">
                                            </td>
                                            <td>
                                                <strong style="font-size:9pt;color:#453a32;line-height:12px">${this.signatureData.phone}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:32px;line-height:12px;">
                                                <img width="24" style="margin:0px" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/email-icon.png" alt="Email">
                                            </td>
                                            <td>
                                                <strong style="font-size:9pt;color:#453a32;line-height:12px">${this.signatureData.email}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:32px;line-height:12px;">
                                                <img width="24" style="margin:0px" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/website-icon.png">
                                            </td>
                                            <td>
                                                <strong style="font-size:9pt;color:#453a32;line-height:12px">www.thatsliving.com</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:32px;line-height:12px;">
                                                <img width="24" style="margin:0px" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/map-pin-icon.png" alt="Address">
                                            </td>
                                            <td>
                                                <strong style="font-size:9pt;color:#453a32;line-height:12px">Doha | Moscow | Sochi | Krasnodar | Warsaw</span>
                                            </td>
                                        </tr>
                                       
                                        
                                    </tbody>
                                </table>
                            </td>

                            <td style="background:#fffff!important;background-color:#fffff!important;">
                            <img src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/background.png" style="height: 210px; width:100%"/>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>`;

            this.signature =  `
                <html>
                    <head>
                        <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
                        <meta name=Generator content="Microsoft Word 15 (filtered)">
                        <style>
                            <!--
                                /* Font Definitions */
                                @font-face
                                    {font-family:Helvetica;
                                    panose-1:2 11 6 4 2 2 2 2 2 4;}
                                @font-face
                                    {font-family:"Cambria Math";
                                    panose-1:2 4 5 3 5 4 6 3 2 4;}
                                @font-face
                                    {font-family:Aptos;}
                                /* Style Definitions */
                                p.MsoNormal, li.MsoNormal, div.MsoNormal
                                    {margin-top:0in;
                                    margin-right:0in;
                                    margin-bottom:8.0pt;
                                    margin-left:0in;
                                    line-height:107%;
                                    font-size:11.0pt;
                                    font-family:"Aptos",sans-serif;}
                                p.MsoNoSpacing, li.MsoNoSpacing, div.MsoNoSpacing
                                    {margin:0in;
                                    font-size:11.0pt;
                                    font-family:"Aptos",sans-serif;}
                                .MsoPapDefault
                                    {margin-bottom:8.0pt;
                                    line-height:107%;}
                                @page WordSection1
                                    {size:595.3pt 841.9pt;
                                    margin:1.0in 1.0in 1.0in 1.0in;}
                                div.WordSection1
                                    {page:WordSection1;}
                                -->
                        </style>
                    </head>
                    <body lang=EN-GB style='word-wrap:break-word'>
                        <div class=WordSection1>
                            <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=916
                                style='width:687.0pt;background:white;border-collapse:collapse'>
                                <tr>
                                <td width=275 style='width:206.25pt;padding:0in 0in 0in 0in'>
                                    <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
                                        line-height:normal'><span style='color:black'><a
                                        href="https://www.thatsliving.com/" target="_blank"><span style='font-size:
                                        12.0pt;font-family:"Helvetica",sans-serif;color:#007BFF;text-decoration:none'><img
                                        border=0 width=234 height=82 id="Picture 11" src="https://thatsliving.com/cdn/shop/files/TL_design_destrict_logo3.jpg?v=1693142386&width=300"
                                        alt=Logo></span></a><a
                                        href="https://cdn.shopify.com/s/files/1/0788/3990/6604/files/THAT_S_LIVING_COMPANY_PROFILE_2023.pdf?v=1695562793"
                                        target="_blank"><span style='font-size:13.0pt;font-family:"Helvetica",sans-serif;
                                        color:#007BFF;text-decoration:none'><img border=0 width=146 height=26
                                        id="Picture 10" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/company-profile-btn.png" alt="Company Profile" style="margin:3px"></span></a></span></p>
                                    <div align=center>
                                        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
                                            style='border-collapse:collapse'>
                                            <tr>
                                            <td valign=bottom style='padding:.75pt .75pt .75pt .75pt'>
                                                <p class=MsoNormal align=center style='margin-bottom:0in;text-align:center;
                                                    line-height:normal'><a href="https://www.instagram.com/thatsliving.qatar/"
                                                    target="_blank"><span style='font-size:12.0pt;font-family:"Times New Roman",serif;
                                                    color:#007BFF;text-decoration:none'><img border=0 width=28 height=28
                                                    id="Picture 9" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/insta-icon.png" alt=Instagram></span></a><span
                                                    style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span><a
                                                    href="https://www.facebook.com/thatslivingqatar/" target="_blank"><span
                                                    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#007BFF;
                                                    text-decoration:none'><img border=0 width=28 height=28 id="Picture 8"
                                                    src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/fb-icon.png" alt=Facebook></span></a><span
                                                    style='font-size:12.0pt;font-family:"Times New Roman",serif'>&nbsp;</span><a
                                                    href="https://www.tiktok.com/@thatsliving.qatar/" target="_blank"><span
                                                    style='font-size:12.0pt;font-family:"Times New Roman",serif;color:#007BFF;
                                                    text-decoration:none'><img border=0 width=28 height=28 id="Picture 7"
                                                    src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/tiktok-icon.png" alt=Tiktok></span></a></p>
                                            </td>
                                            </tr>
                                        </table>
                                    </div>
                                </td>
                                <td width=2 style='width:1.5pt;padding:0in 0in 0in 0in'>
                                    <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
                                        style='font-size:12.0pt;font-family:"Helvetica",sans-serif;color:#212529'><img
                                        border=0 width=5 height=124 id="Picture 6" src="https://media.thatsliving.com/public/uploads/image-png/full-ca84373d7de1c554c2c6c235d6d5c3c5-vertical-strong-line.png"></span></p>
                                </td>
                                <td width=320 style='width:240.0pt;padding:0in 0in 0in 15.0pt'>
                                    <p class=MsoNormal style='margin-top:15.0pt;margin-right:0in;margin-bottom:
                                        0in;margin-left:0in;line-height:normal'><strong><b><span style='font-size:18.0pt;
                                        font-family:"Arial",sans-serif;color:#453A32;font-wedith:bolder'>${this.signatureData.name}</span></b></strong></p>
                                    <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
                                        style='font-size:13.5pt;color:#453A32'>${this.signatureData.designation}</span></p>
                                    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
                                        style='border-collapse:collapse;margin-top:10px'>
                                        <tr>
                                            <td width=32 style='width:24.0pt;padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><img border=0 width=24 height=24 id="Picture 5"
                                                src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/phone-icon.png" alt=Mobile></p>
                                            </td>
                                            <td style='padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><span style='font-size:9.0pt;color:#453A32'>${this.signatureData.phone}</span>
                                            </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width=32 style='width:24.0pt;padding:.75pt .75pt .75pt .75pt'>
                                                <p class=MsoNoSpacing><img border=0 width=24 height=24 id="Picture 4" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/email-icon.png" alt=Email></p>
                                            </td>
                                            <td style='padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><span style='font-size:9.0pt;color:#453A32'>${this.signatureData.email}</span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width=32 style='width:24.0pt;padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><img border=0 width=24 height=24 id="Picture 3" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/website-icon.png"></p>
                                            </td>
                                            <td style='padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><span style='font-size:9.0pt;color:#453A32'>www.thatsliving.com</span></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width=32 style='width:24.0pt;padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><img border=0 width=24 height=24 id="Picture 2" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/map-pin-icon.png" alt=Address></p>
                                            </td>
                                            <td style='padding:.75pt .75pt .75pt .75pt'>
                                            <p class=MsoNoSpacing><span style='font-size:9.0pt;color:#453A32'>Doha | Moscow | Sochi | Krasnodar | Warsaw</span></p>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style='padding:.75pt .75pt .75pt .75pt'>
                                    <p class=MsoNormal style='margin-bottom:0in;line-height:normal'><span
                                        style='font-size:12.0pt;font-family:"Helvetica",sans-serif;color:#212529'><img
                                        border=0 width=327 height=186 id="Picture 1" src="https://media.thatsliving.com/public/uploads/image-png/signature-icons/background.png"></span></p>
                                </td>
                                </tr>
                            </table>
                        </div>
                    </body>
                    </html>

                `;
        }
    },

    watch : {
        value : {
            deep : true,
            handler : function(value){
                console.log("value")
                console.log(value)
                this.signatureData = value;
                this.generateSignature();
            }
        }
    }



}
</script>
<style scoped lang="scss">

</style>