import Axios from 'axios'
import { bearer } from '../helpers'
import store from '../store/furnishweb';

export const furnishwebHttpService = {

    getRequest(endpoint = '', params = {}){
        return Axios.get(`api/${endpoint}`, { params: {...params }, headers : {...bearer()}}).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    postRequest(endpoint = '', params = {}){
        return Axios.post(`api/${endpoint}`, params, { headers: bearer() }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    getToken(token){
        return Axios.get(`api/auth-token?token=${token}`).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    
    get(endpoint = '', params,configParams) {
        if(store.getters[configParams.req]) this.cancelRequest(configParams)
        return this.makeRequest(endpoint,params,configParams);
        
    },
     makeRequest(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();
        // store.commit(configParams.loadingTrue)
        store.commit(configParams.addRequest,axiosSource)
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}

        return Axios.get(`api/${endpoint}`, { params: {...params }, cancelToken: axiosSource.token, headers : {...bearer()}}).then((response) => {
            self.clearOldRequest(configParams,"Success");
           /*  if(configParams.hasOwnProperty('loadingFalse')){
                store.commit(configParams.loadingFalse)
            } */
            return Promise.resolve(response)
        }).catch((error) => {
            // self.logResponseErrors(error)
           /*  console.log("configParams.loadingFalse")
            console.log(configParams.loadingFalse)
            if(configParams.hasOwnProperty('loadingFalse')){
                store.commit(configParams.loadingFalse)
            } */
            return Promise.reject(error)
        })
    },

    post(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();

        if(configParams.length > 0){
            store.commit(configParams.addRequest,axiosSource)
        }
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}

        // console.log(params)
        return Axios.post(`api/${endpoint}`, params, { headers: bearer() }).then((response) => {
            
            if(configParams.length > 0){
                self.clearOldRequest(configParams,"Success");
            }
            return Promise.resolve(response)
        }).catch((error) => {

            if(configParams.length > 0){
                self.logResponseErrors(error)
            }
            return Promise.reject(error)
        })
    },

    store(endpoint,object){

        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}
        return Axios.post(`api/${endpoint}`, params, { headers: bearer() }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    upload(endpoint,formData){

        let session = JSON.parse(localStorage.getItem('user'));
        formData.append('location', session.location.id);
        formData.append('prefix', session.location.prefix);

        
        return Axios.post(`api/${endpoint}`, formData, { 
            headers: {
                'Content-Type': 'application/octet-stream',
                ...bearer(),
            }
        
        }).then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async update(endpoint, object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = { ...location, ...object }
        return await Axios.put(`/api/${endpoint}`, params, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async delete(endpoint, params) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        return await Axios.post(`/api/${endpoint}/${user.cartKey}/delete`, {...location, ...params},{ headers: {
            'Content-Type': 'application/octet-stream',
            ...bearer(),
        }}
         ).catch((error) => {
            return Promise.reject(error)
        })
    },

    cancelRequest(configParams){
        store.commit(configParams.cancelRequest)
        // store.commit(configParams.loadingTrue)
    },
    
    logResponseErrors(err) {
        console.log("http error")
        console.log(err)
        if (Axios.isCancel(err)) {
            console.log("Request cancelled");
        }
    },
    clearOldRequest(configParams,msg) {
          store.commit(configParams.clearOldRequest,msg)    
    }
}