<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsCustomWindow ref="window" title="Change Location" windowClass="collection height-auto" scrollClass='collection height-auto' :height="300" :width="450" @dialog-state="dialogState" :group="group">
            <mdbContainer class="mt-3">
                <mdb-row class="mb-2">
                  <mdb-col col="12">
                      <ThatsInput tabindex="2" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="From" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>
                      <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Location" vid="location_prefix" name="location_prefix"  v-model="form.prefix" :reduce="label => label.value" :reduceLabel="'label'" :options="location" :rules="'required'" :clearable="false" :disabled="false" @select="getLocationSelected()"></ThatsCustomMultiSelect>
                      <ThatsInput tabindex="2" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="To" vid="series_to" name="series_to" v-model="form.replace" size="sm" :disabled="true"/>
                  </mdb-col>
                </mdb-row>
                <mdb-row >
                  <mdb-col col="9">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))" :disabled="((form.prefix !=='') ? false : true)">Change Location</mdb-btn>
                      </template>
                  </mdb-col>     
                </mdb-row>
                      
              </mdbContainer>
       </ThatsCustomWindow>
          </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters } from 'vuex'

import { ThatsInput, ThatsCustomWindow, ThatsCustomMultiSelect} from '../../components/thats/Thats'
export default {
    props : {
      group : {
        type : Number,
        default : 3
      },
      endpoint : {
        type : String,
        default : 3
      }
    },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        ValidationObserver,
        ThatsCustomMultiSelect, 
        ThatsInput,
        ThatsCustomWindow,
  },

    data : () => ({
      active : 0,
      disabled : false,
      disabledBtn : false,
      validated : false,
      formOriginal : null,
      form : {
            series : "",
            prefix : "",
            replace : "",
        },
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      location : []

    }),

    created : function() {
      this.form = this.formVar();
      this.optionsHandler();
    },



    methods: {

      ...mapActions('UpdateLocationModule',['updateRecord']),
       ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
      formVar(){
        return {
            series : "",
            origin : "",
            prefix : "",
            replace : "",
        }
      },
        /* start */


        optionsHandler(){
          const self = this
        // console.log(this.form)


        // if(this.)

        /*  self.GetShowrooms().then((response) =>{
           self.location = []
            if(response.length > 0){
              response.forEach((element) => {

                if(element.prefix !== self.form.origin){
                  const d = {
                    label : element.label,
                    value : element.prefix
                  }
                  self.location.push(d)
                  }
                
              })
            }
        }) */
        },

nextCode(){
        // var self = this;
        /* this.getNextCodePayment().then((response) => {
          self.form.series = response.code
        }); */
      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

     onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;
            if (isValid) {
              this.formLoading = true
                const params = {endpoint : this.endpoint, payload : self.form};
                self.updateRecord(params).then((response) => {
                    self.formLoading = false
                    const emitData = {...response,...self.form};
                    self.$emit('success',emitData);
                });
            }        
      },
      formResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
        this.disabledBtn = false

      },
      
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.formResetter();
          return
        }

      },

      getLocationSelected(){
          console.log("location selection")


          
          // console.log(res)
      }

    },

    computed: {

      ...mapGetters('UpdateLocationModule',
      [
        'formRecord'
      ]),

      },

    watch : {
      formRecord : function(v){
          this.form = v
      },

      "form.prefix" : function(v){
          const {origin,series} = this.form;
          var str = series;
          this.form.replace = str.replace(origin, v);

      }
    }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>