import Axios from 'axios'
import { bearer, token } from '../helpers'
export const ItemService = {
    saveImport(object) {
        return Axios.post(`/api/item/save-import`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    store(object) {
        // const formData = new FormData();
       /*  for (var key in object) {
            var value = object[key];
            formData.append(key, value);
        } */

        /* formData.append('files', object.files[0], object.files[0].name)
        formData.append('name', object.name) */

        /* charset=utf-8; boundary=" + Math.random().toString().substr(2) */
        /* const config = {
            headers: { ...bearer(), 'content-type': 'multipart/form-data;charset=utf-8; boundary=' + Math.random().toString().substr(2),
                "Access-Control-Allow-Origin": "*",}
        } */
        return Axios.post(`/api/item`, object, { headers: bearer()}).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    storeBom(object){
        return Axios.post(`/api/bill_of_material_items`, object, { headers: bearer()}).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    fetchItemByParentId(object){
        return Axios.post(`/api/bill_of_material_items/get_by_parent_id`, object, { headers: bearer()}).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    storeNewImport(object) {
        // const formData = new FormData();
       /*  for (var key in object) {
            var value = object[key];
            formData.append(key, value);
        } */

        /* formData.append('files', object.files[0], object.files[0].name)
        formData.append('name', object.name) */

        /* charset=utf-8; boundary=" + Math.random().toString().substr(2) */
        /* const config = {
            headers: { ...bearer(), 'content-type': 'multipart/form-data;charset=utf-8; boundary=' + Math.random().toString().substr(2),
                "Access-Control-Allow-Origin": "*",}
        } */
        return Axios.post(`/api/item-import`, object, { headers: bearer()}).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', params){
        return await Axios.get(`/api/item${endpoint}`, { params: { ...token(), ...params } }).then(response => {
                return Promise.resolve(response)
            }).catch((error) => {
                return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/item`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    async updateItemCode(obj) {
        return await Axios.put(`/api/update-item-code`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    async updatePrice(obj) {
        return await Axios.put(`/api/item/import-price-update`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async bulkUpdateItem(obj){
        return await Axios.put(`/api/item/bulk-item-update`,obj,{headers : bearer()})
        .then( res => Promise.resolve(res))
        .catch(er => Promise.reject(er))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/item`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    async deleteBOMItem(obj) {
        try {
            const response = await Axios.post(`/api/delete-bom-items`,obj, {
                headers: bearer()
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async checkBOMItem(obj) {
        try {
            const response = await Axios.post(`/api/check-item-exist`,obj, {
                headers: bearer()
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }


}
