import { ApiService } from '../../services'
import {Unauthorized} from '../../helpers/unauthorized.handler'
export const UserModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data : {
            columns: [


                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                    width: '250',

                },
                {
                    label: 'Email',
                    field: 'email',
                    width: '200',
                },


                {
                    label: 'Role',
                    field: 'role',

                },
                {
                    label: 'Mobile',
                    field: 'mobile',

                },
                {
                    label: 'Designation',
                    field: 'designation',

                }
            ],
            rows: [],
        },
        length : 25,
        page : 0,
        total : 1,
        searchTerm : null,
        filters : null,

        formStatus : 'create',
        endpoint : 'user',
        formRecord : {},

    },
    actions : {

        async filterEmployees({ dispatch,rootState },params) {
            return await new Promise((resolve) => {
                const { endpoint } = rootState.UserModule
                ApiService.get(`${endpoint}/filter-employees`, params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async filterItems({ dispatch,rootState },params) {
            return await new Promise((resolve) => {
                const { endpoint } = rootState.UserModule
                ApiService.get(`${endpoint}/filter`, params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async checkToken(ctx) {
            const { endpoint } = ctx.rootState.UserModule
            console.log("Dispatching check token")
            return await new Promise((resolve,reject) => {
                ApiService.get(`${endpoint}/check-token`, {}).then((response) => {
                    ctx.commit("setLogin",true, { root: true })
                    ctx.commit("setUser",response.data, { root: true })
                    resolve(response.data)
                    console.log("ctx-success")
                    console.log(ctx)
                }).catch(error => {
                    console.log("apiservice")
                    console.log(error)
                    reject(error);
                })
            })
        },

        async GetNextCode({ rootState,dispatch }) {
            const { endpoint } = rootState.UserModule
            return await new Promise((resolve) => {
                ApiService.get(`${endpoint}/code`, {}).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        Unauthorized(dispatch, error)
                    })
            })
        },

        async asyncGetData({ rootState, commit,dispatch }) {
            const { loading, length, page, filters, searchTerm, endpoint} = rootState.UserModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get(`${endpoint}/data`, params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch,error)
                    })
                }
            })
        },

        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = rootState.UserModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.UserModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        bulkResetPassword({state},payload){
            const {endpoint} = state
            return new Promise((resolve, reject) => {
                ApiService.update(`${endpoint}/bulk-reset`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        async DeleteRecord({ rootState,commit},payload){
            var self = this
            return await new Promise((resolve, reject) => {
                const { endpoint, Deleteloading } = rootState.UserModule;
                if (!Deleteloading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete(`${endpoint}`,tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        authorizedUser({ state,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = state;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store(`${endpoint}/authorized`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        SetCode: (state, payload)=>{
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
    }
}


