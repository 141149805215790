/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <XWindow ref="window" :title="`Add Sales Target`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>
              <template v-if="formLoading || getLoading || postLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>

<!--                        <template v-if="formStatus == 'update'">-->
<!--                          <mdb-btn color="elegant" icon="printer" size="sm" @click="fnPrintDoc('/inventory-transfer-request/print/'+form.series)"><i class="el-icon-printer"></i> PRINT</mdb-btn>-->
<!--                        </template>-->
                        <template v-if="form.status == 'draft'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('draft'))"><i class="el-icon-success"></i> Save</mdb-btn>
                        </template>
                        <template v-if="form.status == 'for-approval' && isAllowedToApproved">
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('for-approval'))"><i class="el-icon-circle-check"></i> Save</mdb-btn>
                          <mdb-btn color="success" size="sm" @click="passes(onSubmitForm('approved'))"><i class="el-icon-check"></i> Approved</mdb-btn>
                          <mdb-btn color="danger" size="sm" @click="passes(onSubmitForm('rejected'))"><i class="el-icon-close"></i> Reject</mdb-btn>
                        </template>

                      </template>
          </template>

            <template #right-button>
            </template>
            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

                <mdb-row class="tab-row">
                  <mdb-col col="12" class="tab-col">

<!--                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="SHOWROOM" v-model="form.showroom.id" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'" :disabled="(form.status != 'draft' ? true : false)"></ThatsMultiSelect>-->


                  </mdb-col>

                </mdb-row>
                <div ref="spreadsheet" class="bts-window-form mt-1" v-bind:style="{'height': jexcelContainer.height}"></div>
              </template>

            </mdb-tabs>

          </XWindow>

  </ValidationObserver>

  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import {
    ThatsWindow,
    ThatsInput,
    ThatsFieldset,
    ThatsTextarea,
    ThatsMoney,
    // ThatsMultiSelect,
    ThatsDatePicker,
    UploadFromDevice
} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';


import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import InventoryTransferReceivingForm from './InventoryTransferReceivingForm.vue';
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData','btsApproverProvider','btsReceiverProvider'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        // ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        InventoryTransferReceivingForm,
        UploadFromDevice
  },

  data(){

    const self = this;

    return {
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        filteredShowroomx :[ { "id": 4, "name": "Kareem Kandil", "token": "rm0wMvbmOeYAlO1n", "mdbID": 4, "label": "Kareem Kandil", "value": 4 }, { "id": 18, "name": "Ghamer Hussain Ameer", "token": "q1gK4oeEva0ByxjX", "mdbID": 18, "label": "Ghamer Hussain Ameer", "value": 18 }, { "id": 348, "name": "Beatriz Rosales", "token": "rm0wMvbmyAbYAlO1", "mdbID": 348, "label": "Beatriz Rosales", "value": 348 } ],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        jexcelContainer : {
            height : 'calc(100vh - 165px)'
        },
        spreadsheetApplyLoading: false,
        spreadsheet: null,
        tabs : [
                { text: 'Request Details', slot: 'form-slot' },
                // { text: 'Remarks', slot: 'remarks-slot' },
              ],

        gallery : [ "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg" ],
        index : null,

        inventoryTransferOptionsFrom : [],
        inventoryTransferOptionsTo : [],
        inventoryTransferItems : [],
        spreadsheetOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowInsertColumn : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : true,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 165px)',
            tableOverflow : true,
            data:[],
             minDimensions:[6,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],


            // updateTable:function(instance, cell, col, row, val, label, cellName) {
            //     if((row % 2) == 1){
            //         cell.parentNode.style.backgroundColor = '#FAFAFA';
            //     }
            //
            //     if (col == 1) {
            //
            //       if(val != null && val != ''){
            //         cell.innerHTML = '<img src="' + val + '" style="width:80px;height:80px">';
            //       }else{
            //         cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:80px;height:80px">';
            //       }
            //     }
            //
            //
            //     if(col == 7){
            //
            //         let innerHtml = '';
            //
            //         if(['draft'].includes(self.form.status)){
            //           innerHtml += `<div class="p-1 text-right d-block"><el-button size="mini" round><a href="javascript:void(0)" onClick="window.attachFile(${row})" class="d-block p-1"><i class="el-icon-paperclip"></i></a></el-button></di>`
            //           innerHtml += `<div class="d-flex">`
            //         }
            //
            //         const parsedvalue = JSON.parse(JSON.stringify(val))
            //
            //         if (Array.isArray(parsedvalue)) {
            //           parsedvalue.forEach((element,index) => {
            //               innerHtml += `<div style="display:inline-block">`
            //                 if(['draft'].includes(self.form.status)){
            //                     innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileRemove(${row},${index})" class="attach-file-remove text-danger p-1" style="position:absolute"><i class="el-icon-error"></i></a>`
            //                 }
            //               innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileView(${row},${index})" style="display:inline-block"><img src="${element.src}" class='img-thumbnail' style="width:90px"/></a></div>`
            //              // innerHtml += `<a href="javascript:void(0)" onClick="window.attachFileView(${row},${index})"><img src="${element.src}" class='img-thumbnail' style="width:90px"/></a>`
            //
            //           });
            //         }
            //
            //
            //
            //         innerHtml += `</div>`
            //
            //         cell.innerHTML = innerHtml
            //     }
            //
            //
            // },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                  if(['draft'].includes(self.form.status)){

                      if (obj.options.allowInsertRow == true) {
                          items.push({
                              title:obj.options.text.insertANewRowBefore,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y), 1);
                              }
                          });

                          items.push({
                              title:obj.options.text.insertANewRowAfter,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y));
                              }
                          });
                      }

                      if (obj.options.allowDeleteRow == true) {
                          items.push({
                              title:obj.options.text.deleteSelectedRows,
                              onclick:function() {
                                  obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                              }
                          });
                      }
                  }

                        /* if (obj.options.allowDeleteRow == true) {
                        items.push({
                            title:obj.options.text.deleteSelectedRows,
                            onclick:function() {
                                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                            }
                        });

                    } */
                return items;
             },

             onblur: function(instance){
              self.setPasteLoadingFalse()
             },
             // onchange: function(domElement, domElmentCell, column, row, value,oldValue){
             //
             //    if(column == 0){
             //        // self.spreadsheet.setValueFromCoords(1,row,'https://media.thatsliving.com/public/uploads/image-jpeg/thumb-46a757cc2eaf5b00ff32dd6f93468ddf-cla-016-123-hr.jpg',true)
             //
             //
             //        console.log(`${value} ====> ${oldValue} ==== row ${row}`)
             //        if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
             //          self.fnGetItem(value,row)
             //        }
             //    }
             //
             //
             //
             // },
             onafterchanges : function(){
              self.setPasteLoadingFalse()
             },
             onpaste : function(){
               self.setPasteLoadingFalse()

             },
             onbeforechange : function(){
              self.setPasteLoadingTrue()
             }

       }
    }
  },


  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;

       this.resetForm();

        if(this.isMaximized){
          this.width = window.innerWidth;
          this.height = window.innerHeight;
          this.spreadsheetOptions.tableWidth = `${window.innerWidth - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
          this.jexcelContainer.height = `calc(100vh - 195px)`

        }else{
          this.width = 1300;
          this.height = 600;

          this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
          this.jexcelContainer.height = `calc(100vh - 233px)`
        }


  },

  mounted(){
    const self = this



    this.$eventBus.$on('attachFileRemove',function({row, index}){

      let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

      const parsedvalue = JSON.parse(JSON.stringify(cellData))


      MessageBox.confirm('Are you sure you want to remove attachment?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      const newData = [];
                      cellData.forEach((element,innerIndex) => {
                          if(innerIndex != index){
                            newData.push(element)
                          }
                      });

                      self.spreadsheet.setValue(`H${(row+1)}`,newData)

                     /*  const halfBeforeTheUnwantedElement = parsedvalue.slice(0, 2)

                  const halfAfterTheUnwantedElement = parsedvalue(3);

                  const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement); */


                      /* const previousSecondElementOfTheArray = parsedvalue.splice(index,1)
                       */
                      /*
                       */

                    })


       /*  if(self.formStatus == 'create'){


          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){

          if(rowData.attachments.length > 0){
           self.gallery = rowData.attachments.map((item) => item.src,[])
           self.index = index
          }
        } */
    })
    this.$eventBus.$on('attachFileView',function({row, index}){

      const rowData = self.form.items[row]

        if(self.formStatus == 'create'){


          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){

          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (Array.isArray(parsedvalue)) {
            if(parsedvalue.length > 0){
            self.gallery = parsedvalue.map((item) => item.src,[])
            self.index = index
            }
          }



        }
    })
    this.$eventBus.$on('attachFile',function({row}){

        //this.index = index

      //  alert("attach file")
      self.$refs.uploadRef.triggerBrowseFile(row);
    })
    this.$eventBus.$on('uploadDone',function({data,row}){


          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (!Array.isArray(parsedvalue)) {
            cellData = [];
          }

          cellData.push({src : data.path})
          self.spreadsheet.setValue(`H${(row+1)}`,cellData)
          // const cellData = self.spreadsheet.getValue(`H${row}`)
          // console.log("cellData")


          // const cellDatax = self.spreadsheet.getValue(`H${row}`)
          console.log("cellDatax")
          console.log(row)
          console.log(`H${(row+1)}`)
       /*  console.log("uploadDone")
        console.log(data)
        console.log(row) */
    })
  },


  methods: {
    // ...mapActions('BringToShowroomModule',['getItem']),
    ...mapMutations('SalesTargetModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate','setFormDataItems']),
    ...mapMutations('InventoryTransferReceiveModule',{
      setInventoryTransferReceiveFormData : 'setFormData'
    }),
    ...mapGetters('SalesTargetModule',['salesTargetUserData']),
    ...mapActions('SalesTargetModule',['storeRecord','updateRecord','getRecord','getUserTargetData']),
    ...mapActions('InventoryTransferModule',{
        storeInventoryTransfer : 'storeRecord',
        updateInventoryTransfer : 'updateRecord',
        cancelInventoryTransferRecord : 'cancelInventoryTransferRecord',
    }),


      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                tbo: false,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false
              }
      },


    async setPasteLoadingTrue(){
      await this.$nextTick();
      this.onPasteLoading = true
      this.$forceUpdate();
      console.log("paste loading true")
    },
    async setPasteLoadingFalse(){
      await this.$nextTick();
      this.onPasteLoading = false
      this.$forceUpdate();
      console.log("paste loading false")
    },

    onResize({width,height}){

        if(!Number.isNaN(width) && !Number.isNaN(height)){
          console.log("update")
          console.log(width)
          console.log(height)


          if(width > 1300){
            this.spreadsheetOptions.tableWidth = `${width - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
            this.jexcelContainer.height = `calc(100vh - 195px)`
            console.log(`calc(100vh - 165px)`)
          }else{
            this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
            this.jexcelContainer.height = `calc(100vh - 233px)`
            console.log(`calc(100vh - 203px)`)
          }

          this.spreadsheetOptions.style = this.spreadsheet.getStyle();
          const tableData = this.spreadsheet.getJson();

          this.spreadsheet.destroy();
          this.spreadsheetOptions.data = tableData


            if(['for-approval','approved','rejected'].includes(this.form.status)){
              this.spreadsheetOptions.minDimensions = [6,this.form.items]
            }else{
              this.spreadsheetOptions.minDimensions = [6,50]
            }

            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, this.spreadsheetOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)


        }



      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      // this.form.showroom.id = this.userData.location.id
      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(saveAs = 'save'){
       const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){
                 const tableData = this.spreadsheet.getJson();
              if(tableData.length > 0){
                  const checkRow = tableData.filter((item) => item.user_code !== '',[]);
                  // let noQuantity = 0;
                  // let noDescription = 0;
                  // checkRow.forEach(element => {
                  //     if(element.year == '' || element.year <= 0){
                  //       noQuantity++;
                  //     }
                  //
                  //     if(element.month_jan == ''){
                  //       noDescription++;
                  //     }
                  //
                  // });


                  // if(noDescription > 0){
                  //         MessageBox({
                  //             title : 'Error',
                  //             message: 'Item description is required.',
                  //             type: 'error'
                  //         })
                  //     return false;
                  // }

                  // if(noQuantity > 0){
                  //         MessageBox({
                  //             title : 'Error',
                  //             message: 'Items quantity must be greater than zero.',
                  //             type: 'error'
                  //         })
                  //     return false;
                  // }

                  // if(noLocationFromTo > 0){
                  //         MessageBox({
                  //             title : 'Error',
                  //             message: 'Item Location From/To is required.',
                  //             type: 'error'
                  //         })
                  //     return false;
                  // }

                  const getValidData = checkRow.filter((row)=> {
                      return (row.year != '' && row.user_code != '')
                  },[])

                  if(getValidData.length <= 0){
                    MessageBox({
                              title : 'Error',
                              message: 'No valid data',
                              type: 'error'
                          })
                      return false;
                  }


                  const formData = JSON.parse(JSON.stringify(this.form));

                  formData.items = getValidData.map((row)=>{

                    const locationFrom = self.showroomLocation.filter((location) => location.prefix == row.locationFrom,[])[0];
                    row.location_from_id = (locationFrom) ? locationFrom.value : null;



                    return row
                  });

                  // const showroom = self.showroomLocation.filter((location) => location.value == formData.showroom.id,[])[0];
                  // formData.attr.showroom = {
                  //   prefix : showroom.prefix
                  // }
                  formData.status = saveAs;


                  if(this.formStatus == 'create'){
                      console.log("swaraj")
                    this.storeRecord(formData).then((res) => {
                      self.$ThatsNotify.success({
                        message : "Record successfully created."
                      })
                      self.closeDialog()
                      self.$emit('success')
                    }).catch((error) => {
                        self.formLoading = false;
                        if (error.response) {
                            if (error.response.status === 403) {
                                self.$ThatsNotify.error({
                                    message : error.response.data.message
                                })
                            } else if (error.response.status === 500) {
                                self.$ThatsNotify.error({
                                    message : error.response.data.message
                                })
                            } else if (error.response.data.hasOwnProperty('errors')) {
                                self.$refs.observer.setErrors(error.response.data.error);

                                // Set error message for each target object
                                error.response.data.errors.forEach((error, index) => {
                                    self.form.sales_target_data[index].error = error;
                                });
                            }
                        } else if (error.request) {
                            error.response.data.message = error.request;
                        } else {
                            error.response.data.message = error.message;
                        }
                        return;
                    });
                  }

                  if(this.formStatus == 'update'){
                    console.log("swaraj")
                    this.storeRecord(formData).then((res) => {
                      self.$ThatsNotify.success({
                        message : "Record successfully created."
                      })
                      self.closeDialog()
                      self.$emit('success')
                    }).catch((error) => {
                      self.formLoading = false;
                      if (error.response) {
                        if (error.response.status === 403) {
                          self.$ThatsNotify.error({
                            message : error.response.data.message
                          })
                        } else if (error.response.status === 500) {
                          self.$ThatsNotify.error({
                            message : error.response.data.message
                          })
                        } else if (error.response.data.hasOwnProperty('errors')) {
                          self.$refs.observer.setErrors(error.response.data.error);

                          // Set error message for each target object
                          error.response.data.errors.forEach((error, index) => {
                            self.form.sales_target_data[index].error = error;
                          });
                        }
                      } else if (error.request) {
                        error.response.data.message = error.request;
                      } else {
                        error.response.data.message = error.message;
                      }
                      return;
                    });

                  }

                  console.log(getValidData)

              }
            }
    },
    openHistory(){

    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){
        spreadsheetOptions.data = this.form.items


        console.log("spreadsheetOptions")
        console.log(spreadsheetOptions)
        console.log(this.form)



        if(['for-approval','approved','rejected'].includes(this.form.status)){
          spreadsheetOptions.minDimensions = [6,this.form.items]
        }else{
          spreadsheetOptions.minDimensions = [6,50]
        }

        this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, spreadsheetOptions);
        Object.assign(this, this.spreadsheet);
        this.spreadsheet.setWidth(window.innerWidth)



    },




        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)

          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },


        renderTable(){
            const self = this;
            this.spreadsheetOptions.columns = this.tableColumns;

                this.createPopupTable(this.spreadsheetOptions);
        },


        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){
            this.spreadsheetOptions.style = {};
            this.spreadsheet.destroy();

            this.resetForm();
          return
        }

      },

    //   async fnGetItem(itemCode, index){
    //     const self = this;
    //     await this.getItem({item : itemCode, index : index}).then((res)=> {
    //         self.spreadsheet.setValueFromCoords(0,index,res.location_code,true)
    //         self.spreadsheet.setValueFromCoords(1,index,res.photo,true)
    //         self.spreadsheet.setValueFromCoords(2,index,res.name,true)
    //         self.spreadsheet.setValueFromCoords(3,index,res.dimension,true)
    //         self.spreadsheet.setValueFromCoords(4,index,1,true)
    //         const parseIntIndex = parseInt(index)
    //
    //        /*  self.spreadsheet.resetStyle(`A${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`B${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`C${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`D${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`E${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`F${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`G${parseIntIndex+1}`)
    //         self.spreadsheet.resetStyle(`H${parseIntIndex+1}`) */
    //         const backgroundColor = 'transparent'
    //         self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)
    //
    //     }).catch(() => {
    //       const parseIntIndex = parseInt(index)
    //       let backgroundColor = 'rgb(255 200 200)';
    //       if(( parseIntIndex % 2) == 1){
    //         backgroundColor = 'rgb(233 167 167)';
    //       }
    //         self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
    //         self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)
    //
    //     })
    // },



    // makeInventoryTransfer(){
    //     // alert(1)
    //     const self = this;
    //
    //
    //     self.$refs.observer.validate().then((isValid) => {
    //           if(isValid){
    //             const loading = Loading.service({
    //                     fullscreen: true ,
    //                     text: 'Loading',
    //                     spinner: 'el-icon-loading',
    //                     background: 'rgba(255, 255, 255, 0.7)'
    //                   });
    //
    //
    //             this.storeInventoryTransfer({...this.form.showroom_target}).then((res) => {
    //                   self.getRecord({series : self.form.series}).then((res) =>{
    //                       loading.close();
    //
    //                       self.$ThatsNotify.success({
    //                         message : "Record successfully updated."
    //                       })
    //                       self.closeDialog()
    //                       self.$emit('success')
    //
    //                   })
    //             })
    //           }
    //       })
    //
    // },

    handleSelectionChange(v){
      this.form.showroom_target.items = v
    },



    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },

    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      // fnPrintDoc(url){
      //   // this.printUrl = url
      //     const windowHeight =  window.innerHeight;
      //     const innerWidth =  window.innerWidth;
      //
      //    var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
      //   const win = window.open(`${url}`, "_blank", strWindowFeatures);
      //   win.document.title = 'Print';
      //   win.print()
      //
      //   // this.$refs.printWindow.openDialog();
      // },

      // cancelInventoryTransfer(series){
      //     const self = this;
      //       // const deliveryNoteRow = [{...row}];
      //       MessageBox.confirm('Are you sure you want to cancel delivery note?', 'Warning', {
      //       confirmButtonText: 'OK',
      //       cancelButtonText: 'Cancel',
      //       type: 'warning'
      //       }).then(() => {
      //         self.cancelDeliveryNoteRecord(series).then((response) => {
      //             self.setFormRecord(response.data)
      //         })
      //       })
      //
      //   },

        // cancelRecord(row){
        //     const self = this;
        //     MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
        //       confirmButtonText: 'OK',
        //       cancelButtonText: 'Cancel',
        //       type: 'warning'
        //       }).then(() => {
        //         self.cancelInventoryTransferRecord(row.series).then((res) => {
        //             self.closeDialog()
        //             self.$ThatsNotify.success({
        //                 message : "Record successfully updated."
        //             })
        //         }).catch((error) => {
        //
        //             if(error.response.status == 422){
        //                 self.$ThatsNotify.warning({
        //                     message : error.response.data.message
        //                 })
        //             }
        //                 console.log("error.response.data.message")
        //                 console.log(error.response.data.message)
        //         })
        //
        //       })
        //   },



        // receiveInventoryTransfer(row, action){
        //     const inventoryTransferData = JSON.parse(JSON.stringify(row))
        //
        //     const inventoryTransferId = inventoryTransferData.id
        //
        //
        //     const filteredItems = this.form.inventory_transfer_items.filter((item)=>{
        //       return item.it_id == inventoryTransferId;
        //     },[])
        //
        //     console.log("filteredItems");
        //     console.log(filteredItems);
        //     console.log(this.form.inventory_transfer_items);
        //     console.log(this.form.items);
        //
        //     inventoryTransferData.items = filteredItems.map((item) => {
        //       const requestedItem =   this.form.items.filter((innerItem) => {
        //             return innerItem.id == item.itr_item_id
        //         },[])[0]
        //
        //         // const received_quantity = requestedItem.received_quantity == null ? requestedItem.quantity : item.received_quantity
        //
        //         return {...item, photo : requestedItem.photo}
        //     },[])
        //
        //     const mergeFormData = {...this.initialReceiveingFormObject,...inventoryTransferData, action : action}
        //
        //     console.log(mergeFormData)
        //    this.setInventoryTransferReceiveFormData(JSON.parse(JSON.stringify(mergeFormData)))
        //
        //    this.$nextTick(()=>{
        //
        //     this.$refs.inventoryTransferReceivingForm.openDialog()
        //    })
        // },

        receivedSuccess(){
          this.$emit('success')
          this.closeDialog()
        },

        selectTableLocation(option){

          return true
        },

        onInputLocationFrom(v){
          console.log("On input from")
          console.log(v)
        },
        onInputLocationTo(v){
          console.log("On input To")
          console.log(v)
        },

        onSelectLocation(v,origin){
          const self = this;


           this.eventKeyCounter++
      }


  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    // ...mapGetters('InventoryTransferReceiveModule',{initialReceiveingFormObject : 'initialFormObject'}),
    ...mapGetters('SalesTargetModule',['getLoading','formData','formStatus','initialFormObject','putLoading','postLoading','FormRecordItems','FormTarget']),
      tblKey : function(){
        return 1;
      },

      showroomLocation : function(){

        const showroom = this.showroomsProvider();

        return showroom.map((item) => {
            return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
          },[])
      },

      rowShowroomLocation : function(){

      const showroom = this.showroomsProvider();

      return showroom.map((item) => {
          // return {...item, name :item.prefix,id : item.value}
          return item.prefix
        },[])
      },

      userDatax : function(){
        return this.userDataProvider()
      },
      // documentApprover : function(){
      //
      //   const btsApproverProvider = this.btsApproverProvider()
      //   return btsApproverProvider.map((item) => {
      //       return {...item, label : `${item.name}`,value : item.id}
      //     },[])
      // },
      // documentReceiver : function(){
      //
      //   // const intTransDocumentReceiver = this.btsReceiverProvider()
      //
      //   let dReceiver = [];
      //
      //
      //
      //   return dReceiver;
      // },

      tableColumns : function(){


        return [
            {
                type : 'text',
                width : '100px',
                align : 'left',
                name : 'user_code',
                title : 'USER CODE'
            },
                  {
                    type : 'text',
                    width : '100px',
                    align : 'left',
                    name : 'year',
                    title : 'YEAR'
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'month_jan',
                    title : 'JANUARY',
                  },

                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'month_feb',
                    title : 'FEBRUARY'
                  },


                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'month_mar',
                    title : 'MARCH'
                  },

                  {
                      type : 'text',
                      width : '150px',
                      align : 'left',
                      name : 'month_apr',
                    title : 'APRIL',
                    // mask:'#,##.00', decimal:'.'
                  },


                  {
                    type: 'text',
                    width: '150px',
                    title:'MAY',
                    name:'month_may',

                    },




                    {
                      type : 'text',
                      width : '150px',
                      align : 'left',
                      name : 'month_jun',
                      title : 'JUNE'
                    },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_jul',
                title : 'JULY'
            },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_aug',
                title : 'AUGUST'
            },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_sep',
                title : 'SEPTEMBER'
            },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_oct',
                title : 'OCTOBER'
            },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_nov',
                title : 'NOVEMBER'
            },
            {
                type : 'text',
                width : '150px',
                align : 'left',
                name : 'month_dec',
                title : 'DECEMBER'
            },
            ]},

      isAllowedToApproved : function(){
        return this.form.approver.map((row) => row.id,[]).includes(this.userData.user.id) || [2,18].includes(this.userData.user.id)
      },

      showMakeInventoryTransfer : function(){
          return this.form.status == 'approved'
      },

      showInventoryTransfer : function(){
          return this.form.status == 'approved'
      },





  },

  watch: {

      'formData' : function(v){

        const self = this


        // console.log(v.items,v.status,'swaraj')

        if(this.formStatus == 'update'){
          const employee_code = this.FormTarget;
          const self = this;
          this.getUserTargetData({ employee_code:employee_code}).then(res => {
            this.data = res
            const dataArray = this.data.map(obj => Object.values(obj).map(value => String(value)));
            // const dataArray = Object.values(this.data);
           this.form.items = dataArray


            dataArray.forEach((row, index) => {
              self.spreadsheet.setValueFromCoords(0, index, row[0], true);
              self.spreadsheet.setValueFromCoords(1, index, row[1], true);
              self.spreadsheet.setValueFromCoords(2, index, row[2], true);
              self.spreadsheet.setValueFromCoords(3, index, row[3], true);
              self.spreadsheet.setValueFromCoords(4, index, row[4], true);
              self.spreadsheet.setValueFromCoords(5, index, row[5], true);
              self.spreadsheet.setValueFromCoords(6, index, row[6], true);
              self.spreadsheet.setValueFromCoords(7, index, row[7], true);
              self.spreadsheet.setValueFromCoords(8, index, row[8], true);
              self.spreadsheet.setValueFromCoords(9, index, row[9], true);
              self.spreadsheet.setValueFromCoords(10, index, row[10], true);
              self.spreadsheet.setValueFromCoords(11, index, row[11], true);
              self.spreadsheet.setValueFromCoords(12, index, row[12], true);
              self.spreadsheet.setValueFromCoords(13, index, row[13], true);
              // ... Add more setValueFromCoords calls for each value in the row

              const parseIntIndex = parseInt(index);
              const backgroundColor = 'transparent';
              self.spreadsheet.setStyle(`A${parseIntIndex + 1}`, 'background-color', backgroundColor);
              self.spreadsheet.setStyle(`B${parseIntIndex + 1}`, 'background-color', backgroundColor);
              self.spreadsheet.setStyle(`C${parseIntIndex + 1}`, 'background-color', backgroundColor);
              self.spreadsheet.setStyle(`D${parseIntIndex + 1}`, 'background-color', backgroundColor);
              self.spreadsheet.setStyle(`E${parseIntIndex + 1}`, 'background-color', backgroundColor);
              // ... Add more setStyle calls for each cell in the row
            })
            // this.form.items = this.data
            // self.spreadsheet.setValueFromCoords(0,this.form.items[0].user_code,true)
            // console.log(JSON.stringify(this.data),'swaraj');
          });

          if(['draft'].includes(v.status)){
            this.data = JSON.parse(JSON.stringify(v.items));
            // console.log(JSON.parse(JSON.stringify(v.items)), 'Swarajbhuyan')
          }



          this.form = JSON.parse(JSON.stringify({...v, sales_target : {
                items : [
                    {
                      user_code:"",
                        year:"",
                        month_jan:"",
                        month_feb:"",
                        month_mar:"",
                        month_apr:"",
                        month_may:"",
                        month_jun:"",
                        month_jul:"",
                        month_aug:"",
                        month_sep:"",
                        month_oct:"",
                        month_nov:"",
                        month_dec:"",
                    }
                ]
            }}));

            if(v.items.length > 0){
              const itemLocationFrom = v.items.map((item) => item.locationFrom,[])
              const itemLocationTo = v.items.map((item) => item.locationTo,[])

              const uniqueArrayFrom = [];

              for (let i = 0; i < itemLocationFrom.length; i++) {
                if (uniqueArrayFrom.indexOf(itemLocationFrom[i]) === -1) {
                  uniqueArrayFrom.push(itemLocationFrom[i]);
                }
              }

              const uniqueArrayTo = [];

              for (let i = 0; i < itemLocationTo.length; i++) {
                if (uniqueArrayTo.indexOf(itemLocationTo[i]) === -1) {
                  uniqueArrayTo.push(itemLocationTo[i]);
                }
              }



              // this.inventoryTransferOptionsTo = self.showroomLocation.filter((location) => uniqueArrayTo.includes(location.prefix),[]);
              // this.inventoryTransferOptionsFrom = self.showroomLocation.filter((location) => uniqueArrayFrom.includes(location.prefix),[]);

            }

          this.$nextTick(()=>{

            if(this.showMakeInventoryTransfer){
              const checkIfHasInventoryTransfer = this.tabs.filter((item) => item.slot == 'inventory-transfer-slot',[]);

              if(checkIfHasInventoryTransfer.length <= 0){
                this.tabs.push({ text: 'Inventory Transfer', slot: 'inventory-transfer-slot' })
              }
              this.eventKeyCounter++;
            }
            if(this.showInventoryTransfer && this.form.inventory_transfer.length > 0){
              const checkIfHasInventoryTransfer = this.tabs.filter((item) => item.slot == 'inventory-transfer-slot',[]);

              if(checkIfHasInventoryTransfer.length <= 0){
                this.tabs.push({ text: 'Inventory Transfer', slot: 'inventory-transfer-slot' })
              }
              this.eventKeyCounter++;
            }

            if(self.spreadsheet != null){
              self.spreadsheet.destroy();
            }
            self.spreadsheetOptions.columns = this.tableColumns;

            console.log("self.spreadsheetOptions.columns")
            console.log(self.spreadsheetOptions.columns)

            self.createPopupTable(this.spreadsheetOptions);
            self.spreadsheet.setData(this.form.items)
          })

          this.active = 0;


        }
        else{

          if(self.spreadsheet != null){
            self.spreadsheet.destroy();
          }

          this.tabs = [
                { text: 'Request Details', slot: 'form-slot' },
                // { text: 'Remarks', slot: 'remarks-slot' }
              ]
          this.resetForm();
          this.currentDateSetter();
          this.renderTable();
          this.active = 0;
        }

      },



      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },

      // documentApprover : function(value){
      //     this.form.approver = JSON.parse(JSON.stringify(value))
      // },

      showroomLocation : function(value){

          const allowedLocation = this.userData.location_prefix
        this.filteredShowroom = value.filter((i) =>{
            return allowedLocation.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })
      }





  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>
