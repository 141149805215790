import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
export const helpers = {
    getModules: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        return user.modules
    },
    isGeneralManager: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        console.log(user);
        const authorized = ["general-manager"];
        if (user.user.role_slugs.length > 0 && ((appSettings.po_recipient.user_id === user.user_id) || authorized.some(r => user.user.role_slugs.includes(r)))) {
            return true
        }
        return false;
    },

    isDeliveryTeam: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["delivery-team"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isBackOffice: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["back-office"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isAccounting: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["accounting"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isSystemAdministrator: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["administrator"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isShowroomManager: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["showroom-manager"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isSalesExecutive: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["sales"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    isInteriorDesigner: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const authorized = ["interior-designers"];
        if (user.user.role_slugs.length > 0 && authorized.some(r => user.user.role_slugs.includes(r))) {
            return true
        }
        return false;
    },
    itemCreate: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const getUserPermission = user.user.permissions.filter((permission) => {
            return permission.name === 'items';
        })[0]; 
        console.log(getUserPermission);
        console.log(getUserPermission);       
        return getUserPermission?.permission?.create;
    },
    invoiceUpdate: () => {
        let user = JSON.parse(localStorage.getItem('user'));
        const getUserPermission = user.user.permissions.filter((permission) => {
            return permission.name === 'quotation-sales';
        })[0];        
        console.log(getUserPermission);
        console.log(getUserPermission);
        return getUserPermission?.permission?.update;
    },

    addSeparatorsNF(nStr, inD = '.', outD = '.', sep = ',') {

        /* 
        nStr : This is the number to be formatted. This might be a number or a string. No validation is done on this input.
      inD : This is the decimal character for the string. This is usually a dot but might be something else.
      outD : This is what to change the decimal character into.
      sep : This is the separator, which is usually a comma.
       */
        nStr += '';
        var dpos = nStr.indexOf(inD);
        var nStrEnd = '';
        if (dpos != -1) {
            nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
            nStr = nStr.substring(0, dpos);
        }
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(nStr)) {
            nStr = nStr.replace(rgx, '$1' + sep + '$2');
        }
        return nStr + nStrEnd;
    },
    formatMoney : (amount, params = { symbol: "", precision: 2, thousand: ",", decimal: "." }) =>{
        return formatMoney(amount, params);
    },
    unformat : (amount) =>{
        return unformat(amount);
    },

    copyStringToClipboard : (str) => {
        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.setAttribute("id", "dummy_id");
        document.getElementById("dummy_id").value=str;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
    }
}