
<template>
    <div class="page-container">
        <ThatsTable title="Item/Product Barcode Management" ref="table" :endpoint="endpoint" @openCountryOfOrigin="openCountryOfOrigin" @openUpdateModal="OpenUpdateModal" :columns="columns" :height="'calc(100vh - 123px)'" :enableFilter="true" :newBtn="enableNew" :enableDelete="false" @print="fnPrint" @print_small="fnPrintSmall" @print_qr="fnPrintQr" @selectionChange="handleSelectionChange">
            <template #top-right>
               


                <mdb-dropdown multiLevel class="ml-1 barcode-dropdown-toggle" end>
                    <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">Print</mdb-dropdown-toggle>
                    <mdb-dropdown-menu >
                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">QR Code Website</label>
                        
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(true,false)">Print 58x39</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(true,false)">Print 58x39 Discount</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(true,false)">Print 38x25</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(true,false)">Print 38x25 Discount</a>
                   
                    <div class="dropdown-divider"></div>

                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">QR Code Item Code</label>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(false,false)">Print 58x39</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(false,false)">Print 58x39 Discount</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(false,false)">Print 38x25</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(false,false)">Print 38x25 Discount</a>
                    

                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">Logo</label>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(false,true)">Print 58x39</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(false,true)">Print 58x39 Discount</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(false,true)">Print 38x25</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(false,true)">Print 38x25 Discount</a>
                    <div class="px-2 py-2">
                        <label class="mt-1 mb-1 text-muted small">Inventory</label>
                    </div>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelQr(0)">Inventory 58x39</a>
                    <a class="dropdown-item" href="javascript:void(0)" @click="printLabelQr(1)">Inventory 38x25</a>
                    </mdb-dropdown-menu>
                </mdb-dropdown>


             

<!-- 
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabel"></mdb-btn>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabelDiscount">Print 58x39 Discount</mdb-btn>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabelSmall">Print 38x25</mdb-btn>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabelSmallDiscount">Print 38x25 Discount</mdb-btn>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabelQr">Print Qr Code</mdb-btn> -->

             
            </template>

            <template #filter="{dispatchFilter}">
                <BarcodeFilter @filter="dispatchFilter"></BarcodeFilter>
            </template>
            

        </ThatsTable>
        <BulkUpdateCountryOfOrigin ref="bulkUpdateCountryOfOrigin" @success="refreshTable" @delete="formDelete" :group="3"></BulkUpdateCountryOfOrigin>
        <ItemForm ref="itemDialogForm" @success="refreshTable" @delete="formDelete" :group="3"></ItemForm>
        <BarcodeForm ref="transactionSalesOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></BarcodeForm>
       

        
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import BarcodeForm from './BarcodeForm'
import ItemForm from '../item/ItemForm'
import BarcodeFilter from './BarcodeFilter'
import BulkUpdateCountryOfOrigin from './BulkUpdateCountryOfOrigin'
import ThatsTable from './Table'
import {mdbTabs, mdbBtn,mdbIcon,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem} from 'mdbvue'

export default {
    data(){
        return {
            endpoint : 'barcode',    
            activeTab : 'barcode',    
            enableFilter : false,       
            importLoading : false,        
            active : 0,        
            token : null,
            enableNew : false,        
            enableImport : false,
            selection : [],
            toggle : false,
            warehouses : [],
            area : [],
            brands : [],
            category : [],
            collections : [],
            color : []
                   
        }
    },
    components:{
        BarcodeFilter,
        ItemForm,
        BarcodeForm,
        ThatsTable,
        mdbTabs,
        mdbBtn,
        mdbIcon,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,
        BulkUpdateCountryOfOrigin

    },
    created(){
        // this.columns = this.salesColumns;
        this.endpoint = 'barcode';
    },  
    
    provide(){
        return {
            areaProvider: () => this.area,
            categoryProvider: () => this.category,
            collectionProvider: () => this.collections,
            colorProvider: () => this.color,
            brandProvider: () => this.brands,
            warehousesProvider: () => this.warehouses,
        }
    },

    mounted(){
        const self = this;
        self.getWarehouses().then(response => self.warehouses = response)
        self.GetArea().then(response => self.area = response);
        self.GetBrandErp().then(response => {
            const brand = [];
            response.forEach(element => {
              brand.push({brand : element.label,code : element.value})
            });
            self.brands = brand
          });
        self.GetCategory().then(response => {
            const category = [];
            response.forEach(element => {
              category.push({category : element.label,code : element.value})
            });
            self.category = category
          });

          self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
            const collection = [];
                response.forEach(element => {
                    collection.push({collection : element.name,code : element.mdbID})
                });
                self.collections = collection
            })

            self.getColour({'show_in' : 'product'}).then(response => {

                    const c = [];

                    if(response.length){
                        response.forEach(e => {
                        c.push({
                            colour : e.label,
                            code : e.slug,
                            attributes : e.attributes,
                        })
                        })
                        self.color = c
                    }
                    })
            

    },
    methods: {
        // 

        ...mapActions('SapWarehouseModule',[ 'getWarehouses' ]),
        ...mapActions('AreaModule',[ 'GetArea' ]),
        ...mapActions('ColourModule',[ 'getColour' ]),  
        ...mapActions('BrandModule',[ 'GetBrandErp']),
        ...mapActions('CategoryModule',[ 'GetCategory']),
        ...mapActions('CollectionModule',[ 'GetCollections']),
        ...mapActions('ItemModule',[
        'GetBrandRelationship',
      ]),

        ...mapActions('TableV1Module',[
            'resetData',
        ]),
        ...mapMutations('TableV1Module',[
            'SetTotal',
            'SetPage',
            'SetTerm',
            'SetFilters',
            
        ]),

        ...mapActions('ItemModule',{
            itemGetRecord  : 'getRecord'
        }),
        ...mapMutations('ItemModule',{
            itemSetFormRecord : 'SetFormRecord', itemSetFormStatusUpdate: 'SetFormStatusUpdate'
        }),

        ...mapActions('BarcodeModule',[ 'deleteRecord','saveImport','getRecord']),
        ...mapMutations('BarcodeModule',[ 'setFormRecord', 'setFormStatusUpdate','setFormStatusCreate','setTblKey']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){

        },
        OpenUpdateModal(obj){
            const self = this
             const form  = {...obj.row,images : []}           
            self.itemSetFormStatusUpdate()
            self.itemSetFormRecord(form)
            self.itemGetRecord({token_id : obj.row.token_id})
            self.$refs.itemDialogForm.openDialog()

        },
        openUploadWindow(){           
        },
        openWindow(){
            
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v           
           this.$refs.tableImport.openDialog()
        },

        

        setter(){
            let resetDataEndpoint = 'barcode';
          
            
            this.resetData(resetDataEndpoint);
        },

        getActiveTabIndex(index){


            const tabs = ['sales','sales-quotation','sales-order','online-orders'];
            this.activeTab = tabs[index];
            this.SetPage(1)
            this.SetTotal(0);
            this.SetFilters({});
            this.setter()
            // commit('SetTotal', 0)
            /* SetFilters: (state, payload) => {
            state.filters = payload
        }, */
        },

        fnPrint(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintSmall(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print-small/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintQr(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print-qr/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabel(obj = false, logo = false){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print?items=${this.selection}&redirection=${obj}&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelDiscount(obj = false, logo = false){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-discount?items=${this.selection}&redirection=${obj}&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelSmallDiscount(obj = false, logo = false){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-small-discount?items=${this.selection}&redirection=${obj}&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelSmall(obj = false, logo = false){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-small?items=${this.selection}&redirection=${obj}&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelQr(size){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        /* this.selection */
        const self = this;
        const selectedItems = this.tblData.rows.filter((item) => {
            return self.selection.includes(item.token_id)
        },[])

        const printSticker = selectedItems.map((item) => item.series,[])
        console.log(selectedItems);

        const win = window.open(`/barcode/multiple-print-qr?items=${printSticker}&size=${size}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },

      handleSelectionChange(v){
        let selectionArray = [];
          if(v.length > 0){
               selectionArray = v.map((item) =>{
                    return item.token_id
                })
              }
            this.selection = selectionArray
      },


      toggleDropdown(){
            this.toggle = !this.toggle;
        },

        openCountryOfOrigin(){
            this.$refs.bulkUpdateCountryOfOrigin.openDialog();
        }
    },
    computed: {
        ...mapGetters('TableV1Module',[ 'tableLoading']),
        ...mapGetters('TableV1Module',{
            tblData : 'data'
        }),
        ...mapGetters('BarcodeModule',[ 'columns','data', 'page', 'total', 'page', 'length', 'getLoading', ]),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>