import { ApiHttpService } from '../../services'
export const CodeGeneratorModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
            {
                label: 'DocName',
                field: 'name',
                sort: 'asc',
                clickable: true
            },
            {
                label: 'Origin',
                field: 'origin_code',
                sort: 'asc',
                width: '100'
            },
            {
                label: 'Vendor',
                field: 'vendor_code',
                width: '100',
                sort: 'asc'
            },
            {
                label: 'CreatedAt',
                field: 'created_at',
                sort: 'asc',
                width: '250'
            }

            ],
            rows: [],
        },
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {
            name : null,
            origin_code : null,
            vendor_code : null,
            items :[],
        },


        initialFormData : {
            name : null,
            origin_code : null,
            vendor_code : null,
            items :[],
          },

          getLoading : false,
          getRecordLoading : false,
          putLoading : false,

          formStatus : 'create'

        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },
        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setNewItemDataObject : (state, payload)=>{
            state.newItemDataObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){

        
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "CodeGeneratorModule/req",
                        loadingTrue : "CodeGeneratorModule/loadingTrue",
                        addRequest : "CodeGeneratorModule/addRequest",
                        cancelRequest : "CodeGeneratorModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorModule/clearOldRequest"
                    }

                 
                    

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {

                        commit('setRows', response.data.data)
                        commit('setLoading', false)

                        console.log("async data")
                        console.log(response.data.data)
                        
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)

                        console.log("error data")
                        console.log(error)
                        reject(error)
                    })
                }
            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)
                    ApiHttpService.getRequest('new-item/'+obj.id, obj).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },       
        
        async generateData({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "CodeGeneratorModule/req",
                        loadingTrue : "CodeGeneratorModule/loadingTrue",
                        addRequest : "CodeGeneratorModule/addRequest",
                        cancelRequest : "CodeGeneratorModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorModule/clearOldRequest"
                    }
                    ApiHttpService.post('new-item/generate',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    console.log("parsePayload")
                    console.log(parsePayload)
                    const configParams = {
                        req : "CodeGeneratorModule/req",
                        loadingTrue : "CodeGeneratorModule/loadingTrue",
                        addRequest : "CodeGeneratorModule/addRequest",
                        cancelRequest : "CodeGeneratorModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorModule/clearOldRequest"
                    }
                    ApiHttpService.post('new-item/store',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiHttpService.update(`new-item/${updatePayload.id}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialForm: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        getNewItemDataObject: state => state.newItemDataObject,
    }
}



