import { render, staticRenderFns } from "./Attribute.vue?vue&type=template&id=0c0ad2a7&scoped=true&"
import script from "./Attribute.vue?vue&type=script&lang=js&"
export * from "./Attribute.vue?vue&type=script&lang=js&"
import style0 from "./Attribute.vue?vue&type=style&index=0&id=0c0ad2a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c0ad2a7",
  null
  
)

export default component.exports