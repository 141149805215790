<template>
    <div class="print-container">
        print
        <router-view />
    </div>
</template>
<script>

export default {
    name: 'Print',
    components : {
       
    },
    data : ()=>({
       
    })
}
</script>