import { furnishwebHttpService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ProductModule = {
    namespaced: true,
    state: {
        request: null,
        multipleSelection: [],
        length: 24,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},
        initialFormData : {
            item_code : 0,
            parent : 0,
            name : null,
            slug : null,
            sale_price : 0,
            promo_price : 0,
            stock : 0,
            dimension_type : null,
            dimension : null,
            prType : "product",
            ItmGroup : null,
            brand : null,
            area : null,
            category : 373,
            collection : null,
            item_type : 'stock',
            description : "",
            website : '0',
            sell : false,
            inventory : false,
            purchase : false,
            allocated_quantity : 0,
            attr : {
                cube_unit : "",
                attribute : "",
                post_views : 0,
                materials : "",
                materials1 : "",
                fabric : "",
                finishes : "",
                sub_colour : "",
                colour : "",
                colour1 : "",
                colour2 : "",

                future_stock : {
                    qty : 0,
                    eta_text : "",
                    eta : []
                },

                dimension_height : "",
                dimension_width : "",
                dimension_depth : "",
                dimension_diameter : "",
                hole : "",
                capacity : "",
                weight : "",
                meta_title : "",
                meta_keyword : "",
                meta_description : "",
                visible_polish : false,
                barcode : "",
                old_code : "",
                discount : {
                    percent : 0,
                    value : 0,
                    remarks : ""
                },
                collection : {
                    en : "",
                    ar : ""
                }
            },
            image: [],
            location: [],
            warehouses: [],
            bill_of_materials: [],
            qty : 0,
            status : '1',
            soldQtyFromCheckpoint : 0,
            returnQtyFromCheckpoint : 0,
            returnQty : 0,
            invoiceQty : 0,
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,
        deleteLoading : false,

        formStatus : 'create',
    },
    actions: {

        resetData({dispatch}){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', 'item-master/inventory').then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                    Unauthorized(dispatch, error)
                })
            });
        },
        async asyncGetData({ state, commit},endpoint) {
            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {

                commit('setLoading', true)
                commit('setFormStatusUpdate')
                const params = { length: length, page: page, term: searchTerm, filters: filters, view : 'furnishweb' }

                const configParams = {
                    req : "ProductModule/req",
                    loadingTrue : "ProductModule/loadingTrue",
                    addRequest : "ProductModule/addRequest",
                    cancelRequest : "ProductModule/cancelRequest",
                    clearOldRequest : "ProductModule/clearOldRequest"
                }

                furnishwebHttpService.get(endpoint, params, configParams).then((response) => {
                    resolve(response.data)
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setLoading', false)
                    reject(error)
                })

            })
        },

        async getProduct({},item) {
            return await new Promise((resolve, reject) => {
                const params = {  item: item, view : 'furnishweb' }
                const configParams = {
                    req : "ProductModule/req",
                    loadingTrue : "ProductModule/loadingTrue",
                    addRequest : "ProductModule/addRequest",
                    cancelRequest : "ProductModule/cancelRequest",
                    clearOldRequest : "ProductModule/clearOldRequest"
                }
                furnishwebHttpService.get('item-master', params, configParams).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
       
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setDeleteLoadingTrue(state) {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse(state) {
            state.deleteLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
        

    },
    getters: {
        req: state => state.request,
        Loading: state => state.loading,
        record: state => state.record,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
       
    }
}


