
<template>
    <div class="customervendor-container">
        <ThatsTable title="Role" ref="table" :multipleSelection="false" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns"> </ThatsTable>
        <RoleForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></RoleForm>
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import RoleForm from './RoleForm'
import ThatsTable from '../../components/thats/Table'
export default {
    data(){
        return {
            endpoint : 'role/data',    
            importData : {},        
            errorData : {},        
            importLoading : false,        
            active : 0,        
            token : null,        
        }
    },
    components: {
        RoleForm,
        ThatsTable,

    },

    
    methods: {
        // 

        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapMutations('TableModule',[
            'SetPage',
            'SetTerm',
            'SetFilters',
        ]),

        ...mapActions('RoleModule',[ 'DeleteRecord','saveImport' ,'updateStatus','gitItem']),
        ...mapMutations('RoleModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

             

            const form  = {...obj.row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}           
            self.SetFormStatusUpdate()
            self.SetFormRecord(form)
            self.gitItem({token_id : obj.row.token_id})
            self.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        

    },
    computed: {
        ...mapGetters('RoleModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>