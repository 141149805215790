<template>
  <div>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog v-loading="formLoading" :modal="true" :fullscreen="false" :title="'VOUCHER'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" size="small" class="text-left item-dialog">
          <div>
              <div class="p-1">
                  <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between m-1" style="background-color: #fff;">
                      <mdb-navbar-brand class="table-header-navbar-brand">
                          <mdb-tabs class="table-header-tabs" :key="formKey" :active="activeTabIndex" default :links="mdbTabsData" @activeTab="getActiveTab"/>
                      </mdb-navbar-brand>
                  </mdb-navbar>
              </div>

              <div class="dialog-scroll-container">
                  <template v-if="activeTabSlot == 'main'">
                      <mdb-row class="tab-row">
                          <mdb-col lg="12" md="12" sm="12" class="mt-2 pl-0 pr-0">
                              <thats-fieldset class="m-2">
                                  <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Name" placeholder="Name" vid="name" name="name" v-model="formData.name" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />

                                <div ref="textareaWrapper">
                                  <ThatsTextarea :showLabel="true" :rows="5" tabindex="15" :labelClass="'col-md-2'" :fieldClass="'col-md-10 pl-lg-0 pl-md-0 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="formData.description" size="sm" :readOnly="readOnly"/>
                                </div>
                                <ThatsMoney tabindex="4"  labelClass="col-sm-2" fieldClass="col-sm-2 pl-0" label="Min. Amount" placeholder="0" vid="amount" name="amount" size="sm" v-model="formData.amount" :rules="'required'" :validated="validated" :readOnly="readOnly"/>
                                  <ThatsRadio tabindex="16" :labelClass="'col-md-2'" :fieldClass="'col-md-8  pl-0'" label="Status" vid="status" orientation="inline" name="status" :data="status" v-model="formData.status" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/>
                              </thats-fieldset>

                              <template v-if="['create'].includes(formStatus) && !readOnly">
                              <thats-fieldset class="m-2">
                                    <div class="form-group input-group-sm row thats-form">
                                        <label class="col-form-label col-md-2">GENERATE SEQUENCE</label>
                                    </div>
                                        <p class="text-small text-muted">The sequence number will be three characters long and will include leading zeros (e.g., 001, 002).</p>
                                  <!-- <ThatsRadio tabindex="16" :labelClass="'col-md-2'" :fieldClass="'col-md-8  pl-0'" label="TYPE" vid="generate_type" orientation="inline" name="generate_type" :data="generateType" v-model="formData.type" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/> -->
                                  <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Start From" placeholder="From" vid="from" name="from" v-model="formData.from" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
                                  <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Quantity" placeholder="Quantity" vid="quantity" name="quantity" v-model="formData.quantity" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
                              </thats-fieldset>
                              </template>

                          </mdb-col>

                      </mdb-row>

                  </template>

                  <template v-if="activeTabSlot == 'vouchers'">




                   <mdb-row class="tab-row">
                          <mdb-col lg="12" md="12" sm="12" class="mt-2">

                          <mdb-navbar class="z-depth-0 p-0 pl-1 rounded-sm justify-content-between">
                            <mdb-form-inline class="ml-auto" tag="div">

                            <el-input
                                @keydown.enter.prevent="handleSearchTerm"
                                class="table-searchterm-input"
                                placeholder="Search"
                                v-model="searchFilter.search"
                                @input="handleSearchTerm"
                                clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                            </el-input>
                            <mdb-btn color="elegant" size="sm" @click="handleSearchTerm"><i class="el-icon-search"></i> Search</mdb-btn>
                            <mdb-btn color="elegant" size="sm" @click="syncModalGenerate = true"><i class="el-icon-plus"></i> Generate</mdb-btn>

                        </mdb-form-inline>


                        </mdb-navbar>

                          <el-table border :data="formData.items" stripe class="input-table mt-0 custom-element-table">
                            <el-table-column label="Code">
                                <template slot-scope="scope">
                                {{ scope.row.code }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Issued To" width="200">
                                <template slot-scope="scope">
                                {{ scope.row.customer_name }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Issued Date" width="120">
                                <template slot-scope="scope">
                                {{ scope.row.issued_date }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Redeemed Date" width="150">
                                <template slot-scope="scope">
                                {{ scope.row.redeemed_date }}
                                </template>
                            </el-table-column>
<!--                            <el-table-column label="Amount" width="100">-->
<!--                                <template slot-scope="scope">-->
<!--                                {{ scope.row.amount | thousands }}-->
<!--                                </template>-->
<!--                            </el-table-column>-->
                            <el-table-column label="Status" width="200">
                                <template slot-scope="scope">
                                {{ scope.row.status }}
                                    <template v-if="scope.row.status === 'issued'">
                                        <mdb-btn color="elegant" size="sm" @click="openRedeemedModal(scope.row)">Redeemed</mdb-btn>
                                    </template>
                                </template>
                            </el-table-column>

                        </el-table>
                          </mdb-col>
                    </mdb-row>


                  </template>
              </div>

          </div>


          <el-dialog :modal="true" :fullscreen="false" :title="'GENERATE'" :visible.sync="syncModalGenerate" :close-on-click-modal="false" width="400" :modal-append-to-body="true" :append-to-body="true" size="small" class="text-left">
            <thats-fieldset class="m-0">
                <div class="form-group input-group-sm row thats-form">
                    <label class="col-form-label col-md-6">GENERATE SEQUENCE</label>
                </div>
                    <p class="text-small text-muted">The sequence number will be three characters long and will include leading zeros (e.g., 001, 002).</p>
                <!-- <ThatsRadio tabindex="16" :labelClass="'col-md-2'" :fieldClass="'col-md-8  pl-0'" label="TYPE" vid="generate_type" orientation="inline" name="generate_type" :data="generateType" v-model="formData.type" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/> -->
                <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Start From" placeholder="From" vid="from" name="from" v-model="formData.from" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
                <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Quantity" placeholder="Quantity" vid="quantity" name="quantity" v-model="formData.quantity" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
            </thats-fieldset>

            <template slot="footer">
              <div class="pl-3 pr-3">
                  <div class="d-flex">

                    <template v-if="generateLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitGenerateForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="syncModalGenerate = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                      </template>


                  </div>
              </div>
          </template>

          </el-dialog>
          <template slot="footer">
              <div class="pl-3 pr-3">
                  <div class="d-flex">

                    <template v-if="formLoading || viewLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                      </template>


                  </div>
              </div>
          </template>
      </el-dialog>
        <RedeemVoucher ref="redeemVoucher" ></RedeemVoucher>
      </ValidationObserver>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver } from "vee-validate";
import { mdbBtn,mdbBadge,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsMultiSelect,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'


import { MessageBox, Message } from 'element-ui';
import RedeemVoucher from "./RedeemVoucher";

export default {
  components : {
      RedeemVoucher,
      ThatsMultiSelect,
      mdbBadge,
      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardImage,
      ValidationObserver,

       mdbFormInline,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },
      filterFormDataQuotation : {
          type: Object,
          default : () => ({
              searchTermQuot : null
          })
      },
  },
  inject : [],
  data(){
      return {
            customerName : `{{customerName}}`,
            customerMobile : `{{customerMobile}}`,
            showroom : `{{showroom}}`,
            salesPerson : `{{salesPerson}}`,
          syncModal : false,
          syncModalGenerate : false,
          activeTabIndex : 0,
          activeTabObject : { text: 'Details', tabSlot : 'main' },
          mdbTabsData : [
              { text: 'Details', tabSlot : 'main' }
          ],
          group2 : 5,
          formData : null,
          formDataOrginal : null,
          formLoading : false,
          smsType : [
              { label : "New Customers, First Purchase", code : "sms-ncfp"},
              { label : "Returning Customer, New Invoice", code : "sms-invoice"},
              { label : "Welcome Message, New Visitors", code : "sms-welcome"},
              { label : "Reminder Message, New Visitors", code : "sms-reminder"},
              { label : "Welcome Message, New TL Rewards Member", code : "sms-rewards-welcome"},
          ],
          smsMask : [
              { label : "THATSLIVING", code : "THATSLIVING"},
              { label : "TL REWARDS", code : "TL REWARDS"},
          ],
          readOnly : true,
          showError : true,
          validated : true,
            formKey : 0,
            searchFilter : {
                search : ''
            },
            generateLoading : false

      }
  },

  created(){
      const self = this
        this.formData = JSON.parse(JSON.stringify(this.formObject))
      this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))

  },
  methods : {

      ...mapMutations('VoucherModule',['cancelRequest']),
      ...mapActions('VoucherModule',['storeRecord','updateRecord','generate']),

      openDialog(){
          this.syncModal = true
      },
      closeDialog(){
          this.syncModal = false
      },
      resetDefaultTab(){
          this.activeTabIndex = 0,
          this.activeTabObject = { text: 'Details', tabSlot : 'main' }
      },
      getActiveTab(v){
          this.activeTabIndex = v
          this.activeTabObject = this.mdbTabsData[v];
      },
      insertAtCursor(text) {
          this.$nextTick(() => {
              const textarea = this.$refs.textareaWrapper.querySelector('textarea');

              if (textarea) {
                  const startPos = textarea.selectionStart;
                  const endPos = textarea.selectionEnd;
                  const textBefore = this.formData.content.substring(0, startPos);
                  const textAfter = this.formData.content.substring(endPos, this.formData.content.length);
                  this.formData.content = textBefore + text + textAfter;
                  const newPos = startPos + text.length;
                  textarea.focus();
                  textarea.setSelectionRange(newPos, newPos);
              }
          });
      },
      openRedeemedModal(row) {
          console.log('Redeemed button clicked for row:', row);
          this.$refs.redeemVoucher.openNewDialog(row);
      },
      onSubmitTest(){
            const self = this;
            MessageBox.confirm('Are you sure you want to send test message?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                Message.success({
                message: "SMS Test message was sent successfully!"
                });
                self.sendTestSMS({
                    mobile : self.formData.attr.test_destination,
                    type :self.formData.type,
                    action : 'test'
                }).then(() => {
                              self.$ThatsNotify.success(
                                {
                                    message : "Test message was sent"
                                }
                              )
                            })
            }).catch(() => {
                self.$ThatsNotify.error({
                    message : "Test message was not sent"
                })
            });

      },

    onSubmitForm(){
      var self =this
            this.validated = true
           this.$refs.observer.validate().then((isValid) => {

            // console.log(isValid)
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){


                          self.storeRecord(self.formData).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.formLoading = false
                              /*
                              self.FormResetter(); */

                            }).catch((error) => {
                              self.formLoading = false
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.updateRecord(self.formData).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                /* self.FormResetter(); */
                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })


                      }
                    }

            }


            });


    },

    handleSearchTerm(){
        // this.setSearchTerm(this.searchFilter.search)

        const items = this.formDataOrginal.items.filter((item) => {
            return item.code.includes(this.searchFilter.search)
        },[]);

        this.formData.items = items
    },

    onSubmitGenerateForm(){
        const self =this

        if(this.formData.quantity > 0){
            this.generateLoading = true
            this.generate(this.formData).then((response) => {
                self.$ThatsNotify.success();
                self.generateLoading = false
                self.syncModalGenerate = false
            }).catch((error) => {
                self.generateLoading = false
                self.syncModalGenerate = false
                // console.log(error)
                self.$ThatsNotify.success({
                    message : "Record was successfully generated"
                })

                /* if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                        self.$refs.observer.setErrors(error.response.data.errors);
                        this.showError = true
                    }
                } else if (error.request) {
                    self.response.message = error.request
                } else {
                    self.response.message = error.message
                } */


                return;
            })
        }
    }


  },

  computed : {
      ...mapGetters('VoucherModule',{
          formRecord : 'formData',
          viewLoading : 'getLoading',
          postLoading : 'postLoading',
          formObject : 'initialFormObject',
          formStatus : 'formStatus',
      }),


      activeTabSlot : function(){
          return this.activeTabObject.tabSlot
      },

      status : function(){
          return [
              { label : 'Active', value : "active" },
              { label : 'Inactive', value : "inactive" }
          ]
      },
      generateType : function(){
          return [
              { label : 'Auto', value : "auto" },
              { label : 'Manual', value : "manual" }
          ]
      },


  },

  watch : {
      syncModal : function(value){
        const self = this;
        this.formKey++
        if(!value){
            this.cancelRequest();
            this.resetDefaultTab();
            this.formData = JSON.parse(JSON.stringify(this.formObject))
            this.mdbTabsData = [
                { text: 'Details', tabSlot: 'main' }
            ];
        }

        if(this.formStatus == 'update'){

            if (!this.mdbTabsData.some(tab => tab.tabSlot === 'vouchers')) {
                this.mdbTabsData.push({ text: 'Vouchers', tabSlot: 'vouchers' });
            }

        }
            this.readOnly = (this.$helpers.isSystemAdministrator() === true || this.$helpers.isBackOffice() || this.$helpers.itemCreate()) ? false : true;
      },

      formRecord : function(value){
          console.log("formRecord")
          console.log(value)

          const itemData = value;

          this.formData = {...itemData}
          this.formDataOrginal = JSON.parse(JSON.stringify({...itemData}))
      },

  }
}
</script>

<style scoped>
.click-to-add-logo{
font-size: 14px;cursor: pointer;
}



.custom-control {
  position: absolute!important;
  margin: 10px!important;
}

.custom-control.image-delete{
right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
padding: 158px 65px;
text-align: center;
}

.no-image-gallery i.far {
font-size: 42px;
color: #959595;
}

</style>
<style scoped>
.tab-col {
    position: relative;
}

.custom-pagination {
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;
}
</style>


