

<template>
    <el-dialog :visible.sync="dialogVisible" ref="window" title="History" windowClass="collection height-auto " scrollClass='collection height-auto' width="40%" class="history-dialog">
             <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="400" stripe @selection-change="handleSelectionChange">
              <template v-if="hasCustomColumnsSlot">
                  <slot name="custom-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
              </template>
              <template v-else>
                 
                     <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                          <template slot-scope="scope">
                              <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                  <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                              </template>
                              <template v-else>
                                  <!-- {{getValue(scope,col.field)}} -->
                                  <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                              
                              </template>
                          </template>
                          
                      </el-table-column>
              </template>
              
              
              <template slot="empty">
                  <template v-if="loading">
                      <mdb-btn color="elegant" size="sm" rounded disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading data...
                      </mdb-btn>
                    
                  </template>
                  <template v-else>
                      No Data
                  </template>
              </template>
          </el-table>
  
          <el-pagination        
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="current_page"
              :page-sizes="[100, 200, 300, 400]"
              :page-size="length"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
              <!-- <div class="d-flex">
                    <mdb-btn color="grey" size="sm" @click="closeDialog">CLOSE</mdb-btn>
              </div> -->
         </el-dialog>
  </template>
  <script>
  import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbInput} from "mdbvue";
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  
  import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn} from '../../components/thats/Thats'
  import unformat from 'accounting-js/lib/unformat.js'
  import formatMoney from 'accounting-js/lib/formatMoney.js'
  import ThatsCustomWindow from './CustomWindow'
  export default {
      props : {
          group : {
            type : Number,
            default : 5
          }
      },
      components: {
          mdbContainer,
          mdbBtn,
          mdbRow,
          mdbCol,  
          mdbIcon,
          mdbCard,
          mdbTabs,
  
          ValidationProvider,
          ValidationObserver,
          ThatsWindow, 
          ThatsInput,  
          ThatsSeriesInput,
          ThatsPriceInput,  
          ThatsFieldset,
          ThatsRadio,
          ThatsAutocomplete,
          ThatsAutosuggest,
          ThatsNumber,
          ThatsTextarea,
          ThatsDatePicker,
          ThatsMultiSelect,
          ThatsGridSelect,
          ThatsFile,
          ThatsBtn,
          mdbInput,
          ThatsCustomWindow
  
    },
  
      data : () => ({
        active : 0,
        disabled : false,
        validated : false,
        formOriginal : null,
        form : null,
        response : {
          error : null,
          message : null
        },
        
        sendLoading : false,
        formLoading : false,
        showError : false,
        index : null,
        dialogVisible : false
      }),
  
      created : function() {
        var self = this;
        // this.form = this.formVar();
        // this.optionsHandler();
        //  this.isGeneralManager();
      },
  
  
  
      methods: {
         ...mapActions('HistoryModule',
        [
          'asyncGetData',
        ]),
         ...mapMutations('HistoryModule',
        [
          'setPage',
        ]),
        formVar(){
          return {
                  item_id: null,
                  code: null,
                  name : null,
                  description: null,
                  reference: null,
                  qty: null,
                  formatted_price: 0,
                  price: 0,
                  amount: 0,
                  discount: 0,
                  discount_type: null,
                  custom_discount: {
                    apply : true,
                    discount : 0,
                    off_price : 0,
                    promo : 0,
                  },
                  total: 0,
                  remarks: null
                }
        },
  /* start */
  checkKeyCode(evt) {
        var keyCode = evt.which ? evt.which : evt.keyCode;
              
              if([8, 9, 13, 27, 46, 110, 190, 173, 189, 37,39, 46].includes(keyCode) || (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)){
                  return true
                }
              evt.preventDefault();
              
  },
  computeDiscount(){
    const self = this;
    const {custom_discount, price} = this.form;
    const {discount, apply} = custom_discount;
  
    // console.log(discount);
  
    const unFormatPrice = unformat(price);
    let unFormatDiscount = unformat(discount);
  
    // console.log(unFormatDiscount)
  
    if(unFormatDiscount !== null){
  
      
      
      if(parseInt(unFormatDiscount) > 0){
        self.form.discount_type = 'discount'
      }else{
        self.form.discount_type = null
      }
      
      if(unFormatDiscount === ""){
        unFormatDiscount = 0
      }
      
  
      if(apply === true || apply === 1){
        const off = (unFormatDiscount / 100) * unFormatPrice;
        const promo = unFormatPrice - off;
  
        this.form.custom_discount.promo = self.formatNumber(promo)
        this.form.custom_discount.off_price = self.formatNumber(off)
      }else{
  
        if(unFormatDiscount === ""){
            unFormatDiscount = 0;
          }
          const promo = unFormatPrice - unFormatDiscount;
          this.form.custom_discount.promo = self.formatNumber(promo)
          this.form.custom_discount.off_price = self.formatNumber(unFormatDiscount)
      }
    
    }
  
  
  },
  
  computeOff(i){
    const self = this;
    const {custom_discount, price} = this.form;
    const {off_price, apply} = custom_discount;
  
    if((!apply || apply === 0) && off_price !== null && off_price !== ""){
      this.form.custom_discount.promo = self.formatNumber(price - off_price)
    }
  },
  
  formatNumber(n) {
          return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
          });
        },
  
  optionsHandler(){
      this.asyncGetData();
  },
  
  /* end */
        openDialog(){
            this.dialogVisible = true;
            this.optionsHandler();
        },
        closeDialog(){
            this.dialogVisible = false
        },
        getText() {
          let text = this.$refs.descriptionEditor.$children;
          text = text.$el.innerHTML;
          return text;
        },
        FormResetter(){
          this.form = this.formVar()
          requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
          this.validated = false
          this.formLoading = false
          this.sendLoading = false
          this.showError = false
  
  
        },
        
  
        dialogState(state){
          if(!state){
            this.FormResetter();
            return
          }
          if(state){
            this.optionsHandler();
            return
          }
  
        },
  
        formatNumber(n) {
            return formatMoney(n, { symbol: "", precision: 2, thousand: ",", decimal: "." });
        },
  
        handleSizeChange(val) {
              // this.SetLength(val)
              // this.dispatchHandler()
          },
          handleCurrentChange(val) {
              // this.dispatchHandler()
          },
          handleSearchTerm() {
              // this.dispatchHandler()
          },
          handleSelectionChange(val){
              // this.SetSelection(val)
          },
  
      },
  
     computed: {
          ...mapGetters('HistoryModule',[
              'data',
              'page',
              'total',
              'length',
              'searchTerm',
              'loading',
              'selection',
              'columns',
          ]),
          hasCustomColumnsSlot() {
              return ('custom-culumns' in this.$scopedSlots);
          },
          current_page : {
              get : function(){
                 return this.page
              },
              set : function(val){
                  this.setPage (val)
              }
          },
      },
  
  
  
  }
  </script>
  
  <style scoped>
  .click-to-add-logo{
    font-size: 14px;cursor: pointer;
  }
  
  
  
  .custom-control {
      position: absolute!important;
      margin: 10px!important;
  }
  
  .custom-control.image-delete{
    right: 0;cursor: pointer;
  }
  
  .custom-checkbox .custom-control-label::before {
      border-radius: .50rem!important;cursor: pointer;
  }
  
  .no-image-gallery{
    padding: 65px;
    text-align: center;
  }
  
  .no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
  }
  
  </style>