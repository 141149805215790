<template>
    <ValidationObserver class="needs-validation form-custom-format filter-form" tag="form" ref="observer" @submit.prevent="onSubmitForm()" v-slot="{passes}">
            <mdbContainer>
              <label class="filter-label">FILTER</label>
                <mdb-row >
                  <mdb-col col="12">
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Tracking No." placeholder="Tracking No." vid="series" name="series" v-model="form.series" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="CardCode" placeholder="CardCode" vid="card_code" name="card_code" v-model="form.card_code" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile No." placeholder="Mobile No." vid="customer_mobile" name="customer_mobile" v-model="form.customer_mobile" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customer_name" name="customer_name" v-model="form.customer_name" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Code" placeholder="Item Code" vid="item_code" name="item_code" v-model="form.item_code" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sold By" placeholder="Sold By" vid="sold_to" name="sold_to" v-model="form.sold_to" size="sm" />
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Order Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="''"></ThatsMultiSelect>
                      <template v-if="tabIndex===0">
                        <mdb-row>
                            <mdb-col sm="3">
                            </mdb-col>
                            <mdb-col sm="9" class="text-left">
                              <el-checkbox v-model="form.balance">With balance</el-checkbox>
                            </mdb-col>
                        </mdb-row>
                      </template>
                      
                      <div class="form-group input-group-sm row thats-form">
                            <label class="col-form-label col-sm-3">Date Range</label>
                          <div class="col-sm-9 pl-0">
                              <date-range-picker v-model="form.dateRange" :append-to-body="false" class="filter-date-range w-100"></date-range-picker>
                          </div>
                      </div>
                  </mdb-col> 
                </mdb-row>

                <mdb-row class="mt-1 mb-1">
                    <mdb-col col="3">
                    </mdb-col>
                    <mdb-col col="9">
                     <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Search</mdb-btn>
                <mdb-btn color="elegant" size="sm" @click="clearForm">Clear Filter</mdb-btn>
                    </mdb-col>
                </mdb-row>


                
              </mdbContainer>


          </ValidationObserver>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import DateRangePicker from 'vue2-daterange-picker'
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { ThatsNumber,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsCheckbox, ThatsMultiSelect} from '../../components/thats/Thats'
import {mapActions} from 'vuex';
import VueSlider from 'vue-slider-component'
export default {
    components: {
        DateRangePicker,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        ValidationObserver,
        ThatsInput,  ThatsNumber,
        ThatsSeriesInput,
        ThatsFieldset,
        ThatsCheckbox,
        ThatsMultiSelect,
        VueSlider,
        DatePicker,
  },
  props : {
    tabIndex : {
      type : Number,
      default : 0
    },
    filterWhere : {
      type : String,
      default : 'invoice'
    }
  },
  filters: {
      date(val) {
        return val ? val.toLocaleString() : ''
      }
    },
    data(){
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + 6)

      return {
        status : [],
        form : {
          dateRange: {},
          series : "",
          sold_to : "",
          customer_mobile : "",
          customer_name : "",
          item_code : "",
          status : "",
          balance : false,
      },
      }
    },

    mounted(){
    
      self.form = this.formVar();

        const {settings} = JSON.parse(localStorage.getItem('user'));


        if(this.filterWhere === 'invoice'){
          this.status = settings.sales_transaction_order_status;
        }
        if(this.filterWhere === 'quotation'){
          this.status = [
              {
                "code": "no-remarks",
                "label": "Open",
                "seperator": false,
                 "disable" : true
              },
              {
                "code": "closed",
                "label": "Closed",
                "seperator": false,
                "disabled" : true
              },
              {
                "code": "cancel",
                "label": "Cancel",
                "seperator": false
              }
            ];
        }


    },
    methods: {      

        onSubmitForm(){
            this.$emit('filter',this.form)
        },

        formVar(){
          return {
                dateRange: {},
                series : "",
                card_code : "",
                sold_to : "",
                customer_mobile : "",
                customer_name : "",
                item_code : ""
            }
        },

        clearForm(){
          this.form = this.formVar()
        },

        dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date()
        }
        return classes
      }

        /* dateRange(v){
          console.log(v)
        } */



    },

}
</script>
