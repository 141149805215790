<template>
    <div>
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <el-dialog :modal="true" :fullscreen="false" :title="'Item'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" size="small" class="text-left item-dialog">
                <div>
                    <div class="p-1">
                        <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between m-1" style="background-color: #fff;">
                            <mdb-navbar-brand class="table-header-navbar-brand">
                                <mdb-tabs class="table-header-tabs" :active="activeTabIndex" default :links="mdbTabsData" @activeTab="getActiveTab"/>
                            </mdb-navbar-brand>
                        </mdb-navbar>
                    </div>

                    <div class="dialog-scroll-container">
                        <template v-if="activeTabSlot == 'main'">
                            <mdb-row class="tab-row">
                                <mdb-col lg="8" md="8" sm="12" class="mt-2 pl-0 pr-0">
                                    <thats-fieldset class="m-2">

                                        <mdb-row>
                                            <mdb-col col="12" class="pr-0 text-right" style="flex-direction: row;display: flex;justify-content: flex-end;">
                                                <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0 m-1'" :label="'SALES ITEM'" vid="item-sales" orientation="inline" name="item-sales" :data="[]" v-model="formData.sell" :validated="validated" :rules="''" size="sm" :multiple="false" :disabled="readOnly"/>
                                                <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0 m-1'" :label="'INVENTORY ITEM'" vid="item-inventory" orientation="inline" name="item-inventory" :data="[]" v-model="formData.inventory" :validated="validated" :rules="''" size="sm" :multiple="false" :disabled="readOnly"/>
                                                <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0 m-1'" :label="'PURCHASE ITEM'" vid="item-purchase" orientation="inline" name="item-purchase" :data="[]" v-model="formData.purchase" :validated="validated" :rules="''" size="sm" :multiple="false" :disabled="readOnly"/>
                                            </mdb-col>

                                        </mdb-row>
                                        <mdb-row>
                                            <mdb-col lg="6" md="6" sm="12">

                                            </mdb-col>
                                            <mdb-col lg="6" md="6" sm="12">
                                                <ThatsAutocomplete :clearable="false" tabindex="7" :addButton="false" :appendButton="false" labelClass="col-lg-5 col-md-5 col-sm-12" fieldClass="col-lg-7 col-md-7 col-sm-12 pl-lg-0 pl-md-0" label="Product Type" v-model="formData.prType" :reduce="label => label.value" :reduceLabel="'label'" :options="filterProductType" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                            </mdb-col>
                                        </mdb-row>


                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsSeriesInput :show_error="true" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-lg-0 pl-md-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="formData.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="(formStatus == 'update' ? true : false)" />
                                            </mdb-col>
                                            <mdb-col col="6">
                                                <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-lg-0 pl-md-0" label="Supplier Code" placeholder="Supplier Code" vid="barcode" name="barcode" v-model="formData.attr.barcode" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsSeriesInput :show_error="true" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-lg-0 pl-md-0" label="Old Code" placeholder="Old Code" vid="old_code" name="old_code" v-model="formData.attr.old_code" :validated="validated" :rules="''" :keycode="false" size="sm" :readOnly="readOnly" />
                                            </mdb-col>
                                        </mdb-row>
                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-lg-0 pl-md-0" label="Item Name" placeholder="Item Name" vid="namex" name="namex" v-model="formData.name" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>


                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsAutocomplete :clearable="false" tabindex="6" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-7 pl-lg-0 pl-md-0" label="Measurement" v-model="formData.dimension_type"
                                                                   :reduce="dimension_type => dimension_type.code"   :options="dimensionType" :rules="'required'" :readOnly="readOnly"></ThatsAutocomplete>
                                            </mdb-col>
                                            <mdb-col col="6" style="margin-top: -14px !important;">
                                                <ThatsInput tabindex="3" labelClass="col-sm-12" fieldClass="col-sm-12 pl-lg-0 pl-md-3"  placeholder="" vid="dimension" name="dimension" v-model="formData.dimension" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly" />
                                            </mdb-col>
                                        </mdb-row>

                                        <!--                                  <ThatsInput tabindex="3" labelClass="col-sm-2" fieldClass="col-sm-10 pl-lg-0 pl-md-0" label="Dimension" placeholder="WxHxD (cm)" vid="dimension" name="dimension" v-model="formData.dimension" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>-->
                                        <mdb-row>
                                            <mdb-col lg="2" md="2" sm="12">

                                            </mdb-col>
                                            <mdb-col lg="10" md="10" sm="12">
                                                <mdb-row>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-lg-0 pl-md-0" label="Height" placeholder="0" vid="height" name="height" v-model="formData.attr.dimension_height" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-lg-0 pl-md-0" label="Width" placeholder="0" vid="width" name="width" v-model="formData.attr.dimension_width" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-lg-0 pl-md-0" label="Depth" placeholder="0" vid="depth" name="depth" v-model="formData.attr.dimension_depth" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-lg-0 pl-md-0" label="Diameter" placeholder="0" vid="diameter" name="diameter" v-model="formData.attr.dimension_diameter" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                </mdb-row>


                                                <mdb-row>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Hole" placeholder="0" vid="hole" name="hole" v-model="formData.attr.hole" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Capacity" placeholder="0" vid="capacity" name="capacity" v-model="formData.attr.capacity" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Weight" placeholder="0" vid="weight" name="weight" v-model="formData.attr.weight" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput tabindex="11" ref="cube_unit" labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Cubic Feet" v-model="formData.attr.cube_unit" :rules="''" size="sm"  :horizontal="true" :readOnly="readOnly"></ThatsInput>
                                                    </mdb-col>

                                                </mdb-row>


                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsMoney tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="formData.sale_price" :rules="'required'" :validated="validated" :readOnly="readOnly"/>
                                            </mdb-col>


                                        </mdb-row>


                                        <mdb-row>
                                            <mdb-col col="4">
                                                <ThatsMoney tabindex="4"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Discount" placeholder="0" vid="discount_percent" name="discount_percent" size="sm" v-model="formData.attr.discount.percent" :rules="''" :validated="validated" :readOnly="readOnly" :money="itemDiscountPercentConfig"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                                <ThatsMoney :show-label="false" tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-12 pl-0" label="" placeholder="0" vid="discount_value" name="discount_value" size="sm" v-model="formData.attr.discount.value" :rules="''" :validated="validated" :readOnly="readOnly"/>
                                            </mdb-col>
                                        </mdb-row>

                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Discount Remarks" placeholder="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="formData.attr.discount.remarks" :validated="validated" :rules="''" size="sm" :readOnly="readOnly"/>




                                    </thats-fieldset>




                                    <thats-fieldset class="m-2">
                                        <mdb-row>
                                            <mdb-col col="6">

                                                <ThatsAutocomplete :clearable="false" tabindex="6" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Group" v-model="formData.ItmGroup" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="itemGroups" :rules="'required'" :readOnly="readOnly"></ThatsAutocomplete>
                                                <ThatsAutocomplete :clearable="false" tabindex="6" @select="onBrandSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Brand" v-model="formData.brand" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="itemBrands" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                                <ThatsAutocomplete :clearable="false" tabindex="10" @newWindow="openCollectionForm" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Collections" v-model="formData.collection" :reduce="collection => collection.code" :reduceLabel="'collection'" :options="filterCollections" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>


                                            </mdb-col>

                                            <mdb-col col="6">
                                                <ThatsAutocomplete :clearable="false" tabindex="7"  @select="onAreaSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Area" v-model="formData.area" :reduce="area => area.code" :reduceLabel="'area'" :options="filterArea" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                                <ThatsAutocomplete :clearable="false" tabindex="8" @select="onCategorySelect" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Category" v-model="formData.category" :reduce="category => category.code" :reduceLabel="'category'" :options="filterCategory" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                                <ThatsAutocomplete :clearable="false" tabindex="9" :addButton="false" :appendButton="false" :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Attributes" v-model="formData.attr.attribute" :reduce="attribute => attribute.code" :reduceLabel="'attribute'" :options="filterAttributes" :rules="''" :disabled="readOnly"></ThatsAutocomplete>
                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col col="6">
                                                <mdb-row class="form-group input-group-sm thats-form">
                                                    <mdb-col col="4">
                                                        <label class="col-form-label">Materials</label>
                                                    </mdb-col>
                                                    <mdb-col col="8">
                                                        <ThatsVueSelect tabindex="5" @newWindow="openMaterialForm" @select="onSelectMaterial" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="formData.attr.materials" :reduce="material => material.code" :reduceLabel="'material'" :options="materials" :rules="''" :showLabel="false" :clearable="false" :disabled="readOnly"></ThatsVueSelect>
                                                        <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="materials1" name="materials1" v-model="formData.attr.materials1" :validated="validated" :rules="''" size="sm" :showLabel="false" :readOnly="readOnly"/>
                                                    </mdb-col>
                                                </mdb-row>
                                                <ThatsInput tabindex="13" ref="autoFinishes" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Finishes" v-model="formData.attr.finishes" :rules="''" :readOnly="readOnly"></ThatsInput>
                                                <ThatsInput tabindex="12" ref="autoFabric" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Fabric" v-model="formData.attr.fabric" :rules="''" :readOnly="readOnly"></ThatsInput>
                                                <mdb-row  class="form-group input-group-sm thats-form">
                                                    <mdb-col col="4">
                                                        <label class="col-form-label">Colour</label>
                                                    </mdb-col>
                                                    <mdb-col col="8">
                                                        <ThatsVueSelect tabindex="5" @newWindow="openColourForm" @select="onSelectColour" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="formData.attr.colour" :reduce="colour => colour.code" :reduceLabel="'colour'" :options="colour" :rules="''" :showLabel="false" :clearable="false" :disabled="readOnly"></ThatsVueSelect>
                                                        <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour1" name="colour1" v-model="formData.attr.colour1" :validated="validated" :rules="''" size="sm" :showLabel="false" :readOnly="readOnly"/>

                                                    </mdb-col>

                                                </mdb-row>
                                                <ThatsRadio tabindex="16" :labelClass="'col-md-4'" :fieldClass="'col-md-8  pl-0'" label="Item Type" vid="item-type" orientation="inline" name="item-type" :data="itemType" v-model="formData.item_type" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/>
                                                <ThatsRadio tabindex="16" :labelClass="'col-md-4'" :fieldClass="'col-md-8  pl-0'" label="Web live Status" vid="item-website-status" orientation="inline" name="item-website-status" :data="itemWebsiteStatus" v-model="formData.website" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/>


                                            </mdb-col>
                                            <mdb-col col="6">
                                                <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="formData.description" size="sm" :readOnly="readOnly"/>
                                                <ThatsRadio tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" label="Status" vid="item-status" orientation="inline" name="item-status" :data="itemStatus" v-model="formData.status" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/>
                                            </mdb-col>
                                        </mdb-row>

                                    </thats-fieldset>


                                </mdb-col>


                                <mdb-col lg="4" md="4" sm="12" class="mt-2 pl-0 pr-0">
                                    <thats-fieldset class="m-2 ml-0">

                                        <template v-if="formData.image.length > 0">
                                            <mdb-carousel
                                                :items="formData.image.length"
                                                :key="`carousel-${formData.image.length}`"
                                                controlls
                                                :controllIcons="['fas fa-arrow-circle-left fa-2x text-primary', 'fas fa-arrow-circle-right fa-2x text-primary']"
                                            >
                                                <template #[i+1] v-for="(img, i) in formData.image">
                                                    <mdb-card :key="i" style="height: 359px;">

                                                        <template v-if="!readOnly">
                                                            <div class="custom-control image-checkbox custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" @change="onSelect($event,img)" :checked="img.default" :id="'imageCheckbox'+img.mdbID" :value="img.medium">
                                                                <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID" title="Set as default">Set as default</label>
                                                            </div>
                                                            <div class="custom-control image-delete" @click="removeImage(i,img.default)">
                                                                <mdb-icon icon="trash"></mdb-icon>
                                                            </div>
                                                        </template>
                                                        <mdb-card-image :src="img.medium" :alt="img.filename" ></mdb-card-image>

                                                    </mdb-card>
                                                </template>




                                            </mdb-carousel>


                                        </template>
                                        <template v-else>
                                            <div class="no-image-gallery">
                                                <mdb-icon far icon="images" />
                                            </div>
                                        </template>
                                        <template v-if="!readOnly">
                                            <a href="javascript:void(0)" tabindex="17" @click="uploadImage"><mdb-icon icon="image"></mdb-icon> Select Image</a>
                                        </template>
                                    </thats-fieldset>

                                    <thats-fieldset class="m-2">
                                        <label class="small">COUNTRY OF ORIGIN</label>
                                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="English" placeholder="English" vid="collection_en" name="collection_en" v-model="formData.attr.collection.en" :validated="validated" :rules="''" size="sm" :readOnly="readOnly"/>
                                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Arabic" placeholder="Arabic" vid="collection_ar" name="collection_ar" v-model="formData.attr.collection.ar" :validated="validated" :rules="''" size="sm" :readOnly="readOnly" :inputclass="['text-right']"/>
                                    </thats-fieldset>


                                    <thats-fieldset class="m-2">
                                        <label class="small">STOCK DETAILS</label>


                                        <template v-if="formStatus ==='update'">
                                            <template v-if="warehousesLoading">
                                                <p class="sm text-muted"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...</p>
                                            </template>
                                            <template v-else>

                                                <ThatsNumber tabindex="18" :labelClass="'col-sm-5'" :fieldClass="'col-sm-7 pl-0'" label="In Stock" placeholder="Qty" vid="qty" name="qty" v-model="formData.stock" :validated="validated" :rules="'required'" size="sm" :disabled="true" />

                                                <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Sold" v-model="formData.soldQty" :rules="''" :disabled="true"></ThatsNumber>
                                                <!--                                             <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Committed" v-model="formData.Committed" :rules="''" :disabled="true"></ThatsNumber>
                                                                                          <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Available" v-model="formData.Available" :rules="''" :disabled="true"></ThatsNumber> -->



                                            </template>
                                        </template>





                                    </thats-fieldset>

                                </mdb-col>
                            </mdb-row>

                        </template>


                        <template v-if="activeTabSlot == 'inventory-slot'">
                            <mdb-row class="tab-row">
                                <mdb-col col="12" class="mt-2">
                                    <el-table border :data="formData.warehouses" stripe class="input-table mt-0 custom-element-table" :summary-method="getSummary" show-summary>
                                        <el-table-column label="Warehouse" width="250">
                                            <template slot-scope="scope">
                                                {{ scope.row.prefix }}

                                            </template>
                                        </el-table-column>
                                        <el-table-column label="In Stock" width="120">
                                            <template slot-scope="scope">
                                                {{ scope.row.stock }}

                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Sold" width="120">
                                            <template slot-scope="scope">
                                                {{ scope.row.committed }}

                                            </template>
                                        </el-table-column>

                                        <el-table-column label=""></el-table-column>
                                    </el-table>
                                </mdb-col>
                            </mdb-row>
                        </template>

                        <template v-if="activeTabSlot == 'bill-of-materials' && formStatus == 'update'">
                            <mdb-row class="tab-row">
                                <mdb-col col="12" class="mt-2">
                                        <mdb-row>
                                            <mdb-col lg="6" md="6" sm="12">
                                                <ThatsAutocomplete :clearable="true" tabindex="7" :addButton="false" :appendButton="false" labelClass="col-lg-4 col-md-5 col-sm-12" fieldClass="col-lg-8 col-md-7 col-sm-12 pl-lg-0 pl-md-0" label="BOM Type" v-model="formData.bomType" :reduce="label => label.value" :reduceLabel="'label'" :options="billOfMaterialsType" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                      
                                                <template v-if="formData.bomType == 'iSalesTree'">
                                                    <div class="alert alert-info">
                                                        <p class="m-0 text-small"><strong>Note : </strong> Confirm all child items are marked as <strong>Inventory items, <br />Purchase items, and Sales items,</strong> and the <strong>Product Type</strong> is set to <strong>Product</strong> or <strong>Set</strong>.</p>
                                                    </div>
                                                </template>
                                                <template v-if="formData.bomType == 'iAssemblyTree'">
                                                    <div class="alert alert-info">
                                                        <p class="m-0 text-small"><strong>Note : </strong> Confirm all child items are marked as <strong>Inventory items, <br />Purchase items, and Sales items,</strong> and the <strong>Product Type</strong> is set to <strong>Parts</strong>.</p>
                                                    </div>
                                                </template>
                                            </mdb-col>
                                        </mdb-row>

                                    <el-table border :data="formData.bill_of_materials" stripe class="input-table mt-0 custom-element-table">
                                        <el-table-column label="SQ" :width="50">
                                            <template slot-scope="scope">
                                                {{ scope.$index + 1 }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="SKU" :width="250">
                                            <template slot-scope="scope">
                                                <template v-if="!scope.row.sku">
                                                    <GridVueSelect
                                                        class="custom-grid-select"
                                                        v-model="scope.row.sku"
                                                        :reduce="item => item.code"
                                                        :reduceLabel="'code'"
                                                        :options="items"
                                                        :filterable="false"
                                                        :clearable="true"
                                                        @onSearch="statusKeyUp"
                                                        @select="getSelected($event, scope.row)"

                                                    ></GridVueSelect>
                                                </template>
                                                <template v-else>
                                                    {{ scope.row.sku }}
                                                </template>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Item Name" :row-class-name="''">
                                            <template slot-scope="scope">
                                                {{ scope.row.name }}
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Qty" :width="70" align="right">
                                            <template slot-scope="scope">
                                                <!--                                                {{ scope.row.qty }}-->
                                                <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty"  />
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="Action" :width="100" align="center">
                                            <template slot-scope="scope">
                                                <div class="image-delete" @click="handleDelete(scope.row.item_id)" v-if="!isEmptyRow(scope.row)">
                                                    <mdb-icon icon="trash"></mdb-icon>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column label=""></el-table-column> -->


                                    </el-table>
                                    <template v-if="['set'].includes(formDataOrginal.prType)">
                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Allocation" placeholder="Allocation" vid="allocation" name="allocation" v-model="formData.allocated_quantity" :validated="validated" :rules="''" size="sm" :readOnly="readOnly"/>
                                    </template>
                                </mdb-col>
                            </mdb-row>
                        </template>
                        <!--                  Quantity Adjust List Start-->
                        <template v-if="activeTabSlot == 'quantity-adjust' && formStatus == 'update'">
                            <div class="window-inner-container mt-1">
                                <mdb-row class="tab-row">
                                    <mdb-col col="12" class="tab-col">

                                        <el-table ref="purchaseRequestTable" border :data="customerQuotation" class="input-table mt-0 custom-element-table" style="width: 100%" >
                                            <el-table-column label="#" width="35" align="center">
                                                <template slot-scope="scope">
                                                    {{scope.$index + 1}}
                                                </template>
                                            </el-table-column>
                                            <!-- <el-table-column label="User Name" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{scope.row.user.name}}
                                                </template>
                                            </el-table-column> -->
                                            <el-table-column label="Quantity" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{ scope.row.quantity }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Reason" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{scope.row.reason}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Description" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{scope.row.description}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Location" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{scope.row.location.name}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Date" :row-class-name="''">
                                                <template slot-scope="scope">
                                                    {{formatDate(scope.row.created_at)}}
                                                </template>
                                            </el-table-column>

                                            <template slot="empty">
                                                <template v-if="loadingQuotation">
                                                    <mdb-btn color="elegant" size="sm" rounded disabled>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Loading data...
                                                    </mdb-btn>

                                                </template>
                                                <template v-else>
                                                    No Data
                                                </template>
                                            </template>
                                        </el-table>

                                    </mdb-col>
                                    <template>
                                        <div style="margin-left:200px">
                                            <el-pagination
                                                class="custom-pagination"
                                                @size-change="handleSizeQuoteChange"
                                                @current-change="handleCurrentQuoteChange"
                                                :current-page.sync="current_page_quot"
                                                :page-sizes="[25, 50, 100, 200, 300, 400]"
                                                :page-size="customer_quotation_length"
                                                layout="total, sizes, prev, pager, next, jumper"
                                                :total="customer_quotation_total"
                                            ></el-pagination>
                                        </div>
                                    </template>
                                </mdb-row>
                            </div>
                        </template>
                        <!--                  Quantity Adjust List End-->
                    </div>

                </div>
                <template slot="footer">
                    <div class="pl-3 pr-3">
                        <div class="d-flex">

                            <template v-if="formLoading || viewLoading">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </mdb-btn>
                            </template>

                            <template v-else>

                                <template v-if="!readOnly">
                                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                                </template>
                                <template v-if="formStatus == 'update'">
                                    <template v-if="!readOnly">
                                        <mdb-btn color="elegant" size="sm" @click="copyItem"><i class="el-icon-copy text-white cursor-pointer"></i> Copy Item</mdb-btn>

                                        <mdb-btn color="elegant" size="sm" @click="triggerNewEvent"><i class="el-icon-save text-white cursor-pointer"></i> Quantity Adjusment</mdb-btn>
                                        <mdb-btn color="elegant" size="sm" @click="openHistoryDialog"><i class="el-icon-save text-white cursor-pointer"></i> History</mdb-btn>
                                    </template>
                                </template>
                                <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                            </template>


                        </div>
                    </div>
                </template>
            </el-dialog>
        </ValidationObserver>
        <ImageGrid ref="upload" @select="fnImageSelect" :moduleForm="'item-form'" :group="group2"></ImageGrid>
        <QuantityAdjustForm ref="dialogForm" :moduleForm="'item-form'" :group="group2"></QuantityAdjustForm>
        <HistoryDialog ref="historyDialog"></HistoryDialog>

    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver } from "vee-validate";
import { mdbBtn,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GridVueSelect from './GridVueSelect.vue'
import { MessageBox } from 'element-ui';
import QuantityAdjustForm from "./QuantityAdjustForm";
import HistoryDialog from "../../components/thats/HistoryDialog.vue";
export default {
    components : {
        HistoryDialog,
        QuantityAdjustForm,
        mdbBtn,
        mdbTabs,
        mdbNavbar,
        mdbNavbarBrand,
        mdbContainer,
        mdbSelect,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardImage,
        ValidationObserver,
        ImageGrid,
        GridVueSelect,
        mdbFormInline,
        ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
        filterFormDataQuotation : {
            type: Object,
            default : () => ({
                searchTermQuot : null
            })
        },
    },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    data(){
        return {
            syncModal : false,
            activeTabIndex : 0,
            activeTabObject : { text: 'Item Details', tabSlot : 'main' },
            mdbTabsData : [
                { text: 'Item Details', tabSlot : 'main' }, { text: 'Inventory Data', tabSlot : 'inventory-slot' }
            ],
            group2 : 5,
            formData : null,
            formDataOrginal : null,
            formLoading : false,
            readOnly : true,
            showError : true,
            validated : true,
            itemType : [
                {label : "Stock", value : "stock"},
                {label : "Services", value : "services"},
            ],

            itemWebsiteStatus : [
                {label : "Yes", value : "1"},
                {label : "No", value : "0"},
            ],

            itemStatus : [
                {label : "Archive", value : "2"},
                {label : "Active", value : "1"},
                {label : "Discontinued", value : "0"},
            ],



            inventoryData : [],

            /*   filterAttributes : [], */

            finishes : [],
            fabric : [],
            materials : [],
            materials1 : [],
            colour : [],
            colour1 : [],
            locations : [],
            vendors : [],
            brands : [],
            allBrands : [],
            collections : [],
            category : [],
            attributes : [],
            area : [],
            items : [],
            itemDiscountPercentConfig : {
                decimal: '.',
                thousands: ',',
                prefix: '',
                suffix: ' %',
                precision: 2,
                masked: false
            },

            itemGroups : [],
            dimensionType: [
                { label: 'SIZE', code: 'size' },
                { label: 'LENGTH', code: 'length' },
                { label: 'WxHxD', code: 'whd' }
            ],
            itemBrands : [],
            itemCollections : [],
        }
    },
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))
        this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))
    },
    mounted(){
        const self = this
        console.log("mounted")
        console.log(this.categoryComputed)


        self.getColour({'show_in' : 'product'}).then(response => {

            const c = [];

            if(response.length){
                response.forEach(e => {
                    c.push({
                        colour : e.label,
                        code : e.slug,
                        attributes : e.attributes,
                    })
                })
                self.colour = c
            }
        })
        self.getMaterials({'show_in' : 'product'}).then(response => {
            const c = [];
            if(response.length){
                response.forEach(e => {
                    c.push({
                        material : e.label,
                        code : e.slug,
                        attributes : e.attributes,
                    })
                })
                self.materials = c
            }
        })


    },
    methods : {
        ...mapActions('BrandModule',['getBrands']),
        ...mapActions('CollectionModule',['getCollections']),
        ...mapMutations('InventoryItemMasterModule',['cancelRequest','setFormStatusCreate']),
        ...mapMutations('NewItemModule',['cancelRequest','addDeletedBomItem']),
        ...mapActions('InventoryItemMasterModule',['storeRecord','updateRecord']),

        ...mapMutations('QuantityAdjustModule',['setTermQuotation','setFilters','setSearchTermQuotation','setCustomerQuotationPage','setCustomerQuotationLength']),
        ...mapActions('QuantityAdjustModule',['getQuantityAdjust']),

        ...mapActions('ColourModule',[ 'getColour' ]),
        ...mapActions('MaterialModule',[ 'getMaterials' ]),
        ...mapActions('ItemFilterModule',[
            'filterItems','filterItemsBom'
        ]),
        ...mapMutations('HistoryModule',[
            'setModule',
            'setRecordId',
        ]),

        ...mapActions('ItemModule',[
            'GetBrandRelationship'
        ]),
        openDialog(){
            this.syncModal = true
            this.getQuantityAdjust(this.formData.mdbID);
        },
        closeDialog(){
            this.syncModal = false
        },
        resetDefaultTab(){
            this.activeTabIndex = 0,
                this.activeTabObject = { text: 'Item Details', tabSlot : 'main' }
        },
        getActiveTab(v){
            this.activeTabIndex = v
            this.activeTabObject = this.mdbTabsData[v];
        },

        onBrandSelect(){

        },
        onCategorySelect(){
            this.formData.attr.attribute = null
        },
        onAreaSelect(){
            this.formData.category = null
        },
        onSelectMaterial(){

        },
        onSelectColour(){

        },
        openCollectionForm(){

        },
        openMaterialForm(){

        },
        openColourForm(){

        },

        triggerNewEvent(){
            console.log('swaraj Dialog')
            this.$refs.dialogForm.openNewDialog(this.formData)
        },






        formatDate(dateString) {
            const date = new Date(dateString);
            const options = {
                year: "numeric",
                month: "long",
                day: "2-digit",
                // hour: "2-digit",
                // minute: "2-digit",
                // hour12: true,
            };
            return date.toLocaleDateString("en-US", options);
        },


        handleSearchTermQuotation() {
            // this.dispatchHandler()
            this.dispatchFilter({
                ...this.filterFormDataQuotation,searchTermQuot : this.termQuotation
            })
        },
        dispatchFilter(filters){
            this.setFilters(filters)
            this.resetDataQuotation(this.formData.mdbID);
        },
        handleSizeQuoteChange(val) {
            this.setCustomerQuotationLength(val);
            this.dispatchQuoteHandler();
        },
        async dispatchQuoteHandler() {
            await this.getQuantityAdjust(this.formData.mdbID);
        },
        clearSearchField() {
            this.termQuotation = '';

            this.handleSearchTermQuotation();
        },
        handleCurrentQuoteChange(val) {
            this.dispatchQuoteHandler();
        },












        removeImage(i,isDefault){
            var self = this;
            MessageBox.confirm('This will permanently delete the image?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                self.formData.image.splice(i,1)
            })
        },

        onSelect(e,r){

            this.formData.image.forEach((img,i) => {
                if(r.token_id === img.token_id){
                    this.formData.image[i].default = 1
                }else{
                    this.formData.image[i].default  = 0
                }
            })
        },

        uploadImage(){
            this.$refs.upload.openDialog();
        },

        getSummary(param){
            const self = this;
            const { columns, data } = param;
            const sums = [];
            // const numberColumns = [2,3,4,5,6,7,8]

            let totalCol = 2;
            let totalQty= 3;
            // let totalPrice= 4;
            let totalAmount= 5;
            // let totalDiscount= 6;
            let totalTotal= 7;

            columns.forEach((_column, index) => {



                if (index === 0) {
                    sums[index] = 'Total';
                    return;
                }
                if (index === 1) {
                    const qty = data.map(item => {
                        return parseInt(item.stock)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === 2) {
                    const qty = data.map(item => {
                        return parseInt(item.committed)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }



                /*         if (index === 3) {
                          const qty = data.map(item => {
                              return parseInt(item.available)
                            })

                            if (!qty.every(qty => isNaN(qty))) {
                                sums[index] = qty.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                  return prev + curr;
                                } else {
                                  return prev;
                                }
                              }, 0);
                            }
                          return;
                        }
                 */




            });

            // this.toggleCheck()

            return sums;
        },


        onSubmitForm(){
            var self =this
            this.validated = true
            this.$refs.observer.validate().then((isValid) => {

                // console.log(isValid)
                this.showError = false
                if (isValid) {
                    // this.form.description = this.getText();
                    if(self.formLoading === false){
                        self.formLoading = true
                        self.formData.bill_of_materials = self.formData.bill_of_materials.filter((item) => item.item_id != '',[])
                        if(self.formStatus == 'create'){


                            self.storeRecord(self.formData).then((response) => {
                                self.$ThatsNotify.success()
                                self.$emit('success',response)
                                self.closeDialog();
                                self.formLoading = false
                                /*
                                self.FormResetter(); */

                            }).catch((error) => {
                                self.formLoading = false
                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                        self.$refs.observer.setErrors(error.response.data.errors);
                                        this.showError = true
                                    }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                        }

                        if(self.formStatus == 'update'){
                            self.updateRecord(self.formData).then((response) => {
                                self.$ThatsNotify.success({
                                    message : "Record was successfully updated"
                                })
                                // self.closeDialog();
                                self.$emit('success',response)
                                /* self.FormResetter(); */
                                self.formLoading = false

                            }).catch((error) => {

                                const errorType = typeof error

                                if(['object'].includes(errorType)){
                                    console.log(error.message)
                                }

                                console.log("update error")
                                console.log(error, typeof error)

                                console.log(error.message)
                                self.formLoading = false

                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                        self.$refs.observer.setErrors(error.response.data.errors);
                                        this.showError = true
                                    }
                                } else {
                                    self.$ThatsNotify.error({
                                        message : error.message
                                    })
                                }

                                /*  */
                                // return;
                            })


                        }
                    }

                }


            });


        },

        fnImageSelect(v){
            console.log(v)
            let defaultImage = 0;
            if(this.formData.image.length <= 0){
                    defaultImage = 1  
            }
            if(v.length > 0){
                v.forEach((r) => {
                    const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : defaultImage, medium : r.medium, md : r.md, sm : r.sm, lg : r.lg}
                    this.formData.image.push(d)
                })
            }
        },

        isEmptyRow(row) {
            // Check if the row is empty based on your criteria
            return !row.sku && !row.name && !row.qty && !row.price;
        },

        getSelected(v) {
            const selectedItem = this.items.find(item => item.code === v);

            if (selectedItem) {
                const existingItem = this.formData.bill_of_materials.find(item => item.item_id === selectedItem.id);

                if (!existingItem) {
                    if (selectedItem.id !== this.formData.id) {
                        const qty = 1;
                        const price = selectedItem.price;

                        const newItemData = {
                            item_id: selectedItem.id,
                            sku: selectedItem.code,
                            name: selectedItem.label,
                            qty: qty,
                            price: price
                        };

                        const emptyItemIndex = this.formData.bill_of_materials.findIndex(item => item.item_id === '');

                        this.formData.bill_of_materials.splice(emptyItemIndex, 0, newItemData);

                        const updatedEmptyItemIndex = emptyItemIndex + 1;
                        this.formData.bill_of_materials.splice(updatedEmptyItemIndex, 1);


                        this.formData.bill_of_materials.push({
                            item_id: '',
                            sku: '',
                            name: '',
                            qty: '',
                            price: '',
                        });

                    }
                }
            }
        },

        statusKeyUp(v) {
            const params = {
                term: v
            };
            this.dispatchFilterItems(params);
        },
        async handleDelete(id) {
            const confirmed = window.confirm('Are you sure you want to delete this item?');

            if (confirmed) {

                const index = this.formData.bill_of_materials.findIndex(item => item.item_id === id);
                if (index !== -1) {
                    this.formData.bill_of_materials.splice(index, 1);
                    this.addDeletedBomItem(id);
                }


            }
        },
        dispatchFilterItems(params) {
            this.filterItemsBom(params).then((response) => {
                const results = response.map(element => ({
                    label: element.name,
                    code: element.series,
                    formatted_price: element.formatted_price,
                    price: element.sale_price,
                    id: element.mdbID,
                    promotions: element.promotions,
                    qty: element.qty,
                    future: element.future_stock,
                    default: element.default,
                    category: element.category,
                    status: element.status,
                    remaining: element.remaining
                }));


                this.items = results;
            });
        },

        copyItem(){
            this.setFormStatusCreate();
        },
        openHistoryDialog(){
            this.setModule('item')
            this.setRecordId(this.formData.mdbID)
            this.$refs.historyDialog.openDialog();
        }

    },

    computed : {
        ...mapGetters('InventoryItemMasterModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),
        ...mapGetters('QuantityAdjustModule',['searchTermQuot','loadingQuotation','customerQuotation','customer_quotation_total','customer_quotation_page', 'customer_quotation_length']),

        termQuotation : {
            get : function(){
                return this.searchTermQuot
            },
            set : function(val){
                this.setTermQuotation (val)
            }
        },
        current_page_quot: {
            get: function() {
                return parseInt(this.customer_quotation_page);
            },
            set: function(val) {
                this.setCustomerQuotationPage(val);
            }
        },



        activeTabSlot : function(){
            return this.activeTabObject.tabSlot
        },

        brandComputed : function(){
            return this.brandProvider();
        },
        brandGroupComputed : function(){
            const brandProviderData = this.brandProvider();
            return brandProviderData.filter((item) => item.parent == 0,[])
        },

        categoryComputed : function(){
            return this.categoryProvider();
        },
        areaComputed : function(){
            return this.areaProvider();
        },

        collectionComputed : function(){
            return this.collectionProvider();
        },
        /*  colorComputed : function(){
           return this.colorProvider();
         }, */
        warehousesLoading : function(){
            return false
        },

        filterArea : function() {
            const results = this.area
            if(results.length > 0 && this.formStatus == 'create'){
                this.formData.area = results[0].code
            }
            return results;
        },
        filterCategory : function() {
            const results = this.category.filter((item, index) => {
                return item.area === this.formData.area
            })

            if(results.length > 0 && this.formStatus == 'create'){
                this.formData.category = results[0].code
            }
            return results;
        },
        filterCollections : function() {
            if(this.formData.brand === null){
                return []
            }
            const results = this.collections.filter((item, index) => {
                return item.brand_id === this.formData.brand
            })


            const response = [];
            if(results.length > 0){

                results.forEach((el, index) => {
                    if(index === 0 && this.formStatus == 'create'){
                        this.formData.collection = el.value
                    }
                    response.push({collection : el.name,code : el.mdbID})
                })
            }
            return response;

        },

        filterProductType : function() {

            return [
                {label : "Product", value : "product"},
                {label : "Set", value : "set"},
                {label : "Part", value : "part"},
            ];
        },
        billOfMaterialsType : function() {

            return [
                {label : "Sales", value : "iSalesTree"},
                {label : "Assembly", value : "iAssemblyTree"},
            ];
        },

        filterAttributes : function() {
            const self = this;
            const response = [];
            if(this.formData.area === null && this.formData.category === null){
                return response;
            }

            const results = this.filterCategory.filter((item, index) => {
                return item.area === self.formData.area && item.code === self.formData.category
            })

            if(results.length > 0){
                /* if(this.FormStatus == 'create'){
                  this.form.attribute = results[0].attributes[0].code
                } */
                results[0].attributes.forEach((el) => {
                    response.push({attribute : el.text , code : el.slug});
                })
            }
            return response;
        },


    },

    watch : {
        syncModal : function(value){
            const self = this;
            if(!value){
                this.cancelRequest();
                this.resetDefaultTab();
                this.formData = JSON.parse(JSON.stringify(this.formObject))
            }else{

                if(this.allBrands.length <= 0){

                    this.getBrands().then((res) => {
                        const brands = res.map((element) => {
                            return {brand : element.name,code : element.id, parent : element.parent}
                        },[]);
                        self.allBrands = brands
                        self.itemGroups = brands.filter((item) => item.parent === 0,[])
                        // self.itemGroups = brands
                        self.itemBrands = brands.filter((item) => item.parent > 0,[])
                    });
                }
                if(this.itemCollections.length <= 0){

                    this.getCollections().then((res) => {
                        self.itemCollections = res
                    });
                }

                if(self.area.length <= 0){
                    self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
                        self.area = response
                        // self.setArea(response)
                    })
                }

                if(self.category <= 0){

                    self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
                        self.category = response
                        // self.setCategory(response)
                    })
                }



            }

            if(this.formStatus == 'update'){
                const getBomIndex = self.mdbTabsData.findIndex(obj => obj.tabSlot === "bill-of-materials");
                if(getBomIndex < 0){
                    self.mdbTabsData.push({ text: 'Bill of Materials', tabSlot : 'bill-of-materials' })

                    self.mdbTabsData.push({ text: 'Quantity Adjustment', tabSlot : 'quantity-adjust' })
                }


                /*  const getQtyAllocationIndex = self.mdbTabsData.findIndex(obj => obj.tabSlot === "quantity-allocation");
                 if(getQtyAllocationIndex < 0 && ['set'].includes(this.formData.prType)){
                     self.mdbTabsData.push({ text: 'Quantity Allocation', tabSlot : 'quantity-allocation' })
                 } */

                /* const checkIfHasEmptyRow = this.formData.bill_of_materials.filter((item) => item.item_id == '',[]);

                if(checkIfHasEmptyRow.length <= 0){

                } */






            }
            this.readOnly = (this.$helpers.isSystemAdministrator() === true || this.$helpers.isBackOffice() || this.$helpers.itemCreate()) ? false : true;
        },

        formRecord : function(value){
            console.log("formRecord")
            console.log(value)

            const itemData = value;

            if(!itemData.attr.hasOwnProperty('discount')){
                itemData.attr.discount = {
                    percent : null,
                    value : null,
                    remarks : null,
                }
            }
            if(!itemData.hasOwnProperty('bill_of_materials')){
                itemData.bill_of_materials = [{
                    item_id: '',
                    sku: '',
                    name: '',
                    qty: '',
                    price: '',
                }];
            }else{
                if(itemData.bill_of_materials.length <= 0){
                    itemData.bill_of_materials = [{
                        item_id: '',
                        sku: '',
                        name: '',
                        qty: '',
                        price: '',
                    }];
                }else{
                    itemData.bill_of_materials.push({
                        item_id: '',
                        sku: '',
                        name: '',
                        qty: '',
                        price: '',
                    });
                }
            }

            console.log("itemData")
            console.log(itemData)


            console.log("itemData.warehouses")
            console.log(itemData.warehouses)
            const itemWarehouses = itemData.warehouses.filter((item) => item.stock > 0 || item.committed > 0 || item.available > 0,[]);
            console.log("itemWarehouses")
            console.log(itemWarehouses)
            this.formData = {...itemData, warehouses : itemWarehouses}
            this.formDataOrginal = JSON.parse(JSON.stringify({...itemData, warehouses : itemWarehouses}))

            console.log("watch category")
            console.log(this.categoryComputed)

            console.log("watch areaComputed")
            console.log(this.areaComputed)

            console.log("watch collectionComputed")
            console.log(this.collectionComputed)

        },
        brandComputed : function(value){
            console.log("watch brand ==================")
            console.log(value)
            this.allBrands = value
            this.itemGroups = value.filter((item) => item.parent === 0,[])
            this.itemBrands = value.filter((item) => item.parent > 0,[])
        },
        categoryComputed : function(value){
            console.log("watch category")
            console.log(value)
            this.category = value
        },
        areaComputed : function(value){
            console.log("watch area")
            console.log(value)
            this.area = value
        },
        collectionComputed : function(value){
            console.log("watch collectionComputed")
            console.log(value)
            this.collections = value
        },

        "formData.ItmGroup" : function(value){
            /*  console.log("watch ItmGroup")
             console.log(value)
             this.formData.ItmGroup = value */
            this.itemBrands = this.allBrands.filter((item) => item.parent === value,[]);

            if(this.viewLoading === false){
                this.formData.brand = null;
                this.formData.collection = null;
            }
        },
        "formData.brand" : function(){

            if(this.filterCollections.length <= 0 && this.viewLoading === false){
                this.formData.collection = null;
            }
        },
    }
}
</script>

<style scoped>
.click-to-add-logo{
    font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
    right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
    padding: 158px 65px;
    text-align: center;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}

</style>
<style scoped>
.tab-col {
    position: relative;
}

.custom-pagination {
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;
}
</style>


