
<template>
    <div class="customervendor-container">
        <Table title="Customer/Vendor" :endpoint="endpoint" @openModal="OpenFormDialog" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns"></Table>
        <CustomerVendorForm ref="customervendorForm"></CustomerVendorForm>
    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import Table from '../../components/Tables/Table'
import CustomerVendorForm from './CustomerVendorForm'
export default {
    data(){
        return {
            endpoint : 'customer-vendor/data'
        }
    },
    components: {
        CustomerVendorForm,
        Table
    },

    created : function() {
      //always close open window
        this.CloseDialog();
    },
    methods: {

        ...mapActions('TableModule',[ 'resetData' ]),
        ...mapActions('CustomerVendorModule',[ 'DeleteRecord' ]),
        ...mapMutations('CustomerVendorModule',[ 'SetFormRecord', 'SetFormStatusUpdate', ]),
        ...mapMutations('DialogModule',[ 'OpenDialog', 'CloseDialog', ]),
        OpenFormDialog(){
            if(this.DialogIsOpen == true){
                this.$refs.customervendorForm.FormResetter()
            }
            this.OpenDialog()
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteItem(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
                self.resetData(self.endpoint)
            })
        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.OpenDialog()
        }
    },
    computed: {
        ...mapGetters('DialogModule',[ 'DialogIsOpen', ]),
        ...mapGetters('CustomerVendorModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>