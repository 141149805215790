<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="formObserver">

            <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'Call Log'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="30%" class="text-left call-log-dialog" :formStatus="formStatus" @dialog-state="dialogState" :group="group">

            <mdbContainer class="mt-3 mb-0">

              <span>
                <div class="form-group input-group-sm row thats-form m-0">
                    <label class="col-form-label">Contact Details</label>
                </div>
              </span>

              <FieldSetCard>

                    <template v-if="formStatus == 'update'">
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :show_error="true" :disabled="true"/>
                    </template>
                    <template v-else>
                    <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Customer" v-model="form.customer_id" :reduce="customer => customer.value" :reduceLabel="'opt'" :options="customer" :rules="'required'" :clearable="false" @select="getCustomerSelected($event)" @change="getCustomerSelected($event)" @onSearch="searchCustomer"></ThatsCustomMultiSelect>
                    </template>
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile" vid="mobile" name="mobile" v-model="form.mobile" :validated="validated" :rules="'required'" size="sm" :show_error="true" :disabled="true"/>
                    
              </FieldSetCard>

        

      
              <FieldSetCard>

              <div class="row">
                      <div class="col-md-3">
                        <label class="" style="text-transform: uppercase;font-size: 0.8rem !important;font-weight: 600;margin-left: 1px;margin-top:18px">DATE</label>
                      </div>
                      <div class="col-md-9">
                        <div class="row">
                          <div class="col-md-4">
                            <ThatsDatePicker tabindex="1" labelClass="col-md-3" fieldClass="col-md-12 pl-0" label="Date" placeholder="Date" vid="date_of_call" name="date_of_call" v-model="form.date_of_call" :validated="validated" :rules="'required'" size="sm" :showLabel="false" :disabled="isReadonly"/>
                          </div>
                          <div class="col-md-4 pl-0">
                       

                             <ValidationProvider vid="time_of_call" name="time_of_call" :rules="'required'" v-slot="{errors}">
                                <TimePicker v-model="form.time_of_call" class="m-0 mdb-time-picker" placeholder="Time" :errors="errors" :isValid="(errors.length > 0 ? false : true)" :wasValidated="validated" :inputClass="[(errors.length > 0 ? 'is-invalid' : '')]" :disabled="isReadonly"/>
                            </ValidationProvider>

                          </div>
                      </div>
                      </div>
                  </div>

                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-4  pl-0" label="Purpose of the call" v-model="form.purpose_of_call" :reduce="label => label.value" :reduceLabel="'label'" :options="purposeOfCall" :rules="'required'"  :disabled="isReadonly" :multiple="false"></ThatsMultiSelect>
                    
                    <div class="row">
                      <div class="col-md-3">
                        <label class="" style="text-transform: uppercase;font-size: 0.8rem !important;font-weight: 600;margin-left: 1px;margin-top:18px">Call Duration</label>
                      </div>
                      <div class="col-md-4 pl-0">

                      <ValidationProvider vid="call_duration" name="call_duration" :rules="'required'" v-slot="{errors}">
                          <TimePicker v-model="form.call_duration" class="m-0 mdb-time-picker" placeholder="Time" :errors="errors" :isValid="(errors.length > 0 ? false : true)" :wasValidated="validated" :inputClass="[(errors.length > 0 ? 'is-invalid' : '')]" :disabled="isReadonly"/>
                      </ValidationProvider>

                        
                      </div>
                  </div>
                 
                    <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-sm-3'" :fieldClass="'col-sm-9  pl-0'" label="Action to be taken" placeholder="Action to be taken" vid="description" name="description" v-model="form.action_to_be_taken" size="sm" :rules="''"/>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-4  pl-0" label="Satisfaction" v-model="form.satisfaction" :reduce="label => label.value" :reduceLabel="'label'" :options="satisfaction" :rules="''" :multiple="false"></ThatsMultiSelect>
                     <div class="row">
                      <div class="col-md-3">
                        <label class="" style="text-transform: uppercase;font-size: 0.8rem !important;font-weight: 600;margin-left: 1px;margin-top:18px">CALLBACK</label>
                      </div>
                      <div class="col-md-4">
                            <ThatsDatePicker tabindex="1" labelClass="col-md-3" fieldClass="col-md-12 pl-0" label="Date" placeholder="Date" vid="callback" name="callback" v-model="form.callback" :validated="validated" :rules="''" size="sm" :showLabel="false"/>
                        
                      </div>
                  </div>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-4  pl-0" label="Rating" v-model="form.rating" :reduce="label => label.value" :reduceLabel="'label'" :options="rating" :rules="''"   :multiple="false"></ThatsMultiSelect>
                    <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-sm-3'" :fieldClass="'col-sm-9  pl-0'" label="Comments" placeholder="Comments" vid="comments" name="comments" v-model="form.comments" size="sm" :rules="''" />
              </FieldSetCard>  



              <FieldSetCard>
               
                <ThatsMultiSelect labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="User" :clearable="false" :multiple="false" :showLabel="true"  v-model="form.sold_by" :reduce="label => label.code" :reduceLabel="'label'" :options="partnerSalesPerson" :rules="''" :disabled="true"></ThatsMultiSelect>
              </FieldSetCard>
            </mdbContainer>

              <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>

                    <template v-else>
                      <template v-if="['create','update'].includes(formStatus)">
                        <mdb-btn color="elegant" size="sm" @click="onSubmitForm" type="button"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                        </template>
                      
        <!--                 <template v-if="formStatus == 'update'">
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitResend)"><i class="el-icon-save text-white cursor-pointer"></i> Resend</mdb-btn>
                        </template> -->
                        <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                    </template>
                </div>
              </template>
           </el-dialog>

          </ValidationObserver>




  </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsMultiSelect, ThatsTextarea, ThatsDatePicker,FieldSetCard, ThatsCustomMultiSelect} from '../../components/thats/Thats'
import {  mdbRow,mdbCol } from 'mdbvue';
import InternationalMobile from "../../components/thats/InternationalMobile.vue";
import TimePicker from "../../components/TimePicker/TimePicker.vue";

export default {
    components: {
        InternationalMobile, ValidationProvider,
        ThatsTextarea,
        ThatsDatePicker,
        mdbContainer,
        mdbBtn,
        ThatsCustomMultiSelect,
        TimePicker,
        ValidationObserver,
        ThatsInput,
        ThatsMultiSelect,
        mdbRow,
        mdbCol,
        FieldSetCard
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
      
    },

    inject:['showroomsProvider','filteredCategoryProvider','brandProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      customer : [],
        categories : [],
        brands : [],
        partnerSalesPerson : [],
        validated: false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        reminderDate : [
            { label : "After 1 Day", code : "1day"},
            { label : "After 2 Day", code : "2day"},
            { label : "After 3 Day", code : "3day"},
            { label : "After 4 Day", code : "4day"},
            { label : "After 5 Day", code : "5day"},
            { label : "After 6 Day", code : "6day"},
            { label : "After 7 Day", code : "7day"},
        ],
      group2 : 3,

      purposeOfCall : [
        {
            value: 'general-inquiry',
            label: 'General Inquiry (Location, hours, services)'
        },
        {
            value: 'complaint',
            label: 'Complaint'
        },
        {
            value: 'price-inquiry',
            label: 'Price Inquiry'
        },
        {
            value: 'delivery-inquiry',
            label: 'Delivery Inquiry'
        }
      ],
      satisfaction : [
        {
            value: 'pleased',
            label: 'Pleased'
        },
        {
            value: 'neutral',
            label: 'Neutral'
        },
        {
            value: 'upset',
            label: 'Upset'
        }
      ],

      rating : [
        {
            value: 1,
            label: 1
        },
        {
            value: 2,
            label: 2
        },
        {
            value: 3,
            label: 3
        },
        {
            value: 4,
            label: 4
        },
        {
            value: 5,
            label: 5
        },
        {
            value: 6,
            label: 6
        },
         {
            value: 7,
            label: 7
        },
        {
            value: 8,
            label: 8
        },
        {
            value: 9,
            label: 9
        }
        ,
        {
            value: 10,
            label: 10
        }
      ]

    }),

    created : function() {
      this.FormResetter();
       this.optionsHandler();

    },
    mounted() {
        this.getPartner().then((response) => {
            console.log(response,"partner")
            this.partnerSalesPerson = response
        })
        
        

    },
    methods: {

      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('CallLogModule',[
        'store',
        'update',
      ]),
        ...mapActions('TransactionModule',[
            'getPartner']),
      ...mapMutations('CallLogModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
            date_of_call: '',
            time_of_call: '',
            contact_id: '',
            mobile: '',
            name: '',
            purpose_of_call: '',
            action_to_be_taken: '',
            satisfaction: '',
            call_duration: '',
            callback: '',
            rating: 0,
            comments: '',
            
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },

        searchCustomer(v){
            const self = this;
            if(v !== ""){
                self.fnGetContacts({term : v})
            }
        },
        fnGetContacts(params){
            const self = this;
            const contacts = []
            this.GetContacts(params).then((response) => {
                if(response.length > 0){
                    response.forEach((element) => {
        
                        contacts.push({
                            ...element,
                            opt: element.label + " " + element.mobile,
                        })
                    })
                }

            })

            self.customer = contacts;
        },

        getCustomerSelected(v){

            console.log("onSelected Multiselect")
            const selectedCustomer = this.customer.filter(c => {
                return c.value === v;
            })[0];

       
            if(selectedCustomer !== null){
                this.form.contact_id = selectedCustomer.value
                this.form.name = selectedCustomer.label
                this.form.mobile = selectedCustomer.mobile

            }

        },
        /* end */
        openDialog(){
            this.syncModal = true
            this.getCategory();
            this.getBrand();
            const localStorageData = localStorage.getItem('user');

         /*    console.log(data, "swaraj data")
            // Parse the JSON data if necessary
            const parsedData = JSON.parse(localStorageData);

            // Update the form with the default selectionss
            this.form.location = parsedData.location.id;
            this.form.sold_by = parsedData.user_id;

            if (this.formStatus == 'update'){
                this.form.name = data.name;

                this.form.email = data.email;
                this.form.attr = data.attr;
                this.form.attr = data.attr['requestAttr']
                this.form.comments = data.comments
                this.form.reminder_time = data.reminder_time

                // Parse the string into an array of numbers
                const brandIds = JSON.parse(data.brand);

                // Map the brand IDs to their corresponding labels and values
                this.form.brand = brandIds.map(brandId => {
                    const brand = this.brands.find(brand => brand.value === brandId);
                    return brand ? { value: brand.value, label: brand.label } : null;
                });
                // Parse the string into an array of numbers
                const categoryIds = JSON.parse(data.category);

                // Map the category IDs to their corresponding labels and values
                this.form.category = categoryIds.map(categoryId => {
                    const category = this.categories.find(category => category.value === categoryId);
                    return category ? { value: category.value, label: category.label } : null;
                });




            } */

        },
        getCategory() {
            var self = this;
            const categories = self.filteredCategoryProvider();

            const formattedCategories = categories.map(category => {
                return {
                    value: category.id,
                    label: category.name
                };
            });

            self.categories = formattedCategories;

            // Log the result for debugging
            console.log(formattedCategories, "Formatted Categories");
        },
        getBrand() {
            var self = this;
            const brands = self.brandProvider();

            const brandItems = brands.map(brand => {
                return {
                    value: brand.code,
                    label: brand.brand
                };
            });

            self.brands = brandItems;
            //
            // // Log the result for debugging
            console.log(brandItems, "Formatted brand");
        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        copyMobileToWhatsApp() {
            if (this.form.sameAsMobile) {
                this.$set(this.form.attr.contacts, 1, JSON.parse(JSON.stringify({...this.form.attr.contacts[0], type: 'secondary'})));
                this.$forceUpdate();
            } else {
                this.$set(this.form.attr.contacts, 1, {
                    type: 'secondary',
                    intl: false,
                    code: '+974',
                    value: null
                });
            }
        },






        onSubmitForm(){
        var self =this
        self.validated = true
       this.$refs.formObserver.validate().then((isValid) => {
        if(isValid){
            
               if(self.formLoading === false){
                      if(self.formStatus == 'create'){
                        self.formLoading = true
                          self.store(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.formObserver.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                    if(self.formStatus == 'update'){
                      self.formLoading = true
                          self.update(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.formObserver.setErrors(error.response.data.errors);
                                    self.formLoading = false
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      
                    }
                }
          }
       });



         /*  */
           this.showError = false
            /* 

           
            

            alert("test",isValid) */
            /* if (isValid) {
                    

                      

            } */
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.formObserver.reset();
            });
        this.validated = false
        this.formLoading = false
        this.setFormStatusCreate();
        this.showError = false
        const localStorageData = localStorage.getItem('user');
        const parsedData = JSON.parse(localStorageData);
        this.form.sold_by = parsedData.user_id;
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.formStatus == 'create'){
          this.FormResetter();
        }

      },

      onSubmitResend(){

      }



    },

    computed: {

        ...mapGetters('CallLogModule',
      [
        'formStatus',
        'formRecord',
      ]),

        whatsappNumber() {
            if (this.form.sameAsMobile) {
                console.log("Returning form.mobile as whatsappNumber:", this.form.mobile);
                return this.form.mobile;
            } else {
                console.log("Returning form.whatsapp as whatsappNumber:", this.form.whatsapp);
                return this.form.whatsapp;
            }
        },

        showrooms : function(){
            return this.showroomsProvider();
        },

        isReadonly() {
            return (this.formStatus == 'update') ? true : false;
        },


    },

    watch: {
      formRecord : function(value){

         console.log(value, "form record")
         console.log(this.formStatus,'update')

        if(this.formStatus == 'update'){
           const frm = JSON.parse(JSON.stringify(this.formRecord));

           console.log(frm, "form record")

           this.form = {...this.form,...frm}

            /*
            console.log(this.form, "form") */
          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.formStatus == 'create'){
          this.FormResetter();

        }
      },
      syncModal: function(newDialogIsOpen) {
          if (!newDialogIsOpen) {
              this.closeNewDialog()
          }
      }, 

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
