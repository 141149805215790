<template>
  <ValidationObserver class="needs-validation" tag="form" ref="observer" v-slot="{errors, passes}">
      <Auth>
        <template v-if="response.error === 0">
          <p class="small success-text" v-text="response.message"></p>
          <router-link to="/login" class="card-link">Back to Login</router-link>
        </template>

        <template v-if="response.error === null || response.error === 1">

        <ul class="form-error">
            <li v-for="(err,index) in errors" v-bind:key="index">
              <template v-if="err.length > 0">
                 {{err[0]}}
              </template>
             
              </li>
        </ul>
        <ValidationProvider vid="password" name="password" rules="required" v-slot="{invalid}">
          <mdb-input :inputClass="isValid(invalid,form.new_password)" v-model="form.password" label="New Password" type="password" autocomplete="off" />
        </ValidationProvider>
        <ValidationProvider vid="confirm_password" name="confirm_password" rules="required|confirmed:password" v-slot="{invalid}">
          <mdb-input :inputClass="isValid(invalid,form.confirm_password)"  v-model="form.confirm_password" label="Confirm Password" type="password" autocomplete="off" />
        </ValidationProvider>
        <div class="d-flex justify-content-between align-items-center">
            <template v-if="Loading">
              <mdb-btn color="elegant" size="sm" rounded disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
                <mdb-btn color="elegant" rounded size="sm"  @click="passes(onSubmitForm)">Change Password</mdb-btn>
            </template>
            <router-link to="/login" class="card-link">Back to Login</router-link>
          </div>
        </template>
      </Auth>
  </ValidationObserver>
</template>
<script>
import {
  mdbView,
  mdbMask,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbIcon,
  mdbNavbarBrand,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbContainer,
  mdbRow,

  mdbCardHeader,
  mdbCardTitle,
  mdbCardText,
  mdbCardFooter
} from "mdbvue";

import { ValidationObserver,ValidationProvider } from "vee-validate";
import Auth from './Auth';
import { mapActions,mapGetters } from 'vuex'
export default {
    components: {
    mdbView,
    mdbMask,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbIcon,
    mdbNavbarBrand,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbContainer,
    mdbRow,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardText,
    mdbCardFooter,
    Auth,
    ValidationObserver,ValidationProvider
  },
    data : () => ({
      validated : false,
        form : {
          password : null,
          confirm_password : null,
        },
        response : {
          error : null,
          message : null
        }
    }),
    methods: {
      ...mapActions('AuthModule',[
        'ChangePassword'
      ]),
      async onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
              self.form.token = self.$route.params.token;
                self.ChangePassword(self.form).then((response) => {
                    if(response.status === 200){
                      self.response = response.data
                    }
                }).catch((error) => {
                 self.response.error = 1;
                if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                    }
                  } else if (error.request) {
                      self.response.message = error.request
                  } else {
                      self.response.message = error.message
                  }
                  console.log(error)
                  
                  return;
              })
            }        
      },

      isValid(isInvalid,inpt){
        return (isInvalid === true && inpt === '') ? 'is-invalid' : null
      }
    },

    computed: {
      ...mapGetters('AuthModule',
      [
        'Loading'
      ])
    },


}
</script>

