<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="`loyalty-program/transactions/${activeDropdown.slug}`" :columns="columns" :newBtn="false" @open="openNewModal" @row-click="rowClick" :activeLinkText="activeDropdown.text" @tab-item-click="tabItemClick" :key="tableKey">
            <template #custom-right-culumns="{}">
<!--                <el-table-column-->
<!--                    :sortable="false"-->
<!--                    :default-sort = "{prop: 'transaction_type', order: 'descending'}"-->
<!--                    :prop="'transaction_type'"-->
<!--                    :label="activeDropdown.slug === 'quotation-only' ? 'Quotations' : 'Invoices'"-->
<!--                    width="150"-->
<!--                    align="right"-->
<!--                >-->
<!--                    <template slot-scope="scope">-->
<!--                        {{ activeDropdown.slug === 'quotation-only' ? scope.row.quotations_count : scope.row.transaction_count }}-->
<!--                    </template>-->
<!--                </el-table-column>-->

<!--                <template v-if="!['quotation-only'].includes(activeDropdown.slug)">-->
<!--                    <el-table-column :sortable="false" :prop="'spent'" label="Spent" width="150" align="right"  :default-sort = "{prop: 'spent', order: 'descending'}">-->
<!--                        <template slot-scope="scope">-->
<!--                            {{ scope.row.spent | amount }}-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                </template>-->
            </template>
        </ThatsTable>

<!--        <IntegrationWindow ref="integrationWindow"></IntegrationWindow>-->
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
// import IntegrationWindow from '../../components/thats/IntegrationWindow'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'

import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import {LoyaltyReportModule} from "../../store/modules/loyalty-report.module";

export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput
        // IntegrationWindow
    },
    data(){
        return {
            endpoint : 'loyalty-program/transactions',
            approver : [],
            tableKey : 0,
            transactionCountData: { count: 0, sum : 0 },
            spentData: { amount: 0 },
            quotationsCountData: { count: 0 },
            activeDropdown : {
                slug : 'about-expire',
                text : 'Customer\'s w/ about to expire',
            }

        }
    },


    created(){

    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('LoyaltyReportModule',['getRecord','getCustomerDetails','getCustomerQuotation','getCustomerInvoice','getCustomerTrack','cancelInventoryTransferRecord']),
       ...mapMutations('LoyaltyReportModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),


        rowClick(row,transaction_count,spent,quotations_count, transaction_sum){
           const customer_id = row;
            const self = this;

            this.transactionCountData = { count: 0, sum : 0 };
            this.spentData = { amount: 0 };
            this.quotationsCountData = { count: 0 };
           const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });
                // self.setFormStatusUpdate();
            self.getCustomerDetails(customer_id).then(() => {
                loading.close();
                this.getCustomerTrack(customer_id).then((res) => {
                    console.log(res)
                    loading.close();
                    self.$refs.customerSegmentForm.openDialog();
                })
                self.$refs.customerSegmentForm.openDialog();
                self.transactionCountData = { count: transaction_count, sum :  transaction_sum};
                self.spentData = { amount: spent };
                self.quotationsCountData = { count: quotations_count };

            })
            self.getCustomerQuotation(customer_id).then((res) => {
                console.log(res,'Swaraj')
                loading.close();
                self.$refs.customerSegmentForm.openDialog();
            })
            self.getCustomerInvoice(customer_id).then((res) => {
                console.log(res,'Swaraj')
                loading.close();
                self.$refs.customerSegmentForm.openDialog();
            })
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
            this.$refs.customerSegmentForm.openDialog();
        },
        refreshTable(){
            this.tableKey++
        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      cancelRecord(row){
        const self = this;
        MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.series).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                    self.refreshTable();
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log(error.response.data.message)
                })

              })
      },

      receiveInventoryTransfer(row, action){
            const inventoryTransferData = JSON.parse(JSON.stringify(row))

            /* const inventoryTransferId = inventoryTransferData.id */


          /*   const filteredItems = this.form.inventory_transfer_items.filter((item)=>{
              return item.it_id == inventoryTransferId;
            },[])

            console.log("filteredItems");
            console.log(filteredItems);
            console.log(this.form.inventory_transfer_items);
            console.log(this.form.items); */

           /*  inventoryTransferData.items = filteredItems.map((item) => {
              const requestedItem =   this.form.items.filter((innerItem) => {
                    return innerItem.id == item.itr_item_id
                },[])[0]

                return {...item, photo : requestedItem.photo}
            },[]) */

            const mergeFormData = {...this.initialFormObject,...inventoryTransferData, action : action}

            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });
                self.setFormStatusCreate();
                this.getRecord(mergeFormData).then((res) => {

                    console.log("getRecord response")
                    console.log(res)
                    loading.close();

                })


            console.log(mergeFormData)
           /* this.setInventoryTransferReceiveFormData(JSON.parse(JSON.stringify(mergeFormData)))

           this.$nextTick(()=>{

            this.$refs.inventoryTransferReceivingForm.openDialog()
           }) */
        },
        openIntegrationWindow(){
            this.$refs.integrationWindow.openDialog();
        },

        tabItemClick(obj){
            // console.log(obj)
            // this.endpoint = `customer-segments/${obj.slug}`;
            this.activeDropdown = obj
            this.refreshTable();
        }

    },
    computed: {
        ...mapGetters('LoyaltyReportModule',['getLoading', 'columns','initialFormObject']),

        statusArrayText : function(){

            return {'draft' : 'Draft','for-approval' : 'For Approval','approved': 'Approved'};
        }
    },
}


</script>
