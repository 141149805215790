export const ApplyAmountModule = {
    namespaced: true,
    state: {

        form : {
            DocTotal : null,
            TotalVolume: null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            Currency : null,
            items :[]
        },
        initialFormData : {
            DocTotal : null,
            TotalVolume : null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            Currency : null,
            items :[]
        }
    },
    mutations: {
        setFormData : (state, payload)=>{
            state.form = payload
        },
        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },
    },
    getters: {
        formData: state => state.form,
    }
}



