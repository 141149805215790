<template>
    <div class="itmform-container">
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

            <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'Update Coupon'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="40%" class="text-left tier-beneficiary-dialog" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">


                <template #left-button>
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                    </template>
                </template>
                <mdbContainer class="mt-3">

                    <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Validity Date" placeholder="Validity Date" vid="date" name="date" v-model="form.validity_date" :validated="validated"  size="sm"/>

                    <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Expiry Date" placeholder="Expiry Date" vid="date" name="date" v-model="form.expiry_date" :validated="validated"  size="sm"/>

                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Status" v-model="form.coupon_status" :reduce="label => label.value" :reduceLabel="'label'" :options="couponStatus"  ></ThatsMultiSelect>

                </mdbContainer>


                <div class="d-flex">

                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>

                    <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                        <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                    </template>
                </div>
            </el-dialog>

        </ValidationObserver>




    </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsCheckbox,  ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'

export default {
    components: {
        ThatsDatePicker,
        mdbContainer,
        mdbBtn,
        ValidationObserver,
        ThatsInput,
        ThatsCheckbox,
        ThatsMultiSelect,



    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
        syncModal : false,
        invoicePermission : true,
        requiredField : true,
        resetPassword : false,
        checkboxData : [],
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        formLoading : false,
        showError : false,
        designation : [],
        couponStatus : [
            {label : 'Not Used',value : 'not_used'},
            {label : 'Used',value : 'used'}
        ],
        tiers : [],
        group2 : 3

    }),

    created : function() {
        var self = this;

        if(this.FormStatus == 'create'){
            this.form = this.formVar();
        }

        this.optionsHandler();




        /* this.designationFilter().then((response) => {

          self.designation = response
        }) */

        /*  self.GetShowrooms().then((response) =>{
            self.showrooms = response
           }) */


    },
    methods: {

        /*  ...mapActions('ShowroomsModule',[
           'GetShowrooms'
         ]),
    */

        ...mapActions('GenerateNewCouponModule',[
            'UpdateRecord',
        ]),
        ...mapMutations('GenerateNewCouponModule',[
            'SetFormStatusCreate',
        ]),

        formVar(){
            return {
                validity_date : "",
                expiry_date : "",
                coupon_status : "",
                selected:[],
            }
        },
        /* start */



        optionsHandler(){
            var self = this;
        },



        /* end */
        openDialog(){
            this.syncModal = true

        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
        },


        onSubmitForm(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                if(self.formLoading === false){
                    self.formLoading = true
                    if(self.FormStatus == 'create'){
                        const payload = {
                            validity_date: this.form.validity_date,
                            expiry_date: this.form.expiry_date,
                            coupon_status: this.form.coupon_status,
                            selected: this.selectedCouponIds,
                        };
                        self.UpdateRecord(payload).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response)
                            self.closeDialog();
                            self.FormResetter();

                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }

                }

            }
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
        },

        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.FormResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.FormResetter();
            }

        },



    },

    computed: {

        ...mapGetters('GenerateNewCouponModule',
            [
                'FormStatus',
                'FormRecord',
            ]),
        ...mapGetters('CouponModule',
            [
                'selection','selectedCouponIds'
            ]),



    },

    watch: {
        FormRecord : function(){
            if(this.FormStatus == 'update'){
                const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

                this.form = {...this.form,...frm}
                this.form.tier_ids = frm.tier_ids
                //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
            }

            if(this.FormStatus == 'create'){
                this.FormResetter();

            }
        },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}

</style>
