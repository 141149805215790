<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">

        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-input :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" :inputClass="hasError(errors) + ' text-right'" v-model="inputValue" :type="type" autocomplete="off">
      <span class="input-group-text md-addon" slot="append">{{currency}}</span>
    </mdb-input>

                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
import Vue from 'vue'
export default {
    name : 'ThatsAmountInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        currency : {
            type : String,
            default : 'QAR'
        }

    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(errors){
            return (errors.length > 0) ? 'is-invalid' : null
        },
        number_format(number, decimals, dec_point, thousands_sep) {
    
            var n = !isFinite(+number) ? 0 : +number, 
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                toFixedFix = function (n, prec) {
                    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                    var k = Math.pow(10, prec);
                    return Math.round(n * k) / k;
                },
                s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },

        CommaFormatted(amount) {
	var delimiter = ","; // replace comma if desired
	var a = amount.split('.',2)
	var d = a[1];
	var i = parseInt(a[0]);
	if(isNaN(i)) { return ''; }
	var minus = '';
	if(i < 0) { minus = '-'; }
	i = Math.abs(i);
	var n = new String(i);
	var a = [];
	while(n.length > 3) {
		var nn = n.substr(n.length-3);
		a.unshift(nn);
		n = n.substr(0,n.length-3);
	}
	if(n.length > 0) { a.unshift(n); }
	n = a.join(delimiter);
	if(d.length < 1) { amount = n; }
	else { amount = n + '.' + d; }
	amount = minus + amount;
	return amount;
}
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            // console.log(this.number_format(v.transaction.total, 2, '.', ','))
            /* const result = newVal.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            Vue.nextTick(() => this.inputValue = result); */
             const result = this.CommaFormatted(newVal)
            console.log(`${result} from internal`)
             Vue.nextTick(() => this.inputValue = result);
            this.$emit("input", result);
        },
    // Handles external model changes.
        value : function(v) {
           /*  const result = v.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            Vue.nextTick(() => this.inputValue = result); */
             const result = this.CommaFormatted(v)
            console.log(`${result} from external`)
             Vue.nextTick(() => this.inputValue = result);
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>