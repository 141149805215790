<template>
    <div class="component-container">

        <div class="toolbar-container">
            <button class="btn btn-sm btn-elegant" @click="childClick" :disabled="processing">Print</button>
        </div>

        <div id="app-vue-spreadsheet" :style="appVueSpreadSheet">
            <div ref="spreadsheetx"></div>
        </div>


    </div>
</template>

<script>

import moment from 'moment';
import jspreadsheet from '../../jsexcel/jexcel'


import country from './country'

import {mapActions} from 'vuex'
export default {

    data: ()=> ({
        isSave : false,
        appVueSpreadSheet : {
            width : '1300px'
        },
        width : 1300,
        height : 1024,
        dialogIsOpen : false,
        spreadsheet: null,
        spreadsheetexport: null,
        processing: false,
        exporData : [],
        exporDataSaving : false,
        generated_serial : "",
        options : {
            tableOverflow: true,
            tableWidth: "400px",
            tableHeight: "400px",
            wordWrap : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
             data:[[]],
             minDimensions:[1,30],
             columns: [
                {
                    type: 'text',
                    width: '250px',
                    title: 'ItemCode',
                    name: 'item_code',
                    readOnly: false,
                    primaryKey: true
                },
             
                
            ],

       
        },
        exportTableOptions : {
            download : true,
            csvFileName : 'serial-number',
            allowDeleteColumn : false,
            allowRenameColumn : false,
            csvHeader:true,
            tableWidth : '1300px',
            tableHeight : 'calc(100vh - 92px)',
            tableOverflow : true,
             data:[[]],
             minDimensions:[1,35],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'item_code',
                    readOnly: true,
                    primaryKey: true
                },
               
            ],

             contextMenu: function(obj, x, y, e) {
                // var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                } */
                return false;
             }
        }
    }),
    components : {
  
       
    },
    mounted() {
        // this.options.tableWidth = `${window.innerHeight}px`

        this.exportTableOptions.tableWidth = `${window.innerWidth}px`
        this.appVueSpreadSheet.width = `100%`
        this.options.tableWidth = `100%`
        this.options.tableHeight = `calc(100vh - 92.43px)`
        this.spreadsheet = jspreadsheet(this.$refs.spreadsheetx, this.options);

        Object.assign(this, this.spreadsheet);

        
        
        this.height = window.innerHeight;
        /* this.exportTableOptions.allowCopy = false

        this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);

         
            

             this.spreadsheetexport.setWidth(window.innerWidth) */

            //  this.exportTableOptions.options.allowCopy = false

            //  this.exportTableOptions.allowCopy = false

       /*  this.$eventBus.$on('generate', function(){
           
           console.log(spreadsheet.getData());
        }) */

    },
    methods : {

        ...mapActions('SerialNumberModule',['storeRecord','getSerial','validateSerial']),
       async childClick(){
            
            
           const self = this;
            if(this.processing === false){
                this.exporDataSaving = false;
                
                const tableData = this.spreadsheet.getJson();
 
                if(tableData.length > 0){

                    console.log(tableData)
                    
                    const filterData = tableData.filter(function(rowElement){
                        return (rowElement.item_code != "");
                    },[])

                    if(filterData.length > 0){                   

                        const itemCode = filterData.map((item) => {
                            return item.item_code
                        },[])
                        const arrayToString = itemCode.join(',')
                        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
                        const win = window.open(`print/qr-code?code=${arrayToString}`, "_blank", strWindowFeatures);
                        win.document.title = 'Print';
                        win.print() 
                    }
                }
    
                
            }
        },

        persistData(){
            const self =this;
            if(this.exporDataSaving == false){
                this.exporDataSaving = true;
                
                const jsonData = this.spreadsheetexport.getJson();

                // console.log(jsonData)

                const filterData = jsonData.filter(function(rowElement){
                        return (rowElement.item_code != "" && rowElement.item_name != "" && rowElement.origin != "" && rowElement.quantity != "" && rowElement.received_date != "" &&rowElement.vendor_code != "" && rowElement.container_number != "");
                    })
                
                this.storeRecord({"serial" : filterData}).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response);
                              self.exporDataSaving = false;
                              self.isSave = true;

                              self.spreadsheetexport.setData(response.serial)

                              const idsToPrint = response.serial_id.map(e=> e.id);
                                  self.generated_serial = idsToPrint.join(',');
                              /* if(response.serial_id.length > 0){
                                  const idsToPrint = response.serial_id.map(e=> e.id);
                                  self.generated_serial = idsToPrint.join(',');

                                  

                              } */
                            }).catch((error) => {
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       });
            }
        },

        downloadCsv(){
            this.spreadsheetexport.download(true);
        },
        destroyx(){
            // this.spreadsheetexport.destroy();
            //this.exportTableOptions.allowCopy = true
            /*  this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);
             this.spreadsheetexport.setWidth(window.innerWidth) */

             this.recreateSpreadsheet();
             this.exportTableOptions.allowCopy = true
             this.createPopupTable(this.exportTableOptions)
             
        },

        copyTable(){
            this.spreadsheetexport.copy()
        },

        createPopupTable(exportTableOptions){
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.exporData)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        fnPrint(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

            const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

               const win = window.open(`serial-label-print?page=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print() 
            }

            console.log(exporData)


           /*  */
        },
        fnPrintSmall(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";


             const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

              const win = window.open(`/showroom/sticker?items=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
            }

            
        },
        fnPrintSmaller(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";


             const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

              const win = window.open(`/showroom/sticker-small?items=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
            }

            
        }
    },
    computed: {
       
    },

    watch : {

        dialogIsOpen : function(v){
            if(v === false){
                // this.spreadsheetexport.setData([[]]);
                // this.spreadsheetexport = null;

                this.spreadsheetexport.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }
        }
    }

}
</script>