import { ApiService} from '../../services'
export const PicklistModule = {
    namespaced: true,
    state: {
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        postStoreLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date Request',
                    field: 'date',
                },
                {
                    label: 'Tracking No.',
                    field: 'series_display',
                    clickable : true
                },
                {
                    label: 'Invoice Date',
                    field: 'invoice_date',
                },
                {
                    label: 'Item Name',
                    field: 'item_name',
                },
                {
                    label: 'Customer Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Total Amount',
                    field: 'total',
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                },
                {
                    label: 'Remarks',
                    field: 'description',
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        tabIndex: 0,
        formRecord: {},

    
    },
    actions: {
   

        async getRecord({ state, commit }, params) {
            const { viewLoading } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`delivery/item/${params.token_id}`, params).then((response) => {


                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        // Unauthorized(dispatch, error)
                    })
                }
            })
        },
      
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const {postLoading} = state;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('delivery/picklist',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async makePicklistDeliveryNote({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const {postStoreLoading} = state;
                if (!postStoreLoading) {
                    commit('setPostStoreLoadingTrue')
                    ApiService.store('delivery/picklist-delivery-note',payload).then((response) => {
                        commit('setPostStoreLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostStoreLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

    
        updateRecord({}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.update(`picklist/update/${payload.series}`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        
        async cancelRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    ApiService.update(`picklist/cancel/${payload.series}`,payload).then((response) => {
                        commit('setDeleteLoadingFalse')
                        commit('setPutLoadingFalse')
                        commit('setLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        commit('setPutLoadingFalse')
                        commit('setLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
      
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPostStoreLoadingTrue: (state) => {
            state.postStoreLoading = true
        },
        setPostStoreLoadingFalse: (state) => {
            state.postStoreLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setTabIndex: (state, payload) => {
            state.tabIndex = payload
        },

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        tabIndex: state => state.tabIndex,
    }
}


