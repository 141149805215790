<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0 d-flex align-items-center">
                <mdb-date-picker-2 @getValue="getFromValue" :limit="limitDateRangeFrom" :key="2" :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="(show_error == true ? errors[0] : '')" :placeholder="$attrs.placeholder" :inputClass="[...classMerge,hasError(errors)]" v-model="rangeValue.from" :disabled="disabled" autocomplete="off" :readOnly="readOnly" noWrapper bg inline :is-valid="invalid" disabledFuture/>
                <div class="p-1"> - </div>
                <mdb-date-picker-2 @getValue="getToValue" :limit="limitDateRangeTo" :key="3" :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="(show_error == true ? errors[0] : '')" :placeholder="$attrs.placeholder" :inputClass="[...classMerge,hasError(errors)]" v-model="rangeValue.to" :disabled="disabled" autocomplete="off" :readOnly="readOnly" noWrapper bg inline :is-valid="invalid" disabledFuture />
            </div>

            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import mdbDatePicker2 from '../DatePicker2.vue'
export default {
    name : 'ThatsDatePicker',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },

        value: {
            type: Object,
            default : function(){
                return {
                    from : '',
                    to : ''
                }
            },
            required: true,
            validator: (prop) => 'from' in prop && 'to' in prop
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

       
        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputClass : {
            type : Array,
            default : function(){
                return ['==xxx=='];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel : {
            type : Boolean,
            default : true
        },
        orientation : {
            type : String,
            default : 'horizontal'
        },
        readOnly : {
            type : Boolean,
            default : false
        },

        limit : {
            type : Object,
            default : function(){
                return {
                    from : '',
                    to : ''
                }
            }
        }
    },
    components : {
        ValidationProvider,
        mdbDatePicker2 ,
    },

    data : ()=>({
        rangeValue : {
            from : '',
            to : ''},

        limitDateRangeFrom : [
            {
                type : 'fromto',
                from : '',
                to : ''
            }
        ],

        limitDateRangeTo : [
            {
                type : 'fromto',
                from : '',
                to : ''
            }
        ],
        
        
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            if(error.length > 0){
                this.inputClassErrors = ['is-invalid']
            }
            return (error.length > 0) ? 'is-invalid' : null
        },
        getFromValue(value){
            
            if (value) {
                if (this.limit.from && this.limit.to) {
                    if (value >= this.limit.from && value <= this.limit.to) {
                        this.rangeValue.from = value;
                        this.setDateRangeToLimit(value);
                        this.$emit('input',this.rangeValue);
                    } else {
                        this.rangeValue.from = this.limit.from;
                        
                        return;
                    }
                }
                
            }
            
            
        },
        getToValue(value){
            this.rangeValue.to = value;
            this.$emit('input',this.rangeValue);
        },

        setDateRangeToLimit(value){
            const toDate = new Date(value);
            toDate.setDate(toDate.getDate() - 1);
            const toLimitStart = toDate.toISOString().split('T')[0];
            this.limitDateRangeTo = [{
                type : 'fromto',from : toLimitStart,to : ''
            }]
        }
    },
    watch: {
        value: {
                handler(newValue) {
                    this.rangeValue = { ...newValue };
                    this.setDateRangeToLimit(newValue.from);
                    
                },
                deep: true,
        },
        limit : {
            handler(newValue){
                this.limitDateRangeFrom = [{
                    type : 'fromto',...newValue}]
            },
            deep : true
        }
           
    },
    created() {
        if (this.value != null) {
        this.inputValue = this.value;
        }else{
           var MyDate = new Date();
            var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.inputValue = date;
        }
    },

    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.orientation == 'horizontal'){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.orientation == 'horizontal'){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputClass.join(' ');
        },

        classMerge : function(){
            const styleClass = [];
                if(this.orientation == 'horizontal'){
                    styleClass.push("input-horizontal");
                }

            return [...this.inputClass,...styleClass,'w-100']
        }
    },
}

</script>