<template>
    <mdb-btn color="elegant" size="sm" :disabled="disabled" @click="emitClick" :icon="icon" :title="title">
        <template v-if="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
        </template>
        <template v-else>
            <template v-if="show  === 'text'">
                 {{text}}
            </template>
            
        </template>      
    </mdb-btn>           
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { mdbBtn,mdbIcon } from "mdbvue";
import { MessageBox } from 'element-ui';
export default {
    name : 'ThatsBtn',
    props : {
        disabled : {
            type : Boolean,
            default : false
        },
        loading : {
            type : Boolean,
            default : false
        },
        text : {
            type : String,
            default : 'Save'
        },
        icon : {
            type : String,
            default : null
        },
        show : {
            type : String,
            default : 'text'
        },
        title : {
            type : String,
            default : null
        },
    },
    components : {
        mdbBtn,
    },

    data : ()=>({
    }),
    methods : {
        emitClick(){
            if(!this.disabled){
                this.$emit('click')
            }
        }
    },
  
}

</script>