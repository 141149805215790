<template>
    <div class="image-grid-container">
      <hsc-window-style-metal>
      <hsc-window title="Gallery Manager" :resizable="false" :left="250" :top="50" :width="900" :height="675" :minWidth="400" :maxWidth="800" :minHeight="350" :maxHeight="450" :zGroup="group" :closeButton="true" :isOpen.sync="dialogIsOpen">
        <div class="scrollbar image-grid">
            <div class="image-container flex-container">
              <div class="image-item">
                  <mdb-card>
                  <mdb-view class="image-grid-view" hover>
                    <div class="centered">
              
                      <div class="image-list-item position-relative cursor-pointer display-flex justify-content-center align-items-center">
                            <svg class="icon add-image-svg" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M511.5 227.5h-227V.5h-57v227H-.5v57h228v228h57v-228h227z"></path></svg>
                            <div class="input-add-image position-absolute full-width full-height">
                            <label class="display-block full-width full-height cursor-pointer">
                            </label>
                            </div>
                        </div>
                    </div>

                    

                  </mdb-view>
              </mdb-card>

              </div>

              <template v-for="img in data">
              <div class="image-item" v-bind:key="img.token_id">
                <div class="custom-control image-checkbox custom-checkbox">
                    <input type="checkbox" class="custom-control-input" @change="onSelect" v-model="selected" :id="'imageCheckbox'+img.mdbID" :value="img.mdbID">
                    <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID"></label>
                </div>

                  <mdb-card>
                  <mdb-view class="image-grid-view" hover>
                    <div class="centered">
              
                      <img class="show-img img-responsive" :src="img.large">
                    </div>

                  </mdb-view>
                <mdb-card-body >
                  <p class="text-muted muted small">{{img.filename}}</p>
                </mdb-card-body>
              </mdb-card>

              </div>
              </template>
            </div>
        </div> 
    </hsc-window>
      </hsc-window-style-metal>
    </div>
</template>

<script>
import Vue from 'vue'

import { mdbInput,mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon, mdbFormInline } from 'mdbvue';

import {mapGetters,mapActions, mapMutations} from 'vuex'
import { MessageBox } from 'element-ui';

export default {
      name : "GalleryManager",
    components : { 

      mdbContainer,
      mdbRow,
      mdbCol,
      mdbCard,
      mdbCardImage,
      mdbCardHeader,
      mdbCardBody,
      mdbCardTitle,
      mdbCardText,
      mdbCardFooter,
      mdbCardUp,
      mdbCardAvatar,
      mdbCardGroup,
      mdbBtn,
      mdbView,
      mdbMask,
      mdbIcon,
      mdbFormInline,
      mdbInput,


    },
    props : {
      data : {
        type : Array,
      },
      group : {
            type : Number,
            default : 1
        },
    },
    data () {
      return {
        dialogIsOpen : false,
        selected : null,

      }
    },

    methods: {
       
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },


        EmitOpenModal(){
            this.$emit('select');
        },
        onSelect(e){
          this.selected = parseInt(e.target.value)
        },

    },

  }
</script>
<style scope>
.add-image-svg{
    width: 12px;
    height: 12px;
    fill: #222;
}

.image-list-container .image-list-item{
    height: 100px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    overflow: hidden;
}

</style>