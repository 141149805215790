<template>
    <LineChartGenerator
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="chartStyle"
        ref="lineChart"
    />
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
} from 'chart.js';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement
);

export default {
    name: 'LineGraph',
    components: {
        LineChartGenerator
    },
    props: {
        chartId: {
            type: String,
            default: 'line-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => ({
                width: '100%'
            })
        },
        chartData: {
            type: Object,
            default: () => ({})
        },
        plugins: {
            type: Array,
            default: () => []
        },
        toggle: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                tension: 0.3,
                borderWidth: 0.8
            }
        };
    },
    watch: {
        toggle() {
            const chartInstance = this.$refs.lineChart.getCurrentChart();
            chartInstance.data.datasets.forEach(ds => {
                ds.hidden = !ds.hidden;
            });
            chartInstance.update();
        }
    },
    computed: {
        chartStyle() {
            return {
                ...this.styles,
                height: this.height + 'px'
            };
        }
    }
};
</script>

<style scoped lang="scss">
.chart-container {
    flex-grow: 1;
    min-height: 0;

    > div {
        position: relative;
        height: 100%;
    }
}
</style>
