export default [
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 24,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 29,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 77,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 11,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 42,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 5,
        area: 12
    },
    {
        id: 4,
        name: "Home Accent & Decor",
        attributes: [
            {
                index: 3,
                name: "Boat Handicraft",
                slug: "boat-handicraft"
            },
            {
                index: 4,
                name: "Figurines",
                slug: "figurines"
            },
            {
                index: 9,
                name: "Vases & Jars",
                slug: "vases-and-jars"
            },
            {
                index: 10,
                name: "Book End \/ Holder",
                slug: "book-end-holder"
            },
            {
                index: 13,
                name: "Sculptures",
                slug: "sculptures"
            },
            {
                index: 1,
                name: "Box",
                slug: "box"
            },
            {
                index: 1,
                name: "Wall Paper",
                slug: "wall-paper"
            }
        ],
        brand: 7,
        area: 12
    },
    {
        id: 5,
        name: "Flowers & Plants",
        attributes: [
            {
                index: 5,
                name: "Artificial Flowers",
                slug: "artificial-flowers"
            },
            {
                index: 6,
                name: "Greenery",
                slug: "greenery"
            }
        ],
        brand: 13,
        area: 12
    },
    {
        id: 5,
        name: "Flowers & Plants",
        attributes: [
            {
                index: 5,
                name: "Artificial Flowers",
                slug: "artificial-flowers"
            },
            {
                index: 6,
                name: "Greenery",
                slug: "greenery"
            }
        ],
        brand: 27,
        area: 12
    },
    {
        id: 5,
        name: "Flowers & Plants",
        attributes: [
            {
                index: 5,
                name: "Artificial Flowers",
                slug: "artificial-flowers"
            },
            {
                index: 6,
                name: "Greenery",
                slug: "greenery"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 11,
        name: "Candles, Holders & Diffusers",
        attributes: [
            {
                index: 1,
                name: "Scented Candles | \u0634\u0645\u0639\u0629",
                slug: "scented-candles"
            },
            {
                index: 2,
                name: "Candle Holders | \u062d\u0627\u0645\u0644 \u0634\u0645\u0648\u0639",
                slug: "candle-holders"
            },
            {
                index: 1,
                name: "Fragrance Diffusers | \u0645\u0639\u0637\u0631 \u062c\u0648",
                slug: "fragrance-diffusers"
            },
            {
                index: 1,
                name: "Candle | \u0634\u0645\u0639\u0629",
                slug: "candle"
            },
            {
                index: 1,
                name: "Baobab Gift Box",
                slug: "baobab-gift-box"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 11,
        name: "Candles, Holders & Diffusers",
        attributes: [
            {
                index: 1,
                name: "Scented Candles | \u0634\u0645\u0639\u0629",
                slug: "scented-candles"
            },
            {
                index: 2,
                name: "Candle Holders | \u062d\u0627\u0645\u0644 \u0634\u0645\u0648\u0639",
                slug: "candle-holders"
            },
            {
                index: 1,
                name: "Fragrance Diffusers | \u0645\u0639\u0637\u0631 \u062c\u0648",
                slug: "fragrance-diffusers"
            },
            {
                index: 1,
                name: "Candle | \u0634\u0645\u0639\u0629",
                slug: "candle"
            },
            {
                index: 1,
                name: "Baobab Gift Box",
                slug: "baobab-gift-box"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 11,
        name: "Candles, Holders & Diffusers",
        attributes: [
            {
                index: 1,
                name: "Scented Candles | \u0634\u0645\u0639\u0629",
                slug: "scented-candles"
            },
            {
                index: 2,
                name: "Candle Holders | \u062d\u0627\u0645\u0644 \u0634\u0645\u0648\u0639",
                slug: "candle-holders"
            },
            {
                index: 1,
                name: "Fragrance Diffusers | \u0645\u0639\u0637\u0631 \u062c\u0648",
                slug: "fragrance-diffusers"
            },
            {
                index: 1,
                name: "Candle | \u0634\u0645\u0639\u0629",
                slug: "candle"
            },
            {
                index: 1,
                name: "Baobab Gift Box",
                slug: "baobab-gift-box"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 11,
        name: "Candles, Holders & Diffusers",
        attributes: [
            {
                index: 1,
                name: "Scented Candles | \u0634\u0645\u0639\u0629",
                slug: "scented-candles"
            },
            {
                index: 2,
                name: "Candle Holders | \u062d\u0627\u0645\u0644 \u0634\u0645\u0648\u0639",
                slug: "candle-holders"
            },
            {
                index: 1,
                name: "Fragrance Diffusers | \u0645\u0639\u0637\u0631 \u062c\u0648",
                slug: "fragrance-diffusers"
            },
            {
                index: 1,
                name: "Candle | \u0634\u0645\u0639\u0629",
                slug: "candle"
            },
            {
                index: 1,
                name: "Baobab Gift Box",
                slug: "baobab-gift-box"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 12,
        name: "Carpets & Rugs",
        attributes: [
            {
                index: 12,
                name: "30 x 30",
                slug: "30-x-30"
            },
            {
                index: 13,
                name: "45 x 45",
                slug: "45-x-45"
            },
            {
                index: 14,
                name: "60 x 90",
                slug: "60-x-90"
            },
            {
                index: 15,
                name: "60 x 100",
                slug: "60-x-100"
            },
            {
                index: 16,
                name: "100 x 100",
                slug: "100-x-100"
            },
            {
                index: 17,
                name: "100 x 150",
                slug: "100-x-150"
            },
            {
                index: 18,
                name: "120 x 180",
                slug: "120-x-180"
            },
            {
                index: 19,
                name: "130 x 190",
                slug: "130-x-190"
            },
            {
                index: 20,
                name: "152 x 244",
                slug: "152-x-244"
            },
            {
                index: 21,
                name: "160 x 230",
                slug: "160-x-230"
            },
            {
                index: 22,
                name: "170 x 240",
                slug: "170-x-240"
            },
            {
                index: 23,
                name: "200 x 280",
                slug: "200-x-280"
            },
            {
                index: 24,
                name: "200 x 300",
                slug: "200-x-300"
            },
            {
                index: 25,
                name: "200 x 350",
                slug: "200-x-350"
            },
            {
                index: 26,
                name: "210 x 240",
                slug: "210-x-240"
            },
            {
                index: 27,
                name: "240 x 240",
                slug: "240-x-240"
            },
            {
                index: 28,
                name: "240 x 300",
                slug: "240-x-300"
            },
            {
                index: 29,
                name: "250 x 320",
                slug: "250-x-320"
            },
            {
                index: 30,
                name: "250 x 350",
                slug: "250-x-350"
            },
            {
                index: 31,
                name: "250 x 380",
                slug: "250-x-380"
            },
            {
                index: 32,
                name: "255 x 380",
                slug: "255-x-380"
            },
            {
                index: 33,
                name: "260 x 390",
                slug: "260-x-390"
            },
            {
                index: 34,
                name: "270 x 360",
                slug: "270-x-360"
            },
            {
                index: 35,
                name: "280 x 330",
                slug: "280-x-330"
            },
            {
                index: 36,
                name: "280 x 360",
                slug: "280-x-360"
            },
            {
                index: 37,
                name: "280 x 380",
                slug: "280-x-380"
            },
            {
                index: 38,
                name: "300 x 350",
                slug: "300-x-350"
            },
            {
                index: 39,
                name: "300 x 390",
                slug: "300-x-390"
            },
            {
                index: 40,
                name: "300 x 400",
                slug: "300-x-400"
            },
            {
                index: 41,
                name: "300 x 420",
                slug: "300-x-420"
            },
            {
                index: 42,
                name: "340 x 300",
                slug: "340-x-300"
            },
            {
                index: 43,
                name: "350 x 350",
                slug: "350-x-350"
            },
            {
                index: 44,
                name: "400 x 500",
                slug: "400-x-500"
            },
            {
                index: 1,
                name: "287 x 400",
                slug: "287-x-400"
            },
            {
                index: 2,
                name: "230 x 230 (Circle)",
                slug: "230-x-230-circle"
            },
            {
                index: 1,
                name: "110 x 150",
                slug: "110-x-150"
            },
            {
                index: 1,
                name: "230 x 330",
                slug: "230-x-330"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 12,
        name: "Carpets & Rugs",
        attributes: [
            {
                index: 12,
                name: "30 x 30",
                slug: "30-x-30"
            },
            {
                index: 13,
                name: "45 x 45",
                slug: "45-x-45"
            },
            {
                index: 14,
                name: "60 x 90",
                slug: "60-x-90"
            },
            {
                index: 15,
                name: "60 x 100",
                slug: "60-x-100"
            },
            {
                index: 16,
                name: "100 x 100",
                slug: "100-x-100"
            },
            {
                index: 17,
                name: "100 x 150",
                slug: "100-x-150"
            },
            {
                index: 18,
                name: "120 x 180",
                slug: "120-x-180"
            },
            {
                index: 19,
                name: "130 x 190",
                slug: "130-x-190"
            },
            {
                index: 20,
                name: "152 x 244",
                slug: "152-x-244"
            },
            {
                index: 21,
                name: "160 x 230",
                slug: "160-x-230"
            },
            {
                index: 22,
                name: "170 x 240",
                slug: "170-x-240"
            },
            {
                index: 23,
                name: "200 x 280",
                slug: "200-x-280"
            },
            {
                index: 24,
                name: "200 x 300",
                slug: "200-x-300"
            },
            {
                index: 25,
                name: "200 x 350",
                slug: "200-x-350"
            },
            {
                index: 26,
                name: "210 x 240",
                slug: "210-x-240"
            },
            {
                index: 27,
                name: "240 x 240",
                slug: "240-x-240"
            },
            {
                index: 28,
                name: "240 x 300",
                slug: "240-x-300"
            },
            {
                index: 29,
                name: "250 x 320",
                slug: "250-x-320"
            },
            {
                index: 30,
                name: "250 x 350",
                slug: "250-x-350"
            },
            {
                index: 31,
                name: "250 x 380",
                slug: "250-x-380"
            },
            {
                index: 32,
                name: "255 x 380",
                slug: "255-x-380"
            },
            {
                index: 33,
                name: "260 x 390",
                slug: "260-x-390"
            },
            {
                index: 34,
                name: "270 x 360",
                slug: "270-x-360"
            },
            {
                index: 35,
                name: "280 x 330",
                slug: "280-x-330"
            },
            {
                index: 36,
                name: "280 x 360",
                slug: "280-x-360"
            },
            {
                index: 37,
                name: "280 x 380",
                slug: "280-x-380"
            },
            {
                index: 38,
                name: "300 x 350",
                slug: "300-x-350"
            },
            {
                index: 39,
                name: "300 x 390",
                slug: "300-x-390"
            },
            {
                index: 40,
                name: "300 x 400",
                slug: "300-x-400"
            },
            {
                index: 41,
                name: "300 x 420",
                slug: "300-x-420"
            },
            {
                index: 42,
                name: "340 x 300",
                slug: "340-x-300"
            },
            {
                index: 43,
                name: "350 x 350",
                slug: "350-x-350"
            },
            {
                index: 44,
                name: "400 x 500",
                slug: "400-x-500"
            },
            {
                index: 1,
                name: "287 x 400",
                slug: "287-x-400"
            },
            {
                index: 2,
                name: "230 x 230 (Circle)",
                slug: "230-x-230-circle"
            },
            {
                index: 1,
                name: "110 x 150",
                slug: "110-x-150"
            },
            {
                index: 1,
                name: "230 x 330",
                slug: "230-x-330"
            }
        ],
        brand: 52,
        area: 12
    },
    {
        id: 12,
        name: "Carpets & Rugs",
        attributes: [
            {
                index: 12,
                name: "30 x 30",
                slug: "30-x-30"
            },
            {
                index: 13,
                name: "45 x 45",
                slug: "45-x-45"
            },
            {
                index: 14,
                name: "60 x 90",
                slug: "60-x-90"
            },
            {
                index: 15,
                name: "60 x 100",
                slug: "60-x-100"
            },
            {
                index: 16,
                name: "100 x 100",
                slug: "100-x-100"
            },
            {
                index: 17,
                name: "100 x 150",
                slug: "100-x-150"
            },
            {
                index: 18,
                name: "120 x 180",
                slug: "120-x-180"
            },
            {
                index: 19,
                name: "130 x 190",
                slug: "130-x-190"
            },
            {
                index: 20,
                name: "152 x 244",
                slug: "152-x-244"
            },
            {
                index: 21,
                name: "160 x 230",
                slug: "160-x-230"
            },
            {
                index: 22,
                name: "170 x 240",
                slug: "170-x-240"
            },
            {
                index: 23,
                name: "200 x 280",
                slug: "200-x-280"
            },
            {
                index: 24,
                name: "200 x 300",
                slug: "200-x-300"
            },
            {
                index: 25,
                name: "200 x 350",
                slug: "200-x-350"
            },
            {
                index: 26,
                name: "210 x 240",
                slug: "210-x-240"
            },
            {
                index: 27,
                name: "240 x 240",
                slug: "240-x-240"
            },
            {
                index: 28,
                name: "240 x 300",
                slug: "240-x-300"
            },
            {
                index: 29,
                name: "250 x 320",
                slug: "250-x-320"
            },
            {
                index: 30,
                name: "250 x 350",
                slug: "250-x-350"
            },
            {
                index: 31,
                name: "250 x 380",
                slug: "250-x-380"
            },
            {
                index: 32,
                name: "255 x 380",
                slug: "255-x-380"
            },
            {
                index: 33,
                name: "260 x 390",
                slug: "260-x-390"
            },
            {
                index: 34,
                name: "270 x 360",
                slug: "270-x-360"
            },
            {
                index: 35,
                name: "280 x 330",
                slug: "280-x-330"
            },
            {
                index: 36,
                name: "280 x 360",
                slug: "280-x-360"
            },
            {
                index: 37,
                name: "280 x 380",
                slug: "280-x-380"
            },
            {
                index: 38,
                name: "300 x 350",
                slug: "300-x-350"
            },
            {
                index: 39,
                name: "300 x 390",
                slug: "300-x-390"
            },
            {
                index: 40,
                name: "300 x 400",
                slug: "300-x-400"
            },
            {
                index: 41,
                name: "300 x 420",
                slug: "300-x-420"
            },
            {
                index: 42,
                name: "340 x 300",
                slug: "340-x-300"
            },
            {
                index: 43,
                name: "350 x 350",
                slug: "350-x-350"
            },
            {
                index: 44,
                name: "400 x 500",
                slug: "400-x-500"
            },
            {
                index: 1,
                name: "287 x 400",
                slug: "287-x-400"
            },
            {
                index: 2,
                name: "230 x 230 (Circle)",
                slug: "230-x-230-circle"
            },
            {
                index: 1,
                name: "110 x 150",
                slug: "110-x-150"
            },
            {
                index: 1,
                name: "230 x 330",
                slug: "230-x-330"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 12,
        name: "Carpets & Rugs",
        attributes: [
            {
                index: 12,
                name: "30 x 30",
                slug: "30-x-30"
            },
            {
                index: 13,
                name: "45 x 45",
                slug: "45-x-45"
            },
            {
                index: 14,
                name: "60 x 90",
                slug: "60-x-90"
            },
            {
                index: 15,
                name: "60 x 100",
                slug: "60-x-100"
            },
            {
                index: 16,
                name: "100 x 100",
                slug: "100-x-100"
            },
            {
                index: 17,
                name: "100 x 150",
                slug: "100-x-150"
            },
            {
                index: 18,
                name: "120 x 180",
                slug: "120-x-180"
            },
            {
                index: 19,
                name: "130 x 190",
                slug: "130-x-190"
            },
            {
                index: 20,
                name: "152 x 244",
                slug: "152-x-244"
            },
            {
                index: 21,
                name: "160 x 230",
                slug: "160-x-230"
            },
            {
                index: 22,
                name: "170 x 240",
                slug: "170-x-240"
            },
            {
                index: 23,
                name: "200 x 280",
                slug: "200-x-280"
            },
            {
                index: 24,
                name: "200 x 300",
                slug: "200-x-300"
            },
            {
                index: 25,
                name: "200 x 350",
                slug: "200-x-350"
            },
            {
                index: 26,
                name: "210 x 240",
                slug: "210-x-240"
            },
            {
                index: 27,
                name: "240 x 240",
                slug: "240-x-240"
            },
            {
                index: 28,
                name: "240 x 300",
                slug: "240-x-300"
            },
            {
                index: 29,
                name: "250 x 320",
                slug: "250-x-320"
            },
            {
                index: 30,
                name: "250 x 350",
                slug: "250-x-350"
            },
            {
                index: 31,
                name: "250 x 380",
                slug: "250-x-380"
            },
            {
                index: 32,
                name: "255 x 380",
                slug: "255-x-380"
            },
            {
                index: 33,
                name: "260 x 390",
                slug: "260-x-390"
            },
            {
                index: 34,
                name: "270 x 360",
                slug: "270-x-360"
            },
            {
                index: 35,
                name: "280 x 330",
                slug: "280-x-330"
            },
            {
                index: 36,
                name: "280 x 360",
                slug: "280-x-360"
            },
            {
                index: 37,
                name: "280 x 380",
                slug: "280-x-380"
            },
            {
                index: 38,
                name: "300 x 350",
                slug: "300-x-350"
            },
            {
                index: 39,
                name: "300 x 390",
                slug: "300-x-390"
            },
            {
                index: 40,
                name: "300 x 400",
                slug: "300-x-400"
            },
            {
                index: 41,
                name: "300 x 420",
                slug: "300-x-420"
            },
            {
                index: 42,
                name: "340 x 300",
                slug: "340-x-300"
            },
            {
                index: 43,
                name: "350 x 350",
                slug: "350-x-350"
            },
            {
                index: 44,
                name: "400 x 500",
                slug: "400-x-500"
            },
            {
                index: 1,
                name: "287 x 400",
                slug: "287-x-400"
            },
            {
                index: 2,
                name: "230 x 230 (Circle)",
                slug: "230-x-230-circle"
            },
            {
                index: 1,
                name: "110 x 150",
                slug: "110-x-150"
            },
            {
                index: 1,
                name: "230 x 330",
                slug: "230-x-330"
            }
        ],
        brand: 59,
        area: 12
    },
    {
        id: 12,
        name: "Carpets & Rugs",
        attributes: [
            {
                index: 12,
                name: "30 x 30",
                slug: "30-x-30"
            },
            {
                index: 13,
                name: "45 x 45",
                slug: "45-x-45"
            },
            {
                index: 14,
                name: "60 x 90",
                slug: "60-x-90"
            },
            {
                index: 15,
                name: "60 x 100",
                slug: "60-x-100"
            },
            {
                index: 16,
                name: "100 x 100",
                slug: "100-x-100"
            },
            {
                index: 17,
                name: "100 x 150",
                slug: "100-x-150"
            },
            {
                index: 18,
                name: "120 x 180",
                slug: "120-x-180"
            },
            {
                index: 19,
                name: "130 x 190",
                slug: "130-x-190"
            },
            {
                index: 20,
                name: "152 x 244",
                slug: "152-x-244"
            },
            {
                index: 21,
                name: "160 x 230",
                slug: "160-x-230"
            },
            {
                index: 22,
                name: "170 x 240",
                slug: "170-x-240"
            },
            {
                index: 23,
                name: "200 x 280",
                slug: "200-x-280"
            },
            {
                index: 24,
                name: "200 x 300",
                slug: "200-x-300"
            },
            {
                index: 25,
                name: "200 x 350",
                slug: "200-x-350"
            },
            {
                index: 26,
                name: "210 x 240",
                slug: "210-x-240"
            },
            {
                index: 27,
                name: "240 x 240",
                slug: "240-x-240"
            },
            {
                index: 28,
                name: "240 x 300",
                slug: "240-x-300"
            },
            {
                index: 29,
                name: "250 x 320",
                slug: "250-x-320"
            },
            {
                index: 30,
                name: "250 x 350",
                slug: "250-x-350"
            },
            {
                index: 31,
                name: "250 x 380",
                slug: "250-x-380"
            },
            {
                index: 32,
                name: "255 x 380",
                slug: "255-x-380"
            },
            {
                index: 33,
                name: "260 x 390",
                slug: "260-x-390"
            },
            {
                index: 34,
                name: "270 x 360",
                slug: "270-x-360"
            },
            {
                index: 35,
                name: "280 x 330",
                slug: "280-x-330"
            },
            {
                index: 36,
                name: "280 x 360",
                slug: "280-x-360"
            },
            {
                index: 37,
                name: "280 x 380",
                slug: "280-x-380"
            },
            {
                index: 38,
                name: "300 x 350",
                slug: "300-x-350"
            },
            {
                index: 39,
                name: "300 x 390",
                slug: "300-x-390"
            },
            {
                index: 40,
                name: "300 x 400",
                slug: "300-x-400"
            },
            {
                index: 41,
                name: "300 x 420",
                slug: "300-x-420"
            },
            {
                index: 42,
                name: "340 x 300",
                slug: "340-x-300"
            },
            {
                index: 43,
                name: "350 x 350",
                slug: "350-x-350"
            },
            {
                index: 44,
                name: "400 x 500",
                slug: "400-x-500"
            },
            {
                index: 1,
                name: "287 x 400",
                slug: "287-x-400"
            },
            {
                index: 2,
                name: "230 x 230 (Circle)",
                slug: "230-x-230-circle"
            },
            {
                index: 1,
                name: "110 x 150",
                slug: "110-x-150"
            },
            {
                index: 1,
                name: "230 x 330",
                slug: "230-x-330"
            }
        ],
        brand: 7,
        area: 12
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 16,
        name: "Light Components",
        attributes: [
            {
                index: 2,
                name: "Glass",
                slug: "glass"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 3,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 5,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 8,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 9,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 10,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 11,
        area: 4
    },
    {
        id: 19,
        name: "Mini Lamps & Lantern",
        attributes: [],
        brand: 16,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 21,
        name: "Lamp Shades",
        attributes: [
            {
                index: 1,
                name: "Shades",
                slug: "shades"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 23,
        name: "Sconces & Wall Lamps",
        attributes: [
            {
                index: 1,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 2,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Antique Brass",
                slug: "antique-brass"
            },
            {
                index: 6,
                name: "Wall Lamp",
                slug: "wall-lamp"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 91,
        area: 4
    },
    {
        id: 24,
        name: "Table & Mini Lamps",
        attributes: [
            {
                index: 5,
                name: "3245KDMC030",
                slug: "3245kdmc030"
            },
            {
                index: 6,
                name: "3530",
                slug: "3530"
            },
            {
                index: 7,
                name: "8240KD135",
                slug: "8240kd135"
            },
            {
                index: 8,
                name: "8240KDMC037",
                slug: "8240kdmc037"
            },
            {
                index: 9,
                name: "9045KDM023",
                slug: "9045kdm023"
            },
            {
                index: 10,
                name: "YF3535",
                slug: "yf3535"
            },
            {
                index: 11,
                name: "4298",
                slug: "4298"
            },
            {
                index: 12,
                name: "3640KD106",
                slug: "3640kd106"
            },
            {
                index: 13,
                name: "3640KDINES030",
                slug: "3640kdines030"
            },
            {
                index: 14,
                name: "6138",
                slug: "6138"
            },
            {
                index: 15,
                name: "6655",
                slug: "6655"
            },
            {
                index: 16,
                name: "6655KD065",
                slug: "6655kd065"
            },
            {
                index: 17,
                name: "9045KD109",
                slug: "9045kd109"
            },
            {
                index: 1,
                name: "6630KD063",
                slug: "6630kd063"
            },
            {
                index: 1,
                name: "3240",
                slug: "3240"
            },
            {
                index: 1,
                name: "3235KDMC030",
                slug: "3235kdmc030"
            },
            {
                index: 1,
                name: "6630",
                slug: "6630"
            },
            {
                index: 2,
                name: "O3230",
                slug: "o3230"
            },
            {
                index: 3,
                name: "P3230",
                slug: "p3230"
            },
            {
                index: 4,
                name: "PF3230",
                slug: "pf3230"
            },
            {
                index: 5,
                name: "Y3230",
                slug: "y3230"
            },
            {
                index: 1,
                name: "3225KDMB012",
                slug: "3225kdmb012"
            },
            {
                index: 2,
                name: "3225KDMC030",
                slug: "3225kdmc030"
            },
            {
                index: 3,
                name: "B3225",
                slug: "b3225"
            },
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Table Lamp",
                slug: "table-lamp"
            },
            {
                index: 2,
                name: "Mini Lamp",
                slug: "mini-lamp"
            },
            {
                index: 1,
                name: "Black Shade",
                slug: "black-shade"
            },
            {
                index: 2,
                name: "White Shade",
                slug: "white-shade"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 91,
        area: 4
    },
    {
        id: 25,
        name: "Floor Lamps",
        attributes: [
            {
                index: 1,
                name: "Floor Lamp",
                slug: "floor-lamp"
            },
            {
                index: 2,
                name: "NBS",
                slug: "nbs"
            },
            {
                index: 3,
                name: "G8250",
                slug: "g8250"
            },
            {
                index: 4,
                name: "B8250",
                slug: "b8250"
            },
            {
                index: 5,
                name: "3545",
                slug: "3545"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 45,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 36,
        area: 4
    },
    {
        id: 26,
        name: "Chandeliers",
        attributes: [
            {
                index: 4,
                name: "Large",
                slug: "large"
            },
            {
                index: 5,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 6,
                name: "Small",
                slug: "small"
            },
            {
                index: 7,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 8,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 1,
                name: "Chrome",
                slug: "chrome"
            },
            {
                index: 2,
                name: "Nickel",
                slug: "nickel"
            },
            {
                index: 3,
                name: "Brass",
                slug: "brass"
            },
            {
                index: 5,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 59,
        area: 4
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 7,
        area: 2
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 27,
        name: "Bedroom Accessories",
        attributes: [
            {
                index: 11,
                name: "Quilts & Coverlets",
                slug: "quilts-and-coverlets"
            },
            {
                index: 12,
                name: "Sheets",
                slug: "sheets"
            },
            {
                index: 1,
                name: "Pillow Cases & Shams",
                slug: "pillow-cases-and-shams"
            },
            {
                index: 2,
                name: "Blankets & Throws",
                slug: "blankets-and-throws"
            },
            {
                index: 3,
                name: "Duvet Covers",
                slug: "duvet-covers"
            },
            {
                index: 4,
                name: "Bedding Bundles",
                slug: "bedding-bundles"
            },
            {
                index: 1,
                name: "Coatrack",
                slug: "coatrack"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 29,
        name: "Box Springs & Mattresses",
        attributes: [
            {
                index: 1,
                name: "Spring Box \/ \u0633\u0628\u0631\u064a\u0646\u063a \u0628\u0648\u0643\u0633",
                slug: "spring-box"
            },
            {
                index: 2,
                name: "Mattresses \/ \u0645\u0631\u062a\u0628\u0647",
                slug: "mattresses"
            }
        ],
        brand: 15,
        area: 2
    },
    {
        id: 29,
        name: "Box Springs & Mattresses",
        attributes: [
            {
                index: 1,
                name: "Spring Box \/ \u0633\u0628\u0631\u064a\u0646\u063a \u0628\u0648\u0643\u0633",
                slug: "spring-box"
            },
            {
                index: 2,
                name: "Mattresses \/ \u0645\u0631\u062a\u0628\u0647",
                slug: "mattresses"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 15,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 30,
        name: "Benches, Chair & Ottoman - Bedroom",
        attributes: [
            {
                index: 2,
                name: "Ottoman",
                slug: "ottoman"
            },
            {
                index: 2,
                name: "Benches",
                slug: "benches"
            },
            {
                index: 3,
                name: "Vanity Chair",
                slug: "vanity-chair"
            },
            {
                index: 7,
                name: "Make Up Stool",
                slug: "make-up-stool"
            }
        ],
        brand: 59,
        area: 2
    },
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 2,
        area: 2
    },
    
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 15,
        area: 2
    },
    
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 31,
        name: "Dressers",
        attributes: [
            {
                index: 7,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 8,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 9,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 10,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 11,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 12,
                name: "6-Drawers",
                slug: "6-drawers"
            },
            {
                index: 13,
                name: "7-Drawers",
                slug: "7-drawers"
            },
            {
                index: 14,
                name: "8-Drawers",
                slug: "8-drawers"
            },
            {
                index: 15,
                name: "9-Drawers",
                slug: "9-drawers"
            },
            {
                index: 1,
                name: "12-Drawers",
                slug: "12-drawers"
            }
        ],
        brand: 7,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 7,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 32,
        name: "Nightstands",
        attributes: [
            {
                index: 3,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 4,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 5,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 6,
                name: "4-Drawers",
                slug: "4-drawers"
            }
        ],
        brand: 63,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 7,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 33,
        name: "Armoire & Chests",
        attributes: [
            {
                index: 17,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 18,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 19,
                name: "3-Drawers",
                slug: "3-drawers"
            },
            {
                index: 20,
                name: "4-Drawers",
                slug: "4-drawers"
            },
            {
                index: 21,
                name: "5-Drawers",
                slug: "5-drawers"
            },
            {
                index: 22,
                name: "6-Drawers",
                slug: "6-drawers"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 1,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 3,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 7,
        area: 2
    },
    {
        id: 34,
        name: "Beds",
        attributes: [
            {
                index: 1,
                name: "Queen",
                slug: "queen"
            },
            {
                index: 2,
                name: "King",
                slug: "king"
            },
            {
                index: 1,
                name: "King-Black",
                slug: "king-black"
            },
            {
                index: 1,
                name: "Head Board",
                slug: "head-board"
            },
            {
                index: 2,
                name: "Foot Board",
                slug: "foot-board"
            },
            {
                index: 3,
                name: "Side Rail\/Support",
                slug: "side-rail-support"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 35,
        name: "Trolleys & Carts",
        attributes: [
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 4,
                name: "Brass",
                slug: "brass"
            }
        ],
        brand: 3,
        area: 3
    },
    {
        id: 35,
        name: "Trolleys & Carts",
        attributes: [
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 4,
                name: "Brass",
                slug: "brass"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 35,
        name: "Trolleys & Carts",
        attributes: [
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 4,
                name: "Brass",
                slug: "brass"
            }
        ],
        brand: 8,
        area: 3
    },
    {
        id: 35,
        name: "Trolleys & Carts",
        attributes: [
            {
                index: 3,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 4,
                name: "Brass",
                slug: "brass"
            }
        ],
        brand: 5,
        area: 3
    },
    {
        id: 36,
        name: "Display Cabinet - Dining",
        attributes: [],
        brand: 3,
        area: 3
    },
    {
        id: 36,
        name: "Display Cabinet - Dining",
        attributes: [],
        brand: 16,
        area: 3
    },
    {
        id: 37,
        name: "Bars & Counter Stools",
        attributes: [],
        brand: 3,
        area: 3
    },
    {
        id: 37,
        name: "Bars & Counter Stools",
        attributes: [],
        brand: 16,
        area: 3
    },
    {
        id: 37,
        name: "Bars & Counter Stools",
        attributes: [],
        brand: 8,
        area: 3
    },
    {
        id: 37,
        name: "Bars & Counter Stools",
        attributes: [],
        brand: 1,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 1,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 2,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 3,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 4,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 7,
        area: 3
    },
    {
        id: 38,
        name: "Sideboard & Buffets",
        attributes: [
            {
                index: 8,
                name: "Sideboard",
                slug: "sideboard"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 1,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 2,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 3,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 4,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 7,
        area: 3
    },
    {
        id: 39,
        name: "Dining Chairs",
        attributes: [
            {
                index: 1,
                name: "CHAIR",
                slug: "chair"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 1,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 2,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 3,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 4,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 7,
        area: 3
    },
    {
        id: 40,
        name: "Dining Tables",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 1,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 1,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 2,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 3,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 4,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 7,
        area: 3
    },
    {
        id: 41,
        name: "Dinning-Custom Made",
        attributes: [],
        brand: 16,
        area: 3
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 1,
        area: 1
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 2,
        area: 1
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 3,
        area: 1
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 4,
        area: 1
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 7,
        area: 1
    },
    {
        id: 42,
        name: "Living-Custom Upholstery",
        attributes: [],
        brand: 16,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 1,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 3,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 2,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 4,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 7,
        area: 1
    },
    {
        id: 44,
        name: "Storage & Display Cabinets",
        attributes: [],
        brand: 16,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 45,
        name: "Entertainment & TV Cabinets",
        attributes: [
            {
                index: 9,
                name: "TV Media Cabinet",
                slug: "tv-media-cabinet"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 46,
        name: "Benches & Ottoman - Living",
        attributes: [
            {
                index: 1,
                name: "IVORY",
                slug: "ivory"
            }
        ],
        brand: 8,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 47,
        name: "Chairs - Livingroom",
        attributes: [
            {
                index: 1,
                name: "Savona Nude",
                slug: "savona-nude"
            },
            {
                index: 2,
                name: "Clarck Sand",
                slug: "clarck-sand"
            },
            {
                index: 1,
                name: "Cameron Wine Red",
                slug: "cameron-wine-red"
            },
            {
                index: 1,
                name: "Mocha",
                slug: "mocha"
            },
            {
                index: 2,
                name: "Chair",
                slug: "chair"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 48,
        name: "Cocktail & Coffee Tables",
        attributes: [
            {
                index: 3,
                name: "Cocktail Table",
                slug: "cocktail-table"
            },
            {
                index: 4,
                name: "Coffee Table",
                slug: "coffee-table"
            },
            {
                index: 1,
                name: "Top Only",
                slug: "top-only"
            },
            {
                index: 2,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 1,
                name: "Serving Table",
                slug: "serving-table"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 49,
        name: "Side & End Tables",
        attributes: [
            {
                index: 2,
                name: "Side Table",
                slug: "side-table"
            },
            {
                index: 3,
                name: "End Table",
                slug: "end-table"
            },
            {
                index: 1,
                name: "Accent Table",
                slug: "accent-table"
            },
            {
                index: 1,
                name: "Base Only",
                slug: "base-only"
            },
            {
                index: 2,
                name: "Top Only",
                slug: "top-only"
            }
        ],
        brand: 8,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 1,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 2,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 3,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 4,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 7,
        area: 1
    },
    {
        id: 50,
        name: "Settees & Chaises",
        attributes: [],
        brand: 16,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 51,
        name: "Sectionals",
        attributes: [
            {
                index: 1,
                name: "Full set",
                slug: "full-set"
            },
            {
                index: 2,
                name: "Left",
                slug: "left"
            },
            {
                index: 3,
                name: "Right",
                slug: "right"
            },
            {
                index: 4,
                name: "Center",
                slug: "center"
            },
            {
                index: 5,
                name: "Corner",
                slug: "corner"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 52,
        name: "Loveseats & Sofas",
        attributes: [
            {
                index: 5,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 6,
                name: "Loveseat",
                slug: "loveseat"
            },
            {
                index: 1,
                name: "Sofa and Armchairs",
                slug: "sofa-and-armchairs"
            },
            {
                index: 3,
                name: "Right Arm",
                slug: "right-arm"
            },
            {
                index: 4,
                name: "Left Arm",
                slug: "left-arm"
            },
            {
                index: 5,
                name: "Center",
                slug: "center"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 53,
        name: "Antiques",
        attributes: [],
        brand: 16,
        area: 12
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 54,
        name: "Consoles & Desk",
        attributes: [
            {
                index: 1,
                name: "Gold",
                slug: "gold"
            },
            {
                index: 2,
                name: "Silver",
                slug: "silver"
            },
            {
                index: 5,
                name: "Pink\/Ivory",
                slug: "pink-ivory"
            },
            {
                index: 6,
                name: "Ivory\/Beige",
                slug: "ivory-beige"
            }
        ],
        brand: 63,
        area: 1
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 1,
        area: 3
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 3,
        area: 3
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 4,
        area: 3
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 7,
        area: 3
    },
    {
        id: 55,
        name: "Dining Kit Items",
        attributes: [
            {
                index: 1,
                name: "Table Top",
                slug: "table-top"
            },
            {
                index: 1,
                name: "Table Base",
                slug: "table-base"
            },
            {
                index: 3,
                name: "Stand Only",
                slug: "stand-only"
            }
        ],
        brand: 2,
        area: 3
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 1,
        area: 1
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 3,
        area: 1
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 4,
        area: 1
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 7,
        area: 1
    },
    {
        id: 56,
        name: "Living-Chest",
        attributes: [
            {
                index: 2,
                name: "1-Drawer",
                slug: "1-drawer"
            },
            {
                index: 3,
                name: "2-Drawers",
                slug: "2-drawers"
            },
            {
                index: 4,
                name: "3-Drawers",
                slug: "3-drawers"
            }
        ],
        brand: 2,
        area: 1
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 1,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 3,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 16,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 4,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 2,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 7,
        area: 8
    },
    {
        id: 57,
        name: "Bar & Accessories",
        attributes: [],
        brand: 8,
        area: 8
    },
    {
        id: 58,
        name: "Coffee & Tea Cups\/Saucers",
        attributes: [],
        brand: 49,
        area: 6
    },
    {
        id: 58,
        name: "Coffee & Tea Cups\/Saucers",
        attributes: [],
        brand: 6,
        area: 6
    },
    {
        id: 58,
        name: "Coffee & Tea Cups\/Saucers",
        attributes: [],
        brand: 42,
        area: 6
    },
    {
        id: 59,
        name: "Coffee & Tea Pots",
        attributes: [
            {
                index: 6,
                name: "100 CL",
                slug: "100-cl"
            },
            {
                index: 7,
                name: "45 CL",
                slug: "45-cl"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 59,
        name: "Coffee & Tea Pots",
        attributes: [
            {
                index: 6,
                name: "100 CL",
                slug: "100-cl"
            },
            {
                index: 7,
                name: "45 CL",
                slug: "45-cl"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 59,
        name: "Coffee & Tea Pots",
        attributes: [
            {
                index: 6,
                name: "100 CL",
                slug: "100-cl"
            },
            {
                index: 7,
                name: "45 CL",
                slug: "45-cl"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 12,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 16,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 8,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 60,
        name: "Bowl & Dishes",
        attributes: [
            {
                index: 1,
                name: "Bowl",
                slug: "bowl"
            }
        ],
        brand: 3,
        area: 6
    },
    {
        id: 61,
        name: "Tureens",
        attributes: [],
        brand: 6,
        area: 6
    },
    {
        id: 61,
        name: "Tureens",
        attributes: [],
        brand: 49,
        area: 6
    },
    {
        id: 62,
        name: "Platters",
        attributes: [
            {
                index: 3,
                name: "Starter Plate",
                slug: "starter-plate"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 62,
        name: "Platters",
        attributes: [
            {
                index: 3,
                name: "Starter Plate",
                slug: "starter-plate"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 62,
        name: "Platters",
        attributes: [
            {
                index: 3,
                name: "Starter Plate",
                slug: "starter-plate"
            }
        ],
        brand: 86,
        area: 6
    },
    {
        id: 62,
        name: "Platters",
        attributes: [
            {
                index: 3,
                name: "Starter Plate",
                slug: "starter-plate"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 63,
        name: "Dinner Plates",
        attributes: [
            {
                index: 1,
                name: "Plates",
                slug: "plates"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 63,
        name: "Dinner Plates",
        attributes: [
            {
                index: 1,
                name: "Plates",
                slug: "plates"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 63,
        name: "Dinner Plates",
        attributes: [
            {
                index: 1,
                name: "Plates",
                slug: "plates"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 64,
        name: "Glass Containers",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 64,
        name: "Glass Containers",
        attributes: [],
        brand: 69,
        area: 7
    },
    {
        id: 64,
        name: "Glass Containers",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 64,
        name: "Glass Containers",
        attributes: [],
        brand: 12,
        area: 7
    },
    {
        id: 64,
        name: "Glass Containers",
        attributes: [],
        brand: 49,
        area: 7
    },
    {
        id: 65,
        name: "Decanters",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 65,
        name: "Decanters",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 65,
        name: "Decanters",
        attributes: [],
        brand: 69,
        area: 7
    },
    {
        id: 65,
        name: "Decanters",
        attributes: [],
        brand: 16,
        area: 7
    },
    {
        id: 65,
        name: "Decanters",
        attributes: [],
        brand: 3,
        area: 7
    },
    {
        id: 66,
        name: "Goblets",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 66,
        name: "Goblets",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 66,
        name: "Goblets",
        attributes: [],
        brand: 69,
        area: 7
    },
    {
        id: 66,
        name: "Goblets",
        attributes: [],
        brand: 12,
        area: 7
    },
    {
        id: 67,
        name: "Flutes",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 67,
        name: "Flutes",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 67,
        name: "Flutes",
        attributes: [],
        brand: 69,
        area: 7
    },
    {
        id: 67,
        name: "Flutes",
        attributes: [],
        brand: 12,
        area: 7
    },
    {
        id: 68,
        name: "Cups & Mugs",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 68,
        name: "Cups & Mugs",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 68,
        name: "Cups & Mugs",
        attributes: [],
        brand: 69,
        area: 7
    },
    {
        id: 68,
        name: "Cups & Mugs",
        attributes: [],
        brand: 79,
        area: 7
    },
    {
        id: 68,
        name: "Cups & Mugs",
        attributes: [],
        brand: 86,
        area: 7
    },
    {
        id: 69,
        name: "Water Glasses",
        attributes: [
            {
                index: 2,
                name: "PITCHER",
                slug: "pitcher"
            }
        ],
        brand: 36,
        area: 7
    },
    {
        id: 69,
        name: "Water Glasses",
        attributes: [
            {
                index: 2,
                name: "PITCHER",
                slug: "pitcher"
            }
        ],
        brand: 6,
        area: 7
    },
    {
        id: 69,
        name: "Water Glasses",
        attributes: [
            {
                index: 2,
                name: "PITCHER",
                slug: "pitcher"
            }
        ],
        brand: 69,
        area: 7
    },
    {
        id: 69,
        name: "Water Glasses",
        attributes: [
            {
                index: 2,
                name: "PITCHER",
                slug: "pitcher"
            }
        ],
        brand: 12,
        area: 7
    },
    {
        id: 69,
        name: "Water Glasses",
        attributes: [
            {
                index: 2,
                name: "PITCHER",
                slug: "pitcher"
            }
        ],
        brand: 83,
        area: 7
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 6,
        area: 8
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 16,
        area: 8
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 8,
        area: 8
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 49,
        area: 8
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 3,
        area: 8
    },
    {
        id: 70,
        name: "Dining Trays",
        attributes: [
            {
                index: 1,
                name: "Size 1",
                slug: "size-1"
            },
            {
                index: 2,
                name: "Size 2",
                slug: "size-2"
            },
            {
                index: 3,
                name: "Size 3",
                slug: "size-3"
            },
            {
                index: 4,
                name: "Size 4",
                slug: "size-4"
            },
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Small",
                slug: "small"
            },
            {
                index: 3,
                name: "Titanium Gold",
                slug: "titanium-gold"
            },
            {
                index: 4,
                name: "Chrome",
                slug: "chrome"
            }
        ],
        brand: 7,
        area: 8
    },
    {
        id: 71,
        name: "Containers",
        attributes: [],
        brand: 6,
        area: 8
    },
    {
        id: 71,
        name: "Containers",
        attributes: [],
        brand: 16,
        area: 8
    },
    {
        id: 71,
        name: "Containers",
        attributes: [],
        brand: 49,
        area: 8
    },
    {
        id: 71,
        name: "Containers",
        attributes: [],
        brand: 3,
        area: 8
    },
    {
        id: 72,
        name: "Buckets",
        attributes: [],
        brand: 6,
        area: 8
    },
    {
        id: 72,
        name: "Buckets",
        attributes: [],
        brand: 16,
        area: 8
    },
    {
        id: 72,
        name: "Buckets",
        attributes: [],
        brand: 3,
        area: 8
    },
    {
        id: 73,
        name: "Serving Set",
        attributes: [
            {
                index: 1,
                name: "Salad Set",
                slug: "salad-set"
            },
            {
                index: 2,
                name: "Cake Set",
                slug: "cake-set"
            },
            {
                index: 2,
                name: "Cheese Cutter Set",
                slug: "cheese-cutter-set"
            }
        ],
        brand: 68,
        area: 11
    },
    {
        id: 73,
        name: "Serving Set",
        attributes: [
            {
                index: 1,
                name: "Salad Set",
                slug: "salad-set"
            },
            {
                index: 2,
                name: "Cake Set",
                slug: "cake-set"
            },
            {
                index: 2,
                name: "Cheese Cutter Set",
                slug: "cheese-cutter-set"
            }
        ],
        brand: 41,
        area: 11
    },
    {
        id: 73,
        name: "Serving Set",
        attributes: [
            {
                index: 1,
                name: "Salad Set",
                slug: "salad-set"
            },
            {
                index: 2,
                name: "Cake Set",
                slug: "cake-set"
            },
            {
                index: 2,
                name: "Cheese Cutter Set",
                slug: "cheese-cutter-set"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 73,
        name: "Serving Set",
        attributes: [
            {
                index: 1,
                name: "Salad Set",
                slug: "salad-set"
            },
            {
                index: 2,
                name: "Cake Set",
                slug: "cake-set"
            },
            {
                index: 2,
                name: "Cheese Cutter Set",
                slug: "cheese-cutter-set"
            }
        ],
        brand: 16,
        area: 11
    },
    {
        id: 73,
        name: "Serving Set",
        attributes: [
            {
                index: 1,
                name: "Salad Set",
                slug: "salad-set"
            },
            {
                index: 2,
                name: "Cake Set",
                slug: "cake-set"
            },
            {
                index: 2,
                name: "Cheese Cutter Set",
                slug: "cheese-cutter-set"
            }
        ],
        brand: 3,
        area: 11
    },
    {
        id: 74,
        name: "Dinner Knife Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 1,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 41,
        area: 11
    },
    {
        id: 74,
        name: "Dinner Knife Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 1,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 74,
        name: "Dinner Knife Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 1,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 68,
        area: 11
    },
    {
        id: 74,
        name: "Dinner Knife Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 1,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 16,
        area: 11
    },
    {
        id: 75,
        name: "Dinner Fork Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 4,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 41,
        area: 11
    },
    {
        id: 75,
        name: "Dinner Fork Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 4,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 75,
        name: "Dinner Fork Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 4,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 68,
        area: 11
    },
    {
        id: 75,
        name: "Dinner Fork Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 4,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 16,
        area: 11
    },
    {
        id: 76,
        name: "Dinner Spoon Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 3,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 41,
        area: 11
    },
    {
        id: 76,
        name: "Dinner Spoon Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 3,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 76,
        name: "Dinner Spoon Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 3,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 68,
        area: 11
    },
    {
        id: 76,
        name: "Dinner Spoon Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 3,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 16,
        area: 11
    },
    {
        id: 76,
        name: "Dinner Spoon Set",
        attributes: [
            {
                index: 1,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 2,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 3,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 4,
                name: "White",
                slug: "white"
            },
            {
                index: 3,
                name: "Black",
                slug: "black"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 74,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 88,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 98,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 74,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 46,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 77,
        name: "Arts & Frames",
        attributes: [
            {
                index: 1,
                name: "Arts",
                slug: "arts"
            },
            {
                index: 2,
                name: "Picture Frames",
                slug: "picture-frames"
            },
            {
                index: 3,
                name: "Sculptures",
                slug: "sculptures"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 31,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 43,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 18,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 11,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 12,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 49,
        area: 12
    },
    {
        id: 78,
        name: "Vases & Jars",
        attributes: [
            {
                index: 4,
                name: "Vases",
                slug: "vases"
            },
            {
                index: 5,
                name: "Jars",
                slug: "jars"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 80,
        name: "Paintings",
        attributes: [],
        brand: 63,
        area: 12
    },
    {
        id: 80,
        name: "Paintings",
        attributes: [],
        brand: 97,
        area: 12
    },
    {
        id: 80,
        name: "Paintings",
        attributes: [],
        brand: 16,
        area: 12
    },
    {
        id: 80,
        name: "Paintings",
        attributes: [],
        brand: 3,
        area: 12
    },
    {
        id: 81,
        name: "Agate Stones",
        attributes: [],
        brand: 101,
        area: 12
    },
    {
        id: 81,
        name: "Agate Stones",
        attributes: [],
        brand: 6,
        area: 12
    },
    {
        id: 82,
        name: "Placemat & Napkins",
        attributes: [
            {
                index: 6,
                name: "Placemat",
                slug: "placemat"
            },
            {
                index: 7,
                name: "Napkins",
                slug: "napkins"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 82,
        name: "Placemat & Napkins",
        attributes: [
            {
                index: 6,
                name: "Placemat",
                slug: "placemat"
            },
            {
                index: 7,
                name: "Napkins",
                slug: "napkins"
            }
        ],
        brand: 95,
        area: 6
    },
    {
        id: 82,
        name: "Placemat & Napkins",
        attributes: [
            {
                index: 6,
                name: "Placemat",
                slug: "placemat"
            },
            {
                index: 7,
                name: "Napkins",
                slug: "napkins"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 82,
        name: "Placemat & Napkins",
        attributes: [
            {
                index: 6,
                name: "Placemat",
                slug: "placemat"
            },
            {
                index: 7,
                name: "Napkins",
                slug: "napkins"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 82,
        name: "Placemat & Napkins",
        attributes: [
            {
                index: 6,
                name: "Placemat",
                slug: "placemat"
            },
            {
                index: 7,
                name: "Napkins",
                slug: "napkins"
            }
        ],
        brand: 7,
        area: 6
    },
    {
        id: 83,
        name: "Upholstery Furniture",
        attributes: [
            {
                index: 14,
                name: "Ottoman & Benches",
                slug: "ottoman-and-benches"
            },
            {
                index: 15,
                name: "Sofa & Loveseats",
                slug: "sofa-and-loveseats"
            },
            {
                index: 16,
                name: "Chairs",
                slug: "chairs"
            },
            {
                index: 1,
                name: "Pillows",
                slug: "pillows"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 83,
        name: "Upholstery Furniture",
        attributes: [
            {
                index: 14,
                name: "Ottoman & Benches",
                slug: "ottoman-and-benches"
            },
            {
                index: 15,
                name: "Sofa & Loveseats",
                slug: "sofa-and-loveseats"
            },
            {
                index: 16,
                name: "Chairs",
                slug: "chairs"
            },
            {
                index: 1,
                name: "Pillows",
                slug: "pillows"
            }
        ],
        brand: 20,
        area: 9
    },
    {
        id: 83,
        name: "Upholstery Furniture",
        attributes: [
            {
                index: 14,
                name: "Ottoman & Benches",
                slug: "ottoman-and-benches"
            },
            {
                index: 15,
                name: "Sofa & Loveseats",
                slug: "sofa-and-loveseats"
            },
            {
                index: 16,
                name: "Chairs",
                slug: "chairs"
            },
            {
                index: 1,
                name: "Pillows",
                slug: "pillows"
            }
        ],
        brand: 51,
        area: 9
    },
    {
        id: 83,
        name: "Upholstery Furniture",
        attributes: [
            {
                index: 14,
                name: "Ottoman & Benches",
                slug: "ottoman-and-benches"
            },
            {
                index: 15,
                name: "Sofa & Loveseats",
                slug: "sofa-and-loveseats"
            },
            {
                index: 16,
                name: "Chairs",
                slug: "chairs"
            },
            {
                index: 1,
                name: "Pillows",
                slug: "pillows"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 83,
        name: "Upholstery Furniture",
        attributes: [
            {
                index: 14,
                name: "Ottoman & Benches",
                slug: "ottoman-and-benches"
            },
            {
                index: 15,
                name: "Sofa & Loveseats",
                slug: "sofa-and-loveseats"
            },
            {
                index: 16,
                name: "Chairs",
                slug: "chairs"
            },
            {
                index: 1,
                name: "Pillows",
                slug: "pillows"
            }
        ],
        brand: 2,
        area: 9
    },
    {
        id: 84,
        name: "Bedding Sets",
        attributes: [
            {
                index: 1,
                name: "Comforter Set",
                slug: "comforter-set"
            },
            {
                index: 2,
                name: "King \/ Queen Quilt Set",
                slug: "king-queen-quilt-set"
            },
            {
                index: 3,
                name: "King \/ Queen Duvet Set",
                slug: "king-queen-duvet-set"
            },
            {
                index: 17,
                name: "Twin Quilt Set",
                slug: "twin-quilt-set"
            },
            {
                index: 18,
                name: "Twin Duvet Set",
                slug: "twin-duvet-set"
            },
            {
                index: 19,
                name: "Full Bedding Set",
                slug: "full-bedding-set"
            },
            {
                index: 1,
                name: "With Pillow Insert",
                slug: "with-pillow-insert"
            }
        ],
        brand: 20,
        area: 9
    },
    {
        id: 84,
        name: "Bedding Sets",
        attributes: [
            {
                index: 1,
                name: "Comforter Set",
                slug: "comforter-set"
            },
            {
                index: 2,
                name: "King \/ Queen Quilt Set",
                slug: "king-queen-quilt-set"
            },
            {
                index: 3,
                name: "King \/ Queen Duvet Set",
                slug: "king-queen-duvet-set"
            },
            {
                index: 17,
                name: "Twin Quilt Set",
                slug: "twin-quilt-set"
            },
            {
                index: 18,
                name: "Twin Duvet Set",
                slug: "twin-duvet-set"
            },
            {
                index: 19,
                name: "Full Bedding Set",
                slug: "full-bedding-set"
            },
            {
                index: 1,
                name: "With Pillow Insert",
                slug: "with-pillow-insert"
            }
        ],
        brand: 51,
        area: 9
    },
    {
        id: 84,
        name: "Bedding Sets",
        attributes: [
            {
                index: 1,
                name: "Comforter Set",
                slug: "comforter-set"
            },
            {
                index: 2,
                name: "King \/ Queen Quilt Set",
                slug: "king-queen-quilt-set"
            },
            {
                index: 3,
                name: "King \/ Queen Duvet Set",
                slug: "king-queen-duvet-set"
            },
            {
                index: 17,
                name: "Twin Quilt Set",
                slug: "twin-quilt-set"
            },
            {
                index: 18,
                name: "Twin Duvet Set",
                slug: "twin-duvet-set"
            },
            {
                index: 19,
                name: "Full Bedding Set",
                slug: "full-bedding-set"
            },
            {
                index: 1,
                name: "With Pillow Insert",
                slug: "with-pillow-insert"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 84,
        name: "Bedding Sets",
        attributes: [
            {
                index: 1,
                name: "Comforter Set",
                slug: "comforter-set"
            },
            {
                index: 2,
                name: "King \/ Queen Quilt Set",
                slug: "king-queen-quilt-set"
            },
            {
                index: 3,
                name: "King \/ Queen Duvet Set",
                slug: "king-queen-duvet-set"
            },
            {
                index: 17,
                name: "Twin Quilt Set",
                slug: "twin-quilt-set"
            },
            {
                index: 18,
                name: "Twin Duvet Set",
                slug: "twin-duvet-set"
            },
            {
                index: 19,
                name: "Full Bedding Set",
                slug: "full-bedding-set"
            },
            {
                index: 1,
                name: "With Pillow Insert",
                slug: "with-pillow-insert"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 85,
        name: "Curtain & Shades",
        attributes: [
            {
                index: 4,
                name: "Curtains",
                slug: "Blackout"
            },
            {
                index: 5,
                name: "Sheer",
                slug: "Sheer"
            },
            {
                index: 6,
                name: "Blackouts",
                slug: "Outdoor"
            },
            {
                index: 7,
                name: "Shades",
                slug: "Shades"
            },
            {
                index: 12,
                name: "Borders",
                slug: "Blackouts"
            },
            {
                index: 13,
                name: "Outdoor",
                slug: "Hardware--Accessories"
            },
            {
                index: 1,
                name: "Curtain Accessories",
                slug: "Curtain-Accessories"
            }
        ],
        brand: 20,
        area: 9
    },
    {
        id: 85,
        name: "Curtain & Shades",
        attributes: [
            {
                index: 4,
                name: "Curtains",
                slug: "Blackout"
            },
            {
                index: 5,
                name: "Sheer",
                slug: "Sheer"
            },
            {
                index: 6,
                name: "Blackouts",
                slug: "Outdoor"
            },
            {
                index: 7,
                name: "Shades",
                slug: "Shades"
            },
            {
                index: 12,
                name: "Borders",
                slug: "Blackouts"
            },
            {
                index: 13,
                name: "Outdoor",
                slug: "Hardware--Accessories"
            },
            {
                index: 1,
                name: "Curtain Accessories",
                slug: "Curtain-Accessories"
            }
        ],
        brand: 51,
        area: 9
    },
    {
        id: 85,
        name: "Curtain & Shades",
        attributes: [
            {
                index: 4,
                name: "Curtains",
                slug: "Blackout"
            },
            {
                index: 5,
                name: "Sheer",
                slug: "Sheer"
            },
            {
                index: 6,
                name: "Blackouts",
                slug: "Outdoor"
            },
            {
                index: 7,
                name: "Shades",
                slug: "Shades"
            },
            {
                index: 12,
                name: "Borders",
                slug: "Blackouts"
            },
            {
                index: 13,
                name: "Outdoor",
                slug: "Hardware--Accessories"
            },
            {
                index: 1,
                name: "Curtain Accessories",
                slug: "Curtain-Accessories"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 85,
        name: "Curtain & Shades",
        attributes: [
            {
                index: 4,
                name: "Curtains",
                slug: "Blackout"
            },
            {
                index: 5,
                name: "Sheer",
                slug: "Sheer"
            },
            {
                index: 6,
                name: "Blackouts",
                slug: "Outdoor"
            },
            {
                index: 7,
                name: "Shades",
                slug: "Shades"
            },
            {
                index: 12,
                name: "Borders",
                slug: "Blackouts"
            },
            {
                index: 13,
                name: "Outdoor",
                slug: "Hardware--Accessories"
            },
            {
                index: 1,
                name: "Curtain Accessories",
                slug: "Curtain-Accessories"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 87,
        name: "Curtain Hardware",
        attributes: [
            {
                index: 20,
                name: "Curtain Hardware",
                slug: "curtain-hardware"
            },
            {
                index: 21,
                name: "Curtain Accessories",
                slug: "curtain-accessories"
            },
            {
                index: 1,
                name: "Holdback",
                slug: "holdback"
            },
            {
                index: 2,
                name: "Tieback",
                slug: "tieback"
            }
        ],
        brand: 20,
        area: 9
    },
    {
        id: 87,
        name: "Curtain Hardware",
        attributes: [
            {
                index: 20,
                name: "Curtain Hardware",
                slug: "curtain-hardware"
            },
            {
                index: 21,
                name: "Curtain Accessories",
                slug: "curtain-accessories"
            },
            {
                index: 1,
                name: "Holdback",
                slug: "holdback"
            },
            {
                index: 2,
                name: "Tieback",
                slug: "tieback"
            }
        ],
        brand: 51,
        area: 9
    },
    {
        id: 87,
        name: "Curtain Hardware",
        attributes: [
            {
                index: 20,
                name: "Curtain Hardware",
                slug: "curtain-hardware"
            },
            {
                index: 21,
                name: "Curtain Accessories",
                slug: "curtain-accessories"
            },
            {
                index: 1,
                name: "Holdback",
                slug: "holdback"
            },
            {
                index: 2,
                name: "Tieback",
                slug: "tieback"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 87,
        name: "Curtain Hardware",
        attributes: [
            {
                index: 20,
                name: "Curtain Hardware",
                slug: "curtain-hardware"
            },
            {
                index: 21,
                name: "Curtain Accessories",
                slug: "curtain-accessories"
            },
            {
                index: 1,
                name: "Holdback",
                slug: "holdback"
            },
            {
                index: 2,
                name: "Tieback",
                slug: "tieback"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 89,
        name: "Duvet & Covers",
        attributes: [],
        brand: 63,
        area: 9
    },
    {
        id: 89,
        name: "Duvet & Covers",
        attributes: [],
        brand: 59,
        area: 9
    },
    {
        id: 89,
        name: "Duvet & Covers",
        attributes: [],
        brand: 16,
        area: 9
    },
    {
        id: 90,
        name: "Quilts & Coverlets",
        attributes: [],
        brand: 63,
        area: 9
    },
    {
        id: 90,
        name: "Quilts & Coverlets",
        attributes: [],
        brand: 16,
        area: 9
    },
    {
        id: 92,
        name: "Bed Sheets & Blankets",
        attributes: [
            {
                index: 3,
                name: "Comforter",
                slug: "comforter"
            },
            {
                index: 4,
                name: "Blanket",
                slug: "blanket"
            }
        ],
        brand: 63,
        area: 2
    },
    {
        id: 92,
        name: "Bed Sheets & Blankets",
        attributes: [
            {
                index: 3,
                name: "Comforter",
                slug: "comforter"
            },
            {
                index: 4,
                name: "Blanket",
                slug: "blanket"
            }
        ],
        brand: 16,
        area: 2
    },
    {
        id: 92,
        name: "Bed Sheets & Blankets",
        attributes: [
            {
                index: 3,
                name: "Comforter",
                slug: "comforter"
            },
            {
                index: 4,
                name: "Blanket",
                slug: "blanket"
            }
        ],
        brand: 2,
        area: 2
    },
    {
        id: 92,
        name: "Bed Sheets & Blankets",
        attributes: [
            {
                index: 3,
                name: "Comforter",
                slug: "comforter"
            },
            {
                index: 4,
                name: "Blanket",
                slug: "blanket"
            }
        ],
        brand: 4,
        area: 2
    },
    {
        id: 95,
        name: "Upholstery Pillows & Cushions",
        attributes: [
            {
                index: 1,
                name: "Throw Pillow",
                slug: "throw-pillow"
            },
            {
                index: 2,
                name: "Lumbar Pillows",
                slug: "lumbar-pillows"
            },
            {
                index: 3,
                name: "Floor Pillows",
                slug: "floor-pillows"
            },
            {
                index: 4,
                name: "Pillow Cover",
                slug: "pillow-cover"
            },
            {
                index: 5,
                name: "Designers Pillow",
                slug: "designers-pillow"
            },
            {
                index: 1,
                name: "Cushion Fabrics",
                slug: "cushion-fabrics"
            },
            {
                index: 1,
                name: "Stuff Toys",
                slug: "stuff-toys"
            },
            {
                index: 1,
                name: "Fabric",
                slug: "fabric"
            }
        ],
        brand: 3,
        area: 9
    },
    {
        id: 95,
        name: "Upholstery Pillows & Cushions",
        attributes: [
            {
                index: 1,
                name: "Throw Pillow",
                slug: "throw-pillow"
            },
            {
                index: 2,
                name: "Lumbar Pillows",
                slug: "lumbar-pillows"
            },
            {
                index: 3,
                name: "Floor Pillows",
                slug: "floor-pillows"
            },
            {
                index: 4,
                name: "Pillow Cover",
                slug: "pillow-cover"
            },
            {
                index: 5,
                name: "Designers Pillow",
                slug: "designers-pillow"
            },
            {
                index: 1,
                name: "Cushion Fabrics",
                slug: "cushion-fabrics"
            },
            {
                index: 1,
                name: "Stuff Toys",
                slug: "stuff-toys"
            },
            {
                index: 1,
                name: "Fabric",
                slug: "fabric"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 95,
        name: "Upholstery Pillows & Cushions",
        attributes: [
            {
                index: 1,
                name: "Throw Pillow",
                slug: "throw-pillow"
            },
            {
                index: 2,
                name: "Lumbar Pillows",
                slug: "lumbar-pillows"
            },
            {
                index: 3,
                name: "Floor Pillows",
                slug: "floor-pillows"
            },
            {
                index: 4,
                name: "Pillow Cover",
                slug: "pillow-cover"
            },
            {
                index: 5,
                name: "Designers Pillow",
                slug: "designers-pillow"
            },
            {
                index: 1,
                name: "Cushion Fabrics",
                slug: "cushion-fabrics"
            },
            {
                index: 1,
                name: "Stuff Toys",
                slug: "stuff-toys"
            },
            {
                index: 1,
                name: "Fabric",
                slug: "fabric"
            }
        ],
        brand: 59,
        area: 9
    },
    {
        id: 95,
        name: "Upholstery Pillows & Cushions",
        attributes: [
            {
                index: 1,
                name: "Throw Pillow",
                slug: "throw-pillow"
            },
            {
                index: 2,
                name: "Lumbar Pillows",
                slug: "lumbar-pillows"
            },
            {
                index: 3,
                name: "Floor Pillows",
                slug: "floor-pillows"
            },
            {
                index: 4,
                name: "Pillow Cover",
                slug: "pillow-cover"
            },
            {
                index: 5,
                name: "Designers Pillow",
                slug: "designers-pillow"
            },
            {
                index: 1,
                name: "Cushion Fabrics",
                slug: "cushion-fabrics"
            },
            {
                index: 1,
                name: "Stuff Toys",
                slug: "stuff-toys"
            },
            {
                index: 1,
                name: "Fabric",
                slug: "fabric"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 96,
        name: "Wallpapers",
        attributes: [],
        brand: 3,
        area: 12
    },
    {
        id: 96,
        name: "Wallpapers",
        attributes: [],
        brand: 6,
        area: 12
    },
    {
        id: 96,
        name: "Wallpapers",
        attributes: [],
        brand: 16,
        area: 12
    },
    {
        id: 96,
        name: "Wallpapers",
        attributes: [],
        brand: 20,
        area: 12
    },
    {
        id: 97,
        name: "Dinner Sets",
        attributes: [
            {
                index: 3,
                name: "Set of 68",
                slug: "set-of-68"
            },
            {
                index: 4,
                name: "Set of 41",
                slug: "set-of-41"
            },
            {
                index: 5,
                name: "Set of 15",
                slug: "set-of-15"
            }
        ],
        brand: 16,
        area: 6
    },
    {
        id: 97,
        name: "Dinner Sets",
        attributes: [
            {
                index: 3,
                name: "Set of 68",
                slug: "set-of-68"
            },
            {
                index: 4,
                name: "Set of 41",
                slug: "set-of-41"
            },
            {
                index: 5,
                name: "Set of 15",
                slug: "set-of-15"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 97,
        name: "Dinner Sets",
        attributes: [
            {
                index: 3,
                name: "Set of 68",
                slug: "set-of-68"
            },
            {
                index: 4,
                name: "Set of 41",
                slug: "set-of-41"
            },
            {
                index: 5,
                name: "Set of 15",
                slug: "set-of-15"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 98,
        name: "Dining Tray",
        attributes: [],
        brand: 16,
        area: 6
    },
    {
        id: 98,
        name: "Dining Tray",
        attributes: [],
        brand: 6,
        area: 6
    },
    {
        id: 98,
        name: "Dining Tray",
        attributes: [],
        brand: 49,
        area: 6
    },
    {
        id: 98,
        name: "Dining Tray",
        attributes: [],
        brand: 3,
        area: 6
    },
    {
        id: 98,
        name: "Dining Tray",
        attributes: [],
        brand: 7,
        area: 6
    },
    {
        id: 99,
        name: "Tableware",
        attributes: [
            {
                index: 2,
                name: "Chopping\/Cooking Boards",
                slug: "chopping-cooking-boards"
            },
            {
                index: 1,
                name: "Pump Dispenser",
                slug: "pump-dispenser"
            }
        ],
        brand: 16,
        area: 6
    },
    {
        id: 99,
        name: "Tableware",
        attributes: [
            {
                index: 2,
                name: "Chopping\/Cooking Boards",
                slug: "chopping-cooking-boards"
            },
            {
                index: 1,
                name: "Pump Dispenser",
                slug: "pump-dispenser"
            }
        ],
        brand: 49,
        area: 6
    },
    {
        id: 99,
        name: "Tableware",
        attributes: [
            {
                index: 2,
                name: "Chopping\/Cooking Boards",
                slug: "chopping-cooking-boards"
            },
            {
                index: 1,
                name: "Pump Dispenser",
                slug: "pump-dispenser"
            }
        ],
        brand: 6,
        area: 6
    },
    {
        id: 99,
        name: "Tableware",
        attributes: [
            {
                index: 2,
                name: "Chopping\/Cooking Boards",
                slug: "chopping-cooking-boards"
            },
            {
                index: 1,
                name: "Pump Dispenser",
                slug: "pump-dispenser"
            }
        ],
        brand: 42,
        area: 6
    },
    {
        id: 99,
        name: "Tableware",
        attributes: [
            {
                index: 2,
                name: "Chopping\/Cooking Boards",
                slug: "chopping-cooking-boards"
            },
            {
                index: 1,
                name: "Pump Dispenser",
                slug: "pump-dispenser"
            }
        ],
        brand: 95,
        area: 6
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 11,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 5,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 1,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 4,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 1,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 11,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 2,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 6,
        name: "Mirrors",
        attributes: [
            {
                index: 1,
                name: "Round",
                slug: "round"
            },
            {
                index: 2,
                name: "Rectangle",
                slug: "rectangle"
            },
            {
                index: 1,
                name: "Ice Cream Shape",
                slug: "ice-cream-shape"
            },
            {
                index: 2,
                name: "Hexagon",
                slug: "hexagon"
            },
            {
                index: 3,
                name: "Balloon Shape",
                slug: "balloon-shape"
            }
        ],
        brand: 7,
        area: 12
    },
    {
        id: 101,
        name: "Glass Vases",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 101,
        name: "Glass Vases",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 83,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 3,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 6,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 49,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 16,
        area: 7
    },
    {
        id: 102,
        name: "Glass Bowl",
        attributes: [],
        brand: 8,
        area: 7
    },
    {
        id: 103,
        name: "Glass Sets",
        attributes: [],
        brand: 36,
        area: 7
    },
    {
        id: 104,
        name: "Materials",
        attributes: [
            {
                index: 1,
                name: "Metal Swatches",
                slug: "metal-swatches"
            },
            {
                index: 2,
                name: "Wood Swatches",
                slug: "wood-swatches"
            },
            {
                index: 1,
                name: "Boxes",
                slug: "boxes"
            },
            {
                index: 2,
                name: "Samples",
                slug: "samples"
            },
            {
                index: 3,
                name: "Stone Craved",
                slug: "stone-craved"
            },
            {
                index: 4,
                name: "Wooden Paper Holder",
                slug: "wooden-paper-holder"
            },
            {
                index: 1,
                name: "Marble Tiles",
                slug: "marble-tiles"
            }
        ],
        brand: 12,
        area: 10
    },
    {
        id: 104,
        name: "Materials",
        attributes: [
            {
                index: 1,
                name: "Metal Swatches",
                slug: "metal-swatches"
            },
            {
                index: 2,
                name: "Wood Swatches",
                slug: "wood-swatches"
            },
            {
                index: 1,
                name: "Boxes",
                slug: "boxes"
            },
            {
                index: 2,
                name: "Samples",
                slug: "samples"
            },
            {
                index: 3,
                name: "Stone Craved",
                slug: "stone-craved"
            },
            {
                index: 4,
                name: "Wooden Paper Holder",
                slug: "wooden-paper-holder"
            },
            {
                index: 1,
                name: "Marble Tiles",
                slug: "marble-tiles"
            }
        ],
        brand: 16,
        area: 10
    },
    {
        id: 104,
        name: "Materials",
        attributes: [
            {
                index: 1,
                name: "Metal Swatches",
                slug: "metal-swatches"
            },
            {
                index: 2,
                name: "Wood Swatches",
                slug: "wood-swatches"
            },
            {
                index: 1,
                name: "Boxes",
                slug: "boxes"
            },
            {
                index: 2,
                name: "Samples",
                slug: "samples"
            },
            {
                index: 3,
                name: "Stone Craved",
                slug: "stone-craved"
            },
            {
                index: 4,
                name: "Wooden Paper Holder",
                slug: "wooden-paper-holder"
            },
            {
                index: 1,
                name: "Marble Tiles",
                slug: "marble-tiles"
            }
        ],
        brand: 3,
        area: 10
    },
    {
        id: 104,
        name: "Materials",
        attributes: [
            {
                index: 1,
                name: "Metal Swatches",
                slug: "metal-swatches"
            },
            {
                index: 2,
                name: "Wood Swatches",
                slug: "wood-swatches"
            },
            {
                index: 1,
                name: "Boxes",
                slug: "boxes"
            },
            {
                index: 2,
                name: "Samples",
                slug: "samples"
            },
            {
                index: 3,
                name: "Stone Craved",
                slug: "stone-craved"
            },
            {
                index: 4,
                name: "Wooden Paper Holder",
                slug: "wooden-paper-holder"
            },
            {
                index: 1,
                name: "Marble Tiles",
                slug: "marble-tiles"
            }
        ],
        brand: 8,
        area: 10
    },
    {
        id: 104,
        name: "Materials",
        attributes: [
            {
                index: 1,
                name: "Metal Swatches",
                slug: "metal-swatches"
            },
            {
                index: 2,
                name: "Wood Swatches",
                slug: "wood-swatches"
            },
            {
                index: 1,
                name: "Boxes",
                slug: "boxes"
            },
            {
                index: 2,
                name: "Samples",
                slug: "samples"
            },
            {
                index: 3,
                name: "Stone Craved",
                slug: "stone-craved"
            },
            {
                index: 4,
                name: "Wooden Paper Holder",
                slug: "wooden-paper-holder"
            },
            {
                index: 1,
                name: "Marble Tiles",
                slug: "marble-tiles"
            }
        ],
        brand: 42,
        area: 10
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 16,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 3,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 6,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 1,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 49,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 8,
        area: 8
    },
    {
        id: 106,
        name: "SALAD BOWL",
        attributes: [],
        brand: 7,
        area: 8
    },
    {
        id: 107,
        name: "Cake Stands",
        attributes: [],
        brand: 16,
        area: 8
    },
    {
        id: 107,
        name: "Cake Stands",
        attributes: [],
        brand: 42,
        area: 8
    },
    {
        id: 107,
        name: "Cake Stands",
        attributes: [],
        brand: 6,
        area: 8
    },
    {
        id: 107,
        name: "Cake Stands",
        attributes: [],
        brand: 3,
        area: 8
    },
    {
        id: 108,
        name: "Sculptures & Objects",
        attributes: [],
        brand: 16,
        area: 12
    },
    {
        id: 108,
        name: "Sculptures & Objects",
        attributes: [],
        brand: 3,
        area: 12
    },
    {
        id: 108,
        name: "Sculptures & Objects",
        attributes: [],
        brand: 6,
        area: 12
    },
    {
        id: 108,
        name: "Sculptures & Objects",
        attributes: [],
        brand: 11,
        area: 12
    },
    {
        id: 109,
        name: "Coffee & Tea Cups - Glass",
        attributes: [],
        brand: 83,
        area: 7
    },
    {
        id: 110,
        name: "Bath, Towel & Accessories",
        attributes: [
            {
                index: 1,
                name: "Bath Robes",
                slug: "bath-robes"
            },
            {
                index: 2,
                name: "Towels",
                slug: "towels"
            },
            {
                index: 1,
                name: "Mat & Rugs",
                slug: "mat-and-rugs"
            },
            {
                index: 2,
                name: "Shower Curtain",
                slug: "shower-curtain"
            },
            {
                index: 1,
                name: "Soap Dispensers",
                slug: "soap-dispensers"
            },
            {
                index: 2,
                name: "Lotion Dispensers",
                slug: "lotion-dispensers"
            },
            {
                index: 3,
                name: "Soap Dish",
                slug: "soap-dish"
            },
            {
                index: 4,
                name: "Canister",
                slug: "canister"
            },
            {
                index: 5,
                name: "Bath Tumbler",
                slug: "bath-tumbler"
            },
            {
                index: 1,
                name: "Bath Essential Set",
                slug: "bath-essential-set"
            },
            {
                index: 1,
                name: "SPA Collection Bath Set",
                slug: "spa-collection-bath-set"
            },
            {
                index: 1,
                name: "Bath Containers",
                slug: "bath-containers"
            },
            {
                index: 1,
                name: "Printed Tape for Towel",
                slug: "printed-tape-for-towel"
            },
            {
                index: 1,
                name: "Beach Towels",
                slug: "beach-towels"
            },
            {
                index: 1,
                name: "Waste Bucket",
                slug: "waste-bucket"
            },
            {
                index: 1,
                name: "Bathroom Rack",
                slug: "bathroom-rack"
            }
        ],
        brand: 59,
        area: 9
    },
    {
        id: 110,
        name: "Bath, Towel & Accessories",
        attributes: [
            {
                index: 1,
                name: "Bath Robes",
                slug: "bath-robes"
            },
            {
                index: 2,
                name: "Towels",
                slug: "towels"
            },
            {
                index: 1,
                name: "Mat & Rugs",
                slug: "mat-and-rugs"
            },
            {
                index: 2,
                name: "Shower Curtain",
                slug: "shower-curtain"
            },
            {
                index: 1,
                name: "Soap Dispensers",
                slug: "soap-dispensers"
            },
            {
                index: 2,
                name: "Lotion Dispensers",
                slug: "lotion-dispensers"
            },
            {
                index: 3,
                name: "Soap Dish",
                slug: "soap-dish"
            },
            {
                index: 4,
                name: "Canister",
                slug: "canister"
            },
            {
                index: 5,
                name: "Bath Tumbler",
                slug: "bath-tumbler"
            },
            {
                index: 1,
                name: "Bath Essential Set",
                slug: "bath-essential-set"
            },
            {
                index: 1,
                name: "SPA Collection Bath Set",
                slug: "spa-collection-bath-set"
            },
            {
                index: 1,
                name: "Bath Containers",
                slug: "bath-containers"
            },
            {
                index: 1,
                name: "Printed Tape for Towel",
                slug: "printed-tape-for-towel"
            },
            {
                index: 1,
                name: "Beach Towels",
                slug: "beach-towels"
            },
            {
                index: 1,
                name: "Waste Bucket",
                slug: "waste-bucket"
            },
            {
                index: 1,
                name: "Bathroom Rack",
                slug: "bathroom-rack"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 110,
        name: "Bath, Towel & Accessories",
        attributes: [
            {
                index: 1,
                name: "Bath Robes",
                slug: "bath-robes"
            },
            {
                index: 2,
                name: "Towels",
                slug: "towels"
            },
            {
                index: 1,
                name: "Mat & Rugs",
                slug: "mat-and-rugs"
            },
            {
                index: 2,
                name: "Shower Curtain",
                slug: "shower-curtain"
            },
            {
                index: 1,
                name: "Soap Dispensers",
                slug: "soap-dispensers"
            },
            {
                index: 2,
                name: "Lotion Dispensers",
                slug: "lotion-dispensers"
            },
            {
                index: 3,
                name: "Soap Dish",
                slug: "soap-dish"
            },
            {
                index: 4,
                name: "Canister",
                slug: "canister"
            },
            {
                index: 5,
                name: "Bath Tumbler",
                slug: "bath-tumbler"
            },
            {
                index: 1,
                name: "Bath Essential Set",
                slug: "bath-essential-set"
            },
            {
                index: 1,
                name: "SPA Collection Bath Set",
                slug: "spa-collection-bath-set"
            },
            {
                index: 1,
                name: "Bath Containers",
                slug: "bath-containers"
            },
            {
                index: 1,
                name: "Printed Tape for Towel",
                slug: "printed-tape-for-towel"
            },
            {
                index: 1,
                name: "Beach Towels",
                slug: "beach-towels"
            },
            {
                index: 1,
                name: "Waste Bucket",
                slug: "waste-bucket"
            },
            {
                index: 1,
                name: "Bathroom Rack",
                slug: "bathroom-rack"
            }
        ],
        brand: 6,
        area: 9
    },
    {
        id: 110,
        name: "Bath, Towel & Accessories",
        attributes: [
            {
                index: 1,
                name: "Bath Robes",
                slug: "bath-robes"
            },
            {
                index: 2,
                name: "Towels",
                slug: "towels"
            },
            {
                index: 1,
                name: "Mat & Rugs",
                slug: "mat-and-rugs"
            },
            {
                index: 2,
                name: "Shower Curtain",
                slug: "shower-curtain"
            },
            {
                index: 1,
                name: "Soap Dispensers",
                slug: "soap-dispensers"
            },
            {
                index: 2,
                name: "Lotion Dispensers",
                slug: "lotion-dispensers"
            },
            {
                index: 3,
                name: "Soap Dish",
                slug: "soap-dish"
            },
            {
                index: 4,
                name: "Canister",
                slug: "canister"
            },
            {
                index: 5,
                name: "Bath Tumbler",
                slug: "bath-tumbler"
            },
            {
                index: 1,
                name: "Bath Essential Set",
                slug: "bath-essential-set"
            },
            {
                index: 1,
                name: "SPA Collection Bath Set",
                slug: "spa-collection-bath-set"
            },
            {
                index: 1,
                name: "Bath Containers",
                slug: "bath-containers"
            },
            {
                index: 1,
                name: "Printed Tape for Towel",
                slug: "printed-tape-for-towel"
            },
            {
                index: 1,
                name: "Beach Towels",
                slug: "beach-towels"
            },
            {
                index: 1,
                name: "Waste Bucket",
                slug: "waste-bucket"
            },
            {
                index: 1,
                name: "Bathroom Rack",
                slug: "bathroom-rack"
            }
        ],
        brand: 7,
        area: 9
    },
    {
        id: 110,
        name: "Bath, Towel & Accessories",
        attributes: [
            {
                index: 1,
                name: "Bath Robes",
                slug: "bath-robes"
            },
            {
                index: 2,
                name: "Towels",
                slug: "towels"
            },
            {
                index: 1,
                name: "Mat & Rugs",
                slug: "mat-and-rugs"
            },
            {
                index: 2,
                name: "Shower Curtain",
                slug: "shower-curtain"
            },
            {
                index: 1,
                name: "Soap Dispensers",
                slug: "soap-dispensers"
            },
            {
                index: 2,
                name: "Lotion Dispensers",
                slug: "lotion-dispensers"
            },
            {
                index: 3,
                name: "Soap Dish",
                slug: "soap-dish"
            },
            {
                index: 4,
                name: "Canister",
                slug: "canister"
            },
            {
                index: 5,
                name: "Bath Tumbler",
                slug: "bath-tumbler"
            },
            {
                index: 1,
                name: "Bath Essential Set",
                slug: "bath-essential-set"
            },
            {
                index: 1,
                name: "SPA Collection Bath Set",
                slug: "spa-collection-bath-set"
            },
            {
                index: 1,
                name: "Bath Containers",
                slug: "bath-containers"
            },
            {
                index: 1,
                name: "Printed Tape for Towel",
                slug: "printed-tape-for-towel"
            },
            {
                index: 1,
                name: "Beach Towels",
                slug: "beach-towels"
            },
            {
                index: 1,
                name: "Waste Bucket",
                slug: "waste-bucket"
            },
            {
                index: 1,
                name: "Bathroom Rack",
                slug: "bathroom-rack"
            }
        ],
        brand: 3,
        area: 9
    },
    {
        id: 111,
        name: "Fabrics",
        attributes: [
            {
                index: 3,
                name: "Linen",
                slug: "linen"
            },
            {
                index: 4,
                name: "Polyester",
                slug: "polyester"
            },
            {
                index: 5,
                name: "Cotton",
                slug: "cotton"
            },
            {
                index: 6,
                name: "Velvet",
                slug: "velvet"
            },
            {
                index: 7,
                name: "Viscose",
                slug: "viscose"
            },
            {
                index: 8,
                name: "Silk",
                slug: "silk"
            },
            {
                index: 9,
                name: "Nylon",
                slug: "nylon"
            },
            {
                index: 10,
                name: "Damask & Lampas",
                slug: "damask-and-lampas"
            },
            {
                index: 11,
                name: "Sisal",
                slug: "sisal"
            },
            {
                index: 1,
                name: "Polyamide",
                slug: "polyamide"
            },
            {
                index: 2,
                name: "Lurex",
                slug: "lurex"
            },
            {
                index: 3,
                name: "Nylon",
                slug: "nylon"
            }
        ],
        brand: 59,
        area: 9
    },
    {
        id: 111,
        name: "Fabrics",
        attributes: [
            {
                index: 3,
                name: "Linen",
                slug: "linen"
            },
            {
                index: 4,
                name: "Polyester",
                slug: "polyester"
            },
            {
                index: 5,
                name: "Cotton",
                slug: "cotton"
            },
            {
                index: 6,
                name: "Velvet",
                slug: "velvet"
            },
            {
                index: 7,
                name: "Viscose",
                slug: "viscose"
            },
            {
                index: 8,
                name: "Silk",
                slug: "silk"
            },
            {
                index: 9,
                name: "Nylon",
                slug: "nylon"
            },
            {
                index: 10,
                name: "Damask & Lampas",
                slug: "damask-and-lampas"
            },
            {
                index: 11,
                name: "Sisal",
                slug: "sisal"
            },
            {
                index: 1,
                name: "Polyamide",
                slug: "polyamide"
            },
            {
                index: 2,
                name: "Lurex",
                slug: "lurex"
            },
            {
                index: 3,
                name: "Nylon",
                slug: "nylon"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 112,
        name: "Dinner Tea Spoon",
        attributes: [
            {
                index: 5,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 6,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 7,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 8,
                name: "White",
                slug: "white"
            }
        ],
        brand: 41,
        area: 11
    },
    {
        id: 112,
        name: "Dinner Tea Spoon",
        attributes: [
            {
                index: 5,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 6,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 7,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 8,
                name: "White",
                slug: "white"
            }
        ],
        brand: 68,
        area: 11
    },
    {
        id: 112,
        name: "Dinner Tea Spoon",
        attributes: [
            {
                index: 5,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 6,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 7,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 8,
                name: "White",
                slug: "white"
            }
        ],
        brand: 6,
        area: 11
    },
    {
        id: 112,
        name: "Dinner Tea Spoon",
        attributes: [
            {
                index: 5,
                name: "Grey",
                slug: "grey"
            },
            {
                index: 6,
                name: "Pearl",
                slug: "pearl"
            },
            {
                index: 7,
                name: "Taupe",
                slug: "taupe"
            },
            {
                index: 8,
                name: "White",
                slug: "white"
            }
        ],
        brand: 16,
        area: 11
    },
    {
        id: 113,
        name: "Beddings \/ Linens",
        attributes: [
            {
                index: 1,
                name: "Bed Linen Sets",
                slug: "bed-linen-sets"
            },
            {
                index: 2,
                name: "Duvets & Covers",
                slug: "duvets-and-covers"
            },
            {
                index: 3,
                name: "Bedsheets & Blankets",
                slug: "bedsheets-and-blankets"
            },
            {
                index: 4,
                name: "Pillows & Cushion Covers",
                slug: "pillows-and-cushion-covers"
            }
        ],
        brand: 63,
        area: 9
    },
    {
        id: 113,
        name: "Beddings \/ Linens",
        attributes: [
            {
                index: 1,
                name: "Bed Linen Sets",
                slug: "bed-linen-sets"
            },
            {
                index: 2,
                name: "Duvets & Covers",
                slug: "duvets-and-covers"
            },
            {
                index: 3,
                name: "Bedsheets & Blankets",
                slug: "bedsheets-and-blankets"
            },
            {
                index: 4,
                name: "Pillows & Cushion Covers",
                slug: "pillows-and-cushion-covers"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 116,
        name: "Outdoor Furniture",
        attributes: [
            {
                index: 1,
                name: "Set of 8",
                slug: "set-of-8"
            },
            {
                index: 2,
                name: "Set of 3",
                slug: "set-of-3"
            },
            {
                index: 3,
                name: "Set of 6",
                slug: "set-of-6"
            },
            {
                index: 4,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 5,
                name: "Chair, Armchair, Armless",
                slug: "chair-armchair-armless"
            },
            {
                index: 6,
                name: "Umbrella",
                slug: "umbrella"
            },
            {
                index: 7,
                name: "Pillow & Cushion",
                slug: "pillow-and-cushion"
            },
            {
                index: 8,
                name: "Side\/Coffee Tables",
                slug: "side-coffee-tables"
            },
            {
                index: 9,
                name: "Dining Table",
                slug: "dining-table"
            },
            {
                index: 10,
                name: "Dining Chair",
                slug: "dining-chair"
            },
            {
                index: 11,
                name: "Planters, Wooden Garden Retro",
                slug: "planters-wooden-garden-retro"
            },
            {
                index: 12,
                name: "Reception Desk",
                slug: "reception-desk"
            },
            {
                index: 13,
                name: "Chaise Lounge & Ottoman",
                slug: "chaise-lounge-and-ottoman"
            },
            {
                index: 14,
                name: "Bar & Stool",
                slug: "bar-and-stool"
            },
            {
                index: 15,
                name: "Wicker Boat",
                slug: "wicker-boat"
            },
            {
                index: 16,
                name: "Hanging Chair",
                slug: "hanging-chair"
            },
            {
                index: 17,
                name: "Rocking Chair, Swivel Chair",
                slug: "rocking-chair-swivel-chair"
            },
            {
                index: 18,
                name: "Set of 4",
                slug: "set-of-4"
            },
            {
                index: 19,
                name: "Set of 5",
                slug: "set-of-5"
            },
            {
                index: 20,
                name: "Set of 7",
                slug: "set-of-7"
            },
            {
                index: 21,
                name: "Set of 9",
                slug: "set-of-9"
            },
            {
                index: 1,
                name: "Bike",
                slug: "bike"
            },
            {
                index: 2,
                name: "Water Sports\/Floater",
                slug: "water-sports-floater"
            },
            {
                index: 2,
                name: "Middle Chair, Corner Chair",
                slug: "middle-chair-corner-chair"
            }
        ],
        brand: 16,
        area: 13
    },
    {
        id: 116,
        name: "Outdoor Furniture",
        attributes: [
            {
                index: 1,
                name: "Set of 8",
                slug: "set-of-8"
            },
            {
                index: 2,
                name: "Set of 3",
                slug: "set-of-3"
            },
            {
                index: 3,
                name: "Set of 6",
                slug: "set-of-6"
            },
            {
                index: 4,
                name: "Sofa",
                slug: "sofa"
            },
            {
                index: 5,
                name: "Chair, Armchair, Armless",
                slug: "chair-armchair-armless"
            },
            {
                index: 6,
                name: "Umbrella",
                slug: "umbrella"
            },
            {
                index: 7,
                name: "Pillow & Cushion",
                slug: "pillow-and-cushion"
            },
            {
                index: 8,
                name: "Side\/Coffee Tables",
                slug: "side-coffee-tables"
            },
            {
                index: 9,
                name: "Dining Table",
                slug: "dining-table"
            },
            {
                index: 10,
                name: "Dining Chair",
                slug: "dining-chair"
            },
            {
                index: 11,
                name: "Planters, Wooden Garden Retro",
                slug: "planters-wooden-garden-retro"
            },
            {
                index: 12,
                name: "Reception Desk",
                slug: "reception-desk"
            },
            {
                index: 13,
                name: "Chaise Lounge & Ottoman",
                slug: "chaise-lounge-and-ottoman"
            },
            {
                index: 14,
                name: "Bar & Stool",
                slug: "bar-and-stool"
            },
            {
                index: 15,
                name: "Wicker Boat",
                slug: "wicker-boat"
            },
            {
                index: 16,
                name: "Hanging Chair",
                slug: "hanging-chair"
            },
            {
                index: 17,
                name: "Rocking Chair, Swivel Chair",
                slug: "rocking-chair-swivel-chair"
            },
            {
                index: 18,
                name: "Set of 4",
                slug: "set-of-4"
            },
            {
                index: 19,
                name: "Set of 5",
                slug: "set-of-5"
            },
            {
                index: 20,
                name: "Set of 7",
                slug: "set-of-7"
            },
            {
                index: 21,
                name: "Set of 9",
                slug: "set-of-9"
            },
            {
                index: 1,
                name: "Bike",
                slug: "bike"
            },
            {
                index: 2,
                name: "Water Sports\/Floater",
                slug: "water-sports-floater"
            },
            {
                index: 2,
                name: "Middle Chair, Corner Chair",
                slug: "middle-chair-corner-chair"
            }
        ],
        brand: 7,
        area: 13
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 3,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 8,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 16,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 10,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 11,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 9,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 5,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 59,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 45,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 37,
        area: 4
    },
    {
        id: 117,
        name: "Pendants & Ceiling Lamps",
        attributes: [
            {
                index: 1,
                name: "Pendant Lamp",
                slug: "pendant-lamp"
            },
            {
                index: 2,
                name: "Ceiling Lamp",
                slug: "ceiling-lamp"
            }
        ],
        brand: 36,
        area: 4
    },
    {
        id: 118,
        name: "Pedestal & Dividers",
        attributes: [
            {
                index: 1,
                name: "Paravant",
                slug: "paravant"
            }
        ],
        brand: 3,
        area: 12
    },
    {
        id: 118,
        name: "Pedestal & Dividers",
        attributes: [
            {
                index: 1,
                name: "Paravant",
                slug: "paravant"
            }
        ],
        brand: 8,
        area: 12
    },
    {
        id: 118,
        name: "Pedestal & Dividers",
        attributes: [
            {
                index: 1,
                name: "Paravant",
                slug: "paravant"
            }
        ],
        brand: 2,
        area: 12
    },
    {
        id: 118,
        name: "Pedestal & Dividers",
        attributes: [
            {
                index: 1,
                name: "Paravant",
                slug: "paravant"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 119,
        name: "Outdoor Lighting",
        attributes: [],
        brand: 8,
        area: 13
    },
    {
        id: 119,
        name: "Outdoor Lighting",
        attributes: [],
        brand: 16,
        area: 13
    },
    {
        id: 119,
        name: "Outdoor Lighting",
        attributes: [],
        brand: 7,
        area: 13
    },
    {
        id: 120,
        name: "Outdoor Decors & Accessories",
        attributes: [
            {
                index: 1,
                name: "Hammock",
                slug: "hammock"
            },
            {
                index: 2,
                name: "Hammock\/Frame",
                slug: "hammock-frame"
            },
            {
                index: 1,
                name: "Alum Tablet",
                slug: "alum-tablet"
            },
            {
                index: 3,
                name: "Canopy Frame",
                slug: "canopy-frame"
            },
            {
                index: 1,
                name: "Storage Cover",
                slug: "storage-cover"
            }
        ],
        brand: 16,
        area: 13
    },
    {
        id: 120,
        name: "Outdoor Decors & Accessories",
        attributes: [
            {
                index: 1,
                name: "Hammock",
                slug: "hammock"
            },
            {
                index: 2,
                name: "Hammock\/Frame",
                slug: "hammock-frame"
            },
            {
                index: 1,
                name: "Alum Tablet",
                slug: "alum-tablet"
            },
            {
                index: 3,
                name: "Canopy Frame",
                slug: "canopy-frame"
            },
            {
                index: 1,
                name: "Storage Cover",
                slug: "storage-cover"
            }
        ],
        brand: 7,
        area: 13
    },
    {
        id: 121,
        name: "Plants",
        attributes: [
            {
                index: 1,
                name: "Flower",
                slug: "flower"
            },
            {
                index: 2,
                name: "Tree",
                slug: "tree"
            },
            {
                index: 3,
                name: "Fountain",
                slug: "fountain"
            },
            {
                index: 4,
                name: "Glass Bottle",
                slug: "glass-bottle"
            },
            {
                index: 5,
                name: "Potted Plant",
                slug: "potted-plant"
            },
            {
                index: 6,
                name: "Arrangements",
                slug: "arrangements"
            },
            {
                index: 7,
                name: "Moss",
                slug: "moss"
            },
            {
                index: 8,
                name: "Plant Wall",
                slug: "plant-wall"
            },
            {
                index: 1,
                name: "Hanging Plant",
                slug: "hanging-plant"
            },
            {
                index: 1,
                name: "Planter",
                slug: "planter"
            }
        ],
        brand: 16,
        area: 13
    },
    {
        id: 121,
        name: "Plants",
        attributes: [
            {
                index: 1,
                name: "Flower",
                slug: "flower"
            },
            {
                index: 2,
                name: "Tree",
                slug: "tree"
            },
            {
                index: 3,
                name: "Fountain",
                slug: "fountain"
            },
            {
                index: 4,
                name: "Glass Bottle",
                slug: "glass-bottle"
            },
            {
                index: 5,
                name: "Potted Plant",
                slug: "potted-plant"
            },
            {
                index: 6,
                name: "Arrangements",
                slug: "arrangements"
            },
            {
                index: 7,
                name: "Moss",
                slug: "moss"
            },
            {
                index: 8,
                name: "Plant Wall",
                slug: "plant-wall"
            },
            {
                index: 1,
                name: "Hanging Plant",
                slug: "hanging-plant"
            },
            {
                index: 1,
                name: "Planter",
                slug: "planter"
            }
        ],
        brand: 7,
        area: 13
    },
    {
        id: 121,
        name: "Plants",
        attributes: [
            {
                index: 1,
                name: "Flower",
                slug: "flower"
            },
            {
                index: 2,
                name: "Tree",
                slug: "tree"
            },
            {
                index: 3,
                name: "Fountain",
                slug: "fountain"
            },
            {
                index: 4,
                name: "Glass Bottle",
                slug: "glass-bottle"
            },
            {
                index: 5,
                name: "Potted Plant",
                slug: "potted-plant"
            },
            {
                index: 6,
                name: "Arrangements",
                slug: "arrangements"
            },
            {
                index: 7,
                name: "Moss",
                slug: "moss"
            },
            {
                index: 8,
                name: "Plant Wall",
                slug: "plant-wall"
            },
            {
                index: 1,
                name: "Hanging Plant",
                slug: "hanging-plant"
            },
            {
                index: 1,
                name: "Planter",
                slug: "planter"
            }
        ],
        brand: 3,
        area: 13
    },
    {
        id: 123,
        name: "Services - Table Base",
        attributes: [],
        brand: 16,
        area: 9
    },
    {
        id: 124,
        name: "Services - Table Top",
        attributes: [],
        brand: 16,
        area: 9
    },
    {
        id: 125,
        name: "Accessories",
        attributes: [
            {
                index: 1,
                name: "Knobs",
                slug: "knobs"
            },
            {
                index: 1,
                name: "Globe",
                slug: "globe"
            },
            {
                index: 2,
                name: "Jewelry",
                slug: "jewelry"
            },
            {
                index: 1,
                name: "Waste Basket",
                slug: "waste-basket"
            },
            {
                index: 1,
                name: "Services",
                slug: "services"
            },
            {
                index: 1,
                name: "Magazine Rack",
                slug: "magazine-rack"
            },
            {
                index: 2,
                name: "Books",
                slug: "books"
            },
            {
                index: 1,
                name: "Lighter Gas Refill",
                slug: "lighter-gas-refill"
            },
            {
                index: 1,
                name: "Candle Bags",
                slug: "candle-bags"
            },
            {
                index: 1,
                name: "Metal Hanger",
                slug: "metal-hanger"
            },
            {
                index: 2,
                name: "Cabinet Handle",
                slug: "cabinet-handle"
            },
            {
                index: 4,
                name: "Pencil Holder",
                slug: "pencil-holder"
            }
        ],
        brand: 16,
        area: 12
    },
    {
        id: 125,
        name: "Accessories",
        attributes: [
            {
                index: 1,
                name: "Knobs",
                slug: "knobs"
            },
            {
                index: 1,
                name: "Globe",
                slug: "globe"
            },
            {
                index: 2,
                name: "Jewelry",
                slug: "jewelry"
            },
            {
                index: 1,
                name: "Waste Basket",
                slug: "waste-basket"
            },
            {
                index: 1,
                name: "Services",
                slug: "services"
            },
            {
                index: 1,
                name: "Magazine Rack",
                slug: "magazine-rack"
            },
            {
                index: 2,
                name: "Books",
                slug: "books"
            },
            {
                index: 1,
                name: "Lighter Gas Refill",
                slug: "lighter-gas-refill"
            },
            {
                index: 1,
                name: "Candle Bags",
                slug: "candle-bags"
            },
            {
                index: 1,
                name: "Metal Hanger",
                slug: "metal-hanger"
            },
            {
                index: 2,
                name: "Cabinet Handle",
                slug: "cabinet-handle"
            },
            {
                index: 4,
                name: "Pencil Holder",
                slug: "pencil-holder"
            }
        ],
        brand: 6,
        area: 12
    },
    {
        id: 125,
        name: "Accessories",
        attributes: [
            {
                index: 1,
                name: "Knobs",
                slug: "knobs"
            },
            {
                index: 1,
                name: "Globe",
                slug: "globe"
            },
            {
                index: 2,
                name: "Jewelry",
                slug: "jewelry"
            },
            {
                index: 1,
                name: "Waste Basket",
                slug: "waste-basket"
            },
            {
                index: 1,
                name: "Services",
                slug: "services"
            },
            {
                index: 1,
                name: "Magazine Rack",
                slug: "magazine-rack"
            },
            {
                index: 2,
                name: "Books",
                slug: "books"
            },
            {
                index: 1,
                name: "Lighter Gas Refill",
                slug: "lighter-gas-refill"
            },
            {
                index: 1,
                name: "Candle Bags",
                slug: "candle-bags"
            },
            {
                index: 1,
                name: "Metal Hanger",
                slug: "metal-hanger"
            },
            {
                index: 2,
                name: "Cabinet Handle",
                slug: "cabinet-handle"
            },
            {
                index: 4,
                name: "Pencil Holder",
                slug: "pencil-holder"
            }
        ],
        brand: 4,
        area: 12
    },
    {
        id: 126,
        name: "Services & Repairs",
        attributes: [
            {
                index: 2,
                name: "Furniture",
                slug: "furniture"
            },
            {
                index: 3,
                name: "Lighting",
                slug: "lighting"
            },
            {
                index: 4,
                name: "Accessories",
                slug: "accessories"
            },
            {
                index: 1,
                name: "Fabric Customized",
                slug: "fabric-customized"
            },
            {
                index: 1,
                name: "Painting",
                slug: "painting"
            }
        ],
        brand: 16,
        area: 14
    },
    {
        id: 127,
        name: "Curtain Projects",
        attributes: [
            {
                index: 4,
                name: "SI# 030822",
                slug: "si-030822"
            },
            {
                index: 1,
                name: "Sitting Curtain",
                slug: "sitting-curtain"
            },
            {
                index: 2,
                name: "Main Hall",
                slug: "main-hall"
            }
        ],
        brand: 16,
        area: 14
    },
    {
        id: 128,
        name: "Design Fees",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 129,
        name: "CUSTOM DINING TABLE",
        attributes: [
            {
                index: 1,
                name: "Large",
                slug: "large"
            },
            {
                index: 2,
                name: "Medium",
                slug: "medium"
            },
            {
                index: 3,
                name: "Small",
                slug: "small"
            }
        ],
        brand: 16,
        area: 3
    },
    {
        id: 130,
        name: "Customized Headboard",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 131,
        name: "Installations-Wal Lamps",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 132,
        name: "Upholstery Service",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 133,
        name: "Customized Cushion",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 134,
        name: "Trimming",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 135,
        name: "Re-Paint Charges",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 136,
        name: "Wallpaper",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 137,
        name: "Shipping, Custom Duty & Legalization",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 138,
        name: "Customized Box Edge Pillow",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 140,
        name: "Books",
        attributes: [
            {
                index: 1,
                name: "Books",
                slug: "books"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 141,
        name: "Carpentry",
        attributes: [
            {
                index: 2,
                name: "Flower & Plants",
                slug: "flower-and-plants"
            }
        ],
        brand: 16,
        area: 14
    },
    {
        id: 142,
        name: "Install",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 143,
        name: "Customized Bed",
        attributes: [],
        brand: 16,
        area: 14
    },
    {
        id: 192,
        name: "Lighting",
        attributes: [
            {
                index: 1,
                name: "Spare Shade",
                slug: "spare-shade"
            }
        ],
        brand: 16,
        area: 1
    },
    {
        id: 193,
        name: "Bed Frame",
        attributes: [],
        brand: 16,
        area: 2
    },
    {
        id: 193,
        name: "Bed Frame",
        attributes: [],
        brand: 7,
        area: 2
    },
    {
        id: 194,
        name: "Trolleys",
        attributes: [],
        brand: 3,
        area: 1
    },
    {
        id: 195,
        name: "Mattress Pad",
        attributes: [],
        brand: 16,
        area: 13
    },
    {
        id: 197,
        name: "Kids Toys",
        attributes: [
            {
                index: 1,
                name: "Wooden Toys",
                slug: "wooden-toys"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 198,
        name: "Hand Bags",
        attributes: [
            {
                index: 3,
                name: "Handmade Bags",
                slug: "handmade-bags"
            }
        ],
        brand: 16,
        area: 9
    },
    {
        id: 199,
        name: "Lamps",
        attributes: [
            {
                index: 1,
                name: "Lamp Base Only",
                slug: "lamp-base-only"
            },
            {
                index: 2,
                name: "Lamp",
                slug: "lamp"
            }
        ],
        brand: 6,
        area: 4
    }
]