import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir
export const TransactionApiService = {
    async store(endpoint,object,paramLocaton = null) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }

        if(paramLocaton !== null){
            location = paramLocaton
        }
        const params = {...location,...object}
        return await Axios.post(`/api/${endpoint}`, params, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async generate() {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location}
        return await Axios.post(`/api/generate`, params, { headers: bearer() }).then(response => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async get(endpoint, params) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location : session.location.id 
        }
        return await Axios.get(`/api/${endpoint}`, { params: { ...token(), ...params, ...location} }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            console.log(error)
            return Promise.reject(error)
        })
    },

    async update(endpoint, object, paramLocaton = null) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }

        if(paramLocaton !== null){
            location = paramLocaton
        }
        const params = { ...location, ...object }
        return await Axios.put(`/api/${endpoint}`, params, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async delete(endpoint,params) {
        return await Axios.delete(`/api/${endpoint}`, {
            data: {
                tokens: params
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async updateStatus(endpoint,obj) {
        return await Axios.put(`/api/${endpoint}`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}