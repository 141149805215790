<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">

        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-input :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" :inputClass="hasError(errors) + ' text-right'" v-model="inputValue" :type="type" autocomplete="off" :disabled="disabled">
      <span class="input-group-text md-addon" slot="append">{{currency}}</span>
    </mdb-input>

                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
import Vue from 'vue'
export default {
    name : 'ThatsPriceInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        currency : {
            type : String,
            default : 'QAR'
        },
        disabled : {
            type : Boolean,
            default : false
        }

    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(errors){
            return (errors.length > 0) ? 'is-invalid' : null
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            
            const result = newVal.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            Vue.nextTick(() => this.inputValue = result);

            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value : function(v) {
            const result = v.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            Vue.nextTick(() => this.inputValue = result);
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>