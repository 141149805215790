
<template>
  <div class="itmform-container">
      <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
        <XWindow ref="window" title="DATA INTEGRATION" windowClass="height-auto" scrollClass='import-invoice-form height-auto' :height="600" :width="800" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)">
              <template #left-button>

                    <template v-if="(postCounter < dataLength) || processing">
                        <button class="btn btn-sm btn-elegant" :disabled="true"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </template>
                    <template v-else>
                 
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm())" :disabled="processing">PUSH TO SAP</mdb-btn>
                        <mdb-btn color="elegant" size="sm" @click="generateItemWithoutCostPrice" :disabled="processing">Fetch Item w/o cost price from Invoice</mdb-btn>
                      </template>
                      <mdb-btn color="elegant" size="sm" @click="clearForm" :disabled="(postCounter < dataLength) || processing">CLEAR</mdb-btn>

              </template>
            <div class="p-0 m-0 w-100">

              <template v-if="errorMessage">
                <div class="alert alert-danger">{{errorMessage}}</div>
              </template>
              <div ref="spreadsheet"></div>
            </div>
        </XWindow>
      </ValidationObserver>

  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-prototype-builtins */

import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { MessageBox } from 'element-ui';
import XWindow from './XWindow.vue'
import jspreadsheet from '../../jsexcel/jexcel'

export default {
  props : {
    group : {
      type : Number,
      default : 2
    },
    endpoint : {
      type : String,
      default : 'inventory-revaluation'
    }
  },

    components: {

        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
  
        XWindow
  },
    
    data : () => ({
      postCounter : 0,
      dataLength : 0,
      validated : false,
      processing : false,
      formStatus : 'create',
      errorMessage : null,
      spreadsheet: null,
      exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'auto',
            tableOverflow : true,
             data:[[]],
             minDimensions:[3,20],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                
                {
                    type: 'text',
                    width: '200px',
                    title: 'Series',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '150px',
                    align : 'right',
                    title: 'Price',
                    name: 'sale_price',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '150px',
                    align : 'right',
                    title: 'Cost Price',
                    name: 'cost_price',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                },
                {
                    type: 'text',
                    width: '150px',
                    title: 'Brand',
                    name: 'brand',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '150px',
                    title: 'Reference',
                    name: 'ref',
                    readOnly: true,
                },
                
                {
                    type: 'text',
                    width: '340px',
                    title: 'Message',
                    name: 'message',
                    readOnly: true,
                },
                
              
            ],

             contextMenu: function(obj, x, y, e) {

                return false;
             }
        },
    }),

    created : function() {
     
    },
    updated(){
      this.toggleCheck()
    },


    methods: {
      ...mapActions('DataIntegrationModule',['integrate','fetch','post']),

      formResetter(){

      },

      async onSubmitForm(){
        const self = this;
        this.errorMessage = null
        if(this.processing === false){
            const tableData = this.spreadsheet.getJson();
       
            if(tableData.length > 0){
              
              this.postCounter = 0
              const dataArray = tableData.filter((item) => (item.series !== '' && item.series !== null),[]);

              
              // this.dataLength = dataArray.length;

              console.log("dataArray.length",dataArray.length)
              console.log("dataArray",dataArray)

              if(dataArray.length > 0){
                this.processing = true

                dataArray.forEach((element, forIndex) => {
                  const row = 1 + forIndex
                  const messageCol = `F${row}`
                  self.spreadsheet.setStyle(`A${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setStyle(`B${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setStyle(`C${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setStyle(`D${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setStyle(`E${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setStyle(`F${row}`,'background-color','#fb3', true);
                  self.spreadsheet.setValue(messageCol,'Please wait,...', true);
                });
                
                await this.post({endpoint : 'inventory-revaluation', record : {items : dataArray}}).then((res) => {
                  console.log(res)
                  this.processing = false

                  dataArray.forEach((element, forIndex) => {
                    const row = 1 + forIndex
                    const messageCol = `F${row}`
                    self.spreadsheet.setStyle(`A${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setStyle(`B${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setStyle(`C${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setStyle(`D${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setStyle(`E${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setStyle(`F${row}`,'background-color','#00c851', true);
                    self.spreadsheet.setValue(messageCol,'Successfully added', true);
                  });
                }).catch((error) => {
                  console.log(error)
                  this.processing = false
                    if (error.response) {
                      this.errorMessage = error.response.data?.error?.message?.value;
                    } else if (error.request) {
                      this.errorMessage = error.request
                    } else {
                      this.errorMessage = error.message
                    }

                  
                })
                
                /* dataArray.forEach((element, forIndex) => {
                      const row = 1 + forIndex

                      const messageCol = `F${row}`

                        self.processing = true;
                        self.spreadsheet.setValue(messageCol,'Loading...');
                        this.post({endpoint : self.endpoint,record : {series : element.series, value : element.cost_price, index : row}}).then((response) => {
                                  const i = parseInt(response.index);
  
                                  self.spreadsheet.setStyle(`A${i}`,'background-color','#00c851');
                                  self.spreadsheet.setStyle(`B${i}`,'background-color','#00c851');
                                  self.spreadsheet.setStyle(`C${i}`,'background-color','#00c851');
                                  self.spreadsheet.setStyle(`D${i}`,'background-color','#00c851');
                                  self.spreadsheet.setStyle(`E${i}`,'background-color','#00c851');
                                  self.spreadsheet.setStyle(`F${i}`,'background-color','#00c851');
                                  self.spreadsheet.setValue(messageCol,response.message);
                                  self.processing = false
                                  this.postCounter++
                                }).catch((error) => {
  
                                    const i = parseInt(error.response.data.index)
                                    if(error.response.data.hasOwnProperty('message')){
                                      self.spreadsheet.setValue(messageCol,error.response.data.message);
                                    }else{

                                      if(error.response.data.errors.hasOwnProperty('value')){
                                        self.spreadsheet.setValue(messageCol,error.response.data.errors.value);
                                      }
                                    }

                                    self.spreadsheet.setStyle(`A${i}`,'background-color','#fb3');
                                    self.spreadsheet.setStyle(`B${i}`,'background-color','#fb3');
                                    self.spreadsheet.setStyle(`C${i}`,'background-color','#fb3');
                                    self.spreadsheet.setStyle(`D${i}`,'background-color','#fb3');
                                    self.spreadsheet.setStyle(`E${i}`,'background-color','#fb3');
                                    self.spreadsheet.setStyle(`F${i}`,'background-color','#fb3');
                                    self.processing = false
                                    this.postCounter++
  
                                });
                            
                    }); */
              }

            }
        }
      },

      openDialog(){
        this.$refs.window.openDialog();
      },

      createPopupTable(exportTableOptions){
        // console.log("create table")

          if(this.spreadsheet !== null){
              this.destroySpreadSheet()
          }
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)

            this.spreadsheet.setData([])

        },

        async destroySpreadSheet(){
            await this.spreadsheet.destroy();
        },

        dialogState(state){
        this.active = 0;

        if(!state){
          this.formResetter();
          this.spreadsheet.destroy();
          return
        }

        if(this.formStatus == 'create'){
          this.formResetter();
          this.createPopupTable(this.exportTableOptions)   
        }

        this.processing = false
        
      },

      generateItemWithoutCostPrice(){
        const self = this
        this.processing = true;
        this.fetch({endpoint : 'zero-cost-price'}).then((res) => {

            // console.log(res.data)

              this.createPopupTable(this.exportTableOptions);
              const tableData = res.data.map((i) => {
                return {
                  series : i.code,
                  sale_price : i.sale_price,
                  cost_price : 0.00,
                  ref : i.ref,
                  brand : i.brand,
                  message : 'No Cost Price'
                }
              },[])

               this.spreadsheet.setData(tableData)

            this.processing = false; 
        }).catch((error) => {
          this.processing = false
        });
      },
      clearForm(){
          this.destroySpreadSheet();
          this.createPopupTable(this.exportTableOptions);
      }

    },
    watch : {
        dialogIsOpen : function(v){
            if(v === false){
  
                this.spreadsheet.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }

            
        }
    }


}
</script>
<style lang="scss" scoped>

</style>