<template>

    <mdb-pagination class="default-pagination-main" color="dark">

    <mdb-page-item v-if="paginationLabels.first" v-on:click.native="first" :disabled="hasFirst">&laquo;</mdb-page-item>
    <mdb-page-item v-on:click.native="prev" :disabled="hasFirst">&lsaquo;</mdb-page-item>
    <template v-for="page in items">
        <template v-if="page.disable">
            <mdb-page-item :key="page.label">...</mdb-page-item>
        </template>
        <template v-else>  
            <mdb-page-item :active="page.active" :key="page.label" v-on:click.native="goto(page.label)">{{ page.label }}</mdb-page-item>
        </template>
    </template>
    <mdb-page-item v-if="paginationLabels.next" v-on:click.native="next">&rsaquo;</mdb-page-item>
    <mdb-page-item v-on:click.native="last" :disabled="hasLast">&raquo;</mdb-page-item>

    </mdb-pagination>
</template>

<script>

import {  mdbPagination, mdbPageItem, mdbPageNav, } from "mdbvue";


  const defaultLabels = {
    first: '&laquo;',
    prev: '&lsaquo;',
    next: '&rsaquo;',
    last: '&raquo;'
  }

  export default {
      name : 'ThatsPagination',
      components : {
          mdbPagination, mdbPageItem, mdbPageNav,
      },
    props: {
      value: {  // current page
        type: Number,
        required: true
      },
      pageCount: { // page numbers
        type: Number,
        required: true
      },
      classes: {
        type: Object,
        required: false,
        default: () => ({})
      },
      labels: {
        type: Object,
        required: false,
        default: () => ({})
      }
    },

    data() {
      return {

        paginationLabels: {
          ...defaultLabels,
          ...this.labels
        }
      }
    },

    mounted() {
      if (this.value > this.pageCount) {
        this.$emit('input', this.pageCount)
      }
    },

    computed: {
      items() {
        let valPrev = this.value > 1 ? (this.value - 1) : 1 // for easier navigation - gives one previous page
        let valNext = this.value < this.pageCount ? (this.value + 1) : this.pageCount // one next page
        let extraPrev = valPrev === 5 ? 3 : null
        let extraNext = valNext === (this.pageCount - 3) ? (this.pageCount - 1) : null
        let dotsBefore = valPrev > 5 ? 3 : null
        let dotsAfter = valNext < (this.pageCount - 3) ? (this.pageCount - 1) : null

        let output = []

        for (let i = 1; i <= this.pageCount; i += 1) {
          if ([1, this.pageCount, this.value, valPrev, valNext, extraPrev, extraNext, dotsBefore, dotsAfter].includes(i)) {
            output.push({
              label: i,
              active: this.value === i,
              disable: [dotsBefore, dotsAfter].includes(i)
            })
          }
        }

        return output
      },

      hasFirst() {
        return (this.value === 1)
      },

      hasLast() {
        return (this.value === this.pageCount)
      },
    },

    watch: {
      value: function () {
        this.$emit('change')
      }
    },

    methods: {
      first() {
        if (!this.hasFirst) {
          this.$emit('input', 1)
        }
      },

      prev() {
        if (!this.hasFirst) {
          this.$emit('input', (this.value - 1))
        }
      },

      goto(page) {
        this.$emit('input', page)
      },

      next() {
        if (!this.hasLast) {
          this.$emit('input', (this.value + 1))
        }
      },

      last() {
        if (!this.hasLast) {
          this.$emit('input', this.pageCount)
        }
      },
    }
  }
</script>
