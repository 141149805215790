<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">
        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <template v-if="!loading">
                <v-select  @input="setSelected" ref="vselect" :multiple="multiple" v-bind:class="hasError(errors) + ' ' + inputClass" v-model="inputValue" append-to-body :reduce="reduce" :label="reduceLabel" :options="options" @search="onSearch" :filterable="filterable" :clearable="clearable" :disabled="disabled" :selectable="selectable">
                    <template #list-footer>
                      <template v-if="addButton">
                        <li><mdb-btn class="dropdown-new" color="elegant" size="sm" @click="emitNewWindow">Add new</mdb-btn></li>
                    </template>
                </template>
                </v-select>
                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
                </template>
                <template v-else>
                    <p>Loading...</p>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbBtn } from "mdbvue";
import { createPopper } from '@popperjs/core';
export default {
    name : 'ThatsMultiSelect',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        options: {
            type: Array
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputClass : {
            type : String,
            default : ''
        },
        multiple : {
            type : Boolean,
            default : true
        },
        reduce :{
            type : Function,
            default: option => option,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },

        show_error: {
            type: Boolean,
            default : false
        },
        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: true
        },
        disabled : {
            type : Boolean,
            default : false
        },

        addButton: {
            type: Boolean,
            default : false
        },

        loading: {
            type: Boolean,
            default : false
        },
        selectable: {
           type : Function,
            default: option => option,
        },
    },
    components : {
        ValidationProvider,
        mdbBtn,
    },

    data : ()=>({
        inputValue : [],
        placement: 'bottom'
    }),

    methods : {
        setSelected(v){
            this.$emit('select',v)
        },
        emitNewWindow(){
          this.$refs.vselect.closeSearchOptions()
          this.$emit('newWindow')
              },
        onSearch(v){
            this.$emit('onSearch',v)
        },
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },


withPopper (dropdownList, component, {width}) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset', options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({state}) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }]
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },


    },
    computed : {
        finalOptions : function () {
            const withSelected = [];
            this.options.forEach(element => {
                if(element.value === this.value){
                    // this.inputValue = element.value
                    element.selected = true
                    withSelected.push(element)
                }else{
                    withSelected.push(element)
                }
            });

            return withSelected;
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>
