<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Incoming(ETA Confirmed)" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1300" :formStatus="formStatus" @dialog-state="dialogState">
            <template #left-button>
                <template v-if="formStatus === 'update' && formOriginal.status_index === 1">
                  <ThatsBtn :loading="sendLoading" :disabled="sendLoading" :icon="'paper-plane'" :show="'icon'" @click="sendToGeneralManagerClick" :title="'Send to General Manager'"></ThatsBtn>
                </template>

                <template v-if="formLoading || viewLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="form.status_index <= 0">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                  </template>
                  <mdb-btn color="elegant" class="ml-auto" size="sm" tag="a" @click="fnPrintDoc('good-received/print/tagging/'+form.series)" target="_blank" :disabled="viewLoading">Print Item Tag</mdb-btn>
                </template>

            </template>

        
            <div class="mt-1 p-3">
                <mdb-row >
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-5 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>                  
                      <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-5 pl-0" label="P.O. No." placeholder="SKU/Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="true" />                  
                      <template v-if="(formStatus === 'create' || formStatus === 'update') && form.status_index <= 1">
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-5  pl-0" label="Status" v-model="form.status" :reduce="status => status.code" :reduceLabel="'status'" :options="status" :rules="'required'" :filterable="false" :clearable="false" @onSearch="statusKeyUp" :disabled="disabled"></ThatsMultiSelect>
                      </template>
                      <template v-else>
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-5 pl-0" label="Status" vid="status" name="status" v-model="form.status_text" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                      </template>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="ETA" vid="eta" name="eta" v-model="form.attr.eta" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-6 pl-0" label="Prepared By" :placeholder="form.prepared_by" vid="preparedby" name="preparedby" v-model="form.prepared_by" size="sm" :disabled="true" />
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-5  pl-0" label="Vendor" v-model="form.vendor_id" :reduce="vendor => vendor.value" :reduceLabel="'label'" :options="vendors" :rules="'required'" :clearable="false" :disabled="disabled" :addButton="true" @newWindow="openVendor"></ThatsMultiSelect>
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Vendor Ref No." vid="vendor_ref" name="vendor_ref" v-model="form.attr.reference" size="sm" :disabled="disabled"/>
                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-5  pl-0" label="Currency" v-model="form.currency_id" :reduce="currency => currency.value" :reduceLabel="'label'" :options="currency" :rules="'required'" :clearable="false" :disabled="disabled"  :addButton="true" @newWindow="openCurrency"></ThatsMultiSelect>
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Coming from" vid="origin" name="origin" v-model="form.attr.origin" size="sm" :disabled="disabled" />
                    <ThatsFile labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Attachment" vid="attachment" name="attachment" v-model="form.attachment" :disabled="disabled"></ThatsFile>
                  </mdb-col>    
                </mdb-row>

               

                
          <el-table border :data="form.items" class="input-table mt-2" style="width: 100%" :height="'450px'" @selection-change="handleSelectionChange" :key="tableKey">
                  <template v-if="formStatus === 'create' && form.status_index <= 1">
                    <el-table-column type="selection" width="35"> </el-table-column>
                  </template>
                  <el-table-column type="index" width="50" label="#"> </el-table-column>
                  <el-table-column label="SKU" :width="150">
                    <template slot-scope="scope">
                      {{scope.row.code}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Image" width="70">
                    <template slot-scope="scope">
                      <img :src="scope.row.default" style="width:70px" />
                    </template>
                  </el-table-column>
                  <el-table-column label="SERIES" :width="150">
                    <template slot-scope="scope">
                      {{scope.row.series}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Item Name">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.description" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Qty" :width="60" align="right">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.qty" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                    </template>
                  </el-table-column>
                  <el-table-column label="BOM" :width="60" align="right">
                    <template slot-scope="scope">
                      {{scope.row.bom_qty}}
                    </template>
                  </el-table-column>
                  <el-table-column label="TOTAL" :width="60" align="right">
                    <template slot-scope="scope">
                      {{scope.row.total_qty}}
                    </template>
                  </el-table-column>
                  <el-table-column label="Dimension" :width="200">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.dimension" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                    </template>
                  </el-table-column>

                  
                  <el-table-column label="PRINT TAG" :width="100">
                    <template slot-scope="scope">
                      <template v-if="scope.row.part_id !== null">
                      <a href="javascript:void(0)" @click="fnPrintDoc(`good-received/print/tagging/${form.series}/${scope.row.code}/${scope.row.part_id}`)" class="btn btn-sm btn-elegant">PRINT</a>
                      </template>
                      <template v-else>
                      <a href="javascript:void(0)" @click="fnPrintDoc(`good-received/print/tagging/${form.series}/${scope.row.code}`)" class="btn btn-sm btn-elegant">PRINT</a>
                      </template>
                    </template>
                  </el-table-column>
                  
                </el-table>
                
              </div>
       </ThatsWindow>
          </ValidationObserver>

      <BrandForm ref="vendorForm" :group="2" @success="vendorSuccess"></BrandForm>
      <ForeignCurrencyForm ref="foreignCurrencyForm" :group="2" @success="currencySuccess"></ForeignCurrencyForm>
    
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import BrandForm from '../brand/BrandForm'
import ForeignCurrencyForm from '../currency/ForeignCurrencyForm'

export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,

        BrandForm,
        ForeignCurrencyForm,
  },
    
    data : () => ({
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      status : [
            { status : "Draft", code : "draft"},
            { status : "For Approval", code : "for-approval"},
      ],
      items : [],
      selected : [],
      vendors : [],
      currency : [],
      tableKey : 0,

    }),

    created : function() {
      var self = this;

      if(this.formStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
        

      }
       this.optionsHandler();

      //  this.isGeneralManager();
    },



    methods: {
      
      // ...mapActions('VendorsModule',[ 'GetVendors' ]),
      ...mapActions('ForeigncurrencyModule',[ 'GetForeigncurrency' ]),
      ...mapActions('BrandModule',[ 'GetBrands','GetArea' ]),
      ...mapActions('IncomingShipmentModule',[
        'StoreRecord',
        'UpdateRecord',
        'filterItems',
        'updateStatus',
        'sendToGeneralManager',
      ]),
      ...mapMutations('IncomingShipmentModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
          series : 0,
          date : "",
          status_index : 0,
          status : "draft",
          attr : {
              container : null,
              eta : null,
              reference : null,
              origin : null,
          },
          user_id : null,
          prepared_by : null,
          currency_id : null,
          vendor_id : null,
          attachment : [],
          items : [
            {
              code: null,
              description: null,
              qty: null,
              remarks: null
            },
            
          ]
        }
      },
/* start */
optionsHandler(){
  // this.getPurchaseOrder()
  this.GetForeigncurrency().then((response) => {
      this.currency = response
  })
  this.GetBrands().then((response) => {
      this.vendors = response
  })
},

checkGM(){
  return this.$helpers.isGeneralManager()
},
openCurrency(){
  this.$refs.foreignCurrencyForm.openDialog()
},
openVendor(){
  this.$refs.vendorForm.openDialog()
},

vendorSuccess(v){
    const response = v.data;
    const data = {
              value : parseInt(response.mdbID),
              label : response.name,
          }
          this.vendors.push(data);      
},
currencySuccess(v){
    const response = v.data;
    const data = {
              value : parseInt(response.mdbID),
              label : response.name,
          }
          this.currency.push(data);      
},

sendToGeneralManagerClick(){
  // this.sendLoading = true
 const self = this;
  MessageBox.confirm('This PO #'+self.form.series +' will be send to General Manager. Are you sure you want to send?.', 'Warning', {
          confirmButtonText: 'SEND',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {
            const formData = { 
                hash : self.form.token_id,
              }
              if(!self.sendLoading){
                self.sendLoading = true
                 self.sendToGeneralManager(formData).then(() => {
                    self.$ThatsNotify.success({
                      message : "Record was successfully sent"
                    })
                    self.sendLoading = false
                  })
                  
                  
              }

          })

},

approvePurchaseOrder(){
  const self = this;
  if(self.formStatus == 'update' && self.form.status == 'for-approval'){

     MessageBox.confirm('Are you sure you want to approve this record', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {

            const formData = { 
              hash : self.form.token_id,
              status : 'approved'
              }
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                self.$ThatsNotify.success({
                  message : "Record status was successfully change to approved"
                })
                  self.closeDialog();
                  self.$emit('success')
                  self.FormResetter();
                  
              }).catch((error) => {
                self.formLoading = false
                self.response.error = 1;
                if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                      this.showError = true
                    }
                  } else if (error.request) {
                      self.response.message = error.request
                  } else {
                      self.response.message = error.message
                  }
                  return;
              })

          })
      
  }
},
sendPurchaseOrderToVendor(){
  const self = this;
  if(self.formStatus == 'update' && self.form.status == 'approved'){
     MessageBox.confirm('Are you sure you want to send this record to vendor', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {

            const formData = { 
              hash : self.form.token_id,
              status : 'sent'
              }
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                self.$ThatsNotify.success({
                  message : "Record status was successfully sent to vendor"
                })
                  self.closeDialog();
                  self.$emit('success')
                  self.FormResetter();
                  
              }).catch((error) => {
                self.formLoading = false
                self.response.error = 1;
                if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                      this.showError = true
                    }
                  } else if (error.request) {
                      self.response.message = error.request
                  } else {
                      self.response.message = error.message
                  }
                  return;
              })

          })
      
  }
},

updatePurchaseOrderStatus(status){
  const self = this;
  if(self.formStatus == 'update'){
     MessageBox.confirm('Are you sure you want to change status?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {

            const formData = { 
              hash : self.form.token_id,
              status : status
              }
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                self.$ThatsNotify.success({
                  message : "Record status was successfully changed"
                })
                  self.closeDialog();
                  self.$emit('success')
                  self.FormResetter();
                  
              }).catch((error) => {
                self.formLoading = false
                self.response.error = 1;
                if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                      this.showError = true
                    }
                  } else if (error.request) {
                      self.response.message = error.request
                  } else {
                      self.response.message = error.message
                  }
                  return;
              })

          })
      
  }
},
async setPreparedBy(){
    var self = this;
    const loggedUser = await userData()

    const frm = this.form
    frm.prepared_by = loggedUser.user.name

    this.form = frm;
},


deleteItem(){
/*     var myArr = [{id:'a'},{id:'myid'},{id:'c'}];
var index = arr.findIndex(function(o){
     return o.id === 'myid';
})
if (index !== -1) myArr.splice(index, 1); */

 

if(this.selected.length){
    if(this.formStatus === 'update'){
        MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {
              this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          code: null,
                          description: null,
                          qty: null,
                          remarks: null
                        }
                      )
              }
          })
    }else{
      this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          code: null,
                          description: null,
                          qty: null,
                          remarks: null
                        }
                      )
              }
    }
  

  
}


// remove object


},

handleSelectionChange(v){
  this.selected = v
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              this.form.items.push(
                    {
                      code: null,
                      description: null,
                      qty: null,
                      remarks: null
                    }
                  )
            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    if(getSelected){
      this.$set(this.form.items[index], 'description', getSelected.label)
      this.$set(this.form.items[index], 'qty', 0)
      // this.addNewRow(index)
      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            this.form.items.push(
                        {
                          code: null,
                          description: null,
                          qty: null,
                          remarks: null
                        }
                      )
        }
    }
},

statusKeyUp(v){
// this.getPurchaseOrder(v)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();
              
            }

      },
      nextCode(){
        var self = this;
       
       
      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.StoreRecord(self.form).then(() => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.UpdateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success')
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
      },
      
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }

        

      },
      emitDelete(){
        this.$emit('delete',this.formRecord)
      },

      /* isGeneralManager(){
            const self = this;
            const authorized = ["general-manager","administrator"];
            if(self.user.role_slugs.length > 0 && appSettings.po_recipient.user_id === self.user.id){
                  return authorized.some(r=> self.user.role_slugs.includes(r))
            }
            return false;
        } */
    fnPrintDoc(url){

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(`https://docs.thatsliving.com/${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
      },
      

    },

    computed: {

      ...mapGetters('IncomingShipmentModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

    

      disabled : function(){
          var self = this;
          if(self.formStatus === 'update' && self.form.status_index > 1){
            return true
          }
          return true;
      }
    },

    watch: {
      formRecord : function(v){
        console.log("watch")
        console.log(v)
        if(this.formStatus == 'update'){
           this.form = JSON.parse(JSON.stringify(v));
          this.formOriginal = JSON.parse(JSON.stringify(v));
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>