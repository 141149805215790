import { ApiHttpService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const HistoryModule = {
    namespaced: true,
    state: {
        module : 'sales-quotation',
        moduleRecordID : 1,
        tblKey: 0,
        request : null,
        loading: false,
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    align: 'left',
                    width : '180px'
                },
                {
                    label: 'User',
                    field: 'user',
                    sort: 'asc',
                    align: 'left',
                    width : '200px'
                },
                {
                    label: 'Description',
                    field: 'description',
                    sort: 'asc',
                    align: 'left',
                },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    align: 'left',
                    width : '80px'
                },
            ],
            rows: [],
        },
        length: 100,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        Warehouse: [],
        payment_references: [],
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {}

    
    },
    actions: {



      


        async asyncGetData({ state, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm, module, moduleRecordID } = state
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "HistoryModule/req",
                        loadingTrue : "HistoryModule/LoadingTrue",
                        addRequest : "HistoryModule/addRequest",
                        cancelRequest : "HistoryModule/cancelRequest",
                        clearOldRequest : "HistoryModule/clearOldRequest"
                    }
            

                    ApiHttpService.get(`history/${module}/${moduleRecordID}`, params,configParams).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        
    },
    mutations: {

        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {

            const form  = {items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    },
                    credit_note_items : []
                }

            state.formRecord = {...form,...payload}
        },
        setWarehouse: (state, payload) => { 
            state.Warehouse = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },
        setPaymentReference: (state, payload) => {
            state.payment_references = payload
        },
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },
        setModule: (state, payload) => {
            state.module = payload
        },
        setRecordId: (state, payload) => {
            state.moduleRecordID = payload
        },

    },
    getters: {

        req: state => state.req,
        loading: state => state.loading,
        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        Warehouse: state => state.Warehouse,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
        payment_references: state => state.payment_references,
        tblKey: state => state.tblKey,
    }
}


