import Axios from 'axios'
import { token } from '../helpers'
export const TypeService = {
    async get(endpoint = '', params) {
        console.log(endpoint)
        return await Axios.get(`/api/type${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


}