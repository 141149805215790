<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm">  
           <!--  <template #filter="{}">
                    <div>test</div>
            </template> -->


            <template #filter="{}">
               <!--  <el-select
                v-model="filterForm.searchTerm"
                multiple
                filterable
                allow-create
                default-first-option
                clearable
                placeholder="Search">
            </el-select> -->

                 <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="kerwords" vid="searchTerm" name="searchTerm" v-model="filterForm.searchTerm" size="sm" :disabled="false" :rules="''"/>
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Category" v-model="filterForm.category" :reduce="label => label.value" :reduceLabel="'label'" :options="categoryComputed" :rules="''"></ThatsMultiSelect>
              

            </template>



            <template #custom-right-culumns="{}">

            </template>

        

        </ThatsTable>


      <!--   <el-dialog title="Item Master" :visible.sync="itemFormDialog" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form">
            <div class="filter-dalog-body p-4">
                <slot name="filter"></slot>
            </div>

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">
                    <mdb-btn color="elegant" class="ml-0" size="sm"><i class="el-icon-search"></i> Save</mdb-btn>
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="itemFormDialog = false"><i class="el-icon-close"></i> Close</mdb-btn>
                </div>
            </div>

        </el-dialog>
 -->
        
        <ItemMasterDialog ref="moduleComponentForm" @success="refreshTable"></ItemMasterDialog>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import IntegrationWindow from '../../components/thats/IntegrationWindow'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import ItemMasterDialog from './ItemMasterDialog.vue';

export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsInput,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput,
        IntegrationWindow,
        ItemMasterDialog
    },
    inject : ['categoryProvider'],
    provide(){
        return {
            btsApproverProvider : () => this.approverData
        }
    },
    data(){
        return {
            endpoint : 'item-master/data/raw-materials',
            approver : [],
            tableKey : 0,
            itemFormDialog : false,
            filterForm : {
                    searchTerm : [],
                    category : null,
                }
        }
    },


    created(){

        
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('ItemMasterModule',['getRecord']),
       ...mapMutations('ItemMasterModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormData']),

        rowClick(row){
            const self = this;

            this.setFormStatusUpdate();
            this.setFormData(JSON.parse(JSON.stringify({...this.initialFormObject,...row})));
            this.$refs.moduleComponentForm.openDialog();
          
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.moduleComponentForm.openDialog();
            // this.itemFormDialog = true
        },
        refreshTable(){
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        clearFilterForm(){
            this.filterForm = JSON.parse(JSON.stringify(
                {
                    searchTerm : null,
                    category : null,
                }
            ))
        }

    },
    computed: {
        ...mapGetters('ItemMasterModule',['getLoading', 'columns','initialFormObject']),

        categoryComputed : function(){
        
        const codeArray = [];
        const categoryObject = [];
        const category = this.categoryProvider();
        
        if(category.length > 0){
          category.forEach(element => {
                if(!codeArray.includes(element.code)){
                  codeArray.push(element.code)
                  categoryObject.push(element)
                }
          });
        }
          return categoryObject.map((item) => {
              return {...item,label : item.category,value : item.code}
          },[])
        },
        
    },
}


</script>