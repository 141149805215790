<template>
<hsc-window-style-metal>
    <hsc-window title="File Upload" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">
        <mdb-container class="pt-4">
          <p class="m-0">Select CSV file</p>
          <label>
                <input type="file" id="file" ref="file"  accept=".csv, text/csv" v-on:change="handleFileUpload()"/>
            </label>
            <mdb-form-inline tag="div">

              <template v-if="loading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <button class="btn btn-sm btn-elegant" type="button" @click="submitFile">Upload</button>
                </template>

              
            </mdb-form-inline>
        </mdb-container>
    </hsc-window>
    </hsc-window-style-metal>
</template>

<script>

import {mdbBtn,mdbIcon, mdbContainer, mdbFormInline } from 'mdbvue'
import {UploadService} from '../../services'
export default {
    name : "FileUpload",
    components : { 
      mdbBtn,mdbIcon, mdbContainer,mdbFormInline
    },
    props : {
      group : {
            type : Number,
            default : 1
        },
      height : {
        type : Number,
            default : 160
      },
      width : {
        type : Number,
            default : 400
      },
      endpoint : {
        type : String,
            default : 'item/import'
      }
    },
    data () {
      return {
        file: '',
        uploadPercentage: 0,
         x : 0,
            y : 0,
        loading : false,
        dialogIsOpen : false,
    

      }
    },
/* 
 */
    methods: {
        
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

       handleFileUpload(){
        this.file = this.$refs.file.files[0];
        },
        submitFile(){
          if(!this.loading){
            this.loading = true
            UploadService.importFile(this.endpoint,this.file)
                    .then(response => {
                      this.$emit('uploadSuccess',response.data);
                      this.loading = false
                      this.closeDialog();
                      return;
                    });
          }
                    
        }, 

    },


    watch: {
      dialogIsOpen : function(v){
        if(!v){
          this.selectedFiles = [];
          this.images = [];
        }
      }
    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
  }
</script>