<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsCustomWindow ref="window" title="Refund Details" windowClass="collection height-auto" scrollClass='collection height-auto' :height="500" :width="850" @dialog-state="dialogState" :group="group">
           <div class="scrollbar height-auto">
                <mdb-row class="mb-0 mr-0 ml-0 mt-2">
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Collected By" :placeholder="form.attr.collected_by" vid="collected_by" name="collected_by" v-model="form.attr.collected_by" size="sm" :disabled="disabled" :rules="'required'" />
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Identification No." vid="identification_no" name="identification_no" v-model="form.attr.identification_no" size="sm" :disabled="disabled" :rules="'required'"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Mobile No." vid="mobile_no" name="mobile_no" v-model="form.attr.mobile_no" size="sm" :disabled="disabled" :rules="'required'"/>
                      <ThatsFloat tabindex="4"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated" ></ThatsFloat>
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Sales Invoice ID" vid="tracking_no" name="tracking_no" v-model="form.attr.reference" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Refund Receipt ID" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Process By" vid="processed_by" name="processed_by" v-model="form.attr.process_by" size="sm" :disabled="true"/>
                  </mdb-col>    
                </mdb-row>
            </div>     
            <div class="pl-3 pr-3">
                <div class="d-flex">
          
                   <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="disabledBtn">Save</mdb-btn>
                      </template>


                      <template v-if="formSavePrintLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitFormPrint)" :disabled="disabledBtn">Save & Print</mdb-btn>
                      </template>
                </div>
            </div>
       </ThatsCustomWindow>
          </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsFloat} from '../../components/thats/Thats'

export default {
  props : {
    group : {
      type : Number,
      default : 2
    },
    payment_for : {
      type : String,
      default : 'sales-order'
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomWindow,
        ThatsFloat,
        ThatsCustomMultiSelect, 
  },

    data : () => ({
      active : 0,
      showError : false,
      disabled : false,
      disabledBtn : false,
      formLoading : false,
      formSavePrintLoading : false,
      validated : false,
      formOriginal : null,
      form : {},
      response : {
        error : null,
        message : null
      },

    }),

    created : function() {
      var self = this;

      this.form = this.formVar();
      this.currentDateSetter();
      this.optionsHandler();
    },



    methods: {

        ...mapMutations('RefundCollectionModule',[
          'setFormRecord'
        ]),

      formVar(){
        return {
          date : "",
            series : "",
            attr : {
                tracking_no : "",
                collected_by : "",
                identification_no : "",
                mobile_no : "",
                process_by : "",
            },
          amount : 0
        }
      },
      /* start */




      optionsHandler(){

      },

      /* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },



      onSubmitForm(action){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;
            this.formLoading = false;
            this.formSavePrintLoading = false
            if (isValid) {
                if(action === 'save'){
                    if(self.formLoading === false){
                      self.formLoading = true
                            self.$emit('save', self.form)
                      }
                }

                if(action ==='save-print'){
                  if(self.formSavePrintLoading === false){
                      self.formSavePrintLoading = true
                      self.$emit('save-print', self.form)
                    }
                }  
                    
            }        
      },
  
      formResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
        this.formSavePrintLoading = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.formResetter();
          return
        }



      },


        onSubmitForm(){
             var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;
            this.formLoading = false;
            this.formSavePrintLoading = false
            if (isValid) {
              if(self.formLoading === false){
                      self.formLoading = true
                            self.$emit('save', self.form)
                      }
            }
        },
      onSubmitFormPrint(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;
            this.formLoading = false;
            this.formSavePrintLoading = false
            if (isValid) {
                if(self.formSavePrintLoading === false){
                  self.formSavePrintLoading = true
                  self.$emit('save-print', self.form)
                }
            }
      },


    },

    computed: {

      ...mapGetters('RefundCollectionModule',
      [
        'formRecord'
      ]),

      },

    watch: {
      formRecord : function(v){
            this.form = v
            this.formLoading = false;
            this.disabledBtn = false;
            this.formSavePrintLoading = false
      } 

    },
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>