import { ApiService, ApiV1Service, TransactionApiService} from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const TransactionModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '150',
                },
                {
                    label: 'Tracking No.',
                    field: 'series',
                    sort: 'asc',
                    clickable: true
                },

                {
                    label: 'Item Name',
                    field: 'item_names',
                },
                {
                    label: 'Customer/Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                },
                {
                    label: 'Total Amount',
                    field: 'total',
                    align : 'right',
                    width: '120',
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    align: 'right',
                    width: '100',
                },
                {
                    label: 'Print',
                    field: 'print',
                    align: 'right',
                    width: '100',
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        Warehouse: [],
        payment_references: [],
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {}


    },
    actions: {
        async getNextCodePayment({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales/payment/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async filterFabric({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiV1Service.get('sales/filter-fabric', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('sales/filter-item', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('sales/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getCustomerCreditNotes({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`sales/credit-note/${params.token_id}`).then((response) => {
                        commit('setViewLoadingFalse')
                        commit('setPaymentReference',response.data.data)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        getPartner() {
            return new Promise((resolve,reject) => {
                ApiService.get(`sales/partner`).then((response) => {
                    const resolveData = [];
                    if(response.data.length > 0){
                        response.data.forEach((el) => {
                            resolveData.push({
                                label : el.name,
                                code : el.id,
                                isDesigner : el.isDesigner,
                                isShowMan : el.isShowMan,
                            })
                        })
                    }
                    resolve(resolveData)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getShowroomManager() {
            return new Promise((resolve,reject) => {
                ApiService.get(`users/showroom-manager`).then((response) => {
                    const resolveData = [];
                    if(response.data.length > 0){
                        response.data.forEach((el) => {
                            resolveData.push({
                                label : el.name,
                                code : el.id,
                            })
                        })
                    }
                    resolve(resolveData)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getCustomerService() {
            return new Promise((resolve,reject) => {
                ApiService.get(`users/customer-service`).then((response) => {
                    const resolveData = [];
                    if(response.data.length > 0){
                        response.data.forEach((el) => {
                            resolveData.push({
                                label : el.name,
                                code : el.id,
                            })
                        })
                    }
                    resolve(resolveData)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async getRecord({ rootState, commit, dispatch }, params) {
            const { viewLoading, tblKey } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`sales/item/${params.token_id}`, params).then((response) => {

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...response.data.data,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            },
                            showroom : {
                                location : response.data.data.get_location.id,
                                prefix : response.data.data.get_location.prefix,
                            }
                             }
                        }

                        if(!responseData.attr.hasOwnProperty('corporate')){
                            responseData.attr.corporate = false
                        }
                        if(!responseData.attr.hasOwnProperty('contact_person')){
                            responseData.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {

                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }


                        const incrementTblKey = tblKey + 1
                        commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('sales/data', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.TransactionModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    TransactionApiService.store('sales',parsePayload,payload.showroom).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }
                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')

                    const updatePayload = JSON.parse(JSON.stringify(payload))
/*                     const payloadConst = payload;
                    payloadConst.items = []; */

                  /*   let responseData = {...updatePayload};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                    series : null,
                                    date : "",
                                    balance : 0,
                                    paid : 0,
                                    attachment : [],
                                    payments : [],
                                }
                            }
                        } */

                    // commit('setFormRecord', responseData)
                    // commit('setFormRecord', updatePayload)

                    TransactionApiService.update('sales',updatePayload,payload.showroom).then((response) => {
                        commit('setPutLoadingFalse')

                        dispatch('getRecord', { token_id: response.data.data.token_id })

                        // self.getRecord({ token_id: self.form.token_id })
                       /*  let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }

                        commit('setFormRecord', responseData)
                        resolve(responseData) */
                        resolve(response.data.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        openRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiService.update('sales/open',updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async deleteRecord({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('sales',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.TransactionModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/save-import',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`${payload.payment_for}/payment`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updatePayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update('sales/update-payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async storeCreditNote({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.TransactionModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/credit-note',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async getQuotation({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.get(`sales/${payload.mobile}/quotation`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async getMyQuotation({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.get(`sales/get-my-quotation`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {

            const form  = {items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    },
                    credit_note_items : [],
                    showroom : {
                        location : null,
                        prefix : null,
                      },
                }

            state.formRecord = {...form,...payload}
        },
        setWarehouse: (state, payload) => {
            state.Warehouse = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setPaymentReference: (state, payload) => {
            state.payment_references = payload
        },
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        Warehouse: state => state.Warehouse,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
        payment_references: state => state.payment_references,
        tblKey: state => state.tblKey,
    }
}


