import Axios from 'axios'

export const AuthService = {
    async login(params){
        return await Axios.post(`/api/login`, params).then(response => {
            if (response.data.token) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response;
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

/*     async logout(){
        const user = JSON.parse(localStorage.getItem('user'))
        return await Axios.post(`/api/logout`, { token: user.token}).then(() => {
            
            return Promise.resolve();
        }).catch((error) => {
            localStorage.removeItem('user');
            return Promise.reject(error)
        })
    }, */

    async sendReset(email){
        return await Axios.post(`/api/password/email`, {email : email}).then(response => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async sendChangePassword(form){
        return await Axios.post(`/api/password/reset`,form).then(response => {
                return Promise.resolve(response);
            }).catch((error) => {
                return Promise.reject(error)
        })
    }
}