import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir
export const SalesService = {
    async post(endpoint,object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id
        }

        const params = { ...location, ...object }
        return await Axios.post(`/api/sales` + endpoint, params, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async store(object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id
        }

        const params = { ...location, ...object}
        return await Axios.post(`/api/sales`, params, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id
        }

        const params = { ...location, ...object }
        return await Axios.get(`/api/sales${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/sales`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/sales`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async updateStatus(endpoint,obj) {
        return await Axios.put(`/api/sales/` + endpoint, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}