<template>
   <!--  <hsc-window-style-metal>
        <hsc-window v-bind:class="$attrs.windowClass" :title="$attrs.title" :resizable="false"  :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight"  :closeButton="closeButton" :isOpen.sync="dialogIsOpen" :zGroup="group"> -->
            <el-dialog :visible.sync="dialogIsOpen" ref="window" :title="$attrs.title" windowClass="collection height-auto " scrollClass='collection height-auto' width="50%" class="custom-table-dialog">
            
            <div class="table-container pr-0 pl-0">
                <el-table ref="multipleTable" lazy class="custom-element-table" :data="rows" :height="400" stripe @selection-change="handleSelectionChange">
                    <template v-if="multiple">
                     <el-table-column type="selection" width="35"> </el-table-column>
                    </template>
          
                        <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                            <template slot-scope="scope">
                                <template v-if="col.clickable">
                                    <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                                </template>
                                <template v-else>
                                    <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                                </template>
                            </template>
                        </el-table-column>

                    <template slot="empty">
                       <template v-if="loading">
                            <mdb-btn color="elegant" size="sm" rounded disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading data...
                            </mdb-btn>
                        
                        </template>
                        <template v-else>
                            No Data
                        </template>
                    </template>
                </el-table>

                
            </div>


            <span slot="footer" class="dialog-footer">
                <div class="d-flex">
                <slot name="custon-button"></slot>
                <template v-if="loading">
                    <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-if="selected.length > 1">
                    <button class="btn btn-sm btn-elegant" type="button" @click="mergeDialog">Merge</button>
                </template>
                <button class="btn btn-sm btn-grey" type="button" @click="closeDialog">Close</button>

                <el-pagination        
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="current_page"
                    :page-sizes="[10,25,50,100, 200, 300, 400]"
                    :page-size="length"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            </span>



            

       <!--  </hsc-window>
    </hsc-window-style-metal> -->

        </el-dialog>
</template>


<script>
import {
  mdbBtn,
  mdbContainer,
  mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle

} from "mdbvue";
import { mapGetters, mapActions, mapMutations  } from 'vuex';
export default {
    name : "ThatsCustomTableWindow",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
        width : {
            type : Number,
            default : 1024
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 600
        },
        minWidth : {
            type : Number,
            default : 600
        },
        closeButton : {
            type : Boolean,
            default : true
        },
        group : {
            type : Number,
            default : 1
        },

        multiple : {
            type : Boolean,
            default : true
        },
        endpoint : {
            type : String,
            default : ''
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,
        }
    },
    methods: {
        ...mapActions('CustomTableModule',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('CustomTableModule',[
            'setLength',
            'setPage',
            'setSearchTerm',
            'setSelection',
            'setEmptyData',
            'setFilters',
        ]),

        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            this.$emit('delete')
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        },
        handleClick(index,row){
            
            this.$emit('open',{value : row, index : index});
        },
        handleSelectionChange(v){
            this.selected = v.map(i => i.series)
        },
        mergeDialog(){
            this.$emit('merge',this.selected);
        },

        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.dispatchHandler()
        },
        clearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        refreshTable(){
            this.resetData(this.endpoint);
        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },
    },
    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
    computed : {
        ...mapGetters('CustomTableModule',['columns','rows','loading','selection','searchTerm','length','total','page']),

        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.setSearchTerm (val)
            }
        },
        selected : {
            get : function(){
               return this.selection
            },
            set : function(val){
                this.setSelection (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },
    },
    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)
        }
    }
}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }


</style>