<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">
       <mdb-input size="md" :is-invalid="invalid" :class="$attrs.class" :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" :inputClass="hasError(errors)" v-model="inputValue" :disabled="disabled" :label="$attrs.label" :type="type" outline autocomplete="off" />
        <template v-if="show_error == true">
            <span class="errors">{{errors[0]}}</span>
        </template>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'TextInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },
        disabled : {
            type : Boolean,
            default : false
        },
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        }
    },
    watch: {
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>