<template>
  <div>
      <el-dialog :zIndex="(7)" :modal="true" :fullscreen="true" :title="'Contact Details'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" class="text-left tl-dialog tl-dialog-sm">
          <div>
              <div class="dialog-scroll-container pt-0 pb-0 pl-0 pr-0">
                  <template v-if="activeTabSlot == 'main'">

                      <mdb-card class="m-2 sales-report-card">
                        <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                            <div>
                                <h4 class="dark-grey-text mb-0">{{formData.name}}</h4>
                                <p>{{formData.mobile}} <br />{{formData.address}}</p>
                            </div>
                        </mdb-card-body>
                    </mdb-card>
                      <mdb-tabs class="m-1" :active="popupWindowActiveTab" default @activeTab="fnPopupWindowActiveTab" :links="[{ text: 'Dashboard', slot : 'dashboard-slot' },{ text: 'Quotations', slot : 'quotation-slot' },{ text: 'Invoices', slot : 'invoice-slot' },{ text:'Vouchers', slot: 'vouchers'},{ text: 'Contact Details', slot: 'contact-details'},{ text: 'Contact Person', slot: 'contact-person'}]" >
                          <template :slot="'dashboard-slot'">
                              <mdbRow class="tab-row">
                                  <mdbCol md="12" lg="12" class="pr-0 mr-0 pl-0 ml-0">
                                      <Dashboard :title="'Dashboard'" :key="tableKey" :formData="formData"></Dashboard>
                                  </mdbCol>

                              </mdbRow>
                          </template>
                          <template :slot="'quotation-slot'">
                              <QuotationTable :endpoint="'quotations'" :activeTab="popupWindowActiveTab" :key="tableKey" @open="fnOpenRecord" @print="fnPrint" :formData="formData"></QuotationTable>
                          </template>
                          <template :slot="'invoice-slot'">
                              <InvoiceTable :endpoint="'invoices'"  @open="fnOpenRecord" @print="fnPrint"></InvoiceTable>
                          </template>
                          <template :slot="'vouchers'">
<!--                            Vouchers-->
                          </template>
                          <template :slot="'contact-details'">
                              <ContactDetails @success="refreshTable" :detailsActiveTab="popupWindowActiveTab" :formData="formData"></ContactDetails>
                          </template>
                          <template :slot="'contact-person'">
                            <ContactDetailsPerson @success="refreshTable" :detailsActiveTab="popupWindowActiveTab" :formData="formData"></ContactDetailsPerson>
                          </template>
                      </mdb-tabs>


                  </template>
              </div>

          </div>
          <template slot="footer">

            <div class="d-flex">

            <template v-if="formLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </mdb-btn>
                </template>

                <template v-else>
<!--                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>-->
<!--                <template v-if="formStatus == 'update'">-->
<!--                    <mdb-btn color="elegant" size="sm" @click="openHistoryDialog"><i class="el-icon-save text-white cursor-pointer"></i> History</mdb-btn>-->
<!--                </template>-->
                <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                </template>


            </div>
          </template>
      </el-dialog>
      <ContactsForm ref="editContactDialogForm" ></ContactsForm>
      <HistoryDialog ref="historyDialog"></HistoryDialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import {
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbTabs,

} from "mdbvue";

import HistoryDialog from "../../components/thats/HistoryDialog.vue";
import Dashboard from "./Dashboard.vue";
import ContactsForm from "./ContactsForm.vue";
import QuotationTable from "./QuotationTable.vue";
import InvoiceTable from "./InvoiceTable.vue";
import ContactDetails from "./ContactDetails.vue";
import ContactDetailsPerson from "./ContactDetailsPerson.vue";
export default {
  components : {
      ContactDetailsPerson,
      ContactDetails,
      InvoiceTable,
      QuotationTable,
      ContactsForm,
      Dashboard,
    HistoryDialog,
      mdbBtn,
      mdbTabs,
      mdbRow,
      mdbCol,
      mdbCard,
      mdbCardBody,
  },
  props : {
    group : {
          type : Number,
          default : 1
      },

  },
  inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
  data(){
      return {
          syncModal : false,
          customerItems : `customer-items`,
          tableKey : 0,
          activeTabIndex : 0,
          activeTabObject : { text: 'Details', tabSlot : 'main' },
          mdbTabsData : [
              { text: 'Details', tabSlot : 'main' }
          ],
        activeTab: "dashboard",
        active: 0,

          group2 : 5,
          formData : Object,
          formLoading : false,
          readOnly : false,
          showError : true,
          validated : true,
          itemType : [
              {label : "Stock", value : "stock"},
              {label : "Services", value : "services"},
          ],

          itemWebsiteStatus : [
              {label : "Yes", value : "1"},
              {label : "No", value : "0"},
          ],

          itemStatus : [
              {label : "Archive", value : "2"},
              {label : "Active", value : "1"},
              {label : "Discontinued", value : "0"},
          ],
        showCopyButton: false,
        showCopyMessage: false,
        copied: false,
          inventoryData : [],

          filterAttributes : [],

          finishes : [],
          fabric : [],
          materials : [],
          materials1 : [],
          colour : [],
          colour1 : [],
          locations : [],
          vendors : [],
          brands : [],
          collections : [],
          category : [],
          attributes : [],
          area : [],
          items : [],
          itemDiscountPercentConfig : {
                  decimal: '.',
                  thousands: ',',
                  prefix: '',
                  suffix: ' %',
                  precision: 2,
                  masked: false
          },
          popupWindowActiveTab : 0,
          form: null,
          popupInvoiceEndpoint : 'invoices-popup',
          popupQuotationEndpoint : '',
      }
  },
  created(){
      // this.formData = JSON.parse(JSON.stringify(this.formObject))

  },
  mounted(){
      const self = this
      console.log("mounted")
          console.log(this.categoryComputed)

  },
  methods : {

      ...mapMutations('ContactSalesReportModule', ['setLoading','cancelRequest']),
      ...mapActions('ContactCustomTableModule',{
          getQuotationData : 'resetData',
      }),
      ...mapMutations('ContactCustomTableModule',{
          setQuotationFilters : 'setFilters',
      }),
      ...mapActions('ContactInvoicesTableModule',{
          getInvoiceData : 'resetData',
      }),
      ...mapMutations('ContactInvoicesTableModule',{
          setInvoiceFilters : 'setFilters',
      }),
      ...mapActions('TableModule',[
          'asyncGetData',
          'resetData',
      ]),
      passes(callback) {
          if (typeof callback === 'function') {
              callback();
          }
      },
      openDialog(){
          this.syncModal = true

      },
      fnPopupWindowActiveTab(v){
          console.log("Val Swaraj ", v)
          this.popupWindowActiveTab = v
      },
      fnOpenRecord(record){
          console.log('fnOpenRecord')
          console.log(record)
          this.$emit('open', record);
      },
      fnPrint(obj){

          console.log('fnPrint')
          console.log(obj)

          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

          let session = JSON.parse(localStorage.getItem('user'));
          let token = session.token;

          const locationId = session.location.id

          if(obj.origin === 'invoice'){
              const win = window.open(`/sales/invoice/${obj.value.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
              win.document.title = 'Print';
              win.print()
          }

          if(obj.origin === 'quotation'){
              const win = window.open(`/sales/transactions-v3/${obj.value.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
              win.document.title = 'Print';
              win.print()
          }



          // this.printUrl = url
          // this.$refs.printWindow.openDialog();
      },
      closeDialog(){
          this.syncModal = false
        this.asyncGetData('contacts/data');
      },
      refreshTable(){
          this.$emit('success')
      },
      resetDefaultTab(){
          this.activeTabIndex = 0,
          this.activeTabObject = { text: 'Details', tabSlot : 'main' }
      },
      getActiveTab(v){
          this.activeTabIndex = v
          this.activeTabObject = this.mdbTabsData[v];
      },



      printCard(customer){
        let session = JSON.parse(localStorage.getItem('user'));
        let token = session.token;

        const locationId = session.location.id

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(`loyalty-program/print-card/${customer.series}?token=${token}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
      },




},

  computed : {
      ...mapGetters('ContactsModule',
          [
              'FormStatus',
              'FormRecord',
              'Loading'
          ]),
      ...mapGetters('ContactSalesReportModule', ['loading','initFormData']),
      activeTabSlot : function(){
          return this.activeTabObject.tabSlot
      },

      formattedExpirationDate() {
          const date = new Date(this.formData.expiration_date);
          const month = date.getMonth() + 1;
          const year = date.getFullYear().toString().slice(-2);
          return `${month.toString().padStart(2, '0')}/${year}`;
      },
    formattedCardNumber() {
      const cardNumber = this.formData.card_number;


          if (cardNumber && cardNumber.length >= 8) {
              const first4Digits = cardNumber.substring(0, 4);
              const last4Digits = cardNumber.substring(cardNumber.length - 4);
              const middleDigits = cardNumber.substring(4, cardNumber.length - 4);


              const formattedNumber = `${first4Digits} ${middleDigits} ${last4Digits}`;
              return formattedNumber;
          }

          return cardNumber;
    },

    readOnlyComputed : function(){
          return (this.$helpers.isSystemAdministrator() || this.$helpers.isShowroomManager() || this.$helpers.isBackOffice()) ? false : true
      },




  },

  watch : {
      FormRecord : function(){
          if(this.FormStatus == 'update'){

              const formRow = this.FormRecord.row;
              if(!formRow.attr.hasOwnProperty('corporate')){
                  formRow.attr.corporate = false
              }
              if(!formRow.attr.hasOwnProperty('contact_persons')){
                  formRow.attr.contact_persons = []
              }


              formRow.attr = {
                  ...formRow.attr,
                  address2 : {
                      street : null,
                      building : null,
                      zone : null,
                      area : null,
                      city : null,
                      ...formRow.attr.address2
                  },
              }


              this.formData = JSON.parse(JSON.stringify(formRow));
              console.log(formRow, "Swaraj formRow");
              this.customerItems = `customer-items/${this.formData.series}`

          }
      },
      syncModal : function(value){
        const self = this;
          if(!value){
              this.cancelRequest();
              this.resetDefaultTab();
              this.closeDialog();
              // this.formData = JSON.parse(JSON.stringify(this.formObject))
              // console.log(this.formData);
              this.passes(() => {
                  this.setLoading(false);
              });
          }

          if(value){
              this.tableKey++
              this.form = this.initFormData;
          }
      },
      formData(val) {
          if (val) {
              this.form = val;

              if (this.form.name !== null) {
                  this.setQuotationFilters({
                      dateRange: {},
                      series: "",
                      sold_to: "",
                      customer_mobile: "",
                      customer_name: "",
                      item_code: "",
                      status: "",
                      balance: false,
                      card_code: this.formData.series,
                  });
                  this.getQuotationData('quotations');

                  this.setInvoiceFilters({
                      dateRange: {},
                      series: "",
                      sold_to: "",
                      customer_mobile: "",
                      customer_name: "",
                      item_code: "",
                      status: "",
                      balance: false,
                      card_code: this.formData.series,
                  });
                  this.getInvoiceData('invoices');
              }
          }
      },
  }
}
</script>
<style scoped lang="scss">
.sales-report-card.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}


.tab-content{
    height:auto!important;
}
</style>
