<template>
  <div class="itmform-container">
    <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
        <li @click="deleteRow">Delete</li>
    </ul>

    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="LABEL PRINT" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="800" :width="900" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>

              <template v-if="viewLoading || formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="(formStatus == 'update' || formStatus == 'create')">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading || (selectedItems.length > 0 ? false : true)">PRINT</mdb-btn>
                   <!--  <mdb-btn color="elegant" size="sm" @click="fnPrint(`document/export/${form.id}`)" :disabled="viewLoading">Export to Excel</mdb-btn> -->
                  </template>                 
              </template>
              </template>

          <div class="mt-1 pl-3 pr-3">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
                 <el-table :data="form.items" :summary-method="getBinItemSummary" show-summary class="bin-table" @row-contextmenu="rowContectMenu">
                    <el-table-column label="SQ" type="index" width="50"></el-table-column>
                    <el-table-column label="SKU">
                        <template slot-scope="scope">
                            <ThatsGridSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)"></ThatsGridSelect>
                        </template>
                    </el-table-column>
                    <el-table-column label="QTY" align="center" width="100">
                        <template slot-scope="scope">
                          <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.qty" @keydown.enter="addNewRow(scope.$index)" />
                        </template>
                    </el-table-column>
                  </el-table>
              </template>
            </mdb-tabs>
            
            
          </div>
       </ThatsWindow>
          </ValidationObserver>
     
      
  </div>
</template>
<script>
import { mdbClickOutside , mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox} from '../../components/thats/Thats'

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  //#region[VueComponent]
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect, 


  },
  //#endregion
    
    data : () => ({
      context: null,
      viewMenu: false,
      top: '0px',
      left: '0px',
      active : 1,
      validated : false,
      form : null,
      formOriginal : null,
      formLoading : false,
      
      selected : [],
      items : [],
      tabs : [
                { text: 'ITEMS', slot: 'form-slot' }
              ],
              
    }),
    

  created : function() {
      var self = this;
      
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        this.form.items.push(this.formVarItem());
        this.tabs = [
                { text: 'ITEMS', slot: 'form-slot' },
              ]
        
      }
       this.optionsHandler();
    
    },

  mounted(){
  },

  updated(){

  },
  //#region[VueMethod]
    methods: {
      ...mapActions('LabelPrintingModule',['storeRecord','filterItems']),
      ...mapMutations('LabelPrintingModule',['setFormStatusCreate']),
      formVar(){
        return {
          items : []
        }
      },
      formVarItem(){
        return {
          code : null,
          qty : 1
        }
      },
/* start */

    optionsHandler(){
      const self = this;
      
    },
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      onSubmitFormPurchaseRequest(){
            var self =this
            self.form.create_purchase_order = true;
            self.onSubmitForm();
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false


          
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      
                        self.formLoading = true
                        self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully created"
                              })
                              self.$emit('success')
                              self.formLoading = false

                              if(response.data.length > 0){
                                const items = response.data.map(i => i.id).toString();
                                self.fnPrint(`label-print?items=${items}`)
                              }
                              self.closeDialog();
                            }).catch((error) => {

                              self.formLoading = false
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                    }
                    
            }        
      },
      FormResetter(){
        this.form = this.formVar()
        this.form.items.push(this.formVarItem());
       
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        
        this.tabs = [
                { text: 'ITEMS', slot: 'form-slot' }
              ]
              

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
            this.tabs = [
                { text: 'ITEMS', slot: 'form-slot' }
              ]
          
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      getActiveTabIndex(index){
        this.active = index
      },

      binCell({column}){
        console.log(column)
        return column.property
      },
      getBinItemSummary({columns,data}){

        // console.log(data)
        const sums = [];
          columns.forEach((col,index) => {
               if (index === 1) {
                sums[index] = 'Total';
                return;
              }

              if (index === 2) {
              const qty = data.map(item => {
                  return parseInt(item.qty)
                })

                if (!qty.every(qty => isNaN(qty))) {
                    sums[index] = qty.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                      return prev + curr;
                    } else {
                      return prev;
                    }
                  }, 0) - 1;
                } 
              return;
            }
            
          })

          return sums;
      },

      fnPrint(url){
        // alert(s)

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(`https://docs.thatsliving.com/${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
      },
      getSelected(v,index){
        const getSelected = this.items.filter(item => { 
              return (item.code === v)
          })[0];

          if(getSelected){
            this.$set(this.form.items[index], 'description', getSelected.label)
            this.$set(this.form.items[index], 'qty', 1)
            // this.addNewRow(index)
            var dataLength = this.form.items.length - 1;
              if(dataLength === index){
                  this.form.items.push(
                              {
                                code: null,
                                qty: 1,
                              }
                            )
              }
          }
      },
      statusKeyUpFocus(v){
          const localItems = this.items;
        const filterOnFocus = [];
        if(localItems.length > 0){
          localItems.forEach(item => {
            if(this.selectedItems.indexOf(item.code) < 0){
              filterOnFocus.push(item)
            }
          });

          this.items = filterOnFocus
        }
      },
      statusKeyUp(v){
          this.getItems(v);
      },

      getItems(v){
        const results = [];
        this.filterItems({term : v, items : this.selectedItems}).then((response) =>{
              if(response.length > 0){
                response.forEach(element => {
                  results.push({label : element.name, code : element.series})
                });
              }
          }).catch(error => {
            console.log(error)
          })
        this.items = results
      },
       closeMenu() {
            this.viewMenu = false;
            this.context = null
        },
         setMenu(top, left) {
        
        const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },
      deleteRow(){
          const self = this
            if(this.context != null){
              if(self.context.hasOwnProperty('code')){
                   const matchItem = this.form.items.filter((i) => {
                    return i.code != self.context.code;
                  })
                  self.form.items = matchItem
              }
            
              this.closeMenu()
            }

        },
       

        rowContectMenu(row,_col,e){
        const self = this
        if(row.code !== null){
          
          this.context = row
            this.viewMenu = true;
              this.$nextTick(function() {
                self.$refs.right.focus();
                self.setMenu(e.y, e.x)
              }.bind(this));
              e.preventDefault();
                }
      },
      handleOutsideClick(){
        const self = this;
         this.context = null
            this.viewMenu = true;
      },

    },
//#endregion
//#region[VueComputed]
    computed: {

      ...mapGetters('PhysicalCountModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
        'tblKey',
      ]),
     
     selectedItems : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },
      
    },
//endregion
    watch: {
     
      formRecord : function(v){
        const self = this;
        this.active = 0;
        if(this.formStatus == 'update'){
                const jsonParse = JSON.parse(JSON.stringify(v));
                
                this.form = jsonParse;
                this.formOriginal = JSON.parse(JSON.stringify(jsonParse));

        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          
        }
      },
      
    }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}
.grid-input{
  padding : 0;height: 30px;border-radius: 0;
}

</style>