
<template>
    <div class="customervendor-container">
        <ThatsTable title="Others Details" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns"></ThatsTable>
        <OthersForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></OthersForm>
    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import OthersForm from './OthersForm'
import ThatsTable from '../../components/thats/Table'
export default {
    data(){
        return {
            endpoint : 'others/data'
        }
    },
    components: {
        OthersForm,
        ThatsTable
    },

    methods: {
        ...mapActions('OthersModule',[ 'DeleteRecord' ]),
        ...mapMutations('OthersModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })

        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        }
    },
    computed: {
        ...mapGetters('OthersModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>