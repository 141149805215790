<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsCustomWindow ref="window" title="Discount Option" windowClass="collection height-auto" scrollClass='collection height-auto' :height="300" :width="500" @dialog-state="dialogState" :group="3">
           <div class="scrollbar pt-3">
               <div class="container">
                 <template v-if="form.discount_type !== null && form.promotions.length > 0">
                    <mdb-row>
                      <mdb-col col="12">
                          <mdb-input type="radio" id="promo" name="promo" radioValue="promo" v-model="form.discount_type" label="Promo" />
                          <mdb-row>
                                <mdb-col col="8" offsetMd="2" >
                                    <label class="label" v-html="form.remarks"></label>
                                </mdb-col>
                              </mdb-row>

                      </mdb-col>  
                    </mdb-row>
                    
                    <mdb-row>
                      <mdb-col col="12">
                          <mdb-input type="radio" id="discount" name="discount" radioValue="discount" v-model="form.discount_type" label="Custom Discount" />
                        </mdb-col>   
                    </mdb-row>
                  </template>
                            <mdb-row>
                            <mdb-col col="12" class="pl-2">
                              <mdb-row>
                                <mdb-col col="8" offsetMd="4" >
                                  <el-checkbox v-model="form.custom_discount.apply">Apply Discount %</el-checkbox>
                                </mdb-col>
                              </mdb-row>


                            <mdb-row>
                            <mdb-col col="12" >
                                  <div class="form-group input-group-sm row thats-form">
                                      <label class="col-form-label col-sm-3">Base Price</label>
                                      <div class="col-sm-9 pl-0">
                                      <div class="md-form mt-0">
                                          <input type="text" class="form-control text-right" id="applydiscount" disabled  v-model="form.formatted_price">
                                      </div>
                                      </div>
                                  </div>
                                  <div class="form-group input-group-sm row thats-form">
                                      <label class="col-form-label col-sm-3">Discount</label>
                                      <div class="col-sm-9 pl-0">
                                      <div class="md-form mt-0">
                                         <ValidationProvider name="discount" vid="discount" rules="required|min_value:0" v-slot="{ errors }">
                                          <input type="text" class="form-control text-right" id="applydiscount" @keyup="computeDiscount()" @keydown="checkKeyCode($event)"  v-model="form.custom_discount.discount">
                                         <span class="text-red small">{{ errors[0] }}</span>
                                         </ValidationProvider>
                                      </div>
                                      </div>
                                  </div>

                                  <div class="form-group input-group-sm row thats-form">
                                      <label class="col-form-label col-sm-3">Off Price</label>
                                      <div class="col-sm-9 pl-0">
                                      <div class="md-form mt-0">
                                          <input type="text" class="form-control text-right"  disabled v-model="form.custom_discount.off_price">
                                      </div>
                                      </div>
                                  </div>

                                  <div class="form-group input-group-sm row thats-form">
                                      <label class="col-form-label col-sm-3">Promo Price</label>
                                      <div class="col-sm-9 pl-0">
                                      <div class="md-form mt-0">
                                          <input type="text" class="form-control text-right" disabled v-model="form.custom_discount.promo">
                                      </div>
                                      </div>
                                  </div>

                                    </mdb-col>   
                                  </mdb-row>
                                </mdb-col>   
                            </mdb-row>

               </div>
                
        </div>     
        <mdb-container>
            <div class="d-flex">
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Apply Discount</mdb-btn>
                  <mdb-btn color="grey" size="sm" @click="closeDialog">Cancel</mdb-btn>
                </template>
            </div>
        </mdb-container>
       </ThatsCustomWindow>
    </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbInput} from "mdbvue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCustomWindow} from '../../components/thats/Thats'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,

        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        mdbInput,
        ThatsCustomWindow

  },

    data : () => ({
      active : 0,
      disabled : false,
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      index : null,
    

    }),

    created : function() {
      var self = this;
      this.form = this.formVar();
      // this.optionsHandler();
      //  this.isGeneralManager();
    },



    methods: {
      formVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null
              }
      },
/* start */
checkKeyCode(evt) {
      var keyCode = evt.which ? evt.which : evt.keyCode;
            
            if([8, 9, 13, 27, 46, 110, 190, 173, 189, 37,39, 46].includes(keyCode) || (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)){
                return true
              }
            evt.preventDefault();
            
},
computeDiscount(){
  const self = this;
  const {custom_discount, price} = this.form;
  const {discount, apply} = custom_discount;

  // console.log(discount);

  const unFormatPrice = unformat(price);
  let unFormatDiscount = unformat(discount);

  // console.log(unFormatDiscount)

  if(unFormatDiscount !== null){

    
    
    if(parseInt(unFormatDiscount) > 0){
      self.form.discount_type = 'discount'
    }else{
      self.form.discount_type = null
    }
    
    if(unFormatDiscount === ""){
      unFormatDiscount = 0
    }
    

    if(apply === true || apply === 1){
      const off = (unFormatDiscount / 100) * unFormatPrice;
      const promo = unFormatPrice - off;

      this.form.custom_discount.promo = self.formatNumber(promo)
      this.form.custom_discount.off_price = self.formatNumber(off)
    }else{

      if(unFormatDiscount === ""){
          unFormatDiscount = 0;
        }
        const promo = unFormatPrice - unFormatDiscount;
        this.form.custom_discount.promo = self.formatNumber(promo)
        this.form.custom_discount.off_price = self.formatNumber(unFormatDiscount)
    }
  
  }


},

computeOff(i){
  const self = this;
  const {custom_discount, price} = this.form;
  const {off_price, apply} = custom_discount;

  if((!apply || apply === 0) && off_price !== null && off_price !== ""){
    this.form.custom_discount.promo = self.formatNumber(price - off_price)
  }
},

formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },

optionsHandler(){

},

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                  if(self.form.custom_discount.discount != ""){
                      if(this.form.discount_type === null ){
                        this.form.discount_type = 'discount';
                      }

                     this.$emit('apply',{
                       index : this.index,
                       form : this.form
                     })
                  }
                  
                  this.closeDialog()
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false


      },
      

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

      },

    formatNumber(n) {
        return formatMoney(n, { symbol: "", precision: 2, thousand: ",", decimal: "." });
    },

    },

   computed: {

      ...mapGetters('DiscountModule',
      [
        'discountForm',
      ]),
    },

    watch: {
      discountForm : function(v){
        this.form = JSON.parse(JSON.stringify(this.discountForm.form));
        this.index = this.discountForm.index
      },

      "form.custom_discount.apply" : function(v){
        const self = this;
          self.computeDiscount()
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>