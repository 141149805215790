<template>
    <hsc-window-style-metal>
        <hsc-window v-bind:class="$attrs.windowClass" :title="$attrs.title" :resizable="false"  :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="maxWidth" :minHeight="minHeight" :maxHeight="maxHeight"  :closeButton="closeButton" :isOpen.sync="dialogIsOpen" :zGroup="group">
            <slot></slot>

             <template v-if="windowFooter">
                 <div class="pl-3 pr-3">
                    <div class="d-flex">
                        <slot name="winFooter"></slot>
                    </div>
                 </div>
            </template>
        </hsc-window>
    </hsc-window-style-metal>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  mdbBtn,
  mdbContainer,
  mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle

} from "mdbvue";
export default {
    name : "ThatsCustomWindow",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
        width : {
            type : Number,
            default : 800
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 450
        },
        maxWidth : {
            type : Number,
            default : 450
        },
        minWidth : {
            type : Number,
            default : 450
        },
        closeButton : {
            type : Boolean,
            default : true
        },
        group : {
            type : Number,
            default : 1
        },
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0
        }
    },
    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            this.$emit('delete')
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        }
       
    },
    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)
        }
    },

    computed : {
        windowFooter() {
            return ('winFooter' in this.$scopedSlots);
        },
    }
}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }

</style>