import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
import { reject } from 'lodash'
export const LabelPrintingModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        viewLoading: false,
        data: {
            columns: [
                {
                    label: 'CODE',
                    field: 'sku_code',
                    sort: 'asc',
                    width: '300',
                },
                {
                    label: 'TOTAL PRINTED',
                    field: 'total_qty',
                    sort: 'asc',
                    width: '200',
                },
                {
                    label: '',
                    field: '',
                    sort: 'asc',
                }

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

    },
    actions: {

        async filterItems({},params) {
            return await new Promise((resolve, reject) => {
                ApiService.get('filter-items', params).then((response) => {
                    console.log(response);
                    resolve(response.data)
                }).catch(error => {
                    console.log("error filter items")
                    console.log(error)
                    reject(error)
                })
            })
        },

        async storeRecord({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    ApiService.store('label-printing',payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async getRecord({ state, commit, dispatch }, params) {
            const { viewLoading, tblKey } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`document/${params.id}`, params).then((response) => {
                        let responseData = {document_bin : [],...response.data.data};
                        // const incrementTblKey = tblKey + 1
                        // commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const {loading} = state;
                // alert(loading)
                if (!loading) {
                    commit('loadingTrue')
                    ApiService.update(`document/${payload.id}`,payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async deleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.WarehouseModule.Deleteloading;
                if (!loading) {
                    commit('deleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.hash)
                    });
                    ApiService.delete('document',tokens).then((response) => {
                        commit('deleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('deleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        }
    },
    mutations: {
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        deleteLoadingFalse: (state) => {
            state.Deleteloading = false
        },
        deleteLoadingTrue: (state) => {
            state.Deleteloading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },

    },
    getters: {
        loading: state => state.loading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
    }
}


