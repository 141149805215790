/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" :title="title" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <div class="m-0">

                    <div ref="spreadsheetexport" class="new-item-table"></div> 

                    <div class="d-flex">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">SAVE</mdb-btn>
                              <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>


                  </div>
            </div>
            </ValidationObserver>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'

import AmountInput from '../../components/thats/AmountInput.vue'
import country from '../generate-serial/country.js'



export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
    type : {
      type : String,
      default : 'material'
    },
    title : {
      type : String,
      default : 'Variation'
    },
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }

    /* 
    
     {"name": "Afghanistan", "id": "AF", "area" : "f"},
            {"name": "Aland Islands", "id": "AX", "area" : "c"},
            {"name": "Albania", "id": "AL", "area" : "c"},
            {"name": "Algeria", "id": "DZ", "area" : "f"}
            
            */
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        countryData : [],
        variationData : [],
        catObjectArray : [],
        variationObjectArray : [],
        finishObjectArray : [],
        colorObjectArray : [],
        fabricObjectArray : [],
        categoryDropDown : [],
        finishDropDown : [],
        colorDropDown : [],
        fabricDropDown : [],
        form : {

            doc_id: null,
            area_id: null,
            origin_code: null,
            vendor_code : null,
            doc_name : null,
            attr : {
              
            },
          items :[]
        },
        newItemData : [],
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 400,
        width: 800,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'area',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '800px',
            tableHeight : '400px',
            tableOverflow : true,
            data:[],

             minDimensions:[3,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 0,
             columns: [],

            
          updateTable:function(instance, cell, col, row, val, label, cellName) {

              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
            },

             contextMenu: function(obj, x, y, e) {
                var items = [];


                // console.log(obj)


              

                    if(y !== null){
                        
                        if (obj.options.allowInsertRow == true) {
                            /* items.push({
                                title:obj.options.text.insertANewRowBefore,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y), 1);
                                }
                            });
                             */
                            
                            const colData = obj.getColumnData(0)

                               
                              if(colData.length == (parseInt(y)+1)){
                                
                                items.push({
                                    title:obj.options.text.insertANewRowAfter,
                                    onclick:function() {
                                        obj.insertRow(1, parseInt(y));
                                    }
                                });
                              }

                        }
                    }

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });

             }
                return items;
             },

             onblur: function(instance){

              console.log("blur")
              console.log(instance)

             },
             onchange: function(instance, cell, x, y, value){
                 
             },
            onafterchanges(){
               self.updateStyleOnChange()
            },
            updateTable: function (instance, cell, col, row, val, label, cellName) {

              const id = instance.jexcel.options.data[row][2]

              if(col == 0){
                if(id !== '' && parseInt(id) > 0){
                  cell.classList.add('readonly');
                  }
              }
              
            },
             
             
       }
    }
  },
    

  created : function() {


        this.maxHeight = window.innerHeight;
  },

  mounted(){
        const self = this
        this.countryData = country

        
  },

  updated(){

  },
  methods: {

    
    ...mapActions('CodeGeneratorVariationModule',['createUpdateRecord','getRecords']),

    updateStyleOnChange(){
      const self = this


        const getData = self.spreadsheetexport.getData();
      
                const columns = [
                                      'code',
                                      'name',
                                      'id'
                                    ];
                    const newRow = getData.map((item) => {
                      const rowArray = {};
                      columns.forEach((col,index) => {
                            if(['id'].includes(col)){
                                if(isNaN(parseInt(item[index]))){
                                  rowArray[col] = null
                                }else{

                                  rowArray[col] = parseInt(item[index]);
                                }
                            }else{
                              rowArray[col] = item[index]
                            }
                      })

                      return {...rowArray}
                  },[]);



                 const codeColumnData = self.spreadsheetexport.getColumnData(0);

                let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]
                  self.spreadsheetexport.destroy();
                  self.exportTableOptions.data = JSON.parse(JSON.stringify(newRow))
  
                  self.renderTable();

                    codeColumnData.forEach((element,i) => {
                      if(duplicateValues.includes(element)){
                            self.spreadsheetexport.setStyle(`A${(i+1)}`,'background-color','pink');
                            self.spreadsheetexport.setStyle(`B${(i+1)}`,'background-color','pink');
                      }
                    }); 
    },

    resetForm(){
      
      },
    openDialog(){
      const self = this;

      
      

      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    async onSubmitForm(){
       const self = this;

       const getData = self.spreadsheetexport.getData();
            const isValid = self.$refs.observer.validate();
            if(isValid){
                
              const codeColumnData = self.spreadsheetexport.getColumnData(0);

                let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index && item !== '')
                const duplicateValues = [...new Set(findDuplicates(codeColumnData))]

                  if(duplicateValues.length > 0){
                    MessageBox.alert('Column code must be unique', 'Error', {
                      type : 'error'
                    })
                  }else{
                      const columns = [ 'code', 'name', 'id' ];
                      const newRow = getData.filter((item) => {
                        return item[0] !== '' && item[1] !== ''
                      },[]).map((item) => {
                        const rowArray = {};
                        columns.forEach((col,index) => {
                              if(['id'].includes(col)){
                                  if(isNaN(parseInt(item[index]))){
                                    rowArray[col] = null
                                  }else{

                                    rowArray[col] = parseInt(item[index]);
                                  }
                              }else{
                                rowArray[col] = item[index]
                              }
                        })

                        return {...rowArray}
                    },[]);


                    if(self.formLoading === false){
                            const form = {
                              items : JSON.parse(JSON.stringify(newRow)),
                              type : this.type
                              }
                              if(newRow.length > 0){
                                self.formLoading = true;
                                      self.createUpdateRecord(form).then((res) => {
                                            self.$ThatsNotify.success({
                                              message : "Table was updated successfully"
                                            })


                                            self.variationData = res.filter((item) => {
                                                return self.type === item.type
                                              }).map((item) => {
                                              return {
                                                code :  item.code,
                                                name : item.name,
                                                id : item.id
                                              }
                                            },[])


                                            console.log(res)

                                            self.spreadsheetexport.destroy();
                                            self.exportTableOptions.data = self.variationData
                                            self.renderTable();


                                            // self.closeDialog()
                                            self.$emit('success',res)
                                            self.formLoading = false;
                                      }).catch((err) => {
                                          self.formLoading = false;  
                                          self.$ThatsNotify.error({
                                              message : err.response.data.error
                                            })
                                      });
                              }else{
                                MessageBox.alert('No valid data to save.', 'Error', {
                                  type : 'error'
                                })
                              }
                    }
                  }        
            }

            
        },


        createPopupTable(exportTableOptions){

            // exportTableOptions.data = this.variationData


            console.log("before assign")
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },
        reCreatePopupTable(exportTableOptions){

            // exportTableOptions.data = this.variationData


            console.log("before assign")
            console.log(exportTableOptions)
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },

        async recreateSpreadsheet(){
             this.spreadsheetexport.destroy();

            await this.renderTable();
        },
        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },
        renderTable(){
            const self = this;
            this.exportTableOptions.columns = [
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'code',
                    title : 'CODE',
                  },
                  {
                    type : 'text',
                    align : 'left',
                    name : 'name',
                    title : 'Name',
                    width : '585px',
                  },
                  {
                    type: 'hidden',
                    title:'id',
                    name:'id',
                  }
                 
            ];

            
                this.createPopupTable(this.exportTableOptions);
        },



        fnExport(doc){

          window.open(`${this.$route.path}/reports/${doc.id}`, "_blank");
          
        }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    
    ...mapGetters('CodeGeneratorVariationModule',['getVariationData']),
      tblKey : function(){
        return 1;
      }
    
  },

  watch: {
      getVariationData : function(value){
        const self = this;

          this.variationData = value.filter((item) => {
            return self.type === item.type
          }).map((item) => {
            return {
              code :  item.code,
              name : item.name,
              id : item.id
            }
          },[])
      },
      
      'dialogIsOpen' : function(newState,old) {

       const self = this;
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{
                
                    self.exportTableOptions.data = self.variationData
                  self.renderTable();
              }
        },

  


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>