import { ApiHttpService } from '../../services'

import router from '../../router'
export const RebuildQuotationModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '100',
                },
                {
                    label: 'Tracking No.',
                    field: 'series',
                    sort: 'asc',
                    width: '180',
                    clickable: true
                },
                {
                    label: 'Item Name',
                    field: 'item_names',
                },
                {
                    label: 'Customer/Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                },
                {
                    label: 'Total',
                    field: 'total',
                    align : 'right',
                    width: '150',
                    amount: true,
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    align: 'right',
                    width: '100',
                },
                {
                    label: 'Print',
                    field: 'print',
                    align: 'right',
                    width: '100',
                },
                ],
            rows: [],
        },
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},


        initialFormData : {
            series : "",
            date : "",
            approved_date : "",
            status : "draft",
            showroom : {
                id : null,
                name : null,
            },
            description: null,
            attr : {
            },
            prepared_by : {
                id : null,
                name : null 
            },
            requested_by: {
                id : null,
                name : null 
            },
            approved_by: {
                id : null,
                date : null,
                text : null,
                name : null 
            },
            approver: [{ "id": 4, "name": "Kareem Kandil"}, { "id": 348, "name": "Beatriz Rosales"}],
            items: [],
            inventory_transfer_form : {
                date : null,
                
                showroom : {
                    id : null,
                    name : null,
                },
                showroomFrom : {
                    id : null,
                    name : null,
                },
                showroomTo : {
                    id : null,
                    name : null,
                },
                description: null,
                attr : {},
                status : "open",
                prepared_by : {
                    id : null,
                    name : null 
                },
                items : [
                    {
                        series : '',
                        photo : '',
                        name : '',
                        dimension : '',
                        quantity : '',
                        locationFrom : '',
                        locationTo : '',
                        attachment : [
                            {src : "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg"},
                            {src : "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg"},
                        ],
                        description : '',
                    }
                ]
            }
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',
        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setNewItemDataObject : (state, payload)=>{
            state.newItemDataObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){

        
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
           
            const { length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {
            
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "InventoryTransferRequestModule/req",
                        loadingTrue : "InventoryTransferRequestModule/loadingTrue",
                        addRequest : "InventoryTransferRequestModule/addRequest",
                        cancelRequest : "InventoryTransferRequestModule/cancelRequest",
                        clearOldRequest : "InventoryTransferRequestModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}
 
                    ApiHttpService.getRequest('inventory-transfer-request/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
        
        
        async getReceiver() {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('inventory-transfer-request/receiver',{view : router.app._route.name}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    commit('setGetloadingFalse', false)
                    reject(error)
                })
            })
        },     
        
      
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "InventoryTransferRequestModule/req",
                        loadingTrue : "InventoryTransferRequestModule/setPostLoadingTrue",
                        addRequest : "InventoryTransferRequestModule/addRequest",
                        cancelRequest : "InventoryTransferRequestModule/cancelRequest",
                        clearOldRequest : "InventoryTransferRequestModule/clearOldRequest"
                    }
                    ApiHttpService.post('inventory-transfer-request/store',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`inventory-transfer-request/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        getNewItemDataObject: state => state.newItemDataObject,
    }
}



