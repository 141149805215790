import Axios from 'axios'
import { bearer, token } from '../helpers'

export const ForeigncurrencyService = {
    async store(object) {
       
        return await Axios.post(`/api/foreigncurrency`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', params) {
        console.log(endpoint)
        return await Axios.get(`/api/foreigncurrency${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/foreigncurrency`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/foreigncurrency`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}