<template>
  <div>
    <mdb-input :value="handlePickersValue()" @click.native="$refs.timePicker.open()" />
    <mdb-date-picker-2
      ref="datePicker"
      disable-input
      v-model="datePickerValue"
      @close="$refs.timePicker.open()" />
    <mdb-time-picker
      ref="timePicker"
      disable-input
      v-model="timePickerValue" />
  </div>
</template>
<script>
  import { mdbDatePicker2, mdbTimePicker, mdbInput } from 'mdbvue';
  export default {
    name: 'DatePickerPage',
    components: {
      mdbDatePicker2,
      mdbTimePicker,
      mdbInput
    },
    methods: {
      handlePickersValue() {
        let output = 'Pick date and time'
        if (this.datePickerValue && this.timePickerValue) output = `${this.datePickerValue}, ${this.timePickerValue}`
        else if (this.datePickerValue) output = this.datePickerValue
        else if (this.timePickerValue) output = this.timePickerValue
        return output
      }
    },
    data() {
      return {
        datePickerValue: '',
        timePickerValue: ''
      }
    }
  }
</script>
