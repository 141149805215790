import Vue from 'vue'
import store from './store'
import router from './router'
import App from './App.vue'
import Axios from 'axios'
import { Drawer,Table, TableColumn, Pagination, Collapse, CollapseItem, MessageBox, Checkbox,CheckboxGroup, Input, Dropdown, DropdownMenu,DropdownItem,Radio, RadioGroup, Avatar, Loading, Image, Select, Option, Calendar,Popconfirm,Button,Progress,FormItem,Timeline,TimelineItem,Alert,Steps,Step, Card,Popover, Row,Col  } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import ThatsNotify from './util/Notify.js'
import Dialog from './components/dialog'
import "./validation-rules/rules";
import "./filters";
// Vue.config.productionTip = false
/* const accounting = "./lib/accounting"
module.exports = accounting;    */
import CoolLightBox from 'vue-cool-lightbox'
Vue.use(CoolLightBox)
import vSelect from 'vue-select'

import { helpers} from './helpers/global.js'
const isDev = process.env.NODE_ENV === 'development';
const frontEnd = (isDev) ? 'http://thatsliving.com' : 'https://thatsliving.com';

console.log("isDev")
console.log(isDev)

let appEnv = process.env.MIX_APP_ENV;
  console.log("my env variable:");
  console.log(appEnv);

const plugin = {
  install() {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
    Vue.prototype.$fe = frontEnd
  }
}


Vue.use(plugin)

Vue.component('v-select', vSelect)



import * as VueWindow from '@hscmap/vue-window'

Vue.use(VueWindow)


import * as UiWindow from './components/ui-window/ui-window'

console.log(UiWindow)
Vue.use(UiWindow)

const user = localStorage.getItem('user')
if (user !== null) {
  Axios.defaults.headers.common['Authorization'] = user.token
}

locale.use(lang)
Vue.component('MessageBox', MessageBox )
Vue.component('ElCollapse', Collapse)
Vue.component('ElCollapseItem', CollapseItem )
Vue.component('ElDialog', Dialog)
Vue.component('ElTableColumn', TableColumn)
Vue.component('ElPagination', Pagination)
Vue.component('ElTable', Table)
Vue.component('ElCheckbox', Checkbox)
Vue.component('ElRadio', Radio)
Vue.component('ElCheckboxGroup', CheckboxGroup)
Vue.component('ElInput', Input)
Vue.component('ElDropdown', Dropdown)
Vue.component('ElDropdownMenu', DropdownMenu)
Vue.component('ElDropdownItem', DropdownItem)
Vue.component('ElAvatar', Avatar)
Vue.component('ElImage', Image)
Vue.component('ElSelect', Select)
Vue.component('ElOption', Option)
Vue.component('ElRadioGroup', RadioGroup)
Vue.component('ElCalendar', Calendar)
Vue.component('ElPopconfirm', Popconfirm)
Vue.component('ElButton', Button)
Vue.component('ElProgress', Progress)
Vue.component('ElFormItem', FormItem)
Vue.component('ElTimelineItem', TimelineItem)
Vue.component('ElTimeline', Timeline)
Vue.component('ElAlert', Alert)
Vue.component('ElSteps', Steps)
Vue.component('ElStep', Step)
Vue.component('ElCard', Card)
Vue.component('ElRow', Row)
Vue.component('ElCol', Col)
Vue.component('ElPopover', Popover)
Vue.component('ElDrawer', Drawer)
// Loading.service(options);
Vue.prototype.$loading = Loading.service

/* import chat from 'vue-simple-chat'
Vue.use(chat) */
Vue.directive('only', {
  bind (el, binding) {
    el.addEventListener('keydown', (e) => {
      // delete, backpsace, tab, escape, enter,
      let special = [46, 8, 9, 27, 13]
      if (binding.modifiers['decimal']) {
        // decimal(numpad), period
        special.push(110, 190)
      }
      // special from above
      if (special.indexOf(e.keyCode) !== -1 ||
        // Ctrl+A
        (e.keyCode === 65 && e.ctrlKey === true) ||
        // Ctrl+C
        (e.keyCode === 67 && e.ctrlKey === true) ||
        // Ctrl+X
        (e.keyCode === 88 && e.ctrlKey === true) ||
        // home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        return // allow
      }
      if ((binding.modifiers['alpha']) &&
        // a-z/A-Z
        (e.keyCode >= 65 && e.keyCode <= 90)) {
        return // allow
      }
      if ((binding.modifiers['number']) &&
        // number keys without shift
        ((!e.shiftKey && (e.keyCode >= 48 && e.keyCode <= 57)) ||
        // numpad number keys
        (e.keyCode >= 96 && e.keyCode <= 105))) {
        return // allow
      }
      // otherwise stop the keystroke
      e.preventDefault() // prevent
    }) // end addEventListener
  } // end bind
}) // end directive
Vue.prototype.$eventBus = new Vue();

Vue.use(Loading.directive)
Vue.use(ThatsNotify)
import 'jspreadsheet-ce/dist/jspreadsheet.css'
import './jsexcel/jsuites.css'
import './assets/app-generate-serial.css'
import Tippy from 'v-tippy'
import 'v-tippy/dist/tippy.css'
Vue.use(Tippy, {
  position: 'bottom',
  arrow: true
})

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import formatMoney from 'accounting-js/lib/formatMoney.js'
Vue.filter('isEmpty', function(val, args1 = null){
  if(val == null || val == ''){
    if(args1 == null){
      return '-'
    }else{
      return args1
    }
  }else{
    return val
  }
})

Vue.filter('amount', function(val){
  return formatMoney(val, { symbol: "", precision: 2, thousand: ",", decimal: "." });
})

Vue.filter('thousands', function(val){
  return formatMoney(val, { symbol: "", precision: 0, thousand: ",", decimal: "." });
})

Vue.filter('uppercase', function(val){
  return (val !== null) ? val.toUpperCase() : val;
})


Vue.filter('pullOutStatus', function(val){
  // return formatMoney(val, { symbol: "", precision: 2, thousand: ",", decimal: "." });

  const status = [{code : "for-pull-out",label : "For Pull-Out"},{code : "pulled-out",label : "Pulled-Out"}];
  const filterx = status.filter((i) => {
    return i.code === val;
  })[0];

  return (filterx) ? filterx.label : "Unknown transaction order status";

})

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);


Vue.filter('transactionStatus', function(val){
  const salesTransactionStatus = [
    {
      "code": "no-remarks",
      "label": "Open",
      "seperator": false
    },
    {
      "code": "collected",
      "label": "Collected",
      "seperator": false
    },
    {
      "code": "for-pick-up",
      "label": "For Pick Up",
      "seperator": false
    },

    {
      "code": "partial-returned",
      "label": "Partial Returned",
      "seperator": false
    },

    {
      "code": "cancel",
      "label": "Cancelled",
      "seperator": false
    },

    {
      "code": "for-delivery-schedule",
      "label": "For Delivery Schedule",
      "seperator": false
    },
    {
      "code": "for-delivery",
      "label": "For Delivery",
      "seperator": false
    },
    {
      "code": "partial-delivery",
      "label": "Partially Delivered",
      "seperator": false
    },
    {
      "code": "delivered",
      "label": "Delivered",
      "seperator": false
    },

    {
      "code": "to-be-order",
      "label": "To be order",
      "seperator": false
    },
    {
      "code": "installed",
      "label": "Installed",
      "seperator": false
    },
    {
      "code": "rendered",
      "label": "Rendered",
      "seperator": false
    },
    {code : "open",label : "Open"},{code : "cancel",label : "Canceled"},{code : "closed",label : "Closed"}
  ]

  const filterx = salesTransactionStatus.filter((i) => {
    return i.code === val;
  })[0];

  return (filterx) ? filterx.label : "Unknown transaction order status";
})
Vue.filter('salesOrderStatus', function(val){
  /*  const {settings} = JSON.parse(localStorage.getItem('user')); */

  const salesTransactionStatus = [{code : "open",label : "Open"}];

  const filterx = salesTransactionStatus.filter((i) => {
    return i.code === val;
  })[0];

  return (filterx) ? filterx.label : "Unknown transaction order status";
})

import money from 'v-money'
Vue.use(money, {precision: 4, decimal: '.', thousands: ',',})
import VueExcelEditor from 'vue-excel-editor'

Vue.use(VueExcelEditor)

import Echo from "laravel-echo"
import Pusher from "pusher-js"



function checkToken(){
  return new Promise((resolve) => {
    const user = window.localStorage.getItem('user')

/*     store.dispatch('UserModule/checkToken').then((response) => {
      resolve();
    }).catch((r) => {
      console.log("checkToken")
      console.log(r)
      if (Object.prototype.hasOwnProperty.call(r.response, "status") && r.response.status === 401 && r.response.statusText === 'Unauthorized') {


        store.dispatch('AuthModule/Logout', null, { root: true });
      }
    }) */

    if (user) {
      store.dispatch('UserModule/checkToken').then((response) => {
        resolve();
      }).catch((r) => {
        console.log("checkToken")
        console.log(r)
        if (Object.prototype.hasOwnProperty.call(r.response, "status") && r.response.status === 401 && r.response.statusText === 'Unauthorized') {


          store.dispatch('AuthModule/Logout', null, { root: true });
        }
      })

      console.log("MIX_PUSHER_APP_HOST")

      // const userData = JSON.parse(user)
      console.log(process.env.MIX_PUSHER_APP_HOST)

      /* window.Pusher = Pusher;
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: process.env.MIX_PUSHER_APP_KEY,
        wsHost: process.env.MIX_PUSHER_APP_HOST,
        wsPort: 6002,
        wssPort: 6002,
        forceTLS: (process.env.MIX_PUSHER_APP_ENV === 'production') ? true : false,
        encrypted: true,
        disableStats: true,
        authEndpoint: process.env.MIX_PUSHER_APP_URL + '/broadcasting/auth',
        auth: {
          headers: {
            Authorization: "Bearer " + userData.token
          }
        }
      }) */


    }else{
      /* store.dispatch('AuthModule/Logout', null, { root: true });
      resolve(); */

      resolve();
    }
  })
}

//Vue.config.devtools = true
//Vue.config.performance = true
Vue.directive('focus', {
  inserted: function (el) {
  el.focus()
  }
  })

checkToken()
const app = new Vue({
  el: '#app',
  components: {
    mdbContainer, mdbRow, mdbCol
  },
  store,
  router,


  render: h => h(App),

  watch:{
    '$route' (to) {
        // console.log(to)
        if(to.meta.hasOwnProperty('reload') && to.meta.reload==true){
            // window.location.reload()

         //   this.$eventBus.$emit('reloadTable');


        }

    }
}
})

window.attachFile = function(row){
    app.$eventBus.$emit('attachFile',{
      row : row
    })
}
window.attachFileView = function(row, index){
    app.$eventBus.$emit('attachFileView',{
      row : row, index : index
    })
}
window.attachFileRemove = function(row, index){
    app.$eventBus.$emit('attachFileRemove',{
      row : row, index : index
    })
}

window.itemView = function(column,row, value){
  app.$eventBus.$emit('itemView',{
     column : column, row : row,value : value
  })
}
