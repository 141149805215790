<template>
        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">

                <div class="checkbox-container">
                    <el-checkbox v-model="inputValue.intl" :disabled="disabled">Not a Qatar Number?</el-checkbox>
                </div>

                <div class="mobile-input-container">
                    <template v-if="inputValue.intl">
                         <ValidationProvider :vid="`${$attrs.vid}-code`" :name="`${$attrs.vid}-code`" :rules="rules" v-slot="{errors}">
                            <v-select  @input="setSelected" ref="vselect" placeholder="Select Country Code" :multiple="false" :class="'select-country-code '+hasError(errors)" v-model="inputValue.code" append-to-body :reduce="reduce" :label="reduceLabel" :options="options" @search="onSearch" :filterable="filterable" :clearable="clearable" :disabled="disabled" :selectable="selectable">
                            </v-select>
                        </ValidationProvider>
                    </template>

                    <ValidationProvider :vid="`${$attrs.vid}-input`" :name="`${$attrs.vid}-input`" :rules="rules" v-slot="{errors,invalid}">
                            <mdb-input :is-invalid="invalid" :invalidFeedback="errors[0]" :inputClass="comInputClass+' '+bindInputHorizatal + ' ' +hasError(errors)" v-model="inputValue.value" :disabled="disabled" type="text" autocomplete="off" noWrapper bg placeholder="Mobile Number" :maxlength="maxLength" />
                            <template v-if="show_error == true">
                                <span class="errors">{{errors[0]}}</span>
                            </template>
                        </ValidationProvider>
                    
                </div>
                
            </div>
            </div>
        </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbBtn, mdbInput } from "mdbvue";
import { createPopper } from '@popperjs/core';
export default {
    name : 'InternationalMobile',
    
    components : {
        ValidationProvider,
        mdbBtn,
        mdbInput
    },

    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        multiple : {
            type : Boolean,
            default : true
        },
        reduce :{
            type : Function,
            default: option => option.code,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },

        show_error: {
            type: Boolean,
            default : false
        },
        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled : {
            type : Boolean,
            default : false
        },

        addButton: {
            type: Boolean,
            default : false
        },
        horizontal: {
            type: Boolean,
            default : false
        },

        loading: {
            type: Boolean,
            default : false
        },
        selectable: {
           type : Function,
            default: option => option,
        },
        inputclass : {
            type : Array,
            default : function(){
                return ['s'];
            }
        },
    },
    data : ()=>({
        inputValue : {
            intl : false,
            code : null,
            value : null
        },
        placement: 'bottom',
        maxLength: 8,
        options : [
            {label : "Afghanistan (+93)",code : "+93",digit : 9},
{label : "Albania (+355)",code : "+355",digit : 9},
{label : "Algeria (+213)",code : "+213",digit : 9},
{label : "American Samoa (+1)",code : "+1",digit : 7},
{label : "Andorra (+376)",code : "+376",digit : 9},
{label : "Angola (+244)",code : "+244",digit : 9},
{label : "Anguilla (+1)",code : "+1",digit : 7},
{label : "Antigua and Barbuda (+1)",code : "+1",digit : 7},
{label : "Argentina (+54)",code : "+54",digit : 10},
{label : "Armenia (+374)",code : "+374",digit : 8},
{label : "Aruba (+297)",code : "+297",digit : 7},
{label : "Australia (+61)",code : "+61",digit : 15},
{label : "Australian External Territories (+672)",code : "+672",digit : 6},
{label : "Austria (+43)",code : "+43",digit : 13},
{label : "Azerbaijan (+994)",code : "+994",digit : 9},
{label : "Bahamas (+1)",code : "+1",digit : 7},
{label : "Bahrain (+973)",code : "+973",digit : 8},
{label : "Bangladesh (+880)",code : "+880",digit : 10},
{label : "Barbados (+1)",code : "+1",digit : 7},
{label : "Belarus (+375)",code : "+375",digit : 10},
{label : "Belgium (+32)",code : "+32",digit : 9},
{label : "Belize (+501)",code : "+501",digit : 7},
{label : "Benin (+229)",code : "+229",digit : 8},
{label : "Bermuda (+1)",code : "+1",digit : 7},
{label : "Bhutan (+975)",code : "+975",digit : 8},
{label : "Bolivia (Plurinational State of) (+591)",code : "+591",digit : 8},
{label : "Bonaire, Sint Eustatius and Saba (+599)",code : "+599",digit : 7},
{label : "Bosnia and Herzegovina (+387)",code : "+387",digit : 8},
{label : "Botswana (+267)",code : "+267",digit : 8},
{label : "Brazil (+55)",code : "+55",digit : 10},
{label : "British Virgin Islands (+1)",code : "+1",digit : 7},
{label : "Brunei Darussalam (+673)",code : "+673",digit : 7},
{label : "Bulgaria (+359)",code : "+359",digit : 9},
{label : "Burkina Faso (+226)",code : "+226",digit : 8},
{label : "Burundi (+257)",code : "+257",digit : 8},
{label : "Cambodia (+855)",code : "+855",digit : 8},
{label : "Cameroon (+237)",code : "+237",digit : 8},
{label : "Canada (+1)",code : "+1",digit : 10},
{label : "Cape Verde (+238)",code : "+238",digit : 7},
{label : "Cayman Islands (+1)",code : "+1",digit : 7},
{label : "Central African Rep. (+236)",code : "+236",digit : 8},
{label : "Chad (+235)",code : "+235",digit : 8},
{label : "Chile (+56)",code : "+56",digit : 9},
{label : "China (+86)",code : "+86",digit : 12},
{label : "Colombia (+57)",code : "+57",digit : 10},
{label : "Comoros (+269)",code : "+269",digit : 7},
{label : "Congo (+242)",code : "+242",digit : 9},
{label : "Cook Islands (+682)",code : "+682",digit : 5},
{label : "Costa Rica (+506)",code : "+506",digit : 8},
{label : "Côte d'Ivoire (+225)",code : "+225",digit : 8},
{label : "Croatia (+385)",code : "+385",digit : 12},
{label : "Cuba (+53)",code : "+53",digit : 8},
{label : "Curaçao (+599)",code : "+599",digit : 8},
{label : "Cyprus (+357)",code : "+357",digit : 11},
{label : "Czech Rep. (+420)",code : "+420",digit : 12},
{label : "Dem. People's Rep. of Korea (+850)",code : "+850",digit : 17},
{label : "Dem. Rep. of the Congo (+243)",code : "+243",digit : 9},
{label : "Denmark (+45)",code : "+45",digit : 8},
{label : "Diego Garcia (+246)",code : "+246",digit : 7},
{label : "Djibouti (+253)",code : "+253",digit : 6},
{label : "Dominica (+1)",code : "+1",digit : 7},
{label : "Dominican Rep. (+1)",code : "+1",digit : 7},
{label : "Ecuador (+593)",code : "+593",digit : 8},
{label : "Egypt (+20)",code : "+20",digit : 9},
{label : "El Salvador (+503)",code : "+503",digit : 11},
{label : "Equatorial Guinea (+240)",code : "+240",digit : 9},
{label : "Eritrea (+291)",code : "+291",digit : 7},
{label : "Estonia (+372)",code : "+372",digit : 10},
{label : "Ethiopia (+251)",code : "+251",digit : 9},
{label : "Falkland Islands (Malvinas) (+500)",code : "+500",digit : 5},
{label : "Faroe Islands (+298)",code : "+298",digit : 6},
{label : "Fiji (+679)",code : "+679",digit : 7},
{label : "Finland (+358)",code : "+358",digit : 12},
{label : "France (+33)",code : "+33",digit : 9},
{label : "French Dep. and Territories in the Indian Ocean (+262)",code : "+262",digit : 9},
{label : "French Guiana (+594)",code : "+594",digit : 9},
{label : "French Polynesia (+689)",code : "+689",digit : 6},
{label : "Gabon (+241)",code : "+241",digit : 7},
{label : "Gambia (+220)",code : "+220",digit : 7},
{label : "Georgia (+995)",code : "+995",digit : 9},
{label : "Germany (+49)",code : "+49",digit : 13},
{label : "Ghana (+233)",code : "+233",digit : 9},
{label : "Gibraltar (+350)",code : "+350",digit : 8},
{label : "Global Mobile Satellite System (GMSS), shared (+881)",code : "+881",digit : 8},
{label : "Greece (+30)",code : "+30",digit : 10},
{label : "Greenland (+299)",code : "+299",digit : 6},
{label : "Grenada (+1)",code : "+1",digit : 7},
{label : "Group of countries, shared code (+388)",code : "+388",digit : 8},
{label : "Guadeloupe (+590)",code : "+590",digit : 9},
{label : "Guam (+1)",code : "+1",digit : 7},
{label : "Guatemala (+502)",code : "+502",digit : 8},
{label : "Guinea (+224)",code : "+224",digit : 8},
{label : "Guinea-Bissau (+245)",code : "+245",digit : 7},
{label : "Guyana (+592)",code : "+592",digit : 7},
{label : "Haiti (+509)",code : "+509",digit : 8},
{label : "Honduras (+504)",code : "+504",digit : 8},
{label : "Hong Kong, China (+852)",code : "+852",digit : 9},
{label : "Hungary (+36)",code : "+36",digit : 9},
{label : "Iceland (+354)",code : "+354",digit : 9},
{label : "India (+91)",code : "+91",digit : 10},
{label : "Indonesia (+62)",code : "+62",digit : 10},
{label : "Inmarsat SNAC (+870)",code : "+870",digit : 9},
{label : "International Freephone Service (+800)",code : "+800",digit : 8},
{label : "International Networks, shared code (+882)",code : "+882",digit : 8},
{label : "International Networks, shared code (+883)",code : "+883",digit : 8},
{label : "International Premium Rate Service (IPRS) (+979)",code : "+979",digit : 9},
{label : "International Shared Cost Service (ISCS) (+808)",code : "+808",digit : 8},
{label : "Iran (Islamic Republic of) (+98)",code : "+98",digit : 10},
{label : "Iraq (+964)",code : "+964",digit : 10},
{label : "Ireland (+353)",code : "+353",digit : 11},
{label : "Israel (+972)",code : "+972",digit : 8},
{label : "Italy (+39)",code : "+39",digit : 11},
{label : "Jamaica (+1)",code : "+1",digit : 7},
{label : "Japan (+81)",code : "+81",digit : 13},
{label : "Jordan (+962)",code : "+962",digit : 9},
{label : "Kazakhstan (+7)",code : "+7",digit : 10},
{label : "Kenya (+254)",code : "+254",digit : 10},
{label : "Kiribati (+686)",code : "+686",digit : 5},
{label : "Korea (Rep. of) (+82)",code : "+82",digit : 11},
{label : "Kuwait (+965)",code : "+965",digit : 8},
{label : "Kyrgyzstan (+996)",code : "+996",digit : 9},
{label : "Lao P.D.R. (+856)",code : "+856",digit : 10},
{label : "Latvia (+371)",code : "+371",digit : 8},
{label : "Lebanon (+961)",code : "+961",digit : 8},
{label : "Lesotho (+266)",code : "+266",digit : 8},
{label : "Liberia (+231)",code : "+231",digit : 8},
{label : "Libya (+218)",code : "+218",digit : 9},
{label : "Liechtenstein (+423)",code : "+423",digit : 9},
{label : "Lithuania (+370)",code : "+370",digit : 8},
{label : "Luxembourg (+352)",code : "+352",digit : 11},
{label : "Macao, China (+853)",code : "+853",digit : 8},
{label : "Madagascar (+261)",code : "+261",digit : 10},
{label : "Malawi (+265)",code : "+265",digit : 8},
{label : "Malaysia (+60)",code : "+60",digit : 9},
{label : "Maldives (+960)",code : "+960",digit : 7},
{label : "Mali (+223)",code : "+223",digit : 8},
{label : "Malta (+356)",code : "+356",digit : 8},
{label : "Marshall Islands (+692)",code : "+692",digit : 7},
{label : "Martinique (+596)",code : "+596",digit : 9},
{label : "Mauritania (+222)",code : "+222",digit : 7},
{label : "Mauritius (+230)",code : "+230",digit : 7},
{label : "Mexico (+52)",code : "+52",digit : 10},
{label : "Micronesia (+691)",code : "+691",digit : 7},
{label : "Moldova (Republic of) (+373)",code : "+373",digit : 8},
{label : "Monaco (+377)",code : "+377",digit : 9},
{label : "Mongolia (+976)",code : "+976",digit : 8},
{label : "Montenegro (+382)",code : "+382",digit : 12},
{label : "Montserrat (+1)",code : "+1",digit : 7},
{label : "Morocco (+212)",code : "+212",digit : 9},
{label : "Mozambique (+258)",code : "+258",digit : 9},
{label : "Myanmar (+95)",code : "+95",digit : 9},
{label : "Namibia (+264)",code : "+264",digit : 10},
{label : "Nauru (+674)",code : "+674",digit : 7},
{label : "Nepal (+977)",code : "+977",digit : 9},
{label : "Netherlands (+31)",code : "+31",digit : 9},
{label : "New Caledonia (+687)",code : "+687",digit : 6},
{label : "New Zealand (+64)",code : "+64",digit : 10},
{label : "Nicaragua (+505)",code : "+505",digit : 8},
{label : "Niger (+227)",code : "+227",digit : 8},
{label : "Nigeria (+234)",code : "+234",digit : 10},
{label : "Niue (+683)",code : "+683",digit : 4},
{label : "Northern Marianas (+1)",code : "+1",digit : 7},
{label : "Norway (+47)",code : "+47",digit : 8},
{label : "Oman (+968)",code : "+968",digit : 8},
{label : "Pakistan (+92)",code : "+92",digit : 11},
{label : "Palau (+680)",code : "+680",digit : 7},
{label : "Panama (+507)",code : "+507",digit : 8},
{label : "Papua New Guinea (+675)",code : "+675",digit : 11},
{label : "Paraguay (+595)",code : "+595",digit : 9},
{label : "Peru (+51)",code : "+51",digit : 11},
{label : "Philippines (+63)",code : "+63",digit : 10},
{label : "Poland (+48)",code : "+48",digit : 9},
{label : "Portugal (+351)",code : "+351",digit : 11},
{label : "Puerto Rico (+1)",code : "+1",digit : 7},
// {label : "Qatar (+974)",code : "+974",digit : 8},
{label : "Romania (+40)",code : "+40",digit : 9},
{label : "Russian Federation (+7)",code : "+7",digit : 10},
{label : "Rwanda (+250)",code : "+250",digit : 9},
{label : "Saint Helena, Ascension and Tristan da Cunha (+247)",code : "+247",digit : 4},
{label : "Saint Helena, Ascension and Tristan da Cunha (+290)",code : "+290",digit : 4},
{label : "Saint Kitts and Nevis (+1)",code : "+1",digit : 7},
{label : "Saint Lucia (+1)",code : "+1",digit : 7},
{label : "Saint Pierre and Miquelon (+508)",code : "+508",digit : 6},
{label : "Saint Vincent and the Grenadines (+1)",code : "+1",digit : 7},
{label : "Samoa (+685)",code : "+685",digit : 7},
{label : "San Marino (+378)",code : "+378",digit : 10},
{label : "Sao Tome and Principe (+239)",code : "+239",digit : 7},
{label : "Saudi Arabia (+966)",code : "+966",digit : 9},
{label : "Senegal (+221)",code : "+221",digit : 9},
{label : "Serbia (+381)",code : "+381",digit : 12},
{label : "Seychelles (+248)",code : "+248",digit : 7},
{label : "Sierra Leone (+232)",code : "+232",digit : 8},
{label : "Singapore (+65)",code : "+65",digit : 12},
{label : "Sint Maarten (Dutch part) (+1)",code : "+1",digit : 7},
{label : "Slovakia (+421)",code : "+421",digit : 9},
{label : "Slovenia (+386)",code : "+386",digit : 8},
{label : "Solomon Islands (+677)",code : "+677",digit : 5},
{label : "Somalia (+252)",code : "+252",digit : 8},
{label : "South Africa (+27)",code : "+27",digit : 9},
{label : "South Sudan (+211)",code : "+211",digit : 8},
{label : "Spain (+34)",code : "+34",digit : 9},
{label : "Sri Lanka (+94)",code : "+94",digit : 9},
{label : "Sudan (+249)",code : "+249",digit : 9},
{label : "Suriname (+597)",code : "+597",digit : 7},
{label : "Swaziland (+268)",code : "+268",digit : 8},
{label : "Sweden (+46)",code : "+46",digit : 13},
{label : "Switzerland (+41)",code : "+41",digit : 12},
{label : "Syrian Arab Republic (+963)",code : "+963",digit : 10},
{label : "Taiwan, China (+886)",code : "+886",digit : 9},
{label : "Tajikistan (+992)",code : "+992",digit : 9},
{label : "Tanzania (+255)",code : "+255",digit : 9},
{label : "Telecommunications for Disaster Relief (TDR) (+888)",code : "+888",digit : 8},
{label : "Thailand (+66)",code : "+66",digit : 9},
{label : "The Former Yugoslav Republic of Macedonia (+389)",code : "+389",digit : 8},
{label : "Timor-Leste (+670)",code : "+670",digit : 7},
{label : "Togo (+228)",code : "+228",digit : 8},
{label : "Tokelau (+690)",code : "+690",digit : 4},
{label : "Tonga (+676)",code : "+676",digit : 7},
{label : "Trial of a proposed new international service (+991)",code : "+991",digit : 8},
{label : "Trinidad and Tobago (+1)",code : "+1",digit : 7},
{label : "Tunisia (+216)",code : "+216",digit : 8},
{label : "Turkey (+90)",code : "+90",digit : 10},
{label : "Turkmenistan (+993)",code : "+993",digit : 8},
{label : "Turks and Caicos Islands (+1)",code : "+1",digit : 7},
{label : "Tuvalu (+688)",code : "+688",digit : 6},
{label : "Uganda (+256)",code : "+256",digit : 9},
{label : "Ukraine (+380)",code : "+380",digit : 9},
{label : "United Arab Emirates (+971)",code : "+971",digit : 9},
{label : "United Kingdom (+44)",code : "+44",digit : 10},
{label : "United States (+1)",code : "+1",digit : 10},
{label : "United States Virgin Islands (+1)",code : "+1",digit : 7},
{label : "Universal Personal Telecommunication (UPT) (+878)",code : "+878",digit : 8},
{label : "Uruguay (+598)",code : "+598",digit : 11},
{label : "Uzbekistan (+998)",code : "+998",digit : 9},
{label : "Vanuatu (+678)",code : "+678",digit : 7},
{label : "Vatican (+379)",code : "+379",digit : 8},
{label : "Vatican (+39)",code : "+39",digit : 11},
{label : "Venezuela (Bolivarian Republic of) (+58)",code : "+58",digit : 10},
{label : "Viet Nam (+84)",code : "+84",digit : 10},
{label : "Wallis and Futuna (+681)",code : "+681",digit : 6},
{label : "Yemen (+967)",code : "+967",digit : 9},
{label : "Zambia (+260)",code : "+260",digit : 9},
{label : "Zimbabwe (+263)",code : "+263",digit : 10},



          ]
    }),

    methods : {
        setSelected(v){
            this.$emit('select',v)
        },
        emitNewWindow(){
          this.$refs.vselect.closeSearchOptions()
          this.$emit('newWindow')
              },
        onSearch(v){
            this.$emit('onSearch',v)
        },
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },

        
withPopper (dropdownList, component, {width}) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset', options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({state}) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          }]
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },


    },
    computed : {
        finalOptions : function () {
            const withSelected = [];
            this.options.forEach(element => {
                if(element.value === this.value){
                    // this.inputValue = element.value
                    element.selected = true
                    withSelected.push(element)
                }else{
                    withSelected.push(element)
                }
            });

            return withSelected;
        }
        ,
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.horizontal){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.horizontal){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputclass.join(' ');
        },

        computedMaxLength : function(){
            const country_code = this.inputValue.code;

                if(country_code === '+794'){
                        return 8
                }else{

                    const filterNumber = this.options.filter((item) => {
                        return item.code === country_code
                    })[0];

                    console.log("filterNUmber")
                    console.log(filterNumber)
                    console.log(country_code)

                

                    if(filterNumber){
                        return filterNumber.digit
                    }
                }
                return 9

        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {

            if(newVal.intl){
                if(this.inputValue.code === '+974'){
                    this.inputValue.code = null
                }
                this.maxLength = 9
            }else{
                if(this.inputValue.value!== null){
                    this.inputValue.value = this.inputValue.value.slice(0, 8);
                }
            
                this.inputValue.code = '+974'
                this.maxLength = 8
            }


            this.inputValue = newVal;
        },

        "inputValue.intl" : function(v,n){
            if(v){

                if(this.inputValue.code === '+974'){
                    this.inputValue.code = null
                }
                this.maxLength = 9
            }else{
                this.inputValue.code = '+974'

                if(this.inputValue.value!== null){
                    this.inputValue.value = this.inputValue.value.slice(0, 8);
                }
                this.maxLength = 8
            }
        },
        "inputValue.code" : function(v){
      
            if(v === '+794'){
                this.maxLength = 8
            }else{

                const filterNumber = this.options.filter((item) => {
                    return item.code === v
                })[0];

                if(filterNumber){
                    this.maxLength = filterNumber.digit
                }
            }
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>