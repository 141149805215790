import Vue from 'vue';

Vue.filter('paymentMethod', function(value) {

    const paymentOptions = [
        {
          "code": "credit-card",
          "label": "by Card",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "wire-transfer",
          "label": "by Wire Transfer",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "cash",
          "label": "by Cash",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "cheque",
          "label": "by Cheque",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "credit-note",
          "label": "by Credit Memo",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "gift-voucher",
          "label": "Gift Voucher",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "tap",
          "label": "Payment Gateway",
          "seperator": false,
          "disabled": false
        },
        {
          "code": "cod",
          "label": "Cash on Delivery",
          "seperator": false,
          "disabled": false
        }
      ];
    

      const filter = paymentOptions.filter((i) => {
        return i.code === value;
      })[0];
    
      return (filter) ? filter.label : "Unknown Payment Method";
});


