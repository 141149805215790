import { ApiService } from '../../services'
export const RefundCollectionModule = {
    namespaced: true,
    state: {
        loading: false,
        postLoading: false,
        formRecord : {},
        formField : {
            date : "",
            series : "",
            transaction_id : "",
            tracking_no : "",
            attr : {
                collected_by : "",
                identification_no : "",
                mobile_no : "",
                process_by : "",
                reference : "",
            },
            amount : 0
            
        },
        formStatus : 'create',
        payment_references : [],
        overpaid_payment_references : [],
    },
    actions: {
     
        async storeRecord({ state, commit }, payload) {

            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;

                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`item-refund/${payload.refund_series}/collection`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.loading = false
        },
        setLoadingTrue: (state) => {
            state.loading = true
        },
      
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setFormCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },


    },
    getters: {
        postLoading: state => state.postLoading,
        formRecord: state => state.formRecord,
        formStatus: state => state.formStatus,
        formField: state => state.formField
    }
}


