<template>
    
        <v-select @input="setSelected" class="grid-select" :multiple="false" ref="vselect" v-model="inputValue" append-to-body :reduce="reduce" :label="reduceLabel" :options="options" @search="onSearch" :filterable="filterable" :clearable="clearable" @search:focus="onfocus" :disabled="disabled" :pushTags="pushTags">
            <template v-slot:option="option">
                {{ option.code}} - {{ option.label }}
            </template>

            <template #no-options>
                No matching keyword found. 
            </template>
            <template #list-footer>
                      <template v-if="addButton">
                        <li><mdb-btn class="dropdown-new" color="elegant" size="sm" @click="emitNewWindow">Add new</mdb-btn></li>
                    </template>
                </template>

        </v-select>
</template>
<script>
import {mdbBtn} from 'mdbvue'
export default {
    name : 'ThatsGridSelect',
    props : {

        value: {
            type: null
        },
        
        options: {
            type: Array
        },
        reduce :{
            type : Function,
            default: option => option,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },

        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: true
        },

        addButton: {
            type: Boolean,
            default : true
        },
        disabled : {
            type : Boolean,
            default : false
        },
        pushTags : {
            type : Boolean,
            default : false
        }
    },

    components : {
        mdbBtn
    },
    data : ()=>({
        inputValue : [],
        placement: 'bottom'
    }),

    methods : {
        setSelected(v){
            this.$emit('select',v)
        },
        emitNewWindow(){
          this.$refs.vselect.closeSearchOptions()
          this.$emit('newWindow')
        },
        onfocus(){
            this.$emit('onFocus')
        },
        onSearch(v){
            this.$emit('onSearch',v)
        },
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },

    },

    watch: {
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>

