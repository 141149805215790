/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" title="ITEM CODE GENERATOR" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <div class="mt-1">
              <mdb-row class="tab-row">
                      <mdb-col col="4" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-8 pl-0" label="Document Name" vid="doc_name" name="doc_name" v-model="form.doc_name" size="sm" :disabled="false" :rules="'required'"/>
                      </mdb-col>   
                      <mdb-col col="4" class="tab-col">
                       <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-8 pl-0" label="ORIGIN" vid="origin_code" name="origin_code" v-model="form.origin_code" size="sm" :disabled="true" :rules="'required'"/>
                       <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-8 pl-0" label="SUPLIER CODE" vid="vendor_code" name="vendor_code" v-model="form.vendor_code" size="sm" :disabled="true" :rules="'required'"/>
                       </mdb-col>
                    </mdb-row>  
                    <div ref="spreadsheetexport" class="new-item-table-preview"></div> 
                    <div class="d-flex">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">SAVE</mdb-btn>

                              <template v-if="formStatus==='update'">
                                <mdb-btn color="elegant" size="sm" @click="fnExport(form)" :disabled="(form.ExchangeRate <= 0 ? true : false)">Export</mdb-btn>
                              
                              </template>
                              <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>


                  </div>
            </div>
            </ValidationObserver>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'

import AmountInput from '../../components/thats/AmountInput.vue'
import country from '../generate-serial/country.js'

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }

    /* 
    
     {"name": "Afghanistan", "id": "AF", "area" : "f"},
            {"name": "Aland Islands", "id": "AX", "area" : "c"},
            {"name": "Albania", "id": "AL", "area" : "c"},
            {"name": "Algeria", "id": "DZ", "area" : "f"}
            
            */
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        countryData : [],
        areaData : [],
        catObjectArray : [],
        variationObjectArray : [],
        finishObjectArray : [],
        colorObjectArray : [],
        fabricObjectArray : [],
        categoryDropDown : [],
        finishDropDown : [],
        colorDropDown : [],
        fabricDropDown : [],
        form : {

            doc_id: null,
            area_id: null,
            origin_code: null,
            vendor_code : null,
            doc_name : null,
            attr : {
              
            },
          items :[]
        },
        newItemData : [],
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 165px)',
            tableOverflow : true,
             data:[],
             raw:[
              {code : 3,name : 'testingx'}
             ],
             minDimensions:[5,1],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],

            
          updateTable:function(instance, cell, col, row, val, label, cellName) {

              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                    if(y !== null){
                        
                        if (obj.options.allowInsertRow == true) {
                            items.push({
                                title:obj.options.text.insertANewRowBefore,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y), 1);
                                }
                            });
                            
                            items.push({
                                title:obj.options.text.insertANewRowAfter,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y));
                                }
                            });
                        }
                    }

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });

             }
                return items;
             },

             onblur: function(instance){

             },
             onchange: function(instance, cell, x, y, value){
                
                // console.log("change")
             },
             onafterchanges(){
             }
       }
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

        window['EXCHANGERATE'] = 0;
  },

  mounted(){
        const self = this
        this.countryData = country

        
  },

  updated(){

  },
  methods: {

    ...mapActions('CodeGeneratorAreaModule',['getArea']),
    ...mapActions('CodeGeneratorCategoryModule',{
        getCategoryRecords : 'getRecords'
    }),
    ...mapActions('CodeGeneratorVariationModule',{
        getVariations : 'getRecords'
    }),
    ...mapActions('CodeGeneratorModule',['storeRecord','updateRecord']),
    ...mapMutations('ApplyAmountModule',{
       setApplyAmountFormData : 'setFormData'
    }),

    demo(){
      console.log("demo")
      return 0;
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
       const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){
                
            const getData = this.spreadsheetexport.getData();


/* 
'supplier_item_code',
'item_code',
'name',
'area_id',
'category_code',
'color_code',
'material_code',
'fabric_code',
'part_code',
'vendor_code',
'origin_code',
'sequence',
'template_id',

 */
                      const columns = [
                                      'supplier_item_code',
                                      'item_code',
                                      'name',
                                      'area_id',
                                      'category_code',
                                      'color_code',
                                      'material_code',
                                      'fabric_code',
                                      'part_code',
                                      'vendor_code',
                                      'origin_code',
                                      'sequence',
                                      'template_id',
                                      'id'
                                    ];

                                 

                        const newRow = getData.filter((item) => {
                                          return item[2] !== '' && item[1] !== ''&& item[6] !== '' && item[4] !== ''
                                        },[]).map((item) => {
                                          const rowArray = {};
                                          columns.forEach((col,index) => {
                                                if(['category_code','color_code','material_code','fabric_code','area_id','sequence','template_id','id'].includes(col)){
                                                    if(isNaN(parseInt(item[index]))){
                                                      rowArray[col] = null
                                                    }else{

                                                     rowArray[col] = parseInt(item[index]);
                                                    }
                                                }else{
                                                  rowArray[col] = item[index]
                                                }
                                          })

                                          return {...rowArray}
                                      },[]);

                                     

                        if(self.formLoading === false){
                                 const form = {
                                   items : JSON.parse(JSON.stringify(newRow)),
                                   doc_name : this.form.doc_name,
                                   origin_code : this.form.origin_code,
                                    vendor_code : this.form.vendor_code,
                                  }
                                  if(newRow.length > 0){
                                    self.formLoading = true;

                                      if(self.formStatus === 'create'){

                                        self.storeRecord(form).then((res) => {
                                                self.$ThatsNotify.success({
                                                  message : "Record was created successfully"
                                                })
                                                self.closeDialog()
                                                self.$emit('success')
                                                self.$eventBus.$emit('created');
                                                self.formLoading = false;
                                          }).catch((err) => {
                                              self.formLoading = false;
                                              // console.log(err)
  
                                              self.$ThatsNotify.error({
                                                  message : err.response.data.error
                                                })
                                          });
                                      }

                                      if(self.formStatus === 'update'){
                                          form.id = this.form.doc_id
                                          form.name = this.form.doc_name

                                          const finalForm = JSON.parse(JSON.stringify({...this.form,...form}))
                                          self.updateRecord(finalForm).then((res) => {
                                                self.$ThatsNotify.success({
                                                  message : "Record was updated successfully"
                                                })
                                                self.closeDialog()
                                                self.$emit('success')
                                                self.$eventBus.$emit('created');
                                                self.formLoading = false;
                                          }).catch((err) => {
                                              self.formLoading = false;
                                              // console.log(err)
  
                                              self.$ThatsNotify.error({
                                                  message : err.response.data.error
                                                })
                                          });
                                      }
                                  }else{
                                    MessageBox.alert('No valid data to save.', 'Error', {
                                      type : 'error'
                                    })
                                  }
                        }
            }

            
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },

    fnLoadPurchaseOrder(){
        const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.resetData('get-purchase-orders').then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
           
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
    },

    createPopupTable(exportTableOptions){

            exportTableOptions.data = this.newItemData
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },

        async recreateSpreadsheet(){
             this.spreadsheetexport.destroy();
             this.categoryDropDown = [
            {"name": "Albania", "id": "AL", "area" : "c"},
            ]
            await this.renderTable();
        },

        fnNewWindow(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          window.open(`${self.$route.path}/${url}`, "_blank");
        },
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },

        async fnApply(){

        },

        changeSource(){
            this.categoryDropDown = [
            {"name": "Albania", "id": "AL", "area" : "c"},
            ]
        },

        filterCategoryDropdown(){

            console.log("dropdown fileter")
            const f = this.categoryDropDown.filter((item) => {
                return item.area === 'f'
            })

            console.log(f)

            return f
        },

        renderTable(){
            const self = this;
            this.exportTableOptions.columns = [
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'supplier_item_code',
                    title : 'SupplierItemCode',
                    readOnly: true,
                  },
                  {
                    type : 'text',
                    width : '210px',
                    align : 'left',
                    name : 'item_code',
                    title : 'Item Code',
                    readOnly: true,
                  },
                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Item Name',
                  },

                  {
                    type: 'dropdown',
                    width: '300px',
                    title:'Area',
                    name:'area_id',
                    autocomplete:true,
                    source: self.getAreaData,
                    multiple:false,
                    readOnly: true,
                    newOptions: true,
               
                    },
                  {
                    type: 'dropdown',
                    width: '300px',
                    title:'Category',
                    name:'category_code',
                    autocomplete:true,
                    source: self.getNewItemCategoryData,
                    multiple:false,
                    readOnly: true,
                    newOptions: true,
               
                    },
                    {
                      type: 'dropdown',
                      width: '250px',
                      title:'Color',
                      name:'color_code',
                      autocomplete:true,
                      source: self.getVariationData,
                      multiple:false,
                      readOnly: true,
                      newOptions: true,
                 
                    },
                  {
                    type: 'dropdown',
                    width: '250px',
                    title:'Material',
                    name:'material_code',
                    autocomplete:true,
                    source: self.getVariationData,
                    multiple:false,
                    readOnly: true,
                    newOptions: true,
               
                  },
                  {
                    type: 'dropdown',
                    width: '250px',
                    title:'Fabric',
                    name:'fabric_code',
                    autocomplete:true,
                    source: self.getVariationData,
                    multiple:false,
                    readOnly: true,
                    newOptions: true,
               
                  },
                  {
                    type: 'text',
                    width: '250px',
                    title:'Parts',
                    name:'part_code',
                    readOnly: true,
               
                  },
                  {
                    type: 'hidden',
                    width: '250px',
                    title:'vendor_code',
                    name:'vendor_code',
               
                  },
                  {
                    type: 'hidden',
                    width: '250px',
                    title:'origin_code',
                    name:'origin_code',
               
                  },
                  {
                    type: 'hidden',
                    width: '250px',
                    title:'sequence',
                    name:'sequence',
               
                  },
                  {
                    type: 'hidden',
                    width: '250px',
                    title:'template_id',
                    name:'template_id',
               
                  },
                  {
                    type: 'hidden',
                    width: '250px',
                    title:'id',
                    name:'id',
               
                  }
                 
            ];

/* 
area_id
: 
1
category_code
: 
1
color_code
: 
1
fabric_code
: 
null
item_code
: 
"AF2323-CCC1M1001"
material_code
: 
2
name
: 
"234234234"
origin_code
: 
"AF"
sequence
: 
1
supplier_item_code
: 
"234234234"
vendor_code
: 
"2323" */

            // console.log(this.exportTableOptions.columns.map((i) => i.name))
                this.createPopupTable(this.exportTableOptions);
        },

        openNewAreaWindow(){
            this.$refs.codeGenerateAreaForm.openDialog()
        },
        openNewCategoryWindow(){
            this.$refs.codeGenerateCategoryForm.openDialog()
        },

        updateAreaData(){
          const self = this;
          this.getArea().then((res) => {
            self.areaData = res
          })
        },

        async selectAreaCode(v){

          const self = this;
/* 
          console.log("this.category")
          console.log(v)
          console.log(this) */

          this.categoryDropDown = this.catObjectArray.filter((item) => {
              return item.area_id === v
          },[])

          this.spreadsheetexport.destroy();
            await this.renderTable();

             const tableData = this.spreadsheetexport.getData();

            const columnData = []

            tableData.forEach(element => {
                columnData.push('')
            },[]);
            
            this.spreadsheetexport.setColumnData(2,columnData)

        },

        async categorySuccess(row){
            const self = this;
            this.catObjectArray.push(row)

            this.categoryDropDown = this.catObjectArray.filter((item) => {
                return item.area_id === self.form.area_id
            },[])

            this.spreadsheetexport.destroy();
            await this.renderTable();

        },

        async variationSuccess(row){
            this.variationObjectArray.push(row)
            if(row.type === "material"){
                this.finishDropDown.push(row)
            }
            if(row.type === 'color'){
                this.colorDropDown.push(row)
            }
            if(row.type === 'fabric'){
                this.fabricDropDown.push(row)
            }
             this.spreadsheetexport.destroy();
            await this.renderTable();
        },

        fnExport(doc){

          window.open(`${this.$route.path}/reports/${doc.id}`, "_blank");
          
        }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {



    ...mapGetters('CodeGeneratorModule',['getNewItemDataObject','formStatus']),
    ...mapGetters('CodeGeneratorAreaModule',['getAreaData']),
    ...mapGetters('CodeGeneratorCategoryModule',['getNewItemCategoryData']),
    ...mapGetters('CodeGeneratorVariationModule',['getVariationData']),
    

      tblKey : function(){
        return 1;
      }
    
  },

  watch: {

      getNewItemDataObject : function(object){
          // console.log("watch newitemDataObject")
          // console.log(object)

          if(this.spreadsheetexport != null){
              this.spreadsheetexport.destroy();
          }

          this.newItemData = object.items.map((item)=>{
              return {
                  supplier_item_code : item.supplier_item_code,
                  item_code : item.item_code,
                  name : item.name,
                  area_id : item.area_id,
                  category_code : item.category_code,
                  color_code : item.color_code,
                  material_code : item.material_code,
                  fabric_code : item.fabric_code,
                  part_code : item.part_code,
                  vendor_code : item.vendor_code,
                  origin_code : item.origin_code,
                  sequence : item.sequence,
                  template_id : item.template_id,
                  id : item.id
              }
          })

          this.form.doc_name = object.name

          this.form.origin_code = object.origin_code
          this.form.vendor_code = object.vendor_code

          if(this.formStatus === 'update'){
            this.form.doc_id = object.id
            this.form.id = object.id
          }

          // this.spreadsheetexport.destroy();
          this.renderTable();


          // this.renderTable();
      },
      'dialogIsOpen' : function(newState,old) {

       const self = this;
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{
                
                /* this.getArea().then((res) => {
                  self.areaData = res
                })
                this.getCategoryRecords().then((res) => {
                    self.catObjectArray = res
                  })
                this.getVariations().then((res) => {
                    self.variationObjectArray = res

                    console.log("variation")
                    console.log(res)

                    self.finishDropDown = res.filter((item) => {
                      return item.type === "material"
                    },[])

                    self.colorDropDown = res.filter((item) => {
                      return item.type === 'color'
                    },[])
                    self.fabricDropDown = res.filter((item) => {
                      return item.type === 'fabric'
                    },[])
                  }) */

                // this.renderTable();
              }
        },

  


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>