<template>
    <el-dialog :zIndex="(7)" :modal="true" :fullscreen="true" :title="'PHYSICAL COUNT'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" class="text-left tl-dialog">
        <mdbContainer fluid class="pt-2" v-loading="loading">
        <template v-if="form">
          <mdb-row class="tab-row">
                <mdb-col col="12" class="tab-col p-0">
                    <ThatsFieldset>
                        <ThatsInput tabindex="2" labelClass="col-sm-1" fieldClass="col-sm-3 pl-0" label="Warehouse" vid="warehouse" name="warehouse" v-model="form.name" size="sm" :disabled="true" :rules="'required'"/>
                    </ThatsFieldset>

                    <el-table ref="multipleTable" lazy class="custom-element-table" :data="form.items" stripe>
                        <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                            <el-table-column label="SKU" :width="250" prop="series">
                            <template slot-scope="scope">
                                <Copy :text="scope.row.series">{{ scope.row.series }}</Copy>
                            </template>
                            </el-table-column>
                            
                       <!--  <el-table-column label="Image" width="100" prop="image">
                            <template slot-scope="scope">
                                <img :src="scope.row.default" style="width:90px" />
                            </template>
                        </el-table-column> -->
                            <el-table-column label="Item Name" prop="name">
                            <template slot-scope="scope">
                                {{ scope.row.name }}
                            </template>
                            </el-table-column>

                            <el-table-column label="Counted"  :width="150" align="center" prop="quantity">
                                <template slot-scope="scope">
                                    {{ scope.row.qty }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Message"  :width="150" align="center" prop="success">
                                <template slot-scope="scope">
                                    {{ scope.row.message }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Status"  :width="150" align="center" prop="loading">
                                <template slot-scope="scope">
                                    {{ scope.row.status }}
                                </template>
                            </el-table-column>

                        <template slot="empty">
                            No Data
                        </template>
                    </el-table>
                </mdb-col>
            </mdb-row>
        </template>
        </mdbContainer>
        <template slot="footer">
            <div class="pl-3 pr-3">
                <div class="d-flex">
                <mdb-btn color="elegant" size="sm" @click="postQuantity" :disabled="loading"><i class="el-icon-save text-white cursor-pointer"></i> SAVE</mdb-btn>
                <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                </div>
            </div>
        </template>
      </el-dialog>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import {mdbBtn, mdbContainer, mdbRow,mdbCol} from "mdbvue";
import {mapGetters, mapActions, mapMutations} from "vuex";
import { ThatsInput, ThatsFieldset, Copy} from '../../components/thats/Thats'
import { Message, MessageBox } from 'element-ui';
export default {
    components : {
        mdbBtn, mdbContainer,mdbRow,mdbCol,
        ThatsInput,
        ThatsFieldset,
        Copy
    },

    props : {
        tabs : {
            type : Array,
            default : () => [{ text: 'Dashboard', slot : 'dashboard-slot' },{ text: 'Quotations', slot : 'quotation-slot' },{ text: 'Invoices', slot : 'invoice-slot' }]
        } 
    },
    data() {
        return {
            syncModal: false,
            form: null,
            toggleLineChart : true,
            
        }
    },

    created(){
        this.restFormData();
    },
    methods: {
        ...mapMutations('InventoryPostingModule',{
          setLoading : 'setLoading',
        }),...mapActions('InventoryPostingModule',{
          getCounted : 'asyncGetData',
          postCounted : 'save',
        }),
        passes(callback) {
            if (typeof callback === 'function') {
                callback();
            }
        },
        openDialog(){
            this.syncModal = true;
        },
        closeDialog(){
            this.syncModal = false
        },
        restFormData(){
            this.form = JSON.parse(JSON.stringify(this.initFormData));
        },
        copyConSeries(row){
            var dummy = document.createElement("textarea");
              document.body.appendChild(dummy);
              dummy.setAttribute("id", "dummy_id");
              document.getElementById("dummy_id").value=row.series;
              dummy.select();
              document.execCommand("copy");
              document.body.removeChild(dummy);
                Message.success({
                message: "Copied!"
                });

       },

       setItemLoading(index){
            this.$set(this.form.items, index, { ...this.form.items[index],loading: true, success: false, message : "Posting...", status : 'loading'});
       },
       setItemSuccess(index){
            this.$set(this.form.items, index, { ...this.form.items[index],loading: false, success: true, message : 'Successfully posted', status : 'success'});
       },
         setItemFailed(obj = {}){
            const index = obj.index;
                this.$set(this.form.items, index, { ...this.form.items[index],loading: false, success: false, message : 'Failed to post', status : 'failed',...obj});
         },

       postQuantity(){
            MessageBox.confirm('Are you sure you want to post this quantity?', 'Confirmation', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {

                if(this.form.items.length > 0){
                    this.form.items.forEach((element, index) => {
                        this.setItemLoading(index);
                        this.postCounted({...element,index : index}).then((res) => {
                            if(res.hasOwnProperty('index')){
                                this.setItemSuccess(res.index);
                            }
                        }).catch((err) => {
                            if(err.response.data.hasOwnProperty('index')){
                                this.setItemFailed({index : err.index,message : err.response.data.message});
                            }
                            if(err.hasOwnProperty('index')){
                                console.log("Error",err)
                                this.setItemFailed({index : err.index, message : err.request.statusText});
                            }
                        });
                    });
                }
            }).catch(() => {
                this.setLoading(false);
            });
       }
    },
    computed: {
        ...mapGetters('InventoryPostingModule', ['loading','initFormData','formData']),
        
    },
    watch: {
        formData(val){
            if(val){
                this.form = val;
            }
        },
        syncModal(val){
            if(!val){
                this.passes(() => {
                    this.setLoading(false);
                    this.restFormData();
                });
            }            
        }
    }
}
</script>