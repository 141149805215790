import { ApiService, ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ItemExchangeFormModule = {
    namespaced: true,
    state: {
        viewLoading: false,
        tblKey: 0,
        item_to_exchange : [],
        item_to_replace : [],
        viewForm : {
            items : [],
            replacement : [],
        },
        exchangeForm : {
            series : "",
            date : "",
            status_index : 0,
            status : "no-remarks",
            attr : {
              partner : [],
              name : null,
              mobile : null,
              address : null,
              instruction : null,
              other_details : null,
              payment_status : 'no-payment',
              expected_date : null,
              tbo_details : null
            },
            customer : {
              name : null,
              mobile : null,
              address : null,
            },
            user_id : null,
            sold_by : null,
            customer_id : null,
            payment_method : 'cash',
            items : [],
            credit_note : {
              total : 0,
              status : null,
              credit_note_reference : []
            },
            pull_out : [],
            purchase_request_item : [],
            credit_note_items : [],
            delivery_notes : [],
            attachment : [],
            invoice : {
                          series : null,
                          date : "",
                          balance : 0,
                          paid : 0,
                          attachment : [],
                          payments : [],
                      },
            delivery_schedule : {
              date : "",
              remarks : null,
              status : 'for-delivery-schedule',
            },
            total : 0,
            more_discount : {
                enable : false,
                discount : 0,
            },
            cancellation : [],
            cancellation_item : [],
            exchange : [],
            exchange_item : [],
        }
    },
    actions: {
        async getRecord({ state, commit }, params) {
            const { viewLoading, tblKey } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiV1Service.get(`item-exchange/view/${params.series}`, params).then((response) => {
                        const incrementTblKey = tblKey + 1
                        commit('setTblKey', incrementTblKey)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        console.log(error);
                    })
                }
            })
        },
        async storeRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.TransactionModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))

                    ApiService.store('sales',parsePayload).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }
                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
      
        
    },
    mutations: {
        setItemToExchange : (state,payload) => state.item_to_exchange = payload,
        setItemToReplace : (state,payload) => state.item_to_replace = payload,
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },

        setViewLoadingTrue : (state) => state.viewLoading = true,
        setViewLoadingFalse : (state) => state.viewLoading = false,
        setViewForm: (state, payload) => state.viewForm = payload,
        exchangeForm: (state, payload) => state.exchangeForm = payload,

    },
    getters: {
        tblKey: state => state.tblKey,
        itemToExchange: state => state.item_to_exchange,
        itemToReplace: state => state.item_to_replace,
        viewLoading : state => state.viewLoading,
        items: state => state.viewForm.items,
        replacement: state => state.viewForm.replacement,
        viewForm: state => state.viewForm,
        exchangeForm: state => state.exchangeForm
    }
}


