import { ApiGetService } from '../../services'
export const ItemInventoryReportModule = {
    namespaced: true,
    state: {
        request: null,
        loading: false,
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        multipleSelection: [],
        data: [],
    },
    actions: {
        getItemInventory({commit, state }, obj = {}){
            commit('loadingFalse')
            const { length, page } = state
            return new Promise((resolve,reject) => {
                commit('loadingTrue')
                const configParams = {
                    req : "ItemInventoryReportModule/req",
                    loadingTrue : "ItemInventoryReportModule/loadingTrue",
                    addRequest : "ItemInventoryReportModule/addRequest",
                    cancelRequest : "ItemInventoryReportModule/cancelRequest",
                    clearOldRequest : "ItemInventoryReportModule/clearOldRequest"
                }

                const params = { length: length, page: page,...obj}

                ApiGetService.get('inventory', params, configParams).then((response) => {

                    console.log(response)
                    commit('setPage', response.data.current_page)
                    commit('setTotal', response.data.total)
                    resolve(response.data)
                }).catch((err) => reject(err))
            })

        },


    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        setData(state, payload) {
            state.data = payload
        },

        setLength: (state, payload) => {
            state.length = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data = []
        },

    },
    getters: {
        loading: state => state.loading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.Filters,
        req: state => state.request,
        total: state => state.total,
        current_page: state => state.page
    }
}


