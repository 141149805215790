<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
        <el-dialog title="Modify Tier" :visible.sync="dialogIsOpen" :close-on-click-modal="false" width="60%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">
            <div class="dialog-scroll-container p-3 pt-0">
                <template v-if="activeTabSlot == 'main'">
                    <div class="container">
                        <el-steps :active="currentStep" align-center>
                            <el-step icon="el-icon-suitcase" title="Tier Details"></el-step>
                            <el-step icon="el-icon-suitcase" title="Add Transaction"></el-step>
                            <el-step icon="el-icon-bank-card" title="Loyalty Card" ></el-step>
                            <el-step icon="el-icon-finished" title="Finish"></el-step>
                        </el-steps>
                        <ValidationObserver v-if="currentStep === 0">
                            <mdb-row>
                                <mdb-col xl="6" md="6" offset="3">
                                    <div class="mt-4">
                                        <mdb-card class="classic-admin-card mt-1"  v-for="(tier,index) in tiers" :key="index">
                                            <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                                <el-checkbox v-model="tier.selected" @change="onSelectTier(tier,index)"></el-checkbox>
                                                <div >
                                                    <img :src="tier.attr.images" class="loyalty-card-image pl-3"/>
                                                </div>
                                                <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                                    <strong>{{ tier.series }}</strong> <br />
                                                    <strong>{{ tier.name }}</strong> <br />
                                                    <strong>
                                                        Minimum {{ tier.minimum | thousands}} Qualified Points in 12 months
                                                    </strong> <br />
                                                </div>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </div>
                                    <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">
                                        <template>
                                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitMultiStepForm)" :disabled="tierDisable">Next</mdb-btn>
                                        </template>
                                    </mdb-form-inline>
                                </mdb-col>
                            </mdb-row>
                        </ValidationObserver>

                        <ValidationObserver v-if="currentStep === 1">
                            <mdb-row>
                                <mdb-col xl="6" md="6" offset="3">

                                    <mdb-card class="mt-4">
                                        <mdb-card-body>
                                            <ThatsInput labelClass="col-md-3" label="Card Number" placeholder="Card Number" fieldClass="col-md-9"  vid="card.card_number" name="card.card_number" :show_error="true" :showLabel="true" v-model="form.transaction.card_number" :rules="'required'" size="sm" disabled/>
                                            <ThatsInput labelClass="col-md-3" label="Transaction Code" placeholder="Transaction Code" fieldClass="col-md-9"  vid="transaction_code" name="transaction_code" :show_error="true" :showLabel="true" v-model="form.transaction.transaction_code" :rules="'required'" size="sm" />
                                            <ThatsInput labelClass="col-md-3" label="Points" placeholder="Points"
                                                        fieldClass="col-md-9" vid="transaction_amount" name="transaction_amount"
                                                        :show_error="true" :showLabel="true" v-model="form.transaction.transaction_amount"
                                                        :rules="{ required: true, min_value: minVal, max_value: maxVal }" size="sm" />
                                            <ThatsDatePicker tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Transaction Date"
                                                             placeholder="Transaction Date" vid="transaction_date" name="transaction_date" v-model="form.transaction.transaction_date"
                                                             :rules="'required'" size="sm" :show_error="true" :showLabel="true"/>
                                            <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Transaction Type"
                                                              v-model="form.transaction.transaction_type" :reduce="label => label.value"
                                                              :reduceLabel="'label'" :showLabel="true" :show_error="true" :options="types"
                                                              :rules="'required'" :disabled=true></ThatsMultiSelect>
                                        </mdb-card-body>
                                    </mdb-card>
                                    <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">
                                        <template>
                                            <mdb-btn color="elegant" size="sm" @click="backToPrevious">Back</mdb-btn>
                                            <template>
                                                <mdb-btn color="elegant" size="sm" @click="onSubmitMultiStepForm">Next</mdb-btn>
                                            </template>
                                        </template>
                                    </mdb-form-inline>
                                </mdb-col>
                            </mdb-row>
                        </ValidationObserver>
                        <ValidationObserver v-if="currentStep === 2">
                            <mdb-row>
                                <mdb-col xl="6" md="6" offset="3">
                                    <div class="mt-4">
                                        <mdb-card class="classic-admin-card mt-1" >
                                            <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                                <div>
                                                    <img :src="filteredCards.attr.images" class="loyalty-card-image pl-3"/>
                                                </div>
                                                <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                                    <strong>{{ filteredCards.name }}</strong> <br />
                                                    <strong>{{ filteredCards.description }}</strong>
                                                </div>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </div>
                                    <mdb-card class="mt-4">
                                        <mdb-card-body>
                                            <ThatsInput labelClass="col-md-3" label="Card Number" placeholder="Card Number" fieldClass="col-md-9"  vid="card.card_number" name="card.card_number" :show_error="true" :showLabel="true" v-model="form.card.card_number" :rules="'required'" size="sm" />
                                            <ThatsInput labelClass="col-md-3" label="Display Name" placeholder="Display Name" fieldClass="col-md-9"  vid="display_name" name="display_name" :show_error="true" :showLabel="true" v-model="form.card.display_name" :rules="'required'" size="sm" />
                                        </mdb-card-body>
                                    </mdb-card>
                                    <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">
                                        <template>
                                            <mdb-btn color="elegant" size="sm" @click="backToPrevious">Back</mdb-btn>
                                            <template>
                                                <mdb-btn color="elegant" size="sm" @click="onSubmitMultiStepForm">Next</mdb-btn>
                                            </template>
                                        </template>
                                    </mdb-form-inline>
                                </mdb-col>
                            </mdb-row>
                        </ValidationObserver>
                    </div>
                </template>
            </div>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import {mdbBtn, mdbIcon, mdbCard, mdbView, mdbCardBody, mdbCol, mdbRow, mdbFormInline} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,ThatsTextarea,ThatsMultiSelect,ThatsDatePicker } from '../../components/thats/Thats'
import { extend } from 'vee-validate';
import { min_value, max_value } from 'vee-validate/dist/rules';

extend('min_value', {
    ...min_value,
    validate(value, { min }) {
        return value >= min;
    },
    message: 'The value must be greater than or equal to {min}'
});

extend('max_value', {
    ...max_value,
    validate(value, { max }) {
        if (max < 0) {
            return true;
        }
        return value <= max;
    },
    message: 'The value must be less than or equal to {max}'
});
export default {
    components: {
        mdbFormInline, mdbRow, mdbCol, mdbCardBody,
        ThatsDatePicker,
        ThatsMultiSelect,
        mdbBtn,
        mdbIcon,mdbCard,mdbView,
        ValidationObserver,
        ThatsInput,
        ThatsTextarea,
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
        reduce :{
            type : Function,
            default: option => option.code,
        },
        reduceLabel :{
            type: String,
            default: "label"
        },
        show_error: {
            type: Boolean,
            default : false
        },
        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled : {
            type : Boolean,
            default : false
        },
        selectable: {
            type : Function,
            default: option => option,
        },

    },

    data : () => ({
        syncModal : false,
        tableKey : 0,
        activeTabIndex : 0,
        activeTabObject : { text: 'Details', tabSlot : 'main' },
        mdbTabsData : [
            { text: 'Details', tabSlot : 'main' }
        ],
        group2 : 5,
        formData : null,
        formLoading : false,
        readOnly : false,
        showError : true,
        currentStep : 0,
        searchCustomer : null,
        customers : [],
        filteredCards : [],
        cards : [],
        tiers : [],
        dialogIsOpen : false,
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        types : [
            {label : 'Upgrade',value : 'upgrade'},
            {label : 'Downgrade',value : 'downgrade'}
        ],
        isSelectDisabled : false,
        maxVal : 0,
        minVal : 0,

        required:true
    }),

    created : function() {
        var self = this;

        if(this.FormStatus == 'create'){
            this.form = this.formVar();
        }

    },
    methods: {
        ...mapActions('LoyaltyCustomerCardUpgradeModule',[
            'UpdateCustomerCardRecord'
        ]),
        ...mapMutations('LoyaltyCustomerCardUpgradeModule',[
            'SetFormStatusCreate','SetFormRecord'
        ]),
        ...mapMutations('LoyaltyMemberModule',['cancelRequest','setFormStatusCreate']),

        ...mapActions('LoyaltyCardModule',['getLoyaltyCards']),
        ...mapActions('TireModule',['getTiers']),

        ...mapMutations('HistoryModule',[
            'setModule',
            'setRecordId',
        ]),

        formVar(){
            return {
                transaction:{
                    card_number:"",
                    transaction_code:"",
                    transaction_amount:"",
                    transaction_type:"upgrade",
                    transaction_date:"",
                },
                tier:{},
                card:{
                    card_number:"",
                    display_name:""
                },

            }
        },
        /* start */
        closeEditDialog(){
            this.dialogIsOpen = false
            this.form = this.formVar()
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
            this.isSelectDisabled = false;
            this.activeTabIndex = 0;
            this.currentStep = 0;
        },


        onSearch(v){
            this.$emit('onSearch',v)
        },

        resetDefaultTab(){
            this.activeTabIndex = 0,
                this.activeTabObject = { text: 'Details', tabSlot : 'main' }
        },
        getActiveTab(v){
            this.activeTabIndex = v
            this.activeTabObject = this.mdbTabsData[v];
        },

        /* end */
        openModifyDialog(formData){
            this.dialogIsOpen = true
            console.log(formData)
            this.tiers = formData.tiers
            this.form.transaction.card_number = formData.card.card_number
            this.form.card.display_name = formData.card.display_name
            this.form.tier = formData.current_tier;
            const selectedIndex = this.tiers.findIndex(tier => tier.id === formData.current_tier.id);
            this.tiers = this.tiers.map((tier, index) => {
                const selected = (index === selectedIndex);
                return { ...tier, selected };
            });
        },
        closeDialog(){
            this.dialogIsOpen = false;
        },
        onSubmitMultiStepForm(){
            const self = this;
            this.$refs.observer.validate().then((isValid) => {

                if(isValid){

                    if(self.currentStep == 0){
                        self.formData.card.display_name = self.formData.customer.name
                        const current_level = this.formData.current_tier.level;
                        const modify_level = this.form.tier.level;

                        if (current_level > modify_level){
                            console.log('upgrade tiers')
                            this.form.transaction.transaction_type="upgrade"
                            this.maxVal = (this.form.tier.maximum - this.formData.points)
                        } else{
                            console.log('downgrade tiers')
                            this.form.transaction.transaction_type="downgrade"
                            this.maxVal = this.form.tier.maximum
                        }
                        this.minVal = this.form.tier.minimum


                    } if (self.currentStep == 1){
                        self.filteredCards = self.form.tier.card
                    }

                    if(self.currentStep === 2){

                        const storeData = {
                            ...self.formData.customer,
                            card : {...self.form.card},
                            current_card : {...self.formData.card},
                            tier : {...self.form.tier},
                            current_tier : {...self.formData.tier},
                            transaction : {...self.form.transaction},
                        }
                        self.UpdateCustomerCardRecord(storeData).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response)
                            self.closeDialog();
                            self.formLoading = false
                            self.FormResetter();

                        }).catch((error) => {
                            self.formLoading = false
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                                if(error.response.data.hasOwnProperty('message')){
                                    self.$ThatsNotify.error({
                                        message : `${error.response.data.message}`
                                    })
                                }



                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })

                    }else{
                        self.$nextTick(() => {
                            self.currentStep = self.currentStep + 1;
                        })
                    }

                }
            })
        },


        onSelectTier(tier, index) {

            this.form.tier = tier;


            this.tiers = this.tiers.map((item, cardIndex) => {
                const selected = (cardIndex === index) ? true : false;
                return { ...item, selected };
            });
        },

        viewMember(card){
            this.$emit('viewMember',card)
        },

        validateTransactionAmount() {
            const amount = this.form.transaction.transaction_amount;
            if (amount > 50000) {
                alert("Transaction amount must be between 20000 and 50000");
                this.error = "Transaction amount must be between 20000 and 50000";
            } else {
                this.error = null;
            }
        },


        openHistoryDialog(){
            this.setModule('item')
            this.setRecordId(this.formData.mdbID)
            this.$refs.historyDialog.openDialog();
        },

        backToPrevious(){
            this.currentStep--
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
        },

        dialogState(state){
            // this.optionsHandler();
            if(!state){
                this.FormResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.FormResetter();
            }
        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },

        clearLocation(){
            this.form.attr.location = []
        }

    },

    computed: {
        ...mapGetters('LoyaltyCustomerCardUpgradeModule',
            [
                'FormStatus',
                'FormRecord',
            ]),
        ...mapGetters('LoyaltyMemberModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),

        activeTabSlot : function(){
            return this.activeTabObject.tabSlot
        },
        tierDisable() {
            if (this.formData && this.formData.current_tier) {
                const current_level = this.formData.current_tier.level;
                const modify_level = this.form.tier.level;
                return current_level === modify_level;
            } else {
                console.error("formData or current_tier is null or undefined");
                return false;
            }
        },

    },

    watch: {
        FormRecord: function() {
            if (this.FormStatus == 'update') {
                const frm = JSON.parse(JSON.stringify(this.FormRecord));

                frm.attr = {...this.form.attr, ...frm.attr}
                this.getImage = frm.attr.images;
                this.form = {...this.form, ...frm}
            }
            if (this.FormStatus == 'create') {
                this.FormResetter();
            }

        },
        dialogIsOpen: function(newDialogIsOpen) {
            if (!newDialogIsOpen) {
                this.closeEditDialog()
            }
        },
        syncModal : function(value){
            const self = this;
            if(!value){
                this.cancelRequest();
                this.resetDefaultTab();
                this.formData = JSON.parse(JSON.stringify(this.formObject))
                this.tableKey++
            }

            if(value){
                this.getLoyaltyCards().then((res) => {
                    self.cards = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
                this.getTiers().then((res) => {
                    self.tiers = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
            }
            self.currentStep = 0;
            self.customers = [];
        },

        formRecord : function(value){
            this.formData = value

        },

    },
}
</script>

<style lang="scss">

.bts-form-tabs{
    .nav-tabs{
        margin-left: 10px;border-bottom: none!important;
        .nav-item{
            a.active{
                border-bottom: 1px solid #0056b3;
            }
        }
    }

    .tab-content{
        height: auto!important;border-top:1px solid #dee2e6;
    }
}



.avatar-sm {
    width: 56px;
    border-radius: 50%;
}
.chat-message-type {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 0 auto !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
}

.profile-card {
    margin-top: 100px;
}

.profile-card .avatar {
    max-width: 150px;
    max-height: 150px;
    margin: -70px auto 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}

.btn-floating i {
    color: #4f4f4f !important;
}

.loyalty-card-image{
    width: 120px;
}
</style>
