<template>
    <div>

         <ThatsTable title="LOCATION FORM" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm" >
            <template #custom-right-culumns="{}">
            </template>
        </ThatsTable>
        <ComponentForm ref="componentForm" @success="refreshTable"></ComponentForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'

import { ThatsDatePicker, ThatsMultiSelect, ThatsInput, ThatsAutocomplete,ThatsNumber} from '../../components/thats/Thats'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import ComponentForm from './ComponentForm.vue'
export default {
        components:{
            ThatsInput,
            ThatsTable,
            mdbTabs,
            mdbBadge,
            DateRangePicker, mdbRow, mdbCol,
            ThatsDatePicker,
            mdbBtn,
            mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,
            ThatsMultiSelect,
            ValidationObserver,ValidationProvider,
            mdbSelect,
            mdbInput,
            ComponentForm
        },
    inject : [],
    provide(){
        return {

        }
    },
    data(){
        return {
            endpoint : 'location-setup',
            bulkUpdate : 'status',
            viewTransactionLoading : 0,
            tableKey : 0,
            filterForm : {
               
            },
            

        }
    },
    created(){
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {
       ...mapActions('LocationSetupModule',['getRecord','updateRecord']),
       ...mapMutations('LocationSetupModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormData']),
      
        rowClick(row){
            const self = this;

            const mergeFormData = {...this.initialFormObject,...JSON.parse(JSON.stringify(row))}
            self.setFormStatusUpdate();
            self.setFormData(mergeFormData);
            // this.getRecord(mergeFormData)
            self.$refs.componentForm.openDialog();
        },

        clearFilterForm(){
            this.filterForm = { }
        },

     
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.componentForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()

        },

    },
    computed: {
        ...mapGetters('LocationSetupModule',['getLoading', 'columns','initialFormObject']),
        
    },

    watch : {
      
      
    }
}


</script>
