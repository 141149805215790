
<template>

<!-- 
[2:22 PM, 8/10/2020] Thats - Marcus: draft, for approval, approved, sent to vendor, on progress, and finish
[2:22 PM, 8/10/2020] Thats - Marcus: At cancel pa pala
 -->
    <div class="customervendor-container">
           <ThatsTable title="Sales Order" ref="table" :multipleSelection="checkSystemSupport" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns">
        </ThatsTable>
        <SalesOrderForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></SalesOrderForm>
       <!--  
       

        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2"></FileUpload> -->
       
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import SalesOrderForm from './SalesOrderForm'
import ThatsTable from '../../components/thats/Table'
import FileUpload from '../../components/filemanager/FileUpload'
import {mdbTabs} from 'mdbvue'
import { MessageBox } from 'element-ui';
export default {
    data(){
        return {
            endpoint : 'sales-order/data',    
            importData : {},        
            errorData : {},        
            importLoading : false,        
            active : 0,        
            token : null,        
        }
    },
    components: {
        SalesOrderForm,
        ThatsTable,
        FileUpload,
        mdbTabs,

    },

    
    methods: {
        // 

        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapMutations('TableModule',[
            'SetPage',
            'SetTerm',
            'SetFilters',
        ]),

        ...mapActions('SalesOrderModule',[ 'DeleteRecord','saveImport' ,'updateStatus','gitItem']),
        ...mapMutations('SalesOrderModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

             

            const form  = {...obj.row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}           
            self.SetFormStatusUpdate()
            self.SetFormRecord(form)
            self.gitItem({token_id : obj.row.token_id})
            self.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        getActiveTabIndex(index){


            const tabs = ['all','for-approval','in-progress','finished']
            this.endpoint = 'purchase-order/data?status='+tabs[index];
            this.SetPage(1)
            this.resetData('purchase-order/data?status='+tabs[index]);
        },


        updatePurchaseOrderStatus(event,v,row){
            var self = this;
            if(event.target.checked === true){
                
                MessageBox.confirm('Are you sure you want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = true

                    })
                return event.target.checked = false
            }

            if(event.target.checked === false){
                
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = false

                    })
                return event.target.checked = true
            }

            return event.target.checked = false
        /* const self = this;
        console.log(event.target.checked)
        this.$emit("input", false) */
            /* if(v){
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        console.log(event.target);
                        this.$emit("input", event.target.checked)

                    })
            }else{
                MessageBox.confirm('Are you sure want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                         const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)
                    })
            } */
        /* if(self.FormStatus == 'update'){
            MessageBox.confirm('Are you sure you want to change status?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {

                    const formData = { 
                    hash : self.form.token_id,
                    status : status
                    }
                    self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.closeDialog();
                        self.$emit('success')
                        self.FormResetter();
                        
                    }).catch((error) => {
                        self.formLoading = false
                        self.response.error = 1;
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){
                            self.$refs.observer.setErrors(error.response.data.errors);
                            this.showError = true
                            }
                        } else if (error.request) {
                            self.response.message = error.request
                        } else {
                            self.response.message = error.message
                        }
                        return;
                    })

                })
            
        } */
        },

        fnUpdateStatus(formData){
            var self = this;
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.refreshTable();
                    }).catch((error) => {
                        self.$ThatsNotify.success({
                        message : "Something wen't wrong"
                        })
                        self.refreshTable();
                    })
            
        }

    },
    computed: {
        ...mapGetters('SalesOrderModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>