<template>
  <div class="itmform-container">
    <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
        <li  @click="insertRow">Insert</li>
        <template v-if="form.items.length > 2">
          <li @click="moveUpRow">Move Up</li>
          <li @click="moveDownRow">Move Down</li>
        </template>
        <li @click="deleteRow">Delete</li>
    </ul>
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Online Order" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="800" :width="1080" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                  <template v-if="formStatus == 'update'">
                    <mdb-btn @click="fnPrint('/online-orders/transactions/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print</mdb-btn>
                      
                  </template>
                </template>

                <template v-if="selected.length > 0">
                    <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                  </template>
              </template>

            <template #right-button>
              <template v-if="formStatus == 'update'">
                <template v-if="invoice.series === null || parseFloat(invoice.balance) > 0">
                  <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Make Payment</mdb-btn>
                </template>
                <template v-if="invoice.series !== null && checkSystemSupport">
                  <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Add Payment</mdb-btn>
                </template>
                <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && modify === false">
                  <mdb-btn color="elegant" size="sm" @click="enableModify">Modify</mdb-btn>
                </template>
                <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && modify === true">
                  <mdb-btn color="elegant" size="sm" @click="cancelModification" :disabled="viewLoading">Cancel Modification</mdb-btn>
                </template>
                <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager)">
                  <mdb-btn color="elegant" size="sm" :disabled="viewLoading">Return</mdb-btn>
                </template>
                
                <template v-if="invoice.payments.length> 0">
                    <span class="invoice-balance">Total Balance : {{ $helpers.addSeparatorsNF(invoice.balance)}} QAR</span>
                </template>
                
              </template>

              
            </template>
          <template v-if="invoice.payments.length> 0">
            <span class="invoice-number">Sales Invoice #: {{invoice.series}}</span>
          </template>
          <mdbContainer class="mt-1">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">                 
                <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>                  
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.attr.name" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instructions" name="instructions" v-model="form.attr.instructions" size="sm" :disabled="true"/>
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />                  
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Assigned To" :placeholder="form.assigned_to" vid="assigned_to" name="assigned_to" v-model="form.assigned_to" size="sm" :disabled="true" />
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Order Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="noInvoiceDisabled"></ThatsMultiSelect>
                        <template v-if="form.status === 'for-delivery-schedule'">
                          <template v-if="form.hasOwnProperty('delivery')">
                            <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date Request" placeholder="Date Request" vid="date_request" name="date_request" v-model="form.delivery.date" :validated="validated" :rules="'required'" size="sm" :disabled="noInvoiceDisabled"/>
                            <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Delivery Remarks" :placeholder="form.attr.delivery_remarks" vid="delivery_remarks" name="delivery_remarks" v-model="form.delivery.remarks" size="sm" :disabled="noInvoiceDisabled" />
                          </template>
                        </template>
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Payment Status" v-model="form.attr.payment_status" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="''" :filterable="false" :clearable="false" :disabled="noInvoiceDisabled"></ThatsMultiSelect>
                    </mdb-col>
                  </mdb-row>
                  <el-table id="itemTable" border :data="form.items" class="input-table mt-2 itemTable" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" @row-contextmenu="rowContectMenu">
                            <template v-if="formStatus === 'create'">
                              <el-table-column type="selection" width="35"> </el-table-column>
                            </template>
                            <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                            <el-table-column label="SKU" :width="150">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                    {{scope.row.code}}
                                </template>
                                <template v-else>
                                  <GridVueSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></GridVueSelect>
                                </template>
                                
                              </template>
                            </el-table-column>
                            <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                            <el-table-column label="Item Name" :row-class-name="''">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                  
                                    {{scope.row.name}}
                                </template>
                                <template v-else>
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                              </template>
                            </el-table-column>
                            <template v-if="form.invoice === null">
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="disabled">
                                  
                                    {{scope.row.qty}}
                                </template>
                                <template v-else>
                                  <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                                
                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount"></span>
                                  <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <template v-else>
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="disabled">
                                  
                                    {{scope.row.qty}}
                                </template>
                                <template v-else><input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount"></span>
                                 <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <el-table-column label="Remarks">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="disabled" />
                              </template>
                            </el-table-column>
                          </el-table>
              

                          <table class="el-table">
                <tr>
                  <template v-if="!disabled">
                  <td></td>
                  </template>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="width:100px"  class="text-right">
                    

                    <!-- <ThatsCheckbox :label="''" vid="enable-discount" orientation="inline" name="enable-discount" :data="[]" v-model="form.more_discount.enable" :validated="validated" :rules="''" size="sm" :multiple="false" style="float: right; display: block; width: 25px;"/> -->
</td>
                  <td style="width:100px"><el-checkbox v-model="form.more_discount.enable" :disabled="disabled">More Discount </el-checkbox></td>
                  <td style="width:100px"><input type="text" class="form-control grid-input thats-form-control text-right" v-model="form.more_discount.discount" @keydown.enter="addMoreDiscount()" :disabled="disabled" /></td>
                  <td style="width:120px" class="text-right">
                      <strong>{{form.total}}</strong>
                  </td>
                  <template v-if="disabled">
                      <td style="width:130px"></td>
                  </template>
                  <template v-else>
                  <td style="width:114px"></td>
                  </template>
                </tr>
            </table>
            
                          
              </template>
              <template :slot="'invoice-slot'">
                 <mdb-row >
                    <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="true"/>
                    </mdb-col>    
                    <mdb-col col="6">
                      
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instruction" name="instruction" v-model="form.attr.instruction" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Other Details" vid="other_details" name="other_details" v-model="form.attr.other_details" size="sm" :disabled="true"/>
                    </mdb-col>    
                    
                  </mdb-row>
              
                  <el-table border :data="invoice.payments" class="input-table mt-2" style="width: 100%" :height="'250px'">
                    <el-table-column label="Series">
                      <template slot-scope="scope">
                        <a href="javascript:void(0)" @click="openPayment(scope.row)" v-html="scope.row.series"></a>
                
                      </template>
                    </el-table-column>
                    <el-table-column label="Date">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.date" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Mode of Payment">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.mode_of_payment" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Payment Description" width="160">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.payment_method" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Amount" :width="100">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" :value="scope.row.formatted_amount" :disabled="true" />
                      </template>
                    </el-table-column>
                     <el-table-column :width="100" label="Receipt">
                        <template slot-scope="scope">
                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                        </template>
                    </el-table-column>
                  </el-table>

              </template>
              <template :slot="'form-attachment'">
                   <mdbContainer>
                     <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="sales-transaction-attachment" name="sales-transaction-attachment" v-model="form.attachment" :disabled="false" :showLabel="false" :accept="'*'"></ThatsFile>
                   </mdbContainer>
              </template>
            </mdb-tabs>
            
            
          </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>
      <ContactsForm ref="contactForm" :group="4" @success="contactSuccess"></ContactsForm>
      <PaymentForm ref="paymentForm" :group="4" @success="paymentSuccess" @print="fnPrint" :payment_for="'online-orders'"></PaymentForm>
      <UpdatePaymentForm ref="updatePaymentForm" :data="paymentForm" :group="4" @success="updatePaymentSuccess" @print="fnPrint" :formStatus="paymentFormStatus"></UpdatePaymentForm>
      <DiscountForm ref="discount" :group="4" @apply="applyDiscount"></DiscountForm>
      <PrintWindow ref="printWindow" :group="4" :url="printUrl"></PrintWindow>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import ContactsForm from '../contacts/ContactsForm'
import DiscountForm from './DiscountForm'
import PaymentForm from './PaymentForm'
import UpdatePaymentForm from './UpdatePaymentForm'
import PrintWindow from '../../components/thats/PrintWindow'
import GridVueSelect from './GridVueSelect'
import TableFooter from './TableFooter';
import Sortable from 'sortablejs'
import Checkbox from './Checkbox'
import axios from 'axios'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect, 
        PrintWindow, 
        GridVueSelect, 
        ContactsForm,
        DiscountForm,
        PaymentForm,
        TableFooter,
        
        Checkbox,
        UpdatePaymentForm

  },
    
    data : () => ({
      request : null,
      requests : [],
      disabled : false,
      modify : false,
      printUrl : '',
      active : 1,
      validated : false,
      form : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      tabs : [
                { text: 'Online Order Details', slot: 'form-slot' }
              ],
      delivery_status : [],
      showError : false,
      status : [],
      paymentMethod : [],
      items : [],
      customer : [],

      discountForm : null,
      discountFormIndex : null,
      paymentForm : {
                series : 0,
                date : "",
                mode : "full-payment",
                method : "cash",
                customer : "",
                attr : {},
                description : null,
                invoice_series : null,
                amount : 0,
                reference : null,
                transaction_id : null,
                invoice : {
                  series : null,
                  balance : 0,
                  paid : 0,
                  amount : 0,
                },
                
              },
      paymentFormStatus : 'update',
      customFooter : {rows : [{name : "demo"},{remarks : "remarks"}], columns : [{
              field : 'name'
          },{
              field : 'remarks'
          }]},
      total : 0,
      context: null,
      viewMenu: false,
      top: '0px',
      left: '0px',
      newArrange : [],
      sortableState : {},
      sortableStateStatus : true,
    }),
    

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.form.items.push(formItemVar);
        
        this.nextCode()
        this.currentDateSetter();
        this.tabs = [
                { text: 'Online Order Details', slot: 'form-slot' },
                
              ]
        
      }
       this.optionsHandler();
       this.discountForm = this.formItemVar();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.paymentMethod = settings.payment_mode
        this.status = settings.sales_transaction_order_status;

        // console.log(this.$options);
        

        // new Sortable(tbody);

        /*  */
    },

  mounted(){

      const arr = [
          {name : "test1", code : "test-1"},
          {name : "test2", code : "test-2"},
          {name : "test3", code : "test-3"},
          {name : "test4", code : "test-4"},
          {name : "test5", code : "test-5"},
          {name : "test6", code : "test-6"},
          {name : "test7", code : "test-7"},
      ];

      arr.splice(0, 0, arr.splice(2, 1)[0]);

      // console.log(arr)
         
  },
    methods: {

      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('TransactionModule',[
        'filterItems'
      ]),
/*       ...mapMutations('TransactionModule',[
        'setDiscountForm'
      ]), */
      ...mapMutations('DiscountModule',[
        'setDiscountForm',
      ]),

      ...mapActions('MakePaymentModule',[
        'getCustomerCreditNotes'
      ]),
      ...mapMutations('MakePaymentModule',[
        'setPaymentForm',
      ]),
      ...mapActions('OnlineOrderModule',[
        'storeRecord',
        'updateRecord',
        'updateStatus',
        'getRecord',
  
      ]),
      ...mapMutations('OnlineOrderModule',[
        'setFormStatusCreate',      
        'setFormRecord',
        'setFormStatusUpdate'
      ]),
      ...mapMutations('TableModule',[
        'SetPage'
      ]),

      formVar(){
        return {
          series : "",
          date : "",
          status_index : 0,
          status : "no-remarks",
          attr : {
            name : null,
            mobile : null,
            address : null,
            instruction : null,
            other_details : null,
            payment_status : 'no-payment',
          },
          customer : {
            name : null,
            mobile : null,
            address : null,
          },
          user_id : null,
          assigned_to : null,
          customer_id : null,
          payment_method : 'cash',
          items : [            
          ],
          attachment : [],
          invoice : {
            series : null,
            date : "",
            balance : 0,
            paid : 0,
            attachment : [],
            payments : [],
          },
          delivery : {
            date : "",
            remarks : null,
            status : 'for-delivery-schedule',
          },
          total : 0,
          more_discount : {
              enable : false,
              discount : 0,
          }
        }
      },

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null
              }      
      },
/* start */
paymentSuccess(response){
  /* this.setFormRecord(response.data)
  */
  this.$emit('success'); 
  this.closeDialog()
},
updatePaymentSuccess(v){
  const self = this;

  self.getRecord({token_id : self.form.token_id}).then((response) => {
    self.active = 1
  })
},
applyDiscount(v){

  const frm = v.form;
    const qty = frm.qty;
      let price = frm.price;
      let discount = 0;
      let remarks = null;

      if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
        const itemPromo = frm.promotions[0];
        const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

        const discount = unformat(discountValue);
        frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,itemPromo);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }

      if(frm.discount_type === 'discount'){
        const itemPromo = frm.promotions[0];

         const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

        const discount = unformat(discountValue);
        frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


        // frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,frm.custom_discount);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }


   this.$set(this.form.items, v.index, frm)



    /*   if(frm.promotions.length > 0){
        const itemPromo = frm.promotions[0];
        discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          remarks = this.setRemarks(qty,itemPromo);

      }

      this.$set(this.form.items[v.index], 'discount', discount)
      this.$set(this.form.items[v.index], 'remarks', remarks)
      this.$set(this.form.items[v.index], 'total', this.computeTotalAmount(qty, price, discount))
      this.$set(this.form.items[v.index], 'custom_discount', frm.custom_discount) */


},
cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  // console.log(row);
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6,7,8]
        
        let totalCol = 3;
        let totalQty= 4;
        let totalPrice= 5;
        let totalAmount= 6;
        let totalDiscount= 7;
        let totalTotal= 8;

        if((self.formStatus === 'create')){
            totalCol = 4;
            totalQty= 5;
            totalPrice= 6;
            totalAmount= 7;
            totalDiscount= 8;
            totalTotal= 9;
        }
        
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
// console.log(data)
            const discount = data.map(item => {
              //unformat
              // console.log(item.discount)
              let d = 0;
                  if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                    const itemPromo = item.promotions[0];
                    d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // alert(d)
                  }else{
                    d = unformat(item.discount)
                  }
                return d;
              })

                    
            
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                //  this.form.total =  this.formatNumber(sum)
                 this.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
    getActiveTabIndex(index){
      this.active = index
    },

openPaymentWindow(){

    const paymentformVar = {
    transaction : this.form,
    payment_reference : [],

  }

  this.getCustomerCreditNotes(this.form)

  this.setPaymentForm(paymentformVar);
  /* 
  const paymentformVar = {
    transaction : this.form
  }

  this.setPaymentForm(paymentformVar); */
  this.$refs.paymentForm.openDialog()
},

array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
},

move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);  
   return arr;
},

    optionsHandler(){
      const self = this;
      this.dispatchFilterItems({transaction_date : this.form.date})
      // this.linksHandler();


    },
    destroySortable(){
      this.sortableStateStatus = false
      this.sortableState = null
    },
    initSortable(){
        const self = this;
        this.disableSortable();
      const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{
          onEnd: function (/**Event*/evt) {
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            // console.log(self.form.items)
            const toArrange = JSON.parse(JSON.stringify(self.form.items));
           self.newArrange = self.move(toArrange,oldIndex,newIndex);
           
          },
          sort : true
        });
    },
    disableSortable(){
        const self = this;
        // alert(self.sortableState)
        // console.log(self.sortableState)
/*           alert("on destroy")
        alert(Object.keys(self.sortableState).length)
        alert(self.sortableState.constructor) */
        if(Object.keys(self.sortableState).length !== 0){
          //  self.sortableState.destroy()
        const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{ sort : true });
        }

    
    },
    enableSortable(){
        const self = this;
        
      const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{
          onEnd: function (/**Event*/evt) {

            
            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;

            // console.log(evt)
            const toArrange = JSON.parse(JSON.stringify(self.form.items));
           self.newArrange = self.move(toArrange,oldIndex,newIndex);
            // self.newArrange = [...newArray]
            // self.form.items = newArray

            // self.form.items,newArray

            // self.$set(self.form.items,newArray);

            // console.log(newArray)



/* 
            
 */

/*             

            self.form.items = newArray; */

            // toArrange.splice(newIndex, 0, toArrange.splice(oldIndex, 1)[0]);

              // console.log(toArrange)


            // self.form.items = toArrange;

              if(oldIndex > newIndex){
                  // const elOld = self.form.items[oldIndex];

                  for(let i = newIndex;i <= oldIndex;i++){
                    // const el = newArray[i];
                    // console.log(i)
                    // console.log(el.code)
                    // self.$set(self.form.items, i, el);

                    // const el = self.form.items[i];
                    //self.$set(self.form.items, i, self.form.items[i + 1]);
                
                    // self.$set(self.form.items, i + 1, el);
                // this.closeMenu()

                  }
                  

                  // self.$set(self.form.items,newIndex, elOld);


                 /*  self.form.items.slice(oldIndex,1);

                  console.log(self.form.items); */


               /*   const el = self.form.items[i];
                this.$set(this.form.items, index - 1, el);

                   */

              }

              /* self.form.items.forEach(element => {
                  console.log(element.code)
              }); */
              // console.log()
            /* if(self.form.items.length > 0){
              for(let i = 0;i < self.form.items.length;i++){

       
              }
            } */

               /*  console.log(evt)
            if (index > 0) {
                const el = self.form.items[index];

                console.log(self.form.items);

                console.log(el)
                console.log(index)
              } */
                /* this.$set(this.form.items, index, this.form.items[index - 1]);
                this.$set(this.form.items, index - 1, el);
                this.closeMenu() */

            /* var itemEl = evt.item;  // dragged HTMLElement
            evt.to;    // target list
            evt.from;  // previous list
            evt.oldIndex;  // element's old index within old parent
            evt.newIndex;  // element's new index within new parent
            evt.oldDraggableIndex; // element's old index within old parent, only counting draggable elements
            evt.newDraggableIndex; // element's new index within new parent, only counting draggable elements
            evt.clone // the clone element
            evt.pullMode;  // when item is in another sortable: `"clone"` if cloning, `true` if moving */
          },
          sort : self.sortableStateStatus
        });
    },
    customLabel({label , mobile}){
      return `${label} – ${mobile}`
    },
    fnGetContacts(params){
      const self = this;
      const contacts = []
      this.GetContacts(params).then((response) => {
          if(response.length > 0){
            response.forEach((element) => {
              contacts.push({
                opt : element.label + " " + element.mobile,
                label : element.label,
                value : element.value,
                mobile : element.mobile,
                address : element.address,
              })
            })
          }
        
      })

      self.customer = contacts;
    },
    async setPreparedBy(){
        var self = this;
        const loggedUser = await userData()

        const frm = this.form
        frm.assigned_to = loggedUser.user.name
        this.form = frm;
    },


  deleteItem(){

    if(this.selected.length){
        if(this.formStatus === 'update'){
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                  this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){

                  const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
              })
        }else{
          this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
        }
      

      
    }


// remove object


},

handleSelectionChange(v){

    this.selected = v
  // if(this.form.status === 'create'){
  // }
  //  this.form.delivery_status = 'partial-delivery'

},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    if(getSelected){

      const qty = 1;
      let price = getSelected.price;
      let discount = 0;
      let remarks = null;

      if(getSelected.qty <= 0){
          remarks = "To be order";
      }

      if(getSelected.promotions.length > 0){
        const itemPromo = getSelected.promotions[0];

        const cd = {
          apply: true,
          discount: 0,
          off_price: 0,
          promo: 0,
        }

        discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
          remarks = this.setRemarks(qty,itemPromo);
           this.$set(this.form.items[index], 'discount_type','promo');
      }

      this.$set(this.form.items[index], 'item_id', getSelected.id)
      this.$set(this.form.items[index], 'name', getSelected.label)
      this.$set(this.form.items[index], 'default', getSelected.default)
      this.$set(this.form.items[index], 'qty', qty)
      this.$set(this.form.items[index], 'promotions', getSelected.promotions)
      this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
      this.$set(this.form.items[index], 'price', price)
      this.$set(this.form.items[index], 'amount', this.computeAmount(qty, price))
      this.$set(this.form.items[index], 'discount', discount)
      this.$set(this.form.items[index], 'remarks', remarks)
      this.$set(this.form.items[index], 'total', this.computeTotalAmount(qty, price, discount))
      // this.addNewRow(index)

      // console.log(this.form.items[index])

      this.newArrange = this.move(this.form.items,index,index);

      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)
        }
    }
},

statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }

  
},

dispatchFilterItems(params){
  const results = [];
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default})
          });
        }
    })
  this.items = results

},

statusKeyUp(v){
// this.getPurchaseOrder(v)

const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){

                        if(self.newArrange.length > 0){
                          self.form.items = self.newArrange
                        }
                          self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.setFormStatusUpdate()
                              self.setFormRecord(response.data)
                              self.formLoading = false
                              self.SetPage(1)
                              self.newArrange = []
                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                        
                        if(self.newArrange.length > 0){
                          self.form.items = self.newArrange
                        }

                        self.modify = false
                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                              self.setFormRecord(response.data)
                              self.formLoading = false
                              self.newArrange = []
                              self.modify = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.modify = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        // this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();
        this.tabs = [
                { text: 'Online Order Details', slot: 'form-slot' }
              ]
        this.disabled = false
        this.modify = false
        this.newArrange = []

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          // this.disableSortable()
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          this.initSortable();
            this.nextCode();
            this.tabs = [
                { text: 'Online Order Details', slot: 'form-slot' }
              ]
          
        }

        // alert("dialog State " + this.formStatus)
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

/*         console.log("custom");
        console.log(custom_discount); */
        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = unformat(discount);
        const amount = qty * unformat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        this.printUrl = url
        this.$refs.printWindow.openDialog();
      },
      
      getDeliveryStatusSelected(v){
        console.log(v)
      },

      toggleCheck(){
        const self = this;
        if(this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);

          // alert(getCode.toString())
          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              });
                
              }else{
                self.$refs.itemsTable.clearSelection();
              } 

          // const indexZero = this.form.transaction.items[0];
          //     this.$refs.itemsTable.toggleRowSelection(indexZero);

        
        }
      },

      getRowKey(row){
        return row.code
      },
      fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["mobile", "label"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },

    searchCustomer(v){
        const self = this;  
        if(v !== ""){
            this.fnGetContacts({term : v})
        }
    },
      getCustomerSelected(v){
        const selectedCustomer = this.customer.filter(c => {
            return c.value === v;
        })[0];

        if(selectedCustomer !== null){
            this.form.attr.name = selectedCustomer.label
            this.form.attr.mobile = selectedCustomer.mobile
            this.form.attr.address = selectedCustomer.address
        }

      },

      openCustomer(){
        this.$refs.contactForm.openDialog();
      },
      contactSuccess(v){
        const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.address }
        this.customer.unshift(contact);
        this.form.customer_id = v.mdbID
        this.form.mobile = v.mobile
        this.form.attr.name = v.name;
        this.form.attr.mobile = v.mobile;
        this.form.attr.address = v.address;

        // alert(v.name)
      },

      openPayment(paymentRow){
        this.paymentFormStatus = 'update'
        this.paymentForm = paymentRow
        this.paymentForm.invoice_series = this.form.series
        this.paymentForm.customer = this.form.attr.name + " "+ this.form.attr.mobile
        this.$refs.updatePaymentForm.openDialog();
      },
      openUpdatePaymentWindow(){
        this.paymentFormStatus = 'create'
        const pForm = {...this.paymentForm,invoice_series : this.form.seires, customer : this.form.attr.name + " "+ this.form.attr.mobile}
        // this.paymentForm.invoice_series = this.form.series
        this.paymentForm = pForm
        this.$refs.updatePaymentForm.openDialog();
      },
      addMoreDiscount(){

      },
      sendRequest(){
        if (this.request) this.cancelRequest();
          this.makeRequest()
      },
      cancelRequest(){
        this.request.cancel();
      this.clearOldRequest("Cancelled");
      },

      makeRequest(){
const axiosSource = axios.CancelToken.source();
          this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
          axios
            .get("/api/user/filter", { cancelToken: axiosSource.token })
            .then(() => {
              this.clearOldRequest("Success");
            })
            .catch(this.logResponseErrors);
      },
      logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log("Request cancelled");
      }
    },
      clearOldRequest(msg) {
        this.request.msg = msg;
        this.requests.push(this.request);
        this.request = null;
      },

      enableModify(){
        this.modify = true
        this.disabled = false
        if(this.form.items.length === 0){
          const formItemVar = this.formItemVar();
            this.form.items.push(formItemVar)
        }
        this.initSortable();
      },

       openLink(target) {
        console.log(target);
      },
      copyLink(target) {
        console.log(target);
      },
      
      rowContectMenu(row,col,e){
        const self = this

        // console.log(row)
        if((row.code !== null && this.formStatus === 'create') || (this.formStatus === 'update' && row.code !== null && (this.modify === true || this.form.invoice === null))){
            this.context = row
            this.viewMenu = true;
              this.$nextTick(function() {
                self.$refs.right.focus();
                self.setMenu(e.y, e.x)
              }.bind(this));
              e.preventDefault();
        }
      },

      setMenu: function(top, left) {
        
        const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu: function() {
            this.viewMenu = false;
            this.context = null
        },

        deleteRow(){
          const self = this
            if(this.context != null){
              const matchItem = this.form.items.filter((i) => {
                return i.code != self.context.code;
              })
              self.form.items = matchItem
              this.closeMenu()
            }

        },
        moveUpRow(){
           const self = this
            if(this.context != null){
              const index = this.form.items.findIndex(e => e.code == self.context.code);

              if (index > 0) {
                const el = this.form.items[index];
                this.$set(this.form.items, index, this.form.items[index - 1]);

                this.$set(this.form.items, index - 1, el);
                this.closeMenu()
              }

              // const matchItem = this.form.items.filter((i) => {
              //   return i.code != self.context.code;
              // })
              // self.form.items = matchItem
              // this.closeMenu()
            }
        },
        moveDownRow(){
           const self = this
            if(this.context != null){
              const index = this.form.items.findIndex(e => e.code == self.context.code);

              // if (index > 0) {
                
                //  debugger;

                // alert(index +" === " + (self.form.items.length - 2))
                  if (index !== -1 && index < self.form.items.length - 2) {
                    const el = self.form.items[index];
                    this.$set(self.form.items, index, self.form.items[index + 1]);
                    this.$set(self.form.items, index + 1, el);
                    this.closeMenu()
                  }
              // }

              // const matchItem = this.form.items.filter((i) => {
              //   return i.code != self.context.code;
              // })
              // self.form.items = matchItem
              // this.closeMenu()
            }
        },

        cancelModification(){

          const self = this;
          self.getRecord({token_id : self.form.token_id}).then(() => {
            self.modify = false
            self.disabled = true
          })

        },

        insertRow(){
            const self = this
            if(this.context != null){
              const index = this.form.items.findIndex(e => e.code == self.context.code);
              const formItemVar = this.formItemVar();
              this.form.items.splice((index + 1), 0, formItemVar);
              this.closeMenu()
            }


        }

       
      

    },

    computed: {

      ...mapGetters('OnlineOrderModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      noInvoiceDisabled : function(){
          if(this.formStatus === 'create' || this.form.invoice === null || (this.form.invoice !== null && this.form.invoice.series === null)){
            return true;
          }
          return false
      },

     
      invoice : function(){
          let inv = {
          series : null,
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }
/* 
        if(this.formStatus == 'update'){
            if(this.formRecord != null && this.formRecord.invoice !== null){
              inv = this.formRecord.invoice
            }

        }

        inv.balance = unformat(inv.balance) */
        inv.balance = unformat(this.form.total)
        return inv
      },

      checkSystemSupport : function(){
          return this.$helpers.isSystemAdministrator();
      },
      checkShowroomManager : function(){
          return this.$helpers.isShowroomManager();
      }
      
    },

    watch: {
      // viewLoading : function(v){
      //     if(v === false && this.formStatus === 'update'){
      //          if(this.form.invoice !== null  && (v.invoice.hasOwnProperty('series') && v.invoice.series !== null)){
      //            alert("watch viewloading invoiced")

      //          }else{
      //            alert("watch viewloading update quotation")
      //          }
      //     }
      // },
      formRecord : function(v){
        const self = this;
        this.active = 0;
          this.disabled = false
        // alert(this.formStatus);
        if(this.formStatus == 'update'){

          this.initSortable();

          // alert("update")
            // if(!this.viewLoading){
              if(v.invoice !== null && v.invoice.series !== null){
                  this.disabled = true
                      this.tabs = [
                        { text: 'Online Order Details', slot: 'form-slot' }
                      ]

                      // alert("invoiced")
                  }else{
                    this.form.invoice = {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    }
//  alert("quotation")
                    

              }
            // }

                  
                const jsonParse = JSON.parse(JSON.stringify(this.formRecord));
                jsonParse.attr = {...this.form.attr,...jsonParse.attr}
                
                this.form = jsonParse;

                if(!this.disabled){
                  const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }

          if(Object.keys(v).length !== 0 && v.constructor === Object){
            this.customer = [{
                opt : this.form.attr.name + " "+ this.form.attr.mobile,
                label :this.form.attr.name,
                value : this.form.customer_id,
                mobile : this.form.attr.mobile,
                address : this.form.attr.address,
            }];
          }
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          // this.initSortable();
          // alert("watch create")
        }
      },

      'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              }).reduce((a,b) => {
                return a + b;
              })
              const formTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
              if(this.form.more_discount.enable){
                    const unformatedFormTotal = unformat(formTotal);
                    const discount = this.form.more_discount.discount;
                    this.form.total = formatMoney((unformatedFormTotal - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
                }else{
                  this.form.total = formTotal
                } 
                this.total = formTotal;
            }

          },
          deep: true
      },
      "form.more_discount.discount" : function(v){
          const discount = unformat(v);
          const total = unformat(this.total);
            this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });

          if(this.form.more_discount.enable === false){
            this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }


/* 
          if(this.form.more_discount.enable){
                    const unformatedFormTotal = unformat(this.form.total);
                    this.form.total = formatMoney((unformatedFormTotal - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }else{
            this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          } */
      },
      "form.more_discount.enable" : function(v){
        const discount = unformat(this.form.more_discount.discount);
          const total = unformat(this.total);
          this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
            // alert(typeof v + " ==== " + total + "-" + discount)
          if(v === false){
                this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }
          
          
            // this.form.more_discount = false;
          //   alert(1)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
          // if(this.form.more_discount === false){
          //   alert(false)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
      }

    },

   


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}


</style>