<template>
    <div>
  
        <mdbCalendar :events="events" @dateChange="dateChange" :defaultView="'month'"></mdbCalendar>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import {mdbCalendar} from '../../components/vue-fullcalendar'
export default {

  components: {
    mdbCalendar
  },

  data : () => ({
    events : [
      /*  {
            title: 'Meeting',
            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 8, 23, 59,
              59),
            color: 'warning'
          },
          {
            title: 'Front-End Conference',
            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59,
              59),
            color: 'danger'
          },
          {
            title: 'Feedback',
            start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 13),
            end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 13, 23, 59,
              59),
            color: 'success'
          } */
        ],
    date :  new Date(),
    firstDayOfFirstWeekOfMonth : null,
    lastDayOfLastWeekOfMonth : null
  }),

  mounted(){
    /* let newData = []
      let firstDayOfMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay()
      let daysInMonth = this.getDaysInMonth(this.date.getMonth(), this.date.getYear())
      let prevMonth = this.date.getMonth() === 0 ? 11 : this.date.getMonth() - 1
      let prevYear = prevMonth === 12 ? this.date.getYear() - 1 : this.date.getYear()
      let daysInPrevMonth = this.getDaysInMonth(prevMonth, prevYear)
      let lastDayOfPrevMonth = daysInPrevMonth.pop()
      let i = 0, j = 0
      for (i; i < 42; i++) {
        if (i >= firstDayOfMonth) {
          if (daysInMonth[i - firstDayOfMonth] === undefined) {
            j++
            newData.push({
              day: j,
              date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, j),
              type: 'light'
            })
          } else {
            let currentDate = new Date(this.date.getFullYear(), this.date.getMonth(), i - firstDayOfMonth + 1)
            let checkToday = currentDate.toDateString() === new Date().toDateString() ? true : false
            newData.push({
              day: daysInMonth[i - firstDayOfMonth],
              date: currentDate,
              today: checkToday
            })
          }
        } else {
          newData.push({
            day: lastDayOfPrevMonth - firstDayOfMonth + i + 1,
            date: new Date(this.date.getFullYear(), this.date.getMonth() - 1, lastDayOfPrevMonth - firstDayOfMonth + i + 1),
            type: 'light'
          })
        } 
      }


    const firstDayOfFirstWeekOfMonth = newData[0]
    const lastDayOfLastWeekOfMonth = newData[(newData.length - 1)]

    this.lastDayOfLastWeekOfMonth = lastDayOfLastWeekOfMonth
    this.firstDayOfFirstWeekOfMonth = firstDayOfFirstWeekOfMonth


    this.getDeliverySchedule({
        start : firstDayOfFirstWeekOfMonth,
        lastDayOfLastWeekOfMonth : lastDayOfLastWeekOfMonth,
    }).then((res) => {
        console.log(res)
    }).catch((error) => {
        console.log(error)
    }) */

    //   console.log("newData[41]")
    //   console.log()




     /*  this.$emit('calendar',{
        from : newData[0],
        to : newData[41],
      }) */

    this.dispatchGetEvents()
      
  },

  methods : {
    ...mapActions('DeliveryScheduleModule',['getDeliverySchedule']),
        getDaysInMonth(month, year) {
            let date = new Date(year, month, 1)
            let days = []
            while (date.getMonth() === month) {
                days.push(new Date(date).getDate());
                date.setDate(date.getDate() + 1)
            }
            return days;
        },
        dateChange(v){
            console.log("date Change")
            console.log(v)
            // this.date = v.toDateString()

            const date = v



            // Get year, month, and day part from the date
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        this.date = `${year}-${month}-${day}`;
        console.log(this.date);  // Prints: 2022-05-04



            this.dispatchGetEvents()
        },

        dispatchGetEvents(){
            const self = this
          
            this.getDeliverySchedule({
                date : this.date
            }).then((res) => {

              
                self.events = res

             
            })
        },

        pushEvents(){
            this.events.push({
                    title: 'Meeting',
                    start: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7),
                    end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 8, 23, 59,
                    59),
                    type: 'inv',
                    color: 'warning'
                })
        }
  },

  computed : {
        newData : function(){
            const events = [
                                {
                                    "name": "Project Kickoff Meeting",
                                    "dateStart": "2023-04-01T09:00:00.000Z",
                                    "dateEnd": "2023-04-01T11:00:00.000Z",
                                    "type": "inv",
                                    "color": "success"
                                },
                                 {
                                    "name": "Project Kickoff Meeting",
                                    "dateStart": "2023-04-01T09:00:00.000Z",
                                    "dateEnd": "2023-04-01T11:00:00.000Z",
                                    "type": "inv",
                                    "color": "success"
                                },
                                 {
                                    "name": "Project Kickoff Meeting",
                                    "dateStart": "2023-04-01T09:00:00.000Z",
                                    "dateEnd": "2023-04-01T11:00:00.000Z",
                                    "type": "ins",
                                    "color": "success"
                                },
                                {
                                    "name": "New Hire Orientation",
                                    "dateStart": "2023-04-02T08:30:00.000Z",
                                    "dateEnd": "2023-04-02T16:30:00.000Z",
                                    "type": "ins",
                                    "color": "danger"
                                },
                                {
                                    "name": "Team Building Retreat",
                                    "dateStart": "2023-04-05T10:00:00.000Z",
                                    "dateEnd": "2023-04-07T16:00:00.000Z",
                                    "type": "pul",
                                    "color": "warning"
                                },
                                {
                                    "name": "Quarterly Performance Review",
                                    "dateStart": "2023-04-12T14:00:00.000Z",
                                    "dateEnd": "2023-04-12T15:30:00.000Z",
                                    "type": "sch",
                                    "color": "sch",
                                },
                                {
                                    "name": "Product Launch Webinar",
                                    "dateStart": "2023-04-18T16:00:00.000Z",
                                    "dateEnd": "2023-04-18T17:30:00.000Z",
                                    "type": "inv",
                                    "color": "success"
                                },
                                {
                                    "name": "Industry Conference",
                                    "dateStart": "2023-04-22T09:00:00.000Z",
                                    "dateEnd": "2023-04-25T18:00:00.000Z",
                                    "type": "sch",
                                    "color": "sch",
                                }
                                ]


            const groupedEvents = {};

                events.forEach((event) => {
                const startDate = event.dateStart.split("T")[0];
                if (!groupedEvents[startDate]) {
                    groupedEvents[startDate] = {};
                }
                if (!groupedEvents[startDate][event.type]) {
                    groupedEvents[startDate][event.type] = 0;
                }
                groupedEvents[startDate][event.type]++;
                });


                const arrayEvents = []
                const typex = []
                typex['sch']  = 'success'
                typex['inv']  = 'info'
                typex['pul']  = 'warning'

                for (const startDate in groupedEvents) {
                    //   console.log(startDate);
                    for (const type in groupedEvents[startDate]) {
                        // console.log(`- ${groupedEvents[startDate][type]} ${type} events`);

                        const dt = new Date(startDate);

                        arrayEvents.push({
                                    "title": `- ${groupedEvents[startDate][type]} ${type} events`,
                                    start: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()),
                                    end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59),
                                    "type": type,
                                    "color": typex[type],
                                })
                    }
                    }

                console.log(groupedEvents);

            return arrayEvents
        }
  }
}
</script>

<style>
.container {
  margin: 50px auto;
}
</style>
