
<template>
    <div class="customervendor-container">
        <ThatsTable title="Employees Details" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete
        " @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="true" @import="openUploadWindow"></ThatsTable>
        <EmployeesForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></EmployeesForm>
        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2" :endpoint="'employee/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>

    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import EmployeesForm from './EmployeesForm'
import ThatsTable from '../../components/thats/Table'
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from './TableImport'
import TableError from './TableError'
export default {
    data(){
        return {
            endpoint : 'employees/data',
            importLoading : false,
            importData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],        
            errorData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],
        }
    },
    components: {
        FileUpload,
        EmployeesForm,
        TableImport,
        TableError,
        ThatsTable
    },

    methods: {
        ...mapActions('EmployeesModule',[ 'DeleteRecord','saveImport']),
        ...mapMutations('EmployeesModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })

        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        openUploadWindow(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },
        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ import : v}).then((response) => {
                    this.$refs.table.refreshTable()

                    this.importLoading = false;

                    if(response.success > 0){
                        this.$ThatsNotify.success({
                            message : response.message,
                            timeOut : 15000
                        })
                    }
                    this.$refs.tableImport.closeDialog();

                    const err = response.errors.map((i) => {
                        return i.rows.length
                    }).reduce((a,b) =>{
                        return a + b;
                    }); 

                    if(err > 0){
                        this.errorData = response.errors
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : error.message,
                        timeOut : 15000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },
    },
    computed: {
        ...mapGetters('EmployeesModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>