var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"mdb-touch",rawName:"v-mdb-touch:swipe.right.carousel",value:(_vm.swipeRight),expression:"swipeRight",arg:"swipe",modifiers:{"right":true,"carousel":true}},{name:"mdb-touch",rawName:"v-mdb-touch:swipe.left.carousel",value:(_vm.swipeLeft),expression:"swipeLeft",arg:"swipe",modifiers:{"left":true,"carousel":true}}],ref:"sidenav",attrs:{"role":"navigation"},on:{"touchmove":function($event){$event.preventDefault();}}},[_c('transition',{on:{"enter":_vm.enter,"before-enter":_vm.beforeEnter,"leave":_vm.leave}},[_c('div',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],staticClass:"mdbvue-sidenav",class:_vm.sidenavClasses,style:(_vm.sidenavStyle)},
          _vm.expandOnHover && {
            mouseenter: function () {
              _vm.slimMode = false;
            },
            mouseleave: function () {
              _vm.slimMode = true;
            }
          }
        ),[_c(_vm.fixedHeader ? 'div' : 'mdb-scrollbar',{tag:"component",staticClass:"mdbvue-sidenav__content",style:({ padding: _vm.fixedHeader ? '0' : '8px' }),attrs:{"suppressScrollX":""}},[_c('div',{staticClass:"mdbvue-sidenav__header",style:({ padding: _vm.fixedHeader ? '8px' : '0' })},[_vm._t("header")],2),_vm._v(" "),_c(_vm.fixedHeader ? 'mdb-scrollbar' : 'div',{tag:"component",staticClass:"mdbvue-sidenav__body",style:({ padding: _vm.fixedHeader ? '8px' : '0' }),attrs:{"suppressScrollX":""}},[_vm._t("content-header"),_vm._v(" "),_vm._l((_vm.links),function(group,i){return _c('div',{key:i},[(group.name)?_c('h6',{staticClass:"group-title grey-text"},[_vm._v("\n                "+_vm._s(group.name)+"\n              ")]):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"list-unstyled"},_vm._l((group.data),function(link,j){return _c('side-nav-item',{key:j,attrs:{"link":link,"children":link.children,"href":link.href,"to":link.to,"popup":link.popup,"name":link.name,"icon":link.icon,"far":link.far,"fal":link.fal,"fab":link.fab,"fad":link.fad,"active":_vm.activeCategory === j && _vm.value && _vm.activeGroup === i,"slim":_vm.slim && _vm.slimMode},on:{"expandCategory":function($event){return _vm.expandCategory(j, i)},"itemClick":function($event){return _vm.itemClick($event, j, i)},"onItemClick":_vm.onItemClick}})}),1),_vm._v(" "),(i !== _vm.links.length - 1)?_c('hr'):_vm._e()])}),_vm._v(" "),_vm._t("content")],2)],1)],1)]),_vm._v(" "),_c('transition',{attrs:{"enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(_vm.value && _vm.backdrop && _vm.over)?_c('div',{staticClass:"mdbvue-backdrop",class:_vm.backdropClass,on:{"click":function($event){return _vm.$emit('input', false)},"wheel":_vm.blockScroll}}):_vm._e()])],1),_vm._v(" "),_c('div',{ref:"nav"},[_vm._t("nav")],2),_vm._v(" "),_c('div',{ref:"main"},[_vm._t("main")],2),_vm._v(" "),_c('div',{ref:"footer"},[_vm._t("footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }