import { ApiHttpService } from '../../services'

import router from '../../router'
export const DataTableModule = {
    namespaced: true,
    state: {
        data: {
            columns: [],
            rows: [],
        },
        selection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        request : null
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        loadingFalse(state) {
            state.loading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.selection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },
        setFormData : (state, payload)=>{
            state.form = payload
        },

    },
    actions : {
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
            const { length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                    commit('loadingTrue')
                    commit('setRows', [])
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }
                    const configParams = {
                        req : "DataTableModule/req",
                        loadingTrue : "DataTableModule/loadingTrue",
                        addRequest : "DataTableModule/addRequest",
                        cancelRequest : "DataTableModule/cancelRequest",
                        clearOldRequest : "DataTableModule/clearOldRequest"
                    }
                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('loadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('loadingFalse')
                        reject(error)
                    })
                
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.selection,
        loading : state => state.loading,
        tableLoading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request
    }
}



