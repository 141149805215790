import { ApiHttpService } from '../../services'

import router from '../../router'
export const CustomerSegmentModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Series',
                    field: 'series',
                    sort: 'asc',
                    width: '130'
                },
                {
                    label: 'Customer',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                },

                {
                    label: 'Mobile',
                    field: 'mobile',
                    sort: 'asc',
                    width: '200'
                },

                ],
            rows: [],
            customer_rows: [],
            customer_quotation_rows: [],
            customer_invoice_rows: [],
        },
        multipleSelection: [],
        length: 25,
        customer_length: 25,
        customer_quotation_length: 25,
        customer_invoice_length: 25,
        page: 1,
        customer_page: 1,
        customer_quotation_page: 1,
        customer_invoice_page: 1,
        total: 1,
        customer_total: 1,
        customer_quotation_total: 1,
        customer_invoice_total: 1,
        searchTerm: null,
        searchTermQuot: null,
        searchTermInvo: null,
        filters: {},
        loading : false,
        loadingQuotation: false,
        loadingInvoice: false,
        postLoading : false,
        form : {},


        initialFormData : {
            series : "",
            name : "",
            mobile : "",
            description: null,
            attr : {
            },
        },
        customerDetails: {
            name: '',
            id: '',
            mobile: '',
            address : ''
        },

        customerTrack : [],
        customerQuotation: [],
        customerInvoice : [],
        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            // state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        loadingTrueQuotation(state) {
            state.loadingQuotation = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },

        setPage: (state, payload) => {
            state.page = payload
        },

        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },

        // Customer for pagination's starts
        setCustomerLength: (state, payload) => {
            state.customer_length = payload
        },
        setCustomerRows(state, payload) {
            state.data.customer_rows = payload
        },
        setCustomerPage: (state, payload) => {
            state.customer_page = payload
        },
        setCustomerTotal: (state, payload) => {
            state.customer_total = payload
        },
        // Customer for pagination's ends

        // Customer quotations for pagination's starts
        setCustomerQuotationLength: (state, payload) => {
            state.customer_quotation_length = payload
        },
        setCustomerQuotationRows(state, payload) {
            state.data.customer_quotation_rows = payload
        },
        setCustomerQuotationPage: (state, payload) => {
            state.customer_quotation_page = payload
        },
        setCustomerQuotationTotal: (state, payload) => {
            state.customer_quotation_total = payload
        },
        setLoadingQuotation(state) {
            state.loadingQuotation = false;
        },
        // Customer quotations for pagination's ends

        // Customer invoice for pagination's starts
        setCustomerInvoiceLength: (state, payload) => {
            state.customer_invoice_length = payload
        },
        setCustomerInvoiceRows(state, payload) {
            state.data.customer_invoice_rows = payload
        },
        setCustomerInvoicePage: (state, payload) => {
            state.customer_invoice_page = payload
        },
        setCustomerInvoiceTotal: (state, payload) => {
            state.customer_invoice_total = payload
        },
        setLoadingInvoice(state) {
            state.loadingInvoice = false;
        },
        // Customer invoice for pagination's ends

        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSearchTermQuotation: (state, payload) => {
            state.searchTermQuot = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setTermQuotation: (state, payload) => {
            state.searchTermQuot = payload
        },
        setTermInvoice: (state, payload) => {
            state.searchTermInvo = payload
        },
        setCustomerDetails(state, details) {
            state.customerDetails = details;
        },
        setCustomerTrack(state, payload) {
            state.customerTrack = payload;
        },
        setCustomerQuotation(state, payload) {
            state.customerQuotation = payload;
        },
        setCustomerInvoice(state, payload) {
            state.customerInvoice = payload;
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        resetDataQuotation({ dispatch }, endpoint) {
            return new Promise((resolve, reject) => {
                dispatch('getCustomerQuotation', endpoint)
                    .then((res) => {
                        console.log("reset data");
                        console.log(res);
                        resolve(res);
                    })
                    .catch((error) => {
                        console.log("reset error");
                        console.log(error);
                        reject(error);
                    });
            });
        },
        resetDataInvoice({ dispatch }, endpoint) {
            return new Promise((resolve, reject) => {
                dispatch('getCustomerInvoice', endpoint)
                    .then((res) => {
                        console.log("reset data");
                        console.log(res);
                        resolve(res);
                    })
                    .catch((error) => {
                        console.log("reset error");
                        console.log(error);
                        reject(error);
                    });
            });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { length, page, filters, searchTerm, sort } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {

                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name,sort : sort }


                    const configParams = {
                        req : "CustomerSegmentModule/req",
                        loadingTrue : "CustomerSegmentModule/loadingTrue",
                        addRequest : "CustomerSegmentModule/addRequest",
                        cancelRequest : "CustomerSegmentModule/cancelRequest",
                        clearOldRequest : "CustomerSegmentModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })

            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}

                    ApiHttpService.getRequest('customer-segment/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
//           ====================================CUSTOM CODE START========================================
// Get Customer Details
        async getCustomerDetails({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    // const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}

                    ApiHttpService.getRequest('customer-segments/customer/'+obj,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)

                        commit('setCustomerDetails', response.data);
                        resolve(response.data);
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
// Get Customer Track Record

        async getCustomerTrack({ state, commit},obj) {

            const { customer_length, customer_page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {

                commit('setLoading', true)
                commit('setCustomerRows', [])
                commit('setFormStatusUpdate')
                const params = { length: customer_length, page: customer_page, term: searchTerm, filters: filters, view : router.app._route.name }

                const configParams = {
                    req : "CustomerSegmentModule/req",
                    loadingTrue : "CustomerSegmentModule/loadingTrue",
                    addRequest : "CustomerSegmentModule/addRequest",
                    cancelRequest : "CustomerSegmentModule/cancelRequest",
                    clearOldRequest : "CustomerSegmentModule/clearOldRequest"
                }

                ApiHttpService.get('customer-segments/customer/track/'+obj, params, configParams).then((response) => {
                    commit('setCustomerTrack',response.data.data)
                    commit('setCustomerPage',response.data.current_page)
                    commit('setCustomerTotal',response.data.total)
                    commit('setLoading', false)
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setLoading', false)
                    reject(error)
                })

            })
        },

        async getCustomerQuotation({ state, commit }, obj) {
            const { customer_quotation_length, customer_quotation_page, filters, searchTermQuot } = state;

            return await new Promise((resolve, reject) => {
                commit('setLoadingQuotation', false);
                commit('setCustomerQuotationRows', []);
                commit('setFormStatusUpdate');

                const params = {
                    length: customer_quotation_length,
                    page: customer_quotation_page,
                    term: searchTermQuot,
                    filters: filters,
                    view: router.app.$route.name
                };

                const configParams = {
                    req: "CustomerSegmentModule/req",
                    loadingTrue: "CustomerSegmentModule/loadingTrueQuotation",
                    addRequest: "CustomerSegmentModule/addRequest",
                    cancelRequest: "CustomerSegmentModule/cancelRequest",
                    clearOldRequest: "CustomerSegmentModule/clearOldRequest"
                };

                ApiHttpService.get('customer-segments/customer/quotations/' + obj, params, configParams)
                    .then((response) => {
                        if (response.data && response.data.data) {
                            const quotationsArray = Object.values(response.data.data);
                            commit('setCustomerQuotation', quotationsArray);
                            commit('setCustomerQuotationPage', response.data.pagination.current_page);
                            commit('setCustomerQuotationTotal', response.data.pagination.total);
                        }
                        commit('setLoadingQuotation', false);
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        commit('setLoadingQuotation', false);
                        reject(error);
                    });
            });
        },


        async getCustomerInvoice({ state, commit},obj) {

            const { customer_invoice_length, customer_invoice_page, filters, searchTermInvo } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {

                commit('setLoadingInvoice', false)
                commit('setCustomerInvoiceRows', [])
                commit('setFormStatusUpdate')
                const params = { length: customer_invoice_length, page: customer_invoice_page, term: searchTermInvo, filters: filters, view : router.app._route.name }

                const configParams = {
                    req : "CustomerSegmentModule/req",
                    loadingTrue : "CustomerSegmentModule/loadingTrue",
                    addRequest : "CustomerSegmentModule/addRequest",
                    cancelRequest : "CustomerSegmentModule/cancelRequest",
                    clearOldRequest : "CustomerSegmentModule/clearOldRequest"
                }

                ApiHttpService.get('customer-segments/customer/invoices/'+obj, params, configParams).then((response) => {
                    const invoicesArray = Object.values(response.data.data);
                    commit('setCustomerInvoice', invoicesArray);
                    commit('setCustomerInvoicePage',response.data.pagination.current_page)
                    commit('setCustomerInvoiceTotal',response.data.pagination.total)
                    commit('setLoadingInvoice', false)
                    resolve(response.data.data)
                }).catch(error => {
                    commit('setLoadingInvoice', false)
                    reject(error)
                })

            })
        },
// Store Customer Tracking
        async storeCustomerTrack({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "CustomerSegmentModule/req",
                        loadingTrue : "CustomerSegmentModule/setPostLoadingTrue",
                        addRequest : "CustomerSegmentModule/addRequest",
                        cancelRequest : "CustomerSegmentModule/cancelRequest",
                        clearOldRequest : "CustomerSegmentModule/clearOldRequest"
                    }
                    ApiHttpService.post('customer-segments/customer-track',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },






        //           ====================================CUSTOM CODE END========================================

        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "CustomerSegmentModule/req",
                        loadingTrue : "CustomerSegmentModule/setPostLoadingTrue",
                        addRequest : "CustomerSegmentModule/addRequest",
                        cancelRequest : "CustomerSegmentModule/cancelRequest",
                        clearOldRequest : "CustomerSegmentModule/clearOldRequest"
                    }
                    ApiHttpService.post('customer-segment/store',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`customer-segment/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        customer_rows: state => state.data.customer_rows,
        customer_quotation_rows: state => state.data.customer_quotation_rows,
        customer_invoice_rows: state => state.data.customer_invoice_rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        customer_page: state => state.customer_page,
        customer_quotation_page: state => state.customer_quotation_page,
        customer_invoice_page: state => state.customer_invoice_page,
        total: state => state.total,
        customer_total: state => state.customer_total,
        customer_quotation_total: state => state.customer_quotation_total,
        customer_invoice_total: state => state.customer_invoice_total,
        length: state => state.length,
        customer_length: state => state.customer_length,
        customer_quotation_length: state => state.customer_quotation_length,
        customer_invoice_length: state => state.customer_invoice_length,
        searchTerm: state => state.searchTerm,
        searchTermQuot: state => state.searchTermQuot,
        searchTermInvo: state => state.searchTermInvo,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        customerData: state => state.customerDetails,
        customerTrack: state => state.customerTrack,
        customerQuotation: state => state.customerQuotation,
        customerInvoice: state => state.customerInvoice,
        loadingQuotation: state => state.loadingQuotation,
        loadingInvoice: state => state.loadingInvoice,
    }
}



