<template>
  <div class="mdb-month-view pr-2 pl-2 pt-0 pb-0">
<!--     <div class="d-flex justify-content-between mb-1">
      <div>
        <mdb-btn-group size="sm">
          <mdb-btn color="elegant" icon="chevron-left" darkWaves @click.native="changeMonth('prev')"></mdb-btn>
          <mdb-btn color="elegant" icon="chevron-right" darkWaves @click.native="changeMonth('next')"></mdb-btn>
          <mdb-btn class="px-3" color="elegant" darkWaves @click.native="changeMonth('today')">{{todayText}}</mdb-btn>
        </mdb-btn-group>
        
        <mdb-btn-group size="sm" class="ml-1">
          <mdb-btn class="px-3" color="elegant" darkWaves @click.native="openDeliveryNoteIntegration">PUSH TO SAP</mdb-btn>
        </mdb-btn-group>
      </div>
      <h2>{{monthNames[date.getMonth()]}} {{date.getFullYear()}}</h2>
      <mdb-btn-group size="sm">
       <mdb-btn color="elegant" darkWaves @click.native="changeView('month')">Month</mdb-btn> 
   <mdb-btn outline="info" darkWaves @click.native="changeView('week')">Week</mdb-btn> 
       <mdb-btn color="elegant" darkWaves @click.native="changeView('list')">List</mdb-btn> 
      </mdb-btn-group>
    </div> -->


          <mdb-navbar class="z-depth-0 p-0 pl-2 rounded-sm justify-content-between">
                <mdb-btn-group size="sm">
                  <mdb-btn color="elegant" icon="chevron-left"  size="sm" darkWaves @click.native="changeMonth('prev')"></mdb-btn>
                  <mdb-btn color="elegant" icon="chevron-right" size="sm"  darkWaves @click.native="changeMonth('next')"></mdb-btn>
                  <mdb-btn class="px-3" color="elegant"  size="sm" darkWaves @click.native="changeMonth('today')">{{todayText}}</mdb-btn>
                </mdb-btn-group>
                <div class="text-center">
                  {{monthNames[date.getMonth()]}} {{date.getFullYear()}}
                </div>

                <mdb-form-inline class="ml-auto" tag="div">
                      <!-- <el-input                          
                          class="table-searchterm-input"
                          placeholder="Search"
                          v-model="term"
                          clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                      </el-input>
                      
                      <mdb-btn-group size="sm" class="ml-1">
                        <mdb-btn color="elegant" size="sm"><i class="el-icon-search"></i> Search</mdb-btn>
                      </mdb-btn-group> -->
                      <mdb-btn-group size="sm" class="ml-1">
                        <mdb-btn class="px-3" color="elegant"  size="sm" darkWaves @click.native="openDeliveryNoteIntegration">PUSH TO SAP</mdb-btn>
                      </mdb-btn-group>
                    
                </mdb-form-inline>
            </mdb-navbar>


    <table>
      <thead>
        <tr>
          <th v-for="(weekDay, index) in dayNames" :key="index">{{weekDay}}</th> 
        </tr>
      </thead>
      <tbody>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(0,7)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>

       
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">


              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">   
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)">
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(7,14)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">
              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">
                 
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)" >
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(14,21)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">
              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">
                  
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)">
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(21,28)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">
              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">
                  
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)">
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(28,35)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">
              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">
                  
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)">
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
        <tr>
          <drop tag="td" v-for="(day, index) in monthData.slice(35,42)" @drop="handleDrop($event, day)" @mouseover.native="tdHover(day.date)" :key="index" :class="{'light-blue lighten-4': day.today === true, 'rgba-mdb-color-light': day.date >= newEventRangeStart && day.date <= newEventRangeEnd, 'rgba-mdb-color-slight': day.date.getDay() === 0 || day.date.getDay() === 6}" @click.native.stop="addEvent($event, day.date)">
            <div :class="[{'text-light': day.type === 'light'}, 'mdb-day-field']">{{day.day}}</div>
            <div v-for="(event, eventIndex) in newEventData" :key="eventIndex">
              <div @click.stop="editEvent(eventIndex)">
                <template v-if="event.start <= new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) && event.end >= day.date">
                 
                  <div :transfer-data="JSON.stringify(event)" :class="eventClass(event, day)">
                    <div class="d-flex justify-content-between">
                      <div>{{event.title}}</div>
                      <div><mdb-badge class="custom-badge" pill :color="event.colorDark">{{event.count}}</mdb-badge></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </drop>
        </tr>
      </tbody>            
    </table>

  </div>
</template>

<script>
import { mdbBtn, mdbBtnGroup, mdbTooltip,mdbBadge,
  mdbFormInline,
  mdbNavbar,
  mdbNavbarBrand,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem
} from 'mdbvue'
import { Drag, Drop } from 'vue-drag-drop';

const mdbMonthView =  {
  name: 'mdbMonthView',
  components: {
    mdbBtn,
    mdbBtnGroup,
    mdbTooltip,
    Drag,
    Drop,
    mdbBadge,
    mdbFormInline,
    mdbNavbar,
    mdbNavbarBrand,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem
  },
  props: {
    defaultDate: {
      type: Date
    },
    dayNames: {
      type: Array
    },
    monthNames: {
      type: Array
    },
    todayText: {
      type: String
    },
    events: {
      type: [Array, Boolean]
    }
  },
  data() {
    return {
      monthData: [],
      ctrl: false,
      newEventRangeStart: false,
      newEventRangeEnd: false,
      term : null
    };
  },
  created() {
    this.date = this.defaultDate
    this.buildMonthData()
  },
  methods: {
    getDaysInMonth(month, year) {
      let date = new Date(year, month, 1)
      let days = []
      while (date.getMonth() === month) {
        days.push(new Date(date).getDate());
        date.setDate(date.getDate() + 1)
      }
      return days;
    },
    buildMonthData() {
      let newData = []
      let firstDayOfMonth = new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay()
      let daysInMonth = this.getDaysInMonth(this.date.getMonth(), this.date.getYear())
      let prevMonth = this.date.getMonth() === 0 ? 11 : this.date.getMonth() - 1
      let prevYear = prevMonth === 12 ? this.date.getYear() - 1 : this.date.getYear()
      let daysInPrevMonth = this.getDaysInMonth(prevMonth, prevYear)
      let lastDayOfPrevMonth = daysInPrevMonth.pop()
      let i = 0, j = 0
      for (i; i < 42; i++) {
        if (i >= firstDayOfMonth) {
          if (daysInMonth[i - firstDayOfMonth] === undefined) {
            j++
            newData.push({
              day: j,
              date: new Date(this.date.getFullYear(), this.date.getMonth() + 1, j),
              type: 'light'
            })
          } else {
            let currentDate = new Date(this.date.getFullYear(), this.date.getMonth(), i - firstDayOfMonth + 1)
            let checkToday = currentDate.toDateString() === new Date().toDateString() ? true : false
            newData.push({
              day: daysInMonth[i - firstDayOfMonth],
              date: currentDate,
              today: checkToday
            })
          }
        } else {
          newData.push({
            day: lastDayOfPrevMonth - firstDayOfMonth + i + 1,
            date: new Date(this.date.getFullYear(), this.date.getMonth() - 1, lastDayOfPrevMonth - firstDayOfMonth + i + 1),
            type: 'light'
          })
        } 
      }

      this.$emit('calendar',{
        from : newData[0],
        to : newData[41],
      })
      
      this.monthData = newData;
    },
    tdHover(date) {
      if (this.ctrl) this.newEventRangeEnd = date
    },
    openDeliveryNoteIntegration() {
      this.$emit('openDeliveryNoteIntegration')
    },
    changeMonth(target) {
      if (target === 'prev') {
        this.date = new Date(this.date.getFullYear(), this.date.getMonth() - 1)
        this.buildMonthData()
        this.$emit('dateChange', this.date)
      }
      if (target === 'next') {
        this.date = new Date(this.date.getFullYear(), this.date.getMonth() + 1)
        this.buildMonthData()
        this.$emit('dateChange', this.date)
      }
      if (target === 'today') {
        this.date = new Date()
        this.buildMonthData()
        this.$emit('dateChange', this.date)
      }
    },
    eventClass(event, day) {
      return [
        'mdb-event text-white medium px-1',
        event.color ? 'bg-' + event.color : 'bg-info',
        event.start.toDateString() === day.date.toDateString() && event.start.toDateString() === event.end.toDateString() ? 'mdb-event-single-x' :
          event.start < day.date ? 
            new Date(event.end.getFullYear(), event.end.getMonth(), event.end.getDate(), event.end.getHours(), event.end.getMinutes(), event.end.getSeconds()) > new Date(day.date.getFullYear(), day.date.getMonth(), day.date.getDate(), 23, 59, 59) ? 'mdb-event-long-x' : 'mdb-event-end-x' :
          'mdb-event-start-x'
      ]
    },
    editEvent(eventIndex) {
        
      this.$emit('editEvent', this.newEventData[eventIndex])

      
    },
    addEvent(event, date) {

      const  dateFormat = date.getFullYear() + "-"+('0' + (date.getMonth()+1)).slice(-2)+"-"+('0' + date.getDate()).slice(-2);
      const selectedDate = this.newEventData.filter((item) => {
        return item.date === dateFormat
      },[])


      if (event.ctrlKey && this.ctrl) {
        this.ctrl = false
        this.$emit('addEvent', this.newEventRangeStart, new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),selectedDate)
        this.newEventRangeStart = false
        this.newEventRangeEnd = false
      } else if (event.ctrlKey && !this.ctrl) {
        this.ctrl = true
        this.newEventRangeStart = date
        this.newEventRangeEnd = date
      } else {
        this.ctrl = false
        this.newEventRangeStart = false
        this.newEventRangeEnd = false
        this.$emit('addEvent', date, new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),selectedDate)
      }

      console.log("add event")


     
     // const  dateFormat = ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth()+1)).slice(-2) + '/' + date.getFullYear();



      //var dateFormat = date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()


      

    /*   console.log(dateFormat)
      console.log(selectedDate)
      console.log(this.newEventData) */
    },
    changeView(view) {
      this.$emit('changeView', view)
    },
    handleDrop(event, day) {
      let length = event.end.getTime() - event.start.getTime()
      event.start = day.date
      event.end = new Date(day.date.getTime() + length)
      this.$emit('eventsUpdate', this.events)
    },

    pushEvent(){
        this.events.push({ "title": "New event", "start": "2023-03-26", "end": "2023-03-27", "color": "info" })
        his.buildMonthData()
    }
  },
  watch: {
    defaultDate(newVal) {
      this.date = newVal
      this.buildMonthData()
    }
  },

  computed : {
    newEventData : function(){
            const events = this.events
            const groupedEvents = {};

                events.forEach((event) => {
                const startDate = event.start.split("T")[0];
                if (!groupedEvents[startDate]) {
                    groupedEvents[startDate] = {};
                }
                if (!groupedEvents[startDate][event.type]) {
                    groupedEvents[startDate][event.type] = 0;
                  
                }
                  groupedEvents[startDate][event.type]++;
                
                });



                const arrayEvents = []
                const typeColor = []
                typeColor['delivered']  = 'success'
                typeColor['delivery-schedule']  = 'info'
                typeColor['pullout-schedule']  = 'secondary'
                typeColor['pullout']  = 'warning'
                typeColor['delivered-pushed-to-sap']  = 'default'
                typeColor['picklist']  = 'default'

                

                 const typeLabel = []
                typeLabel['delivered']  = 'Confirmed Delivery'
                typeLabel['delivery-schedule']  = 'Scheduled Delivery'
                typeLabel['pullout-schedule']  = 'Scheduled Pullout'
                typeLabel['pullout']  = 'Pullout'
                typeLabel['delivered-pushed-to-sap']  = 'Delivered'
                typeLabel['picklist']  = 'Picklist'


                for (const startDate in groupedEvents) {


                  const getData = events.filter(function(innerItem){
                      return innerItem.date === startDate
                  },[])
                   
                    for (const type in groupedEvents[startDate]) {
                       
                            const dt = new Date(startDate);


                            const data = getData.filter(function(item){
                              return item.type === type
                            },[])

                                arrayEvents.push({
                                    "title": `${typeLabel[type]}`,
                                    "count": `${groupedEvents[startDate][type]}`,
                                    start: dt,
                                    end: new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 23, 59, 59),
                                    "type": type,
                                    "data": data,
                                    "date": startDate,
                                    "dataLength": data.length,
                                    "color": typeColor[type],
                                    "colorDark": `${typeColor[type]}-color-dark`,
                                    "tooltip" : ""
                                })
                    }
                    }

                // console.log(groupedEvents);

             return [...arrayEvents]
        }
  }
}

export default mdbMonthView;
export { mdbMonthView as mdbMonthView };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdb-month-view {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;

}
.mdb-month-view table {
  table-layout: fixed;
  width: 100%;
      height: calc(100vh - 93px);
}
.mdb-month-view table th, .mdb-month-view table td {
  border: 1px solid transparent;border-radius: 12px;
}
.mdb-month-view table th {
  text-align: center;
  height: 30px;
  font-weight: bold;
}
.mdb-month-view table td.rgba-mdb-color-slight {
    background-color: rgba(69,82,110,0.1)!important;
}
.mdb-month-view table td {
  height: 12vh;
  padding-top: 25px;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  background-color: #fff;
}
.mdb-month-view table td:hover {
  background-color:#bfe0ef!important;
}
.mdb-day-field {
  position: absolute;
  right: 8px;
  top: 5px;
}
.mdb-event {
  cursor: pointer;
  /* font-weight: bold; */
  margin: 4px;
  border-radius: 12px;
    padding-right: 0px!important;
    padding-left: 12px!important;
}
.mdb-event:hover {
  opacity: 0.7;
}
.mdb-event-single {
  margin: 1px 2px;
}
.mdb-event-start {
  margin: 1px 0;
  margin-left: 2px;
  margin-right: -2px;
}
.mdb-event-long {
  margin: 1px 0;
  margin-left: -2px;
  margin-right: -2px;
  text-indent: -9999px; 
}
.mdb-event-end {
  margin: 1px 0;
  margin-left: -2px;
  margin-right: 2px;
  text-indent: -9999px; 
}
.tooltip {
  font-size: .75rem;
}

.custom-badge{
  font-size: 95%!important;box-shadow: initial!important;font-weight: initial!important;
}
</style>
