<template>

    <div class="contact-form-container">
                <mdbContainer class="mt-1">
<!--{{form.attr.contact_persons}}-->
                    <template>
                        <mdb-btn type="button" color="elegant" size="sm" @click="newContactPerson">New Contact Person</mdb-btn>
                        <el-table  :data="form.attr.contact_persons" stripe>
                            <el-table-column prop="mobile" label="Phone/Mobile" width="180"></el-table-column>
                            <el-table-column prop="name" label="Contact Name"></el-table-column>
                            <el-table-column label="" width="80" align="right">
                                <template slot-scope="scope">
                                    <mdb-icon class="cursor-pointer" far icon="edit" color="warning" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'update')"/>
                                    <mdb-icon class="cursor-pointer" far icon="trash-alt" color="danger" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'delete')"/>
                                </template>
                            </el-table-column>
                            <template slot="empty">
                                No Data
                            </template>
                        </el-table>
                    </template>
                </mdbContainer>
        <ContactPersonEdit ref="contactPersionWindow" @change="onChangeContactPerson" :group="999"></ContactPersonEdit>
    </div>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset,
     ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage} from '../../components/thats/Thats'

import InternationalMobile from '../../components/thats/InternationalMobile.vue'
import ContactPerson from './ContactPerson.vue'
import { MessageBox } from 'element-ui'
import ContactPersonEdit from "./ContactPersonEdit.vue";
import {mdbBadge, mdbBtn, mdbCol, mdbContainer, mdbIcon, mdbRow, mdbTabs} from "mdbvue";
export default {
    components: {
        ContactPersonEdit,
        mdbContainer,
        ValidationObserver,
        ValidationProvider,

        ThatsInput,
        ThatsTextarea,
        ThatsSeriesInput,
        ThatsCheckbox,
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        mdbBtn,
        mdbRow,
        mdbCol,
        InternationalMobile  ,
        mdbTabs,
        mdbIcon,
        mdbBadge,
        ContactPerson

    },

    props : {
        group : {
            type : Number,
            default : 1
        },
        formData : {
            type: Object,
            required: true
        },
        detailsActiveTab: {
            type: Number,
            required: true
        }
    },

    data : () => ({
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        formLoading : false,


        status : [
            { status : "Active", code : "active"},
            { status : "Inactive", code : "inactive"},
        ],

        salutation : [
            { label : "Mr.", code : "Mr."},
            { label : "Ms.", code : "Ms."}
        ],

        cities : [
            {label :"Doha",code : "Doha"},
            {label :"Al-Rayyan",code : "Al-Rayyan"},
            {label :"Al Khor",code : "Al Khor"},
            {label :"Al Wakrah",code : "Al Wakrah"},
            {label :"Umm-Salal",code : "Umm-Salal"},
            {label :"Al-Dayian",code : "Al-Dayian"},
            {label :"Ash-Shamal",code : "Ash-Shamal"}
        ],
        tabs : [
            { text: 'Contact Details', slot: 'form-slot' },
            { text: 'Contact Persons', slot: 'form-contact-person' },
        ],

        active : 0,
        corportKey : 0,

        contactPersonColumns : [

            {
                label: 'Contact Name',
                field: 'name',
                sort: 'asc',
                width: '200',
                clickable: true
            },
            {
                label: 'Address',
                field: 'address',
                sort: 'asc',
            },

            {
                label: 'Phone/Mobile',
                field: 'mobile',
                sort: 'asc',
            },
            {
                label: 'Email',
                field: 'email',
                sort: 'asc',
            },

        ]
    }),

    created : function() {

        // if(this.FormStatus == 'create'){
        //     this.form = this.formVar();
        // }

    },
    mounted() {
        // this.formData = this.form

    },
    methods: {

        ...mapActions('ContactsModule',[
            'StoreRecord',
            'UpdateRecord',
            'GetNextCode',
            'getRecord',
        ]),
        ...mapMutations('ContactsModule',[
            'SetFormStatusCreate','SetFormStatusUpdate'
        ]),
        ...mapMutations('ContactPersonModule',{
            contactPersonCreate : 'SetFormStatusCreate',
            contactPersonUpdate : 'SetFormStatusUpdate'
        }),

        formVar(){
            return {
                series : 0,
                name : null,
                mobile : null,
                reference : '',
                description : null,
                image : [],
                attr : {

                    address: null,
                    address2 : {
                        street : null,
                        building : null,
                        zone : null,
                        area : null,
                        city : null,
                    },
                    email : null,
                    origin : 'portal',
                    first_name : null,
                    middle_name : null,
                    last_name : null,
                    salutation : null,
                    corporate : false,
                    contacts : [
                        {
                            type : 'primary',
                            intl : false,
                            code : '+974',
                            value : null
                        },
                        {
                            type : 'secondary',
                            intl : false,
                            code : '+974',
                            value : null
                        },
                        {
                            type : 'home',
                            intl : false,
                            code : '+974',
                            value : null
                        }
                    ],
                    contact_persons : [],
                    nationality : null,
                    qid : null,
                    dob : null,
                },
                status : 'active'
            }
        },
        /* start */




        // initializeFormData() {
        //     if (this.FormStatus === 'update' && this.formData) {
        //         const formRow = this.formData;
        //         if (!formRow.attr.hasOwnProperty('corporate')) {
        //             formRow.attr.corporate = false;
        //         }
        //         if (!formRow.attr.hasOwnProperty('contact_persons')) {
        //             formRow.attr.contact_persons = [];
        //         }
        //
        //         formRow.attr = {
        //             ...formRow.attr,
        //             address2: {
        //                 street: null,
        //                 building: null,
        //                 zone: null,
        //                 area: null,
        //                 city: null,
        //                 ...formRow.attr.address2
        //             },
        //         };
        //
        //         this.form = JSON.parse(JSON.stringify(formRow));
        //     }
        // },


        uploadImage(){
            this.$refs.upload.openDialog();
        },

        nextCode(){
            var self = this;
            this.GetNextCode().then((response) => {
                self.form.series = response.code
            });
        },

        demo(v){
            console.log(v)
        },

        /* end */
        openDialog(){
            this.$refs.window.openDialog()
        },
        closeDialog(){
            this.$refs.window.closeDialog()
        },
        fnImageSelect(v){
            console.log(v)
            this.form.image = v
        },
        onSubmitForm(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();


            console.log(isValid)
            if (isValid) {
                // this.form.description = this.getText();
                if(self.formLoading === false){
                    self.formLoading = true
                    if(self.FormStatus == 'create'){
                        self.StoreRecord(self.form).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response.data)
                            self.closeDialog();
                            self.FormResetter();

                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }

                    if(self.FormStatus == 'update'){
                        self.UpdateRecord(self.form).then((response) => {
                            self.$ThatsNotify.success({
                                message : response.message
                            })
                            self.closeDialog();
                            const successConctaForm = JSON.parse(JSON.stringify(self.form));
                            self.$emit('update',successConctaForm)
                            self.FormResetter();
                            this.$emit('success',successConctaForm)
                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }
                }

            }
        },

        getText() {
            let text = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
        },

        dialogState(state){
            if(!state){
                this.FormResetter();
                this.$refs.contactPersionWindow.closeDialog();
                return
            }

            if(this.FormStatus == 'create'){
                this.nextCode();
            }

            this.optionsHandler();

        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },
        getActiveTabIndex(index){
            this.active = index
        },
        initializeFormData() {
            this.form = { ...this.formData };
        },
        setTabArray(value){
            if(value){

                this.tabs = [
                    { text: 'Contact Details', slot: 'form-slot' },
                    { text: 'Contact Persons', slot: 'form-contact-person' },
                ]
            }else{

                this.tabs = [
                    { text: 'Contact Details', slot: 'form-slot' }
                ]
            }
        },

        newContactPerson(){
            this.contactPersonCreate();
            this.$refs.contactPersionWindow.openDialog();
        },
        fnOpenContactPersonWindow(index,row,status){
            var self = this;
            console.log(row)
            if(status === 'delete'){
                MessageBox.confirm('Are you sure you want to delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    self.form.attr.contact_persons.splice(index, 1);
                    self.$ThatsNotify.success({
                        message : "Item has been successfully removed"
                    })
                })
            }else{
                this.contactPersonUpdate({
                    ...row,
                    index : index
                });

                this.$refs.contactPersionWindow.openDialog();
            }
        },
        onChangeContactPerson(data) {
            console.log(data, 'swaraj data contact person');

            if (data.hasOwnProperty('index')) {
                // Update an existing contact person
                this.form.attr.contact_persons.splice(data.index, 1, data);
            } else {
                // Add a new contact person
                this.form.attr.contact_persons.push(data);
            }

            this.saveContactPerson();
        },


        async saveContactPerson() {
            try {
                if (this.FormStatus === 'create') {
                    // await this.StoreRecord(this.form);
                    this.StoreRecord(this.form)
                    // await this.getUpdatedData();
                    this.$notify({ type: 'success', message: 'Record successfully created' });
                } else if (this.FormStatus === 'update') {
                    await this.UpdateRecord(this.form)

                    this.$notify({ type: 'success', message: 'Record successfully updated' });
                }
            } catch (error) {
                this.$notify({ type: 'error', message: 'Error saving record' });
            }
        },
        // async getUpdatedData() {
        //     try {
        //         // Fetch updated data for the form
        //         // Example implementation assuming you have a method or service to fetch the data
        //         const updatedData = await ;
        //         // Update form data with fetched updated data
        //         this.form = updatedData;
        //     } catch (error) {
        //         console.error('Error fetching updated data:', error);
        //         // Handle error fetching updated data
        //         throw error; // Propagate error if needed
        //     }
        // }

    },

    computed: {

        ...mapGetters('ContactsModule',
            [
                'FormStatus',
                'FormRecord',
                'Loading'
            ]),
    },

    watch: {
        formData: {
            handler(newVal) {
                this.initializeFormData();
            },
            deep: true,
            immediate: true
        },
        detailsActiveTab(newVal) {
            if (newVal === 5) {
                this.active = 0;
                this.initializeFormData();

            }
        },
        FormRecord() {
            if (this.FormStatus === 'update') {
                const formRow = this.FormRecord.row;
                if (!formRow.attr.hasOwnProperty('corporate')) {
                    formRow.attr.corporate = false;
                }
                if (!formRow.attr.hasOwnProperty('contact_persons')) {
                    formRow.attr.contact_persons = [];
                }

                formRow.attr = {
                    ...formRow.attr,
                    address2: {
                        street: null,
                        building: null,
                        zone: null,
                        area: null,
                        city: null,
                        ...formRow.attr.address2
                    },
                };

                this.form = JSON.parse(JSON.stringify(formRow));
            }
        },
        "form.attr.contacts": {
            handler(v) {
                const firstObject = v[0];
                if (firstObject) {
                    this.form.mobile = firstObject.value;
                }
            },
            deep: true
        },
        "form.attr.salutation"(v) {
            let name = '';
            name += (v != "" && v !== null) ? v : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " " + this.form.attr.first_name : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " " + this.form.attr.middle_name : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " " + this.form.attr.last_name : '';

            this.form.name = name.trim();
        },
        "form.attr.first_name"(v) {
            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation !== null) ? this.form.attr.salutation : '';
            name += (v != "" && v !== null) ? " " + v : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " " + this.form.attr.middle_name : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " " + this.form.attr.last_name : '';

            this.form.name = name.trim();
        },
        "form.attr.middle_name"(v) {
            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation !== null) ? this.form.attr.salutation : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " " + this.form.attr.first_name : '';
            name += (v != "" && " " + v !== null) ? " " + v : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " " + this.form.attr.last_name : '';

            this.form.name = name.trim();
        },
        "form.attr.last_name"(v) {
            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation !== null) ? this.form.attr.salutation : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " " + this.form.attr.first_name : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " " + this.form.attr.middle_name : '';
            name += (v != "" && " " + v !== null) ? " " + v : '';

            this.form.name = name.trim();
        },
        "form.attr.corporate"(value) {
            this.corportKey = this.corportKey + 1;
            this.form.attr.last_name = null;
            this.form.attr.salutation = null;
        }


        /*       "form.name" : function(val) {

                if(val !== null){
                  const res = val.substring(0, 4)
                  this.form.short = res.toUpperCase();
                }
              } */

        /* area : {
          immediate : true,
          handler(){
            this.areaHandle();
          }

        } */
    },


}
</script>

<style scoped lang="scss">
.sales-report-card.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}


.tab-content{
    height:auto!important;
}
</style>
