<template>

    <div class="admin-container">
        <div class="body-container d-flex flex-row justify-content-start  ">
        <Navbar @openNofication="openNotification"></Navbar>
            <div class="left-sidebar-container flex-shrink-0">
                <SideNav
                        :data="groupsContent"
                        v-model="show8"
                        :over="false"
                        groups
                        color="elegant"
                        :sidenavClass="['custom-leftsidebar']"
                        @onItemClick="onItemClick"
                    >

                    <template slot="content-header">
                      <h6 class="role-title">System Admin Dashboard</h6>
                    </template>

                </SideNav>

            </div>
            <div class="right-body-container flex-grow-1" v-bind:class="activeRoute">
                <router-view :key="$route.path"/>
            </div>
        </div>

        <InventoryForm ref="openInventoryForm"></InventoryForm>
        <ItemCodeUpdate ref="openItemCodeUpdate"></ItemCodeUpdate>

         <CoolLightBox
            :items="gallery"
            :index="index"
            :useZoomBar="true"
            @close="index = null">
          </CoolLightBox>


    <mdb-modal :show="modal" @close="modal = false" danger>
      <mdb-modal-header>
        <mdb-modal-title>Scheduled Maintenance</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>Dear User,<br />
        <br />
        Due to scheduled maintenance activity, Portal will not be available on <strong>September 22,2022 11PM</strong> to <strong>September 23,2022 12PM</strong>.

        <br />
        <br />
        Regards, <br />
        <strong>IT TEAM</strong>


        <br />
        <br />

        --------------------------------------------------

        <br />
        <br />

        <div style="text-align:right">

          عزيزي المستخدم،
          <br /><br />
نظرًا لأنشطة الصيانة المجدولة ، لن تكون البوابة متاحة من الساعة 11:00 مساءاً من يوم 22 سبتمبر 2022  الى الساعة 12:00 ظهراً من يوم 23 سبتمبر 2022 .
<br /><br />
شكراً،<br />
فريق تكنولوجيا المعلومات
        </div>

      </mdb-modal-body>
      <mdb-modal-footer>
        <mdb-btn color="danger" @click.native="modal = false">Close</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>

    <div id="toast-container" class="md-toast-bottom-right" aria-live="polite" role="alert">
        <div class="md-toast p-0 file-upload-toast" v-for="(image,index) in uploadImages" :key="index" v-bind:class="{'done' : image.completed}" style="width: 150px!important;">
            <img :src="image.path" style="width:150px"/>
            <el-progress :percentage="image.progress" :show-text="false"></el-progress>
        </div>
    </div>

    </div>

</template>
<script>
import Navbar from '../components/Navbar'
import SideNav from '../components/nav/SideNav'
import ItemCodeUpdate from '../components/administration/ItemCodeUpdate'
import InventoryForm from '../views/inventory/InventoryForm'
import {
  mdbSideNav2,
  mdbContainer,
  mdbRow,
  mdbIcon,
  mdbAvatar,
  mdbBtn,
  mdbTooltip,
  mdbResize,
  mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,

} from "mdbvue";

import { userData } from '../helpers/user'

import {mapActions} from 'vuex'
import {UploadService} from '../services'
export default {
    name: 'Authenticated',
    components : {
        Navbar,
        mdbRow,
        mdbIcon,
        mdbAvatar,
        mdbBtn,
        mdbTooltip,
        mdbSideNav2,
        mdbContainer,
        mdbResize,
        mdbModal,
        mdbModalHeader,
        mdbModalTitle,
        mdbModalBody,
        mdbModalFooter,
        ItemCodeUpdate,
        SideNav,
        InventoryForm
    },
    provide() {
      return {
        userData : userData(),
        posShowroom : ['MPDFC','AMTM','AMDFC'],
        showroomsProvider: () => this.computedShowrooms,
        userDataProvider: () => this.computedUserData,
        brandProvider: () => this.brands,
        categoryProvider: () => this.category,
        areaProvider: () => this.area,
        collectionProvider: () => this.collections,
        filteredCategoryProvider: () => this.filteredCategory,
        materialProvider: () => this.materialMaster,
        colourMasterProvider: () => this.colourMaster,
        sizeMasterProvider: () => this.sizeMaster,
        colorProvider: () => this.color,
      }
    },

    data(){
      return {
        uploadImages : [],
        gallery : [],
        index : null,
        show8 : true,
        modal : false,
        groupsContent: [
        {
          data: [
            {
              name: "Cockpit",
              slug: "dashboard",
              to: "/",
              icon: "tachometer-alt"
            },



            {
              name: "Inventory",
              slug: "item-services",
              icon: "box-open",
              children: [
             /*    {
                  name: "Product List",
                  to: "/items",
                  slug: "items",
                }, */
                {
                  name: "Item Master",
                  to: "/inventory-item-master",
                  slug: "inventory-item-master",
                },
                {
                  name: "Item Pomotions",
                  to: "/item-promotion",
                  slug: "item-promotion",
                },
              /*   {
                  name: "Fabric & Textile",
                  to: "/fabric-textile",
                  slug: "fabric-textile",
                },
                {
                  name: "Wallpapers",
                  to: "/wallpapers",
                  slug: "wallpapers",
                }, */
                    {
                  name: "Brands",
                  to: "/brand",
                  slug: "brand",
                },

                {
                  name: "Category",
                  to: "/category",
                  slug: "category",
                },
                {
                  name: "Collections",
                  to: "/collection",
                  slug: "collection",
                }
              ]
            },



             {
              name: "Sales",
              slug: "sales",
              icon: "file-alt",
              children: [

                 {
                  name: "Quotations",
                  to: "/quotations",
                  slug: "quotations",
                },
                {
                  name: "Invoices",
                  to: "/transaction-v1",
                  slug: "transaction-v1",
                },


                {
                  name: "Credit Memo",
                  to: "/sales-return",
                  slug: "sales-return",
                },

                {
                  name: "Customer Segments",
                  to: "/customer-segments",
                  slug: "customer-segments",
                },

              ]
            },



            {
              name: "Purchase Order",
              slug: "purchase-order",
              icon: "shopping-cart",
              children: [

                 {
                  name: "Purchase Order",
                  to: "/purchase-order",
                  slug: "purchase-order",
                },
                {
                  name: "Goods Receipt Note",
                  to: "/goods-receipt-note",
                  slug: "goods-receipt-note",
                },
                 {
                  name: "Code Generator",
                  to: "/code-generator",
                  slug: "code-generator",
                },
               /*   {
                  name: "PO for Approval",
                  to: "/purchase-order-for-approval",
                  slug: "purchase-order-for-approval",
                },
 */
              ]
            },

          /*   {
              name: "Inventory",
              slug: "inventory",
              icon: "file-alt",
              to: "/inventory",
              popup : true
            }, */
            {
              name: "Bring to Showroom",
              slug: "bring-to-showroom",
              icon: "file-alt",
              to: "/bring-to-showroom",

            },






             {
              name: "Warehouse",
              slug: "warehouse",
              icon: "warehouse",
              children: [

                {
                  name: "Inventory Transfer Request",
                  to: "/inventory-transfer-request",
                  slug: "inventory-transfer-request",
                },
                {
                  name: "Inventory Transfer",
                  to: "/inventory-transfer",
                  slug: "inventory-transfer",
                },
                {
                  name: "Delivery Schedule",
                  to: "/delivery-schedule-calendar",
                  slug: "delivery-schedule-calendar",
                },

                {
                  name: "Raw Materials",
                  to: "/item-master-raw-materials",
                  slug: "item-master-raw-materials",
                },

                {
                  name: "Local Suppliers",
                  to: "/local-supplier",
                  slug: "local-supplier",
                },
                {
                  name: "Local Purchase Del. Note",
                  to: "/local-purchase-delivery-note",
                  slug: "local-purchase-delivery-note",
                },




              ]
            },

            {
              name: "Loyalty Program",
              slug: "loyalty-program",
              icon: "file-alt",
              children: [

                 {
                  name: "Loyalty Cards",
                  to: "/loyalty-cards",
                  slug: "loyalty-cards",
                },
                 {
                  name: "Tiers",
                  to: "/tiers",
                  slug: "tiers",
                },
                  {
                  name: "Tier Benefits",
                  to: "/tier-benefits",
                  slug: "tier-benefits",
                },
                {
                  name: "Members",
                  to: "/loyalty-members",
                  slug: "loyalty-members",
                },
                  {
                      name: "Transactions",
                      to: "/transaction-reports",
                      slug: "transaction-reports",
                  },
                  {
                      name: "Reports",
                      to: "/loyalty-program-reports",
                      slug: "loyalty-program-reports",
                  },
                  {
                      name: "Coupons",
                      to: "/coupons",
                      slug: "coupons",
                  },
                  // {
                  //     name: "Issue Coupon",
                  //     to: "/issue-coupons",
                  //     slug: "issue-coupons",
                  // },
                  {
                      name: "App CMS",
                      to: "/app-cms",
                      slug: "app-cms",
                  },

              ]
            },

            {
              name : "Reports",
              icon : "chart-pie",
              slug : "report",
              children: [
                {
                  name: "Sales by Item",icon : "sales",to : "sales-reports",slug : "sales-reports"
                },
                {
                  name: "Sales by Location",icon : "sales",to : "sales-report-by-brand",slug : "sales-report-by-brand"
                },
                {
                  name: "Sales Report",icon : "sales",to : "sales-report-showroom-manager",slug : "sales-report-showroom-manager"
                },

              ]
            },

            {
              name: "Business Partners",
              slug: "business-partners",
              icon: "file-alt",
              children: [
                {
                      name: "Call Logs",
                      to: "/call-logs",
                      slug: "call-logs",
                  },
                  {
                      name: "Visitor Logs",
                      to: "/customer-logs",
                      slug: "customer-logs",
                  },
                {
                  name: "Customers",
                  to: "/contacts",
                  slug: "contacts",
                },
                {
                  name: "Vouchers",
                  to: "/vouchers",
                  slug: "vouchers",
                },
                {
                  name: "Chat",
                  to: "/chat-webhook",
                  slug: "chat-webhook",
                },

              ]
            },


            {
                  name: "Inventory Count",
                  slug: "inventory-count",
                  icon: "file-alt",
                  children: [

                      {
                          name: "Physical Count",
                          to: "/physical-inventory-count",
                          slug: "physical-inventory-count",
                      },
                      {
                          name: "Posted Inventory",
                          to: "/posted-inventory",
                          slug: "posted-inventory",
                      },
                      {
                          name: "Inventory Transfer",
                          to: "/inventory-transfer-v2",
                          slug: "inventory-transfer-v2",
                      },

                      {
                          name: "Receive Transfer",
                          to: "/receive-transfer",
                          slug: "receive-transfer",
                      },
                  ]
              },


            {
              name: "Back Office",
              slug: "back-office",
              icon: "desktop",
               children: [

                {
                  name: "Barcode",
                  to: "/barcode",
                  slug: "barcode",
                },
                {
                  name: "Barcode Fabric",
                  to: "/barcode-fabric",
                  slug: "barcode-fabric",
                },
                ]
            },

            {
              name: "Apps",
              slug: "tl-apps",
              icon: "desktop",
               children: [
                {
                  name: "Serial Number Generator",
                  to: "/generate-serial-number-v2",
                  slug: "generate-serial-number-v2",
                },
                {
                  name: "Print Sticker",
                  to: "/generate-serial-number-showroom",
                  slug: "generate-serial-number-showroom",
                },
                {
                  name: "Re-Print Sticker",
                  to: "/reprint-sticker",
                  slug: "reprint-sticker",
                },
                {
                  name: "Price QR Code",
                  to: "/print-qr-code",
                  slug: "print-qr-code",
                },

                ]
            },


            {
              name: "Company Setup",
              slug: "company-setup",
              icon: "building",
              children: [
                {
                  name: "Location Setup",
                  to: "/showrooms",
                  slug: "showrooms",
                },
                {
                  name: "SMS Template",
                  to: "/sms-template",
                  slug: "sms-template",
                },

               /*  {
                  name: "Foreign Currency",
                  to: "/foreigncurrency",
                  slug: "foreigncurrency",
                } */
              ]
            },

            {
              name: "User account",
              slug: "user-account",
              icon: "user-plus",
              children: [
                {
                  name: "Register User",
                  to: "/user",
                  slug: "user",
                },

                {
                  name: "Log In History",
                  to: "/user-log",
                  slug: "user-log",

                },
                  {
                      name: "Sales Person Target",
                      to: "/sales-target",
                      slug: "sales-target",
                  },
                  {
                      name: "Showroom Target",
                      to: "/showroom-target",
                      slug: "showroom-target",
                  }
              ]
            },

              // {
              //     name: "Authorization",
              //     slug: "authorization",
              //     icon: "lock",
              //     children: [
              //         {
              //             name: "User Authorization",
              //             to: "/user-authorization",
              //             slug: "user-authorization",
              //         },
              //     ]
              // }
          ],

          activeRoute : 'welcome'
        },

      ],

      showrooms : [],
      color : [],
      area : [],
      brands : [],
      category : [],
      collections : [],
      filteredCategory : [],
      showroomsAll : [],
      materialMaster : [],
      colourMaster : [],
      sizeMaster : [],

      }
    },
    created(){
      const self = this
      this.activeRoute = this.$route.name

      console.log("authenticated created")
      let user = JSON.parse(localStorage.getItem('user'));
      if(user.user.roles.length > 0){

        const permission = ["dashboard",'inventory','print-qr-code','sales-promo',"authorization","local-supplier","item-master-raw-materials","local-purchase-delivery-note","goods-receipt-note","sales-quotations","chat-webhook","vouchers",...user.user.roles[0].permissions];



        const disallowedFromSales = ['brand','category','collection','delivery','sales-return','item-cancellation','item-exchange','item-refund','contacts','costing-tool', "loyalty-cards", "tiers", "tier-benefits", "coupons","issue-coupons"];

        const ft = this.groupsContent[0].data.filter((element) => {
            return permission.includes(element.slug)
        });

      console.log("ft")
      console.log(permission)
      console.log(ft)

        const menus = []

        if(ft.length){

          ft.forEach(element => {
            const menu = {
                name : element.name,
                slug : element.slug,
                icon : element.icon,
                popup : element.popup,
                to : element.to
            }

            if(element.hasOwnProperty('children') && element.children.length > 0){
              const childrenElement = [];
              element.children.forEach(childElement => {
                  if(self.$helpers.isSalesExecutive()){
                      if(!disallowedFromSales.includes(childElement.slug)){
                        childrenElement.push(childElement)
                      }
                    }else{
                      childrenElement.push(childElement)
                    }
              });

              if(childrenElement.length > 0){
                menu.children = childrenElement
              }
            }
              menus.push(menu);
          });
        }

        console.log(menus);
        this.groupsContent[0].data = menus

         const userDataOjbect = userData();
          self.GetShowrooms().then((response) =>{
                 self.showrooms = response.filter((item) => {
                     return userDataOjbect.location_prefix.includes(item.prefix)
                 })
                 self.showroomsAll = response
        });





      }
    },

    mounted(){

      console.log("authenticated mounted")
      //this.$ThatsNotify.info({ title: "Scheduled Maintenance", message: 'Heads up! There will ', position: 'top center', timeOut: 90000 })
      /* const self = this;
      setTimeout(function(){
        self.modal = false
      }, 30000);

      this.$on('open-notification', function(){
        alert(1)
      }) */
      const self = this;
      const userDataOjbect = userData();

        this._keyListener = function(e) {
            if (e.key === "u" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault(); // present "Save Page" from getting triggered.
                // alert("upload")
                this.$refs.openItemCodeUpdate.openDialog()
            }
        };

        document.addEventListener('keydown', this._keyListener.bind(this));


        this.$eventBus.$on('uploadFiles',(data) => {
            /* console.log("item")
            console.log(item) */
            const totalUploaded = parseInt(this.uploadImages.length)

            let newIndex = totalUploaded;
            console.log("totalUploaded")
                          console.log(newIndex)

            data.selectedFiles.forEach((file, index) => {
                  let reader = new FileReader()
                      reader.onload = (e) => {
                        let dataURI = e.target.result
                        if (dataURI) {
                          console.log("totalUploadedIndex")
                          console.log(newIndex)

                          const imageIndex = newIndex

                          self.uploadImages.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false, index : imageIndex})

                          console.log("uploadImages")
                          console.log(this.uploadImages)

                          UploadService.uploadFromDevice({file : file, index : imageIndex}, event => {

                              console.log("Uploadevent")
                              event.imageIndex = imageIndex
                              console.log(event)
                              console.log(imageIndex)
                            self.uploadImages[event.imageIndex].progress = Math.round((100 * event.loaded) / event.total);


                          })
                          .then(response => {
                            self.$eventBus.$emit('uploadDone',{data : {...response.data}, row : data.triggerIndex})

                            self.uploadImages[response.data.index].completed = true
                            self.uploadImages[response.data.index].loading = false
                          })
                          .catch(() => {

                          });

                          newIndex = newIndex + 1
                        }
                      }
                      reader.readAsDataURL(file)
            })
        })

        self.GetBrandErp().then(response => {

          console.log("brand response")
          console.log(response)
          const brand = [];
          response.forEach(element => {
          brand.push({brand : element.label,code : element.value, parent : element.parent})
          });
          self.brands = brand
          });
          self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
            self.collections = response
            // self.setCollections(response)
        })

        self.getCategoryFilter().then((response) => {
            self.filteredCategory = response
        })
        self.getMaterialMaster().then((response) => {
            self.materialMaster = response
        })
        self.getColourFilter().then((response) => {
            self.colourMaster = response
        })
        self.getSizeFilter().then((response) => {
            self.sizeMaster = response
        })
          self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
            self.area = response
            // self.setArea(response)
        })
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
            self.category = response
            // self.setCategory(response)
        })

        self.$ThatsNotify.info({
                    message : "The system now triggers live integration with SAP. All new transactions created through the portal will be pushed to SAP in real time.",
                    title: "System Notification",
                    timeOut: 15000
                })

    },

    beforeUpdate(){
      console.log("authenticated beforeUpdate")
      console.log("authenticated beforeUpdate ::::" + this.activeRoute)
      const self = this;
      self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area?test=x'}).then((response) => {
            self.area = response
            // self.setArea(response)

            console.log("authenticated beforeUpdate response")
        })
    },
    updated(){
      console.log("authenticated updated")
    },
    methods : {
      ...mapActions('SizeMasterModule',{
        getSizeFilter : 'filter'
      }),
      ...mapActions('ColourMasterModule',{
        getColourFilter : 'filter'
      }),
      ...mapActions('CategoryMasterModule',{
        getCategoryFilter : 'filter'
      }),
      ...mapActions('MaterialMasterModule',{
        getMaterialMaster : 'filter'
      }),
      ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
      ...mapActions('ItemModule',[
        'GetBrandRelationship'
      ]),
      ...mapActions('BrandModule',[ 'GetBrandErp']),
      openNotification(){
        this.modal = true
      },

      onItemClick(v){
          if(v.slug == 'inventory'){
                this.$refs.openInventoryForm.openDialog()
          }
      }
    },
    computed : {
      computedShowrooms : function(){
          return this.showroomsAll;
      },
      computedUserData : function(){
          return userData;
      }
    },
    watch: {
     '$route' (to){
        this.activeRoute = to.name
      }
  },

  beforeDestroy() {
        document.removeEventListener('keydown', this._keyListener);
    }
}
</script>
<style scoped>
.admin-container{
    height: calc(100vh - 42px);margin-top: 42px;width: inherit;
}
.body-container{
    height: inherit;
}
.body-container .left-sidebar-container{
    width: 240px;
}


</style>
