<template>
<el-dialog title="FURNISHWEB" :visible.sync="syncDialog" :close-on-click-modal="false" width="100%" class="text-left digital-signature-dialog-form" :show-close="showClose" @close="fnClose">
    <div class="filter-dalog-body">
    <mdb-card>
            <mdb-card-body>
        <mdb-row>
        <mdb-col class="text-center">
        <div class="step-header">
        <h3>Step 1</h3>
            
            <p>Open the browser on your mobile phone and <br />go to <br /><strong><b>https://furnishweb.thatsliving.com</b></strong> <br />            <br />
            or Scan this QR code to open <br />the website directly. <br />
            </p> 
        </div>   
            
            <mdb-card class="border border-light">
            <mdb-card-body>
                <qrcode-vue :value="'https://furnishweb.thatsliving.com'" :size="200" level="H" />
            </mdb-card-body>
            </mdb-card>

        </mdb-col>
        <mdb-col class="text-center">
        <div class="step-header">
        <h3>Step 2</h3>
        
        <p>Enter your email and password on the login page <br />
        or tap <strong><b>"SCAN QR"</b></strong> button to scan QR code <br />to log in
            </p>
             </div>  
            <img :src="'../../../assets/fw.png'" height="30" alt="" class="w-100 h-auto">
        </mdb-col>
        <mdb-col class="text-center">
        <div class="step-header">
        <h3>Scan to login</h3>
        </div>  
         <mdb-card class="border border-light">
            <mdb-card-body>
                <slot></slot>
            </mdb-card-body>
            </mdb-card>
         </mdb-col>
    </mdb-row>
       </mdb-card-body>
            </mdb-card>
    </div>
</el-dialog>
</template>

<script>
import {mdbCard, mdbCardBody, mdbBtn, mdbRow, mdbCol} from 'mdbvue'
import { Message } from 'element-ui';
import QrcodeVue from 'qrcode.vue'
export default {
    components : {
        mdbCard, mdbCardBody,
        mdbBtn,
        mdbRow, mdbCol,
        QrcodeVue
    },
    data() {
        return {
            showClose : true,
            syncDialog : false
        }
    },


    mounted(){
    },
    methods: {
        
        openDialog(){
            this.syncDialog = true;
        },
        closeDialog(){
            this.syncDialog = false;
            this.$nextTick(() => {
                this.syncDialog = false;
            })
        },

        fnClose(){
            this.closeDialog()
            this.$emit('close');
        },
        

       
    },

    watch : {
    }



}
</script>
<style scoped lang="scss">
.step-header{
    min-height : 150px
}
</style>