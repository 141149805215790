<template>
   <div class="table-container chat-wrapper d-flex flex-column align-items-stretch">
        <div class="chat-container d-flex align-items-stretch">
            <div class="aside" v-loading="loadingMessage">
                <!-- Aside content goes here -->
                <div class="aside-header border-bottom border-light" >
                    <div class="d-flex">
                        <h6>CHAT</h6>            
                    </div>
                    <div class="d-flex">

                        <el-input
                            @keydown.enter.native="handleSearchTerm"
                            @clear="handleClearSearchTerm"
                            class="table-searchterm-input w-100 p-0"
                            placeholder="Search"
                            v-model="chatTerm"
                            clearable style="align-self:flex-start; vertical-align: middle;height:initial!important;padding:0!important">
                        </el-input>

                    </div>

                   <!--  <div class="d-flex pr-1 pl-1 pt-1 pb-0 mt-1 ah-bottom-btn">
                        <span class="badge badge-pill border text-grey-text mr-1 blue-grey lighten-5 p-1 pr-2 pl-2 z-depth-0">ALL</span>
                        <span class="badge badge-pill border text-grey-text mr-1 blue-grey lighten-5 p-1 pr-2 pl-2 z-depth-0">UNREAD</span>
                        <span class="badge badge-pill border text-grey-text mr-1 blue-grey lighten-5 p-1 pr-2 pl-2 z-depth-0">UNASSIGNED</span>
                    </div> -->
                </div>

                <div class="contacts-container">
                    <div class="list-group">
                    <a
                        href="#"
                        class="list-group-item list-group-item-action media"
                        v-for="(chat,index) in chats":key="index"
                        v-bind:class="{ active : isActiveChat(chat) }"
                        @click="onClickChat(chat)"
                        >

                        <mdb-icon fas  icon="user-circle" size="3x" class="avatar-sm float-left" />
                        <div class="d-flex justify-content-between mb-1 ">
                            <h6 class="author-name mb-0"><strong>{{ chat.display_as }}</strong></h6>
                            <small>{{ chat.sent_short }}</small>
                        </div>

                        <p class="text-truncate">
                            {{ chat.last_message_text }}
                        </p>
                    </a>
                </div>
                </div>

            </div>
            <div class="message-container border-left border-light">
                <template v-if="activeChat != null">
                    <div class="active-chat-room d-flex flex-column">
                        <div class="d-flex align-items-center chat-room-header border-bottom border-light justify-content-between">
                            <div class="d-flex align-items-center">
                                <mdb-icon fas  icon="user-circle" size="3x" class="avatar-sm float-left" />
                                <div class="d-flex justify-content-between mb-1 ">
                                    <h6 class="author-name mb-0" :title="activeChat.mobile">{{ activeChat.display_as }}</h6>
                                    
                                </div>
                            </div>
                            <div>

                                <el-dropdown @command="handleChatMoreAction" class="justify-content-end">
                                    <span class="el-dropdown-link">
                                        <i class="el-icon-more el-icon--right"></i>
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="chat-info">Chat Info</el-dropdown-item>
                                        <el-dropdown-item command="customer-details">Customer Details</el-dropdown-item>
                                    </el-dropdown-menu>
                                    </el-dropdown>
                            </div>
                        </div>

                        <div class="d-flex flex-column chat-room-body" ref="scrollContainer" v-bind:style="chatBodyHeightStyle">

                            <template v-if="activeChat != null && activeChat.hasOwnProperty('messages') && activeChat.messages.length > 0">
                                <div class="d-inline-flex chat-message mt-1 mb-1" v-for="(message, index) in activeChat.messages" v-bind:key="index" v-bind:class="{'justify-content-start' : (message.user_id == null),'justify-content-end' : (message.user_id != null)}">
                                    <div class="d-flex flex-column">
                                        <div class=" rounded p-3">
                                                <div class="mb-0 p-message chat-message-body" v-html="message.body"></div>
                                                <span class="text-small text-sm mb-0"  v-bind:class="{'text-right' : (message.user_id != null)}">{{ message.sent_at }}</span>
                                            </div>
                                            <template v-if=" (message.user_id != null)">
                                                <span class="text-muted text-small text-sm m-1"  v-bind:class="{'text-right' : (message.user_id != null)}">
                                                <strong>{{ message.user.name }}</strong>
                                                </span>
                                            </template>
                                    </div>
                                </div>
                            </template>
                        </div>
                    
                        <div class="d-flex align-items-center chat-room-footer b">
                            <div  class="box-footer chat-input-section mb-0 d-flex align-items-center w-100">
                                <textarea  class="form-control bg-light border-light rounded w-100" placeholder="Type message" rows="1" style="resize:none" v-model="chatMessage" @keydown.enter.prevent="chatMessageReply" ref="chatMessage"></textarea>
                                <div class="icon-textarea flex-shrink-1 d-flex align-items-center">
                                    <!-- <div class="svg-button">
                                        <button  class="btn btn-md btn-link text-decoration-none" type="button"><i  class="ri-attachment-line"></i></button>
                                        <input type="file" style="display: none;">
                                    </div> -->
                                    <div class="send-button">
                                        <button class="btn btn-md btn-primary btn-lg chat-send send-disabled m-0 ml-1" type="button" @click="chatMessageReply">
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="no-chat-selected d-flex justify-content-center align-items-center">
                            <div>No chat selected</div>
                        </div>
                </template>
                                
            </div>
        </div>


        <!-- <div class="p-0 pt-2 pb-1 header-panel-container">
            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">
                <mdb-navbar-brand class="table-header-navbar-brand">
                    <mdb-tabs class="table-header-tabs" :active="active" default :links="[ { text: 'CHAT WEBHOOK',slot:'chat-webhook' }]"  />
                </mdb-navbar-brand>
            </mdb-navbar>
        </div>
        
        <div class="scroll-container">
            <JsonViewer v-for="(jsonData,index) in chatWebhook.data" v-bind:key="index" :value="jsonData" :expand-depth=5 :expanded="true" :copyable="true"  boxed>
            <template #copy>Copy</template></JsonViewer>
        </div> -->

        <el-drawer
        title="CHAT INFO"
        :visible.sync="showDrawer"
        :direction="drawerDirection"
        :before-close="handleDrawerClose">
            <mdbContainer class="mt-0">
                <template v-if="activeChat != null">
                    <div class="d-flex align-items-center chat-room-header border-bottom border-light justify-content-between mb-2 pb-2">
                            <div class="d-flex align-items-center">
                                <mdb-icon fas  icon="user-circle" size="3x" class="avatar-sm float-left" />
                                <div class="d-flex flex-column justify-content-start">
                                    <h6 class="author-name mb-0" :title="activeChat.mobile">{{ activeChat.display_as }}</h6>
                                    <p class="m-0">{{ activeChat.mobile }}</p>
                                </div>
                            </div>
                        </div>

                    <mdbRow class="mt-2">
                        <mdbCol md="12">
                            <span size="sm">
                        <div class="form-group input-group-sm row thats-form">
                            <label class="col-form-label col-md-6">Showroom</label>
                        </div>
                    </span>
                    <mdb-badge class="chat-badge z-depth-0" pill color="success" :key="index" v-for="(location, index) in activeChat.locations_data"> {{ location.name }}</mdb-badge>
                        </mdbCol>
                    </mdbRow>
                    <mdbRow class="mt-2">
                        <mdbCol md="12">
                            <span size="sm" >
                                <div class="form-group input-group-sm row thats-form">
                                    <label class="col-form-label col-md-6">Sales Representation</label>
                                </div>
                            </span>

                            

                            <mdb-badge class="chat-badge z-depth-0" pill color="success" :key="index" v-for="(user,index) in activeChat.user_data"> {{ user.name }}</mdb-badge>
                        </mdbCol>
                    </mdbRow>
                </template>
            </mdbContainer>
        </el-drawer>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* import JsonViewer from 'vue-json-viewer' */

import {
    mdbTabs, 
    mdbNavbar,
    mdbNavbarBrand,
    mdbIcon,
    mdbDropdown,
      mdbDropdownItem,
      mdbDropdownMenu,
      mdbDropdownToggle,
      mdbRow,
      mdbCol,
      mdbContainer,
      mdbBadge
    
} from "mdbvue";
import { mapActions, mapMutations } from 'vuex';
import {ThatsFieldset} from '../../components/thats/Thats';
export default {
    components:{
        mdbTabs, 
        mdbNavbar,
        mdbNavbarBrand,
        mdbIcon,
        mdbDropdown,
      mdbDropdownItem,
      mdbDropdownMenu,
      mdbDropdownToggle,
      ThatsFieldset,
      mdbRow,
      mdbCol,
      mdbContainer,
      mdbBadge
       
    },
    inject : [],
    provide(){
        return {

        }
    },
    data(){
        return {
            active : 0,
            sendContent : null,
            loadingMessage : false,
            chatWebhook : {
                data : [],
                loading : false,
                error : false,
                errorMessage : ''
            },
            chatMessage : '',
            chats : [],
            chatCopy : [],
            activeChat : null,
            chatTerm : '',
            chatBodyHeightStyle : {
                height: 'calc(100vh - 190px)'
            },

            showDropdown : false,
            showDrawer : false,
            drawerDirection : 'rtl',
            
        }
    },
    created(){
    },

    mounted(){
        const self = this;

       

        window.Echo.channel('chat').listen('ChatMessageEvent', (e) => {
            console.log("Chat message");
            console.log(e);

            if(e.hasOwnProperty('record')){
                const record = e.record;

                const index = self.chatCopy.findIndex(item => item.id == record.thread_id);

                if (index !== -1) {
                    const [item] = self.chatCopy.splice(index, 1);
                    console.log(item);
                    console.log("ChatMessageEvent");
                    // item.messages.push(record);

                    const recordCopy = JSON.parse(JSON.stringify(record));
                    if(recordCopy.message_type == 'message'){
                        delete recordCopy.thread;
                        item.messages.push(recordCopy);
                    }

                    item.last_message_text = recordCopy.body;
                    item.sent_short = recordCopy.sent_at;
                    self.chatCopy.unshift(item);

                   self.chats = JSON.parse(JSON.stringify(self.chatCopy));

                   if(self.activeChat != null && self.activeChat.id == record.thread_id){
                    console.log("schroll to bottom");
                        self.activeChat = item;
                        self.scrollToBottom();
                   }

                }else{
                    const recordCopy = JSON.parse(JSON.stringify(record));
                    delete recordCopy.thread;

                    console.log("recordCopy")
                    console.log(recordCopy)

                     
                    self.chatCopy.unshift({
                        ...record.thread,
                        display_as : record.thread.mobile,
                        sent_short : record.thread.last_message_at,
                        last_message_text : record.body,
                        messages : [recordCopy]
                    });

                    self.chats = JSON.parse(JSON.stringify(self.chatCopy));
                
                }
            }
                // self.chatWebhook.data.unshift(e);

            
        });
        self.loadingMessage = true;
        this.getChatWebhook().then((response) => {
            console.log("response");
            console.log(response);

            if(response.length > 0){
                self.chats = response.map(function(data){
                    return {
                        ...data,
                        display_as : data.name,
                        sent_short : data.last_message_at,
                        last_message_text : data.last_message.body,
                    };
                })

                self.chatCopy = JSON.parse(JSON.stringify(self.chats));
            }
            self.loadingMessage = false;
        }).catch((error) => {
            self.loadingMessage = false;
            console.log(error);
        });

    },
    methods: {
        ...mapActions('ChatWebhookModule', {
            getChatWebhook : 'resetData',
            storeRecord : 'storeRecord',

        }),

        ...mapMutations('ChatWebhookModule', {
            setSearchTerm : 'setSearchTerm',
        }),
        isActiveChat(chat){
            let isActive = false
            if(this.activeChat !=null && this.activeChat.hasOwnProperty('id') && this.activeChat.id == chat.id){
                isActive = true;
            }
            return isActive;
        },

        handleSearchTerm() {
            const self = this;
            const existInChatCopy = self.searchFromChatTerm();
            if(existInChatCopy.length > 0){
                self.chats = existInChatCopy;
            }else{
                self.loadingMessage = true;
                self.setSearchTerm(self.chatTerm);
                this.getChatWebhook().then((response) => {
                    console.log("response");
                    console.log(response);

                    self.chats = response.map(function(data){
                            return {
                                ...data,
                                display_as : data.name,
                                sent_short : data.last_message_at,
                                last_message_text : data.last_message.body,
                            };
                        })

                    self.chatCopy = JSON.parse(JSON.stringify(self.chats));
                    self.loadingMessage = false;
                }).catch((error) => {
                    self.loadingMessage = false;
                    console.log(error);
                });
            } 
        },

        handleClearSearchTerm() {
            const self = this;
            self.loadingMessage = true;
            self.setSearchTerm('');
                this.getChatWebhook().then((response) => {
                    console.log("response");
                    console.log(response);

                    self.chats = response.map(function(data){
                            return {
                                ...data,
                                display_as : data.name,
                                sent_short : data.last_message_at,
                                last_message_text : data.last_message.body,
                            };
                        })

                    self.chatCopy = JSON.parse(JSON.stringify(self.chats));
                    self.loadingMessage = false;
                }).catch((error) => {
                    self.loadingMessage = false;
                    console.log(error);
                });
        },

        searchFromChatTerm(){
            const self = this;
            return self.chatCopy.filter(function(chat){
                return chat.display_as.toLowerCase().includes(self.chatTerm.toLowerCase());
            },[]);
        },
        chatMessageReply(){
            const self = this;
            // console.log(self.chatMessage);
            self.storeRecord({
                thread_id : self.activeChat.id,
                mobile : self.activeChat.mobile_number,
                body : self.chatMessage
            }).then((response) => {
                console.log("response");
                console.log(response);
                // self.activeChat.messages.push({
                //     ...response.data
                // });
                self.scrollToBottom();
                self.chatMessage = '';
                self.chatBodyHeightStyle.height = `calc(100vh - 190px)`;
            }).catch((error) => {
                console.log(error);
            });
        },

        onClickChat(chat){
            const self = this;
            self.activeChat = chat;
            self.scrollToBottom();
        },

        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.scrollContainer;
                container.scrollTop = container.scrollHeight;
            });
        },

        handleChatMoreAction(action){
            console.log("action");
            console.log(action);

            if(action == 'chat-info'){
                this.showDrawer = true;
            }
        },
        handleDrawerClose(){
            this.showDrawer = false;
        }
    },
    computed: {
        
    },

    watch : {
        chatMessage : function(value){
            const messageInput = this.$refs.chatMessage;
            const lineHeightInPixels = 16;
            
            messageInput.setAttribute(
                `style`,
                `height:${lineHeightInPixels}px;overflow-y:hidden;resize:none`
            );

            const height = messageInput.style.height;
            const scrollHeight = messageInput.scrollHeight;
            messageInput.style.height = height;
            let count = Math.floor(scrollHeight / lineHeightInPixels);

            console.log("count");
            console.log(count);

            if(value == ''){
                count = 2;
            }

            if(count <= 2){
                this.chatBodyHeightStyle.height = `calc(100vh - 190px)`;
            }else{
                this.chatBodyHeightStyle.height = `calc(100vh - ${151 + (count*lineHeightInPixels)}px)`;
            }


            this.$nextTick(() => {
                messageInput.setAttribute(
                    `style`,
                    `height:${count*lineHeightInPixels}px;overflow-y:hidden;resize:none`
                );                
            });
        }
      
    }
}


</script>

<style scoped lang="scss">

.el-dropdown{
    font-size: 24px!important;cursor: pointer;
}
.chat-wrapper {
    width: inherit;
    .chat-container{
        width: inherit;
        .aside{
            background-color: azure;
            width: 500px;
            flex-grow: 0;
            
            .aside-header{
                background-color: #fff;padding: 8px;
                width: 500px;
                h6{
                    text-transform: uppercase;
                    text-align: center;
                    margin: 10px 0;
                    font-weight: 600;
                }

                .ah-bottom-btn{
                    .badge{
                        background-color: #4285f4!important;
                        border-color: #4285f4!important;
                    }
                
                }
            }
            .contacts-container{
                overflow-y: auto;
                height: calc(100vh - 160px);
                padding:5px;
            }
        }
        .message-container{
            background-color: #fff;
            flex-grow: 1;
            width: calc(100vw - 740px);

            .chat-room-header, .chat-room-footer{
                padding: 15px;
                background-color: #fff;
            }

            .chat-room-body{
                overflow-y: auto;
                height: calc(100vh - 190px);
                padding: 15px;
                background-color: #fff;
                .chat-message{
                    >div{
                        max-width: 100%;
                    }
                    &.justify-content-start{
                        >div{
                            >div{
                                background-color: #f6f6f6;
                                color: #000;
                                >span{
                                    color: #000!important;
                                }
                            }
                        }
                    }

                    &.justify-content-end{
                        >div{
                            >div{
                                background-color: #4285f4;
                                color: #fff;
                                >span{
                                    color: #fff!important;
                                }
                            }
                            
                        }
                    }
                    .p-message{
                        word-wrap: break-word; 
                        overflow-wrap: break-word;
                        white-space: normal; 
                    }
                    span{
                        font-size: 12px;
                    }

                    .p-message{
                        font-size: 14px;
                    }
                }
            }

            .no-chat-selected{
                height: calc(100vh - 145px);
                display: flex;
                justify-content: center;
                align-items: center;
                
            }
            
        }
    }
}





.list-group{
    .list-group-item{
        background: transparent;border: 1px solid transparent;border-radius: 5px;
        &.active, &.active:hover{
            z-index: 2;
            color: #fff;
            background-color: #4285f4;
            .avatar-sm{
                color: #fff;
            
            }
        }
        &:hover {
            z-index: 1;
            color: #495057;
            text-decoration: none;
            background-color: #deeeff;
            
        }
        .avatar-sm {
            width: 56px;
            border-radius: 50%;
            color: #b9b9b9;
        }
        
        p{
            margin-bottom: 0px;font-size: 14px;
        }
        .author-name{
            strong{
                font-weight: 600;
            }
        }
    }

   


}


</style>

