/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" @submit.prevent="onSubmitForm()">
      <XWindow ref="window" :title="`Item Inventory`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="'create'" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>
          
        </template>
        <template #right-button>

          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="computed_current_page"
              :page-sizes="[25,50,100,250,500,1000,2000]"
              :page-size="length"
              layout="sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </template>
        <!--  -->

        <div class="mr-1 ml-1 p-0 pt-2 pb-1 header-panel-container">

            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">

              <div class="filter-container d-flex">
                <div class="el-form-item m-0">
                <label class="el-form-item__label mb-0" style="width: 100px;">BRAND</label>
                <div class="el-form-item__content" style="margin-left: 100px;">
                  <el-select
                                v-model="form.brands"
                                filterable
                                placeholder="Select"
                                clearable>
                                <el-option
                                  v-for="item in brandOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                </div>
              </div>

               <div class="el-form-item m-0">
                <div class="el-form-item__content">
                  <el-input
                  placeholder="Search (Ctrl+f)"
                  v-model="form.searchTerm"
                  size="mini"
                  clearable
                  ref="searchField"
                  v-on:keyup.enter="handleSearchTerm"
                  >
                </el-input>
                </div>
              </div>

             <!--  <div class="el-form-item m-0">
                <label class="el-form-item__label mb-0" style="width: 100px;">CATEGORY</label>
                <div class="el-form-item__content" style="margin-left: 100px;">
                  <el-select
                                v-model="form.category"
                                filterable
                                multiple
                                collapse-tags
                                placeholder="Select">
                                <el-option
                                  v-for="item in categoryOptions"
                                  :key="item.value"
                                  :label="item.label"
                                  :value="item.value">
                                </el-option>
                              </el-select>
                </div>
              </div> -->

              <template v-if="formLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" style="width: .8rem;height:.8rem"></span>
                  Loading...
                </mdb-btn>
              </template>
              <template v-else>
                <mdb-btn color="elegant" size="sm" @click="handleSearchTerm"><i class="el-icon-search text-white cursor-pointer"></i>  Search</mdb-btn>
              </template>
              </div>

               <!--  <div style="width : 300px" class="mr-2">
                  <el-input
                  placeholder="Search (Ctrl+f)"
                  v-model="searchFilter"
                  size="mini"
                  clearable
                  @change="onChangeSearch"
                  ref="searchField"
                  >
                </el-input>
                </div> -->



            </mdb-navbar>
        </div>




        <div ref="spreadsheet" class="bts-window-form mt-1" v-bind:style="{'height': spreadsheetOptions.tableHeight}"></div>

      </XWindow>

  </ValidationObserver>

    <ItemForm ref="itemForm"></ItemForm>

  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbInput, mdbContainer, mdbFormInline, mdbNavbar,mdbNavbarBrand} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker, UploadFromDevice} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';
import ItemForm from './itemForm.vue'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        UploadFromDevice,
        mdbFormInline,
        mdbNavbar,mdbNavbarBrand,
        ItemForm
  },

  data(){

    const self = this;

    return {
        tblKey : 0,
        editEventModal : true,
        windowState : false,
        disabled : false,
        formLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        searchFilter : null,

        active: 0,
        eventKeyCounter: 0,

        data : [],
        form : {
          searchTerm : null,
          name : null,
          supplier_code : null,
          brands : null,
          area : null,
          collections : null,
          category : null,
          colour : null,

        },

        itemWindow : {

          width: 1400,
        },
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        spreadsheetApplyLoading: false,
        spreadsheet: null,

        index : null,
        collectionOptions: [],
        categoryOptions: [],
        brandOptions: [],

        spreadsheetOptions : {
          search:false,
          filters: false,
          copyCompatibility: false,
            download : false,
            csvFileName : 'Inventory',
            allowDeleteRow : false,
            allowInsertRow : false,
            allowInsertColumn : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : true,
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'calc(100vh - 145px)',
            tableOverflow : true,
            data:[],
             minDimensions:[6,25],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],
             columnSorting:true,

            updateTable:function(instance, cell, col, row, val, label, cellName) {
                if((row % 2) == 1){
                    cell.parentNode.style.backgroundColor = '#FAFAFA';
                }
                if (col == 0) {

                  if(val != null && val != ''){
                    //cell.innerHTML = '<img src="' + val + '" style="width:80px;height:80px">';
                    cell.innerHTML = `<a href="javascript:void(0)" class="view-item" onClick="window.itemView(${col},${row},'${val}')">${val}</a>`
                  }
                }

                if (col == 1) {

                  if(val != null && val != ''){
                    cell.innerHTML = '<img src="' + val + '" style="width:80px;height:80px">';
                  }else{
                    cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:80px;height:80px">';
                  }
                }

            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                if (obj.options.allowExport) {
                  items.push({
                      title: "Copy",
                      shortcut: 'Ctrl + C',
                      onclick: function () {
                          obj.copy();
                      }
                  });
                  items.push({
                      title: "Download",
                      shortcut: 'Ctrl + S',
                      onclick: function () {
                          obj.download();
                      }
                  });
              }

                return items;
             },

             onselection: function(instance, x1, y1, x2, y2, origin){
               /*  console.log("selected")
                console.log(x1,y1,x2,y2) */
             },

             onblur: function(instance){
             },
             onchange: function(domElement, domElmentCell, column, row, value,oldValue){

                if(column == 0){


                }



             },
             onafterchanges : function(){

             },
             onpaste : function(){

             },
             onbeforechange : function(){

             }
       },


    }
  },


  created() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;

        if(window.innerWidth >= 1600){
          this.itemWindow.width = 1600
        }else{
          this.itemWindow.width = window.innerWidth
        }

       this.resetForm();
  },

  unmounted() {
    window.removeEventListener('resize', this.getDimensions);
  },

  mounted(){
    const self = this
    window.addEventListener('resize', this.getDimensions);


      this.getItemBrand().then((res) => {
        self.brandOptions = res.map(item => {
            return { value: item.code, label: item.name };
          },[]);
      });

      /*this.getItemCategory().then((res) => {
        self.categoryOptions = res.map(item => {
            return { value: item.code, label: item.name };
          },[]);
      });

      this.getItemCollection().then((res) => {
        self.collectionOptions = res.map(item => {
            return { value: item.code, label: item.name };
          },[]);
      });*/

      this.$eventBus.$on('itemView',function({column,row, value}){
            // console.log(column,row,value)

            const cellValue = self.spreadsheet.getValueFromCoords(0,row);

            console.log("cellValue")
            console.log(cellValue)
            const itemCode = (cellValue !== null) ? cellValue : value
            self.editEventModal = true
            self.getItem(`item/get-by-item-code?item=${itemCode}`)
            self.$refs.itemForm.openDialog();
      })

      this._keyListener = function(e) {
                if(this.windowState){
                  if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
                      e.preventDefault();
                      this.$refs.searchField.focus();
                  }
                }
            };
          document.addEventListener('keydown', this._keyListener.bind(this));
  },


  methods: {
    ...mapMutations('ItemModule',['setViewLoadingFalse','setViewLoadingTrue']),
    ...mapActions('ItemModule',['getItem']),
    ...mapActions('ItemBrandModule',['getItemBrand']),
    ...mapActions('ItemCategoryModule',['getItemCategory']),
    ...mapActions('ItemCollectionModule',['getItemCollection']),
    ...mapActions('ItemInventoryReportModule',['getItemInventory']),
    ...mapMutations('ItemInventoryReportModule',['setPage','setLength']),

    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      if(document.documentElement.clientWidth >= 1600){
          this.itemWindow.width = 1600
        }else{
          this.itemWindow.width = document.documentElement.clientWidth
        }

    },

    handleSearchTerm(){
        this.setPage(1);
        this.handleFetch();
    },
    handleFetch() {
        const self = this;
/*         if(this.form.brands == null || this.form.category == null){
          return false;
        } */
        this.formLoading = true
          this.getItemInventory({filters : this.form}).then((res) => {
              this.formLoading = false
              console.log("res")
              console.log(res)
              this.spreadsheet.setData(res.data)

          }).catch((error) => {
            console.log(error)
            this.formLoading = false
          })
    },


    onResize({width,height}){

        if(!Number.isNaN(width) && !Number.isNaN(height)){


          this.spreadsheetOptions.style = this.spreadsheet.getStyle();
          const tableData = this.spreadsheet.getJson();

          this.spreadsheet.destroy();
          this.spreadsheetOptions.data = tableData

            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, this.spreadsheetOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)


        }



      },
    resetForm(){
      // this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },




    createPopupTable(spreadsheetOptions){

        this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, spreadsheetOptions);
        Object.assign(this, this.spreadsheet);
        this.spreadsheet.setWidth(window.innerWidth)



    },
    renderTable(){
            const self = this;
            this.spreadsheetOptions.columns = this.tableColumns;
            this.createPopupTable(this.spreadsheetOptions);
    },


      dialogState(newState){

        this.windowState = newState
        if(!newState){
          if(this.spreadsheet != null){
            this.spreadsheetOptions.style = {};
            this.spreadsheet.destroy();
          }

            this.resetForm();
          return
        }else{
          this.resetForm();
          this.renderTable();
        }

      },



    setColumnProperty(){
      if(this.form.status != 'create'){
        const currentUser = this.userData.user.id
          const checkCurrentUser =  this.form.approver.map((row) => row.id,[]);

          if(checkCurrentUser.includes(currentUser) && this.form.status == 'for-approval'){
            this.spreadsheetOptions.columns = this.tableColumns.map((row) => {

              const enableColumn = ['quantity','description'];
              const readOnly = enableColumn.includes(row.name) ? false : true
              return {
                ...row,
                readOnly : readOnly
              }
            },[])
          }else{
            this.spreadsheetOptions.columns = this.tableColumns.map((row) => {
              return {
                ...row,
                readOnly : true
              }
            },[])
          }
      }

    },



      fnPrintDoc(url){

          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

      },

      onChangeSearch(v){
        this.spreadsheet.search(v);
      },

      handleSizeChange(val) {
          this.setLength(val)
          this.setPage(1)
          this.handleFetch()
        },
        handleCurrentChange(val) {

          console.log("handleCurrentChange")
          console.log(val)
          this.setPage(val)
          this.handleFetch()
        },



  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
      ...mapGetters('ItemInventoryReportModule',['total','length','current_page']),

      tableColumns : function(){


        return [
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'series',
                    title : 'Item Code',
                    readOnly : true
                  },
                  {
                    type : 'text',
                    width : '90px',
                    align : 'left',
                    name : 'default',
                    title : 'Photo',
                    readOnly : true
                  },

                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Item/Description',
                    readOnly : true
                  },


                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'dimension',
                    title : 'Dimension',
                    readOnly : true
                  },
                  {
                    type : 'numeric',
                    width : '130px',
                    align : 'right',
                    name : 'sale_price',
                    title : 'Price',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                    readOnly : true
                  },

                  {
                    type : 'numeric',
                    width : '150px',
                    align : 'center',
                    name : 'stock',
                    title : 'In Stock',
                    mask:'#,##.00', decimal:'.' ,
                    readOnly : true
                  },
                  {
                    type : 'numeric',
                    width : '150px',
                    align : 'center',
                    name : 'soldQty',
                    title : 'Sold',
                    mask:'#,##.00', decimal:'.' ,
                    readOnly : true
                  },
               
                  {
                    type : 'hidden',
                    name : 'id',
                    readOnly : true
                  },


            ]
      },

      computed_current_page : {
            get : function(){
               return this.current_page
            },
            set : function(val){
                this.setPage(val)
            }
      },



  },

  watch: {




      documentApprover : function(value){


          this.form.approver = JSON.parse(JSON.stringify(value))
      },

      showroomLocation : function(value){

          const allowedLocation = this.userData.location_prefix
        this.filteredShowroom = value.filter((i) =>{
            return allowedLocation.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })
      }





  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>
