<template>
    <div class="table-container pr-0">
        <div class="d-flex justify-content-between align-items-center pt-1 pl-3 pr-3">

            
            <h5 class="module-title mb-0" v-text="$attrs.title"></h5>
            <mdb-form-inline class="ml-auto">
                <template v-if="Selection.length">
                    <mdb-btn color="elegant" size="sm" class="mr-auto" @click="ClearSelected">Clear</mdb-btn>
                    <mdb-btn color="elegant" icon="trash" size="sm" class="mr-auto" @click="EmitDeleteModal">Delete</mdb-btn>
                </template>
                <template v-else>
                    <input class="form-control mr-sm-2 table-searchterm-input" v-model="term" type="text" placeholder="Search" aria-label="Search"/>
                    <mdb-btn color="elegant" size="sm" class="mr-auto" @click="handleSearchTerm">Search</mdb-btn>
                    <slot name="filter"></slot>
                    <mdb-btn color="elegant" icon="refresh" size="sm" class="mr-auto" @click="refreshTable">Refresh</mdb-btn>
                    <mdb-btn color="elegant" icon="plus" size="sm" class="mr-auto" @click="EmitOpenModal">New</mdb-btn>
                </template>
                    
            </mdb-form-inline>
        </div>
    
    <div class="table-container pr-0">
        <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="'calc(100vh - 138px)'" stripe @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="35"> </el-table-column>
            <template v-for="col in columns">
                <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                    <template slot-scope="scope">
                        <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                            <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                        </template>
                        <template v-else>
                            <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                          
                        </template>
                    </template>
                    
                </el-table-column>
            </template>
            <template slot="empty">
                <template v-if="Loading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading data...
                    </mdb-btn>
                  
                </template>
                <template v-else>
                    No Data
                </template>
            </template>
        </el-table>
        <el-pagination        
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        </div>
    </div>
</template>
<script>
import {
  mdbBtn,
  mdbFormInline
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'
export default {
    data(){
        return {
            
        }
    },
    props : {
        endpoint : {
            type: String,
            required : true
        },
        columns : {
            type: Array,
            required : true
        },

        
    },
    components: {
        mdbBtn,
        mdbFormInline,
    },

    created: function () {
        this.SetEmptyData()
         this.dispatchHandler()
   
    },

    mounted() {
        // app.$on('')
    },
    methods: {
        ...mapActions('TableModule',[
            'asyncGetData',
            'resetData',
            
            
        ]),
        ...mapMutations('TableModule',[
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
        ]),

        EmitOpenModal(){
            this.$emit('openModal')
        },
        EmitDeleteModal(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes',self.Selection)
                    }).catch(() => {
                        self.$emit('deleteNo')  
                    });

        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
            this.SetLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        ClearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        handleClick(index,row){
            this.$emit('openUpdateModal',{index : index,row : row})
        },

        refreshTable(){
            this.resetData(this.endpoint);
        }
    },
    
    computed: {
        ...mapGetters('TableModule',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',
            'Loading',
            'Selection',
        ]),
        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.SetTerm (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.SetPage (val)
            }
        }
      
    },

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>