<template>
<div class="classic-form-page">
   <mdb-view class="d-flex justify-content-center align-items-center" style="background-color: #f9f6fc;">
       <mdb-container>
          <mdb-row>
            <div class="col-xl-5 col-lg-6 col-md-6 col-sm-12 mx-auto">
              <mdb-card>
                      <mdb-card-header class="elegant-color white-text">
                        <img :src="'../../assets/tl-design-district-logo-white.png'" class="brand-logo"/>
                      </mdb-card-header>
                      <mdb-card-body>
                        <slot></slot>
                      </mdb-card-body>
                    </mdb-card>
                 </div>
          </mdb-row>
        </mdb-container>  
    </mdb-view>
</div>
</template>
<script>
import {
  mdbView,
  mdbMask,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbIcon,
  mdbNavbarBrand,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbContainer,
  mdbRow,

  mdbCardHeader,
  mdbCardTitle,
  mdbCardText,
  mdbCardFooter
} from "mdbvue";
export default {
    components: {
    mdbView,
    mdbMask,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbIcon,
    mdbNavbarBrand,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbContainer,
    mdbRow,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardText,
    mdbCardFooter
  },
    data : () => ({
        
    })
}
</script>


<style scoped>
.classic-form-page {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
}

.classic-form-page .view {
  height: 100vh;
}

.classic-form-page h6 {
  line-height: 1.7;
}

.classic-form-page .navbar {
  transition: background 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.classic-form-page .navbar .md-form {
  margin: 0;
}

.top-nav-collapse {
  background: #424f95 !important;
}

.brand-logo{
  width: 50%;margin: 0 auto;
}

@media (max-width: 768px) {
  .classic-form-page .navbar:not(.top-nav-collapse) {
    background: #424f95 !important;
  }
}

</style>
