<template>
    <ValidationObserver
        class="needs-validation form-custom-format"
        v-bind:class="{'was-validated': validated}"
        tag="form"
        ref="observer"
        v-slot="{passes}"
        @submit.prevent="onSubmitForm"
    >
        <el-dialog
            :modal="true"
            ref="window"
            :fullscreen="true"
            :title="'Create Product'"
            :visible.sync="syncModal"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="text-left bulk-create-dialog"
            :formStatus="formStatus"
            @dialog-state="dialogState"
            :group="group"
            :showClose="!processing"
        >
            <div ref="spreadsheet"></div>
            <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <template v-if="formStatus == 'create'">
                            <template v-if="processing == true">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Processing...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">
                                    <i class="el-icon-check text-white cursor-pointer"></i> Save
                                </mdb-btn>
                            </template>
                            <mdb-btn color="grey" size="sm" @click="passes(clearForm)">
                                <i class="el-icon-copy-document text-white cursor-pointer"></i> Clear
                            </mdb-btn>
                            <mdb-btn color="grey" size="sm" @click="passes(onSubmitCopyTemplate)">
                                <i class="el-icon-copy-document text-white cursor-pointer"></i> Copy Template
                            </mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="closeDialog">
                            <i class="el-icon-close text-white cursor-pointer"></i> Close
                        </mdb-btn>
                    </template>
                </div>
            </template>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import {  mdbRow,mdbCol } from 'mdbvue';
import jspreadsheet from '../../jsexcel/jexcel'
import { drop } from "lodash";

import { MessageBox, Message } from "element-ui";

import  {ThatsDialog} from '../../components/thats/Thats'

const dropdownBrandFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.parent == value
    },[]);
    return filterResult
}


const dropdownCategoryFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

const dropdownCollectionFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

export default {
    components: { ValidationProvider,
        mdbBtn,
        ValidationObserver,
        mdbRow,
        mdbCol,
        ThatsDialog
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    inject:[],
    data : () => ({
        syncModal : false,
        validated: false,
        formStatus : "select",
        form : null,
        formData:null,
        formDataOrginal : null,
        formLoading : false,
        processing : false,
        showError : false,
        group2 : 3,
        spreadsheet: null,
        exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'calc(100vh - 85px)',
            tableOverflow : true,
            data:[[]],
            minDimensions:[3,50],
            onCopy : null,
            selectionCopy : false,
            allowCopy : true,
            columns: [

                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Code',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Supply Code',
                    name: 'supply_code',
                    readOnly: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Old Code',
                    name: 'old_code',
                    readOnly: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Name',
                    name: 'item_name',
                    readOnly: false
                },
                // {
                //     type: 'text',
                //     width: '100px',
                //     title: 'Opening Qty',
                //     name: 'qty',
                //     readOnly: false,
                // },
                // {
                //     type: 'text',
                //     width: '100px',
                //     title: 'Stock',
                //     name: 'stock_qty',
                //     readOnly: false,
                // },
                //
                // {
                //     type: 'text',
                //     width: '100px',
                //     title: 'Sold',
                //     name: 'sold_qty',
                //
                // },
                // {
                //     type: 'text',
                //     width: '100px',
                //     title: 'In Transit',
                //     name: 'in_transit_qty',
                //
                // },
                // {
                //     type: 'text',
                //     width: '100px',
                //     title: 'Under Prd.',
                //     name: 'under_production_qty',
                //
                // },
                {
                    type: 'text',
                    width: '340px',
                    title: 'Response',
                    name: 'message',
                },


            ],

            contextMenu: function(obj, x, y, e) {

                return false;
            }
        },

        isIndeterminate: false,
        checkAll: true,
        checkedOptions: [
            // 'opening_qty',
            // 'stock_qty',
            // 'sold_qty',
            // 'in_transit_qty',
            // 'under_production_qty',
            'group',
            'brand',
            'area',
            'category',
            'collection',
        ],
        checkboxOptions : [
            // { label: 'Opening Qty', value: 'opening_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'Stock', value: 'stock_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'Sold', value: 'sold_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'In Transit', value: 'in_transit_qty', width : '100px', dropdown : false, type : 'text' },
            // { label: 'Under Production', value: 'under_production_qty', width : '100px', dropdown : false, type : 'text' },
            { label: 'Group', value: 'group', width : '100px', dropdown: true, type : 'dropdown', source : 'groupComputed'},
            { label: 'Brand', value: 'brand', width : '100px', dropdown: true, type : 'dropdown', source : 'brandComputed'},
            { label: 'Area', value: 'area', width : '100px', dropdown: true, type : 'dropdown', source : 'areaComputed'},
            { label: 'Category', value: 'category', width : '100px', dropdown: true, type : 'dropdown', source : 'categoryComputed'},
            { label: 'Collection', value: 'collection', width : '100px', dropdown: true, type : 'dropdown', source : 'collectionComputed'},
            { label: 'Item Status', value: 'status', width : '100px', dropdown: true, type : 'dropdown', source : [
                    {name : "Archive", id : "Archive"},
                    {name : "Active", id : "Active"},
                    {name : "Discontinued", id : "Discontinued"},
                ]},
            { label: 'Item Type', value: 'item_type', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Stock", id : "Stock"},
                    {name : "Service", id : "Service"},
                ]},
            { label: 'Web Live Status', value: 'website', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Yes", id : "Yes"},
                    {name : "No", id : "No"},
                ]},
            { label: 'Price', value: 'sale_price', width : '150px', dropdown: false, type : 'numeric'},
        ],

        postedData : [],



    }),
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))
        this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))
    },
    mounted() {

    },
    methods: {
        ...mapActions('InventoryItemMasterModule',['storeRecord']),
        createPopupTable(exportTableOptions){
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setData([])
        },

        async recreateSpreadsheet(){
            await this.spreadsheet.destroy();
        },
        /* start */



        optionsHandler(){
            var self = this;
        },



        /* end */

        openDialog(){
            this.syncModal = true
            this.formResetter();
            this.formStatus = 'select'
            /* */
        },
        closeDialog(){
            this.syncModal = false
            this.formResetter();
        },

        onSubmitCopyTemplate(){
            const self = this;

            const selectedColumns = this.checkboxOptions.filter((item) => {
                return self.checkedOptions.includes(item.value)
            },[])

            var txt = "Series\t";
            if(selectedColumns.length > 0){
                selectedColumns.forEach(element => {
                    txt += element.label+"\t"
                });
                txt += "\n"
            }

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value=txt;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            Message.success({
                message: "Copied!"
            });
        },

        onSubmitSelectForm() {
            const self = this;

            this.formResetter();

            this.formStatus = 'create';
            const tableCols = this.checkboxOptions.filter((obj) => {
                return this.checkedOptions.includes(obj.value)
            },[]).map((obj) => {
                const dropdownObject = {};
                const numericObject = {};

                if (obj.hasOwnProperty('dropdown') && obj.dropdown == true) {
                    const fromComputed = ['brandComputed', 'categoryComputed', 'areaComputed', 'groupComputed','collectionComputed'];
                    dropdownObject.source = fromComputed.includes(obj.source) ? this[`${obj.source}`] : obj.source;
                    dropdownObject.autocomplete = true;
                    dropdownObject.multiple = false;
                    if (obj.source == 'brandComputed') {
                        dropdownObject.filter = dropdownBrandFilter;
                    }
                    if (obj.source == 'categoryComputed') {
                        dropdownObject.filter = dropdownCategoryFilter;
                    }

                }

                if (obj.type == 'numeric') {
                    numericObject.mask = '#,##.00';
                    numericObject.width = 100;
                    numericObject.decimal = '.';
                }
                return {
                    type: obj.type,
                    width: obj.width,
                    title: obj.label,
                    name: obj.value,
                    readOnly: false,
                    ...dropdownObject,
                    ...numericObject
                };
            });

            const finalColumns = [
                { type: 'text', width: '200px', title: 'Item Code', name: 'series', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Item Name', name: 'name', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Supply Code', name: 'supply_code', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Old Code', name: 'old_code', readOnly: false, primaryKey: false },
                ...tableCols,
                { type: 'numeric', width: '100px', title: 'Price', name: 'sale_price', readOnly: false, primaryKey: false },
                // { type: 'numeric', width: '100px', title: 'Qty', name: 'qty', readOnly: false, primaryKey: false },
                { type: 'dropdown', width: '150px', title: 'Dimension Type', name: 'dimension_type', readOnly: false, primaryKey: false,source : [
                        { name: 'Size', id: 'size' },
                        { name: 'WxHxD', id: 'whd' }
                    ] },
                { type: 'text', width: '200px', title: 'Dimension', name: 'dimension', readOnly: false, primaryKey: false },
                { type: 'text', width: '400px', title: 'Description', name: 'description', readOnly: false, primaryKey: false },
                { type: 'dropdown', width: '150px', title: 'Item Status', name: 'status', readOnly: false, primaryKey: false,source : [
                        {name : "Archive", id : "Archive"},
                        {name : "Active", id : "Active"},
                        {name : "Discontinued", id : "Discontinued"},
                    ] },
                { type: 'dropdown', width: '100px', title: 'Weblive Status', name: 'website', readOnly: false, primaryKey: false,source : [
                        {name : "Yes", id : "Yes"},
                        {name : "No", id : "No"},
                    ] },

                { type: 'dropdown', width: '100px', title: 'SaleItem', name: 'sale_item', readOnly: false, primaryKey: false,source : [
                        {name : "Yes", id : "Yes"},
                        {name : "No", id : "No"},
                    ] },
                { type: 'dropdown', width: '100px', title: 'PurchaseItem', name: 'purchase_item', readOnly: false, primaryKey: false,source : [
                        {name : "Yes", id : "Yes"},
                        {name : "No", id : "No"},
                    ] },
                { type: 'dropdown', width: '100px', title: 'InventoryItem', name: 'inventory_item', readOnly: false, primaryKey: false,source : [
                        {name : "Yes", id : "Yes"},
                        {name : "No", id : "No"},
                    ] },
                { type: 'dropdown', width: '100px', title: 'ProductType', name: 'prType', readOnly: false, primaryKey: false,source : [
                    {name : "Product", id : "Product"},
                    {name : "Set", id : "Set"},
                    {name : "Part", id : "Part"},
            ] },
                { type: 'text', width: '340px', title: 'Response', name: 'message', readOnly: false, primaryKey: false },

            ];

            this.$nextTick(() => {
                const width = this.$refs.window.width;
                this.exportTableOptions.tableWidth = "100%";
                this.exportTableOptions.columns = finalColumns;
                this.createPopupTable(this.exportTableOptions);
            });
        },

        onSubmitBack(){
            const self = this;
            MessageBox.confirm('Are you sure you want to go back?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {

                self.formStatus = 'select';
                self.form = self.formVar()
                requestAnimationFrame(() => {
                    self.$refs.observer.reset();
                });
                self.validated = false
                self.formLoading = false
                if(self.spreadsheet){
                    self.spreadsheet.destroy();
                }

            }).catch(() => {
                return
            });
        },

        onSubmitForm() {
            const self = this;

            if (this.processing) {
                return false;
            }

            const tableData = this.spreadsheet.getJson();

            MessageBox.confirm('Warning: Are you sure you want to submit the data. Do you want to continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                if (tableData.length > 0) {
                    this.postCounter = 0;
                    this.dataLength = tableData.filter(item => item.series !== '').length;

                    if (this.dataLength > 0) {
                        const validData = tableData.filter(item => item.series !== '').map(item => {
                            return { ...item, completed: false };
                        });

                        if (validData.length === 0) {
                            self.$ThatsNotify.warning({
                                message: `Please fill up the series column.`,
                                timeOut: 10000
                            });
                            return false;
                        }

                        console.log('dataLength', validData);
                        const columns = self.iterateAlphabetByNumber((self.exportTableOptions.columns.length));
                        self.postedData = JSON.parse(JSON.stringify(validData));

                        // Process each element sequentially using a promise chain
                        let chain = Promise.resolve();

                        validData.forEach((element, forIndex) => {
                            const row = 1 + forIndex;

                            // Mapping function
                            function mapElementToFormData(element) {

                                const itemAttr = self.formObject.attr;
                                return {
                                    ...self.formObject,
                                    series: element.series,
                                    name: element.name || null,
                                    sale_price: element.sale_price || 0,
                                    dimension_type: element.dimension_type || null,
                                    dimension: element.dimension || null,
                                    prType: element.prType.toLowerCase(),
                                    ItmGroup: element.group || null,
                                    brand: element.brand || null,
                                    area: element.area || null,
                                    category: element.category || 373,
                                    collection: element.collection || null,
                                    description: element.description || null,
                                    website: element.website === 'Yes' ? '1' : '0',
                                    sell: element.sale_item === 'Yes'  ? '1' : '0',
                                    inventory: element.inventory_item === 'Yes' ? '1' : '0',
                                    purchase: element.purchase_item === 'Yes' ? '1' : '0',
                                    allocated_quantity: 0,
                                    attr: {
                                        ...itemAttr,
                                        old_code: element.old_code || "",
                                    },
                                    status: element.status === 'Active' ? '1' : (element.status === 'Discontinued' ? '0' : '2'),
                                };
                            }

                            const mappedData = mapElementToFormData(element);

                            // Chain the promises
                            chain = chain.then(() => {
                                self.formData = { ...mappedData };

                                columns.forEach(col => {
                                    self.spreadsheet.setStyle(`${col}${row}`, 'background-color', '#fff');
                                });

                                self.spreadsheet.setValue(`${columns[columns.length - 1]}${row}`, "Please wait...");

                                return self.storeRecord({ ...self.formData, index: row }).then(response => {
                                    console.log(response);
                                    columns.forEach(col => {
                                        self.spreadsheet.setStyle(`${col}${row}`, 'background-color', '#00c851');
                                    });
                                    self.spreadsheet.setValue(`${columns[columns.length - 1]}${row}`, response.message);
                                    self.postCounter++;
                                    self.$set(self.postedData[row - 1], 'completed', true);
                                }).catch(error => {
                                    console.log(error.response);
                                    const errors = error.response.data.errors || {};
                                    let errorMessage = "";
                                    errorMessage = Object.keys(errors).map(field => `${field}: ${errors[field][0]}`).join(", ");
                                    console.log(errorMessage, "Swaraj errors foreach");
                                    columns.forEach(col => {
                                        self.spreadsheet.setStyle(`${col}${row}`, 'background-color', '#fb3');
                                    });
                                    self.spreadsheet.setValue(`${columns[columns.length - 1]}${row}`, errorMessage);
                                    self.postCounter++;
                                    self.$set(self.postedData[row - 1], 'completed', true);
                                    self.postCounter++;
                                    self.$set(self.postedData[row - 1], 'completed', true);
                                });
                            });
                        });

                        // After all promises in the chain are resolved
                        chain.finally(() => {
                            console.log('All records processed.');
                            this.processing = false;
                        });
                    }
                }
            }).catch((error) => {
                // Handle the case where the user cancels the confirmation dialog
                console.log('Confirmation dialog was cancelled.',error);
            });
        },


        formResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.processing = false
            this.setFormStatusCreate();

            if(this.spreadsheet){
                this.spreadsheet.destroy();
            }
        },

        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.formResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.formResetter();
            }

        },

        formVar(){
            return {
                items : [],
            }
        },
        setFormStatusCreate(){
            this.formStatus = 'create'
        },

        handleCheckAllChange(val) {
            this.checkedOptions = val ? this.checkboxOptions.map(i => i.value,[]) : [];
            this.isIndeterminate = false;
        },
        handleCheckedOptionsChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkboxOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxOptions.length;
        },

        iterateAlphabetByNumber(numColumns) {
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const columns = [];

            for (let i = 0; i < numColumns; i++) {
                let label = '';
                let index = i;
                while (index >= 0) {
                    label = alphabet[index % 26] + label;
                    index = Math.floor(index / 26) - 1;
                }

                columns.push(label);
            }
            return columns;
        },
        clearForm(){
            this.recreateSpreadsheet();
            this.createPopupTable(this.exportTableOptions);
        },
    },

    computed: {
        ...mapGetters('InventoryItemMasterModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),
        filterProductType : function() {

            return [
                {label : "Product", value : "product"},
                {label : "Set", value : "set"},
                {label : "Part", value : "part"},
            ];
        },
        checkedOptionsComputed : function(){
            return (this.checkedOptions.length > 0) ? true : false;
        },

        brandComputed : function(){
            const brandProvider =  this.brandProvider();

            return brandProvider.map((item) => {
                const name = `${item.code} - ${item.brand}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },
        groupComputed : function(){
            const brandProviderData = this.brandProvider();
            return brandProviderData.filter((item) => item.parent == 0,[]).map((item) => {
                const name = `${item.code} - ${item.brand}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },

        categoryComputed : function(){
            return this.categoryProvider().map((item) => {
                const name = `${item.code} - ${item.category}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },
        areaComputed : function(){
            return this.areaProvider().map((item) => {
                const name = `${item.code} - ${item.area}`
                return {...item,name : name.toUpperCase(),id : item.code}
            },[])
        },

        collectionComputed : function(){
            return this.collectionProvider();
        },

        enableButton : function(){
            return (this.areaComputed.length > 0 && this.brandComputed.length > 0 && this.categoryComputed.length > 0) ? false : true;
        },

        isAllCompleted : function(){
            return (this.postedData.length > 0) ? this.postedData.every((item) => item.completed == true) : false;
        }
    },

    watch: {

        brandComputed : function(v){
            this.filteredBrands = v
        },
        syncModal(val) {
            if (val && this.formStatus === 'select') {
                this.onSubmitSelectForm();
            }
        },
        isAllCompleted : function(value){
            if(value == true){
                this.processing = false;
                this.$emit('success');
            }
        }
    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
