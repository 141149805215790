export const PaymentMeansModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        formStatus: 'create',
        formData: null,
        initialFormData: {
            series : 0,
            date : "",
            mode : "full-payment",
            method : "credit-card",
            attr : {
                cheque_details : null,
                payment_reference : null,
                discount_remarks : null,
                realized : false,
                loyalty_card_number : null
            },
            description : null,
            amount : 0,
            reference : null,
        },

        merchants : [{code : '651',label : '651'},{code : '250',label : '250'},{code : '251',label : '251'},{code : '252',label : '252'},{code : '253',label : '253'},{code : '254',label : '254'},{code : '255',label : '255'},{code : '256',label : '256'},{code : '258',label : '258'},{code : '163',label : '163'}],
        cardTypes : [{code : 'naps',label : 'NAPS'},{code : 'visa',label : 'VISA'},{code : 'master',label : 'MASTER'},{code : 'cb',label : 'CB'}]
    },
    mutations: {
        setFormDataCreate(state,payload = {}) {
            state.formStatus = 'create'
            const formDataObject = {
                ...JSON.parse(JSON.stringify(state.initialFormData)),
                ...payload
            }
            console.log("formDataObject")
            console.log(formDataObject)
            state.formData = formDataObject
        },
        setFormDataUpdate(state, payload = {}) {
            state.formStatus = 'update'
            state.formData = {
                ...payload
            }
        },
        setTblKey(state) {
            state.tblKey++
        },
        setFormStatus(state, payload) {
            state.formStatus = payload
        },
    },
    getters: {
        formStatus: state => state.formStatus,
        formRecord: state => state.formData,
        initialFormData: state => state.initialFormData,
        tblKey: state => state.tblKey,
        merchants: state => state.merchants,
        cardTypes: state => state.cardTypes
    }
}


