import {ContactsService, ApiHttpService, ApiService} from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ContactsModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [


                {
                    label: '',
                    field: 'series',
                    sort: 'asc',
                    width: '100'
                },
                {
                    label: 'Contact Name',
                    field: 'name',
                    sort: 'asc',
                    width: '200',
                    clickable: true
                },
                {
                    label: 'Address',
                    field: 'address',
                    sort: 'asc',
                },

                {
                    label: 'Phone/Mobile',
                    field: 'mobile',
                    sort: 'asc',
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                },

                {
                    label: 'Status',
                    field: 'status_label',
                    sort: 'asc',
                },


            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        formRecordUpdate: {},

        Contacts: [],


    },
    actions: {


        async GetContacts({ dispatch,commit },params) {
            return await new Promise((resolve) => {
                ContactsService.get('/filter', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {

                            const constAttr = element.attr

                            if(!constAttr.hasOwnProperty('corporate')){
                                constAttr.corporate = false;
                            }

                            if(!constAttr.hasOwnProperty('contact_persons')){
                                constAttr.contact_persons = [];
                            }
                            const row = { ...element,label: element.name, value: element.mdbID, attr : constAttr }
                            res.push(row)
                        })
                    }
                    commit('setContacts',res)
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async findLoyaltyMembers({ dispatch,commit },params) {
            return await new Promise((resolve) => {
                ApiHttpService.get('/loyalty-members', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {

                            const constAttr = element.attr

                            if(!constAttr.hasOwnProperty('corporate')){
                                constAttr.corporate = false;
                            }

                            if(!constAttr.hasOwnProperty('contact_persons')){
                                constAttr.contact_persons = [];
                            }
                            const row = { ...element,label: element.name, value: element.mdbID, attr : constAttr }
                            res.push(row)
                        })
                    }
                    commit('setContacts',res)
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getRecord({ dispatch, commit, state },params) {
            const { loading,} = state
            return await new Promise((resolve) => {
                 if (loading === false) {
                    commit('LoadingTrue')
                    ContactsService.get(`/${params.mdbID}`, params).then((response) => {

                        const responseData = response.data.data;



                        if(!responseData.attr.hasOwnProperty('corporate')){
                            responseData.attr.corporate = false
                        }
                        if(!responseData.attr.hasOwnProperty('contact_persons')){
                            responseData.attr.contact_persons = []
                        }
                        resolve(responseData)
                        commit('LoadingFalse')
                    }).catch(error => {
                        commit('LoadingFalse')
                        Unauthorized(dispatch, error)
                    })
            }
            })
        },

        async GetNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ContactsService.get('/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = rootState.ContactsModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ContactsService.get('\/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },



        async StoreRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.ContactsModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ContactsService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        // async getCustomerItemRecord({ rootState, commit }, payload) {
        //     var self = this
        //     return await new Promise((resolve, reject) => {
        //         const loading = rootState.ContactsModule.loading;
        //         if (!loading) {
        //             commit('LoadingTrue')
        //             ApiHttpService.get('customer-items/{series}',payload).then((response) => {
        //                 commit('LoadingFalse')
        //                 resolve(response.data)
        //             }).catch((error) => {
        //                 commit('LoadingFalse')
        //                 reject(error)
        //             })
        //         }
        //     })
        // },
        async getCustomerItemRecord({ state, commit }, params) {
            const { viewLoading } = state
            return await new Promise((resolve, reject) => {
                if (!viewLoading) {
                    commit('LoadingTrue')
                    ApiService.get(`customer-items/${params.series}`, params).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch(error => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async getCustomerItemQuotationRecord({ state, commit }, params) {
            const { viewLoading } = state
            return await new Promise((resolve, reject) => {
                if (!viewLoading) {
                    commit('LoadingTrue')
                    ApiService.get(`get-quotation-item/${params.series}`, params).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch(error => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ContactsModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ContactsService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.ContactsModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ContactsService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ContactsModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ContactsService.saveImport(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setFormRecordUpdate: (state, payload) => {
            state.formRecord = payload
        },
        setContacts: (state, payload) => {
            state.Contacts = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        formRecordUpdate: state => state.formRecordUpdate,
        Contacts: state => state.Contacts,
    }
}


