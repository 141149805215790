<template>
    <div>
        <input class="display-none" type="file" ref="file" :accept="accept" @change="selectFile" :multiple="multiple" />
       <!--  <div class="md-toast" :class="['md-toast-success']" :style="{backgroundColor:'red'}" v-for="(image,index) in images" :key="index">
            <img :src="image.path"/>
        </div>
 -->
        <div id="toast-container" class="md-toast-bottom-right" aria-live="polite" role="alert">
        <div class="md-toast p-0" v-for="(image,index) in images" :key="index" :style="{width: '150px!important'}">
            <img :src="image.path" style="width:150px"/>
        </div>
        </div>
    </div>
</template>
<script>
import {UploadService} from '../../services'
export default {
    props : {
      multiple : {
        type : Boolean,
        default : true
      },
      accept : {
        type : String,
        default : 'image/*'
      },
    },
    data () {
      return {
        dialogIsOpen : false,

        selectedFiles: [],

        progress: 0,
        images : [],

        formLoading : false,

        triggerIndex : null

      }
    },
    methods : {
        triggerBrowseFile(value){
            // alert(triggerIndex)this

            this.triggerIndex = value
            const elem = this.$refs.file
            elem.click()
        },
        selectFile() {
            const self = this
            this.images = [];
            this.selectedFiles = [];
            this.selectedFiles = this.$refs.file.files;

            this.$eventBus.$emit('uploadFiles',{selectedFiles : this.selectedFiles, triggerIndex : this.triggerIndex});

            /* this.selectedFiles.forEach(async(file, index) => {
                let reader = await new FileReader()

                    reader.onload = (e) => {
                                let dataURI = e.target.result
                                    if (dataURI) {
                                        this.images.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false})
                                    }
                                }
                                await reader.readAsDataURL(file)
                        })


            this.selectedFiles.forEach((file, index) => {

             
                UploadService.uploadImage(file).then(response => {
            

                     this.$emit('uploadSuccess', response)
                    return;
                    })
                    .catch(() => {
                       

                    });
                    
            })     */
        },


        uploadBtn() {
          const self = this;
        
              this.selectedFiles.forEach((file, index) => {

                this.images[index].loading = true
                  UploadService.uploadImage(file, event => {
                    this.images[index].progress = Math.round((100 * event.loaded) / event.total);
                  })
                    .then(response => {
                      this.message = response.data.message;
                      this.images[index].completed = true
                      this.images[index].loading = false
                      self.$ThatsNotify.success({
                                message : `${this.images[index].name} was successfully uploaded`
                              })
                      this.$emit('uploadSuccess', this.images[index])
                      return;
                    })
                    .catch(() => {
                      this.images[index].progress = 0;
                      this.images[index].completed = false;
                      this.images[index].loading = false
                      this.images[index].error = true;
                       self.$ThatsNotify.error({
                                message : `${this.images[index].name} upload failed`
                        })

                    });
                    
              })            
            
          }

        
    }
}
</script>

<style lang="scss">
.display-none{
    display: none;
}
</style>