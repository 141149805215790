<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Online Application" windowClass="Foreigncurrency height-auto" scrollClass='Foreigncurrency height-auto' @deleteYes="emitDelete" :width="650" :group="group" :formStatus="FormStatus" @dialog-state="dialogState">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
              <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-4">
             <mdb-row >

              <mdb-col col="12">
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Currency Code" vid="name" name="name" v-model="form.full" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Mobile" vid="mobile" name="mobile" v-model="form.mobile" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Email" vid="email" name="email" v-model="form.email" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Position/Title" vid="title" name="title" v-model="form.title" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Date Applied" vid="date_applied" name="date_applied" v-model="form.date_human" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                  <ThatsVueSelect tabindex="5" :addButton="false" :appendButton="false"  labelClass="col-md-3" label="Status" fieldClass="col-md-9  pl-0" v-model="form.status" :reduce="label => label.value" :reduceLabel="'label'" :options="status" :rules="'required'" :clearable="false"></ThatsVueSelect>

              </mdb-col>
             </mdb-row>
         
          
          </mdbContainer>
        
       </ThatsWindow>



  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage, ThatsSelect, ThatsVueSelect} from '../../components/thats/Thats'

export default {
    name : "ForeignCurrencyForm",
    components: {
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        ThatsSelect,
        ThatsVueSelect,
        mdbBtn,
        mdbRow,
        mdbCol  
        
  },
    
props : {
      group : {
            type : Number,
            default : 1
        },
    },
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,



         status : [
        { label : 'Resume for review', value: 'for-review' },
        { label : 'Unqualified', value: 'unqualified' },
        { label : 'Shortlisted', value: 'shortlisted' },
        { label : 'Lineup for Interview', value: 'for-interview' },
        { label : 'Kept for Reference', value: 'for-reference' },
        { label : 'Deployed', value: 'deployed' },
      ],
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      } 
      
      this.optionsHandler()
    },
    methods: {

      ...mapActions('OnlineApplicationModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
      ]),
      ...mapMutations('OnlineApplicationModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          name : null,
          last_name : null,
          email : null,
          attr : {
            mobile : ""
          },
          status : 'for-review'
        }
      },
/* start */
     

      optionsHandler(){


        

      },
      
      
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.image = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      
                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }


      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      }

    },

    computed: {

      ...mapGetters('OnlineApplicationModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>