<template>
  <div class="itmform-container">
  
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="PUSH TO SAP" windowClass="collection height-auto" scrollClass='collection height-auto' :height="800" :width="900" :formStatus="'create'" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                 <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="splitInvoice.length <= 0">PUSH TO SAP</mdb-btn>
                </template>
            </template>
            <div class="mt-1 pl-3 pr-3">
                <ThatsTextarea :showLabel="false" fieldClass="col-sm-12" label="Payments" vid="payments" name="payments" v-model="form.payments"></ThatsTextarea>




              <el-table border :data="splitInvoice" class="input-table mt-0" style="width: 100%">

                 
                      <el-table-column label="Series" :width="250" align = "right">
                        <template slot-scope="scope">
                            {{scope.row.series}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Status" :width="120" align = "right">
                      <template slot-scope="scope">
                        <template v-if="scope.row.loading">
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="scope.row.success != null">
                                <template v-if="scope.row.success === true">
                                  <span class="a-success">Done</span>
                                </template>
                                <template v-else>
                                  <a href="javascript::void(0)" class="a-retry" @click="fnRetry({series : scope.row.series, index : scope.row.index})">Retry</a>
                                </template>
                              
                            </template>
                              <template v-else>
                                -
                              </template>
                          </template>
                        </template>
                      </el-table-column>

                      <el-table-column label="Response" align = "right">
                        <template slot-scope="scope">
                        <template v-if="scope.row.message !== null">
                              <template v-if="scope.row.success === true">
                                  <span class="a-success">{{scope.row.message}}</span>
                                </template>
                                <template v-else>
                                  <span class="a-retry">{{scope.row.message}}</span>
                              </template>
                        </template>
                        </template>
                      </el-table-column>
                  </el-table>

            </div>

       </ThatsWindow>
    </ValidationObserver>
     
      
  </div>
</template>
<script>
import { mdbClickOutside , mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox, ThatsCustomWindow, HistoryWindow} from '../../components/thats/Thats'


export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  //#region[VueComponent]
    components: {
      
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect, 

        
     

  },
  //#endregion
    
    data : () => ({
          form : {
            payments : null
          },
          formLoading : false,
          validated : false,
          success : [],
    }),
    

  created : function() {
      var self = this;
    


    },

  mounted(){
  },

  updated(){
 
  },
  //#region[VueMethod]
    methods: {
    ...mapActions('InvoiceModule',['multiplePaymentIntegration']),
      openDialog(){
        this.$refs.window.openDialog();
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      dialogState(){
       this.form.payments = null;
        
      },
      onSubmitForm(){
          const self = this;

          const completed = [];

          const checkFailed = self.splitInvoice.filter(element => element.success === 'failed' || element.success === null).length
          //&& checkFailed > 0
          if(this.formLoading === false ){
            this.formLoading = true;
            
            if(this.form.payments !== null && this.form.payments !== ""){

              Promise.all(this.splitInvoice.map(async (element) => {

                  if(element.series != "" && (element.success === 'failed' || element.success === null)){
                     self.$set(this.splitInvoice[element.index],'loading', true)
                     self.$set(this.splitInvoice[element.index],'complete', false)
                   await this.multiplePaymentIntegration({series : element.series, index : element.index}).then((response) => {

                          if(response.hasOwnProperty('index')){

                                self.$set(self.splitInvoice[response.index],'loading', false)
                                self.$set(self.splitInvoice[response.index],'success', true)
                                self.$set(self.splitInvoice[response.index],'message', "Success")
                                self.$set(self.splitInvoice[response.index],'complete', true)

                                  if(self.splitInvoice.length === self.splitInvoice.filter((e) => e.complete === true).length){
                                    self.formLoading = false;
                                  }
                            }

                       }).catch((err) => {

                         if(err.response.hasOwnProperty('data')){
                             const resData = err.response.data;
                              if(err.response.status === 422){
                                  
                              }

                              const message = resData.error || resData.message

                              self.$set(self.splitInvoice[resData.index],'loading', false)
                              self.$set(self.splitInvoice[resData.index],'success', 'failed')
                              self.$set(self.splitInvoice[resData.index],'message', message)
                              self.$set(self.splitInvoice[resData.index],'complete', true)

                              console.log('completed')
                              console.log(self.splitInvoice.filter((e) => e.complete === true).length)
                                if(self.splitInvoice.length === self.splitInvoice.filter((e) => e.complete === true).length){
                                      self.formLoading = false;
                                    }
                         }
                        
                       });
                  }
              }));

               
            }

          }

         
          

      },

      fnRetry(element){
        const self = this;

        if(this.splitInvoice[element.index].loading === false){
            this.formLoading = true;
            self.$set(this.splitInvoice[element.index],'loading', true)
                    this.multiplePaymentIntegration({series : element.series, index : element.index}).then((response) => {
                            self.$set(self.splitInvoice[element.index],'loading', false)
                            self.$set(self.splitInvoice[resData.index],'success', true)
                            self.$set(self.splitInvoice[resData.index],'message', "Success")
                            self.$set(self.splitInvoice[resData.index],'complete', true)
                                  if(self.splitInvoice.length === self.splitInvoice.filter((e) => e.complete === true).length){
                                    self.formLoading = false;
                                  }

                    }).catch((err) => {

                      const resData = err.response.data;
                      self.$set(self.splitInvoice[resData.index],'complete', true)
                      self.$set(self.splitInvoice[resData.index],'loading', false)
                      self.$set(self.splitInvoice[resData.index],'success', 'failed')
                      const message = resData.error || resData.message
                      self.$set(self.splitInvoice[resData.index],'message', message)

                      if(self.splitInvoice.length === self.splitInvoice.filter((e) => e.complete === true).length){
                                    self.formLoading = false;
                                  }

                      /* if(err.response.status === 422){
                          self.$set(self.splitInvoice[element.index],'loading', false)
                          self.$set(self.splitInvoice[element.index],'success', 'failed')
                      } */
                    });
          }
      }
    },
//#endregion
//#region[VueComputed]
    computed: {

          splitInvoice : function(){
            const self = this;
            let res = [];
            if(self.form.payments !== null && self.form.payments !== ""){

              const str = self.form.payments.replace(/(\r\n|\n|\r)/gm, "")
                const splitted =  str.split(",").filter(String);

  
                res = splitted.map((res, index) => {
                      const series = res.replace(/ /g, "")
                      if(series.length > 0){
                        return {series : series, success : null, loading : false, index : index, message : null, complete : false}
                      }
                })
              }
            return res;
          },

          completed : function(){
              return this.splitInvoice.filter((e) => {                  
                  return e.complete === true
              })
          }
          
      
    },
//endregion
    watch: {
     
      "splitInvoice.complete": function(newVal, previousVal) {
        console.log("new Value is " + newVal,
        "previous value is " + previousVal);
      }
    },

   


}
</script>
