<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" v-bind:class="bindLabelHorizatal +' '+ labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">


                <div class="d-flex flex-row">
                <div style="width:150px">
                    <div class="w-100 md-form">
                        <v-select class="" ref="vselect" :multiple="false" v-model="inputValue.from" append-to-body :reduce="option => option.code" :label="'label'" :options="currencyOptions" :filterable="false" :clearable="false"></v-select>
                    </div>
                </div>
                <money @keydown.native="fnInputValue" v-model="inputValue.rate" v-bind="options" class="text-right ml-2 w-100" autocomplete="off"></money>
                </div>


                       
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'ExchangeRateInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: Object,
            default : ()=> {
                return {
                    rate : 0,
                    from : 'usd',
                    to : 'qar',
                }
            }
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputclass : {
            type : Array,
            default : function(){
                return ['s'];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },
        totalAmount: {
            type: Number,
            default : 0
        },
        
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : {
            amount : 0,
            percent : 0
        },

        activeInput : null
    }),

    

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        setSelected(v){
            this.$emit('select',v)
        },

        open(){
            this.$emit('open',this.$attrs.vid)
        },
        fnInputPercent(){
            this.activeInput = 'percent'
            //this.inputValue.amount = this.totalAmount * value / 100
        },
        fnInputValue(){
            this.activeInput = 'value'
            // console.log("Value")
            // console.log(value)
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        },

        "inputValue.percent" : function(v){
            if(this.activeInput === 'percent'){
                this.inputValue.amount = this.totalAmount * v / 100
            }
        },
        "inputValue.amount" : function(v){
            if(this.activeInput === 'value'){
            this.inputValue.percent = (v / this.totalAmount) * 100
            }
        }

    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    },
    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.horizontal){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.horizontal){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputclass.join(' ');
        },

        options : function(){
            const conf = {
                decimal: '.',
                thousands: ',',
                prefix: '',
                suffix: ' QAR',
                precision: 2,
                masked: false
            }
            return conf;
        },
        percent_options : function(){
            const conf = {
                decimal: '.',
                thousands: ',',
                prefix: '',
                suffix: '',
                precision: 2,
                masked: false
            }
            return conf;
        },

        currencyOptions : function(){

            return [{code : 'usd', label : 'USD'},{code : 'eur', label : 'EUR'},{code : 'gbp', label : 'GBP'},{code : 'dkk', label : 'DKK'},{code : 'qar', label : 'QAR'}]
        }
    },
}

</script>