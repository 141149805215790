
<template>
    <div class="page-container">
        <ThatsTable title="Bill of Marterials" ref="table" :multipleSelection="checkSystemSupport" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :height="'calc(100vh - 155px)'" :newBtn="true" :importBtn="false" :enableFilter="false">
            <template #tabs>
                <mdb-tabs class="table-tabs" :active="active" default :links="[{ text: 'Items' }]"  @activeTab="getActiveTabIndex" />
            </template>
            <template #custom-buttons>
                <template v-if="selectedItem.length > 0 && activeTab === 'by-item'">

                     <mdb-dropdown class="inactive-reactive mr-auto ml-1 mt-0"> 
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm" class=" mt-0" style="margin-top:0!important">EXPORT</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu" style="left:-99px">
                            <mdb-dropdown-item @click="fnExport">Export</mdb-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-item @click="fnExportAll">Export All</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>

                </template>
            </template>
        </ThatsTable>

        <BillsOfMaterialsForm ref="formWindow" :group="3" @success="refreshTable"></BillsOfMaterialsForm>
        
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';

import { userData } from '../../helpers/user'
import BillsOfMaterialsForm from './BillsOfMaterialsForm';
import ThatsTable from './Table'
import {mdbTabs,mdbBtn, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle,} from 'mdbvue'

export default {
    data(){
        return {
            endpoint : 'bom',    
            enableFilter : true,         
            importLoading : false,        
            activeTab : 'by-document',    
            active : 0,        
            token : null,
            enableNew : true,        
            enableImport : true,
                   
                    
            importLoading : false,    
                    
        }
    },
    components:{
        BillsOfMaterialsForm,
        ThatsTable,
        mdbTabs,
        mdbBtn,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,

    },
    created(){
    },   
    methods: {
        // 

        ...mapActions('TableV2Module',[
            'resetData',
        ]),
        ...mapMutations('TableV2Module',[
            'SetTotal',
            'SetPage',
            'SetTerm',
            'SetFilters',
            
        ]),

        ...mapActions('BillOfMaterialModule',[ 'deleteRecord','saveImport','getRecord']),
        ...mapMutations('BillOfMaterialModule',[ 'setFormRecord', 'setFormStatusUpdate','setFormStatusCreate','setTblKey']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;

            this.deleteRecord(items).then((response) => {
                self.resetData(this.endpoint)
                self.$ThatsNotify.success({
                        message : `(${response.deleted}) record was successfully deleted.`
                    })
            })
            // this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;
            
            this.getRecord({series : obj.row.series})
            
            this.$refs.formWindow.openDialog();
        },
        openUploadWindow(){
             var self = this;
            
           
        },
        openWindow(){
            var self = this
            self.$refs.formWindow.openDialog();
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },



        setter(){
             const loggedUser = userData()
         
        },

        getActiveTabIndex(index){


            const tabs = ['eta-confirmed','by-item'];
            this.activeTab = tabs[index];
            this.active = index;
            this.SetPage(1)
            this.SetTotal(0);
            this.SetFilters({});
            
            // const ep = (index === 0) ? 'data/purchase-order?status=eta-confirmed' : 'documents/physical-count-item'
                // this.endpoint = ep    
           
            this.resetData(this.endpoint);

          
        },

        fnPrint(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        if(this.activeTab === 'sales'){
            const win = window.open(`/sales/invoice/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
        }
        
        if(this.activeTab === 'sales-quotation'){
                const win = window.open(`/sales/transactions/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
        }

       

        // this.printUrl = url
        // this.$refs.printWindow.openDialog();
      },
    
    fnExport(){
        // alert(1)
        // console.log(this.selectedItem)
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const selected = this.selectedItem.map(i => i.id);
         const win = window.open(`https://docs.thatsliving.com/warehouse-management/export/?items=${selected.toString()}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            // win.print()
    },
    fnExportAll(){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

         const win = window.open(`https://docs.thatsliving.com/warehouse-management/export`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            // win.print()
    }

    },
    computed: {
        ...mapGetters('TableV2Module',{
            tblKey: 'tblKey',
            selectedItem: 'Selection',
        }),
       
        ...mapGetters('BillOfMaterialModule',['columns']),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>