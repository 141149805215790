/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{}" @submit.prevent="onSubmitForm('open-updated')">
       <XWindow ref="window" :title="`Inventory Transfer`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>
              <!-- <template v-if="formLoading || getLoading || onPasteLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template> -->

           

              <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <template v-if="form.status !='cancelled'">
                        <mdb-btn color="elegant" icon="printer" size="sm" @click="fnPrintDoc('/inventory-transfer/print/'+form.series)"><i class="el-icon-printer"></i> PRINT</mdb-btn>
                        </template>
                        <template v-if="!['received-with-discrepancy','received','cancelled'].includes(form.status)">
                          <mdb-btn color="elegant" size="sm" @click="onSubmitForm('open-updated')">Save</mdb-btn>
                        </template>
                      
                        <template v-if="['received-with-discrepancy'].includes(form.status) && form.action =='adjustment'">
                          <mdb-btn color="elegant" size="sm" @click="onSubmitForm('adjustment')">Save Adjustment</mdb-btn>
                        </template>
                        <template v-if="['open-updated','open'].includes(form.status) && countReceived > 0">
                          <mdb-btn color="elegant" size="sm" @click="onSubmitForm('received')">Received</mdb-btn>
                        </template>
                      </template>
                      
          </template>

            <template #right-button>
            </template>
            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" :key="eventKeyCounter">
              <template :slot="'form-slot'">
                
                  <mdb-row class="tab-row">
                      <mdb-col col="12" class="tab-col">

                        <mdb-row class="tab-row">
                                <mdb-col col="6" class="tab-col">
                                  <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Document Date" placeholder="Document Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                                  <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="SHOWROOM" v-model="form.showroom.id" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'" :disabled="(form.status != 'draft' ? true : false)" :selectable="(option) => filteredShowroom.includes(option.prefix)"></ThatsMultiSelect>
                                  <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="description" name="description" :rows="1" v-model="form.description" size="sm"/>
                                 </mdb-col>   
                                <mdb-col col="6" class="tab-col">
                                 
                                  <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Series" vid="series" name="series" v-model="form.series" size="sm" :disabled="true" :rules="''"/>
                                  <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" :label="((form.status === 'open' ? 'Receiver' : 'Received By'))" vid="received_by" name="received_by" v-model="form.receiver.name" size="sm" :disabled="true" :rules="'required'"/>
                                  <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Status" vid="status" name="status" v-model="form.status_text" size="sm" :disabled="true" :rules="'required'"/>
                                </mdb-col>
                              </mdb-row>
                          
                                <el-table ref="deliveryItemsTable" border :key="eventKeyCounter" stripe :data="form.items" class="input-table mt-2 custom-element-table " :summary-method="getSummaries" show-summary style="width: 100%" @selection-change="handleSelectionChange" :row-key="(row) => row.id">
                                      <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                                      <el-table-column label="SKU" :width="150">
                                        <template slot-scope="scope">
                                        {{ scope.row.series }}
                                        </template>
                                      </el-table-column>
                                      <el-table-column label="Image" width="90">
                                            <template slot-scope="scope">
                                              <img :src="scope.row.photo" style="width:90px" />
                                            </template>
                                          </el-table-column>
                                      <el-table-column label="Item Name" :width="250">
                                        <template slot-scope="scope">
                                          {{scope.row.name}}
                                        </template>
                                      </el-table-column>
                                        <el-table-column label="Quantity" :width="120" align="center">
                                          <template slot-scope="scope">
                                            <template v-if="form.action =='adjustment'">
                                              <input type="number" class="form-control grid-input inventory-form-input border" v-model="scope.row.quantity" />
                                            </template>
                                            <template v-else>
                                              {{ scope.row.quantity }}
                                            </template>

                                          </template>
                                        </el-table-column>
                                        <el-table-column label="From" :width="100">
                                          <template slot-scope="scope">
                                            {{ scope.row.attr.locationFrom }}
                                          </template>
                                        </el-table-column>

                                        <el-table-column label="To" :width="100">
                                          <template slot-scope="scope">
                                            {{ scope.row.attr.locationTo }}
                                          </template>
                                        </el-table-column>

                                        <el-table-column label="Received Qty" :width="150" align="center">
                                          <template slot-scope="scope">
                                            <template v-if="form.action =='receive'">
                                              <input type="number" class="form-control grid-input inventory-form-input border" v-model="scope.row.received_quantity" />
                                          </template>
                                          <template v-else>
                                            {{ scope.row.received_quantity }}
                                          </template>

                                            
                                          </template>
                                        </el-table-column>

                                        <el-table-column :width="120" align="center">
                                        <template slot-scope="{}" slot="header">

                                          <template v-if="form.action =='receive'">
                                            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllReceived" @change="handleCheckAllChange">Received?</el-checkbox>
                                          </template>
                                          <template v-else>
                                            Received
                                          </template>
                                        </template>
                                        <template slot-scope="scope">
                                          <template v-if="form.action =='receive'">
                                            <el-checkbox v-model="scope.row.received" style="margin-left:6px" @change="handleCheckedReceivedChange" :disabled="(scope.row.received_quantity <= 0 ? true : false)"></el-checkbox>
                                          </template>
                                          <template v-else>
                                                {{ scope.row.received == true ? 'Yes' : 'No' }}
                                          </template>
                                          
                                        </template>
                                      </el-table-column>
                                        
                                        <el-table-column label="Remarks" align = "left" width="250">
                                          <template slot-scope="scope">
                                              
                                            <ValidationProvider :rules="(((parseInt(scope.row.quantity) !=  parseInt(scope.row.received_quantity)) && (scope.row.received)) ? 'required' : '')" :vid="`description-${scope.row.id}`" :name="`description-${scope.row.id}`" v-slot="{ errors }">
       
                                              <textarea :key="scope.row.id" v-model="scope.row.description" :disabled="!canSelectRow" class="w-100 d-block"></textarea>

                                              <span class="text-sm small text-red text-danger w-100 d-block">{{ errors[0] }}</span>
                                              </ValidationProvider>


                                          
                                          </template>
                                        </el-table-column>
                                        <el-table-column></el-table-column>
                                    </el-table>

                  

                    </mdb-col>
                  </mdb-row>
                  
               
              </template>
              
             
            </mdb-tabs>
            
          </XWindow>
  </ValidationObserver>
  <el-dialog
      title="Warning"
      :visible.sync="dialogVisible"
      width="30%" class="warning">
      <div class="text-center"><i class="el-icon-warning text-warning" style="font-size:42px;"></i></div>
      <p>You are about to update the status with a mismatch received quantity.<br />Are you sure you want to continue?</p>
      <div slot="footer" class="dialog-footer text-left">
        <mdb-btn color="elegant" size="sm" @click="onSubmitForm('received-with-discrepancy')">Continue with Discrepancy</mdb-btn>
        <mdb-btn color="elegant" size="sm" @click="onSubmitForm('open-updated')">Save, Keep it Open</mdb-btn>
        <mdb-btn color="grey" size="sm" @click="dialogVisible = false">Cancel</mdb-btn>

      </div>
    </el-dialog>

 

  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData','btsApproverProvider'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer
  },
    
  data(){

    const self = this;

    return {
      dialogVisible : false,
        isIndeterminate : false,
        checkAllReceived : false,
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,

        tabs : [
                { text: 'Details', slot: 'form-slot' },
              ],
        gallery : [],
        index : 0
       
    }
  },
    

  created : function() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.resetForm();
        
  },




  methods: {
    ...mapMutations('InventoryTransferReceiveModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapActions('InventoryTransferReceiveModule',['storeRecord','updateRecord','getRecord']),

    onResize({width,height}){  

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));


      console.log("this.userData")
      console.log(this.userData)
      this.form.received_by.id = this.userData.user.id
      this.form.received_by.name = this.userData.user.name

      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(saveAs = 'open'){
       const self = this;
            self.$refs.observer.validate().then((isValid) => {
              if(isValid){

                  if((saveAs == 'received' && this.form.action == 'receive') || (saveAs == 'adjustment' && this.form.action == 'adjustment')){

                    let totalQuantity = 0;
                      const qty = self.form.items.map(item => {
                            return parseInt(item.quantity)
                          })

                          totalQuantity = qty.reduce((prev, curr) => {
                              const value = Number(curr);
                              if (!isNaN(value)) {
                                return prev + curr;
                              } else {
                                return prev;
                              }
                            }, 0);


                            let totalReceivedQuantity = 0;
                          const receivedQuantity = self.form.items.map(item => {
                                return parseInt(item.received_quantity)
                              })

                          totalReceivedQuantity = receivedQuantity.reduce((prev, curr) => {
                              const value = Number(curr);
                              if (!isNaN(value)) {
                                return prev + curr;
                              } else {
                                return prev;
                              }
                            }, 0);
                      
                      if(totalReceivedQuantity != totalQuantity && this.form.action == 'receive'){
                        self.dialogVisible = true;
                        return false
                      
                      }

                      if(totalReceivedQuantity != totalQuantity && this.form.action == 'adjustment'){
                        MessageBox({
                              title : 'Error',
                              message: 'Quantity and Received Quantity is mismatch.',
                              type: 'error'
                          })
                        return false
                      
                      }

                      if(totalReceivedQuantity == totalQuantity && this.form.action == 'adjustment'){
                        saveAs = 'received'
                      }

                  }
                  self.dialogVisible = false
                  self.updateInventoryTransfer(self.form,saveAs)

                }
            });
            /* const isValid = 
             */
    },
    updateInventoryTransfer(obj,status){
      const self = this;
        const data = {...obj, status : status}

        if(this.formLoading == false){
          this.formLoading = true;
          this.updateRecord(data).then((res) => {
            self.formLoading = false
              self.$ThatsNotify.success({
                message : "Record successfully updated."
              })
              self.closeDialog()
              self.$emit('success')
          }).catch((err) => {
            self.formLoading = false
            self.closeDialog()
          })
        }
    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){
              
        
    },

    

        
    fnPrint(url){
      const self = this;
      console.log(`${self.$route.path}/${url}`)
      
      var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
      const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
      win.document.title = 'Print';
      win.print()
    },


        renderTable(){
            const self = this;
            


               
        },

     
        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){
           
            
            this.resetForm();
          return
        }
        
      },

    

    handleSelectionChange(v){

      console.log("handle selection")
      this.form.inventory_transfer_receiving_form.items = v
    },

    canSelectRow(row, index){
        if(row.remaining <= 0){
           return false
        }
        return true;
    },

    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },
    
    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      fnPrintDoc(url){
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },
      
      receiveInventoryTransfer(){
          let totalQuantity = 0;
          const qty = this.form.items.map(item => {
                return parseInt(item.quantity)
              })

              totalQuantity = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);


                let totalReceivedQuantity = 0;
              const receivedQuantity = this.form.items.map(item => {
                    return parseInt(item.received_quantity)
                  })

              totalReceivedQuantity = receivedQuantity.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
          



          
          if(totalReceivedQuantity != totalQuantity){
            this.dialogVisible = true;
            return false
           /*  MessageBox.confirm('You are about to updated status with mismatch received quantity. Are you sure you want to continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
             
            }) */


            
          }

          alert(`${totalQuantity} != ${this.countReceived}`)
      },

      handleCheckAllChange(val) {
        var self = this;
          this.isIndeterminate = false;
          this.form.items.forEach((row,index) => {
            const isReceivable = (row.received_quantity > 0) ? true : false;
            self.$set(self.form.items,index, {...row,received : isReceivable});
          })


      },
      handleCheckedReceivedChange(value) {
        const self = this;
        const checked = this.form.items.filter(row => {
          return row.received === true
        }).map(e => e.received).length;

        const isIndeterminate = checked > 0 && checked < this.form.items.length;

        const checkAllReceived = checked === this.form.items.length

        this.$nextTick(() => {
            self.isIndeterminate = isIndeterminate
            self.checkAllReceived = checkAllReceived
        });

        /* this.sele
        console.log((checked > 0 && checked < this.form.items.length)) */
      },

      getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
       
        columns.forEach((column, index) => {
          if (index === 3) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === 4) {
            const qty = data.map(item => {
                return parseInt(item.quantity)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }

          if (index === 7) {
            const qty = data.map(item => {
                return parseInt(item.received_quantity)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
        
            

          });

          // this.toggleCheck()

        return sums;
      },

      handleClose(){
          this.dialogVisible = false
      }
     
  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('InventoryTransferReceiveModule',['getLoading','formData','formStatus','initialFormObject']),
      tblKey : function(){
        return 1;
      },

      showroomLocation : function(){
        const showroom = this.showroomsProvider();
        return showroom.map((item) => {
            return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
          },[])
      },

      rowShowroomLocation : function(){

      const showroom = this.showroomsProvider();

      return showroom.map((item) => {
          // return {...item, name :item.prefix,id : item.value}
          return item.prefix
        },[])
      },

      userDatax : function(){
        return this.userDataProvider()
      },
      documentApprover : function(){
        return this.btsApproverProvider()
      },

      countReceived : function(){
        return this.form.items.filter((item) => item.received == true,[]).length
      }

    
  },

  watch: {
      'formData' : function(v){

        const self = this
        
        this.eventKeyCounter++
        
        console.log("receive item")
        console.log(v)

        var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form = JSON.parse(JSON.stringify({...v,received_by : { id :this.userData.user.id, name : this.userData.user.name }}));
          this.$nextTick(()=>{

          
          })

        /* 
        if(this.formStatus == 'update'){



          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);

          this.form = JSON.parse(JSON.stringify({...v, date : date}));
          this.$nextTick(()=>{

          
          })

          this.active = 0;
                    

        }else{

         

          this.tabs = [
                { text: 'Request Details', slot: 'form-slot' },
                { text: 'Remarks', slot: 'remarks-slot' }
              ]
          this.resetForm();
          this.active = 0;
        } */
           
      },

      

      

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },

      documentApprover : function(value){
          this.form.approver = JSON.parse(JSON.stringify(value))
      },

      showroomLocation : function(value){

          const allowedLocation = this.userData.location_prefix
        this.filteredShowroom = value.filter((i) =>{
            return allowedLocation.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })
      },

      



  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>