<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">
        <div class="checkbox-container">
            <template v-if="multiple">
                    <template v-if="$attrs.orientation == 'inline'">
                            <div class="custom-control  custom-checkbox custom-control-inline" v-for="(dt,index) in data" v-bind:key="index">
                                <input type="checkbox" class="custom-control-input" v-model="selected" :id="`cb-${$attrs.vid}-${dt.value}`" :value="dt.value">
                                <label class="custom-control-label" :for="`cb-${$attrs.vid}-${dt.value}`">{{dt.label}}</label>
                            </div>
                    </template>
                    <template v-else>
                        <div class="custom-control custom-checkbox" v-for="(dt,index) in data" v-bind:key="index">
                            <input type="checkbox" class="custom-control-input" v-model="selected" :id="`cb-${$attrs.vid}-${dt.value}`" :value="dt.value">
                            <label class="custom-control-label" :for="`cb-${$attrs.vid}-${dt.value}`">{{dt.label}}</label>
                        </div>
                    </template>
            </template>
            <template v-else>
               <div class="custom-control custom-checkbox custom-control-inline">
                    <input type="checkbox" class="custom-control-input" v-model="selected" :id="'simplecheckbox'+$attrs.vid" :value="true">
                    <label class="custom-control-label" :for="'simplecheckbox'+$attrs.vid">{{label}}</label>
                </div>
            </template>
        <template v-if="show_error == true">
            <span class="errors">{{errors[0]}}</span>
        </template>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline } from "mdbvue";
export default{
    name : 'Checkbox',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: [Array, Boolean]
        },
        validated: {
            type: Boolean,
            default : true
        },

        data: {
            type: Array
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        multiple : {
            type : Boolean,
            default : true
        },
        label : {
            type : String,
        }

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
    },

    data : ()=>({
        selected : [],
    }),


    watch: {
    // Handles internal model changes.
        selected(newVal) {

             this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.selected = newVal;
        }
    },
    created() {
        if (this.value) {
        this.selected = this.value;
        }
    }
}

</script>

<style scoped>


.errors{
    display: block!important;
}
</style>