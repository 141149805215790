<template>
    <el-dialog :zIndex="(8)" :title="flag_data + ' Table'" :visible.sync="syncModal" class="text-left pie-table" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" :modal="true">
    <div class="filter-dalog-body p-0">
                      <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" stripe>
                          <el-table-column type="index" width="35" label="#"> </el-table-column>
                              <el-table-column v-for="col in currentColumns" :key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                              <template slot-scope="scope">
                                  <template v-if="flag_data === 'brand'">
                                      <template v-if="col.field === 'name'">{{ scope.row.brand }}</template>
                                      <template v-if="col.field === 'total_qty'">{{ scope.row.total_qty }}</template>
                                      <template v-if="col.field === 'total_value'">QAR {{ scope.row.total_value | thousands }}</template>
                                  </template>
                                  <template v-else-if="flag_data === 'category'">
                                      <template v-if="col.field === 'name'">{{ scope.row.category }}</template>
                                      <template v-if="col.field === 'total_qty'">{{ scope.row.total_qty }}</template>
                                      <template v-if="col.field === 'total_value'">QAR {{ scope.row.total_value | thousands  }}</template>
                                  </template>
                                  <template v-else-if="flag_data === 'location'">
                                      <template v-if="col.field === 'location'">{{ scope.row.location }}</template>
                                      <template v-if="col.field === 'value'">QAR {{ scope.row.value | thousands }}</template>
                                  </template>
                              </template>
                          </el-table-column>
                          <template slot="empty">
<!--                              <template v-if="viewTransactionLoading">-->
<!--                                  <mdb-btn color="elegant" size="sm" rounded disabled>-->
<!--                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>-->
<!--                                      Loading data...-->
<!--                                  </mdb-btn>-->

<!--                              </template>-->
                              <template>
                                  No Data
                              </template>
                          </template>
                      </el-table>
<!--                      <el-pagination-->
<!--                          @size-change="handleSizeChange"-->
<!--                          @current-change="handleCurrentChange"-->
<!--                          :current-page.sync="current_page"-->
<!--                          :page-sizes="[10,25,50,100, 200, 300, 400]"-->
<!--                          :page-size="length"-->
<!--                          layout="total, sizes, prev, pager, next, jumper"-->
<!--                          :total="total">-->
<!--                      </el-pagination>-->

                  </div>

                  <template slot="footer">
                      <div class="pl-3 pr-3">
                          <div class="d-flex">
                              <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                          </div>
                      </div>
                  </template>
           </el-dialog>
</template>
<script>
import { mdbBtn } from "mdbvue";
import { mapGetters } from 'vuex'

export default {
    components: {
        mdbBtn,
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
        columns : {
            type : Array,
            default : () => {
                return [
                    {
                        label: 'Name',
                        field: 'name',
                    },
                    {
                        label: 'Quantity',
                        field: 'total_qty',
                    },
                    {
                        label: 'Total Value',
                        field: 'total_value',
                    }
                ]
            }
        },
    },
    inject:['showroomsProvider'],
    data : () => ({
        data: {
            columns: [],
            rows: [],
        },
        syncModal : false,
      form : null,
      formData : null,
      flag_data : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        isWalkin : false,
      disabled : false,
      isModal : false,
      group2 : 3
    }),


  mounted() {

  },
  methods: {
    openNewDialog(data, flag) {
          this.flag_data = flag;
          if (this.flag_data === "brand"){
              this.data.rows = data.brand_sales;
          }else if (this.flag_data === "category"){
              console.log("swaraj banda pila")
              this.data.rows = data.category_sales;
          }else{
              this.data.rows = data.location_sales;
          }


        this.syncModal = true;
    },
        openDialog(){
            this.syncModal = true
        },


      refreshTable(){
          this.SetFilters(this.filterObj)
          this.resetData(this.endpoint);
      },
        closeDialog(){
            this.syncModal = false

        },

        closeNewDialog(){
            this.syncModal = false

        },

    },

    computed: {
      ...mapGetters('AuthModule',[ 'user', ]),

      enableEditButton : function(){
        return !["AMTM","AMDFC","TLKD","ECDFC","MPDFC",null,"null"].includes(this.form.showroom.prefix)
      },
        current_page : {
            get : function(){
                return this.page
            },
            set : function(val){
                this.SetPage (val)
            }
        },
        currentColumns() {
            if (this.flag_data === 'location') {
                return [
                    {
                        label: 'Location',
                        field: 'location',
                    },
                    {
                        label: 'Value',
                        field: 'value',
                    }
                ];
            }
            return this.columns;
        }

    },

    watch: {
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.custom-z-index {
  z-index: 0 !important; /* Set the desired z-index */
}

</style>
