<template>
   <el-table id="itemTable" ref="itemTable" :key="`replace${tblKey}`" border :data="tableData" class="input-table itemTable" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary @row-contextmenu="rowContectMenu" v-mdb-click-outside="handleOutsideClick">
            <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                <el-table-column label="SKU" width="200">
                    <template slot-scope="scope">
                        <GridVueSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></GridVueSelect>
                    </template>
                </el-table-column>
                <el-table-column label="Image" width="90">
                    <template slot-scope="scope">
                        <img :src="scope.row.default" style="width:100%" @click="clickShowSearch(scope.row,scope.$index)" />
                        <template v-if="scope.row.show_search">
                            <div class="search-fabric">
                            <label>Enter/Scan Fabric Code</label>
                            <button type="button" class="close" aria-label="Close" @click="closeFabric(scope.$index)">
                                <span aria-hidden="true">×</span>
                            </button>
                            <GridFabricSelect :reduce="item => item.id" :reduceLabel="'id'" :options="fabrics" :filterable="false" :clearable="false" @onSearch="statusKeyUpFabric" @select="getSelectedFabric($event,scope.$index,scope.row)"></GridFabricSelect>
                            </div>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Item Name" :row-class-name="''">
                    <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                    </template>
                </el-table-column>
                <el-table-column label="Qty" :width="70" align="right">
                    <template slot-scope="scope">
                        <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableQuantity(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column label="Price" :width="100" align = "right">
                    <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="!enablePrice(scope.row)" @keydown.enter="addNewRow(scope.$index)"  @input="calculatePrice(scope.$index,$event)" @focus="removeFormat(scope.$index)" @blur="formatOnBlur(scope.$index)"/>
                        </template>
                </el-table-column>
                <el-table-column label="Amount" :width="100" align = "right">
                    <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                    </template>
                </el-table-column>
                <el-table-column label="Discount" :width="100" align = "right">
                    <template slot-scope="scope">
                        <span v-html="scope.row.discount"></span>
                        <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                        <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="Total Amount" :width="120" align = "right">
                    <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                    </template>
                </el-table-column>

        </el-table>
</template>
<script>
import { mapActions } from 'vuex'
import GridVueSelect from './GridVueSelect'
import GridFabricSelect from './GridFabricSelect'
import unformat from 'accounting-js/lib/unformat.js'
import { mdbBtn, mdbRow,mdbCol, mdbIcon, mdbClickOutside} from "mdbvue";
export default {
    directives: {
      mdbClickOutside
    },
    components : {
        GridFabricSelect,
        GridVueSelect,
        mdbBtn, mdbRow,mdbCol, mdbIcon
    },
    props : {
        value : {
            type : Array
        }
    },
    data(){
        return {
            selected : [],
            tableData : [
                {
                default: "",
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: 1,
                max: 1,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply : true,
                    discount : 0,
                    off_price : 0,
                    promo : 0,
                },
                total: 0,
                tbo: false,
                show_search: false,
                remarks: null
                } 
            ],
            items : [],
            fabrics : [],
            modify : false,
            disabled : false,
            tblKey : 0,
            context: null,
            viewMenu: true,
            top: '0px',
            left: '0px',
        }
    },
    methods : {
        ...mapActions('InvoiceModule',[
            'filterItems',    
        ]),
        ...mapActions('TransactionModule',[
            'filterFabric'
        ]),
        handleSelectionChange(v){
            this.selected = v
        },
        formatNumber(n) {
            return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
            });
        },
        getSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];
            
            let totalCol = 3;
            let totalQty= 4;
            let totalPrice= 5;
            let totalAmount= 6;
            let totalDiscount= 7;
            let totalTotal= 8;
            
            columns.forEach((_column, index) => {
            if (index === totalCol) {
                sums[index] = 'Total';
                return;
            }

            
            if (index === totalQty) {
                const qty = data.map(item => {
                    return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
                })

                if (!qty.every(qty => isNaN(qty))) {
                    sums[index] = qty.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                } 
                return;
            }
            
            if (index === totalAmount) {
                const amount = data.map(item => {
                    return parseInt(item.qty) * parseFloat(item.price);
                });

                if (!amount.every(amount => isNaN(amount))) {
                    const sum = amount.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);

                    sums[index] = this.formatNumber(sum)

                }

                return;
            }
            if (index === totalDiscount) {
                
                const discount = data.map(item => {
                    return unformat(item.discount);
                })
                
                if (!discount.every(discount => isNaN(discount))) {
                    const sum = discount.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);

                    sums[index] = this.formatNumber(sum)

                }
                return;
            }

            if (index === totalTotal) {
                const total = data.map(item => {

                    return unformat(item.total);
                });
                
                if (!total.every(total => isNaN(total))) {
                    const sum = total.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    this.total =  this.formatNumber(sum)
                    sums[index] = this.formatNumber(sum)

                }
                return;
            }  

            });

            return sums;
        },

        statusKeyUp(v){
            const params = {term : v, items : [],transaction_date : ""};
            this.dispatchFilterItems(params)
        },
        dispatchFilterItems(params){
            const results = [];
            this.filterItems(params).then((response) =>{
                    if(response.length > 0){
                    response.forEach(element => {
                        results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default,category : element.category,  remaining : element.remaining, status : element.status})
                    });
                    }
                })
            this.items = results

        },
        statusKeyUpFocus(){      
        },

        getSelected(v,index){
            const getSelected = this.items.filter(item => { 
                    return (item.code === v)
                })[0];

                if(getSelected){
                    const qty = 1;
                    let price = getSelected.price;
                    let discount = 0;
                    let remarks = null;
                    if(getSelected.promotions.length > 0){
                        const itemPromo = getSelected.promotions[0];

                        const cd = {
                        apply: true,
                        discount: 0,
                        off_price: 0,
                        promo: 0,
                        }

                        discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
                        remarks = this.setRemarks(qty,itemPromo);
                        this.$set(this.tableData[index], 'discount_type','promo');
                    }
                    this.$set(this.tableData[index], '$index$', index)
                    this.$set(this.tableData[index], 'category', getSelected.category)
                    this.$set(this.tableData[index], 'item_id', getSelected.id)
                    this.$set(this.tableData[index], 'name', getSelected.label)
                    this.$set(this.tableData[index], 'default', getSelected.default)
                    this.$set(this.tableData[index], 'qty', qty)
                    this.$set(this.tableData[index], 'promotions', getSelected.promotions)
                    this.$set(this.tableData[index], 'formatted_price', getSelected.formatted_price)
                    this.$set(this.tableData[index], 'price', price)
                    this.$set(this.tableData[index], 'amount', this.computeAmount(qty, price))
                    this.$set(this.tableData[index], 'discount', discount)
                    this.$set(this.tableData[index], 'remarks', remarks)
                    this.$set(this.tableData[index], 'total', this.computeTotalAmount(qty, price, discount))
                    this.$emit('selected',{index :  index, row : this.tableData[index]})

                    // alert(this.tableData.length)
                    // alert(this.tableData.length)

                    if(this.tableData.length === 1 && index === 0){
                        this.tableData.push({
                            '$index$': this.tableData.length + 1,
                            default: "",
                            item_id: null,
                            code: null,
                            name : null,
                            category : null,
                            description: null,
                            reference: null,
                            qty: 1,
                            max: 1,
                            formatted_price: 0,
                            price: 0,
                            amount: 0,
                            discount: 0,
                            discount_type: null,
                            custom_discount: {
                                apply : true,
                                discount : 0,
                                off_price : 0,
                                promo : 0,
                            },
                            total: 0,
                            tbo: false,
                            show_search: false,
                            remarks: null
                            });
                    }
                    
                }
        },
        computeDiscount(qty,price,promo,discount_type,custom_discount){
            if(discount_type !== null){
            let discountPrice = 0
            let apply = false;
            let discount = 0;
                if(discount_type === 'promo'){
                    apply = promo.apply
                    discount = promo.discount
                }else{
                    if(custom_discount !== null){
                        if(custom_discount.hasOwnProperty("apply")){
                            apply = custom_discount.apply;
                        }
                        if(custom_discount.hasOwnProperty("discount")){
                            discount = custom_discount.discount;
                        }
                    }
                }
            const p = parseFloat(price) * qty;
                    
                    if(apply === true || apply === 1){
                        discountPrice = parseFloat(p) * (discount / 100);
                    }else{
                        discountPrice = parseFloat(discount);
                    }
            return discountPrice;
            }
            return 0;
        },

        setRemarks(_qty,promo){
            if(promo.apply === true || promo.apply === 1){
                if(promo.discount > 0){
                    return `${promo.discount}% Discount`;
                }else{
                    return "";
                }
            }else{
                if(promo.discount > 0){
                    return `${this.formatNumber(promo.discount)} QAR Off`;
                }else{
                    return "";
                }
            }
        },
        computeAmount(qty,price){
            return this.formatNumber((qty * parseFloat(price)));
        },
        computeTotalAmount(qty,price,discount){
            const d = unformat(discount);
            const amount = qty * unformat(price);
            const totalAmount = amount - d;
            return this.formatNumber(((d > 0) ? totalAmount : amount));
        },

        clickShowSearch(row,index){
            if(this.modify === true && (row.category === 95 || row.category === 132 || row.category === 127)){
            this.$set(this.tableData[index], 'show_search', true);
            }
        },
        closeFabric(index){
            this.$set(this.tableData[index],'show_search',false);
        },
        statusKeyUpFabric(v){
            const params = {term : v};
            const results = [];
            this.filterFabric(params).then((response) =>{
                    if(response.length > 0){
                        response.forEach(element => {
                            results.push({thumb: element.thumb,label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.price, id : element.id, default : element.default, description : element.description})
                        });
                    }
                })
            this.fabrics = results
        },
        getSelectedFabric(v,index,row){
            const getItem = this.fabrics.filter( e => {
                return (e.id === v);
            })[0]
            if(getItem){
                const name = `${row.name}/${getItem.code}/${getItem.label}`;
                this.$set(this.tableData[index], 'default', getItem.thumb)
                this.$set(this.tableData[index], 'name', name)
            }
        },
        addNewRow(index){
            var dataLength = this.tableData.length - 1;

            if(dataLength === index){
                const item = this.tableData[index];
                if(item.code !== null && item.description !== null){
                    const formItemVar = this.formItemVar();
                    this.tableData.push(formItemVar)

                    }
            }

        },
        enableQuantity(row){
            return false
        },
        openDiscount(index,row){
            this.$emit('open-discount',{index : index , row : row})
        },
        enablePrice(row){
            const cat = [137, 136, 126, 83, 127, 129, 132, 130, 135, 128,142, 143,95,133, 87];

            if((this.$helpers.isInteriorDesigner() || this.$helpers.isSalesExecutive() || this.$helpers.isSystemAdministrator()) && row.category !== null && cat.includes(row.category) && this.modify === true){
                return true;
            }
            return false
        },
        calculate(index){
            const self = this;
            if(typeof self.tableData[index] !== 'undefined') {
                const item = self.tableData[index];
                
                let discount = 0;
                let remarks = null;

                if(item.promotions.length > 0){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    remarks = this.setRemarks(item.qty,item.custom_discount);
                }

                if(item.code !== null && item.name !== null){
                    const amount = this.computeAmount(item.qty,item.price);
                    const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                    this.$set(self.tableData[index], 'remarks',remarks)
                    this.$set(self.tableData[index], 'discount', this.formatNumber(discount))
                    this.$set(self.tableData[index], 'amount', amount)
                    this.$set(self.tableData[index], 'total', totalAmount)

                }
            }
        },

        rowContectMenu(row,_col,e){
            this.$emit('rowContectMenu',{row : row, col : _col, e: e});
        },
        handleOutsideClick(){
            this.$emit('handleOutsideClick');
        }       

    },
    watch : {
        'tableData' : {
            handler: function(v) {
                // this.tblKey++;
                this.$emit('change',v)
            },
            deep: true
        },
        value(newVal) {
            this.tableData = newVal;
            // this.tblKey++;
        }
    }
}
</script>