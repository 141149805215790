<template>
<hsc-window-style-metal>
   <hsc-window v-bind:class="$attrs.windowClass" :title="$attrs.title" :resizable="false" :left="x" :top="y"  :width="width" :minWidth="400" :maxWidth="800" :minHeight="minHeight" :maxHeight="maxHeight" :zGroup="group" :closeButton="true" :isOpen.sync="dialogIsOpen" style="height:auto!important">
        <mdb-carousel
            :items="data"
            indicators
            controlls
        ></mdb-carousel>
    </hsc-window>
</hsc-window-style-metal>
</template>
<script>
import { mdbCarousel } from "mdbvue";
export default {
    name : 'ThatsMediaPlayer',
    components: {
      mdbCarousel
    },
    props : {
        data : {
            type : Array,
            default : []
        },
        width : {
            type : Number,
            default : 600
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 450
        },
        group : {
            type : Number,
            default : 1
        },
    },
    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,

            containerStyle : {

            }
        }
    },
    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)
        }
    }
}
</script>

<style>
.media-container {
    height: 800px; 
    width: 400px;
    white-space: nowrap;
    text-align: center; margin: 0;
}

</style>