<template>
    <el-dialog :title="title" :visible.sync="dialogVisibleSync" :close-on-click-modal="false" width="40%" size="small" class="text-left item-invoice-form">
        <div class="filter-dalog-body p-0">

            <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="600" stripe>
                    <el-table-column type="index" width="35" label="#"> </el-table-column>


                    <el-table-column :sortable="false" :prop="'id'" label="DocNum" >
                        <template slot-scope="scope">
                            {{ scope.row.series}}
                        </template>
                    </el-table-column>

                    <el-table-column :sortable="false" :prop="'id'" label="ETA" >
                        <template slot-scope="scope">
                            {{ scope.row.header_attr.eta_date | isEmpty('Not available')}}
                        </template>
                    </el-table-column>


                    <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.field === 'status'">
                            
                                <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.header_status]"><i :class="statusArrayIcon[scope.row.header_status]"></i> {{ scope.row.status_text}}</mdb-badge>
                            </template>
                            <template v-else>
                                {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}
                            
                            </template>
                        </template>
                    </el-table-column>
                   <template slot="empty">
                       <template v-if="viewTransactionLoading">
                           <mdb-btn color="elegant" size="sm" rounded disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading data...
                           </mdb-btn>
                       
                       </template>
                       <template v-else>
                           No Data
                       </template>

                      
                    </template>
   
   
               </el-table>

            <el-pagination        
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="current_page"
                :page-sizes="[10,25,50,100, 200, 300, 400]"
                :page-size="length"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </el-dialog>
</template>
<script>
import {

    mdbBtn,
    mdbBadge
} from "mdbvue";
import { mapActions, mapMutations ,mapGetters  } from 'vuex'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import { Loading } from 'element-ui';
const formatMoneyConfig = { symbol: "", precision: 2, thousand: ",", decimal: "." }
export default {
    components : {
        mdbBtn,
        mdbBadge
    },
    props : {
        endpoint : {
            type : String,
            default : 'sales/invoice'
        },
        title : {
            type : String,
            default : 'Invoices'
        }
    },
    data(){
        return {
            dialogVisibleSync : false,
            columns : [
/*                 {
                    label: 'Date',
                    field: 'date',
                    width : '150px'
                }, */
           
                {
                    label: 'Qty',
                    field: 'qty',
                    align: 'right',
                    width : '150px'
                },
               
                {
                    label: 'Status',
                    field: 'status',
                    align: 'right',
                    width : '150px'
                },
               
            ],
            filterObj : {
                dateRange: {},
                series : "",
                sold_to : "",
                customer_mobile : "",
                customer_name : "",
                item_code : "",
                status : "",
                balance : false,
            },

            
        }
    },

    methods : {
        ...mapActions('TableV2Module',[
          'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('TableV2Module',[
            'SetTotal',
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
            'SetFilters',
        ]),
        
        openDialog(){
            this.dialogVisibleSync = true
        },

        handleSaleTransactionClick(row){
            this.$emit('rowClick',row)
        },

        triggerGetTransaction(obj){
            const self = this;

            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            }); 

            this.SetTotal(0)
            this.filterObj = {item_code : obj.series}
            this.SetFilters(this.filterObj)
            this.resetData(this.endpoint).then(() => {
                loading.close();
                self.dialogVisibleSync = true
            });
           
        },
        async dispatchHandler(){
            this.SetFilters(this.filterObj)
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
          this.SetLength(val)
          
          this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.SetTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        refreshTable(){
            this.SetFilters(this.filterObj)
          this.resetData(this.endpoint);
        },
        dispatchFilter(filters){
            this.SetTotal(0)
            this.SetFilters(filters)
            this.resetData(this.endpoint);
        },
    },
    computed: {
      ...mapGetters('TableV2Module',[
          'data',
          'page',
          'total',
          'length',
          'searchTerm',
          'tableLoading',
          'Selection',
          'viewTransactionLoading',
      ]),
      term : {
          get : function(){
             return this.searchTerm
          },
          set : function(val){
              this.SetTerm (val)
          }
      },
      current_page : {
          get : function(){
             return this.page
          },
          set : function(val){
              this.SetPage (val)
          }
      },



      hasFilter(){
          return !!this.$scopes.filter;
      },

      hasFilterSlot() {
          console.log(this.$scopedSlots)
          // console.log(('filter' in this.$scopedSlots))
          return ('filter' in this.$scopedSlots);
      },

      hasTabsSlot() {
          return ('tabs' in this.$scopedSlots);
      },
      hasRightSlot() {
          return ('top-right' in this.$scopedSlots);
      },
      hasCustomColumnsSlot() {
          return ('custom-culumns' in this.$scopedSlots);
      },

      statusArrayColor : function(){
            
            return {'draft' : 'badge-light','for-approval' : 'warning','S' : 'warning','P' : 'warning','R' : 'success','open': 'success','canceled' : 'danger','closed' : 'danger'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','S' : 'el-icon-ship','P' : 'el-icon-circle-check','open': 'el-icon-success','R': 'el-icon-success','canceled' : 'el-icon-remove','closed' : 'el-icon-remove'};
        },

        documentStatus : function(){
            return {'S' : 'In Shipment','P' : 'In Port','R' :'Received' }
        }

  },
}
</script>