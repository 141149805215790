export default [
    {
        id: 210,
        name: "INTL-Caracole Classic",
        brand_id: 1
    },
    {
        id: 208,
        name: "TL-Mattress",
        brand_id: 16
    },
    {
        id: 207,
        name: "Cheryl",
        brand_id: 1
    },
    {
        id: 206,
        name: "Modern Artisan Remix",
        brand_id: 1
    },
    {
        id: 205,
        name: "La Moda",
        brand_id: 1
    },
    {
        id: 204,
        name: "Classic Elegance",
        brand_id: 1
    },
    {
        id: 203,
        name: "Lillian",
        brand_id: 1
    },
    {
        id: 202,
        name: "Modern Expression",
        brand_id: 1
    },
    {
        id: 201,
        name: "Rep.li.ca",
        brand_id: 4
    },
    {
        id: 200,
        name: "De Majo",
        brand_id: 5
    },
    {
        id: 199,
        name: "That's Living Floral",
        brand_id: 16
    },
    {
        id: 197,
        name: "Interlude",
        brand_id: 5
    },
    {
        id: 196,
        name: "GRACE GHANEM",
        brand_id: 16
    },
    {
        id: 195,
        name: "Steven Paints",
        brand_id: 16
    },
    {
        id: 194,
        name: "HUONG NGA",
        brand_id: 16
    },
    {
        id: 193,
        name: "REGINA ANDREW - ACCESSORIES",
        brand_id: 16
    },
    {
        id: 192,
        name: "TL Kids",
        brand_id: 16
    },
    {
        id: 191,
        name: "Porcel",
        brand_id: 6
    },
    {
        id: 190,
        name: "TL-Services",
        brand_id: 16
    },
    {
        id: 188,
        name: "TL-Lacquer",
        brand_id: 16
    },
    {
        id: 187,
        name: "Kataryna Crystal",
        brand_id: 6
    },
    {
        id: 186,
        name: "Artepelle",
        brand_id: 6
    },
    {
        id: 185,
        name: "TL-Outdoor",
        brand_id: 16
    },
    {
        id: 176,
        name: "TL-Lightnings",
        brand_id: 16
    },
    {
        id: 175,
        name: "Herdmar",
        brand_id: 6
    },
    {
        id: 170,
        name: "Firma",
        brand_id: 5
    },
    {
        id: 169,
        name: "Renzo Lighting",
        brand_id: 5
    },
    {
        id: 168,
        name: "Nourison Rugs",
        brand_id: 16
    },
    {
        id: 166,
        name: "Theodore Upholstery",
        brand_id: 4
    },
    {
        id: 165,
        name: "Bernhardt Upholstery",
        brand_id: 2
    },
    {
        id: 164,
        name: "Rory Dobner",
        brand_id: 6
    },
    {
        id: 163,
        name: "FFDM Collections",
        brand_id: 16
    },
    {
        id: 162,
        name: "Downtown Bedding Collection",
        brand_id: 16
    },
    {
        id: 161,
        name: "Argentatus",
        brand_id: 49
    },
    {
        id: 160,
        name: "TL-Beddings",
        brand_id: 16
    },
    {
        id: 159,
        name: "TL-Others",
        brand_id: 16
    },
    {
        id: 158,
        name: "Ambiance-Glassware",
        brand_id: 6
    },
    {
        id: 153,
        name: "Augousti Paris",
        brand_id: 16
    },
    {
        id: 152,
        name: "Braun + Company",
        brand_id: 6
    },
    {
        id: 151,
        name: "A Modern Grand Tour",
        brand_id: 16
    },
    {
        id: 150,
        name: "Ambiance Accessories",
        brand_id: 6
    },
    {
        id: 149,
        name: "TL-Agate Stones",
        brand_id: 6
    },
    {
        id: 148,
        name: "Baobab Diffuser & Candles",
        brand_id: 16
    },
    {
        id: 147,
        name: "All State Floral",
        brand_id: 16
    },
    {
        id: 146,
        name: "Everly",
        brand_id: 1
    },
    {
        id: 145,
        name: "TL-Furnitures",
        brand_id: 16
    },
    {
        id: 144,
        name: "Maya Paris",
        brand_id: 7
    },
    {
        id: 134,
        name: "Sabre Paris",
        brand_id: 6
    },
    {
        id: 108,
        name: "Kataryna Dmoch for Thats Living",
        brand_id: 16
    },
    {
        id: 107,
        name: "Lelievre - Missoni - Rykiel",
        brand_id: 16
    },
    {
        id: 106,
        name: "Combi",
        brand_id: 6
    },
    {
        id: 105,
        name: "Plumbike",
        brand_id: 16
    },
    {
        id: 104,
        name: "Visual Comfort",
        brand_id: 5
    },
    {
        id: 102,
        name: "Hudson Valley Lighting",
        brand_id: 5
    },
    {
        id: 100,
        name: "TL-Accessories",
        brand_id: 16
    },
    {
        id: 99,
        name: "TL-Silverware",
        brand_id: 16
    },
    {
        id: 98,
        name: "Regina Andrew",
        brand_id: 5
    },
    {
        id: 97,
        name: "American Modern  Painting",
        brand_id: 16
    },
    {
        id: 96,
        name: "Crystal Julia",
        brand_id: 6
    },
    {
        id: 95,
        name: "Dunoon Mugs",
        brand_id: 6
    },
    {
        id: 94,
        name: "Bodum",
        brand_id: 6
    },
    {
        id: 93,
        name: "Nude Glass",
        brand_id: 6
    },
    {
        id: 92,
        name: "TL-Scented Candles",
        brand_id: 16
    },
    {
        id: 91,
        name: "Eclipse-Lightings",
        brand_id: 5
    },
    {
        id: 90,
        name: "TL Carpet",
        brand_id: 16
    },
    {
        id: 89,
        name: "Universal Furniture",
        brand_id: 16
    },
    {
        id: 88,
        name: "Lladro",
        brand_id: 6
    },
    {
        id: 87,
        name: "Ballet OB",
        brand_id: 49
    },
    {
        id: 86,
        name: "Fortuna",
        brand_id: 49
    },
    {
        id: 85,
        name: "Camellia",
        brand_id: 49
    },
    {
        id: 84,
        name: "Simply Anna Vivian",
        brand_id: 49
    },
    {
        id: 83,
        name: "Saturn",
        brand_id: 49
    },
    {
        id: 82,
        name: "Grace",
        brand_id: 49
    },
    {
        id: 81,
        name: "Atlantico",
        brand_id: 49
    },
    {
        id: 80,
        name: "Allegro",
        brand_id: 49
    },
    {
        id: 79,
        name: "DOM Platinum",
        brand_id: 49
    },
    {
        id: 78,
        name: "New Blue Legacy",
        brand_id: 49
    },
    {
        id: 77,
        name: "Adamastor",
        brand_id: 49
    },
    {
        id: 76,
        name: "Infinity",
        brand_id: 49
    },
    {
        id: 75,
        name: "Excentric",
        brand_id: 49
    },
    {
        id: 74,
        name: "Ethereal Blue",
        brand_id: 49
    },
    {
        id: 73,
        name: "Lush Forest",
        brand_id: 49
    },
    {
        id: 72,
        name: "London",
        brand_id: 49
    },
    {
        id: 71,
        name: "Passion",
        brand_id: 49
    },
    {
        id: 70,
        name: "Pantheon",
        brand_id: 49
    },
    {
        id: 69,
        name: "Olympus Stravaganza PT",
        brand_id: 49
    },
    {
        id: 68,
        name: "Royal Velvet",
        brand_id: 49
    },
    {
        id: 67,
        name: "Premium Gold",
        brand_id: 49
    },
    {
        id: 66,
        name: "Georgian",
        brand_id: 49
    },
    {
        id: 65,
        name: "Stripes",
        brand_id: 49
    },
    {
        id: 64,
        name: "Graphic Floral",
        brand_id: 49
    },
    {
        id: 63,
        name: "Cannobio",
        brand_id: 49
    },
    {
        id: 62,
        name: "Bernhardt",
        brand_id: 2
    },
    {
        id: 55,
        name: "Linea",
        brand_id: 2
    },
    {
        id: 52,
        name: "Allure",
        brand_id: 2
    },
    {
        id: 51,
        name: "East Hampton",
        brand_id: 2
    },
    {
        id: 50,
        name: "Domain Blanc",
        brand_id: 2
    },
    {
        id: 49,
        name: "Profile",
        brand_id: 2
    },
    {
        id: 48,
        name: "Adagio",
        brand_id: 2
    },
    {
        id: 47,
        name: "Jet Set",
        brand_id: 2
    },
    {
        id: 46,
        name: "Decorage",
        brand_id: 2
    },
    {
        id: 45,
        name: "Criteria",
        brand_id: 2
    },
    {
        id: 44,
        name: "Calista",
        brand_id: 2
    },
    {
        id: 43,
        name: "Axiom",
        brand_id: 2
    },
    {
        id: 42,
        name: "Eichholtz",
        brand_id: 3
    },
    {
        id: 41,
        name: "Theodore Alexander",
        brand_id: 4
    },
    {
        id: 38,
        name: "Al Thorp Living History",
        brand_id: 4
    },
    {
        id: 37,
        name: "English Cabinet Maker",
        brand_id: 4
    },
    {
        id: 36,
        name: "Stephen Church",
        brand_id: 4
    },
    {
        id: 35,
        name: "SLD",
        brand_id: 4
    },
    {
        id: 34,
        name: "Studio",
        brand_id: 4
    },
    {
        id: 32,
        name: "Jamie Drake",
        brand_id: 4
    },
    {
        id: 31,
        name: "TA Studio 2",
        brand_id: 4
    },
    {
        id: 30,
        name: "TA Studio 1",
        brand_id: 4
    },
    {
        id: 29,
        name: "Vanucci Eclectics",
        brand_id: 4
    },
    {
        id: 28,
        name: "Iconic",
        brand_id: 4
    },
    {
        id: 27,
        name: "Michael Berman",
        brand_id: 4
    },
    {
        id: 26,
        name: "Oasis",
        brand_id: 4
    },
    {
        id: 25,
        name: "Keno Bors.",
        brand_id: 4
    },
    {
        id: 24,
        name: "Steve Leung",
        brand_id: 4
    },
    {
        id: 23,
        name: "Regency",
        brand_id: 1
    },
    {
        id: 22,
        name: "Cobblestone",
        brand_id: 1
    },
    {
        id: 21,
        name: "INTL-Composition",
        brand_id: 1
    },
    {
        id: 20,
        name: "Modern Uptown",
        brand_id: 1
    },
    {
        id: 19,
        name: "Caracole Upholstery",
        brand_id: 1
    },
    {
        id: 18,
        name: "Caracole Signature",
        brand_id: 1
    },
    {
        id: 17,
        name: "The Everly",
        brand_id: 1
    },
    {
        id: 16,
        name: "Fontainebleau",
        brand_id: 1
    },
    {
        id: 15,
        name: "Avondale",
        brand_id: 1
    },
    {
        id: 14,
        name: "Adela",
        brand_id: 1
    },
    {
        id: 13,
        name: "Yours Truly",
        brand_id: 1
    },
    {
        id: 12,
        name: "Signature Urban",
        brand_id: 1
    },
    {
        id: 11,
        name: "Signature Simpatico",
        brand_id: 1
    },
    {
        id: 10,
        name: "Signature Promethean",
        brand_id: 1
    },
    {
        id: 9,
        name: "Signature Debut",
        brand_id: 1
    },
    {
        id: 8,
        name: "Caracole Couture",
        brand_id: 1
    },
    {
        id: 7,
        name: "Modern Streamline",
        brand_id: 1
    },
    {
        id: 6,
        name: "Modern Grace",
        brand_id: 1
    },
    {
        id: 5,
        name: "Modern Fusion",
        brand_id: 1
    },
    {
        id: 4,
        name: "Modern Edge",
        brand_id: 1
    },
    {
        id: 3,
        name: "Modern Remix",
        brand_id: 1
    },
    {
        id: 2,
        name: "Modern Artisan",
        brand_id: 1
    },
    {
        id: 1,
        name: "Caracole Classic",
        brand_id: 1
    }
]