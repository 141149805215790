<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsCustomWindow ref="window" title="Option" windowClass="collection height-auto" scrollClass='collection height-auto' :height="300" :width="500" @dialog-state="dialogState" :group="group">
           <div class="scrollbar pt-3">
               <div class="container">
                  <ThatsMoney :money="moneyOption" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0 text-right" :inputclass="['text-right','pl-1']" label="Doc Total" vid="docTotal" name="docTotal" v-model="form.DocTotal" size="sm" :disabled="true"/>
                  <ThatsMoney :money="moneyOption" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0 text-right" :inputclass="['text-right','pl-1']" label="Total Volume" vid="totalVolume" name="totalVolume" v-model="form.TotalVolume" size="sm" :disabled="true"/>
                  <ChargeInput @input="fnChargeInput" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" :label="form.applyTo.name" vid="chargeField" name="chargeField" v-model="form.Option" size="sm" :disabled="true" :totalAmount="form.DocTotal"/>
                  <ThatsMoney :money="{ decimal: '.', thousands: ',', precision: 2, masked: false, suffix : ' QAR' }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Exchange Rate" vid="rateInput" name="rateInput" v-model="form.Option.rate" size="sm" :disabled="false"/>
                  <ThatsMoney :money="{ decimal: '.', thousands: ',', precision: 2, masked: false, suffix : ' QAR' }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Value" vid="amountInput" name="amountInput" v-model="form.Option.value" size="sm" :disabled="true"/>
                </div>


        </div>     
        <mdb-container>
            <div class="d-flex">
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Apply</mdb-btn>
                  <mdb-btn color="grey" size="sm" @click="closeDialog">Cancel</mdb-btn>
                </template>
            </div>
        </mdb-container>
       </ThatsCustomWindow>
    </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbInput} from "mdbvue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsInput, ThatsMoney,ThatsFieldset, ThatsCustomWindow} from '../../components/thats/Thats'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import AmountPercentInput from '../../components/thats/AmountPercentInput.vue'
import ExchangeRateInput from '../../components/thats/ExchangeRateInput.vue'
import ChargeInput from '../../components/thats/ChargeInput.vue'
export default {
  props : {
    group : {
      type : Number,
      default : 1
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbInput,
        ThatsMoney,
        ValidationProvider,
        ValidationObserver,
        ThatsInput,
        ThatsFieldset,
        ThatsCustomWindow,
        AmountPercentInput,
        ExchangeRateInput,
        ChargeInput

  },

    data : () => ({
      active : 0,
      disabled : false,
      validated : false,
      formOriginal : null,
      form : {
        DocTotal : 0,
        TotalVolume : 0,
        applyTo : {
            code : null,
            name : null
        },
        Option : {

            foreign_currency :  'QAR',
            amount : 0,
            currency :  'QAR',
            rate : 1,
            value : 0,
        }
          
      },
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      index : null,
      demo : 'discount',
        moneyOption : {
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    suffix: '',
                    precision: 2,
                    masked: false
                }

    }),

    created : function() {
      var self = this;
    //   this.form = this.formVar();
      // this.optionsHandler();
      //  this.isGeneralManager();

      
    },



    methods: {
     
      /* start */

      optionsHandler(){

      },

      /* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      
      onSubmitForm(){
        this.$emit('apply',{applyTo : this.form.applyTo, Option : this.form.Option})
        this.closeDialog();
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        // this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false


      },
      

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

      },

    formatNumber(n) {
        return formatMoney(n, { symbol: "", precision: 2, thousand: ",", decimal: "." });
    },

    fnChargeInput(v){
      // console.log("chargeInput")
      // console.log(v)
      const amount = unformat(v.amount);
      const rate = unformat(v.rate);
        if(amount > 0 && rate > 0){
              this.form.Option.value = amount * rate;
        }
    }

    },

   computed: {

      ...mapGetters('ApplyAmountModule',['formData']),
    },

    watch: {
        'formData' : function(v){
            console.log(v)
            this.form = v
        },

        
    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>