<template>
  <ValidationObserver ref="observer" v-slot="{ passes}">
    <form class="needs-validation" v-on:submit.prevent="demo">
    <Auth>
      <template v-if="errorMessage !== null">
        <p class="small red-text mb-4" v-text="errorMessage"></p>
      </template>

      <template v-if="!chooseLocation">
          <validation-provider rules="required">
            <mdb-input v-model="form.email" label="Email" class="mb-1" autocomplete="off" required @keyup.native.enter="passes(onSubmitForm)"  @keydown.native="getLocation" />
          </validation-provider>

          <validation-provider rules="required">
            <mdb-input v-model="form.password" label="Password" class="mb-1" type="password" @keyup.native.enter="passes(onSubmitForm)"/>
          </validation-provider>
      
          <!-- <validation-provider rules="required">
            <mdb-select v-model="showrooms" placeholder="Select location" label="Location" @getValue="getSelectValue" />
          </validation-provider> -->
            <div class="d-flex justify-content-between align-items-center">
              <template v-if="Loading">
                <mdb-btn color="elegant" size="sm" rounded disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template>
              <template v-else>
                <mdb-btn color="elegant" rounded size="sm" @click="passes(onSubmitForm)">Login</mdb-btn>
              </template>
              <router-link to="/forgot-password" class="card-link">Forgot Password</router-link>
            </div>
        </template>

        <template v-else>
         <div class="row">
           <div class="col-12 text-center">
              <template v-if="user !== null">
                <div class="block"><el-avatar :size="50" :src="circleUrl"></el-avatar></div>

                <validation-provider rules="required">
                  <label class="mb-0">Please select location</label>
                  <mdb-select v-model="filterShowrooms" @getValue="getSelectValue" placeholder="Select Location" />
                </validation-provider>

                  <div class="d-flex justify-content-between align-items-center">
                    <template v-if="Loading">
                      <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </mdb-btn>
                    </template>
                    <template v-else>
                      <mdb-btn color="elegant" rounded size="sm" @click="passes(onSubmitForm)">Select</mdb-btn>
                    </template>
                  </div>
              </template>
           </div>
         </div>
        </template>
    </Auth>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import Auth from './Auth';
import { mapActions, mapGetters } from 'vuex'
import {
  mdbView,
  mdbMask,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbIcon,
  mdbNavbarBrand,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbContainer,
  mdbRow,

  mdbSelect,
  mdbCardHeader,
  mdbCardTitle,
  mdbCardText,
  mdbCardFooter
} from "mdbvue";

export default {
    components: {
    mdbView,
    mdbMask,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbIcon,
    mdbNavbarBrand,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbContainer,
    mdbRow,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardText,
    mdbCardFooter,
    Auth,
    mdbSelect,
    ValidationObserver,
    ValidationProvider
  },
    data : () => ({
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
        chooseLocation : false,
        validated : false,
        showrooms : [],
        filterShowrooms : [],
        form : {
          email : null,
          password : null,
          showroom : null,
        },
        errorMessage : null,
        countDown : 1000,
        timer : null,
        user : null,
    }),
    created(){
        const self = this;
        self.GetShowrooms().then((response) =>{
            if(response.length > 0){
              response.forEach((element,index) => {
                const d = {
                  text : element.label,
                  value : element.value,
                  selected : false,
                }

                if(index === 0){
                  d.selected = true
                }

                self.showrooms.push(d)

                
              })
            }
        })
    },

    mounted(){
        // const tokenLocalStorage = JSON.parse(localStorage.getItem('user'));
        //this.user = tokenLocalStorage.user
        //  console.log(tokenLocalStorage)
    },
    methods : {
      ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
      ...mapActions('AuthModule',[
        'login'
      ]),
      countDownTimer(str) {
        const self = this;
          if(this.countDown > 0) {
              setTimeout(() => {
                  this.countDown -= 1
                  self.errorMessage = str.replace(":seconds", this.countDown);
                  self.timer = this.countDownTimer(str)
              }, 1000)
          }
      },
      async onSubmitForm(event) {
            var self =this
            this.validated = true
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
              if(this.Loading === false){

                // console.log("Login")
                // console.log(this.form)
                self.login(this.form).then((response) => {

                    self.chooseLocation = true;
                    self.errorMessage = null
                    self.user = response
                    if(response.location ===null && response.user.attr.location.length > 0){
                        const availableLocation = self.showrooms.filter((loc)=>{
                          return response.user.attr.location.includes(loc.value);
                        }).map((item,index) => {
                          return {...item,selected : (index === 0 ? true : false)}
                        })

                      self.form.showroom = availableLocation[0].value
                      self.filterShowrooms = availableLocation;
                      
                    }

                    


                }).catch((error) => {
                  if(error.response.hasOwnProperty('status') && error.response.status === 401){
                    self.errorMessage = error.response.data.message;
                  }
                  
                  if(error.response.hasOwnProperty('status') && error.response.status === 429){
                    self.errorMessage = error.response.data.message;
                  }
                  
                })
              }
            }else{

            }
        },

        getSelectValue(v){
          this.form.showroom = v
        },

        getLocation(){
          if(this.validateEmail(this.form.email)){
            // console.log("valid")
          }
        },
         validateEmail(email) {
          const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return re.test(String(email).toLowerCase());
      }
    },
    computed: {
      ...mapGetters('AuthModule',
      [
        'Loading'
      ])
    },

}
</script>

<style scoped>
/* .was-validated .form-control:invalid, .form-control.is-invalid */
</style>