<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="User Registration" windowClass="height-auto" scrollClass='height-auto' @deleteYes="emitDelete" :height="400" :width="600" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-4">

              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile" vid="mobile" name="mobile" v-model="form.attr.mobile" :validated="validated" :rules="''" size="sm" />
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Title/Position" vid="designation" name="designation" v-model="form.attr.designation" :validated="validated" :rules="''" size="sm" />
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Employee Code" vid="employee_code" name="employee_code" v-model="form.attr.employee_code" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Email" vid="email" name="email" v-model="form.email" :validated="validated" :rules="'required|email'" size="sm" :show_error="true"/>
              <template v-if="FormStatus === 'create' || resetPassword === true">
                <ThatsInput labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Password" vid="password" name="password" v-model="form.password" :validated="validated" :rules="editPassword" size="sm" />
              </template>
              <template v-if="FormStatus === 'update' && resetPassword === false">
                <mdb-row>
                    <mdb-col col="9" offset="3" class="pl-0">
                      <mdb-btn color="elegant" size="sm" @click="resetPassword = true; form.change_password = true">Reset Password</mdb-btn>
                    </mdb-col>
                </mdb-row>
              </template>
              <template v-if="FormStatus === 'update' && resetPassword === true">
                <mdb-row>
                    <mdb-col col="9" offset="3" class="pl-0">
                      <p class="small mb-0">Please enter a new password</p>
                    </mdb-col>
                </mdb-row>
              </template>


                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Role" v-model="form.role_id" :reduce="label => label.code" :reduceLabel="'label'" :options="roles" :rules="'required'"></ThatsMultiSelect>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Location" v-model="form.attr.location" :reduce="label => label.value" :reduceLabel="'label'" :options="showrooms" :rules="''"></ThatsMultiSelect>

                    <mdb-row>
                      <mdb-col col="9" offset="3" class="pl-0">

                        <mdb-btn color="elegant" size="sm" @click="allLocation">All Location</mdb-btn>
                        <mdb-btn color="elegant" size="sm" @click="clearLocation">Clear Location</mdb-btn>
                      </mdb-col>
                    </mdb-row>


                    <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Active'" vid="isActive" orientation="inline" name="isActive" :data="[]" v-model="form.active" :validated="validated" :rules="''" size="sm" :multiple="false"/>

              </mdbContainer>
       </ThatsWindow>

          </ValidationObserver>

      <GalleryManager ref="gallery" :data="form.images" :group="group2"></GalleryManager>
      <ImageGrid ref="upload" @select="fnImageSelect" :moduleForm="'item-form'" :group="group2"></ImageGrid>


  </div>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon,
        mdbCarousel, mdbCard,
    mdbCardImage,} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsFloat,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsMultiSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GalleryManager from '../../components/image/GalleryManager';
import Autocomplete from '../../components/Autocomplete';

import { MessageBox } from 'element-ui';
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardImage,


        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsPriceInput,
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        Autocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsCheckbox,
        ThatsMultiSelect,

        ImageGrid,
        GalleryManager,

        ThatsFloat,


  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
      designation : [],
      roles : [],
     /*  showrooms : [], */
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
      }

       this.optionsHandler();

      this.filterItems().then((response) => {

        self.roles = response
      })
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */
      ...mapActions('Designation',{
        designationFilter : 'filterItems'
      }),
      ...mapActions('RoleModule',[
        'filterItems'
      ]),
      ...mapActions('UserModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
      ]),
      ...mapMutations('UserModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          name : "",
          email : "",
          password : "",
          confirm_password : "",
          role_id : 1,
          change_password : false,
          active : true,
          sales : false,
          change_password : false,
          attr : {
            mobile : null,
            designation : null,
            location : [],
            employee_code : null,
          },
          permission : [
            { name : "items",
              permission:{
                create : false,
                  update : false,
                  delete : false,
              }
            },
                { name : "quotation-sales",
                  permission:{
                      create : false,
                      update : false,
                      delete : false,
                  }
                },
          ]
        }
      },
        /* start */

        onSelect(e,r){

          this.form.images.forEach((img,i) => {
              if(r.token_id === img.token_id){
                  this.form.images[i].default = 1
              }else{
                this.form.images[i].default  = 0
              }
          })
        },
        openGalleryManager(){
        this.$refs.gallery.openDialog()
        },
        openDialog(){
                this.$refs.window.openDialog();
        },
        optionsHandler(){
          var self = this;
        },
        uploadImage(){
          this.$refs.upload.openDialog();
        },
        nextCode(){
          var self = this;
          /* this.GetNextCode().then((response) => {
            self.form.item_code = response.code
          }); */
        },
        fnImageSelect(v){

          if(v.length > 0){
            v.forEach((r,i) => {
              const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
              if(i === 0){
                d.default = 1;
              }
                this.form.images.push(d)
            })

          }
        },

        /* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.resetPassword = false
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      allLocation(){

          const locations = this.showrooms.map((i) => i.value);

          this.form.attr.location = locations
      },
      clearLocation(){

          this.form.attr.location = []
      }

    },

    computed: {

      ...mapGetters('UserModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      editPassword  : function(){
          if(this.FormStatus == 'update' && this.resetPassword !== true){
              return '';
          }
        return 'required';
      },

      showrooms : function(){
        return this.showroomsProvider();
      }

    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          // this.form = this.FormRecord.row;
           const frm = JSON.parse(JSON.stringify(this.FormRecord.row));
            if(frm.roles.length > 0){
               frm.role_id = frm.roles[0].id
            }

            frm.attr = {...this.form.attr,...frm.attr}

            this.form = {...this.form,...frm}

          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>
