<template>
    <div>
         <ThatsTable title="Transactions" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm" @viewRelatedDocument="viewRelatedDocument">
<!--             <template #filter="{}">-->
<!--                 <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customer_name" name="customer_name" v-model="filterForm.customer_name" size="sm" :disabled="false" :rules="''"/>-->
<!--                 <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile No." placeholder="Mobile No." vid="mobile" name="mobile" v-model="filterForm.mobile" size="sm" :disabled="false" :rules="''"/>-->
<!--                 <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Code" placeholder="Transaction Code" vid="code" name="code" v-model="filterForm.code" size="sm" :disabled="false" :rules="''"/>-->
<!--                 <ThatsDatePicker   tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Start Date" placeholder="Start Date" vid="from" name="from" v-model="filterForm.start_date" size="sm" :disabled="false" :rules="''"/>-->
<!--                 <ThatsDatePicker  tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="End Date" placeholder="End Date" vid="to" name="to" v-model="filterForm.end_date" size="sm" :disabled="false" :rules="''"/>-->


<!--                 <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Tiers" v-model="filterForm.tiers" :reduce="label => label.id" :reduceLabel="'label'" :options="tiers" :rules="''"></ThatsMultiSelect>-->
<!--                 <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Actions" v-model="filterForm.action" :reduce="label => label.value" :reduceLabel="'label'" :options="actions" :rules="''"></ThatsMultiSelect>-->
<!--             </template>-->
        </ThatsTable>
        <CustomerLogForm ref="dialogForm" @success="refreshTable"></CustomerLogForm>
        <CustomerView ref="viewCustomerLogs" @success="refreshTable"></CustomerView>
        <CustomerLogView ref="customerLogView" @success="refreshTable"></CustomerLogView>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput, ThatsAutocomplete} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { Loading } from 'element-ui';
import CustomerLogForm from "./CustomerLogForm.vue";
import CustomerView from "./CustomerView.vue";
import CustomerLogView from "./CustomerLogView.vue";

export default {
        components:{
            CustomerLogView,
            CustomerView,
            CustomerLogForm,
        ThatsInput,
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput,
        ThatsAutocomplete,
    },

    provide(){
        return {

        }
    },
    data(){
        return {
            endpoint : 'customer-logs/data',
            viewTransactionLoading : 0,
            tableKey : 0,
            filterForm : {
                customer_name : null,
                mobile : null,
                whatsapp : null,
                location : "",
                sales_person : "",
                brand : [],
                category : [],
            },
            area : [],
            tiers : [],
            category : [],
            collection : [],
            actions:[
                { label : "Payment", value : "payment"},
                { label : "Downgrade", value : "downgrade"},
                { label : "Reversal", value : "reversal"},
                { label : "System", value : "system"},
                { label : "Refund", value : "refund"},
            ]
        }
    },
    created(){
        this.asyncGetData().then((res) => {
            this.tiers = res;
        })
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {
        // ...mapActions('TireModule',[
        //     'asyncGetData'
        // ]),
        ...mapActions('CustomerLogsModule',['asyncGetData']),
        ...mapMutations('CustomerLogsModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormStatusView','setFormRecord']),


        rowClick(row){
            const self = this;

            const mergeFormData = {...JSON.parse(JSON.stringify(row))}

           /*  const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            }); */
            self.setFormRecord(mergeFormData);
            self.setFormStatusView();
            // this.asyncGetData(mergeFormData).then((res) => {
            // loading.close();
            // this.$refs.dialogForm.openDialog(mergeFormData);
            this.$refs.dialogForm.openDialog();
                // this.$refs.viewCustomerLogs.openDialog(mergeFormData);
            // })
        },
        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        viewMember(row){
            this.$refs.dialogForm.closeDialog()
            this.rowClick(row)
        },

        viewRelatedDocument(obj){

            if(obj.reference == 'orderQty'){
                this.$refs.relatedDocumentDialogPurchaseOrder.triggerGetTransaction(obj.row)
            }
            if(obj.reference == 'incomingQty'){
                this.$refs.relatedDocumentDialogIncomingContainer.triggerGetTransaction(obj.row)
            }
            if(obj.reference == 'stock'){
                this.$refs.relatedDocumentDialogHistory.triggerGetTransaction(obj.row)
            }

        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.dialogForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()

        },

        clearFilterForm(){
            this.filterForm = {
                customer_name : null,
                mobile : null,
                whatsapp : null,
                location : "",
                sales_person : "",
                brand : [],
                category : [],
            }
        },


    },
    computed: {

        ...mapGetters('CustomerLogsModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ])

    },

    watch : {
        categoryComputed : function(value){
          console.log("watch category")
          console.log(value)
          this.category = value
      },
      areaComputed : function(value){
          console.log("watch area")
          console.log(value)
          this.area = value
      },
      collectionComputed : function(value){
          console.log("watch collectionComputed")
          console.log(value)
          this.collections = value
      }
    }
}


</script>
