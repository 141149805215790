<template>
    <div class="table-container pr-0 pl-0">
        <div class="p-0 pt-1 pb-1 header-panel-container">
            <mdb-navbar class="z-depth-0 p-0 pl-1 rounded-sm justify-content-between">
                <mdb-form-inline class="ml-auto" tag="div">

                <el-input
                    @keydown.enter.prevent="handleSearchTerm"
                    class="table-searchterm-input"
                    placeholder="Search"
                    v-model="term"
                    clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                </el-input>
                <mdb-btn color="elegant" size="sm" @click="handleSearchTerm"><i class="el-icon-search"></i> Search</mdb-btn>
                <template v-if="hasButtonRightSlot">
                    <slot name="button-right"></slot>
                </template>
            </mdb-form-inline>


            </mdb-navbar>
        </div>
        <el-table ref="multipleTable" lazy class="custom-element-table" :data="rows" :height="tableHeight" stripe>
            <template v-for="col in columns">
                <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                    <template slot-scope="scope">
                        <template v-if="col.clickable">
                            <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                        </template>
                        <template v-else-if="col.field === 'print' && scope.row['status'] !== 'cancel' && scope.row['status'] !== 'closed'">
                                <a href="javascript:void(0)" class="btn btn-sm btn-elegant" @click="handleClickPrint(scope.$index, scope.row)">Print</a>
                            </template>
                        <template v-else>
                            <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                        </template>
                    </template>
                </el-table-column>
            </template>

            <template slot="empty">
                <template v-if="loading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading data...
                    </mdb-btn>

                </template>
                <template v-else>
                    No Data
                </template>
            </template>
        </el-table>

        <div class="d-flex">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="current_page"
                :page-sizes="[25,50,100, 200, 300, 400]"
                :page-size="length"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>


</template>


<script>
import {
  mdbBtn,
  mdbContainer,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbNavbar,
  mdbFormInline

} from "mdbvue";
import { mapGetters, mapActions, mapMutations  } from 'vuex';
export default {
    name : "InvoiceTable",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbNavbar,
        mdbFormInline
    },

    props : {

        endpoint : {
            type : String,
            default : ''
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,
            tableHeight : 'calc(100vh - 328px)'
        }
    },
    methods: {
        ...mapActions('ContactInvoicesTableModule',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('ContactInvoicesTableModule',[
            'setLength',
            'setPage',
            'setSearchTerm',
            'setSelection',
            'setEmptyData',
            'setFilters',
        ]),

        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            this.$emit('delete')
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        },
        handleClick(index,row){

            this.$emit('open',{value : row, index : index});
        },
        handleSelectionChange(v){
            this.selected = v.map(i => i.series)
        },
        mergeDialog(){
            this.$emit('merge',this.selected);
        },

        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },

        clearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        refreshTable(){
            this.resetData(this.endpoint);
        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },

        handleClickPrint(index,row){
            this.$emit('print',{value : row, index : index, origin : 'invoice'});
        },

         handleSearchTerm() {
            this.dispatchFilter({
                ...this.filters,
                searchTerm : this.term
            })
        },

        dispatchFilter(filters){
            this.setFilters(filters)
             this.resetData(`invoices`).then(() => this.loading = false);
        },
    },

    computed : {
        ...mapGetters('ContactInvoicesTableModule',['rows','loading','selection','searchTerm','length','total','page','filters']),
        ...mapGetters('TransactionModule',['columns']),
        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.setSearchTerm (val)
            }
        },
        selected : {
            get : function(){
               return this.selection
            },
            set : function(val){
                this.setSelection (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },
        hasButtonRightSlot() {
            return ('button-right' in this.$scopedSlots);
        },
    },

}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }

</style>
