<template>
  <div
    :class="textareaClass"
    contenteditable="true"
    ref="content"
    @click="getState"
    @input="input"
  >
    <slot />
  </div>
</template>

<script>
const Entities = require('html-entities').AllHtmlEntities
const entities = new Entities()
const beautify = require('js-beautify').html;

const mdbWysiwygTextarea = {
  name: 'mdbWysiwygTextarea',
  props: {
    activeView: {
      type: String,
      default: 'html'
    }
  },
  computed: {
    textareaClass() {
      return [
        'mdb-wysiwyg-textarea',
        this.view !== 'html' && 'show-raw'
      ]
    }
  },
  methods: {
    getState() {
      document.queryCommandState('bold') === true ? this.$emit('state', 'bold', true) : this.$emit('state', 'bold', false)
      document.queryCommandState('italic') === true ? this.$emit('state', 'italic', true) : this.$emit('state', 'italic', false)
      document.queryCommandState('strikeThrough') === true ? this.$emit('state', 'strikeThrough', true) : this.$emit('state', 'strikeThrough', false)
      document.queryCommandState('underline') === true ? this.$emit('state', 'underline', true) : this.$emit('state', 'underline', false)
      document.queryCommandState('justifyLeft') === true ? this.$emit('state', 'justifyLeft', true) : this.$emit('state', 'justifyLeft', false)
      document.queryCommandState('justifyCenter') === true ? this.$emit('state', 'justifyCenter', true) : this.$emit('state', 'justifyCenter', false)
      document.queryCommandState('justifyRight') === true ? this.$emit('state', 'justifyRight', true) : this.$emit('state', 'justifyRight', false)
      document.queryCommandState('justifyFull') === true ? this.$emit('state', 'justifyFull', true) : this.$emit('state', 'justifyFull', false)
      this.$emit('textState', document.queryCommandValue('formatBlock'))
    },
    input(e) {
      this.$emit('getContent', {html: e.target.innerHTML, text: e.target.innerText});
    }
  },
  data() {
    return {
      view: this.activeView,
      content: 'Example text'
    }
  },
  watch: {
    activeView(newVal) {
      this.view = newVal
      if (newVal === 'html') {
        this.$refs.content.innerHTML = entities.decode(this.$refs.content.innerHTML)
      } else {
        this.$refs.content.innerHTML = entities.encode(beautify(this.$refs.content.innerHTML))
      }
    },
  },
}

export default mdbWysiwygTextarea;
export { mdbWysiwygTextarea as mdbWysiwygTextarea };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mdb-wysiwyg-textarea {
  display: block;
  padding: 15px;
  width: 100%;
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
  border: 0;
  outline: none;
  resize: none;
  background: #fff;
}
.mdb-wysiwyg-textarea.show-raw {
  white-space: pre-line;
}
</style>
