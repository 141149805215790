<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm">  
            <template #filter="{}">
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="kerwords" vid="searchTerm" name="searchTerm" v-model="filterForm.searchTerm" size="sm" :disabled="false" :rules="''"/>
    
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="docStatus" :rules="''"></ThatsMultiSelect>
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Type" v-model="filterForm.type" :reduce="label => label.value" :reduceLabel="'label'" :options="docType" :rules="''"></ThatsMultiSelect>

     
            </template> 

            <template #custom-right-culumns="{}">
                <el-table-column :sortable="false" :prop="'id'" label="PROMOTION">
                    <template slot-scope="scope">
                        {{ scope.row.name }}
                    </template>
                </el-table-column>


            
                <el-table-column :sortable="false" :prop="'id'" label="START" width="250">
                    <template slot-scope="scope">
                        {{ scope.row.promo_start }}
                    </template>
                </el-table-column>

                <el-table-column :sortable="false" :prop="'id'" label="END" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.promo_end}}
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150">
                    <template slot-scope="scope">
                        
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ statusArrayText[scope.row.status] }}</mdb-badge>
                        
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" :prop="'id'" label="Type" width="160">
                    <template slot-scope="scope">
                        
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.type]"><i :class="statusArrayIcon[scope.row.type]"></i> {{ typeArrayText[scope.row.type] }}</mdb-badge>
                        
                    </template>
                </el-table-column>
               

            </template>

        </ThatsTable>
        <ItemPromotionForm ref="moduleForm" @success="refreshTable"></ItemPromotionForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import ItemPromotionForm from './Form.vue'

export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,ItemPromotionForm,
        mdbSelect,
        mdbInput,
        ThatsInput
    },
    inject : [],
    provide(){
        return {}
    },
    data(){
        return {
            endpoint : 'item-promotion',
            approver : [],
            receiver : [],
            tableKey : 0,
            filterForm : {
                date : '',
                searchTerm : null,
                location : null,
                approvers : null,
                receiver : null,
                status : null,
                type : null,
            },
            docStatus : [
                {value : 'draft', label : 'Draft'},
                {value : 'active', label : 'Active'},
                {value : 'inactive', label : 'Inactive'},
            ],

            docType : [
                {value : 'public', label : 'Public'},
                {value : 'silent', label : 'Silent'},
            ],

            
        }
    },


    created(){

      const self = this;
      
    },

    mounted(){
        const self = this;

        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('ItemPromotionModule',['getRecord','getReceiver']),
       ...mapMutations('ItemPromotionModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),

        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
            this.getRecord(row).then(() => {
                loading.close();
                self.$refs.moduleForm.openDialog();
            })
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.moduleForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      clearFilterForm(){
        this.filterForm = JSON.parse(JSON.stringify(
            {
                date : '',
                searchTerm : null,
                location : null,
                approvers : null,
                receiver : null,
                status : null,
            }
        ))
      }
    },
    computed: {
        ...mapGetters('ItemPromotionModule',['getLoading', 'columns','initialForm']),


        statusArrayText : function(){
        
            return {'draft' : 'Draft','inactive' : 'Inactive','active': 'Active','expired' : "Expired",'cancelled' : 'Cancelled',};
        },
        typeArrayText : function(){
        
            return {'silent' : 'Silent Discount','public' : 'Public Promotion'};
        },
        statusArrayColor : function(){
        
            return {'draft' : 'badge-light','inactive' : 'warning','active': 'success','expired' : 'warning','cancelled' : 'warning','silent': 'warning','public': 'success'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','inactive' : 'el-icon-circle-check','active': 'el-icon-success','expired' : 'el-icon-more','cancelled' : 'el-icon-more','silent': 'el-icon-circle-check','public': 'el-icon-circle-check'};
        },
    },
}

</script>