import { ApiService } from '../../services'

export const PaymentModule = {
    namespaced : true,
    state : {
        paymentForm : {
            series : 0,
            id : null,
            date : "",
            mode : "full-payment",
            method : "cash",
            customer : "",
            attr : {
              cheque_details : null, 
              payment_reference : null,
              discount_remarks : null, 
            },
            description : "",
            canceled : "no",
            amount : 0,
            reference : null,
            transaction_id : null,
            invoice : {
              series : null,
              balance : 0,
              paid : 0,
              amount : 0,
            },
            status : 'closed'
          },
          paymentLoading : false,
          formStatus : 'update'
    },

    mutations : {
        setFormData : (state, payload) => state.paymentForm = payload,
        loadingTrue : (state) => state.paymentLoading = true,
        loadingFalse : (state) => state.paymentLoading = false,
        formCreate : state => state.formStatus = 'create',
        formUpdate : state => state.formStatus = 'update'
    },

    actions : {
        update : function({state,commit},payload){
            if(!state.paymentLoading){
                commit('loadingTrue');
                ApiService.update(payload).then((res) => {
                        resolve(res)
                        commit('loadingFalse')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                })
            }
        }
    },

    getters : {
        paymentForm : state => state.paymentForm,
        formStatus : state => state.formStatus
    }

}

export default PaymentModule