<template>
    <div style="width:100vw;background-color:#f8f8f8">
        <router-view />
    </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    name: 'Guest',

    computed : {
        ...mapGetters('AppModule',['modal'])
    }
}
</script>