<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'Customer Logs View'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="40%" class="text-left tier-beneficiary-dialog" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-3">


                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :show_error="true" disabled/>
                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile" vid="mobile" name="mobile" v-model="form.mobile"  disabled/>
                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Whatsapp Number" vid="mobile" name="mobile" v-model="form.whatsapp" disabled/>

<!--                <ValidationProvider vid="attr.contacts.0.value" name="attr.contacts.0.value" :rules="'required'" v-slot="{errors}">-->
<!--                    <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Mobile" vid="attr.contacts.0.valuex" name="attr.contacts.0.valuex" v-model="form.attr.contacts[0]" :rules="'required'" :show_error="true" disabled></InternationalMobile>-->
<!--                    <template v-if="errors.length > 0">-->
<!--                        <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>-->
<!--                    </template>-->
<!--                </ValidationProvider>-->
<!--                <ValidationProvider vid="attr.contacts.1.value" name="attr.contacts.1.value" :rules="''" v-slot="{errors}">-->
<!--                    <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Whatsapp" vid="attr.contacts.1.value" name="attr.contacts.1.value" v-model="form.attr.contacts[1]" :rules="'required'" :show_error="true" disabled></InternationalMobile>-->

<!--                    <template v-if="errors.length > 0">-->
<!--                        <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>-->
<!--                    </template>-->
<!--                </ValidationProvider>-->

<!--                <div class="custom-control custom-checkbox" style="margin-left: 168px;">-->
<!--                    <input type="checkbox" class="custom-control-input" id="defaultChecked3" v-model="form.sameAsMobile" @change="copyMobileToWhatsApp">-->
<!--                    <label class="custom-control-label" for="defaultChecked3">Same as Mobile</label>-->
<!--                </div>-->
                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Email" vid="email" name="email" v-model="form.email" :validated="validated"  size="sm" :show_error="true" disabled/>


                <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brand" v-model="form.brand" :reduce="label => label.value" :reduceLabel="'label'" :options="brands" :rules="''" disabled></ThatsMultiSelect>
                <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Category" v-model="form.category" :reduce="label => label.value" :reduceLabel="'label'" :options="categories" :rules="''" disabled></ThatsMultiSelect>
                <ThatsMultiSelect :clearable="false" :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Location" v-model="form.location" :reduce="label => label.value" :reduceLabel="'label'" :options="showrooms" :rules="''" disabled></ThatsMultiSelect>

                <ThatsMultiSelect labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sales Person" :clearable="false" :multiple="false" :showLabel="true"  v-model="form.sold_by" :reduce="label => label.code" :reduceLabel="'label'" :options="partnerSalesPerson" :rules="''" :disabled="true"></ThatsMultiSelect>

                <ThatsMultiSelect placeholder="Reminder Date" :clearable="false" :multiple="false"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0"  label="Reminder Day" v-model="form.reminder_date" :reduce="label => label.code" :reduceLabel="'label'" :options="reminderDate" :rules="''" disabled></ThatsMultiSelect>
                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Reminder Time" vid="time" name="time" v-model="form.reminder_time" :validated="validated"  size="sm" :show_error="true" disabled/>


                <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-sm-3'" :fieldClass="'col-sm-9  pl-0'" label="Comments" placeholder="Comments" vid="description" name="description" v-model="form.comments" size="sm" :rules="''" disabled/>



            </mdbContainer>


                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
<!--                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>-->
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>

          </ValidationObserver>




  </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsMultiSelect, ThatsTextarea, ThatsDatePickerPage} from '../../components/thats/Thats'
import {  mdbTimePicker,mdbDatePicker2,mdbRow,mdbCol } from 'mdbvue';
import InternationalMobile from "../../components/thats/InternationalMobile.vue";

export default {
    components: {
        InternationalMobile, ValidationProvider,
        ThatsTextarea,
        ThatsDatePickerPage,
        mdbContainer,
        mdbBtn,
        mdbTimePicker,
        mdbDatePicker2,
        ValidationObserver,
        ThatsInput,
        ThatsMultiSelect,
        mdbRow,
        mdbCol,

  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider','filteredCategoryProvider','brandProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
        categories : [],
        brands : [],
        partnerSalesPerson : [],
        validated: false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        reminderDate : [
            { label : "After 1 Day", code : "1day"},
            { label : "After 2 Day", code : "2day"},
            { label : "After 3 Day", code : "3day"},
            { label : "After 4 Day", code : "4day"},
            { label : "After 5 Day", code : "5day"},
            { label : "After 6 Day", code : "6day"},
            { label : "After 7 Day", code : "7day"},
        ],
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
    mounted() {
        this.getPartner().then((response) => {
            console.log(response,"partner")
            this.partnerSalesPerson = response
        })


    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */

      ...mapActions('CustomerLogsModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
        ...mapActions('TransactionModule',[
            'getPartner']),
      ...mapMutations('CustomerLogsModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
            name: '',
            mobile: '',
            whatsapp: '',
            location: '',
            reminder_date: '1day',
            reminder_time: '09:00am',
            comments: '',
            sold_by: '',
            sameAsMobile: false,
            brand : [],
            category : [],
            attr : {
                contacts: [
                    {
                        type: 'primary',
                        intl: false,
                        code: '+974',
                        value: null
                    },
                    {
                        type: 'secondary',
                        intl: false,
                        code: '+974',
                        value: null
                    },
                ]
            }
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },



        /* end */
        openDialog(data){
            this.syncModal = true
            this.getCategory();
            this.getBrand();
            const localStorageData = localStorage.getItem('user');

            console.log(data, "swaraj data")
            // Parse the JSON data if necessary
            const parsedData = JSON.parse(localStorageData);

            // Update the form with the default selectionss
            this.form.location = parsedData.location.id;
            this.form.sold_by = parsedData.user_id;

            if (this.formStatus == 'update'){
                this.form.name = data.name;

                this.form.email = data.email;
                this.form.attr = data.attr;
                this.form.attr = data.attr['requestAttr']
                this.form.comments = data.comments
                this.form.reminder_time = data.reminder_time
                this.form.whatsapp = data.whatsapp_mobile
                this.form.mobile = data.mobile;

                const reminderOption = this.reminderDate.find(option => option.code === data.reminder_date);

// Set the form.reminder_date to the label of the matched option, or an empty string if no match found
                this.form.reminder_date = reminderOption ? reminderOption.label : '';
                // Parse the string into an array of numbers
                const brandIds = JSON.parse(data.brand);

                // Map the brand IDs to their corresponding labels and values
                this.form.brand = brandIds.map(brandId => {
                    const brand = this.brands.find(brand => brand.value === brandId);
                    return brand ? { value: brand.value, label: brand.label } : null;
                });
                // Parse the string into an array of numbers
                const categoryIds = JSON.parse(data.category);

                // Map the category IDs to their corresponding labels and values
                this.form.category = categoryIds.map(categoryId => {
                    const category = this.categories.find(category => category.value === categoryId);
                    return category ? { value: category.value, label: category.label } : null;
                });




            }

        },
        getCategory() {
            var self = this;
            const categories = self.filteredCategoryProvider();

            const formattedCategories = categories.map(category => {
                return {
                    value: category.id,
                    label: category.name
                };
            });

            self.categories = formattedCategories;

            // Log the result for debugging
            console.log(formattedCategories, "Formatted Categories");
        },
        getBrand() {
            var self = this;
            const brands = self.brandProvider();

            const brandItems = brands.map(brand => {
                return {
                    value: brand.code,
                    label: brand.brand
                };
            });

            self.brands = brandItems;
            //
            // // Log the result for debugging
            console.log(brandItems, "Formatted brand");
        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        copyMobileToWhatsApp() {
            if (this.form.sameAsMobile) {
                this.$set(this.form.attr.contacts, 1, { ...this.form.attr.contacts[0] });
            } else {
                this.$set(this.form.attr.contacts, 1, {
                    type: 'secondary',
                    intl: false,
                    code: '+974',
                    value: null
                });
            }
        },






        onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {

      ...mapGetters('TierBeneficiaryModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
        ...mapGetters('CustomerLogsModule',
      [
        'formStatus'
      ]),

        whatsappNumber() {
            if (this.form.sameAsMobile) {
                console.log("Returning form.mobile as whatsappNumber:", this.form.mobile);
                return this.form.mobile;
            } else {
                console.log("Returning form.whatsapp as whatsappNumber:", this.form.whatsapp);
                return this.form.whatsapp;
            }
        },

        showrooms : function(){
            return this.showroomsProvider();
        }


    },

    watch: {
      FormRecord : function(){
        if(this.formStatus == 'update'){
           const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            this.form = {...this.form,...frm}
          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.formStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

        "form.attr.contacts" : {
            handler(v){
                // console.log("watch")
                // console.log(v)

                const firstObject  = v[0];

                if(firstObject){
                    this.form.mobile = firstObject.value
                }
            },
            deep : true
        },


    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}

</style>
