<template>
    <ui-window-metal>
        <ui-window v-bind:class="$attrs.windowClass" :title="$attrs.title" :resizable="false" :width="width" :height="height" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="closeButton" :zGroup="(group + 1)" :isMaximized="isMaximized" :maximizeTopOffset="0" @resize="onResize">
            <template slot="closeIcon">
                <i class="el-icon-close"></i>
            </template>
            <template slot="maximizedIcon">
                <i class="el-icon-full-screen"></i>
            </template>
            <template slot="restoreIcon">
                <i class="el-icon-copy-document"></i>
            </template>

            <div class="scrollbar" v-bind:class="$attrs.scrollClass">

                <slot></slot>
            </div>


            <div class="pl-3 pr-3 footer-window">
            <div class="d-flex align-items-center">
                <slot name="left-button"></slot>


                <template v-if="formStatus === 'update'">
                <!-- <mdb-btn color="grey" class="ml-0" size="sm" @click="emitCopy">Copy</mdb-btn> -->
                    <!-- <mdb-dropdown class="inactive-reactive ml-0 thats-dropdown">
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm">Inactive/Reactive</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="emitStatus('inactive')">Inactive</mdb-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-item @click="emitDelete">Delete</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> -->

                    <template v-if="showHistory">
                        <mdb-btn color="grey" class="ml-0" size="sm" @click="emitHistory">History</mdb-btn>
                    </template>
                </template>
                <mdb-btn color="grey" class="ml-0" size="sm" @click="dialogIsOpen = false"><i class="el-icon-close"></i> Close</mdb-btn>
                <slot name="right-button"></slot>
            </div>
        </div>

          <!--   <template v-if="windowFooter">
                 <div class="pl-3 pr-3 footer-window">
                    <div class="d-flex">
                        <slot name="winFooter"></slot>
                         <mdb-btn color="elegant" size="sm" class="mr-0" :icon="'file-alt'" @click="emitHistory" :disabled="!closeButton">History</mdb-btn>
                         <mdb-btn color="elegant" size="sm" :icon="'times'" @click="closeDialog" :disabled="!closeButton">Close</mdb-btn>
                    </div>
                 </div>
            </template> -->
        </ui-window>
    </ui-window-metal>
</template>


<script>
import { MessageBox } from 'element-ui';
import {
  mdbBtn,
  mdbContainer,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle

} from "mdbvue";
export default {
    name : "XWindow",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
        width : {
            type : Number,
            default : 800
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 250
        },
        maxHeight : {
            type : Number,
            default : 450
        },
        formStatus : {
            type : String,
            default : 'create'
        },
        group : {
            type : Number,
            default : 2
        },
        position : {
            type : String,
            default : 'center'
        },
        closeButton : {
            type : Boolean,
            default : true
        },
        isMaximized : {
            type : Boolean,
            default : false
        },
        showHistory : {
            type : Boolean,
            default : false
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0
        }
    },
    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },


        emitDelete(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes')
                    }).catch(() => {
                        self.$emit('deleteNo')
                    });
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        },
        onResize(v){
            this.$emit('onResize',v)
        },

        fnDialogIsOpen(v){
            console.log(v)
        }

    },
    beforeDestroy() {
      /*   this.x = ((window.innerWidth + 0) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2 */
    },
    beforeMount() {

       /*  this.x = ((window.innerWidth + 0) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2 */

    },
    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)
            this.x = 0
            this.y = 0
        },
    },

    computed : {
        windowFooter() {
            return ('winFooter' in this.$scopedSlots);
        },

        computedDialogIsOpen(){
            return this.dialogIsOpen
        }
    }
}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }



</style>
