<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">

        <div class="form-group input-group-sm row thats-form">
            
            <template v-if="showLabel">
                <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <money v-model="inputValue" v-bind="money" class="form-control form-control-sm null text-right" autocomplete="off" :disabled="disabled" :readOnly="readOnly"></money>

                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";

import Vue from 'vue'
import money from 'v-money'
 
// register directive v-money and component <money>
Vue.use(money, {precision: 4})
export default {
    name : 'ThatsMoney',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: [Number, String, null]
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        currency : {
            type : String,
            default : 'QAR'
        },
        disabled : {
            type : Boolean,
            default : false
        },
        readOnly : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        money : {
            type : Object,
            default : () => {
                return {
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    suffix: ' QAR',
                    precision: 2,
                    masked: false
                }
            }
        }

    },
    components : {
        ValidationProvider,
    },

    data : ()=>({
        inputValue : 0
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(errors){
            return (errors.length > 0) ? 'is-invalid' : null
        }
    },
    computed : {
        options : function(){
            const conf = {
                decimal: '.',
                thousands: ',',
                prefix: '',
                suffix: ' '+ this.currency,
                precision: 2,
                masked: false
            }
            return conf;
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            
            // const result = newVal.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // Vue.nextTick(() => this.inputValue = newVal);

            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value : function(v) {
            this.inputValue = (v == null) ? 0 : v;
            // Vue.nextTick(() => this.inputValue = v);
        }
    },
    created() {
        if (this.value) {
            this.inputValue = (this.value == null) ? 0 : this.value;
        }
    },
}

</script>