<template>
  <div class="update-payment-form-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm('save')">
       <ThatsCustomWindow ref="window" title="Payment Details" windowClass="collection height-auto" scrollClass='collection height-auto' :height="500" :width="800" @dialog-state="dialogState" :group="3">
           
           
            <mdbContainer class="mt-3">
            
                <mdb-row class="mb-2">
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Customer Name" :placeholder="form.customer" vid="name" name="name" v-model="form.customer" size="sm" :disabled="true" />
                      <!-- <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Mode of Payment" v-model="form.mode" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMode" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"  @select="getModeSelected($event)"></ThatsMultiSelect> -->
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Mode of Payment" v-model="form.mode" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMode" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"></ThatsMultiSelect>
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Payment Description" v-model="form.method" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"></ThatsMultiSelect>
                      <!-- <template v-if="form.method ==='cheque'">
                          <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Cheque Details" :placeholder="form.attr.cheque_details" vid="name" name="name" v-model="form.attr.cheque_details" size="sm"/>
                      </template> -->
                        <template v-if="$helpers.isSystemAdministrator()">
                      <ThatsMoney tabindex="4" :key="1"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated"></ThatsMoney>
                        </template>
                        <template v-else>
                      <ThatsMoney tabindex="4" :key="2"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated" :disabled="disableAmount"></ThatsMoney>
                        </template>

                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Payment Ref. No." vid="description" name="description" v-model="form.description" size="sm" :disabled="false" :rules="requiredIfCancel"/>            
<!-- 
                       <ThatsTextarea tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="remarks" vid="description" name="description" v-model="form.description" size="sm" :rules="requiredIfCancel"/> -->

          
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Sales Invoice ID" vid="invoice" name="invoice" v-model="form.invoice_series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Receipt ID" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="form.attr.discount_remarks" size="sm" :disabled="false"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Payment Ref. No." vid="payment_reference" name="payment_reference" v-model="form.attr.payment_reference" size="sm" :disabled="false"/>            
                  </mdb-col>    
                </mdb-row>
                <mdb-row >
                  <mdb-col col="9">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))" :disabled="disabledBtn">Save</mdb-btn>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save-print'))" :disabled="disabledBtn">Save & Print</mdb-btn>
                      </template>
                      
                  </mdb-col>    
                  <!-- <mdb-col col="3" class="text-right">
                      <template v-if="form.invoice !== null">
                          <template v-if="form.invoice.balance_float > 0">
                            <template v-if="formPayBalanceLoading">
                              <mdb-btn color="elegant" size="sm" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </mdb-btn>
                            </template>
                            <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('pay-balance'))">Pay Balance?</mdb-btn>
                            </template>                           
                          </template>
                      </template>
                  </mdb-col> -->    
                </mdb-row>          
              </mdbContainer>
       </ThatsCustomWindow>
          </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs} from "mdbvue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import ContactsForm from '../contacts/ContactsForm'
import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
export default {
    components: {
          ValidationProvider,
          mdbContainer,
          mdbBtn,
          mdbRow,
          mdbCol,  
          mdbIcon,
          mdbCard,
          mdbTabs,


          ValidationObserver,
          ThatsWindow, 
          ThatsInput,  
          ThatsSeriesInput,
          ThatsPriceInput,  
          ThatsFieldset,
          ThatsRadio,
          ThatsAutocomplete,
          ThatsAutosuggest,
          ThatsNumber,
          ThatsTextarea,
          ThatsDatePicker,
          ThatsMultiSelect,
          ThatsGridSelect,
          ThatsFile,
          ThatsBtn,
          ThatsCustomWindow,
          ThatsMoney,

          ContactsForm, 
          ThatsCustomMultiSelect, 
    },

    props : {
        data : {
            type : Object,
            default : function(){
              return {
                series : 0,
                date : "",
                mode : "full-payment",
                method : "cash",
                customer : "",
                attr : {
                  cheque_details : null, 
                  payment_reference : null,
                  discount_remarks : null, 
                },
                description : "",
                invoice_series : null,
                amount : 0,
                reference : null,
                transaction_id : null,
                invoice : {
                  series : null,
                  balance : 0,
                  paid : 0,
                  amount : 0,
                },
                
              }
            }
        },
        formStatus : {
            type : String,
            default : 'create'
        }
    },

    created(){
        const self = this;
        self.form = self.formVar();
        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.paymentMethod = settings.payment_method
    },

    data : () => ({
      active : 0,
      disabled : false,
      disabledBtn : false,
      validated : false,
      formOriginal : null,
      form : {
          series : 0,
          date : "",
          mode : "full-payment",
          method : "cash",
          customer : "",
          attr : {
            cheque_details : null, 
            payment_reference : null,
            discount_remarks : null, 
          },
          description : null,
          invoice_series : null,
          amount : 0,
          reference : null,
          transaction_id : null,
          invoice : {
             series : null,
             balance : 0,
             paid : 0,
             amount : 0,
          },
          
        },
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      formSavePrintLoading : false,
      formPayBalanceLoading : false,
      showError : false,
      paymentMethod : [],
      items : [],
      selected : [],
      customer : [],
      payment_transactions : [],
      transactions : {},
      


    }),

   


    methods: {

      ...mapActions('InvoiceModule',[
        'updatePayment',
      ]),

      formVar(){
        return {
          series : 0,
          date : "",
          mode : "full-payment",
          method : "cash",
          customer : "",
          attr : {
            cheque_details : null, 
            payment_reference : null, 
          },
          description : null,
          invoice_series : null,
          amount : 0,
          reference : null,
          transaction_id : null,
          invoice : {
             series : null,
             balance : 0,
             balance_float : 0,
             paid : 0,
             amount : 0,
          },
          
        }
      },

      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
        this.currentDateSetter();

      },
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      optionsHandler(){

      },
      onSubmitForm(action){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;

            console.log(isValid);

           
            if (isValid) {

              
                // if(action === 'save'){
                    if(self.formLoading === false){
                      self.formLoading = true

                      console.log(isValid);
               return false;
                        self.updatePayment(this.form).then((response) => {
                           self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                            self.$emit('success',self.form)
                            self.formLoading = false
                            self.disabledBtn = false
                            self.closeDialog();
                            if(action ==='save-print'){
                                 self.$emit('print',response.print)
                            }
                        })
                      }
                // }
                if(action ==='save-print'){
                    if(self.formSavePrintLoading === false){
                        self.formSavePrintLoading = true
                        self.updatePayment(this.form).then(() => {
                            self.formSavePrintLoading = false
                            self.disabledBtn = false
                        })

                        if(action ==='save-print'){
                          self.$emit('print',response.print)
                          /* var win = window.open(response.print, '_blank');
                          if (win.focus) {
                              win.focus();
                          } */
                        }
                    }
                }
               /* 

                
                if(action ==='pay-balance'){
                    if(self.formPayBalanceLoading === false){
                        self.formPayBalanceLoading = true
                        self.dispatchPayment(action).then(() => {
                            self.formPayBalanceLoading = false
                            self.disabledBtn = false
                        })
                    }
                }
 */


            
                    
                    
            }        
      },

      
 
    },

    computed: {
        disableAmount : function() {
          return (this.form.mode === 'full-payment') ? true : false
        },  
        paymentMode : function() {
          const {settings} = JSON.parse(localStorage.getItem('user'));
          let mode = settings.payment_mode
            if(this.form.invoice.series != null && this.form.invoice.balance_float > 0){
               mode = settings.payment_mode.filter(item => {
				        return item.code !== 'full-payment'
            })

            }
          return mode;
        },

        requiredIfCancel : function(){
          return (this.form.mode === 'cancelled') ? 'required' : ''
        }

      },

    watch: {
      data : function(v){
          const formData = {...this.formVar(),...v}
          formData.attr = {...this.form.attr,...formData.attr}
          this.form = formData
      },
    },
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>