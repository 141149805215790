export const LayoutModule = {
    namespaced: true,
    state: {
        layout: null
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload
        }
    },
    getters: {
        layout: state => state.layout
    }
}