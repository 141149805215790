<template>
    <div class="page-container">
        <ThatsTable title="Item Sales Report" ref="table" :multipleSelection="false" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :newBtn="false" :height="'calc(100vh - 165px)'">
            <template #tabs>
                <div style="width: 800px" class="p-1">
                    <div class="form-group input-group-sm row thats-form">
                            <label class="col-form-label col-sm-2">Select Date</label>
                            <div class="col-sm-10 pl-0">
                                <div class="d-flex flex-row">
                                    <date-range-picker v-model="form.date_range" :append-to-body="false" class="filter-date-range date-range-picker-small"></date-range-picker>
                                    <button class="btn btn-sm btn-elegant m-0 ml-1" style="width:80px" @click="generateReport" :disabled="tableLoading">Generate</button>
                                    <button class="btn btn-sm btn-elegant m-0 ml-1" style="width:130px" @click="exportReport" :disabled="tableLoading">Export to Xls</button>
                                    <button class="btn btn-sm btn-elegant m-0 ml-1" style="width:100px" @click="printReport" :disabled="tableLoading">Print</button>
                                </div>

                            </div>
                        </div>    
                </div>               
            </template>
            <template  #filter="{dispatchFilter}">
                <DeliveryFilter @filter="dispatchFilter" :tabIndex="active"></DeliveryFilter>
            </template>
            
        </ThatsTable>

        <DeliveryForm ref="dialogForm" @success="refreshTable" @delete="formDelete"  :group="3"></DeliveryForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import DeliveryForm from './DeliveryForm'

import ThatsTable from './Table'
import DeliveryFilter from './DeliveryFilter.vue'
import {mdbTabs, mdbRow, mdbCol} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
export default {
    data(){
         let endDate = new Date();
      endDate.setDate(endDate.getDate() + 6)

        return {
            // endpoint : 'delivery/data?status=for-delivery',    
            importData : {},        
            errorData : {},        
            importLoading : false,        
            active : 0,        
            activeTab : 0,        
            token : null,
            form : {
                date_range : ""
            },
        }
    },
    components:{

        DeliveryForm,
        ThatsTable,
        DeliveryFilter,
        mdbTabs,
        DateRangePicker, mdbRow, mdbCol
        

    },

    created(){
        this.currentDateSetter();

        this.SetFilters(this.form)
    },

    
    methods: {
        // 

        ...mapActions('TableV1Module',[
            'resetData',
        ]),
        ...mapMutations('TableV1Module',[
            'SetPage',
            'SetTerm',
            'SetFilters',
        ]),

        ...mapActions('DeliveryModule',[ 'DeleteRecord','saveImport' ,'updateStatus','getRecord','getPullOutRecord']),
        ...mapMutations('DeliveryModule',[ 'setFormRecord', 'setFormStatusUpdate','setTabIndex']),

        dateFormat (classes, date) {
            if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
            }
            return classes
        },

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

             

            const form  = {...obj.row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}           
            self.setFormStatusUpdate()
            self.setFormRecord(form)
            

            if(this.activeTab !=='pull-out'){
                self.getRecord({token_id : obj.row.token_id})
                self.openWindow()
            }

        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        getActiveTabIndex(index){


            const tabs = ['for-delivery','scheduled-for-delivery','tomorrow-delivery','pending-delivery','pull-out','cancel-re-schedule','delivered','pull-out','all']

            this.endpoint = 'delivery/data?status='+tabs[index];

            this.activeTab = tabs[index]

            this.setTabIndex(index)
        
            if(tabs[index] === 'for-delivery'){
                this.columns = this.columnOne;
            }
            if(tabs[index] !== 'for-delivery'){
                this.columns = this.columnTwo;
            }

            if(tabs[index] === 'all'){
                this.columns = this.columnAll;
            }

            if(tabs[index] === 'pull-out'){
                this.endpoint = 'delivery/pull-out';
            }
        
            this.SetFilters({})
            this.SetPage(1)
            this.resetData(this.endpoint);
        },


        updatePurchaseOrderStatus(event,v,row){
            var self = this;
            if(event.target.checked === true){
                
                MessageBox.confirm('Are you sure you want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = true

                    })
                return event.target.checked = false
            }

            if(event.target.checked === false){
                
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = false

                    })
                return event.target.checked = true
            }

            return event.target.checked = false
        /* const self = this;
        console.log(event.target.checked)
        this.$emit("input", false) */
            /* if(v){
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        console.log(event.target);
                        this.$emit("input", event.target.checked)

                    })
            }else{
                MessageBox.confirm('Are you sure want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                         const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)
                    })
            } */
        /* if(self.FormStatus == 'update'){
            MessageBox.confirm('Are you sure you want to change status?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {

                    const formData = { 
                    hash : self.form.token_id,
                    status : status
                    }
                    self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.closeDialog();
                        self.$emit('success')
                        self.FormResetter();
                        
                    }).catch((error) => {
                        self.formLoading = false
                        self.response.error = 1;
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){
                            self.$refs.observer.setErrors(error.response.data.errors);
                            this.showError = true
                            }
                        } else if (error.request) {
                            self.response.message = error.request
                        } else {
                            self.response.message = error.message
                        }
                        return;
                    })

                })
            
        } */
        },

        fnUpdateStatus(formData){
            var self = this;
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.refreshTable();
                    }).catch((error) => {
                        self.$ThatsNotify.success({
                        message : "Something wen't wrong"
                        })
                        self.refreshTable();
                    })
            
        },

        currentDateSetter(){
            let currentDate = new Date();
    //   endDate.setDate(endDate.getDate() + 6)

            this.form.date_range = {
                startDate: currentDate, 
                endDate: currentDate
            }
        },

        generateReport(){
            this.SetFilters(this.form)
            this.resetData(this.endpoint);
        },

        exportReport(){
            // var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
            //   const win = window.open(`reports/sales-report/print/${this.form.date_range.startDate}/${this.form.date_range.endDate}`, "_blank", strWindowFeatures);
            // win.document.title = 'Print';
            // win.print()

            // const date1 = this.form.date_range.startDate.toLocaleDateString();
            // const date2 = this.form.date_range.endDate.toLocaleDateString();

            var startDate = new Date(this.form.date_range.startDate).toISOString().slice(0, 10);
            var endDate = new Date(this.form.date_range.endDate).toISOString().slice(0, 10);



        /*     var datearray = date1.split("/");
            var startDate = datearray[2] + '-' + datearray[0] + '-' + datearray[1];
            var date2array = date2.split("/");
            var endDate = date2array[2] + '-' + date2array[0] + '-' + date2array[1]; */


            // var startDate = date1.replaceAll("/", "-");
            // var endDate = date2.replaceAll("/", "-");

            window.open(`reports/sales-report/print/${startDate}/${endDate}`,'_blank');
// window.open(url);
        },
        printReport(){
            const date1 = this.form.date_range.startDate.toLocaleDateString();
            const date2 = this.form.date_range.endDate.toLocaleDateString();


            var datearray = date1.split("/");
            var startDate = datearray[2] + '-' + datearray[0] + '-' + datearray[1];
            var date2array = date2.split("/");
            var endDate = date2array[2] + '-' + date2array[0] + '-' + date2array[1];
            window.open(`reports/sales-report/print-pdf/${startDate}/${endDate}`,'_blank');
// window.open(url);
        }

    },
    computed: {
        ...mapGetters('TableV1Module',['tableLoading']),
        ...mapGetters('ReportModule',['getLoading', 'columns']),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        },

        endpoint : function(){
            return `reports/sales-report`
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }

  .date-range-picker-small .form-control{
      height: calc(1.5em + .5rem + 2px)!Important;
      line-height: 1.5;
  }
</style>