
<template>
    <div class="customervendor-container">
        <ThatsTable title="Designation" ref="table" :multipleSelection="false" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="true" @import="openUploadWindow"></ThatsTable>
        <DesignationForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></DesignationForm>
       <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2" :endpoint="'designation/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>

    </div>
</template>
<script>

import { mapGetters, mapMutations,mapActions } from 'vuex';
import DesignationForm from './DesignationForm'
import ThatsTable from '../../components/thats/Table'
import { MessageBox } from 'element-ui';
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from './TableImport'
import TableError from './TableError'
export default {
    data(){
        return {
            endpoint : 'designation/data',
            importLoading : false,
            importData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],        
            errorData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],    
        }
    },
    components: {
        DesignationForm,
        ThatsTable,
        FileUpload,
TableImport,
TableError
       
    },
    
    methods: {

        ...mapActions('DesignationModule',[ 'deleteRecord','getRecord','saveImport']),
        ...mapMutations('DesignationModule',[ 'setFormRecord', 'setFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.deleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;
            const form  = {...obj.row,attachment : []}           
            self.setFormStatusUpdate()
            self.setFormRecord(form)
            self.getRecord({token_id : obj.row.token_id})
            self.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        openUploadWindow(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
           
           this.$refs.tableImport.openDialog()
        },
        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ import : v}).then((response) => {
                    this.$refs.table.refreshTable()

                    this.importLoading = false;

                    if(response.success > 0){
                        this.$ThatsNotify.success({
                            message : response.message,
                            timeOut : 15000
                        })
                    }
                    this.$refs.tableImport.closeDialog();

                    const err = response.errors.map((i) => {
                        return i.rows.length
                    }).reduce((a,b) =>{
                        return a + b;
                    }); 

                    if(err > 0){
                        this.errorData = response.errors
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : error.message,
                        timeOut : 15000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },
        

    },
    computed: {
        ...mapGetters('DesignationModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'getloading']),
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>