import Axios from 'axios'
import { bearer } from '../helpers'
import store from '../store';
const isDev = process.env.NODE_ENV === 'development';
// const apiProtocol = (!isDev) ? 'https://v1.thatsliving.com/api' : 'http://127.0.0.1:9001/api';

export const ApiGetService = {
    get(endpoint = '', params,configParams) {
        if(store.getters[configParams.req]) this.cancelRequest(configParams)

        console.log(configParams)
        console.log(store.getters[configParams.req])
        return this.makeRequest(endpoint,params,configParams);
        
    },
     makeRequest(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();
        store.commit(configParams.loadingTrue)
        store.commit(configParams.addRequest,axiosSource)
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}

        // console.log(params)
        return Axios.get(`api/${endpoint}`, { params: {...params }, cancelToken: axiosSource.token, headers : {...bearer()}}).then((response) => {
            self.clearOldRequest(configParams,"Success");
            return Promise.resolve(response)
        }).catch((error) => {
            self.logResponseErrors(error)
            return Promise.reject(error)
        })
    },

    cancelRequest(configParams){
        store.commit(configParams.cancelRequest)
        store.commit(configParams.loadingTrue)
    },
    
    logResponseErrors(err) {
        if (Axios.isCancel(err)) {
            console.log("Request cancelled");
        }
    },
    clearOldRequest(configParams,msg) {
          store.commit(configParams.clearOldRequest,msg)    
    }
}