/* eslint-disable no-prototype-builtins */
<template>
  <div class="itmform-container">

    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="PURCHASE ORDER" windowClass="collection height-auto" scrollClass='collection height-auto' :height="800" :width="1300" :formStatus="formStatus" @dialog-state="dialogState" :group="group" @history="openHistory">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  
                    <mdb-btn color="elegant" size="sm" type="button" @click="loadPurcheseOrderItems"  :disabled="(selected.length > 0 ? false : true)">Load Items</mdb-btn>

                  
                </template>

               
              </template>

            <template #right-button>

                
              
            </template>
        
          <div class="mt-1 pl-3 pr-3">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

                <mdb-row class="tab-row">
                    <mdb-col col="4" class="tab-col pl-0 pr-0">
                      <ValidationProvider :vid="'vendor-code'" :name="'vendor-code'" :rules="{required : true}" v-slot="{errors}">
                          <div class="form-group input-group-sm mt-1">
                              <mdb-input size="sm" placeholder="Vendor Code" v-model="form.vendor_code" label="Vendor Code" type="text" autocomplete="off" noWrapper bg @keydown.enter.native.prevent="onSubmitForm"/>
                              <template v-if="errors.length > 0">
                                  <span class="errors">{{errors[0]}}</span>
                              </template>
                          </div>
                      </ValidationProvider>
                    </mdb-col>
                    <mdb-col col="2" class="tab-col pl-0">
                      <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="loadPurchaseOrderLoading">Load PO</mdb-btn>
                    </mdb-col>
                </mdb-row>


                <el-table id="itemTable" ref="itemTable" :key="tblKey" border :data="data" stripe class="input-table mt-2 itemTable" style="width: 100%" @selection-change="handleSelectionChange" :cell-class-name="cellClassName" v-loading="loadPurchaseOrderLoading" :row-key="getRowKey">
                      <el-table-column type="selection" width="35"> 
                      </el-table-column>
                      <el-table-column label="PO. No." width="150"> 
                        <template slot-scope="scope">{{scope.row.DocNum}}</template>
                      </el-table-column>
                      <el-table-column label="Vendor Code" width="150"> 
                        <template slot-scope="scope">{{scope.row.CardCode}}</template>
                      </el-table-column>
                      <el-table-column label="Vendor Name"> 
                        <template slot-scope="scope">{{scope.row.CardName}}</template>
                      </el-table-column>
                      <el-table-column label="NumAtCard"> 
                        <template slot-scope="scope">{{scope.row.NumAtCard}}</template>
                      </el-table-column>
                      <el-table-column label="Comments"> 
                        <template slot-scope="scope">{{scope.row.Comments}}</template>
                      </el-table-column>
                      <el-table-column label="Amount" align="right"> 
                        <template slot-scope="scope"><strong>{{scope.row.DocTotal | amount}}</strong></template>
                      </el-table-column>
                </el-table>
              </template>
            </mdb-tabs>
            
            
          </div>
       </ThatsWindow>
      </ValidationObserver>
      
    <CostingForm ref="costingForm"></CostingForm>
   
  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
import { ThatsWindow,ThatsInput} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import axios from 'axios'
import CostingForm from './CostingForm'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput,
        CostingForm  
  },
    
  data(){
    return {
        validated : false,
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        data : [],
        form : {
          vendor_code : ''
        },
        loadPurchaseOrderLoading : false,
        formLoading : false,
        selected : []
    }
  },
    

  created : function() {
    
  },

  mounted(){

  },

  updated(){
  
  },
  methods: {

    ...mapActions('CostingToolModule',['getPuchaseOrderByCode','getPuchaseOrder']),
    ...mapMutations('CostingToolModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
// ...mapMutations('CostingToolModule',['setFormStatusCreate','setFormStatusUpdate']),
    dialogState(state){
        this.active = 0;

   
        if(!state){
          this.resetForm();
          return
        }
    },
    resetForm(){
      
      },
    openDialog(){
      this.$refs.window.openDialog();
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
        // 


         const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          // self.setSearchTerm(this.form.vendor_code)
          self.getPuchaseOrderByCode({"term" : this.form.vendor_code}).then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
              // self.closeDialog();
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
        
        /* */


    },
    openHistory(){

    },
    handleSelectionChange(value){
       this.selected = value
    },
    cellClassName(){

    },


    loadPurcheseOrderItems(){
       const self = this;

        if(this.formLoading === false){
          this.formLoading = true
              const card_code = this.form.vendor_code;

              if(this.selected.length > 0){
                const filterVendor = this.selected.filter((item) => {
                  return item.CardCode === card_code;
                },[])[0];


                if(filterVendor){
                    const formData = JSON.parse(JSON.stringify({...this.initialForm,...filterVendor,items: this.selected}))


                    this.setFormData(formData);
                    this.getPuchaseOrder({CardCode : filterVendor.CardCode,DocEntries : this.selected.map((i)=> i.DocEntry)}).then(() => {
                      self.formLoading = false
                      self.setFormStatusCreate();
                      self.$refs.costingForm.openDialog()
                      self.closeDialog()
                    }).catch(() => self.formLoading = false);
                    // this.$refs.costingForm.openDialog()
                }
              }
        }
    },

    fnLoadPurchaseOrder(){
      /*   const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.getPuchaseOrderByCode({"term" : this.form.vendor_code}).then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
              // self.closeDialog();
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        } */
    },

    getRowKey(row){
        return row.DocNum
      },

  },

  computed: {
      tblKey : function(){
        return 1;
      },
      formStatus : function(){
        return 'create';
      },

      ...mapGetters('CostingToolModule',['initialForm'])
    
  },

  watch: {
    

  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}
</style>