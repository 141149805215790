import { ApiService } from '../../services'
export const DashboardModule = {
    namespaced: true,
    state: {
        area : []
    },
    actions: {
        getOpenQuotations({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-open-quotations', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOpenQuotationsBySalesperon({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-open-quotations-by-sales-person', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOpenQuotationsByLocation({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-open-quotations-by-location', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOpenQuotationsCurrentMonth({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-open-quotations-current-month', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getOpenQuotationsLastMonth({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-open-quotations-last-month', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getInvoicesCurrentMonth({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-invoice-current-month', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getInvoicesConversionRate({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-invoice-conversion-rate', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getInvoicesMonthlySales({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-invoice-monthly-sales', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },

        getCreditNotes({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('get-credit-notes', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },


        getSalesReport({}, params = {}) {
            return new Promise((resolve, reject) => {
                ApiService.get('sales-report', params).then((response) => {
                    resolve(response);
                }).catch(error => {
                    reject(error)
                })
            })
        },

    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },

        setData: (state,payload) => {
            state.area = payload
        },
       

    },
    getters: {
        area : state => state.area
    }
}


