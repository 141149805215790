<template>
<el-dialog title="SELECT" :visible.sync="syncDialog" :close-on-click-modal="false" size="small" class="text-left select-location-dialog-form" :show-close="showClose" @close="fnClose">
    <div class="filter-dalog-body" v-loading="getLoading">
        <div class="list-group">
            <a v-for="(location, index) in data" :key="index" class="border-light cursor-pointer list-group-item d-flex justify-content-between align-items-center select-location-item" href="javascript:void(0)" @click="fnSelect(location)">
                <strong>{{ location.prefix }} - {{ location.name }}</strong>
            </a>
        </div>
    </div>
</el-dialog>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            syncDialog: false,
            showClose: true,
            getLoading: false,
            selected : null,
            data : []
        }
    },

    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ...mapActions('LocationSetupModule', ['get']),
        fnClose(){
            this.$emit('close');
        },
        fnSelect(location){
            this.$emit('select', location);
            this.$nextTick(() => {
                this.syncDialog = false;
            })
        }
    },

    watch: {
        visible: function (val) {
            const self = this;
            this.syncDialog = val;
            
            if(val){
                self.getLoading = true;
                this.get().then((res) => {
                    self.data = res.map((item) => {
                        return {
                            ...item,
                            value: item.id,
                            label: item.name,
                            selected: false
                        }
                    },[]);
                    self.$nextTick(() => {
                        self.getLoading = false;
                    })
                }).catch(() => {
                    self.getLoading = false;
                })
            }
        }
    },
}
</script>
<style scoped>
.select-location-item{
    text-transform : uppercase;
    font-size : 12px;
}
</style>