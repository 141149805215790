import { ApiHttpService } from '../../services'
export const SizeMasterModule = {
    namespaced: true,
    state: {
        request: null,
        loading : false,
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        loadingTrue(state) {
            state.loading = true
        },
        loadingFalse(state) {
            state.loading = false
        },

       
    },
    actions : {

        async filter({ commit}) {
            return await new Promise((resolve, reject) => {

                    commit('loadingTrue')
                    const params = {}
                    const configParams = {
                        req : "SizeMasterModule/req",
                        loadingTrue : "SizeMasterModule/loadingTrue",
                        addRequest : "SizeMasterModule/addRequest",
                        cancelRequest : "SizeMasterModule/cancelRequest",
                        clearOldRequest : "SizeMasterModule/clearOldRequest"
                    }

                    ApiHttpService.get("size-master/filter", params, configParams).then((response) => {
                        resolve(response.data)
                        commit('loadingFalse')
                    }).catch(error => {
                        commit('loadingFalse')
                        reject(error)
                    })

            })
        },


    },
    getters: {
        loading : state => state.loading,
        req: state => state.request,
    }
}



