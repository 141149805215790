import { OthersService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const OthersModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [
                {
                    label: 'Contact Name',
                    field: 'name',
                    sort: 'asc',
                    width: '200',
                    clickable: true
                },
                {
                    label: 'Address',
                    field: 'address',
                    sort: 'asc',
                },
                
                {
                    label: 'Phone/Mobile',
                    field: 'mobile',
                    sort: 'asc',
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                },

                {
                    label: 'Status',
                    field: 'status_label',
                    sort: 'asc',
                },


            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

        Others: [],


    },
    actions: {

        async GetOthers({ dispatch,commit },params) {
            return await new Promise((resolve) => {
                OthersService.get('/filter', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label: element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    commit('setOthers',res)
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async GetNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                OthersService.get('/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = rootState.OthersModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    OthersService.get('\/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },



        async StoreRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.OthersModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    OthersService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.OthersModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    OthersService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.OthersModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    OthersService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        }
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setOthers: (state, payload) => {
            state.Others = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        Others: state => state.Others,
    }
}


