<template>
    <hsc-window v-bind:class="$attrs.windowClass" :title="$attrs.title" :resizable="false" :left="400" :top="50" :width="width" :height="height" :minWidth="400" :maxWidth="800" :minHeight="minHeight" :maxHeight="maxHeight"  :closeButton="true" :isOpen.sync="dialogIsOpen">
        <mdb-container class="dialog-header">
            <mdb-btn color="grey" class="ml-0" size="sm" @click="CloseDialog">Close</mdb-btn>
        </mdb-container> 
        <div class="scrollbar" v-bind:class="$attrs.scrollClass" >
            <slot></slot>
        </div>     
         <!-- <div class="d-flex flex-row">
             <slot name="footer"></slot>
            <mdb-btn color="grey" class="ml-0" size="sm" @click="CloseDialog">Close</mdb-btn>
         </div> -->
    </hsc-window>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  mdbBtn,
mdbContainer
} from "mdbvue";
export default {
    components: {
        mdbBtn,
        mdbContainer,
    },

    props : {
        width : {
            type : Number,
            default : 800
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 450
        }
    },
    methods: {
        ...mapMutations('DialogModule',[
            'CloseDialog'
        ]),

       
    },
    computed: {
        ...mapGetters('DialogModule',{
            dialogSate : 'DialogIsOpen'
        }),

        dialogIsOpen : {
                get : function () {
                    return this.dialogSate
                },
                set : function(val) {
                    this.CloseDialog(val)
                }
            }
        
    },

    watch : {
        'dialogSate' : function(newState,old) {
            this.$emit('DialogState',newState)
        }
    }
}
</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }

</style>