<template>
      <div class="table-container pr-0">
          <h5 class="module-title mb-0 p-1 pb-0" v-bind:class="{'ml-auto': hasTabsSlot}" v-text="$attrs.title"></h5>
          <div class="d-flex justify-content-between align-items-center pt-1 pl-3 pr-3">

            <template v-if="hasTabsSlot">
                <slot name="tabs"></slot>
            </template>
            

        
            <mdb-form-inline class="ml-auto" tag="div">
                <template v-if="Selection.length">
                    <mdb-btn color="elegant" size="sm" class="mr-auto  mt-0" @click="ClearSelected">Clear</mdb-btn>
                    <!-- <mdb-dropdown class="inactive-reactive mr-auto ml-1 mt-0"> 
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm" class=" mt-0" style="margin-top:0!important">Inactive/Reactive</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="emitStatus('inactive')">Inactive</mdb-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-item @click="EmitDeleteModal">Delete</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> -->
                    <mdb-btn color="elegant" icon="trash" size="sm" class="mr-auto mt-0" @click="EmitDeleteModal">Delete</mdb-btn>
                
                </template>
                <template v-else>
                    <input class="form-control mr-sm-2 table-searchterm-input" v-model="term" type="text" placeholder="Search" aria-label="Search" @keydown.enter.prevent="handleSearchTerm"/>
                     

                     <template v-if="hasFilterSlot && enableFilter">
           
                        <div class="btn-group mt-0">
                            <button type="button" class=" btn btn-sm btn-elegant mt-0" @click="handleSearchTerm">Search</button>
                                <button type="button" class="mr-auto btn btn-sm btn-grey dropdown-toggle-split mt-0" v-mdb-click-outside="away" @click="toggleDropdown" title="Advanced Filter"><mdb-icon icon="filter"></mdb-icon></button>
                                <div class="dropdown-menu dropdown-menu-right" ref="dropdownFilter" v-bind:class="[{'show ' : toggle},filterClass]" @click.stop>
                                    <slot name="filter" :dispatchFilter="dispatchFilter"></slot>
                                    </div>
                            </div>
                         
                     </template>
                     <template v-else>
                         <mdb-btn color="elegant" icon="refresh" size="sm" class="mt-0" @click="handleSearchTerm">Search</mdb-btn>
                     </template>
                     <!-- <div class="btn-group">
                        <button type="button" class=" btn btn-sm btn-elegant" @click="handleSearchTerm">Search</button>
                        <button type="button" class=" btn btn-sm btn-grey dropdown-toggle-split" @click="toggleDropdown" title="Advanced Filter"><mdb-icon icon="filter"></mdb-icon></button>
                        <div class="dropdown-menu">
                                <a href="#" class="dropdown-item">Action</a>
                                <a href="#" class="dropdown-item">Another action</a>
                                <div class="dropdown-divider"></div>
                                <a href="#" class="dropdown-item">Separated link</a>
                            </div>
                    </div> -->
                     <mdb-btn color="elegant" icon="refresh" size="sm" class="mt-0" @click="refreshTable">Refresh</mdb-btn>
                    <!-- <template v-if="importBtn || exportBtn">
                        <div class="btn-group">
                            <template v-if="importBtn">
                                <button type="button" v-bind:class="[{'mr-auto' : !exportBtn}]" class=" btn btn-sm btn-elegant mt-0" title="Import File" @click="emitImport">Import</button>
                            </template>
                            <template v-if="exportBtn">
                                
                            <button type="button" class="btn btn-sm btn-grey" title="Export as Excel" @click="emitExport">Export</button>    
                            </template>
                        </div>
                     </template> -->
                    <template v-if="newBtn">
                    <mdb-btn color="elegant" icon="plus" size="sm" class="mt-0" @click="EmitOpenModal">New</mdb-btn>
                    </template>

                    <template v-if="$helpers.isSystemAdministrator()">

                        <template v-if="hasRightSlot">
                            <slot name="top-right"></slot>
                        </template>
                        <!-- <mdb-btn color="elegant" icon="upload" size="sm" class="mt-0 mr-0" @click="EmitOpenBulkModal">Invoice Push</mdb-btn>
                        <mdb-btn color="elegant" icon="upload" size="sm" class="mt-0 mr-0" @click="EmitOpenPaymentModal">Payment Push</mdb-btn>
                        <mdb-btn color="elegant" icon="upload" size="sm" class="mt-0 mr-0" @click="EmitOpenCreditNoteModal">Credit Note Push</mdb-btn> -->
                    </template>

                </template>
                    
            </mdb-form-inline>
        </div>

        <el-table ref="multipleTable" border lazy class="custom-element-table" :data="data.rows" :height="height" stripe @selection-change="handleSelectionChange" :cell-class-name="cell">
            <template v-if="multipleSelection">
                <el-table-column type="selection" width="35"> </el-table-column>
            </template>

            <template v-if="hasCustomColumnsSlot">
                <slot name="custom-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
            </template>
            <template v-else>
                   <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>

                            <template v-else-if="col.field === 'customer_mobile'">
                                {{scope.row[col.field]}} <span class="copy" @click="copyConSeries(scope.row)" :ref="`copy-text-${scope.row.id}}`">Copy</span> 
                            </template>
                            <template v-else-if="col.field === 'status'"> 
                                {{scope.row[col.field] | transactionStatus}}
                            </template>
                            <template v-else-if="col.field === 'amount' || col.field === 'total' || col.field === 'balance' || col.field === 'diff'" >
                                {{scope.row[col.field] | amount}}
                            </template>
                            <template v-else-if="col.field === 'print' && scope.row['status'] !== 'cancel' && scope.row['status'] !== 'closed'">
                                <a href="javascript:void(0)" class="btn btn-sm btn-elegant" @click="handleClickPrint(scope.$index, scope.row)">Print</a>
                            </template>
                            <template v-else>
                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>                            
                            </template>
                        </template>
                        
                    </el-table-column>
            </template>
            
            
            <template slot="empty">
                <template v-if="tableLoading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading data...
                    </mdb-btn>
                  
                </template>
                <template v-else>
                    No Data
                </template>
            </template>
        </el-table>

        <el-pagination        
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
        
        </div>
</template>
<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside, 
    mdbTabs, 
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'
import {token} from '../../helpers/headers'
import { Message } from 'element-ui';
export default {
    name : "ThatsTable",
    data(){
        return {
            toggle : false,
            token : null,
        }
    },
    props : {
        endpoint : {
            type: String,
            required : true
        },
        columns : {
            type: Array,
            required : true
        },
        height : {
            type: String,
            default : 'calc(100vh - 128px)'
        },
        filterClass : {
            type: String,
            default : 'sf-dropdown-menu'
        },
        importBtn : {
            type: Boolean,
            default : false
        },
        exportBtn : {
            type: Boolean,
            default : false
        },
        multipleSelection : {
            type: Boolean,
            default : true
        },
        newBtn : {
            type: Boolean,
            default : true
        },
        enableFilter : {
            type: Boolean,
            default : false
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbTabs,
         
    },
directives: {
      mdbClickOutside
    },
    created: function () {
        this.SetFilters({})
        this.SetEmptyData()
         this.dispatchHandler()
        const tokenObj = token();

        this.token = tokenObj.token
        this.SetTerm ("")
    },



    methods: {
        ...mapActions('TableV2Module',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('TableV2Module',[
            'SetTotal',
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
            'SetFilters',
        ]),

        EmitOpenModal(){
            this.$emit('openModal')
        },
        EmitOpenBulkModal(){
            this.$emit('openPushModal')
        },
        EmitOpenPaymentModal(){
            this.$emit('openPaymentPushModal')
        },
        EmitOpenCreditNoteModal(){
            this.$emit('openCreditNotePushModal')
        },
        EmitDeleteModal(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes',self.Selection)
                    }).catch(() => {
                        self.$emit('deleteNo')  
                    });

        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
            this.SetLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.SetTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        ClearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        handleClickPrint(index,row){
            this.$emit('print',{index : index,row : row})
        },
        handleClick(index,row){
            this.$emit('openUpdateModal',{index : index,row : row})
        },
        handleClickTrigger(index,row,trigger){
            this.$emit('viewWindow',{window:trigger,index : index,row : row})
        },

        refreshTable(){
            this.resetData(this.endpoint);
        },
        emitStatus(status){
            this.$emit('status',status)
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        toggleDropdown(){
            this.toggle = !this.toggle;
        },

        away(){
            this.toggle = false
        },

        dispatchFilter(filters){
            this.SetTotal(0)
            this.SetFilters(filters)
             this.resetData(this.endpoint);
        },
    emitImport(){
            this.$emit('import')
        },
        emitExport(){
            // this.$emit('export')

             window.open(this.endpoint+"/export", "_blank");  
        },

        downloadFile(response, filename,ext) {

            var newBlob = new Blob([response.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'})

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = filename + '.xlsx'
            link.click()
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 100)
            },
        cell({column}) {
            // console.log(column)
        if(column.property === 'balance'){
          return 'text-red'
        }
        if(column.property === 'customer_mobile'){
          return 'customer_mobile'
        }
        return '';
       },

       copyConSeries(row){
            var dummy = document.createElement("textarea");
              document.body.appendChild(dummy);
              dummy.setAttribute("id", "dummy_id");
              document.getElementById("dummy_id").value=row.attr.cx_series;
              dummy.select();
              document.execCommand("copy");
              document.body.removeChild(dummy);

              
                Message.success({
                message: "Copied!"
                });

       }

    },
    
    computed: {
        ...mapGetters('TableV2Module',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',
            'tableLoading',
            'Selection',
        ]),
        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.SetTerm (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.SetPage (val)
            }
        },

        hasFilter(){
            return !!this.$scopes.filter;
        },

        hasFilterSlot() {
            console.log(this.$scopedSlots)
            // console.log(('filter' in this.$scopedSlots))
            return ('filter' in this.$scopedSlots);
        },

        hasTabsSlot() {
            return ('tabs' in this.$scopedSlots);
        },
        hasRightSlot() {
            return ('top-right' in this.$scopedSlots);
        },
        hasCustomColumnsSlot() {
            return ('custom-culumns' in this.$scopedSlots);
        },

    },

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}
.form-control.table-searchterm-input{
    align-self: flex-start!important;
    margin-top: 2px!important;
}

</style>