import {ApiHttpService} from '../../services'

import router from "../../router";
export const QuantityAdjustModule = {
    namespaced: true,
    state: {
        loading: false,
        data : {

            rows: [],
            customer_quotation_rows: [],
        },
        length : 25,
        page : 0,
        total : 1,
        searchTerm : null,
        filters : null,

        formStatus : 'create',
        endpoint : 'quantity-adjust',
        formRecord : {},
        form : {},

        customer_quotation_length: 25,
        customer_quotation_page: 1,
        searchTermQuot: null,
        loadingQuotation: false,
        customerQuotation: [],
        customer_quotation_total: 1,

    },
    actions : {
        async getQuantityAdjust({ rootState, commit }, obj) {
            const { customer_quotation_length, customer_quotation_page, endpoint} = rootState.QuantityAdjustModule

            return await new Promise((resolve, reject) => {
                commit('setLoadingQuotation', false);
                commit('setCustomerQuotationRows', []);
                commit('setFormStatusUpdate');

                const params = {
                    length: customer_quotation_length,
                    page: customer_quotation_page,
                    view: router.app.$route.name
                };

                const configParams = {
                    req: "QuantityAdjustModule/req",
                    loadingTrue: "QuantityAdjustModule/loadingTrueQuotation",
                    addRequest: "QuantityAdjustModule/addRequest",
                    cancelRequest: "QuantityAdjustModule/cancelRequest",
                    clearOldRequest: "QuantityAdjustModule/clearOldRequest"
                };

                ApiHttpService.get(`${endpoint}/data/` + obj, params, configParams)
                    .then((response) => {
                        if (response.data && response.data.data && response.data.data) {
                            const quotationsArray = response.data.data;
                                commit('setCustomerQuotation', quotationsArray);
                                commit('setCustomerQuotationPage', response.data.pagination.current_page);
                                commit('setCustomerQuotationTotal', response.data.pagination.total);
                        }
                        commit('setLoadingQuotation', false);
                        resolve(response.data.data);
                    })
                    .catch((error) => {
                        commit('setLoadingQuotation', false);
                        reject(error);
                    });
            });
        },

        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = rootState.QuantityAdjustModule;
                if (!loading) {
                    commit('LoadingTrue')

                    ApiHttpService.store(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },






    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        SetCode: (state, payload)=>{
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        setCustomerQuotationLength: (state, payload) => {
            state.customer_quotation_length = payload
        },
        setCustomerQuotationPage: (state, payload) => {
            state.customer_quotation_page = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setSearchTermQuotation: (state, payload) => {
            state.searchTermQuot = payload
        },
        setTermQuotation: (state, payload) => {
            state.searchTermQuot = payload
        },
        setLoadingQuotation(state) {
            state.loadingQuotation = false;
        },
        setCustomerQuotationRows(state, payload) {
            state.data.customer_quotation_rows = payload
        },
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        loadingTrueQuotation(state) {
            state.loadingQuotation = true
        },
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            // state.request.msg = message;
            state.request = null;
        },
        setCustomerQuotation(state, payload) {
            state.customerQuotation = payload;
        },
        setCustomerQuotationTotal: (state, payload) => {
            state.customer_quotation_total = payload
        },
    },
    getters: {
        Loading: state => state.loading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,


        customer_quotation_length: state => state.customer_quotation_length,
        customer_quotation_page: state => state.customer_quotation_page,
        filters: state => state.filters,
        searchTermQuot: state => state.searchTermQuot,
        loadingQuotation: state => state.loadingQuotation,
        customer_quotation_rows: state => state.data.customer_quotation_rows,
        customerQuotation: state => state.customerQuotation,
        customer_quotation_total: state => state.customer_quotation_total,
    }
}


