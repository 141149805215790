<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer"   @submit.prevent="onSubmitForm()">
        <el-dialog :title="'Loyalty Card'" :visible.sync="dialogIsOpen" :close-on-click-modal="false" width="40%" size="small" class="text-left item-quantity-adjustment-form" :show-close="!formLoading">
            <div class="container">

                <div v-if="this.getImage && showCard == false" class="image-container">
                    <img :src="this.getImage" alt="Selected Image" class="show-img custom-card"/>
                    <a class="remove-icon" @click="toggleCard">
                        <i class="fa fa-times"></i>
                    </a>
                </div>

                <mdb-card wide class="custom-card-empty" v-else-if="!selectedImage">
<!--                <mdb-card wide class="custom-card-empty" v-if="!selectedImage">-->
                    <mdb-view gradient="none" style="position: relative;height:215px">
                        <input
                            type="file"
                            ref="fileInput"
                            class="display-none"
                            accept="image/*"
                            @change="handleFileSelect"
                        />
                        <a class="centered-plus" @click="triggerBrowseFile">
                            <i class="fa fa-plus" style="color: black;"></i>
                        </a>
                    </mdb-view>
                </mdb-card>

                <div v-else-if="selectedImage" class="image-container">
                    <img :src="selectedImage" alt="Selected Image" class="show-img custom-card" />
                    <button class="remove-icon" @click="removeImage">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>

<!--            <div class="image-list-item position-relative cursor-pointer custom-card-empty"  v-for="(image, index) in this.form.images" :key="index">-->
<!--                <div class="centered">-->
<!--                    <img class="show-img img-responsive" :src="image.path">-->
<!--                </div>-->
<!--                <div class="icon-container">-->
<!--                    <template v-if="image.completed">-->
<!--                        <mdb-icon icon="check-circle" />-->
<!--                    </template>-->
<!--                    <template v-if="image.error">-->
<!--                        <mdb-icon icon="redo-alt" />-->
<!--                    </template>-->
<!--                </div>-->
<!--            </div>-->

            <input class="display-none" type="file" ref="file" :accept="accept" @change="selectFile"  />

            <ThatsInput :show_error="true"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Card Name" vid="name" name="name" v-model="form.name"  :rules="'required'" size="sm" />
            <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="form.description" size="sm" :rules="'required'"/>
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="defaultChecked2" v-model="form.status" true-value="active" false-value="inactive">
                <label class="custom-control-label" for="defaultChecked2">Active</label>
            </div>
            <template slot="footer">
                <div class="p-0">
                    <div class="d-flex">
                        <template v-if="formLoading">
                            <mdb-btn color="elegant" size="sm" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </mdb-btn>
                        </template>
                        <template v-else>
                            <mdb-btn color="elegant" class="ml-0 mt-0 mb-0" size="sm" @click="onSubmitForm"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                            <mdb-btn color="grey" class="mt-0 mb-0" size="sm" @click="closeNewDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                        </template>
                    </div>
                </div>
            </template>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import {mdbBtn, mdbIcon, mdbCard, mdbView} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,ThatsTextarea} from '../../components/thats/Thats'
export default {
    components: {
        mdbBtn,
        mdbIcon,mdbCard,mdbView,
        ValidationObserver,
        ThatsInput,
        ThatsTextarea,
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
        accept : {
            type : String,
            default : 'image/*'
        },
    },

    // inject:['showroomsProvider'],
    data : () => ({
        dialogIsOpen : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
      designation : [],
      roles : [],
        selectedFiles: [],
        currentFile: undefined,
        progress: 0,
        // images : [],
      group2 : 3,
        showCard: false,
        getImage: "",
        selectedImage: null

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }
       // this.optionsHandler();
      // this.filterItems().then((response) => {
      //   self.roles = response
      // })

    },
    methods: {
      ...mapActions('LoyaltyCardModule',[
        'StoreRecord',
        'UpdateRecord','asyncGetData'
      ]),
      ...mapMutations('LoyaltyCardModule',[
        'SetFormStatusCreate','SetFormRecord'
      ]),

      formVar(){
        return {
            name : "",
            description : "",
            images : [],
            status:"active"

        }
      },
        /* start */
        triggerBrowseFile(){
            // const elem = this.$refs.file
            // elem.click()
            this.$refs.fileInput.click();
        },
        toggleCard() {
            this.showCard = true;
            // this.form.attr.images = null
        },
        selectFile() {
            this.form.images = [];
            this.selectedFiles = [];
            this.selectedFiles = this.$refs.file.files;

            this.selectedFiles.forEach((file, index) => {
                let reader = new FileReader()

                reader.onload = (e) => {
                    let dataURI = e.target.result
                    if (dataURI) {
                        this.form.images.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false})
                    }
                }
                reader.readAsDataURL(file)
            })
        },

        handleFileSelect(event) {
            this.form.images = [];
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let dataURI = e.target.result
                    this.selectedImage = dataURI
                    if (dataURI) {
                        this.form.images.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false})
                    }
                };
                reader.readAsDataURL(file);
            }
        },

        onSelect(e,r){
          this.form.images.forEach((img,i) => {
              if(r.token_id === img.token_id){
                  this.form.images[i].default = 1
              }else{
                this.form.images[i].default  = 0
              }
          })
        },

        removeImage() {
            this.selectedImage = null;
            this.form.images = [];
        },


        openGalleryManager(){
        this.$refs.gallery.openDialog()
        },
        closeNewDialog(){
            this.dialogIsOpen = false
            this.FormResetter();
            self.form = null;
            this.showError = false;
            this.showCard = false;
            this.removeImage();
            this.getImage = "";
            this.selectedImage = null;
        },

        openNewDialog(){
            this.dialogIsOpen = true
            // this.form.item_id = formData.mdbID
            // this.form.item_code = formData.series
            // let user = JSON.parse(localStorage.getItem('user'));
            // this.form.user_id = user.user_id;
        },

        uploadImage(){
          this.$refs.upload.openDialog();
        },

        fnImageSelect(v){
          if(v.length > 0){
            v.forEach((r,i) => {
              const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
              if(i === 0){
                d.default = 1;
              }
                this.form.images.push(d)
            })
          }
        },

        /* end */
      openDialog(){
          this.dialogIsOpen = true
      },
      closeDialog(){
          this.dialogIsOpen = false;
          console.log('banda bia')
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){

                      if(self.FormStatus == 'create'){
                          self.formLoading = true
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();
                              self.asyncGetData();
                              self.removeImage();
                              this.showCard = false;
                              this.getImage = "";
                              this.selectedImage = null;

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.formLoading = true
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                self.asyncGetData();
                                self.SetFormRecord();
                                self.removeImage();
                              this.showCard = false;
                              this.getImage = "";
                              this.selectedImage = null;

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })

                      }
                    }
                    }


      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.resetPassword = false
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        // this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },
      // allLocation(){
      //     const locations = this.showrooms.map((i) => i.value);
      //     this.form.attr.location = locations
      // },
      clearLocation(){
          this.form.attr.location = []
      }

    },

    computed: {
      ...mapGetters('LoyaltyCardModule',
      [
        'FormStatus',
        'FormRecord',
      ]),


      // showrooms : function(){
      //   return this.showroomsProvider();
      // }

    },

    watch: {
        FormRecord: function() {
            if (this.FormStatus == 'update') {
                const frm = JSON.parse(JSON.stringify(this.FormRecord));

                frm.attr = {...this.form.attr, ...frm.attr}
                this.getImage = frm.attr.images;
                this.form = {...this.form, ...frm}
            }
            if (this.FormStatus == 'create') {
                this.FormResetter();
            }

        },
        dialogIsOpen: function(newDialogIsOpen) {
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },
}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}
.quantity-adjust-container .modal-dialog {
    z-index: 9999 !important;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

.position-relative {
    position: relative;
}

.cursor-pointer {
    cursor: pointer;
}
.centered {
    left: 50%;
    transform: translate(-50%,-50%);
    top: 50%;
    position: absolute;
    display: block;
}
.image-input label {
    display: block;
}
.display-none {
    display: none;
}
.preview-image:hover .image-overlay, .preview-image:hover .image-overlay-details{
    opacity: 1;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.show-img {
    max-width: 350px;
    max-height: 197.58px;

    display: block;
    vertical-align: middle;
}

.image-list-container .image-list-item {
    height: 100px;
    width: 100px;
    border-radius: 4px;
    border: 1px solid #D6D6D6;
    overflow: hidden;
}
.image-list-container .image-list-item:not(:last-child) {
    margin-right: 5px;
    margin-bottom: 5px;
}
.image-list-container .image-list-item .show-img {
    max-width: 100px;
    max-height: 100px;
}
.image-list-container .image-highlight {
    border: 1px solid #2fa3e7;
}

.input-add-image label {
    display: block;
}


.image-list-item {
    margin-bottom: 0;
}


.centered-plus {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
/* color: #333; */
}

.custom-card-empty {
    margin-top: 15px;
    width: 350px;
    height: 215px;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom-card {

    width: 350px;
    height: 215px;




}
.display-none {
    display: none;
}
.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size : 100%;
}
.image-container {
    position: relative;
    display: inline-block;
}

.remove-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: red;
    padding: 0;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease;
}

.image-container:hover .remove-icon {
    opacity: 1; /* Show on hover */
}
.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
</style>
