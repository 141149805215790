const area = [
    {
        brand: 4,
        name: "Fabric \/Textiles \/ Others id-9 brand-4",
        id: 9
    },
    {
        brand: 5,
        name: "Home Decor id-12 brand-5",
        id: 12
    },
    {
        brand: 5,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 5,
        name: "Serveware",
        id: 8
    },
    {
        brand: 7,
        name: "Glassware",
        id: 7
    },
    {
        brand: 7,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 7,
        name: "Serveware",
        id: 8
    },
    {
        brand: 7,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 7,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 7,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 7,
        name: "Services",
        id: 14
    },
    {
        brand: 16,
        name: "Services",
        id: 14
    },
    {
        brand: 127,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 127,
        name: "Serveware",
        id: 8
    },
    {
        brand: 127,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 126,
        name: "Living Room",
        id: 1
    },
    {
        brand: 2,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 20,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 42,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 42,
        name: "Serveware",
        id: 8
    },
    {
        brand: 42,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 8,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 8,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 49,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 14,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 8,
        name: "Serveware",
        id: 8
    },
    {
        brand: 8,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 11,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 11,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 49,
        name: "Glassware",
        id: 7
    },
    {
        brand: 49,
        name: "Serveware",
        id: 8
    },
    {
        brand: 50,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 51,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 53,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 54,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 54,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 56,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 57,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 58,
        name: "Living Room",
        id: 1
    },
    {
        brand: 61,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 65,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 66,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 68,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 68,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 70,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 71,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 72,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 73,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 77,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 78,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 80,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 84,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 85,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 90,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 88,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 92,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 94,
        name: "Living Room",
        id: 1
    },
    {
        brand: 94,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 97,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 4,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 101,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 6,
        name: "Lighting",
        id: 4
    },
    {
        brand: 6,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 6,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 6,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 6,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 2,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 3,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 3,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 3,
        name: "Serveware",
        id: 8
    },
    {
        brand: 3,
        name: "Glassware",
        id: 7
    },
    {
        brand: 3,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 13,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 1,
        name: "Serveware",
        id: 8
    },
    {
        brand: 1,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 1,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 8,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 41,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 63,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 63,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 63,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 59,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 52,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 3,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 16,
        name: "Outdoor\r\n",
        id: 13
    },
    {
        brand: 16,
        name: "Flatware\r\n",
        id: 11
    },
    {
        brand: 16,
        name: "Home Decor",
        id: 12
    },
    {
        brand: 124,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 123,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 122,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 121,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 120,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 119,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 118,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 117,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 116,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 115,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 114,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 113,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 112,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 111,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 110,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 109,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 108,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 107,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 106,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 105,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 104,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 103,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 102,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 59,
        name: "Others",
        id: 10
    },
    {
        brand: 59,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 59,
        name: "Lighting",
        id: 4
    },
    {
        brand: 59,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 3,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 8,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 8,
        name: "Living Room",
        id: 1
    },
    {
        brand: 8,
        name: "Accessories",
        id: 5
    },
    {
        brand: 8,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 8,
        name: "Glassware",
        id: 7
    },
    {
        brand: 8,
        name: "Others",
        id: 10
    },
    {
        brand: 12,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 12,
        name: "Others",
        id: 10
    },
    {
        brand: 79,
        name: "Others",
        id: 10
    },
    {
        brand: 86,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 86,
        name: "Glassware",
        id: 7
    },
    {
        brand: 86,
        name: "Others",
        id: 10
    },
    {
        brand: 36,
        name: "Lighting",
        id: 4
    },
    {
        brand: 36,
        name: "Others",
        id: 10
    },
    {
        brand: 16,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 16,
        name: "Serveware",
        id: 8
    },
    {
        brand: 16,
        name: "Glassware",
        id: 7
    },
    {
        brand: 16,
        name: "Others",
        id: 10
    },
    {
        brand: 5,
        name: "Others",
        id: 10
    },
    {
        brand: 3,
        name: "Others",
        id: 10
    },
    {
        brand: 1,
        name: "Others",
        id: 10
    },
    {
        brand: 11,
        name: "Others",
        id: 10
    },
    {
        brand: 49,
        name: "Accessories",
        id: 5
    },
    {
        brand: 95,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 96,
        name: "Accessories",
        id: 5
    },
    {
        brand: 96,
        name: "Others",
        id: 10
    },
    {
        brand: 98,
        name: "Others",
        id: 10
    },
    {
        brand: 99,
        name: "Glassware",
        id: 7
    },
    {
        brand: 101,
        name: "Others",
        id: 10
    },
    {
        brand: 97,
        name: "Others",
        id: 10
    },
    {
        brand: 52,
        name: "Others",
        id: 10
    },
    {
        brand: 56,
        name: "Others",
        id: 10
    },
    {
        brand: 57,
        name: "Others",
        id: 10
    },
    {
        brand: 63,
        name: "Lighting",
        id: 4
    },
    {
        brand: 63,
        name: "Serveware",
        id: 8
    },
    {
        brand: 63,
        name: "Others",
        id: 10
    },
    {
        brand: 63,
        name: "Glassware",
        id: 7
    },
    {
        brand: 63,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 70,
        name: "Others",
        id: 10
    },
    {
        brand: 72,
        name: "Others",
        id: 10
    },
    {
        brand: 74,
        name: "Others",
        id: 10
    },
    {
        brand: 51,
        name: "Fabric \/Textiles \/ Others",
        id: 9
    },
    {
        brand: 19,
        name: "Others",
        id: 10
    },
    {
        brand: 18,
        name: "Others",
        id: 10
    },
    {
        brand: 12,
        name: "Glassware",
        id: 7
    },
    {
        brand: 20,
        name: "Others",
        id: 10
    },
    {
        brand: 28,
        name: "Others",
        id: 10
    },
    {
        brand: 29,
        name: "Others",
        id: 10
    },
    {
        brand: 27,
        name: "Others",
        id: 10
    },
    {
        brand: 43,
        name: "Accessories",
        id: 5
    },
    {
        brand: 42,
        name: "Others",
        id: 10
    },
    {
        brand: 43,
        name: "Others",
        id: 10
    },
    {
        brand: 46,
        name: "Others",
        id: 10
    },
    {
        brand: 66,
        name: "Others",
        id: 10
    },
    {
        brand: 83,
        name: "Glassware",
        id: 7
    },
    {
        brand: 79,
        name: "Glassware",
        id: 7
    },
    {
        brand: 31,
        name: "Others",
        id: 10
    },
    {
        brand: 33,
        name: "Others",
        id: 10
    },
    {
        brand: 36,
        name: "Glassware",
        id: 7
    },
    {
        brand: 6,
        name: "Serveware",
        id: 8
    },
    {
        brand: 6,
        name: "Glassware",
        id: 7
    },
    {
        brand: 6,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 17,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 49,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 68,
        name: "Serveware",
        id: 8
    },
    {
        brand: 69,
        name: "Glassware",
        id: 7
    },
    {
        brand: 14,
        name: "Others",
        id: 10
    },
    {
        brand: 1,
        name: "Accessories",
        id: 5
    },
    {
        brand: 101,
        name: "Accessories",
        id: 5
    },
    {
        brand: 100,
        name: "Living Room",
        id: 1
    },
    {
        brand: 99,
        name: "Accessories",
        id: 5
    },
    {
        brand: 98,
        name: "Accessories",
        id: 5
    },
    {
        brand: 98,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 97,
        name: "Accessories",
        id: 5
    },
    {
        brand: 96,
        name: "Living Room",
        id: 1
    },
    {
        brand: 95,
        name: "Accessories",
        id: 5
    },
    {
        brand: 94,
        name: "Accessories",
        id: 5
    },
    {
        brand: 93,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 93,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 92,
        name: "Accessories",
        id: 5
    },
    {
        brand: 91,
        name: "Lighting",
        id: 4
    },
    {
        brand: 90,
        name: "Accessories",
        id: 5
    },
    {
        brand: 89,
        name: "Accessories",
        id: 5
    },
    {
        brand: 88,
        name: "Accessories",
        id: 5
    },
    {
        brand: 87,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 86,
        name: "Accessories",
        id: 5
    },
    {
        brand: 85,
        name: "Accessories",
        id: 5
    },
    {
        brand: 84,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 84,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 84,
        name: "Living Room",
        id: 1
    },
    {
        brand: 83,
        name: "Accessories",
        id: 5
    },
    {
        brand: 82,
        name: "Living Room",
        id: 1
    },
    {
        brand: 81,
        name: "Lighting",
        id: 4
    },
    {
        brand: 80,
        name: "Accessories",
        id: 5
    },
    {
        brand: 79,
        name: "Accessories",
        id: 5
    },
    {
        brand: 78,
        name: "Accessories",
        id: 5
    },
    {
        brand: 77,
        name: "Accessories",
        id: 5
    },
    {
        brand: 76,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 76,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 76,
        name: "Living Room",
        id: 1
    },
    {
        brand: 75,
        name: "Accessories",
        id: 5
    },
    {
        brand: 75,
        name: "Lighting",
        id: 4
    },
    {
        brand: 74,
        name: "Accessories",
        id: 5
    },
    {
        brand: 73,
        name: "Accessories",
        id: 5
    },
    {
        brand: 72,
        name: "Accessories",
        id: 5
    },
    {
        brand: 71,
        name: "Accessories",
        id: 5
    },
    {
        brand: 70,
        name: "Living Room",
        id: 1
    },
    {
        brand: 67,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 67,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 67,
        name: "Living Room",
        id: 1
    },
    {
        brand: 66,
        name: "Accessories",
        id: 5
    },
    {
        brand: 65,
        name: "Accessories",
        id: 5
    },
    {
        brand: 64,
        name: "Lighting",
        id: 4
    },
    {
        brand: 63,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 63,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 63,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 63,
        name: "Accessories",
        id: 5
    },
    {
        brand: 63,
        name: "Living Room",
        id: 1
    },
    {
        brand: 62,
        name: "Living Room",
        id: 1
    },
    {
        brand: 61,
        name: "Accessories",
        id: 5
    },
    {
        brand: 61,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 61,
        name: "Living Room",
        id: 1
    },
    {
        brand: 60,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 60,
        name: "Living Room",
        id: 1
    },
    {
        brand: 59,
        name: "Accessories",
        id: 5
    },
    {
        brand: 57,
        name: "Accessories",
        id: 5
    },
    {
        brand: 56,
        name: "Accessories",
        id: 5
    },
    {
        brand: 55,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 55,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 55,
        name: "Living Room",
        id: 1
    },
    {
        brand: 54,
        name: "Accessories",
        id: 5
    },
    {
        brand: 53,
        name: "Accessories",
        id: 5
    },
    {
        brand: 52,
        name: "Accessories",
        id: 5
    },
    {
        brand: 51,
        name: "Accessories",
        id: 5
    },
    {
        brand: 50,
        name: "Accessories",
        id: 5
    },
    {
        brand: 48,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 48,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 48,
        name: "Living Room",
        id: 1
    },
    {
        brand: 47,
        name: "Accessories",
        id: 5
    },
    {
        brand: 45,
        name: "Lighting",
        id: 4
    },
    {
        brand: 44,
        name: "Accessories",
        id: 5
    },
    {
        brand: 42,
        name: "Accessories",
        id: 5
    },
    {
        brand: 41,
        name: "Accessories",
        id: 5
    },
    {
        brand: 40,
        name: "Living Room",
        id: 1
    },
    {
        brand: 39,
        name: "Accessories",
        id: 5
    },
    {
        brand: 38,
        name: "Accessories",
        id: 5
    },
    {
        brand: 37,
        name: "Lighting",
        id: 4
    },
    {
        brand: 36,
        name: "Accessories",
        id: 5
    },
    {
        brand: 35,
        name: "Living Room",
        id: 1
    },
    {
        brand: 34,
        name: "Lighting",
        id: 4
    },
    {
        brand: 32,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 32,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 32,
        name: "Living Room",
        id: 1
    },
    {
        brand: 31,
        name: "Accessories",
        id: 5
    },
    {
        brand: 30,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 30,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 30,
        name: "Living Room",
        id: 1
    },
    {
        brand: 29,
        name: "Accessories",
        id: 5
    },
    {
        brand: 28,
        name: "Accessories",
        id: 5
    },
    {
        brand: 27,
        name: "Accessories",
        id: 5
    },
    {
        brand: 26,
        name: "Accessories",
        id: 5
    },
    {
        brand: 25,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 24,
        name: "Accessories",
        id: 5
    },
    {
        brand: 23,
        name: "Accessories",
        id: 5
    },
    {
        brand: 23,
        name: "Lighting",
        id: 4
    },
    {
        brand: 22,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 22,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 22,
        name: "Living Room",
        id: 1
    },
    {
        brand: 21,
        name: "Accessories",
        id: 5
    },
    {
        brand: 21,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 21,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 21,
        name: "Living Room",
        id: 1
    },
    {
        brand: 20,
        name: "Accessories",
        id: 5
    },
    {
        brand: 19,
        name: "Accessories",
        id: 5
    },
    {
        brand: 18,
        name: "Accessories",
        id: 5
    },
    {
        brand: 16,
        name: "Accessories",
        id: 5
    },
    {
        brand: 16,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 16,
        name: "Lighting",
        id: 4
    },
    {
        brand: 16,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 16,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 16,
        name: "Living Room",
        id: 1
    },
    {
        brand: 15,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 13,
        name: "Accessories",
        id: 5
    },
    {
        brand: 1,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 1,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 1,
        name: "Living Room",
        id: 1
    },
    {
        brand: 2,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 2,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 2,
        name: "Living Room",
        id: 1
    },
    {
        brand: 3,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 3,
        name: "Lighting",
        id: 4
    },
    {
        brand: 3,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 3,
        name: "Accessories",
        id: 5
    },
    {
        brand: 3,
        name: "Living Room",
        id: 1
    },
    {
        brand: 4,
        name: "Living Room",
        id: 1
    },
    {
        brand: 4,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 4,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 5,
        name: "Lighting",
        id: 4
    },
    {
        brand: 6,
        name: "Accessories",
        id: 5
    },
    {
        brand: 7,
        name: "Lighting",
        id: 4
    },
    {
        brand: 7,
        name: "Dining Room",
        id: 3
    },
    {
        brand: 7,
        name: "Bedroom",
        id: 2
    },
    {
        brand: 7,
        name: "Dinnerware",
        id: 6
    },
    {
        brand: 7,
        name: "Accessories",
        id: 5
    },
    {
        brand: 7,
        name: "Living Room",
        id: 1
    },
    {
        brand: 8,
        name: "Lighting",
        id: 4
    },
    {
        brand: 9,
        name: "Lighting",
        id: 4
    },
    {
        brand: 10,
        name: "Lighting",
        id: 4
    },
    {
        brand: 11,
        name: "Accessories",
        id: 5
    },
    {
        brand: 11,
        name: "Lighting",
        id: 4
    },
    {
        brand: 12,
        name: "Accessories",
        id: 5
    }
]

export default area