<template>
    <div class="filter-dalog-body p-0">
        <div class="p-0 header-panel-container border-bottom border-light">
            <mdb-navbar class="z-depth-0 p-0 pl-2 rounded-sm justify-content-between">
<!--                <mdb-navbar-brand class="table-header-navbar-brand">-->
<!--&lt;!&ndash;                    <mdb-tabs class="table-header-tabs" :active="0" default :links="[ { text: title,slot:'main' }]"  />&ndash;&gt;-->
<!--                </mdb-navbar-brand>-->

                <mdb-form-inline class="ml-auto" tag="div">
                    <input class="form-control mr-sm-1 table-searchterm-input border border-light" v-model="filterObj.term" type="text" placeholder="Search" aria-label="Search" style="margin-top: 6px!important;" @keydown.enter.prevent="handleSearchTerm"/>
                    <mdb-btn color="elegant" size="sm" @click="handleSearchTerm" class="m-1"><i class="el-icon-search"></i> Search</mdb-btn>

                    <template v-if="!isEditable">

                    <mdb-dropdown>
                        <mdb-dropdown-toggle color="elegant" size="sm" slot="toggle"  class="m-1">Points</mdb-dropdown-toggle>
                        <mdb-dropdown-menu style="margin-left: -55px">
                            <mdb-dropdown-item @click="openNewWindow('transaction')">Add Points</mdb-dropdown-item>
                            <mdb-dropdown-item @click="openNewWindow('refund')">Reduce Points</mdb-dropdown-item>
                            <!-- <mdb-dropdown-item @click="openNewWindow('system')">System Transaction</mdb-dropdown-item> -->
                        </mdb-dropdown-menu>
                    </mdb-dropdown>
                    </template>
                </mdb-form-inline>
            </mdb-navbar>
        </div>
        <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="400" stripe>
                <el-table-column type="index" width="35" label="#"> </el-table-column>
                <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                    <template slot-scope="scope">
                        <template v-if="col.field === 'status'">
                            <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.header_status]"><i :class="statusArrayIcon[scope.row.header_status]"></i> {{ scope.row.status_text}}</mdb-badge>
                        </template>
                        <template v-else-if="col.field === 'amount' || col.field === 'total' || col.field === 'balance' || col.field === 'diff'" >
                            {{scope.row[col.field] | amount}}
                        </template>
                        <template v-else>
                            {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}

                        </template>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <template v-if="viewTransactionLoading">
                        <mdb-btn color="elegant" size="sm" rounded disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading data...
                        </mdb-btn>

                    </template>
                    <template v-else>
                        No Data
                    </template>
                </template>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>
<script>
import {
    mdbBtn,
        mdbBadge,
        mdbFormInline,
        mdbNavbar,
        mdbNavbarBrand,
        mdbNavbarToggler,
        mdbNavbarNav,
        mdbNavItem,
        mdbTabs, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle
} from "mdbvue";
import { mapActions, mapMutations ,mapGetters  } from 'vuex'
import { ThatsInput,ThatsTextarea} from '../../components/thats/Thats'


export default {
    components : {
        mdbBtn,
        mdbBadge,
        mdbFormInline,
        mdbNavbar,
        mdbNavbarBrand,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },
    props : {
        endpoint : {
            type : String,
            default : 'sales/invoice'
        },
        title : {
            type : String,
            default : 'Transactions'
        },
        columns : {
            type : Array,
            default : () => {
                return [
                    {
                        label: 'Code',
                        field: 'transaction_code',
                    },
                    {
                        label: 'Date',
                        field: 'date',
                    },
                    {
                        label: 'Points',
                        field: 'amount',
                    },
                    {
                        label: 'Type',
                        field: 'type',
                    }
                ]
            }
        },
        refresh : {
            type : Boolean,
            default : false
        },
        isEditable: {
            type : Boolean,
            default : false
        },

    },
    data(){
        return {
            dialogVisibleSync : false,
            filterObj : {
                term : null
            },
            dialogIsOpen: false,

        }
    },
    mounted(){
        this.dispatchHandler();
    },
    methods : {
        ...mapActions('TableV2Module',[
          'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('TableV2Module',[
            'SetTotal',
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
            'SetFilters',
        ]),
        openDialog(){
            this.dialogVisibleSync = true
        },
        handleSaleTransactionClick(row){
            this.$emit('rowClick',row)
        },
        openNewWindow(data){
            this.$emit('dialogClick', data)
        },

        triggerGetTransaction(obj){
            const self = this;
            this.SetTotal(0)
            this.filterObj = {item_code : obj.series}
            this.SetFilters(this.filterObj)
            this.resetData(this.endpoint).then(() => {
            });
        },
        async dispatchHandler(){
            this.SetFilters(this.filterObj)
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
          this.SetLength(val)

          this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.SetTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        refreshTable(){
            this.SetFilters(this.filterObj)
          this.resetData(this.endpoint);
        },
        dispatchFilter(filters){
            this.SetTotal(0)
            this.SetFilters(filters)
            this.resetData(this.endpoint);
        },
        triggerNewEvent(){
        },
    },
    computed: {
      ...mapGetters('TableV2Module',[
          'data',
          'page',
          'total',
          'length',
          'searchTerm',
          'tableLoading',
          'Selection',
          'viewTransactionLoading',
      ]),
      term : {
          get : function(){
             return this.searchTerm
          },
          set : function(val){
              this.SetTerm (val)
          }
      },
      current_page : {
          get : function(){
             return this.page
          },
          set : function(val){
              this.SetPage (val)
          }
      },
      hasFilter(){
          return !!this.$scopes.filter;
      },
      hasFilterSlot() {
          console.log(this.$scopedSlots)
          // console.log(('filter' in this.$scopedSlots))
          return ('filter' in this.$scopedSlots);
      },
      hasTabsSlot() {
          return ('tabs' in this.$scopedSlots);
      },
      hasRightSlot() {
          return ('top-right' in this.$scopedSlots);
      },
      hasCustomColumnsSlot() {
          return ('custom-culumns' in this.$scopedSlots);
      },
      statusArrayColor : function(){

            return {'draft' : 'badge-light','for-approval' : 'warning','S' : 'warning','P' : 'warning','R' : 'success','open': 'success','canceled' : 'danger','closed' : 'danger'};
        },
        statusArrayIcon : function(){

            return {'draft' : 'el-icon-more','S' : 'el-icon-ship','P' : 'el-icon-circle-check','open': 'el-icon-success','R': 'el-icon-success','canceled' : 'el-icon-remove','closed' : 'el-icon-remove'};
        },
        documentStatus : function(){
            return {'S' : 'In Shipment','P' : 'In Port','R' :'Received' }
        }
  },
}
</script>
