<template>
 <div class="pie-chart">
  <Pie
    ref="pieChart"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="chartStyle"
    :width="width"
    :height="height"

  />
    <ul class="chart-legend">
        <li v-for="(row, index) in localChartLabels" :key="index" :class="{toggled : row.toggle}" :style="getTextStyle(row.toggle)" @click="onLegendClick(row, index)">
            <span :style="{color: row.color}">■</span>
            {{row.label}}
        </li>
    </ul>
 </div>
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
// import ChartDataLabels from 'chartjs-plugin-datalabels'

import {
  Chart as PieChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from 'chart.js'

PieChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => [
        {
        id: "pieLabelsLine",
        afterDraw(chart) {
          const {
            ctx,
            chartArea: { width, height },
          } = chart;

          const cx = chart._metasets[0].data[0].x;
          const cy = chart._metasets[0].data[0].y;

          const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);

          chart.data.datasets.forEach((dataset, i) => {
            chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
              const { x: a, y: b } = datapoint.tooltipPosition();

              const x = 2 * a - cx;
              const y = 2 * b - cy;
              const halfwidth = width / 2;
              const halfheight = height / 2;
              const xLine = x >= halfwidth ? x : x - 20;
              const yLine = y >= halfheight ? y + 20 : y -20;

              const extraLine = x >= halfwidth ? 10 : -10;

              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
              ctx.fill();
              ctx.moveTo(x, y);
              ctx.lineTo(xLine, yLine);
              ctx.lineTo(xLine + extraLine, yLine);
              ctx.strokeStyle = dataset.backgroundColor[index];;
              ctx.stroke();
                const label = chart.data.labels[index];
              const textWidth = ctx.measureText(chart.data.labels[index]).width;
              ctx.font = "12px Arial";
              const textXPosition = x >= halfwidth ? "left" : "right";
              const plusFivePx = x >= halfwidth ? 5 : -5;
              ctx.textAlign = textXPosition;
              ctx.textBaseline = "middle";
              ctx.fillStyle = dataset.backgroundColor[index];

              ctx.fillText(
                ((chart.data.datasets[0].data[index] * 100) / sum).toFixed(2) +
                  "%",
                xLine + extraLine + plusFivePx,
                yLine
              );
            });
          });
        },
      },


       /*
       //Legend spacing
       {
        id: 'LegendMarginPlugin',
        beforeInit(chart, legend, options) {
            const fitValue = chart.legend.fit;

            chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();

            let top;
            const marginTop = 50;

            Object.defineProperty(this, 'top', {
                get() {
                return top + marginTop;
                },
                set(v) {
                top = v;
                },
            });

            this.height += 50;
            };
        },
        } */
      ]
    },
    chartData: {
      type: Object,
      default: () => {}
    },
    toggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {


      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
            padding: 80,

          },



        plugins: {
                legend: {
                    display: false,
                },


               }

        /* plugins: {
          datalabels: {
            color: '#fff',
            anchor: 'end',
            align: 'start',
            offset: -10,
            borderWidth: 2,
            borderColor: '#fff',
            borderRadius: 25,
            backgroundColor: (context) => context.dataset.backgroundColor,
            font: {
              weight: 'bold',
            },
            formatter: (value, context) => {

                console.log("context", context)
              let total = 0;
              let dataArr = context.chart.data.datasets[0].data;
              dataArr.forEach(data => {
                total += data;
              });
              let percentage = ((value / total) * 100).toFixed(2) + "%";
              return percentage;
            }
          }
        } */
      },
    localChartLabels : [],
    localChartData : {
        labels: [],
        datasets: []
    }
    }
  },
  methods : {
        onLegendClick(obj, idx){
            // obj.toggle = !obj.toggle;
            this.localChartLabels = this.localChartLabels.map((label, index) => {
                if (idx === index) {
                    label.toggle = !obj.toggle;;
                }
                return label;
            });

            const chartInstance = this.$refs.pieChart.getCurrentChart();

            console.log("chartInstance", chartInstance)

            chartInstance.getDatasetMeta(0).data[idx].hidden = obj.toggle;
            chartInstance.update();
        },

        getTextStyle(lineThrought) {
            return {
                textDecoration: lineThrought ? 'line-through' : 'none',
            };
        },
  },
  watch: {
    toggle: function() {
      const chartInstance = this.$refs.pieChart.getCurrentChart()
      chartInstance.data.datasets.forEach(function(ds) {
        ds.hidden = !ds.hidden;
      });
      chartInstance.update();
    },


    chartData: {
      immediate: false,
      handler(newChartData) {

    const legendColor = newChartData.datasets[0].backgroundColor;
        const updatedChartData = newChartData.labels.map((label, index) => ({
            label: label,
            color: legendColor[index],
            toggle: false
            }));

            this.localChartLabels = updatedChartData;
      }
    }

  },
  computed: {
    chartStyle() {
      return {
        ...this.styles,
        height: this.height + 'px'
      }
    },

  },
}
</script>

<style scoped lang="scss">

.pie-chart{
    ul.chart-legend{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: stretch;
        align-items: stretch;
        padding: 0;
        margin: 5px;
        list-style: none;
        li{
            order: 0;
            flex: 0 1 auto;
            align-self: auto;
            width : 50%;
            cursor: pointer;
            padding: 0 5px;
            text-transform: uppercase;
            font-size: 12px;
            span{
                margin-right: 5px;
            }
        }
    }
}

</style>
