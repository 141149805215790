import { required, confirmed, length, email, min_value } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import unformat from 'accounting-js/lib/unformat.js'
extend("required", {
    ...required,
    message: "This field is required"
});

extend("email", {
    ...email,
    message: "This field must be a valid email"
});

extend("confirmed", {
    ...confirmed,
    message: "This field confirmation does not match"
});

extend("length", {
    ...length,
    message: "This field must have 2 options"
});
extend("min_value", {
    ...min_value
});

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

extend('equalTo', {
    params: ['target','msg'],
    validate(value, { target }) {
        return unformat(value) === unformat(target);
    },
    message: `{msg}`
});

extend("preventZeroValue", {
    validate: (value) => {
        const unformatValue = unformat(value);
        if (unformatValue <= 0) {
            return false;
        }
        
        return true;
    },
    message: 'Amount must be greater than 0'
});

extend("preventHighValue", {
    validate: (value, { otherValue, validate }) => {
        const unformatValue = unformat(value)
        // console.log(`${value} - ${otherValue} - ${validate}`)
        if(validate === true && unformatValue > otherValue){
            return false;
        }
        
        return true
    },
    params: ["otherValue","msg", "validate"],
    message: '{msg}'
  });

extend("preventOverpaid", {
    validate: (value, { otherValue, validate }) => {
        const unformatValue = unformat(value)
        // console.log(`${value} - ${otherValue} - ${validate}`)
        if(validate === true && unformatValue > otherValue){
            return false;
        }
        
        return true
    },
    params: ["otherValue","msg", "validate"],
    message: '{msg}'
  });

extend("maxDifference", {
    params: ["otherValue", "maxDifference"],
    validate: (value, { otherValue, maxDifference }) => {

        

        console.log(`${value} - other ${otherValue} -- max ${maxDifference}`)
      if (maxDifference === null || maxDifference === 0 || maxDifference >= Math.abs(value - otherValue)) {
        return true;
      }
      return false;
    },
    message:
      "The difference between the two numbers is too great. The maximum allowed is difference is {maxDifference}."
  });