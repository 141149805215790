<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'Tier Benefit'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="40%" class="text-left tier-beneficiary-dialog" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-3">


                <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>

                <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Apply Type" v-model="form.apply_type" :reduce="label => label.code" :reduceLabel="'label'" :options="applyType" :rules="'required'"></ThatsMultiSelect>

                <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Benefit Type" v-model="form.benefit_type" :reduce="label => label.code" :reduceLabel="'label'" :options="benefitType" :rules="'required'"></ThatsMultiSelect>

                <template v-if="form.benefit_type == 'discount'">
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Discount %" vid="discount" name="discount" v-model="form.discount" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Additional Discount %" vid="additional_discount" name="additional_discount" v-model="form.additional_discount" :validated="validated"  size="sm" :show_error="true"/>
                </template>
                <template v-if="form.benefit_type == 'gift'">
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Number of Gift Card" vid="number_of_gift_card" name="number_of_gift_card" v-model="form.number_of_gift_card"  placeholder="Number of Gift Card" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>
                </template>
                <template v-if="form.benefit_type == 'voucher'">
                    <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Number of Voucher" vid="number_of_voucher" name="number_of_voucher" v-model="form.number_of_voucher"  placeholder="Number of Voucher" :validated="validated" :rules="'required'" size="sm" :show_error="true"/>
                </template>

                <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Tier" v-model="form.tier_ids" :reduce="label => label.id" :reduceLabel="'label'" :options="tiers" :rules="''"></ThatsMultiSelect>


                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="defaultChecked3" v-model="form.status" true-value="active" false-value="inactive">
                    <label class="custom-control-label" for="defaultChecked3">Active</label>
                </div>
              </mdbContainer>


                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>

          </ValidationObserver>




  </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsCheckbox,  ThatsMultiSelect} from '../../components/thats/Thats'

export default {
    components: {
        mdbContainer,
        mdbBtn,
        ValidationObserver,
        ThatsInput,
        ThatsCheckbox,
        ThatsMultiSelect,



  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
      designation : [],
        applyType : [
            { label : "Once", code : "once"},
            { label : "Multiple", code : "multiple"}
      ],
        benefitType : [
            { label : "Discount", code : "discount"},
            { label : "Gift", code : "gift"},
            { label : "Promotional", code : "promotional"},
            { label : "Service", code : "service"},
            { label : "Voucher", code : "voucher"}

      ],
        tiers : [],
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();



        this.asyncGetData().then((res) => {
            self.tiers = res;
        })
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */

      ...mapActions('TireModule',[
        'asyncGetData'
      ]),
      ...mapActions('TierBeneficiaryModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
      ...mapMutations('TierBeneficiaryModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          name : "",
            benefit_type : "",
            apply_type : "",
            discount : "",
            additional_discount : "",
            number_of_gift_card : "",
            number_of_voucher : "",
            tier_ids : [],
            status: "active",
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },



        /* end */
        openDialog(){
            this.syncModal = true

        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
        },


      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {

      ...mapGetters('TierBeneficiaryModule',
      [
        'FormStatus',
        'FormRecord',
      ]),




    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
           const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            this.form = {...this.form,...frm}
            this.form.tier_ids = frm.tier_ids
          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>
