<template>
  <div>
    <ValidationObserver class="needs-validation form-custom-format " tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog :modal="true" :fullscreen="false" :title="'Loyalty Discount Calculator'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" class="text-left discount-calculator">
          <div>


              <div class="dialog-scroll-container">



                 <!--  <ThatsInput labelClass="col-sm-6" fieldClass="col-sm-6" label="Document Total" placeholder="0" vid="document_total" name="document_total" v-model="documentTotal" :rules="''" size="sm" :horizontal="true" :readOnly="true"/> -->
                <mdb-row class="tab-row">
                          <mdb-col lg="12" md="12" sm="12" class="mt-2 pb-2">

                    <!--         {{ documentTotal }} -->
                            <el-card shadow="never">
                                <mdb-row>
                                    <mdb-col col="6">Document Total</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(documentTotal) }}</strong></mdb-col>
                                </mdb-row>
                                <!-- <mdb-row>
                                    <mdb-col col="6">New Document Total With Float</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ discountedItemsTotal }}</strong></mdb-col>
                                </mdb-row> -->
                                <mdb-row>
                                    <mdb-col col="6">New Document Total</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(roundAmount(discountedItemsTotal)) }}</strong></mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6">ServiceItemTotal</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(serviceItemTotal) }}</strong></mdb-col>
                                </mdb-row>


                                <mdb-row>
                                    <mdb-col col="12">
                                        Percentage <br />
                                        <vue-slider class="mt-1 mb-1" v-bind="vueSliderOptions" v-model="documentDiscountPercent" :key="`slider-1`"></vue-slider>
                                    </mdb-col>
                                </mdb-row>

                                <mdb-row>
                                    <mdb-col col="4">
                                        <input type="text" v-model="documentDiscountPercent" class="form-control percent-" placeholder="Document Total" @input="percentChanged($event.target.value)"/>
                                    </mdb-col>
                                    <mdb-col col="2">

                                    </mdb-col>
                                    <mdb-col col="6">
<!--                                        <mdb-row>-->
<!--                                            <mdb-col col="6">Payment Amount</mdb-col>-->
<!--                                            <mdb-col col="6" class="text-right"><strong>{{ formatNumber((discountedItemsTotal * (documentDiscountPercent / 100))) }}</strong></mdb-col>-->
<!--                                        </mdb-row>-->
                                        <mdb-row>
                                            <mdb-col col="6">Payment Amount</mdb-col>
                                            <mdb-col col="6" class="text-right"><strong>{{ formatNumber(roundAmount((discountedItemsTotal * (documentDiscountPercent / 100)))) }}</strong></mdb-col>
                                        </mdb-row>
                                    </mdb-col>

                                </mdb-row>


                                <mdb-row>
                                    <mdb-col col="6">Reached Tier</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ (reachedTier != null ? reachedTier.name : "") }}</strong></mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6">Existing Points</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(existingPoints) }}</strong></mdb-col>
                                </mdb-row>

<!--                                <mdb-row>-->
<!--                                    <mdb-col col="6">Total Earned Points</mdb-col>-->
<!--                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber((existingPoints + (discountedItemsTotal * (documentDiscountPercent / 100)))) }}</strong></mdb-col>-->
<!--                                </mdb-row>-->

                                <mdb-row>
                                    <mdb-col col="6">Total Earned Points</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(roundAmount((existingPoints + (discountedItemsTotal * (documentDiscountPercent / 100))))) }}</strong></mdb-col>
                                </mdb-row>

                            </el-card>

<!--
                            {{ data.card }}
 -->



                            <!-- <input type="text" v-model="documentTotal" placeholder="Document Total" readonly/><br />

                            <input type="text" v-model="paymentAmount" placeholder="paymentAmount" readonly/><br />
                   -->

                          <!--   <table border="1">
                                <tr>

                                    <th>Name</th>
                                    <th>Threshold</th>
                                    <th>discountPercent</th>
                                    <th>tierAmountAllocation</th>
                                    <th>tierDiscount</th>
                                    <th>remainingAmount</th>
                                </tr>
                                <tr v-for="(deduction, index) in tierDiscounts" :key="index">

                                    <td>{{ deduction.name }}</td>
                                    <td>{{ deduction.max }}</td>
                                    <td>{{ deduction.discountPercent }}</td>
                                    <td>{{ deduction.tierAmountAllocation }}</td>
                                    <td>{{ deduction.discountValue }}</td>
                                    <td>{{ deduction.remainingAmount }}</td>
                                </tr>
                                </table> -->

                              <!--

                                <button @click="calculateDiscount(paymentAmount,existingPoints)">Calculate Discount</button> -->


                                <!-- <table border="1">
                                <tr>

                                    <th>ItemCode</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th>discount</th>
                                    <th>NetAmount</th>
                                    <th>Remarks</th>
                                </tr>
                                <tr v-for="(item, index) in items" :key="index">

                                    <td>{{ item.code }}</td>
                                    <td>{{ item.price}}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.amount }}</td>
                                    <td>{{ item.discount }}</td>
                                    <td>{{ item.total }}</td>
                                    <td>{{ item.remarks }}</td>
                                </tr>
                                </table> -->

                          <!--       {{ paymentAmount }}




                                {{ reachedTier }}
                                {{ paymentAmount }}
 -->

                                <!--
 -->
<!--


                                <input type="text" v-model="maxDiscountInput" placeholder="Document Total" @input="maxDiscountChanged($event.target.value)"/><br />





                                {{ maxDiscountInput * (documentDiscountPercent / 100)}} -->


                                <!-- <table border="1">
                                <tr>

                                    <th>ItemCode</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total</th>
                                    <th>discount</th>
                                    <th>NetAmount</th>
                                    <th>Remarks</th>
                                </tr>
                                <tr v-for="(item, index) in items" :key="index">

                                    <td>{{ item.code }}</td>
                                    <td>{{ item.price}}</td>
                                    <td>{{ item.qty }}</td>
                                    <td>{{ item.amount }}</td>
                                    <td>{{ item.multiple_tier_discount.percent }}</td>
                                    <td>{{ item.multiple_tier_discount.percent }}</td>
                                    <td>{{ item.multiple_tier_discount.total }}</td>
                                    <td>{{ item.remarks }}</td>
                                </tr>
                                <tr >

                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>{{ discountedItemsTotal }}</td>
                                    <td></td>
                                </tr>

                                <tr >
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>{{ (discountedItemsTotal * (documentDiscountPercent / 100))}}</td>
                                <td></td>
                                </tr>
                                </table> -->
                          </mdb-col>
                      </mdb-row>
                </div>

          </div>
          <template slot="footer">

            <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0 mb-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <mdb-btn class="mt-0 mb-0" color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="(documentDiscountPercent <= 0 ? true : false)"><i class="el-icon-save text-white cursor-pointer" ></i> Apply</mdb-btn>

                        </template>
                        <mdb-btn class="mt-0 mb-0" color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                      </template>
          </template>
      </el-dialog>
      </ValidationObserver>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver } from "vee-validate";
import { mdbBtn,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'


import { MessageBox, Message } from 'element-ui';
import formatMoney from 'accounting-js/lib/formatMoney.js'
import unformat from 'accounting-js/lib/unformat.js'
import VueSlider from 'vue-slider-component'
export default {
  components : {

      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardImage,
      ValidationObserver,
      VueSlider,
       mdbFormInline,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },
        data : {
          type: Object,
          default : () => ({
              total : 0
          })
      },
  },
  inject : [],
  data(){
      return {
        syncModal : false,
        formLoading : false,
        readOnly : false,
        tiers: [
            { min : 0,max: 20000, discountPercent: 0.05, name: "COMFORT", additionalDiscount : 0 },
            { min : 20000,max: 150000, discountPercent: 0.1, name: "ELEGANCE", additionalDiscount : 0 },
            { min : 150000,max: 500000, discountPercent: 0.125, name: "LUXURY", additionalDiscount : .05 },
            { min : 500000,max: 2000000, discountPercent: 0.15, name: "PRESTIGE", additionalDiscount : 0.05 },
            { min : 2000000,max: 9999999, discountPercent: 0.2, name: "TITANIUM", additionalDiscount : 0.10 },
        ],
        tierDiscounts: [],
        reachedTier: null,
        paymentAmount: 0,
        existingPoints: 0,
        items : [],
        calculatedDiscountItems : [],
        documentTotal : 0,
        documentDiscountPercent : 0,
        maxDiscount : 0,
        maxDiscountInput : 0,
        serviceItemTotal : 0,
        vueSliderOptions : {
            dotSize: 14,
            width: 'auto',
            height: 4,
            contained: false,
            direction: 'ltr',
            data: null,
            dataLabel: 'label',
            dataValue: 'value',
            min: 0,
            max: 100,
            interval: 1,
            disabled: false,
            clickable: true,
            duration: 0.5,
            adsorb: false,
            lazy: false,
            tooltip: 'active',
            tooltipPlacement: 'top',
            tooltipFormatter: void 0,
            useKeyboard: false,
            keydownHook: null,
            dragOnClick: false,
            enableCross: true,
            fixed: false,
            minRange: void 0,
            maxRange: void 0,
            order: true,
            marks: false,
            dotOptions: void 0,
            dotAttrs: void 0,
            process: true,
            dotStyle: void 0,
            railStyle: void 0,
            processStyle: void 0,
            tooltipStyle: void 0,
            stepStyle: void 0,
            stepActiveStyle: void 0,
            labelStyle: void 0,
            labelActiveStyle: void 0
        }
      }
  },
  created(){

  },
  mounted(){
      const self = this


  },
  methods : {


      openDialog(){
          this.syncModal = true
      },
      closeDialog(){
          this.syncModal = false
      },
      roundAmount(amount) {
          let integerPart = Math.floor(amount); // Get the integer part of the number
          let fractionalPart = amount % 1; // Get the fractional part of the number

          if (fractionalPart <= 0.49) {
              fractionalPart = 0.00;
          } else if (fractionalPart >= 0.50) {
              fractionalPart = 0.50;
          }else {
              fractionalPart = 0.00;
          }

          return integerPart + fractionalPart;
      },

      calculateDiscount(paymentAmount, existingPoints) {

        const self = this;
        let remainingAmount = paymentAmount;
        let allocationStarted = false;

        const tierDiscounts = [];

        for (const tier of this.tiers) {
          const { min, max } = tier;

          // Determine if the allocation should start in this tier based on existing points
          if (existingPoints >= min && existingPoints < max) {
            allocationStarted = true;
          }

          if (allocationStarted) {
            // Calculate the available capacity in the tier to allocate
            const tierCapacity = max - min;
            const tierAvailableToAllocate =
              existingPoints > min
                ? tierCapacity - (existingPoints - min)
                : tierCapacity;

            // Allocate either the remaining amount or the tier's available capacity, whichever is less
            const tierAmountAllocation = Math.min(
              remainingAmount,
              tierAvailableToAllocate
            );
            const discountValue = tierAmountAllocation * tier.discountPercent;


            console.log("tierAmountAllocation", tierAmountAllocation,remainingAmount,tierAvailableToAllocate)

            tierDiscounts.push({...tier, min, max, tierAmountAllocation, discountValue, remainingAmount });
            remainingAmount -= tierAmountAllocation;
          } else {
            // If allocation hasn't started, append zero for this tier
            tierDiscounts.push({...tier, min, max, tierAmountAllocation: 0,discountValue: 0, remainingAmount : 0});
          }

          // If there's no remaining amount, stop the allocation process
          if (remainingAmount <= 0) {
            break;
          }
        }

        if(this.paymentAmount <= 0 && existingPoints >= 0){
            const initialTier = this.tiers.filter(( { min, max }) => (existingPoints >= min && existingPoints < max),[]);
            const tier = initialTier[0];

            console.log("initialTier")
            console.log(tier)
            const tierAmountAllocation = existingPoints;
            const discountValue = tierAmountAllocation * tier.discountPercent;


            const index = tierDiscounts.findIndex(obj => obj.name === tier.name);

            tierDiscounts[index] = index > -1 ? {...tier, tierAmountAllocation, discountValue, remainingAmount : discountValue } : {...tier, tierAmountAllocation, discountValue, remainingAmount : discountValue };


           /*  tierDiscounts.push({...tier, tierAmountAllocation, discountValue, remainingAmount : discountValue });

            console.log("tierDiscounts")
            console.log(tierDiscounts) */
            /*
            this.reachedTier = {...initialTier[0], tierAmountAllocation}
            this.tierDiscounts = tierDiscounts; */
        }




        const reachedTierObject = tierDiscounts[tierDiscounts.length - 1];
        const reachedTierValue = tierDiscounts.map((item) => item.discountValue).reduce((acc, next) => acc + next, 0);

        this.reachedTier = {...reachedTierObject, value : reachedTierValue}
        this.tierDiscounts = tierDiscounts;



        const NoDiscountItemTotal = this.items.filter((item) => (item.code != null && (item.hasOwnProperty("is_service_item") && item.is_service_item === false)),[]).map((item) => unformat(item.total),[]).reduce((acc, next) => acc + next, 0)
        this.items = this.items.map((item) => {
            let amount = unformat(item.amount);
            let lineTotal = unformat(item.total);
            let lineDiscountTotal = 0
            let lineDiscountPercent = 0
            let lineTotalAfterDiscount= 0
            let discount = 0;
            if (item.discount_type === 'promo' && item.promotions.length > 0) {
                const itemPromo = item.promotions[0];
                discount = this.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);
            }
            if (item.discount_type === 'discount') {
                discount = this.computeDiscount(item.qty, item.price, {}, item.discount_type, item.custom_discount);
            }

            const hasMultipleTier = tierDiscounts.filter((item) => item.discountValue > 0,[]);

            const hasMultipleTierLength = hasMultipleTier.length;

            let remarks = item.remarks;


            if(item.hasOwnProperty("is_service_item") && item.is_service_item === false){
                if(discount == 0){
                    if(hasMultipleTierLength > 1){
                        const discountPercent = (lineTotal/NoDiscountItemTotal)
                        // lineDiscountTotal = this.roundAmount(self.reachedTier.value * discountPercent)
                        // lineTotalAfterDiscount = this.roundAmount(lineTotal - lineDiscountTotal)
                        lineDiscountTotal = self.reachedTier.value * discountPercent
                        lineTotalAfterDiscount = lineTotal - lineDiscountTotal
                        lineDiscountPercent = ((lineDiscountTotal / lineTotal));
                        remarks = 'Loyalty Membership Discount'
                    }else{
                        const currentTier = hasMultipleTier[0]

                        if(currentTier){
                            console.log("currentTier")
                            console.log(currentTier)

                            // lineDiscountTotal = this.roundAmount(lineTotal * currentTier.discountPercent)
                            // lineTotalAfterDiscount = this.roundAmount(lineTotal * (1 - currentTier.discountPercent))
                            lineDiscountTotal = lineTotal * currentTier.discountPercent
                            lineTotalAfterDiscount = lineTotal * (1 - currentTier.discountPercent)
                            lineDiscountPercent = currentTier.discountPercent;

                            console.log(lineTotalAfterDiscount,lineDiscountPercent)
                            remarks = `${currentTier.name} Discount`
                        }



                    }
                }



                if(discount > 0){

                    if(hasMultipleTierLength > 1){
                        const lineAdditionalDiscount = lineTotal * self.reachedTier.additionalDiscount
                        const additionalDiscount =  discount + lineAdditionalDiscount
                        // lineDiscountTotal = this.roundAmount(additionalDiscount);
                        // lineDiscountPercent = this.roundAmount(((lineDiscountTotal / amount)));
                        // lineTotalAfterDiscount = this.roundAmount(amount - lineDiscountTotal)
                        lineDiscountTotal = additionalDiscount;
                        lineDiscountPercent = ((lineDiscountTotal / amount));
                        lineTotalAfterDiscount = amount - lineDiscountTotal
                        remarks = 'Loyalty Membership Discount'
                    }else{
                        const currentTier = hasMultipleTier[0]

                        if(currentTier){
                            const lineAdditionalDiscount = lineTotal * (currentTier?.additionalDiscount || 0)
                            const additionalDiscount =  discount + lineAdditionalDiscount
                            // lineDiscountTotal = this.roundAmount(additionalDiscount);
                            // lineDiscountPercent = this.roundAmount(((lineDiscountTotal / amount)));
                            // lineTotalAfterDiscount = this.roundAmount(amount - lineDiscountTotal)
                            lineDiscountTotal = additionalDiscount;
                            lineDiscountPercent = ((lineDiscountTotal / amount));
                            lineTotalAfterDiscount = amount - lineDiscountTotal
                            remarks = `${currentTier?.name}`
                        }
                        console.log("currentTier discounted item")
                        console.log("currentTier")
                        console.log(currentTier)

                    }
                }
            }else{
                lineDiscountTotal = 0
                lineDiscountPercent = 0
                lineTotalAfterDiscount = amount
            }

            return {
                ...item,
                multiple_tier_discount : {
                    apply : true,
                    discount : lineDiscountTotal,
                    percent : lineDiscountPercent,
                    value : lineTotalAfterDiscount / item.qty,
                    total : lineTotalAfterDiscount
                },
                remarks : remarks
            }
        })

      },

      calculate() {
        const self = this;
        self.items = self.data.items.filter((item) => {
            return item.code !== null && item.name !== null
        },[]).map((item) => {
            let discount = 0;
            let amount = parseInt(item.qty) * parseFloat(item.price);
            let remarks = null;
            let total = 0;
            if(item.code !== null && item.name !== null){
                if (item.discount_type === 'promo' && item.promotions.length > 0) {
                    const itemPromo = item.promotions[0];
                    discount = this.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);
                    console.log("Promo discount", discount)
                    remarks = this.setRemarks(itemPromo);
                }
                if (item.discount_type === 'discount') {
                    discount = this.computeDiscount(item.qty, item.price, {}, item.discount_type, item.custom_discount);
                    remarks = this.setRemarks(item.custom_discount);
                }
                total = amount - discount;
            }
          return {
            ...item,
            amount : amount,
            discount : self.formatNumber(discount),
            total : self.formatNumber(total),
            remarks : remarks,
            multiple_tier_discount : {
                apply : false,
                discount : 0,
                percent : 0,
                value : 0
            },

          };
        });
      },
    formatNumber(n) {
        return formatMoney(n, { symbol: "", precision: 2, thousand: ",", decimal: "." })
    },

    computeDiscount(qty, price, promo, discount_type, custom_discount) {
        if (discount_type !== null) {
            let apply = discount_type === 'promo' ? promo.apply : (custom_discount !== null && custom_discount.hasOwnProperty("apply") ? custom_discount.apply : false);
            let discount = discount_type === 'promo' ? promo.discount : (custom_discount !== null && custom_discount.hasOwnProperty("discount") ? custom_discount.discount : 0);
            const p = parseFloat(price) * qty;
            const discountPrice = apply ? parseFloat(p) * (discount / 100) : parseFloat(discount);
            return discountPrice;
        }
        return 0;
    },

    setRemarks(promo) {
        return promo.apply ? (promo.discount > 0 ? `${promo.discount}% Discount` : "") : (promo.discount > 0 ? `${this.formatNumber(promo.discount)} QAR Off` : "");
    },
    paymentAmountChanged(value){
        this.calculateDiscount(value,this.existingPoints)
    },

    percentChanged(value){
        this.paymentAmount =  (this.documentTotal * (parseInt(value) / 100))

        console.log("this.paymentAmount")
        console.log(this.paymentAmount)
        console.log(this.documentTotal)
        console.log((parseInt(value) / 100))
        console.log(value)
        this.calculateDiscount(this.paymentAmount,this.existingPoints)
        this.maxDiscountInput = this.items.map((item) => unformat(item.multiple_tier_discount.total),[]).reduce((acc, next) => acc + next, 0)

    },

    maxDiscountChanged(value){
        const getPercent = (value / this.documentTotal)

        this.paymentAmount =  (this.documentTotal * getPercent)
        this.documentDiscountPercent = getPercent * 100
        console.log("getPercent")
        console.log(getPercent)
    },


    onSubmitForm(){

        console.log("beforeApply",this.data)
        const self = this;
        const data = {
            ...this.data,
            paymentAmount : (this.discountedItemsTotal * (this.documentDiscountPercent / 100)),
            paymentPercent : (this.documentDiscountPercent / 100),
            documentValue : this.discountedItemsTotal,
            tierDiscounts : this.tierDiscounts,
            reachedTier : this.reachedTier,
            attr : {
                ...this.data.attr,
                multiple_tier_discount : {
                    apply : true,
                    discount : this.discountedItemsTotal * (this.documentDiscountPercent / 100),
                    percent : this.documentDiscountPercent,
                    value : this.discountedItemsTotal - (this.discountedItemsTotal * (this.documentDiscountPercent / 100)),
                    tierDiscounts : this.tierDiscounts,
                }
            },
            items : JSON.parse(JSON.stringify(this.items.map((item) => {
                return {
                    ...item,
                    amount : self.formatNumber(item.amount),
                    discount : self.formatNumber(item.multiple_tier_discount.discount),
                    total : self.formatNumber(item.multiple_tier_discount.total)
                }
            }))),
        }

        MessageBox.confirm('Are you sure you want to apply tier discount? This action will overwrite discounts', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then(() => {
             console.log("emitApply",data)
        this.$emit('apply',data)
        this.syncModal = false
        })
    }

  },

  computed : {
   /*  itemsTotal(){
        const total = this.items.map((item) => unformat(item.total),[]).reduce((acc, next) => acc + next, 0)
        const percent = parseFloat(this.form.more_discount.percent) / 100;

        return total - (total * percent)

    } */

    discountedItemsTotal(){
        // return this.items.map((item) => unformat(item.multiple_tier_discount.total),[]).reduce((acc, next) => acc + next, 0)
        let total =  this.items.map((item) => unformat(item.multiple_tier_discount.total),[]).reduce((acc, next) => acc + next, 0)
        console.log(this.items, "New Document Total")
        // return this.roundAmount(total);
        return total;
        /* const percent = parseFloat(this.data.more_discount.percent) / 100;
        return total - (total * percent) */


    }
  },

  watch : {
      syncModal : function(value){
        const self = this;
        if(value){

            this.calculate()
            const total = this.items.map((item) => unformat(item.total),[]).reduce((acc, next) => acc + next, 0)


            console.log("syncModal")
            console.log(total)
            /* const percent = parseFloat(this.data.more_discount.percent) / 100;
            this.documentTotal = total - (total * percent);  */
            // this.documentTotal = total
            // this.documentTotal = this.roundAmount(total);
            this.documentTotal = total;
            this.documentDiscountPercent = 100;
            this.paymentAmount =  unformat(this.documentTotal)
            this.existingPoints = this.data.card.points
            this.calculateDiscount(this.paymentAmount,this.existingPoints)

            this.maxDiscount = this.items.map((item) => unformat(item.multiple_tier_discount.total),[]).reduce((acc, next) => acc + next, 0)
            this.maxDiscountInput = this.maxDiscount

            this.serviceItemTotal = this.items.filter((item) => (item.code != null && (item.hasOwnProperty("is_service_item") && item.is_service_item === true)),[]).map((item) => unformat(item.total),[]).reduce((acc, next) => acc + next, 0)
            //const percent = parseFloat(this.data.more_discount.percent) / 100;
           // this.maxDiscount =  maxTotal - (maxTotal * percent)

        }
      },

      /* "data.items" : {
          handler : function(value){

            const self = this;
            this.calculate()

          },
          deep : true
      } */

      documentDiscountPercent : function(value){
        this.paymentAmount =  (this.documentTotal * (parseInt(value) / 100))

        console.log("this.paymentAmount")
        console.log(this.paymentAmount)
        console.log(this.documentTotal)
        console.log((parseInt(value) / 100))
        console.log(value)
        this.calculateDiscount(this.paymentAmount,this.existingPoints)
        this.maxDiscountInput = this.items.map((item) => unformat(item.multiple_tier_discount.total),[]).reduce((acc, next) => acc + next, 0)
      }
  }
}
</script>
