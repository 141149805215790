<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :title="'Redeem Voucher'" :visible.sync="syncModal" :close-on-click-modal="false" width="60%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>

                  <div class="filter-dalog-body p-0">
                      <div class="mt-2">
                          <mdb-card class="custom-mdb-card">
                              <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                  <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                      <strong>{{ customer_date.name }} - {{customer_date.series}}</strong> <br />
                                      <strong>{{ customer_date.mobile }}</strong> <br />
                                      <strong>{{ customer_date.email }}</strong> <br />
                                  </div>
                              </mdb-card-body>
                          </mdb-card>
                          <mdb-row class="tab-row">
                              <mdb-col lg="12" md="12" sm="12" class="mt-2 pl-0 pr-0">
                                  <thats-fieldset class="m-2">
                                      <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Voucher Code" placeholder="Voucher Code" vid="code" name="code" v-model="form.code" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />

                                      <ThatsInput :show_error="true" tabindex="1" labelClass="col-md-2" fieldClass="col-md-10 pl-lg-0 pl-md-0" label="Ref Number" placeholder="Ref Number" vid="invoice_number" name="invoice_number" v-model="form.invoice_number" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />
                                      <ThatsAutocomplete
                                          :clearable="false"
                                          tabindex="6"
                                          :addButton="false"
                                          :appendButton="false"
                                          labelClass="col-md-2"
                                          fieldClass="col-md-10 pl-lg-0 pl-md-0"
                                          label="Store"
                                          v-model="form.store"
                                          :reduce="store => store.code"
                                          :options="store"
                                          :rules="'required'"
                                          disabled>
                                      </ThatsAutocomplete>
                                      <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Redeem Date" placeholder="Redeeme Date" vid="date" name="date" v-model="form.redeem_date" :validated="validated"  size="sm" :rules="'required'"/>
                                      <div ref="textareaWrapper">
                                       <ThatsTextarea :showLabel="true" :rows="5" tabindex="15" :labelClass="'col-md-2'" :fieldClass="'col-md-10 pl-lg-0 pl-md-0 item-textarea'" label="Remarks" placeholder="Remarks" vid="remarks" name="remarks" v-model="form.remarks" size="sm"/>
                                      </div>
                                  </thats-fieldset>
                              </mdb-col>
                          </mdb-row>
                      </div>
                  </div>

                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>
          </ValidationObserver>


  </div>
</template>
<script>
import { mdbBtn, mdbCard,mdbCardBody,mdbRow,mdbCol } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsDatePicker,ThatsTextarea,ThatsFieldset,ThatsInput,ThatsAutocomplete } from '../../components/thats/Thats'
import {MessageBox} from "element-ui";


export default {
    components: {
        mdbBtn,
        mdbCard,
        mdbRow,
        mdbCol,
        mdbCardBody,
        ValidationObserver,
        ThatsDatePicker,
        ThatsFieldset,
        ThatsInput,
        ThatsAutocomplete,
        ThatsTextarea
  },


    inject:['showroomsProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : {
          id:'',
          remarks:'',
          code:'',
          invoice_number:'',
          store:'palette',
          redeem_date:''
      },
        store: [
            { label: 'PALETTE', code: 'palette' },
            { label: 'KARMELLO', code: 'karmello' },
            { label: 'KALIAN', code: 'kalian' },
            { label: 'CACCIA', code: 'caccia' },
        ],
      formData : null,
        customer_date: {
            name: '',
            mobile: ''
        },
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        isWalkin : false,
      disabled : false,
      isModal : false,
        readOnly : true,
      group2 : 3
    }),

    created : function() {
      var self = this;

      if(this.formStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();



    },
  mounted() {
      //

  },
  methods: {

      ...mapMutations('VoucherModule',['cancelRequest']),
      ...mapActions('VoucherModule',['redeemVoucher']),


      formVar(){
        return {
            id:'',
            remarks:'',
            code:'',
            invoice_number:'',
            store:'palette',
            redeem_date:''
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },
    openNewDialog(data) {
      this.customer_date = data.customer;
      this.form.code = data.code;
      this.form.id = data.id;
      this.form.invoice_number = data.attr.document.series;
      this.syncModal = true;
    },


    /* end */
        openDialog(){
            this.syncModal = true
        },




        closeDialog(){
            this.syncModal = false
          this.FormResetter();
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
        },




      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true

                      if(self.formStatus == 'update'){
                          MessageBox.confirm('Are you sure you want to update the status to redeem?', 'Warning', {
                              confirmButtonText: 'OK',
                              cancelButtonText: 'Cancel',
                              type: 'warning'
                          }).then(() => {
                              self.redeemVoucher(self.form).then((response) => {
                                  self.$ThatsNotify.success({
                                      message : "Record successfully updated."
                                  })
                                  self.closeDialog();
                                  self.$emit('success',response)
                                  self.FormResetter();

                              }).catch((error) => {
                                  self.formLoading = false;

                                  if (error.response) {
                                      if (error.response.status === 404) {
                                          self.$ThatsNotify.error({
                                              message: error.response.data.error
                                          });
                                      } else if (error.response.status === 422) {
                                          // Handle coupon not found error
                                          self.$ThatsNotify.error({
                                              message: error.response.data.error
                                          });
                                      }
                                  } else if (error.request) {
                                      error.response.data.message = error.request;
                                  } else {
                                      error.response.data.message = error.message;
                                  }

                                  return;
                              })

                          }).catch(() => {
                              // On cancel, close the dialog
                              self.closeDialog();
                              self.FormResetter();
                          });


                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        // this.SetFormStatusCreate();
        this.showError = false

      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.formStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {
        ...mapGetters('VoucherModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),
    },

    watch: {

        syncModal: function(newDialogIsOpen) {
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.custom-z-index {
  z-index: 0 !important; /* Set the desired z-index */
}
.custom-mdb-card {
    font-weight: 400;
    border: 0;
    max-width: 1080px;
    margin: 0px 10px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12) !important;
}
</style>
