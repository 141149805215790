import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const SalesOrderModule = {
    namespaced: true,
    state: {
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '150',
                },
                {
                    label: 'Tracking No.',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width: '120',
                },
                
                {
                    label: 'Item Name',
                    field: 'item_names',
                },
                {
                    label: 'Customer/Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Total Amount',
                    field: 'total',
                    align : 'right'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    align: 'right',
                    width: '100',
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {}

    
    },
    actions: {
        async getNextCodePayment({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales-order/payment/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('sales-order/filter-item', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('sales-order/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getRecord({ rootState, commit }, params) {
            const { viewLoading } = rootState.SalesOrderModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`sales-order/item/${params.token_id}`, params).then((response) => {
                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = rootState.SalesOrderModule
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('sales-order', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.SalesOrderModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales-order',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ rootState, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = rootState.SalesOrderModule.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update('sales-order',payload).then((response) => {
                        commit('setPutLoadingFalse')
                        commit('setFormRecord', response.data.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async deleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesOrderModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('sales-order',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.SalesOrderModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales-order/save-import',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesOrderModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales-order/payment',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
    }
}


