<template>
    <div class="component-container">

        <div class="toolbar-container">
            <button class="btn btn-sm btn-elegant" @click="childClick">Import</button>
        </div>

        <div id="app-vue-spreadsheet" :style="appVueSpreadSheet">
            <div ref="spreadsheetx"></div>
        </div>


        <hsc-window-style-metal>
            <hsc-window title="Generate Serial Number" :resizable="false" :left="0" :top="0" :height="height" style="z-index : 999!important;width:100%" class="export-window" :closeButton="true" :isOpen.sync="dialogIsOpen">
                <div class="toolbar-container">

                    <template v-if="exporDataSaving">
                        <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </template>
                    <template v-else>
                        <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave">Save</button>
                    </template>

                    <template v-if="isSave">
                        <button class="btn btn-sm btn-elegant" @click="downloadCsv">Download CSV</button>
                        <button class="btn btn-sm btn-elegant" @click="copyTable">Copy</button>
                    </template>
                    
                </div>
                <div class="window-export-container" :style="appVueSpreadSheet">

                   
                    <div ref="spreadsheetexport" class="demo-x"></div>
                </div>
            </hsc-window>
        </hsc-window-style-metal>

    </div>
</template>

<script>

import moment from 'moment';
import jspreadsheet from '../../jsexcel/jexcel'


import country from './country'
import brands from './brand'
import category from './category'
import collection from './collection'
import area from './area'


const dropdown = function(instance, cell, c, r, source) {
    // Get a value from the same row but previous column
    // var value = $(instance).jspreadsheet('getValue', c-1 + '-' + r);
   /*  const value = instance.getValue();

    console.log(value)
    console.log(cell)
    console.log(source) */

    const a = instance.jspreadsheet('getValue', c-1 + '-' + r);

    console.log(a)

    console.log(instance)
    console.log(cell)
    console.log(c)
    console.log(r)
    console.log(source)

    return ['Apples','Bananas','Oranges'];

  /*   // Return the values will be part in your current column
    if (value == 1) {
        return ['Apples','Bananas','Oranges'];
    } else if (value == 2) {
        return ['Carrots'];
    } else {
        return source;
    } */
}

const onChanged = function(v){
    console.log(v)
}

/* const filterArea = function(){
    instance, cell, c, r, source
}
 */

import {mapActions} from 'vuex'
export default {

    data: ()=> ({
        isSave : false,
        appVueSpreadSheet : {
            width : '1300px'
        },
        width : 1300,
        height : 1024,
        dialogIsOpen : false,
        spreadsheet: null,
        spreadsheetexport: null,
        processing: false,
        exporData : [],
        exporDataSaving : false,
        options : {
            tableOverflow: true,
            tableWidth: "400px",
            tableHeight: "400px",
            wordWrap : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            autoIncrement : true,
             data:[[]],
             minDimensions:[10,50],
             onchange: function(instance, cell, c, r, value) {
                 const row = parseInt(r)
                 const column = parseInt(c)

                 if(c == 3){
                      const results = area.filter((item) => {
                        return item.brand == value
                    })
                     /* if(results.length > 0){
                        instance.jspreadsheet.setValueFromCoords( column + 1, row, results[0].id);
                    } */
                 }
                 
            },
             columns: [
                  {
                    type: 'number',
                    width: '150px',
                    title: 'Index',
                    name: 'index',
                    primaryKey: true
                },
                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'series',
                    readOnly: false,
                },
                {
                    type: 'text',
                    width: '250px',
                    title:'ItemName',
                    name:'name'
                },
                 {
                    /* type: 'numeric',
                    width: '200px',
                    title:'Price',
                    mask:'QAR #,###.00', decimal:',',
                    name:'price' */

                    type: 'numeric',
                    title:'Price',
                    mask:'#,##.00',
                    width:100,
                    decimal:'.',
                     name:'sale_price'
                },
                 {
                    type: 'text',
                    width: '180px',
                    title:'Dimension',
                    name:'dimension',
                },

                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Brand',
                    name:'brand',
                    autocomplete:true,
                    source:brands,
                    multiple:false,
                },
               
                
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Area',
                    name:'area',
                    autocomplete:true,
                    source:area,
                    multiple:false,
                    filter: function(instance, cell, c, r, source){

                        const getBrandRow = instance.jspreadsheet.getValueFromCoords(5,r);

                       
                            if(getBrandRow){
                                return area.filter((el) => {
                                    return el.brand == getBrandRow 
                                })
                            }

                            return [];
                    }
                },

                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Category',
                    name:'category',
                    autocomplete:true,
                    source: category,
                    multiple:false,
                    filter : function(instance, cel, col, row, source ){
                        const getBrandRow = instance.jspreadsheet.getValueFromCoords(5,row);
                        const getAreaRow = instance.jspreadsheet.getValueFromCoords(6,row);
                          // return [`${getBrandRow} -- ${getAreaRow}`]
                            if(getAreaRow && getBrandRow){                                
                                const c = category.filter((item) => {
                                        return item.brand == getBrandRow && item.area == getAreaRow
                                    });



                                    // return c;

                                    return c
                            }
                        return [];
                    }
                },
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Collection',
                    name:'collection',
                    autocomplete:true,
                    source:collection,
                    multiple:false,
                     filter : function(instance, cel, col, row, source ){
                            const getBrandRow = instance.jspreadsheet.getValueFromCoords(5,row);

                            
                            if(getBrandRow){
                                const c = collection.filter((item) => {
                                        return item.brand_id == getBrandRow
                                    });

                                    return c
                            }
                        return [];
                    }
                },

                {
                    type: 'text',
                    width: '200px',
                    title:'Description',
                    name:'description',
                    readOnly: false,
                },
                {
                    type: 'text',
                    width: '100px',
                    title:'Upload Status',
                    name:'update_status',
                    readOnly: true,
                },
                
               
            ],

            contextMenu: function(obj, x, y, e) {
                var items = [];

                if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                }
                return items;
             }
        },
        exportTableOptions : {
            download : true,
            csvFileName : 'serial-number',
            allowDeleteColumn : false,
            allowRenameColumn : false,
            csvHeader:true,
            tableWidth : '1300px',
            tableHeight : 'calc(100vh - 92px)',
            tableOverflow : true,
             data:[[]],
             minDimensions:[6,35],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                {
                    type: 'autonumber',
                    width: '150px',
                    title: 'Index',
                    name: 'index',
                    readOnly: true,
                    primaryKey: true
                },
                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'item_code',
                    readOnly: true,
                    primaryKey: true
                },
                {
                    type: 'text',
                    width: '250px',
                    title:'ItemName',
                    name:'item_name',
                    readOnly: true,
                },
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Brand',
                    name:'brand',
                    autocomplete:true,
                    source:country,
                    multiple:false,
                    readOnly: true,
                },
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Category',
                    name:'category',
                    autocomplete:true,
                    source:country,
                    multiple:false,
                    readOnly: true,
                },
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Area',
                    name:'area',
                    autocomplete:true,
                    source:country,
                    multiple:false,
                    readOnly: true,
                },

                {
                    type: 'text',
                    width: '80px',
                    title:'Description',
                    name:'description',
                    readOnly: true,
                },
                {
                    type: 'calendar',
                    width: '100px',
                    title:'ReceivedDate',
                    name: 'received_date',
                    readOnly: true,
                },
                {
                    type: 'numeric',
                    width: '200px',
                    title:'Quantity',
                    name:'quantity',
                    readOnly: true,
                },
                {
                    type: 'number',
                    width: '200px',
                    title:'Sequence',
                    name:'sequence',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '200px',
                    title:'SerialNumer',
                    name:'serial',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '250px',
                    title:'Barcode',
                    name:'barcode',
                    readOnly: true,
                },
            ],

             contextMenu: function(obj, x, y, e) {
                // var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                } */
                return false;
             }
        },

        itemForm : {
          item_code : 0,
          parent : 0,
          name : null,
          slug : null,
          sale_price : 0,
          promo_price : 0,
          dimension : null,

          brand : null,
          area : null,
          category : null,
          collection : null,

          item_type : 'stock',
          description : "",
          website : 1,
          attr : {
              cube_unit : "",
              attribute : "",
              post_views : 0,
              materials : "",
              materials1 : "",
              fabric : "",
              finishes : "",
              sub_colour : "",
              colour : "",
              colour1 : "",
              colour2 : "",

              future_stock : {
                qty : 0,
                eta_text : "",
                eta : []
              },

              dimension_height : "",
              dimension_width : "",
              dimension_depth : "",
              dimension_diameter : "",
              hole : "",
              capacity : "",
              weight : "",
              meta_title : "",
              meta_keyword : "",
              meta_description : "",
              visible_polish : false,
              barcode : "",
              old_code : "",
          },

          child: [],
          order_eta: [],

          image: [],
          images: [],
          location: [],

          qty : 0,
          status : '1'
          
        },
        brands : []
    }),
    components : {
  
       
    },
    mounted() {
        // this.options.tableWidth = `${window.innerHeight}px`
        const self =this;
        this.exportTableOptions.tableWidth = `${window.innerWidth}px`
        this.appVueSpreadSheet.width = `100%`
        this.options.tableWidth = `100%`
        this.options.tableHeight = `calc(100vh - 92.43px)`
        // this.options.onChange = onChanged
        this.spreadsheet = jspreadsheet(this.$refs.spreadsheetx, this.options);

        Object.assign(this, this.spreadsheet);

     /*    this.spreadsheet.onchange(function(v){
            alert(v)
        }) */

        
        
        this.height = window.innerHeight;
      /*   self.GetBrandErp().then(response => {
            const brand = [];
            response.forEach(element => {
              brand.push({"name": element.label, "id": element.value});

            });

            
            self.brands = brand
          });
        console.log("area")
           self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
           console.log(JSON.parse(JSON.stringify(response)))
        })
        console.log("category")
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
             console.log(JSON.parse(JSON.stringify(response)))
        }) */
       /*   console.log("collections")
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
             console.log(JSON.parse(JSON.stringify(response)))
        }) */

    },
    methods : {
        /* ...mapActions('ItemModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
        'GetBrandRelationship'
        ]),
        ...mapActions('BrandModule',['GetBrandErp','GetArea']), */
        ...mapActions('ItemModule',['StoreImport']),
        onCellChanged(v){
            console.log(v)
        },
       async childClick(){
            
            const self = this;

            if(this.processing === false){
                // this.exporDataSaving = false;
                
                const tableData = this.spreadsheet.getJson();
                let exporDataArray = [];
                if(tableData.length > 0){

                    
                    
                    const filterData = tableData.filter(function(rowElement){
                        return (rowElement.series != "" && rowElement.name != "" && rowElement.price != "" && rowElement.brand != "" && rowElement.area != "" &&rowElement.category != "" && rowElement.collection != "");
                    })

                    if(filterData.length > 0){
                        // this.processing = true;
                        var serialResponse = [];
// 

                        filterData.forEach(element => {


                            // 

                            // this.spreadsheet.setStyle(s);
                           
                        //    this.spreadsheet.setValueFromCoords( 10, i-1, "Done");
                        //    this.spreadsheet.setValue(`M${i}`,'Success',true);

                           /*  
                            this.spreadsheet.setStyle(`A${i}`,'background-color','green'); */

                            


                            const importItem = {
                                series : 0,
                                parent : 0,
                                name : null,
                                slug : null,
                                sale_price : 0,
                                promo_price : 0,
                                dimension : null,

                                brand : null,
                                area : null,
                                category : null,
                                collection : null,

                                item_type : 'stock',
                                description : "",
                                website : 1,
                                attr : {
                                    cube_unit : "",
                                    attribute : "",
                                    post_views : 0,
                                    materials : "",
                                    materials1 : "",
                                    fabric : "",
                                    finishes : "",
                                    sub_colour : "",
                                    colour : "",
                                    colour1 : "",
                                    colour2 : "",

                                    future_stock : {
                                        qty : 0,
                                        eta_text : "",
                                        eta : []
                                    },

                                    dimension_height : "",
                                    dimension_width : "",
                                    dimension_depth : "",
                                    dimension_diameter : "",
                                    hole : "",
                                    capacity : "",
                                    weight : "",
                                    meta_title : "",
                                    meta_keyword : "",
                                    meta_description : "",
                                    visible_polish : false,
                                    barcode : "",
                                    old_code : "",
                                },

                            child: [],
                            order_eta: [],

                            image: [],
                            images: [],
                            location: [],

                            qty : 0,
                            status : '1',
                            ...element
                            
                            }
                            this.StoreImport(importItem).then((response) => {
                            //   self.$ThatsNotify.success()
                            //   self.$emit('success',response);
                            //   self.exporDataSaving = false;
                              self.isSave = true;

                              console.log(response);

                              if(response.index){
                                   const i = parseInt(response.index);

                                // self.spreadsheet.setValueFromCoords( i, 10, "Done");
                                self.spreadsheet.setStyle(`A${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`B${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`C${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`D${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`E${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`F${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`G${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`H${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`I${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`J${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`K${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`L${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`M${i}`,'background-color','green');
                                self.spreadsheet.setStyle(`N${i}`,'background-color','green');

                                // this.spreadsheet.setValueFromCoords( i, 10, "Done");
                                

                              }

                             


                            

                            }).catch((error) => {
                                // console.log(error);
                                // console.log(error.response.data);
                                if(error.response.data.index){
                                    const i = parseInt(error.response.data.index);
                                    self.spreadsheet.setStyle(`A${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`B${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`C${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`D${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`E${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`F${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`G${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`H${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`I${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`J${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`K${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`L${i}`,'background-color','red');
                                    self.spreadsheet.setStyle(`M${i}`,'background-color','red');
                                }

                                
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       });
                        });


                        



                        //  this.spreadsheet.setValueFromCoords( 0, 5, "lorem");



                       /*  await this.getSerial({"serial" : filterData}).then((response) => {
                                serialResponse = response
                            });

                                filterData.forEach(rowElement  => {
                                   const itemQty = parseInt(rowElement.quantity);
                                    const datestring = moment(rowElement.received_date).format('YYYY-MM-DD');
                                    const monthYear = moment(rowElement.received_date).format('YYYYMM');


                                    const foundExactItem = serialResponse.filter((e) => {
                                        return (e.item_code = rowElement.item_code && e.origin == rowElement.origin && e.received_date == datestring  && e.vendor_code == rowElement.vendor_code && e.origin == rowElement.origin)
                                    })[0]

                                    const foundMonth = serialResponse.filter((e) => {
                                        return (e.item_code = rowElement.item_code && e.origin == rowElement.origin && e.month_year == monthYear  && e.vendor_code == rowElement.vendor_code && e.origin == rowElement.origin)
                                    })[0]

                                    if((foundExactItem && foundMonth) || serialResponse.length <= 0){

                                        for (let index = 1; index <= itemQty; index++) {

                                            const  sequence = index.toString().padStart(5, '0');
        
                                            const datestring = moment(rowElement.received_date).format('YYMM');
        
                                            const serial = `${rowElement.origin}${rowElement.vendor_code}${datestring}${sequence}`;
                                            const barcode = `${rowElement.item_code}@${serial}`;
                                            
                                            exporDataArray.push({...rowElement ,"sequence" : sequence,"serial" : serial,"barcode" : barcode}) 
                            
                                        }
                                    }
                                    if(foundExactItem == null && foundMonth){
                                        const startFrom = parseInt(foundMonth.last_sequence)

                                        for (let index = startFrom; index <= startFrom+itemQty; index++) {

                                            const  sequence = index.toString().padStart(5, '0');
        
                                            const datestring = moment(rowElement.received_date).format('YYMM');
        
                                            const serial = `${rowElement.origin}${rowElement.vendor_code}${datestring}${sequence}`;
                                            const barcode = `${rowElement.item_code}@${serial}`;
                                            
                                            exporDataArray.push({...rowElement ,"sequence" : sequence,"serial" : serial,"barcode" : barcode}) 
                            
                                        }
                                        
                                    }

                                  
                                });

                                

                                if(exporDataArray.length > 0){
                                    this.exporData = exporDataArray
                                    this.exportTableOptions.allowCopy = false
                                    this.dialogIsOpen = true
                                    this.processing = false;
                                } */

                    }

                    

                    
                }
    
                
            }
        },

        persistData(){
            const self =this;
            if(this.exporDataSaving == false){
                this.exporDataSaving = true;
                
                const jsonData = this.spreadsheetexport.getJson();

                // console.log(jsonData)

                const filterData = jsonData.filter(function(rowElement){
                        return (rowElement.item_code != "" && rowElement.item_name != "" && rowElement.origin != "" && rowElement.quantity != "" && rowElement.received_date != "" &&rowElement.vendor_code != "");
                    })
                
                this.storeRecord({"serial" : filterData}).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response);
                              self.exporDataSaving = false;
                              self.isSave = true;
                            }).catch((error) => {
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       });
            }
        },

        downloadCsv(){
            this.spreadsheetexport.download(true);
        },
        destroyx(){
            // this.spreadsheetexport.destroy();
            //this.exportTableOptions.allowCopy = true
            /*  this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);
             this.spreadsheetexport.setWidth(window.innerWidth) */

             this.recreateSpreadsheet();
             this.exportTableOptions.allowCopy = true
             this.createPopupTable(this.exportTableOptions)
             
        },

        copyTable(){
            this.spreadsheetexport.copy()
        },

        createPopupTable(exportTableOptions){
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.exporData)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        }
    },
    computed: {
       
    },

    watch : {

        dialogIsOpen : function(v){
            if(v === false){
                // this.spreadsheetexport.setData([[]]);
                // this.spreadsheetexport = null;

                this.spreadsheetexport.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }
        }
    }

}
</script>