
<template>
    <div class="customervendor-container">
        <ThatsTable title="Vendor Details" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="true" :exportBtn="false" @import="openUploadWindow"></ThatsTable>
        <VendorsForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></VendorsForm>
        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2" :endpoint="'vendors/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>
    </div>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import VendorsForm from './VendorsForm'
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from '../../components/filemanager/TableImport'
import TableError from '../../components/filemanager/TableError'
import ThatsTable from '../../components/thats/Table'
export default {
    data(){
        return {
            endpoint : 'vendors/data',
            importData : {},        
            errorData : {},        
            importLoading : false,
        }
    },
    components: {
        VendorsForm,
        ThatsTable,
        FileUpload,
        TableImport,
        TableError
    },

    methods: {
        ...mapActions('VendorsModule',[ 'DeleteRecord','saveImport']),
        ...mapMutations('VendorsModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })

        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        openUploadWindow(){
            this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
           this.$refs.tableImport.openDialog()
        },
        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

    },
    computed: {
        ...mapGetters('VendorsModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>