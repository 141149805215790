import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir
export const ContactsService = {
    saveImport(object) {
        return Axios.post(`/api/contacts/save-import`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async store(object) {
       
        return await Axios.post(`/api/contacts`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', obj) {

        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...obj}

        return await Axios.get(`/api/contacts${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/contacts`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/contacts`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}