<template>
    <ValidationObserver class="needs-validation form-custom-format filter-form" tag="form" ref="observer" @submit.prevent="onSubmitForm()" v-slot="{passes}">
            <mdbContainer>
              <label class="filter-label">Filter</label>
                 <mdb-row >
                  <mdb-col col="12">
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Tracking No." placeholder="Tracking No." vid="series" name="series" v-model="form.series" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile No." placeholder="Mobile No." vid="customer_mobile" name="customer_mobile" v-model="form.customer_mobile" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customer_name" name="customer_name" v-model="form.customer_name" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Code" placeholder="Item Code" vid="item_code" name="item_code" v-model="form.item_code" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Name" placeholder="Item Name" vid="item_name" name="item_name" v-model="form.item_name" size="sm" />
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sold By" placeholder="Sold By" vid="sold_to" name="sold_to" v-model="form.sold_to" size="sm" />
                      <template v-if="![1,2].includes(tabIndex)">
                      <div class="form-group input-group-sm row thats-form">
                            <label class="col-form-label col-sm-3">Date Range</label>
                          <div class="col-sm-9 pl-0">
                              <date-range-picker v-model="form.dateRange" :append-to-body="false" class="filter-date-range w-100"></date-range-picker>
                          </div>
                      </div>
                      </template>
                  </mdb-col> 
                </mdb-row>
                
                <mdb-row class="mt-1 mb-1">
                    <mdb-col col="3">
                    </mdb-col>
                    <mdb-col col="9">
                     <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Search</mdb-btn>
                    <mdb-btn color="elegant" size="sm" @click="clearForm">Clear Filter</mdb-btn>
                    </mdb-col>
                </mdb-row>


                
              </mdbContainer>


          </ValidationObserver>
</template>
<script>
/* eslint-disable vue/no-unused-components */

import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { ThatsNumber,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsCheckbox, ThatsMultiSelect} from '../../components/thats/Thats'
import VueSlider from 'vue-slider-component'
import DateRangePicker from 'vue2-daterange-picker'
import { mapGetters } from 'vuex';
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        ValidationObserver,
        ThatsInput,  ThatsNumber,
        ThatsSeriesInput,
        ThatsFieldset,
        ThatsCheckbox,
        ThatsMultiSelect,
        VueSlider,
        DateRangePicker
  },    
    data : () => ({
      form : {
           dateRange: {},
          series : "",
          sold_to : "",
          customer_mobile : "",
          customer_name : "",
          item_code : "",
          item_name : "",
          status : "",
      },

     

    }),
    mounted(){
    
      self.form = this.formVar();
    },
    methods: {      

        onSubmitForm(){
            this.$emit('filter',this.form)
        },

        formVar(){
          return {
                dateRange: {},
                series : "",
                sold_to : "",
                customer_mobile : "",
                customer_name : "",
                item_code : "",
                item_name : "",
                status : "",
            }
        },

        clearForm(){
          this.form = this.formVar()
        },

        dateFormat (classes, date) {
          if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
          }
          return classes
        }



    },

    computed : {
      ...mapGetters('DeliveryModule',['tabIndex'])
    },
    watch : {
      tabIndex : function(v){

        if([1,2].includes(v)){
          this.form = this.formVar();
        }
      }
    }

}
</script>
