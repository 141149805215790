/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" title="CATEGORY" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
           <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            
            <div class="scrollbar" v-bind:class="$attrs.scrollClass" >
              <mdb-row class="tab-row mt-2">
                      <mdb-col col="12" class="tab-col">
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="AREA" v-model="form.area_id" :reduce="item => item.id" :reduceLabel="'name'" :options="areaData" :rules="'required'" :filterable="true" :clearable="false" :disabled="false" :addButton="true" @newWindow="openNewAreaWindow"></ThatsMultiSelect>
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-4 pl-0" label="CODE" vid="code" name="code" :rules="'required'" v-model="form.code" size="sm" :disabled="false"/>
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="NAME" vid="name" name="name" :rules="'required'" v-model="form.name" size="sm" :disabled="false"/>
                       </mdb-col> 
                    </mdb-row> 
             </div>     
            <div class="pl-3 pr-3">
                <div class="d-flex">
                  <template v-if="formLoading">
                          <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                          </mdb-btn>
                        </template>
                        <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                                <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                        </template>
                </div>
            </div>
            </ValidationObserver>
        </hsc-window>
        <CodeGeneratorAreaForm ref="codeGenerateAreaForm" :group="5" @success="updateAreaData"></CodeGeneratorAreaForm>
       </hsc-window-style-metal>
</template>
<script>
import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'

import AmountInput from '../../components/thats/AmountInput.vue'
import country from '../generate-serial/country.js'
import CodeGeneratorAreaForm from './CodeGeneratorAreaForm.vue'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect,
        CodeGeneratorAreaForm
  },
    
  data(){
    return {
        dialogIsOpen : false,
        height: 300,
        width: 450,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        inputValue : 0,
        validated : false,
        form : {
          code : null,
          name : null
        },
        formLoading : false,
        areaData :[]
    }
  },
    

  created : function() {

     /*    this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth; */
  },
  mounted(){
        const self = this
        this.getArea().then((res) => {
          self.areaData = res
        })
  },
  updated(){

  },
  methods: {
    ...mapActions('CodeGeneratorAreaModule',['getArea']),
    ...mapActions('CodeGeneratorCategoryModule',['resetData','storeRecord','updateRecord']),

    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
      const self = this;
        this.$refs.observer.validate().then((valid) => {
            if(valid){

                if(self.formLoading ===false){
                  self.formLoading = true
                  self.storeRecord(self.form).then((res) => {
                    self.formLoading = false
                    self.$ThatsNotify.success({
                                message : `Record was added successfully.`
                            })
                    self.closeDialog();
                    self.$emit('success', res.data)
                  }).catch((error) => {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                    }
                    self.formLoading = false
                  })
                }
            }
        })
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },
    updateAreaData(){
      const self = this
        this.getArea().then((res) => {
          self.areaData = res
        })
    },
    openNewAreaWindow(){
            this.$refs.codeGenerateAreaForm.openDialog()
        },

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CostingToolModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      }
    
  },

  watch: {
      'formData' : function(v){
        const self = this
        this.form = v;
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

            if(newState){
              this.updateAreaData()
            }
        },
  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>