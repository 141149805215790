import { ApiHttpService } from '../../services'
export const OnlinePaymentLinkModule = {
    namespaced: true,
    state: {
        request: null,
        loading : false,
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        loadingTrue(state) {
            state.loading = true
        },
        loadingFalse(state) {
            state.loading = false
        },

       
    },
    actions : {

        async getStatus({ commit},params = {}) {
            return await new Promise((resolve, reject) => {

                    commit('loadingTrue')
                    const configParams = {
                        req : "OnlinePaymentLinkModule/req",
                        loadingTrue : "OnlinePaymentLinkModule/loadingTrue",
                        addRequest : "OnlinePaymentLinkModule/addRequest",
                        cancelRequest : "OnlinePaymentLinkModule/cancelRequest",
                        clearOldRequest : "OnlinePaymentLinkModule/clearOldRequest"
                    }

                    ApiHttpService.get(`online-payment-link/${params.invoice_id}`, params, configParams).then((response) => {
                        resolve(response.data)
                        commit('loadingFalse')
                    }).catch(error => {
                        commit('loadingFalse')
                        reject(error)
                    })

            })
        },

        async createLink({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('loadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "OnlinePaymentLinkModule/req",
                        loadingTrue : "OnlinePaymentLinkModule/loadingTrue",
                        addRequest : "OnlinePaymentLinkModule/addRequest",
                        cancelRequest : "OnlinePaymentLinkModule/cancelRequest",
                        clearOldRequest : "OnlinePaymentLinkModule/clearOldRequest"
                    }
                    ApiHttpService.post('online-payment-link',parsePayload,configParams).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },


    },
    getters: {
        loading : state => state.loading,
        req: state => state.request,
    }
}



