<template>
<ValidationObserver ref="observer" name="form" v-slot="{ errors,valid ,passes}">
        
  <form class="needs-validation">
    <Auth>

      <template v-if="errorMessage.error === 0">
        <p class="small success-text" v-text="errorMessage.message"></p>
        <router-link to="/login" class="card-link">Back to Login</router-link>
      </template>


      <template v-if="errorMessage.error === null || errorMessage.error === 1">
        <p class="small red-text" v-text="errorMessage.message"></p>

        <validation-provider name="email" rules="required|email">
          <mdb-input v-model="email" label="Email" autocomplete="off" />
        </validation-provider>
        <div class="d-flex justify-content-between align-items-center">
            <template v-if="Loading">
              <mdb-btn color="elegant" size="sm" rounded disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" rounded size="sm"  @click="passes(onSubmitForm)">Reset Password</mdb-btn>
            </template>
            <router-link to="/login" class="card-link">Back to Login</router-link>
          </div>

      </template>
      
    </Auth>
  </form>
</ValidationObserver>
</template>
<script>
import {
  mdbView,
  mdbMask,
  mdbBtn,
  mdbCard,
  mdbCardBody,
  mdbInput,
  mdbIcon,
  mdbNavbarBrand,
  mdbNavbar,
  mdbNavbarToggler,
  mdbNavbarNav,
  mdbNavItem,
  mdbContainer,
  mdbRow,
  mdbAlert,
  mdbCardHeader,
  mdbCardTitle,
  mdbCardText,
  mdbCardFooter
} from "mdbvue";

import { ValidationObserver,ValidationProvider } from "vee-validate";
import Auth from './Auth';
import { mapActions,mapGetters } from 'vuex'
export default {
    components: {
    mdbView,
    mdbMask,
    mdbBtn,
    mdbCard,
    mdbCardBody,
    mdbInput,
    mdbIcon,
    mdbNavbarBrand,
    mdbNavbar,
    mdbNavbarToggler,
    mdbNavbarNav,
    mdbNavItem,
    mdbContainer,
    mdbRow,
    mdbCardHeader,
    mdbCardTitle,
    mdbCardText,
    mdbCardFooter,
    Auth,
    ValidationObserver,ValidationProvider,
    mdbAlert
  },
    data : () => ({
        validated : false,
        email : null,
        errorMessage : {
          error : null,
          message : null,
        }
    }),
    methods: {
      ...mapActions('AuthModule',[
        'SendReset'
      ]),
      async onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = await this.$refs.observer.validate();
            if (isValid) {
              self.SendReset(self.email).then((response) => {
                  self.errorMessage =  response.data
              }).catch((error) => {
                 self.errorMessage.error = 1;
                if (error.response) {

                    if(error.response.data.hasOwnProperty('errors')){
                      self.errorMessage.message = error.response.data.errors.email[0]
                    }
                  } else if (error.request) {
                      self.errorMessage.message = error.request
                  } else {
                      self.errorMessage.message = error.message
                  }
                  console.log(error)
                  
                  return;
              })
            }

        
      }
    },
        computed: {
      ...mapGetters('AuthModule',
      [
        'Loading'
      ])
    },
}
</script>

