<template>
    <div>
        <ThatsTable  title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="tableColumns" :newBtn="true" @open="openNewModal" @openUpdateModal="OpenUpdateModal" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm"  @getActiveTabIndex="openIssueCoupon">

            <template #custom-right-culumns="{}" v-if="active === 0">

                <el-table-column :sortable="false" :prop="'id'" label="Status" width="250">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status === 'not_used'">
                            Not Used
                        </template>
                        <template v-else>
                            Used
                        </template>
                    </template>
                </el-table-column>

            </template>
            <template #filter="{}">
                <template v-if="active === 1">
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customer_name" name="customer_name" v-model="filterForm.customer_name" size="sm" :disabled="false" :rules="''"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile No." placeholder="Mobile No." vid="mobile" name="mobile" v-model="filterForm.mobile" size="sm" :disabled="false" :rules="''"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Benefit Name" placeholder="Benefit Name" vid="benefit_name" name="benefit_name" v-model="filterForm.benefit_name" size="sm" :disabled="false" :rules="''"/>
                    <ThatsDatePicker :show_error="false" tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Expiry Date" placeholder="Expiry Date" vid="date" name="date" v-model="filterForm.expiry_date" :disabled="false" :rules="''"  size="sm"/>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Tiers" v-model="filterForm.tier" :reduce="label => label.id" :reduceLabel="'label'" :options="tiers" :rules="''"></ThatsMultiSelect>
                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="customerStatus" :rules="''"></ThatsMultiSelect>

                </template>
                <template v-if="active === 0">
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Coupon Code" placeholder="Coupon Code" vid="coupon_code" name="coupon_code" v-model="filterForm.coupon_code" size="sm" :disabled="false" :rules="''"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Amount" placeholder="Amount" vid="amount" name="amount" v-model="filterForm.amount" size="sm" :disabled="false" :rules="''"/>
                    <ThatsDatePicker :show_error="false" tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Issue Date" placeholder="Issue Date" vid="issue_date" name="issue_date" v-model="filterForm.issue_date" :disabled="false" :rules="''"  size="sm"/>
                    <ThatsDatePicker :show_error="false" tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Expiry Date" placeholder="Expiry Date" vid="date" name="date" v-model="filterForm.expiry_date" :disabled="false" :rules="''"  size="sm"/>
                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="couponStatus" :rules="''"></ThatsMultiSelect>

                </template>
            </template>

            <template #button-right="{}" v-if="active === 0">

                <mdb-dropdown multiLevel class="ml-1 barcode-dropdown-toggle" end>
                    <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">Action</mdb-dropdown-toggle>
                    <mdb-dropdown-menu >
                        <a class="dropdown-item" href="javascript:void(0)" @click="openGenerateNew">Generate New Code</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="openUpdateCoupon">Update Coupon</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="openBulkUpdate">Bulk Update</a>
                    </mdb-dropdown-menu>
                </mdb-dropdown>

            </template>



        </ThatsTable>
        <GenerateNewCodeForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></GenerateNewCodeForm>
        <UpdateForm ref="dialogUpdateForm" @success="refreshTable"></UpdateForm>
        <CouponForm ref="bringToShowroomForm" @success="refreshTable"></CouponForm>
        <IssueCoupon  v-if="isIssueCouponOpen"  @success="refreshTable"></IssueCoupon>
        <IssueCouponForm ref="issueForm" @success="refreshTable" />
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {
    mdbTabs,
    mdbRow,
    mdbCol,
    mdbBtn,
    mdbSelect,
    mdbInput,
    mdbBadge,
    mdbDropdownMenu,
    mdbDropdown,
    mdbDropdownToggle
} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import CouponForm from "./CouponForm.vue";
import GenerateNewCodeForm from "./GenerateNewCodeForm.vue";
import UpdateForm from "./UpdateForm.vue";
import IssueCoupon from "../issue-coupon/IssueCoupon.vue";
import IssueCouponForm from "../issue-coupon/IssueCouponForm.vue";

export default {
    components:{
        IssueCouponForm,
        IssueCoupon,
        GenerateNewCodeForm,
        UpdateForm,
        mdbDropdownToggle, mdbDropdown, mdbDropdownMenu,
        CouponForm,
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput,
        ThatsInput
    },
    inject : ['showroomsProvider'],
    provide(){
        return {
            btsApproverProvider : () => this.approverData,
            btsReceiverProvider : () => this.receiverData,
        }
    },
    data(){
        return {
            endpoint : 'coupons',
            approver : [],
            // tableColumns : [],
            receiver : [],
            tableKey : 0,
            active : 0,
            filterForm : {
                // customer_name : null,
                // coupon_code : null,
                // mobile : null,
                // amount : null,
                // benefit_name : null,
                // tier : [],
                // status : "",
                // issue_date : '',
                // expiry_date : '',
                // other_status :  "",
                date:'',
                searchTerm: null,
                status: null,
            },
            customerStatus:[
                { label : "All", value : "all"},
                { label : "Open", value : "open"},
                { label : "Done", value : "done"},
                { label : "Close", value : "close"},
            ],
            couponStatus:[

                { label : "All", value : "all"},
                { label : "Used", value : "used"},
                { label : "Not Used", value : "not_used"}
            ],
            benefitStatus : [
                { label : "Issue", value : "issue"},
                { label : "Redeem", value : "redeemed"},
            ],
            isIssueCouponOpen: false,
            docStatus : [
                {value : 'for-approval', label : 'For Approval'},
                {value : 'rejected', label : 'Rejected'},
                {value : 'approved', label : 'Approved'},
            ]
        }
    },


    created(){

        const self = this;
        this.getApprover().then((res) =>  this.approver = res);
        this.getReceiver().then((res) => {
            if(res.length > 0){
                self.receiver = res.filter((user)=>{
                    return ['warehouse','delivery-team','showroom-manager','general-manager','interior-designers','administrator','back-office'].some(item => user.role_slugs.includes(item));
                })
            }
        });
        this.asyncGetData().then((res) => {
            this.tiers = res;
        })
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {
        ...mapActions('TireModule',[
            'asyncGetData'
        ]),
        ...mapActions('BringToShowroomModule',['getApprover']),
        ...mapActions('CouponModule',['getRecord','getReceiver']),
        ...mapMutations('CouponModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormData','setShowroomTargetFormData']),
        // ...mapMutations('CustomerCouponModule',['SetFormStatusUpdate','SetFormStatusCreate','SetFormRecord']),


        ...mapMutations('TableModule',[
            'SetPage',
        ]),

        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            self.setFormStatusUpdate();
            this.getRecord(row).then(() => {
                loading.close();

                self.$refs.bringToShowroomForm.openDialog();
            })
        },
        openIssueCoupon(value) {
            this.active = value
            if(value == 0){
                this.endpoint = 'coupons'
            }else{
                this.endpoint = 'customer-coupons'
            }

            this.refreshTable();
            //this.isIssueCouponOpen = true;

            this.$nextTick(()=>{
                this.$refs.table.setActiveTabIndex(value)
            })

        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
            this.$refs.bringToShowroomForm.openDialog();
        },
        OpenUpdateModal(obj) {
            this.setFormStatusUpdate()
            this.setEmptyForm();
            if (this.active === 1){
                this.$refs.issueForm.openDialog(obj);
            }else{
                this.$refs.bringToShowroomForm.openDialog();
            }

        },
        refreshTable() {
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()

        },


        fnPrintDoc(url) {
            const windowHeight = window.innerHeight;
            const innerWidth = window.innerWidth;

            var strWindowFeatures = "location=yes,height=" + windowHeight + ",width=" + innerWidth + ",scrollbars=yes,status=yes";
            const win = window.open(`${url}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()

        },

        openGenerateNew() {
            this.$refs.dialogForm.openDialog()
        },
        openUpdateCoupon() {
            this.$refs.dialogUpdateForm.openDialog()
        },
        openBulkUpdate() {
            this.OpenUpdateModal();
        },

        formDelete(record) {
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        clearFilterForm() {
            this.filterForm = JSON.parse(JSON.stringify(
                {
                    date : '',
                    searchTerm: null,
                    status: null,

                }
            ))
        }


    },
    computed: {
        ...mapGetters('CouponModule', ['getLoading', 'initialForm', 'form']),
        ...mapGetters('CouponModule', {couponCol: 'columns'}),
        ...mapGetters('CustomerCouponModule', {customerCoupon: 'columns'}),
        tableColumns: function () {
            if (this.endpoint == 'coupons') {
                return this.couponCol
            } else {
                return this.customerCoupon
            }
        },
        approverData: function () {
            return this.approver
        },
        receiverData: function () {
            return this.receiver
        },

        statusArrayText: function () {

            return {
                'draft': 'Draft',
                'for-approval': 'For Approval',
                'approved': 'Approved',
                'rejected': 'Rejected',
                'save': 'Saved'
            };
        },
        statusArrayColor: function () {

            return {
                'draft': 'badge-light',
                'for-approval': 'warning',
                'save': 'warning',
                'approved': 'success',
                'rejected': 'danger'
            };
        },
        statusArrayIcon: function () {

            return {
                'draft': 'el-icon-more',
                'for-approval': 'el-icon-circle-check',
                'save': 'el-icon-circle-check',
                'approved': 'el-icon-success',
                'rejected': 'el-icon-remove'
            };
        },

        showroomLocation: function () {

            const showroom = this.showroomsProvider();

            return showroom.map((item) => {
                return {...item, label: `${item.prefix} - ${item.label}`, prefix: item.prefix}
            }, [])
        },
        documentApprover: function () {

            return this.approver.map((item) => {
                return {...item, label: `${item.name}`, value: item.id}
            }, [])
        },
        documentReceiver: function () {
            return this.receiver.filter((user) => {
                return ['warehouse', 'delivery-team', 'showroom-manager', 'interior-designers', 'back-office', 'administrator'].some(item => user.role_slugs.includes(item))
            }, []).map((item) => {
                return {...item, label: `${item.name}`, value: item.id}
            }, [])
        },
    },
}


</script>
