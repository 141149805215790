<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid">
      <div class="file-upload-wrapper" ref="fileform" @click="triggerClick" :class="computedClasses" :style="{height: `${height}px`}"  v-loading="loading">
          <div class="file-upload display-flex justify-content-center align-items-center w-100">

            <div class="text-center" v-if="((internalFiles.length==0 && files.length <= 0) || files.length <= 0)">
                <svg viewBox="0 0 700 500" height="50px">
                  <path class="upload-icon" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"/>
                </svg>
                <p>{{ message }}</p>
                <p class="error-message" v-if="sizeError">{{ generalErrorText }}</p>
              </div>


              <template v-if="files.length > 0">
                <div class="image-wrapper w-100" v-bind:class="size" v-for="(image, index) in files" :key="index">
                  <el-button type="danger" class="image-remove-btn" icon="el-icon-delete" circle @click.stop="removeFile( index )"></el-button>
                  <div class="image-list-item position-relative cursor-pointer image-highlight display-flex justify-content-center align-items-center text-center" :style="{height:`${height}px`}">
                    <img class="show-img img-responsive" :src="image.path" :style="{maxHeight:`${height}px`,maxWidth: '100%'}">
                  </div>
                </div>
              </template>

          </div>
      </div>
        <input type="file" class="file-input" ref="input" @change="handleFileUpload" :multiple="multiple" :accept="accept"/>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline } from "mdbvue";
import { computed } from "vue";
import { ApiHttpService } from '../../../services'
import { MessageBox } from "element-ui";
export default {
    name : 'ThatsImage',
    props : {
       
        value: {
            type: Array
        },

        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        multiple : {
            type : Boolean,
            default : false
        },
        size : {
            type : String,
            default : 'image-smal'
        },
        disabled : {
            type : Boolean,
            default : false
        },

        height: {
          type: [Number, String],
          default: 250
        },
        wrapperClass : {
          type : [String,Array],
          default : () => ['border','border-light', 'display-flex', 'justify-content-center', 'align-items-center','cursor-pointer']
        },
        accept : {
          type : String,
          default : 'image/*'
        },

        multiple : {
          type : Boolean,
          default : false
        },
        

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
    },

    data : ()=>({
        data : [],
        loading: false,
        dragAndDropAble: false,
        files : [],
        internalFiles : [],
        sizeError: false,
        message : 'Drag and drop your files here or click to upload',
        generalErrorText : 'File size is too large',
        multipleChunks : [],
        itr : 0
    }),
    methods : {
        emitUpload(){
            this.$emit('open-form');
        },

        isDragAndDropAble() {
          const div = document.createElement('div');
          return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        },

        handleFileUpload(event) {
          const uploadedFiles = event.dataTransfer !== undefined ? event.dataTransfer.files : this.$refs.input.files;

          console.log("uploadedFiles")
          console.log(uploadedFiles)
          this.sizeError = false;
          if (this.multiple) {
            for (let i = 0; i < uploadedFiles.length; i++ ) {
              if (this.isFileSizeRight(uploadedFiles[i])) {
                this.internalFiles.push(uploadedFiles[i]);
              } else {this.sizeError = true}
            }
          } else {
            this.internalFiles = [];
            (this.isFileSizeRight(uploadedFiles[0])) && this.internalFiles.push(uploadedFiles[0]);
          }
          
        },

        triggerClick() {
          this.$refs.input.click();
        },

        isFileSizeRight(file) {
          if (this.isMaxSizeProvided) {
            if (this.sizeToByte(this.maxSize) > file.size) {
              return true
            } else {
              this.sizeError = true;
              return false;
            }
          }
          return true;
        },
        sizeToByte(size) {
          let value = 0;

          if (size !== 0) {
            let unit = size.slice(-1).toUpperCase();
            let kb = 1024;
            let mb = kb * 1024;
            let gb = mb * 1024;

            if (unit === 'K') {
              value = parseFloat(size) * kb;
            } else if (unit === 'M') {
              value = parseFloat(size) * mb;
            } else if (unit === 'G') {
              value = parseFloat(size) * gb;
            }
          }
          return value;
        },

        createMultipleChunks(){
        const self = this;
        this.multipleChunks = [];

        if(this.internalFiles.length > 0){
            Array.prototype.forEach.call(this.internalFiles, file => {
                let size = 1024 * 1024, chunks = Math.ceil(file.size / size);
                const c = {name: file.name, progress : 0, size : 100, loading : false, path : "", highlight: 0,chunk : [], mime : file.type}
                for (let i = 0; i < chunks; i++) {
                    c.chunk.push(file.slice(
                        i * size, Math.min(i * size + size, file.size), file.type
                    ));
                }
                
                let reader = new FileReader()
                reader.onload = (e) => {
                    let dataURI = e.target.result
                        if (dataURI) {
                            let path = dataURI;
                            const fileType = self.getFileType(file);
                            if(fileType == 'video'){
                                path = '/assets/video.jpg';
                            }
                            c.path = path;
                        }
                }
                reader.readAsDataURL(file) 
                this.multipleChunks.push(c)
            }); 
        }
    },

    recursive(i){
        const self = this;
        self.itr = i

        const arraySize = self.multipleChunks.length

        if(self.multipleChunks.length > 0){
        
          if(self.multipleChunks[self.itr].chunk.length > 0){
              let formData = new FormData;
              const chunks = self.multipleChunks[self.itr];
              formData.set('mime', chunks.mime);
              formData.set('chunkNumber', chunks.chunk.length);
              formData.set('is_last', chunks.chunk.length === 1);
              formData.set('file', chunks.chunk[0], `${self.internalFiles[self.itr].name}.part`);
  
              self.loading = true
  
              ApiHttpService.upload('upload-image-2',formData).then(response => {
                      self.multipleChunks[self.itr].progress =  parseInt((self.multipleChunks[self.itr].size / chunks.chunk.length));
                          self.multipleChunks[self.itr].loading = true
                      if(chunks.chunk.length  == 1){
                          self.multipleChunks[self.itr].completed = true
                          self.multipleChunks[self.itr].loading = false
                          self.$ThatsNotify.success({
                          message : `${response.data.name} was successfully uploaded`
                          })
  
                          if(self.multiple){
                            self.files = [...self.files,response.data]
                          }else{
                            self.files = [response.data]
                          }
                          self.loading = false
                      }
                      self.multipleChunks[self.itr].chunk.shift();
                      
                      self.recursive(self.itr)
  
              }).catch(error => {
                  self.multipleChunks[self.itr].error = true
  
              }); 
              self.itr = i;
            }else{
                if(self.itr < (arraySize - 1)){
                    self.itr++
                    this.recursive(self.itr)
                }
                
                
            }
        }

        
      },

      getFileType(file) {
          if(file.type.match('image.*')){
              return 'image';
          }
          if(file.type.match('video.*')){
              return 'video';
          }
          return 'other';
      },


      removeFile(key) {
        MessageBox.confirm('Are you sure you want to remove this file?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.files.splice(key, 1);
          this.$emit('input', this.files);
        });
      },
        
    },

    watch: {
        files(newVal) {
            this.$emit("input", newVal);
        },
        value(newVal) {
            this.files = newVal;
        },

        internalFiles(){
            this.createMultipleChunks()

            if(this.multipleChunks.length > 0){
                this.$nextTick(() => {
                    this.recursive(this.itr)
                })
            }
        }

    },
    created() {
        if (this.value) {
        this.files = this.value;
        }
    },

    mounted() {
      this.dragAndDropAble = this.isDragAndDropAble();
      if (this.dragAndDropAble) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( evt => {
          this.$refs.fileform.addEventListener(evt, e => {
            e.preventDefault();
            e.stopPropagation();
          });
        })
        this.$refs.fileform.addEventListener('drop', e => {
          this.handleFileUpload(e);
        })

      }
    },

    computed : {

      /* {disabled:disabled,...wrapperClass} */
      computedClasses() {
        if (typeof this.wrapperClass === 'string') {
          this.wrapperClass += (this.disabled) ? ' disabled' : '';
          return this.wrapperClass; 
        } else if (Array.isArray(this.wrapperClass)) {
          this.wrapperClass.push((this.disabled) ? 'disabled' : '')
          return this.wrapperClass.join(' ');
        } else {
          let empty = '';
          if (this.disabled) {
            empty = 'disabled';
          }
          return empty; 
        }
      } 
    }
}

</script>


<style scoped>

.file-upload-wrapper{
  display: flex;
  justify-content: center;
}

.file-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.image-remove-btn{
  position: absolute;
    right: 0;
    margin: 10px 24px;
    z-index: 999;
}

.image-list-item{
  display: flex;
}
</style>