import Axios from 'axios'
import { bearer, token } from '../helpers'
export const CategoryService = {
    async store(object) {
       
        return await Axios.post(`/api/category`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    get(endpoint = '', params) {
        console.log(endpoint)
        return Axios.get(`/api/category${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/category`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },
    async updateOrder(obj) {
        return await Axios.put(`/api/category/order`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/category`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}