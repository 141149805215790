export const DiscountModule = {
    namespaced: true,
    state: {
        discountForm : {}
    },
    
    mutations: {
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },

    },
    getters: {
        discountForm: state => state.discountForm
    }
}


