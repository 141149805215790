import { SalesService } from '../../services'
import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const SalesModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '150',
                },
                {
                    label: 'Tracking No.',
                    field: 'series_display',
                    sort: 'asc',
                    clickable: true
                },
                
                {
                    label: 'Item Name',
                    field: 'item_names',
                },
                {
                    label: 'Customer/Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Total Amount',
                    field: 'total',
                    align : 'right'
                },
                {
                    label: 'Status',
                    field: 'status_text',
                    sort: 'asc',
                    align: 'right',
                    width: '100',
                },

                

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

        Warehouse: [],
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {}


    },
    actions: {

        

        async GetNextCodePayment({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales/payment/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async GetNextCode({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                SalesService.get('/filter-item', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getItem({ rootState, commit }, params) {
            const { getItemLoading } = rootState.SalesModule
            return await new Promise((resolve) => {
                if (!getItemLoading) {
                    commit('getItemLoadingTrue');
                    ApiService.get(`sales/item/${params.token_id}`, params).then((response) => {
                        commit('SetFormRecord', response.data.data)
                        commit('getItemLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('getItemLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = rootState.SalesModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    SalesService.get('\/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },




        async updatePayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update('sales/update-payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('sales/payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async StoreRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    SalesService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    SalesService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateStatus({ rootState, commit }, { endpoint, payload}) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    SalesService.updateStatus(endpoint,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    SalesService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ContactsModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('sales/save-import',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    mutations: {
        getItemLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        getItemLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setWarehouse: (state, payload) => { 
            state.Warehouse = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        Warehouse: state => state.Warehouse,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
        getItemLoading: state => state.getItemLoading,
    }
}


