<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-date-picker-2 :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="(show_error == true ? errors[0] : '')" :placeholder="$attrs.placeholder" :inputClass="[...classMerge,hasError(errors)]" v-model="inputValue" :disabled="disabled" autocomplete="off" :readOnly="readOnly" noWrapper bg :is-valid="invalid" :limit="limit"/>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import mdbDatePicker2 from '../DatePicker2.vue'
export default {
    name : 'ThatsDatePicker',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

       
        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputClass : {
            type : Array,
            default : function(){
                return ['==xxx=='];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel : {
            type : Boolean,
            default : true
        },
        orientation : {
            type : String,
            default : 'horizontal'
        },
        readOnly : {
            type : Boolean,
            default : false
        },

        limit: {
        type: Array,
        default: () => ([])
      },
    },
    components : {
        ValidationProvider,
        mdbDatePicker2 ,
    },

    data : ()=>({
        inputValue : null,
        inputClassErrors : []
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            if(error.length > 0){
                this.inputClassErrors = ['is-invalid']
            }
            return (error.length > 0) ? 'is-invalid' : null
        },

        currentDateSetter(){
            var MyDate = new Date();
            var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.inputValue = date;
        },
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value != null) {
        this.inputValue = this.value;
        }else{
           var MyDate = new Date();
            var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.inputValue = date;
        }
    },

    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.orientation == 'horizontal'){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.orientation == 'horizontal'){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputClass.join(' ');
        },

        classMerge : function(){
            const styleClass = [];
                if(this.orientation == 'horizontal'){
                    styleClass.push("input-horizontal");
                }

            return [...this.inputClass,...styleClass]
        }
    },
}

</script>