/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" :title="windowTitle" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">
           <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            
            <div class="scrollbar pb-0" v-bind:class="$attrs.scrollClass" >
                  <div class="row">
                    <div class="col-4 mr-0 pr-0">
                        <div class="template-list-container">
                          
                          <div class="list-group">
                            <a href="javascript:void(0)" class="list-group-item" v-bind:class="{'active' : (activeTemplate !== null && activeTemplate.id === item.id)}" v-for="(item) in templateData" :key="item.id" @click="onClickTemplate(item)">{{item.name}}</a>
                          </div>


                         <!--   <mdb-list-group>
                            <mdb-list-group-item tag="div" v-for="(item) in templateData" :key="item.id" @click="onClickTemplate(item)">{{item.name}}</mdb-list-group-item>
                          </mdb-list-group> -->
                        
                        </div>


                        <div class="text-right">
                          <mdb-btn color="elegant" size="sm" @click="openNewTemplate">New template</mdb-btn>
                        </div>

                    </div>
                    <div class="col-8 pl-0 ml-0">

                      <el-table ref="multipleTable" height="260" :data="partsData" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="55"> </el-table-column>
                        <el-table-column label="Code" width="80"> <template slot-scope="scope">{{ scope.row.code }}</template> </el-table-column>
                        <el-table-column property="name" label="Name" > </el-table-column>
                      </el-table>

                      <div class="text-right">
                        <mdb-btn color="elegant" size="sm" @click="openNewParts">New Parts</mdb-btn>
                      </div>
                    </div>
                  </div>
             </div>     
            <div class="pl-3 pr-3 border-top">
                <div class="d-flex">
                  <template v-if="formLoading">
                          <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                          </mdb-btn>
                        </template>
                        <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                                <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                        </template>
                </div>
            </div>
            </ValidationObserver>
        </hsc-window>
        <CodeGeneratorVariationForm ref="codeGenerateTemplateForm" type="template" :group="5" @success="variationSuccess"></CodeGeneratorVariationForm>
        <CodeGeneratorVariationForm ref="codeGeneratePartsForm" type="parts" :group="5" @success="variationSuccess"></CodeGeneratorVariationForm>
       </hsc-window-style-metal>
</template>
<script>
import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput,
  mdbListGroup, mdbListGroupItem
  } from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'

import AmountInput from '../../components/thats/AmountInput.vue'
import country from '../generate-serial/country.js'
import CodeGeneratorVariationForm from './CodeGeneratorVariationForm.vue'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
    type : {
      type : String,
      default : 'finish'
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect,
        mdbListGroup, mdbListGroupItem,
        CodeGeneratorVariationForm
  },
    
  data(){
    return {
        dialogIsOpen : false,
        height: 400,
        width: 600,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        inputValue : 0,
        validated : false,
        form : {
          code : null,
          name : null
        },
        formLoading : false,
        variation : [],
        templateData : [],
        partsData : [],
        activeTemplate : null
    }
  },
    

  created : function() {

     /*    this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth; */
  },

  mounted(){
        const self = this
  },

  updated(){

  },
  methods: {

    ...mapActions('CodeGeneratorVariationModule',['resetData','storeRecord','updateRecords','getRecords']),

    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
      const self = this;
        this.$refs.observer.validate().then((valid) => {
            if(valid){

                if(self.formLoading ===false){
                  self.updateRecords({items : self.templateData}).then((res) => {
                    self.formLoading = false
                    self.$ThatsNotify.success({
                                message : `Record was updated successfully.`
                            })
                    self.activeTemplate = null;
                    self.$refs.multipleTable.clearSelection();
                    self.templateData = res
                    self.$emit('success',res)
                    self.closeDialog();
                  }).catch((error) => {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                    }
                    self.formLoading = false
                  })
                }
            }
        })
    },
    openHistory(){

    },

    cellClassName(){

    },

    openNewParts(){
      this.$refs.codeGeneratePartsForm.openDialog()
    },
    openNewTemplate(){
      this.$refs.codeGenerateTemplateForm.openDialog()
    },
    variationSuccess(data){

        if(data.type === 'template'){
              this.templateData.push(data)
        }
        if(data.type === 'parts'){
              this.partsData.push(data)
        }

        this.activeTemplate = null;
        this.$refs.multipleTable.clearSelection();
    },
    onClickTemplate(item){
      const self = this

        
        const activeTemplateParse = JSON.parse(JSON.stringify(item))
        const ItemAttrib = item.attr
        activeTemplateParse.attr = ItemAttrib
        this.activeTemplate =activeTemplateParse

        self.$refs.multipleTable.clearSelection();


        if(ItemAttrib !== null && ItemAttrib.parts.length > 0){
            // //alert(1)
            const selectedRow = [];
            ItemAttrib.parts.forEach(element => {
                  const index = self.partsData.map(object => object.code).indexOf(element);
                  selectedRow.push(self.partsData[index])
            });

            if(selectedRow.length > 0){
              selectedRow.forEach((row) => {
                  self.$refs.multipleTable.toggleRowSelection(row);
              })
            }else{
              self.$refs.multipleTable.clearSelection();
            }
        }else{
          self.$refs.multipleTable.clearSelection();
          }
    },
    handleSelectionChange(select){
      const self = this;

  
      if(this.activeTemplate !== null){
        if(this.activeTemplate.attr !== null){

      const ItemAttrib = this.activeTemplate.attr

      ItemAttrib.parts = select.map((item) => {
           return item.code
       },[])


         self.activeTemplate.attr = ItemAttrib


       const index = this.templateData.map(object => object.id).indexOf(this.activeTemplate.id);

       this.$set(this.templateData,index,self.activeTemplate)

       console.log(index)
    }else{
      let ItemAttrib = {}
      ItemAttrib.parts  =select.map((item) => {
           return item.code
       },[])

        self.activeTemplate.attr = ItemAttrib

        const index = this.templateData.map(object => object.id).indexOf(this.activeTemplate.id);

       this.$set(this.templateData,index,self.activeTemplate)
       
    }
      }

        /* this.$nextTick(()=>{
        }) */
    }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CostingToolModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      },

      windowTitle : function(){
          return 'PARTS TEMPLATE'
      }
    
  },

  watch: {
      'formData' : function(v){
        const self = this
        this.form = v;
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

          console.log("newState")
          console.log(newState)
       const self = this;
              if(newState){
                    this.getRecords().then((res) => {
                          self.templateData = res.filter((item)=>{
                              return item.type === 'template'
                          },[])
                          self.partsData = res.filter((item)=>{
                              return item.type === 'parts'
                          },[])
                    })
                return
              }
        },
  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>