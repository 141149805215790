import { ApiHttpService } from '../../services'
export const SapPurchaseOrderModule = {
    namespaced: true,
    state: {
        data: {
            columns: [

            {
                label: 'DocNum',
                field: 'DocNum',
                sort: 'asc',
                width: '130',
                clickable: true
            },
            {
                label: 'Vendor Code',
                field: 'CardCode',
                sort: 'asc',
                width: '130',
                clickable: true
            },
            {
                label: 'Vendor',
                field: 'CardName',
                sort: 'asc',
            },
            
            {
                label: 'Currency',
                field: 'DocCurrency',
                sort: 'asc',
                width: '100'
            },
            {
                label: 'Reference',
                field: 'NumAtCard',
                sort: 'asc',
                width: '300'
            },
            {
                label: 'Remarks',
                field: 'Comments',
                sort: 'asc',
                width: '300'
            },
            {
                label: 'Discount',
                field: 'DiscountPercent',
                width: '100',
                align : 'right'
            },
            {
                label: 'Total',
                field: 'DocTotalFc',
                amount : true,
                sort: 'asc',
                width: '150',
                align : 'right'
            },
            {
                label: 'Date',
                field: 'DocDate',
                width: '200',
                align : 'right'
            },
            

            ],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {
            CardCode : null,
            CardName : null,
            DocTotal : 0,
            DocNum : null,
            PostingDate : null,
            DocCurrency : null,
            CurrencySymbol : null,
            ExchangeRate : null,
            
            attr : {
               
              },
            items :[],
            rowObject : null,
            status : 'draft',
        },


        initialFormData : {
            CardCode : null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            Currency : null,
            ExchangeRate : 0,
            attr : {
                
              },
            items :[],
            rowObject : null,
            status : 'draft',
          },

          getLoading : false,
          getRecordLoading : false,
          putLoading : false,

          formStatus : 'create'

        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },
        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log(res)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "SapPurchaseOrderModule/req",
                        loadingTrue : "SapPurchaseOrderModule/loadingTrue",
                        addRequest : "SapPurchaseOrderModule/addRequest",
                        cancelRequest : "SapPurchaseOrderModule/cancelRequest",
                        clearOldRequest : "SapPurchaseOrderModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },


        async getArea({},obj) {

            return await new Promise((resolve, reject) => {
                    ApiHttpService.getRequest('new-item/area', obj).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },

       

        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))


                    const configParams = {
                        req : "SapPurchaseOrderModule/req",
                        loadingTrue : "SapPurchaseOrderModule/loadingTrue",
                        addRequest : "SapPurchaseOrderModule/addRequest",
                        cancelRequest : "SapPurchaseOrderModule/cancelRequest",
                        clearOldRequest : "SapPurchaseOrderModule/clearOldRequest"
                    }


                    ApiHttpService.post('costing',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        // commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiHttpService.update(`sap/purchase-order/${updatePayload.DocEntry}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async getRecord({state,commit},obj){
            return await new Promise((resolve,reject) => {
                const getLoading = state.getRecordLoading;

                if(!getLoading){
                    commit('setGetRecordGetLoading', true);

                    ApiHttpService.getRequest(`sap/purchase-order/${obj.DocEntry}`).then((response) => {
                        commit('setGetloadingFalse', false)

                        console.log(response)

                        const valueData = response.data.Items.map((item, index) => {
                            
                            return {
                                    ...item,
                                    Picture : '<img src="'+item.Image+'" style="height:50px;">'

                                };
                          });

                          commit('setFormDataItems',valueData)
                        resolve(response.data)
                        commit('setGetRecordGetLoading', false)
                    }).catch(error => {
                        commit('setGetRecordGetLoading', false)
                        reject(error)
                    }) 
                }
            })
        }
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialForm: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
    }
}



