<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Wallpaper" windowClass="height-auto" scrollClass='height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="checkRoleNewButton">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                  </template>
                </template>
            </template>           
            <mdbContainer class="mt-4">
                <mdb-row >
                  <mdb-col col="8">
                    <thats-fieldset>
                      <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-4 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" />                  
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Name" placeholder="Item Name" vid="namex" name="namex" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                      <mdb-row>
                        <mdb-col col="6">
                          <ThatsPriceInput tabindex="4"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Sales Price" placeholder="0" vid="price" name="price" size="sm" v-model="form.price" :rules="'required'" :validated="validated"/> 
                        </mdb-col>
                        <mdb-col col="6">
                        
                        </mdb-col>
                      </mdb-row>
                    </thats-fieldset>
                    
                    <thats-fieldset class="mt-1">
                    <mdb-row>
                      <mdb-col col="12">
                        <ThatsAutocomplete tabindex="5" @newWindow="openCollectionForm" :addButton="true" :appendButton="false" labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Collections" v-model="form.collection" :reduce="collection => collection.code" :reduceLabel="'collection'" :options="filterCollections" :rules="'required'" :clearable="false"></ThatsAutocomplete>
                        <mdb-row>
                          <mdb-col col="3">
                            <label>Colour</label>
                          </mdb-col>
                          
                          <mdb-col col="3">
                            <ThatsVueSelect tabindex="5" @newWindow="openColourForm" @select="onSelectColour" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.colour" :reduce="colour => colour.code" :reduceLabel="'colour'" :options="colour" :rules="'required'" :showLabel="false" :clearable="false"></ThatsVueSelect>
                          </mdb-col>
                          <mdb-col col="3">
                            <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour1" name="colour1" v-model="form.attr.colour1" :validated="validated" :rules="''" size="sm" :showLabel="false"/>
                          </mdb-col>
                          <mdb-col col="3">
                            <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour2" name="colour2" v-model="form.attr.colour2" :validated="validated" :rules="''" size="sm" :showLabel="false"/>
                          </mdb-col>
                        
                        </mdb-row>
                        
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Plus Product" placeholder="Plus Product" vid="plus_product" name="plus_product" v-model="form.attr.plus_product" :validated="validated" :rules="''" size="sm" />
                        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Weight/Unit" placeholder="Weight/Unit" vid="weight_unit" name="weight_unit" v-model="form.attr.weight_unit" :validated="validated" :rules="''" size="sm" />
                        
                        <mdb-row>

                          <mdb-col col="6">
                           <ThatsInput tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Roll of length" placeholder="Length" vid="length" name="width_height" v-model="form.attr.length" :validated="validated" :rules="''" size="sm" />
                          </mdb-col>
                          <mdb-col col="6">
                           <ThatsInput tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Width" placeholder="Width" vid="width" name="width_height" v-model="form.attr.width" :validated="validated" :rules="''" size="sm" />
                          </mdb-col>
                        </mdb-row>
                           <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Dimension" placeholder="Dimension" vid="dimension" name="dimension" v-model="form.description" :validated="validated" :rules="''" size="sm" />
                        <ThatsAutocomplete tabindex="10" @newWindow="openBrandForm" :addButton="true" :appendButton="false" labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brands" v-model="form.brand" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="filterBrands" :rules="'required'" :clearable="false"></ThatsAutocomplete>
                        <ThatsRadio tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" label="Status" vid="item-status" orientation="inline" name="item-status" :data="itemStatus" v-model="form.status" :validated="validated" :rules="'required'" size="sm" />
                      <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Hide in website'" vid="item-status" orientation="inline" name="item-status" :data="checkboxData" v-model="form.website" :validated="validated" :rules="''" size="sm" :multiple="false" />
                      </mdb-col>
                    </mdb-row>

                    </thats-fieldset>

                  </mdb-col>

                  <mdb-col col="4 pl-0">
                    <thats-fieldset>
                      <template v-if="form.images.length > 0">
                    <mdb-carousel
                      :items="form.images.length"
                      controlls
                      :controllIcons="['fas fa-arrow-circle-left fa-2x text-white', 'fas fa-arrow-circle-right fa-2x text-white']"
                    >
                     <template #[i+1] v-for="(img, i) in form.images">
                        <mdb-card :key="i">

                          <div class="custom-control image-checkbox custom-checkbox">
                              <input type="checkbox" class="custom-control-input" @change="onSelect($event,img)" :checked="img.default" :id="'imageCheckbox'+img.mdbID" :value="img.medium">
                              <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID" title="Set as default">Set as default</label>
                          </div>

                          <div class="custom-control image-delete" @click="removeImage(i,img.default)">
                              <mdb-icon icon="trash"></mdb-icon>
                          </div>
                          <mdb-card-image
                            :src="img.medium"
                            :alt="img.filename"
                          ></mdb-card-image>
                          
                        </mdb-card>
                      </template>

                    

                    
                    </mdb-carousel>
                      </template>
                      <template v-else>
                        <div class="no-image-gallery">
                          <mdb-icon far icon="images" />
                        </div>
                      </template>
                    <a href="javascript:void(0)" tabindex="17" @click="uploadImage"><mdb-icon icon="image"></mdb-icon> Select Image</a>
                 </thats-fieldset>

                
                  </mdb-col>    
                </mdb-row>
              </mdbContainer>
       </ThatsWindow>

          </ValidationObserver>

      <GalleryManager ref="gallery" :data="form.images" :group="group2"></GalleryManager>
      <ImageGrid ref="upload" @select="fnImageSelect" :moduleForm="'item-form'" :group="group2"></ImageGrid>
      <CollectionForm ref="collectionForm" :group="group2" @success="collectionSuccess" :show="'wallpaper'"></CollectionForm>
      <ColourForm ref="colourForm" :group="group2" @success="colourSuccess" :show="'wallpaper'"></ColourForm>
      <BrandForm ref="brandForm" :group="group2" @success="brandSuccess"></BrandForm>
     
  </div>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon,
        mdbCarousel, mdbCard,
    mdbCardImage,} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsFloat,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GalleryManager from '../../components/image/GalleryManager';
import Autocomplete from '../../components/Autocomplete';

import Category from '../category/Category'
import CategoryForm from '../category/CategoryForm'
import BrandForm from '../brand/BrandForm'
import CollectionForm from '../collection/CollectionForm'
import ColourForm from '../colour/ColourForm'
import { MessageBox } from 'element-ui';
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardImage,


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        Autocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsCheckbox,
        ThatsVueSelect,

        ImageGrid,
        GalleryManager,

        Category,
        CategoryForm,
        BrandForm,
        CollectionForm,
        ColourForm,
        ThatsFloat,
        
        
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    data : () => ({
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,

      vendors : [],
      brands : [],
      collections : [],
      category : [],
      attributes : [],
      fabricAttributes : [],
      area : [],

      itemType : [
        {label : "Stock", value : "stock"},
        {label : "Services", value : "services"},
      ],

      itemStatus : [
        {label : "New Arrival", value : "2"},
        {label : "Active", value : "1"},
        {label : "Discontinued", value : "0"},
      ],

      widthrange : [],
      materials : [],
      materials1 : [],
      finishes : [],
      fabric : [],
      colour : [],
      colour1 : [],
      pattern : [],
      pattern1 : [],
      application : [],
      showError : false,

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
      }

       this.optionsHandler();
      
      
    },
    methods: {
      ...mapActions('ColourModule',[ 'getColour' ]),
      ...mapActions('MaterialModule',[ 'getMaterials' ]),
      ...mapActions('PatternModule',[ 'getPattern' ]),
      // ...mapActions('BrandModule',[ 'GetBrands','GetBrandErp']),
      ...mapActions('WallpaperModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
        'GetCollection',
        'GetBrands',
        'GetBrandRelationship'
      ]),
      ...mapMutations('WallpaperModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          series : 0,
          parent : 0,
          name : null,
          slug : null,
          price : 0,
          collection : 173,
          brand : 16,
          area : 12,
          category : 96,
          description : null,
          website : false,
          attr : {
              weight_unit : "",
              plus_product : "",
              dimension : "",
              attributes : "",
              length : "",
              width : "",
              colour : "",
              colour1 : "",
              colour2 : ""
          },
          images: [],
          status : "1"
        }
      },
/* start */
fabricAttributeSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { fabric_attribute: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.fabricAttributes.push(itemConst)
  this.form.attr.attributes = v.slug
},
materialSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { material: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.materials.push(itemConst)
  this.form.attr.material = v.slug
  self.materials1 = []
  if(v.attr.attributes.length > 0){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { material1: item.text, code: item.slug,selected : false }
      self.materials1.push(c1)
      if(index == 0){
        self.form.attr.material1 = c1.code
      }
    })
  }
},
useApplicationSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { application: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.application.push(itemConst)
  this.form.attr.use_application = v.slug
},
widthRangeSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { widthrange: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.widthrange.push(itemConst)
  this.form.attr.width_range = v.slug
},
patternSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { pattern: v.name, code: v.slug,selected : false, attributes : v.attr.attributes}
  this.pattern.push(itemConst);
  this.form.attr.pattern = v.slug

  console.log(this.pattern)
  console.log(itemConst)
  self.pattern1 = []
  if(v.attr.attributes.length > 0){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { pattern1: item.text, code: item.slug,selected : false }
      self.pattern1.push(c1)
      if(index == 0){
          self.form.attr.pattern1 = c1.code
      }
    })
  }

},
colourSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { colour: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.colour.push(itemConst);
  this.form.attr.colour = v.slug
  self.colour1 = []
  if(v.attr.attributes.length){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { colour1: item.text, code: item.slug,selected : false }
      self.colour1.push(c1)
      if(index == 0){
        self.form.attr.colour1 = c1.code
      }
    })
  }
},
brandSuccess(response){
  /* const self = this;
  const v = response.data;
  const itemConst = { colour: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.colour.push(itemConst);
  this.form.attr.colour = v.slug
  self.colour1 = []
  if(v.attr.attributes.length){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { colour1: item.text, code: item.slug,selected : false }
      self.colour1.push(c1)
      if(index == 0){
        self.form.attr.colour1 = c1.code
      }
    })
  } */
},
collectionSuccess(v){
  
          this.collections.push(v.data);
},
removeImage(i,isDefault){
  var self = this;
 MessageBox.confirm('This will permanently delete the image?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      self.form.images.splice(i,1)                        
                    })
},
onSelectPattern(v){
  const self = this;
  this.form.attr.pattern1 = ""
  self.pattern1 = [];
  const selected = this.pattern.filter(i => {
    return i.code === v
  })[0]

  if(selected){
    selected.attributes.forEach((item, index) => {
      const c1 = { pattern1: item.text, code: item.slug,selected : false }
      self.pattern1.push(c1)
      if(index == 0){
          self.form.attr.pattern1 = c1.code
      }
    })
  }
  

},
onSelectMaterial(v){
  const self = this;
  this.form.attr.material1 = ""
  self.materials1 = [];
  const selected = this.materials.filter(i => {
    return i.code === v
  })[0]

  if(selected){
    selected.attributes.forEach((item, index) => {
      const c1 = { material1: item.text, code: item.slug,selected : false }
      self.materials1.push(c1)
      if(index == 0){
          self.form.attr.material1 = c1.code
      }
    })
  }

},
onSelectColour(v){
  const self = this;
/*   this.form.attr.colour1 = "" */
  self.colour1 = [];
  const selected = this.colour.filter(i => {
    return i.code === v
  })[0]

  /* if(selected){
    selected.attributes.forEach((item, index) => {
      const c1 = { colour1: item.text, code: item.slug,selected : false }
      self.colour1.push(c1)
      if(index == 0){
          self.form.attr.colour1 = c1.code
      }
    })
  }
   */

},
onSelectColour1(v){

  const selected = this.colour.filter(i => {
    return i.code === self.form.attr.colour
  })[0]

  console.log(selected)
},
onSelect(e,r){
  
  this.form.images.forEach((img,i) => {
      if(r.token_id === img.token_id){
          this.form.images[i].default = 1
      }else{
        this.form.images[i].default  = 0
      }
  })
},

convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
},

openBrandForm(){
this.$refs.brandForm.openDialog()
},
openCollectionForm(){
this.$refs.collectionForm.openDialog()
},
openWidthRangeForm(){
this.$refs.widthRangeForm.openDialog()
},
openColourForm(){
this.$refs.colourForm.openDialog()
},
openUseApplicationForm(){
this.$refs.useApplicationForm.openDialog()
},
openMaterialForm(){
this.$refs.materialForm.openDialog()
},
openPatternForm(){
this.$refs.patternForm.openDialog()
},
openFabricAttributeForm(){
this.$refs.fabricAttributeForm.openDialog()
},

openGalleryManager(){
this.$refs.gallery.openDialog()
},

materialBlur(e){
  console.log(e)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();
              
            }

      },
      optionsHandler(){
        var self = this;
        /* self.GetCollection().then((response) => {
            self.collections = response
        }) */
        self.GetBrands().then(response => {
            /* const brand = [];
            response.forEach(element => {
              brand.push({brand : element.name,code : element.brand_id})
            }); */
            self.brands = response
          });


        self.GetBrandRelationship({'endpoint' : 'area'}).then((response) => {
            self.area = response

        })
        self.GetBrandRelationship({'endpoint' : 'category'}).then((response) => {
            self.category = response

        })
        self.GetBrandRelationship({'show_in' : 'wallpaper', 'endpoint' : 'collections'}).then((response) => {
            self.collections = response
        })


        self.getColour({'show_in' : 'wallpaper'}).then(response => {

          const c = [];

            if(response.length){
              response.forEach(e => {
                c.push({
                  colour : e.label,
                  code : e.slug,
                  attributes : e.attributes,
                })
              })
              self.colour = c
            }
        })
        

      },
      
      uploadImage(){
        this.$refs.upload.openDialog();
      },

      nextCode(){
        var self = this;
        this.GetNextCode().then((response) => {
          self.form.item_code = response.code
        });
      },

      


      fnImageSelect(v){
        
        if(v.length > 0){
          v.forEach((r,i) => {
            const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
            if(i === 0){
              d.default = 1;
            }
              this.form.images.push(d)
          })

        }
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },
      
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }

        

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      

    },

    computed: {

      ...mapGetters('WallpaperModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      group2 : function(){
        return (this.group + 2);
      },
      filterCollections : function() {
        const response = [];
        if(this.collections.length > 0){
          this.collections.forEach((el, index) => {
            /* if(index === 0 && this.FormStatus == 'create'){
                this.form.collection = el.value
              } */
            response.push({collection : el.name,code : el.mdbID})  
          })
        }
        return response;
          
      },
      colourSubFilter : function() {
        const response = [];
        if(this.collections.length > 0){
          this.collections.forEach((el, index) => {
            if(index === 0 && this.FormStatus == 'create'){
                this.form.collection = el.value
              }
            response.push({collection : el.name,code : el.mdbID})  
          })
        }
        return response;
          
      },
      filterBrands : function() {
        const response = [];
        if(this.brands.length > 0){
          this.brands.forEach((el, index) => {
            
            response.push({brand : el.name,code : el.mdbID})  
          })
        }
        return response;
          
      },
      checkRoleNewButton : function(){
            if(this.$helpers.isSalesExecutive()){
                return false
            }
            return true;
        }


    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          // this.form = this.FormRecord.row;
           this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
           this.form.status = this.form.status.toString()
           this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },

      "form.name" : function(v){
        if(this.FormStatus === 'create' && v != null){
          this.form.slug = this.convertToSlug(v)
        }
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>