import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {SurveyModule} from './modules/survey.module'
export default new Vuex.Store({
    modules : {
        SurveyModule
    },
    getters : {
        
    }
})