<template>
    <div>
        <el-dialog :modal="true" :fullscreen="false" :title="'Item'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" size="small" class="text-left item-dialog">
            <div v-loading="viewLoading">
                <div class="p-1">
                    <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between m-1" style="background-color: #fff;">
                        <mdb-navbar-brand class="table-header-navbar-brand">
                            <mdb-tabs class="table-header-tabs" :active="activeTabIndex" default :links="mdbTabsData" @activeTab="getActiveTab"/>
                        </mdb-navbar-brand>                
                    </mdb-navbar>    
                </div>

                <div class="dialog-scroll-container">
                    <template v-if="activeTabSlot == 'main'">
                        <mdb-row class="tab-row">
                            <mdb-col col="8" class="mt-2">
                                <thats-fieldset class="m-2">

                                    <mdb-row>
                                        <mdb-col col="12" class="pr-0 text-right">
                                            <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0 m-1'" :label="'SALES ITEM'" vid="item-status" orientation="inline" name="item-status" :data="[]" v-model="formData.sell" :validated="validated" :rules="''" size="sm" :multiple="false" :readOnly="readOnly"/>
                                        </mdb-col>
                                        
                                    </mdb-row>

                                    
                                    <mdb-row>
                                        <mdb-col col="6">
                                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="formData.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />                  
                                        </mdb-col>
                                        <mdb-col col="6">
                                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Supplier Code" placeholder="Supplier Code" vid="barcode" name="barcode" v-model="formData.attr.barcode" :validated="validated" :rules="'required'" :keycode="false" size="sm" :readOnly="readOnly" />                  
                                        </mdb-col>
                                    </mdb-row>
    
                                    <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Item Name" placeholder="Item Name" vid="namex" name="namex" v-model="formData.name" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>
                                    <ThatsInput tabindex="3" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Dimension" placeholder="WxHxD (cm)" vid="dimension" name="dimension" v-model="formData.dimension" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>
                                    <mdb-row>
                                        <mdb-col col="2">
                                        
                                        </mdb-col>
                                        <mdb-col col="10">
                                            <mdb-row>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Height" placeholder="0" vid="height" name="height" v-model="formData.attr.dimension_height" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Width" placeholder="0" vid="width" name="width" v-model="formData.attr.dimension_width" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Depth" placeholder="0" vid="depth" name="depth" v-model="formData.attr.dimension_depth" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Diameter" placeholder="0" vid="diameter" name="diameter" v-model="formData.attr.dimension_diameter" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                        </mdb-row>
                
                
                                        <mdb-row>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Hole" placeholder="0" vid="hole" name="hole" v-model="formData.attr.hole" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Capacity" placeholder="0" vid="capacity" name="capacity" v-model="formData.attr.capacity" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Weight" placeholder="0" vid="weight" name="weight" v-model="formData.attr.weight" :validated="validated" :rules="''" size="sm" :horizontal="true" :readOnly="readOnly"/>
                                            </mdb-col>
                                            <mdb-col col="3">
                                            <ThatsInput tabindex="11" ref="cube_unit" labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Cubic Feet" v-model="formData.attr.cube_unit" :rules="''" size="sm"  :horizontal="true" :readOnly="readOnly"></ThatsInput>
                                            </mdb-col>
                                            
                                        </mdb-row>
                
                                        
                                        </mdb-col>
                                    </mdb-row>
    
                                    <mdb-row>
                                        <mdb-col col="6">
                                            <ThatsMoney tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="formData.sale_price" :rules="'required'" :validated="validated" :readOnly="readOnly"/> 
                                        </mdb-col>
                                       <!--  <mdb-col col="3">
                                            <ThatsMoney tabindex="4"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Discount" placeholder="0" vid="discount_percent" name="discount_percent" size="sm" v-model="formData.attr.discount.percent" :rules="'required'" :validated="validated" :readOnly="readOnly" :money="itemDiscountPercentConfig"/> 
                                        </mdb-col>
                                        <mdb-col col="3">
                                            <ThatsMoney :show-label="false" tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-12 pl-0" label="" placeholder="0" vid="discount_value" name="discount_value" size="sm" v-model="formData.attr.discount.value" :rules="'required'" :validated="validated" :readOnly="readOnly"/> 
                                        </mdb-col> -->
                                       
                                    </mdb-row>
    
                                    
                                </thats-fieldset>
    
                                <thats-fieldset class="m-2">
                                <label class="small">COUNTRY OF ORIGIN</label>
                                <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="English" placeholder="English" vid="collection_en" name="collection_en" v-model="formData.attr.collection.en" :validated="validated" :rules="''" size="sm" :readOnly="readOnly"/>
                                <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Arabic" placeholder="Arabic" vid="collection_ar" name="collection_ar" v-model="formData.attr.collection.ar" :validated="validated" :rules="''" size="sm" :readOnly="readOnly" :inputclass="['text-right']"/>
                                
                                </thats-fieldset>
    
    
                                <thats-fieldset class="m-2">
                                    <mdb-row>
                                    <mdb-col col="6">
                                        <ThatsAutocomplete :clearable="false" tabindex="6" @select="onBrandSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Brand" v-model="formData.brand" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="brandComputed" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                        <ThatsAutocomplete :clearable="false" tabindex="8" @select="onCategorySelect" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Category" v-model="formData.category" :reduce="category => category.code" :reduceLabel="'category'" :options="filterCategory" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                        
                                       
                                    </mdb-col>
                
                                    <mdb-col col="6">
                                        <ThatsAutocomplete :clearable="false" tabindex="7"  @select="onAreaSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Area" v-model="formData.area" :reduce="area => area.code" :reduceLabel="'area'" :options="filterArea" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                        <ThatsAutocomplete :clearable="false" tabindex="9" :addButton="false" :appendButton="false" :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Attributes" v-model="formData.attr.attribute" :reduce="attribute => attribute.code" :reduceLabel="'attribute'" :options="filterAttributes" :rules="''" :disabled="readOnly"></ThatsAutocomplete>
                                        
                                    </mdb-col>
                                    </mdb-row>
                           
                                    <mdb-row>
                                    <mdb-col col="6">
                                        <ThatsAutocomplete :clearable="false" tabindex="10" @newWindow="openCollectionForm" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Collections" v-model="formData.collection" :reduce="collection => collection.code" :reduceLabel="'collection'" :options="filterCollections" :rules="'required'" :disabled="readOnly"></ThatsAutocomplete>
                                        <mdb-row class="form-group input-group-sm thats-form">
                                        <mdb-col col="4">
                                            <label class="col-form-label">Materials</label>
                                        </mdb-col>
                                        <mdb-col col="8">
                                            <ThatsVueSelect tabindex="5" @newWindow="openMaterialForm" @select="onSelectMaterial" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="formData.attr.materials" :reduce="material => material.code" :reduceLabel="'material'" :options="materials" :rules="''" :showLabel="false" :clearable="false" :disabled="readOnly"></ThatsVueSelect>
                                            <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="materials1" name="materials1" v-model="formData.attr.materials1" :validated="validated" :rules="''" size="sm" :showLabel="false" :readOnly="readOnly"/>  
                                        </mdb-col>
                                        </mdb-row>
                                        <ThatsInput tabindex="13" ref="autoFinishes" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Finishes" v-model="formData.attr.finishes" :rules="''" :readOnly="readOnly"></ThatsInput>
                                        <ThatsInput tabindex="12" ref="autoFabric" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Fabric" v-model="formData.attr.fabric" :rules="''" :readOnly="readOnly"></ThatsInput>
                                        <mdb-row  class="form-group input-group-sm thats-form">
                                        <mdb-col col="4">
                                            <label class="col-form-label">Colour</label>
                                        </mdb-col>
                                        <mdb-col col="8">
                                            <ThatsVueSelect tabindex="5" @newWindow="openColourForm" @select="onSelectColour" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="formData.attr.colour" :reduce="colour => colour.code" :reduceLabel="'colour'" :options="colour" :rules="''" :showLabel="false" :clearable="false" :disabled="readOnly"></ThatsVueSelect>
                                            <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour1" name="colour1" v-model="formData.attr.colour1" :validated="validated" :rules="''" size="sm" :showLabel="false" :readOnly="readOnly"/>
                                            
                                        </mdb-col>
                                        
                                        </mdb-row>
                                        <ThatsRadio tabindex="16" :labelClass="'col-md-4'" :fieldClass="'col-md-8  pl-0'" label="Item Type" vid="item-type" orientation="inline" name="item-type" :data="itemType" v-model="formData.item_type" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>
                                    
                                    
                                    </mdb-col>
                                    <mdb-col col="6">
                                        <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="formData.description" size="sm" :readOnly="readOnly"/>
                                        <ThatsRadio tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" label="Status" vid="item-status" orientation="inline" name="item-status" :data="itemStatus" v-model="formData.status" :validated="validated" :rules="'required'" size="sm" :readOnly="readOnly"/>
                                    </mdb-col>
                                    </mdb-row>
                
                                    </thats-fieldset>
                                    
    
                            </mdb-col>


                            <mdb-col col="4" class="mt-2 pl-0">
                                <thats-fieldset class="m-2 ml-0">
                                <template v-if="formData.image.length > 0">
                                    <mdb-carousel
                                        :items="formData.image.length"
                                        controlls
                                        :controllIcons="['fas fa-arrow-circle-left fa-2x text-primary', 'fas fa-arrow-circle-right fa-2x text-primary']"
                                    >
                                        <template #[i+1] v-for="(img, i) in formData.image">
                                            <mdb-card :key="i" style="height: 359px;">
                    
                                                <template v-if="!readOnly">
                                                <div class="custom-control image-checkbox custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" @change="onSelect($event,img)" :checked="img.default" :id="'imageCheckbox'+img.mdbID" :value="img.medium">
                                                    <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID" title="Set as default">Set as default</label>
                                                </div>
                                                <div class="custom-control image-delete" @click="removeImage(i,img.default)">
                                                    <mdb-icon icon="trash"></mdb-icon>
                                                </div>
                                                </template>
                                                <mdb-card-image :src="img.medium" :alt="img.filename" ></mdb-card-image>
                                                
                                            </mdb-card>
                                        </template>
                
                                    
                
                                    
                                    </mdb-carousel>
                                </template>
                                <template v-else>
                                    <div class="no-image-gallery">
                                    <mdb-icon far icon="images" />
                                    </div>
                                </template>
                                <template v-if="!readOnly">
                                <a href="javascript:void(0)" tabindex="17" @click="uploadImage"><mdb-icon icon="image"></mdb-icon> Select Image</a>
                                </template>
                            </thats-fieldset>

                            <thats-fieldset class="m-2">
                                    <label class="small">STOCK DETAILS</label>
                        
                                    
                                    <template v-if="formStatus ==='update'">
                                        <template v-if="warehousesLoading">
                                            <p class="sm text-muted"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...</p>
                                        </template>
                                        <template v-else>
                    
                                            <ThatsNumber tabindex="18" :labelClass="'col-sm-5'" :fieldClass="'col-sm-7 pl-0'" label="In Stock" placeholder="Qty" vid="qty" name="qty" v-model="formData.stock" :validated="validated" :rules="'required'" size="sm" :disabled="true" />
                                        
                                            <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Sold" v-model="formData.soldQty" :rules="''" :disabled="true"></ThatsNumber>
<!--                                             <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Committed" v-model="formData.Committed" :rules="''" :disabled="true"></ThatsNumber>
                                            <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Available" v-model="formData.Available" :rules="''" :disabled="true"></ThatsNumber> -->
                    
                    
                                            
                                        </template>
                                    </template>
                
                
                                
                                            
                
                                </thats-fieldset>
                                
                            </mdb-col>
                        </mdb-row>
                    
                    </template>


                    <template v-if="activeTabSlot == 'inventory-slot'">
                        <mdb-row class="tab-row">
                            <mdb-col col="12" class="mt-2">
                                <el-table border :data="(formData.warehouses.filter((item) => item.InStock > 0 || item.Committed > 0 || item.Ordered > 0 || item.Available > 0, []))" stripe class="input-table mt-0 custom-element-table" :summary-method="getSummary" show-summary>
                                    <el-table-column label="Warehouse" width="250">
                                        <template slot-scope="scope">
                                        {{ scope.row.WarehouseName }}
                                
                                        </template>
                                        </el-table-column>
                                        <el-table-column label="In Stock" width="120">
                                        <template slot-scope="scope">
                                        {{ scope.row.InStock }}
                                
                                        </template>
                                        </el-table-column>
                                        <el-table-column label="Committed" width="120">
                                        <template slot-scope="scope">
                                        {{ scope.row.Committed }}
                                
                                        </template>
                                        </el-table-column>
                                        <el-table-column label="Ordered" width="120">
                                        <template slot-scope="scope">
                                        {{ scope.row.Ordered }}
                                
                                        </template>
                                        </el-table-column>
                                        <el-table-column label="Available" width="120">
                                        <template slot-scope="scope">
                                        {{ scope.row.Available }}
                                
                                        </template>
                                        </el-table-column>
                                        <el-table-column label=""></el-table-column>
                                    </el-table>
                                    </mdb-col>
                                    </mdb-row>
                    </template>
                </div>

            </div>
            <template slot="footer">
                <div class="pl-3 pr-3">
                    <div class="d-flex">
                        <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                    </div>
                </div>
            </template>
        </el-dialog>

    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver } from "vee-validate";
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'

export default {
    components : {
        mdbBtn,
        mdbTabs,
        mdbNavbar,
        mdbNavbarBrand,
        mdbContainer,
        mdbSelect, 
        mdbRow,
        mdbCol, 
        mdbIcon, 
        mdbCarousel, 
        mdbCard,
        mdbCardImage,
        ValidationObserver,
        ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
    },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    data(){
        return {
            syncModal : false,
            activeTabIndex : 0,
            activeTabObject : { text: 'Item Details', tabSlot : 'main' },
            mdbTabsData : [
                { text: 'Item Details', tabSlot : 'main' }, { text: 'Inventory Data', tabSlot : 'inventory-slot' }
            ],

            formData : null,

            readOnly : true,
            showError : true,
            validated : true,
            itemType : [
                {label : "Stock", value : "stock"},
                {label : "Services", value : "services"},
            ],

            itemStatus : [
                {label : "New Arrival", value : "2"},
                {label : "Active", value : "1"},
                {label : "Discontinued", value : "0"},
            ],

            inventoryData : [],

            filterAttributes : [],
           
            finishes : [],
            fabric : [],
            showError : false,
            materials : [],
            materials1 : [],
            colour : [],
            colour1 : [],
            locations : [],
            vendors : [],
            brands : [],
            collections : [],
            category : [],
            attributes : [],
            area : [],
            itemDiscountPercentConfig : {
                    decimal: '.',
                    thousands: ',',
                    prefix: '',
                    suffix: ' %',
                    precision: 2,
                    masked: false
            }
        }
    },
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))
    },
    mounted(){
        const self = this
        console.log("mounted")
            console.log(this.categoryComputed)


            self.getColour({'show_in' : 'product'}).then(response => {

                const c = [];

                if(response.length){
                    response.forEach(e => {
                    c.push({
                        colour : e.label,
                        code : e.slug,
                        attributes : e.attributes,
                    })
                    })
                    self.colour = c
                }
                })
                self.getMaterials({'show_in' : 'product'}).then(response => {
                const c = [];
                if(response.length){
                    response.forEach(e => {
                    c.push({
                        material : e.label,
                        code : e.slug,
                        attributes : e.attributes,
                    })
                    })
                    self.materials = c
                }
                }) 


    },
    methods : {
        ...mapMutations('ItemModule',['cancelRequest']),
        ...mapActions('ColourModule',[ 'getColour' ]),
        ...mapActions('MaterialModule',[ 'getMaterials' ]),
        openDialog(){
            this.syncModal = true
        },
        closeDialog(){
            this.syncModal = false
        },
        resetDefaultTab(){
            this.activeTabIndex = 0,
            this.activeTabObject = { text: 'Item Details', tabSlot : 'main' }
        },
        getActiveTab(v){
            this.activeTabIndex = v
            this.activeTabObject = this.mdbTabsData[v];
        },

        onBrandSelect(){

        },
        onCategorySelect(){

        },
        onAreaSelect(){

        },
        onSelectMaterial(){

        },
        onSelectColour(){

        },
        openCollectionForm(){

        },
        openMaterialForm(){

        },
        openColourForm(){

        },

        removeImage(i,isDefault){
            var self = this;
            MessageBox.confirm('This will permanently delete the image?', 'Warning', {
                                confirmButtonText: 'OK',
                                cancelButtonText: 'Cancel',
                                type: 'warning'
                                }).then(() => {
                                self.formData.image.splice(i,1)                        
                                })
            },

            onSelect(e,r){
            
            this.formData.image.forEach((img,i) => {
                if(r.token_id === img.token_id){
                    this.formData.image[i].default = 1
                }else{
                    this.formData.image[i].default  = 0
                }
            })
        },

        uploadImage(){

        },

        getSummary(param){
        const self = this;
        const { columns, data } = param;
        const sums = [];
        // const numberColumns = [2,3,4,5,6,7,8]
        
        let totalCol = 2;
        let totalQty= 3;
        // let totalPrice= 4;
        let totalAmount= 5;
        // let totalDiscount= 6;
        let totalTotal= 7;
        
        columns.forEach((_column, index) => {
    

          
          if (index === 0) {
            sums[index] = 'Total';
            return;
          }
          if (index === 1) {
            const qty = data.map(item => {
                return parseInt(item.InStock)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }

          if (index === 2) {
            const qty = data.map(item => {
                return parseInt(item.Committed)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }

          if (index === 3) {
            const qty = data.map(item => {
                return parseInt(item.Ordered)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }

          if (index === 4) {
            const qty = data.map(item => {
                return parseInt(item.Available)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
         

            

          });

          // this.toggleCheck()

        return sums;
      }

    },

    computed : {
        ...mapGetters('ItemModule',{
            formRecord : 'FormRecord',
            viewLoading : 'ViewLoading',
            formObject : 'formObject',
            formStatus : 'FormStatus',
        }),
        activeTabSlot : function(){
            return this.activeTabObject.tabSlot
        },
        
        brandComputed : function(){
          return this.brandProvider();
        },

        categoryComputed : function(){
          return this.categoryProvider();
        },
        areaComputed : function(){
          return this.areaProvider();
        },

        collectionComputed : function(){
          return this.collectionProvider();
        },
       /*  colorComputed : function(){
          return this.colorProvider();
        }, */
        warehousesLoading : function(){
            return false
        },

        filterArea : function() {
            if(this.formData.brand === null){
            return []
            }
            const results = this.area.filter((item, index) => {
                return item.brand === this.formData.brand
            })

            if(results.length > 0 && this.formStatus == 'create'){
                    this.formData.area = results[0].code
            }
            return results;
        },
        filterCategory : function() {
        
            if(this.formData.brand === null && this.formData.area === null){
            return []
            }

            const results = this.category.filter((item, index) => {
                return item.brand === this.formData.brand && item.area === this.formData.area
            })

            if(results.length > 0 && this.formStatus == 'create'){
                    this.formData.category = results[0].code
            }
            return results;
        },
        filterCollections : function() {
            if(this.formData.brand === null){
            return []
            }
            const results = this.collections.filter((item, index) => {
                return item.brand_id === this.formData.brand
            })


            const response = [];
            if(results.length > 0){

            results.forEach((el, index) => {
                if(index === 0 && this.formStatus == 'create'){
                    this.formData.collection = el.value
                }
                response.push({collection : el.name,code : el.mdbID})  
            })
            }
            return response;
            
        },
    },

    watch : {
        syncModal : function(value){
            if(!value){
                this.cancelRequest();
                this.resetDefaultTab();
                this.formData = JSON.parse(JSON.stringify(this.formObject))
            }
        },

        formRecord : function(value){
            console.log("formRecord")
            console.log(value)
            this.formData = value
        },
        categoryComputed : function(value){
            console.log("watch category")
            console.log(value)
            this.category = value
        },
        areaComputed : function(value){
            console.log("watch area")
            console.log(value)
            this.area = value
        },
        collectionComputed : function(value){
            console.log("watch collectionComputed")
            console.log(value)
            this.collections = value
        }
    }
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 158px 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>