<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Sales Transaction" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                  <template v-if="formStatus == 'update'">
                    <mdb-btn @click="fnPrint('/sales/transactions/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print</mdb-btn>
                     
                  </template>
                </template>

                <template v-if="selected.length > 0">
                    <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                  </template>
              </template>

            <template #right-button>
              <template v-if="formStatus == 'update'">
                  <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Make Payment</mdb-btn>

              </template>

              
            </template>

          <mdbContainer class="mt-1">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
<!-- 
                <button type="button" @click="sendRequest">send</button>
                <button type="button" @click="cancelRequest">cancel</button>
                <div class="request" v-for="(req, index) of requests" :key="index">
      Request {{ index + 1 }}
      {{ req.msg }}
    </div>

    <div v-if="request" class="request">
      Request {{ requests.length + 1 }}
      {{ request.msg }}
    </div> -->
                  
                <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>                  
                      <template v-if="formStatus === 'update' && form.status !== 'no-remarks'">
                          <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.attr.name" size="sm" :disabled="disabled"/>
                        
                          
                      </template>
                      <template v-else>
                        <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Customer" v-model="form.customer_id" :reduce="customer => customer.value" :reduceLabel="'opt'" :options="customer" :rules="'required'" :clearable="false" :disabled="disabled" @select="getCustomerSelected($event)" :addButton="true" @newWindow="openCustomer" @onSearch="searchCustomer" :selected_option="true"></ThatsCustomMultiSelect>
                      </template>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="false"/>
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />                  
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sold By" :placeholder="form.sold_by" vid="sold_by" name="sold_by" v-model="form.sold_by" size="sm" :disabled="true" />
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Order Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false"></ThatsMultiSelect>
                        <template v-if="form.status === 'for-delivery-schedule'">
                          <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date Request" placeholder="Date Request" vid="date_request" name="date_request" v-model="form.delivery.date" :validated="validated" :rules="'required'" size="sm"/>
                          <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Delivery Remarks" :placeholder="form.attr.delivery_remarks" vid="delivery_remarks" name="delivery_remarks" v-model="form.delivery.remarks" size="sm" />
                        </template>
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Payment Status" v-model="form.attr.payment_status" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="''" :filterable="false" :clearable="false"></ThatsMultiSelect>
                    </mdb-col>

                  
                  </mdb-row>
                  <el-table border :data="form.items" class="input-table mt-2 itemTable" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell">
                            <template v-if="formStatus === 'create' || disabled === false">
                              <el-table-column type="selection" width="35"> </el-table-column>
                            </template>
                            <el-table-column label="SKU" :width="150">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                    {{scope.row.code}}
                                </template>
                                <template v-else>
                                  <GridVueSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></GridVueSelect>
                                </template>
                                
                              </template>
                            </el-table-column>
                            <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                            <el-table-column label="Item Name" :row-class-name="''">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                  
                                    {{scope.row.name}}
                                </template>
                                <template v-else>
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                              </template>
                            </el-table-column>
                            <template v-if="form.invoice === null">
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="disabled">
                                  
                                    {{scope.row.qty}}
                                </template>
                                <template v-else>
                                  <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                                
                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount"></span>
                                  <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <template v-else>
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="disabled">
                                  
                                    {{scope.row.qty}}
                                </template>
                                <template v-else><input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" :disabled="disabled" />
                                </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount"></span>
                                 <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <el-table-column label="Remarks">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="disabled" />
                              </template>
                            </el-table-column>
                          </el-table>

                          <table class="el-table">
                <tr>
                  <template v-if="!disabled">
                  <td></td>
                  </template>

                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style="width:100px"  class="text-right">
                    

                    <!-- <ThatsCheckbox :label="''" vid="enable-discount" orientation="inline" name="enable-discount" :data="[]" v-model="form.more_discount.enable" :validated="validated" :rules="''" size="sm" :multiple="false" style="float: right; display: block; width: 25px;"/> -->
</td>
                  <td style="width:100px"><el-checkbox v-model="form.more_discount.enable" :disabled="disabled">More Discount </el-checkbox></td>
                  <td style="width:100px"><input type="text" class="form-control grid-input thats-form-control text-right" v-model="form.more_discount.discount" @keydown.enter="addMoreDiscount()" :disabled="disabled" /></td>
                  <td style="width:120px" class="text-right">
                      <strong>{{form.total}}</strong>
                  </td>
                  <template v-if="disabled">
                      <td style="width:130px"></td>
                  </template>
                  <template v-else>
                  <td style="width:114px"></td>
                  </template>
                </tr>
            </table>
            
                          
              </template>
              
             
            </mdb-tabs>
            
            
          </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>
      <ContactsForm ref="contactForm" :group="3" @success="contactSuccess"></ContactsForm>
      <PaymentForm ref="paymentForm" :group="3" @success="paymentSuccess" @print="fnPrint"></PaymentForm>
      <UpdatePaymentForm ref="updatePaymentForm" :data="paymentForm" :group="3" @success="updatePaymentSuccess" @print="fnPrint" :formStatus="paymentFormStatus"></UpdatePaymentForm>
      <DiscountForm ref="discount" :group="3" @apply="applyDiscount"></DiscountForm>
      <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import ContactsForm from '../contacts/ContactsForm'
import DiscountForm from './DiscountForm'
import PaymentForm from './PaymentForm'
import UpdatePaymentForm from './UpdatePaymentForm'
import PrintWindow from '../../components/thats/PrintWindow'
import GridVueSelect from './GridVueSelect'
import TableFooter from './TableFooter';
import Sortable from 'sortablejs'
import Checkbox from './Checkbox'
import axios from 'axios'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect, 
        PrintWindow, 
        GridVueSelect, 
        ContactsForm,
        DiscountForm,
        PaymentForm,
        TableFooter,
        Sortable,
        Checkbox,
        UpdatePaymentForm

  },
    
    data : () => ({
      request : null,
      requests : [],
      printUrl : '',
      active : 1,
      validated : false,
      form : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      tabs : [
                { text: 'Sales Details', slot: 'form-slot' }
              ],
      delivery_status : [],
      showError : false,
      status : [],
      paymentMethod : [],
      items : [],
      selected : [],
      customer : [],

      discountForm : null,
      discountFormIndex : null,
      paymentForm : {
                series : 0,
                date : "",
                mode : "full-payment",
                method : "cash",
                customer : "",
                attr : {},
                description : null,
                invoice_series : null,
                amount : 0,
                reference : null,
                transaction_id : null,
                invoice : {
                  series : null,
                  balance : 0,
                  paid : 0,
                  amount : 0,
                },
                
              },
      paymentFormStatus : 'update',
      customFooter : {rows : [{name : "demo"},{remarks : "remarks"}], columns : [{
              field : 'name'
          },{
              field : 'remarks'
          }]},
      total : 0
    }),
    

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.form.items.push(formItemVar);
        
        this.nextCode()
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' },
                
              ]
        
      }
       this.optionsHandler();
       this.discountForm = this.formItemVar();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.paymentMethod = settings.payment_mode
        this.status = settings.sales_transaction_order_status;
    },

  mounted(){
const tbody = document.querySelector('.itemTable .el-table__body-wrapper tbody')
         Sortable.create(tbody)
  },
    methods: {

      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('SalesOrderModule',[
        'storeRecord',
        'updateRecord',
        'filterItems',
        'updateStatus',
        'getRecord',
        
      ]),
      ...mapMutations('SalesOrderModule',[
        'setFormStatusCreate',
        'setPaymentForm',
        'setFormRecord',
        'setFormStatusUpdate',
        'setDiscountForm',
      ]),

      formVar(){
        return {
          series : "",
          date : "",
          status_index : 0,
          status : "no-remarks",
          attr : {
            name : null,
            mobile : null,
            address : null,
            instruction : null,
            other_details : null,
            payment_status : 'no-payment',
          },
          customer : {
            name : null,
            mobile : null,
            address : null,
          },
          user_id : null,
          sold_by : null,
          customer_id : null,
          payment_method : 'cash',
          items : [            
          ],
          attachment : [],
          invoice : {
            series : null
          },
          delivery : {
            date : null,
            remarks : null,
            status : 'for-delivery-schedule',
          },
          total : 0,
          more_discount : {
              enable : false,
              discount : 0,
          }
        }
      },

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null
              }      
      },
/* start */
paymentSuccess(response){
  this.setFormRecord(response.data)
  this.$emit('success')
},
updatePaymentSuccess(v){
  const self = this;

  self.getRecord({token_id : self.form.token_id}).then((response) => {
    self.active = 1
  })
},
applyDiscount(v){

  const frm = v.form;
    const qty = frm.qty;
      let price = frm.price;
      let discount = 0;
      let remarks = null;

      if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,itemPromo);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }

      if(frm.discount_type === 'discount'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,frm.custom_discount);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }


   this.$set(this.form.items, v.index, frm)



    /*   if(frm.promotions.length > 0){
        const itemPromo = frm.promotions[0];
        discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          remarks = this.setRemarks(qty,itemPromo);

      }

      this.$set(this.form.items[v.index], 'discount', discount)
      this.$set(this.form.items[v.index], 'remarks', remarks)
      this.$set(this.form.items[v.index], 'total', this.computeTotalAmount(qty, price, discount))
      this.$set(this.form.items[v.index], 'custom_discount', frm.custom_discount) */


},
cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6,7,8]
        
        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

        if(self.formStatus === 'create' || this.disabled === false){
            totalCol = 3;
            totalQty= 4;
            totalPrice= 5;
            totalAmount= 6;
            totalDiscount= 7;
            totalTotal= 8;
        }
        
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {

            const discount = data.map(item => {
              let d = 0;
                  if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                    const itemPromo = item.promotions[0];
                    d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                    // alert(d)
                  }else{
                    d = unformat(item.discount)
                  }
                return d;
              })

                    
            
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                //  this.form.total =  this.formatNumber(sum)
                 this.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
    getActiveTabIndex(index){
      this.active = index
    },

openPaymentWindow(){
  const paymentformVar = {
    transaction : this.form
  }

  this.setPaymentForm(paymentformVar);
  this.$refs.paymentForm.openDialog()
},
    optionsHandler(){
      const self = this;
      this.dispatchFilterItems({transaction_date : this.form.date})
    },
    customLabel({label , mobile}){
      return `${label} – ${mobile}`
    },
    fnGetContacts(params){
      const self = this;
      const contacts = []
      this.GetContacts(params).then((response) => {
          if(response.length > 0){
            response.forEach((element) => {
              contacts.push({
                opt : element.label + " " + element.mobile,
                label : element.label,
                value : element.value,
                mobile : element.mobile,
                address : element.address,
              })
            })
          }
        
      })

      self.customer = contacts;
    },
    async setPreparedBy(){
        var self = this;
        const loggedUser = await userData()

        const frm = this.form
        frm.sold_by = loggedUser.user.name
        this.form = frm;
    },


  deleteItem(){

    if(this.selected.length){
        if(this.formStatus === 'update'){
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                  this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){

                  const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
              })
        }else{
          this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
        }
      

      
    }


// remove object


},

handleSelectionChange(v){
   this.form.items = v

   this.form.delivery_status = 'partial-delivery'
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    if(getSelected){

      const qty = 1;
      let price = getSelected.price;
      let discount = 0;
      let remarks = null;

      if(getSelected.promotions.length > 0){
        const itemPromo = getSelected.promotions[0];

        const cd = {
          apply: true,
          discount: 0,
          off_price: 0,
          promo: 0,
        }

        discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
          remarks = this.setRemarks(qty,itemPromo);
           this.$set(this.form.items[index], 'discount_type','promo');
      }

      console.log(getSelected)

      this.$set(this.form.items[index], 'item_id', getSelected.id)
      this.$set(this.form.items[index], 'name', getSelected.label)
      this.$set(this.form.items[index], 'default', getSelected.default)
      this.$set(this.form.items[index], 'qty', qty)
      this.$set(this.form.items[index], 'promotions', getSelected.promotions)
      this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
      this.$set(this.form.items[index], 'price', price)
      this.$set(this.form.items[index], 'amount', this.computeAmount(qty, price))
      this.$set(this.form.items[index], 'discount', discount)
      this.$set(this.form.items[index], 'remarks', remarks)
      this.$set(this.form.items[index], 'total', this.computeTotalAmount(qty, price, discount))
      // this.addNewRow(index)

      // console.log(this.form.items[index])
      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)
        }
    }
},

statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }

  
},

dispatchFilterItems(params){
  const results = [];
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default})
          });
        }
    })
  this.items = results

},

statusKeyUp(v){
// this.getPurchaseOrder(v)

const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.setFormStatusUpdate()
                              self.setFormRecord(response.data)
                              self.formLoading = false
                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                        
                        console.log(this.form)
                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                              self.setFormRecord(response.data)
                              self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Order', slot: 'form-slot' }
              ]

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
            this.tabs = [
                { text: 'Sales Order', slot: 'form-slot' }
              ]
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

/*         console.log("custom");
        console.log(custom_discount); */
        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 alert(amount)
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        // this.printUrl = url
        // this.$refs.printWindow.openDialog();

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(url, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

      },
      
      getDeliveryStatusSelected(v){
        console.log(v)
      },

      toggleCheck(){
        const self = this;
        if(this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);

          // alert(getCode.toString())
          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              })

               
                
              }else{
                self.$refs.itemsTable.clearSelection();
              } 

          // const indexZero = this.form.transaction.items[0];
          //     this.$refs.itemsTable.toggleRowSelection(indexZero);

        
        }
      },

      getRowKey(row){
        return row.code
      },
      fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["mobile", "label"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },

    searchCustomer(v){
        const self = this;  
        if(v !== ""){
            this.fnGetContacts({term : v})
        }
    },
      getCustomerSelected(v){
        const selectedCustomer = this.customer.filter(c => {
            return c.value === v;
        })[0];

        if(selectedCustomer !== null){
            this.form.attr.name = selectedCustomer.label
            this.form.attr.mobile = selectedCustomer.mobile
            this.form.attr.address = selectedCustomer.address
        }

      },

      openCustomer(){
        this.$refs.contactForm.openDialog();
      },
      contactSuccess(v){
        const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.address }
        this.customer.unshift(contact);
        this.form.customer_id = v.mdbID
        this.form.mobile = v.mobile
      },

      openPayment(paymentRow){
        this.paymentFormStatus = 'update'
        this.paymentForm = paymentRow
        this.paymentForm.invoice_series = this.form.series
        this.paymentForm.customer = this.form.attr.name + " "+ this.form.attr.mobile
        this.$refs.updatePaymentForm.openDialog();
      },
      openUpdatePaymentWindow(){
        this.paymentFormStatus = 'create'
        const pForm = {...this.paymentForm,invoice_series : this.form.seires, customer : this.form.attr.name + " "+ this.form.attr.mobile}
        // this.paymentForm.invoice_series = this.form.series
        this.paymentForm = pForm
        this.$refs.updatePaymentForm.openDialog();
      },
      addMoreDiscount(){

      },
      sendRequest(){
        if (this.request) this.cancelRequest();
          this.makeRequest()
      },
      cancelRequest(){
        this.request.cancel();
      this.clearOldRequest("Cancelled");
      },

      makeRequest(){
const axiosSource = axios.CancelToken.source();
          this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
          axios
            .get("/api/user/filter", { cancelToken: axiosSource.token })
            .then(() => {
              this.clearOldRequest("Success");
            })
            .catch(this.logResponseErrors);
      },
      logResponseErrors(err) {
      if (axios.isCancel(err)) {
        console.log("Request cancelled");
      }
    },
      clearOldRequest(msg) {
        this.request.msg = msg;
        this.requests.push(this.request);
        this.request = null;
      }

    },

    computed: {

      ...mapGetters('SalesOrderModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          if(self.formStatus === 'update'){
            return true;
          }
          return false;
      },


      
    },

    watch: {
      formRecord : function(v){
        const self = this;
        this.active = 0;
        if(this.formStatus == 'update'){

          const jsonParse = JSON.parse(JSON.stringify(this.formRecord));
          jsonParse.attr = {...this.form.attr,...jsonParse.attr}
          
          this.form = jsonParse;

          if(!this.disabled){
            const formItemVar = this.formItemVar();
            this.form.items.push(formItemVar)
          }
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

      'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              }).reduce((a,b) => {
                return a + b;
              })
              const formTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
              if(this.form.more_discount.enable){
                    const unformatedFormTotal = unformat(formTotal);
                    const discount = this.form.more_discount.discount;
                    this.form.total = formatMoney((unformatedFormTotal - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
                }else{
                  this.form.total = formTotal
                } 
                this.total = formTotal;
            }

          },
          deep: true
      },
      "form.more_discount.discount" : function(v){
          const discount = unformat(v);
          const total = unformat(this.total);
            this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });

          if(this.form.more_discount.enable === false){
            this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }


/* 
          if(this.form.more_discount.enable){
                    const unformatedFormTotal = unformat(this.form.total);
                    this.form.total = formatMoney((unformatedFormTotal - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }else{
            this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          } */
      },
      "form.more_discount.enable" : function(v){
        const discount = unformat(this.form.more_discount.discount);
          const total = unformat(this.total);
          this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
            // alert(typeof v + " ==== " + total + "-" + discount)
          if(v === false){
                this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }
          
          
            // this.form.more_discount = false;
          //   alert(1)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
          // if(this.form.more_discount === false){
          //   alert(false)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}
</style>