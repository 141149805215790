<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Showrooms Setup" windowClass="Showrooms height-auto" scrollClass='Showrooms height-auto' @deleteYes="emitDelete" :width="650" :formStatus="FormStatus" @dialog-state="dialogState">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
              <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-4">
             <mdb-row >

              <mdb-col col="12">
                  <ThatsSeriesInput labelClass="col-md-3" fieldClass="col-md-4 pl-0" label="Showroom ID" placeholder="Showrooms Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" size="sm" :disabled="true" />                  
                  <ThatsInput labelClass="col-md-3" fieldClass="col-md-4 pl-0" label="Warehouse Code" placeholder="Warehouse Code" vid="warehouse_code" name="warehouse_code" v-model="form.attr.warehouse_code" :validated="validated" :rules="'required'" size="sm"/>                  
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Prefix" vid="prefix" name="prefix" v-model="form.attr.prefix" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Showroom Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Arabic" vid="arabic_name" name="arabic_name" v-model="form.attr.arabic" :validated="validated" :rules="''" size="sm" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Phone/Mobile" vid="mobile" name="mobile" v-model="form.mobile" :validated="validated" :rules="''" size="sm" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="form.attr.address" :validated="validated" :rules="''" size="sm" />
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Store Timings" vid="storetimings" name="storetimings" v-model="form.attr.storetimings" :validated="validated" :rules="''" size="sm" />
                  <ThatsMultiSelect  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brands" v-model="form.attr.brands" :reduce="label => label.value" :reduceLabel="'label'" :options="filterBrands" :rules="'required'"></ThatsMultiSelect>
                   <mdb-row>
                      <mdb-col col="3">
                          <label for="name" class="col-form-label col-md-3">Image</label>
                      </mdb-col>  
                      <mdb-col col="9">
                         <ThatsImage @open-form="uploadImage" v-model="form.image" :size="'image-full'"></ThatsImage>
                        <label @click="uploadImage" class="click-to-add-logo">Add Image</label>
                      </mdb-col>  
                    </mdb-row>
           
              </mdb-col>




              
             </mdb-row>
         
          
          </mdbContainer>
        
       </ThatsWindow>

      <ImageGrid ref="upload" :multiple="false" @select="fnImageSelect" :group="2"></ImageGrid>

  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid'
export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        mdbBtn,
        mdbRow,
        // ImageUpload,
        ImageGrid,
        mdbCol  
        
  },
    
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,

      area : [],
      brands : [],
      category : [],
      filterBrands : [],

      status : [
            { status : "Active", code : "active"},
            { status : "Inactive", code : "inactive"}, 
          ]
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      } 
      
      this.optionsHandler()
    },
    methods: {
      ...mapActions('BrandModule',[
        'GetBrandErp'
      ]),
      ...mapActions('ShowroomsModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
      ]),
      ...mapMutations('ShowroomsModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          series : 0,
          name : null,
          mobile : null,
          reference : '',
          description : null,
          image : [],
          attr : {
            prefix : null,
            warehouse_code : null,
            address : null,
            email : null,
            storetimings : null,
            arabic : null,
            brands : [],
          },
          status : 'active',
          
        }
      },
/* start */

      optionsHandler(){
        const self = this;
          this.GetBrandErp().then((response) => {
            self.filterBrands = response;
          })
      },
      
      uploadImage(){
        this.$refs.upload.openDialog();
      },

      nextCode(){
        var self = this;
        this.GetNextCode().then((response) => {
          self.form.series = response.code
        });
      },

      demo(v){
        console.log(v)
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.image = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then(() => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success')
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
            this.nextCode();
        }

        this.optionsHandler();

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      }

    },

    computed: {

      ...mapGetters('ShowroomsModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
        }
      },

/*       "form.name" : function(val) {

        if(val !== null){
          const res = val.substring(0, 4)
          this.form.short = res.toUpperCase();
        }
      } */

      /* area : {
        immediate : true,
        handler(){
          this.areaHandle();
        }
        
      } */
    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>