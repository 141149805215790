import { ApiGetServiceVer2 } from '../../services'
export const SapInvoicesModule = {
    namespaced: true,
    state: {
        columns : [
            {
              "label": "Date",
              "field": "date_human",
              "width": "150"
            },
            {
              "label": "Series",
              "field": "series",
              "clickable" : true,
              "width": "150"
            },
            
            {
              "label": "Business Partner",
              "field": "customer_name_mobile"
            },
            {
              "label": "Sold By",
              "field": "sold_by"
            },
            {
              "label": "Total",
              "field": "total",
              "align": "right",
              "width": 150
            },
            {
              "label": "Balance",
              "field": "balance",
              "align": "right",
              "width": 150
            },
            
           /*  {
              "label": "Print",
              "field": "print",
              "align": "right",
              "width": 100
            } */
          ],
        data: {
            columns: [],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        formRecord: {},
        viewLoading : false,
        postLoading : false,
        cancelLoading : false,
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setCancelLoadingTrue: (state) => {
            state.cancelLoading = true
        },
        setCancelLoadingFalse: (state) => {
            state.cancelLoading = false
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
    },
    actions : {
        async resetData({dispatch}, endpoint){
            dispatch('asyncGetData', endpoint)
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "SapInvoicesModule/req",
                        loadingTrue : "SapInvoicesModule/loadingTrue",
                        addRequest : "SapInvoicesModule/addRequest",
                        cancelRequest : "SapInvoicesModule/cancelRequest",
                        clearOldRequest : "SapInvoicesModule/clearOldRequest"
                    }

                    ApiGetServiceVer2.get(endpoint, params, configParams).then((response) => {
                        // commit('setColumns', response.data.columns)
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setRows', response.data.data)
                        commit('setLoading', false)
                        resolve(response.data.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },


        async getRecord({ state, commit }, params) {
            const { viewLoading } = state
            return await new Promise((resolve,reject) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');

                    //const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "SapInvoicesModule/req",
                        loadingTrue : "SapInvoicesModule/loadingTrue",
                        addRequest : "SapInvoicesModule/addRequest",
                        cancelRequest : "SapInvoicesModule/cancelRequest",
                        clearOldRequest : "SapInvoicesModule/clearOldRequest"
                    }
                    const formData = JSON.parse(JSON.stringify({...params,items : [],delivery : [],pull_out : []}))
                    commit('setFormRecord',formData)
                    ApiGetServiceVer2.get(`sap/get-invoice/${params.DocEntry}`, params, configParams).then((response) => {
                        commit('setFormRecord',{...formData,...response.data})
                        commit('setViewLoadingFalse')
                        resolve({...formData,...response.data})
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async makePullOut({ state, commit }, params) {
            const { viewLoading } = state
            return await new Promise((resolve,reject) => {
                if (!viewLoading) {
                    commit('setPostLoadingTrue');
                    const configParams = {
                        req : "SapInvoicesModule/req",
                        loadingTrue : "SapInvoicesModule/loadingTrue",
                        addRequest : "SapInvoicesModule/addRequest",
                        cancelRequest : "SapInvoicesModule/cancelRequest",
                        clearOldRequest : "SapInvoicesModule/clearOldRequest"
                    }
                    const formData = JSON.parse(JSON.stringify({...params,items : [],delivery : [],pull_out : []}))
                    commit('setFormRecord',formData)
                    ApiGetServiceVer2.post(`sap/pull-out`, params, configParams).then((response) => {
                        commit('setFormRecord',{...formData,...response.data})
                        commit('setPostLoadingFalse')
                        resolve({...formData,...response.data})
                    }).catch(error => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async makeDeliveryNote({ state, commit }, params) {
            const { postLoading } = state
            return await new Promise((resolve,reject) => {
                if (!postLoading) {
                    commit('setPostLoadingTrue');
                    const configParams = {
                        req : "SapInvoicesModule/req",
                        loadingTrue : "SapInvoicesModule/loadingTrue",
                        addRequest : "SapInvoicesModule/addRequest",
                        cancelRequest : "SapInvoicesModule/cancelRequest",
                        clearOldRequest : "SapInvoicesModule/clearOldRequest"
                    }
                    const formData = JSON.parse(JSON.stringify({...params.form}))
                    ApiGetServiceVer2.post(`sap/delivery-note`, params.submit, configParams).then((response) => {
                        commit('setFormRecord',{...formData,...response.data.data})
                        commit('setPostLoadingFalse')
                        resolve({...formData,...response.data.data})
                    }).catch(error => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },


        async cancelDeliveryNoteRecord({ state, commit }, series) {
            return await new Promise((resolve, reject) => {
                const {cancelLoading} = state;
                if (!cancelLoading) {
                    commit('setCancelLoadingTrue')
                    ApiGetServiceVer2.update(`sap/delivery-note/cancel/${series}`).then((response) => {
                        commit('setCancelLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setCancelLoadingFalse')
                        reject(error)
                    })
                }
            })
        },



        /* makePullOut
makeDeliverNote */
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.columns,
        selected : state => state.multipleSelection,
        tableLoading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        formRecord : state => state.formRecord,
        getLoading : state => state.viewLoading,
        postLoading : state => state.postLoading,
        cancelLoading : state => state.cancelLoading,
    }
}



