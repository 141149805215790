<template>
  <div>
<!--    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">-->
      <el-dialog :modal="true" :fullscreen="false" :title="'Member'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="50%" class="text-left loyalty-member-dialog">
          <div v-loading="viewLoading">
              <div class="dialog-scroll-container pt-0 pb-0 pl-0 pr-0">
                  <template v-if="activeTabSlot == 'main'">
                      <div  style="background-color: #b08d57">
                        <mdb-row>
                                <mdb-col xl="4" md="4" class="mb-4" style="text-align: center;padding-left:40px">
                                    <mdb-card wide class="custom-card">
                                        <section style="margin-top: 30px">
                                            <!-- Profile Icon -->
                                            <div style="font-size: 28px; color: #ffffff;">
                                                <i class="fa fa-user-circle"></i>
                                            </div>
                                            <!-- Name -->
                                            <p class="card-title mb-2">
                                                <strong style="color: white">{{ formData.customer.name }}</strong>
                                            </p>
                                            <!-- Mobile -->
                                            <p class="mb-3" style="color: white">{{ formData.customer.mobile }}</p>
                                            <!-- Member Since -->
                                            <p class="mb-4" style="color: white">Member since: {{ formData.issued_date }}</p>
                                            <!-- Edit Button -->
                                            <div style="margin-top: -13px">


                                                <mdb-btn color="elegant" size="sm" @click="handleCustomerClick">
                                                  {{ (readOnlyComputed == true) ? 'View' : 'Edit' }}
                                                </mdb-btn>
                                            </div>
                                        </section>
                                    </mdb-card>
                                </mdb-col>
                                <mdb-col xl="4" md="4" class="mb-6">
                                        <mdb-card wide class="custom-card" style="margin-bottom:10px;margin-left: auto;margin-right: auto;">
                                        <mdb-view gradient="none" cascade style="position: relative;height:215px">
                                            <div class="bg-image" :style="'background-image: url(' + formData.card.attr.images + ');'"></div>
                                            <div class="card-info">
                                                <h3 class="card-header-title">&nbsp;</h3>
                                                <div class="card-body">
                                                    <div v-if="formData.status == 'for-issue-card'">
                                                        <mdb-btn color="elegant" size="sm" @click="updateFormDialog">
                                                            Issue New Card
                                                        </mdb-btn>
                                                    </div>
                                                  <p class="card-description card-number" v-if="formData.status !== 'for-issue-card'">
                                                    <b @mouseover="showCopyButton = true" @mouseleave="showCopyButton = false">
                                                      {{ formattedCardNumber }}
                                                      <span class="copy" v-show="showCopyButton" @click="copyCardNumber" :class="{ 'active': copied }">
          {{ copied ? 'Copied!' : 'Copy' }}
        </span>
                                                    </b>
                                                  </p>
                                                    <div></div>
                                                    <div class="row" v-if="formData.status != 'for-issue-card'">
                                                        <div class="col-md-8">
                                                            <p class="card-description name-text"><b>{{ formData.display_name }}</b></p>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <p class="card-description exp-date-text"><b>{{ formattedExpirationDate }}</b></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </mdb-view>
                                    </mdb-card>

                                    <template v-if="formData.status != 'for-issue-card'">
                                        <div class="text-center">
                                                <mdb-btn color="elegant" size="sm" @click="printCard(formData.customer)" style="background-color: #b08d57; border: none;">PRINT CARD</mdb-btn>
                                                <mdb-btn color="elegant" size="sm" @click="updateFormDialog" style="background-color: #b08d57; border: none;">RE-ISSUE CARD</mdb-btn>
                                        </div>
                                    </template>
                                </mdb-col>
                                <mdb-col xl="4" md="4" class="mb-6">
                                    <mdb-card wide class="custom-card" style="background: #b08d57; border: none; transition: transform 0.3s, box-shadow 0.3s;">
                                        <!-- Background image -->
                                        <div class="bg-image" :style="'background-image: url(' + formData.tier.attr.images + '); background-size: cover;'"></div>

                                        <div class="card-content text-center">
                                            <h3 class="card-header-title mt-3" style="color: #ffffff; font-size: 24px;">{{ formData.current_tier.name }}</h3>
                                            <h1 class="card-header-title" style="color: #ffffff; font-size: 36px;">{{ formData.points.toLocaleString() }}</h1>

                                            <!-- Customized progress bar -->
<!--                                            <mdb-progress :height="17" :value="calculatePercentageDifference(formData.tier,formData.next_tier)" color="dark" style="width: 90%; margin-left: 16px;">-->
<!--                                                {{ calculatePercentageDifference(formData.tier,formData.next_tier) }}%-->
<!--                                            </mdb-progress>-->
                                            <mdb-progress :height="17" :value="parseFloat(calculatePercentageDifference(formData.tier, formData.next_tier))" color="dark" style="width: 90%; margin-left: 16px;">
                                                {{ calculatePercentageDifference(formData.tier, formData.next_tier) }}%
                                            </mdb-progress>
                                            <p class="card-description tier-text" style="color: #ffffff; font-size: 18px; margin-top: -14px; margin-right: 22px;">
                                                <b>{{ (formData.tier.tier_id !== formData.next_tier.id) ? formData.next_tier.name : 'Infinity' }}</b>
                                            </p>

                                            <div class="d-flex justify-content-between" style="padding: 20px">
                                                <!-- Joined Date -->
                                                <p class="card-description tier-text" style="color: #ffffff; font-size: 12px;">
                                                    Joined: {{ formData.card.issued_date }}
                                                </p>

                                                <!-- Renewal Date -->
                                                <p class="card-description tier-text" style="color: #ffffff; font-size: 12px;">
                                                    Renewal: {{ formData.card.expiration_date }}
                                                </p>
                                            </div>

                                            <template v-if="!readOnlyComputed">
                                                <mdb-btn color="elegant" size="sm" @click="modifyFormDialog" style="margin-top: -35px; background-color: #b08d57; border: none;">Modify</mdb-btn>
                                            </template>
                                        </div>
                                    </mdb-card>
                                </mdb-col>

                        </mdb-row>
                      </div>
                    <mdb-tabs class="table-header-tabs" :active="active" default :links="tabs" @activeTab="handleTabChange" />
                        <mdb-row v-if="active === 0">
                            <mdb-col xl="12" md="12">

                                <mdb-card style="margin-top: 20px">
                                    <mdb-card-body class="p-0">
                                        <CardTable ref="refreshTable" :endpoint="transactionEndpoints" :title="'Transactions'" :key="tableKey" @dialogClick="handleDialogClick" :isEditable="readOnlyComputed"></CardTable>
                                    </mdb-card-body>
                                </mdb-card>
                            </mdb-col>
                        </mdb-row>

                    <mdb-row v-if="active === 1">
                      <mdb-col xl="12" md="12">

                        <mdb-card style="margin-top: 20px">
                          <mdb-card-body class="p-0">
                            <BenefitCardTable ref="refreshTable" @open-service-modal="openServiceModal" :endpoint="benefitEndpoints" :title="'Benefits'" :key="tableKey" @dialogClick="handleBenefitsDialogClick"></BenefitCardTable>

                          </mdb-card-body>
                        </mdb-card>
                      </mdb-col>
                    </mdb-row>

<!--                    <mdb-row v-if="active === 2">-->
<!--                      <mdb-col xl="12" md="12">-->

<!--                        <mdb-card style="margin-top: 20px">-->
<!--                          <mdb-card-body class="p-0">-->
<!--                            <IssueCouponCardTable ref="refreshTable" :endpoint="issueCouponEndpoints" :title="'Issue Coupons'" :key="tableKey" @dialogClick="handleIssueCouponDialogClick"></IssueCouponCardTable>-->

<!--                          </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                      </mdb-col>-->
<!--                    </mdb-row>-->



                  </template>
              </div>

          </div>
          <template slot="footer">

            <div class="d-flex">

            <template v-if="formLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </mdb-btn>
                </template>

                <template v-else>
<!--                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>-->
                <template v-if="formStatus == 'update'">
                    <mdb-btn color="elegant" size="sm" @click="openHistoryDialog"><i class="el-icon-save text-white cursor-pointer"></i> History</mdb-btn>
                </template>
                <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                </template>


            </div>
          </template>
      </el-dialog>
<!--      </ValidationObserver>-->
      <HistoryDialog ref="historyDialog"></HistoryDialog>
      <LoyaltyTransactionForm ref="dialogForm" @success="refreshData" ></LoyaltyTransactionForm>
      <CustomerIssueCoupon ref="issueCoupondialogForm" @success="refreshData" ></CustomerIssueCoupon>
      <CustomerIssueGift ref="issueGiftdialogForm" @success="refreshData" ></CustomerIssueGift>
      <ModifyCardForm ref="modifyDialogForm" @success="refreshData" ></ModifyCardForm>
      <CustomerForm ref="editCustomerDialog" @success="refreshData" :isEditable="readOnlyComputed"></CustomerForm>
      <UpdateLoyaltyCardForm ref="updateLoyaltyCard" @success="refreshData" ></UpdateLoyaltyCardForm>
      <CustomerServiceBenefit ref="updateServiceBenefit" @success="refreshData" ></CustomerServiceBenefit>
      <RedeemedServiceBenefit ref="redeemedServiceStatus"  @success="refreshData" ></RedeemedServiceBenefit>
      <RedeemedVoucher ref="redeemedVoucherStatus"  @success="refreshData" ></RedeemedVoucher>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import { ValidationObserver } from "vee-validate";

import {
    mdbBtn,
    mdbContainer,
    mdbSelect,
    mdbFormInline,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbCarousel,
    mdbCard,
    mdbCardBody,
    mdbCardText,
    mdbCardTitle,
    mdbCardImage,
    mdbTabs,
    mdbNavbar,
    mdbNavbarBrand,
    mdbProgress,
    mdbView
} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'

// import { MessageBox } from 'element-ui';

import HistoryDialog from "../../components/thats/HistoryDialog.vue";
import CardTable from "./CardTable.vue"
import LoyaltyTransactionForm from "./LoyaltyTransactionForm.vue";
import CustomerForm from "./CustomerForm.vue";
import ModifyCardForm from "./ModifyCardForm.vue";
import UpdateLoyaltyCardForm from "./UpdateLoyaltyCardForm.vue";
import BenefitCardTable from "./BenefitCardTable.vue";
import CustomerIssueCoupon from "./CustomerIssueCoupon.vue";
import IssueCouponCardTable from "./IssueCouponCardTable.vue";
import CustomerServiceBenefit from "./CustomerServiceBenefit.vue";
import RedeemedServiceBenefit from "./RedeemedServiceBenefit.vue";
import CustomerIssueGift from "./CustomerIssueGift.vue";
import RedeemedVoucher from "./RedeemedVoucher.vue";
export default {
  components : {
      RedeemedVoucher,
      CustomerIssueGift,
      RedeemedServiceBenefit,
    CustomerServiceBenefit,
    IssueCouponCardTable,
    CustomerIssueCoupon,
    BenefitCardTable,
      UpdateLoyaltyCardForm,
      ModifyCardForm,
      CustomerForm,
      LoyaltyTransactionForm,
      mdbView,
    HistoryDialog,
      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardBody,
      mdbCardImage,
      mdbCardText,
      mdbCardTitle,
      mdbProgress ,
      // ValidationObserver,

       mdbFormInline,
       CardTable,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },

  },
  inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
  data(){
      return {
          syncModal : false,
          transactionEndpoints : `loyalty-program/transactions`,
          benefitEndpoints : `loyalty-program/customer-benefits`,
          issueCouponEndpoints : `loyalty-issue-coupon`,
          tableKey : 0,
          activeTabIndex : 0,
          activeTabObject : { text: 'Details', tabSlot : 'main' },
          mdbTabsData : [
              { text: 'Details', tabSlot : 'main' }
          ],
        activeTab: "transactions",
        active: 0,
        tabs: [
          { text: "Transactions", slot: "transactions" },
          { text: "Benefits", slot: "benefits" },
          // { text: "Issue Coupons", slot: "issue-coupons" },
        ],

          group2 : 5,
          formData : null,
          formLoading : false,
          readOnly : false,
          showError : true,
          validated : true,
          itemType : [
              {label : "Stock", value : "stock"},
              {label : "Services", value : "services"},
          ],

          itemWebsiteStatus : [
              {label : "Yes", value : "1"},
              {label : "No", value : "0"},
          ],

          itemStatus : [
              {label : "Archive", value : "2"},
              {label : "Active", value : "1"},
              {label : "Discontinued", value : "0"},
          ],
        showCopyButton: false,
        showCopyMessage: false,
        copied: false,
          inventoryData : [],

          filterAttributes : [],

          finishes : [],
          fabric : [],
          materials : [],
          materials1 : [],
          colour : [],
          colour1 : [],
          locations : [],
          vendors : [],
          brands : [],
          collections : [],
          category : [],
          attributes : [],
          area : [],
          items : [],
          itemDiscountPercentConfig : {
                  decimal: '.',
                  thousands: ',',
                  prefix: '',
                  suffix: ' %',
                  precision: 2,
                  masked: false
          }
      }
  },
  created(){
      this.formData = JSON.parse(JSON.stringify(this.formObject))

  },
  mounted(){
      const self = this
      console.log("mounted")
          console.log(this.categoryComputed)


  },
  methods : {
      ...mapMutations('LoyaltyMemberModule',['cancelRequest','setFormStatusCreate']),
      ...mapActions('LoyaltyMemberModule',['storeRecord','updateRecord','getRecord']),

    ...mapMutations('HistoryModule',[
        'setModule',
        'setRecordId',
      ]),
      openDialog(){
          this.syncModal = true

      },
      closeDialog(){
          this.syncModal = false
      },
      resetDefaultTab(){
          this.activeTabIndex = 0,
          this.activeTabObject = { text: 'Details', tabSlot : 'main' }
      },
      getActiveTab(v){
          this.activeTabIndex = v
          this.activeTabObject = this.mdbTabsData[v];
      },



      handleDialogClick(data) {
          console.log('Received data from CardTable:', data, this.formData);
          this.$refs.dialogForm.openNewDialog(data, this.formData);
      },

    openServiceModal({ row }) {
      console.log('Received data from Child:', row, this.formData);
      if (row.tier_beneficiary.benefit_type == "service"){
          if (row.issue_count === row.available_count){
              this.$refs.redeemedServiceStatus.openNewDialog(row, this.formData);
          }else {
              this.$refs.updateServiceBenefit.openNewDialog(row, this.formData);
          }

      }else if(row.tier_beneficiary.benefit_type == "voucher") {
          if (row.issue_count === row.available_count){
              this.$refs.redeemedVoucherStatus.openNewDialog(row, this.formData);
          }else{
              this.$refs.issueCoupondialogForm.openNewDialog(row, this.formData);
          }

      }else{
          this.$refs.issueGiftdialogForm.openNewDialog(row, this.formData);
      }

    },
    copyCardNumber() {
      // Copy to clipboard logic
      const textArea = document.createElement('textarea');
      textArea.value = this.formattedCardNumber.replace(/\s/g, ''); // Remove spaces
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);

      this.copied = true;

      // Hide the 'Copied!' message after 2 seconds
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },

    handleBenefitsDialogClick(data) {
      console.log('Received data from CardTable:', data, this.formData);
      this.$refs.issueCoupondialogForm.openNewDialog(data, this.formData);
    },
    handleIssueCouponDialogClick(data) {
      console.log('Received data from CardTable:', data, this.formData);
      this.$refs.issueCoupondialogForm.openNewDialog(data, this.formData);
    },

      handleCustomerClick() {
          console.log('Received data from Customer:', this.formData);
          this.$refs.editCustomerDialog.openEditDialog(this.formData);
      },
      modifyFormDialog() {
          this.$refs.modifyDialogForm.openModifyDialog(this.formData);
      },

      updateFormDialog() {
          this.$refs.updateLoyaltyCard.openUpdateDialog(this.formData);
      },

      refreshData(){
          this.$refs.refreshTable.refreshTable();
          this.getRecord(this.formData)
          this.$emit('success')
      },

    handleTabChange(index) {
        console.log(index,"tab")
        this.active = index
    },

    // onSubmitForm(){
    //   var self =this
    //         this.validated = true
    //        this.$refs.observer.validate().then((isValid) => {
    //
    //         console.log(isValid)
    //         this.showError = false
    //         if (isValid) {
    //             // this.form.description = this.getText();
    //                 if(self.formLoading === false){
    //                   self.formLoading = true
    //                   self.formData.billOfMaterials = self.formData.billOfMaterials.filter((item) => item.item_id != '',[])
    //                   if(self.formStatus == 'create'){
    //
    //
    //                       self.storeRecord(self.formData).then((response) => {
    //                           self.$ThatsNotify.success()
    //                           self.$emit('success',response)
    //                           self.closeDialog();
    //                           self.formLoading = false
    //                           /*
    //                           self.FormResetter(); */
    //
    //                         }).catch((error) => {
    //                           self.formLoading = false
    //                           if (error.response) {
    //                               if(error.response.data.hasOwnProperty('errors')){
    //                                 self.$refs.observer.setErrors(error.response.data.errors);
    //                                 this.showError = true
    //                               }
    //                             } else if (error.request) {
    //                                 self.response.message = error.request
    //                             } else {
    //                                 self.response.message = error.message
    //                             }
    //                             return;
    //                         })
    //                   }
    //
    //                   if(self.formStatus == 'update'){
    //                       self.updateRecord(self.formData).then((response) => {
    //                           self.$ThatsNotify.success({
    //                             message : "Record was successfully updated"
    //                           })
    //                             self.closeDialog();
    //                             self.$emit('success',response)
    //                             /* self.FormResetter(); */
    //                             self.formLoading = false
    //
    //                         }).catch((error) => {
    //                           self.formLoading = false
    //                           if (error.response) {
    //                               if(error.response.data.hasOwnProperty('errors')){
    //                                 self.$refs.observer.setErrors(error.response.data.errors);
    //                                 this.showError = true
    //                               }
    //                             } else if (error.request) {
    //                                 self.response.message = error.request
    //                             } else {
    //                                 self.response.message = error.message
    //                             }
    //                             return;
    //                         })
    //                   }
    //                 }
    //
    //         }
    //
    //
    //         });
    //
    //
    // },



        openHistoryDialog(){
            this.setModule('loyalty-member')
            this.setRecordId(this.formData.customer.id)
            this.$refs.historyDialog.openDialog();
        },

      calculatePercentageDifference(currentTier, nextTier) {
          const currentPoints = currentTier.points - currentTier.tier.maximum;
          const targetPoints = nextTier.minimum;

          if (currentTier.tier_id === nextTier.id) {
              return 100;
          }

          if (targetPoints <= 0) {
              return 0;
          }

          const difference = Math.max(0, targetPoints + currentPoints);
          const percentageDifference = (difference / targetPoints) * 100;

          return percentageDifference.toFixed(2);
      },

      printCard(customer){
        let session = JSON.parse(localStorage.getItem('user'));
        let token = session.token;

        const locationId = session.location.id

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(`loyalty-program/print-card/${customer.series}?token=${token}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
      }


},

  computed : {
      ...mapGetters('LoyaltyMemberModule',{
          formRecord : 'formData',
          viewLoading : 'getLoading',
          postLoading : 'postLoading',
          formObject : 'initialFormObject',
          formStatus : 'formStatus',
      }),

      activeTabSlot : function(){
          return this.activeTabObject.tabSlot
      },

      formattedExpirationDate() {
          const date = new Date(this.formData.expiration_date);
          const month = date.getMonth() + 1;
          const year = date.getFullYear().toString().slice(-2);
          return `${month.toString().padStart(2, '0')}/${year}`;
      },
    formattedCardNumber() {
      const cardNumber = this.formData.card_number;


          if (cardNumber && cardNumber.length >= 8) {
              const first4Digits = cardNumber.substring(0, 4);
              const last4Digits = cardNumber.substring(cardNumber.length - 4);
              const middleDigits = cardNumber.substring(4, cardNumber.length - 4);


              const formattedNumber = `${first4Digits} ${middleDigits} ${last4Digits}`;
              return formattedNumber;
          }

          return cardNumber;
    },

    readOnlyComputed : function(){
          return (this.$helpers.isSystemAdministrator() || this.$helpers.isShowroomManager() || this.$helpers.isBackOffice()) ? false : true
      },




  },

  watch : {
      syncModal : function(value){
        const self = this;
          if(!value){
              this.cancelRequest();
              this.resetDefaultTab();
              this.formData = JSON.parse(JSON.stringify(this.formObject))
          }

          if(value){
              this.tableKey++
          }
      },

      formRecord : function(value){
          this.formData = value
          this.transactionEndpoints = `loyalty-program/transactions/${this.formData.series}`
          this.benefitEndpoints = `loyalty-program/customer-benefits/${this.formData.series}`
          this.issueCouponEndpoints = `loyalty-issue-coupon/${this.formData.series}`
      },
  }
}
</script>

<style lang="scss">

.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}



.avatar-sm {
  width: 56px;
  border-radius: 50%;
}
.chat-message-type {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}

.profile-card {
  margin-top: 100px;
}

.profile-card .avatar {
  max-width: 150px;
  max-height: 150px;
  margin: -70px auto 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.btn-floating i {
  color: #4f4f4f !important;
}

.member-card-title{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}



.custom-card {

    width: 350px;
    height: 215px;
}

.card-header-title,
.card-description {
    position: relative;
    z-index: 1;
    color: black;
}

.card-description.exp-date-text {
    font-size: 9px;
    margin-bottom: 5px;
    text-align: right;
    margin-right:-5px;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
}
.card-description.name-text {
    font-size: 11px;
    margin-bottom: 5px;
    text-align: right;
    margin-right:50px;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
}

.card-description.exp-date {
    font-size: 18px;
    margin-bottom: 5px;
    text-align: right;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
}

.card-description.card-number {
    font-size: 28px;
    margin-top: 40px;
    margin-bottom: 0;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
}
.card-description.tier-text {
    font-size: 14px;
    margin-top: -18px;
    margin-bottom: 5px;
    text-align: right;
    margin-right:-5px;
    font-family: "PT Sans", Calibri, Tahoma, sans-serif;
}
.card-number button {
  margin-left: 8px;
  cursor: pointer;
  display: none;
}

.card-number:hover button {
  display: inline-block;
}

span.copy {
  font-size: 13px;
  cursor: pointer;
}

span.copy.active {
  display: none;
}

span.copy:hover {
  background-color: #e0e0e0; /* Add your desired hover background color */
}

</style>

