<template>
    <div class="table-container pr-0 pl-0">
        <el-table ref="multipleTable" lazy class="custom-element-table" :data="rows" :height="650" stripe>
            <template v-for="col in columns">
                <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                    <template slot-scope="scope">
                        <template v-if="col.clickable">
                            <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                        </template>
                        <template v-else>
                            <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                        </template>
                    </template>
                </el-table-column>
            </template>

            <template slot="empty">
                <template v-if="loading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading data...
                    </mdb-btn>
                
                </template>
                <template v-else>
                    No Data
                </template>
            </template>
        </el-table>

        <div class="d-flex">
            <el-pagination        
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="current_page"
                :page-sizes="[25,50,100, 200, 300, 400]"
                :page-size="length"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </div>

            
</template>


<script>
import {
  mdbBtn,
  mdbContainer,
  mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle

} from "mdbvue";
import { mapGetters, mapActions, mapMutations  } from 'vuex';
export default {
    name : "QuotationByLocationTable",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
       
        endpoint : {
            type : String,
            default : ''
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,
        }
    },
    methods: {
        ...mapActions('QuotationTableModule',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('QuotationTableModule',[
            'setLength',
            'setPage',
            'setSearchTerm',
            'setSelection',
            'setEmptyData',
            'setFilters',
        ]),

        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            this.$emit('delete')
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        },
        handleClick(index,row){
            
            this.$emit('open',{value : row, index : index});
        },
        handleSelectionChange(v){
            this.selected = v.map(i => i.series)
        },
        mergeDialog(){
            this.$emit('merge',this.selected);
        },

        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.dispatchHandler()
        },
        clearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        refreshTable(){
            this.resetData(this.endpoint);
        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },
    },

    computed : {
        ...mapGetters('QuotationTableModule',['columns','rows','loading','selection','searchTerm','length','total','page','data']),

        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.setSearchTerm (val)
            }
        },
        selected : {
            get : function(){
               return this.selection
            },
            set : function(val){
                this.setSelection (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },
    },

}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }

</style>