import { ApiHttpService,ApiGetService } from '../../services'

import router from '../../router'
export const BusinessPartnerModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Series',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width: '150'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                },
                {
                    label: 'Mobile',
                    field: 'mobile',
                    sort: 'asc',
                    width: '250'
                },

                {
                    label: 'Address',
                    field: 'address',
                    sort: 'asc',
                    width: '300'
                },

              
              
                ],
            rows: [],
        },
        searchRequest: null,
        searchLoading : false,
        request: null,
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},


        initialFormData : {
            series : 0,
            name : null,
            mobile : null,
            reference : '',
            description : null,
            image : [],
            attr : {
  
              address: null,
              address2 : {
                  street : null,
                  building : null,
                  zone : null,
                  area : null,
                  city : null,
              },
              email : null,
              origin : 'portal',
              first_name : null,
              middle_name : null,
              last_name : null,
              salutation : null,
              corporate : false,
              contacts : [
                  {
                      type : 'primary',
                      intl : false,
                      code : '+974',
                      value : null
                  },
                  {
                      type : 'secondary',
                      intl : false,
                      code : '+974',
                      value : null
                  },
                  {
                      type : 'home',
                      intl : false,
                      code : '+974',
                      value : null
                  }
              ],
              contact_persons : []
            },
            status : 'active'
          },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',
        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            console.log("addRequest")
            console.log(state)
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        addSearchRequest : (state, req) => {
            state.searchRequest = {cancel : req.cancel, msg : "loading"}
            console.log("addRequest")
            console.log(state)
        },
        cancelSearchRequest : (state) => {
            state.searchRequest.cancel()
            state.loading = true
        },
        clearOldSearchRequest(state, message) {
            state.searchRequest.msg = message;
            state.searchRequest = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setSearchLoadingTrue: (state) => {
            state.postLoading = true
        },
        setSearchLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){

        
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },

        async syncData({commit}){

        
            return new Promise((resolve, reject) => { 
                const parsePayload = {}
                const configParams = {
                    req : "BusinessPartnerModule/req",
                    loadingTrue : "BusinessPartnerModule/loadingTrue",
                    addRequest : "BusinessPartnerModule/addRequest",
                    cancelRequest : "BusinessPartnerModule/cancelRequest",
                    clearOldRequest : "BusinessPartnerModule/clearOldRequest"
                }
                ApiHttpService.post('local-supplier/sync',parsePayload,configParams).then((response) => {
                    commit('setPostLoadingFalse')
                    resolve(response.data)
                }).catch((error) => {
                    commit('setPostLoadingFalse')
                    reject(error)
                })
        });
        },

        async asyncGetData({ state, commit},endpoint) {
           
            const { length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {
            
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "BusinessPartnerModule/req",
                        loadingTrue : "BusinessPartnerModule/loadingTrue",
                        addRequest : "BusinessPartnerModule/addRequest",
                        cancelRequest : "BusinessPartnerModule/cancelRequest",
                        clearOldRequest : "BusinessPartnerModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },
        async asyncSearchData({commit},params) {
            return await new Promise((resolve, reject) => {
                    commit('setSearchLoadingTrue')
                    const configParams = {
                        req : "BusinessPartnerModule/searchRequest",
                        loadingTrue : "BusinessPartnerModule/setSearchLoadingTrue",
                        addRequest : "BusinessPartnerModule/addSearchRequest",
                        cancelRequest : "BusinessPartnerModule/cancelSearchRequest",
                        clearOldRequest : "BusinessPartnerModule/clearOldSearchRequest"
                    }

                    ApiHttpService.get(`local-supplier/search`, params, configParams).then((response) => {
                        commit('setSearchLoadingFalse')
                        resolve(response.data)
                    }).catch(error => {
                        commit('setSearchLoadingFalse')
                        reject(error)
                    })
                
            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}
 
                    ApiHttpService.getRequest('inventory-transfer-request/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
        
        
        async getReceiver() {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('inventory-transfer-request/receiver',{view : router.app._route.name}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    commit('setGetloadingFalse', false)
                    reject(error)
                })
            })
        },     
        
      
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "BusinessPartnerModule/req",
                        loadingTrue : "BusinessPartnerModule/setPostLoadingTrue",
                        addRequest : "BusinessPartnerModule/addRequest",
                        cancelRequest : "BusinessPartnerModule/cancelRequest",
                        clearOldRequest : "BusinessPartnerModule/clearOldRequest"
                    }
                    ApiHttpService.post('local-supplier',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setFormData',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`local-supplier/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        searchRequest: state => state.searchRequest,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        getNewItemDataObject: state => state.newItemDataObject,
    }
}



