<template>
    <ValidationObserver class="needs-validation form-custom-format filter-form" tag="form" ref="observer" @submit.prevent="onSubmitForm()" v-slot="{passes}">
            <mdbContainer>
              <label class="filter-label">Filter</label>
                <mdb-row >
                  <mdb-col col="12">
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Category" placeholder="Category" vid="filtername" name="filtername" v-model="form.name" size="sm" />
                      <ThatsRadio labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Area" vid="filter-area" orientation="inline" name="filter-area" :data="area" v-model="form.area" :rules="''" size="sm" />
                      
                  </mdb-col> 
                </mdb-row>

                <mdb-row class="mt-1 mb-1">
                    <mdb-col col="3">
                    </mdb-col>
                    <mdb-col col="9">
                     <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Search</mdb-btn>
                <mdb-btn color="elegant" size="sm" @click="clearForm">Clear Filter</mdb-btn>
                    </mdb-col>
                </mdb-row>


                
              </mdbContainer>


          </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { ThatsNumber,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsCheckbox, ThatsMultiSelect, ThatsRadio} from '../../components/thats/Thats'
import {mapActions} from 'vuex';
import VueSlider from 'vue-slider-component'
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        ValidationObserver,
        ThatsInput,  ThatsNumber,
        ThatsSeriesInput,
        ThatsFieldset,
        ThatsCheckbox,
        ThatsMultiSelect,
        VueSlider,
        ThatsRadio,
  },

  props : {
      area : {
          type : Array
      }
  },
    
    data : () => ({
      form : {
          area : null,
          name : null,
      },
    }),
    
    methods: {      
        ...mapActions('AreaModule',[ 'GetArea' ]),
        onSubmitForm(){
            this.$emit('filter',this.form)
        },

        formVar(){
          return {
                area : null,
                name : null,
            }
        },

        clearForm(){
          this.form = this.formVar()
        }

    },

}
</script>
