import {ApiGetService, ItemService} from '../../services'
export const ItemFilterModule = {
    namespaced: true,
    state: {
        request: null,
        loading: false,
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        multipleSelection: [],
        data: [],
        selectedItems:[]
    },
    actions: {
        async filterItems({commit }, params){
            commit('loadingFalse')
            return await new Promise((resolve,reject) => {
                commit('loadingTrue')
                const configParams = {
                    req : "ItemFilterModule/req",
                    loadingTrue : "ItemFilterModule/loadingTrue",
                    addRequest : "ItemFilterModule/addRequest",
                    cancelRequest : "ItemFilterModule/cancelRequest",
                    clearOldRequest : "ItemFilterModule/clearOldRequest"
                }
                ApiGetService.get('item-master/available', params, configParams).then((response) => {
                    resolve(response.data)
                }).catch((err) => reject(err))
            })

        },




        async filterItemsBom({commit }, params){
            commit('loadingFalse')
            return await new Promise((resolve,reject) => {
                commit('loadingTrue')
                const configParams = {
                    req : "ItemFilterModule/req",
                    loadingTrue : "ItemFilterModule/loadingTrue",
                    addRequest : "ItemFilterModule/addRequest",
                    cancelRequest : "ItemFilterModule/cancelRequest",
                    clearOldRequest : "ItemFilterModule/clearOldRequest"
                }
                ApiGetService.get('bom-item/filter', params, configParams).then((response) => {
                    commit('setSelectedItem', response.data);
                    resolve(response.data)
                }).catch((err) => reject(err))
            })
        }


    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        setData(state, payload) {
            state.data = payload
        },

        setLength: (state, payload) => {
            state.length = payload
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data = []
        },
        setSelectedItem: (state, items) => {
            state.selectedItems = items
        },

    },
    getters: {
        loading: state => state.loading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.Filters,
        req: state => state.request,
        selectedItems: state => state.selectedItems,
    }
}


