<template>
<hsc-window-style-metal>
    <hsc-window title="Print by Size" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">    
      <el-table :ref="'importTable1'" lazy class="custom-element-table" :data="data.rows" stripe :height="tableHeight"  :row-class-name="errorClassName">
            <template v-for="col in data.columns">
                <el-table-column v-bind:key="col.field" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('dropdown') && col.dropdown === true">
                                
                                <AutoCompleteNoLabel v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+eventKey" :index="`${scope.$index}-${index}`" @select="onSelectLocation" :showLabel="false"  :addButton="false" :appendButton="false" v-model="rowSize.size" :reduce="label => label.value" :reduceLabel="'label'" :options="cushionSizeFilter" :rules="''" :disabled="false"></AutoCompleteNoLabel>
                                <a href="javascript:void(0)" @click="newItemSize(scope.row.series,scope.$index)">Add Item size</a>
                            </template>
                            <template v-else-if="col.field === 'thumb'">
                                <img :src="scope.row.thumb" class='img-thumbnail'/>
                            </template>
                            <template v-else-if="col.field === 'rm'">
                                <div class="c-container" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <template v-if="index > 0">
                                        <a href="javascript:void(0)" @click="removeItemSize(scope.row.series,scope.$index,index)"><mdb-icon icon="times"></mdb-icon></a>
                                    </template>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'item'">
                                <div v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <AutoCompleteNoLabel  :key="scope.$index+eventKey" :index="`${scope.$index}-${index}`" :showLabel="false" v-model="rowSize.item" :reduce="label => label.value"  @select="onItemSelect" :reduceLabel="'label'" :addButton="false" :appendButton="false" :options="cushionOption" :rules="''" :disabled="false"></AutoCompleteNoLabel>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'none'">
                                <div class="c-container" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <el-radio v-model="rowSize.class" label="n" class="fabric-radio" @change="fabricClassChange(scope.$index,index)"></el-radio>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'piping'">
                                <div class="c-container" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <el-radio v-model="rowSize.class" label="p" class="fabric-radio" @change="fabricClassChange(scope.$index,index)"></el-radio>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'emb'">
                                <div class="c-container" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <el-radio v-model="rowSize.class" label="e" class="fabric-radio" @change="fabricClassChange(scope.$index,index)"></el-radio>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'piping_emb'">
                                <div class="c-container" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    <el-radio v-model="rowSize.class" label="ep" class="fabric-radio" @change="fabricClassChange(scope.$index,index)"></el-radio>
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else-if="col.field === 'price'">
                                <div class="c-container" style="justify-content:right" v-for="(rowSize,index) in scope.row.sizes" :key="scope.$index+index+'cb'">
                                    {{rowSize.price | amount}}
                                </div>
                                <a href="javascript:void(0)">&nbsp;</a>
                            </template>
                            <template v-else>
                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                            </template>
                        </template>
                </el-table-column>
            </template>



        <template slot="empty">
            No Data
        </template>
    </el-table>
    
            <div class="d-flex">
                <button class="btn btn-sm btn-elegant" type="button" @click="print('/fabric-textile/multiple-print')">Print</button>
                <button class="btn btn-sm btn-elegant" type="button" @click="print('/fabric-textile/multiple-print-small')">Print Small</button>
                <button class="btn btn-sm btn-grey" type="button" @click="closeDialog">Close</button>
            </div>
      

    </hsc-window>
</hsc-window-style-metal>
</template>

<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside,
     mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane, mdbAlert 
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'
import AutoCompleteNoLabel from '../../components/thats/AutoCompleteNoLabel';
// import AutoCompleteNoLabel from '../../components/thats/AutoCompleteNoLabel';
export default {
    name : "BarcodeFabricForm",

    data(){
        return {
            toggle : false,
            current_page : 1,
            x : 0,
            y : 0,
            dialogIsOpen : false,
            active : 0,
            cushionOption : [
                {
                    label : 'Cushion',
                    value : 'cushion',
                },
                {
                    label : 'Cover Only',
                    value : 'cover-only'
                },
            ],
            cushionSize : [
                        {
                        "label": "30 x 40",
                        "value": "30x40",
                        "price": 300,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "30 x 50",
                        "value": "30x50",
                        "price": 300,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "30 x 60",
                        "value": "30x60",
                        "price": 300,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "35 x 75",
                        "value": "35x75",
                        "price": 300,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "40 x 40",
                        "value": "40x40",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "40 x 60 ",
                        "value": "40x60 ",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "40 x 70",
                        "value": "40x70",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "43 x 43",
                        "value": "43x43",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "45 x 45",
                        "value": "45x45",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "46 x 46",
                        "value": "46x46",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "50 x 50",
                        "value": "50x50",
                        "price": 500,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "50 x 35",
                        "value": "50X35",
                        "price": 400,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "55 x 50",
                        "value": "55X50",
                        "price": 500,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "55 x 55",
                        "value": "55X55",
                        "price": 500,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "60 x 60",
                        "value": "60x60",
                        "price": 600,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "65 x 65",
                        "value": "65X65",
                        "price": 600,
                        "reference": "cushion",
                        "pipping": 50,
                        "embroidery": 75,
                        "pipping_embroidery": 125
                        },
                        {
                        "label": "30 x 40",
                        "value": "30x40",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "30 x 50",
                        "value": "30x50",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "30 x 60",
                        "value": "30x60",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "35 x 75",
                        "value": "35x75",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "40 x 40",
                        "value": "40x40",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "40 x 60 ",
                        "value": "40x60 ",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "40 x 70",
                        "value": "40x70",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "43 x 43",
                        "value": "43x43",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "45 x 45",
                        "value": "45x45",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "46 x 46",
                        "value": "46x46",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "50 x 50",
                        "value": "50x50",
                        "price": 300,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "50 x 35",
                        "value": "50X35",
                        "price": 200,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "55 x 50",
                        "value": "55X50",
                        "price": 300,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "55 x 55",
                        "value": "55X55",
                        "price": 300,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "60 x 60",
                        "value": "60x60",
                        "price": 400,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        },
                        {
                        "label": "65 x 65",
                        "value": "65X65",
                        "price": 400,
                        "reference": "cover-only",
                        "pipping": 50,
                        "embroidery": 50,
                        "pipping_embroidery": 100
                        }
                        ],
            
            eventKey : 0
            
        }
    },



    props : {
        tableHeight : {
            type: String,
            default : 'calc(100vh - 300px)'
        },

         group : {
            type : Number,
            default : 1
        },
        height : {
            type : Number,
                default : 690
        },
        width : {
            type : Number,
                default :1250
        },
        loading : {
            type : Boolean,
            default : false
        }
    },
    components: {
        AutoCompleteNoLabel,
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
         mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane,
         mdbAlert
    },
directives: {
      mdbClickOutside
    },

    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        handleSizeChange(val) {
            
        },
        handleCurrentChange(val) {
            
        },
        handleSearchTerm() {
           
        },
        handleSelectionChange(val){
            
        },
        ClearSelected(){
           
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        importData(){
            this.$emit('import',this.data)
        },
        getActiveTabIndex(index){
            this.active = index
        },

        errorClassName({ row }) {

        if (row.hasOwnProperty('message')) {
            return 'error-row'
        }
        return ''
        },

        removeItemSize(code,rowIndex,sizeIndex){
            const row = this.data.rows.filter((item) => {
                return  code === item.series
            })[0]

            if(row !== null){

                row.sizes.splice(sizeIndex,1);

                this.$set(this.data.rows,rowIndex,row)
            }
        },

        newItemSize(code,sizeIndex ){

            // alert(`${code} - ${sizeIndex}`)
            const row = this.data.rows.filter((item) => {
                return item.series === code;
            })[0];


            console.log(code)
            console.log(row)

            row.sizes.push({
                            size : '30x60',
                            piping : false,
                            emb : false,
                            piping_emb : false,
                            class : 'n',
                            price : 300,
                            item : 'cushion'
                        })
            this.$set(this.data.rows,sizeIndex.rows, row)
        },

        fabricClassChange(rowIndex,sizeIndex ){
            this.eventKey++;



            console.log(`${rowIndex} - ${sizeIndex}`)

            const row = this.data.rows.filter((x,index) => {
                return  rowIndex === index
            })[0]

            if(row !== null){
               const item = row.sizes.filter((size,index) => {
                    return (sizeIndex === index )
                })[0]

             
                if(item !== null){
                    const selectedSize = item.size 
                    const selectedItem = item.item 

                    const cushion = this.cushionSize.filter((size) => {
                        return (size.value === selectedSize && size.reference === selectedItem)
                    })[0]

                    let letCushionSize = cushion.price;
                    if(item.class === 'p'){
                        letCushionSize = letCushionSize + cushion.pipping;
                    }
                    if(item.class === 'e'){
                        letCushionSize = letCushionSize + cushion.embroidery;
               
                    }
                    if(item.class === 'ep'){
                        letCushionSize = letCushionSize + cushion.pipping_embroidery;
                    }

                    item.price = letCushionSize
                    
                    this.$set(this.data.rows,rowIndex,row)
                }
            }

            // const row = this.data.rows[rowIndex];

            // let size = row.size[index];

            // const size.price = 
        },

        print(url){
            // console.log(this.data.rows);

            const extractedRows = [];
            if(this.data.rows.length > 0){
                this.data.rows.forEach((item) => {
                    if(item.sizes.length > 0){
                        item.sizes.forEach((size) => {
                            /* const finalItem = {
                                series : item.series,
                                size : size.size,
                                price : size.price,
                                class : size.class,
                            } */
                            const stringify = `${item.series}-x-${size.size}-x-${size.class}-x-${size.price}`

                            extractedRows.push(stringify)
                        })
                    }
                })
            }

            const joinArray = extractedRows.join(',');

            console.log(joinArray)

             var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
            const win = window.open(`${url}?items=${joinArray}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()

            
        },

        onItemSelect(v){
            const {index,value} = v

            console.log("onItemSelect")
            console.log(value)
            const splitIndex = index.split("-");

            if(splitIndex.length > 0){
                const rowIndex = parseInt(splitIndex[0])
                const sizeIndex = parseInt(splitIndex[1])

                const row = this.data.rows.filter((x,index) => {
                    return  rowIndex === index
                })[0]



                if(row !== null){

                    this.$nextTick(()=>{
                             const size = row.sizes.filter((size,index) => {
                                return (sizeIndex === index )
                            })[0]
         

                            if(size !== null){

                                const cushion = this.cushionSize.filter((rowsize) => {
                                    return (rowsize.value === size.size && rowsize.reference === value)
                                })[0]

                                console.log("On Item Select Custion")
                                console.log(cushion)
                                console.log(value)


                                let letCushionSize = cushion.price;
                                if(size.class === 'p'){
                                    letCushionSize = letCushionSize + cushion.pipping;
                                }
                                if(size.class === 'e'){
                                    letCushionSize = letCushionSize + cushion.embroidery;
                                }

                                if(size.class === 'ep'){
                                    letCushionSize = letCushionSize + cushion.pipping_embroidery;
                                }

                                size.price = letCushionSize        
                                
                                
                                console.log(letCushionSize)
                                console.log(size)
                                console.log(row)
                                this.$set(this.data.rows,rowIndex,row)
                            }
                    })
               


                }
            }
        },

        onSelectLocation(v){
            const {index,value} = v


            const splitIndex = index.split("-");

            if(splitIndex.length > 0){
                const rowIndex = parseInt(splitIndex[0])
                const sizeIndex = parseInt(splitIndex[1])

                const row = this.data.rows.filter((x,index) => {
                    return  rowIndex === index
                })[0]



                if(row !== null){

                    this.$nextTick(()=>{
                             const size = row.sizes.filter((size,index) => {
                                return (sizeIndex === index )
                            })[0]
                            console.log("size")
                            console.log(size)
                            // console.log(splitIndex)

                            if(size !== null){
                                const selectedSize = value 

                                const cushion = this.cushionSize.filter((rowsize) => {
                                    return (rowsize.value === selectedSize && size.item === rowsize.reference)
                                })[0]


                                let letCushionSize = cushion.price;
                                if(size.class === 'p'){
                                    letCushionSize = letCushionSize + cushion.pipping;
                                }
                                if(size.class === 'e'){
                                    letCushionSize = letCushionSize + cushion.embroidery;
                                }

                                if(size.class === 'ep'){
                                    letCushionSize = letCushionSize + cushion.pipping_embroidery;
                                }

                                size.price = letCushionSize                        
                                this.$set(this.data.rows,rowIndex,row)
                            }
                    })
               


                }
            }
            
        },



        fnCustionSize(){
            return this.cushionSize
        }

        

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

    computed : {
       ...mapGetters('BarcodeFabricFormModule',[
            'data',
            'columns'
        ]),

        cushionSizeFilter : function(){
            return this.cushionSize.filter((item) => item.reference === 'cushion',[])
        }
    },
    watch : {
 
    }

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}

.tab-content{
    padding: 0
}
.alert{
    border-radius : 0!important;padding : .25rem 1.25rem!important;
}

.fabric-radio{
    margin-bottom: 0!important;
}
.fabric-radio>.el-radio__label{
    display: none!important;
}

.c-container{
    height: 32px; display: flex; justify-content: center; align-items: center;
}
</style>