<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Designation" windowClass="height-auto" scrollClass='height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="2">
            <template #left-button>
                <template v-if="formLoading">
                  <button class="btn btn-sm btn-elegant" type="button" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                </template>
                <template v-else>
                  <button class="btn btn-sm btn-elegant" type="button" @click="passes(onSubmitForm)">Save</button>
                </template>
              </template>

          
          <div class="container mt-1">
                <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="ID" placeholder="ID" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="true" />                  
                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Designation" vid="designation" name="designation" v-model="form.name" size="sm" :disabled="disabled"/>

          </div>
       </ThatsWindow>
          </ValidationObserver>

  </div>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsTextarea} from '../../components/thats/Thats'
import { MessageBox } from 'element-ui';
export default {
    components: {
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,

  },
    
    data : () => ({
      validated : false,
      formOriginal : null,
      form : null,
      
      formLoading : false,
      showError : false,
      

    }),

  created : function() {
      var self = this;

      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.nextCode()        
      }
       this.optionsHandler();
    },



    methods: {
      ...mapActions('DesignationModule',[
        'storeRecord',
        'ppdateRecord',
        'getNextCode',        
      ]),
      ...mapMutations('DesignationModule',[
        'setFormStatusCreate',
        'setFormRecord',
        'setFormStatusUpdate',
      ]),

      formVar(){
        return {
          series : 0,
          description : null,
          attr : {},
          status : "active",
          attachment : []
        }
      },

    openDialog(){
      this.$refs.window.openDialog();
    },
    closeDialog(){
        this.$refs.window.closeDialog()
    },
    nextCode(){
      var self = this;
      this.getNextCode().then((response) => {
        self.form.series = response.code
      });
    },
    /* start */
    optionsHandler(){
    },
    /* end */
     
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success')


                              self.setFormStatusUpdate()
                              self.setFormRecord(response.data)
                              self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                                self.setFormRecord(response.data)
                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.setFormStatusCreate();
        this.showError = false
      },
      
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },
    },

    computed: {

      ...mapGetters('DesignationModule',
      [
        'formStatus',
        'formRecord',
      ]),
      
    },

    watch: {
      FormRecord : function(v){
        
        if(this.formStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(v));
          this.formOriginal = JSON.parse(JSON.stringify(this.FormRecord));          
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

    },


}
</script>
