<template>
    <ValidationProvider :vid="vid" :name="name" :rules="rules" v-slot="{invalid,errors}">
        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
                <div class="md-form mt-0">
                <input type="text"
                    ref="input"
                    class="form-control"
                    :disabled="disabled"
                    :readOnly="readOnly"
                    :name="name"
                    :is-valid="invalid"
                    :placeholder="$attrs.placeholder"
                    v-on:input="updateValue($event.target.value)"
                    v-on:keydown="checkKeyCode($event)"
                    :value="value"
                    v-bind:class="{'is-invalid' : hasError(errors)}"
                    >
                    <template v-if="show_error == true">
                        <span class="errors">{{errors[0]}}</span>
                    </template>
                </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : "ThatsSeriesInput",
    props : {
        vid: String,
        name: String,
        rules : {
            type : [Object, String],
            default : 'required'
        },
       
        value: {
            required: true,
            default: null
        },

        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        disabled : {
            type : Boolean,
            default : false
        },
        readOnly : {
            type : Boolean,
            default : false
        },
        keycode : {
            type : Boolean,
            default : true
        }

    },
    components : {
        ValidationProvider,
        mdbInput
    },

    data : ()=>({
        inputValue : 0
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (isInvalid === true && this.validated === true)) ? true : false
        },

       updateValue: function(cVal) {
        this.$refs.input.value = cVal.toString();
        this.inputValue = cVal.toString();
        this.$emit("input", cVal.toString());
        },
        checkKeyCode(evt) {
            if(this.keycode){

                var keyCode = evt.which ? evt.which : event.keyCode;
            
            //Accept Numbers Only and dash
            if ((keyCode > 47 && keyCode < 58) ||
                keyCode === 8 ||
                keyCode === 9 ||
                keyCode === 173 ||
                keyCode === 37 ||
                keyCode === 39) {
                    return true;
            }
            evt.preventDefault();
            }
        },

        hasError(errors){
            return (errors.length > 0) ? true : false
        }
    },
    
  created() {
    if (this.value) {
      this.inputValue = (this.value != null ) ? this.value.toString() : "";
    }
  }
}
</script>