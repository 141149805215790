<template>
<!-- :left="300" :top="20" -->
<hsc-window-style-metal >

    <hsc-window v-bind:class="$attrs.windowClass +' '+ parentContainerStyle" :title="$attrs.title" :resizable="false" :left="x" :top="y"  :width="width" :height="height" :minWidth="400" :maxWidth="800" :minHeight="minHeight" :maxHeight="maxHeight" :zGroup="group" :closeButton="true" :isOpen.sync="dialogIsOpen">
        <div class="scrollbar" v-bind:class="$attrs.scrollClass" >
            <slot></slot>
        </div>
        <div class="pl-3 pr-3">
            <div class="d-flex">
                <slot name="left-button"></slot>


                <template v-if="formStatus === 'update'">
                <!-- <mdb-btn color="grey" class="ml-0" size="sm" @click="emitCopy">Copy</mdb-btn> -->
                    <!-- <mdb-dropdown class="inactive-reactive ml-0 thats-dropdown">
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm">Inactive/Reactive</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="emitStatus('inactive')">Inactive</mdb-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <mdb-dropdown-item @click="emitDelete">Delete</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> -->
                    <mdb-btn color="grey" class="ml-0" size="sm" @click="emitHistory">History</mdb-btn>
                </template>
                <mdb-btn color="grey" class="ml-0" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                <slot name="right-button"></slot>
            </div>
        </div>
    </hsc-window>
</hsc-window-style-metal>
</template>


<script>
import { StyleFactory, WindowType } from '@hscmap/vue-window'

import { mapGetters, mapMutations } from 'vuex'
import { MessageBox } from 'element-ui'

import {
  mdbBtn,
  mdbContainer,
  mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle

} from "mdbvue";
export default {
    name : "ThatsWindow",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
        width : {
            type : Number,
            default : 800
        },
        height : {
            type : Number,
            default : 400
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 450
        },
        formStatus : {
            type : String,
            default : 'create'
        },
        group : {
            type : Number,
            default : 9999
        },
        position : {
            type : String,
            default : 'center'
        },
        parentContainerStyle  : {
            type : String,
            default : ''
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0
        }
    },
    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes')
                    }).catch(() => {
                        self.$emit('deleteNo')
                    });
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        }

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)


        }
    }
}

</script>

<style scoped>
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
  }

</style>
