<template>
<div class="w-100">



    <template v-if="records.length > 0 && searchTerm != null && searchTerm != '' && searchTermTrue">
        <div class="d-flex flex-fill align-items-center mt-5">
            <mdb-alert color="success" class="w-100">

            <div class="d-flex justify-content-between align-items-center w-100">
                <span>Search result for : <strong>{{searchTerm}}</strong></span>
                <mdb-btn size="sm" color="info" class="z-depth-0 mx-0 round rounded" @click="onClearSearch">Clear</mdb-btn>
            </div>
            </mdb-alert>
        </div>
    </template>

    <div class="row">
            <mdb-col lg="4" md="3" sm="6" xs="6" class="mb-lg-0 mb-4" :key="item.id" v-for="item in records">

                       <!-- <div class="flex-container">
                       </div>  -->
                        <mdb-card cascade narrow ecommerce class="border border-light z-depth-0 flex-containerx">
                            <a href="javascript:void(0)" @click="viewGallery(item)" class="flex-itemsx">
                            <mdb-view overlay="white-slight">
                                <mdb-card-image :src="item.default" />
                            </mdb-view>
                            </a>

                            <mdb-card-body class="text-center flex-itemsx" cascade>
                                <a href="" class="grey-text">
                                    <h6><strong>{{item.name}}</strong></h6>
                                </a>
                                <p class="text-muted text-small product-other-details"> {{item.series || '-'}} <br> {{item.dimension || '-'}} <br> </p>
                           
                            <mdb-card-footer color="white" textColor="black" class="pb-0 px-0">

                            <div class="d-flex align-items-center justify-content-between py-1">
                                <span class="float-leftx font-weight-bold">
                                    <strong>Available</strong>
                                    </span>
                                <span class="float-rightx">

                                    {{item.available}}
                                   
                                    </span>
                                </div>

                                <div class="d-flex align-items-center justify-content-between py-1">
                                <span class="float-leftx font-weight-bold">
                                    <strong>Price </strong>
                                    </span>
                                <span class="float-rightx">

                                 
                                   

                                   <template v-if="item.hasOwnProperty('promotions') && item.promotions.length > 0">
                                        <span><span class="red-text"><s>{{item.formatted_price}} QAR</s></span> {{fnFormatMoney(item.promo.promo)}} QAR</span>
                                        
                                   </template>
                                   <template v-else>
                                   {{item.formatted_price}} QAR
                                   </template>
                                    </span>
                                </div>

                                 <mdb-btn size="sm" color="elegant" class="z-depth-0 w-100 mx-0" @click="viewProduct(item.series)">View</mdb-btn>

                            
                            </mdb-card-footer>
                            </mdb-card-body>
                        </mdb-card>
                        </mdb-col>

    </div>

    <template v-if="records.length > 0 && totalRecord > perPage">
        <div class="d-flex flex-fill align-items-center mt-3">
            <mdb-btn size="sm" color="elegant" class="z-depth-0 w-100 mx-1" @click="prevPage">Previous</mdb-btn>
            <mdb-btn size="sm" color="elegant" class="z-depth-0 w-100 mx-1" @click="nextPage">Next</mdb-btn>
        </div>
    </template>


    <CoolLightBox :items="items" :index="index" :useZoomBar="true" @close="index = null"></CoolLightBox>

    <mdb-modal :show="modal" title="Product" size="lg" @close="modal = false">
            <mdb-modal-header>
                <mdb-modal-title class="font-weight-bold">Product Details</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body v-loading="productLoading">
                <template v-if="product != null">
                    <div class="row">

                        <div class="col-md-8 text-left order-sm-2 order-md-last">

                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-12 col-md-4 col-lg-4"> Name : </dt>
                                <dd class="col-sm-12 col-md-8 col-lg-8"> {{product.name}} </dd>
                            </dl>
                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-12 col-md-4 col-lg-4"> Item Code : </dt>
                                <dd class="col-sm-12 col-md-8 col-lg-8"> {{product.series}} </dd>
                            </dl>
                           
                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-4"> Dimension : </dt>
                                <dd class="col-sm-8"> {{product.dimension}} </dd>
                            </dl>
                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-4"> Finishes/Materials : </dt>
                                <dd class="col-sm-8"> {{product.attr.finishes || 'N/A'}} </dd>
                            </dl>
                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-4"> Colours : </dt>
                                <dd class="col-sm-8"> {{product.attr.colour || 'N/A'}} </dd>
                            </dl>
                            <dl class="row mt-0 mb-0">
                                <dt class="col-sm-4"> Fabric Use : </dt>
                                <dd class="col-sm-8"> {{product.attr.fabric || 'N/A'}} </dd>
                            </dl>
                            
                            <dl class="row mt-1">
                                <dt class="col-sm-12"> Overview : </dt>
                                <dd class="col-sm-12"> {{product.description}} </dd>
                            </dl>
                            <dl class="row mt-1">
                                <dt class="col-sm-6 col-lg-4 col-md-4"> Available : </dt>
                                <dt class="col-sm-6 col-lg-8 col-md-8"> {{product.available}}</dt>
                            </dl>
                            
                            <dl class="row mt-1">
                                <dt class="col-sm-12 col-lg-4 col-md-4"> Item Price</dt>
                                <dt class="col-sm-12 col-lg-8 col-md-8">
                                
                                <template v-if="product.hasOwnProperty('promotions') && product.promotions.length > 0">
                                        <span><span class="red-text"><s>{{product.formatted_price}} QAR</s></span> {{product.promo.price}} QAR</span>
                                        
                                   </template>
                                   <template v-else>
                                   {{product.formatted_price}} QAR
                                   </template>
                                </dt>
                            </dl>


                         
                        
                        </div>
                        <div class="col-md-4 text-center order-sm-1 order-md-last">
                            <a href="javascript:void(0)" @click="viewGallery(product)">
                                <img :src="product.default" class="item-image w-100"  />
                            </a>
                            <small>Click to view more images</small>
                        </div>

                    </div>
                         <mdb-btn color="elegant" size="sm" block class="mt-2 w-100 z-depth-0" @click.native="fnAddToCart">Add to Cart</mdb-btn>
                </template>
          </mdb-modal-body>
         <!--  <mdb-modal-footer>
              <mdb-btn @click="saveNote" color="black">Save</mdb-btn>
              <mdb-btn @click="closeModal" color="black">Close</mdb-btn>
          </mdb-modal-footer> -->
      </mdb-modal>


      <mdb-modal :show="modalCart" title="Cart" size="lg" @close="modalCart = false">
            <mdb-modal-header>
                <mdb-modal-title class="font-weight-bold">Cart</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <template v-if="cart.length > 0">
                    <mdb-row>
                        <mdb-col lg="12" md="12" sm="12" >
                            <template v-if="cart!== null && cart.length > 0">
                                <mdb-row v-for="(item,index) in cart" :key="index" class="item-crt w-100">
                                    <mdb-col md="4" lg="3" sm="12" class="cart-item-image">
                                        <div class="item-img-crt">
                                        <img :src="item.default" alt=""/>
                                        </div>
                                    </mdb-col>
                                    
                                    <mdb-col md="8" class=" pt-3 cart-item-details"  lg="9" sm="12">
                                        <div class="item-info-crt pl-3 pr-3">
                                        <p class="mb-0">{{item.name }}</p>
                                        <p>{{item.sub_total}} QAR</p>
                                        </div>
                                        
                                        <div class="def-side-crt def-number-input number-input safari_only">
                                        <button type="button" @click="decrementItem(index,item)" class="minus"></button>
                                        <input class="quantity" min="1" name="quantity" v-model="item.quantity" readonly type="number">
                                        <button type="button" class="plus" @click="incrementItem(item)"></button>
                                        </div>

                                        <a href="javascript:void(0)" class="text-decoration-none text-uppercase remove-item-crt" @click="fnRemoveItem(index,item)"><close-icon></close-icon> Remove</a>

                                    </mdb-col>

                                <div class="w-100"><hr class="hr-dark"></div>
                            </mdb-row>

                    
                            </template>
                            <template v-else>
                                <div class="empty-cart-note">
                                    <p>Your cart is currently empty.</p>
                                </div>
                            </template>
                        </mdb-col>
                        <mdb-col lg="12" md="12" sm="12">
                            <mdb-card class="cart-item-card cart-item-summary z-depth-0">
                                <mdb-card-body>
                                 
                                    <div class="item-detail-crt w-100">
                                    <dl class="row mx-0 mb-0">
                                      

                                        <dt class="col-sm-6 text-left item-total">Total</dt>
                                        <dd class="col-sm-6 text-right item-total-equal">{{cartTotal}} QAR</dd>

                                    </dl>

                                 
                                    

                                    </div>
                                   
                                    <div class="item-detail-crt w-100">
                                    <dl class="row mx-0 mb-0">
                                      

                                        <dd class="col-sm-6 text-left"><mdb-btn size="sm" color="elegant" class="z-depth-0" @click="modalCart = false">CLOSE</mdb-btn></dd>
                                        <dd class="col-sm-6 text-right"><mdb-btn size="sm" color="elegant" class="z-depth-0" @click="checkoutCart">CHECKOUT</mdb-btn></dd>

                                    </dl>

                                 
                                    

                                    </div>

                                </mdb-card-body>

                                </mdb-card>
                                    
                                    
                        </mdb-col>
                   </mdb-row>
                </template>
          </mdb-modal-body>
  
      </mdb-modal>

    <ValidationObserver class="needs-validation form-custom-format" tag="form" ref="customeObserver"  v-slot="{passes}" @submit.prevent="onPlaceOrderForm()">    
      <mdb-modal :show="modalCustomer" title="Cart" size="lg" @close="modalCustomer = false">
            <mdb-modal-header>
                <mdb-modal-title class="font-weight-bold">Customer</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <template v-if="modalCustomer">
                 <validation-provider rules="required" vid="customerMobile" name="customerMobile" v-slot="{errors,invalid}">
                     <mdb-autocomplete
                            @search="requestCustomerNumber"
                            :data="customers"
                            :filterFunction="item => item"
                            label="Enter customer mobile"
                            :isAsync="true" outline
                            @select="onSelectCustomer"
                            v-model="checkoutForm.mobile"
                            @clear="onClear"
                            :invalidFeedback="errors[0]"
                            :is-invalid="invalid"
                            :inputClass="hasError(errors)" 
                        />
                        <template v-if="errors.length > 0">
                            <span class="validation-provider invalid-feedback">{{errors[0]}}</span>
                        </template>
                    </validation-provider>
                  <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customername" name="customername" v-model="checkoutForm.name" size="sm"  :rules="'required'" :disabled="true" />
                  <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Address" placeholder="Address" vid="address" name="address" v-model="checkoutForm.address" size="sm"  :rules="''" />
                     <div class="w-100"><hr class="hr-light"></div>
                                        
                    <h5 class="font-weight-bold modal-title" data-v-135c83c0="">Order</h5>
                    <div class="item-detail-crt w-100">
                        <dl class="row mx-0 mb-0">
                            <dt class="col-sm-6 text-left item-total">
                            Total
                            </dt>
                            <dd class="col-sm-6 text-right item-total-equal">{{cartTotal}} QAR</dd>

                        </dl>
                    </div>
                    <div class="w-100"><hr class="hr-light"></div>
                    <h5 class="font-weight-bold modal-title" data-v-135c83c0="">Salesperson</h5>
                    <dl class="row mt-0 mb-0">
                                        <dt class="col-sm-9">{{sales_person}} </dt>
                                    </dl>
                    <!-- <mdb-btn color="elegant" size="sm" block class="mt-2 w-100 z-depth-0 w-100" @click.native="P">PLACE ORDER</mdb-btn> -->
                </template>
          </mdb-modal-body>

          <mdb-modal-footer>
                <!-- <mdb-btn color="black" size="md" @click.native="modalCustomer = false">Cancel</mdb-btn> -->
                <template v-if="cart.length > 0">
                    <template v-if="placeOrderLoading">
                        <mdb-btn color="black" size="sm" class="mt-2 w-100 z-depth-0 w-100">Please wait...</mdb-btn>
                    </template>
                    <template v-else>
                    <mdb-btn color="black" size="sm" class="mt-2 w-100 z-depth-0 w-100" @click="passes(onPlaceOrderForm)">Place Order</mdb-btn>
                    </template>
                </template>
            </mdb-modal-footer>

      </mdb-modal>
      </ValidationObserver>

      <mdb-modal :show="modalScan" title="Scan Qr" size="lg" @close="modalScan = false">
            <mdb-modal-header>
                <mdb-modal-title class="font-weight-bold">Scan Qr</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>

             <template v-if="decodedString === null">
                        <camera-scanner @decode="onDecode" ref="cameraScanner" :track="repaint" class="m-1" :constraints="constraints" :showReset="true" :showChange="true" :showStart="true">
                        </camera-scanner>
                        
                    </template>

                    <template v-if="checking">
                        <p class="text-center"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Searching product...</p> 
                    </template>
                    <template v-if="notfound && checking == false">
                        <p class="text-center">No product found.</p> 
                    </template>

          </mdb-modal-body>

      </mdb-modal>

      <mdb-modal :show="modalLoading" size="lg" @close="modalLoading = false" centered class="z-depth-0">
           
            <mdb-modal-body>

            <strong><span class="spinner-border spinner-border-sm " role="status" aria-hidden="true"></span>
                              Please wait...</strong>
          </mdb-modal-body>

      </mdb-modal>


      <mdb-modal :show="modalSearch" size="lg" @close="modalSearch = false" centered class="z-depth-0">
           
            <mdb-modal-body>

            <TextInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Search" placeholder="Search" vid="Search" name="Search" v-model="searchTerm" size="sm"  :rules="''"/>
            <mdb-btn color="black" size="sm" class="mt-2 w-100 z-depth-0 w-100" @click="onSearchTerm">Search</mdb-btn>
          </mdb-modal-body>

      </mdb-modal>

      
</div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {mdbAlert,mdbModalFooter,mdbCardFooter,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle} from 'mdbvue'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import {TextInput} from '../components/common'
import mdbAutocomplete from '../components/common/Autocomplete'
import CameraScanner from '../components/CameraScanner'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { QrcodeStream } from 'vue-qrcode-reader'
export default {
    components : {
        CameraScanner,mdbAlert,QrcodeStream,mdbModalFooter,ValidationObserver,ValidationProvider,TextInput,mdbAutocomplete,mdbRow, mdbCol, mdbCard, mdbCardBody, mdbCardFooter, mdbCardTitle,  mdbView, mdbCardImage,  mdbTooltip, mdbBtn, mdbModal, mdbModalBody, mdbModalHeader, mdbModalTitle
    },
    data : () => ({
        decodedString : null,
        decodedLoginString : null,
        checking : false,
        scanModal : false,
        modal : false,
        records: [],
        items: [],
        index: [],
        loading: false,
        product : null,
        productLoading : false,
        modalCart : false,
        modalScan : false,
        modalCustomer : false,
        customers : [],
        checkoutForm : null,
        placeOrderLoading : false,
        notfound : false,
        sales_person : "",
        perPage : 24,
        totalRecord : 24,
        current_page : 1,
        last_page : 1,
        modalLoading : false,
        modalSearch : false,
        clearSearch : false,
        searchTerm : null,
        searchTermTrue : null,
        constraints: {
            facingMode: 'user'
        },
    }), 
    mounted(){
        const self = this;
        this.checkoutForm = this.customerObj();
        this.fnSearchProduct();

        this.$eventBus.$on('open-cart', function(){
            self.modalCart = true
        })
        
        this.$eventBus.$on('open-scan', function(){
            self.modalScan = true
            self.decodedString = null
            self.notfound = false
            self.checking = false
        })
        
        this.$eventBus.$on('open-filter', function(){
            self.modalSearch = true
        })

        let user = JSON.parse(localStorage.getItem('user'));  
        self.sales_person = user.user.name
    },
    methods : {
        ...mapActions('UserModule',[
            'authToken'
        ]),
        ...mapActions('CustomerModule',[
            'findCustomer'
        ]),
        ...mapActions('ProductModule',[
            'resetData','getProduct'
        ]),
        ...mapMutations('ProductModule',[
            'setPage','setSearchTerm','setFilters'
        ]),
        ...mapActions('CartModule',[
            'addToCart','getCart','updateCart','deleteItem','placeOrder'
        ]),
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        async fnSearchProduct(){
            const self = this
            self.loading = true;
            self.modalLoading = true;
            await this.resetData().then((response) => {
                console.log(response)

                self.current_page = response.current_page
                self.last_page = response.last_page
                self.totalRecord = response.total
        
                self.records = response.data.map((item) => {
                    return {...item, default : item.default.replace("/thumb-", "/medium-")}
                },[])
                self.decodedString = null
                self.checking = false;
                self.notfound = true;
                self.scanModal = true;
                self.loading = false;
                self.modalLoading = false;
                 if(self.searchTerm != null && self.searchTerm != ""){
                    self.searchTermTrue = true
                 }

                 if(self.clearSearch){
                    self.clearSearch = false;
                    self.searchTerm = null;
                 }

                 self.scrollToTop()
                
            })
        },

        viewGallery(v){

            if(v.image.length > 0){
                this.items = v.image.map((i) => {
                    return i.large
                });

                this.index = 0
            }
        },

        fnAddToCart(){
            const self = this;
            this.addToCart(this.product).then(() => {
                self.$ThatsNotify.success({
                    message: "Item was succesfully added to cart"
                });

                self.modal = false
                self.modalCart = true
                            /* MessageBox({
                              title : 'Success',
                              message: 'Item successfully added to cart',
                              type: 'success'
                          }) */
            });
        },

        viewProduct(series){
            const self = this
            
            this.productLoading = true
            this.getProduct(series).then((response) => {
                console.log("response",response)
                self.product = {
                    ...response,
                    default : response.default.replace("/thumb-", "/medium-")
                }
                self.productLoading = false
                self.modalScan = false
                self.modal = true
                // self.getDefault()
            }).catch((err) => {

                console.log("View Product error",err)
                 self.modal = false
                self.productLoading = false
                self.checking = false
                self.notfound = true
            })
        },

        incrementItem(v){
            

            v.quantity = v.quantity + 1
            var Price = v.price;

            if(v.hasOwnProperty('promo') && v.promo != null){

                console.log("promo",v.promo.promo)
                Price = v.promo.promo
            }
            var calcSubTotal = parseFloat(Price) * parseFloat(v.quantity);
            v.sub_total = formatMoney(calcSubTotal,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
            this.updateCart(v)
      },
      decrementItem(index,v){

         
        if(v.quantity >= 2){
          v.quantity = v.quantity - 1

          var Price = v.price;
          if(v.hasOwnProperty('promo') && v.promo != null){
            console.log("promo",v.promo.promo)
                Price = v.promo.promo
            }
            var calcSubTotal = parseFloat(Price) * parseFloat(v.quantity);
            v.sub_total = formatMoney(calcSubTotal,{ symbol: "", precision: 2, thousand: ",", decimal: "." });

            this.updateCart(v)
        }
      },

      fnRemoveItem(index,item){

            const self = this

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success z-depth-0',
                    cancelButton: 'btn btn-danger z-depth-0'
                },
                buttonsStyling: false
                })

                swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
                }).then((result) => {
                if (result.isConfirmed) {
                    swalWithBootstrapButtons.fire(
                    'Deleted!',
                    'Your slide has been deleted.',
                    'success'
                    )

                    self.deleteItem(item)
                    self.cart.splice(index,1)
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Swal.DismissReason.cancel
                ) {
                    swalWithBootstrapButtons.fire(
                    'Cancelled',
                    'Your imaginary file is safe :)',
                    'error'
                    )
                }
                })

      },

      checkoutCart(){
        this.modalCart = false
        this.modalCustomer = true
      },

      customerObj(){
          return {
                contact_id : "",
                mobile : "",
                name : "",
                address : "",
                instructions : "",
            }
        },

      requestCustomerNumber(value){
        const self = this
        if (value === "") {
          this.customers = [];
          return;
        }
            this.findCustomer({
                term : value
            }).then((res) =>{
                self.customers = res;
            }).catch(err => {
            console.log(err);
            self.customer = [];
          });
      },
      onSelectCustomer(v){
             const self = this;
          self.checkoutForm.contact_id = v.id;
          self.checkoutForm.mobile = v.mobile;
          self.checkoutForm.name = v.name;
          self.checkoutForm.address = v.address;
      },
      onClear(){
        this.checkoutForm = this.customerObj();
      },
      onPlaceOrderForm(){
          const self = this;
          self.placeOrderLoading = true;
          const success = this.$refs.customeObserver.validate();
            if (!success) {
                return;
            }

            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false
                })

            if (success) {
                
                // console.log(this.checkoutForm)
                this.placeOrder(this.checkoutForm).then((response) => {
                    
                    console.log("placeOrder")
                    console.log(response)
                    swalWithBootstrapButtons.fire(
                        'Success!',
                        `Your order has been placed. Ref. # ${response.data.series}`,
                        'success'
                    )
                    self.checkoutForm = self.customerObj();
                    self.placeOrderLoading = false;
                    self.modalCustomer = false;
                });

            }
      },

        onDecode(decodedString){
            this.decodedString = decodedString;
            this.checking = true;
            this.viewProduct(decodedString)
        },

        repaint (location, ctx) {
            const {
            topLeftCorner,
            topRightCorner,
            bottomLeftCorner,
            bottomRightCorner,
            } = location

            ctx.strokeStyle = 'blue' // instead of red

            ctx.beginPath()
            ctx.moveTo(topLeftCorner.x, topLeftCorner.y)
            ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y)
            ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y)
            ctx.lineTo(topRightCorner.x, topRightCorner.y)
            ctx.lineTo(topLeftCorner.x, topLeftCorner.y)
            ctx.closePath()

            ctx.stroke()
        },

        onDecodeLogin(decodedString){
            const self = this
            this.decodedLoginString = decodedString;
             const splitQcode = this.decodedLoginString.split('-x-');
            if(splitQcode.length > 1){
                       /*  const locationToken = splitQcode[1].replace(/(\r\n|\n|\r)/gm, "");

                   
                            const params = {token : locationToken, bearer : splitQcode[0]}; */
                            this.authToken(splitQcode[0]).then((res) => {
                                console.log(res)
                                if (res.data.token) {
                                    localStorage.setItem('user', JSON.stringify(res.data));
                                }
                                self.$router.go()
                                // self.$router.push({ name: 'welcome' });
                            }).catch(error => {
                                
                            })
                    }
        },

        nextPage(){
            const nextPage = (this.current_page == this.last_page) ? this.current_page : this.current_page + 1;
            this.setPage(nextPage)
            this.fnSearchProduct()
        },

        prevPage(){
            const nextPage = (this.current_page == 1) ? this.current_page : this.current_page - 1;
            this.setPage(nextPage)
            this.fnSearchProduct()
        },

        onSearchTerm(){
           
            if(this.searchTerm != null && this.searchTerm != ""){
                
                this.setPage(1)
                this.setSearchTerm(this.searchTerm)
                const filter = {"searchTerm":this.searchTerm,"supplierCode":null,"itemName":null,"ItmGroup":"0","brands":"0","collection":"0","category":["0"],"materials":"ALL","colour":"ALL","attribute":"ALL","status":"all","website":"all","availability":"0","in_transit":"0","under_production":"0","range":[-9999,9999]}
                this.setFilters(filter)
                this.modalSearch = false;
                this.fnSearchProduct()
                
            }
        },

        onClearSearch(){
            this.clearSearch = true
            this.setPage(1)
            this.setSearchTerm(null)
            const filter = {"searchTerm":null,"supplierCode":null,"itemName":null,"ItmGroup":"0","brands":"0","collection":"0","category":["0"],"materials":"ALL","colour":"ALL","attribute":"ALL","status":"all","website":"all","availability":"0","in_transit":"0","under_production":"0","range":[-9999,9999]}
            this.setFilters(filter)
            this.modalSearch = false;
            this.fnSearchProduct()
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth', 
            });
        },

        fnFormatMoney(v){
            return formatMoney(v,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        },
    },
    computed : {
        ...mapGetters('CartModule',['cart']),
        cartTotal : function(){
            const total = this.cart.map((item) => {
                    return unformat(item.sub_total)
                }).reduce((a, b) => a + b, 0);
            return formatMoney(total,{ symbol: "", precision: 2, thousand: ",", decimal: "." });
        }
    }
}

</script>

