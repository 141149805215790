<template>

        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer"   @submit.prevent="onSubmitForm()">
            <el-dialog title="Add / Refund Points" :visible.sync="dialogIsOpen" :close-on-click-modal="false" width="40%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">


                <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Transaction Code" vid="transaction_code" name="transaction_code" v-model="form.transaction_code"  :rules="'required'" size="sm" />
                <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Transaction Date" placeholder="Transaction Date" vid="date" name="date" v-model="form.date" :rules="'required'" size="sm"/>
                <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Amount" vid="amount" name="amount" v-model="form.amount"  :rules="'required'" size="sm" />
                <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Transaction Type" v-model="form.type" :reduce="label => label.value" :reduceLabel="'label'" :options="types" :rules="'required'" :disabled="isSelectDisabled"></ThatsMultiSelect>
                <template slot="footer">
                    <div class="p-0">
                        <div class="d-flex">
                            <template v-if="formLoading">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" class="ml-0 mt-0 mb-0" size="sm" @click="onSubmitForm"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                                <mdb-btn color="grey" class="mt-0 mb-0" size="sm" @click="closeNewDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                            </template>
                        </div>
                    </div>
                </template>
            </el-dialog>
        </ValidationObserver>

</template>
<script>
import {mdbBtn, mdbIcon, mdbCard, mdbView} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,ThatsTextarea,ThatsMultiSelect,ThatsDatePicker } from '../../components/thats/Thats'
export default {
    components: {
        ThatsDatePicker,
        ThatsMultiSelect,
        mdbBtn,
        mdbIcon,mdbCard,mdbView,
        ValidationObserver,
        ThatsInput,
        ThatsTextarea,
  },
    props : {
      group : {
            type : Number,
            default : 1
        },

    },

    data : () => ({
        dialogIsOpen : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        isSelectDisabled : false,

        types : [
            {label : 'Payment',value : 'payment'},
            {label : 'Refund',value : 'refund'},
            {label : 'System',value : 'system'},
        ],

        currentFile: undefined,
        progress: 0,
      group2 : 3,

        required:true

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

    },
    methods: {
      ...mapActions('LoyaltyTransactionModule',[
        'StoreRecord',
        'SearchInvoiceRecord',
        'UpdateRecord'
      ]),
      ...mapMutations('LoyaltyTransactionModule',[
        'SetFormStatusCreate','SetFormRecord'
      ]),

      formVar(){
        return {
            transaction_code  : "",
            date : "",
            amount : "",
            type:"payment",
            tier_id :"",
            customer_id  :"",
            card_id   :"",
            card_number   :"",
            next_tier   :"",
            current   :Object,

        }
      },
        /* start */


        closeNewDialog(){
            this.dialogIsOpen = false
            this.form = this.formVar()
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
            this.isSelectDisabled = false;
        },





        /* end */
        openNewDialog(data, formData){
          this.dialogIsOpen = true
            console.log(data)
            if (data === 'transaction') {
                this.form.type = 'payment'
                this.isSelectDisabled = true;
            }
            if (data === 'refund') {

                this.form.type = 'refund'
                this.isSelectDisabled = true;
            }
            if (data === 'system') {
                this.form.type = 'system'
                this.isSelectDisabled = false;
            }
            this.form.tier_id = formData.customer.tier.tier_id
            this.form.card_id = formData.card_id
            this.form.customer_id = formData.customer_id
            this.form.card_number = formData.card_number
            this.form.next_tier = formData.next_tier.minimum
            this.form.current = formData.current_tier

      },
      closeDialog(){
          this.dialogIsOpen = false;
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){

                      if(self.FormStatus == 'create'){
                          self.formLoading = true
                          // this.form.type = this.form.type.value;
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                              this.showCard = false;


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                    }
                    }


      },
        handleSearch() {
            this.form.amount = "";
            this.form.date = "";
            this.SearchInvoiceRecord(this.form).then((response) => {
               this.form.amount = response.payment.amount
               this.form.date = response.payment.date
            }).catch((error) => {
                console.log(error)
                this.form.amount = "";
                this.form.date = "";
            })
        },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        // this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      clearLocation(){
          this.form.attr.location = []
      }

    },

    computed: {
      ...mapGetters('LoyaltyCardModule',
      [
        'FormStatus',
        'FormRecord',
      ]),





    },

    watch: {
        FormRecord: function() {
            if (this.FormStatus == 'update') {
                const frm = JSON.parse(JSON.stringify(this.FormRecord));

                frm.attr = {...this.form.attr, ...frm.attr}
                this.getImage = frm.attr.images;
                this.form = {...this.form, ...frm}
            }
            if (this.FormStatus == 'create') {
                this.FormResetter();
            }

        },
        'form.transaction_code': function(newTransactionCode) {
            this.handleSearch(newTransactionCode);
        },
        dialogIsOpen: function(newDialogIsOpen) {
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },
}
</script>

<style scoped>

.cursor-pointer {
    cursor: pointer;
}

.input-add-image label {
    display: block;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
</style>
