<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Material" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :width="650" :height="450" :group="group" :formStatus="FormStatus" @dialog-state="dialogState">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-4">
             <mdb-row >
              <mdb-col col="12">
                  <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Material Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                  <template v-if="attributes">
                  <ThatsAttribute  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sub Material" vid="attribute" name="attribute" v-model="form.attr.attributes" :validated="validated" :rules="''"></ThatsAttribute>
                  </template>
              </mdb-col>
             </mdb-row>
          </mdbContainer>
        
       </ThatsWindow>



  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbTbl, mdbTblHead, mdbTblBody,mdbIcon,mdbInput} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {
  ThatsInput, 
  ThatsTextarea, 
  ThatsSeriesInput, 
  ThatsCheckbox,
  ThatsFieldset, 
  ThatsWindow, 
  ThatsRadio, 
  ThatsMultiSelect, 
  ThatsAutocomplete,
  ThatsAttribute,
  
  } from '../../components/thats/Thats'

let nextTodoId = 1

export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsAttribute,
        mdbBtn,
        mdbRow,
        mdbCol,
        
        mdbTbl,
      mdbTblHead,
      mdbTblBody,
      mdbIcon,mdbInput
        
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
      show : {
            type : String,
            default : 'product'
        },
      attributes : {
            type : Boolean,
            default : true
        },
    },
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.form.show_in = this.show
    } 

    
      
      this.optionsHandler()
    },
    methods: {

      ...mapActions('MaterialModule',[
        'StoreRecord',
        'UpdateRecord'
      ]),
      ...mapMutations('MaterialModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {

          name : null,
          description : null,
          show_in : 'product',
          attr : { 
            attributes : [],
          },
          status : 'true'
        }
      },
/* start */

      optionsHandler(){

      
      },
      
    

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.logo = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },


      FormResetter(){
        this.form = this.formVar()
        this.form.show_in = this.show
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.showError = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }

        this.optionsHandler();

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },



    },

    computed: {

      ...mapGetters('MaterialModule',
      [
        'FormStatus',
        'FormRecord',
      ]),

    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
        }
      },

      show :  function(v) {
          this.form.show_in = v
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>