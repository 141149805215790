<template>
    <div class="dashboard-container">
        <div class="d-flex flex-row align-content-center align-items-center">

             <h4 class="m-4 mb-2">Dashboard</h4>

              <mdb-dropdown class="" color="elegant"> 
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm">{{activeFilter.text}}</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="selectDashboardLocation(allObject)">All Location</mdb-dropdown-item>
                            <mdb-dropdown-item v-for="location in showrooms" :key="location.prefix" @click="selectDashboardLocation(location)">{{location.label}}</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> 
              
                    <mdb-dropdown class="ml-2" color="elegant"> 
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm">{{currentMonth}}</mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item v-for="month in yearToYearMonths" :key="month"  @click="selectDashboardMonth(month)">{{month}}</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown> 

        </div>

       
       
      <div class="dashboard">      
            <div class="tl-dashboard-container">
                <div class="container mt-0 mb-0 p-0">
                    <div class="block-1">
                        <template v-if="checkSalesExecutive || checkSystemSupport">
                            <mdb-card class="open-quotation-card m-2 dashboard-card" style="width: 25rem" v-loading="quotationSalesPeronLoading">
                                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                    <h4 class="dark-grey-text mb-0">Open Quotations</h4>
                                    <mdb-dropdown class="card-dropdown"> 
                                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mdb-dropdown-toggle>
                                        <mdb-dropdown-menu class="inactive-reactive-menu">
                                            <mdb-dropdown-item @click="viewMyQuotation">View</mdb-dropdown-item>
                                        </mdb-dropdown-menu>
                                    </mdb-dropdown> 
                                </mdb-card-body>
                            
                                <mdb-card-body class="pt-0">
                                        <h1 class="dark-grey-text">{{quotations.count}}</h1>
                                        <mdb-card-text>{{quotations.locations}}</mdb-card-text>
                                    </mdb-card-body>
                                </mdb-card>
                        </template>
                    </div>

                    <div class="block-1">
                        <template v-if="showShowroomCard">
                            <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="graphLoading">
                                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                    <h4 class="dark-grey-text mb-0">Monthly Sales</h4>

                                    <mdb-dropdown class="card-dropdown"> 
                                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mdb-dropdown-toggle>
                                        <mdb-dropdown-menu class="inactive-reactive-menu">
                                            <mdb-dropdown-item @click="toggleLineChart = !toggleLineChart">Show/Hide</mdb-dropdown-item>
                                        </mdb-dropdown-menu>
                                    </mdb-dropdown> 

                                </mdb-card-body>
                                
                                <mdb-card-body class="pt-0 border-bottom border-light">
                                        <LineGraph :chartData="chartData" :toggle="toggleLineChart"></LineGraph>
                                </mdb-card-body>
                                </mdb-card>

                        </template>
                    </div>

                    <template v-if="showShowroomCard">
                        <div class="block-2">
                            <mdbRow>
                                <mdbCol lg="4" md="4" sm="12" class="pr-0 mr-0">
                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="quotationCurrentMonthLoading">
                                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                        <h4 class="dark-grey-text mb-0">Number of Open Quotation</h4>
                                        <mdb-dropdown class="card-dropdown"> 
                                            <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mdb-dropdown-toggle>
                                            <mdb-dropdown-menu class="inactive-reactive-menu">
                                                <mdb-dropdown-item @click="viewLocationQuotation(activeFilter)">View</mdb-dropdown-item>
                                            </mdb-dropdown-menu>
                                        </mdb-dropdown> 
                                    
                                    </mdb-card-body>
                                    
                                    <mdb-card-body class="pt-0 border-bottom border-light">

                                        <ul>
                                            <li>
                                                <span>Current Month</span>
                                                <h3 class="dark-grey-text text-right mb-0">{{quotationCurrentMonth.current.count}}</h3>
                                            </li>
                                            <li>
                                                <span>Previous Month</span>
                                                <h3 class="dark-grey-text text-right mb-0">{{quotationCurrentMonth.previous.count}}</h3>
                                            </li>
                                            <li>
                                                <span>Total</span>
                                                <h5 class="dark-grey-text text-right">{{quotationCurrentMonth.all.count}}</h5>
                                            </li>
                                        </ul>

                                    </mdb-card-body>

                                    <div style="height:300px">

                                    <el-table ref="multipleTable" lazy class="card-element-table" :data="quotationCurrentMonth.data" height="300" stripe >
                                            <el-table-column :sortable="false" label="Name" align="left">
                                                    <template slot-scope="scope">
                                            
                                                        <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Curr" align="right" width="80" prop="current">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.current | thousands}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Prev" align="right" width="80" prop="previous">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.previous | thousands}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Total" align="right" width="80" prop="all">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.all | thousands}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                        </el-table>

                                    </div>
                                    </mdb-card>

                                </mdbCol>
                                <mdbCol lg="4" md="4" sm="12">

                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="invoiceCurrentMonthLoading">
                                        <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                            <h4 class="dark-grey-text mb-0">Number of Invoices</h4>
                                        </mdb-card-body>
                                        
                                        <mdb-card-body class="pt-0 border-bottom border-light">

                                            <ul>
                                                <li>
                                                    <span>Current Month</span>
                                                    <h1 class="dark-grey-text text-right mb-0 text-success">{{invoiceCurrentMonth.current.count}}</h1>
                                                </li>
                                                <li>
                                                    <span>Previous</span>
                                                    <h5 class="dark-grey-text text-right">{{invoiceCurrentMonth.previous.count}}</h5>
                                                </li>
                                            </ul>

                                        </mdb-card-body>


                                        <div style="height:300px">

                                    <el-table ref="multipleTable" lazy class="card-element-table" :data="invoiceCurrentMonth.data" height="300" stripe >
                                            <el-table-column :sortable="false" label="Name" align="left">
                                                    <template slot-scope="scope">
                                                        <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Curr" align="right" width="80" prop="current">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill :color="(scope.row.current_total > scope.row.previous_total) ?  'success' : 'danger'" class="z-depth-0">{{scope.row.current_total | thousands}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Prev" align="right" width="80" prop="previous">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill color="light-blue" class="z-depth-0">{{scope.row.previous_total | thousands}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                        </el-table>

                                    </div>

                                        <div class="data-legend">
                                            <mdb-badge pill :color="'success'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Higher than prev. month
                                        <mdb-badge pill :color="'danger'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Lower than prev. month
                                        </div>
                                        </mdb-card>

                                </mdbCol>
                                <mdbCol lg="4" md="4" sm="12" class="pl-0 ml-0">
                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="invoiceConversionRateLoading">
                                        <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                            <h4 class="dark-grey-text mb-0">Conversion Rate</h4>
                                        </mdb-card-body>                                        
                                        <mdb-card-body class="pt-0 border-bottom border-light">
                                            <ul>
                                                <li>
                                                    <span>Current Month</span>
                                                    <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (conversionRate.current.total > conversionRate.previous.total), 'text-danger' : (conversionRate.current.total < conversionRate.previous.total)}">{{conversionRate.current.total}}%</h1>
                                                </li>
                                                <li>
                                                    <span>Previous</span>
                                                    <h5 class="dark-grey-text text-right">{{conversionRate.previous.total}}%</h5>
                                                </li>
                                            </ul>

                                        </mdb-card-body>

                                        <div style="height:300px">

                                        <el-table ref="multipleTable" lazy class="card-element-table" :data="conversionRate.data" height="300" stripe >
                                                <el-table-column :sortable="false" label="Name" align="left">
                                                        <template slot-scope="scope">
                                                            <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                        </template>
                                                    </el-table-column>
                                                <el-table-column :sortable="true" label="Curr" align="right" width="80" prop="current_total">
                                                        <template slot-scope="scope">
                                                            <mdb-badge pill :color="(scope.row.current.total > scope.row.previous.total) ?  'success' : 'danger'" class="z-depth-0">{{scope.row.current_total}}%</mdb-badge>
                                                        </template>
                                                    </el-table-column>
                                                <el-table-column :sortable="true" label="Prev" align="right" width="80" prop="previous_total">
                                                        <template slot-scope="scope">
                                                            <mdb-badge pill color="light-blue" class="z-depth-0">{{scope.row.previous_total}}%</mdb-badge>
                                                        </template>
                                                    </el-table-column>
                                            

                                            
                                            </el-table>

                                        </div>


                                        <div class="data-legend">
                                                <mdb-badge pill :color="'success'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Higher than prev. month
                                            <mdb-badge pill :color="'danger'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Lower than prev. month
                                            </div>
                                        </mdb-card>
                                </mdbCol>
                            </mdbRow>

                        </div>


                        <div class="block-3">
                            <mdbRow>
                                <mdbCol lg="5" md="4" sm="12" class="pr-0 mr-0">
                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="quotationCurrentMonthLoading">
                                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                        <h4 class="dark-grey-text mb-0">Value of Open Quotation</h4>
                                        <mdb-dropdown class="card-dropdown"> 
                                            <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mdb-dropdown-toggle>
                                            <mdb-dropdown-menu class="inactive-reactive-menu">
                                                <mdb-dropdown-item @click="viewLocationQuotation(allObject)">View</mdb-dropdown-item>
                                            </mdb-dropdown-menu>
                                        </mdb-dropdown>
                                    </mdb-card-body>



                                    
                                    <mdb-card-body class="pt-0 border-bottom border-light">

                                        <ul>
                                            <li>
                                                <span>Current Month</span>
                                                <h5 class="dark-grey-text text-right mb-0">{{quotationCurrentMonth.current.value | amount}}</h5>
                                            </li>
                                            <li>
                                                <span>Last Month</span>
                                                <h5 class="dark-grey-text text-right mb-0">{{quotationCurrentMonth.previous.value | amount}}</h5>
                                            </li>
                                            <li>
                                                <span>Total</span>
                                                <h5 class="dark-grey-text text-right">{{quotationCurrentMonth.all.value | amount}}</h5>
                                            </li>
                                        </ul>

                                    </mdb-card-body>

                                    <div style="height:300px">

                                    <el-table ref="multipleTable" lazy class="card-element-table" :data="quotationCurrentMonth.data" height="300" stripe>
                                            <el-table-column :sortable="false" label="Name" align="left">
                                                    <template slot-scope="scope">
                                                        <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Curr" align="right" width="100" prop="current_value">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.current_value | amount}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Prev" align="right" width="100" prop="previous_value">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.previous_value | amount}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Total" align="right" width="100" prop="all_value">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill class="z-depth-0">{{scope.row.all_value | amount}}</mdb-badge>
                                                    </template>
                                                </el-table-column>

                                        
                                        </el-table>

                                    </div>
                                    
                                    </mdb-card>
                                </mdbCol>
                                <mdbCol lg="4" md="4" sm="12">

                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="invoiceCurrentMonthLoading">
                                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                        <h4 class="dark-grey-text mb-0">Value of Invoices</h4>

                                        
                                        <span v-bind:class="{'text-success' : (invoiceCurrentMonth.current.value >= invoiceCurrentMonth.previous.value),'text-danger' : (invoiceCurrentMonth.current.value < invoiceCurrentMonth.previous.value)}">
                                            <template v-if="invoiceCurrentMonth.difference > 0">
                                                <mdb-icon :color="((invoiceCurrentMonth.current.value >= invoiceCurrentMonth.previous.value) ? 'success' : 'danger')" :icon="((invoiceCurrentMonth.current.value > invoiceCurrentMonth.previous.value) ? 'arrow-up' : 'arrow-down')"></mdb-icon>
                                            </template>
                                            {{invoiceCurrentMonth.difference}}%
                                        </span>
                                    </mdb-card-body>



                                    
                                    <mdb-card-body class="pt-0 border-bottom border-light">

                                        <ul>
                                            <li>
                                                <span>Net Sales</span>
                                                <h3 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (invoiceCurrentMonth.current.value > invoiceCurrentMonth.previous.value), 'text-danger' : (invoiceCurrentMonth.current.value < invoiceCurrentMonth.previous.value)}">{{invoiceCurrentMonth.current.value | amount}}</h3>
                                            </li>
                                            <li>
                                                <span>Sales</span>
                                                <h5 class="dark-grey-text text-right">{{invoiceCurrentMonth.current.invoice | amount}}</h5>
                                            </li>
                                            <li>
                                                <span>Return</span>
                                                <h5 class="dark-grey-text text-right">{{invoiceCurrentMonth.current.return | amount}}</h5>
                                            </li>

                                            <li>
                                                <span>Collection</span>
                                                <h5 class="dark-grey-text text-right">{{invoiceCurrentMonth.current.collection | amount}}</h5>
                                            </li>
                                        </ul>

                                    </mdb-card-body>


                                    <div style="height:300px">

                                    <el-table ref="multipleTable" lazy class="card-element-table" :data="invoiceCurrentMonth.data" height="300" stripe >
                                            <el-table-column :sortable="false" label="Name" align="left">
                                                    <template slot-scope="scope">
                                                        <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="false" label="" align="right">
                                                    <template slot-scope="scope">

                                                        <span v-bind:class="{'text-success' : (scope.row.current_value >= scope.row.previous_value),'text-danger' : (scope.row.current_value < scope.row.previous_value)}">
                                                            <template v-if="invoiceCurrentMonth.difference > 0">
                                                                <mdb-icon :color="((scope.row.current_value >= scope.row.previous_value) ? 'success' : 'danger')" :icon="((scope.row.current_value > scope.row.previous_value) ? 'arrow-up' : 'arrow-down')"></mdb-icon>
                                                            </template>
                                                            {{scope.row.difference}}%

                                                    </span>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Curr" align="right" width="80" prop="current_value">
                                                    <template slot-scope="scope">

                                                    <mdb-badge pill :color="(scope.row.current_value > scope.row.previous_value) ?  'success' : 'danger'" class="z-depth-0">{{scope.row.current_value | amount}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Prev" align="right" width="80" prop="previous_value">
                                                    <template slot-scope="scope">
                                                    <mdb-badge pill color="light-blue" class="z-depth-0">{{scope.row.previous_value | amount}}</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                        

                                        
                                        </el-table>

                                    </div>



                                    <div class="data-legend">
                                            <mdb-badge pill :color="'success'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Higher than prev. month
                                        <mdb-badge pill :color="'danger'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Lower than prev. month
                                        </div>
                                    </mdb-card>

                                </mdbCol>
                                <mdbCol lg="3" md="4" sm="12"  class="pl-0 ml-0">

                                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" v-loading="invoiceConversionRateLoading">
                                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                        <h4 class="dark-grey-text mb-0">Conversion Rate</h4>
                                    </mdb-card-body>



                                    
                                    <mdb-card-body class="pt-0 border-bottom border-light" >

                                        <ul>
                                            <li>
                                                <span>Current Month</span>
                                                <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (conversionRate.current.value > conversionRate.previous.value), 'text-danger' : (conversionRate.current.value < conversionRate.previous.value)}">{{conversionRate.current.value}}%</h1>
                                            </li>
                                            <li>
                                                <span>Previous</span>
                                                <h5 class="dark-grey-text text-right">{{conversionRate.previous.value}}%</h5>
                                            </li>
                                        </ul>

                                    </mdb-card-body>


                                    <div style="height:300px">

                                    <el-table ref="multipleTable" lazy class="card-element-table" :data="conversionRate.data" height="300" stripe>
                                            <el-table-column :sortable="false" label="Name" align="left">
                                                    <template slot-scope="scope">
                                                        <a href="javascript:void(0)" @click="openWindowSalesPerson(scope.row)">{{scope.row.name}}</a>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Curr" align="right" width="80" prop="current_value">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill :color="(scope.row.current.value > scope.row.previous.value) ?  'success' : 'danger'" class="z-depth-0">{{scope.row.current_value}}%</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                            <el-table-column :sortable="true" label="Prev" align="right" width="80" prop="previous_value">
                                                    <template slot-scope="scope">
                                                        <mdb-badge pill color="light-blue" class="z-depth-0">{{scope.row.previous_value}}%</mdb-badge>
                                                    </template>
                                                </el-table-column>
                                        </el-table>

                                    </div>

                                    <div class="data-legend">
                                            <mdb-badge pill :color="'success'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Higher than prev. month
                                        <mdb-badge pill :color="'danger'" class="z-depth-0" style="line-height: 11px !important;">&nbsp;</mdb-badge> Lower than prev. month
                                        </div>
                                    </mdb-card>
                                </mdbCol>
                            </mdbRow>


                            

                                    

                                


                                    

                        </div>

                        </template>

                </div>
            </div>
        </div>


    <CustomTableWindow title="Quotations" ref="customTableWindow" :group="(group + 1)" :multiple="false" @open="openQuotation" :width="1024" :height="400" :endpoint="endpoint"></CustomTableWindow>

    <TransactionForm ref="transactionForm" :group="(20000)" @success="closeCustomTable" @payment="closeCustomTable"></TransactionForm>

    <ThatsCustomWindow  title="Sales Person" ref="customWindowForSales" :width="1300" :height="750" windowClass="popup-user-window">

            <div class="dashboard-window">


                <mdb-card class="m-2 dashboard-card">
                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">

                    <div>
                    <h4 class="dark-grey-text mb-0">{{popupUser.name}}</h4>
                    <p class="text-sm">{{popupUser.designation}} - {{popupUser.mobile}}</p>
                    </div>
                    <!-- <mdb-dropdown> 
                        <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"></mdb-dropdown-toggle>
                        <mdb-dropdown-menu class="inactive-reactive-menu">
                            <mdb-dropdown-item @click="viewMyQuotation">View</mdb-dropdown-item>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>  -->
                </mdb-card-body>
             
                </mdb-card>

                <div class="window-block">

                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                        <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                            <h4 class="dark-grey-text mb-0">Number of Open Quotation</h4>
                        </mdb-card-body>
                        
                        <mdb-card-body class="pt-0 border-bottom border-light">

                            <template v-if="popUpQuotationCurrentMonthLoading">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>

                                <ul class="popup-card-number">
                                <li>
                                    <span>Current Month</span>
                                    <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.quotation.current_total > 0),'text-danger' : (popupUser.quotation.current_total <= 0)}">{{popupUser.quotation.current_total}}</h1>
                                    
                                </li>
                                <li>
                                    <span>Previous Month</span>
                                    <h5 class="dark-grey-text text-right">{{popupUser.quotation.previous_total}}</h5>
                                </li>
                                <li>
                                    <span>Total</span>
                                    <h5 class="dark-grey-text text-right">{{popupUser.quotation.all_total}}</h5>
                                </li>
                            </ul>
                            </template>
                        </mdb-card-body>
                    </mdb-card>

                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                        <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                            <h4 class="dark-grey-text mb-0">Number OF INVOICES</h4>


                            <span v-bind:class="{'text-success' : (popupUser.invoice.current.count >= popupUser.invoice.previous.count),'text-danger' : (popupUser.invoice.current.count < popupUser.invoice.previous.count)}">
                                <template v-if="popupUser.invoice.difference > 0">
                                    <mdb-icon :color="((popupUser.invoice.current.count >= popupUser.invoice.previous.count) ? 'success' : 'danger')" :icon="((popupUser.invoice.current.count >= popupUser.invoice.previous.count) ? 'arrow-up' : 'arrow-down')"></mdb-icon>
                                </template>
                                {{popupUser.invoice.difference}}%
                            </span>

                        </mdb-card-body>
                        
                        <mdb-card-body class="pt-0 border-bottom border-light">

                        <template v-if="popUpInvoiceCurrentMonthLoading">
                                <div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                            </template>
                            <template v-else>

                                <ul class="popup-card-number">
                                <li>
                                    <span>Current Month</span>
                                    <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.invoice.current.count > popupUser.invoice.previous.count),'text-danger' : (popupUser.invoice.current.count <= popupUser.invoice.previous.count)}">{{popupUser.invoice.current.count}}</h1>
                                    
                                </li>
                                <li>
                                    <span>Previous Month</span>
                                    <h5 class="dark-grey-text text-right">{{popupUser.invoice.previous.count | amount}}</h5>
                                </li>
                            </ul>
                            </template>
                        </mdb-card-body>
                        </mdb-card>
                    


                    <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                        <h4 class="dark-grey-text mb-0">CONVERSION RATE</h4>
                    </mdb-card-body>
                    
                    <mdb-card-body class="pt-0 border-bottom border-light">

                        <template v-if="popUpInvoiceConversionRateLoading">
                            <div class="d-flex justify-content-center">
                                <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </template>
                        <template v-else>

                            <ul class="popup-card-number">
                            <li>
                                <span>Current Month</span>
                                <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.conversion_rate.current.value > popupUser.conversion_rate.previous.value),'text-danger' : (popupUser.conversion_rate.current.value <= popupUser.conversion_rate.previous.value)}">{{popupUser.conversion_rate.current.value}}%</h1>
                                
                            </li>
                            <li>
                                <span>Previous Month</span>
                                <h5 class="dark-grey-text text-right">{{popupUser.conversion_rate.previous.value}}%</h5>
                            </li>
                        </ul>
                        </template>

                    </mdb-card-body>
                    </mdb-card>

                </div>
                <div class="window-block">
                

                
                
                <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                    <h4 class="dark-grey-text mb-0">VALUE OF OPEN QUOTATION</h4>
                </mdb-card-body>
                
                <mdb-card-body class="pt-0 border-bottom border-light">

                    <template v-if="popUpQuotationCurrentMonthLoading">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>

                        <ul class="popup-card-number">
                            <li>
                                <span>Current Month</span>
                                <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.quotation.current_value > popupUser.quotation.previous_value),'text-danger' : (popupUser.quotation.current_total <= popupUser.quotation.previous_value)}">{{popupUser.quotation.current_value | amount}}</h1>
                                
                            </li>
                            <li>
                                <span>Previous Month</span>
                                <h5 class="dark-grey-text text-right">{{popupUser.quotation.previous_value | amount}}</h5>
                            </li>
                            <li>
                                <span>Total</span>
                                <h5 class="dark-grey-text text-right">{{popupUser.quotation.all_value | amount}}</h5>
                            </li>
                        </ul>
                    </template>
                    

                </mdb-card-body>
                </mdb-card>

          
                <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                    <h4 class="dark-grey-text mb-0">VALUE OF INVOICES</h4>


                    <span v-bind:class="{'text-success' : (popupUser.invoice.current.value >= popupUser.invoice.previous.value),'text-danger' : (popupUser.invoice.current.value < popupUser.invoice.previous.value)}">
                        <template v-if="popupUser.invoice.difference > 0">
                            <mdb-icon :color="((popupUser.invoice.current.value >= popupUser.invoice.previous.value) ? 'success' : 'danger')" :icon="((popupUser.invoice.current.value >= popupUser.invoice.previous.value) ? 'arrow-up' : 'arrow-down')"></mdb-icon>
                        </template>
                         {{popupUser.invoice.difference}}%
                    </span>

                </mdb-card-body>
                
                <mdb-card-body class="pt-0 border-bottom border-light">

                   <template v-if="popUpInvoiceCurrentMonthLoading">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>

                        <ul class="popup-card-number">
                        <li>
                            <span>Current Month</span>
                            <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.invoice.current.value > popupUser.invoice.previous.value),'text-danger' : (popupUser.invoice.current.value <= popupUser.invoice.previous.value)}">{{popupUser.invoice.current.value | amount}}</h1>
                            
                        </li>
                        <li>
                            <span>Previous Month</span>
                            <h5 class="dark-grey-text text-right">{{popupUser.invoice.previous.value | amount}}</h5>
                        </li>
                    </ul>
                    </template>
                </mdb-card-body>
                </mdb-card>


                <mdb-card class="open-quotation-by-sales-person-card m-2 dashboard-card" style="width: 22rem;">
                <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                    <h4 class="dark-grey-text mb-0">CONVERSION RATE</h4>
                </mdb-card-body>
                
                <mdb-card-body class="pt-0 border-bottom border-light">

                    <template v-if="popUpInvoiceConversionRateLoading">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </template>
                    <template v-else>

                        <ul class="popup-card-number">
                        <li>
                            <span>Current Month</span>
                            <h1 class="dark-grey-text text-right mb-0" v-bind:class="{'text-success' : (popupUser.conversion_rate.current.total > popupUser.conversion_rate.previous.total),'text-danger' : (popupUser.conversion_rate.current.total <= popupUser.conversion_rate.previous.total)}">{{popupUser.conversion_rate.current.total}}%</h1>
                            
                        </li>
                        <li>
                            <span>Previous Month</span>
                            <h5 class="dark-grey-text text-right">{{popupUser.conversion_rate.previous.total}}%</h5>
                        </li>
                    </ul>
                    </template>

                </mdb-card-body>
                </mdb-card>

                </div>
            </div>

            <div>
                 <mdb-tabs class="tab-table popup-tab-table" :active="popupWindowActiveTab" default @activeTab="fnPopupWindowActiveTab" :links="[{ text: 'Quotations', slot : 'quotation-slot' },{ text: 'Invoices', slot : 'invoice-slot' }]">
                    <template :slot="'quotation-slot'">
                        <QuotationTable :endpoint="popupQuotationEndpoint"  @open="openQuotation"></QuotationTable>
                    </template>
                    <template :slot="'invoice-slot'">
                        <InvoiceTable :endpoint="popupInvoiceEndpoint"  @open="openInvoice"></InvoiceTable>
                    </template>
                 </mdb-tabs>
            </div>

    </ThatsCustomWindow>

    <ThatsCustomWindow  title="All Quotations" ref="quotationWindowByLocation" :width="1200" :height="650">
        <QuotationByLocationTable :endpoint="popupQuotationEndpointAll"  @open="openQuotation"></QuotationByLocationTable>
    </ThatsCustomWindow>

    <SalesReport ref="salesReport" @open="openDocument"></SalesReport>
    <SalesPersonSalesReport ref="salesPersonSalesReport" @open="openDocument" :tabs="[{ text: 'Quotations', slot : 'quotation-slot' },{ text: 'Invoices', slot : 'invoice-slot' }]"></SalesPersonSalesReport>
    <InvoiceForm ref="invoiceForm" :group="(20000)" ></InvoiceForm>

    </div>
</template>
<script>
import {mdbIcon, mdbCard,mdbCardBody,mdbCardTitle,mdbCardText,mdbView,mdbBtn,mdbTooltip,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,mdbBadge, mdbAlert,mdbTabs, mdbRow, mdbCol} from 'mdbvue';
import { mapActions , mapMutations, mapGetters  } from 'vuex';
import CustomTableWindow from '../components/thats/CustomTableWindow.vue'
import TransactionForm from '../views/transaction/TransactionForm.vue'
import InvoiceForm from '../views/transaction/InvoiceForm.vue'
import ThatsCustomWindow from '../components/thats/CustomWindow.vue'
import QuotationTable from '../components/thats/QuotationTable.vue'
import InvoiceTable from '../components/thats/InvoiceTable.vue'
import QuotationByLocationTable from '../components/thats/QuotationByLocationTable.vue'
import LineGraph from '../components/dashboard/LineGraph.vue'
import { userData } from '../helpers/user';

import SalesReport from '../components/account/SalesReport.vue';
import SalesPersonSalesReport from '../components/dashboard/SalesReport.vue';
export default {
    components : {
        mdbRow, mdbCol,mdbIcon, mdbCard,mdbCardBody,mdbCardTitle,mdbCardText,mdbView,mdbBtn,mdbTooltip,mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,mdbBadge, mdbAlert,mdbTabs,
        CustomTableWindow,
        TransactionForm,
        ThatsCustomWindow,
        QuotationTable,
        InvoiceTable,
        QuotationByLocationTable,
        LineGraph,
        SalesReport,
        InvoiceForm,
        SalesPersonSalesReport
    },
   /*  inject :['userData'], */

   inject : ['showroomsProvider'],
    data(){
        return {
            desktop : [
                {slug : "customer-information",label : "Customer Information",icon : "briefcase",connect_to : "", to : "/"},
                {slug : "items-services",label : "Items & Services",icon : "box",connect_to : "", to : "/"},
                {slug : "locations",label : "Locations",icon : "map-pin",connect_to : "", to : "/"},
                {slug : "dtr-payroll",label : "DTR & Payroll",icon : "stopwatch",connect_to : "", to : "/"},
                {slug : "sales-quotation",label : "Sales & Quotation",icon : "file",connect_to : "sales-invoice next", to : "/"},
                {slug : "sales-invoice",label : "Sales & Invoice",icon : "file-invoice-dollar",connect_to : "payments next", to : "/"},
                {slug : "payment",label : "Payments",icon : "credit-card",connect_to : "", to : "/"},
                {slug : "account-finance",label : "Account/Finance",icon : "chart-line",connect_to : "", to : "/"},
                {slug : "purchase-order",label : "Purchase Order",icon : "file-powerpoint",connect_to : "shipments next", to : "/"},
                {slug : "shipments",label : "Shipments",icon : "ship",connect_to : "purchase-receive next", to : "/"},
                {slug : "purchase-receive",label : "Purchase Receive",icon : "hand-holding",connect_to : "", to : "/"},
                {slug : "reports",label : "Reports",icon : "chart-pie",connect_to : "", to : "/"},
            ],

            quotations : {
                count : 0,
                locations : null,
            },
            quotationCurrentMonth : {
                current : {
                    count : 0,
                    value : 0
                },
                previous : {
                    count : 0,
                    value : 0
                },
                all : {
                    count : 0,
                    value : 0
                },
                data : []
            },

            quotationCurrentPreviousMonth : {
                current : {
                    count : 0,
                    value : 0
                },
                previous : {
                    count : 0,
                    value : 0
                },
                data : []
            },
            invoiceCurrentMonth : {
                current : {
                    count : 0,
                    value : 0,
                    invoice : 0,
                    return : 0
                },
                previous : {
                    count : 0,
                    value : 0,
                    invoice : 0,
                    return : 0
                },
                data : [],
                difference : 0
            },
            quotationLastMonth : {
                count : 0,
                locations : null
            },
            quotationsBySalesperson : {
                count : 0,
                data : []
            },
            conversionRate : {
                current : 0,
                previous : 0,
                data : []
            },
            quotationLocation : {
                current : {
                    count : 0,
                    value : 0
                },
                previous : {
                    count : 0,
                    value : 0
                },
                all : {
                    count : 0,
                    value : 0
                },
                data : [],
            },
            creditNote : {
                total : 0,
                exchange : 0,
                refund : 0,
                data : [],
            },
            group : 20010,
            quotationSalesPeronLoading : false,
            quotationCurrentMonthLoading : false,
            invoiceCurrentMonthLoading : false,
            invoiceConversionRateLoading : false,
            quotationLocationLoading : false,
            graphLoading : false,
            creditNoteLoading : false,
            activeFilter : {
                code : 'all',
                text : 'All Location'
            },

           /*  showrooms : [], */

            allObject : {label : "All Location",code  : "all"},

            endpoint : 'sales/get-my-quotation',


    
            popUpQuotationCurrentMonthLoading : false,
            popUpInvoiceCurrentMonthLoading : false,
            popUpInvoiceConversionRateLoading : false,
            popupUser : {
                name : '',
                designation : '',
                mobile : '',

                quotation : {
                    current_total : 0,
                    previous_total : 0,
                    current_value : 0,
                    previous_vaue : 0,
                },
                invoice : {
                    current : {
                        count : 0,
                        value : 0,
                    },
                    previous : {
                        count : 0,
                        value : 0,
                    },
                    difference : 0
                },
                conversion_rate : {
                    current : 0,
                    previous : 0
                }
            },
            popupInvoiceEndpoint : 'invoices-popup',
            popupQuotationEndpoint : '',
            popupQuotationEndpointAll : '',
            yearToYearMonths : [],
            currentMonth : '',
            chartData: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July'
                ],
                datasets: [
                ]
            },
            toggleLineChart : true,
            popupWindowActiveTab : 0
            
        }
    },
    mounted(){
        const self = this
        // this.getOpenQuotationsBySalesperon().then((response) => {
            //         self.quotationsBySalesperson = response.data
        // });


        var dateObj = new Date();

        var dateFormatOptions = {
        month: 'long',
        year: 'numeric'
        };

        self.currentMonth = dateObj.toLocaleString('en-US', dateFormatOptions)

        for (var i = 0; i < 12; ++i) {

            const d = new Date(dateObj.toLocaleString('en-US', dateFormatOptions));
            let year = d.getFullYear();

            if(year >= 2022){

               if(!self.yearToYearMonths.includes(dateObj.toLocaleString('en-US', dateFormatOptions))){
                    self.yearToYearMonths.push(dateObj.toLocaleString('en-US', dateFormatOptions));
               }
                    dateObj.setMonth(dateObj.getMonth() - 1);
            }
        }


        this.quotationSalesPeronLoading = true;
        this.quotationCurrentMonthLoading = true;
        this.invoiceCurrentMonthLoading = true;
        this.invoiceConversionRateLoading = true;
        this.graphLoading = true;
        this.creditNoteLoading = true;


        if(this.checkSalesExecutive || this.checkSystemSupport){
            this.getOpenQuotations().then((response) => {
                    self.quotations.count = response.data.count
                    self.quotations.locations = response.data.locations
                    self.quotationSalesPeronLoading = false;
            });
        }
      

        this.dashboard()

        // self.showrooms.unshift({ "label": "All Location", "value": 0, "prefix": "all" })
        // console.log(self.userData);

      /*   const userDataOjbect = userData();
        self.GetShowrooms().then((response) =>{
                 self.showrooms = response.filter((item) => {
                     return userDataOjbect.location_prefix.includes(item.prefix)
                 })
        }); */
        self.getInvoicesMonthlySales().then((response) =>{
               self.chartData = response.data
               self.graphLoading = false
        });
        
/* 

chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July'
        ],
        datasets: [
        ]
      },
      */

        

    },
    methods : {
        ...mapActions('DashboardModule',['getOpenQuotations','getOpenQuotationsBySalesperon','getOpenQuotationsCurrentMonth','getOpenQuotationsLastMonth','getInvoicesCurrentMonth','getInvoicesConversionRate','getInvoicesMonthlySales','getOpenQuotationsByLocation',
        "getCreditNotes","getSalesReport"]),

        ...mapActions('TransactionModule',[
        'getMyQuotation'
      ]),
      ...mapMutations('CustomTableModule',{
        customTableModuleSetData : 'setData',
        setCustomTableLoading : 'setLoading'
      }),

      ...mapActions('CustomTableModule',[
            'asyncGetData',
            'resetData',
        ]),
      ...mapMutations('QuotationTableModule',{
        quotationTableModuleSetData : 'setData',
        setQuotationTableLoading : 'setLoading',
        setQuotationTableFilter : 'setFilters',
      }),

      ...mapActions('QuotationTableModule',{
          quotationsTable : 'asyncGetData',
          resetQuotationsTable:  'resetData'
      }),
      ...mapActions('InvoiceTableModule',{
          invoiceTable : 'asyncGetData',
          resetInvoiceTable:  'resetData'
      }),

      ...mapMutations('InvoiceModule',{
            invoiceSetRecord : 'setFormRecord',
            invoiceStatusUpdate : 'setFormStatusUpdate',
            invoiceSetTblKey : 'setTblKey'
        }),
        ...mapActions('InvoiceModule',{
            invoiceGetRecordSeries : 'getRecordSeries',
        }),


      ...mapActions('UserSalesReportModule',{
          getUserSalesReport : 'asyncGetData',
      }),
      ...mapMutations('UserSalesReportModule',{
          setSalesReportFilters : 'setFilters',
      }),
      ...mapActions('TransactionModule',['getRecord']),
      ...mapMutations('TransactionModule',[ 'setFormRecord', 'setFormStatusUpdate','setTblKey']), 
      selectDashboardLocation(location){
            this.activeFilter.code = location.prefix
            this.activeFilter.text = location.label
            this.dispatchSelectedLocation();
      },


      dispatchSelectedLocation(){

          const self = this

            this.quotationSalesPeronLoading = true;
            this.quotationCurrentMonthLoading = true;
            this.invoiceCurrentMonthLoading = true;
            this.invoiceConversionRateLoading = true;
             if(this.checkSalesExecutive || this.checkSystemSupport){
                this.getOpenQuotations({selected : this.activeFilter.code, month : self.currentMonth}).then((response) => {
    
                        self.quotations.count = response.data.count
                        self.quotations.locations = response.data.locations
                        self.quotationSalesPeronLoading = false
                });
             }

            this.getOpenQuotationsCurrentMonth({selected : this.activeFilter.code,month : self.currentMonth}).then((response) => {
                    self.quotationCurrentMonth = response.data;

                    console.log("Response quotation current month")
                    console.log(response)
                    self.quotationCurrentPreviousMonth = response.data
                    self.quotationCurrentMonthLoading = false
            });
            this.getInvoicesCurrentMonth({selected : this.activeFilter.code,month : self.currentMonth}).then((response) => {
                    self.invoiceCurrentMonth = response.data;
                    self.invoiceCurrentMonthLoading = false
            });
            this.getInvoicesConversionRate({selected : this.activeFilter.code,month : self.currentMonth}).then((response) => {
                    self.conversionRate = response.data;
                    self.invoiceConversionRateLoading = false
            });

            this.creditNoteLoading = true;
            self.getCreditNotes({selected : this.activeFilter.code,month : self.currentMonth}).then((response) =>{

                self.creditNote = response.data;
                self.creditNoteLoading = false;
            });


/* 
            this.getInvoicesConversionRate({selected : this.activeFilter.code,month : self.currentMonth}).then((response) => {
                    self.conversionRate = response.data;
                    self.invoiceConversionRateLoading = false
            });
 */
             this.quotationLocationLoading = true;
         
            /* this.getOpenQuotationsByLocation({selected : this.activeFilter.code,month : self.currentMonth}).then((response) => {
                    self.quotationLocation = response.data;
                    self.quotationLocationLoading = false
            }); */
            
      },

      viewMyQuotation(){
          const self = this;
          
        //   this.setCustomTableLoading(true)

        //   this.getMyQuotation().then((response) => {
        //         const customTableData = {
        //             rows : response.data,
        //             columns : response.columns
        //         }
        //          self.customTableModuleSetData(customTableData);
        //           this.setCustomTableLoading(false)
        //   })

// const userDataOjbect = userData();

//           this.resetData(`sales/get-my-quotation/${userDataOjbect.user_id}`);

          self.$refs.salesPersonSalesReport.openDialog();

        //   
        //               self.customTableModuleSetData(customTableData);
        //               self.$refs.customTableWindow.openDialog();
const userDataOjbect = userData();

const userObj  = {
            ...userDataOjbect.user,
            name :userDataOjbect.user.name,
            id : userDataOjbect.user.id,
        }
        console.log("Lorem Ipsum dolor")
        console.log(userObj)
        delete userObj.location;


        const startOfMonth = new Date(this.currentMonth);
        startOfMonth.setDate(1);        

        const endOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0);
        const startDate = startOfMonth.toISOString().split('T')[0];
        const currentDate = new Date().toISOString().split('T')[0];

        let endDate;
        if (startOfMonth.getMonth() === new Date().getMonth()) {
            endDate = currentDate;
        } else {
            endDate = endOfMonth.toISOString().split('T')[0];
        }

        this.getUserSalesReport({
            ...userObj,
            from : startDate,
            to : endDate,
            trigger : true
        });
      },

      viewLocationQuotation(filter){
          const self = this;
          
        //   this.setCustomTableLoading(true)

        //   this.getMyQuotation().then((response) => {
        //         const customTableData = {
        //             rows : response.data,
        //             columns : response.columns
        //         }
        //          self.customTableModuleSetData(customTableData);
        //           this.setCustomTableLoading(false)
        //   })



          this.setQuotationTableFilter(filter);
          this.popupQuotationEndpointAll = `quotation/by-location`;
          this.resetQuotationsTable(`quotation/by-location`);

          self.$refs.quotationWindowByLocation.openDialog();

        //   
        //               self.customTableModuleSetData(customTableData);
        //               self.$refs.customTableWindow.openDialog();

      },
        openDocument(v){
            console.log("openDocument")
            console.log(v)

            const regex = /^[A-Z]+-\d{6}-\d{3}$/;
            if(regex.test(v.value.series)){
                this.openQuotation(v)
            }else{
                this.openInvoice(v.value)
            }
        },
      openQuotation(v){
        //   console.log(v)
        const self = this;
        const form  = {...v.value,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    showroom : {
                                location : null,
                                prefix : null
                            },
                    credit_notes : [],
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    }
                    } 
                    self.setFormStatusUpdate()
                    self.setFormRecord(form)
                    self.getRecord({token_id : v.value.token_id})
                    self.setTblKey(this.transactionTblKey + 1);
                    self.$refs.transactionForm.openDialog();
      },
      openInvoice(obj){
        const self = this;
       const form  = {...obj,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    },
                    creditnotes : [],
                    credit_note_items : [],
                    cancellation : [],
                    cancellation_item : [],
                    refund : [],
                    refund_item : [],
                    exchange : [],
                    credit_memo : [],
                    exchange_item : [],
                    enable_push : false,
                    location : null,
                    } 


                    if(!form.attr.hasOwnProperty('corporate')){
                            form.attr.corporate = false
                        }
                        if(!form.attr.hasOwnProperty('contact_person')){
                            form.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {
                  
                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }
                    self.invoiceStatusUpdate()
                    self.invoiceSetRecord(form)
                    self.invoiceGetRecordSeries({token_id : obj.token_id, series : obj.series})
                    this.invoiceSetTblKey(this.salesTblKey + 1);
                    this.$refs.invoiceForm.openDialog();
      },

      closeCustomTable(){
          this.$refs.customTableWindow.closeDialog();
      },

      openWindowSalesPerson(q){
        //   const self = this;

        //     this.popupUser = {
        //         name : q.name,
        //         designation : q.user.designation,
        //         mobile : q.user.mobile,
        //         quotation : {
        //             current_total : 0,
        //             previous_total : 0,
        //             current_value : 0,
        //             previous_vaue : 0,
        //         },
        //         invoice : {
        //             current : {
        //                 count : 0,
        //                 value : 0
        //             },
        //             previous : {
        //                 count : 0,
        //                 value : 0
        //             },
        //             difference : 0
        //         },
        //         conversion_rate : {
        //             current : 0,
        //             previous : 0
        //         }
        //     }

        //     this.popUpQuotationCurrentMonthLoading = true;
        //     this.popUpInvoiceCurrentMonthLoading = true;
        //     this.popUpInvoiceConversionRateLoading = true;

        //     this.getOpenQuotationsCurrentMonth({user_id : q.id, month : self.currentMonth}).then((response) => {
        //         self.popUpQuotationCurrentMonthLoading = false;
        //         self.popupUser.quotation = {
        //             current_total : response.data.current.count,
        //             previous_total :response.data.previous.count,
        //             all_total :response.data.all.count,
        //             current_value : response.data.current.value,
        //             previous_value : response.data.previous.value,
        //             all_value : response.data.all.value,
        //         }
        //     });
        //     this.getInvoicesCurrentMonth({user_id : q.id, month : self.currentMonth}).then((response) => {
        //         self.popUpInvoiceCurrentMonthLoading = false;
        //          self.popupUser.invoice = {
        //                 current : {
        //                     count : response.data.current.count,
        //                     value : response.data.current.value
        //                 },
        //                 previous : {
        //                     count : response.data.previous.count,
        //                     value : response.data.previous.value,
        //                 },
        //             difference : response.data.difference,
        //         }
        //     });
        //     this.getInvoicesConversionRate({user_id : q.id, month : self.currentMonth}).then((response) => {
        //         self.popUpInvoiceConversionRateLoading = false;
        //          self.popupUser.conversion_rate = {
        //             current : response.data.current,
        //             previous : response.data.previous,
        //         }
        //     });
            
        //     self.popupQuotationEndpoint = `sales/get-my-quotation/${q.id}`
        //     self.popupInvoiceEndpoint = `sales/get-my-invoice/${q.id}`

        //     this.resetData(`sales/get-my-quotation/${q.id}`);
        //     this.resetInvoiceTable(`sales/get-my-invoice/${q.id}`);

        // this.$refs.customWindowForSales.openDialog();

        this.$refs.salesReport.openDialog();

        const userObj  = {
            ...q.user,
            name : q.name,
            id : q.id,
        }
        console.log("Lorem Ipsum dolor")
        console.log(userObj)
        delete userObj.location;


        const startOfMonth = new Date(this.currentMonth);
        startOfMonth.setDate(1);        

        const endOfMonth = new Date(startOfMonth.getFullYear(), startOfMonth.getMonth() + 1, 0);
        const startDate = startOfMonth.toISOString().split('T')[0];
        const currentDate = new Date().toISOString().split('T')[0];

        let endDate;
        if (startOfMonth.getMonth() === new Date().getMonth()) {
            endDate = currentDate;
        } else {
            endDate = endOfMonth.toISOString().split('T')[0];
        }

        this.getUserSalesReport({
            ...userObj,
            from : startDate,
            to : endDate,
            trigger : true
        });
      },


    dashboard(){
          const self = this;


            this.quotationSalesPeronLoading = true;
            this.quotationCurrentMonthLoading = true;
            this.invoiceCurrentMonthLoading = true;
            this.invoiceConversionRateLoading = true;
            this.quotationLocationLoading = true;
            this.creditNoteLoading = true;
            var d = new Date(self.currentMonth);

            var date =d.getFullYear()  + '-' + ('0' + (d.getMonth()+1)).slice(-2) +'-'+ ('0' + d.getDate()).slice(-2);

            this.getOpenQuotationsCurrentMonth({selected : this.activeFilter.code,month : date}).then((response) => {

                // console.log("Response quotation current month")
                //     console.log(response)
                    self.quotationCurrentPreviousMonth = response.data


                    self.quotationCurrentMonth = response.data;
                    self.quotationCurrentMonthLoading = false
            });
            this.getInvoicesCurrentMonth({selected : this.activeFilter.code,month : date}).then((response) => {
                    self.invoiceCurrentMonth = response.data;
                    self.invoiceCurrentMonthLoading = false
            });
            this.getInvoicesConversionRate({selected : this.activeFilter.code,month : date}).then((response) => {
                    self.conversionRate = response.data;
                    self.invoiceConversionRateLoading = false
            });

            self.getCreditNotes({selected : this.activeFilter.code,month : date}).then((response) =>{

                self.creditNote = response.data;
                self.creditNoteLoading = false;
            });

            /* this.getOpenQuotationsByLocation({selected : this.activeFilter.code,month : date}).then((response) => {
                    self.quotationLocation = response.data;
                    self.quotationLocationLoading = false
            }); */
      },

      selectDashboardMonth(date){
          this.currentMonth = date
          this.dashboard()
      },

      valueDifference(a,b){
         return (1 - a / b) * 100;
      },

      fnPopupWindowActiveTab(v){
        this.popupWindowActiveTab = v
      },

      openSalesReport(){
        this.$refs.salesReport.openDialog();
        this.getUserSalesReport();
      }
    },

    computed : {
        ...mapGetters('InvoiceModule',{
            salesTblKey: 'tblKey',
        }),
        ...mapGetters('TransactionModule',{
            transactionTblKey: 'tblKey',
        }),
        showShowroomCard : function(){
          return this.checkSystemSupport || this.checkShowroomManager;
      },

      checkSalesExecutive : function(){
          return this.$helpers.isSalesExecutive();
      },
      checkSystemSupport : function(){
          return this.$helpers.isSystemAdministrator();
      },
      checkShowroomManager : function(){
          return this.$helpers.isShowroomManager();
      },

      showrooms : function(){
        return this.showroomsProvider();
      }
    }
}
</script>
<style scoped lang="scss">

.inactive-reactive-menu.dropdown-menu{
    padding: 0!important;
    max-height: 400px;
    overflow-y: auto;
    background: #fff;
}

.el-table th > .cell{
    font-size: 12px;
    font-weight: 600;
}
</style>