<template>
    <div>
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitMultiStepForm()">
          <el-dialog :modal="false" :fullscreen="false" :title="'New Member'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="true" width="50%" class="text-left loyalty-member-dialog" :class="{ 'custom-z-index': shouldApplyCustomZIndex }">


          <div class="dialog-scroll-container p-3 pt-0">
                    <template v-if="activeTabSlot == 'main'">

                        <div class="container">
                            <el-steps :active="currentStep" align-center>
                                <el-step icon="el-icon-user" title="Select Customer"></el-step>
                                <el-step icon="el-icon-suitcase" title="Loyalty Member Details"></el-step>
                                <el-step icon="el-icon-suitcase" title="Tier Details"></el-step>
                                <el-step icon="el-icon-bank-card" title="Loyalty Card" ></el-step>
                                <el-step icon="el-icon-finished" title="Finish"></el-step>
                            </el-steps>


                            <ValidationObserver v-if="currentStep === 0">

                                <!--    <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                                   <input v-model="formData.customer.name" type="text" placeholder="name">
                                   <span>{{ errors[0] }}</span>
                                   </ValidationProvider> -->

                                <mdb-row>
                                    <mdb-col xl="6" md="6" offset="3">

                                        <mdb-form-inline class="d-flex justify-content-center align-items-center mt-4" tag="div">

                                            <el-input

                                                class="table-searchterm-input"
                                                placeholder="Enter mobile number"
                                                v-model="searchCustomer"
                                                clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important;margin-top: 6px;"  @keydown.enter.prevent="findCustomer" >
                                            </el-input>

                                            <mdb-btn color="elegant" size="sm" @click="findCustomer"><i class="el-icon-search"></i> Search</mdb-btn>
                                            <div class="ml-6">
                                                <mdb-btn v-if="isLoyaltyMembersRoute" color="elegant" size="sm" type="button"  @click="openCustomer">Add New</mdb-btn>
                                            </div>
                                        </mdb-form-inline>

                                        <mdb-card
                                            class="mt-1 z-depth-1"
                                            v-for="(card, index) in customers"
                                            :key="index"
                                        >
                                            <mdb-card-body>
                                                <div class="d-flex justify-content-between align-items-center p-2 pl-3">
                                                    <div class="d-flex align-items-center">
                                                        <el-checkbox v-model="card.selected" @change="onSelect(card, index)" :disabled="card.loyaltyMembership"></el-checkbox>
                                                        <div class="ml-3">
                                                            <strong>{{ card.series }} - {{ card.name }}</strong>
                                                            <p class="mb-0">{{ card.mobile }}</p>

                                                        </div>
                                                    </div>
                                                    <template v-if="card.loyaltyMembership">
                                                        <div class="text-center">
                                                            <p>Already a Member</p>
                                                            <mdb-btn color="elegant" size="sm" type="button" @click="viewMember(card)">View</mdb-btn>
                                                        </div>
                                                    </template>

                                                </div>
                                            </mdb-card-body>
                                        </mdb-card>


                                        <mdb-form-inline class="d-flex justify-content-end align-items-center mt-4" tag="div">

                                            <template v-if="hasSelectedCustomer">
                                                <mdb-btn color="elegant" size="sm" @click="onSubmitMultiStepForm">Next</mdb-btn>
                                            </template>

                                        </mdb-form-inline>
                                    </mdb-col>
                                </mdb-row>
                            </ValidationObserver>
                            <ValidationObserver v-if="currentStep === 1">
                                <div class="card card-fieldset mt-1">
                                    <div class="card-body">
<!--                                        <mdb-row>-->
<!--                                            -->
<!--                                        </mdb-row>-->
                                        <mdb-row>
                                          <mdb-col md="3" class="mb-3">
                                            <ThatsMultiSelect :multiple="false" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Title"
                                                              v-model="formData.customer.attr.title" :reduce="label => label.code" :reduceLabel="'label'"
                                                              :options="titles" :rules="'required'" :show_error="true" :showLabel="true">
                                            </ThatsMultiSelect>
                                          </mdb-col>
                                            <mdb-col md="9" class="mb-3">
                                                <ThatsInput labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Full Name" vid="full_name"
                                                            name="full_name" v-model="formData.customer.attr.full_name" :validated="validated" :rules="'required'" :show_error="true" :showLabel="true"
                                                            size="sm" />
                                            </mdb-col>
                                          <p>As per official documents</p>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col md="12" class="mb-3">
                                                <ThatsInput labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="QID / Passport" vid="qid"
                                                            name="qid" v-model="formData.customer.attr.qid" :validated="validated" :rules="'required'" :show_error="true" :showLabel="true"
                                                            size="sm" />
                                            </mdb-col>
                                            <mdb-col md="12" class="mb-3">
                                                <ThatsMultiSelect :multiple="false" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Nationality"
                                                                  v-model="formData.customer.attr.nationality" :reduce="label => label.code" :reduceLabel="'label'"
                                                                  :options="nations" :rules="'required'" :show_error="true" :showLabel="true">
                                                </ThatsMultiSelect>

                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col md="6" class="mb-3">
                                                <ThatsMultiSelect @input="setSelected" :multiple="false" labelClass="col-md-6" fieldClass="col-md-6 pl-0" label="Mobile Number"
                                                                  v-model="formData.customer.attr.contacts[0].code" append-to-body :reduce="reduce"
                                                                  :options="options" @search="onSearch" :filterable="filterable" :clearable="clearable"
                                                                  :disabled="disabled" :selectable="selectable">
                                                </ThatsMultiSelect>
                                            </mdb-col>
                                            <mdb-col md="6" class="mb-3">
                                                <ThatsInput
                                                    labelClass="col-md-0 mb-0"
                                                    fieldClass="col-md-12 pl-0 mb-0"
                                                    label="Mobile"
                                                    vid="mobile"
                                                    name="mobile"
                                                    v-model="formData.customer.mobile"
                                                    :validated="validated"
                                                    :rules="{ required: true, maxDigits: digitCount }"
                                                    size="sm"
                                                    :show_error="true"
                                                    :showLabel="false"
                                                />
                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col md="12" class="mb-3">
                                                <ThatsInput labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Email" vid="email" name="email"
                                                          v-model="formData.customer.email" :validated="validated" :rules="'required|email'" :show_error="true" :showLabel="true" size="sm" />
                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>


                                            <mdb-col md="12" class="mb-3">
                                                <ThatsDatePicker tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Date of birth"
                                                                 placeholder="Date of birth"  v-model="formData.customer.attr.dob"
                                                                 :rules="{ required: true, minAge: true }" size="sm" :show_error="true" :showLabel="true"/>


                                            </mdb-col>
                                            <mdb-col md="12" class="mb-3">
                                                <ThatsMultiSelect :multiple="false" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Gender"
                                                                  v-model="formData.customer.attr.gender" :reduce="label => label.code" :reduceLabel="'label'"
                                                                  :options="genders" :rules="'required'" :show_error="true" :showLabel="true">
                                                </ThatsMultiSelect>
                                            </mdb-col>

                                        </mdb-row>






                                        <mdb-row>
                                            <mdb-col md="12" class="mb-3">
                                                <ThatsMultiSelect :multiple="false" @input="setMaritalSelected" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Marital Status"
                                                                  v-model="formData.customer.attr.marital_status" :reduce="label => label.code" :reduceLabel="'label'"
                                                                  :options="maritals" :rules="'required'" :show_error="true" :showLabel="true">
                                                </ThatsMultiSelect>
                                            </mdb-col>
                                            <mdb-col md="12" class="mb-3" v-if="display == true">
                                                <ThatsDatePicker tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Anniversary Date"
                                                                 placeholder="Anniversary Date" vid="anniversary_date" name="anniversary_date" v-model="formData.customer.attr.anniversary_date"
                                                                 size="sm" :show_error="true" :showLabel="true"/>
                                            </mdb-col>
                                        </mdb-row>


                                        <mdb-row>
                                            <mdb-col lg="9" md="9" sm="12" offset-lg="3" offset-sm="3" class="mt-1 pl-0">
                                                <ImageUpload v-model="formData.customer.attr.images" :size="'image-full'"/>
                                            </mdb-col>
                                        </mdb-row>
                                        

                                      



                                    </div>

                                    <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">
                                        <template>
                                            <mdb-btn color="elegant" size="sm" @click="backToPrevious">Back</mdb-btn>
                                            <template>
                                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitMultiStepForm)">Next</mdb-btn>
                                            </template>
                                        </template>
                                    </mdb-form-inline>
                                </div>
                            </ValidationObserver>
                            <ValidationObserver v-if="currentStep === 2">
                                <mdb-row>
                                    <mdb-col xl="6" md="6" offset="3">
                                        <div class="mt-4">

                                            <mdb-card class="classic-admin-card mt-1"  v-for="(tier,index) in tiers" :key="index">
                                                <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                                    <el-checkbox v-model="tier.selected" @change="onSelectTier(tier,index)" :disabled="((formData.customer.points >= tier.minimum && formData.customer.points <= tier.maximum) || (tier.maximum === -1 && formData.customer.points >= tier.minimum) ? false : true)"></el-checkbox>
                                                    <div >
                                                        <img :src="tier.attr.images" class="loyalty-card-image pl-3"/>
                                                    </div>

                                                    <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                                        <strong>{{ tier.series }}</strong> <br />
                                                        <strong>{{ tier.name }}</strong> <br />
                                                        <strong>Minimum {{ tier.minimum | thousands}} Qualified Points in 12 months</strong> <br />

                                                    </div>


                                                </mdb-card-body>
                                            </mdb-card>
                                        </div>


                                        <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">

                                            <template v-if="hasSelectedCustomer">
                                                <mdb-btn color="elegant" size="sm" @click="backToPrevious">Back</mdb-btn>

                                                <template v-if="hasSelectedTier">
                                                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitMultiStepForm)">Next</mdb-btn>
                                                </template>
                                            </template>

                                        </mdb-form-inline>
                                    </mdb-col>
                                </mdb-row>
                            </ValidationObserver>


                            <ValidationObserver v-if="currentStep === 3">
                                <mdb-row>
                                    <mdb-col xl="6" md="6" offset="3">
                                        <div class="mt-4">

                                            <mdb-card class="classic-admin-card mt-1"  v-for="(card,index) in filteredCards" :key="index">
                                                <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                                    <el-checkbox v-model="card.selected" @change="onSelectLoyaltyCard(card,index)"></el-checkbox>

                                                    <div >
                                                        <img :src="card.attr.images" class="loyalty-card-image pl-3"/>
                                                    </div>

                                                    <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                                        <strong>{{ card.name }}</strong> <br />
                                                        <strong>{{ card.description }}</strong>
                                                    </div>
                                                </mdb-card-body>

                                            </mdb-card>
                                        </div>


                                        <mdb-card class="mt-4">
                                            <mdb-card-body>
<!--                                                <ThatsInput labelClass="col-md-3" label="Card Number" placeholder="Card Number" fieldClass="col-md-9"  vid="card.card_number" name="card.card_number" :show_error="true" :showLabel="true" v-model="formData.card.card_number"  size="sm" />-->
                                                <ThatsInput labelClass="col-md-3" label="Display Name" placeholder="Display Name" fieldClass="col-md-9"  vid="display_name" name="display_name" :show_error="true" :showLabel="true" v-model="formData.card.display_name" :rules="'required'" size="sm" />
                                            </mdb-card-body>
                                        </mdb-card>

                                        <mdb-form-inline class="d-flex justify-content-between align-items-center mt-4" tag="div">

                                            <template v-if="hasSelectedCustomer">
                                                <mdb-btn color="elegant" size="sm" @click="backToPrevious">Back</mdb-btn>

                                                <template v-if="hasSelectedCard">
                                                    <mdb-btn color="elegant" size="sm" @click="onSubmitMultiStepForm">Next</mdb-btn>
                                                </template>
                                            </template>

                                        </mdb-form-inline>
                                    </mdb-col>
                                </mdb-row>
                            </ValidationObserver>


                        </div>

                        <!--   <mdb-row>
                              <mdb-col xl="12" md="12">
                                  <section class="card profile-card mb-4 text-center">
                                      <div class="avatar z-depth-1-half grey lighten-5">
                                      <mdb-icon class="dark-grey-text" far icon="user" style=" font-size: 42px; margin: 38px 41px; "/>
                                      </div>

                                      <div class="card-body">
                                      <h4 class="card-title"><strong>{{ formData.display_name }}</strong></h4>
                                      <h5>{{ formData.customer.mobile }}</h5>
                                      <p class="dark-grey-text">Member since : {{ formData.issued_date }}</p>
                                      </div>
                                  </section>
                              </mdb-col>
                          </mdb-row>


                          <mdb-row>
                              <mdb-col xl="4" md="4" class="mb-4">
                                  <mdb-card color="warning-color" class="classic-admin-card">
                                              <mdb-card-body>
                                          <div class="pull-right">
                                              <i class="fas fa-file"></i>
                                          </div>
                                          <p class="white-text">QUOTATIONS</p>
                                          <h4 class="text-right">0</h4>
                                      </mdb-card-body>

                                  </mdb-card>

                      </mdb-col>

                              <mdb-col xl="4" md="4" class="mb-4">
                              <mdb-card color="light-blue" class="classic-admin-card lighten-1">
                                  <mdb-card-body>
                                      <div class="pull-right">
                                      <i class="fas fa-file-invoice-dollar"></i>
                                  </div>
                                  <p class="white-text">INVOICES</p>
                                  <h4 class="text-right">0</h4>

                                  <mdb-progress :height="15" :value="25" color="danger">25%</mdb-progress>

                              </mdb-card-body>

                          </mdb-card>

                      </mdb-col>
                  </mdb-row>

                          <mdb-row>
                              <mdb-col xl="12" md="12">

                                  <mdb-card>
                                      <mdb-card-body class="p-0">
                                          <CardTable :endpoint="'loyalty-program/transactions'" :title="'Transactions'"></CardTable>
                                      </mdb-card-body>
                                  </mdb-card>
                              </mdb-col>
                          </mdb-row>


   -->
                    </template>
                </div>


                <template slot="footer">

                    <div class="d-flex">

                        <template v-if="formLoading">
                            <mdb-btn color="elegant" size="sm" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </mdb-btn>
                        </template>

                        <template v-else>
                            <!--  <template v-if="currentStep > 0">
                                 <mdb-btn color="elegant" size="sm" type="button" @click="backToPrevious"><i class="el-icon-save text-white cursor-pointer"></i> Previous</mdb-btn>
                             </template>
                         <mdb-btn color="elegant" size="sm" @click="passes(onSubmitMultiStepForm)"><i class="el-icon-save text-white cursor-pointer"></i> Next</mdb-btn> -->



                            <template v-if="formStatus == 'update'">
                                <mdb-btn color="elegant" size="sm" @click="openHistoryDialog"><i class="el-icon-save text-white cursor-pointer"></i> History</mdb-btn>
                            </template>
                            <mdb-btn color="grey" size="sm" @click="closeModelWindow"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                        </template>


                    </div>
                </template>
            </el-dialog>
        </ValidationObserver>
        <HistoryDialog ref="historyDialog"></HistoryDialog>
        <ContactsForm ref="contactForm" :group="3" @success="contactSuccess" @update="contactUpdateSuccess" class="contacts-form-container"></ContactsForm>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate';




import {
    mdbBtn,
    mdbContainer,
    mdbSelect,
    mdbFormInline,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbCarousel,
    mdbView,
    mdbCard,
    mdbCardBody,
    mdbCardText,
    mdbCardTitle,
    mdbCardImage,
    mdbTabs,
    mdbNavbar,
    mdbNavbarBrand,
    mdbProgress,
    mdbInput
} from "mdbvue";
import { ThatsMultiSelect,ThatsCustomMultiSelect,ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect, ThatsDatePicker, ImageUpload} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import HistoryDialog from "../../components/thats/HistoryDialog.vue";
import CardTable from "./CardTable.vue"
import InternationalMobile from "../../components/thats/InternationalMobile.vue";

import { extend } from 'vee-validate';
import ContactsForm from "../contacts/ContactsForm.vue";

extend('maxDigits', (value,digitCount) => {
    if (value && value.length <= digitCount) {
        return true;
    }
    return `Mobile number must be ${digitCount} digits or less`;
})

extend('minAge', (value) => {
  const dob = new Date(value);
  const currentDate = new Date();

  if (!isNaN(dob) && dob < currentDate) {
    const age = currentDate.getFullYear() - dob.getFullYear();

    if (age >= 18) {
      return true;
    }
  }

  return 'Age must be 18 or older';
});



export default {
    components : {
        ImageUpload,
        ContactsForm,
        ThatsCustomMultiSelect,
        mdbInput,
        ThatsMultiSelect,
        InternationalMobile,
        HistoryDialog,
        mdbBtn,
        mdbTabs,
        mdbView,
        mdbNavbar,
        mdbNavbarBrand,
        mdbContainer,
        mdbSelect,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardBody,
        mdbCardImage,
        mdbCardText,
        mdbCardTitle,
        mdbProgress ,
        ValidationObserver,
        ValidationProvider,
        mdbFormInline,
        CardTable,
        ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect,ThatsDatePicker

    },

    props : {
        group : {
            type : Number,
            default : 1
        },
        reduce :{
            type : Function,
            default: option => option.code,
        },
        reduceLabel :{
            type: String,
            default: "label"
        },
        show_error: {
            type: Boolean,
            default : false
        },
        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled : {
            type : Boolean,
            default : false
        },
        selectable: {
            type : Function,
            default: option => option,
        },

    },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    data(){
        return {
            syncModal : false,
            currentRoutePath: this.$route.path,
            tableKey : 0,
            activeTabIndex : 0,
            activeTabObject : { text: 'Details', tabSlot : 'main' },
            mdbTabsData : [
                { text: 'Details', tabSlot : 'main' }
            ],
            display:false,
            group2 : 5,
            digitCount: 0,
            formData : null,
            formLoading : false,
            readOnly : false,
            showError : true,
            validated : true,
            currentStep : 0,
            searchCustomer : null,
            customers : [],
            filteredCards : [],
            cards : [],
            tiers : [],
            genders : [
                { label : "Male", code : "male"},
                { label : "Female", code : "female"}
            ],
            maritals : [
                { label : "Single", code : "Single"},
                { label : "Married", code : "Married"}
            ],
            titles : [
                { label : "Ms.", code : "Ms"},
                { label : "Mr.", code : "Mr"},
                { label : "Mrs.", code : "Mrs"},
                { label : "Sheikh", code : "Sheikh"},
                { label : "Sheikha", code : "Sheikha"}
            ],
            corportKey : 0,

            inputValue : {
                intl : false,
                code : null,
                value : null
            },
            maxMobileLength:8,
            showCard: false,
            getImage: "",
            selectedImage: null,

            options : [
                {label : "Afghanistan (+93)",code : "+93",digit : 9},
                {label : "Albania (+355)",code : "+355",digit : 9},
                {label : "Algeria (+213)",code : "+213",digit : 9},
                {label : "American Samoa (+1)",code : "+1",digit : 7},
                {label : "Andorra (+376)",code : "+376",digit : 9},
                {label : "Angola (+244)",code : "+244",digit : 9},
                {label : "Anguilla (+1)",code : "+1",digit : 7},
                {label : "Antigua and Barbuda (+1)",code : "+1",digit : 7},
                {label : "Argentina (+54)",code : "+54",digit : 10},
                {label : "Armenia (+374)",code : "+374",digit : 8},
                {label : "Aruba (+297)",code : "+297",digit : 7},
                {label : "Australia (+61)",code : "+61",digit : 15},
                {label : "Australian External Territories (+672)",code : "+672",digit : 6},
                {label : "Austria (+43)",code : "+43",digit : 13},
                {label : "Azerbaijan (+994)",code : "+994",digit : 9},
                {label : "Bahamas (+1)",code : "+1",digit : 7},
                {label : "Bahrain (+973)",code : "+973",digit : 8},
                {label : "Bangladesh (+880)",code : "+880",digit : 10},
                {label : "Barbados (+1)",code : "+1",digit : 7},
                {label : "Belarus (+375)",code : "+375",digit : 10},
                {label : "Belgium (+32)",code : "+32",digit : 9},
                {label : "Belize (+501)",code : "+501",digit : 7},
                {label : "Benin (+229)",code : "+229",digit : 8},
                {label : "Bermuda (+1)",code : "+1",digit : 7},
                {label : "Bhutan (+975)",code : "+975",digit : 8},
                {label : "Bolivia (Plurinational State of) (+591)",code : "+591",digit : 8},
                {label : "Bonaire, Sint Eustatius and Saba (+599)",code : "+599",digit : 7},
                {label : "Bosnia and Herzegovina (+387)",code : "+387",digit : 8},
                {label : "Botswana (+267)",code : "+267",digit : 8},
                {label : "Brazil (+55)",code : "+55",digit : 10},
                {label : "British Virgin Islands (+1)",code : "+1",digit : 7},
                {label : "Brunei Darussalam (+673)",code : "+673",digit : 7},
                {label : "Bulgaria (+359)",code : "+359",digit : 9},
                {label : "Burkina Faso (+226)",code : "+226",digit : 8},
                {label : "Burundi (+257)",code : "+257",digit : 8},
                {label : "Cambodia (+855)",code : "+855",digit : 8},
                {label : "Cameroon (+237)",code : "+237",digit : 8},
                {label : "Canada (+1)",code : "+1",digit : 10},
                {label : "Cape Verde (+238)",code : "+238",digit : 7},
                {label : "Cayman Islands (+1)",code : "+1",digit : 7},
                {label : "Central African Rep. (+236)",code : "+236",digit : 8},
                {label : "Chad (+235)",code : "+235",digit : 8},
                {label : "Chile (+56)",code : "+56",digit : 9},
                {label : "China (+86)",code : "+86",digit : 12},
                {label : "Colombia (+57)",code : "+57",digit : 10},
                {label : "Comoros (+269)",code : "+269",digit : 7},
                {label : "Congo (+242)",code : "+242",digit : 9},
                {label : "Cook Islands (+682)",code : "+682",digit : 5},
                {label : "Costa Rica (+506)",code : "+506",digit : 8},
                {label : "Côte d'Ivoire (+225)",code : "+225",digit : 8},
                {label : "Croatia (+385)",code : "+385",digit : 12},
                {label : "Cuba (+53)",code : "+53",digit : 8},
                {label : "Curaçao (+599)",code : "+599",digit : 8},
                {label : "Cyprus (+357)",code : "+357",digit : 11},
                {label : "Czech Rep. (+420)",code : "+420",digit : 12},
                {label : "Dem. People's Rep. of Korea (+850)",code : "+850",digit : 17},
                {label : "Dem. Rep. of the Congo (+243)",code : "+243",digit : 9},
                {label : "Denmark (+45)",code : "+45",digit : 8},
                {label : "Diego Garcia (+246)",code : "+246",digit : 7},
                {label : "Djibouti (+253)",code : "+253",digit : 6},
                {label : "Dominica (+1)",code : "+1",digit : 7},
                {label : "Dominican Rep. (+1)",code : "+1",digit : 7},
                {label : "Ecuador (+593)",code : "+593",digit : 8},
                {label : "Egypt (+20)",code : "+20",digit : 9},
                {label : "El Salvador (+503)",code : "+503",digit : 11},
                {label : "Equatorial Guinea (+240)",code : "+240",digit : 9},
                {label : "Eritrea (+291)",code : "+291",digit : 7},
                {label : "Estonia (+372)",code : "+372",digit : 10},
                {label : "Ethiopia (+251)",code : "+251",digit : 9},
                {label : "Falkland Islands (Malvinas) (+500)",code : "+500",digit : 5},
                {label : "Faroe Islands (+298)",code : "+298",digit : 6},
                {label : "Fiji (+679)",code : "+679",digit : 7},
                {label : "Finland (+358)",code : "+358",digit : 12},
                {label : "France (+33)",code : "+33",digit : 9},
                {label : "French Dep. and Territories in the Indian Ocean (+262)",code : "+262",digit : 9},
                {label : "French Guiana (+594)",code : "+594",digit : 9},
                {label : "French Polynesia (+689)",code : "+689",digit : 6},
                {label : "Gabon (+241)",code : "+241",digit : 7},
                {label : "Gambia (+220)",code : "+220",digit : 7},
                {label : "Georgia (+995)",code : "+995",digit : 9},
                {label : "Germany (+49)",code : "+49",digit : 13},
                {label : "Ghana (+233)",code : "+233",digit : 9},
                {label : "Gibraltar (+350)",code : "+350",digit : 8},
                {label : "Global Mobile Satellite System (GMSS), shared (+881)",code : "+881",digit : 8},
                {label : "Greece (+30)",code : "+30",digit : 10},
                {label : "Greenland (+299)",code : "+299",digit : 6},
                {label : "Grenada (+1)",code : "+1",digit : 7},
                {label : "Group of countries, shared code (+388)",code : "+388",digit : 8},
                {label : "Guadeloupe (+590)",code : "+590",digit : 9},
                {label : "Guam (+1)",code : "+1",digit : 7},
                {label : "Guatemala (+502)",code : "+502",digit : 8},
                {label : "Guinea (+224)",code : "+224",digit : 8},
                {label : "Guinea-Bissau (+245)",code : "+245",digit : 7},
                {label : "Guyana (+592)",code : "+592",digit : 7},
                {label : "Haiti (+509)",code : "+509",digit : 8},
                {label : "Honduras (+504)",code : "+504",digit : 8},
                {label : "Hong Kong, China (+852)",code : "+852",digit : 9},
                {label : "Hungary (+36)",code : "+36",digit : 9},
                {label : "Iceland (+354)",code : "+354",digit : 9},
                {label : "India (+91)",code : "+91",digit : 10},
                {label : "Indonesia (+62)",code : "+62",digit : 10},
                {label : "Inmarsat SNAC (+870)",code : "+870",digit : 9},
                {label : "International Freephone Service (+800)",code : "+800",digit : 8},
                {label : "International Networks, shared code (+882)",code : "+882",digit : 8},
                {label : "International Networks, shared code (+883)",code : "+883",digit : 8},
                {label : "International Premium Rate Service (IPRS) (+979)",code : "+979",digit : 9},
                {label : "International Shared Cost Service (ISCS) (+808)",code : "+808",digit : 8},
                {label : "Iran (Islamic Republic of) (+98)",code : "+98",digit : 10},
                {label : "Iraq (+964)",code : "+964",digit : 10},
                {label : "Ireland (+353)",code : "+353",digit : 11},
                {label : "Israel (+972)",code : "+972",digit : 8},
                {label : "Italy (+39)",code : "+39",digit : 11},
                {label : "Jamaica (+1)",code : "+1",digit : 7},
                {label : "Japan (+81)",code : "+81",digit : 13},
                {label : "Jordan (+962)",code : "+962",digit : 9},
                {label : "Kazakhstan (+7)",code : "+7",digit : 10},
                {label : "Kenya (+254)",code : "+254",digit : 10},
                {label : "Kiribati (+686)",code : "+686",digit : 5},
                {label : "Korea (Rep. of) (+82)",code : "+82",digit : 11},
                {label : "Kuwait (+965)",code : "+965",digit : 8},
                {label : "Kyrgyzstan (+996)",code : "+996",digit : 9},
                {label : "Lao P.D.R. (+856)",code : "+856",digit : 10},
                {label : "Latvia (+371)",code : "+371",digit : 8},
                {label : "Lebanon (+961)",code : "+961",digit : 8},
                {label : "Lesotho (+266)",code : "+266",digit : 8},
                {label : "Liberia (+231)",code : "+231",digit : 8},
                {label : "Libya (+218)",code : "+218",digit : 9},
                {label : "Liechtenstein (+423)",code : "+423",digit : 9},
                {label : "Lithuania (+370)",code : "+370",digit : 8},
                {label : "Luxembourg (+352)",code : "+352",digit : 11},
                {label : "Macao, China (+853)",code : "+853",digit : 8},
                {label : "Madagascar (+261)",code : "+261",digit : 10},
                {label : "Malawi (+265)",code : "+265",digit : 8},
                {label : "Malaysia (+60)",code : "+60",digit : 9},
                {label : "Maldives (+960)",code : "+960",digit : 7},
                {label : "Mali (+223)",code : "+223",digit : 8},
                {label : "Malta (+356)",code : "+356",digit : 8},
                {label : "Marshall Islands (+692)",code : "+692",digit : 7},
                {label : "Martinique (+596)",code : "+596",digit : 9},
                {label : "Mauritania (+222)",code : "+222",digit : 7},
                {label : "Mauritius (+230)",code : "+230",digit : 7},
                {label : "Mexico (+52)",code : "+52",digit : 10},
                {label : "Micronesia (+691)",code : "+691",digit : 7},
                {label : "Moldova (Republic of) (+373)",code : "+373",digit : 8},
                {label : "Monaco (+377)",code : "+377",digit : 9},
                {label : "Mongolia (+976)",code : "+976",digit : 8},
                {label : "Montenegro (+382)",code : "+382",digit : 12},
                {label : "Montserrat (+1)",code : "+1",digit : 7},
                {label : "Morocco (+212)",code : "+212",digit : 9},
                {label : "Mozambique (+258)",code : "+258",digit : 9},
                {label : "Myanmar (+95)",code : "+95",digit : 9},
                {label : "Namibia (+264)",code : "+264",digit : 10},
                {label : "Nauru (+674)",code : "+674",digit : 7},
                {label : "Nepal (+977)",code : "+977",digit : 9},
                {label : "Netherlands (+31)",code : "+31",digit : 9},
                {label : "New Caledonia (+687)",code : "+687",digit : 6},
                {label : "New Zealand (+64)",code : "+64",digit : 10},
                {label : "Nicaragua (+505)",code : "+505",digit : 8},
                {label : "Niger (+227)",code : "+227",digit : 8},
                {label : "Nigeria (+234)",code : "+234",digit : 10},
                {label : "Niue (+683)",code : "+683",digit : 4},
                {label : "Northern Marianas (+1)",code : "+1",digit : 7},
                {label : "Norway (+47)",code : "+47",digit : 8},
                {label : "Oman (+968)",code : "+968",digit : 8},
                {label : "Pakistan (+92)",code : "+92",digit : 11},
                {label : "Palau (+680)",code : "+680",digit : 7},
                {label : "Panama (+507)",code : "+507",digit : 8},
                {label : "Papua New Guinea (+675)",code : "+675",digit : 11},
                {label : "Paraguay (+595)",code : "+595",digit : 9},
                {label : "Peru (+51)",code : "+51",digit : 11},
                {label : "Philippines (+63)",code : "+63",digit : 10},
                {label : "Poland (+48)",code : "+48",digit : 9},
                {label : "Portugal (+351)",code : "+351",digit : 11},
                {label : "Puerto Rico (+1)",code : "+1",digit : 7},
                {label : "Qatar (+974)",code : "+974",digit : 8},
                {label : "Romania (+40)",code : "+40",digit : 9},
                {label : "Russian Federation (+7)",code : "+7",digit : 10},
                {label : "Rwanda (+250)",code : "+250",digit : 9},
                {label : "Saint Helena, Ascension and Tristan da Cunha (+247)",code : "+247",digit : 4},
                {label : "Saint Helena, Ascension and Tristan da Cunha (+290)",code : "+290",digit : 4},
                {label : "Saint Kitts and Nevis (+1)",code : "+1",digit : 7},
                {label : "Saint Lucia (+1)",code : "+1",digit : 7},
                {label : "Saint Pierre and Miquelon (+508)",code : "+508",digit : 6},
                {label : "Saint Vincent and the Grenadines (+1)",code : "+1",digit : 7},
                {label : "Samoa (+685)",code : "+685",digit : 7},
                {label : "San Marino (+378)",code : "+378",digit : 10},
                {label : "Sao Tome and Principe (+239)",code : "+239",digit : 7},
                {label : "Saudi Arabia (+966)",code : "+966",digit : 9},
                {label : "Senegal (+221)",code : "+221",digit : 9},
                {label : "Serbia (+381)",code : "+381",digit : 12},
                {label : "Seychelles (+248)",code : "+248",digit : 7},
                {label : "Sierra Leone (+232)",code : "+232",digit : 8},
                {label : "Singapore (+65)",code : "+65",digit : 12},
                {label : "Sint Maarten (Dutch part) (+1)",code : "+1",digit : 7},
                {label : "Slovakia (+421)",code : "+421",digit : 9},
                {label : "Slovenia (+386)",code : "+386",digit : 8},
                {label : "Solomon Islands (+677)",code : "+677",digit : 5},
                {label : "Somalia (+252)",code : "+252",digit : 8},
                {label : "South Africa (+27)",code : "+27",digit : 9},
                {label : "South Sudan (+211)",code : "+211",digit : 8},
                {label : "Spain (+34)",code : "+34",digit : 9},
                {label : "Sri Lanka (+94)",code : "+94",digit : 9},
                {label : "Sudan (+249)",code : "+249",digit : 9},
                {label : "Suriname (+597)",code : "+597",digit : 7},
                {label : "Swaziland (+268)",code : "+268",digit : 8},
                {label : "Sweden (+46)",code : "+46",digit : 13},
                {label : "Switzerland (+41)",code : "+41",digit : 12},
                {label : "Syrian Arab Republic (+963)",code : "+963",digit : 10},
                {label : "Taiwan, China (+886)",code : "+886",digit : 9},
                {label : "Tajikistan (+992)",code : "+992",digit : 9},
                {label : "Tanzania (+255)",code : "+255",digit : 9},
                {label : "Telecommunications for Disaster Relief (TDR) (+888)",code : "+888",digit : 8},
                {label : "Thailand (+66)",code : "+66",digit : 9},
                {label : "The Former Yugoslav Republic of Macedonia (+389)",code : "+389",digit : 8},
                {label : "Timor-Leste (+670)",code : "+670",digit : 7},
                {label : "Togo (+228)",code : "+228",digit : 8},
                {label : "Tokelau (+690)",code : "+690",digit : 4},
                {label : "Tonga (+676)",code : "+676",digit : 7},
                {label : "Trial of a proposed new international service (+991)",code : "+991",digit : 8},
                {label : "Trinidad and Tobago (+1)",code : "+1",digit : 7},
                {label : "Tunisia (+216)",code : "+216",digit : 8},
                {label : "Turkey (+90)",code : "+90",digit : 10},
                {label : "Turkmenistan (+993)",code : "+993",digit : 8},
                {label : "Turks and Caicos Islands (+1)",code : "+1",digit : 7},
                {label : "Tuvalu (+688)",code : "+688",digit : 6},
                {label : "Uganda (+256)",code : "+256",digit : 9},
                {label : "Ukraine (+380)",code : "+380",digit : 9},
                {label : "United Arab Emirates (+971)",code : "+971",digit : 9},
                {label : "United Kingdom (+44)",code : "+44",digit : 10},
                {label : "United States (+1)",code : "+1",digit : 10},
                {label : "United States Virgin Islands (+1)",code : "+1",digit : 7},
                {label : "Universal Personal Telecommunication (UPT) (+878)",code : "+878",digit : 8},
                {label : "Uruguay (+598)",code : "+598",digit : 11},
                {label : "Uzbekistan (+998)",code : "+998",digit : 9},
                {label : "Vanuatu (+678)",code : "+678",digit : 7},
                {label : "Vatican (+379)",code : "+379",digit : 8},
                {label : "Vatican (+39)",code : "+39",digit : 11},
                {label : "Venezuela (Bolivarian Republic of) (+58)",code : "+58",digit : 10},
                {label : "Viet Nam (+84)",code : "+84",digit : 10},
                {label : "Wallis and Futuna (+681)",code : "+681",digit : 6},
                {label : "Yemen (+967)",code : "+967",digit : 9},
                {label : "Zambia (+260)",code : "+260",digit : 9},
                {label : "Zimbabwe (+263)",code : "+263",digit : 10},
            ],
            nations : [
                {
                    code: "Afghan",
                    label: "Afghan"
                },
                {
                    code: "\u00c5land Island",
                    label: "\u00c5land Island"
                },
                {
                    code: "Albanian",
                    label: "Albanian"
                },
                {
                    code: "Algerian",
                    label: "Algerian"
                },
                {
                    code: "American Samoan",
                    label: "American Samoan"
                },
                {
                    code: "Andorran",
                    label: "Andorran"
                },
                {
                    code: "Angolan",
                    label: "Angolan"
                },
                {
                    code: "Anguillan",
                    label: "Anguillan"
                },
                {
                    code: "Antarctic",
                    label: "Antarctic"
                },
                {
                    code: "Antiguan or Barbudan",
                    label: "Antiguan or Barbudan"
                },
                {

                    code: "Argentine",
                    label: "Argentine"
                },
                {
                    code: "Armenian",
                    label: "Armenian"
                },
                {
                    code: "Aruban",
                    label: "Aruban"
                },
                {

                    code: "Australian",
                    label: "Australian"
                },
                {

                    code: "Austrian",
                    label: "Austrian"
                },
                {

                    code: "Azerbaijani, Azeri",
                    label: "Azerbaijani, Azeri"
                },
                {

                    code: "Bahamian",
                    label: "Bahamian"
                },
                {
                    code: "Bahraini",
                    label: "Bahraini"
                },
                {
                    code: "Bangladeshi",
                    label: "Bangladeshi"
                },
                {

                    code: "Barbadian",
                    label: "Barbadian"
                },
                {
                    code: "Belarusian",
                    label: "Belarusian"
                },
                {
                    code: "Belgian",
                    label: "Belgian"
                },
                {
                    code: "Belizean",
                    label: "Belizean"
                },
                {

                    code: "Beninese, Beninois",
                    label: "Beninese, Beninois"
                },
                {
                    code: "Bermudian, Bermudan",
                    label: "Bermudian, Bermudan"
                },
                {
                    code: "Bhutanese",
                    label: "Bhutanese"
                },
                {
                    code: "Bolivian",
                    label: "Bolivian"
                },
                {
                    code: "Bonaire",
                    label: "Bonaire"
                },
                {
                    code: "Bosnian or Herzegovinian",
                    label: "Bosnian or Herzegovinian"
                },
                {
                    code: "Motswana, Botswanan",
                    label: "Motswana, Botswanan"
                },
                {
                    code: "Bouvet Island",
                    label: "Bouvet Island"
                },
                {
                    code: "Brazilian",
                    label: "Brazilian"
                },
                {
                    code: "BIOT",
                    label: "BIOT"
                },
                {
                    code: "Bruneian",
                    label: "Bruneian"
                },
                {
                    code: "Bulgarian",
                    label: "Bulgarian"
                },
                {
                    code: "Burkinab\u00e9",
                    label: "Burkinab\u00e9"
                },
                {
                    code: "Burundian",
                    label: "Burundian"
                },
                {
                    code: "Cabo Verdean",
                    label: "Cabo Verdean"
                },
                {
                    code: "Cambodian",
                    label: "Cambodian"
                },
                {
                    code: "Cameroonian",
                    label: "Cameroonian"
                },
                {
                    code: "Canadian",
                    label: "Canadian"
                },
                {
                    code: "Caymanian",
                    label: "Caymanian"
                },
                {
                    code: "Central African",
                    label: "Central African"
                },
                {
                    code: "Chadian",
                    label: "Chadian"
                },
                {
                    code: "Chilean",
                    label: "Chilean"
                },
                {
                    code: "Chinese",
                    label: "Chinese"
                },
                {
                    code: "Christmas Island",
                    label: "Christmas Island"
                },
                {
                    code: "Cocos Island",
                    label: "Cocos Island"
                },
                {
                    code: "Colombian",
                    label: "Colombian"
                },
                {
                    code: "Comoran, Comorian",
                    label: "Comoran, Comorian"
                },
                {
                    code: "Congolese",
                    label: "Congolese"
                },
                {
                    code: "Cook Island",
                    label: "Cook Island"
                },
                {
                    code: "Costa Rican",
                    label: "Costa Rican"
                },
                {
                    code: "Ivorian",
                    label: "Ivorian"
                },
                {
                    code: "Croatian",
                    label: "Croatian"
                },
                {
                    code: "Cuban",
                    label: "Cuban"
                },
                {
                    code: "Cura\u00e7aoan",
                    label: "Cura\u00e7aoan"
                },
                {
                    code: "Cypriot",
                    label: "Cypriot"
                },
                {
                    code: "Czech",
                    label: "Czech"
                },
                {
                    code: "Danish",
                    label: "Danish"
                },
                {
                    code: "Djiboutian",
                    label: "Djiboutian"
                },
                {
                    code: "Dominican",
                    label: "Dominican"
                },
                {
                    code: "Ecuadorian",
                    label: "Ecuadorian"
                },
                {
                    code: "Egyptian",
                    label: "Egyptian"
                },
                {
                    code: "Salvadoran",
                    label: "Salvadoran"
                },
                {
                    code: "Equatorial Guinean, Equatoguinean",
                    label: "Equatorial Guinean, Equatoguinean"
                },
                {
                    code: "Eritrean",
                    label: "Eritrean"
                },
                {
                    code: "Estonian",
                    label: "Estonian"
                },
                {
                    code: "Ethiopian",
                    label: "Ethiopian"
                },
                {
                    code: "Falkland Island",
                    label: "Falkland Island"
                },
                {
                    code: "Faroese",
                    label: "Faroese"
                },
                {
                    code: "Fijian",
                    label: "Fijian"
                },
                {
                    code: "Finnish",
                    label: "Finnish"
                },
                {
                    code: "French",
                    label: "French"
                },
                {
                    code: "French Guianese",
                    label: "French Guianese"
                },
                {
                    code: "French Polynesian",
                    label: "French Polynesian"
                },
                {
                    code: "French Southern Territories",
                    label: "French Southern Territories"
                },
                {
                    code: "Gabonese",
                    label: "Gabonese"
                },
                {
                    code: "Gambian",
                    label: "Gambian"
                },
                {
                    code: "Georgian",
                    label: "Georgian"
                },
                {
                    code: "German",
                    label: "German"
                },
                {
                    code: "Ghanaian",
                    label: "Ghanaian"
                },
                {
                    code: "Gibraltar",
                    label: "Gibraltar"
                },
                {
                    code: "Greek, Hellenic",
                    label: "Greek, Hellenic"
                },
                {
                    code: "Greenlandic",
                    label: "Greenlandic"
                },
                {
                    code: "Grenadian",
                    label: "Grenadian"
                },
                {
                    code: "Guadeloupe",
                    label: "Guadeloupe"
                },
                {
                    code: "Guamanian, Guambat",
                    label: "Guamanian, Guambat"
                },
                {
                    code: "Guatemalan",
                    label: "Guatemalan"
                },
                {
                    code: "Channel Island",
                    label: "Channel Island"
                },
                {
                    code: "Guinean",
                    label: "Guinean"
                },
                {
                    code: "Bissau-Guinean",
                    label: "Bissau-Guinean"
                },
                {
                    code: "Guyanese",
                    label: "Guyanese"
                },
                {
                    code: "Haitian",
                    label: "Haitian"
                },
                {
                    code: "Heard Island or McDonald Islands",
                    label: "Heard Island or McDonald Islands"
                },
                {
                    code: "Vatican",
                    label: "Vatican"
                },
                {
                    code: "Honduran",
                    label: "Honduran"
                },
                {
                    code: "Hong Kong, Hong Kongese",
                    label: "Hong Kong, Hong Kongese"
                },
                {
                    code: "Hungarian, Magyar",
                    label: "Hungarian, Magyar"
                },
                {
                    code: "Icelandic",
                    label: "Icelandic"
                },
                {
                    code: "Indian",
                    label: "Indian"
                },
                {
                    code: "Indonesian",
                    label: "Indonesian"
                },
                {
                    code: "Iranian, Persian",
                    label: "Iranian, Persian"
                },
                {
                    code: "Iraqi",
                    label: "Iraqi"
                },
                {
                    code: "Irish",
                    label: "Irish"
                },
                {
                    code: "Manx",
                    label: "Manx"
                },
                {
                    code: "Israeli",
                    label: "Israeli"
                },
                {
                    code: "Italian",
                    label: "Italian"
                },
                {
                    code: "Jamaican",
                    label: "Jamaican"
                },
                {
                    code: "Japanese",
                    label: "Japanese"
                },
                {
                    code: "Jordanian",
                    label: "Jordanian"
                },
                {
                    code: "Kazakhstani, Kazakh",
                    label: "Kazakhstani, Kazakh"
                },
                {
                    code: "Kenyan",
                    label: "Kenyan"
                },
                {
                    code: "I-Kiribati",
                    label: "I-Kiribati"
                },
                {
                    code: "North Korean",
                    label: "North Korean"
                },
                {
                    code: "South Korean",
                    label: "South Korean"
                },
                {
                    code: "Kuwaiti",
                    label: "Kuwaiti"
                },
                {
                    code: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
                    label: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz"
                },
                {
                    code: "Lao, Laotian",
                    label: "Lao, Laotian"
                },
                {
                    code: "Latvian",
                    label: "Latvian"
                },
                {
                    code: "Lebanese",
                    label: "Lebanese"
                },
                {
                    code: "Basotho",
                    label: "Basotho"
                },
                {
                    code: "Liberian",
                    label: "Liberian"
                },
                {
                    code: "Libyan",
                    label: "Libyan"
                },
                {
                    code: "Liechtenstein",
                    label: "Liechtenstein"
                },
                {
                    code: "Lithuanian",
                    label: "Lithuanian"
                },
                {
                    code: "Luxembourg, Luxembourgish",
                    label: "Luxembourg, Luxembourgish"
                },
                {
                    code: "Macanese, Chinese",
                    label: "Macanese, Chinese"
                },
                {
                    code: "Macedonian",
                    label: "Macedonian"
                },
                {
                    code: "Malagasy",
                    label: "Malagasy"
                },
                {
                    code: "Malawian",
                    label: "Malawian"
                },
                {
                    code: "Malaysian",
                    label: "Malaysian"
                },
                {
                    code: "Maldivian",
                    label: "Maldivian"
                },
                {
                    code: "Malian, Malinese",
                    label: "Malian, Malinese"
                },
                {
                    code: "Maltese",
                    label: "Maltese"
                },
                {
                    code: "Marshallese",
                    label: "Marshallese"
                },
                {
                    code: "Martiniquais, Martinican",
                    label: "Martiniquais, Martinican"
                },
                {
                    code: "Mauritanian",
                    label: "Mauritanian"
                },
                {
                    code: "Mauritian",
                    label: "Mauritian"
                },
                {
                    code: "Mahoran",
                    label: "Mahoran"
                },
                {
                    code: "Mexican",
                    label: "Mexican"
                },
                {
                    code: "Micronesian",
                    label: "Micronesian"
                },
                {
                    code: "Moldovan",
                    label: "Moldovan"
                },
                {
                    code: "Mon\u00e9gasque, Monacan",
                    label: "Mon\u00e9gasque, Monacan"
                },
                {
                    code: "Mongolian",
                    label: "Mongolian"
                },
                {
                    code: "Montenegrin",
                    label: "Montenegrin"
                },
                {
                    code: "Montserratian",
                    label: "Montserratian"
                },
                {
                    code: "Moroccan",
                    label: "Moroccan"
                },
                {
                    code: "Mozambican",
                    label: "Mozambican"
                },
                {
                    code: "Burmese",
                    label: "Burmese"
                },
                {
                    code: "Namibian",
                    label: "Namibian"
                },
                {
                    code: "Nauruan",
                    label: "Nauruan"
                },
                {
                    code: "Nepali, Nepalese",
                    label: "Nepali, Nepalese"
                },
                {
                    code: "Dutch, Netherlandic",
                    label: "Dutch, Netherlandic"
                },
                {
                    code: "New Caledonian",
                    label: "New Caledonian"
                },
                {
                    code: "New Zealand, NZ",
                    label: "New Zealand, NZ"
                },
                {
                    code: "Nicaraguan",
                    label: "Nicaraguan"
                },
                {
                    code: "Nigerien",
                    label: "Nigerien"
                },
                {
                    code: "Nigerian",
                    label: "Nigerian"
                },
                {
                    code: "Niuean",
                    label: "Niuean"
                },
                {
                    code: "Norfolk Island",
                    label: "Norfolk Island"
                },
                {
                    code: "Northern Marianan",
                    label: "Northern Marianan"
                },
                {
                    code: "Norwegian",
                    label: "Norwegian"
                },
                {
                    code: "Omani",
                    label: "Omani"
                },
                {
                    code: "Pakistani",
                    label: "Pakistani"
                },
                {
                    code: "Palauan",
                    label: "Palauan"
                },
                {
                    code: "Palestinian",
                    label: "Palestinian"
                },
                {
                    code: "Panamanian",
                    label: "Panamanian"
                },
                {
                    code: "Papua New Guinean, Papuan",
                    label: "Papua New Guinean, Papuan"
                },
                {
                    code: "Paraguayan",
                    label: "Paraguayan"
                },
                {
                    code: "Peruvian",
                    label: "Peruvian"
                },
                {
                    code: "Philippine, Filipino",
                    label: "Philippine, Filipino"
                },
                {
                    code: "Pitcairn Island",
                    label: "Pitcairn Island"
                },
                {
                    code: "Polish",
                    label: "Polish"
                },
                {
                    code: "Portuguese",
                    label: "Portuguese"
                },
                {
                    code: "Puerto Rican",
                    label: "Puerto Rican"
                },
                {
                    code: "Qatari",
                    label: "Qatari"
                },
                {
                    code: "R\u00e9unionese, R\u00e9unionnais",
                    label: "R\u00e9unionese, R\u00e9unionnais"
                },
                {
                    code: "Romanian",
                    label: "Romanian"
                },
                {
                    code: "Russian",
                    label: "Russian"
                },
                {
                    code: "Rwandan",
                    label: "Rwandan"
                },
                {
                    code: "Barth\u00e9lemois",
                    label: "Barth\u00e9lemois"
                },
                {
                    code: "Saint Helenian",
                    label: "Saint Helenian"
                },
                {
                    code: "Kittitian or Nevisian",
                    label: "Kittitian or Nevisian"
                },
                {
                    code: "Saint Lucian",
                    label: "Saint Lucian"
                },
                {
                    code: "Saint-Martinoise",
                    label: "Saint-Martinoise"
                },
                {
                    code: "Saint-Pierrais or Miquelonnais",
                    label: "Saint-Pierrais or Miquelonnais"
                },
                {
                    code: "Saint Vincentian, Vincentian",
                    label: "Saint Vincentian, Vincentian"
                },
                {
                    code: "Samoan",
                    label: "Samoan"
                },
                {
                    code: "Sammarinese",
                    label: "Sammarinese"
                },
                {
                    code: "S\u00e3o Tom\u00e9an",
                    label: "S\u00e3o Tom\u00e9an"
                },
                {
                    code: "Saudi, Saudi Arabian",
                    label: "Saudi, Saudi Arabian"
                },
                {
                    code: "Senegalese",
                    label: "Senegalese"
                },
                {
                    code: "Serbian",
                    label: "Serbian"
                },
                {
                    code: "Seychellois",
                    label: "Seychellois"
                },
                {
                    code: "Sierra Leonean",
                    label: "Sierra Leonean"
                },
                {
                    code: "Singaporean",
                    label: "Singaporean"
                },
                {
                    code: "Sint Maarten",
                    label: "Sint Maarten"
                },
                {
                    code: "Slovak",
                    label: "Slovak"
                },
                {
                    code: "Slovenian, Slovene",
                    label: "Slovenian, Slovene"
                },
                {
                    code: "Solomon Island",
                    label: "Solomon Island"
                },
                {
                    code: "Somali, Somalian",
                    label: "Somali, Somalian"
                },
                {
                    code: "South African",
                    label: "South African"
                },
                {
                    code: "South Georgia or South Sandwich Islands",
                    label: "South Georgia or South Sandwich Islands"
                },
                {
                    code: "South Sudanese",
                    label: "South Sudanese"
                },
                {
                    code: "Spanish",
                    label: "Spanish"
                },
                {
                    code: "Sri Lankan",
                    label: "Sri Lankan"
                },
                {
                    code: "Sudanese",
                    label: "Sudanese"
                },
                {
                    code: "Surinamese",
                    label: "Surinamese"
                },
                {
                    code: "Svalbard",
                    label: "Svalbard"
                },
                {
                    code: "Swazi",
                    label: "Swazi"
                },
                {
                    code: "Swedish",
                    label: "Swedish"
                },
                {
                    code: "Swiss",
                    label: "Swiss"
                },
                {
                    code: "Syrian",
                    label: "Syrian"
                },
                {
                    code: "Chinese, Taiwanese",
                    label: "Chinese, Taiwanese"
                },
                {
                    code: "Tajikistani",
                    label: "Tajikistani"
                },
                {
                    code: "Tanzanian",
                    label: "Tanzanian"
                },
                {
                    code: "Thai",
                    label: "Thai"
                },
                {
                    code: "Timorese",
                    label: "Timorese"
                },
                {
                    code: "Togolese",
                    label: "Togolese"
                },
                {
                    code: "Tokelauan",
                    label: "Tokelauan"
                },
                {
                    code: "Tongan",
                    label: "Tongan"
                },
                {
                    code: "Trinidadian or Tobagonian",
                    label: "Trinidadian or Tobagonian"
                },
                {
                    code: "Tunisian",
                    label: "Tunisian"
                },
                {
                    code: "Turkish",
                    label: "Turkish"
                },
                {
                    code: "Turkmen",
                    label: "Turkmen"
                },
                {
                    code: "Turks and Caicos Island",
                    label: "Turks and Caicos Island"
                },
                {
                    code: "Tuvaluan",
                    label: "Tuvaluan"
                },
                {
                    code: "Ugandan",
                    label: "Ugandan"
                },
                {
                    code: "Ukrainian",
                    label: "Ukrainian"
                },
                {
                    code: "Emirati, Emirian, Emiri",
                    label: "Emirati, Emirian, Emiri"
                },
                {
                    code: "British, UK",
                    label: "British, UK"
                },
                {
                    code: "American",
                    label: "American"
                },

                {
                    code: "Uruguayan",
                    label: "Uruguayan"
                },
                {
                    code: "Uzbekistani, Uzbek",
                    label: "Uzbekistani, Uzbek"
                },
                {
                    code: "Ni-Vanuatu, Vanuatuan",
                    label: "Ni-Vanuatu, Vanuatuan"
                },
                {
                    code: "Venezuelan",
                    label: "Venezuelan"
                },
                {
                    code: "Vietnamese",
                    label: "Vietnamese"
                },
                {
                    code: "British Virgin Island",
                    label: "British Virgin Island"
                },
                {
                    code: "U.S. Virgin Island",
                    label: "U.S. Virgin Island"
                },
                {
                    code: "Wallis and Futuna, Wallisian or Futunan",
                    label: "Wallis and Futuna, Wallisian or Futunan"
                },
                {
                    code: "Sahrawi, Sahrawian, Sahraouian",
                    label: "Sahrawi, Sahrawian, Sahraouian"
                },
                {
                    code: "Yemeni",
                    label: "Yemeni"
                },
                {
                    code: "Zambian",
                    label: "Zambian"
                },
                {
                    code: "Zimbabwean",
                    label: "Zimbabwean"
                }
            ]
        }
    },
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))

    },
    mounted(){
        const self = this
        console.log("mounted")
        console.log(this.categoryComputed)

    },

    methods : {
        ...mapMutations('LoyaltyMemberModule',['cancelRequest','setFormStatusCreate']),
        ...mapActions('LoyaltyMemberModule',['storeRecord','updateRecord']),
        ...mapActions('LoyaltyCardModule',['getLoyaltyCards']),
        ...mapActions('TireModule',['getTiers']),
        ...mapActions('FindLoyaltyMemberModule',{
            findLoyalMember : 'findRecord'
        }),

        ...mapMutations('HistoryModule',[
            'setModule',
            'setRecordId',
        ]),
        openDialog(){
            this.syncModal = true

        },
        closeDialog(){
            this.syncModal = false

        },
        resetDefaultTab(){
            this.activeTabIndex = 0,
                this.activeTabObject = { text: 'Details', tabSlot : 'main' }
        },
        getActiveTab(v){
            this.activeTabIndex = v
            this.activeTabObject = this.mdbTabsData[v];
        },

      validateDOB(value) {
        if (!value) {
          this.showError = true;
          return 'Date of birth is required';
        }

        const currentDate = new Date();
        const selectedYear = new Date(value).getFullYear();
        const currentYear = currentDate.getFullYear();
        const age = currentYear - selectedYear;

        if (age < 18) {
          this.showError = true;
          return 'Age must be 18 or older';
        }

        this.showError = false;
        return true;
      },
        emitNewWindow(){
            // this.$refs.vselect.closeSearchOptions()
            this.$emit('newWindow')
        },



        getCustomerSelected(v){

            const self = this;
            const selectedCustomer = this.customer.filter(c => {
                return c.value === v;
            })[0];

            if(selectedCustomer !== null){
                this.form.attr.name = selectedCustomer.label
                this.form.attr.mobile = selectedCustomer.mobile
                this.form.attr.address = selectedCustomer.address
                this.form.attr.cx_series = selectedCustomer.series
                this.form.attr.corporate = selectedCustomer.attr.corporate

                this.activeCustomer = selectedCustomer

                let add = ""
                add += (selectedCustomer.attr.address != "" && selectedCustomer.attr.address !== null) ? selectedCustomer.attr.address  : "";

                add += (selectedCustomer.attr.address2.street != "" && selectedCustomer.attr.address2.street !== null) ? ","+selectedCustomer.attr.address2.street  : "";
                add += (selectedCustomer.attr.address2.building != "" && selectedCustomer.attr.address2.building !== null) ? ","+selectedCustomer.attr.address2.building  : "";
                add += (selectedCustomer.attr.address2.zone != "" && selectedCustomer.attr.address2.zone !== null) ? ","+selectedCustomer.attr.address2.zone  : "";
                add += (selectedCustomer.attr.address2.area != "" && selectedCustomer.attr.address2.area !== null) ? "/"+selectedCustomer.attr.address2.area  : "";
                add += (selectedCustomer.attr.address2.city != "" && selectedCustomer.attr.address2.city !== null) ? "/"+selectedCustomer.attr.address2.city  : "";



                this.displayAddress = add.trim();

            }

        },

        openCustomer(){
            this.$refs.contactForm.openDialog();
        },

      closeModelWindow(){
        this.syncModal = false;
        this.formData = JSON.parse(JSON.stringify(this.formObject))
          console.log("swaraj close")
          this.selectedImage = "";
      },

        editCustomer(){
            const self = this;
            const {address,mobile, name,email} = this.form.attr

            const em = (typeof email === 'undefined') ? "" : email
            const addr = (typeof address === 'undefined') ? "" : address
            if(this.form.customer_id !== null){
                this.setFormContactStatusUpdate()
                this.getContact({mdbID : this.form.customer_id,mobile : mobile,address : addr, name : name, email : email}).then((response) => {

                    const contactFormUpdate = {
                        row : response
                    }
                    self.setFormRecordUpdate(contactFormUpdate)
                });
                this.$refs.contactForm.openDialog()
            }
        },

        contactSuccess(v){
            this.activeCustomer = v
            const contact = { name: v.name, mdbID: v.mdbID, id: v.mdbID, loyaltyMembership :false,points:0,check_points:[],reference:"customer",status:"active", mobile: v.mobile, address : v.attr.address, series : v.series, address2 : v.attr.address2,attr : v.attr,email:v.attr.email }
            this.customers.unshift(contact);
            this.onSelect(contact,0);
        },
        contactUpdateSuccess(v){
            const {mobile, name} = v
            const {address, email, address2, corporate} = v.attr
            this.form.attr = {...this.form.attr, address : address,address2 : address2, mobile : mobile, name : name, email : email, corporate : corporate}


            this.activeCustomer = v
            let ndx = null
            const con = this.customer.filter((e,index) => {
                ndx = index
                return e.value === v.mdbID
            })[0];

            const finalContact = {
                opt : name + " "+ mobile,
                label :name,
                value : con.value,
                mobile : mobile,
                address : address,
                attr : v.attr
            }



            if(ndx !== null){


                this.$set(this.customer,ndx,finalContact)
                let add = v.address

                add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
                add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";

                this.displayAddress = add.trim();

            }

        },



        /* start */
        triggerBrowseFile(){
            // const elem = this.$refs.file
            // elem.click()
            this.$refs.fileInput.click();
        },
        toggleCard() {
            this.showCard = true;
            // this.form.attr.images = null
        },


        handleFileSelect(event) {
            this.formData.customer.attr.images = [];
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    let dataURI = e.target.result
                    this.selectedImage = dataURI
                    if (dataURI) {
                        this.formData.customer.attr.images.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false})
                    }
                };
                reader.readAsDataURL(file);
            }
        },



        removeImage() {
            this.selectedImage = null;
            this.form.images = [];
        },

        uploadImage(){
            this.$refs.upload.openDialog();
        },

        fnImageSelect(v){
            if(v.length > 0){
                v.forEach((r,i) => {
                    const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
                    if(i === 0){
                        d.default = 1;
                    }
                    this.form.images.push(d)
                })
            }
        },
        // setSelected(value) {
        //     // Handle the selected value from Vue Select
        //     this.inputValue.code = value;
        //     this.updateMaxlength();
        // },
        // updateMaxlength() {
        //     const selectedOption = this.options.find(option => option.code === this.inputValue.code);
        //     console.log(selectedOption);
        //     if (selectedOption) {
        //         this.maxMobileLength = selectedOption.digit;
        //     }
        // },

        setSelected(selectedCode) {
            const selectedCountry = this.options.find(option => option.code === selectedCode);
            if (selectedCountry) {
                this.formData.customer.attr.contacts[0].code = selectedCode;
                this.digitCount = selectedCountry.digit;
            } else {
                this.formData.customer.attr.contacts[0].code = '';
                this.digitCount = 0;
            }
        },
        setMaritalSelected(selectedCode) {
            if (selectedCode === "Married") {
                this.display = true
            } else {
                this.display = false
            }
        },


        onSearch(v){
            this.$emit('onSearch',v)
        },



        onSubmitMultiStepForm(){
            const self = this;
            this.$refs.observer.validate().then((isValid) => {

                if(isValid){

                    if(self.currentStep == 0){
                        self.formData.card.display_name = self.formData.customer.name
                    }

                    if(self.currentStep === 3){

                        const storeData = {
                            ...self.formData.customer,
                            card : {...self.formData.card},
                            tier : {...self.formData.tier},
                        }
                        self.formData.card.card_number = self.formData.customer.series
                        self.storeRecord(storeData).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('viewMember',response)
                            self.closeDialog();
                            self.formLoading = false
                            this.selectedImage = "";
                            /*
                            self.FormResetter(); */

                        }).catch((error) => {
                            self.formLoading = false
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                                if(error.response.data.hasOwnProperty('message')){
                                    self.$ThatsNotify.error({
                                        message : `${error.response.data.message}`
                                    })
                                }



                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })

                    }else{
                        self.$nextTick(() => {
                            self.currentStep = self.currentStep + 1;
                        })
                    }

                }
            })
        },

        triggerFindCustomer(obj){
            const self = this;
            this.findLoyalMember({
                term : obj.attr.cx_series
            }).then((response) => {
                self.customers = response.map((item) => {
                    return {...item,selected : true}

                })

                this.formData = {...this.formData,customer : {...JSON.parse(JSON.stringify(self.customers[0]))}}

                // console.log(response)
            })
        },

        findCustomer(){

            const self = this;
            this.findLoyalMember({
                term : this.searchCustomer
            }).then((response) => {
                self.customers = response.map((item) => {
                    return {...item,selected : false}
                })
                // console.log(response)
            })
        },


        onSelect(card,index){
            this.formData = {...this.formData,customer : {...card}}
            this.customers = this.customers.map((item, cardIndex) => {
                const selected = (cardIndex === index) ? true : false
                return {...item,selected : selected}
            })
        },
        onSelectLoyaltyCard(card,index){
            this.formData = {...this.formData,card : {...this.formData.card,...card}}
            this.cards = this.cards.map((item, cardIndex) => {
                const selected = (cardIndex === index) ? true : false
                return {...item,selected : selected}
            })
        },
        onSelectTier(tier,index){


            const card = this.cards.filter((item) => {
                return item.id === tier.card_id
            },[])[0]


            this.filteredCards = this.cards.filter((item) => item.id === tier.card_id,[]).map((item, cardIndex) => {
                return {...item,selected : true}
            },[])



            this.formData = {...this.formData,tier : {...tier}, card : {...card}}
            this.tiers = this.tiers.map((item, cardIndex) => {
                const selected = (cardIndex === index) ? true : false
                return {...item,selected : selected}
            })
        },

        viewMember(card){
            this.$emit('viewMember',card)
        },
        onSubmitForm(){
            var self =this
            this.validated = true
            this.$refs.observer.validate().then((isValid) => {

                console.log(isValid)
                this.showError = false
                if (isValid) {
                    // this.form.description = this.getText();
                    if(self.formLoading === false){
                        self.formLoading = true
                        self.formData.billOfMaterials = self.formData.billOfMaterials.filter((item) => item.item_id != '',[])
                        if(self.formStatus == 'create'){


                            self.storeRecord(self.formData).then((response) => {
                                self.$ThatsNotify.success()
                                self.$emit('success',response)
                                self.closeDialog();
                                self.formLoading = false
                                /*
                                self.FormResetter(); */

                            }).catch((error) => {
                                self.formLoading = false
                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                        self.$refs.observer.setErrors(error.response.data.errors);
                                        this.showError = true
                                    }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                        }

                        if(self.formStatus == 'update'){
                            self.updateRecord(self.formData).then((response) => {
                                self.$ThatsNotify.success({
                                    message : "Record was successfully updated"
                                })
                                self.closeDialog();
                                self.$emit('success',response)
                                /* self.FormResetter(); */
                                self.formLoading = false

                            }).catch((error) => {
                                self.formLoading = false
                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                        self.$refs.observer.setErrors(error.response.data.errors);
                                        this.showError = true
                                    }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                        }
                    }

                }


            });


        },



        openHistoryDialog(){
            this.setModule('loyalty-member')
            this.setRecordId(this.formData.customer.id)
            this.$refs.historyDialog.openDialog();
        },

        backToPrevious(){
            this.currentStep--
        }

    },

    computed : {
        required() {
            return required
        },
        ...mapGetters('LoyaltyMemberModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),

        activeTabSlot : function(){
            return this.activeTabObject.tabSlot
        },

        hasSelectedCustomer : function(){
            return (this.customers.filter((item) => item.selected === true,[]).length > 0) ? true : false
        },
        hasSelectedCard : function(){
            return (this.filteredCards.filter((item) => item.selected === true,[]).length > 0) ? true : false
        },
        hasSelectedTier : function(){
            return (this.tiers.filter((item) => item.selected === true,[]).length > 0) ? true : false
        },
      shouldApplyCustomZIndex() {
        return this.$route.path === '/loyalty-members';
      },
      isLoyaltyMembersRoute() {
        return this.currentRoutePath === '/loyalty-members';
      },


    },

    watch : {
        syncModal : function(value){
            const self = this;
            if(!value){
                this.cancelRequest();
                this.resetDefaultTab();
                this.formData = JSON.parse(JSON.stringify(this.formObject))
                this.tableKey++
            }

            if(value){
                this.getLoyaltyCards().then((res) => {
                    self.cards = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
                this.getTiers().then((res) => {
                    self.tiers = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
            }
            self.currentStep = 0;
            self.customers = [];
        },

        formRecord : function(value){
            this.formData = value

        },




    }
}
</script>

<style lang="scss">

.bts-form-tabs{
    .nav-tabs{
        margin-left: 10px;border-bottom: none!important;
        .nav-item{
            a.active{
                border-bottom: 1px solid #0056b3;
            }
        }
    }

    .tab-content{
        height: auto!important;border-top:1px solid #dee2e6;
    }
}



.avatar-sm {
    width: 56px;
    border-radius: 50%;
}
.chat-message-type {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 0 auto !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
}

.profile-card {
    margin-top: 100px;
}

.profile-card .avatar {
    max-width: 150px;
    max-height: 150px;
    margin: -70px auto 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}

.btn-floating i {
    color: #4f4f4f !important;
}

.loyalty-card-image{
    width: 120px;
}
.custom-z-index {
    z-index: 1 !important; /* Set the desired z-index */
}
</style>

