<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

            <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'Customer Logs'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="40%" class="text-left customer-log-dialog" :formStatus="formStatus" @dialog-state="dialogState" :group="group">

            <mdbContainer class="mt-3 mb-0">

              <span>
                <div class="form-group input-group-sm row thats-form m-0">
                    <label class="col-form-label">Contact Details</label>
                </div>
              </span>

              <FieldSetCard>
                      <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="First Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" :show_error="true" :disabled="isReadonly"/>
                      <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Last Name" vid="last_name" name="last_name" v-model="form.attr.last_name" :validated="validated" :rules="'required'" size="sm" :show_error="true" :disabled="isReadonly"/>
                      <ValidationProvider vid="attr.contacts.0.value" name="attr.contacts.0.value" :rules="'required'" v-slot="{errors}">
                          <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Mobile" vid="attr.contacts.0.valuex" name="attr.contacts.0.valuex" v-model="form.attr.contacts[0]" :rules="'required'" :show_error="true" :disabled="isReadonly"></InternationalMobile>
                          <template v-if="errors.length > 0">
                              <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                          </template>
                      </ValidationProvider>
                      <ValidationProvider vid="attr.contacts.1.value" name="attr.contacts.1.value" :rules="''" v-slot="{errors}">
                          <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Whatsapp" vid="attr.contacts.1.value" name="attr.contacts.1.value" v-model="form.attr.contacts[1]" :rules="'required'" :show_error="true" :disabled="isReadonly"></InternationalMobile>
                          <template v-if="errors.length > 0">
                              <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                          </template>
                      </ValidationProvider>

                      <mdbRow>
                        <mdbCol sm="9" offset-sm="3" md="9" offset-md="3">
                          <input type="checkbox" class="custom-control-input" id="defaultChecked3" v-model="form.sameAsMobile" @change="copyMobileToWhatsApp"  :disabled="isReadonly">
                            <label class="custom-control-label" for="defaultChecked3">Same as Mobile</label>
                        </mdbCol>
                      </mdbRow>

                      <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Email" vid="email" name="email" v-model="form.email" :validated="validated"  size="sm" :show_error="true"  :disabled="isReadonly"/>
  

              </FieldSetCard>

              <span>
                <div class="form-group input-group-sm row thats-form m-0">
                    <label class="col-form-label">Interested In</label>
                </div>
              </span>

              <FieldSetCard>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brand" v-model="form.brand" :reduce="label => label.value" :reduceLabel="'label'" :options="brands" :rules="''"  :disabled="isReadonly"></ThatsMultiSelect>
                    <ThatsMultiSelect :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Category" v-model="form.category" :reduce="label => label.value" :reduceLabel="'label'" :options="categories" :rules="''"  :disabled="isReadonly"></ThatsMultiSelect>
                    <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-sm-3'" :fieldClass="'col-sm-9  pl-0'" label="Comments" placeholder="Comments" vid="description" name="description" v-model="form.comments" size="sm" :rules="''"  :disabled="isReadonly"/>
              </FieldSetCard>  


              <span>
                <div class="form-group input-group-sm row thats-form m-0">
                    <label class="col-form-label">Set Reminder</label>
                </div>
              </span>

              <FieldSetCard>
 
                    <ThatsMultiSelect placeholder="Reminder Date" :clearable="false" :multiple="false"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0"  label="Day(s)" v-model="form.reminder_date" :reduce="label => label.code" :reduceLabel="'label'" :options="reminderDate" :rules="''" :disabled="isReadonly"></ThatsMultiSelect>
                    <div class="row">
                      <div class="col-md-3">
                        <label class="" style="text-transform: uppercase;font-size: 0.8rem !important;font-weight: 600;margin-left: 1px;margin-top:18px">Time</label>
                      </div>
                      <div class="col-md-9 pl-0">
                        <mdb-time-picker v-model="form.reminder_time"/>
                      </div>
                  </div>

                  <template v-if="form.hasOwnProperty('last_send_datetime')">
                    <mdbRow>
                      <mdbCol sm="9" offset-sm="3" md="9" offset-md="3" class="pl-0">
                          <mdbAlert color="info">
                            Message last sent : {{ form.last_send_datetime }}
                          </mdbAlert>
                      </mdbCol>
                    </mdbRow>
                  </template>
              </FieldSetCard>  

              <FieldSetCard>
                <ThatsMultiSelect :clearable="false" :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Location" v-model="form.location_id" :reduce="label => label.value" :reduceLabel="'label'" :options="showrooms" :rules="''" :disabled="isReadonly"></ThatsMultiSelect>
                <ThatsMultiSelect labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sales Person" :clearable="false" :multiple="false" :showLabel="true"  v-model="form.sold_by" :reduce="label => label.code" :reduceLabel="'label'" :options="partnerSalesPerson" :rules="''" :disabled="true"></ThatsMultiSelect>
              </FieldSetCard>
            </mdbContainer>

              <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>

                    <template v-else>
                      <template v-if="formStatus == 'create'">
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                        </template>
                      
        <!--                 <template v-if="formStatus == 'update'">
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitResend)"><i class="el-icon-save text-white cursor-pointer"></i> Resend</mdb-btn>
                        </template> -->
                        <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                    </template>
                </div>
              </template>
           </el-dialog>

          </ValidationObserver>




  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbAlert} from "mdbvue";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsMultiSelect, ThatsTextarea, ThatsDatePickerPage, FieldSetCard} from '../../components/thats/Thats'
import {  mdbTimePicker,mdbDatePicker2,mdbTimePicker2,mdbRow,mdbCol } from 'mdbvue';
import InternationalMobile from "../../components/thats/InternationalMobile.vue";
import { isReadonly } from "vue";

export default {
    components: {
        InternationalMobile, ValidationProvider,
        ThatsTextarea,
        ThatsDatePickerPage,
        mdbContainer,
        mdbBtn,
        mdbTimePicker,
        mdbDatePicker2,
        mdbTimePicker2,
        ValidationObserver,
        ThatsInput,
        ThatsMultiSelect,
        mdbRow,
        mdbCol,
        mdbAlert,
        FieldSetCard
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
      
    },

    inject:['showroomsProvider','filteredCategoryProvider','brandProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
        categories : [],
        brands : [],
        partnerSalesPerson : [],
        validated: false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        reminderDate : [
            { label : "After 1 Day", code : "1day"},
            { label : "After 2 Day", code : "2day"},
            { label : "After 3 Day", code : "3day"},
            { label : "After 4 Day", code : "4day"},
            { label : "After 5 Day", code : "5day"},
            { label : "After 6 Day", code : "6day"},
            { label : "After 7 Day", code : "7day"},
        ],
      group2 : 3

    }),

    created : function() {
      var self = this;
      this.FormResetter();
       this.optionsHandler();
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
    mounted() {
        this.getPartner().then((response) => {
            console.log(response,"partner")
            this.partnerSalesPerson = response
        })
        
        

    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */

      ...mapActions('CustomerLogsModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
        ...mapActions('TransactionModule',[
            'getPartner']),
      ...mapMutations('CustomerLogsModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
            name: '',
            
            mobile: '',
            whatsapp: '',
            location_id: '',
            reminder_date: '1day',
            reminder_time: '09:00am',
            comments: '',
            sales_person_id: '',
            sameAsMobile: false,
            brand : [],
            category : [],
            attr : {
                first_name: '',
                last_name: '',
                contacts: [
                    {
                        type: 'primary',
                        intl: false,
                        code: '+974',
                        value: null
                    },
                    {
                        type: 'secondary',
                        intl: false,
                        code: '+974',
                        value: null
                    },
                ]
            }
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },



        /* end */
        openDialog(){
            this.syncModal = true
            this.getCategory();
            this.getBrand();
            const localStorageData = localStorage.getItem('user');

         /*    console.log(data, "swaraj data")
            // Parse the JSON data if necessary
            const parsedData = JSON.parse(localStorageData);

            // Update the form with the default selectionss
            this.form.location = parsedData.location.id;
            this.form.sold_by = parsedData.user_id;

            if (this.formStatus == 'update'){
                this.form.name = data.name;

                this.form.email = data.email;
                this.form.attr = data.attr;
                this.form.attr = data.attr['requestAttr']
                this.form.comments = data.comments
                this.form.reminder_time = data.reminder_time

                // Parse the string into an array of numbers
                const brandIds = JSON.parse(data.brand);

                // Map the brand IDs to their corresponding labels and values
                this.form.brand = brandIds.map(brandId => {
                    const brand = this.brands.find(brand => brand.value === brandId);
                    return brand ? { value: brand.value, label: brand.label } : null;
                });
                // Parse the string into an array of numbers
                const categoryIds = JSON.parse(data.category);

                // Map the category IDs to their corresponding labels and values
                this.form.category = categoryIds.map(categoryId => {
                    const category = this.categories.find(category => category.value === categoryId);
                    return category ? { value: category.value, label: category.label } : null;
                });




            } */

        },
        getCategory() {
            var self = this;
            const categories = self.filteredCategoryProvider();

            const formattedCategories = categories.map(category => {
                return {
                    value: category.id,
                    label: category.name
                };
            });

            self.categories = formattedCategories;

            // Log the result for debugging
            console.log(formattedCategories, "Formatted Categories");
        },
        getBrand() {
            var self = this;
            const brands = self.brandProvider();

            const brandItems = brands.map(brand => {
                return {
                    value: brand.code,
                    label: brand.brand
                };
            });

            self.brands = brandItems;
            //
            // // Log the result for debugging
            console.log(brandItems, "Formatted brand");
        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        copyMobileToWhatsApp() {
            if (this.form.sameAsMobile) {
                this.$set(this.form.attr.contacts, 1, JSON.parse(JSON.stringify({...this.form.attr.contacts[0], type: 'secondary'})));
                this.$forceUpdate();
            } else {
                this.$set(this.form.attr.contacts, 1, {
                    type: 'secondary',
                    intl: false,
                    code: '+974',
                    value: null
                });
            }
        },






        onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.setFormStatusCreate();
        this.showError = false
        const localStorageData = localStorage.getItem('user');
        const parsedData = JSON.parse(localStorageData);
        this.form.location_id = parsedData.location.id;
        this.form.sold_by = parsedData.user_id;
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.formStatus == 'create'){
          this.FormResetter();
        }

      },

      onSubmitResend(){

      }



    },

    computed: {

        ...mapGetters('CustomerLogsModule',
      [
        'formStatus',
        'formRecord',
      ]),

        whatsappNumber() {
            if (this.form.sameAsMobile) {
                console.log("Returning form.mobile as whatsappNumber:", this.form.mobile);
                return this.form.mobile;
            } else {
                console.log("Returning form.whatsapp as whatsappNumber:", this.form.whatsapp);
                return this.form.whatsapp;
            }
        },

        showrooms : function(){
            return this.showroomsProvider();
        },

        isReadonly() {
            return this.formStatus === 'view';
        },


    },

    watch: {
      formRecord : function(){
        if(this.formStatus == 'update' || this.formStatus == 'view'){
           const frm = JSON.parse(JSON.stringify(this.formRecord));

           console.log(frm, "form record")

           this.form = {...this.form,...frm}

            /*
            console.log(this.form, "form") */
          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.formStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

        "form.attr.contacts" : {
            handler(v){
                const firstObject  = v[0];
                if(firstObject){
                    this.form.mobile = firstObject.value
                }
               
                if(v[1].value == null || v[1].value == ""){
                    this.form.whatsapp = firstObject.value
                }

            },
            deep : true
        },

        "form.attr.first_name" : {
            handler(v){
                let name  = "";
                if(v){
                  name = `${v} ${this.form.attr.last_name}`
                }
                this.form.name = name
            },
            deep : true
        },

        "form.attr.last_name" : {
            handler(v){
                let name  = "";
                if(v){
                  name = `${this.form.attr.first_name} ${v}`
                }
                this.form.name = name
            },
            deep : true
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
