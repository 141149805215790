<template>
    <div class="component-container">

        <div class="toolbar-container">
            <button class="btn btn-sm btn-elegant" @click="childClick" :disabled="processing">Check Serial Number</button>
        </div>

        <div id="app-vue-spreadsheet" :style="appVueSpreadSheet">
            <div ref="spreadsheetx"></div>
        </div>


        <hsc-window-style-metal>
            <hsc-window title="Generate Serial Number" :resizable="false" :left="0" :top="0" :height="height" style="z-index : 999!important;width:100%" class="export-window" :closeButton="true" :isOpen.sync="dialogIsOpen">
                <div class="toolbar-container">

                    <template v-if="exporDataSaving">
                        <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </template>
                    <template v-else>
                        <!-- <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave">Save</button> -->
                        <button class="btn btn-sm btn-elegant" @click="fnPrint">Print</button>
                        <button class="btn btn-sm btn-elegant" @click="fnPrintSmall">Print 58x39</button>
                        <button class="btn btn-sm btn-elegant" @click="fnPrintSmaller">Print 25x39</button>

                    </template>

                    <template v-if="isSave">

                        <button class="btn btn-sm btn-elegant" @click="fnPrint">Print</button>
                        <button class="btn btn-sm btn-elegant" @click="fnPrintSmall">Print 58x39</button>


                       <button class="btn btn-sm btn-elegant" @click="downloadCsv">Download CSV</button>
                        <button class="btn btn-sm btn-elegant" @click="copyTable">Copy</button>
                    </template>
                    
                </div>
                <div class="window-export-container" :style="appVueSpreadSheet">

                   
                    <div ref="spreadsheetexport" class="demo-x"></div>
                </div>
            </hsc-window>
        </hsc-window-style-metal>

    </div>
</template>

<script>

import moment from 'moment';
import jspreadsheet from '../../jsexcel/jexcel'


import country from './country'

import {mapActions} from 'vuex'
export default {

    data: ()=> ({
        isSave : false,
        appVueSpreadSheet : {
            width : '1300px'
        },
        width : 1300,
        height : 1024,
        dialogIsOpen : false,
        spreadsheet: null,
        spreadsheetexport: null,
        processing: false,
        exporData : [],
        exporDataSaving : false,
        generated_serial : "",
        options : {
            tableOverflow: true,
            tableWidth: "400px",
            tableHeight: "400px",
            wordWrap : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
             data:[[]],
             minDimensions:[2,30],
             columns: [
                {
                    type: 'text',
                    width: '250px',
                    title: 'ItemCode',
                    name: 'item_code',
                    readOnly: false,
                    primaryKey: true
                },
                {
                    type: 'text',
                    width: '200px',
                    title:'Serial',
                    name:'serial'
                },
                
            ],

           /*  contextMenu: function(obj, x, y, e) {
                var items = [];

                if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                }
                return items;
             } */
        },
        exportTableOptions : {
            download : true,
            csvFileName : 'serial-number',
            allowDeleteColumn : false,
            allowRenameColumn : false,
            csvHeader:true,
            tableWidth : '1300px',
            tableHeight : 'calc(100vh - 92px)',
            tableOverflow : true,
             data:[[]],
             minDimensions:[6,35],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'item_code',
                    readOnly: true,
                    primaryKey: true
                },
                {
                    type: 'text',
                    width: '250px',
                    title:'ItemName',
                    name:'item_name',
                    readOnly: true,
                },
                {
                    type: 'dropdown',
                    width: '180px',
                    title:'Origin',
                    name:'origin',
                    autocomplete:true,
                    source:country,
                    multiple:false,
                    readOnly: true,
                },

                {
                    type: 'text',
                    width: '80px',
                    title:'VendorCode',
                    name:'vendor_code',
                    readOnly: true,
                },
                {
                    type: 'calendar',
                    width: '100px',
                    title:'ReceivedDate',
                    name: 'received_date',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '100px',
                    title:'ContainerNumber',
                    name: 'container_number'
                },
                {
                    type: 'numeric',
                    width: '200px',
                    title:'Quantity',
                    name:'quantity',
                    readOnly: true,
                },
                {
                    type: 'number',
                    width: '200px',
                    title:'Sequence',
                    name:'sequence',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '200px',
                    title:'SerialNumer',
                    name:'serial',
                    readOnly: true,
                },
                
                {
                    type: 'text',
                    width: '250px',
                    title:'Barcode',
                    name:'barcode',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '200px',
                    title:'Location',
                    name:'location'
                },
            ],

             contextMenu: function(obj, x, y, e) {
                // var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                } */
                return false;
             }
        }
    }),
    components : {
  
       
    },
    mounted() {
        // this.options.tableWidth = `${window.innerHeight}px`

        this.exportTableOptions.tableWidth = `${window.innerWidth}px`
        this.appVueSpreadSheet.width = `100%`
        this.options.tableWidth = `100%`
        this.options.tableHeight = `calc(100vh - 92.43px)`
        this.spreadsheet = jspreadsheet(this.$refs.spreadsheetx, this.options);

        Object.assign(this, this.spreadsheet);

        
        
        this.height = window.innerHeight;
        /* this.exportTableOptions.allowCopy = false

        this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);

         
            

             this.spreadsheetexport.setWidth(window.innerWidth) */

            //  this.exportTableOptions.options.allowCopy = false

            //  this.exportTableOptions.allowCopy = false

       /*  this.$eventBus.$on('generate', function(){
           
           console.log(spreadsheet.getData());
        }) */

    },
    methods : {

        ...mapActions('SerialNumberModule',['storeRecord','getSerial','validateSerial']),
       async childClick(){
            
            
           const self = this;
            if(this.processing === false){
                this.exporDataSaving = false;
                
                const tableData = this.spreadsheet.getJson();

                let exporDataArray = [];
                if(tableData.length > 0){

                    
                    
                    const filterData = tableData.filter(function(rowElement){
                        return (rowElement.item_code != "" && rowElement.serial != "");
                    })

                    if(filterData.length > 0){
                        this.processing = true;

                        await this.validateSerial({"serial" : filterData}).then((response) => {
                                exporDataArray = response
                                self.processing = false;
                            });

                                if(exporDataArray.length > 0){
                                    this.exporData = exporDataArray
                                    this.exportTableOptions.allowCopy = false
                                    this.dialogIsOpen = true
                                    this.processing = false;
                                }

                    }

                    

                    
                }
    
                
            }
        },

        persistData(){
            const self =this;
            if(this.exporDataSaving == false){
                this.exporDataSaving = true;
                
                const jsonData = this.spreadsheetexport.getJson();

                // console.log(jsonData)

                const filterData = jsonData.filter(function(rowElement){
                        return (rowElement.item_code != "" && rowElement.item_name != "" && rowElement.origin != "" && rowElement.quantity != "" && rowElement.received_date != "" &&rowElement.vendor_code != "" && rowElement.container_number != "");
                    })
                
                this.storeRecord({"serial" : filterData}).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response);
                              self.exporDataSaving = false;
                              self.isSave = true;

                              self.spreadsheetexport.setData(response.serial)

                              const idsToPrint = response.serial_id.map(e=> e.id);
                                  self.generated_serial = idsToPrint.join(',');
                              /* if(response.serial_id.length > 0){
                                  const idsToPrint = response.serial_id.map(e=> e.id);
                                  self.generated_serial = idsToPrint.join(',');

                                  

                              } */
                            }).catch((error) => {
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       });
            }
        },

        downloadCsv(){
            this.spreadsheetexport.download(true);
        },
        destroyx(){
            // this.spreadsheetexport.destroy();
            //this.exportTableOptions.allowCopy = true
            /*  this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);
             this.spreadsheetexport.setWidth(window.innerWidth) */

             this.recreateSpreadsheet();
             this.exportTableOptions.allowCopy = true
             this.createPopupTable(this.exportTableOptions)
             
        },

        copyTable(){
            this.spreadsheetexport.copy()
        },

        createPopupTable(exportTableOptions){
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.exporData)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        fnPrint(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

            const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

               const win = window.open(`serial-label-print?page=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print() 
            }

            console.log(exporData)


           /*  */
        },
        fnPrintSmall(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";


             const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

              const win = window.open(`/showroom/sticker?items=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
            }

            
        },
        fnPrintSmaller(){
            const self = this;
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";


             const exporData = this.exporData.map(function(i){
                return i.id;
            });

            if(exporData.length > 0){
               const arrayToString = exporData.join(',')

              const win = window.open(`/showroom/sticker-small?items=${arrayToString}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
            }

            
        }
    },
    computed: {
       
    },

    watch : {

        dialogIsOpen : function(v){
            if(v === false){
                // this.spreadsheetexport.setData([[]]);
                // this.spreadsheetexport = null;

                this.spreadsheetexport.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }
        }
    }

}
</script>