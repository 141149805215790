<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">
        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="$attrs.labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-select append-to-body search :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" :inputClass="hasError(errors)" v-model="finalOptions" validation ref="select" v-on:getValue="getSelectValue" autocomplete="off" noWrapper bg />
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbSelect } from "mdbvue";
export default {
    name : 'ThatsSelect',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        options: {
            type: Array
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        }
    },
    components : {
        ValidationProvider,
        mdbSelect,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },

        getSelectValue(v){
            this.$emit("input", v);
            this.inputValue = v;
        }
    },
    computed : {
        finalOptions : function () {
            const withSelected = [];
            this.options.forEach(element => {
                if(element.value === this.value){
                    // this.inputValue = element.value
                    element.selected = true
                    withSelected.push(element)
                }else{
                    withSelected.push(element)
                }
            });

            return withSelected;
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>