import Axios from 'axios'
import { bearer, token } from '../helpers'
export const UploadService = {
    
    uploadImage(file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file);

        formData.append("index", file);
        return Axios.post(`/api/upload-image`, formData, {
            headers:
            {
                ...bearer(),
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
        })
    },

    uploadFromDevice(file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file.file);

        let index = null;

        if(file.hasOwnProperty('index')){
            index = file.index
        }
        formData.append("index", index);
        return Axios.post(`/api/upload-from-device`, formData, {
            headers:
            {
                ...bearer(),
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
        })
    },


    uploadFile(file, onUploadProgress) {
        let formData = new FormData();
        formData.append("files", file);
        return Axios.post(`/api/upload-file`, formData, {
            headers:
            {
                ...bearer(),
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
        })
    },

    importFile(endpoint,file, onUploadProgress) {
        let formData = new FormData();
        formData.append("file", file);
        return Axios.post(`/api/${endpoint}`, formData, {
            headers:
            {
                ...bearer(),
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress
        })
    },

    
    async store(object) {
       
        return await Axios.post(`/api/uploads`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', params) {
        console.log(endpoint)
        return await Axios.get(`/api/uploads${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/uploads`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/uploads`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}