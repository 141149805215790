import Axios from 'axios'
import { token } from '../helpers'
import store from '../store';
let request = null;

export const TableService = {
    async get(endpoint = '', object) {
      /*   console.log(request)
        console.log("table request")
        if (request){
            this.cancelRequest()
        };
       

            console.log(axiosSource)
            console.log("cancel token")
          request = { cancel: axiosSource.cancel, msg: "Loading..." }; */

        //   console.log()

        const axiosSource = Axios.CancelToken.source();
        // store.commit('TableModule/addRequest',{ cancel: axiosSource.cancel, msg: "Loading..." })
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object,...token()}

        return await Axios.get(`/api/${endpoint}`, { params: params}).then(response => {
            // this.clearOldRequest("Success");
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
        /* .catch(this.logResponseErrors) */
    },

    cancelRequest(){
        request.cancel();
        this.clearOldRequest("Cancelled");
      },

    logResponseErrors(err) {
        if (Axios.isCancel(err)) {
          console.log("Request cancelled");
        }
      },
      clearOldRequest(msg) {
        request.msg = msg;
        request = null;
    
    }

}

// Axios.interceptors.request.use(request, requestError);