/* eslint-disable no-empty-pattern */
import { ApiHttpService } from '../../services'

import router from '../../router'
export const InventoryTransferPostingModule = {
    namespaced: true,
    state: {

        request: null,
        loading : false,
        form : {},
        requestData : {},
        initFormData : {
            locaton : "",
            name : "",
            date : "",
            items: [],
        },




    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },

        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },

        setFormData(state, payload) {
            state.form = payload
        }
    },
    actions : {
        get({dispatch, commit, state}, params){
            commit('setFormData',{
                ...JSON.parse(JSON.stringify(state.initFormData)),
                ...JSON.parse(JSON.stringify(params))
            })
            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', params).then((res) => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            });
        },
        async asyncGetData({ state, commit}, obj) {
            commit('setLoading', true)
            return await new Promise((resolve, reject) => {
                const params = { ...obj,view : router.app._route.name }
                const configParams = {
                    req : "InventoryTransferPostingModule/req",
                    loadingTrue : "InventoryTransferPostingModule/loadingTrue",
                    addRequest : "InventoryTransferPostingModule/addRequest",
                    cancelRequest : "InventoryTransferPostingModule/cancelRequest",
                    clearOldRequest : "InventoryTransferPostingModule/clearOldRequest"
                }
                ApiHttpService.get(`document/location/${params.id}`, params, configParams).then((response) => {
                    resolve(response.data)
                    commit('setFormData',{
                        ...JSON.parse(JSON.stringify(state.form)),
                        items : response.data.map((item, index) => {
                            return {...item,index : index, success : false, error : false, loading : false}
                        })
                    })
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setLoading', false)
                    reject(error)
                })

            })
        },
        save({dispatch}, params){
            return new Promise((resolve, reject) => {
                dispatch('asyncSave', params).then((res) => {
                    resolve(res)
                }).catch(error => {
                    reject({...error,...params})
                })
            });
        },


        async asyncSave({}, obj) {
            return await new Promise((resolve, reject) => {
                const params = { ...obj,view : router.app._route.name }
                ApiHttpService.postRequest(`document/post/${params.id}`, params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject({...error,...obj})
                })

            })
        },

    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        loading : state => state.loading,
        formData : state => state.form,
        initFormData : state => state.initFormData,
    }
}



