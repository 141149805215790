<template>
      <div class="table-container">
        <div class="p-0 pt-2 pb-1 header-panel-container">
            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">
                <mdb-navbar-brand class="table-header-navbar-brand">
                     <h5 class="module-title mb-0 p-1 pb-0 ml-auto">Transactions</h5>

<!--                    <mdb-tabs class="table-header-tabs" :active="active" default :links="[ { text: 'Loyalty Transactions',slot:'members' }]" @activeTab="getActiveTabIndex"  />-->
                </mdb-navbar-brand>

                <mdb-form-inline class="ml-auto" tag="div">
<!--                <input class="form-control mr-sm-2 table-searchterm-input border border-light" v-model="term" type="text" placeholder="Search" aria-label="Search" style="margin-top: 8px!important;" @keydown.enter.prevent="handleSearchTerm"/>-->


                    <mdb-btn color="elegant" size="sm" @click="handleClearFilterForm"> Refresh</mdb-btn>
                    <template v-if="hasFilterSlot">
                        <mdb-btn color="elegant" size="sm" @click="openFilterDialog" class="ml-0"><i class="el-icon-s-operation"></i> Filter</mdb-btn>
                    </template>

                    <template v-if="newBtn">
                        <mdb-btn color="elegant" size="sm" @click="emitExport"><i class="el-icon-download"></i> Export Excel</mdb-btn>
                    </template>
                    <template v-if="hasButtonRightSlot">
                        <slot name="button-right"></slot>
                    </template>
            </mdb-form-inline>


            </mdb-navbar>
        </div>

          <el-table ref="multipleTable" :border="true" lazy class="custom-element-table" :data="data.rows" :height="height" stripe @selection-change="handleSelectionChange" :show-header="true">
              <el-table-column type="index" width="35" label="#"> </el-table-column>
              <template v-if="multipleSelection">
                  <el-table-column type="selection" width="35"> </el-table-column>
              </template>

              <template v-if="hasCustomColumnsSlot">
                  <slot name="custom-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
              </template>
              <template v-else>
                  <el-table-column v-for="(col, index) in columns" :key="index" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align" :resizable="true">
                      <template slot-scope="scope">
                          <template v-if="col.hasOwnProperty('isAmount') && col.isAmount ==  true">
                              {{ scope.row[col.field] | amount }}
                          </template>
                          <template v-else-if="col.hasOwnProperty('field') && col.field == 'transaction_code'">
                              {{ scope.row.transaction_code}}
                          </template>
                          <template v-else-if="col.hasOwnProperty('field') && col.field == 'name'">
                              {{ scope.row.customer.name }}
                          </template>
                          <template v-else-if="col.hasOwnProperty('field') && col.field == 'tier'">
                          {{ scope.row.tier.name }}
                      </template>

                          <!-- Add other conditions as needed -->
                          <template v-else>
                              {{ scope.row[col.field] }}
                          </template>
                      </template>
                  </el-table-column>
              </template>

              <template v-if="hasRightCustomColumnsSlot">
                  <slot name="custom-right-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
              </template>

              <template slot="empty">
                  <template v-if="loading">
                      <mdb-btn color="elegant" size="sm" rounded disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading data...
                      </mdb-btn>
                  </template>
                  <template v-else>
                      <template v-if="showError">
                          <el-alert :title="errorMessage" type="error" show-icon :closable="false"></el-alert>
                      </template>
                      <template v-else>
                          No Data
                      </template>
                  </template>
              </template>
          </el-table>


        <el-dialog title="Filter" :visible.sync="filterDialog" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form">
            <div class="filter-dalog-body p-4">
                <slot name="filter"></slot>


            </div>

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="handleFilterForm"><i class="el-icon-search"></i> Search</mdb-btn>
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="handleClearFilterForm"><i class="el-icon-close"></i> Clear</mdb-btn>
                </div>
            </div>

        </el-dialog>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        <CoolLightBox
            :items="gallery"
            :index="index"
            :useZoomBar="true"
            @close="index = null">
            </CoolLightBox>


        </div>
</template>
<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside,
    mdbTabs,
    mdbInput,
    mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'
import {token} from '../../helpers/headers'
export default {
    name : "ThatsTable",
    data(){
        return {
            toggle : false,
            token : null,
            active : 0,
            filterDialog : false,
            loading : false,
            gallery : [],
            index : [],
            showError : false,
            errorMessage : null
        }
    },
    props : {
        endpoint : {
            type: String,
            required : true,
            default : 'loyalty-program/transactions'
        },
        columns : {
            type: Array,
            required : true
        },
        height : {
            type: String,
            default : 'calc(100vh - 140px)'
        },
        filterClass : {
            type: String,
            default : 'sf-dropdown-menu'
        },
        importBtn : {
            type: Boolean,
            default : false
        },
        exportBtn : {
            type: Boolean,
            default : false
        },
        multipleSelection : {
            type: Boolean,
            default : true
        },
        newBtn : {
            type: Boolean,
            default : true
        },
        filterFormData : {
            type: Object,
            default : () => ({
                searchTerm : null
            })
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbTabs,
        mdbInput,
        mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
    },
directives: {
      mdbClickOutside
    },
    created: function () {

         this.dispatchHandler()
        const tokenObj = token();

        this.token = tokenObj.token
        this.setTerm ("")
    },

    mounted(){
        const self = this;
        this.$eventBus.$on('created',function(){
        //    self.dispatchHandler();
        location.reload();
        })
        this.$eventBus.$on('reloadTable',function(){
            self.setFilters({});
            //self.refreshTable();
        })
    },

    methods: {
        ...mapActions('LoyaltyTransactionReport',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('LoyaltyTransactionReport',[
            'setLength',
            'setPage',
            'setTerm',
            'setSelection',
            'setFilters',
        ]),

        triggerNewEvent(){
            this.$emit('open','new')
        },
        EmitDeleteModal(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes',self.selection)
                    }).catch(() => {
                        self.$emit('deleteNo')
                    });

        },

        // async dispatchHandler(){
        //     const self = this;
        //     this.loading = true
        //     this.showError = false
        //     await this.asyncGetData(`${this.endpoint}?active=${this.active}`)
        //     .then(() => {
        //         self.loading = false
        //         self.showError = false
        //     })
        //     .catch((error) => {
        //         self.loading = false
        //
        //         if(error.response.data.hasOwnProperty('message')){
        //             self.showError = true
        //             self.errorMessage = error.response.data.message
        //         }
        //
        //     }) ;
        // },

        async dispatchHandler() {
            const self = this;
            this.loading = true;
            this.showError = false;

            try {
                await this.asyncGetData(`${this.endpoint}`);
                self.loading = false;
                self.showError = false;
            } catch (error) {
                self.loading = false;

                if (error.response && error.response.data && error.response.data.hasOwnProperty('message')) {
                    self.showError = true;
                    self.errorMessage = error.response.data.message;
                } else {
                    console.error("Error response structure is unexpected:", error);
                }
            }
        },

        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            // this.dispatchHandler()
            this.dispatchFilter({
                ...this.filterFormData,searchTerm : this.term
            })
        },
        handleSelectionChange(val){
            this.setSelection(val)
        },
        ClearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        handleClick(row){
            this.$emit('row-click',row)
        },
        handleClickTrigger(index,row,trigger){
            this.$emit('viewWindow',{window:trigger,index : index,row : row})
        },

        refreshTable(){
            this.handleClearFilterForm();
        },
        emitStatus(status){
            this.$emit('status',status)
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        toggleDropdown(){
            this.toggle = !this.toggle;
        },

        away(){
            this.toggle = false
        },

        dispatchFilter(filters){
            const self = this;
            this.setFilters(filters)
            this.loading = true
             this.resetData(`${this.endpoint}`)
            .then(() => {
                self.loading = false
                self.showError = false
            })
            .catch((error) => {
                self.loading = false

                if(error.response.data.hasOwnProperty('message')){
                    self.showError = true
                    self.errorMessage = error.response.data.message
                }

            }) ;


        },
        emitImport(){
            this.$emit('import')
        },
        emitExport() {
            const encodedFilterData = encodeURIComponent(JSON.stringify(this.filterFormData));

            const exportUrl = `${this.endpoint}/export?filters=${encodedFilterData}`;

            window.open(exportUrl, '_blank');

        },

        downloadFile(response, filename,ext) {

            var newBlob = new Blob([response.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'})

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = filename + '.xlsx'
            link.click()
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 100)
            },
        openFilterDialog(){
            this.filterDialog = true
        },

        handleFilterForm(){
            // console.log(this.filterFormData)
            this.dispatchFilter(this.filterFormData)
        },
        handleClearFilterForm(){
            this.$emit('clearFilterForm')
            this.$nextTick(() => {
                this.dispatchFilter(this.filterFormData)
            })
        },

        viewSoldTo(row){
            this.$emit('viewSoldTo',row)
        },
        viewRelatedDocument(reference,row){
            this.$emit('viewRelatedDocument',{reference : reference, row : row})
        },
        getActiveTabIndex(value){
            this.active = value
            this.dispatchFilter(this.filterFormData)
        }
    },

    computed: {
        ...mapGetters('LoyaltyTransactionReport',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',

            'selection',
        ]),
        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.setTerm (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },

        hasFilterSlot() {
            return ('filter' in this.$scopedSlots);
        },

        hasButtonRightSlot() {
            return ('button-right' in this.$scopedSlots);
        },

        hasTabsSlot() {
            return ('tabs' in this.$scopedSlots);
        },
        hasCustomColumnsSlot() {
            return ('custom-culumns' in this.$scopedSlots);
        },

        hasRightCustomColumnsSlot() {
            return ('custom-right-culumns' in this.$scopedSlots);
        },

    },

}
</script>

