import { AreaService } from '../../services'
export const AreaModule = {
    namespaced: true,
    state: {
        area : []
    },
    actions: {
         GetArea({ commit }, params = {}) {
            return new Promise((resolve, reject) => {
                AreaService.get('/filter', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    commit('setData',res);
                    resolve(res);
                }).catch(error => {
                    reject(error)
                })
            })
        },

    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },

        setData: (state,payload) => {
            state.area = payload
        },
       

    },
    getters: {
        area : state => state.area
    }
}


