<template>
    <div>
         <ThatsTable title="Local Purchase Delivery Note" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm">  
            <template #filter="{}">
                
                
              

                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="kerwords" vid="searchTerm" name="searchTerm" v-model="filterForm.searchTerm" size="sm" :disabled="false" :rules="''"/>
                <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Doc Date" placeholder="Date" vid="date" name="date" v-model="filterForm.date" :rules="''" size="sm" :disabled="false"/>                  
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="docStatus" :rules="''"></ThatsMultiSelect>

     
            </template> 

            <template #custom-right-culumns="{}">

                <el-table-column :sortable="false" :prop="'id'" label="Vendor">
                    <template slot-scope="scope">
                        
                      {{ scope.row.vendor.series }} - {{ scope.row.vendor.name }}/{{ scope.row.vendor.mobile }}
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150">
                    <template slot-scope="scope">
                        
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ scope.row.status_text}}</mdb-badge>
                        
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" width="120" :prop="'id'" label="PRINT">
                        <template slot-scope="scope">
                            <el-button @click="fnPrintDoc('/inventory-transfer-request/print/'+scope.row.series)" size="small" class="text-info"><i class="el-icon-printer text-info cursor-pointer"></i> PRINT</el-button>
                        </template>
                    </el-table-column>

            </template>

        </ThatsTable>
        <PurchaseDeliveryNoteForm ref="purchaseDeliveryNoteForm" @success="refreshTable"></PurchaseDeliveryNoteForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import PurchaseDeliveryNoteForm from './PurchaseDeliveryNoteForm.vue'

export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,PurchaseDeliveryNoteForm,
        mdbSelect,
        mdbInput,
        ThatsInput
    },
    inject : ['showroomsProvider'],
    provide(){
        return {
            btsApproverProvider : () => this.approverData,
            btsReceiverProvider : () => this.receiverData,
        }
    },
    data(){
        return {
            endpoint : 'local-purchase-delivery-note',
            approver : [],
            receiver : [],
            tableKey : 0,
            filterForm : {
                date : '',
                searchTerm : null,
                location : null,
                approvers : null,
                receiver : null,
                status : null,
            },
            docStatus : [
                {value : 'canceled', label : 'Canceled'},
                {value : 'open', label : 'Open'},
            ]
        }
    },


    created(){

      const self = this;
 
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('LocalPurchaseDeliveryNoteModule',['getRecord']),
       ...mapMutations('LocalPurchaseDeliveryNoteModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),

        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
            this.getRecord(row).then(() => {
                loading.close();
                
                self.$refs.purchaseDeliveryNoteForm.openDialog();
            })
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.purchaseDeliveryNoteForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      clearFilterForm(){
        this.filterForm = JSON.parse(JSON.stringify(
            {
                date : '',
                searchTerm : null,
                location : null,
                approvers : null,
                receiver : null,
                status : null,
            }
        ))
      },


      
    },
    computed: {
        ...mapGetters('LocalPurchaseDeliveryNoteModule',['getLoading', 'columns','initialForm']),
        approverData : function(){
            return this.approver
        },
        receiverData : function(){
            return this.receiver
        },

        statusArrayText : function(){
        
            return {'draft' : 'Draft','open' : 'Open','closed': 'Closed'};
        },
        statusArrayColor : function(){
        
            return {'draft' : 'badge-light','for-approval' : 'warning','open': 'success','canceled' : 'danger'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','for-approval' : 'el-icon-circle-check','save' : 'el-icon-circle-check','open': 'el-icon-success','canceled' : 'el-icon-remove'};
        },

        showroomLocation : function(){

            const showroom = this.showroomsProvider();

            return showroom.map((item) => {
                return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
            },[])
        },
        documentApprover : function(){

            return this.approver.map((item) => {
                return {...item, label : `${item.name}`,value : item.id}
            },[])
        },
        documentReceiver : function(){
            return this.receiver.filter((user) => {
                return ['warehouse','delivery-team','showroom-manager','interior-designers','back-office','administrator'].some(item => user.role_slugs.includes(item)) 
            },[]).map((item) => {
                return {...item, label : `${item.name}`,value : item.id}
                },[])
        },
    },
}


</script>