<template>
    <div class="filter-dalog-body p-0">
        <div class="p-0 header-panel-container border-bottom border-light">

        <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="REPORT" name="1">
<!--                <div class="row">-->
<!--                    <div class="col-md-4">-->
<!--                        <mdb-card class="sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">BRAND</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--                                <canvas id="brand-chart-quotation" width="400" height="400"></canvas>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                    </div>-->
<!--                    <div class="col-md-4">-->
<!--                        <mdb-card class="sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">CATEGORY</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--                                <canvas id="category-chart-quotation" width="400" height="400"></canvas>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->

<!--                    </div>-->
<!--                    <div class="col-md-4">-->
<!--                        <mdb-card class="sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">LOCATION</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--                                <canvas id="location-chart-quotation" width="400" height="400"></canvas>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                    </div>-->
<!--                </div>-->

                <mdbRow class="tab-row">
                    <mdbCol md="4" lg="4" class="pr-0 mr-0 pl-0 ml-0">
                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                <h4 class="dark-grey-text mb-0 card-header-title">BY BRANDS</h4>
                            </mdb-card-body>
                            <mdb-card-body class="p-1">
                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieBrandChartData" :height="400"></PieGraph>
                            </mdb-card-body>
                        </mdb-card>
                    </mdbCol>
                    <mdbCol md="4" lg="4" class="pr-0 mr-0">
                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                <h4 class="dark-grey-text mb-0 card-header-title">BY CATEGORY</h4>
                            </mdb-card-body>
                            <mdb-card-body class="p-1">
                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieCateogryChartData" :height="400"></PieGraph>
                            </mdb-card-body>
                        </mdb-card>
                    </mdbCol>
                    <mdbCol md="4" lg="4" class="pr-0 mr-0">
                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                <h4 class="dark-grey-text mb-0 card-header-title">BY LOCATION</h4>
                            </mdb-card-body>
                            <mdb-card-body class="p-1">
                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieLocationChartData" :height="400"></PieGraph>
                            </mdb-card-body>
                        </mdb-card>
                    </mdbCol>
                </mdbRow>
            </el-collapse-item>
        </el-collapse>

        <br/>
        <div class="table-container pr-0 pl-0">
            <div class="p-0 pt-1 pb-1 header-panel-container">
                <mdb-navbar class="z-depth-0 p-0 pl-1 rounded-sm justify-content-between">
                    <mdb-form-inline class="ml-auto" tag="div">

                        <el-input
                            @keydown.enter.prevent="handleSearchTerm"
                            class="table-searchterm-input"
                            placeholder="Search"
                            v-model="term"
                            clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                        </el-input>
                        <mdb-btn color="elegant" size="sm" @click="handleSearchTerm"><i class="el-icon-search"></i> Search</mdb-btn>
                        <template v-if="hasButtonRightSlot">
                            <slot name="button-right"></slot>
                        </template>
                    </mdb-form-inline>


                </mdb-navbar>
            </div>

            <el-table ref="multipleTable" lazy class="custom-element-table" :data="rows" :height="tableHeight" stripe :sort-change="sortChange">
                <template v-for="col in columns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.clickable">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else-if="col.field === 'print' && scope.row['status'] !== 'cancel' && scope.row['status'] !== 'closed'">
                                <a href="javascript:void(0)" class="btn btn-sm btn-elegant" @click="handleClickPrint(scope.$index, scope.row)">Print</a>
                            </template>
                            <template v-else>
                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                            </template>
                        </template>
                    </el-table-column>
                </template>

                <template slot="empty">
                    <template v-if="loading">
                        <mdb-btn color="elegant" size="sm" rounded disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading data...
                        </mdb-btn>

                    </template>
                    <template v-else>
                        No Data
                    </template>
                </template>
            </el-table>

            <div class="d-flex">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="current_page"
                    :page-sizes="[25,50,100, 200, 300, 400]"
                    :page-size="length"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>

    </div>
    </div>
</template>


<script>
import {
    mdbBtn,
    mdbContainer,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbNavbar,
    mdbFormInline, mdbCardBody, mdbCard, mdbCol, mdbRow

} from "mdbvue";
import { mapGetters, mapActions, mapMutations  } from 'vuex';
import Chart from "chart.js/auto";
import PieGraph from "../../components/dashboard/PieGraph.vue";
export default {
    name : "QuotationTable",
    components: {
        PieGraph, mdbRow, mdbCol,
        mdbCard, mdbCardBody,
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbNavbar,
        mdbFormInline
    },

    props : {
        endpoint : {
            type : String,
            default : ''
        },
        formData: {
            type: Object,
            required: true
        },
        activeTab: {
            type: Number,
            required: true
        }
    },

    data(){
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,
            activeName: "1",
            contactItems:null,
            pieBrandChartData: {},
            pieCtr : 0,
            pieCateogryChartData: {},
            pieLocationChartData: {},
            tableHeight : 'calc(100vh - 328px)'
        }
    },
    created() {
        //
    },
    mounted() {
        // if (this.activeTab === 1) {
        //   this.fetchData();
        // }
        this.getCustomerItemQuotationRecord({ series: this.formData.series })
            .then(response => {
                this.contactItems = response;
                this.renderBrandChart();
                this.renderCategoryChart();
                this.renderLocationChart();
            })
            .catch(error => {
                console.error('Error fetching quotation record:', error);
            });

    },

    methods: {
        ...mapActions('ContactsModule',['getCustomerItemQuotationRecord']),
        ...mapActions('ContactCustomTableModule',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('ContactCustomTableModule',[
            'setLength',
            'setPage',
            'setSearchTerm',
            'setSelection',
            'setEmptyData',
            'setFilters',
        ]),
        fetchData() {
                //
        },
        renderBrandChart() {
            // const ctx = document.getElementById('brand-chart-quotation').getContext('2d');

            // Extract and count brand occurrences from the data
            if (!this.contactItems || !this.contactItems.quotationTransactions) {
                console.error('contactItems or quotationTransactions are not available.');
                return;
            }

            const brandAmounts = this.contactItems.quotationTransactions.reduce((acc, transaction) => {
                transaction.items.forEach(item => {
                    const brandName = item.brand_name;
                    const amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                    if (!acc[brandName]) {
                        acc[brandName] = 0;
                    }
                    acc[brandName] += amount;
                });
                return acc;
            }, {});

            // Sort brands by amount and select the top 10
            const sortedBrands = Object.keys(brandAmounts).sort((a, b) => brandAmounts[b] - brandAmounts[a]).slice(0, 10);
            const amounts = sortedBrands.map(brand => brandAmounts[brand]);

            const colors = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];

            const brandData = {
                labels: sortedBrands.length > 0 ? sortedBrands : ['No Data'],
                datasets: [{
                    data: amounts.length > 0 ? amounts : [1],
                    backgroundColor: sortedBrands.length > 0 ? colors.slice(0, sortedBrands.length) : ['#cccccc'] // Ensure we have enough colors for all labels
                }]
            };

            // new Chart(ctx, {
            //     type: 'pie',
            //     data: brandData,
            //     options: {
            //         responsive: true,
            //         maintainAspectRatio: false
            //     }
            // });
            this.pieBrandChartData = brandData;
        },



        // renderCategoryChart() {
        //     // const ctx = document.getElementById('category-chart-quotation').getContext('2d');
        //
        //     // Extract and count category occurrences from the data
        //     if (!this.contactItems || !this.contactItems.quotationTransactions) {
        //         console.error('contactItems or quotationTransactions are not available.');
        //         return;
        //     }
        //
        //     const categoryAmounts = this.contactItems.quotationTransactions.reduce((acc, transaction) => {
        //         transaction.items.forEach(item => {
        //             const categoryName = item.category_name;
        //             const amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
        //             if (!acc[categoryName]) {
        //                 acc[categoryName] = 0;
        //             }
        //             acc[categoryName] += amount;
        //         });
        //         return acc;
        //     }, {});
        //
        //     // Prepare the data for the chart
        //     const labels = Object.keys(categoryAmounts);
        //     const amounts = Object.values(categoryAmounts);
        //     const colors = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];
        //
        //     const categoryData = {
        //         labels: labels.length > 0 ? labels : ['No Data'],
        //         datasets: [{
        //             data: amounts.length > 0 ? amounts : [1],
        //             backgroundColor: labels.length > 0 ? colors.slice(0, labels.length) : ['#cccccc'] // Ensure we have enough colors for all labels
        //         }]
        //     };
        //
        //     // new Chart(ctx, {
        //     //     type: 'pie',
        //     //     data: categoryData,
        //     //     options: {
        //     //         responsive: true,
        //     //         maintainAspectRatio: false
        //     //     }
        //     // });
        //     this.pieCateogryChartData = categoryData
        // },
        renderCategoryChart() {
            // const ctx = document.getElementById('brand-chart-quotation').getContext('2d');

            // Extract and count brand occurrences from the data
            if (!this.contactItems || !this.contactItems.quotationTransactions) {
                console.error('contactItems or quotationTransactions are not available.');
                return;
            }

            const categoryAmounts = this.contactItems.quotationTransactions.reduce((acc, transaction) => {
                transaction.items.forEach(item => {
                    const brandName = item.category_name;
                    const amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                    if (!acc[brandName]) {
                        acc[brandName] = 0;
                    }
                    acc[brandName] += amount;
                });
                return acc;
            }, {});

            // Sort brands by amount and select the top 10
            const sortedCategorys = Object.keys(categoryAmounts).sort((a, b) => categoryAmounts[b] - categoryAmounts[a]).slice(0, 10);
            const amounts = sortedCategorys.map(brand => categoryAmounts[brand]);

            const colors = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];

            const categoryData = {
                labels: sortedCategorys.length > 0 ? sortedCategorys : ['No Data'],
                datasets: [{
                    data: amounts.length > 0 ? amounts : [1],
                    backgroundColor: sortedCategorys.length > 0 ? colors.slice(0, sortedCategorys.length) : ['#cccccc'] // Ensure we have enough colors for all labels
                }]
            };

            // new Chart(ctx, {
            //     type: 'pie',
            //     data: brandData,
            //     options: {
            //         responsive: true,
            //         maintainAspectRatio: false
            //     }
            // });
            this.pieCateogryChartData = categoryData;
        },


        renderLocationChart() {
            // const ctx = document.getElementById('location-chart-quotation').getContext('2d');
            // Extract and sum transaction_amount for each location prefix from the data
            if (!this.contactItems || !this.contactItems.quotationTransactions) {
                console.error('contactItems or quotationTransactions are not available.');
                return;
            }

            const locationAmounts = this.contactItems.quotationTransactions.reduce((acc, item) => {
                const locationPrefix = item.location_prefix;
                const transactionAmount = item.transaction_amount || 0; // Use 0 as default if transaction_amount is missing
                if (!acc[locationPrefix]) {
                    acc[locationPrefix] = 0;
                }
                acc[locationPrefix] += transactionAmount;
                return acc;
            }, {});

            // Prepare the data for the chart
            const locations = Object.keys(locationAmounts);
            const amounts = locations.map(location => locationAmounts[location]);

            const colorMapping = {
                'TLDFC': '#4A52E8',
                'TLTM': '#D68A8A',
                'CATM': '#000000',
                'EZTM': '#4A52E8',
                'TADFC': '#800080',
                'TATM': '#4A52E8',
                'BHDFC': '#800020',
                'BHTM': '#4A52E8',
                'MPDFC': '#4A52E8',
                'ECDFC': '#FF6B6B',
                'ECTM': '#E6E6FA',
                'AMDFC': '#CD7F32',
                'AMTM': '#228B22',
                'TLPE': '#DAA520',
                'TLQA': '#1E90FF',
                'TLKD': '#FFD700',
                'AHTM': '#36454F',
                'TLB2B': '#4A52E8',
                'TLID': '#008080',
                'AHDFC': '#DC143C',
                'TLWEB': '#808080',
                'BMDFC': '#D2691E',
                'TCPL': '#4B0082',
                'RLDFC': '#F5F5F5',
                'ELDFC': '#4A52E8',
                'CADFC': '#9932CC'
            };

            const locationData = {
                labels: locations.length > 0 ? locations : ['No Data'],
                datasets: [{
                    data: amounts.length > 0 ? amounts : [1],
                    backgroundColor: locations.length > 0 ? locations.map(location => colorMapping[location] || '#cccccc') : ['#cccccc']
                }]
            };

            // new Chart(ctx, {
            //     type: 'pie',
            //     data: locationData,
            //     options: {
            //         responsive: true,
            //         maintainAspectRatio: false
            //     }
            // });
            this.pieLocationChartData = locationData
        },

        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            this.$emit('delete')
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        },
        handleClick(index,row){

            this.$emit('open',{value : row, index : index});
        },
        handleSelectionChange(v){
            this.selected = v.map(i => i.series)
        },
        mergeDialog(){
            this.$emit('merge',this.selected);
        },

        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },

        clearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        refreshTable(){
            this.resetData(this.endpoint);
        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },

        sortChange(col,prop, order){
            console.log("sortChange")
            console.log(col)
            console.log(prop)
            console.log(order)
        },
        handleClickPrint(index,row){
            this.$emit('print',{value : row, index : index, origin : 'quotation'});
        },

        handleSearchTerm() {
            this.dispatchFilter({
                ...this.filters,
                searchTerm : this.term
            })
        },

        dispatchFilter(filters){
            this.setFilters(filters)
            this.resetData(`quotations`);
        },


    },

    computed : {
        ...mapGetters('ContactCustomTableModule',['rows','loading','selection','searchTerm','length','total','page','filters']),
        ...mapGetters('TransactionModule',['columns']),
        term : {
            get : function(){
                return this.searchTerm
            },
            set : function(val){
                this.setSearchTerm (val)
            }
        },
        selected : {
            get : function(){
                return this.selection
            },
            set : function(val){
                this.setSelection (val)
            }
        },
        current_page : {
            get : function(){
                return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },

        hasButtonRightSlot() {
            return ('button-right' in this.$scopedSlots);
        },
    },
    watch: {
        activeTab(newVal) {
            if (newVal === 1) {
                this.getCustomerItemQuotationRecord({ series: this.formData.series })
                    .then(response => {
                        this.contactItems = response;
                        this.renderBrandChart();
                        this.renderCategoryChart();
                        this.renderLocationChart();
                    })
                    .catch(error => {
                        console.error('Error fetching quotation record:', error);
                    });
                console.log("Quotation Table", newVal)
            }
        }
    },

}

</script>

<style >
.scrollbar {
    position: relative;
    height: 422px;
    width: 100%;
    overflow-y: auto;
    border-bottom: 1px solid #ddd;
}

</style>
<style scoped lang="scss">
.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}
</style>
