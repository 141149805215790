import { ApiGetServiceVer2 } from '../../services'
export const QuotationTableModule = {
    namespaced: true,
    state: {
        data: {
            columns: [],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },
    },
    actions : {
        async resetData({dispatch}, endpoint){
            dispatch('asyncGetData', endpoint)
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "QuotationTableModule/req",
                        loadingTrue : "QuotationTableModule/loadingTrue",
                        addRequest : "QuotationTableModule/addRequest",
                        cancelRequest : "QuotationTableModule/cancelRequest",
                        clearOldRequest : "QuotationTableModule/clearOldRequest"
                    }

                    ApiGetServiceVer2.get(endpoint, params, configParams).then((response) => {
                        commit('setColumns', response.data.columns)
                        commit('setPage', response.data.data.current_page)
                        commit('setTotal', response.data.data.total)
                        commit('setRows', response.data.data.data)
                        commit('setLoading', false)
                        resolve(response.data.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
    }
}



