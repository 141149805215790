export const ContactPersonModule = {
    namespaced: true,
    state: {
        loading: false,
       
        formStatus: 'create',
        formRecord: {},
        formRecordUpdate: {},
        formInit : {
            name : null,
            mobile : null,
            attr : {
  
              address: null,
              address2 : {
                  street : null,
                  building : null,
                  zone : null,
                  area : null,
                  city : null,
              },
              designation : null,
              email : null,
              first_name : null,
              middle_name : null,
              last_name : null,
              salutation : null,
              contacts : [
                  {
                      type : 'primary',
                      intl : false,
                      code : '+974',
                      value : null
                  },
                  {
                      type : 'secondary',
                      intl : false,
                      code : '+974',
                      value : null
                  },
                  {
                      type : 'home',
                      intl : false,
                      code : '+974',
                      value : null
                  }
              ]
            }
          }

    },
    actions: {
    },
    mutations: {
      

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
            state.formRecord = JSON.parse(JSON.stringify(state.formInit))
        },

        SetFormStatusUpdate: (state,payload) => {
            state.formStatus = 'update'
            state.formRecord = JSON.parse(JSON.stringify(payload))
        },
    
       
    },
    getters: {
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
    }
}


