import { ApiService } from '../../services'
export const UpdateLocationModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        loading: false,
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},    
    },
    actions: {
     
        updateRecord({ state, commit }, params) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update(`${params.endpoint}`,params.payload).then((response) => {
                        commit('setPutLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
       
        
    },
    mutations: {
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setFormRecord: (state,payload) => {
            state.formRecord = payload
        },
    },
    getters: {
        putLoading: state => state.putLoading,
        formRecord: state => state.formRecord,
    }
}


