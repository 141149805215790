<template>
    <mdb-card class="border border-light">
        <mdb-card-body class="p-3">
            <slot></slot>
        </mdb-card-body>
    </mdb-card>
</template>
<script>
	import { mdbCard, mdbCardBody } from 'mdbvue';
	export default {
		name: 'CardPage',
		components: {
			mdbCard,
			mdbCardBody,
		}
    }

</script>