<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Brands" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :width="900" :group="group" :formStatus="FormStatus" @dialog-state="dialogState">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
              <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-4">
             <mdb-row >

              <mdb-col col="6">
                  <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="Brand ID" vid="short" name="short" v-model="form.short" :validated="validated" :rules="''" size="sm" :disabled="true"/>
                  <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Brand Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Address" vid="address" name="address" v-model="form.attr.address" :validated="validated" :rules="'required'" size="sm" />
                  <thats-fieldset class="mt-1">
                    <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Contact Person" vid="contact_person" name="contact_person" v-model="form.attr.contact_person" :validated="validated" :rules="''" size="sm" />
                    <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Email" vid="email" name="email" v-model="form.attr.email" :validated="validated" :rules="'email'" size="sm" />
                    <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Contact_number" vid="contact_number" name="contact_number" v-model="form.attr.contact_number" :validated="validated" :rules="''" size="sm" />
                  </thats-fieldset>
                  <ThatsCheckbox :label="'Hidden'" vid="brand-hidden-status" orientation="inline" name="brand-hidden-status" :data="[]" v-model="form.hidden" :validated="validated" :rules="''" size="sm" :multiple="false" />
              </mdb-col>

              <mdb-col col="6 pl-0">

                 <mdb-row >

                  <mdb-col col="9">
                    <thats-fieldset>
                    <label>Supplier Website Login</label>
                      <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Website" vid="website" name="website" v-model="form.attr.website" :validated="validated" :rules="''" size="sm" />
                      <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Username" vid="username" name="username" v-model="form.attr.username" :validated="validated" :rules="''" size="sm" />
                      <ThatsInput  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Password" vid="password" name="password" v-model="form.attr.password" :validated="validated" :rules="''" size="sm" />
                    </thats-fieldset>
                  </mdb-col>
                  <mdb-col col="3 pl-0">

                    <ThatsImage @open-form="uploadImage" v-model="form.logo"></ThatsImage>

                    <label @click="uploadImage" class="click-to-add-logo">Click to Add logo</label>

                  </mdb-col>
                 </mdb-row> 

                 <label>Select Area</label>

                <ThatsCheckbox vid="brandarea" orientation="inline" name="brandarea" :data="area" v-model="form.area" :validated="validated" :rules="'arearequired'" :show_error="true"/>
              </mdb-col>
             </mdb-row>
         
          
          </mdbContainer>
        
       </ThatsWindow>

      <ImageGrid ref="upload" :multiple="false" :moduleForm="'brand-form'" @select="fnImageSelect" :group="(group + 1)"></ImageGrid>

  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsImage} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid'
// import ImageUpload from '../../components/image/ImageUpload'
import { required, confirmed, length, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("arearequired", {
    ...required,
    message: "Area field is required"
});

export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsImage,
        mdbBtn,
        mdbRow,
        // ImageUpload,
        ImageGrid,
        mdbCol  
        
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    
    data : () => ({
      validated : false,
      activePill : 0,
      getActiveTabIndex : 0,
      form : null,
      file : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      SelectValue : false,

      options : [],
      area : [],
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();

      } 
      this.areaHandle();
    },
    methods: {
      ...mapActions('AreaModule',[
            'GetArea'
        ]),
      ...mapActions('BrandModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
      ...mapMutations('BrandModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          name : null,
          short : null,
          attr : {
            address : null,
            contact_person : null,
            email : null,
            contact_number : null,
            website : null,
            username : null,
            password : null,
          },
          logo : [],
          area : [],
          status : 'true',
          hidden : false
        }
      },
/* start */
      areaHandle(){
        this.GetArea().then(response => this.area = response);
      },
      
      uploadImage(){
        this.$refs.upload.openDialog();
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.logo = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let [,text] = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
        }
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      }

    },

    computed: {

      ...mapGetters('BrandModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
          if(this.form.attr === null){
              this.form.attr = {
                address : null,
                contact_person : null,
                email : null,
                contact_number : null,
                website : null,
                username : null,
                password : null,
              }
          }
          // this.form.other_details = JSON.parse(this.form.other_details);
        }
      },

      "form.name" : function(val) {

        if(val !== null){
          const res = val.substring(0, 4)
          this.form.short = res.toUpperCase();
        }
      }

      /* area : {
        immediate : true,
        handler(){
          this.areaHandle();
        }
        
      } */
    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>