<template>
<hsc-window-style-metal>
    <hsc-window title="Data Integration" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">
      <mdb-tabs class="table-tabs mb-2 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex">
             <template v-if="data.length > 0">
                 <template v-for="(arr,index) in data" :slot="arr.slug">
                     <el-table :key="index" :ref="'importTable'+index" lazy class="custom-element-table" :data="arr.rows" stripe :height="tableHeight" :row-class-name="errorClassName">
                             <template v-for="col in arr.columns">
                                    <el-table-column v-bind:key="col.field" :width="col.width" :prop="col.field" :label="col.label">
                                        <template slot-scope="scope">
                                            <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                                        </template>
                                    </el-table-column>
                                </template>
            
            
                            <template slot="empty">
                                No Data
                            </template>
                        </el-table>
                 </template>
                   
            </template> 
      </mdb-tabs>
      <mdb-container>
            <div class="d-flex">
                 <template v-if="loading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <button class="btn btn-sm btn-elegant" type="button" @click="importData">Import</button>
                  <button class="btn btn-sm btn-grey" type="button" @click="closeDialog">Cancel</button>
                </template>
            </div>
        </mdb-container>

    </hsc-window>
</hsc-window-style-metal>
</template>

<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside,
     mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane, mdbAlert 
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'

export default {
    name : "TableImport",
    data(){
        return {
            toggle : false,
            current_page : 1,
            x : 0,
            y : 0,
            dialogIsOpen : false,
            active : 0
        }
    },



    props : {
        data : {
            type: Array,
            required : true
        },

        tableHeight : {
            type: String,
            default : 'calc(100vh - 130px)'
        },

         group : {
            type : Number,
            default : 1
        },
        height : {
            type : Number,
                default : 600
        },
        width : {
            type : Number,
                default : 1024
        },
        loading : {
            type : Boolean,
            default : false
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
         mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane,
         mdbAlert
    },
directives: {
      mdbClickOutside
    },

    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        handleSizeChange(val) {
            
        },
        handleCurrentChange(val) {
            
        },
        handleSearchTerm() {
           
        },
        handleSelectionChange(val){
            
        },
        ClearSelected(){
           
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        importData(){
            this.$emit('import',this.data)
        },
        getActiveTabIndex(index){
            this.active = index
        },

        errorClassName({ row }) {

        if (row.hasOwnProperty('message')) {
            return 'error-row'
        }
        return ''
        },

        

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

    computed : {
        tabs : function(){
            let tabsArray = []
                if(this.data.length > 0){
                    tabsArray = this.data.map((item) => {
                        return {text : item.name, slot : item.slug}
                    })

                    /* { text: 'Sales Details', slot: 'form-slot' } */
                }
            return tabsArray
        }
    }

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}

.tab-content{
    padding: 0
}
.alert{
    border-radius : 0!important;padding : .25rem 1.25rem!important;
}
</style>