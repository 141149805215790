<template>
    <ValidationProvider :vid="vid" :name="name" :rules="rules" v-slot="{invalid,errors}">
        <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass + ' flex-row'">
                <div class="md-form mt-0">
                <input type="text"
                    ref="input"
                    class="form-control text-right float-form"
                    :disabled="disabled"
                    :name="name"
                    :is-valid="invalid"
                    :placeholder="$attrs.placeholder"
                    v-on:input="updateValue($event.target.value)"
                    v-on:keydown="checkKeyCode($event)"
                    v-on:focus="onFocusFormat($event.target.value)"
                    v-on:blur="onBlurFormat($event.target.value)"
                    :value="value"
                    v-bind:class="{'is-invalid' : hasError(errors)}"
                    >
                    <template v-if="show_error == true">
                        <span class="errors">{{errors[0]}}</span>
                    </template>
                </div>
                <span class="unit p-1">QAR</span>
            </div>

            
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
export default {
    name : "ThatsFloat",
    props : {
        vid: String,
        name: String,
        rules : {
            type : [Object, String],
            default : 'required'
        },
       
        value: {
            required: true,
            type: [Number, String],
            default: 0
        },

        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        disabled : {
            type : Boolean,
            default : false
        },
        keycode : {
            type : Boolean,
            default : true
        }

    },
    components : {
        ValidationProvider,
        mdbInput
    },

    data : ()=>({
        inputValue : 0
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (isInvalid === true && this.validated === true)) ? true : false
        },

       updateValue: function(cVal) {

        this.$emit("input", unformat(cVal));
        },
        checkKeyCode(evt) {
            if(this.keycode){

                var keyCode = evt.which ? evt.which : event.keyCode;

                if(evt.ctrlKey === true && (keyCode == 65 || keyCode == 97)){
                    return true;
                }else{
    
                    if ((keyCode > 47 && keyCode < 58) ||
                        keyCode === 8 ||
                        keyCode === 9 ||
                        keyCode === 173 ||
                        keyCode === 37 ||
                        keyCode === 39 ||
                        keyCode === 190 || (keyCode > 90 && keyCode < 106) || keyCode === 109 || keyCode === 91 || keyCode === 92)  {
                            return true;
                    }

                }
            evt.preventDefault();
            }
        },

        hasError(errors){
            return (errors.length > 0) ? true : false
        },

        onFocusFormat(v){
            // alert("focus")
            this.inputValue = unformat(v)
        },
            // const fm = formatMoney(this.value, { symbol: "", precision: 2, thousand: ",", decimal: "." });
        onBlurFormat(v){
            // alert(1)
            const fm = formatMoney(v, { symbol: "", precision: 0, thousand: ",", decimal: "." });
            // alert("blur")
            // this.$emit("input", fm);
            this.inputValue = fm;
        }
    },
    
  created() {
    if (this.value) {
    //   this.inputValue = this.value.toString();
      this.inputValue = unformat(this.value)
    }

    
  },

   watch: {

        inputValue(newVal) {

            this.$emit("input", newVal);
        },

    },


}
</script>

<style scoped>
.flex-row{
    display: flex;
}

.thats-form .md-form .form-control.float-form {
    padding: 0.2rem 10px 0.2rem 10px !important;
}
</style>