/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format goods-receipt-note " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <XWindow ref="window" :title="`Goods Receipt Note (${form.status.toUpperCase()})`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>
              <!-- <template v-if="formLoading || getLoading || onPasteLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template> -->

          

              <template v-if="formLoading || findLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>

                        <template v-if="form.status == 'open'"> 
                          <template v-if="readOnly == false">
                            <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('open'))"><i class="el-icon-success"></i> Save</mdb-btn> 
                            <template v-if="formStatus == 'update'">
                                <template v-if="formOriginal.hasOwnProperty('attr') && formOriginal.attr.shipment_status == 'W'">
                                  <mdb-btn color="elegant" size="sm"  @click="passes(updateRecordStatus('closed','Closing a document is irreversible. Are you sure you want to continue?'))"><i class="el-icon-close"></i> Close Document</mdb-btn>                        
                                </template>

                                <template v-if="formOriginal.hasOwnProperty('attr') && ['S','P'].includes(formOriginal.attr.shipment_status)">
                                  <mdb-btn color="elegant" size="sm"  @click="passes(updateRecordStatus('canceled'))"><i class="el-icon-close"></i> Cancel Document</mdb-btn>                        
                                  </template>
                                

                              
                              
                            </template>                       
                          </template>
                        </template>

                        <template v-if="formStatus == 'update'">
                          <mdb-btn color="elegant" size="sm"  @click="openHistory"><i class="el-icon-success"></i> History</mdb-btn>   
                        </template>

                       <!--  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('draft'))"><i class="el-icon-success"></i> Save as draft</mdb-btn> -->
                      <!--   <template v-if="formStatus == 'update'">
                          <mdb-btn color="elegant" icon="printer" size="sm" @click="fnPrintDoc('/inventory-transfer-request/print/'+form.series)"><i class="el-icon-printer"></i> PRINT</mdb-btn>
                        </template> -->
                      </template>
     
                      
          </template>

            <template #right-button>
            </template>

            <span class="invoice-number">Document No.: {{form.series}} </span>

            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
             
                <mdb-row class="tab-row">
                  <mdb-col col="6" class="tab-col">
<!-- :editButton="true" @edit="editCustomer" @select="getCustomerSelected($event)" :addButton="true" @newWindow="openCustomer"  -->

                    <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Vendor Code" v-model="form.vendor_id" :reduce="vendor => vendor.value" :reduceLabel="'opt'" :options="vendorOptions" :rules="'required'" :clearable="false" @onSearch="searchVendor" :selected_option="true" @select="getVendorSelected($event)" :disabled="true"></ThatsCustomMultiSelect>
                    <ThatsMultiSelect :show_error="true" :multiple="false" :clearable="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Status" v-model="form.attr.shipment_status" :reduce="label => label.value" :reduceLabel="'label'" :options="userDefinedDocStatus" :rules="''" :disabled="readOnly"></ThatsMultiSelect>
                    <!-- 
                      <ThatsMultiSelect :show_error="true" :multiple="false" :clearable="false" labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="form.attr.shipment_status" :reduce="label => label.value" :reduceLabel="'label'" :options="userDefinedDocStatus" :rules="''" :disabled="readOnly"></ThatsMultiSelect>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Vendor Name" vid="vendor_name" name="vendor_name" v-model="form.vendor.display" size="sm" :disabled="true" :rules="'required'"/> -->
                    </mdb-col>   
                    <mdb-col col="6" class="tab-col">
                      <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="ETA" placeholder="ETA" vid="date" name="date" v-model="form.attr.shipment_eta" :validated="validated" :rules="'required'" size="sm" :disabled="readOnly"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Container Numer" vid="container_number" name="container_number" v-model="form.attr.container_number" size="sm" :disabled="readOnly" :rules="'required'"/>
                    </mdb-col>
                  </mdb-row>
                <mdb-row class="tab-row">
                  <mdb-col col="12" class="tab-col">

                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Vendor Ref. No." vid="remarks" name="remarks" v-model="form.description" size="sm" :disabled="readOnly" :rules="'required'"/>
                  </mdb-col>
                  </mdb-row>
                <div ref="spreadsheet" class="bts-window-form mt-1" v-bind:style="{'height': jexcelContainer.height}"></div>
              </template>
              
             

              
            </mdb-tabs>
            
          </XWindow>
    
  </ValidationObserver>
  <HistoryDialog ref="historyDialog"></HistoryDialog>

  <CoolLightBox 
            :items="gallery" 
            :index="index"
            :useZoomBar="true"
            @close="index = null">
            </CoolLightBox>
    <UploadFromDevice ref="uploadRef"></UploadFromDevice>
  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer, mdbAlert } from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker, UploadFromDevice, ThatsCustomMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';
import HistoryDialog from "../../components/thats/HistoryDialog.vue";
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },

    readOnly : {
      type : Boolean,
      default : false
    },
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        UploadFromDevice,
        ThatsCustomMultiSelect,
        mdbAlert,
        HistoryDialog
  },
    
  data(){

    const self = this;

    return {
        lastColumnUpdated : null,
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        alertObject : {
            show : false,
            message : null,
            appearance : 'success'
        },
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        formOriginal : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        jexcelContainer : {
            height : 'calc(100vh - 165px)'
        },
        spreadsheetApplyLoading: false,
        spreadsheet: null,
        tabs : [
                { text: 'Details', slot: 'form-slot' },
              ],
        userDefinedDocStatus : [
                { label: 'In Shipment', value: 'S' },
                { label: 'In Port', value: 'P' },
                { label: 'In Warehouse', value: 'W' },
                { label: 'Received', value: 'R' },
              ],

        gallery : [ "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg" ],
        index : null,

        vendorOptions : [],
        inventoryTransferOptionsFrom : [],
        inventoryTransferOptionsTo : [],
        inventoryTransferItems : [],
        spreadsheetOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowInsertColumn : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : true,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 250px)',
            tableOverflow : true,
            data:[],
             minDimensions:[6,10],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],

            
            updateTable:function(instance, cell, col, row, val, label, cellName) {
                if((row % 2) == 1){
                    cell.parentNode.style.backgroundColor = '#FAFAFA';
                }
               
                if (col == 2) {
                  
                  if(val != null && val != ''){
                    cell.innerHTML = '<img src="' + val + '" style="width:60px;height:60px">';
                  }else{
                    cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:60px;height:60px">';
                  }
                }



            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                  if(['create'].includes(self.formStatus)){

                      if (obj.options.allowInsertRow == true) {
                          items.push({
                              title:obj.options.text.insertANewRowBefore,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y), 1);
                              }
                          });
                          
                          items.push({
                              title:obj.options.text.insertANewRowAfter,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y));
                              }
                          });
                      }
    
                      if (obj.options.allowDeleteRow == true) {
                          items.push({
                              title:obj.options.text.deleteSelectedRows,
                              onclick:function() {
                                  obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                              }
                          });
                      }
                  }

                        /* if (obj.options.allowDeleteRow == true) {
                        items.push({
                            title:obj.options.text.deleteSelectedRows,
                            onclick:function() {
                                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                            }
                        });

                    } */
                return items;
             },

             onblur: function(instance){
              self.setPasteLoadingFalse()
             },

             onbeforechange : function(domElement, domElmentCell, column, row, value,oldValue){
              console.log(`before`)
              console.log(`row = ${row}, colum = ${column} , value = ${value}`)
              console.log(`endOfbefore`)
             },
             onchange: function(domElement, domElmentCell, column, row, value,oldValue){

  
              if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
                  console.log(`col = ${column}, row = ${row}, value = ${value}, oldValue = ${oldValue}`)
              }
              

             
              

                
                if(column == 1){
                    // self.spreadsheet.setValueFromCoords(1,row,'https://media.thatsliving.com/public/uploads/image-jpeg/thumb-46a757cc2eaf5b00ff32dd6f93468ddf-cla-016-123-hr.jpg',true)
                    

                    console.log(`${value} ====> ${oldValue} ==== row ${row}`)
                    if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
                      self.fnGetItem(value,row)
                    }
                }


                //Quantity
                if(column == 5 || column == 6 || column == 8){

                    console.log("column")
                    console.log(column)
                    if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
                     self.fnCalculateRow(row)
                    }
                }

                if(column == 9){
                  if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){

                   
                    self.fnCalculateTotalColumn(row)
                               
                  }
                }

       


             },

             onselection : function(){

             },
             onafterchanges : function(){
              self.setPasteLoadingFalse()
             },
             onpaste : function(){
               self.setPasteLoadingFalse()
              
             },
             onbeforechange : function(){
              self.setPasteLoadingTrue()
             }
        
       }
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        
       this.resetForm();

        if(this.isMaximized){
          this.width = window.innerWidth;
          this.height = window.innerHeight;
          this.spreadsheetOptions.tableWidth = `${window.innerWidth - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 239px)`
          this.jexcelContainer.height = `calc(100vh - 239px)`

        }else{
          this.width = 1300;
          this.height = 600;

          this.spreadsheetOptions.tableWidth = `${1300 - 10}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 250px)`
          this.jexcelContainer.height = `calc(100vh - 260px)`
        }

        
  },

  mounted(){
    const self = this
    
  },


  methods: {
    ...mapActions('BusinessPartnerModule',['asyncSearchData']),
    ...mapActions('ItemMasterModule',['getItem']),
    ...mapMutations('GoodsReceiptNoteModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapActions('GoodsReceiptNoteModule',['storeRecord','updateRecord','getRecord']),
    ...mapMutations('HistoryModule',[
        'setModule',
        'setRecordId',
      ]),

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                tbo: false,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false
              }      
      },


    async setPasteLoadingTrue(){
      await this.$nextTick();
      this.onPasteLoading = true
      this.$forceUpdate();
      console.log("paste loading true")
    },
    async setPasteLoadingFalse(){
      await this.$nextTick();
      this.onPasteLoading = false
      this.$forceUpdate();
      console.log("paste loading false")
    },

    onResize({width,height}){


     
        
        if(!Number.isNaN(width) && !Number.isNaN(height)){
          console.log("update")
          console.log(width)
          console.log(height)


          if(width > 1300){
            this.spreadsheetOptions.tableWidth = `${width - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 239px)`
            this.jexcelContainer.height = `calc(100vh - 239px)`
            console.log(`calc(100vh - 165px)`)

            this.width = 1200;
            this.height = 800;
          }else{
            this.spreadsheetOptions.tableWidth = `${1300 - 10}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 250px)`
            this.jexcelContainer.height = `calc(100vh - 260px)`
            console.log(`calc(100vh - 203px)`)
          }
   
          this.spreadsheetOptions.style = this.spreadsheet.getStyle();
          const tableData = this.spreadsheet.getJson();
          
          this.spreadsheet.destroy();
          this.spreadsheetOptions.data = tableData

            this.setColumnProperty()

            if(['update'].includes(this.formStatus)){
              this.spreadsheetOptions.minDimensions = [6,this.form.items]
            }else{
              this.spreadsheetOptions.minDimensions = [6,10]
            }

            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, this.spreadsheetOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)
            

        }
        

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(saveAs = 'save'){
       const self = this;
            self.$refs.observer.validate().then((isValid) => {
              if(isValid){
                 const tableData = this.spreadsheet.getJson();
                  if(tableData.length > 0){
                      const checkRow = tableData.filter((item) => item.code !== '',[]);
                      let noQuantity = 0;
                      let noDescription = 0;
                      let noUnitPrice = 0;
                      let invalidRows = 0;
                      const invalidRowIndes = [];
                      checkRow.forEach((element, index) => {
                        if(element.valid == false){
                          invalidRows++;
                          invalidRowIndes.push(index+1)
                        }
                          if(element.quantity == '' || element.quantity <= 0){
                            noQuantity++;
                          }

                          if(element.name == ''){
                            noDescription++;
                          }
                         /*  if(element.unit_price == '' || element.unit_price < 0){
                            noUnitPrice++;
                          } */
                      });
                      
                      if(invalidRows > 0){
                              MessageBox({
                                  title : 'Error',
                                  message: `Submission not possible for Row(s) ${invalidRowIndes.toString()}: Items not found in master list.`,
                                  type: 'error'
                              })
                          return false;
                      }

                      if(noDescription > 0){
                              MessageBox({
                                  title : 'Error',
                                  message: 'Item description is required.',
                                  type: 'error'
                              })
                          return false;
                      }

                      if(noQuantity > 0){
                              MessageBox({
                                  title : 'Error',
                                  message: 'Items quantity must be greater than zero.',
                                  type: 'error'
                              })
                          return false;
                      }

                     /*  if(noUnitPrice > 0){
                              MessageBox({
                                  title : 'Error',
                                  message: 'Invalid Unit price.',
                                  type: 'error'
                              })
                          return false;
                      } */

                      const getValidData = checkRow.filter((row)=> {
                          return (row.quantity != '' && row.name != '')
                      },[])

                      if(getValidData.length <= 0){
                        MessageBox({
                                  title : 'Error',
                                  message: 'No valid data',
                                  type: 'error'
                              })
                          return false;
                      }


                      const formData = JSON.parse(JSON.stringify(this.form));

                      formData.items = getValidData.map((row)=>{
                        return row
                      },[]);

                    
                      formData.status = saveAs;
                      if(this.formStatus == 'create'){
                        this.storeRecord(formData).then((res) => {
                          self.$ThatsNotify.success({
                            message : "Record successfully created."
                          })
                          self.closeDialog()
                          self.$emit('success')
                        }).catch((error) =>{
                          self.formLoading = false
                          self.alertObject.show = true
                          self.alertObject.appearance = 'danger'

                        console.log("error.response.data")
                        console.log(error.response.data)

                            if(error.response.data.hasOwnProperty('error')){

                              let message = "Something went wrong."
                              if(typeof error.response.data.error === 'object'){
                                message = `Error code : ${error.response.data.error.code}, ${error.response.data.error.message.value}`
                              }

                              if(typeof error.response.data.error === 'number' && error.response.data.error == 1){
                                message = `${error.response.data.message}`
                              }

                              if(typeof error.response.data.errors === 'object' && error.response.data.errors.hasOwnProperty('code')){
                                message = `${error.response.data.errors.code[0]}`
                              }

                              self.$ThatsNotify.error({
                                message : message,
                                timeOut: 30000
                              })
                              // self.alertObject.message = `Error code : ${error.response.data.error.code} - ${error.response.data.error.message.value}`
                            }

                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                  self.$refs.observer.setErrors(error.response.data.errors);
                                  this.showError = true
                                }
                              } else if (error.request) {
                                  self.response.message = error.request
                              } else {
                                  self.response.message = error.message
                              }
                              return;
                        });

                      }

                      if(this.formStatus == 'update'){
                        self.formLoading = true
                          this.updateRecord(formData).then((res) => {
                            self.formLoading = false
                            self.$ThatsNotify.success({
                              message : "Record successfully updated."
                            })
                            
                            
                            self.closeDialog()
                            self.$emit('success')
                          }).catch((error) => {
                              self.formLoading = false


                              if(error.response.data.hasOwnProperty('error')){

                              let message = "Something went wrong."
                              if(typeof error.response.data.error === 'object'){
                                message = `Error code : ${error.response.data.error.code}, ${error.response.data.error.message.value}`
                              }

                              if(typeof error.response.data.error === 'number' && error.response.data.error == 1){
                                message = `${error.response.data.message}`
                              }

                              if(typeof error.response.data.errors === 'object' && error.response.data.errors.hasOwnProperty('code')){
                                message = `${error.response.data.errors.code[0]}`
                              }

                              self.$ThatsNotify.error({
                                message : message,
                                timeOut: 30000
                              })
                              // self.alertObject.message = `Error code : ${error.response.data.error.code} - ${error.response.data.error.message.value}`
                              }


                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      console.log(getValidData)
                      
                  }
                }
            });
           /*   */

            
    },
    openHistory(){
      this.setModule('goods-receipt-note')
          this.setRecordId(this.form.id)
        this.$refs.historyDialog.openDialog();
    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){
        spreadsheetOptions.data = this.form.items


        console.log("spreadsheetOptions")
        console.log(spreadsheetOptions)
        console.log(this.form)


        this.setColumnProperty()

        if(['update'].includes(this.formStatus)){
          spreadsheetOptions.minDimensions = [6,this.form.items]
        }else{
          spreadsheetOptions.minDimensions = [6,10]
        }

        this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, spreadsheetOptions);
        Object.assign(this, this.spreadsheet);
        this.spreadsheet.setWidth(window.innerWidth)

        
        
    },

    

        
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },


        renderTable(){
            const self = this;
            this.spreadsheetOptions.columns = this.tableColumns;


            


                this.createPopupTable(this.spreadsheetOptions);
        },

     
        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){
            this.spreadsheetOptions.style = {};
            this.spreadsheet.destroy();
            
            this.resetForm();
          return
        }
        
      },

      fnCalculateRow(row){

        const rowData = this.spreadsheet.getRowData(row)
        const series = rowData[0];

          if(series !== null && series !== ''){
             const rowData = this.spreadsheet.getRowData(row)
             const unitPrice = unformat(rowData[4])
             const quantity = parseInt(rowData[5])
             const totalPrice = unitPrice * quantity
             this.spreadsheet.setValueFromCoords(6,row,totalPrice,true)
             const discountPercent =  (unformat(rowData[7]) / 100)
             const priceTotal = totalPrice - (totalPrice * discountPercent)
               this.spreadsheet.setValueFromCoords(8,row,priceTotal,true)
             
          }
      },
      fnCalculateTotalColumn(row){

        const self = this;
        return new Promise(() => {
          const rowData = self.spreadsheet.getRowData(row)
          const unitPrice = unformat(rowData[4])
          const quantity = parseInt(rowData[5])
          const totalPrice = unitPrice * quantity
          const total = unformat(rowData[8])
          const sumValue = 100 - ((total / totalPrice) * 100)
          self.spreadsheet.ignoreEvents = true;
          self.spreadsheet.setValueFromCoords(7,row,`${parseFloat(sumValue).toFixed(2).toString()}`,true)
          self.spreadsheet.ignoreEvents = false;
        })

      },

      async fnGetItem(itemCode, index){
        const self = this;
        await this.getItem({item : itemCode, index : index}).then((res)=> {

          console.log("getItems")
          console.log(res)
            // self.spreadsheet.setRowData(index,[res.series, res.photo, res.name, res.dimension, res.sale_price,1 ,res.sale_price,0 , res.sale_price,null,res.id,res.attr], true);
            self.spreadsheet.ignoreEvents = true;
            self.spreadsheet.ignoreHistory = true;
            self.spreadsheet.setValueFromCoords(0,index,res.parent_code,true)
            self.spreadsheet.setValueFromCoords(1,index,res.code,true)
            self.spreadsheet.setValueFromCoords(2,index,res.photo,true)
            self.spreadsheet.setValueFromCoords(3,index,res.name,true)
            self.spreadsheet.setValueFromCoords(4,index,res.dimension,true)
            self.spreadsheet.setValueFromCoords(5,index,res.sale_price,true)
            self.spreadsheet.setValueFromCoords(6,index,1,true)
            self.spreadsheet.setValueFromCoords(7,index,res.sale_price,true)
            self.spreadsheet.setValueFromCoords(8,index,0,true)
            self.spreadsheet.setValueFromCoords(9,index,res.sale_price,true)
            self.spreadsheet.setValueFromCoords(13,index,true,true)

            self.spreadsheet.ignoreEvents = false;
            self.spreadsheet.ignoreHistory = false;


            
            const parseIntIndex = parseInt(index)
            
           /*  self.spreadsheet.resetStyle(`A${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`B${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`C${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`D${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`E${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`F${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`G${parseIntIndex+1}`)
            self.spreadsheet.resetStyle(`H${parseIntIndex+1}`) */
            const backgroundColor = 'transparent'
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`I${parseIntIndex+1}`, 'background-color', backgroundColor)

        }).catch((error) => {

          console.log("get Item error")
          console.log(error)
          const parseIntIndex = parseInt(index)
          let backgroundColor = 'rgb(255 200 200)';
          if(( parseIntIndex % 2) == 1){
            backgroundColor = 'rgb(233 167 167)';            
          }
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`I${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setValueFromCoords(12,parseIntIndex,false,true)

        })
    },

    setColumnProperty(){
      if(this.form.status != 'draft' && this.formStatus == 'update'){
        this.spreadsheetOptions.columns = this.updateTableColumns.map((row) => {

        const enableColumn = ['parent_code'];
        const readOnly = enableColumn.includes(row.name) ? false : true
        return {
          ...row,
          readOnly : readOnly
        }
        },[])
      }

    },

    handleSelectionChange(v){

      this.form.inventory_transfer_form.items = v
    },

    canSelectRow(row, index){
      /* let inventoryTransferFrom = false
      let inventoryTransferTo = false

      if(this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form == null){
        console.log("nullllll")
      } */


     const inventoryTransferFrom = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomFrom != null && this.form.inventory_transfer_form.hasOwnProperty('showroomFrom') && this.form.inventory_transfer_form.showroomFrom.value !== null) ? true : false
      const inventoryTransferTo = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomTo != null && this.form.inventory_transfer_form.hasOwnProperty('showroomTo') && this.form.inventory_transfer_form.showroomTo.value !== null) ? true : false
        if(row.remaining <= 0 || !inventoryTransferTo || !inventoryTransferFrom){
           return false
        }
        return true;
    },

    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },
    
    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },


        updateRecordStatus(status,message = 'Canceling a document is irreversible. Are you sure you want to continue?'){
            const self = this;

            const formData = JSON.parse(JSON.stringify(this.form));

            if(status == 'closed'){
              console.log("formData.items")
              console.log(formData.items)

              const expectedQty = formData.items.map((row) => {
                return row.quantity
              },[]).reduce((a,b) => a + b,0);

              const receivedQty = formData.items.map((row) => {
                return row.received
              },[]).reduce((a,b) => a + b,0);

              let message = 'Are you sure you want to close this document?'
              if(receivedQty < expectedQty){
                message = 'Received quantity is less than expected quantity. Are you sure you want to close this document?'
              }

              MessageBox.confirm(message, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                  self.formLoading = true
                  self.updateRecord({...formData, status : status}).then((res) => {
                      self.formLoading = false
                     console.log("res")
                      console.log(res)
                      self.$ThatsNotify.success({
                          message : res.message
                      })
                      self.$emit('success')
                      self.closeDialog()
                  }).catch((error) => {
                    self.formLoading = false
                    if(error.response.data.hasOwnProperty('error')){
  
                      let message = "Something went wrong."
                      if(typeof error.response.data.error === 'object'){
                        message = `Error code : ${error.response.data.error.code}, ${error.response.data.error.message.value}`
                      }
  
                      if(typeof error.response.data.error === 'number' && error.response.data.error == 1){
                        message = `${error.response.data.message}`
                      }
  
                      self.$ThatsNotify.error({
                        message : message,
                        timeOut: 30000
                      })
                    }
                  })
                  
                })

            }else{
  
              MessageBox.confirm(message, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                  self.formLoading = true
                  self.updateRecord({...formData, status : status}).then((res) => {
                      self.formLoading = false
                     
                      self.$ThatsNotify.success({
                          message : "Record successfully cancelled."
                      })
                      self.$emit('success')
                      self.closeDialog()
                  }).catch((error) => {
                    self.formLoading = false
                    if(error.response.data.hasOwnProperty('error')){
  
                      let message = "Something went wrong."
                      if(typeof error.response.data.error === 'object'){
                        message = `Error code : ${error.response.data.error.code}, ${error.response.data.error.message.value}`
                      }
  
                      if(typeof error.response.data.error === 'number' && error.response.data.error == 1){
                        message = `${error.response.data.message}`
                      }
  
                      self.$ThatsNotify.error({
                        message : message,
                        timeOut: 30000
                      })
                    }
                  })
                  
                })
            }
          },


        selectTableLocation(option){

          return true
        },

        onInputLocationFrom(v){
          console.log("On input from")
          console.log(v)
        },
        onInputLocationTo(v){
          console.log("On input To")
          console.log(v)
        },

   
      searchVendor(v){
          console.log("Search Vendor")
          console.log(v)


          if(v !== null){

            const contacts = []
            this.asyncSearchData({term : v}).then((response) => {


              console.log("asyncSearchData")
              console.log(response)
              if(response.length > 0){
                response.forEach((element) => {
                  contacts.push({
                    ...element,
                    value : element.id,
                    label : element.series,
                    opt : element.series+"/" + element.name + "/" + element.mobile,
                    display : element.name + "/" + element.mobile,
                  })
                })


                this.vendorOptions = contacts
              }
            })
          }



      },

      getVendorSelected(vendorObj){

        const self = this;
        const selectedVendor = this.vendorOptions.filter(vendor => {
            return vendor.value === vendorObj;
        },[])[0];

        console.log(vendorObj)

        if(selectedVendor){
          this.form.vendor = selectedVendor
        }
          
      }
      
     
  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('GoodsReceiptNoteModule',{initialReceiveingFormObject : 'initialFormObject'}),
    ...mapGetters('GoodsReceiptNoteModule',['getLoading','formData','formStatus','initialFormObject','putLoading','postLoading','findLoading']),
      tblKey : function(){
        return 1;
      },

     


      userDatax : function(){
        return this.userDataProvider()
      },
      

      tableColumns : function(){

        
        return [

                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'parent_code',
                    title : 'Parent Code',
                    readOnly : false
                  },

                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'code',
                    title : 'Item Code',
                    readOnly : true
                  },
                  {
                    type : 'text',
                    width : '70px',
                    align : 'left',
                    name : 'photo',
                    title : 'Photo',
                    readOnly : true
                  },

                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Description',
                    readOnly : true
                  },

                  
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'dimension',
                    title : 'Dimension',
                    readOnly : true
                  },

                  {
                    type : 'hidden',
                    width : '150px',
                    align : 'center',
                    name : 'unit_price',
                    title : 'Unit Price',
                    mask:'#,##0.00', decimal:'.',
                    readOnly : true 
                  },

                  {
                    type : 'numeric',
                    width : '100px',
                    align : 'center',
                    name : 'quantity',
                    title : 'Quantity',
                    mask:'#,##.00', decimal:'.',
                    readOnly : true 
                  },

                  {
                    type : 'hidden',
                    width : '150px',
                    align : 'center',
                    name : 'price',
                    title : 'Price',
                    mask:'#,##0.00', decimal:'.',
                    readOnly : true 
                  },
                  {
                    type : 'hidden',
                    width : '150px',
                    align : 'center',
                    name : 'discount',
                    title : 'Discount',
                    mask:'#,##0.00 %', decimal:'.',
                    readOnly : true 
                  },

                  {
                    type : 'hidden',
                    width : '150px',
                    align : 'center',
                    name : 'total',
                    title : 'Total',
                    mask:'#,##0.00', decimal:'.',
                    readOnly : false  
                  },

                 

                    {
                      type : 'text',
                      align : 'left',
                      name : 'description',
                      width : '250px',
                      title : 'Remarks',
                      readOnly : true
                    },
                    {
                      type : 'hidden',
                      name : 'id',
                    },
                    
                    {
                      type : 'hidden',
                      name : 'attr',
                    },
                    {
                      type : 'hidden',
                      name : 'valid',
                    },
                    {
                      type : 'hidden',
                      name : 'line_num',
                    }

            ]
      },

      updateTableColumns : function(){

        
      return [

                {
                  type : 'text',
                  width : '250px',
                  align : 'left',
                  name : 'parent_code',
                  title : 'Parent Code',
                  readOnly : false
                },

                {
                  type : 'text',
                  width : '250px',
                  align : 'left',
                  name : 'code',
                  title : 'Item Code',
                  readOnly : true
                },
                {
                  type : 'text',
                  width : '70px',
                  align : 'left',
                  name : 'photo',
                  title : 'Photo',
                  readOnly : true
                },

                {
                  type : 'text',
                  width : '350px',
                  align : 'left',
                  name : 'name',
                  title : 'Description',
                  readOnly : true
                },

                
                {
                  type : 'text',
                  width : '250px',
                  align : 'left',
                  name : 'dimension',
                  title : 'Dimension',
                  readOnly : true
                },

                {
                  type : 'hidden',
                  width : '150px',
                  align : 'center',
                  name : 'unit_price',
                  title : 'Unit Price',
                  mask:'#,##0.00', decimal:'.',
                  readOnly : true 
                },

                {
                  type : 'numeric',
                  width : '100px',
                  align : 'center',
                  name : 'quantity',
                  title : 'Quantity',
                  mask:'#,##.00', decimal:'.',
                  readOnly : true 
                },

                {
                  type : 'numeric',
                  width : '100px',
                  align : 'center',
                  name : 'received',
                  title : 'Received',
                  mask:'#,##.00', decimal:'.',
                  readOnly : true 
                },

                {
                  type : 'hidden',
                  width : '150px',
                  align : 'center',
                  name : 'price',
                  title : 'Price',
                  mask:'#,##0.00', decimal:'.',
                  readOnly : true 
                },
                {
                  type : 'hidden',
                  width : '150px',
                  align : 'center',
                  name : 'discount',
                  title : 'Discount',
                  mask:'#,##0.00 %', decimal:'.',
                  readOnly : true 
                },

                {
                  type : 'hidden',
                  width : '150px',
                  align : 'center',
                  name : 'total',
                  title : 'Total',
                  mask:'#,##0.00', decimal:'.',
                  readOnly : false  
                },

              

                  {
                    type : 'text',
                    align : 'left',
                    name : 'description',
                    width : '250px',
                    title : 'Remarks',
                    readOnly : true
                  },
                  {
                    type : 'hidden',
                    name : 'id',
                  },
                  
                  {
                    type : 'hidden',
                    name : 'attr',
                  },
                  {
                    type : 'hidden',
                    name : 'valid',
                  },
                  {
                    type : 'hidden',
                    name : 'line_num',
                  }

          ]
      },

      
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        
        if(this.formStatus == 'update'){

          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);


          this.form = JSON.parse(JSON.stringify({...v}));

          this.formOriginal = JSON.parse(JSON.stringify({...v}));

          console.log("this.form")
          console.log(this.form)


          this.$nextTick(()=>{
            
            self.vendorOptions = [
              {...this.form.vendor}
            ];

            if(self.spreadsheet != null){
              self.spreadsheet.destroy();
            }
/* 
            console.log("formData updateTableColumns",this.updateTableColumns)
            self.spreadsheetOptions.columns = this.updateTableColumns; */

            this.setColumnProperty()

            self.createPopupTable(this.spreadsheetOptions);
            self.spreadsheet.setData(this.form.items)
          })

          this.active = 0;
                    

        }else{

         /*  if(self.spreadsheet != null){
            self.spreadsheet.destroy();
          }

          this.tabs = [
                { text: 'Details', slot: 'form-slot' },
              ]
          this.resetForm();
          this.currentDateSetter();
          this.renderTable(); */


          this.form = JSON.parse(JSON.stringify({...v}));

            this.$nextTick(()=>{
              
              self.vendorOptions = [
                {...this.form.vendor}
              ];

              if(self.spreadsheet != null){
                self.spreadsheet.destroy();
              }
              self.spreadsheetOptions.columns = this.tableColumns;

              this.setColumnProperty()

              self.createPopupTable(this.spreadsheetOptions);
              self.spreadsheet.setData(this.form.items)
            })


          this.active = 0;
        }
           
      },

      

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },


   



  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>