<template>
  <div class="mdb-calendar">


    <mdb-month-view 
      v-if="view === 'month'" 
      :defaultDate="date"
      :dayNames="shortDayNames"
      :monthNames="monthNames"
      :todayText="todayText"
      :events="events"
      @editEvent="editEvent"
      @addEvent="addEvent"
      @dateChange="dateChange"
      @eventsUpdate="eventsUpdate"
      @changeView="changeView"
      @openDeliveryNoteIntegration="openDeliveryNoteIntegration"
      />

    <mdb-week-view
      v-if="view === 'week'"
      :defaultDate="date"
      :dayNames="shortDayNames"
      :monthNames="shortMonthNames"
      :events="newEvents"
      :todayText="todayText"
      @editEvent="editEvent"
      @addEvent="addEvent"
      @dateChange="dateChange"
      @eventsUpdate="eventsUpdate"
      @changeView="changeView" />

    <mdb-list-view
      v-if="view === 'list'"
      :defaultDate="date"
      :dayNames="dayNames"
      :monthNames="shortMonthNames"
      :events="events"
      :todayText="todayText"
      @changeView="changeView"
      @editEvent="editEvent"
      @dateChange="dateChange"
    />

   
    <el-dialog :modal="true" :title="'New Schedule'" :visible.sync="newScheduleModal" :close-on-click-modal="true" :modal-append-to-body="true" :append-to-body="true" :close-on-press-escape="true" size="small" class="text-left new-schedule">
        <ul class="select-new-icon">
          <li>
            <a href="javascript:void(0)" @click="fnNewSchedule('delivery-schedule')">
              <i class="el-icon-truck"></i>
              <span>Schedule Delivery</span>
            </a>
          </li>
          <li>
            <a href="javascript:void(0)" @click="fnNewSchedule('pullout-schedule')">
              <i class="el-icon-truck pullout"></i>
              <span>
                Schedule Pullout
              </span>
            </a>
          </li>
        </ul>
    </el-dialog>

    <el-dialog :modal="true" :title="scheduleTypes[activeEvent.type]" :visible.sync="editEventModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="600" size="small" class="text-left delivery-schedule" :showFullscreen="true">

        <div class="d-flex justify-content-between p-2">

      <h5 class="m-2 mt-0">{{ new Date(activeEvent.end).toDateString() }}</h5>
    </div>        

        <el-table id="scheduleTable" ref="scheduleTable" :key="tblKey" stripe border :data="activeEvent.data" height="750" class="input-table mt-2 itemTable" style="width: 100%">
          <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
          <el-table-column width="200" label="Series" align="left">
            <template slot-scope="scope">
    
                <template v-if="(scope.$index + 1) <= activeEvent.data.length">
                  
                  <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row,scope.row.type)">{{ scope.row.title }}</a>
                  

                </template>
                <template v-if="(scope.$index + 1) == activeEvent.data.length">
                  <template v-if="['delivery-schedule','pullout-schedule'].includes(activeEvent.type) && activeEvent.isEditTable">
                    <GridVueSelect v-model="scope.row.title" :reduce="item => item.code" :reduceLabel="'code'" :options="invoices" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)"></GridVueSelect>
                  </template>
                  <template v-else>
                    {{ scope.row.title }}
                  </template>
                </template>            
            </template>
          </el-table-column>
          <el-table-column label="Customer" align="left">
            <template slot-scope="scope">{{ scope.row.data.transaction.customer_mobile }}</template>
          </el-table-column>
          <el-table-column label="Sold By" align="left" width="250">
            <template slot-scope="scope">{{ scope.row.data.transaction.sales_person }}</template>
          </el-table-column>
          <el-table-column label="Amount" align="right" width="150">
            <template slot-scope="scope">{{ scope.row.data.transaction.total }}</template>
          </el-table-column>
          <el-table-column width="300" label="Remarks" align="left">
            <template slot-scope="scope">
              <template v-if="scope.row.editDescription">
                <ThatsTextarea :vid="`textArea${scope.$index}`" :rows="4" :showLabel="false" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-12 pl-0 delivery-textarea" :wrapperClass="'delivery-textarea'" label="" name="description" v-model="scope.row.data.description" size="sm" :disabled="false" :rules="''" @blur="onBlur(scope.$index, scope.row)"/>
              </template>
              <template v-else>
                <div class="schedule-remarks">
                 
                  <template v-if="(scope.$index + 1) < activeEvent.data.length && activeEvent.isEditTable">
                    <a href="javascript:void(0)" @click="fnEditDescription(scope.$index, scope.row)"><mdb-icon far icon="edit" /></a>
                  </template>
                  <template v-if="scope.row.data.description != null">
                    {{ scope.row.data.description }}
                  </template>
                  <template v-else>
                    <i>No Remarks</i>
                  </template>
                  </div>
              </template>
            </template>
          </el-table-column>

         
          <template v-if="['delivery-schedule','delivered','picklist'].includes(activeEvent.type)">
            <el-table-column width="180" label="Status" align="left">
              <template slot-scope="scope">

                <template v-if="['delivery-schedule'].includes(activeEvent.type)">
                  <template v-if="activeEvent.isEditTable">
                    <template v-if="!!activeRecordStatus[scope.row.data.status]">
                      <el-dropdown :hide-on-click="false" trigger="click">                     
                        <mdb-badge class="el-dropdown-link delivery-badge cursor-pointer" pill :color="(warningRowStatus.includes(scope.row.data.status) ? 'warning' : 'success')">
                          <template v-if="warningRowStatus.includes(scope.row.data.status)">
                            <i class="el-icon-warning"></i> 
                          </template>
                          <template v-else>
                            <i class="el-icon-check"></i> 
                          </template>
                          
                          {{ activeRecordStatus[scope.row.data.status] }}</mdb-badge>
                       
                        <el-dropdown-menu slot="dropdown" >
                          
                          <el-dropdown-item :disabled="!activeEvent.isEditTable">
                              <el-popconfirm :key="scope.$index"
                                confirm-button-text='OK'
                                cancel-button-text='Cancel'
                                icon="el-icon-info"
                                icon-color="red"
                                title="Are you sure you want to change status?"
                                style="right:0!important"
                                @onConfirm="updateRecordStatus(scope.$index,scope.row)"
                              >
                              <div slot="reference">
                                {{ dropdownRowStatus[scope.row.data.status] }}
                              </div>
                            </el-popconfirm>
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                      </template>
                  </template>
                  <template v-else>
                    <mdb-badge class="el-dropdown-link delivery-badge cursor-pointer" pill :color="(warningRowStatus.includes(scope.row.data.status) ? 'warning' : 'success')">
                          <template v-if="warningRowStatus.includes(scope.row.data.status)">
                            <i class="el-icon-warning"></i> 
                          </template>
                          <template v-else>
                            <i class="el-icon-check"></i> 
                          </template>
                          {{ activeRecordStatus[scope.row.data.status] }}
                      </mdb-badge>
                  </template>
                </template>

                <template v-else>
                  {{ activeRecordStatus[scope.row.data.status] }}
                </template>
  
                                 
              </template>
            </el-table-column>
          </template>

        
            <el-table-column width="150" label="" align="center">
                <template slot-scope="scope">
                  <template v-if="!!scope.row.title">
         
                  
                    <el-dropdown :hide-on-click="false" size="mini" split-button type="primary" trigger="click">
                      Action
                      <el-dropdown-menu slot="dropdown">
                       <!--  <el-dropdown-item>{{ activeEvent.type }}</el-dropdown-item> -->
                       
                        
                        <template v-if="['pullout-schedule'].includes(activeEvent.type)">
                          <el-dropdown-item @click.native="makeDeliveryNote(scope.row)"><i class="el-icon-tickets text-success cursor-pointer"></i> Make Pullout</el-dropdown-item>
                        </template>
                        
                        
                        <template v-if="['delivery-schedule'].includes(activeEvent.type)">
                          <el-dropdown-item @click.native="makeDeliveryNote(scope.row)"><i class="el-icon-tickets text-success cursor-pointer"></i> Make Delivery Note</el-dropdown-item>
                          <el-dropdown-item @click.native="makeDeliveryNote(scope.row)"><i class="el-icon-tickets text-success cursor-pointer"></i> Make Picklist</el-dropdown-item>
                        </template>
                       <!--  <template v-if="['delivery-schedule'].includes(activeEvent.type)">
                        </template> -->

                        <el-dropdown-item @click.native="fnPrintDoc('/sales/invoice/'+scope.row.data.transaction.token_id)">
                          <i class="el-icon-printer text-info cursor-pointer"></i> Print Invoice
                        </el-dropdown-item>

                        <template v-if="['delivered'].includes(activeEvent.type)">
                          <el-dropdown-item @click.native="fnPrintDoc('/delivery/print-note/'+scope.row.data.token_id)">
                            <i class="el-icon-printer text-info cursor-pointer"></i> Print a copy
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="fnPrintDoc('/delivery/print-as/final-delivery/'+scope.row.data.token_id)">
                            <i class="el-icon-printer text-info cursor-pointer"></i> Print all Items
                          </el-dropdown-item>

                          <template v-if="scope.row.attr.hasOwnProperty('document') && scope.row.attr.document.entry === null">
                              <el-dropdown-item @click.native="cancelDeliveryNote(scope.row.title, activeEvent)">
                                <i class="el-icon-error text-danger cursor-pointer"></i> Cancel
                              </el-dropdown-item>
                          </template>
                        </template>
                        
                        <template v-if="['pullout'].includes(activeEvent.type)">
                          <el-dropdown-item @click.native="fnPrintDoc('/pull-out/print-note/'+scope.row.data.token_id)">
                            <i class="el-icon-printer text-info cursor-pointer"></i> Print a copy
                          </el-dropdown-item>

                          <el-dropdown-item @click.native="cancelPullOut(scope.row.title, activeEvent)">
                            <i class="el-icon-error text-danger cursor-pointer"></i> Cancel
                          </el-dropdown-item>
                        </template>
  
                        <template v-if="['delivery-schedule','pullout-schedule'].includes(activeEvent.type) && activeEvent.isEditTable">
                        <el-dropdown-item>
                          <template v-if="(scope.$index + 1) < activeEvent.data.length">
                            <el-popconfirm :key="scope.$index"
                                      confirm-button-text='OK'
                                      cancel-button-text='Cancel'
                                      icon="el-icon-info"
                                      icon-color="red"
                                      title="Are you sure you want to delete?"
                                      style="right:0!important"
                                      @onConfirm="removeRecord(scope.$index,scope.row)"
                                    >
                                    <div slot="reference">
                                      <i class="el-icon-error text-danger cursor-pointer"></i> Delete
                                    </div>
                            </el-popconfirm>
                            </template>
                        </el-dropdown-item>
                        </template>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                </template>
              </el-table-column>
          

        </el-table>

        <div class="pl-3 pr-3">
            <div class="d-flex">
          <!-- <template v-if="hasDifference.length > 0">
            <mdb-btn color="elegant" @click.native="editEventModal = false" size="sm">Save</mdb-btn>
          </template> -->

         
          <mdb-btn color="elegant" @click.native="editEventModal = false" size="sm">Close</mdb-btn>
          <template v-if="['delivered'].includes(activeEvent.type)">
            <mdb-btn color="elegant" @click.native="deliveryImportFormIntegration" size="sm">Push to SAP</mdb-btn>
          </template>
          </div>
        </div>



      </el-dialog>

      <InvoiceForm ref="invoiceForm" :group="2500"></InvoiceForm>
      <DeliveryForm ref="deliveryForm" :group="2500" @success="refreshCalendar" @openPicklist="openPicklist"></DeliveryForm>
      <PicklistForm ref="picklistForm" :group="2500" @success="refreshCalendar" ></PicklistForm>
      <ImportDeliveryNoteForm ref="deliveryImportForm" :group="2500"></ImportDeliveryNoteForm>
  </div>
</template>

<script>
import { mdbMonthView } from './mdbMonthView'
import { mdbWeekView } from './mdbWeekView'
import { mdbListView } from './mdbListView'
import orderBy from 'lodash.orderby'
import { mdbModal, mdbModalHeader, mdbModalTitle, mdbModalBody, mdbModalFooter, mdbBtn, mdbInput ,mdbTabs, mdbBadge, mdbIcon} from 'mdbvue'
import GridVueSelect from './GridVueSelect'
import { mapActions, mapMutations } from 'vuex'
import { ThatsTextarea} from '../../thats/Thats'
import { MessageBox } from 'element-ui';
import InvoiceForm from '../../../views/transaction/InvoiceForm'
import ImportDeliveryNoteForm from '../../../views/transaction/ImportDeliveryNoteForm'
import DeliveryForm from './DeliveryForm'
import PicklistForm from './PicklistForm'
const mdbCalendar =  {
  name: 'mdbCalendar',
  components: {
    mdbMonthView,
    mdbWeekView,
    mdbListView,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter,
    mdbBtn,mdbIcon,
    mdbInput,mdbTabs,mdbBadge,ThatsTextarea,
    GridVueSelect,
    InvoiceForm,
    DeliveryForm,
    PicklistForm,
    ImportDeliveryNoteForm
  },
  props: {
    defaultView: {
      type: String,
      default: 'month'
    },
    defaultDate: {
      type: Date,
      default: () => new Date()
    },
    monthNames: {
      type: Array,
      default: () => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    shortMonthNames: {
      type: Array,
      default: () => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    dayNames: {
      type: Array,
      default: () => ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    },
    shortDayNames: {
      type: Array,
      default: () => ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
    },
    todayText: {
      type: String,
      default: 'Today'
    },
    events: {
      type: [Array, Boolean],
      default: false
    }
  },

  provide() {
    return {
      activeEventProvider: () => this.computedActiveEvent
    }
  },

  data() {
    return {
      newScheduleModal: false,

      newSchedule : null,
      view: this.defaultView,
      date: this.defaultDate,
      newEvents: orderBy(this.events, ['start', 'end'], ['asc', 'desc']),
      editEventModal: false,
      editEventIndex: 0,
      addEventModal: false,
      activeEvent: {
        data : []
      },
      activeEventCopy: {
        data : []
      },
      newEvent: {},
      activeEventData: {},
      newEventData: {},
      getActiveTabIndex : 0,
      tabs : [
          { text: 'Delivery Schedule', slot: 'delivery-schedule' },
          { text: 'Confirmed Delivery', slot: 'delivered' },
      ],
      tblKey : 0,
      active : 0,
      invoices : [],
      showSave : false,
      activeScheduleModal : false,
      activeSchedule : {
        title : null,
        start : null,
        end : null,
        title : null,
        data : []
      }
    }
  },
  methods: {

    ...mapMutations('InvoiceModule',{
            invoiceSetRecord : 'setFormRecord',
            invoiceStatusUpdate : 'setFormStatusUpdate',
            invoiceSetTblKey : 'setTblKey',
            setDeliveryNoteIntegrationData : 'setDeliveryNoteIntegrationData',
        }),
        ...mapActions('InvoiceModule',{
            invoiceGetRecord : 'getRecord',
            invoiceGetRecordSeries : 'getRecordSeries',
        }),
        ...mapMutations('DeliveryModule',{
          setDeliferyFormStatusUpdate : 'setFormStatusUpdate',
            setDeliveryFormRecord : 'setFormRecord',
            setDeliveryTabIndex : 'setTabIndex',
        }),
        ...mapActions('DeliveryModule',{
          getDeliveryRecord : 'getDeliverySchedule',
          fnCancelDeliveryNote : 'cancelDeliveryNote',
          fnCancelPulloutRecord : 'cancelPullOutRecord',
        }),

      

    ...mapActions('DeliveryScheduleModule',['getSearchInvoice','storeRecord','updateRecord','deleteRecord']),
    editEvent(eventIndex) {

      const dateToCheck = new Date(eventIndex.date);
      dateToCheck.setHours(0,0,0,0);
      const today = new Date();
      today.setHours(0,0,0,0);
      const editEventParsed = JSON.parse(JSON.stringify(eventIndex))
      let isEditTable = false
      if (dateToCheck >= today) {
        editEventParsed.data.push({
                "title": null,
                "start": new Date(),
                "end": new Date(),
                "date": new Date(),
                "data": {
                  transaction : {
                    customer_mobile : null,
                    total : 0
                  },
                  invoice : {
                    series : null
                  },
                  description : null
                },
                "type": eventIndex.type,
                editDescription : false
              })

              isEditTable = true
        }
      const updatedData = editEventParsed.data.map((item) => {
        return {...item,editDescription : false}
      })
      
            editEventParsed.data = updatedData
            editEventParsed.isEditTable = isEditTable
            this.activeEvent = editEventParsed
            this.activeEventCopy = JSON.parse(JSON.stringify(editEventParsed))
      /* this.editEventIndex = eventIndex
      this.activeEvent = this.newEvents[eventIndex] */

      this.tblKey++
      this.editEventModal = true
    },

    fnNewSchedule(type){


        this.tblKey++
        const {start, end,data } = this.newSchedule;
        let isEditTable = false

      let updatedData = []
        if(['delivery-schedule'].includes(type)){
          const deliveryScheduleData = data.filter(item => item.type ==='delivery-schedule',[])[0];

          if(!!deliveryScheduleData){
            updatedData = deliveryScheduleData.data.map((item) => {
              return {...item,editDescription : false}
            },[])
          }

          updatedData.push({
              "title": null,
              "start": start,
              "end": start,
              "date": start,
              "data": {
                transaction : {
                  customer_mobile : null,
                  total : 0
                },
                invoice : {
                  series : null
                },
                description : null
              },
              "type": "delivery-schedule",
              editDescription : false
            })

            let editEventParsed = {
                color : "info",
                colorDark : "info-color-dark",
                title : start,
                start : start,
                end : end,
                data : updatedData,
                count : updatedData.length,
                dataLength : updatedData.length,
                type : "delivery-schedule",
                isEditTable : true
              }

          this.activeEvent = editEventParsed
          this.activeEventCopy = JSON.parse(JSON.stringify(editEventParsed))

          this.newScheduleModal = false;
        }else{
            // alert("new pull out")

            console.log("add pullout")
            console.log(start)
            console.log(end)
            console.log(data)



            const pulloutScheduleData = data.filter(item => item.type ==='pullout-schedule',[])[0];

          if(!!pulloutScheduleData){
            updatedData = pulloutScheduleData.data.map((item) => {
              return {...item,editDescription : false}
            },[])
          }

          updatedData.push({
              "title": null,
              "start": start,
              "end": start,
              "date": start,
              "data": {
                transaction : {
                  customer_mobile : null,
                  total : 0
                },
                invoice : {
                  series : null
                },
                description : null
              },
              "type": "pullout-schedule",
              editDescription : false
            })

            let editEventParsed = {
                color : "info",
                colorDark : "info-color-dark",
                title : start,
                start : start,
                end : end,
                data : updatedData,
                count : updatedData.length,
                dataLength : updatedData.length,
                type : "pullout-schedule",
                isEditTable : true
              }

          this.activeEvent = editEventParsed
          this.activeEventCopy = JSON.parse(JSON.stringify(editEventParsed))



            this.newScheduleModal = false;

        }
        

        if(updatedData.length > 0){
          this.editEventModal = true
        }

        
    },
    addEvent(start, end, data) {

    //  


      const dateToCheck = new Date(end);
      dateToCheck.setHours(0,0,0,0);
      const today = new Date();
      today.setHours(0,0,0,0);


      console.log("click")
      console.log(start)
      console.log(end)
      console.log(data)

      //check data if has schedule-delivery or schedule pullout

      const checkData = data.filter(item => item.type === 'delivery-schedule' || item.type === 'pullout-schedule', []);

      
      // alert(checkData.length)



     /*  if (dateToCheck >= today) {
          alert(1)
      }else{
        alert(2)
      }
 */


      


     /*  const dateToCheck = new Date(end);
      dateToCheck.setHours(0,0,0,0);
      const today = new Date();
      today.setHours(0,0,0,0); */



      console.log(dateToCheck)
      console.log(today)
      


      const deliveryScheduleData = data.filter(item => item.type ==='delivery-schedule',[])[0];

      let updatedData = []
      if(!!deliveryScheduleData){
        updatedData = deliveryScheduleData.data.map((item) => {
          return {...item,editDescription : false}
        },[])
      }

      let isEditTable = false

      if (dateToCheck >= today) {
        
        //today and future
        // alert(checkData.length)

          updatedData.push({
              "title": null,
              "start": start,
              "end": start,
              "date": start,
              "data": {
                transaction : {
                  customer_mobile : null,
                  total : 0
                },
                invoice : {
                  series : null
                },
                description : null
              },
              "type": "delivery-schedule",
              editDescription : false
            })

            isEditTable = true
          if(checkData.length <= 1 ){
            this.newSchedule = {
              start : start, end : end, data : data, 
            };

            this.newScheduleModal = true;
          }

      }else{

        //Open Delivery Schedule
        if(updatedData.length > 0){

          let editEventParsed = {
            color : "info",
            colorDark : "info-color-dark",
            title : start,
            start : start,
            end : end,
            data : updatedData,
            count : updatedData.length,
            dataLength : updatedData.length,
            type : "delivery-schedule",
            isEditTable : isEditTable
          }


          console.log(editEventParsed);
          this.activeEvent = editEventParsed
          this.activeEventCopy = JSON.parse(JSON.stringify(editEventParsed))

          this.editEventModal = true
        }
      }
      this.tblKey++



    },
    updateEvent(value, key) {
      if (key === 'title') this.activeEvent.title = value
      if (key === 'start') {
        let newDateAndTime = value.split(',')
        let newDate = newDateAndTime[0].split('.')
        let newTime = newDateAndTime[1].split(':')
        this.activeEvent.start = new Date(newDate[2], newDate[1] - 1, newDate[0], newTime[0], newTime[1], newTime[2])
      }
      if (key === 'end') {
        let newDateAndTime = value.split(', ')
        let newDate = newDateAndTime[0].split('.')
        let newTime = newDateAndTime[1].split(':')
        this.activeEvent.end = new Date(newDate[2], newDate[1] - 1, newDate[0], newTime[0], newTime[1], newTime[2])
      }
      this.newEvents = orderBy(this.newEvents, ['start', 'end'], ['asc', 'desc'])
    },
    updateEvents() {
      this.newEvents = orderBy(this.newEvents, ['start', 'end'], ['asc', 'desc'])
    },
    updateNewEvent(value, key) {
      if (key === 'title') this.newEvent.title = value
      if (key === 'start') {
        let newDateAndTime = value.split(',')
        let newDate = newDateAndTime[0].split('.')
        let newTime = newDateAndTime[1].split(':')
        this.newEvent.start = new Date(newDate[2], newDate[1] - 1, newDate[0], newTime[0], newTime[1], newTime[2])
      }
      if (key === 'end') {
        let newDateAndTime = value.split(', ')
        let newDate = newDateAndTime[0].split('.')
        let newTime = newDateAndTime[1].split(':')
        this.newEvent.end = new Date(newDate[2], newDate[1] - 1, newDate[0], newTime[0], newTime[1], newTime[2])
      }
    },
    pushEvent() {
      if (this.newEvent.start >= this.newEvent.end) return
      this.newEvents.push(this.newEvent)
      this.newEvents = orderBy(this.newEvents, ['start', 'end'], ['asc', 'desc'])
      this.addEventModal = false
    },
    deleteEvent() {
      this.newEvents.splice(this.editEventIndex, 1)
      this.newEvents = orderBy(this.newEvents, ['start', 'end'], ['asc', 'desc'])
      this.editEventModal = false
    },
    changeView(view) {
      this.view = view
    },
    dateChange(date) {
      this.date = date
      this.$emit('dateChange',date)
    },
    eventsUpdate(events) {
      this.newEvents = events
      this.newEvents = orderBy(this.newEvents, ['start', 'end'], ['asc', 'desc'])
    },

    printRowDetails(datax,row,keyName){
        const rowData = datax.filter((item) => {
          return item.data.invoice.series == row
        },[])[0]

        if(rowData ){
            return data.data.transaction[keyName]
        }
        return '-'
    },
    statusKeyUp(v){
      const self =this;
      const {end,data, type} = this.activeEvent;

      if(v != ''){
        const existing = data.filter((item) => item.title != "").map((item) => item.title,[]);
      const params = {term : v, date : end, whereNotIn : existing, type : type};
        this.activeEvent.date;

        this.getSearchInvoice(params).then((res) => {
          console.log("invoice search result")
          // console.log(res)
            const invoiceSearchResult = [];
            res.data.forEach(element => {
                invoiceSearchResult.push({
                    code : element.title,
                    label : element.data.transaction.customer_mobile,
                    amount : element.data.transaction.total,
                    data : element,
                })
            });

            self.invoices = invoiceSearchResult;
         
        }).catch((error) => {
          console.log(error)
        })
        console.log("OnKeyUp")
        console.log(params)
      }

         /*  
            this.dispatchFilterItems(params) */
    },

    dispatchFilterItems(params){
      const results = [];
      /* this.filterItems(params).then((response) =>{
            if(response.length > 0){
              response.forEach(element => {
                results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default,category : element.category,  remaining : element.remaining, status : element.status})
              });
            }
        })
      this.items = results */

    },

    statusKeyUpFocus(){
      // this.invoices = []
     /*  const localItems = this.activeEvent.data;
      const filterOnFocus = [];
      if(localItems.length > 0){
        localItems.forEach(item => {
          if(this.itemSeries.indexOf(item.title) < 0){
            filterOnFocus.push(item)
          }
        });

        this.items = filterOnFocus
      } */


    },

    getSelected(v,index){
      const self = this
      const getSelected = this.invoices.filter((item) => { 
            return item.code === v
        },[])[0];

        if(getSelected){
            /* this.activeEvent.data.push({
              ...getSelected.data,
              type : "delivery-schedule"
            }) */

            const recordType = this.activeEvent.type;

             this.$set(this.activeEvent.data, index, {
              ...getSelected.data,
              type : recordType
            })


            this.activeEvent.data.push({
              "title": null,
              "start": getSelected.data.start,
              "end": getSelected.data.end,
              "date": getSelected.data.date,
              "data": {
                transaction : {
                  customer_mobile : null,
                  total : 0
                },
                invoice : {
                  series : null
                },
                description : null
              },
              "type": recordType,
              editDescription : false
            })
/* 
            console.log("getSelected)")
            console.log({...getSelected.data,type : "delivery-schedule"}) */

            
            this.storeRecord({id :getSelected.data.data.transaction.mdbID, invoice : getSelected.data.data.invoice.series, date :  getSelected.data.date, type : recordType}).then((res) => {
                self.events.push({...getSelected.data,data : {...getSelected.data.data,status : "for-delivery", description : null},type : recordType})

            })
            this.invoices = [...JSON.parse(JSON.stringify(this.invoices)).filter((item) => item.code !== v)];
            // this.$refs.scheduleTable

           /*  console.log(this.$refs['scheduleTable'])
            this.$refs['scheduleTable'].$el.scrollTop = 0 */
            
            /* 
            this.$nextTick(() => {
            }); */
            // this.getDifference()

        }

    },

    getDifference(){
      const eventCopy = this.activeEventCopy.data.filter(item => item.title !== '').map(item => item.title);
        const eventActive = this.activeEvent.data.filter(item => item.title !== '').map(item => item.title);

      //  const x =  eventActive.filter(x => !eventCopy.includes(x))

       //let difference = arr1.filter(x => !arr2.includes(x));
       const x = eventCopy.filter(x => !eventActive.includes(x))
                 .concat(eventActive.filter(x => !eventCopy.includes(x)))

      alert(`${eventCopy.toString()} ------------- ${eventActive.toString()} --- ${x.length}`)
        return x
    },


    onBlur(index,data){
      this.$set(this.activeEvent.data, index, {
              ...data,
              editDescription : false
            })

            console.log(data)
      this.updateRecord({id :data.data.transaction.mdbID, invoice : data.data.invoice.series, date :  data.date, description : data.data.description, type : data.type})
      
    },

    fnEditDescription(index,data){
      this.activeEvent.data = this.activeEvent.data.map((item) => {
          return {...item,editDescription : false}
      })

      
      this.$set(this.activeEvent.data, index, {
              ...data,
              editDescription : true
            })


           /*  this.$nextTick(() => {
              this.$refs[`textArea${index}`].focus();
            }); */
    },
    fnEditDescriptionByType(scheduleIndex,index,data){
      const activeScheduleData = this.activeSchedule[scheduleIndex].data.map((item) => {
          return {...item,editDescription : false}
      },[])


      console.log(activeScheduleData)
/* 
      
      this.$set(this.activeSchedule[scheduleIndex].data, index, {
              ...data,
              editDescription : true
            }) */
      /* this.$set(this.activeSchedule[scheduleIndex].data, index, {
              ...data,
              editDescription : true
            })
 */

           /*  this.$nextTick(() => {
              this.$refs[`textArea${index}`].focus();
            }); */
    },

    updateRecordStatus(index, data){      
      this.$set(this.activeEvent.data, index, {...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})
      this.$set(this.events, data.identifier, {...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})

      console.log({...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})
      this.updateRecord({id :data.data.transaction.mdbID, invoice : data.data.invoice.series, date :  data.date, description : data.data.description, type : data.type, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')})
      
    },

    removeRecord(index, data){     

      const self = this;
      console.log(data)
      this.activeEvent.data.splice(index, 1);

      const indexIdentifier = this.events.findIndex(obj => obj.identifier === data.identifier);
      this.events.splice(indexIdentifier, 1);

      this.deleteRecord({
        type : data.type,
        start : data.start,
        end : data.end,
        date : data.date,
        invoice : data.title,
        id : data.data.transaction.mdbID
      }).then((res) => {
        // console.log(res)
        self.$ThatsNotify.success({
                    message : `${data.title} was successfully deleted.`
                })
      })

     // this.$set(this.events, data.index, {...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})


     /*  this.$set(this.activeEvent.data, index, {...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})
      this.$set(this.events, data.index, {...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})

      console.log({...data,data : {...data.data, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')}})
      this.updateRecord({id :data.data.transaction.mdbID, invoice : data.data.invoice.series, date :  data.date, description : data.data.description, type : data.type, status : (data.data.status === 'confirmed' ? 'for-delivery' : 'confirmed')})
       */
    },
    makeDeliveryNote(data){

      console.log("Request Delivery note")
      console.log(data)
      const form  = {...data.data.transaction,
                    items : [],
                    delivery : [],
                    delivered_items : [],
                    attachment : [], invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    invoice : { ...data.data.invoice },
                    transaction : {...data.data.transaction,invoice : { ...data.data.invoice }}
                  }           
            this.setDeliferyFormStatusUpdate()
            this.setDeliveryFormRecord(form)
            this.getDeliveryRecord({
                  type : data.type,
                  start : data.start,
                  end : data.end,
                  date : data.date,
                  invoice : data.title,
                  id : data.data.transaction.mdbID,
                  token_id : data.data.transaction.token_id
                })
            
            this.$refs.deliveryForm.openDialog()
    },

    fnPrintDoc(url){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(url, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
        return false
    },

    handleClick(index,obj, type){
      const self = this;


      
      if(['delivery-schedule','pullout-schedule'].includes(type)){
                      
                      const form  = {...obj.data.transaction,items : [],attachment : [],invoice : {
                                        series : null,
                                        date : "",
                                        balance : 0,
                                        paid : 0,
                                        attachment : [],
                                        payments : [],
                                    },
                                    credit_note : {
                                        total : 0,
                                        status : null,
                                        credit_note_reference : []
                                    },
                                    creditnotes : [],
                                    credit_note_items : [],
                                    cancellation : [],
                                    cancellation_item : [],
                                    refund : [],
                                    refund_item : [],
                                    exchange : [],
                                    credit_memo : [],
                                    exchange_item : [],
                                    enable_push : false,
                                    location : null,
                                    } 
                
                
                             
                                    if(!form.attr.hasOwnProperty('corporate')){
                                            form.attr.corporate = false
                                        }
                                        if(!form.attr.hasOwnProperty('contact_person')){
                                            form.attr.contact_person = {
                                                name : null,
                                                mobile : null,
                                                attr : {
                                  
                                                  address: null,
                                                  address2 : {
                                                      street : null,
                                                      building : null,
                                                      zone : null,
                                                      area : null,
                                                      city : null,
                                                  },
                                                  designation : null,
                                                  email : null,
                                                  first_name : null,
                                                  middle_name : null,
                                                  last_name : null,
                                                  salutation : null
                                                }
                                              }
                                        }
                      self.invoiceStatusUpdate()
                      self.invoiceSetRecord(form)
                      self.invoiceGetRecord({token_id : obj.data.transaction.token_id, series : obj.data.invoice.series})
  
                      this.invoiceSetTblKey(this.salesTblKey + 1);
                      this.$refs.invoiceForm.openDialog();
        }


        if(['delivered','pullout'].includes(type)){
          const form  = {...obj.data.transaction,
                    items : [],
                    delivery : [],
                    delivered_items : [],
                    attachment : [], invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    invoice : { ...obj.data.invoice },
                    transaction : {...obj.data.transaction,invoice : { ...obj.data.invoice }}
                  }           
            this.setDeliferyFormStatusUpdate()
            this.setDeliveryFormRecord(form)
            this.getDeliveryRecord({
                  type : obj.type,
                  start : obj.start,
                  end : obj.end,
                  date : obj.date,
                  invoice : obj.title,
                  id : obj.data.transaction.mdbID,
                  token_id : obj.data.transaction.token_id
                })
            
            this.$refs.deliveryForm.openDialog()
        }

        if(['picklist'].includes(type)){
          const form  = {...obj.data.transaction,
                    items : [],
                    delivery : [],
                    delivered_items : [],
                    attachment : [], invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    invoice : { ...obj.data.invoice },
                    transaction : {...obj.data.transaction,invoice : { ...obj.data.invoice }}
                  }           
            this.setDeliferyFormStatusUpdate()
            this.setDeliveryFormRecord(form)
            this.getDeliveryRecord({
                  type : obj.type,
                  start : obj.start,
                  end : obj.end,
                  date : obj.date,
                  invoice : obj.title,
                  id : obj.data.transaction.mdbID,
                  token_id : obj.data.transaction.token_id
                })
            
            this.$refs.picklistForm.openDialog()
        }
    },


    refreshCalendar(){
      this.$emit('dateChange',this.date)
      },

      openPicklist(data){
        console.log("open picklist");
        console.log(data);



        const form =
          {...transaction,
                    items : [],
                    delivery : [],
                    delivered_items : [],
                    attachment : [], invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    invoice : { ...data.data.invoice },
                    transaction : {...data.data.transaction,invoice : { ...data.data.invoice }}
                  }      
        
     
        this.setDeliferyFormStatusUpdate()
        this.setDeliveryFormRecord(form)
        this.$refs.picklistForm.openDialog()
      },

      cancelDeliveryNote(series,data){
          const self = this;


        
            MessageBox.confirm('Are you sure you want to cancel delivery note?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              self.fnCancelDeliveryNote({series : series, date : data.date, }).then((response) => {
                 // self.setFormRecord(response.data)
                 self.$emit('dateChange',self.date)
                 self.$ThatsNotify.success({
                    message : `${series} was successfully deleted.`
                })
              })
            })

        },
        
        cancelPullOut(series,data){
          const self = this;


        
            MessageBox.confirm('Are you sure you want to cancel pullout?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {

              console.log("pullout data")
              console.log(data)

              const getTransactionId = data.data.filter((item)=>{
                  return item.title === series
              },[])

              if(getTransactionId.length > 0){
                console.log(getTransactionId)

                const pulloutRecord = getTransactionId[0]
                self.fnCancelPulloutRecord({series : series, date : data.date, obj : pulloutRecord.data }).then((response) => {
                   // self.setFormRecord(response.data)
                   self.$emit('dateChange',self.date)
                   self.$ThatsNotify.success({
                      message : `${series} was successfully deleted.`
                  })
                })
              }
            })

        },

        openDeliveryNoteIntegration(){
            // alert(1)
            this.$refs.deliveryImportForm.openDialog();
        },
        
        deliveryImportFormIntegration(){
         console.log(this.activeEvent.data)

         const activeData = this.activeEvent.data.filter((item) => item.type == 'delivered' && item.title !== null,[]).map((item) => item.title,[]);

          if(activeData.length > 0){
            this.setDeliveryNoteIntegrationData(activeData)
            this.$refs.deliveryImportForm.openDialog();
          }
        }

       /*  cancelPullOut(object, token){
          const self = this;
            // const deliveryNoteRow = [{...row}];
            MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              self.cancelPullOutRecord({"obj" : object, "token_id" : token}).then((response) => {
                  self.setFormRecord(response.data)
              })
            })

        },
 */



  },

  computed : {
      scheduleTypes :  function(){
        const typeLabel = []
                typeLabel['delivered']  = 'Delivery Notes'
                typeLabel['delivery-schedule']  = 'Scheduled Delivery'
                typeLabel['pullout']  = 'Pullout'
                typeLabel['pullout-schedule']  = 'Scheduled Pullout'
                typeLabel['delivered-pushed-to-sap']  = 'Delivered'
                typeLabel['picklist']  = 'Picklist'
                return typeLabel
      },
      activeRecordStatus :  function(){
        const statusLabel = []
                statusLabel['for-delivery']  = 'Need Confirmation'
                statusLabel['partial-delivery']  = 'Delivered'
                statusLabel['final-delivery']  = 'Delivered'
                statusLabel[null]  = 'Need Confirmation'
                statusLabel['confirmed']  = 'Confirmed'
                statusLabel['delivered']  = 'Confirmed'
                statusLabel['pullout']  = 'Pullout'
                statusLabel['picklist']  = 'Picklist'
                statusLabel['open']  = 'Open'
                statusLabel['closed']  = 'Closed'
                statusLabel['partially-picked']  = 'Partially Picked'
                statusLabel['picked']  = 'Picked'
                statusLabel['canceled']  = 'Cancelled'
                return statusLabel
      },
      dropdownRowStatus :  function(){
        const statusLabel = []
                statusLabel['for-delivery']  = 'Confirmed'
                statusLabel['delivered']  = 'Need Confirmation'
                statusLabel['confirmed']  = 'Need Confirmation'
                statusLabel['partial-delivery']  = 'Confirmed'
                statusLabel['final-delivery']  = 'Confirmed'
                statusLabel[null]  = 'Confirmed'
                return statusLabel
      },
      
      warningRowStatus :  function(){
          return ['for-delivery','partial-delivery','final-delivery', null,'delivered']
      },

      hasDifference : function(){

        
        const eventCopy = this.activeEventCopy.data.filter(item => item.title !== '').map(item => item.title);
        const eventActive = this.activeEvent.data.filter(item => item.title !== '').map(item => item.title);

        return eventCopy.filter(x => !eventActive.includes(x))
                 .concat(eventActive.filter(x => !eventCopy.includes(x)))

        /* return {
            copy : eventCopy,
            copyx : eventActive,
        } */
        // return eventCopy.filter(title=> eventActive.includes(title));


/*         const isSameUser = (a, b) => a.value === b.value && a.display === b.display;

// Get items that only occur in the left array,
// using the compareFunction to determine equality.
const onlyInLeft = (left, right, compareFunction) => 
  left.filter(leftValue =>
    !right.some(rightValue => 
      compareFunction(leftValue, rightValue)));

const onlyInA = onlyInLeft(a, b, isSameUser);
const onlyInB = onlyInLeft(b, a, isSameUser);

const result = [...onlyInA, ...onlyInB]; */
      },

      computedActiveEvent : function(){
          return this.activeEvent;
      }

      

      
  },
  watch: {
    defaultDate(newVal) {
      this.date = newVal;
    },
    newEvents() {
      this.$emit('getEvents', this.newEvents)
    },
   /*  activeEvent() {
      this.activeEvent.start.getDate() < 10 ? this.activeEventData.startDate = '0' + this.activeEvent.start.getDate() : this.activeEventData.startDate = this.activeEvent.start.getDate()
      this.activeEvent.start.getMonth() < 10 ? this.activeEventData.startMonth = '0' + (this.activeEvent.start.getMonth() + 1) : this.activeEventData.startMonth = (this.activeEvent.start.getMonth() + 1)
      this.activeEvent.start.getHours() < 10 ? this.activeEventData.startHours = '0' + this.activeEvent.start.getHours() : this.activeEventData.startHours = this.activeEvent.start.getHours()
      this.activeEvent.start.getMinutes() < 10 ? this.activeEventData.startMinutes = '0' + this.activeEvent.start.getMinutes() : this.activeEventData.startMinutes = this.activeEvent.start.getMinutes()
      this.activeEvent.start.getSeconds() < 10 ? this.activeEventData.startSeconds = '0' + this.activeEvent.start.getSeconds() : this.activeEventData.startSeconds = this.activeEvent.start.getSeconds()
      this.activeEvent.end.getDate() < 10 ? this.activeEventData.endDate = '0' + this.activeEvent.end.getDate() : this.activeEventData.endDate = this.activeEvent.end.getDate()
      this.activeEvent.end.getMonth() < 10 ? this.activeEventData.endMonth = '0' + (this.activeEvent.end.getMonth() + 1) : this.activeEventData.endMonth = (this.activeEvent.end.getMonth() + 1)
      this.activeEvent.end.getHours() < 10 ? this.activeEventData.endHours = '0' + this.activeEvent.end.getHours() : this.activeEventData.endHours = this.activeEvent.end.getHours()
      this.activeEvent.end.getMinutes() < 10 ? this.activeEventData.endMinutes = '0' + this.activeEvent.end.getMinutes() : this.activeEventData.endMinutes = this.activeEvent.end.getMinutes()
      this.activeEvent.end.getSeconds() < 10 ? this.activeEventData.endSeconds = '0' + this.activeEvent.end.getSeconds() : this.activeEventData.endSeconds = this.activeEvent.end.getSeconds()
    }, */
    newEvent() {
      this.newEvent.start.getDate() < 10 ? this.newEventData.startDate = '0' + this.newEvent.start.getDate() : this.newEventData.startDate = this.newEvent.start.getDate()
      this.newEvent.start.getMonth() < 10 ? this.newEventData.startMonth = '0' + (this.newEvent.start.getMonth() + 1) : this.newEventData.startMonth = (this.newEvent.start.getMonth() + 1)
      this.newEvent.start.getHours() < 10 ? this.newEventData.startHours = '0' + this.newEvent.start.getHours() : this.newEventData.startHours = this.newEvent.start.getHours()
      this.newEvent.start.getMinutes() < 10 ? this.newEventData.startMinutes = '0' + this.newEvent.start.getMinutes() : this.newEventData.startMinutes = this.newEvent.start.getMinutes()
      this.newEvent.start.getSeconds() < 10 ? this.newEventData.startSeconds = '0' + this.newEvent.start.getSeconds() : this.newEventData.startSeconds = this.newEvent.start.getSeconds()
      this.newEvent.end.getDate() < 10 ? this.newEventData.endDate = '0' + this.newEvent.end.getDate() : this.newEventData.endDate = this.newEvent.end.getDate()
      this.newEvent.end.getMonth() < 10 ? this.newEventData.endMonth = '0' + (this.newEvent.end.getMonth() + 1) : this.newEventData.endMonth = (this.newEvent.end.getMonth() + 1)
      this.newEvent.end.getHours() < 10 ? this.newEventData.endHours = '0' + this.newEvent.end.getHours() : this.newEventData.endHours = this.newEvent.end.getHours()
      this.newEvent.end.getMinutes() < 10 ? this.newEventData.endMinutes = '0' + this.newEvent.end.getMinutes() : this.newEventData.endMinutes = this.newEvent.end.getMinutes()
      this.newEvent.end.getSeconds() < 10 ? this.newEventData.endSeconds = '0' + this.newEvent.end.getSeconds() : this.newEventData.endSeconds = this.newEvent.end.getSeconds()
    },

    /* newSchedule : {
      handler : function(val){
          console.log("demo demo")
          console.log(val)
      },
      deep : true
    } */
  }
}

export default mdbCalendar;
export { mdbCalendar as mdbCalendar };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.custom-control-label {
  cursor: pointer;
}

.mdb-calendar{
      height: calc(100vh - 42px);
}

.delivery-badge{
  font-size: 14px;
  box-shadow: initial!important;
}
.vs__selected-options{
  padding: 0!important;
}
.vs--single .vs__selected{
  padding-left: 0!important;margin-left: 0!important;
}


</style>
