/* eslint-disable no-prototype-builtins */
<template>
  <div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <XWindow ref="window" :title="`Item Promotion`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)" :isMaximized="isMaximized" @onResize="onResize">

        <template #left-button>



              <template v-if="formLoading || getLoading || postLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <template v-if="form.status == 'draft'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('draft'))"><i class="el-icon-success"></i> Save as draft</mdb-btn>
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('active'))"><i class="el-icon-success"></i> Save as Active</mdb-btn>
                          <mdb-btn color="elegant" size="sm" type="button" @click="fnPrint(form.series)"><i class="el-icon-print"></i> Print</mdb-btn>
                          <mdb-btn color="elegant" size="sm" type="button" @click="fnExport(form.series)"><i class="el-icon-print"></i> Export</mdb-btn>
                        </template>

                        <template v-if="form.status == 'active'">
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('active'))"><i class="el-icon-success"></i> Save</mdb-btn>
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('inactive'))"><i class="el-icon-close"></i> Deactivate</mdb-btn>
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('cancelled'))"><i class="el-icon-close"></i> Cancel</mdb-btn>
                          <mdb-btn color="elegant" size="sm" type="button" @click="fnPrint(form.series)"><i class="el-icon-print"></i> Print</mdb-btn>
                          <mdb-btn color="elegant" size="sm" type="button" @click="fnExport(form.series)"><i class="el-icon-print"></i> Export</mdb-btn>
                        </template>

                        <template v-if="form.status == 'inactive'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('inactive'))"><i class="el-icon-success"></i> Save</mdb-btn>
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('active'))"><i class="el-icon-success"></i> Activate</mdb-btn>
                        </template>

                        <template v-if="form.status == 'cancelled'">
                          <mdb-btn color="elegant" size="sm"  @click="passes(onSubmitForm('active'))"><i class="el-icon-success"></i> Activate</mdb-btn>
                        </template>
                      </template>


          </template>

            <template #right-button>
            </template>
            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

                <mdb-row class="tab-row">
                  <mdb-col col="3" class="tab-col">

                    <ThatsInput :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Document" placeholder="Document" vid="document" name="document" v-model="form.name" :validated="validated" :rules="'required'" size="sm"/>
                  </mdb-col>
                  <mdb-col col="3" class="tab-col">
                    <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="State Date" placeholder="State Date" vid="state_date" name="state_date" v-model="form.promo_start" :validated="validated" :rules="'required'" size="sm"/>
                  </mdb-col>
                  <mdb-col col="3" class="tab-col">
                  <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="End Date" placeholder="End Date" vid="end_date" name="end_date" v-model="form.promo_end" :validated="validated" :rules="'required'" size="sm"/>
                  </mdb-col>

                  <mdb-col col="3" class="tab-col">
                    <ThatsAutocomplete :clearable="false" tabindex="7" :addButton="false" :appendButton="false" labelClass="col-lg-5 col-md-5 col-sm-12" fieldClass="col-lg-7 col-md-7 col-sm-12 pl-lg-0 pl-md-0" label="Type" v-model="form.type" :reduce="label => label.value" :reduceLabel="'label'" :options="promotionType" :rules="'required'"></ThatsAutocomplete>
                  </mdb-col>

                </mdb-row>

                <div ref="spreadsheet" class="bts-window-form mt-1" v-bind:style="{'height': jexcelContainer.height}"></div>
              </template>



            </mdb-tabs>

          </XWindow>

  </ValidationObserver>


  <CoolLightBox
            :items="gallery"
            :index="index"
            :useZoomBar="true"
            @close="index = null">
            </CoolLightBox>
    <UploadFromDevice ref="uploadRef"></UploadFromDevice>
  </div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown,
  mdbDropdownItem,
  mdbDropdownMenu,
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker, UploadFromDevice,ThatsAutocomplete } from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['posShowroom','showroomsProvider','userData'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        UploadFromDevice,
        ThatsAutocomplete
  },

  data(){

    const self = this;

    return {
      promotionType : [
               {label : "Silent Discount", value : "silent"},
               {label : "Public Promotion", value : "public"}
           ],
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        filteredShowroomx :[ { "id": 4, "name": "Kareem Kandil", "token": "rm0wMvbmOeYAlO1n", "mdbID": 4, "label": "Kareem Kandil", "value": 4 }, { "id": 18, "name": "Ghamer Hussain Ameer", "token": "q1gK4oeEva0ByxjX", "mdbID": 18, "label": "Ghamer Hussain Ameer", "value": 18 }, { "id": 348, "name": "Beatriz Rosales", "token": "rm0wMvbmyAbYAlO1", "mdbID": 348, "label": "Beatriz Rosales", "value": 348 } ],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        jexcelContainer : {
            height : 'calc(100vh - 165px)'
        },
        spreadsheetApplyLoading: false,
        spreadsheet: null,
        tabs : [
                { text: 'Details', slot: 'form-slot' }
              ],

        gallery : [ "https://media.thatsliving.com/public/uploads/image-jpeg/small-4e5d8cb97525e59399d12ba1cba3f0c3-cnig-1702912-sl-001.jpg" ],
        index : null,

        inventoryTransferOptionsFrom : [],
        inventoryTransferOptionsTo : [],
        inventoryTransferItems : [],
        spreadsheetOptions : {

          copyCompatibility: true,
            download : false,
            csvFileName : 'promotions',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowInsertColumn : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : true,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 165px)',
            tableOverflow : true,
            data:[],
             minDimensions:[6,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],


            updateTable:function(instance, cell, col, row, val, label, cellName) {
                if((row % 2) == 1){
                    cell.parentNode.style.backgroundColor = '#FAFAFA';
                }

                if (col == 1) {

                  if(val != null && val != ''){
                    cell.innerHTML = '<img src="' + val + '" style="width:80px;height:80px">';
                  }else{
                    cell.innerHTML = '<img src="https://media.thatsliving.com/public/uploads/image-png/full-e271b992b2be985538ba519189106b1f-tl100.png" style="width:80px;height:80px">';
                  }
                }




            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                  if(['draft'].includes(self.form.status)){

                      if (obj.options.allowInsertRow == true) {
                          items.push({
                              title:obj.options.text.insertANewRowBefore,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y), 1);
                              }
                          });

                          items.push({
                              title:obj.options.text.insertANewRowAfter,
                              onclick:function() {
                                  obj.insertRow(1, parseInt(y));
                              }
                          });
                      }

                      if (obj.options.allowDeleteRow == true) {
                          items.push({
                              title:obj.options.text.deleteSelectedRows,
                              onclick:function() {
                                  obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                              }
                          });
                      }
                  }

                        /* if (obj.options.allowDeleteRow == true) {
                        items.push({
                            title:obj.options.text.deleteSelectedRows,
                            onclick:function() {
                                obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                            }
                        });

                    } */
                return items;
             },

             onblur: function(instance){
              self.setPasteLoadingFalse()
             },
             onchange: function(domElement, domElmentCell, column, row, value,oldValue){

                if(column == 0){
                    // self.spreadsheet.setValueFromCoords(1,row,'https://media.thatsliving.com/public/uploads/image-jpeg/thumb-46a757cc2eaf5b00ff32dd6f93468ddf-cla-016-123-hr.jpg',true)


                    console.log(`${value} ====> ${oldValue} ==== row ${row}`)
                    if((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)){
                      self.fnGetItem(value,row)
                    }
                }



             },
             onafterchanges : function(){
              self.setPasteLoadingFalse()
             },
             onpaste : function(){
               self.setPasteLoadingFalse()

             },
             onbeforechange : function(){
              self.setPasteLoadingTrue()
             }

       }
    }
  },


  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;

       this.resetForm();

        if(this.isMaximized){
          this.width = window.innerWidth;
          this.height = window.innerHeight;
          this.spreadsheetOptions.tableWidth = `${window.innerWidth - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
          this.jexcelContainer.height = `calc(100vh - 195px)`

        }else{
          this.width = 1300;
          this.height = 600;

          this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
          this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
          this.jexcelContainer.height = `calc(100vh - 233px)`
        }


  },

  mounted(){
    const self = this
    this.$eventBus.$on('attachFileRemove',function({row, index}){

      let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

      const parsedvalue = JSON.parse(JSON.stringify(cellData))


      MessageBox.confirm('Are you sure you want to remove attachment?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      const newData = [];
                      cellData.forEach((element,innerIndex) => {
                          if(innerIndex != index){
                            newData.push(element)
                          }
                      });

                      self.spreadsheet.setValue(`H${(row+1)}`,newData)

                     /*  const halfBeforeTheUnwantedElement = parsedvalue.slice(0, 2)

                  const halfAfterTheUnwantedElement = parsedvalue(3);

                  const copyWithoutThirdElement = halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement); */


                      /* const previousSecondElementOfTheArray = parsedvalue.splice(index,1)
                       */
                      /*
                       */

                    })


       /*  if(self.formStatus == 'create'){


          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){

          if(rowData.attachments.length > 0){
           self.gallery = rowData.attachments.map((item) => item.src,[])
           self.index = index
          }
        } */
    })
    this.$eventBus.$on('attachFileView',function({row, index}){

      const rowData = self.form.items[row]

        if(self.formStatus == 'create'){


          self.gallery = rowData[7].map((item) => item.src,[])
           self.index = index
        }
        if(self.formStatus == 'update'){

          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (Array.isArray(parsedvalue)) {
            if(parsedvalue.length > 0){
            self.gallery = parsedvalue.map((item) => item.src,[])
            self.index = index
            }
          }



        }
    })
    this.$eventBus.$on('attachFile',function({row}){

        //this.index = index

      //  alert("attach file")
      self.$refs.uploadRef.triggerBrowseFile(row);
    })
    this.$eventBus.$on('uploadDone',function({data,row}){


          let cellData = self.spreadsheet.getValue(`H${(row+1)}`)

          const parsedvalue = JSON.parse(JSON.stringify(cellData))

          if (!Array.isArray(parsedvalue)) {
            cellData = [];
          }

          cellData.push({src : data.path})
          self.spreadsheet.setValue(`H${(row+1)}`,cellData)

    })
  },


  methods: {
    ...mapActions('ItemModule',['fetchItem']),
    ...mapMutations('ItemPromotionModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),

    ...mapActions('ItemPromotionModule',['storeRecord','updateRecord','getRecord']),


      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                tbo: false,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false
              }
      },


    async setPasteLoadingTrue(){
      await this.$nextTick();
      this.onPasteLoading = true
      this.$forceUpdate();
      console.log("paste loading true")
    },
    async setPasteLoadingFalse(){
      await this.$nextTick();
      this.onPasteLoading = false
      this.$forceUpdate();
      console.log("paste loading false")
    },

    onResize({width,height}){

        if(!Number.isNaN(width) && !Number.isNaN(height)){
          console.log("update")
          console.log(width)
          console.log(height)


          if(width > 1300){
            this.spreadsheetOptions.tableWidth = `${width - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 195px)`
            this.jexcelContainer.height = `calc(100vh - 195px)`
            console.log(`calc(100vh - 165px)`)
          }else{
            this.spreadsheetOptions.tableWidth = `${1300 - 3}px`
            this.spreadsheetOptions.tableHeight = `calc(100vh - 233px)`
            this.jexcelContainer.height = `calc(100vh - 233px)`
            console.log(`calc(100vh - 203px)`)
          }

          this.spreadsheetOptions.style = this.spreadsheet.getStyle();
          const tableData = this.spreadsheet.getJson();

          this.spreadsheet.destroy();
          this.spreadsheetOptions.data = tableData

            this.setColumnProperty()

            if(['for-approval','approved','rejected'].includes(this.form.status)){
              this.spreadsheetOptions.minDimensions = [6,this.form.items]
            }else{
              this.spreadsheetOptions.minDimensions = [6,50]
            }

            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, this.spreadsheetOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)


        }



      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));


      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(saveAs = 'save'){
       const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){
                 const tableData = this.spreadsheet.getJson();
                 const getData = this.spreadsheet.getData();

                const mapData = [];
                 getData.forEach(element => {
                    const rowData = {}
                    self.tableColumns.forEach((col, index) => {
                      rowData[col.name] = element[index];
                    })
                    mapData.push(rowData)
                 });

                 console.log(mapData)
              if(mapData.length > 0){
                  const checkRow = mapData.filter((item) => item.series !== '',[]);
                  let noDiscount = 0;
                  let noPrice = 0;
                  let itemNoteFound = 0;

                  checkRow.forEach(element => {
                      if(element.discount == '' || element.discount <= 0){
                        noDiscount++;
                      }
                  });

                  if(noDiscount > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'Items discount must be greater than zero.',
                              type: 'error'
                          })
                      return false;
                  }

                  checkRow.forEach(element => {
                      if(element.price == '' || element.price <= 0){
                        noPrice++;
                      }
                  });

                  if(noPrice > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'Items price must be greater than zero.',
                              type: 'error'
                          })
                      return false;
                  }

                  checkRow.forEach(element => {
                      if(element.item_id == '' || element.item_id <= 0){
                        itemNoteFound++;
                      }
                  });

                  if(itemNoteFound > 0){
                          MessageBox({
                              title : 'Error',
                              message: 'There are items not exists in Item Master.',
                              type: 'error'
                          })
                      return false;
                  }


                  const getValidData = checkRow.filter((row)=> {
                      return (row.discount != '' && row.price && row.item_id != '')
                  },[])

                  if(getValidData.length <= 0){
                    MessageBox({
                              title : 'Error',
                              message: 'No valid data',
                              type: 'error'
                          })
                      return false;
                  }

                  this.form.items = getValidData;
                  // this.form.status = saveAs;
                  const formData = JSON.parse(JSON.stringify(this.form));

                  if(this.formStatus == 'create'){
                    formData.status = saveAs;
                    this.storeRecord(formData).then((res) => {
                      self.$ThatsNotify.success({
                        message : "Record successfully created."
                      })
                      self.closeDialog()
                      self.$emit('success')
                    }).catch((error) => {
                        const responseData = error.response?.data;
                        const errorMessage = responseData?.message || 'An error occurred while creating the record.';

                        let duplicateItemsList = '';
                        if (responseData?.duplicates && responseData.duplicates.length > 0) {
                            duplicateItemsList = '<ul>';
                            responseData.duplicates.forEach(item => {
                                duplicateItemsList += `<li>Series: ${item.series}</li>`;
                            });
                            duplicateItemsList += '</ul>';
                        }

                        MessageBox({
                            title: 'Error',
                            message: `${errorMessage}${duplicateItemsList ? '<br><br>Duplicates Found:<br>' + duplicateItemsList : ''}`,

                            dangerouslyUseHTMLString: true // Allows HTML rendering in the message
                        });
                    });
                  }

                  if(this.formStatus == 'update'){


                    if(saveAs == 'cancelled'){
                      MessageBox.confirm('Are you sure you want to Approve?', 'Warning', {
                          confirmButtonText: 'OK',
                          cancelButtonText: 'Cancel',
                          type: 'warning'
                          }).then(() => {

                            self.formLoading = true
                            formData.status = saveAs;
                              this.updateRecord(formData).then((res) => {
                                self.$ThatsNotify.success({
                                  message : "Record successfully created."
                                })
                                self.closeDialog()
                                self.$emit('success')
                                self.formLoading = false
                              });

                          })
                    }else{

                    self.formLoading = true
                    formData.status = saveAs;
                      this.updateRecord(formData).then((res) => {
                        self.$ThatsNotify.success({
                          message : "Record successfully created."
                        })
                        self.closeDialog()
                        self.$emit('success')
                        self.formLoading = false
                      });

                    }


                  }

                  console.log(getValidData)

              }
            }


    },
    openHistory(){

    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){
        spreadsheetOptions.data = this.form.items


        console.log("spreadsheetOptions")
        console.log(spreadsheetOptions)
        console.log(this.form)


        this.setColumnProperty()

        if(['active','approved','expired'].includes(this.form.status)){
          spreadsheetOptions.minDimensions = [6,this.form.items]
        }else{
          spreadsheetOptions.minDimensions = [6,10]
        }

        this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, spreadsheetOptions);
        Object.assign(this, this.spreadsheet);
        this.spreadsheet.setWidth(window.innerWidth)


        console.log(this.spreadsheet)


    },




        fnPrint(series){
          const self = this;
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/print/${series}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExport(series){
          const self = this;
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/export/${series}`, "_blank", strWindowFeatures);
          win.document.title = 'Export';
        },


        renderTable(){
            const self = this;
            this.spreadsheetOptions.columns = this.tableColumns;

            console.log("this.spreadsheetOptions");
            console.log(this.spreadsheetOptions);
            this.createPopupTable(this.spreadsheetOptions);


        },


        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){
            this.spreadsheetOptions.style = {};
            this.spreadsheet.destroy();

            this.resetForm();
          return
        }

      },

      async fnGetItem(itemCode, index){
        const self = this;
        await this.fetchItem({item : itemCode, index : index}).then((res)=> {
            const row = parseInt((index + 1))
            self.spreadsheet.setValueFromCoords(0,index,res.itemCode,true)
            self.spreadsheet.setValueFromCoords(1,index,res.photo,true)
            self.spreadsheet.setValueFromCoords(2,index,res.name,true)
            self.spreadsheet.setValueFromCoords(3,index,res.dimension,true)
            self.spreadsheet.setValueFromCoords(4,index,res.sale_price,true)
            self.spreadsheet.setValueFromCoords(5,index,0,true)

            self.spreadsheet.setValueFromCoords(6,index,`=IF(F${row} > 0,E${row}*(F${row}/100),"")`,true)
            self.spreadsheet.setValueFromCoords(7,index,`=IF(F${row} > 0,E${row}-(E${row}*(F${row}/100)),"")`,true)
            self.spreadsheet.setValueFromCoords(8,index,res.id,true)
            self.spreadsheet.setValueFromCoords(10,index,1,true)
            const parseIntIndex = parseInt(index)


            const backgroundColor = 'transparent'
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)

        }).catch(() => {
          const parseIntIndex = parseInt(index)
          let backgroundColor = 'rgb(255 200 200)';
          if(( parseIntIndex % 2) == 1){
            backgroundColor = 'rgb(233 167 167)';
          }
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`G${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`H${parseIntIndex+1}`, 'background-color', backgroundColor)

        })
    },

    setColumnProperty(){
      if(this.form.status != 'draft' && this.formStatus == 'update'){
        const currentUser = this.userData.user.id

        /*  this.spreadsheetOptions.columns = this.tableColumns.map((row) => {
              return {
                ...row,
                readOnly : true
              }
            },[]) */
      }

    },


    handleSelectionChange(v){

      this.form.inventory_transfer_form.items = v
    },

    canSelectRow(row, index){
      /* let inventoryTransferFrom = false
      let inventoryTransferTo = false

      if(this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form == null){
        console.log("nullllll")
      } */


     const inventoryTransferFrom = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomFrom != null && this.form.inventory_transfer_form.hasOwnProperty('showroomFrom') && this.form.inventory_transfer_form.showroomFrom.value !== null) ? true : false
      const inventoryTransferTo = (this.form.hasOwnProperty('inventory_transfer_form') && this.form.inventory_transfer_form.showroomTo != null && this.form.inventory_transfer_form.hasOwnProperty('showroomTo') && this.form.inventory_transfer_form.showroomTo.value !== null) ? true : false
        if(row.remaining <= 0 || !inventoryTransferTo || !inventoryTransferFrom){
           return false
        }
        return true;
    },

    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },

    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },



  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {

    ...mapGetters('ItemPromotionModule',['getLoading','formData','formStatus','initialFormObject','putLoading','postLoading']),
      tblKey : function(){
        return 1;
      },


      tableColumns : function(){


        return [
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'series',
                    title : 'Item Code'
                  },
                  {
                    type : 'text',
                    width : '90px',
                    align : 'left',
                    name : 'photo',
                    title : 'Photo',
                    readOnly : true
                  },

                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Description',
                    readOnly : true
                  },


                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'dimension',
                    title : 'Dimension',
                    readOnly : true
                  },

                  {
                    type : 'numeric',
                    width : '150px',
                    align : 'center',
                    name : 'price',
                    title : 'Price',
                    mask:'#,##.00', decimal:'.'
                  },


                  {
                    type : 'numeric',
                    width : '100px',
                    align : 'center',
                    name : 'discount',
                    title : 'Discount %',
                    mask:'#,##.00', decimal:'.'
                  },
                  {
                    type : 'numeric',
                    width : '150px',
                    align : 'center',
                    name : 'discount_off_price',
                    title : 'Off Price',
                    mask:'#,##.00', decimal:'.',
                    readOnly : false
                  },

                  {
                    type : 'numeric',
                    width : '150px',
                    align : 'center',
                    name : 'discount_promo_price',
                    title : 'Promo Price',
                    mask:'#,##.00', decimal:'.',
                    readOnly : false
                  },
                    {
                      type : 'hidden',
                      name : 'item_id',
                    },


                    {
                      type : 'hidden',
                      name : 'id',
                    },
                    {
                      type : 'hidden',
                      name : 'apply',
                    },





            ]
      },

      isAllowedToApproved : function(){
        return this.form.approver.map((row) => row.id,[]).includes(this.userData.user.id) || [2,18].includes(this.userData.user.id)
      },

      showMakeInventoryTransfer : function(){
          return this.form.status == 'approved'
      },

      showInventoryTransfer : function(){
          return this.form.status == 'approved'
      },





  },

  watch: {
      'formData' : function(v){

        const self = this


        if(this.formStatus == 'update'){

          /*
            self.spreadsheet.setValueFromCoords(6,index,`=IF(F${row} > 0,E${row}*(F${row}/100),"")`,true)
            self.spreadsheet.setValueFromCoords(7,index,`=IF(F${row} > 0,E${row}-(E${row}*(F${row}/100)),"")`,true)
             */

          v.items = v.items.map((item, index) => {
            const row = parseInt((index + 1))
            return {
              series : item.code,
              photo : item.photo,
              name : item.name,
              dimension : item.dimension,
              price : item.price,
              discount : item.discount,
              discount_off_price : `=IF(F${row} > 0,E${row}*(F${row}/100),"")`,
            /*   discount_off_price : `${row}`, */
              discount_promo_price : `=IF(F${row} > 0,E${row}-(E${row}*(F${row}/100)),"")`,
              item_id : item.item_id,
              id : item.id,
              apply : item.apply,
            }
          })

          this.form = JSON.parse(JSON.stringify({...v}));


            this.$nextTick(()=>{
              if(self.spreadsheet != null){
                self.spreadsheet.destroy();
              }
              self.spreadsheetOptions.columns = this.tableColumns;

              this.setColumnProperty()
              console.log("self.spreadsheetOptions.columns")
              console.log(self.spreadsheetOptions.columns)

              self.createPopupTable(this.spreadsheetOptions);


              self.spreadsheet.setData(this.form.items)
            })

            this.active = 0;
            this.eventKeyCounter++;


        }else{

          if(self.spreadsheet != null){
            self.spreadsheet.destroy();
          }


          this.resetForm();
          this.currentDateSetter();
          this.renderTable();


          this.active = 0;
        }

      },



      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },



  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>
