import {ApiHttpService, ApiService} from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const LoyaltyRedeemVoucherModule = {
    namespaced: true,
    state: {
        request: null,
        loading: false,
        data: {
            columns: [],
            rows: [],
        },
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        multipleSelection: [],
        tblKey: 0,
        formStatus : 'create',
    },
    actions: {
        async resetData({dispatch }, endpoint){
            dispatch('asyncGetData', endpoint)
        },
        async asyncGetData({ state, commit, dispatch },endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    commit('SetData', [])
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get(endpoint, params).then((response) => {
                        console.log(response,"Swaraj Bhuyan")
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data)
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch(error => {
                        commit('LoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        StoreRecord({ rootState,commit,dispatch },payload){
            return new Promise((resolve, reject) => {
                const { loading} = rootState.LoyaltyRedeemVoucherModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiHttpService.store(`loyalty-program/loyalty-voucher-benefit/redeemed-voucher-benefit`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                        dispatch('asyncGetData', `loyalty-program/loyalty-voucher-benefit/redeemed-voucher/${payload.series}/${payload.benefit_id}`)
                            .then(() => {
                                resolve(response.data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            state.request.cancel()
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetTerm: (state, payload) => {
            state.searchTerm = payload
        },
        SetSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        SetFilters: (state, payload) => {
            state.filters = payload
        },
        SetEmptyData: (state) => {
            state.data.rows = []
        },
        SetTblKey: (state, payload) => {
            state.tblKey = payload
        },
        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

    },
    getters: {
        tableLoading: state => state.loading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        Filters: state => state.Filters,
        Selection: state => state.multipleSelection,
        tblKey: state => state.tblKey,
        FormStatus: state => state.formStatus,
        viewTransactionLoading: state => state.loading,
    }
}


