<template>
<hsc-window-style-metal>
    <hsc-window title="Image uploader" :resizable="false" :left="250" :top="50" :width="600" :height="350" :minWidth="350" :maxWidth="800" :minHeight="350" :maxHeight="450" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">
      <div class="scrollbar image-upload">
        <mdb-container>
          <div class="image-list-container display-flex flex-wrap mt-2">

            <div @click="triggerBrowseFile" class="image-list-item position-relative cursor-pointer display-flex justify-content-center align-items-center">
              <svg class="icon add-image-svg" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M511.5 227.5h-227V.5h-57v227H-.5v57h228v228h57v-228h227z"></path></svg>
              <div class="input-add-image position-absolute full-width full-height">
                <label class="display-block full-width full-height cursor-pointer">
                </label>
              </div>
            </div>


            <div class="image-list-item position-relative cursor-pointer" :class="'image-highlight'" v-for="(image, index) in images" :key="index">
              <div class="centered">
                
                <img class="show-img img-responsive" :src="image.path">
              </div>
                    <div class="icon-container">
                  <template v-if="image.completed">
                      <mdb-icon icon="check-circle" />
                  </template>
                  <template v-if="image.error">
                      <mdb-icon icon="redo-alt" />
                  </template>
                    </div>
            </div>




          </div>
      </mdb-container>

       <input class="display-none" type="file" ref="file" :accept="accept" @change="selectFile" :multiple="multiple" />


      </div>

       <mdb-form-inline class="image-container-header" tag="div">

         <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" icon="refresh" size="sm" @click="triggerBrowseFile">Browse</mdb-btn>
              <mdb-btn color="elegant" icon="plus" size="sm" :disabled="!selectedFiles" @click="uploadBtn">Upload</mdb-btn>
            </template>
          
      </mdb-form-inline>
      
    </hsc-window>
</hsc-window-style-metal>
</template>

<script>

import {mdbBtn,mdbIcon, mdbContainer, mdbFormInline } from 'mdbvue'
import { bearer } from '../../helpers'
import {UploadService} from '../../services'
import Axios from 'axios'
export default {
    name : "ImageUpload",
    components : { 
      mdbBtn,mdbIcon, mdbContainer,mdbFormInline
    },

    props : {
      multiple : {
        type : Boolean,
        default : true
      },
      accept : {
        type : String,
        default : 'image/*'
      },

      group : {
            type : Number,
            default : 1
        },
    },
    data () {
      return {
        dialogIsOpen : false,

        selectedFiles: [],
        currentFile: undefined,
        progress: 0,
        images : [],

        formLoading : false

      }
    },
/* 
 */
    methods: {
        
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        triggerBrowseFile(){
            const elem = this.$refs.file
            elem.click()
        },
        selectFile() {
          this.images = [];
          this.selectedFiles = [];
          this.selectedFiles = this.$refs.file.files;
        
          this.selectedFiles.forEach((file, index) => {
            let reader = new FileReader()

                reader.onload = (e) => {
                  let dataURI = e.target.result
                  if (dataURI) {
                    this.images.push({name: file.name, path: dataURI, highlight: 0, default: 0, completed : false,progress : 0,error  : false, loading : false})
                  }
                }
                reader.readAsDataURL(file)
      })


           

        },

        uploadBtn() {
          const self = this;
              this.currentFile = this.selectedFiles.item(0);

              console.log(this.currentFile)
              this.selectedFiles.forEach((file, index) => {
              console.log(file)
                this.images[index].loading = true
                  UploadService.uploadImage(file, event => {
                    this.images[index].progress = Math.round((100 * event.loaded) / event.total);
                  })
                    .then(response => {
                      this.message = response.data.message;
                      this.images[index].completed = true
                      this.images[index].loading = false
                      self.$ThatsNotify.success({
                                message : `${this.images[index].name} was successfully uploaded`
                              })
                      this.$emit('uploadSuccess')
                      return;
                    })
                    .catch(() => {
                      this.images[index].progress = 0;
                      this.images[index].completed = false;
                      this.images[index].loading = false
                      this.images[index].error = true;

                       self.$ThatsNotify.error({
                                message : `${this.images[index].name} upload failed`
                              })

                    });
                    
              })    
              
              this.$refs.file.value = '';
              const selectedFiles = this.$refs.file.files;
              console.log("selectedFiles",selectedFiles)
            
          }     

    },

    computed: {
          imagePreview () {
            let index = findIndex(this.selectedFiles, { highlight: 1 })
            if (index > -1) {
              return this.selectedFiles[index].path
            } else {
              return this.selectedFiles.length ? this.selectedFiles[0].path : ''
            }
          },
    }, 
    
    watch: {
      dialogIsOpen : function(v){
        if(!v){
          this.selectedFiles = [];
          this.images = [];
        }
      }
    },
  }
</script>
<style scoped>

.image-container-header{
  justify-content : flex-start!important
}
.text-small {
  font-size: 11px;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.text-center {
  text-align: center;
}
.text-primary {
  color: #2fa3e6;
}
.display-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.centered {
  left: 50%;
  transform: translate(-50%,-50%);
  top: 50%;
  position: absolute;
  display: block;
}

.image-center {
  width: 100%;
  height: 100%;
}
.image-icon-drag {
  fill: #c9c8c8;
  height: 50px;
  width: 50px;
}
.drag-text {
  padding-top: 5px;
  color: #777;
  font-weight: 400;
  line-height: 1.5;
}
.browse-text {
  font-size: 86%;
  color: #206ec5;
  text-decoration: none;
}
.image-input {
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.image-input label {
  display: block;
}
.drag-upload-cover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FCFEFF;
  opacity: .9;
  z-index: 1;
  margin: 5px;
  border: 2px dashed #268DDD;
}
.drag-upload-cover {
  font-weight: 400;
  font-size: 20px;
}
.icon-drag-drop {
  height: 50px;
  width: 50px;
  fill: #2fa3e6;
}
.drop-text-here {
  margin: 0;
  line-height: 1.5;
}
.display-none {
  display: none;
}

/* list images*/
.image-list {
  border: 1px solid #D6D6D6;
}
.preview-image {
  height: 140px;
  padding: 5px;
  border-radius: 15px;
  box-sizing: border-box;
}
.image-overlay {
  background: rgba(0,0,0,.7);
  z-index: 10;
  border-radius: 5px;
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}
.image-overlay-details {
  position: absolute;
  z-index: 11;
  opacity: 0;
  transform: translate(0,-50%);
  top: 50%;
}
.icon-overlay {
  width: 40px;
  height: 40px;
  fill: #fff;
}
.preview-image:hover .image-overlay, .preview-image:hover .image-overlay-details{
  opacity: 1;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.show-img {
  max-height: 100px;
  max-width: 140px;
  display: block;
  vertical-align: middle;
}
/*image bottom*/
.image-bottom {
  bottom: 0;
  left: 0;
  height: 40px;
  padding: 5px 10px;
  box-sizing: border-box;
}
.image-primary {
  border-radius: 4px;
  background-color: #e3edf7;
  padding: 3px 7px;
  font-size: 11px;
  margin-right: 5px;
}
.image-icon-primary {
  width: 10px;
  height: 10px;
  margin-right: 2px;
}
.image-icon-delete {
  height: 14px;
  width: 14px;
  fill: #222;
}
.image-edit {
  margin-right: 10px;
}
.image-icon-edit {
  height: 14px;
  width: 14px;
  fill: #222;
}
.image-list-container {
  width: 100%;
  min-height: 50px;
}
.image-list-container .image-list-item {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  overflow: hidden;
}
.image-list-container .image-list-item:not(:last-child) {
  margin-right: 5px;
  margin-bottom: 5px;
}
.image-list-container .image-list-item .show-img {
  max-width: 100px;
  max-height: 100px;
}
.image-list-container .image-highlight {
  border: 1px solid #2fa3e7;
}
.add-image-svg {
  width: 12px;
  height: 12px;
  fill: #222;
}
.input-add-image {
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}
.input-add-image label {
  display: block;
}
.image-icon-info {
  width: 14px;
  height: 14px;
  fill: #222;
}
.mark-text-primary {
  color: #034694;
}
.popper-custom {
  background: #000;
  padding: 10px;
  border: none;
  box-shadow: none;
  color: white;
  text-align: left;
  font-size: 12px;
}
</style>