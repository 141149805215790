
<template>
    <div class="customervendor-container">
        <ThatsTable title="Online Appliation" ref="table" class="window-table" :endpoint="endpoint" @openModal="OpenFormDialog" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :newBtn="false">
            <template #custom-culumns="{columns, getValue, handleClick}">
                <template v-for="col in columns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                </template>


                <el-table-column :width="100" label="Resume">
                    <template slot-scope="scope">
                        <a :href="scope.row.path" target='_blank'><span class="badge badge-dark text-uppercase">Download</span></a>
                    </template>
                </el-table-column>

                

            </template>
        </ThatsTable>
        <OnlineApplicationForm ref="dialogForm" @success="refreshTable"></OnlineApplicationForm>

        
    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import OnlineApplicationForm from './OnlineApplicationForm'
import {ThatsTable} from '../../components/thats/Thats'
export default {
    data(){
        return {
            endpoint : 'online-application/data'
        }
    },
    components: {
        ThatsTable,
        OnlineApplicationForm
    },

    created : function() {
      //always close open window
        this.closeDialog();
    },
    methods: {

        ...mapActions('TableModule',[ 'resetData' ]),
        ...mapActions('OnlineApplicationModule',[ 'DeleteRecord' ]),
        ...mapMutations('OnlineApplicationModule',[ 'SetFormRecord', 'SetFormStatusUpdate', ]),
        // ...mapMutations('DialogModule',[ 'OpenDialog', 'CloseDialog', ]),
openDialog(){
            this.$refs.dialogForm.openDialog()
      },
      closeDialog(){
          this.$refs.dialogForm.closeDialog()
      },
        OpenFormDialog(){
            if(this.DialogIsOpen == true){
                this.$refs.dialogForm.FormResetter()
            }
            this.openDialog()
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
                self.resetData(self.endpoint)
            })
        },
        OpenUpdateModal(obj){
            console.log(obj)
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openDialog()
        },

        openGridWindow(){
            this.$refs.window.openDialog();
        },
         refreshTable(){
            this.$refs.table.refreshTable()
        },
    },
    computed: {
        ...mapGetters('DialogModule',[ 'DialogIsOpen', ]),
        ...mapGetters('OnlineApplicationModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>