<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Category" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :width="650" :height="450" :group="group" :formStatus="FormStatus" @dialog-state="dialogState">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
              <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-4">
             <mdb-row >

              <mdb-col col="12">
                  <!-- <ThatsSeriesInput labelClass="col-sm-3" fieldClass="col-sm-4 pl-0" label="Category ID" placeholder="Category Code" vid="item_code" name="item_code" v-model="form.series" :validated="validated" :rules="'required'" size="sm" :disabled="true" />                   -->
                  <!-- <ThatsAutocomplete :data="category" :appendButton="false" :addButton="false" @select="handleSelect" @blur="handleBlur" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Parent" placeholder="Parent" vid="category" name="parent" v-model="form.parent" :validated="validated" :rules="''"/>
                    <input type="hidden" v-model="form.parent_id" /> -->

                  <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Category Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Arabic" vid="arabic" name="arabic" v-model="form.attr.locale.arabic" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Poland" vid="poland" name="poland" v-model="form.attr.locale.poland" :validated="validated" :rules="''" size="sm" />
                  <ThatsInput :show_error="showError"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Variation Label" vid="variation_label" name="variation_label" v-model="form.attr.variation_label" :validated="validated" :rules="''" size="sm" />

                  <ThatsAttribute  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Attributes" vid="attribute" name="attribute" v-model="form.attr.attributes" :validated="validated" :rules="''"></ThatsAttribute>
                  <ThatsRadio labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Area" vid="area" orientation="inline" name="area" :data="area" v-model="form.area" :validated="validated" :rules="'required'" size="sm" :show_error="true" />

                  <ThatsMultiSelect  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brands" v-model="form.brands" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="filterBrands" :rules="'required'"></ThatsMultiSelect>
                  <!-- <ThatsMultiSelect :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Brands" v-model="form.brands" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="brands"></ThatsMultiSelect> -->
                  <mdb-row >
                    <mdb-col offset="3" col="9" class="pl-0">
                        <ThatsCheckbox :label="'Hidden'" vid="category-hidden-status" orientation="inline" name="category-hidden-status" :data="[]" v-model="form.hidden" :validated="validated" :rules="''" size="sm" :multiple="false" />
                    </mdb-col>
                  </mdb-row>
              </mdb-col>

              
             </mdb-row>
         
          
          </mdbContainer>
        
       </ThatsWindow>



  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbTbl, mdbTblHead, mdbTblBody,mdbIcon,mdbInput} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {
  ThatsInput, 
  ThatsTextarea, 
  ThatsSeriesInput, 
  ThatsCheckbox,
  ThatsFieldset, 
  ThatsWindow, 
  ThatsRadio, 
  ThatsMultiSelect, 
  ThatsAutocomplete,
  ThatsAttribute,
  
  } from '../../components/thats/Thats'

let nextTodoId = 1

export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsAttribute,
        mdbBtn,
        mdbRow,
        mdbCol,
        
        mdbTbl,
      mdbTblHead,
      mdbTblBody,
      mdbIcon,mdbInput
        
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,


      options: [{country: 'Canada', code: 'CA'},{country: 'Qatar', code: 'QA'}],

      area : [],
      brands : [],
      category : [],


    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      } 
      
      this.optionsHandler()
    },
    methods: {
      ...mapActions('AreaModule',[ 'GetArea' ]),
      ...mapActions('BrandModule',[ 'GetBrands' ]),
      ...mapActions('CategoryModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
        'GetCategory',
        'GetBrandByArea',
        'GetBrandByAreaErp'
      ]),
      ...mapMutations('CategoryModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          series : 0,
          name : null,
          slug : null,
          parent_id : 0,
          parent : null,
          area : "1",
          brands : [],
          attr : { 
            variation_label : null,
            attributes : [],
            locale : {
              arabic : null,
              poland : null,
            }
          },
          status : 'true',
          hidden : false
        }
      },
/* start */
onSelectArea(v){
  this.brands = [];
  this.form.brands = []
   this.GetBrandByAreaErp({area : v}).then(response => {
            const brands = [];
            response.forEach(element => {
              brands.push({brand : element.label,code : element.value})
            });
            this.brands = brands
          });

},
addAttr () {

            const trimmedText = this.attributeText.trim()
            alert(trimmedText)
			if (trimmedText) {
				this.items.push({
					id: nextTodoId++,
					text: trimmedText
				})
				this.attributeText = ''
      }
      
      // alert(1)
		},
		removeAttr (idToRemove) {
			this.items = this.items.filter(item => {
				return item.id !== idToRemove
			})
    },
    
      handleSelect(v){
        alert(v)
      },
      handleBlur(){
        alert("blur")
      },
      optionsHandler(){

        this.GetArea().then(response => {
              const area = [];
            response.forEach(element => {
              area.push({label : element.label,value : `${element.value}`})
            });
            this.area = area
          });

        this.GetBrandByAreaErp().then(response => {
            this.brands = response
          });


      

        /* if(this.brands.length <= 0){

          this.GetBrands().then(response => {
            const brands = [];
            response.forEach(element => {
              brands.push({brand : element.label,code : element.value})
            });
            this.brands = brands
          });
        } */

        if(this.category.length <= 0){
          this.GetCategory().then(response => this.category = response);
        }
      },
      
      uploadImage(){
        this.$refs.upload.openDialog();
      },

      nextCode(){
        var self = this;
        this.GetNextCode().then((response) => {
          self.form.series = response.code
        });
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.logo = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let [,text] = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.showError = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
            this.nextCode();
        }

        this.optionsHandler();

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      convertToSlug(Text)
      {
          return Text
              .toLowerCase()
              .replace(/ /g,'-')
              .replace(/[^\w-]+/g,'')
              ;
      },

      slugify(string) {
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
            },

    },

    computed: {

      ...mapGetters('CategoryModule',
      [
        'FormStatus',
        'FormRecord',
      ]),

      filterBrands : function() {
        
        if(this.form.area === null){
          return []
        }
        const results = this.brands.filter((item, index) => {
            return item.area === parseInt(this.form.area)
        })
          const response = []
        if(results.length > 0){
          results.forEach((el) => {
              response.push({brand : el.label,code : parseInt(el.value)})
          })
          if(this.FormStatus == 'create'){
            this.form.brands = [];
          }
        }else{
          this.form.brands = [];
        }

        return response;
      },

      

    },

    watch: {
      FormRecord : function(){
        const self = this
        if(this.FormStatus == 'update'){
          const frm = JSON.parse(JSON.stringify(this.FormRecord.row));
          if(!this.FormRecord.row.attr.hasOwnProperty('locale') ){

            // this.form.attr.push("locale");
            frm.attr.locale = {
              arabic : null,
              poland : null,
            }
          }

          if(this.FormRecord.row.hasOwnProperty('attributes') ){
              if(this.FormRecord.row.attr.attributes.length > 0){
                    frm.attr.attributes = this.FormRecord.row.attr.attributes.map(el => {
                        const element = {...el};
                        element.slug = self.slugify(el.text)
                        return element;
                    })
                }
          }

          this.form = frm
          
        }
      },

      "form.name" : function(v){
        if(this.FormStatus === 'create' && v != null){
          this.form.slug = this.convertToSlug(v)
        }
      }


/*       "form.name" : function(val) {

        if(val !== null){
          const res = val.substring(0, 4)
          this.form.short = res.toUpperCase();
        }
      } */

      /* area : {
        immediate : true,
        handler(){
          this.areaHandle();
        }
        
      } */
    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>