import { ApiHttpService } from '../../services'

import router from '../../router'
export const InventoryItemMasterModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'SKU/Code',
                    field: 'series',
                    sort: 'asc',
                    width : '180',
                    fixed: true,
                    clickable: true,

                },
                {
                    label: 'Image',
                    field: 'default_image',
                    sort: 'asc',
                    width: '100',
                    fixed: true,

                },
                /*   {
                      label: 'SKU/Code',
                      field: 'series',
                      sort: 'asc',
                      width : '200',
                  }, */

                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                    width : '300',
                },

                /* {
                    label: 'Category',
                    field: 'category_name',
                    width: '200',
                }, */

                /*  {
                     label: 'Dimension',
                     field: 'dimension',
                     width: '250',
                 }, */
                {
                    label: 'Price',
                    field: 'sale_price',
                    isAmount : true,
                    align : 'right',

                    width: '180',
                },
                {
                    label: 'Promo Price',
                    field: 'promo_price',
                    width: '180',
                    align : 'right',
                    isAmount : true,

                },
                {
                    label: 'Available',
                    field: 'available',
                    width: '100',
                    align : 'center',
                },
                {

                    label: 'Stock',
                    field: 'stock_qty',
                    width: '100',
                    align : 'center',

                },
                {
                    label: 'Sold',
                    field: 'all_sold_qty',
                    width: '100',
                    align : 'center',
                },
                {
                    label: 'In Transit',
                    field: 'in_transit_qty',
                    width: '110',
                    align : 'center',
                },
                {
                    label: 'Under Production',
                    field: 'under_production_qty',
                    width: '180',
                    align : 'center',
                },
            ],
            rows: [],
        },
        request: null,
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},
        initialFormData : {
            item_code : 0,
            parent : 0,
            name : null,
            slug : null,
            sale_price : 0,
            promo_price : 0,
            stock : 0,
            dimension_type : null,
            dimension : null,
            prType : "product",
            ItmGroup : null,
            brand : null,
            area : null,
            category : 373,
            collection : null,
            item_type : 'stock',
            description : "",
            website : '0',
            sell : false,
            inventory : false,
            purchase : false,
            allocated_quantity : 0,
            bomType : null,
            attr : {
                cube_unit : "",
                attribute : "",
                post_views : 0,
                materials : "",
                materials1 : "",
                fabric : "",
                finishes : "",
                sub_colour : "",
                colour : "",
                colour1 : "",
                colour2 : "",

                future_stock : {
                    qty : 0,
                    eta_text : "",
                    eta : []
                },

                dimension_height : "",
                dimension_width : "",
                dimension_depth : "",
                dimension_diameter : "",
                hole : "",
                capacity : "",
                weight : "",
                meta_title : "",
                meta_keyword : "",
                meta_description : "",
                visible_polish : false,
                barcode : "",
                old_code : "",
                discount : {
                    percent : 0,
                    value : 0,
                    remarks : ""
                },
                collection : {
                    en : "",
                    ar : ""
                }
            },
            image: [],
            location: [],
            warehouses: [],
            bill_of_materials: [],
            qty : 0,
            status : '1',
            soldQtyFromCheckpoint : 0,
            returnQtyFromCheckpoint : 0,
            returnQty : 0,
            invoiceQty : 0,
            stock : 0
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,
        deleteLoading : false,

        formStatus : 'create',

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setDeleteLoadingTrue(state) {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse(state) {
            state.deleteLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
            });
        },
        async asyncGetData({ state, commit},endpoint) {
            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {

                commit('setLoading', true)
                commit('setRows', [])
                commit('setFormStatusUpdate')
                const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                const configParams = {
                    req : "InventoryItemMasterModule/req",
                    loadingTrue : "InventoryItemMasterModule/loadingTrue",
                    addRequest : "InventoryItemMasterModule/addRequest",
                    cancelRequest : "InventoryItemMasterModule/cancelRequest",
                    clearOldRequest : "InventoryItemMasterModule/clearOldRequest"
                }

                ApiHttpService.get(endpoint, params, configParams).then((response) => {
                    commit('setRows', response.data.data)
                    commit('setPage',response.data.current_page)
                    commit('setTotal',response.data.total)
                    resolve(response.data.data)
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setLoading', false)
                    reject(error)
                })

            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)
                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}
                    ApiHttpService.getRequest('item-master?item='+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)


                        commit('setFormData',{...initialFormData,...response.data, bill_of_materials : response.data.bill_of_materials, website : response.data.website.toString()})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },


        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "InventoryItemMasterModule/req",
                        loadingTrue : "InventoryItemMasterModule/loadingTrue",
                        addRequest : "InventoryItemMasterModule/addRequest",
                        cancelRequest : "InventoryItemMasterModule/cancelRequest",
                        clearOldRequest : "InventoryItemMasterModule/clearOldRequest"
                    }
                    ApiHttpService.post('item-master',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...payload}
                    ApiHttpService.update(`item-master`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        const itemObject = {...initialFormData,...responseData, bill_of_materials : responseData.bill_of_materials, website : responseData.website.toString()}
                        console.log('setFormData',itemObject)
                        commit('setFormData',itemObject)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async getTransactions({ state, commit }, params) {
            const { getLoading } = state
            return await new Promise((resolve, reject) => {
                if (!getLoading) {
                    commit('setGetloadingTrue');
                    ApiHttpService.getRequest(`item/get-transactions/${params.token_id}`).then((response) => {
                        commit('setGetloadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setGetloadingFalse')
                        reject(error)
                    })
                }
            })
        },


        async bulkItemUpdate({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.update(`item-master/bulk-update`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async syncItemMaster({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.update(`item-master/sync`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },


    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,

        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        deleteLoading: state => state.deleteLoading,

    }
}



