import { TypeService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const TypeModule = {
    namespaced: true,
    state: {
        loading: false,
        
    },
    actions: {
        async GetTypes({ dispatch }) {
            return await new Promise((resolve) => {
                TypeService.get('/filter', {}).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label: element.name, value: element.token_id }
                            res.push(row)
                        })
                    } 
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        

    },
    getters: {
        Loading: state => state.loading,
        
    }
}


