<!-- eslint-disable vue/return-in-computed-property -->

<template>
    <div class="payment-form-container">
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observerPayment" v-slot="{passes}" @submit.prevent="onSubmitForm('save')">

            <el-dialog :visible.sync="dialogVisible" ref="window" title="Payment Details" windowClass="collection height-auto " scrollClass='collection height-auto' width="50%" class="payment-dialog" :close-on-click-modal="false">
                <mdbContainer class="mt-3">

                    <mdb-row class="mb-2">
                        <mdb-col col="6">
                            <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5 pl-0" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                            <ThatsInput tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Customer Name" :placeholder="form.customer" vid="name" name="name" v-model="form.customer" size="sm" :disabled="true" />
                            <ThatsInput tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Loyalty Number"  vid="card_number" name="card_number" v-model="form.attr.loyalty_number" size="sm" :disabled="true" />

                            <ThatsInput :show_error="true" tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Loyalty Card Number" vid="card_number" name="card_number" v-model="form.attr.loyalty_card_number" size="sm" :disabled="false" :rules="{checkCard: cards}"/>


                        </mdb-col>
                        <mdb-col col="6">
                            <ThatsInput tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" label="Sales Invoice ID" vid="invoice" name="invoice" v-model="form.invoice_series" size="sm" :disabled="true"/>
                            <ThatsInput tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" label="Receipt ID" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>

                        </mdb-col>
                    </mdb-row>

                    <mdb-row>
                        <mdb-col col="12" class="pl-0 pr-0">

                            <el-table ref="multiplePaymentTable" border stripe :data="multiplePaymentData" class="input-table mt-2" style="width: 100%">
                                <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>

                                <el-table-column label="Method" :width="200">
                                    <template slot-scope="scope">
                                        <a href="javascript:void(0)" @click="editPayment(scope.row,scope.$index)">{{scope.row.method | paymentMethod}}</a>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Reference">
                                    <template slot-scope="scope">
                                        {{scope.row.reference}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="Amount" width="200" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount | amount}}
                                    </template>
                                </el-table-column>

                                <el-table-column label="" width="64" align="center">
                                    <template slot-scope="scope">
                                        <template v-if="!['tap'].includes(scope.row.method)">

                                            <i class="el-icon-error text-danger cursor-pointer" @click="removePayment(scope.$index)"></i>
                                            <!-- <el-popconfirm :key="scope.$index"
                                                  title="Are you sure to remove this?"
                                                  confirm-button-text='OK'
                                                  cancel-button-text='No, Thanks'
                                                  @confirm="removePayment(scope.$index,scope.row)"
                                                >
                                                <div slot="reference">
                                                          <i class="el-icon-error text-danger cursor-pointer"></i>
                                                        </div>
                                              </el-popconfirm> -->
                                        </template>
                                    </template>
                                </el-table-column>
                            </el-table>


                        </mdb-col>
                    </mdb-row>


                    <mdb-row class="mb-2">
                        <mdb-col col="6" class="pl-0">
                            <mdb-btn color="elegant" size="sm" @click="openPaymentMeansDialog">Add Payment</mdb-btn>
                        </mdb-col>
                        <mdb-col col="6" class="pr-0">
<!--                            {{ form.transaction.attr.multiple_tier_discount.discount }}-->
                            <ThatsMoney tabindex="4"  labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Applied Amount" placeholder="0" vid="paymentMeansTotal" name="paymentMeansTotal" size="sm" v-model="paymentMeansTotal" :rules="{ required: true, equalTo: {target : calculatedPayableAmount ,msg : `The total must be equal to the amount.`}, preventOverpaid : {otherValue: form.amount, msg : `Amount cannot be more than ${$helpers.formatMoney(form.amount)}`, validate : true}  }" :validated="validated" :show_error="true" :disabled="true"></ThatsMoney>
                            <ThatsMoney tabindex="4"  labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="calculatedPayableAmount" :rules="''" :validated="validated" :show_error="true" :disabled="true"></ThatsMoney>
                            <ThatsMoney tabindex="4"  labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Invoice Total" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="''" :validated="validated" :show_error="true" :disabled="true"></ThatsMoney>
                            <ThatsMoney tabindex="4"  labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Total Balance Due" placeholder="0" vid="balance" name="balance" size="sm" v-model="balance" :rules="''" :validated="validated" :show_error="true" :disabled="true"></ThatsMoney>
                        </mdb-col>
                    </mdb-row>


                </mdbContainer>


                <template slot="footer">
                    <div class="p-0">
                        <div class="d-flex">
                            <template v-if="formLoading">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Loading...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))" :disabled="disabledBtn">Save</mdb-btn>
                            </template>
                        </div>
                    </div>
                </template>

                <!--    </ThatsCustomWindow> -->

                <mdb-modal :show="showModal" @close="showModal = false" class="modal">
                    <mdb-modal-body>
                        <p style="color:red;">Please prioritize the issuance of the loyalty card number.</p>
                    </mdb-modal-body>
                    <mdb-modal-footer>
                        <mdb-btn @click="closeModal" color="elegant" size="sm">Close</mdb-btn>
                    </mdb-modal-footer>
                </mdb-modal>



            </el-dialog>
        </ValidationObserver>
        <PaymentMeans ref="paymentMeansDialog" :setFieldValue="onPaymentMeanSetValue"/>

    </div>
</template>
<script>
/*eslint-disable no-prototype-builtins*/
/*eslint-disable vue/return-in-computed-property*/
import {
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbModal,
    mdbModalFooter,  mdbModalBody
} from "mdbvue";
import { ValidationObserver, extend } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import PaymentMeans from  './PaymentMeans'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsInput, ThatsDatePicker, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import unformat from 'accounting-js/lib/unformat.js'
import Fuse from 'fuse.js'



extend('checkCard', (value, otherValue) => {
    // do something and finally return an object like this one:
    console.log(value)
    console.log(otherValue)
    let isValid = true;

    if(value !== "" && !otherValue.includes(value)){
        console.log("test")
        console.log([10, 3].includes(value))

        return false
    }

    return {
        valid: isValid, // or false
        data: {
            required: false // or false
        }
    };
}, {
    computesRequired: true
});


export default {
    props : {
        data : {
            type : Object,
            default : () => {}
        },
    },
    inject : ['posShowroom'],
    components: {
        mdbModalBody, mdbModalFooter, mdbModal,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,

        ValidationObserver,

        ThatsInput,

        ThatsDatePicker,

        ThatsMoney,


        PaymentMeans
    },

    data : () => ({
        componentKey: 0,
        active : 0,
        showModal: false,
        card_number : null,
        dialogVisible : false,
        disabled : false,
        disabledBtn : false,
        validated : false,
        formOriginal : null,
        form : {
            series : 0,
            date : "",
            mode : "full-payment",
            method : "credit-card",
            customer : "",
            customer_id : null,
            attr : {
                cheque_details : null,
                payment_reference : null,
                discount_remarks : null,
                realized : false,
                loyalty_card_number : null,
                loyalty_number : null
            },
            credit_notes : [],
            description : null,
            invoice_series : null,
            amount : 0,
            reference : null,
            transaction_id : null,
            transaction: {
                total : 0,
                remaining : 0,
            },
            invoice : {
                series : null,
                balance : 0,
                paid : 0,
                amount : 0,
            },
            credit_note : {
                reference : null,amount: 0,
            }
        },
        response : {
            error : null,
            message : null
        },

        sendLoading : false,
        formLoading : false,
        formSavePrintLoading : false,
        formPayBalanceLoading : false,
        showError : false,
        paymentMethod : [],
        items : [],
        selected : [],
        customer : [],
        payment_transactions : [],
        transactions : {

        },
        payment_reference : [],
        overpaid_payment_reference : [],



        multiplePaymentData : []

    }),

    created : function() {

        this.form = this.formVar();
        this.currentDateSetter();

        this.optionsHandler();
        this.nextCode()
        //  this.isGeneralManager();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.paymentMethod = settings.payment_method
    },



    methods: {
        ...mapMutations('PaymentMeansModule',[
            'setFormDataCreate','setFormDataUpdate'
        ]),
        ...mapActions('TransactionModule',[
            'getNextCodePayment',
        ]),
        ...mapActions('MakePaymentModule',[
            'addPayment',
            'addPaymentWithTransaction'
        ]),


        formVar(){
            return {
                series : 0,
                date : "",
                mode : "full-payment",
                method : "credit-card",
                customer : "",
                customer_id : null,
                attr : {
                    cheque_details : null,
                    mid : null,
                    payment_reference : null,
                    discount_remarks : null,
                },
                credit_notes : [],
                description : null,
                invoice_series : null,
                amount : 0,
                reference : null,
                transaction_id : null,
                transaction : {
                    remaining : 0
                },
                invoice : {
                    series : null,
                    balance : 0,
                    balance_float : 0,
                    paid : 0,
                    amount : 0,
                },
                credit_note : {
                    reference : null,amount: 0,
                },
                reference_amount : 0

            }
        },
        /* start */

        getPaymentReferenceSelected(v){
            const self = this;

            const paymentReference = this.payment_reference.filter((e) => {
                return e.code === v;
            })[0];

            const stringifyForm = JSON.parse(JSON.stringify(self.form));

            let amount = unformat(stringifyForm.amount);

            if(paymentReference && amount > paymentReference.amount){
                amount = paymentReference.amount;
            }


            this.form.mode = 'no-payment'
            this.form.amount =this.$helpers.formatMoney(amount);
            this.form.credit_note = paymentReference
            this.form.reference_amount = paymentReference.amount;

        },

        roundAmount(amount) {
            let integerPart = Math.floor(amount); // Get the integer part of the number
            let fractionalPart = amount % 1; // Get the fractional part of the number

            if (fractionalPart <= 0.49) {
                fractionalPart = 0.00;
            } else if (fractionalPart >= 0.50) {
                fractionalPart = 0.50;
            }
            else {
                fractionalPart = 0.00;
            }

            return integerPart + fractionalPart;
        },

        getOverPaymentReferenceSelected(v){
            const self = this;

            const paymentReference = this.overpaid_payment_references.filter((e) => {
                return e.code === v;
            })[0];

            const stringifyForm = JSON.parse(JSON.stringify(self.form));

            let amount = unformat(stringifyForm.amount);

            if(paymentReference && amount > paymentReference.amount){
                amount = paymentReference.amount;
            }


            this.form.mode = 'over-paid'
            this.form.amount =this.$helpers.formatMoney(amount);
            this.form.credit_memo = paymentReference
            this.form.reference_amount = paymentReference.amount;
        },

        getModeSelected(v){
            const {total, invoice} = this.paymentForm.transaction
            this.form.amount = total
            if(invoice !== null && invoice.series !== null){
                this.form.amount = invoice.balance
            }else{
                this.form.amount = total
            }

            if(v === 'no-payment'){
                this.form.method = 'credit-note'
            }else{
                this.form.method = 'credit-card'
            }

            /* if(v === 'final-payment'){
              if(invoice !== null && invoice.series !== null){
                this.form.amount = invoice.balance
                }else{
                this.form.amount = invoice.balance
              }
            }else{
              this.form.amount = total
            } */

            /*
             if(v === 'full'){
                  if(this.paymentForm.transaction.has_invoice === true && this.paymentForm.transaction.invoice !== null){
                    this.form.amount = parseFloat(this.paymentForm.transaction.invoice.balance).toFixed(2)
                  }else{
                    this.form.amount = parseFloat(this.paymentForm.transaction.total).toFixed(2)
                  }
              }else{
                this.form.amount = parseFloat(this.paymentForm.transaction.total).toFixed(2)
              } */

        },
        getMethodSelected(v){
// console.log(v)

            if(v === 'credit-note'){
                this.form.mode = 'no-payment'
            }
        },
        openPaymentWindow(){

        },
        getActiveTabIndex(index){
            console.log(index);
        },

        fuseSearch(options, search) {
            const fuse = new Fuse(options, {
                keys: ["mobile", "label"],
                shouldSort: true
            });
            return search.length
                ? fuse.search(search).map(({ item }) => item)
                : fuse.list;
        },


        optionsHandler(){
            // console.log(this.form)


            // if(this.)
        },

        nextCode(){

            // this.getNextCodePayment().then((response) => {
            //   self.form.series = response.code
            // });
        },
        /* end */
        openDialog(){
            // this.$refs.window.openDialog()

            this.dialogVisible = true
        },
        closeDialog(){
            // this.$refs.window.closeDialog()
            this.dialogVisible = false,
                this.form.transaction.attr.found_loyalty = false
            this.form.attr.loyalty_card_number = null
        },

        async dispatchPayment(action){
            const self = this;

            self.form.amount = unformat(self.form.amount);
            const params = {...self.form,action : action, payment_for : self.payment_for, multiple : self.multiplePaymentData}

            console.log(params)
            console.log("dispatchPayment")

            // console.log(self.form.transaction)

            return new Promise(resolve =>{
                this.addPayment(params).then((response) => {
                    self.$ThatsNotify.success({message : "Payment was sucessfully added"});
                    self.$emit('success',response)
                    self.closeDialog();
                    self.FormResetter();
                    resolve()
                })
            })

            /* if(self.formStatus =='create' && self.form.transaction.hasOwnProperty("showroom") &&  self.posShowroom.includes(self.form.transaction.showroom.prefix)){
                return new Promise(resolve => {
                    self.addPaymentWithTransaction(params).then((response) => {
                      self.$ThatsNotify.success({message : "Payment was sucessfully added"});
                      self.$emit('success',response);
                      self.closeDialog();
                      self.FormResetter();
                      resolve();
                    })
                })
            }else{

            } */

            /*  */
        },
        openNoteModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        onSubmitForm(action){
            var self =this
            this.validated = true
            this.$refs.observerPayment.validate().then((res) => {
                self.showError = false

                if (res) {
                    MessageBox.confirm(`Are you sure you want to make payment?`, 'Warning', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        type: 'warning'
                    }).then(() => {
                        if(self.formLoading === false){
                            self.formLoading = true
                            self.disabledBtn = true;
                            self.dispatchPayment(action).then(() => {
                                self.formLoading = false
                                self.disabledBtn = false
                            }).catch((error) => {
                                self.formLoading = false
                                self.disabledBtn = false

                                console.log(error)
                                self.$ThatsNotify.error({message : error.message});
                            })
                        }
                    })

                }


            });


            /*  t      */
        },
        onSubmitFormPrint(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observerPayment.validate();
            this.showError = false
            this.disabledBtn = true;
            if (isValid) {
                if(self.formSavePrintLoading === false){
                    self.formSavePrintLoading = true
                    self.dispatchPayment('save-print').then(() => {
                        self.formSavePrintLoading = false
                        self.disabledBtn = false
                    })
                }

            }
        },

        getText() {
            let text = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observerPayment.reset();
            });
            this.validated = false
            this.formLoading = false
            this.sendLoading = false
            this.showError = false
            this.currentDateSetter();

        },

        currentDateSetter(){
            var MyDate = new Date();
            var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.form.date = date;
        },
        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.FormResetter();
                this.multiplePaymentData = [];
                return
            }

        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },

        formatNumber(n) {
            return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
                return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
            });
        },

        number_format(number, decimals, dec_point, thousands_sep) {

            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                toFixedFix = function (n, prec) {
                    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                    var k = Math.pow(10, prec);
                    return Math.round(n * k) / k;
                },
                s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },

        addSeparatorsNF(nStr, inD, outD, sep){

            /*
            nStr : This is the number to be formatted. This might be a number or a string. No validation is done on this input.
          inD : This is the decimal character for the string. This is usually a dot but might be something else.
          outD : This is what to change the decimal character into.
          sep : This is the separator, which is usually a comma.
          */
            nStr += '';
            var dpos = nStr.indexOf(inD);
            var nStrEnd = '';
            if (dpos != -1) {
                nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
                nStr = nStr.substring(0, dpos);
            }
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(nStr)) {
                nStr = nStr.replace(rgx, '$1' + sep + '$2');
            }
            return nStr + nStrEnd;
        },

        openPaymentMeansDialog(){
            this.setFormDataCreate({
                date : this.form.date,
            })
            this.$refs.paymentMeansDialog.openDialog();
        },

        onPaymentMeanSetValue(value){

            if (value.hasOwnProperty('index')) {
                this.$set(this.multiplePaymentData, value.index, value);
            } else {
                this.multiplePaymentData.push(value);
            }
        },

        paymentSummary(param) {
            const { columns, data } = param;
            const sums = [];

            columns.forEach((column, index) => {
                if (index === 2) {
                    sums[index] = 'Total';
                    return;
                }

                if (index === 3) {
                    const amount = data.map(item => parseFloat(item.amount));

                    if (!amount.some(isNaN)) {
                        const sum = amount.reduce((prev, curr) => prev + curr, 0);
                        sums[index] = this.$helpers.formatMoney(sum, { symbol: '', precision: 2, thousand: ',', decimal: '.' });
                    }

                    return;
                }
            });

            return sums;
        },
        editPayment(row,index){
            this.setFormDataUpdate({
                ...row,
                index
            })
            this.$refs.paymentMeansDialog.openDialog();
        },
        removePayment(idx){

            MessageBox.confirm(`Are you sure you want to this paypment?`, 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.multiplePaymentData.splice(idx, 1);
            })

            /* console.log("removePayment")
            console.log(idx)
            console.log(row)
            console.log(this.multiplePaymentData)
            this.multiplePaymentData.splice(idx, 1); */
        },

        truncateToTwoDecimalPlaces(value) {
            // Convert the value to a string
            const valueString = value.toString();
            const decimalIndex = valueString.indexOf('.');

            // If there's a decimal point, truncate the number to two decimal places
            if (decimalIndex !== -1) {
                return parseFloat(valueString.slice(0, decimalIndex + 3));
            }

            // If there's no decimal point, just return the value as is
            return value;
        },

        addInitialPayment(obj) {
            if (!this.multiplePaymentData.some(item => item.reference === obj.reference)) {
                this.multiplePaymentData.push(obj);
            }
        }

    },

    computed: {
        ...mapGetters('LoyaltyMemberModule',
            [
                'cards'
            ]),
        ...mapGetters('TransactionModule',
            [
                'formStatus'
            ]),
        ...mapGetters('MakePaymentModule',
            [
                'paymentForm',
                'payment_references',
                'overpaid_payment_references',
            ]),

        // calculatedPayableAmount : {
        //     get(){
        //         if(this.form.transaction.hasOwnProperty('attr')){
        //             if(this.form.transaction.attr.hasOwnProperty('multiple_tier_discount') && this.form.transaction.attr.multiple_tier_discount !== null && this.form.transaction.attr.multiple_tier_discount.apply === true){
        //                 return parseFloat(this.form.transaction.attr.multiple_tier_discount.discount.toFixed(2));
        //             }
        //         }
        //     },
        //     set(){
        //         if(this.form.transaction.hasOwnProperty('attr')){
        //             if(this.form.transaction.attr.hasOwnProperty('multiple_tier_discount') && this.form.transaction.attr.multiple_tier_discount !== null && this.form.transaction.attr.multiple_tier_discount.apply === true){
        //                 return parseFloat(this.form.transaction.attr.multiple_tier_discount.discount.toFixed(2));
        //             }
        //         }
        //     }
        //
        // },

        calculatedPayableAmount: {
            get() {
                if (this.form.transaction.hasOwnProperty('attr')) {
                    const discount = this.form.transaction.attr?.multiple_tier_discount?.discount;
                    const applyDiscount = this.form.transaction.attr?.multiple_tier_discount?.apply;

                    if (discount !== undefined && applyDiscount === true) {
                        return this.truncateToTwoDecimalPlaces(this.roundAmount(discount));
                    }
                }
            },
            set() {
                if (this.form.transaction.hasOwnProperty('attr')) {
                    const discount = this.form.transaction.attr?.multiple_tier_discount?.discount;
                    const applyDiscount = this.form.transaction.attr?.multiple_tier_discount?.apply;

                    if (discount !== undefined && applyDiscount === true) {
                        this.form.transaction.attr.multiple_tier_discount.discount = this.truncateToTwoDecimalPlaces(this.roundAmount(discount));
                    }
                }
            }
        },



        balance :{
            get(){
                let totalAmount = parseFloat(this.form.amount);

                if(this.form.transaction.hasOwnProperty('attr')){
                    if(this.form.attr.hasOwnProperty('multiple_tier_discount') && this.form.attr.multiple_tier_discount !== null && this.form.attr.multiple_tier_discount.apply === true){
                        totalAmount = parseFloat(this.form.attr.multiple_tier_discount.discount.toFixed(2));
                    }
                }

                return totalAmount - this.multiplePaymentData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
            },
            set(){
                let totalAmount = parseFloat(this.form.amount);
                if(this.form.transaction.hasOwnProperty('attr')){
                    if(this.form.attr.hasOwnProperty('multiple_tier_discount') && this.form.attr.multiple_tier_discount !== null && this.form.attr.multiple_tier_discount.apply === true){
                        totalAmount = parseFloat(this.form.attr.multiple_tier_discount.discount.toFixed(2));
                    }
                }
                return totalAmount - this.multiplePaymentData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
            }
        },
        paymentMeansTotal: {
            get() {
                return this.multiplePaymentData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
            },
            set() {
                return this.multiplePaymentData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
            }
        },
        paymentMode : function() {
            const {settings} = JSON.parse(localStorage.getItem('user'));
            let mode = settings.payment_mode
            if(this.form.invoice != null && this.form.invoice.series != null && this.form.invoice.balance_float > 0){
                // mode = settings.payment_mode.shift();
                mode = settings.payment_mode.filter(item => {
                    return item.code !== 'full-payment'
                })

            }
            return mode;
        },

        paymentReferenceRules : function(){
            if(this.form.method === 'credit-note' || this.form.method === 'wire-transfer' || this.form.method === 'cheque'){
                return 'required';
            }

            if(this.form.method === 'credit-card'){
                return 'required|length:6';
            }
            return '';
        },
        isCreditNote : function(){
            if(this.form.method === 'credit-note'){
                return true;
            }
            return false;
        },

        paymentReferenceLabel : function(){
            if(this.form.method === 'credit-card'){
                return 'Authorization Code';
            }

            if(this.form.method === 'cheque'){
                return 'Cheque No.';
            }
            if(this.form.method === 'credit-note'){
                return 'CN No.';
            }

            if(this.form.method === 'wire-transfer'){
                return 'Transaction No.';
            }

            return 'Payment Ref. No.'
        },
        maxLength : function(){
            if(this.form.method === 'wire-transfer' || this.form.method === 'cheque'){
                return '255';
            }
            if(this.form.method === 'credit-card'){
                return '6';
            }
            if(this.form.method === 'credit-note'){
                return '10';
            }
            return '50';
        },

    },

    watch: {
        paymentForm : function(v){
            // console.log(v)
            this.componentKey++
            let frm = this.formVar();
            // frm.amount =  parseFloat(v.transaction.total).toFixed(2);

            console.log(v)
            frm.amount =  unformat(v.transaction.total);
            frm.customer = v.transaction.attr.name
            frm.customer_id = v.transaction.customer_id
            frm.transaction_id = v.transaction.mdbID
            if (frm.invoice == null){
                if (v.transaction.attr.loyalty_member == true){
                    if(v.transaction.attr.loyalty_member_object.length > 0){
                        if(v.transaction.attr.loyalty_member_object[0].status === 'for-issue-card'){
                            frm.attr.loyalty_card_number = "";
                            // this.openNoteModal();
                            frm.attr.loyalty_number = v.transaction.attr.loyalty_number;
                        }else{
                            frm.attr.loyalty_card_number = v.transaction.attr.loyalty_member_object[0].card_number;
                            frm.attr.loyalty_number = v.transaction.attr.loyalty_number;
                        }
                    }

                }
                else {
                    frm.attr.loyalty_card_number = "";
                }
            }else{
                if (v.transaction.attr.loyalty_member == true){
                    if(v.transaction.attr.loyalty_member_object.length > 0){
                        if(v.transaction.attr.loyalty_member_object[0].status === 'for-issue-card'){
                            frm.attr.loyalty_card_number = "";
                            // this.openNoteModal();
                            frm.attr.loyalty_number = v.transaction.attr.loyalty_number;
                        }else{
                            frm.attr.loyalty_card_number = v.transaction.attr.loyalty_member_object[0].card_number;
                            frm.attr.loyalty_number = v.transaction.attr.loyalty_number;
                        }
                    }
                }else {
                    frm.attr.loyalty_card_number = "";
                }
            }

            frm.transaction = v.transaction
            frm.transaction.total = unformat(v.transaction.total)

            if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice.series !== null){

                frm.invoice_series = v.transaction.invoice.series
                frm.invoice = v.transaction.invoice
                frm.invoice.balance_float =  unformat(v.transaction.invoice.balance);
                // frm.amount = parseFloat(v.transaction.invoice.balance).toFixed(2);
                frm.amount = unformat(v.transaction.invoice.balance);
                frm.mode = 'down-payment'
            }
            if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice !== null && v.transaction.invoice.series !== null){
                frm.mode = 'full-payment'
            }
            frm.attr = {realized : false,...frm.attr,...v.attr}

            var MyDate = new Date();
            frm.date = MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
            this.form = frm
            // console.log("payment watch form")
            // this.getCustomerCreditNote();


        },

        payment_references : function(v){
            this.payment_reference = v
        },
        overpaid_payment_references : function(v){
            this.overpaid_payment_reference = v
        },
        "form.method" : function(){
            this.form.attr.card_type = null
            this.form.attr.payment_reference = null
            this.form.attr.mid = null
        },

        dialogVisible : function(v){
            this.componentKey++
            this.dialogState(v);
        }

        /*  'form.method' : function(val){
           alert(val)
         } */
    },
}
</script>

<style scoped>
.click-to-add-logo{
    font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
    right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
    padding: 65px;
    text-align: center;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}


</style>
