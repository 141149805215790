<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" @submit.prevent="onSubmitForm()">
        <ul id="right-click-menu" tabindex="-1" ref="rightExchange" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
            <li  @click="insertRow">Insert</li>
                <template v-if="itemToReplace.length > 2">
                <li @click="moveUpRow">Move Up</li>
                <li @click="moveDownRow">Move Down</li>
            </template>
            <li @click="deleteRow">Delete</li>
        </ul>
        <ThatsCustomWindow ref="window" title="ITEM EXCHANGE" windowClass="collection height-auto" scrollClass='collection height-auto' :height="800" :width="1300" @dialog-state="dialogState" :group="group">
           
            

           
            

            <div class="scrollbar" v-bind:class="'collection height-auto'" >
                 <div class="mt-1 pl-3 pr-3">
                    <mdb-tabs class="table-tabs mb-0 form" :active="0" default :links="[{ text: 'EXCHANGE DETAILS', slot: 'exchange-slot' },{ text: 'RETURN', slot: 'form-slot' }]" >

                         <template :slot="'exchange-slot'">
                             <mdb-row class="tab-row">
                                <mdb-col col="6" class="tab-col pl-0">
            
                                 <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date" vid="name" name="date" v-model="exchangeForm.invoice.date" size="sm" :disabled="true"/>
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="exchangeForm.attr.name" size="sm" :disabled="true"/>
                               
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="exchangeForm.attr.mobile" size="sm" :disabled="true"/>
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="exchangeForm.attr.address" size="sm" :disabled="true"/>
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instructions" name="instructions" v-model="exchangeForm.attr.instructions" size="sm" :disabled="true"/>
                                </mdb-col>    
                                <mdb-col col="6" class="tab-col pl-0 pr-0">
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Invoice No." vid="invoice" name="series" v-model="exchangeForm.invoice.series" size="sm" :disabled="true"/>
                                  <mdb-row>
                                        <mdb-col sm="4" class="tab-col">
                                            <label v-bind:class="'col-form-label'">Sold By</label>
                                        </mdb-col>
                                        <mdb-col sm="4" class="tab-col">
                                            <ThatsInput :showLabel="false" tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-12 pl-0" label="Sold By" :placeholder="exchangeForm.sold_by" vid="sold_by" name="sold_by" v-model="exchangeForm.sold_by" size="sm" :disabled="true" />
                                        </mdb-col>
                                        
                                    </mdb-row>
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Order Status" :placeholder="exchangeForm.status" vid="order_status" name="order_status" v-model="exchangeForm.status" size="sm" :disabled="true" />
                                    <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Payments Status" :placeholder="exchangeForm.attr.payment_status" vid="payment_status" name="payment_status" v-model="exchangeForm.attr.payment_status" size="sm" :disabled="true" />
                                    
                                  
                                </mdb-col>
                            </mdb-row>
                               
                            <el-table ref="multipleTable" lazy class="custom-element-table" :key="`exchange${tblKey}`" :data="itemToReplace" stripe @selection-change="handleSelectionChange" :cell-class-name="cellClassName" :summary-method="getSummaries" show-summary>
                                <el-table-column type="index" width="50" label="SQ" align="center"></el-table-column>
                                <el-table-column width="200" label="CODE">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                        <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:100%" />
                                        </template>
                                    </el-table-column>
                                <el-table-column label="ITEM NAME">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="QTY" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="PRICE" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="AMOUNT" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="DISCOUNT" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="TOTAL" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                            
                            </el-table>
                        </template>

                        <template :slot="'form-slot'">
                            <el-table ref="multipleTable" lazy class="custom-element-table" :key="`return${tblKey}`" :data="itemToExchange" stripe @selection-change="handleSelectionChange" :cell-class-name="cellClassName" :summary-method="getSummaries" show-summary>
                                <el-table-column type="index" width="50" label="SQ" align="center"></el-table-column>
                                <el-table-column width="200" label="CODE">
                                    <template slot-scope="scope">
                                        {{scope.row.code}}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Image" width="90">
                                        <template slot-scope="scope">
                                        <img :src="scope.row.default" style="width:100%" />
                                        </template>
                                    </el-table-column>
                                <el-table-column label="ITEM NAME">
                                    <template slot-scope="scope">
                                        {{scope.row.name}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="QTY" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.qty}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="PRICE" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.price | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="AMOUNT" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="DISCOUNT" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.discount | amount}}
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" label="TOTAL" align="right">
                                    <template slot-scope="scope">
                                        {{scope.row.total}}
                                    </template>
                                </el-table-column>
                            
                            </el-table>

                        </template>
                    </mdb-tabs>
                </div>
            </div>     
            <div class="pl-3 pr-3">
                <div class="d-flex">
                    <mdb-btn color="grey" class="ml-0" size="sm" @click="closeDialog">Close</mdb-btn>
                </div>
            </div>

        </ThatsCustomWindow>
        <DiscountForm ref="discount" :group="(group + 1)" @apply="applyDiscount"></DiscountForm>
    </ValidationObserver>
</template>
<script>
import { mapGetters,mapMutations } from 'vuex'
import { ValidationObserver } from "vee-validate";
import {ThatsCustomWindow, ThatsInput} from '../../components/thats/Thats'
import {mdbTabs,mdbBtn, mdbCol, mdbRow} from 'mdbvue'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import InputItemTable from './InputItemTable.vue'
import DiscountForm from './DiscountForm'
export default {
    components : {
        ValidationObserver,
        ThatsCustomWindow,
        mdbTabs,
        InputItemTable,
        DiscountForm,mdbBtn,
        ThatsInput,
        mdbCol,
        mdbRow
    },
    props : {
        group : {
            type : Number,
            default : 2
        }
    },
    data(){
        return {
            context: null,
            viewMenu: false,
            top: '0px',
            left: '0px',
            dialogIsOpen : false,
            validated : false,
            x : 0,
            y : 0,

            // itemToReplace : [],
        }
    },
    methods : {
        ...mapMutations('DiscountModule',[
        'setDiscountForm',
      ]),
        handleSelectionChange(){

        },
        cellClassName(){

        },
        openDialog(){
            // this.dialogIsOpen = true
            this.$refs.window.openDialog()
        },

        closeDialog(){
            // this.dialogIsOpen = false
            this.$refs.window.closeDialog()
        },

        onSubmitForm(){

        },

        optionsHandler(){

        },
        formResetter(){
            this.itemToReplace = [];
        },
        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.formResetter();
                return
            }
        },
        formatNumber(n) {
            return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
            });
        },
        getSummaries(param) {
            const self = this;
            const { columns, data } = param;
            const sums = [];
            
            let totalCol = 3;
            let totalQty= 4;
            let totalPrice= 5;
            let totalAmount= 6;
            let totalDiscount= 7;
            let totalTotal= 8;

        
            
            columns.forEach((_column, index) => {
            if (index === totalCol) {
                sums[index] = 'Total';
                return;
            }

            
            if (index === totalQty) {
                const qty = data.map(item => {
                    return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
                })

                if (!qty.every(qty => isNaN(qty))) {
                    sums[index] = qty.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                } 
                return;
            }
            
            if (index === totalAmount) {
                const amount = data.map(item => {
                    return parseInt(item.qty) * parseFloat(item.price);
                });

                if (!amount.every(amount => isNaN(amount))) {
                    const sum = amount.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);

                    sums[index] = this.formatNumber(sum)

                }

                return;
            }
            if (index === totalDiscount) {
                
                const discount = data.map(item => {
                    return unformat(item.discount);
                })

                        
                
                
                if (!discount.every(discount => isNaN(discount))) {
                    const sum = discount.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);

                    sums[index] = this.formatNumber(sum)

                }
                return;
            }

            if (index === totalTotal) {
                const total = data.map(item => {

                    return unformat(item.total);
                });
                
                if (!total.every(total => isNaN(total))) {
                    const sum = total.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                    this.total =  this.formatNumber(sum)
                    sums[index] = this.formatNumber(sum)

                }
                return;
            }  

            });

            return sums;
      },

      rowContectMenu(row,_col,e){
        const self = this
        
        if((row.code !== null && this.formStatus === 'create') || (this.formStatus === 'update' && row.code !== null && (this.modify === true || this.form.invoice.series === null))){
            this.context = row
            this.viewMenu = true;
              this.$nextTick(function() {
                self.$refs.rightExchange.focus();
                self.setMenu(e.y, e.x)
              }.bind(this));
              e.preventDefault();
        }
      },

      setMenu: function(top, left) {
        
        const largestHeight = window.innerHeight - this.$refs.rightExchange.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.rightExchange.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu: function() {
            this.viewMenu = false;
            this.context = null
        },

        deleteRow(){
          const self = this
            if(this.context != null){
                 if(self.context.hasOwnProperty('$index$')){
                    const matchItem = this.itemToReplace.filter((i) => {
                        return i.$index$ !== self.context.$index$;
                    })
                    self.itemToReplace = matchItem
                }
              this.closeMenu()
            }

        },
        moveUpRow(){
           const self = this
            if(this.context != null){
              const index = this.itemToReplace.findIndex(e => e.code == self.context.code);

              if (index > 0) {
                const el = this.itemToReplace[index];
                this.$set(this.itemToReplace, index, this.itemToReplace[index - 1]);

                this.$set(this.itemToReplace, index - 1, el);
                this.closeMenu()
              }
            }
        },
        moveDownRow(){
           const self = this
            if(this.context != null){
              const index = this.itemToReplace.findIndex(e => e.code == self.context.code);

                  if (index !== -1 && index < self.itemToReplace.length - 2) {
                    const el = self.itemToReplace[index];
                    this.$set(self.itemToReplace, index, self.itemToReplace[index + 1]);
                    this.$set(self.itemToReplace, index + 1, el);
                    this.closeMenu()
                  }

            }
        },

        insertRow(){
            const self = this
            if(this.context != null){

            
              if(self.context.hasOwnProperty('id')){
                const index = this.itemToReplace.findIndex(e => e.id == self.context.id);
                const formItemVar = this.formItemVar();
                this.itemToReplace.splice((index + 1), 0, formItemVar);
              }
              if(self.context.hasOwnProperty('item_id')){
                const index = this.itemToReplace.findIndex(e => e.item_id == self.context.item_id);
                const formItemVar = this.formItemVar();
                this.itemToReplace.splice((index + 1), 0, formItemVar);
              }
              this.closeMenu()
            }


        },

        inputItemTableHandleChange(v){
            this.itemToReplace = v
        },



        rowInputItemTableContextMenu({row,col,e}){
            const self = this
            if(row.code !== null){
                this.context = row
                this.viewMenu = true;
                this.$nextTick(function() {
                    self.$refs.rightExchange.focus();
                    self.setMenu(e.y, e.x)
                }.bind(this));
                e.preventDefault();
            }
            
        },

         formItemVar(){
            return {
                $index$: 0,
                default: "",
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: 1,
                max: 1,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply : true,
                    discount : 0,
                    off_price : 0,
                    promo : 0,
                },
                total: 0,
                tbo: false,
                show_search: false,
                remarks: null
                }      
        },

        reSort() {
          for (let i = 0; i < this.itemToReplace.length; i++) {
            this.itemToReplace[i].$index$ = i;
          }
        },

        getItemSelected({index}){
            const dataLength = this.itemToReplace.length - 1;

            if(dataLength === index){
                const formItemVar = this.formItemVar();
                this.itemToReplace.push({
                    ...formItemVar,
                    $index$ : this.itemToReplace.length
                })
                this.reSort()
            }
        },

        insetRow(){
            const lastIndex = this.itemToReplace.length;
            this.itemToReplace.push({
                    ...this.formItemVar(),
                    $index$ : lastIndex
                })
        },

        openDiscount({index,row}){
            this.setDiscountForm({index : index, form: row});
            this.$refs.discount.openDialog();
        },
        applyDiscount(v){
            const self = this;
            const applyDiscountType = v.form.custom_discount.apply
            const applyDiscountValue = v.form.custom_discount.discount
            const frm = v.form;
                const qty = frm.qty;
                    let price = frm.price;

                    if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
                    const itemPromo = frm.promotions[0];
                    const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

                    const discount = unformat(discountValue);
                    frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                    frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
                    frm.remarks = this.setRemarks(qty,itemPromo);
                    frm.total = this.computeTotalAmount(qty, price, frm.discount)
                    }

                    if(frm.discount_type === 'discount'){
                    const itemPromo = frm.promotions[0];

                    const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

                    const discount = unformat(discountValue);
                    frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                    // frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
                    frm.remarks = this.setRemarks(qty,frm.custom_discount);
                    frm.total = this.computeTotalAmount(qty, price, frm.discount)
                    }
                if(v.apply_all){
                    const items = self.itemToReplace;
                    console.log(items)
                    if(items.length > 0){
                    items.forEach((item, index)=>{

                        
                        // const itemForm = JSON.parse(JSON.stringify(item));

                        const unFormatPrice = unformat(item.price);
                        let unFormatDiscount = unformat(applyDiscountValue);
                        item.custom_discount.apply = applyDiscountType
                        item.custom_discount.discount = applyDiscountValue
                        if(unFormatDiscount !== null){    
                            if(parseInt(unFormatDiscount) > 0){
                                item.discount_type = 'discount'
                            }else{
                                item.discount_type = null
                            }
                            
                            if(unFormatDiscount === ""){
                                unFormatDiscount = 0
                            }
                            
                            if(applyDiscountType === true || applyDiscountType === 1){
                                const off = (unFormatDiscount / 100) * unFormatPrice;
                                const promo = unFormatPrice - off;

                                item.custom_discount.promo = self.formatNumber(promo)
                                item.custom_discount.off_price = self.formatNumber(off)
                            }else{

                                if(unFormatDiscount === ""){
                                    unFormatDiscount = 0;
                                }
                                const promo = unFormatPrice - unFormatDiscount;
                                item.custom_discount.promo = self.formatNumber(promo)
                                item.custom_discount.off_price = self.formatNumber(unFormatDiscount)
                            }
                            
                        }

                        const qty = item.qty;
                        let price = item.price;

                        if(item.promotions.length > 0 && item.discount_type === 'promo'){
                            const itemPromo = item.promotions[0];
                            const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                            const discount = unformat(discountValue);
                            item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                            item.discount = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);
                            item.remarks = self.setRemarks(qty,itemPromo);
                            item.total = self.computeTotalAmount(qty, price, item.discount)
                        }

                    
                            const itemPromo = item.promotions[0];

                            const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                            const discount = unformat(discountValue);
                            item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });

                            item.remarks = self.setRemarks(qty,item.custom_discount);
                            item.total = self.computeTotalAmount(qty, price, item.discount)


                        self.$set(self.itemToReplace, index, item)
                    
                    })
                    }
                    
                }else{
                this.$set(self.itemToReplace, v.index, frm)
                }



            },
            computeDiscount(qty,price,promo,discount_type,custom_discount){
                if(discount_type !== null){
                let discountPrice = 0
                let apply = false;
                let discount = 0;
                    if(discount_type === 'promo'){
                        apply = promo.apply
                        discount = promo.discount
                    }else{
                        if(custom_discount !== null){
                            if(custom_discount.hasOwnProperty("apply")){
                                apply = custom_discount.apply;
                            }
                            if(custom_discount.hasOwnProperty("discount")){
                                discount = custom_discount.discount;
                            }
                        }
                    }
                const p = parseFloat(price) * qty;
                        
                        if(apply === true || apply === 1){
                            discountPrice = parseFloat(p) * (discount / 100);
                        }else{
                            discountPrice = parseFloat(discount);
                        }
                return discountPrice;
                }
                return 0;
            },

            setRemarks(_qty,promo){
                if(promo.apply === true || promo.apply === 1){
                    if(promo.discount > 0){
                        return `${promo.discount}% Discount`;
                    }else{
                        return "";
                    }
                }else{
                    if(promo.discount > 0){
                        return `${this.formatNumber(promo.discount)} QAR Off`;
                    }else{
                        return "";
                    }
                }
            },
            computeAmount(qty,price){
                return this.formatNumber((qty * parseFloat(price)));
            },
            computeTotalAmount(qty,price,discount){
                const d = unformat(discount);
                const amount = qty * unformat(price);
                const totalAmount = amount - d;
                return this.formatNumber(((d > 0) ? totalAmount : amount));
            },

            requestItemExchange(){
                const getItemReplace = this.itemToReplace.filter((item) => {
                                            return item.code !== null && item.name !== null
                                        });
                this.$emit('request-exchange',getItemReplace)
            }

    },
    computed : {
        ...mapGetters('ItemExchangeFormModule',['tblKey','itemToExchange','items','replacement','itemToReplace','exchangeForm']),

       
    },

    watch : {
         exchangeForm : function(v){
            console.log("exchangeForm")
            console.log(v)
        }
    }
}
</script>