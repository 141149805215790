<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="false" @open="openNewModal" @row-click="rowClick" :key="tableKey">  
           <!--  <template #filter="{}">
                    <div>test</div>
            </template> -->

            <template #custom-right-culumns="{}">
                <el-table-column :sortable="false" :prop="'id'" label="PREPARED BY">
                    <template slot-scope="scope">
                        {{ scope.row.prepared_by.name }}
                    </template>
                </el-table-column>


               <!--  <el-table-column :sortable="false" :prop="'id'" label="Approved by" width="250">
                    <template slot-scope="scope">
                        {{ scope.row.approved_by.name }}
                    </template>
                </el-table-column>

                <el-table-column :sortable="false" :prop="'id'" label="Approved Date" width="200">
                    <template slot-scope="scope">
                        {{ scope.row.approved_by.date }}
                    </template>
                </el-table-column> -->
                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150">
                    <template slot-scope="scope">
                        {{ scope.row.status_text}}
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" width="100" :prop="'id'" label="-">
                        <template slot-scope="scope">
                            <!-- <a href="javascript:void(0)" @click="fnPrintDoc('/inventory-transfer/print/'+scope.row.series)" class="btn btn-sm btn-elegant">PRINT</a> -->

                            <el-dropdown :hide-on-click="true" trigger="click">
                            <el-button size="mini"><i class="el-icon-more el-icon--center"></i></el-button>
                            <el-dropdown-menu slot="dropdown">
                                <template v-if="scope.row.status != 'cancelled'">
                                    <el-dropdown-item @click.native="fnPrintDoc('/inventory-transfer/print/'+scope.row.series)"><i class="el-icon-printer text-info cursor-pointer"></i> PRINT</el-dropdown-item>
                                </template>
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'view')"><i class="el-icon-success text-success cursor-pointer"></i> VIEW</el-dropdown-item>
                             

                              <template v-if="['open','open-updated'].includes(scope.row.status)">
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'receive')"><i class="el-icon-success text-success cursor-pointer"></i> RECEIVE</el-dropdown-item>
                              </template>

                              <template v-if="['received-with-discrepancy'].includes(scope.row.status)">
                                <el-dropdown-item @click.native="receiveInventoryTransfer(scope.row,'adjustment')"><i class="el-icon-success text-success cursor-pointer"></i> ADJUSTMENT</el-dropdown-item>
                              </template>

                             <!--  <template v-if="['received-with-discrepancy','received',].includes(scope.row.status)">
                              </template> -->

                              <template v-if="scope.row.status == 'open'">
                                <el-dropdown-item @click.native="cancelRecord(scope.row)"><i class="el-icon-error text-danger cursor-pointer"></i> CANCEL</el-dropdown-item>
                              </template>

                            </el-dropdown-menu>
                          </el-dropdown>


                        </template>
                    </el-table-column>

            </template>

        </ThatsTable>

    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';


export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput,
    },

    provide(){
        return {
            btsApproverProvider : () => this.approverData
        }
    },
    data(){
        return {
            endpoint : 'inventory-transfer',
            approver : [],
            tableKey : 0
        }
    },


    created(){
      //  this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

      /* console.log("this.$route")
      console.log(this.$route) */
        this.getApprover().then((res) =>  this.approver = res);
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {
       ...mapActions('BringToShowroomModule',['getApprover']),
       ...mapActions('InventoryTransferModule',['getRecord','cancelInventoryTransferRecord']),
       ...mapMutations('InventoryTransferModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),
       ...mapMutations('InventoryTransferReceiveModule',{
        setInventoryTransferReceiveFormData : 'setFormData'
        }),

        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
            this.getRecord(row).then(() => {
                loading.close();
                
                self.$refs.bringToShowroomForm.openDialog();
            })
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.bringToShowroomForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      cancelRecord(row){
        const self = this;
        MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.series).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                    self.refreshTable();
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log(error.response.data.message)
                })
                
              })
      },

      receiveInventoryTransfer(row, action){
            const inventoryTransferData = JSON.parse(JSON.stringify(row))

            /* const inventoryTransferId = inventoryTransferData.id */

            
          /*   const filteredItems = this.form.inventory_transfer_items.filter((item)=>{
              return item.it_id == inventoryTransferId;
            },[])

            console.log("filteredItems");
            console.log(filteredItems);
            console.log(this.form.inventory_transfer_items);
            console.log(this.form.items); */

           /*  inventoryTransferData.items = filteredItems.map((item) => {
              const requestedItem =   this.form.items.filter((innerItem) => {
                    return innerItem.id == item.itr_item_id
                },[])[0]

                return {...item, photo : requestedItem.photo}
            },[]) */

            const mergeFormData = {...this.initialFormObject,...inventoryTransferData, action : action}

            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
                this.getRecord(mergeFormData).then((res) => {

                    console.log("getRecord response")
                    console.log(res)
                    loading.close();
                    self.setInventoryTransferReceiveFormData({...JSON.parse(JSON.stringify(res)),action : action})
                    self.$refs.bringToShowroomForm.openDialog();
                })

            
            console.log(mergeFormData)
           /* this.setInventoryTransferReceiveFormData(JSON.parse(JSON.stringify(mergeFormData)))

           this.$nextTick(()=>{

            this.$refs.inventoryTransferReceivingForm.openDialog()
           }) */
        },

    },
    computed: {
        ...mapGetters('InventoryTransferModule',['getLoading', 'columns','initialFormObject']),
        approverData : function(){
            return this.approver
        },

        statusArrayText : function(){
        
            return {'draft' : 'Draft','for-approval' : 'For Approval','approved': 'Approved'};
        }
    },
}


</script>