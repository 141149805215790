<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid">
       <div class="form-group input-group-sm row thats-form">
           <template v-if="showLabel">
                <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
           </template>
            <div :class="computedFieldClass">
            <div class="md-form mt-0">
                <template v-if="!disabled">
                    <template v-if="loading">
                    <mdb-btn color="elegant" size="sm" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </mdb-btn>
                    </template>
                    <template v-else>
                    <button class="btn btn-sm btn-elegant" type="button" @click="browseFile">Browse File</button>
                    </template>
                </template>

                
                <input class="display-none" :accept="accept" :multiple="multiple" type="file" id="file" ref="file" v-on:change="submitFile()"/>
                   
            </div>
            </div>

    
            <div class="col-md-12 p-0">
                    <div class="chip chip-sm success ripple-parent" v-for="(item, index) in value" :key="item.id" @click="fileHandler(item)">
                        {{item.filename}} 
                        <template v-if="!disabled">
                            <i class="fas fa-times close" @click.stop="onClose(index)"></i> 
                        </template>
                    </div>
                </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput,mdbBtn, mdbFormInline, mdbChip  } from "mdbvue";
import Axios from 'axios'
import { bearer } from '../../helpers'
import { MessageBox } from 'element-ui';
export default {
    name : 'ThatsFile',
    props : {
       
        value: {
            type: Array
        },

        show_error: {
            type: Boolean,
            default : false
        },

        multiple : {
            type : Boolean,
            default : true
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel : {
            type : Boolean,
            default : true
        },
        accept : {
            type : String,
            default : "application/msword, application/vnd.ms-excel, application/pdf"
        }
    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
        mdbChip,
        mdbBtn,
    },

    data : ()=>({
        data : [],
        selectedFiles : [],
        file: null,
        chunks: [],
        uploaded: 0,
        loading: false,
        uploadPercentage: 0
    }),
    methods : {
        browseFile(){
            const elem = this.$refs.file
            elem.click()
        },

        submitFile(){
            const self = this
            const fileList = this.$refs.file.files;    
            let formData = new FormData();

            if(fileList.length > 0){
                for(let i=0; i < fileList.length;i++){
                    formData.append('files[]',fileList[i]);
                }

                if(self.loading === false){
                    self.loading = true
                    Axios.post( '/api/upload-file', formData,
                    {
                        headers: {
                            ...bearer(),
                            'Content-Type': 'multipart/form-data'
                        },
                    }
                    ).then(function(response){
                        console.log()
                        if(response.data.data.length > 0){
                            const uploadData = [...self.data,...response.data.data];
                            self.data = uploadData
                            self.$emit('input',uploadData);
                        }
                        
                        
                        self.loading = false
                        return
                    })
                    .catch(function(e){
                        self.loading = false
                    });
                }
                
            }

                /* Array
                .from(Array(fileList.length).keys())
                .map(x => {
                    formData.append('files', fileList[x], fileList[x].name);
                }); */

            

                /* if(self.loading === false){
                    
                         this.$refs.file.files.forEach((file, index) => {
                            let formData = new FormData();
                            formData.append('file', file);
                            self.loading = true

                            Axios.post( '/api/upload-file',
                                formData,
                                {
                                    headers: {
                                        ...bearer(),
                                        'Content-Type': 'multipart/form-data'
                                    },
                                }
                            ).then(function(response){
                                self.data.push(response.data.data)
                                self.loading = false
                                return
                            })
                            .catch(function(e){
                                self.loading = false
                            });
                    })
                    
                } */
                
                
        },

        onClose(index){
            const self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    self.data.splice(index,1)
                   return true;
                })
            return false;
        },

        fileHandler(item){
            window.open(item.path, '_blank');
        }

    },

    watch: {
    // Handles internal model changes.
        data(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.data = newVal;
        },

       /*  chunks(n, o) {
            if (n.length > 0) {
                this.upload();
            }
        } */

    },
    created() {
        if (this.value) {
        this.data = this.value;
        }
    },
    computed : {
        computedFieldClass : function(){
            return (this.showLabel === false) ? 'col-md-12' : this.fieldClass
        }
    }
    /* computed: {
            progress() {
                return Math.floor((this.uploaded * 100) / this.file.size);
            },
            formData() {
                let formData = new FormData;

                formData.set('is_last', this.chunks.length === 1);
                formData.set('file', this.chunks[0], `${this.file.name}.part`);

                return formData;
            },
            config() {
                return {
                    method: 'POST',
                    data: this.formData,
                    url: '/api/upload-file',
                    headers: {
                        ...bearer(),
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent){
                        this.uploaded += progressEvent.loaded;
                    }
                    
                };
            }
        }, 
        
        onUploadProgress: function( progressEvent ) {
                            this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 )
                        }.bind(this)
        */
}

</script>

<style scoped>
.display-none {
  display: none;
}
</style>