<template>
      <div class="table-container">

<!--          {{columns}}-->
        <div class="p-0 pt-2 pb-1 header-panel-container">
            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">
                <mdb-navbar-brand class="table-header-navbar-brand">
                   <!--  <h5 class="module-title mb-0 p-1 pb-0 ml-auto">Inventory Transfer Request</h5> -->

                    <mdb-tabs class="table-header-tabs" :active="active" default :links="[ { text: 'SALES TARGET' }]"  />
                </mdb-navbar-brand>

                <mdb-form-inline class="ml-auto" tag="div">
                <input class="form-control mr-sm-2 table-searchterm-input border border-light" v-model="term" type="text" placeholder="Search" aria-label="Search" style="margin-top: 8px!important;" @keydown.enter.prevent="handleSearchTerm"/>

                <!-- <template v-if="hasFilterSlot">

                    <div class="btn-group">
                        <button type="button" class=" btn btn-sm btn-elegant" @click="handleSearchTerm">Search</button>
                            <button type="button" class="mr-auto btn btn-sm btn-grey dropdown-toggle-split" v-mdb-click-outside="away" @click="toggleDropdown" title="Advanced Filter"><mdb-icon icon="filter"></mdb-icon></button>
                            <div class="dropdown-menu dropdown-menu-right" ref="dropdownFilter" v-bind:class="[{'show ' : toggle},filterClass]" @click.stop>
                                <slot name="filter" :dispatchFilter="dispatchFilter"></slot>
                                </div>
                        </div>

                    </template>
                    <template v-else>
                    </template> -->
                    <mdb-btn color="elegant" size="sm" @click="handleSearchTerm"><i class="el-icon-search"></i> Search</mdb-btn>
                    <template v-if="hasFilterSlot">
                        <mdb-btn color="elegant" size="sm" @click="openFilterDialog" class="ml-0"><i class="el-icon-s-operation"></i> Filter</mdb-btn>
                    </template>

                <template v-if="newBtn">
                    <mdb-btn color="elegant" size="sm" @click="triggerNewEvent"><i class="el-icon-plus"></i> New</mdb-btn>
                </template>
            </mdb-form-inline>
            <!-- <mdb-navbar-nav>
                font-size: 12px;
                line-height: 15px;
                margin: 5px;
                background-color: #2e2e2e;
                border-radius: 2px;
                color: #ffff;
                <template v-if="hasFilterSlot">

                    <div class="btn-group">
                        <button type="button" class=" btn btn-sm btn-elegant" @click="handleSearchTerm">Search</button>
                            <button type="button" class="mr-auto btn btn-sm btn-grey dropdown-toggle-split" v-mdb-click-outside="away" @click="toggleDropdown" title="Advanced Filter"><mdb-icon icon="filter"></mdb-icon></button>
                            <div class="dropdown-menu dropdown-menu-right" ref="dropdownFilter" v-bind:class="[{'show ' : toggle},filterClass]" @click.stop>
                                <slot name="filter" :dispatchFilter="dispatchFilter"></slot>
                                </div>
                        </div>
                        <mdb-nav-item href="#" class="active" icon="search" color="elegant"  @click="handleSearchTerm">SEARCH</mdb-nav-item>

                    </template>
                    <template v-else>

                        <mdb-nav-item href="#" class="active" icon="search" color="elegant"  @click="handleSearchTerm">SEARCH</mdb-nav-item>
                    </template>



                   <mdb-nav-item href="#">Features</mdb-nav-item>
                   <mdb-nav-item href="#">Pricing</mdb-nav-item>
                   <mdb-nav-item class="disabled" href="#">Disabled</mdb-nav-item>
               </mdb-navbar-nav> -->
                <!--  -->
                <!-- <mdb-form-inline>
                        <mdb-input size="sm" type="text" placeholder="Search" aria-label="Search" inputClass="border border-0"/>
                        <mdb-btn color="elegant" size="sm" type="submit">Search</mdb-btn>
                        <mdb-btn color="elegant" size="sm" type="submit" icon="plus">New</mdb-btn>

                        <mdb-navbar-toggler>
                    <mdb-navbar-nav>

                    <mdb-nav-item href="#" class="active" icon="plus" color="elegant">NEW</mdb-nav-item>
                    <mdb-nav-item href="#">Features</mdb-nav-item>
                    <mdb-nav-item href="#">Pricing</mdb-nav-item>
                    <mdb-nav-item class="disabled" href="#">Disabled</mdb-nav-item>
                </mdb-navbar-nav>
                </mdb-navbar-toggler>

                    </mdb-form-inline> -->

            </mdb-navbar>
        </div>

    <!--       <div class="d-flex justify-content-between align-items-center pt-1 pl-3 pr-3">
          <h5 class="module-title mb-0 p-1 pb-0" v-bind:class="{'ml-auto': hasTabsSlot}" v-text="$attrs.title"></h5>

            <mdb-form-inline class="ml-auto" tag="div">
                <input class="form-control mr-sm-2 table-searchterm-input" v-model="term" type="text" placeholder="Search" aria-label="Search" @keydown.enter.prevent="handleSearchTerm"/>
                <mdb-btn color="elegant" icon="refresh" size="sm" class="mr-auto mt-0" @click="handleSearchTerm">Search</mdb-btn>
                <mdb-btn color="elegant" icon="plus" size="sm" class="mr-auto mt-0" @click="triggerNewEvent">New</mdb-btn>
            </mdb-form-inline>

        </div> -->

        <el-table ref="multipleTable" :border="true" lazy class="custom-element-table" :data="data.rows" :height="height" stripe @selection-change="handleSelectionChange" :show-header="true">
                <el-table-column type="index" width="35" label="#"> </el-table-column>
            <template v-if="multipleSelection">
                <el-table-column type="selection" width="35"> </el-table-column>
            </template>

            <template v-if="hasCustomColumnsSlot">
                <slot name="custom-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
            </template>
            <template v-else>

                   <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align" :resizable="true">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.row.employee_code)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else-if="col.amount">
                                {{scope.row[col.field] | amount}}
                            </template>
                            <template v-else>
                                <span v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></span>
                            </template>
                        </template>
                    </el-table-column>
            </template>

         <!--    <el-table-column :sortable="false" width="100" :prop="'id'" label="EXPORT">
                        <template slot-scope="scope">
                            <a :href="`${$route.path}/reports/${scope.row.id}`" class="btn btn-sm btn-elegant">EXPORT</a>
                        </template>
                    </el-table-column> -->


                    <template v-if="hasRightCustomColumnsSlot">
                <slot name="custom-right-culumns" :columns="columns" :getValue="getValue" :token="token" :handleClick="handleClick"></slot>
            </template>

            <template slot="empty">
                <template v-if="loading">
                    <mdb-btn color="elegant" size="sm" rounded disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading data...
                    </mdb-btn>

                </template>
                <template v-else>
                    No Data
                </template>
            </template>
        </el-table>


        <el-dialog title="Filter" :visible.sync="filterDialog" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form">
            <div class="filter-dalog-body p-4">
                <slot name="filter"></slot>
            </div>

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="handleFilterForm"><i class="el-icon-search"></i> Search</mdb-btn>
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="handleClearFilterForm"><i class="el-icon-close"></i> Clear</mdb-btn>
                </div>
            </div>

        </el-dialog>

        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

        </div>
</template>
<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside,
    mdbTabs,
    mdbInput,
    mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'
import {token} from '../../helpers/headers'
export default {
    name : "ThatsTable",
    data(){
        return {
            toggle : false,
            token : null,
            active : 0,
            filterDialog : false
        }
    },
    props : {
        endpoint : {
            type: String,
            required : true,
            default : 'new-item'
        },
        columns : {
            type: Array,
            required : true
        },
        height : {
            type: String,
            default : 'calc(100vh - 140px)'
        },
        filterClass : {
            type: String,
            default : 'sf-dropdown-menu'
        },
        importBtn : {
            type: Boolean,
            default : false
        },
        exportBtn : {
            type: Boolean,
            default : false
        },
        multipleSelection : {
            type: Boolean,
            default : true
        },
        newBtn : {
            type: Boolean,
            default : true
        },
        filterFormData : {
            type: Object,
            default : () => ({
                searchTerm : null
            })
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        mdbTabs,
        mdbInput,
        mdbNavbar,
      mdbNavbarBrand,
      mdbNavbarToggler,
      mdbNavbarNav,
      mdbNavItem,
    },
directives: {
      mdbClickOutside
    },
    created: function () {
         this.dispatchHandler()
        const tokenObj = token();

        this.token = tokenObj.token
        this.setTerm ("")
    },

    mounted(){
        const self = this;
        this.$eventBus.$on('created',function(){
        //    self.dispatchHandler();
        location.reload();
        })
        this.$eventBus.$on('reloadTable',function(){
            self.setFilters({});
            //self.refreshTable();
        })
    },

    methods: {
        ...mapActions('SalesTargetModule',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('SalesTargetModule',[
            'setLength',
            'setPage',
            'setTerm',
            'setSelection',
            'setFilters',
        ]),

        triggerNewEvent(){
            this.$emit('open','new')
        },
        EmitDeleteModal(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes',self.selection)
                    }).catch(() => {
                        self.$emit('deleteNo')
                    });

        },

        async dispatchHandler(){
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
            this.setLength(val)
            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            // this.dispatchHandler()
            this.dispatchFilter({
                ...this.filterFormData,searchTerm : this.term
            })
        },
        handleSelectionChange(val){
            this.setSelection(val)
        },
        ClearSelected(){
            this.$refs.multipleTable.clearSelection();
        },
        handleClick(row){
            this.$emit('openUpdateModal',row)
        },
        handleClickTrigger(index,row,trigger){
            this.$emit('viewWindow',{window:trigger,index : index,row : row})
        },

        refreshTable(){
            this.resetData(this.endpoint);
        },
        emitStatus(status){
            this.$emit('status',status)
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        toggleDropdown(){
            this.toggle = !this.toggle;
        },

        away(){
            this.toggle = false
        },

        dispatchFilter(filters){
            this.setFilters(filters)
             this.resetData(this.endpoint);
        },
        emitImport(){
            this.$emit('import')
        },
        emitExport(){
            // this.$emit('export')

             window.open(this.endpoint+"/export", "_blank");
        },

        downloadFile(response, filename,ext) {

            var newBlob = new Blob([response.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'})

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob)
                return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = filename + '.xlsx'
            link.click()
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data)
            }, 100)
            },
        openFilterDialog(){
            this.filterDialog = true
        },

        handleFilterForm(){
            // console.log(this.filterFormData)
            this.dispatchFilter(this.filterFormData)
        },
        handleClearFilterForm(){
            this.$emit('clearFilterForm')
            this.$nextTick(() => {
                this.dispatchFilter(this.filterFormData)
            })
        }
    },

    computed: {
        ...mapGetters('SalesTargetModule',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',
            'loading',
            'selection',
        ]),
        term : {
            get : function(){
               return this.searchTerm
            },
            set : function(val){
                this.setTerm (val)
            }
        },
        current_page : {
            get : function(){
               return this.page
            },
            set : function(val){
                this.setPage (val)
            }
        },

        hasFilterSlot() {
            return ('filter' in this.$scopedSlots);
        },

        hasTabsSlot() {
            return ('tabs' in this.$scopedSlots);
        },
        hasCustomColumnsSlot() {
            return ('custom-culumns' in this.$scopedSlots);
        },

        hasRightCustomColumnsSlot() {
            return ('custom-right-culumns' in this.$scopedSlots);
        },

    },

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}
.form-control.table-searchterm-input{
    align-self: flex-start!important;
    margin-top: 2px!important;
}


</style>
