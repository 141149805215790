<template>
    
        <CSelect class="sales-grid-select" @input="setSelected" :multiple="false" ref="vselect" v-model="inputValue" append-to-body :calculate-position="withPopper" :reduce="reduce" :label="reduceLabel" :options="options" @search="onSearch" :filterable="filterable" :clearable="clearable" @search:focus="onfocus" :disabled="disabled">
            <template #list-header>
      <li class="grid-select-header">
          <div class="row  ml-0 mr-0">
              <div class="col-md-2">
                    SKU
              </div>
              <div class="col-md-4">
                    ITEM DESCRIPTION
              </div>
              <div class="col-md-1 text-right">
                  PRICE
              </div>
              <div class="col-md-1">
                  QTY
              </div>
              <div class="col-md-1">
                  FUTURE
              </div>
              <div class="col-md-3">
                  ETA & SCHEDULES
              </div>
          </div>
      </li>
    </template>
            <template v-slot:option="option">
                <div class="row ml-0 mr-0">
              <div class="col-md-2">
                    {{ option.code}}
              </div>
              <div class="col-md-4">
                  {{ option.label }}
              </div>
                <div class="col-md-1 text-right">
                  {{ option.price | amount}}
              </div>
              <div class="col-md-1">
                  {{ option.remaining}}
              </div>
              <div class="col-md-1">
                  {{ option.future}}
              </div>
              <div class="col-md-3 text-wrap">
                  <template v-if="option.remaining <= 0">
                      To be order
                  </template>
                  <template v-else>
                        <template v-if="option.eta.length > 0">
                            <template v-for="(item) in option.eta">({{item.qty}}) {{item.eta}}</template>
                        </template>
                        <template v-else>
                            No ETA
                            </template>
                  </template>
              </div>
          </div>
            </template>

            <template #no-options>
                No matching keyword found. 
            </template>
            <template #list-footer>
                      <template v-if="addButton">
                        <li><mdb-btn class="dropdown-new" color="elegant" size="sm" @click="emitNewWindow">Add new</mdb-btn></li>
                    </template>
                </template>

        </CSelect>
</template>
<script>
import {mdbBtn} from 'mdbvue'
import { createPopper } from '@popperjs/core';
import CSelect from '../../components/v-select'
export default {
    name : 'GridVueSelect',
    props : {

        value: {
            type: null
        },
        
        options: {
            type: Array
        },
        reduce :{
            type : Function,
            default: option => option,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },

        filterable: {
            type: Boolean,
            default : true
        },
        clearable: {
            type: Boolean,
            default: true
        },

        addButton: {
            type: Boolean,
            default : true
        },
        disabled : {
            type : Boolean,
            default : false
        },
        header : {
            type : Boolean,
            default : false
        }
    },

    components : {
        mdbBtn,
        CSelect,
    },
    data : ()=>({
        inputValue : [],
        placement: 'bottom'
    }),

    methods : {
        setSelected(v){
            this.$emit('select',v)
        },
        emitNewWindow(){
          this.$refs.vselect.closeSearchOptions()
          this.$emit('newWindow')
        },
        onfocus(){
            this.$emit('onFocus')
        },
        onSearch(v){
            this.$emit('onSearch',v)
        },
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        withPopper (dropdownList, component, {width}) {
        /**
         * We need to explicitly define the dropdown width since
         * it is usually inherited from the parent with CSS.
         */
        dropdownList.style.width = width;

        /**
         * Here we position the dropdownList relative to the $refs.toggle Element.
         *
         * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
         * the dropdownList overlap by 1 pixel.
         *
         * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
         * wrapper so that we can set some styles for when the dropdown is placed
         * above.
         */
        const popper = createPopper(component.$refs.toggle, dropdownList, {
            placement: this.placement,
            modifiers: [
            {
                name: 'offset', options: {
                offset: [0, -1]
                }
            },
            {
                name: 'toggleClass',
                enabled: true,
                phase: 'write',
                fn ({state}) {
                component.$el.classList.toggle('drop-up', state.placement === 'top')
                },
            }]
        });

        /**
         * To prevent memory leaks Popper needs to be destroyed.
         * If you return function, it will be called just before dropdown is removed from DOM.
         */
        return () => popper.destroy();
        }
    },

    watch: {
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>

<style scoped>

</style>

