/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" :title="'Bulk Reset'" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">


            <template #left-button>
              <template v-if="formLoading || getLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template>
            </template>
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="fnSave('save')">
            <div class="mt-1">
              <mdb-row class="tab-row">
                      <mdb-col col="2" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Prefix" vid="prefix" name="prefix" v-model="form.prefix" size="sm" :disabled="false"/>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Suffix" vid="suffix" name="suffix" v-model="form.suffix" size="sm" :disabled="false"/>                        
                      </mdb-col>
                      <mdb-col col="1" class="tab-col">
                        <div class="checkbox-container">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="form.small" id="small"/>
                                <label class="custom-control-label" for="small">a-z</label>
                            </div>
                        </div>                      
                        <div class="checkbox-container">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="form.big" id="big"/>
                                <label class="custom-control-label" for="big">A-Z</label>
                            </div>
                        </div>                        
                      </mdb-col>
                      <mdb-col col="1" class="tab-col">
                             
                        <div class="checkbox-container">
                            <div class="custom-control custom-checkbox ">
                                <input type="checkbox" class="custom-control-input" v-model="form.num" id="numeric"/>
                                <label class="custom-control-label" for="numeric">0-9</label>
                            </div>
                        </div>                      
                        <div class="checkbox-container">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" v-model="form.special" id="special"/>
                                <label class="custom-control-label" for="special">!@#$%^&*()</label>
                            </div>
                        </div>                      
                      </mdb-col>
                      <mdb-col col="1" class="tab-col">
                             
                        <ThatsInput tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Length" vid="length" name="length" v-model="form.length" size="sm" :disabled="false"/> 
                         <div class="badge badge-success">{{passwordRandom}}</div>         
                      </mdb-col>

                      <mdb-col>

                        <template v-if="spreadsheetApplyLoading">
                          <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                          </mdb-btn>
                        </template>
                        <template v-else>
                          <mdb-btn color="elegant" class="ml-0" size="sm" @click="fnApply">Apply</mdb-btn>
                        </template>

                       
                        
                      </mdb-col>   
                      
                    </mdb-row>  


                    <!-- {{form.items}} -->

                    <div ref="spreadsheetexport" class="demo-x"></div> 

                    <div class="d-flex">

                      <template v-if="formLoading || getLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>

                        <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save'))">Reset Password</mdb-btn>

                              
                              <mdb-btn color="grey" size="sm" class="mt-0" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>

                  </div>
              
              
            </div>
            </ValidationObserver>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import jspreadsheet from '../../jsexcel/jexcel'


export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        data : [],
        form : {

            prefix : 'thAts',
            suffix : '2023!',
            small : false,
            big : false,
            num : false,
            special : false,
            length : 9,
        },
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 230px)',
            tableOverflow : true,
             data:[],
             raw:[
              {code : 3,name : 'testingx'}
             ],
             minDimensions:[3,10],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 3,
             columns: [],

            

             contextMenu: function(obj, x, y, e) {
                var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                          alert(1)
                            obj.download(true);
                        }
                    });
                } */

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });
             }
                return items;
             },

             onblur: function(instance){
              // console.log(instance)
              // console.log("blur")
              // self.fnApplyAmountSetValue(16, 0, 343434)
             },
             onchange: function(instance, cell, x, y, value){
                
                // console.log("change")
             },
             onafterchanges(){
              //  console.log("after change")
              //  self.fnApplyAmountSetValue(16, 0, 343434)
              
             }

            /*  footers: [['','','TOTAL','=SUMCOL(TABLE(),3)','','','=SUMCOL(TABLE(),6)','=SUMCOL(TABLE(),7)','=SUMCOL(TABLE(),8)','=SUMCOL(TABLE(),9)','=SUMCOL(TABLE(),10)','=SUMCOL(TABLE(),11)','=SUMCOL(TABLE(),12)'],''], */
        },

        saveAsDropUpIsOpen : false
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

        window['EXCHANGERATE'] = 0;
  },

  mounted(){
     /*  this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, this.exportTableOptions);
      Object.assign(this, this.spreadsheetexport); */

      
    console.log(this.$route)
  },

  updated(){

  },
  methods: {

    ...mapActions('UserModule',['bulkResetPassword']),
    ...mapMutations('CostingToolModule',['setSearchTerm','setEmptyForm']),
    ...mapMutations('ApplyAmountModule',{
       setApplyAmountFormData : 'setFormData'
    }),

    demo(){
      console.log("demo")
      return 0;
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
        
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },

    fnLoadPurchaseOrder(){
        const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.resetData('get-purchase-orders').then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
           
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
    },

    createPopupTable(exportTableOptions){

          console.log("Watch form")
        console.log(this.form.items)


            exportTableOptions.data = this.form.items
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.form.items)

            console.log("Table created")
            console.log(this.spreadsheetexport)
            console.log(this.exportTableOptions)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        jspreadsheetData(){
            const tableData = this.spreadsheetexport.getData();
                let itemData = [];

                  if(tableData.length > 0){

                    const fields = [
                        'Name',
                        'Email',
                        'Password',
                        'Success',
                    ];

                    itemData = tableData.filter((item)=>{
                        return item[0] !== '' && item[1] !== ''
                    },[]).map((i, index)=>{  
                          const items = {}
                          i.forEach((value,index) => {
                                items[fields[index]] = value;
                            })
                            items.index = index+1
                         return items;
                      })                
                  }


                return itemData
        },

        fnSave(status){
            const self = this;

            MessageBox.confirm(`Are you sure you want to bulk password update?`, 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                    const spreadsheetData = self.jspreadsheetData();

                    console.log(spreadsheetData);
                    if(spreadsheetData.length > 0){
                        spreadsheetData.forEach((item) => {
                                self.bulkResetPassword(item).then((response) => {

                                     if(response.index){
                                        const i = parseInt(response.index);
                                            self.spreadsheetexport.setStyle(`A${i}`,'background-color','#00c851');
                                            self.spreadsheetexport.setStyle(`B${i}`,'background-color','#00c851');
                                            self.spreadsheetexport.setStyle(`C${i}`,'background-color','#00c851');
                                        }

                                }).catch((error) => {
                                         const i = parseInt(error.response.data.index);
                                        if(i){
                                           
                                            self.spreadsheetexport.setStyle(`A${i}`,'background-color','red');
                                            self.spreadsheetexport.setStyle(`B${i}`,'background-color','red');
                                            self.spreadsheetexport.setStyle(`C${i}`,'background-color','red');
                                        }
                                           
                                });

                        })
                    }else{
                        self.$ThatsNotify.warning({
                                message : "No Email data"
                              })
                    }

              })
            

        },

        fnNewWindow(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          window.open(`${self.$route.path}/${url}`, "_blank");
        },
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },

        async fnApply(){               
            const self = this;

            const getData = this.spreadsheetexport.getData();
            
            const newTableData = getData.map((rowData, rowIndex) => {
                          const mapItemData = [...rowData];

                          let chars = '';

                            if(self.form.num){
                                chars += '0123456789';
                            }
                            if(self.form.small){
                                chars += 'abcdefghijklmnopqrstuvwxyz';
                            }
                            if(self.form.special){
                                chars += '!@#$%^&*()';
                            }
                            if(self.form.big){
                                chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                            }
                            const passwordLength = self.form.length;
                            let password = "";

                            for (var i = 0; i <= passwordLength; i++) {
                                var randomNumber = Math.floor(Math.random() * chars.length);
                                password += chars.substring(randomNumber, randomNumber +1);
                            }
                        
                            mapItemData[2] = `${self.form.prefix}${password}${self.form.suffix}`;
                            mapItemData[3] = false;
                          return mapItemData
                  });

            await self.spreadsheetexport.setData(newTableData) 
            console.log(getData)
        },


        fnOpen(v){

           const getData = this.spreadsheetexport.getData();
           const DocTotal = getData.map((i) => unformat(i[33]),[]).reduce((a,b) => a +b,0)


           const totalVolume = getData.map((item) => {
                return unformat(item[6]) * item[4];
            },[]).reduce((a,b) => a +b, 0);


            const applyTo = {
                "freightCharge" : "Freight Charge",
                "customDuty" : "Custom Duty",
                "customClearance" : "Custom Clearance",
                "shippingLine" : "Shipping Line"
            }


            // alert(`${v} --- ${applyTo[]}`)
              let option = {
                active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
              }

            if(v === 'freightCharge'){
                  option = this.form.attr.FreightCharge
              }
              if(v === 'customDuty'){
                  option = this.form.attr.CustomDuty
              }
              if(v === 'customClearance'){
                  option = this.form.attr.CustomClearance
              }
              if(v === 'shippingLine'){
                 option = this.form.attr.ShippingLine
              }


            const applyAmountFormData = { TotalVolume : totalVolume, DocTotal : DocTotal,applyTo : {code : v, name : applyTo[v]}, Option: JSON.parse(JSON.stringify(option))}

            console.log("OnOpen")
            console.log(applyAmountFormData)
            this.setApplyAmountFormData(applyAmountFormData);
            this.$refs.applyAmount.openDialog();
        },

        fnApplyAmount(v){
            console.log("onApply")
            console.log(v)
              if(v.applyTo.code === 'freightCharge'){
                  this.form.attr.FreightCharge =  {...this.form.attr.FreightCharge,...v.Option}
              }
              if(v.applyTo.code === 'customDuty'){
                  this.form.attr.CustomDuty = {...this.form.attr.CustomDuty,...v.Option}
              }
              if(v.applyTo.code === 'customClearance'){
                  this.form.attr.CustomClearance = {...this.form.attr.CustomClearance,...v.Option}
              }
              if(v.applyTo.code === 'shippingLine'){
                  this.form.attr.ShippingLine =  {...this.form.attr.ShippingLine,...v.Option}
              }
        },

        fnApplyAmountSetValue(x, y, value){
            // console.log(this.spreadsheetexport)

            this.spreadsheetexport.setValueFromCoords(x,y,value)
        },

        updateAndSave(status){
          this.fnSave(status)
        }

        

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CostingToolModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      },
      title : function(){
          return `${this.form.CardCode}-${this.form.CardName} (${this.form.status})`
      },
      passwordRandom : function(){
        let chars = '';

        if(this.form.num){
            chars += '0123456789';
        }
        if(this.form.small){
            chars += 'abcdefghijklmnopqrstuvwxyz';
        }
        if(this.form.special){
            chars += '!@#$%^&*()';
        }
        if(this.form.big){
            chars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        }
        const passwordLength = this.form.length;
        let password = "";

        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }

        return `${this.form.prefix}${password}${this.form.suffix}`;

      }
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        // this.form = {...v, items : valueData};
        this.form = v;

        
        this.CurrencySymbol = v.CurrencySymbol
        const data = [
          {item_code : "serial" , item_name : "barcode"}
        ]

      
           
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

       
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{

                 console.log("newState")
        console.log(newState)
        console.log(this.form)






        this.exportTableOptions.columns = [

                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'Name',
                    title : 'Name'
                  },
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'Email',
                    title : 'Email'
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'Password',
                    title : 'Password'
                  },
                  {
                    type : 'hidden',
                    width : '150px',
                    align : 'left',
                    name : 'Success',
                    title : 'Success'
                  },
                  
               

            ];


            // console.log(this.exportTableOptions.columns.map((i) => i.name))
                this.createPopupTable(this.exportTableOptions);

               
              }
        },

        "form.ExchangeRate" : function(v){
           window['EXCHANGERATE'] = v;
            
        }


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>