import { ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ItemDependencyModule = {
    namespaced: true,
    state: {
        area : [],
        brand : [],
        collection : [],
        category : [],
    },
    actions: {
        async getArea({ dispatch,commit }, params = {}) {
            return await new Promise((resolve) => {
                ApiV1Service.get('area', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    commit('setArea',res);
                    resolve(res);
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async getCollection({ dispatch,commit }, params = {}) {
            return await new Promise((resolve) => {
                ApiV1Service.get('collections', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    commit('setCollection',res);
                    resolve(res);
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async getCategory({ dispatch,commit }, params = {}) {
            return await new Promise((resolve) => {
                ApiV1Service.get('category', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label : element.name, value: element.mdbID }
                            res.push(row)
                        })
                    }
                    commit('setCategory',res);
                    resolve(res);
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async getBrand({ dispatch,commit }, params = {}) {
            return await new Promise((resolve) => {
                ApiV1Service.get('brands', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label: element.name, value: element.mdbID, area: element.area }
                            res.push(row)
                        })
                    }
                    commit('setBrand',res);
                    resolve(res);
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async getColour({ dispatch,commit }, params = {}) {
            return await new Promise((resolve) => {
                ApiV1Service.get('colour', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label: element.name, value: element.mdbID, slug: element.slug,attributes : element.attr.attributes}
                            res.push(row)
                        })
                    }
                    commit('setBrand',res);
                    resolve(res);
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

    },
    mutations: {
        setArea: (state,payload) => {
            state.area = payload
        },
        setBrand: (state,payload) => {
            state.brand = payload
        },
        setCategory: (state,payload) => {
            state.category = payload
        },
        setCollection: (state,payload) => {
            state.collection = payload
        },
       
    },
    getters: {
        area : state => state.area,
        brand : state => state.brand,
        collection : state => state.collection,
        category : state => state.category,
    }
}


