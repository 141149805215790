<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" v-bind:class="bindLabelHorizatal +' '+ labelClass"><el-checkbox v-model="inputValue.active">{{$attrs.label}}</el-checkbox></label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <money v-model="inputValue.value" v-bind="options" class="text-right mr-2 w-100 form-control" autocomplete="off"></money>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'AmountInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        totalAmount: {
            type: Number
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputclass : {
            type : Array,
            default : function(){
                return ['s'];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },
        maxlength: {
            type: String,
            default : ""
        },

        options : {
            type: Object,
            default : ()=>{
                return { decimal: '.', thousands: ',', prefix: '', suffix: '', precision: 2, masked: false }
            }
        },

        reduce :{
            type : Function,
            default: option => option,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },
        
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : {
            active : false,
            value : 0,
        }
    }),

    

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        setSelected(v){
            this.$emit('select',v)
        },

    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        },


    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    },
    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.horizontal){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.horizontal){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputclass.join(' ');
        }
    },
}

</script>