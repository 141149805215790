<template>
    <span>
        <slot></slot>
        <span class="copy" @click="copyText(text)">Copy</span> 
    </span>
</template>

<script>
import { Message } from 'element-ui';
export default {
    props: {
        text: {
            type: String,
            default: ""
        }
    },
    methods: {
        copyText(text) {
            var dummy = document.createElement("textarea");
              document.body.appendChild(dummy);
              dummy.setAttribute("id", "dummy_id");
              document.getElementById("dummy_id").value=text;
              dummy.select();
              document.execCommand("copy");
              document.body.removeChild(dummy);
                Message.success({
                message: "Copied!"
                });
        }
    }
}
</script>