import { SalesService } from '../../services'
import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const RoleModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [
                
                {
                    label: 'Name',
                    field: 'name',
                    clickable: true
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        endpoint: 'role',


    },
    actions: {
      
        
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('role/filter', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async gitItem({ rootState, commit }, params) {
            const { getItemLoading } = rootState.RoleModule
            return await new Promise((resolve) => {
                if (!getItemLoading) {
                    commit('getItemLoadingTrue');
                    ApiService.get(`role/${params.token_id}`, params).then((response) => {
                        commit('SetFormRecord', response.data.data)
                        commit('getItemLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('getItemLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm } = rootState.RoleModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get(`role/data`, params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async StoreRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = rootState.RoleModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store(`role`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.RoleModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update(`role`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
       
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.RoleModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete(`role`,tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        }
    },
    mutations: {
        getItemLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        getItemLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },


    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord
    }
}


