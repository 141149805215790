import { ItemService, ApiService , ApiHttpService, } from '../../services'
import {Unauthorized} from '../../helpers/unauthorized.handler'
import Axios from 'axios'
export const ItemModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        loading: false,
        viewLoading: false,
        Deleteloading: false,
        request : null,
        bom:[],
        bomItem : [],
        data : {
            columns: [

                {
                    label: 'SKU/Code',
                    field: 'series',
                    sort: 'asc',
                    width : '200',

                },

                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,

                },
                {
                    label: 'Image',
                    field: 'image_default',
                    sort: 'asc',
                    width: '100',
                },

                {
                    label: 'Category',
                    field: 'category_name',
                    width: '200',
                },

                {
                    label: 'Dimension',
                    field: 'dimension',
                    width: '200',
                },
                {
                    label: 'Price',
                    field: 'sale_price',
                    isAmount : true,
                    align : 'right',
                    width: '100',

                },
                {
                    label: 'Promo Price',
                    field: 'promo_price',
                    width: '100',
                    align : 'right',
                    isAmount : true,

                },
                /* {
                    label: '%Off',
                    field: 'off_price',
                    align : 'right',
                    width: '100',

                }, */
                {
                    label: 'Remaining',
                    field: 'remaining',
                    width: '150',

                },
                {
                    label: 'Ordered',
                    field: 'ordered',
                    width: '150',

                },

                {
                    label: 'Committed',
                    field: 'committed',
                    width: '100',

                },
                {
                    label: 'Future',
                    field: 'future_stock',
                    width: '100',

                },
                {
                    label: 'Brand',
                    field: 'brand_name',
                },
                {
                    label: 'Area',
                    field: 'area_name',
                },
            ],
            rows: [],
        },
        length : 25,
        page : 0,
        total : 1,
        searchTerm : null,
        filters : null,

        formStatus : 'create',
        formStatusCopy: 'create',
        formRecord : {},
        formRecordCopy : {},
        formObject : {
            item_code : 0,
            parent : 0,
            name : null,
            slug : null,
            sale_price : 0,
            promo_price : 0,
            stock : 0,
            returnQty : 0,
            soldQty : 0,
            InStock : 0,
            Committed : 0,
            InStock : 0,
            Ordered : 0,
            Available : 0,
            dimension : null,

            brand : null,
            area : null,
            category : null,
            collection : null,

            item_type : 'stock',
            description : "",
            website : false,
            sell : false,
            attr : {
                cube_unit : "",
                attribute : "",
                post_views : 0,
                materials : "",
                materials1 : "",
                fabric : "",
                finishes : "",
                sub_colour : "",
                colour : "",
                colour1 : "",
                colour2 : "",

                future_stock : {
                  qty : 0,
                  eta_text : "",
                  eta : []
                },

                dimension_height : "",
                dimension_width : "",
                dimension_depth : "",
                dimension_diameter : "",
                hole : "",
                capacity : "",
                weight : "",
                meta_title : "",
                meta_keyword : "",
                meta_description : "",
                visible_polish : false,
                barcode : "",
                old_code : "",
                discount : {
                  percent : 0,
                  value : 0,
                  remarks : ""
                },
                collection : {
                  en : "",
                  ar : ""
                }
            },

            child: [],
            order_eta: [],

            image: [],
            images: [],
            location: [],
            warehouses: [],
            billOfMaterials: [],

            qty : 0,
            status : '1',
            InStock : 0,
            Ordered : 0,
            Committed : 0,
            Available : 0

          },



        area : [],
        brands : [],
        category : [],
        collections : [],
        colour : [],
        materials : [],



    },
    actions : {

        getItem({commit,state},endpoint) {
            commit('setViewLoadingTrue');
            return new Promise((resolve, reject) => {
                    const configParams = {
                        req : "ItemModule/req",
                        loadingTrue : "ItemModule/setViewLoadingTrue",
                        loadingFalse : "ItemModule/setViewLoadingFalse",
                        addRequest : "ItemModule/addRequest",
                        cancelRequest : "ItemModule/cancelRequest",
                        clearOldRequest : "ItemModule/clearOldRequest"
                    }

                    const formData = {...JSON.parse(JSON.stringify(state.formObject))}
  /*                   const inventory = state.inventory
                    const params = { length: inventory.length, page: inventory.page, filters: inventory.filters } */
                    commit('SetFormRecord',formData)
                    commit('SetFormStatusUpdate')
                    ApiHttpService.get(endpoint, {}, configParams).then((response) => {
                            commit('setViewLoadingFalse')
                            commit('SetFormRecord',{...formData,...response.data})
                            resolve(response.data)
                            /* commit('setInventoryPage', response.data.current_page)
                            commit('setInventoryTotal', response.data.total) */

                        }).catch(error => {

                            const getErrorProtype =  Object.getPrototypeOf(error)
                            console.log("getErrorProtype")
                            console.log(getErrorProtype)
                            console.log(getErrorProtype.__CANCEL__)
                            if(getErrorProtype.hasOwnProperty('__CANCEL__') && !getErrorProtype.__CANCEL__){
                                commit('setViewLoadingFalse')
                            }


                            reject(error)
                    })

            })
        },

        async getEta({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-eta/${params.token_id}`).then((response) => {
                        // commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getTransactions({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-transactions/${params.token_id}`).then((response) => {
                        // commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getRecord({ rootState, commit, dispatch }, params) {
            const { viewLoading } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`item/get-item/${params.token_id}`, params).then((response) => {
                        commit('SetFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        GetBrandRelationship({ dispatch },params) {
            return new Promise((resolve) => {
                ItemService.get(`/${params.endpoint}`, params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async GetNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                    ItemService.get('/code', {}).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        Unauthorized(dispatch, error)
                    })
            })
        },

        async asyncGetData({ rootState, commit,dispatch }) {
            const { loading, length, page, filters, searchTerm} = rootState. ItemModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ItemService.get('/data', params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch,error)
                    })
                }
            })
        },

        ReplicateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.store(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        StoreImport({},payload){
             return new Promise((resolve, reject) => {
                ItemService.storeNewImport(payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
             })
        },
        PriceImport({},payload){
             return new Promise((resolve, reject) => {
                ItemService.updatePrice(payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
             })
        },
        bulkUpdateItemCode({}, payload){
            return new Promise((resolve,reject) => {
                ItemService.updateItemCode(payload).then((res) => {
                    resolve(res.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        bulkUpdateItem({}, payload){
            return new Promise((resolve,reject) => {
                ItemService.bulkUpdateItem(payload).then((res) => {
                    resolve(res.data)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        UpdateRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.put(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState,commit},payload){
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.Deleteloading;
                if (!loading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ItemService.deleteSelected(tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },

        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ItemService.saveImport(payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        validateData({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('item/validate',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateQuantity({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.ItemModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('item/update-quantity',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updatePrice({}, payload) {
            return ApiService.store('item/update-price',payload).then((response) => {
                return Promise.resolve(response.data)
            }).catch((error) => {
                return Promise.reject(error)
            })
        },

        async fetchItem({},obj) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('item/fetch', obj).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    mutations: {

        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request !== null && state.request.cancel !== null){
                state.request.cancel()
                state.viewLoading = true
            }
        },
        clearOldRequest(state, message) {
            // state.request.msg = message;
            state.request = {
                cancel : null,
                msg : null
            };
        },
        addNewBomItem(state, newItemData) {
            state.bomItem.push(newItemData);
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        SetCode: (state, payload)=>{
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },
        SetFormCopyStatusCreate: (state)=>{
            state.formStatusCopy = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormCopyStatusUpdate: (state) => {
            state.formStatusCopy = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        SetFormRecordCopy: (state,payload) => {
            state.formRecordCopy = payload
        },
        setArea: (state,payload) => {
            state.area = payload
        },
        setBrands: (state,payload) => {
            state.brands = payload
        },
        setCategory: (state,payload) => {
            state.category = payload
        },
        setCollections: (state,payload) => {
            state.collections = payload
        },
        setColour: (state,payload) => {
            state.colour = payload
        },
        setMaterials: (state,payload) => {
            state.colour = payload
        },
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },

    },
    getters: {
        req: state => state.request,
        Loading: state => state.loading,
        ViewLoading: state => state.viewLoading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        formStatusCopy: state => state.formStatusCopy,
        FormRecord: state => state.formRecord,
        formRecordCopy: state => state.formRecordCopy,
        getBrands: state => state.brands,
        getArea: state => state.area,
        getCategory: state => state.category,
        getCollections: state => state.collections,
        getColour: state => state.colour,
        getMaterials: state => state.materials,
        formObject: state => state.formObject,
        tblKey: state => state.tblKey,
        bom: state => state.bom,
        bomItem: state => state.bomItem,
    }
}


