import { ApiService } from '../../services'
export const DataIntegrationModule = {
    namespaced: true,
    state: {},
    actions: {
        integrate({}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.store(`data-integration/${payload.endpoint}`,payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        fetch({}, payload) {
            return new Promise((resolve, reject) => {
                ApiService.get(`item-master/${payload.endpoint}`,payload).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async post({}, payload) {
            return await ApiService.store(`item-master/${payload.endpoint}`, payload.record)
        },
      
    },

}


