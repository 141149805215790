
<template>
    <div class="module-view-container">
        <ThatsTable title="Wallpapers" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="checkRoleImportButton" 
:newBtn="checkRoleNewButton" @import="importProduct">
            <template #custom-culumns="{columns, getValue,handleClick}">
                <template v-for="col in columns">
                    <template v-if="checkGroup1(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                    </template>
                    <template v-if="col.field === 'thumb'">
                        <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                            <template slot-scope="scope">
                                    <a href="javascript:void(0)" @click="viewImage(scope.$index, scope.row)" >
                                        <img :src="scope.row.image.thumbnail" />
                                    </a>
                        </template>
                        </el-table-column>
                    </template>
                    <template v-if="checkGroup2(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                    </template>

                </template>
            </template>
            
            <template #filter="{dispatchFilter}">
                <WallpapersFilter @filter="dispatchFilter"></WallpapersFilter>
            </template>

           
        </ThatsTable>
        <WallpapersForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></WallpapersForm>
        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2" :endpoint="'wallpaper/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>
        <ThatsMediaPlayer :group="2" ref="galleryWindow" title="Gallery Manager" :data="galleryData"></ThatsMediaPlayer>
    </div>
</template>
<script>

import { mapGetters, mapMutations,mapActions } from 'vuex';
import {mdbTabs} from 'mdbvue'
import WallpapersForm from './WallpapersForm'
import WallpapersFilter from './WallpapersFilter'
import ThatsTable from '../../components/thats/Table'
import ThatsMediaPlayer from '../../components/thats/MediaPlayer'
import ThatsCustomWindow from '../../components/thats/CustomWindow'
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from '../../components/filemanager/TableImport'
import TableError from '../../components/filemanager/TableError'
export default {
    data(){
        return {
            endpoint : 'wallpaper/data',    
            importData : {},        
            errorData : {},        
            importLoading : false,        
            group1 : ["series","brand_name","name"],        
            group2 : ['description', 'formatted_price', 'qty',],        
            galleryData : [],        
            active : 0,
        }
    },
    components: {
        WallpapersFilter,
        WallpapersForm,
        ThatsTable,
        FileUpload,
        TableImport,
        TableError,
        ThatsCustomWindow,
        ThatsMediaPlayer,
        mdbTabs,

    },

    methods: {
        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapActions('WallpaperModule',[ 'DeleteRecord','saveImport' ]),
        ...mapMutations('WallpaperModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetPage']),
        checkGroup1(column){
           return this.group1.includes(column)
        },
        checkGroup2(column){
           return this.group2.includes(column)
        },
        viewImage(index,row){
            this.galleryData = [];
            if(row.images.length){
                row.images.forEach(element => {
                    this.galleryData.push({
                        img: true,
                        src: element.large,
                        alt: element.name,
                        caption: {
                        title: element.name
                        }
                    })
                });

                this.$refs.galleryWindow.openDialog()
            }

        },

        viewEtaHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        viewSoldToHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        getActiveTabIndex(index){

            const tabs = [1,2,3,0,4,5]
            this.endpoint = 'wallpaper/data?status='+tabs[index];
            this.SetPage(1)
            this.resetData('wallpaper/data?status='+tabs[index]);

        },

    },
    computed: {
        ...mapGetters('WallpaperModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
        checkRoleImportButton : function(){
                if(this.$helpers.isSalesExecutive()){
                    return false
                }
            return true;
        },
        checkRoleNewButton : function(){
            if(this.$helpers.isSalesExecutive()){
                return false
            }
            return true;
        },
        isAllowed : function(){
            if(this.$helpers.isSalesExecutive()){
                return false
            }
            return true;
        }
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>