import { ApiHttpService } from '../../services'
export const CodeGeneratorCategoryModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
            {
                label: 'Code',
                field: 'Code',
                sort: 'asc',
                width: '200',
                clickable: true
            },
            {
                label: 'Name',
                field: 'Name',
                sort: 'asc',
            },
            
          

            ],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {
            code : null,
            name : null,     
        },


        initialFormData : {
            code : null,
            name : null,
          },

          getLoading : false,
          getRecordLoading : false,
          putLoading : false,

          formStatus : 'create',
          newItemCategoryData : []
        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },
        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
        setNewItemCategoryData : (state,payload) => state.newItemCategoryData = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log(res)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "CodeGeneratorCategoryModule/req",
                        loadingTrue : "CodeGeneratorCategoryModule/loadingTrue",
                        addRequest : "CodeGeneratorCategoryModule/addRequest",
                        cancelRequest : "CodeGeneratorCategoryModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorCategoryModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        
                        commit('setRows', response.data.data)
                        commit('setLoading', false)
                        
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },

        async getRecords({commit}) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('new-item/category').then((response) => {
                    commit('setNewItemCategoryData',response.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))


                    const configParams = {
                        req : "CodeGeneratorCategoryModule/req",
                        loadingTrue : "CodeGeneratorCategoryModule/loadingTrue",
                        addRequest : "CodeGeneratorCategoryModule/addRequest",
                        cancelRequest : "CodeGeneratorCategoryModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorCategoryModule/clearOldRequest"
                    }


                    ApiHttpService.post('new-item/category',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data};
                        // commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async createUpdateRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "CodeGeneratorCategoryModule/req",
                        loadingTrue : "CodeGeneratorCategoryModule/loadingTrue",
                        addRequest : "CodeGeneratorCategoryModule/addRequest",
                        cancelRequest : "CodeGeneratorCategoryModule/cancelRequest",
                        clearOldRequest : "CodeGeneratorCategoryModule/clearOldRequest"
                    }
                    ApiHttpService.post('new-item/category/create-update',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        /* let responseData = {...response.data.data};
                        resolve(responseData) */
                        commit('setNewItemCategoryData',response.data.data)
                        resolve(response.data.data)

                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },


        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiHttpService.update(`costing/${updatePayload.id}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        }
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialForm: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        getNewItemCategoryData: state => state.newItemCategoryData,
    }
}



