import { ApiService, ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ItemExchangeModule = {
    namespaced: true,
    state: {
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'invoice_date',
                },
                {
                    label: 'Tracking No.',
                    field: 'series_display',
                    clickable : true
                },
                {
                    label: 'Item Name',
                    field: 'item_name',
                },
                {
                    label: 'Customer Mobile',
                    field: 'customer_mobile',
                },
                {
                    label: 'Total Amount',
                    field: 'formated_total',
                    align: 'right',
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                },
                {
                    label: 'Status',
                    field: 'item_status',
                    clickable : true
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

    
    },
    actions: {
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('/sales-exchange/filter', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('sales-exchange/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getRecord({ rootState, commit, dispatch}, params) {
            const { viewLoading } = rootState.ReturnExchangeModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiV1Service.get(`item-exchange/${params.series}/${params.activeTab}`, params).then((response) => {
                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

 
        updateRecord({ rootState, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = rootState.ReturnExchangeModule.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update(`item-exchange/${payload.series}`,payload).then((response) => {
                        commit('setPutLoadingFalse')
                        commit('setFormRecord', response.data.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async deleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.ReturnExchangeModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('item-exchange',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = false
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = true
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
       

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
    }
}


