<template>
    <el-dialog title="Invoices" :visible.sync="dialogVisibleSync" :close-on-click-modal="false" width="70%" size="small" class="text-left item-invoice-form">
        <div class="filter-dalog-body p-0">

            <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="600" stripe>
                    <el-table-column type="index" width="35" label="#"> </el-table-column>
                    <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align" :resizable="true">
                        <template slot-scope="scope">
                            <template v-if="col.field === 'series'">
                                <a href="javascript:void(0)" @click="handleSaleTransactionClick(scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}
                            
                            </template>
                        </template>
                    </el-table-column>
                   <template slot="empty">
                       <template v-if="loading">
                           <mdb-btn color="elegant" size="sm" rounded disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading data...
                           </mdb-btn>
                       
                       </template>
                       <template v-else>
                           No Data
                       </template>
                    </template>
   
   
               </el-table>

            <el-pagination        
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="current_page"
                :page-sizes="[10,25,50,100, 200, 300, 400]"
                :page-size="length"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>
    </el-dialog>
</template>
<script>
import { mapActions, mapMutations ,mapGetters  } from 'vuex'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import { Loading } from 'element-ui';
const formatMoneyConfig = { symbol: "", precision: 2, thousand: ",", decimal: "." }
export default {
    data(){
        return {
            dialogVisibleSync : false,
            columns : [
                {
                    label: 'Date',
                    field: 'date',
                    width : '150px'
                },
                {
                    label: 'Invoice No.',
                    field: 'series',
                    width : '200px'
                },
                {
                    label: 'Customer',
                    field: 'customer_mobile',
                    width : '300px'
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                    width : '300px'
                },
                {
                    label: 'Qty',
                    field: 'countQty',
                    align: 'right',
                },
                {
                    label: 'Status',
                    field: 'status',
                    align: 'right',
                    width : '150px'
                },
            ],
            filterObj : {
                dateRange: {},
                series : "",
                sold_to : "",
                customer_mobile : "",
                customer_name : "",
                item_code : "",
                status : "",
                balance : false,
            },

            endpoint : 'item-master/transactions',
        }
    },

    methods : {
        ...mapActions('DataTableModule',[
          'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('DataTableModule',[
            'setTotal',
            'setLength',
            'setPage',
            'setTerm',
            'setSelection',
            'setEmptyData',
            'setFilters',
        ]),
        
        openDialog(){
            this.dialogVisibleSync = true
        },

        handleSaleTransactionClick(row){
            this.$emit('rowClick',row)
        },

        triggerGetTransaction(obj){
            const self = this;

            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            }); 

            this.setTotal(0)
            this.filterObj = {...this.filterObj,term : obj.series}
            this.setFilters(this.filterObj)
            this.resetData(`${this.endpoint}?item=${obj.series}`).then(() => {
                loading.close();
                self.dialogVisibleSync = true
            });
           /*  const soldDataLocal = [];
            this.data = [];
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
            }); 

            self.getTransactions(obj).then((response) => {
                loading.close();
                if(response.length > 0){
                        response.forEach(element => {
                            const total = unformat(element.transactions.total);
                            const paymentPaid = unformat(element.transactions.invoice.paid);
                            const balance = formatMoney((total - paymentPaid),formatMoneyConfig)
                            soldDataLocal.push({
                                sort_date : new Date(element.transactions.invoice.date),
                                date : element.transactions.invoice.date,
                                customer : element.transactions.customer_mobile,
                                transaction : element.transactions.invoice.series,
                                qty : element.qty,
                                status : element.transactions.status,
                                token_id : element.transactions.token_id,
                                soldby : element.transactions.sold_by,
                                total : formatMoney(total,formatMoneyConfig),
                                balance : balance,
                            })
                        });

                        this.data = soldDataLocal.sort((a, b) => b.sort_date > a.sort_date ? 1: -1);
                    }
                self.dialogVisibleSync = true
            }); */
        },
        async dispatchHandler(){
            this.setFilters(this.filterObj)
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
          this.SetLength(val)
          
          this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.setTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.setSelection(val)
        },
        refreshTable(){
            this.setFilters(this.filterObj)
          this.resetData(this.endpoint);
        },
        dispatchFilter(filters){
            this.setTotal(0)
            this.setFilters(filters)
            this.resetData(this.endpoint);
        },
    },
    computed: {
      ...mapGetters('DataTableModule',[
          'data',
          'page',
          'total',
          'length',
          'searchTerm',
          'loading',
          'selection',
      ]),
      term : {
          get : function(){
             return this.searchTerm
          },
          set : function(val){
              this.setTerm (val)
          }
      },
      current_page : {
          get : function(){
             return this.page
          },
          set : function(val){
              this.setPage (val)
          }
      },

      finalData : function(){
        const self = this;
        const fData = [];
          /*   if(this.data.rows.length > 0){
                this.data.rows.forEach(element => {

                    const countQty = element.itm_names.filter((item) => {
                        return item.code === self.filterObj.item_code
                    },[]).map((item) => item.qty, 0).reduce((a,b) => a + b,0)
                    const row = {...element,
                        countQty : countQty
                    }
                    
                    fData.push(row)
                });
            } */
        return fData;
      },

      hasFilter(){
          return !!this.$scopes.filter;
      },

      hasFilterSlot() {
          console.log(this.$scopedSlots)
          // console.log(('filter' in this.$scopedSlots))
          return ('filter' in this.$scopedSlots);
      },

      hasTabsSlot() {
          return ('tabs' in this.$scopedSlots);
      },
      hasRightSlot() {
          return ('top-right' in this.$scopedSlots);
      },
      hasCustomColumnsSlot() {
          return ('custom-culumns' in this.$scopedSlots);
      },

  },
}
</script>