<template>
    <div class="page-container">
        <ThatsTable title="Purchase Order" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="false" :height="'calc(100vh - 125px)'" @open="openNewModal" @row-click="rowClick"></ThatsTable>
        <PurchaseOrderForm ref="purchaseOrderForm" @success="refreshTable"></PurchaseOrderForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import moment from 'moment'

import PurchaseOrderForm from './PurchaseOrderForm'
import { Loading } from 'element-ui';
export default {
        components:{
        ThatsTable,
        mdbTabs,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect,
        mdbInput,
        PurchaseOrderForm

    },data(){
         let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6)

        return {
            // endpoint : 'delivery/data?status=for-delivery',    
            filterBrands : [],        
            importData : {},        
            errorData : {},        
            importLoading : false,        
            active : 0,        
            activeTab : 0,        
            token : null,
            form : {
                date : "",
                date_range : {
                    startDate: null, 
                    endDate: null
                },
                brands : []
            },
            userLocation : [],


            newCostingDialog : false
        }
    },


    created(){
        const self = this
        this.currentDateSetter();
    },



    
    methods: {
        ...mapActions('SapPurchaseOrderModule',[ 'getRecord']),
        ...mapMutations('SapPurchaseOrderModule',['setFormStatusUpdate','setFormData']),
        moment,   
        OpenUpdateModal(obj){
            var self = this;

             

         /*    const form  = {...obj.row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}           
            self.setFormStatusUpdate()
            self.setFormRecord(form)
            

            if(this.activeTab !=='pull-out'){
                self.getRecord({token_id : obj.row.token_id})
                self.openWindow()
            } */

        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

     


        fnUpdateStatus(formData){
            var self = this;
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.refreshTable();
                    }).catch((error) => {
                        self.$ThatsNotify.success({
                        message : "Something wen't wrong"
                        })
                        self.refreshTable();
                    })
            
        },

        currentDateSetter(){

            var todayDate = new Date().toISOString().slice(0, 10);

            this.form.date = todayDate

            this.form.date_range = {
                startDate: todayDate, 
                endDate: todayDate
            }

        },


        exportReport(){

            const startDate = this.moment(this.form.date_range.startDate).format('YYYY-MM-DD')
            const endDate = this.moment(this.form.date_range.endDate).format('YYYY-MM-DD')

            window.open(`reports/sales-report/export/${startDate}/${endDate}?brands=${this.form.brands}`,'_blank');
// window.open(url);
        },
        printReport(){
            window.open(`reports/sales-report/print-pdf/${this.form.date_range.startDate}to${this.form.date_range.startDate}?brands=${this.form.brands}`,'_blank');
// window.open(url);
        },
        getSelectValue(v){
            // console.log(v)
            this.form.brands = v
        },
        openNewModal(v){
            if(v === 'new'){
                this.$refs.purchaseOrderTableForm.openDialog();
            }
        },

        onSubmitForm(){
            const self =this
            const isValid = this.$refs.observer.validate();

            if(isValid){
                   self.$refs.purchaseOrderTableForm.openDialog();
                   this.newCostingDialog = false

            }
        },

        rowClick(row){
            const self = this;
            this.setFormStatusUpdate();
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });                
             const formData = {...this.initialForm,...row,items: []}
            this.setFormData(formData);
            this.getRecord(row).then((res) => {
                loading.close()
                self.$refs.purchaseOrderForm.openDialog();
            }).catch((res) => {
                console.log("Purchase ORder Form")
                console.log(res)
            })
        }

    },
    computed: {

        ...mapGetters('SapPurchaseOrderModule',['getLoading', 'columns','initialForm']),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        },

        endpoint : function(){
            return `sap/purchase-order`
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }

  .date-range-picker-small .form-control{
      height: calc(1.5em + .5rem + 2px)!Important;
      line-height: 1.5;
  }
</style>