<template>
<hsc-window-style-metal>
    <hsc-window title="Data Integration" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">
      <div class="table-container pr-0 pl-0">
        <el-table ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="tableHeight" stripe @selection-change="handleSelectionChange">
            <template v-for="col in data.columns">
                <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align" :fixed="col.fixed">
                    <template slot-scope="scope">
                            {{getValue(scope,col.field)}}
                            {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}
                    </template>
                    
                </el-table-column>
            </template>
           
        </el-table>

        
        
        </div>

        <mdb-container>
            <div class="d-flex">
                 <template v-if="loading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <button class="btn btn-sm btn-elegant" type="button" @click="importData">Import</button>
                  <button class="btn btn-sm btn-grey" type="button" @click="closeDialog">Cancel</button>
                </template>
            </div>
        </mdb-container>

    </hsc-window>
</hsc-window-style-metal>
</template>

<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside 
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'

export default {
    name : "ThatsTable",
    data(){
        return {
            toggle : false,
            current_page : 1,
            x : 0,
            y : 0,
            dialogIsOpen : false,
        }
    },



    props : {
        data : {
            type: Object,
            required : true
        },

        tableHeight : {
            type: String,
            default : 'calc(100vh - 128px)'
        },

         group : {
            type : Number,
            default : 1
        },
        height : {
            type : Number,
                default : 600
        },
        width : {
            type : Number,
                default : 1024
        },
        loading : {
            type : Boolean,
            default : false
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },
directives: {
      mdbClickOutside
    },

    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        handleSizeChange(val) {
            
        },
        handleCurrentChange(val) {
            
        },
        handleSearchTerm() {
           
        },
        handleSelectionChange(val){
            
        },
        ClearSelected(){
           
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        importData(){
            this.$emit('import',this.data.rows)
        }

        

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}


</style>