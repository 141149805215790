<template>
  <div>
    <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @openUpdateModal="OpenUpdateModal" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm">
<!--      <template #filter="{}">-->
<!--        <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="kerwords" vid="searchTerm" name="searchTerm" v-model="filterForm.searchTerm" size="sm" :disabled="false" :rules="''"/>-->
<!--        <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Doc Date" placeholder="Date" vid="date" name="date" v-model="filterForm.date" :rules="''" size="sm" :disabled="false"/>-->
<!--        <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Location" v-model="filterForm.location" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="''"></ThatsMultiSelect>-->
<!--        <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Approved By" v-model="filterForm.approvers" :reduce="label => label.value" :reduceLabel="'label'" :options="documentApprover" :rules="''"></ThatsMultiSelect>-->
<!--        <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Receiver" v-model="filterForm.receiver" :reduce="label => label.value" :reduceLabel="'label'" :options="documentReceiver" :rules="''"></ThatsMultiSelect>-->
<!--        <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="docStatus" :rules="''"></ThatsMultiSelect>-->


<!--      </template>-->



    </ThatsTable>
    <ShowroomTargetRequestForm ref="bringToShowroomForm" @success="refreshTable"></ShowroomTargetRequestForm>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import ShowroomTargetRequestForm from './ShowroomTargetRequestForm.vue'

export default {
  components:{
    ThatsTable,
    mdbTabs,
    mdbBadge,
    DateRangePicker, mdbRow, mdbCol,
    ThatsDatePicker,
    mdbBtn,
    ThatsMultiSelect,
    ValidationObserver,ValidationProvider,ThatsMultiVueSelect,ShowroomTargetRequestForm,
    mdbSelect,
    mdbInput,
    ThatsInput
  },
  inject : ['showroomsProvider'],
  provide(){
    return {
      btsApproverProvider : () => this.approverData,
      btsReceiverProvider : () => this.receiverData,
    }
  },
  data(){
    return {
      endpoint : 'showroom-target',
      approver : [],
      receiver : [],
      tableKey : 0,
      filterForm : {
        date : '',
        searchTerm : null,
        location : null,
        approvers : null,
        receiver : null,
        status : null,
      },
      docStatus : [
        {value : 'for-approval', label : 'For Approval'},
        {value : 'rejected', label : 'Rejected'},
        {value : 'approved', label : 'Approved'},
      ]
    }
  },


  created(){

    const self = this;
    this.getApprover().then((res) =>  this.approver = res);
    this.getReceiver().then((res) => {
      if(res.length > 0){
        self.receiver = res.filter((user)=>{
          return ['warehouse','delivery-team','showroom-manager','general-manager','interior-designers','administrator','back-office'].some(item => user.role_slugs.includes(item));
        })
      }
    });
  },

  mounted(){
    const self = this;

    // console.log("onmounted")
    this.$eventBus.$on('reloadTable',function(){
      self.tableKey++
      self.setFilters();
    })
  },
  methods: {
    ...mapActions('BringToShowroomModule',['getApprover']),
    ...mapActions('ShowroomTargetModule',['getRecord','getReceiver']),
    ...mapMutations('ShowroomTargetModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters','setFormData','setShowroomTargetFormData']),

    rowClick(row){
      const self = this;
      const loading = Loading.service({
        fullscreen: true ,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      });
      self.setFormStatusUpdate();
      this.getRecord(row).then(() => {
        loading.close();

        self.$refs.bringToShowroomForm.openDialog();
      })
    },
    openNewModal(){
      this.setFormStatusCreate();
      this.setEmptyForm();
      this.$refs.bringToShowroomForm.openDialog();
    },
    OpenUpdateModal(obj) {
      this.setShowroomTargetFormData(obj)
      this.setFormStatusUpdate()
      this.setEmptyForm();
      this.$refs.bringToShowroomForm.openDialog();
    },
    refreshTable(){
      // //alert(1)
      this.tableKey++
      this.$refs.table.refreshTable()

    },

    fnPrintDoc(url){
      const windowHeight =  window.innerHeight;
      const innerWidth =  window.innerWidth;

      var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
      const win = window.open(`${url}`, "_blank", strWindowFeatures);
      win.document.title = 'Print';
      win.print()

    },

    clearFilterForm(){
      this.filterForm = JSON.parse(JSON.stringify(
          {
            date : '',
            searchTerm : null,
            location : null,
            approvers : null,
            receiver : null,
            status : null,
          }
      ))
    }
  },
  computed: {
    ...mapGetters('ShowroomTargetModule',['getLoading', 'columns','initialForm','form']),
    approverData : function(){
      return this.approver
    },
    receiverData : function(){
      return this.receiver
    },

    statusArrayText : function(){

      return {'draft' : 'Draft','for-approval' : 'For Approval','approved': 'Approved','rejected' :'Rejected','save' : 'Saved'};
    },
    statusArrayColor : function(){

      return {'draft' : 'badge-light','for-approval' : 'warning','save' : 'warning','approved': 'success','rejected' : 'danger'};
    },
    statusArrayIcon : function(){

      return {'draft' : 'el-icon-more','for-approval' : 'el-icon-circle-check','save' : 'el-icon-circle-check','approved': 'el-icon-success','rejected' : 'el-icon-remove'};
    },

    showroomLocation : function(){

      const showroom = this.showroomsProvider();

      return showroom.map((item) => {
        return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
      },[])
    },
    documentApprover : function(){

      return this.approver.map((item) => {
        return {...item, label : `${item.name}`,value : item.id}
      },[])
    },
    documentReceiver : function(){
      return this.receiver.filter((user) => {
        return ['warehouse','delivery-team','showroom-manager','interior-designers','back-office','administrator'].some(item => user.role_slugs.includes(item))
      },[]).map((item) => {
        return {...item, label : `${item.name}`,value : item.id}
      },[])
    },
  },
}


</script>
