<template>
<hsc-window-style-metal>
    <hsc-window title="Insuffecient Stock" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="4"  :closeButton="true" :isOpen.sync="dialogIsOpen">
    <el-table lazy class="custom-element-table" :data="rows" stripe :height="tableHeight" :row-class-name="errorClassName">
            
                <el-table-column label="ETA">
                    <template slot-scope="scope">
                            {{scope.row.eta[0].po.eta}}
                    </template>
                </el-table-column>
                <el-table-column label="Description">
                    <template slot-scope="scope">
                            {{scope.row.name}}
                    </template>
                </el-table-column>
                <el-table-column label="Qty">
                    <template slot-scope="scope">
                            {{scope.row.qty}}
                    </template>
                </el-table-column>
                <el-table-column label="Reserve">
                    <template slot-scope="scope">
                            <input type="number" min="0" class="form-control grid-input thats-form-control text-right" v-on:change="quantityChange(scope.row,scope.$index)" v-model="scope.row.reserve" />
                    </template>
                </el-table-column>
        <template slot="empty">
            No Data
        </template>
    </el-table>
      <mdb-container>
            <div class="d-flex">
                <mdb-btn color="elegant" size="sm" @click="saveContinue('continueWith')">Continue with reservation</mdb-btn>
                <button class="btn btn-sm btn-grey" type="button" @click="saveContinue('continueWithout')">Continue w/o reservation</button>
            </div>
        </mdb-container>

    </hsc-window>
</hsc-window-style-metal>
</template>

<script>
import {
    mdbIcon,
    mdbContainer,
    mdbBtn,
    mdbFormInline,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbClickOutside,
     mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane, mdbAlert 
} from "mdbvue";

import { mapGetters, mapMutations,mapActions } from 'vuex';
import { MessageBox } from 'element-ui'

export default {
    name : "InsuffecientStock",
    data(){
        return {
            toggle : false,
            current_page : 1,
            x : 0,
            y : 0,
            dialogIsOpen : false,
            active : 0
        }
    },



    props : {
        tableHeight : {
            type: String,
            default : 'calc(100vh - 250px)'
        },

         group : {
            type : Number,
            default : 1
        },
        height : {
            type : Number,
                default : 400
        },
        width : {
            type : Number,
                default : 800
        }
    },
    components: {
        mdbIcon,
        mdbBtn,
        mdbContainer,
        mdbFormInline,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
         mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane,
         mdbAlert
    },
directives: {
      mdbClickOutside
    },

    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        handleSizeChange(val) {
            
        },
        handleCurrentChange(val) {
            
        },
        handleSearchTerm() {
           
        },
        handleSelectionChange(val){
            
        },
        ClearSelected(){
           
        },

        getValue(scope,string){
            var result = string.split(".");
            const response = [];
            result.forEach(element => {
                response.push(element)
            });

            return scope.row.response;
        },

        importData(){
            this.$emit('import',this.data)
        },
        getActiveTabIndex(index){
            this.active = index
        },

        errorClassName({ row }) {

        if (row.hasOwnProperty('message')) {
            return 'error-row'
        }
        return ''
        },

        saveContinue(action){
            this.$emit('saveContinue',action)
            this.closeDialog()
        },


        quantityChange(v,index){
            
            const reserve = parseInt(v.reserve);

            if(reserve > v.qty){
                v.reserve = v.qty 
            }
            
            if(reserve < 0){
                v.reserve = 0
            }

              

                this.$set(this.rows,index,v);
        // console.log(v)
/*         const qty = parseInt(v.qty);
          if(qty > v.reserve){
              v.reserve = v.reserve;
          }
          if(qty < 0){
            v.reserve = 0;
          } */
      },

        

    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

    computed : {
        ...mapGetters('InsufficientAlertModule',['rows','columns'])
    },
/*     watch : {
        data  : function(v){
            const errs = v.map(i => i.rows.length)
            if(errs.length > 0){
                    if(errs[0] === 0 && errs[1] > 0){
                        this.active = 1;
                    }
            }
        }
    } */

}
</script>
<style scoped>
.table-container{ padding : 0 5px } .custom-element-table{ position: relative; }
</style>

<style>
.dropdown-menu.show{
    top:100%!important;
    position:absolute!important;
}

.select-store .dropdown-menu {
    position: relative!important;
}

.dropdown-menu.sf-dropdown-menu{
    min-width: 600px;background-color: #f3f3f3;
}

.tab-content{
    padding: 0
}
.alert{
    border-radius : 0!important;padding : .25rem 1.25rem!important;
}
</style>