<template>
  <div>
    <ValidationObserver class="needs-validation form-custom-format " tag="form" ref="dialogObserver" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog :modal="true" :fullscreen="false" :title="'ISSUE VOUCHER'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" class="text-left discount-calculator">
          <div>
             

              <div class="dialog-scroll-container">


    
                 <!--  <ThatsInput labelClass="col-sm-6" fieldClass="col-sm-6" label="Document Total" placeholder="0" vid="document_total" name="document_total" v-model="documentTotal" :rules="''" size="sm" :horizontal="true" :readOnly="true"/> -->
                <mdb-row class="tab-row">
                          <mdb-col lg="12" md="12" sm="12" class="mt-2 pb-2">    

                    <!--         {{ documentTotal }} -->
                            <template v-if="form !== null">
                                <el-card shadow="never">
                                    <mdb-row>
                                        <mdb-col col="6">CUSTOMER</mdb-col>
                                        <mdb-col col="6" class="text-right"><strong>{{ form.customer.name }}</strong></mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="6">MOBILE</mdb-col>
                                        <mdb-col col="6" class="text-right"><strong>{{form.customer.mobile}}</strong></mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="6">TOTAL</mdb-col>
                                        <mdb-col col="6" class="text-right"><strong>{{ formatNumber(form.document.valid_amount) }}</strong>

                                        
                                        </mdb-col>
                                        <mdb-col col="12" class="text-right">
                                            <mdbAlert color="warning">
                                                Please note that the total amount does not include the credit note
                                            </mdbAlert>
                                        </mdb-col>
                                    </mdb-row>




                                    </el-card>

                                     <el-card shadow="never">

                                        <template v-if="vouchers.length > 0">
                                        <template v-if="!hasIssuedVoucher">
                                        <mdb-row>
                                        <mdb-col col="6"><strong>AVAILABLE VOUCHER</strong></mdb-col>
                                           
                                        </mdb-row>
                                        <mdb-row>
                                        <hr>
                                        <mdb-col col="6">{{form.voucher.name}}</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                <ValidationProvider :rules="{ required: true }" v-slot="{errors}" vid="reference_number" name="reference_number">
                                                    <input type="text" v-model="form.reference_number" vid="reference_number" name="reference_number" class="form-control text-right percent-" min="0" placeholder="VOUCHER CODE"/>
                                                    <span class="errors">{{errors[0]}}</span>
                                                </ValidationProvider>
                                            </mdb-col>
                                        </mdb-row>
                                        </template>
                                        <template v-else>
                                            <mdb-row>
                                                <mdb-col col="12" class="text-center">
                                                    <mdbAlert color="success">
                                                        Voucher has been issued by {{form.document.attr.voucher.issued_by}}
                                                    </mdbAlert>
                                                </mdb-col>
                                                <mdb-col col="6" class="">
                                                    {{form.document.attr.voucher.name}}
                                                </mdb-col>
                                                <mdb-col col="6" class="text-right">
                                                    {{form.document.attr.voucher.code}}
                                                </mdb-col>

                                                
                                            </mdb-row>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <mdb-row>
                                                <mdb-col col="12" class="text-center">
                                                    <span class="text-danger">No voucher available</span>
                                                </mdb-col>
                                            </mdb-row>
                                        </template>
                                    </el-card>
                            </template>
                          </mdb-col>
                      </mdb-row>
                </div>

          </div>
          <template slot="footer">
              
            <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0 mb-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <template v-if="vouchers.length > 0 && !hasIssuedVoucher">
                                <mdb-btn class="mt-0 mb-0" color="elegant" size="sm" @click="onSubmitForm"><i class="el-icon-check text-white cursor-pointer"></i> Issue Voucher</mdb-btn>
                            
                            </template>
                        </template>
                        <mdb-btn class="mt-0 mb-0" color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                      </template>
          </template>
      </el-dialog>
      </ValidationObserver>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mdbBtn,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand, mdbAlert} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'


import { MessageBox, Message } from 'element-ui';
import formatMoney from 'accounting-js/lib/formatMoney.js'
import unformat from 'accounting-js/lib/unformat.js'
import VueSlider from 'vue-slider-component'
export default {
  components : {
        mdbAlert,
      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardImage,
      ValidationObserver,
      ValidationProvider,
      VueSlider,
       mdbFormInline,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },
  },
  inject : [],
  data(){
      return {
        syncModal : false,
        formLoading : false,
        readOnly : false,
        form : null,
        vouchers : []
      }
  },
  created(){

  },
  mounted(){
      const self = this
     

  },
  methods : {
        ...mapActions('IssueVoucherModule',['getRecords','storeRecord']),

      openDialog(){
          this.syncModal = true
      },
      closeDialog(){
          this.syncModal = false
      },    


      formatNumber(n) {
        return formatMoney(unformat(n), { symbol: "", precision: 2, thousand: ",", decimal: "." })
    },

    onSubmitForm(){

        const self = this;
        this.$refs.dialogObserver.validate().then((valid) => {
            if(valid){
                MessageBox.confirm('Are you sure you want to voucher?.', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {
                    self.formLoading = true
                    self.storeRecord(self.form).then((response) => {
                        self.formLoading = false
                        self.syncModal = false

                        self.$nextTick(() => {
                           self.$emit('success',response)
                            self.$ThatsNotify.success({
                                message : "Voucher issued successfully"
                            })
                        })
                        
                    }).catch((error) => {
                        self.formLoading = false
                        if (error.response) {

                               console.log("error.response.data")    
                                console.log(error.response.data.errors)   
                            if(error.response.data.hasOwnProperty('errors')){
                                self.$refs.dialogObserver.setErrors(error.response.data.errors);
                              
                                this.showError = true
                            }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }

                            
                       
                    })
                })
        }
        });


        
    },

    issueVoucher(){

    },
     processAmounts(data) {
        data.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount));
        for (let i = 1; i < data.length; i++) {
            data[i].amount = data[i - 1].amount;
        }

        return data;
    },

    parseVouchers(data){
        const self = this;
        data.forEach(item => item.amount = parseFloat(item.amount));
        data.sort((a, b) => a.amount - b.amount);
        for (let i = 0; i < data.length; i++) {
            if (i === data.length - 1) {
                data[i].max = 999999;
            } else {
                data[i].max = unformat( data[i + 1].amount);
            }
        }
        data.forEach(item => item.amount = unformat(item.amount));
        const filterVoucher = data.filter((item) => {
            return unformat(self.form.document.valid_amount) >= item.amount && unformat(self.form.document.valid_amount) < item.max
        },[]);

        console.log("filterVoucher")
        console.log(filterVoucher)
        self.vouchers = filterVoucher;
        if(self.vouchers.length > 0){
            self.form.voucher = self.vouchers[0]
        }
        
        return data;
    }
  
  },

  computed : {
    ...mapGetters('IssueVoucherModule',['loading','formData','initialFormObject']),

    filteredVourchers : function(){
        const self = this
        return this.vouchers.filter((item) => {
            return unformat(self.form.document.total) >= item.amount && self.form.document.total < item.max
        },[])
    },
    hasIssuedVoucher : function(){
        
        return this.form.hasOwnProperty('document') && this.form.document.hasOwnProperty('attr') && this.form.document.attr.hasOwnProperty('voucher') && this.form.document.attr.voucher !== null
    }
  },

  watch : {
      syncModal : function(value){
        const self = this;
        this.form = {...this.initialFormObject}
        if(value){
            this.getRecords({filters : {status : 'active'}}).then((response) => {
                const data = response.data
                self.parseVouchers(data)
            })
        }
      },

      formData : function(value){
          this.form = {...value}
      },

      vouchers : function(value){
        if(this.form.voucher === null && value.length > 0){
            this.parseVouchers(value)
        }
      }
  
  }
}
</script>