<template>
 
         <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
                <div class="md-form mt-0">
                    <ul class="thats-attr">
                        <li class="attr-item" v-for="(item,index) in items" :key="index">
                           <mdb-input class="mt-0 mb-2" size="sm" v-model="item.text" @input="createSlug(index)">
                                <span class="input-group-text md-addon cursor-pointer" slot="append" @click="removeAttr(item.index)"><mdb-icon icon="times"></mdb-icon></span>
                            </mdb-input>
                        </li>
                        <li class="attr-item">
                            <mdb-input type="text" class="mt-0 mb-2" size="sm" v-model="attributeText" @keyup.native.enter="addAttr">
                                <span class="input-group-text md-addon cursor-pointer" slot="append" @click="addAttr"><mdb-icon icon="plus"></mdb-icon></span>
                            </mdb-input>
                        </li>
                    </ul>                    
                </div>
            </div>
        </div>

</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline,mdbBtn,mdbIcon } from "mdbvue";

let nextTodoId = 1


export default{
    name : 'ThatsAttribute',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: Array
        },
        validated: {
            type: Boolean,
            default : 'true'
        },

        data: {
            type: Array
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        }

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbBtn,
        mdbIcon,
        mdbFormInline,
    },

    data : ()=>({
        items : [],
        attributeText : null,
    }),

    methods : {

        slugify(string) {
            const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
            const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
            const p = new RegExp(a.split('').join('|'), 'g')

            return string.toString().toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '') // Trim - from end of text
            },

        createSlug(index){
            //console.log(`${value} - ${index}`)
            const self = this;
            if(typeof self.items[index] !== 'undefined') {
                const text = self.items[index].text;
                //  const slug = text.trim().replace(/ /g,'-').replace(/[^\w-]+/g,'')
                self.items[index].slug = self.slugify(text)
               /*  console.log(slug)
                self.$set(self.item,'slug',slug) */
            }
        },
        addAttr () {
            const trimmedText = this.attributeText.trim()
			if (trimmedText) {
				this.items.push({
					index : nextTodoId++,
					text  : trimmedText,
					slug  : this.slugify(trimmedText),
				})
                this.attributeText = ''
            }
		},
		removeAttr (idToRemove) {
			this.items = this.items.filter(item => {
				return item.index !== idToRemove
            })
            /* .map(item => item.id).indexOf(idToRemove)
           if (itemIndex !== -1) this.items.splice(itemIndex,1); */
		}
    },

     watch: {
        items(newVal) {

            this.$emit("input", newVal);
        },
        value(newVal) {
        this.items = newVal;
          
        }
    },
    created() {
        const self = this
        if (this.value) {
        this.items = this.value;
        }
    }

   
}

</script>

<style lang="scss" scoped>
.thats-attr{
    padding : 0;margin : 0;
    li{
        list-style: none;display: flex;align-items: center;
    }
}
</style>
