import { ApiHttpService } from '../../services'

import router from '../../router'
export const LoyaltyMemberModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Card',
                    field: 'default_image',
                    sort: 'asc',
                    width: '100',
                },
                {
                    label: 'Card Number',
                    field: 'card_number',
                    sort: 'asc',
                    width : '200',

                },
                {
                    label: 'Tier',
                    field: 'tier',
                    sort: 'asc',
                    width : '200',

                },

                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                },

                {
                    label: 'Points',
                    field: 'points',
                    width: '250',
                },

                {

                    label: 'Status',
                    field: 'status',
                    width: '180',
                },
                ],
            rows: [],
        },
        cards : [],
        benefits : [],
        request: null,
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},
        initialFormData : {
            "id": null,
            "card_id": null,
            "customer_id": null,
            "card_number": null,
            "issued_date": null,
            "display_name": null,
            "points": 0,
            "status": "active",
            "customer": {
                "id": null,
                "series": null,
                "name": null,
                "mobile": null,
                "email": null,
                "code": null,
                "points": 0,
                "attr" : {
                    contacts:[
                        {
                            "type": "",
                            "intl": "",
                            "code": "",
                            "value": ""
                        }
                    ],
                    first_name : "",
                    last_name : "",
                    dob:"",
                    gender:"",
                    anniversary_date:"",
                    nationality:"",
                    qid:"",
                    marital_status:"",
                    title:"",
                    full_name:"",
                    images : []
                }
            },
            card: {
                "id": null,
                "series": null,
                card_number : null,
                display_name : null,
                expiration_date : null,
                "attr" : {
                    images : null
                }
            },
            tier: {
                "id": null,
                "series": null,
                name : null,
                expiration_date : null,
                points : 0,
                "attr" : {
                    images : null
                },
                tier: {
                    "id": null,
                    "series": null,
                    name : null,
                    expiration_date : null,
                    points : 0,
                    "attr" : {
                        images : null
                    }
                },
            },
            current_tier: {
                "id": null,
                "series": null,
                name : null,
                minimum : null,
                maximum : null,
                points : 0,
                status : null,
                "attr" : {
                    images : null
                }
            },
            next_tier: {
                "id": null,
                "series": null,
                name : null,
                minimum : 0,
                maximum : null,

                status : null,
                "attr" : {
                    images : null
                }
            }
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,
        deleteLoading : false,

        formStatus : 'create',

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setDeleteLoadingTrue(state) {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse(state) {
            state.deleteLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setCards: (state, payload) => {
            state.cards = payload
        },
        setTierBenefits: (state, payload) => {
            state.benefits = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {

                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "LoyaltyMemberModule/req",
                        loadingTrue : "LoyaltyMemberModule/loadingTrue",
                        addRequest : "LoyaltyMemberModule/addRequest",
                        cancelRequest : "LoyaltyMemberModule/cancelRequest",
                        clearOldRequest : "LoyaltyMemberModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        resolve(response.data.data)
                        commit('setLoading', false)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })

            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)
                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}
                    ApiHttpService.getRequest('loyalty-program/customer-loyalty-cards/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
        async getCustomerLoyaltyMembership({commit},series) {
            return await new Promise((resolve, reject) => {
                commit('setCards',[])
                ApiHttpService.getRequest('loyalty-program/customer-loyalty-cards/cards/'+series,{view : router.app._route.name}).then((response) => {
                    const cards = response.data.map((item) =>  item.card_number,[]);
                    commit('setCards',cards)
                    resolve(response.data)
                }).catch(error => {
                    commit('setCards',[])
                    reject(error)
                })
            })
        },

        async getBenefitsByCardId({commit},card_id) {
            return await new Promise((resolve, reject) => {
                commit('setTierBenefits',[])
                ApiHttpService.getRequest('loyalty-program/tier-beneficiary/tier-benefits/'+card_id,{view : router.app._route.name,origin : 'portal'}).then((response) => {
                    const benefits = response.data;
                    commit('setTierBenefits',benefits)
                    resolve(response.data)
                }).catch(error => {
                    commit('setTierBenefits',[])
                    reject(error)
                })
            })
        },


        async getRewardsTiers({}) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('loyalty-program/tiers',{view : router.app._route.name}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },


        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "LoyaltyMemberModule/req",
                        loadingTrue : "LoyaltyMemberModule/loadingTrue",
                        addRequest : "LoyaltyMemberModule/addRequest",
                        cancelRequest : "LoyaltyMemberModule/cancelRequest",
                        clearOldRequest : "LoyaltyMemberModule/clearOldRequest"
                    }
                    ApiHttpService.post('loyalty-program/customers',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`loyalty-program/customer-loyalty-cards`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async findLoyaltyMembers({ dispatch,commit },params) {
            return await new Promise((resolve) => {
                ApiHttpService.get('/members', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {

                            const constAttr = element.attr

                            if(!constAttr.hasOwnProperty('corporate')){
                                constAttr.corporate = false;
                            }

                            if(!constAttr.hasOwnProperty('contact_persons')){
                                constAttr.contact_persons = [];
                            }
                            const row = { ...element,label: element.name, value: element.mdbID, attr : constAttr }
                            res.push(row)
                        })
                    }
                    commit('setContacts',res)
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,

        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        deleteLoading: state => state.deleteLoading,
        cards: state => state.cards,
        benefits: state => state.benefits,

    }
}
//FIND Loyal Member

export const FindLoyaltyMemberModule = {
    namespaced: true,
    state: {
        request: null,
        loading : false
    },

    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        loadingTrue(state) {
            state.loading = true
        },
        loadingFalse(state) {
            state.loading = true
        }
    },

    actions : {

        async findRecord({ commit},params = {}) {
            return await new Promise((resolve, reject) => {
                    commit('loadingTrue')
                    const configParams = {
                        req : "FindLoyaltyMemberModule/req",
                        loadingTrue : "FindLoyaltyMemberModule/loadingTrue",
                        addRequest : "FindLoyaltyMemberModule/addRequest",
                        cancelRequest : "FindLoyaltyMemberModule/cancelRequest",
                        clearOldRequest : "FindLoyaltyMemberModule/clearOldRequest"
                    }

                    ApiHttpService.get(`loyalty-program/customers/find-members`, {...params,view : router.app._route.name}, configParams).then((response) => {
                        resolve(response.data)
                        commit('loadingFalse')
                    }).catch(error => {
                        commit('loadingFalse')
                        reject(error)
                    })

            });
        }
    },

    getters: {
        loading : state => state.loading,
        req: state => state.request,
    }

}
