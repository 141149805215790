<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="false" @open="openNewModal" @row-click="rowClick" :key="tableKey">
           <!--  <template #filter="{}">
                    <div>test</div>
            </template> -->

            <template #button-right>

               <!--  
                <template v-if="$helpers.isSystemAdministrator()">
                    <mdb-btn color="elegant" size="sm" @click="openLocationDialog"><i class="el-icon-plus"></i> Post Count</mdb-btn>
                </template>
               
               <mdb-dropdown multiLevel class="ml-1 barcode-dropdown-toggle" end>
                    <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">POST PHYSICAL COUNT</mdb-dropdown-toggle>
                    <mdb-dropdown-menu >
                 
                    <a class="dropdown-item" href="javascript:void(0)">...</a>

                    </mdb-dropdown-menu>
                </mdb-dropdown> -->
            </template>
            <template #custom-right-culumns="{}">
                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150" align="right">
                    <template slot-scope="scope">
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ statusArrayText[scope.row.status]}}</mdb-badge>
                    </template>
                </el-table-column>


            </template>



        </ThatsTable>
        <DocumentItem ref="documentItem" @success="refreshTable"></DocumentItem>

        <el-dialog title="POST DOCUMENT" :visible.sync="postDialog" :close-on-click-modal="false" width="20%" size="small" class="text-left filter-form" :show-close="!postLoading">
            <div class="filter-dalog-body p-4">
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Document" placeholder="Document" vid="series" name="series" v-model="document.series" size="sm" />
            </div>

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">
                    <template v-if="postLoading">
                    <mdb-btn color="elegant" size="sm" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                    </mdb-btn>
                </template>
                <template v-else>
                    <mdb-btn color="elegant" class="ml-0" size="sm" @click="handlePostForm"><i class="el-icon-save"></i> POST</mdb-btn>
                </template>
                </div>
            </div>

        </el-dialog>

        <LocationDialog :visible="locationDialog" @close="locationDialog = false" @select="onSelectLocation"></LocationDialog>
        <PostingQuantityDialog ref="countedQtyDialog"></PostingQuantityDialog>


    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn, mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsInput, LocationDialog} from '../../components/thats/Thats'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import DocumentItem from "./DocumentItem.vue";
import PostingQuantityDialog from "./PostingQuantityDialog.vue";
export default {
        components:{
        ThatsTable,
        mdbBadge,
        mdbBtn,DocumentItem,
        ThatsInput,
        LocationDialog,
        PostingQuantityDialog
    },


    data(){
        return {
            endpoint : 'documents',
            tableKey : 0,
            postDialog : false,
            postLoading : false,
            document : {
                series : null
            },
            locationDialog : false,
        }
    },


    created(){
        //
    },

    mounted(){
        const self = this;
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('InventoryPostingModule',{
        getItems : 'get',
       }),
       ...mapActions('PhysicalInventoryCountModule',['getRecord','cancelInventoryTransferRecord','postDocument']),
       ...mapMutations('PhysicalInventoryCountModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),

        rowClick(row){
            const self = this;
            self.receiveInventoryTransfer(row,'view')
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.documentItem.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()

        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      cancelRecord(row){
        const self = this;
        MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.id).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                    self.refreshTable();
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log(error.response.data.message)
                })

              })
      },

      receiveInventoryTransfer(row, action){
            const inventoryTransferData = JSON.parse(JSON.stringify(row))

            const mergeFormData = {...this.initialFormObject,...inventoryTransferData, action : action}

            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });
                self.setFormStatusUpdate();
                this.getRecord(mergeFormData).then((res) => {

                    console.log("getRecord response")
                    console.log(res)
                    loading.close();
                    self.$refs.documentItem.openDialog();
                }).catch(() => {
                    loading.close();
                })


            console.log(mergeFormData)

        },
        handlePostForm(){
            const self = this;
            
            if(self.postLoading == false){
                self.postLoading = true;
                self.postDocument(self.document).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Document successfully posted."
                    })
                    self.document.series = null;
                    self.refreshTable();
                    self.postDialog = false;
                }).catch((error) => {
                    self.document.series = null;
                    self.postLoading = false;
                    if(error.response.data.hasOwnProperty('message')){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                    console.log(error.response.data.message)
                })
            }
        },

        openLocationDialog(){
            this.locationDialog = true;
        },

        onSelectLocation(value){
            this.getItems({...value})
            this.$refs.countedQtyDialog.openDialog()
        }


    },
    computed: {
        ...mapGetters('PhysicalInventoryCountModule',['getLoading', 'columns','initialFormObject']),

        statusArrayText : function(){
            return {'draft' : 'Draft','open' : 'Open','closed': 'Closed','cancelled' :'Cancelled'};
        },
        statusArrayColor : function(){
            return {'draft' : 'badge-light','open' : 'success','closed' : 'badge-light','cancelled': 'danger'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','open' : 'el-icon-circle-check','cancelled' : 'el-icon-remove','closed': 'el-icon-success'};
        },
        
    },
}


</script>
