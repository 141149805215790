<template>
  <div class="itmform-container">
      <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Sales Order" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="900" :formStatus="FormStatus" @dialog-state="dialogState" :group="2">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
              </template>

            <mdbContainer class="mt-3">
                <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Name" :placeholder="form.name" vid="name" name="name" v-model="form.name" size="sm"/>
                <mdb-row>
                  <mdb-col col="10" offset="2">
                    <template v-if="form.permissions.length > 0">
                      <ul class="modules mt-3">
                          <li v-for="(permission,index) in permissions" :key="permission.value">
                            <p class="mb-1 mt-1">{{permission.label}}</p>
                            <el-checkbox-group 
                                v-model="form.permissions[index].permission">
                                 <el-checkbox v-for="permissionArraySub in permission.permission" :label="permissionArraySub" :key="permissionArraySub">{{permissionArraySub}}</el-checkbox>
                              </el-checkbox-group>
                          </li>
                      </ul>
                    </template>

                     <!--  <ul>
                          <li v-for="(permission,index) in form.permissions" :key="permission.value">
                            {{permission.permission.view}}
                            <el-checkbox v-model="form.permissions[index].permission.view">{{permission.label}}</el-checkbox>

                          </li>
                      </ul> -->
                  </mdb-col>
                </mdb-row>
              <!--   {{form.permissions}} -->
            </mdbContainer>
          </ThatsWindow>
        </ValidationObserver>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsBtn, ThatsCheckbox} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import { required, confirmed, length, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
extend("checkboxRequired", {
    ...required,
    message: "Permission field is required"
});


export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsCheckbox,  
        

  },
    
    data : () => ({
      active : 0,
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      permissions : []
    }),

  created : function() {
      var self = this;
      const modules = this.$helpers.getModules();
      if(modules.length > 0){
        this.permissions = modules;
      console.log(modules);
      }

       if(this.FormStatus == 'create'){
        this.form = this.formVar();
        
      }

       this.optionsHandler();
    },



    methods: {
      ...mapActions('RoleModule',[
        'StoreRecord',
        'UpdateRecord',
        'updateStatus',
        
      ]),
      ...mapMutations('RoleModule',[
        'SetFormStatusCreate',
        'SetFormRecord',
        'SetFormStatusUpdate',
      ]),

      formVar(){
        return {
          name : "",
          permissions : [],
        }
      },

     
/* start */

optionsHandler(){
  // this.getPurchaseOrder()
  const self = this;
  
},
 openDialog(){
        this.$refs.window.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();
              
            }

      },
      nextCode(){
        var self = this;
       
      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
              if(self.formLoading === false){
                self.formLoading = true
                // alert(self.FormStatus)                
                if(self.FormStatus == 'create'){
                    self.StoreRecord(self.form).then((response) => {
                        self.$ThatsNotify.success()
                        self.$emit('success')


                        self.SetFormStatusUpdate()
                        self.SetFormRecord(response.data)
                        self.formLoading = false
                      }).catch((error) => {
                        self.formLoading = false
                        self.response.error = 1;
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){
                              self.$refs.observer.setErrors(error.response.data.errors);
                              this.showError = true
                            }
                          } else if (error.request) {
                              self.response.message = error.request
                          } else {
                              self.response.message = error.message
                          }
                          return;
                      })
                }

                if(self.FormStatus == 'update'){
                    self.UpdateRecord(self.form).then(() => {
                        self.$ThatsNotify.success({
                          message : "Record was successfully updated"
                        })
                          // self.$ThatsNotify.success()
                        self.$emit('success')
                          // self.FormResetter();

                          self.SetFormRecord(response.data)
                          self.formLoading = false

                      }).catch((error) => {
                        self.formLoading = false
                        self.response.error = 1;
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){
                              self.$refs.observer.setErrors(error.response.data.errors);
                              this.showError = true
                            }
                          } else if (error.request) {
                              self.response.message = error.request
                          } else {
                              self.response.message = error.message
                          }
                          return;
                      })
                }
              }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        // this.form.permissions = this.permissions
        
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
          this.form.permissions = this.$helpers.getModules();
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

    },

    computed: {

      ...mapGetters('RoleModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      ...mapGetters('AuthModule',[ 'user', ]),
    },

    watch: {
      FormRecord : function(v){
        this.active = 0;
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord));
          // this.form.permissions = [...JSON.parse(this.form.permissions),...this.permissions];
          // this.form.permissions = [...this.permissionArrayChecked,...this.permissionArrayCheckedTwo];
          // frmRecord.permissions = JSON.parse(JSON.stringify(frmRecord.permissions));
          this.formOriginal = JSON.parse(JSON.stringify(this.FormRecord));
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
          this.form.permissions = this.$helpers.getModules();
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.modules{
  padding : 0;
  margin : 0;
}

.modules li{
  list-style: none;text-transform: uppercase;font-size: 12px;font-weight: bold;
}

</style>