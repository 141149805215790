import { furnishwebHttpService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
import router from '../../router/index-furnishweb'
export const UserModule = {
    namespaced: true,
    state : {
        endpoint : 'user',
    },
    actions : {

        async checkToken({ dispatch },token) {
            return await new Promise((resolve) => {
                furnishwebHttpService.getToken(token).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async authToken({ dispatch },token) {
            return await new Promise((resolve) => {
                furnishwebHttpService.getToken(token).then((response) => {
                    resolve(response)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
    },
   
}


