
import store from '../../store/furnishweb'

console.log("store")
console.log(store)
export default function auth({ next, router, to }) {
  const currentRouteName = to.name;
  console.log('Current route name auth:', currentRouteName);

  let user = JSON.parse(localStorage.getItem('user'));
  if (user === null || (user.hasOwnProperty('location') && user.location === null)) {
    return store.dispatch('AuthModule/Logout', null, { root: true });

  }
  store.commit('AppModule/setLayout','authenticated');
  return next();
}