<template>
    <ValidationObserver class="needs-validation form-custom-format filter-form" tag="form" ref="observer" @submit.prevent="onSubmitForm()" v-slot="{passes}">
            <mdbContainer>
              <label class="filter-label">Filter</label>
                <mdb-row >
                  <mdb-col col="12">
                      <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="form.series" size="sm" />
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Name" placeholder="Item Name" vid="name" name="name" v-model="form.name" size="sm" />
                      
                  </mdb-col> 
                </mdb-row>

               
                <mdb-row>
                    <mdb-col col="3">
                        <label>Price Range</label>
                    </mdb-col>
                    <mdb-col col="9">
                      <div class="row">
                        <div class="col-md-5 pl-3">
                          <ThatsNumber tabindex="1" labelClass="" fieldClass="col-sm-12 pl-0" vid="rangeone" name="rangeone" v-model="form.range[0]" size="sm" />
                        </div>  
                        <div class="col-md-2">~</div>
                        <div class="col-md-5">
                          <ThatsNumber tabindex="1" labelClass="" fieldClass="col-sm-12 pl-0" vid="rangeone" name="rangeone" v-model="form.range[1]" size="sm" />
                        </div>  
                      </div>

                      <mdb-row>
                          <mdb-col col="12 pr-4">
                            <vue-slider class="mt-1 mb-1" v-bind="slider" v-model="form.range" ></vue-slider>
                          </mdb-col>
                      </mdb-row>

                    </mdb-col>
                </mdb-row>

                

                <mdb-row >
                  <mdb-col col="12">
                    <ThatsMultiSelect  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Collections" v-model="form.collections" :reduce="label => label.value" :reduceLabel="'label'" :options="filterCollections" ></ThatsMultiSelect>
                  </mdb-col>
                </mdb-row>

                <mdb-row class="mt-1 mb-1">
                    <mdb-col col="3">
                    </mdb-col>
                    <mdb-col col="9">
                     <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Search</mdb-btn>
                <mdb-btn color="elegant" size="sm" @click="clearForm">Clear Filter</mdb-btn>
                    </mdb-col>
                </mdb-row>


                
              </mdbContainer>


          </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { ThatsNumber,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsCheckbox, ThatsMultiSelect} from '../../components/thats/Thats'
import {mapActions} from 'vuex';
import VueSlider from 'vue-slider-component'
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        ValidationObserver,
        ThatsInput,  ThatsNumber,
        ThatsSeriesInput,
        ThatsFieldset,
        ThatsCheckbox,
        ThatsMultiSelect,
        VueSlider,
  },
    
    data : () => ({
      form : {
          series : "",
          name : "",
          colour : "",
          range : [0,99999],
          area : [],
          brands : [],
          category : [],
          collections : [],
      },

      area : [],
      brands : [],
      category : [],
      collections : [],
      tooltipFormatter : v => `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
      slider:{
              width: '100%',
              min: 0, 
              max: 99999,
              useKeyboard: true,
              enableCross: true,
              tooltipPlacement :'bottom',
              tooltipFormatter : (v) => {
                return `${('' + v).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
              }     
      }

    }),
    mounted(){
      const self = this;
    
      self.GetCollection().then(response => {
           /*  const collection = [];
            response.forEach(element => {
              collection.push({collection : element.label,code : element.value})
            }); */
            self.collections = response
          });
      self.form = this.formVar();
    },
    methods: {      
      ...mapActions('FabricModule',[ 'GetCollection']),
        onSubmitForm(){
            this.$emit('filter',this.form)
        },

        formVar(){
          return {
                series : "",
                name : "",
                range : [0,99999],
                collections : [],
            }
        },

        clearForm(){
          this.form = this.formVar()
        }

    },
    computed : {
      filterCollections : function() {
        const response = [];
        if(this.collections.length > 0){
          this.collections.forEach((el, index) => {
            response.push({label : el.name,value : el.mdbID})  
          })
        }
        return response;
          
      },
    }



}
</script>
