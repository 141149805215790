<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="false" @open="openNewModal" @row-click="rowClick" :key="tableKey">
           <!--  <template #filter="{}">
                    <div>test</div>
            </template> -->

            <template #custom-right-culumns="{}">






                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150">
                    <template slot-scope="scope">
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ statusArrayText[scope.row.status]}}</mdb-badge>
                    </template>
                </el-table-column>


            </template>



        </ThatsTable>
        <DocumentClosedItem ref="bringToShowroomForm" @success="refreshTable"></DocumentClosedItem>
        <IntegrationWindow ref="integrationWindow"></IntegrationWindow>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import IntegrationWindow from '../../components/thats/IntegrationWindow'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import DocumentClosedItem from "./DocumentClosedItem.vue";
export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,DocumentClosedItem,
        mdbSelect,
        mdbInput,
        IntegrationWindow
    },


    data(){
        return {
            endpoint : 'posted-inventory',
            approver : [],
            tableKey : 0
        }
    },


    created(){
    
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('PostedInventoryModule',['getRecord','cancelInventoryTransferRecord']),
       ...mapMutations('PostedInventoryModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),

        rowClick(row){
            const self = this;

            const inventoryTransferData = JSON.parse(JSON.stringify(row))
            const mergeFormData = {...this.initialFormObject,...inventoryTransferData, action : 'view'}
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });
                self.setFormStatusUpdate();
                this.getRecord(mergeFormData).then((res) => {

                    console.log("getRecord response")
                    console.log(res)
                    loading.close();
                    self.$refs.bringToShowroomForm.openDialog();
                })
          
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.bringToShowroomForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()

        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      cancelRecord(row){
        const self = this;
        MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.id).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                    self.refreshTable();
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log(error.response.data.message)
                })

              })
      },


    },
    computed: {
        ...mapGetters('PostedInventoryModule',['getLoading', 'columns','initialFormObject']),
        statusArrayText : function(){
            return {'draft' : 'Draft','open' : 'Open','closed': 'Closed','cancelled' :'Cancelled'};
        },
        statusArrayColor : function(){
            return {'draft' : 'badge-light','open' : 'success','closed' : 'badge-light','cancelled': 'danger'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','open' : 'el-icon-circle-check','cancelled' : 'el-icon-remove','closed': 'el-icon-success'};
        },
    },
}


</script>
