
<template>
 <ValidationObserver class="needs-validation form-custom-format" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
    <div class="customervendor-container" style="padding:15px">
      <mdb-row>
        <mdb-col col="4">
          <ThatsInput :show_error="true"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Old Code" vid="oldseries" name="oldseries" v-model="form.oldseries" :rules="'required'" size="sm" />
          <ThatsInput :show_error="true"  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="New Code" vid="series" name="series" v-model="form.series" :rules="'required'" size="sm" />
          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Submit</mdb-btn>
        </mdb-col>
      </mdb-row>
    </div>


    </ValidationObserver>
</template>
<script>
import { mdbBtn, mdbRow,mdbCol} from "mdbvue";
import { ThatsInput } from '../../components/thats/Thats'
import { ValidationObserver } from "vee-validate";
import {mapActions} from 'vuex'
export default {
    components: {
       mdbBtn,
       mdbRow,mdbCol,
       ThatsInput,
       ValidationObserver
    },
    data(){
        return {
            endpoint : 'category/data',
            area : [],
            form : {
              series : "",
              oldseries : ""
            }
        }
    },
    
    created(){
      

    },
    methods: {
        ...mapActions('CodeChangeModule',['StoreRecord']),
       onSubmitForm(){
         const self = this;
          this.StoreRecord(this.form).catch((error) => {
                if (error.response) {
                    if (error.response.data.hasOwnProperty('errors')) {
                        self.$refs.observer.setErrors(error.response.data.errors);
                    }
                }
                return;
            })
       }
    },
    computed: {
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .table-row-input{
      width : 100%;
  }
</style>