import { ApiService, ApiV1Service} from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ReportByBrandModule = {
    namespaced: true,
    state: {
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Brand',
                    field: 'brand',
                    align: 'left',
                    width: '250',
                },
                {
                    label: 'Date',
                    field: 'date_human',
                },
                {
                    label: 'Tracking No.',
                    field: 'series',
                    /* clickable : true */
                },
                {
                    label: 'Total Sales',
                    field: 'total',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'Total Collection',
                    field: 'paid',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'Cash',
                    field: 'payment_cash',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'CC/DC',
                    field: 'payment_credit_debit_card',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'Chk/Bank Transfer',
                    field: 'payment_cheque_wire_transfer',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'Credit note',
                    field: 'payment_credit_note',
                    amount: true,
                    align: 'right',
                },
                {
                    label: 'Expected Delivery Date',
                    field: 'delivery_date',
                    width: '250',
                },
                {
                    label: 'Sales Staff',
                    field: 'sold_by',
                    width: '150',
                },
            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        tabIndex: 0,
        formRecord: {},

    
    },
    actions: {
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('/delivery/filter', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('delivery/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getRecord({ rootState, commit }, params) {
            const { viewLoading } = rootState.DeliveryModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`delivery/item/${params.token_id}`, params).then((response) => {
                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getPullOutRecord({ rootState, commit }, params) {
            const { viewLoading } = rootState.DeliveryModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`delivery/pull-out/${params.token_id}`, params).then((response) => {
                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        // Unauthorized(dispatch, error)
                        console.log(error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = rootState.DeliveryModule
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiV1Service.get('delivery/data', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.DeliveryModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('delivery',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ rootState, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = rootState.DeliveryModule.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update(`delivery/${payload.action}`,payload).then((response) => {
                        commit('setPutLoadingFalse')
                        commit('setFormRecord', response.data.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.DeliveryModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('delivery',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async deleteDeliveryNoteRecord({ rootState, commit }, obj) {
            return await new Promise((resolve, reject) => {
                const loading = rootState.DeliveryModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    obj.rows.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete(`delivery-note/${obj.series}`,tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async cancelDeliveryNoteRecord({ rootState, commit }, series) {
            return await new Promise((resolve, reject) => {
                const loading = rootState.DeliveryModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    ApiService.update(`delivery-note/cancel/${series}`).then((response) => {
                        commit('setDeleteLoadingFalse')
                        commit('setPutLoadingFalse')
                        commit('setLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        commit('setPutLoadingFalse')
                        commit('setLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.DeliveryModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('delivery/save-import',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setTabIndex: (state, payload) => {
            state.tabIndex = payload
        },

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        tabIndex: state => state.tabIndex,
    }
}


