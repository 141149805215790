
export function userData() {
    c
    if (user) {
        return user;
    } else {
        return {};
    }
}
export function authorization() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'Authorization': user.token };
    } else {
        return {};
    }
}

export function token() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'token': user.token };
    } else {
        return {};
    }
}

export function bearer() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}

export function CSRFToken() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.token) {
        return { 'X-CSRFToken': user.token };
    } else {
        return {};
    }
}

export function tokenString() {

    // return document.cookie('erpthatsliving_session')
    return { token: window.Laravel.csrfToken}
    
    // return this.$cookie.get('erpthatsliving_session')
}
export function tokenBearer() {

    // return document.cookie('erpthatsliving_session')
    return { 'Authorization': 'Bearer ' + window.Laravel.csrfToken };
    
    // return this.$cookie.get('erpthatsliving_session')
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}


function getCookiex(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
