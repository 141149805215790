import { ApiHttpService,ApiGetService } from '../../services'

import router from '../../router'
export const ItemMasterModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Series',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width: '150'
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                },
                {
                    label: 'Category',
                    field: 'category_name',
                    sort: 'asc',
                    width: '250'
                },

                {
                    label: 'Dimension',
                    field: 'dimension',
                    sort: 'asc',
                    width: '250'
                },

                {
                    label: 'Price',
                    field: 'sale_price',
                    sort: 'asc',
                    width: '150',
                    align : 'right'
                },
              
                ],
            rows: [],
        },
        request: null,
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},


        initialFormData : {
            item_code : null,
            parent : 0,
            name : null,
            slug : null,
            sale_price : 0,
            promo_price : 0,
            InStock : 0,
            Committed : 0,
            InStock : 0,
            Ordered : 0,
            Available : 0,
            dimension : null,
  
            brand : null,
            area : null,
            category : null,
            collection : null,
  
            item_type : 'stock',
            description : "",
            website : false,
            sell : false,
            attr : {
                cube_unit : "",
                attribute : "",
                post_views : 0,
                materials : "",
                materials1 : "",
                fabric : "",
                finishes : "",
                sub_colour : "",
                colour : "",
                colour1 : "",
                colour2 : "",
  
                future_stock : {
                  qty : 0,
                  eta_text : "",
                  eta : []
                },
  
                dimension_height : "",
                dimension_width : "",
                dimension_depth : "",
                dimension_diameter : "",
                hole : "",
                capacity : "",
                weight : "",
                meta_title : "",
                meta_keyword : "",
                meta_description : "",
                visible_polish : false,
                barcode : "",
                old_code : "",
                discount : {
                  percent : 0,
                  value : 0,
                  remarks : ""
                },
                collection : {
                  en : "",
                  ar : ""
                }
            },
  
            child: [],
            order_eta: [],
  
            image: [],
            images: [],
            location: [],
            warehouses: [],
  
            qty : 0,
            status : '1',
            InStock : 0,
            Ordered : 0,
            Committed : 0,
            Available : 0
            
          },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',
        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            console.log("addRequest")
            console.log(state)
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){

        
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
           
            const { length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {
            
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "ItemMasterModule/req",
                        loadingTrue : "ItemMasterModule/loadingTrue",
                        addRequest : "ItemMasterModule/addRequest",
                        cancelRequest : "ItemMasterModule/cancelRequest",
                        clearOldRequest : "ItemMasterModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },

        async getItem({},obj) {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('item-master/'+obj.item,{view : router.app._route.name}).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },



        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}
 
                    ApiHttpService.getRequest('inventory-transfer-request/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
        
        
        async getReceiver() {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('inventory-transfer-request/receiver',{view : router.app._route.name}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    commit('setGetloadingFalse', false)
                    reject(error)
                })
            })
        },     
        
      
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "ItemMasterModule/req",
                        loadingTrue : "ItemMasterModule/setPostLoadingTrue",
                        addRequest : "ItemMasterModule/addRequest",
                        cancelRequest : "ItemMasterModule/cancelRequest",
                        clearOldRequest : "ItemMasterModule/clearOldRequest"
                    }
                    ApiHttpService.post('item-master/raw-materials',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setFormData',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`item-master/raw-materials/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        getNewItemDataObject: state => state.newItemDataObject,
    }
}



