<template>
  <div class="itmform-container">

    <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
        <li  @click="insertRow">Insert</li>
        <li @click="deleteRow">Delete</li>
    </ul>

          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="BILL OF MATERIAL" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState">
            <template #left-button>
                <template v-if="formStatus === 'update' && formOriginal.status_index === 1">
                  <ThatsBtn :loading="sendLoading" :disabled="sendLoading" :icon="'paper-plane'" :show="'icon'" @click="sendToGeneralManagerClick" :title="'Send to General Manager'"></ThatsBtn>
                </template>

                <template v-if="formLoading || viewLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="(((form.series !== null && form.items.length > 1) ? false : true))">Save</mdb-btn>
                 
                </template>

            </template>

        
            <mdbContainer class="mt-2">
       


                <mdb-row >
                  <mdb-col col="6">
                    <ThatsCustomMultiSelect :multiple="false" :fetchLoading="searchItemLoading" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="ITEM CODE" v-model="form.series" :reduce="label => label.series" :reduceLabel="'label'" :options="items" :rules="'required'" :clearable="false" @select="getItemSelected($event)" :addButton="false" @onSearch="searchItem" :selected_option="true"></ThatsCustomMultiSelect>
                    <ThatsInput labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="ITEM Name" v-model="form.name" :rules="'required'" :disabled="true"></ThatsInput>
                  </mdb-col>    
                  
                </mdb-row>
                
                <el-table border :data="formITems" class="input-table mt-2" style="width: 100%" :height="'500px'" :key="tblKey" @row-contextmenu="rowContextMenu">
                  <el-table-column type="index" width="50" label="#"> </el-table-column>
                  <el-table-column label="Item Code" :width="200">
                    <template slot-scope="scope">
<!-- :disabled="(form.series === null || form.series === '' ? true : false)"  -->
                        <v-select class="grid-select" :multiple="false" :clearable="false" ref="vselect" v-model="scope.row.series" append-to-body  :reduce="label => label.series" :reduceLabel="'label'" :options="itemParts" push-tags taggable :createOption="createOption" @input="getItemPartSelected($event,scope.$index)" @search="searchItemPart" @search:focus="statusKeyUpFocus">
                          <template v-slot:option="option">
                              {{ option.series}} - {{ option.label }}
                          </template>
                          <template #no-options>
                              No matching keyword found. 
                          </template>
                        
                      </v-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Item Description" >
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.description"/>
                    </template>
                  </el-table-column>                  
                  <el-table-column label="Qty/Conf." :width="100">
                    <template slot-scope="scope">
                      <input type="number" class="form-control grid-input thats-form-control" v-model="scope.row.qty" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Remarks" >
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.attr.remarks" />
                    </template>
                  </el-table-column>                  
                </el-table>

               
              </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>
    
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomMultiSelect
  },
    
    data : () => ({
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      status : [
            { status : "Draft", code : "draft"},
            { status : "For Approval", code : "for-approval"},
      ],
      items : [],
      itemParts : [],
      selected : [],
      vendors : [],
      options : [{
        label : "lorem",
        series : "lorem asdfsf"
      }],
      tblKey : 0,

      context: null,
      viewMenu: false,
      top: '0px',
      left: '0px',
      searchItemLoading : false

    }),

    created : function() {
      var self = this;

      if(this.formStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
        

      }
       this.optionsHandler();

      //  this.isGeneralManager();
    },



    methods: {
      
      // ...mapActions('VendorsModule',[ 'GetVendors' ]),
      ...mapActions('ItemFilterModule',{
        filterItem : 'filterItem'
      }),

      ...mapActions('BillOfMaterialModule',[
        'storeRecord',
        'UpdateRecord',
        'filterItems',
        'updateStatus',
        'sendToGeneralManager',
      ]),
      ...mapMutations('BillOfMaterialModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
          series : null,
          name : null,
          items : [
            {
              series: null,
              description: null,
              qty: 0,
              attr: { remarks : null}
            },
            
          ]
        }
      },
      formItemVar(){
        return {
              series: null,
              description: null,
              qty: 0,
              attr: { remraks : null}
            }
      },
/* start */
optionsHandler(){

},


deleteItem(){
/*     var myArr = [{id:'a'},{id:'myid'},{id:'c'}];
var index = arr.findIndex(function(o){
     return o.id === 'myid';
})
if (index !== -1) myArr.splice(index, 1); */

 

if(this.selected.length){
    if(this.formStatus === 'update'){
        MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {
              this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          series: null,
                          description: null,
                          qty: null,
                          attr: { remarks : null}
                        }
                      )
              }
          })
    }else{
      this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          series: null,
                          description: null,
                          qty: null,
                          attr: { remarks : null}
                        }
                      )
              }
    }
  

  
}


// remove object


},

handleSelectionChange(v){
  this.selected = v
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              this.form.items.push(
                    {
                      series: null,
                      description: null,
                      qty: 1,
                      attr: { remarks : null}
                    }
                  )
            }
    }

},

getItemPartSelected(v,index){
  const getSelected = this.itemParts.filter(item => { 
        return (item.series === v)
    })[0];

//    console.log(getSelected)

    if(typeof getSelected !== 'undefined'){
      // alert(1)
      this.$set(this.form.items[index], 'series', getSelected.series)
      this.$set(this.form.items[index], 'description', getSelected.label)
      this.$set(this.form.items[index], 'qty', 1)
      this.$set(this.form.items[index], 'attr', {remarks : null})
      // this.addNewRow(index)
      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            this.form.items.push(
                        {
                          series: null,
                          description: null,
                          qty: 1,
                          attr: { remarks : null}
                        }
                      )
        }
    }else{
      
       this.$set(this.form.items[index], 'series', v)
       this.$set(this.form.items[index], 'description', v)
      this.$set(this.form.items[index], 'qty', 1)
      this.$delete(this.form.items[index],'part_id')
      var dataLength = this.form.items.length - 1;
      if(dataLength === index){
            this.form.items.push(
                        {
                          series: null,
                          description: null,
                          qty: 1,
                          attr: { remarks : null}
                        }
                      )
        }
    }

    this.tblKey++
},



statusKeyUp(v){
// this.getPurchaseOrder(v)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();
              
            }

      },
      nextCode(){
        var self = this;

      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true

                      self.storeRecord({...self.form,items : (self.formITems.filter((item) => { return item.series !== null}))}).then(() => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })

                      /* if(self.formStatus == 'create'){
                          
                      }

                      if(self.formStatus == 'update'){
                          self.UpdateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success')
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      } */
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.items = [];
        this.itemParts = [];
      },
      
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }

        

      },
      emitDelete(){
        this.$emit('delete',this.formRecord)
      },

      fnPrintDoc(url){
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`https://docs.thatsliving.com/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
      },
      searchItem(v){
        const self = this;

        // if(v !== null && v !== ''){
          self.searchItemLoading = true
          this.filterItem({term : v })
              .then((res) => {
                // if(v !== null && v !== ''){
                self.items = res.map((item) => {
                  return {...item,code : item.series,label : item.series +" "+item.name,name : item.name,raw_name : item.name}
                  })

                  self.searchItemLoading = false
                // }
              }).catch((err) => {
                console.log(err)
              })
            // }
      },
      getItemSelected(v){
            // console.log(v)
            const getItem = this.items.filter((item) => {
                return item.series === v
            })[0]
            if(getItem !== null){

              const bomItems = getItem.bom.map((item) => {
                  return {...item,label : item.series}
              })
              const itemData = [...bomItems];
              itemData.push(this.formItemVar())
              this.form = {...getItem, items : itemData}
            }
            this.tblKey++;
      },

      statusKeyUpFocus(){
          const localItems = this.itemParts;
          const filterOnFocus = [];
          if(localItems.length > 0){
            localItems.forEach(item => {
              if(this.itemPartSeries.indexOf(item.code) < 0){
                filterOnFocus.push(item)
              }
            });

            this.itemParts = filterOnFocus
          }

      },
      statusKeyUp(){

      },
      getSelected(){

      },
     createOption(newOption){
          return {code: newOption,series : newOption, label : newOption, attr: { remraks : null}}
      },

      searchItemPart(v){
          console.log(`--search ${v}`)
      },

      rowContextMenu(row,_col,e){
        console.log(row)
        console.log(_col)
        console.log(e)
        const self = this
        
       this.context = row
            this.viewMenu = true;
              this.$nextTick(function() {
                self.$refs.right.focus();
                self.setMenu(e.y, e.x)
              }.bind(this));
              e.preventDefault();
      },

      setMenu(top, left) {
        
        const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu() {
            this.viewMenu = false;
            this.context = null
        },

        deleteRow(){
          const self = this
            if(this.context != null){
              this.form.items.splice(this.context.$index,1)
/* 
              if(self.context.hasOwnProperty('$index')){
                   const matchItem = this.form.items.filter((i) => {
                    return i.bom_id != self.context.bom_id;
                  })
                  self.form.items = matchItem
              } */

              this.closeMenu()
            }

        },
      insertRow(){
            const self = this
            if(this.context != null){
              if(self.context.hasOwnProperty('$index')){
                // const index = this.form.items.findIndex(e => e.$index == self.context.$index);
                const formItemVar = this.formItemVar();
                this.form.items.splice((parseInt(self.context.$index) + 1), 0, formItemVar);
              }
              this.closeMenu()
            }


        },

    },

    computed: {

      ...mapGetters('BillOfMaterialModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

    itemPartSeries : function(){
          var self = this;
          let filteredSeries =  self.form.items.filter((item) => {
                  return (item.series !== "" && item.series !== null)
              }).map(function(key){
                    return key.series
              })

          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          if(self.form.series === null){
            return true
          }
          return true;
      },

      formITems : function(){
        return this.form.items.map((item, index) => {
            return {...item,$index : index}
        })
      }
    },

    watch: {
      formRecord : function(v){
        console.log("watch")
        console.log(v)
        if(this.formStatus == 'update'){
           this.form = JSON.parse(JSON.stringify(v));
          this.formOriginal = JSON.parse(JSON.stringify(v));
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>