
<template>
    <div class="module-view-container">
        <ThatsTable title="App Cms" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="false" @import="importProduct">

             <template #custom-culumns="{columns, getValue, handleClick}">
                        <template v-for="col in columns">
                            <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                                <template slot-scope="scope">
                                    <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                        <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row.page_title}`"></a>
                                    </template>
                                  <template v-else-if="col.field === 'default_image'">
                                      <img :src="scope.row.images" :alt="scope.row.images" class="img-thumbnail" style="max-width: 100px; max-height: 100px;">
                                  </template>
<!--                                  <template v-else-if="col.field === 'code'">-->
<!--                                    {{scope.row.coupon.code}}-->
<!--                                  </template>-->
<!--                                  <template v-else-if="col.field === 'coupon_amount'">-->
<!--                                    {{scope.row.coupon.amount}}-->
<!--                                  </template>-->
                                    <template v-else>
                                        {{getValue(scope,col.field)}}
                                        <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>

                                    </template>
                                </template>

                            </el-table-column>
                        </template>

             </template>
        </ThatsTable>
        <AppCmsForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></AppCmsForm>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>



    </div>
</template>
<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import {mdbTabs, mdbBtn} from 'mdbvue'
import ThatsTable from '../../components/thats/Table'
import TableError from '../../components/filemanager/TableError'
import AppCmsForm from "./AppCmsForm.vue";
export default {
    data(){
        return {
            endpoint : 'app-cms',
            importData : {},
            errorData : {},
            importLoading : false,
            group1 : ["name"],
            group2 : ['email', 'role',],
            galleryData : [],
            active : 0,
            signature : '',
        }
    },
    components: {
        AppCmsForm,
        ThatsTable,
        TableError,
        mdbTabs,
        mdbBtn

    },

    methods: {
        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapActions('AddCmsModule',[ 'DeleteRecord','saveImport' ]),

        ...mapMutations('AddCmsModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetPage']),
        checkGroup1(column){
           return this.group1.includes(column)
        },
        checkGroup2(column){
           return this.group2.includes(column)
        },
        viewImage(index,row){
            this.galleryData = [];
            if(row.images.length){
                row.images.forEach(element => {
                    this.galleryData.push({
                        img: true,
                        src: element.large,
                        alt: element.name,
                        caption: {
                        title: element.name
                        }
                    })
                });

                this.$refs.galleryWindow.openDialog()
            }

        },

        viewEtaHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        viewSoldToHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
          console.log(obj, 'Swaraj Bhuyan')
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)

           this.$refs.tableImport.openDialog()
        },


        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }

        },

        getActiveTabIndex(index){

            const tabs = [1,2,3,0,4,5]
            this.endpoint = 'wallpaper/data?status='+tabs[index];
            this.SetPage(1)
            this.resetData('wallpaper/data?status='+tabs[index]);

        },



        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            },
        openBulkReset(){
            this.$refs.bulkReset.openDialog();
        }

    },
    computed: {
        ...mapGetters('AddCmsModule',[ 'data', 'page', 'total', 'page', 'length','columns', 'Loading', ])
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>
