<template>
    <div class="customervendor-container">
        <ThatsTable title="User Authorization" :multipleSelection="checkSystemSupport" :endpoint="endpoint" @openModal="OpenFormDialog" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns">


            <template #custom-culumns="{columns, getValue, handleClick}">
                <template v-for="col in columns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>

                            </template>
                        </template>

                    </el-table-column>
                </template>

                <el-table-column label="Digital Signature">
                    <template slot-scope="scope">
                        {{scope.row.id}}
                    </template>
                </el-table-column>



            </template>

        </ThatsTable>
    </div>
</template>
<script>

import ThatsTable from "../../components/thats/Table.vue";

export default {
    components: {
        ThatsTable
    },
    data(){
        return {
            //
        }
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
}
.placement {
    position: absolute;
    right: 0;
}
</style>
