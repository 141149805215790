import { ApiHttpService } from '../../services'
export const SapWarehouseModule = {
    namespaced: true,
    state: {
        data: {},
        loading : false,
        request : null,
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        
    },
    actions : {

        async getWarehouses({ commit}) {

            return await new Promise((resolve, reject) => {
        
                    commit('setLoading', true)


                    const configParams = {
                        req : "SapWarehouseModule/req",
                        loadingTrue : "SapWarehouseModule/loadingTrue",
                        addRequest : "SapWarehouseModule/addRequest",
                        cancelRequest : "SapWarehouseModule/cancelRequest",
                        clearOldRequest : "SapWarehouseModule/clearOldRequest"
                    }

                    ApiHttpService.get('warehouses', {}, configParams).then((response) => {
                        commit('setData', response.data)
                        commit('setLoading', false)
                        resolve(response.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },

     


    },
    getters: {
        data: state => state.data,
        warehousesLoading : state => state.loading,
        req: state => state.request,
    }
}



