import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir

const isDev = process.env.NODE_ENV === 'development';
const apiProtocol = (!isDev) ? 'https://v1.thatsliving.com/api' : 'http://127.0.0.1:9001/api';


export const ApiV1Service = {
    async store(endpoint,object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}
        return await Axios.post(`${apiProtocol}/${endpoint}`, params, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async get(endpoint, params) {
        let session = JSON.parse(localStorage.getItem('user'));


        const showroom = ["showroom-manager"];
        let showroomManager = false
        if (session.user.role_slugs.length > 0 && showroom.some(r => session.user.role_slugs.includes(r))) {
            showroomManager = true
        }

        const sales = ["sales"];
        let salesExecutive = false
        if (session.user.role_slugs.length > 0 && sales.some(r => session.user.role_slugs.includes(r))) {
            salesExecutive = true
        }

        let location = {
            location : session.location.id,
            show : session.location_prefix,
            sales : salesExecutive,
            showroom_manager : showroomManager,
        }
        return await Axios.get(`${apiProtocol}/${endpoint}`, { params: { ...token(), ...params, ...location} }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async update(endpoint,params) {
        return await Axios.put(`/api/${endpoint}`, params, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async delete(endpoint,params) {
        return await Axios.delete(`/api/${endpoint}`, {
            data: {
                tokens: params
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async updateStatus(endpoint,obj) {
        return await Axios.put(`/api/${endpoint}`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}
