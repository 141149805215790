<template>
  <div class="itmform-container">
      <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <ThatsWindow ref="window" title="Sales Promotion" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="FormStatus" @dialog-state="dialogState">
            <template #left-button>
                

                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>

                <template v-if="selected.length > 0">
                  <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                </template>
            </template>

          
            <mdbContainer class="mt-4">
                <mdb-row >
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Promo Name" vid="name" name="name" v-model="form.name" size="sm" :disabled="disabled" :rules="'required'"/>                  
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-6 pl-0" label="Prepared By" :placeholder="form.prepared_by" vid="preparedby" name="preparedby" v-model="form.prepared_by" size="sm" :disabled="true" />
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Remarks" vid="remarks" name="remarks" v-model="form.description" size="sm" :disabled="disabled"/>                              
                     
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsMultiSelect :loading="brandLoading" :multiple="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brands" v-model="form.brands" :reduce="brand => brand.value" :reduceLabel="'label'" :options="brands" :rules="'required'" :clearable="false" :disabled="disabled" :addButton="true" @newWindow="openVendor" @select="getBrandSelected($event)"></ThatsMultiSelect>
                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="form.status" :reduce="status => status.code" :reduceLabel="'status'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"></ThatsMultiSelect>
                    <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Start Date" placeholder="Start Date" vid="promo_start" name="promo_start" v-model="form.promo_start" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                    <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="End Date" placeholder="End Date" vid="promo_end" name="promo_end" v-model="form.promo_end" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                  <ThatsFile labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Attachment" vid="attachment" name="attachment" v-model="form.attachment" :disabled="disabled"></ThatsFile>
                  </mdb-col>    
                </mdb-row>

                 <el-table border :data="form.items" class="input-table mt-2" style="width: 100%" :height="'330px'" @selection-change="handleSelectionChange">
                  <template v-if="FormStatus === 'create' && form.status_index <= 1">
                    <el-table-column type="selection" width="35"> </el-table-column>
                  </template>
                  <el-table-column label="SKU" :width="150">
                    <template slot-scope="scope">
                      <ThatsGridSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></ThatsGridSelect>
                    </template>
                  </el-table-column>
                  <el-table-column label="Item Name">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Price" :width="80" align="right">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                    </template>
                  </el-table-column>
                  <el-table-column :width="30" align="center">
                    <template slot="header" slot-scope="scope">
                    <el-checkbox v-model="checked" :indeterminate="indeterminate"></el-checkbox>
                  </template>
                  <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.apply" ></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="Discount" :width="100" align="right">
                    <template slot-scope="scope">
                      <div class="input-group" v-bind:class="{'suffix' :scope.row.apply }">                           
                        <input type="text" min="0" class="form-control form-control-discount grid-input thats-form-control text-right" @keyup="computeDiscount(scope.$index)" @keydown="checkKeyCode($event)" v-model="scope.row.discount" :disabled="disabled" />
                        <template v-if="scope.row.apply">
                          <span class="input-group-addon ">%</span>
                        </template>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Off Price" :width="90" align="right">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control text-right" @keydown="checkKeyCode($event)" v-model="scope.row.off_price" :disabled="true" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Promo Price" :width="120" align="right">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control text-right" @keydown="checkKeyCode($event)"  v-model="scope.row.promo" :disabled="true" />
                    </template>
                  </el-table-column>
                  <el-table-column label="Remarks">
                    <template slot-scope="scope">
                      <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.description" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                    </template>
                  </el-table-column>
                  
                </el-table>
                
              </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>

      <BrandForm ref="vendorForm" :group="2" @success="vendorSuccess"></BrandForm>
      <ForeignCurrencyForm ref="foreignCurrencyForm" :group="2" @success="currencySuccess"></ForeignCurrencyForm>
    
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard } from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import BrandForm from '../brand/BrandForm'
import ForeignCurrencyForm from '../currency/ForeignCurrencyForm'

export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,

        BrandForm,
        ForeignCurrencyForm,
  },
    
    data : () => ({
      search : "",
      checked : true,
      indeterminate : false,
      brandLoading : false,
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      status : [
            { status : "Draft", code : "draft"},
            { status : "Active", code : "active"},
      ],
      items : [],
      selected : [],
      brands : [],

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.currentDateSetter();
      }
       this.optionsHandler();

      //  this.isGeneralManager();
    },



    methods: {
      
      // ...mapActions('VendorsModule',[ 'GetVendors' ]),
      // ...mapActions('ForeigncurrencyModule',[ 'GetForeigncurrency' ]),
      ...mapActions('BrandModule',[ 'GetBrands' ]),
      ...mapActions('SalesPromoModule',[
        'StoreRecord',
        'UpdateRecord',
        'filterItems',
      ]),
      ...mapMutations('SalesPromoModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          series : 0,
          date : "",
          promo_start : "",
          promo_end : "",
          status_index : 0,
          status : "draft",
          attr : {
              
          },
          user_id : null,
          prepared_by : null,
          brands : [],
          attachment : [],
          items : [
            {
              code: null,
              item_id: null,
              apply: false,
              description: null,
              attr: [],
              price: 0,
              discount: 0,
              off_price: 0,
              promo: 0,
              name: null
            },
            
          ]
        }
      },
/* start */

computeDiscount(i){
  const self = this;
  const {discount, apply, price} = this.form.items[i];

  const p = parseFloat(price);

  if(apply === true && discount !== null && discount !== ""){
    const off = (discount / 100) * p;
    const promo = p - off;

    this.form.items[i].promo = self.formatNumber(promo)
    this.form.items[i].off_price = self.formatNumber(off)
  }else{
    const d = parseFloat(discount);
    const promo = p - d;

    this.form.items[i].promo = self.formatNumber(promo)
    this.form.items[i].off_price = self.formatNumber(d)
  }
},

computeOff(i){
  const self = this;
  const {off_price, apply, price} = this.form.items[i];
  if(!apply && off_price !== null && off_price !== ""){
    this.form.items[i].promo = self.formatNumber(price - off_price)
  }
},
currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
optionsHandler(){
  // this.getItems()
  if(!this.brandLoading && this.brands.length <= 0){
    this.brandLoading = true
    this.GetBrands().then((response) => {
        this.brands = response
        this.brandLoading = false
    })
  }
},

openVendor(){
  this.$refs.vendorForm.openDialog()
},

vendorSuccess(v){
    const response = v.data;
    const data = {
              value : parseInt(response.mdbID),
              label : response.name,
          }
          this.vendors.push(data);      
},
currencySuccess(v){
    const response = v.data;
    const data = {
              value : parseInt(response.mdbID),
              label : response.name,
          }
          this.currency.push(data);      
},

updatePurchaseOrderStatus(status){
  const self = this;
  if(self.FormStatus == 'update'){
     MessageBox.confirm('Are you sure you want to change status?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {

            const formData = { 
              hash : self.form.token_id,
              status : status
              }
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                self.$ThatsNotify.success({
                  message : "Record status was successfully changed"
                })
                  self.closeDialog();
                  self.$emit('success')
                  self.FormResetter();
                  
              }).catch((error) => {
                self.formLoading = false
                self.response.error = 1;
                if (error.response) {
                    if(error.response.data.hasOwnProperty('errors')){
                      self.$refs.observer.setErrors(error.response.data.errors);
                      this.showError = true
                    }
                  } else if (error.request) {
                      self.response.message = error.request
                  } else {
                      self.response.message = error.message
                  }
                  return;
              })

          })
      
  }
},
async setPreparedBy(){
    var self = this;
    const loggedUser = await userData()

    const frm = this.form
    frm.prepared_by = loggedUser.user.name

    this.form = frm;
},

checkKeyCode(evt) {



                var keyCode = evt.which ? evt.which : event.keyCode;
            

            //Accept Numbers Only and dash
            if ((keyCode > 47 && keyCode < 58) ||
                keyCode === 8 ||
                keyCode === 9 ||
                keyCode === 173 ||
                keyCode === 37 ||
                keyCode === 39 || (keyCode > 97 && keyCode < 105)) {
                    return true;
            }
            evt.preventDefault();
            
        },
deleteItem(){


if(this.selected.length){
    if(this.FormStatus === 'update'){
        MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {
              this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          code: null,
                          item_id: null,
                          apply: false,
                          description: null,
                          price: 0,
                          formatted_price: "",
                           attr: [],
                          discount: 0,
                          off_price: 0,
                          promo: 0,
                          name: null
                        }
                      )
              }
          })
    }else{
      this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                this.form.items.push(
                        {
                          code: null,
                          item_id: null,
                          apply: false,
                          name: null,
                          price: 0,
                          formatted_price: "",
                           attr: [],
                          discount: 0,
                          off_price: 0,
                          promo: 0,
                          description: null
                        }
                      )
              }
    }
  

  
}


// remove object


},

handleSelectionChange(v){
  this.selected = v
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.name !== null){
              this.form.items.push(
                   {
                    code: null,
                    item_id: null,
                    apply: false,
                    name: null,
                    price: 0,
                    formatted_price: "",
                     attr: [],
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                    description: null
                  })
            }
    }

},

getBrandSelected(v){

  const params = {brands : v};
        this.dispatchFilterItems(params);
},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    if(getSelected){
      this.$set(this.form.items[index], 'description', "")
      this.$set(this.form.items[index], 'promo', 0)
      this.$set(this.form.items[index], 'off_price', 0)
      this.$set(this.form.items[index], 'discount', 0)
      this.$set(this.form.items[index], 'apply', false)
      this.$set(this.form.items[index], 'price', getSelected.price)
      this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
      this.$set(this.form.items[index], 'item_id', getSelected.id)
      this.$set(this.form.items[index], 'name', getSelected.label)

      // this.addNewRow(index)
      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            this.form.items.push(
                        {
                          code: null,
                          item_id: null,
                          apply: false,
                          name: null,
                          price: 0,
                          formatted_price: "",
                           attr: [],
                          discount: 0,
                          off_price: 0,
                          promo: 0,
                          description: null
                        })
        }
    }
},

/* end */
      openDialog(){
            this.$refs.window.openDialog()
            // console.log(this.$refs.window)
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then(() => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success')
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },
      
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

        

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },
      
      dispatchFilterItems(params){
        const self = this;
        if(self.form.brands.length > 0){
          const results = [];
          self.filterItems(params).then((response) =>{
            if(response.length > 0){
              response.forEach(element => {
                results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID})
                  });
                }
            })
          self.items = results
        }
      },
      getItems(v){
        const params = {term : v, items : this.itemSeries,brands : this.form.brands};
        this.dispatchFilterItems(params)
      },
      statusKeyUpFocus(){

        if(this.form.brands.length > 0){

          const localItems = this.items;
        const filterOnFocus = [];
        if(localItems.length > 0){
          localItems.forEach(item => {
            if(this.itemSeries.indexOf(item.code) < 0){
              filterOnFocus.push(item)
            }
          });

          this.items = filterOnFocus
        }
        }

        
      },
      statusKeyUp(v){
        
      this.getItems(v)
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },

    },

    computed: {

      ...mapGetters('SalesPromoModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })
          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          if(self.FormStatus === 'update' && self.form.status_index > 1){
            return true
          }
          return false;
      },

      
    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
           this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
          this.formOriginal = JSON.parse(JSON.stringify(this.FormRecord.row));
           this.form.items.push(
                        {
                          code: null,
                          item_id: null,
                          apply: false,
                          name: null,
                          price: 0,
                          formatted_price: "",
                           attr: [],
                          discount: 0,
                          off_price: 0,
                          promo: 0,
                          description: null
                        }
                      )
                      
          const params = {brands : this.form.brands};
          this.dispatchFilterItems(params);
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },

      "form.brands" : function(v,n){
        /* console.log(v)
        console.log(n) */
      },
      "form.items" : {
          handler(v){
            const self = this;
            const itemLength = v.length;
            let falseLength = v.length;
            let trueLength = 0;
                if(itemLength > 0){
                    v.forEach((element, index) => {
                      self.computeDiscount(index)
                        if(element.apply && element.code != null && element.code != null){
                          trueLength++
                        }else{
                          falseLength--
                        }
                    });
                }

                if((itemLength - 1) === trueLength ){
                  self.indeterminate = false
                  self.checked = true
                }else{
                  self.indeterminate = true
                  // self.checked = false
                }

                if(falseLength === 0 && trueLength === 0){
                    self.indeterminate = false
                    self.checked = false
                }

          },
          deep: true
        },
      checked : function(v){
        const self = this;
         self.form.items.forEach((element,index) => {
              if(element.code != null && element.name != null){
                self.$set(self.form.items[index], 'apply', v)
                self.computeDiscount(index)
              }
            });
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

.input-group-addon {
    padding: 5px 0;
    font-size: 14px;
    line-height: 1;
    color: #555;
    text-align: center;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

</style>