
<template>
    <div class="page-container">
        <ThatsTable title="SAP Invoices" ref="table" :multipleSelection="false" :endpoint="endpoint" @openUpdateModal="openUpdateModal" :columns="columns" :height="'calc(100vh - 155px)'" :newBtn="false" :importBtn="false" :enableFilter="false" @print="fnPrint">

            <template #tabs>
                           <!-- <button type="button" @click="active=0">0</button>
           <button type="button" @click="active=1">1</button>
           <button type="button" @click="active=2">2</button>
           <button type="button" @click="active=3">3</button> -->
                <mdb-tabs class="table-tabs" :active="active" default :links="[{ text: 'Invoices', disabled : tableLoading }]"  @activeTab="getActiveTabIndex" />
            </template>

        </ThatsTable>

        <SapDeliveryForm ref="invoiceForm" @success="refreshTable" :group="3"></SapDeliveryForm>
       
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './SapTable'
import SapDeliveryForm  from './SapDeliveryForm'
import FileUpload from '../../components/filemanager/FileUpload'
import {mdbTabs} from 'mdbvue'
import { userData } from '../../helpers/user'

const demoSalesMethods = {
    testing(){
        alert(1)
    },

    testing2(){
        alert(2)
    }
}
export default {
    data(){
        return {
            endpoint : 'sap/invoice?view=invoice',    
            activeTab : 'sales',    
            enableFilter : true,    
            columns : [],               
            importLoading : false,        
            active : 0,        
            token : null,
            enableNew : true,        
            enableImport : true,
            importData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                },
                {
                    name : "DETAILS",
                    slug : "details",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],        
            errorData : [
                {
                    name : "HEAD",
                    slug : "head",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                },
                {
                    name : "DETAILS",
                    slug : "details",
                    columns : [
                        {
                            field: "ecount_code",
                            label: "ecount_code",
                            width: 150
                        }
                    ],
                    rows : [
                        {
                            ecount_code: "20383BH"
                        }
                    ],
                    errors : []
                }
            ],             
            importLoading : false,             
            // invoicesWindow : []
        }
    },
    components:{
        SapDeliveryForm,
        ThatsTable,
        FileUpload,
        mdbTabs,

    },
    created(){
        this.columns = this.salesColumns;
         const loggedUser = userData()
        this.endpoint =  `sap/invoice?user=${loggedUser.user.id}`;
    },   
    methods: {
        // 

        ...mapActions('SapInvoicesModule',[
            'resetData',
            'getRecord'
        ]),
        ...mapMutations('SapInvoicesModule',[
            'setTotal',
            'setPage',
            'setTerm',
            'setFilters',
            
        ]),

        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },

        setter(){
             const loggedUser = userData()
            let resetDataEndpoint = `sap/invoice?user=${loggedUser.user.id}`;
            this.endpoint = resetDataEndpoint
            this.resetData(resetDataEndpoint);
        },

        getActiveTabIndex(index){

            const tabs = ['sales'];
            this.activeTab = tabs[index];
            this.active = index;
            this.setPage(1)
            this.setTotal(0);
            this.setFilters({});
            this.setter();
        },

        fnPrint(obj){
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

            let session = JSON.parse(localStorage.getItem('user'));
            let token = session.token;

            const locationId = session.location.id

            if(this.activeTab === 'sales'){
                const win = window.open(`/sap/invoice/${obj.row.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
                win.document.title = 'Print';
                win.print()
            }
      },


      openUpdateModal(obj){
            console.log(obj)
            this.getRecord(obj.row)
            this.$refs.invoiceForm.openDialog();
      }


    },
    computed: {
        ...mapGetters('SapInvoicesModule',{
            salesColumns : 'columns',
            tableLoading : 'tableLoading',
        }),
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>