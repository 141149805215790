<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                
                <label :for="$attrs.vid" v-bind:class="bindLabelHorizatal +' '+ labelClass"><el-checkbox v-model="inputValue.active">{{$attrs.label}}</el-checkbox></label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">

                <div class="d-flex flex-row">
                <money @keyup.native="fnKeyDownMoney" v-model="inputValue.value" v-bind="inputOption" class="text-right mr-2 w-100" autocomplete="off"></money>
                <div style="width: 60px;padding-right: 5px;">
                    <a href="javascript:void(0)" class="btn-percent" style="display: block;padding: 5px 0;" @click="open"><i class="el-icon-money"></i></a>
                </div>
                <div style="width:150px">
                    <div class="w-100 md-form">
                        <v-select class="" @input="setSelected" ref="vselect" :multiple="false" v-model="inputValue.distribution" append-to-body :reduce="reduce" :label="reduceLabel" :options="[{code : 'volume', label : 'Volume'},{code : 'value', label : 'Value'}]" :filterable="false" :clearable="false"></v-select>
                    </div>
                </div>
                </div>
                              
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'CostingInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        totalAmount: {
            type: Number
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputclass : {
            type : Array,
            default : function(){
                return ['s'];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },
        maxlength: {
            type: String,
            default : ""
        },

        options : {
            type: Object,
            default : ()=>{
                return { decimal: '.', thousands: ',', prefix: '', suffix: ' QAR', precision: 2, masked: false }
            }
        },

        reduce :{
            type : Function,
            default: option => option,
        },
        reduceLabel :{
            type: String,
	        default: "label"
        },
        
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : {
            active : false,
            distribution : 'volume',
            foreign_currency :  'QAR',
            amount : 0,
            currency :  'QAR',
            rate : 1,
            value : 0,
        }
    }),

    

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        setSelected(v){
            this.$emit('select',v)
        },

        open(){
     
            this.$emit('open',this.$attrs.vid)
        },
        fnKeyDownMoney(v){
            this.inputValue.foreign_currency = this.inputValue.currency;
            this.inputValue.rate = 1;
            this.inputValue.amount = this.inputValue.value
            
            // this.inputValue.amount = this.inputValue.value
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        },

        /* "inputValue.amount" : {
            handler(value){
                this.inputValue.percent = (value / this.totalAmount) * 100
            },
            deep : true
        } */
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    },
    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.horizontal){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.horizontal){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputclass.join(' ');
        },
        inputOption : function(){
            return {...this.options,suffix : ' '+this.inputValue.currency}
        }
    },
}

</script>