<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Delivery Scheduler" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                 <template v-if="viewLoading || putLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                   Loading
                  </mdb-btn>
                </template>
                <template v-else>
                 <template v-if="isAllowed">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))">Save</mdb-btn>
                  </template>
                </template>
              </template>

          <span class="invoice-number">Sales Invoice #: {{form.transaction.invoice.series}}</span>
          <mdbContainer class="mt-1">
           
            <mdb-tabs class="table-tabs mb-2 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              
              <template :slot="'pull-out'">
                   <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" vid="pullout_date" name="pullout_date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsMultiSelect :multiple="false"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Pull-Out Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="true"></ThatsMultiSelect>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="remarks" name="remarks" v-model="form.remarks" size="sm" :disabled="false"/>
                      
                    </mdb-col>    
                  </mdb-row>

                  <el-table ref="itemsTable" border :data="form.items" class="input-table mt-2" style="width: 100%"  :summary-method="getDeliverySummaries" show-summary :cell-class-name="cell" :row-key="getRowKey">
                     <el-table-column label="SKU" :width="150">
                        <template slot-scope="scope">
                         <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Name">
                        <template slot-scope="scope">
                          {{scope.row.name}}
                        </template>
                      </el-table-column>
                        <el-table-column label="Qty" :width="70">
                          <template slot-scope="scope">
                            {{scope.row.qty}}
                          </template>
                        </el-table-column>
                        <el-table-column label="Price" :width="100" align = "right">
                          <template slot-scope="scope">
                            {{scope.row.price | amount}}
                          </template>
                        </el-table-column>
                        <el-table-column label="Amount" :width="100" align = "right">
                          <template slot-scope="scope">
                            {{scope.row.qty * scope.row.price | amount}}
                          </template>
                        </el-table-column>
                        <el-table-column label="Discount" :width="90" align = "right">
                          <template slot-scope="scope">
                            {{scope.row.discount | amount}}
                          </template>
                        </el-table-column>
                        <el-table-column label="Total Amount" :width="120" align = "right">
                          <template slot-scope="scope">
                            {{scope.row.total | amount}}
                          </template>
                        </el-table-column>
                      <el-table-column label="Status">
                        <template slot-scope="scope">
                          <div v-html="scope.row.remarks"></div>
                        </template>
                      </el-table-column>
                    </el-table>

                 
              </template>
            </mdb-tabs>
            
          </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import PrintWindow from '../../components/thats/PrintWindow'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomMultiSelect, 
        PrintWindow, 

  },
    
    data : () => ({
      printUrl : '',
      active : 0,
      activeTab : 'form-slot',
      validated : false,
      formOriginal : null,
      form : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      status : [{code : "for-pull-out",label : "For Pull-Out"},{code : "pulled-out",label : "Pulled-Out"}],
      tabs : [
                { text: 'Pull-Out Order', slot: 'pull-out' },
              ],
      delivery_status : [],
      showError : false,
    }),

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
      
        this.currentDateSetter();

        
      }
       this.optionsHandler();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.delivery_status = settings.delivery_status

    },
    updated(){
      this.toggleCheck()
    },


    methods: {
      ...mapActions('PullOutModule',[
        'updateRecord'
      ]),
      ...mapMutations('PullOutModule',[
        'setFormStatusCreate',
        'setPaymentForm',
        'setFormRecord',
        'setFormStatusUpdate',
      ]),

      formVar(){
        return {
          series : "",
          action : "save",
          date : "",
          attr : {
            process_by : ""
          },
          description : null,
          status : "for-delivery",
          items : [],
          attachment : [],
          transaction : {
            series : "",
            date : "",
            status_index : 0,
            status : "no-remarks",
            attr : {
              name : null,
              mobile : null,
              address : null,
              instruction : null,
              other_details : null,
              payment_status : 'no-payment',
            },
            customer : {
              name : null,
              mobile : null,
              address : null,
            },
            user_id : null,
            sold_by : null,
            customer_id : null,
            payment_method : 'cash',
            items : [],
            attachment : [],
            invoice : {
              series : null
            },
            delivery : {
              date : null,
              remarks : null,
              status : 'for-delivery',
            }
          }
        }
      },

      formItemVar(){
        return {
                default: "",
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null
              }
                      
      },
/* start */

cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

  getDeliverySummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6]
        
        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

       
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }
                return d;
              })
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                 this.form.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
getActiveTabIndex(index){

  const tabs = ['form-slot', 'invoice-slot', 'form-attachment', 'deliveries' , 'pull-out']
  this.active = index
  this.activeTab = tabs[index]
},


optionsHandler(){
  // this.getPurchaseOrder()
  const self = this;
  // this.dispatchFilterItems({transaction_date : this.form.date})
  // self.fnGetContacts()
},

handleSelectionChange(v){
   this.form.items = v
  //  this.form.delivery_status = 'partial-delivery'
},


 openDialog(){
        this.$refs.window.openDialog();
      },
  nextCode(){
    var self = this;
  },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(action){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true

                      if(self.formStatus == 'update'){
                        
                        self.form.action = action;

                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                              self.setFormRecord(response.data)
                              self.formLoading = false
                              self.form.items = [];

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.attr.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

/*         console.log("custom");
        console.log(custom_discount); */
        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(url, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },
      
      getDeliveryStatusSelected(v){
        console.log(v)
      },

      toggleCheck(){
        const self = this;


        if(this.form.transaction.hasOwnProperty('items') && this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);
          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              })

               
                
              }else{
                self.$refs.itemsTable.clearSelection();
              } 

          // const indexZero = this.form.transaction.items[0];
          //     this.$refs.itemsTable.toggleRowSelection(indexZero);

        
        }
      },

      getRowKey(row){
        return row.code
      },

      getQtyForDelivery(code){
        // console.log(this.formOriginal)

        const getItemFormOriginal = this.formOriginal.items.filter((i) => {

          // console.log(i)
          return i.code === code
        })[0];


        // const this.formOriginal.items.
        /* if(typeof this.formOriginal.items[index] !== 'undefined'){
            return this.formOriginal.items[index].qty
        }
        return 0; */
/* 
        console.log("qty")
        console.log(code)
        console.log(getItemFormOriginal) */
      },

      quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },

      createDeliveryNote(){

      },

      canSelectRow(row, index){
        if(row.remaining <= 0){
           return false
        }
        return true;
        },
      disableInput(row){
        // console.log(row)
        if(row.remaining <= 0){
           return true
        }
        return false;
        },

    },

    computed: {

      ...mapGetters('PullOutModule',
      [
        'viewLoading',
        'putLoading',
        'formStatus',
        'formRecord',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          /* if(self.formStatus === 'update'){
            if(self.form.invoice !== null && self.form.invoice.series !== null){
              return true;
            }
          } */
          return false;
      },
      invoice : function(){
          let inv = {
          series : "",
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }

        /* if(this.formStatus == 'update'){
            if(this.FormRecord.transactions != null && this.FormRecord.transactions.invoice !== null){
              inv = this.FormRecord.transactions.invoice
            }

        } */
        return inv
      },
      isAllowed : function(){
            if(this.$helpers.isDeliveryTeam() || this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;
        },

      btnText : function(){
          if(this.form.items.length > 0){
              return "Delivery Note";
          }
          return "Save";
      }
    },

    watch: {
      formRecord : function(v){
        const self = this;
        this.active = 0;
        if(this.formStatus == 'update'){
          const jsonParse = JSON.parse(JSON.stringify(this.formRecord));
          jsonParse.attr = {...this.form.attr,...jsonParse.attr}

          this.form = {...this.form,...jsonParse};
          self.formOriginal = JSON.parse(JSON.stringify(jsonParse))
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

      'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              }).reduce((a,b) => {
                return a + b;
              })
              this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
            }

          },
          deep: true
      },

      'form.attachment' : {
        handler : function(v){
            // alert("attachment")
        },
        deep: true
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}
</style>