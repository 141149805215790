<template>
    <div class="filter-dalog-body p-0">
        <div class="p-0 header-panel-container border-bottom border-light">
            <mdb-navbar class="z-depth-0 p-0 pl-2 rounded-sm justify-content-between">
                <mdb-navbar-brand class="table-header-navbar-brand">
<!--                    <mdb-tabs class="table-header-tabs" :active="0" default :links="[ { text: title,slot:'main' }]"  />-->
                </mdb-navbar-brand>

                <mdb-form-inline class="ml-auto" tag="div">
<!--                    <input class="form-control mr-sm-1 table-searchterm-input border border-light" v-model="filterObj.term" type="text" placeholder="Search" aria-label="Search" style="margin-top: 6px!important;" @keydown.enter.prevent="handleSearchTerm"/>-->
<!--                    <mdb-btn color="elegant" size="sm" @click="handleSearchTerm" class="m-1"><i class="el-icon-search"></i> Search</mdb-btn>-->
<!--                    <mdb-dropdown>-->
<!--                        <mdb-dropdown-toggle color="elegant" size="sm" slot="toggle"  class="m-1">Issue Benefits</mdb-dropdown-toggle>-->
<!--                        <mdb-dropdown-menu style="margin-left: -55px">-->
<!--                            <mdb-dropdown-item @click="openNewWindow('issue-coupon')">Issue Coupons</mdb-dropdown-item>-->
<!--                            <mdb-dropdown-item @click="openNewWindow('update-service')">Update Service Status</mdb-dropdown-item>-->
<!--                        </mdb-dropdown-menu>-->
<!--                    </mdb-dropdown>-->
                </mdb-form-inline>
            </mdb-navbar>
        </div>
        <el-table :border="true" ref="multipleTable" lazy class="custom-element-table" :data="data.rows" :height="400" stripe>
                <el-table-column type="index" width="35" label="#"> </el-table-column>
                <el-table-column v-for="col in columns" v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                    <template slot-scope="scope">
                      <template v-if="col.field === 'benefit_name'">
                        <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)">
                          {{ scope.row.tier_beneficiary.name }}
                        </a>
                      </template>

                        <template v-if="col.field === 'tier_name'">
                           <div v-if="scope.row.tier_beneficiary.tiers.length > 0">
                               {{ scope.row.tier_beneficiary.tiers[0].name }}
                           </div>
                        </template>

                      <template v-if="col.field === 'status'">
                            <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ scope.row.status}}</mdb-badge>
                        </template>
                        <template v-else>
                            {{ (scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field]) }}
                        </template>
                    </template>
                </el-table-column>
                <template slot="empty">
                    <template v-if="viewTransactionLoading">
                        <mdb-btn color="elegant" size="sm" rounded disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading data...
                        </mdb-btn>

                    </template>
                    <template v-else>
                        No Data
                    </template>
                </template>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="current_page"
            :page-sizes="[10,25,50,100, 200, 300, 400]"
            :page-size="length"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div>
</template>
<script>
import {
    mdbBtn,
        mdbBadge,
        mdbFormInline,
        mdbNavbar,
        mdbNavbarBrand,
        mdbNavbarToggler,
        mdbNavbarNav,
        mdbNavItem,
        mdbTabs, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle
} from "mdbvue";
import { mapActions, mapMutations ,mapGetters  } from 'vuex'
import { ThatsInput,ThatsTextarea} from '../../components/thats/Thats'


export default {
    components : {
        mdbBtn,
        mdbBadge,
        mdbFormInline,
        mdbNavbar,
        mdbNavbarBrand,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },
    props : {
        endpoint : {
            type : String,
            default : 'sales/invoice'
        },
        title : {
            type : String,
            default : 'Transactions'
        },
        columns : {
            type : Array,
            default : () => {
                return [
                    {
                        label: 'Benefit Name',
                        field: 'benefit_name',
                    },
                    {
                        label: 'Tier',
                        field: 'tier_name',
                    },
                  {
                    label: 'Available',
                    field: 'available',
                  },
                    {
                        label: 'Issue',
                        field: 'issue_count',
                    },
                  {
                    label: 'Redeem',
                    field: 'redeemed_count',
                  },
                    {
                        label: 'Status',
                        field: 'status',
                    },
                    {
                        label: 'Expire Date',
                        field: 'expiry_date',
                    }
                ]
            }
        },
        refresh : {
            type : Boolean,
            default : false
        }
    },
    data(){
        return {
            dialogVisibleSync : false,
            filterObj : {
                term : null
            },
            dialogIsOpen: false,

        }
    },
    mounted(){
        this.dispatchHandler();
    },
    methods : {
        ...mapActions('CustomerBenefitModule',[
          'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('CustomerBenefitModule',[
            'SetTotal',
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
            'SetFilters',
        ]),
        openDialog(){
            this.dialogVisibleSync = true
        },
        handleSaleTransactionClick(row){
            this.$emit('rowClick',row)
        },
        openNewWindow(data){
            this.$emit('dialogClick', data)
        },
      handleClick(index,row){
        console.log(row, 'swaraj bhuyan')
        this.$emit('open-service-modal', { index, row });
      },
        triggerGetTransaction(obj){
            const self = this;
            this.SetTotal(0)
            this.filterObj = {item_code : obj.series}
            this.SetFilters(this.filterObj)
            this.resetData(this.endpoint).then(() => {
            });
        },
        async dispatchHandler(){
            this.SetFilters(this.filterObj)
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
          this.SetLength(val)

          this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.SetTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        refreshTable(){
            this.SetFilters(this.filterObj)
          this.resetData(this.endpoint);
        },
        dispatchFilter(filters){
            this.SetTotal(0)
            this.SetFilters(filters)
            this.resetData(this.endpoint);
        },
        triggerNewEvent(){
        },
    },
    computed: {
      ...mapGetters('CustomerBenefitModule',[
          'data',
          'page',
          'total',
          'length',
          'searchTerm',
          'tableLoading',
          'Selection',
          'viewTransactionLoading',
      ]),
      term : {
          get : function(){
             return this.searchTerm
          },
          set : function(val){
              this.SetTerm (val)
          }
      },
      current_page : {
          get : function(){
             return this.page
          },
          set : function(val){
              this.SetPage (val)
          }
      },
      hasFilter(){
          return !!this.$scopes.filter;
      },
      hasFilterSlot() {
          console.log(this.$scopedSlots)
          // console.log(('filter' in this.$scopedSlots))
          return ('filter' in this.$scopedSlots);
      },
      hasTabsSlot() {
          return ('tabs' in this.$scopedSlots);
      },
      hasRightSlot() {
          return ('top-right' in this.$scopedSlots);
      },
      hasCustomColumnsSlot() {
          return ('custom-culumns' in this.$scopedSlots);
      },
      statusArrayColor : function(){

            return {'draft' : 'badge-light','for-approval' : 'warning','S' : 'warning','P' : 'warning','R' : 'success','open': 'success','canceled' : 'danger','closed' : 'danger'};
        },
        statusArrayIcon : function(){

            return {'draft' : 'el-icon-more','S' : 'el-icon-ship','P' : 'el-icon-circle-check','open': 'el-icon-success','R': 'el-icon-success','canceled' : 'el-icon-remove','closed' : 'el-icon-remove'};
        },
        documentStatus : function(){
            return {'S' : 'In Shipment','P' : 'In Port','R' :'Received' }
        }
  },
}
</script>
