<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :modal="true" ref="window" :fullscreen="false" :title="'View Customer Logs'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" width="40%" class="text-left tier-beneficiary-dialog" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-3">

                <div class="row">
                    <div class="col-sm-4">
                        <label>Name:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.name }}</p>
                    </div>
                </div>
                <div class="row" v-if="form.attr && form.attr['existingContact'] !== null">
                    <div class="col-sm-4">
                        <label class="text-primary">Existing Customer:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>CX-Series : <b class="text-primary">{{ form.attr['existingContact']['series'] }} </b>, MID : <b class="text-primary">{{ form.attr['existingContact']['id'] }}</b></p>
                    </div>
                </div>


                <div class="row">
                    <div class="col-sm-4">
                        <label>Mobile Number:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.mobile }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label>Whatsapp Number:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.whatsapp_mobile }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label>Email:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.email }}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        <label>Brands:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.brand_names }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label>Category:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.category_names }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label>Location:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.location_name }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <label>Sales Person:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.sales_person_name }}</p>
                    </div>
                </div>



                <div class="row">
                    <div class="col-sm-4">
                        <label>Remainder Date and Time:</label>
                    </div>
                    <div class="col-sm-8 pl-0">
                        <p>{{ form.date_reminder }} {{form.reminder_time}}</p>
                    </div>
                </div>

            </mdbContainer>


                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
<!--                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>-->
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>

          </ValidationObserver>




  </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsMultiSelect, ThatsTextarea, ThatsDatePickerPage} from '../../components/thats/Thats'
import {  mdbTimePicker,mdbDatePicker2 } from 'mdbvue';

export default {
    components: {
        ThatsTextarea,
        ThatsDatePickerPage,
        mdbContainer,
        mdbBtn,
        mdbTimePicker,
        mdbDatePicker2,
        ValidationObserver,
        ThatsInput,
        ThatsMultiSelect,


  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider','filteredCategoryProvider','brandProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
        categories : [],
        brands : [],
        partnerSalesPerson : [],
        validated: false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
        reminderDate : [
            { label : "After 1 Day", code : "1day"},
            { label : "After 2 Day", code : "2day"},
            { label : "After 3 Day", code : "3day"},
            { label : "After 4 Day", code : "4day"},
            { label : "After 5 Day", code : "5day"},
            { label : "After 6 Day", code : "6day"},
            { label : "After 7 Day", code : "7day"},
        ],
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
    mounted() {
        this.getPartner().then((response) => {
            console.log(response,"partner")
            this.partnerSalesPerson = response
        })

    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */

      ...mapActions('CustomerLogsModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
        ...mapActions('TransactionModule',[
            'getPartner']),
      ...mapMutations('CustomerLogsModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
            name: '',
            mobile: '',
            whatsapp: '',
            location: '',
            reminder_date: '',
            reminder_time: '',
            comments: '',
            sold_by: '',
            sameAsMobile: false,
            brand : [],
            category : [],
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },



        /* end */
        openDialog(data){
            this.syncModal = true
            this.getCategory();
            this.getBrand();
           this.form = data;

        },
        getCategory() {
            var self = this;
            const categories = self.filteredCategoryProvider();

            const formattedCategories = categories.map(category => {
                return {
                    value: category.id,
                    label: category.name
                };
            });

            self.categories = formattedCategories;

            // Log the result for debugging
            console.log(formattedCategories, "Formatted Categories");
        },
        getBrand() {
            var self = this;
            const brands = self.brandProvider();

            const brandItems = brands.map(brand => {
                return {
                    value: brand.code,
                    label: brand.brand
                };
            });

            self.brands = brandItems;
            //
            // // Log the result for debugging
            console.log(brandItems, "Formatted brand");
        },
        closeDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },

        closeNewDialog(){
            this.syncModal = false
            this.FormResetter();
            this.form.reminder_time = ''
        },


      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {

      ...mapGetters('TierBeneficiaryModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
        ...mapGetters('CustomerLogsModule',
      [
        'formStatus'
      ]),

        whatsappNumber() {
            if (this.form.sameAsMobile) {
                console.log("Returning form.mobile as whatsappNumber:", this.form.mobile);
                return this.form.mobile;
            } else {
                console.log("Returning form.whatsapp as whatsappNumber:", this.form.whatsapp);
                return this.form.whatsapp;
            }
        },

        showrooms : function(){
            return this.showroomsProvider();
        }


    },

    watch: {
      FormRecord : function(){
        if(this.formStatus == 'update'){
           const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            this.form = {...this.form,...frm}
          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.formStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },


    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}

</style>
