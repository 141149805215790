<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid">
        <div class="image-list-container display-flex flex-wrap mt-2">
            <template v-if="multiple">
                <div class="image-list-item position-relative cursor-pointer image-highlight" v-bind:class="size" v-for="(image, index) in data" :key="index">
                    <div class="centered">
                    <img class="show-img img-responsive" :src="image.thumbnail">
                    </div>
                        <template v-if="image.default">
                        <div class="icon-container">
                            <mdb-icon icon="check-circle" />
                        </div>
                        </template>
                </div>


                <div @click="emitUpload" class="image-list-item position-relative cursor-pointer display-flex justify-content-center align-items-center" v-bind:class="size">
                    <svg class="icon add-image-svg" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M511.5 227.5h-227V.5h-57v227H-.5v57h228v228h57v-228h227z"></path></svg>
                    <div class="input-add-image position-absolute full-width full-height">
                    <label class="display-block full-width full-height cursor-pointer">
                    </label>
                    </div>
                </div>
            </template>

            <template v-if="!multiple">
                <div  @click="emitUpload" class="image-list-item position-relative cursor-pointer display-flex justify-content-center align-items-center" v-bind:class="size">
                <template v-if="!multiple && data.length === 1">

                  <template v-if="size === 'image-small'">
                    <div class="centered">
                        <img class="show-img img-responsive" :src="data[0].small">
                        </div>
                        </template>

                    
                        
                        <template v-if="size === 'image-full'">
                          <div class="centered-big">
                              <img class="show-img img-responsive" :src="data[0].path">
                        </div>
                        </template>
                </template>
                <template v-else>
                    <svg class="icon add-image-svg" xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512"><path d="M511.5 227.5h-227V.5h-57v227H-.5v57h228v228h57v-228h227z"></path></svg>
                    <div class="input-add-image position-absolute full-width full-height">
                        <label class="display-block full-width full-height cursor-pointer">
                        </label>
                    </div>
                </template>
                </div>

            </template>
          

        </div>

    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline } from "mdbvue";
export default {
    name : 'ThatsImage',
    props : {
       
        value: {
            type: Array
        },

        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        multiple : {
            type : Boolean,
            default : false
        },
        size : {
            type : String,
            default : 'image-smal'
        }
        

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
    },

    data : ()=>({
        data : [],
    }),
    methods : {
        emitUpload(){
            this.$emit('open-form');
        }
    },

    watch: {
    // Handles internal model changes.
        data(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.data = newVal;
        },

    },
    created() {
        if (this.value) {
        this.data = this.value;
        }
    }
}

</script>

<style scoped>

.image-container-header{
  justify-content : flex-start!important
}
.text-small {
  font-size: 11px;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.text-center {
  text-align: center;
}
.text-primary {
  color: #2fa3e6;
}
.display-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.centered {
  left: 50%;
  transform: translate(-50%,-50%);
  top: 50%;
  position: absolute;
  display: block;
}

.image-center {
  width: 100%;
  height: 100%;
}
.image-icon-drag {
  fill: #c9c8c8;
  height: 50px;
  width: 50px;
}
.drag-text {
  padding-top: 5px;
  color: #777;
  font-weight: 400;
  line-height: 1.5;
}
.browse-text {
  font-size: 86%;
  color: #206ec5;
  text-decoration: none;
}
.image-input {
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.image-input label {
  display: block;
}
.drag-upload-cover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FCFEFF;
  opacity: .9;
  z-index: 1;
  margin: 5px;
  border: 2px dashed #268DDD;
}
.drag-upload-cover {
  font-weight: 400;
  font-size: 20px;
}
.icon-drag-drop {
  height: 50px;
  width: 50px;
  fill: #2fa3e6;
}
.drop-text-here {
  margin: 0;
  line-height: 1.5;
}
.display-none {
  display: none;
}

/* list images*/
.image-list {
  border: 1px solid #D6D6D6;
}
.preview-image {
  height: 140px;
  padding: 5px;
  border-radius: 15px;
  box-sizing: border-box;
}
.image-overlay {
  background: rgba(0,0,0,.7);
  z-index: 10;
  border-radius: 5px;
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}
.image-overlay-details {
  position: absolute;
  z-index: 11;
  opacity: 0;
  transform: translate(0,-50%);
  top: 50%;
}
.icon-overlay {
  width: 40px;
  height: 40px;
  fill: #fff;
}
.preview-image:hover .image-overlay, .preview-image:hover .image-overlay-details{
  opacity: 1;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}
.show-img {
  max-height: 100px;
  max-width: 140px;
  display: block;
  vertical-align: middle;
}
/*image bottom*/
.image-bottom {
  bottom: 0;
  left: 0;
  height: 40px;
  padding: 5px 10px;
  box-sizing: border-box;
}
.image-primary {
  border-radius: 4px;
  background-color: #e3edf7;
  padding: 3px 7px;
  font-size: 11px;
  margin-right: 5px;
}
.image-icon-primary {
  width: 10px;
  height: 10px;
  margin-right: 2px;
}
.image-icon-delete {
  height: 14px;
  width: 14px;
  fill: #222;
}
.image-edit {
  margin-right: 10px;
}
.image-icon-edit {
  height: 14px;
  width: 14px;
  fill: #222;
}
.image-list-container {
  width: 100%;
  min-height: 50px;
}
.image-list-container .image-list-item.image-full .show-img {
  max-height: 200px;
  max-width: 100%;
}
.image-list-container .image-list-item.image-full {
  height: 200px;
  width: 100%;
}
.image-list-container .image-list-item {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  border: 1px solid #D6D6D6;
  overflow: hidden;
}
.image-list-container .image-list-item:not(:last-child) {
  margin-right: 5px;
  margin-bottom: 5px;
}
.image-list-container .image-list-item .show-img {
  max-width: 100px;
  max-height: 100px;
}
.image-list-container .image-highlight {
  border: 1px solid #2fa3e7;
}
.add-image-svg {
  width: 12px;
  height: 12px;
  fill: #222;
}
.input-add-image {
  overflow: hidden;
  opacity: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}
.input-add-image label {
  display: block;
}
.image-icon-info {
  width: 14px;
  height: 14px;
  fill: #222;
}
.mark-text-primary {
  color: #034694;
}
.popper-custom {
  background: #000;
  padding: 10px;
  border: none;
  box-shadow: none;
  color: white;
  text-align: left;
  font-size: 12px;
}

</style>