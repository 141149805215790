import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
import auth from '../middleware/furnishweb/auth'
import guest from '../middleware/furnishweb/guest'
import Welcome from '../furnishweb/views/Welcome'
import Scanner from '../furnishweb/views/Scanner'
import Login from '../furnishweb/views/Login'
const routes = [
    { path: '/login', name: 'login', component: Login, meta: { middleware: guest } },
    { path: '/', name: 'welcome', component: Welcome, meta: { middleware: [auth]}},
    { path: '/scanner', name: 'scanner', component: Scanner, meta: { middleware: [auth]}},
   
]

const router = new Router({
    mode: 'history',
    routes
})

function nextFactory(context,middleware,index) {
    const subsequentMiddleware = middleware[index]
    if(!subsequentMiddleware) return context.next
    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context,middleware,index + 1);
        subsequentMiddleware({...context,next : nextMiddleware})
    }
}

async function beforeEach(to, from, next){


    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
        const context = {
            from,
            next,
            router,
            to
        }
        const nextMiddleware = nextFactory(context, middleware, 1)
        return middleware[0]({ ...context, next: nextMiddleware })
    }

    return next()

}
router.beforeEach(beforeEach)

export default router
