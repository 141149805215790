export function userData() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return user;
    } else {
        return {};
    }
}

export function userRoles() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return user.user.roles;
    } else {
        return {};
    }
}
export function userRoleSlugs() {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        return user.user.role_slug;
    } else {
        return {};
    }
}
export function userIsAuthor(author_id) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.user_id === author_id) {
        return true;
    } else {
        return false;
    }
}

export function hasPermission() {
    return userRoleSlugs();
}