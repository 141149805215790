import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


import { AuthModule } from './fw/auth.module'
import { ProductModule } from './fw/product.module'
import { AppModule } from './modules/app.module'
import { LayoutModule } from './modules/layout.module'
import { ShowroomsModule } from './modules/showrooms.module'
import { CartModule } from './fw/cart.module'
import { CustomerModule} from './fw/customer.module'
import { UserModule} from './fw/user.module'
export default new Vuex.Store({
    modules : {
        AppModule,
        AuthModule,
        LayoutModule,
        ShowroomsModule,
        ProductModule,
        CartModule,
        CustomerModule,
        UserModule 
    },
    getters : {
        
    }
})