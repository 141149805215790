<template>
  <div class="itmform-container">
  
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="PUSH TO SAP" windowClass="collection height-auto" scrollClass='collection height-auto' :height="800" :width="900" :formStatus="'create'" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                 <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="splitInvoice.length <= 0">PUSH TO SAP</mdb-btn>
                </template>
            </template>
            <div class="mt-1 pl-3 pr-3">
                <ThatsTextarea :showLabel="false" fieldClass="col-sm-12" label="Invoices" vid="invoices" name="invoices" v-model="form.invoices"></ThatsTextarea>

              <el-table border :data="success" class="input-table mt-0" style="width: 100%">

                 
                      <el-table-column label="Series" :width="120" align = "right">
                        <template slot-scope="scope">
                            {{scope.row.series}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Status" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{(scope.row.success === true) ? 'Success' : 'Failed'}}
                      </template>
                      </el-table-column>
                  </el-table>
            </div>

       </ThatsWindow>
    </ValidationObserver>
     
      
  </div>
</template>
<script>
import { mdbClickOutside , mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox, ThatsCustomWindow, HistoryWindow} from '../../components/thats/Thats'


export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  //#region[VueComponent]
    components: {
      
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect, 

        
     

  },
  //#endregion
    
    data : () => ({
          form : {
            invoices : null
          },
          formLoading : false,
          validated : false,
          success : []
    }),
    

  created : function() {
      var self = this;
    


    },

  mounted(){
  },

  updated(){
 
  },
  //#region[VueMethod]
    methods: {
      ...mapActions('InvoiceModule',['multipleIntegration']),
      openDialog(){
        this.$refs.window.openDialog();
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      dialogState(){
       this.form.invoices = null;
        
      },
      onSubmitForm(){
          const self = this;

          const completed = [];

          if(this.formLoading === false){
            this.formLoading = true;
            
            if(this.form.invoices !== null && this.form.invoices !== ""){

              Promise.all(this.splitInvoice.map(async (element) => {
                await this.multipleIntegration({series : element.series}).then(() => {
                            self.success.push({series :  element.series, success : true})
                  // console.log("response")
                  // console.log(res)
                           /*  const index = self.splitInvoice.findIndex(item => item.series === res.post_data.series);
                              self.$set(self.splitInvoice[index],'success', true); */

                              if(self.splitInvoice.length === self.success.length){
                                self.formLoading = false;
                              }

                       }).catch((err) => {
                          if(self.splitInvoice.length === self.success.length){
                                self.formLoading = false;
                              }
                       });
              }));

                 /* this.splitInvoice.forEach(async (element) => {
                       await this.multipleIntegration({series : element.series}).then((res) => {
                            console.log(res);
                       });
                }); */
            }

          }

         
          

      }
    },
//#endregion
//#region[VueComputed]
    computed: {

          splitInvoice : function(){

            if(this.form.invoices !== null && this.form.invoices !== ""){
              const splitted =  this.form.invoices.split(",");

              return splitted.map((res) => {
                return {series : res.replace(/ /g, ""), success : false}
              })
            }
            return [];
          }
      
    },
//endregion
    watch: {
     

    },

   


}
</script>
