import Axios from 'axios'
import { bearer, token } from '../helpers'
//spelling sir
export const VendorsService = {
    saveImport(object) {
        return Axios.post(`/api/vendors/save-import`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },
    async store(object) {
       
        return await Axios.post(`/api/vendors`, object, { headers: bearer() }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },


    async get(endpoint = '', params) {
        console.log(endpoint)
        return await Axios.get(`/api/vendors${endpoint}`, { params: { ...token(), ...params } }).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.reject(error)
        })
    },

    async put(obj) {
        return await Axios.put(`/api/vendors`, obj, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    async deleteSelected(obj) {
        return await Axios.delete(`/api/vendors`, {
            data: {
                tokens: obj
            }, headers: bearer()
        })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

}