import { ApiGetService } from '../../services'
export const ItemBrandModule = {
    namespaced: true,
    state: {
        request: null,
        loading: false,
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        multipleSelection: [],
        data: [],
    },
    actions: {
        getItemBrand({commit }, params = {}){
            commit('loadingFalse')
            return new Promise((resolve,reject) => {
                commit('loadingTrue')
                const configParams = {
                    req : "ItemBrandModule/req",
                    loadingTrue : "ItemBrandModule/loadingTrue",
                    addRequest : "ItemBrandModule/addRequest",
                    cancelRequest : "ItemBrandModule/cancelRequest",
                    clearOldRequest : "ItemBrandModule/clearOldRequest"
                }
                ApiGetService.get('item-brand', params, configParams).then((response) => {
                    resolve(response.data)
                }).catch((err) => reject(err))
            })
        
        },


    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        setData(state, payload) {
            state.data = payload
        },
       
        setLength: (state, payload) => {
            state.length = payload
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data = []
        },

    },
    getters: {
        loading: state => state.loading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.Filters,
        req: state => state.request,
    }
}


