import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const OnlineApplicationModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [

                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '100',

                },
                {
                    label: 'Applicant Name',
                    field: 'full',
                    clickable: true,
                    sort: 'asc',
                    width: '250',

                },

                {
                    label: 'Phone',
                    field: 'mobile',
                    sort: 'asc',
                    width: '250',

                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',

                },
                {
                    label: 'Position/Title',
                    field: 'title',
                    sort: 'asc',
                },
/* 
                {
                    label: 'Resume',
                    field: 'path',
                    width: '70',
                }, */


                {
                    label: 'Status',
                    field: 'status_text',
                    align: 'right',
                    width: '200',

                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: null,

        formStatus: 'create',
        endpoint: 'online-application',
        formRecord: {},

    },
    actions: {

     
        async GetNextCode({ rootState, dispatch }) {
            const { endpoint } = rootState.OnlineApplicationModule
            return await new Promise((resolve) => {
                ApiService.get(`${endpoint}/code`, {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { loading, length, page, filters, searchTerm, endpoint } = rootState.OnlineApplicationModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get(`${endpoint}/data`, params).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        //reject(error)
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        StoreRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.OnlineApplicationModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store(`${endpoint}`, payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.OnlineApplicationModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update(`${endpoint}`, payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const { endpoint, Deleteloading } = rootState.OnlineApplicationModule;
                if (!Deleteloading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete(`${endpoint}`, tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
    }
}


