/* eslint-disable no-prototype-builtins */
<template>
<div>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" @submit.prevent="onSubmitForm">
    <el-dialog title="Business Partner Master Data" :visible.sync="dialogVisible" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form" ref="window" :show-close="!formLoading">
      <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" :key="eventKeyCounter">
              <template :slot="'form-slot'">
                <div class="filter-dalog-body p-4">
                    <ThatsFieldset>
                      <ThatsSeriesInput labelClass="col-md-3" fieldClass="col-md-3 pl-0" label="Contact ID" placeholder="Contacts Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" size="sm" :disabled="true" />                 

                      <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><el-checkbox v-model="form.attr.corporate">Corporate Customer</el-checkbox></mdb-col></mdb-row>

                      <template v-if="form.attr.corporate">
                      <ValidationProvider vid="name" name="name" :rules="'required'" v-slot="{errors}" :key="'corporateValidationProvider'+corportKey">
                      <ThatsInput :key="'corportateName'+corportKey" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" />
                      
                      <template v-if="errors.length > 0">
                                <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                      </ValidationProvider>
                      </template>
                      <template v-else>
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-3  pl-0" label="Salutation" v-model="form.attr.salutation" :reduce="label => label.code" :reduceLabel="'label'" :options="salutation" :rules="''"></ThatsMultiSelect>
                      <ValidationProvider vid="name" name="name" :rules="'required'" v-slot="{errors}" :key="'customer'+corportKey">
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="First Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" />
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Middle Name" vid="middle_name" name="middle_name" v-model="form.attr.middle_name" :validated="validated" :rules="''" size="sm" />
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Last Name" vid="last_name" name="last_name" v-model="form.attr.last_name" :validated="validated" :rules="'required'" size="sm" />
                      <template v-if="errors.length > 0">
                                <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                      </ValidationProvider>
                      </template>

                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Email" vid="email" name="email" v-model="form.attr.email" :validated="validated" :rules="''" size="sm" />

                      </ThatsFieldset>

                      <div class="card card-fieldset mt-2">
                        <div class="card-body">
                            <ValidationProvider vid="attr.contacts.0.value" name="attr.contacts.0.value" :rules="'required'" v-slot="{errors}">
                                <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Primary Number" vid="attr.contacts.0.valuex" name="attr.contacts.0.valuex" v-model="form.attr.contacts[0]" :rules="'required'" :show_error="true"></InternationalMobile>
                                  <template v-if="errors.length > 0">
                                    <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                  </template>
                              </ValidationProvider>
                            <ValidationProvider vid="attr.contacts.1.value" name="attr.contacts.1.value" :rules="''" v-slot="{errors}">
                                <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Secondary Number" vid="attr.contacts.1.value" name="attr.contacts.1.value" v-model="form.attr.contacts[1]" :rules="''"></InternationalMobile>
                                  
                                  <template v-if="errors.length > 0">
                                      <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                  </template>
                              </ValidationProvider>
                              
                          <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Home Tel Number" vid="attr.contacts.2.value" name="attr.contacts.2.value" v-model="form.attr.contacts[2]" :rules="''"></InternationalMobile>
                        </div>
                      </div>

                      <div class="card card-fieldset mt-2">
                          <div class="card-body">
                          <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="form.attr.address" :validated="validated" :rules="''" size="sm" />

                          <mdb-row >
                              <mdb-col offset="3" col="3">
                                <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Bldg" vid="bldg" name="bldg" v-model="form.attr.address2.building" :validated="validated" :rules="''" size="sm" />
                              </mdb-col>
                              <mdb-col col="3">
                                <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Street" vid="street" name="street" v-model="form.attr.address2.street" :validated="validated" :rules="''" size="sm" />
                              </mdb-col>
                              <mdb-col col="3">
                                <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Zone" vid="zone" name="zone" v-model="form.attr.address2.zone" :validated="validated" :rules="''" size="sm" />
                              </mdb-col>
                          </mdb-row>
                          <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Area" vid="area" name="area" v-model="form.attr.address2.area" :validated="validated" :rules="'required'" size="sm" />
                          <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="City" v-model="form.attr.address2.city" :reduce="label => label.code" :reduceLabel="'label'" :options="cities" :rules="'required'"></ThatsMultiSelect>

                        </div>
                      </div>

    
                  </div>  
              </template>


              <template :slot="'form-contact-person'">
                <div class="filter-dalog-body p-4">
                <mdb-btn type="button" color="elegant" size="sm" @click="newContactPerson">New Contact Person</mdb-btn>
                <el-table ref="multipleTable" lazy class="custom-element-table" :data="form.attr.contact_persons" stripe>
                    <el-table-column prop="mobile" label="Phone/Mobile" width="180"></el-table-column>
                    <el-table-column prop="name" label="Contact Name"></el-table-column>
                    <el-table-column label="" width="80" align="right">
                      <template slot-scope="scope">
                      <mdb-icon class="cursor-pointer" far icon="edit" color="warning" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'update')"/>
                      <mdb-icon class="cursor-pointer" far icon="trash-alt" color="danger" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'delete')"/>
                    </template>
                    </el-table-column>
                      <template slot="empty">
                          No Data
                      </template>
                  </el-table>
                  </div>
              </template>
              
             
            </mdb-tabs>


            

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">

                  <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="onSubmitForm"><i class="el-icon-save"></i> Save</mdb-btn>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="dialogVisible = false"><i class="el-icon-close"></i> Close</mdb-btn>
                      </template>

                      
                </div>
            </div>
        </el-dialog>
  </ValidationObserver>
  <BusinessPartnerContactPersonDialog ref="contactPersionWindow" @change="onChangeContactPerson"></BusinessPartnerContactPersonDialog>
</div>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'
import { MessageBox } from 'element-ui';
import XWindow from '../../components/thats/XWindow.vue'
import InternationalMobile from '../../components/thats/InternationalMobile.vue'
import { Loading } from 'element-ui';

import BusinessPartnerContactPersonDialog from "./BusinessPartnerContactPersonDialog.vue";
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : [],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        ThatsSeriesInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        InternationalMobile,
        BusinessPartnerContactPersonDialog
  },
    
  data(){

    const self = this;

    return {

        dialogVisible : false,
        isIndeterminate : false,
        checkAllReceived : false,
        showError : true,
        disabled : false,
        onPasteLoading : false,
        isMaximized : false,
        inputValue : 0,
        validated : false,
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 900,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,

        status : [
            { status : "Active", code : "active"},
            { status : "Inactive", code : "inactive"}, 
          ],

        salutation : [
              { label : "Mr.", code : "Mr."},
              { label : "Ms.", code : "Ms."}
            ],

        cities : [
          {label :"Doha",code : "Doha"},
          {label :"Al-Rayyan",code : "Al-Rayyan"},
          {label :"Al Khor",code : "Al Khor"},
          {label :"Al Wakrah",code : "Al Wakrah"},
          {label :"Umm-Salal",code : "Umm-Salal"},
          {label :"Al-Dayian",code : "Al-Dayian"},
          {label :"Ash-Shamal",code : "Ash-Shamal"}
        ],
        tabs : [
                { text: 'Contact Details', slot: 'form-slot' },
                { text: 'Contact Persons', slot: 'form-contact-person' },
              ],
        gallery : [],
        categoryOptions : [],
        index : 0,
        corportKey : 0,
       
    }
  },
    

  created : function() {
        
        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.resetForm();
        
  },




  methods: {
    ...mapMutations('BusinessPartnerModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapActions('BusinessPartnerModule',['storeRecord','updateRecord','getRecord']),
    ...mapMutations('ContactPersonModule',{
          contactPersonCreate : 'SetFormStatusCreate',
          contactPersonUpdate : 'SetFormStatusUpdate'
      }),
    onResize({width,height}){  

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      },
    openDialog(){
        // this.$refs.window.openDialog()

        this.dialogVisible = true
    },
    closeDialog(){
      // this.$refs.window.closeDialog()
      this.dialogVisible = false
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(){
       const self = this;
       
            self.$refs.observer.validate().then((isValid) => {
              if(isValid){
                  self.formLoading = true
                  
                  if(this.formStatus == 'create'){
                    self.storeRecord(self.form).then((res) => {
                      self.showError = false
                      self.formLoading = false
                      self.$ThatsNotify.success({
                          message : "Record successfully added."
                        })
                        self.closeDialog()
                        self.$emit('success')
                    }).catch((error) => {
                        self.showError = true
                        self.formLoading = false
                        if(error.response.data.hasOwnProperty('errors')){
                          self.$refs.observer.setErrors(error.response.data.errors);
                        }
                    })
                  }

                  if(this.formStatus == 'update'){
                    self.updateRecord(self.form).then((res) => {
                      self.showError = false
                      self.formLoading = false
                      self.$ThatsNotify.success({
                          message : "Record successfully updated."
                        })
                        self.closeDialog()
                        self.$emit('success')
                    }).catch((error) => {
                        self.showError = true
                        self.formLoading = false
                        if(error.response.data.hasOwnProperty('errors')){
                          self.$refs.observer.setErrors(error.response.data.errors);
                        }
                    })
                  }
                }
            });
    },

    cellClassName(){

    },

     

      dialogState(newState){
        if(!newState){
           
            
            this.resetForm();
          return
        }
        
      },

      openCategory(){
        this.$refs.itemMasterCategoryDialog.openDialog();
      },

      categorySuccess(value){
        console.log(value)

        this.categoryOptions.push({
          area : 0,
          brand : 0,
          attributes : [],
          label : value.data.name,
          category : value.data.name,
          code : value.data.id,
          value : value.data.id,
        })

        this.form.category = value.data.id

        this.$nexttick (() => this.$refs.observer.reset ());
      },

      newContactPerson(){
          this.contactPersonCreate();
          this.$refs.contactPersionWindow.openDialog();
      },

      fnOpenContactPersonWindow(index,row,status){
        var self = this;
        console.log(row)
        if(status === 'delete'){
            MessageBox.confirm('Are you sure you want to delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.form.attr.contact_persons.splice(index, 1);
                        self.$ThatsNotify.success({
                            message : "Item has been successfully removed"
                          })
                    })
        }else{
            this.contactPersonUpdate({
              ...row,
              index : index
            });

            this.$refs.contactPersionWindow.openDialog();
        }
      },
      onChangeContactPerson(data){
          if(data.hasOwnProperty('index')){
              this.form.attr.contact_persons.splice(data.index, 1, data)
          }else{
              this.form.attr.contact_persons.push(data)
          }
      } 

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('BusinessPartnerModule',['getLoading','formData','formStatus','initialFormObject']),
      tblKey : function(){
        return 1;
      },
     
  },
  watch: {
      'formData' : function(v){
        const self = this
        this.eventKeyCounter++
          this.form = JSON.parse(JSON.stringify({...this.initialFormObject,...v}));
          this.$nextTick(()=>{

          
          })
      },

      "form.attr.contacts" : {
          handler(v){
          // console.log("watch")
          // console.log(v)

            const firstObject  = v[0];

            if(firstObject){
                this.form.mobile = firstObject.value
            }
        },
        deep : true
      },

      "form.attr.salutation" : function(v){
        
          let name = '';
          name += (v != "" && v !== null) ? v : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
        
      },
      "form.attr.first_name" : function(v){

        let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (v != "" && v !== null) ? " "+v : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
      },
      "form.attr.middle_name" : function(v){

         let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (v != "" && " "+v !== null) ? " "+v : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
      },
      "form.attr.last_name" : function(v){
         let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (v != "" && " "+v !== null) ? " "+v : '';

          this.form.name = name.trim();
      },

      "form.attr.corporate" : function(value){
        this.corportKey = this.corportKey + 1
        this.form.attr.last_name = null
        this.form.attr.salutation = null

        
      
        // alert(value);
      }
      


  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>