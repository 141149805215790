<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">

        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" class="col-sm-3 col-form-label" :class="labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-input v-focus :ref="$attrs.vid" outline :is-invalid="invalid" active :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" v-model="inputValue" :wrapperClass="wrapperClass" :inputClass="hasError(errors) + ' p-3'" type="textarea" :rows="rows" autocomplete="off" noWrapper bg :disabled="disabled" :readOnly="readOnly" @blur="onBlur()"/>
                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>


import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'ThatsTextarea',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        wrapperClass : {
            type : String,
            default : ''
        },
        rows : {
            type : Number,
            default : 5
        },
        disabled : {
            type : Boolean,
            default : false
        },
        readOnly : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },
        
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        onBlur(){
            this.$emit("blur");
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>