/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" :title="(formStatus === 'create' ? 'New' : title)" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">


            <template #left-button>
              <template v-if="formLoading || getLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template>
            </template>
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="fnSave('save')">
            <div class="mt-1">
              <mdb-row class="tab-row">
                      <mdb-col col="3" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Vendor Code" vid="mobile" name="mobile" v-model="form.CardCode" size="sm" :disabled="true"/>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Name" vid="name" name="name" v-model="form.CardName" size="sm" :disabled="true"/>
                       </mdb-col>
                      <mdb-col col="2" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Discount" vid="DiscountPercent" name="DiscountPercent" v-model="form.DiscountPercent" size="sm" :disabled="true"/>
                        <ThatsMoney :money="{ decimal: '.', thousands: ',', precision: 2, masked: false,prefix : `${form.DocCurrency} ` }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="DocTotal" vid="doctotal" name="doctotal" v-model="form.DocTotalFc" size="sm" :disabled="true" :rules="{required : true}"/>
                      </mdb-col>
                      <mdb-col col="7" class="tab-col">
                        <mdb-row class="tab-row">
                          <mdb-col col="8" class="tab-col">
                            <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Vendor Ref. No." vid="ref" name="ref" v-model="form.NumAtCard" size="sm" :disabled="true"/>
                            <ThatsTextarea tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Comments" vid="textComment" name="textComment" :rows="1" v-model="form.Comments" size="sm" :disabled="false"/>
                            </mdb-col>
                          <mdb-col col="4" class="tab-col">
                            <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="DocDate" vid="docDate" name="docDate" v-model="form.DocDate" size="sm" :disabled="true"/>
                            <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Status" v-model="form.U_PoStatus" :reduce="label => label.code" :reduceLabel="'label'" :options="[{code : 'For Approval', label : 'For Approval'},{code : 'Approved', label : 'Approved w/ comments'}]" :rules="'required'" :filterable="false" :clearable="false" :disabled="false"></ThatsMultiSelect>
                          </mdb-col>   
                          
                        </mdb-row>
                      </mdb-col>
                    </mdb-row>  


                    <!-- {{form.items}} -->

                    <div ref="spreadsheetexport" class="demo-x"></div> 

                    <div class="d-flex">

                      <template v-if="formLoading || getLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>

                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save'))">Save</mdb-btn>
                             <!--  <template v-if="formStatus == 'create'">
                              </template>
                              <template v-if="formStatus == 'update'">
                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save'))" :disabled="(form.ExchangeRate <= 0 ? true : false)">Save</mdb-btn>

                                  <mdb-dropdown class="ml-0 ui-dropup"> 
                                        <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">Save as...</mdb-dropdown-toggle>
                                        <mdb-dropdown-menu v-bind:style="{transform : 'translate3d(0px, -149px, 0px)!important'}">
                                            <mdb-dropdown-item @click="updateAndSave('for-review')">For Accounts Review</mdb-dropdown-item>
                                            <mdb-dropdown-item @click="updateAndSave('for-approval')">For Approval</mdb-dropdown-item>
                                            <mdb-dropdown-item @click="updateAndSave('approved')">Approved</mdb-dropdown-item>
                                        </mdb-dropdown-menu>
                                    </mdb-dropdown>
                    
                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save-export'))" :disabled="(form.ExchangeRate <= 0 ? true : false)">Save & Export</mdb-btn>
                              </template> -->
                              <mdb-btn color="grey" size="sm" class="mt-0" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>

                  </div>
              
              
            </div>
            </ValidationObserver>
        <ApplyAmount ref="applyAmount" :group="(group + 1)" @apply="fnApplyAmount"></ApplyAmount>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import axios from 'axios'
import money from 'v-money'
import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'
import ApplyAmount from './ApplyAmount.vue'
import AmountInput from '../../components/thats/AmountInput.vue'
function demo(){
  return 0
}

const lorem = 50



window['SUMCOL'] = function(instance,columnId) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    }
    return formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['CUSTOMSUM'] = function(instance, row,column) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  /* var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    } */
    return formatMoney(row+column, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}

window['EXCHANGERATE'] = 0;

window['FORMATVALUE'] = function(value) {
  
    return formatMoney(value, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['COSTPRICE'] = function(e,l,m,n,o) {

  let currencySymbol = ''
  let unitPrice = unformat(e)

              if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.TxtLineTotal.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]
              }


      const freightCharge = unformat(l)
      const customDuty = unformat(m)
      const customClearance = unformat(n)
      const shippingLine = unformat(o)
      
    return formatMoney((unitPrice + freightCharge + customDuty + customClearance + shippingLine), { symbol: currencySymbol , precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICE'] = function(c,n,o) {

  

  const costPrice = unformat(n)
    const multiplier = unformat(o)
    let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]

              }
 
    return formatMoney((costPrice * multiplier), { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICEQAR'] = function(n,o) {
  const costPrice = unformat(n)
      const multiplier = unformat(o)
   const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];

   


    return formatMoney((costPrice * multiplier) * exchangeRate, { symbol: "QAR ", precision: 2, thousand: ",", decimal: "." });
}
window['MULTIPLIERWIHOUTCOST'] = function(c,n,o) {

  // const unitPrice = unformat(c)
  //     const freightCharge = unformat(j)
const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];
  const unitPrice = unformat(c)
      const unitCostPrice = unformat(n)
      const multiplier = unformat(o)

      const rp = (unitCostPrice * multiplier) * exchangeRate

      let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
          const stringSegment = c.trim().split(/\s+/);
          currencySymbol = stringSegment[0]
      }
/* 
console.log("MULTIPLIERWIHOUTCOST")
      console.log(unitPrice)
      console.log(unitCostPrice)
      console.log(multiplier)
      console.log(rp)
      console.log((rp / unitPrice/ exchangeRate)) */

    let multiplierWihtoutCost = 0
    if(multiplier > 0 && rp > 0){
      multiplierWihtoutCost = ( rp / unitPrice/ exchangeRate)
    }
  
    return formatMoney(multiplierWihtoutCost, { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}



// console.table(window);

const onChange = function(instance){
  console.log(instance)
}

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ApplyAmount,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        data : [],
        form : {

            CardCode : null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            DocCurrency : 'QAR',
            CurrencySymbol : null,
            ExchangeRate : 0,

          attr : {
            FreightCharge : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                CustomDuty : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                CustomClearance : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                ShippingLine : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                Remarks : null,
                Multiplier : {
                    active : false,
                    value : 0
                },
                StandardDiscount : {
                    active : false,
                    value : 0
                }
          },
          items :[]
        },
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 230px)',
            tableOverflow : true,
             data:[],
             raw:[
              {code : 3,name : 'testingx'}
             ],
             minDimensions:[6,20],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 3,
             columns: [],

           
             footers: [['','','','','','TOTAL','=SUMCOL(TABLE(),6)']],

            updateTable:function(instance, cell, col, row, val, label, cellName) {


              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
              
            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                          alert(1)
                            obj.download(true);
                        }
                    });
                } */

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });
             }
                return items;
             },

             onblur: function(instance){
              // console.log(instance)
              // console.log("blur")
              // self.fnApplyAmountSetValue(16, 0, 343434)
             },
             onchange: function(instance, cell, x, y, value){
                
                // console.log("change")
             },
             onafterchanges(){
              //  console.log("after change")
              //  self.fnApplyAmountSetValue(16, 0, 343434)
              
             }

            /*  footers: [['','','TOTAL','=SUMCOL(TABLE(),3)','','','=SUMCOL(TABLE(),6)','=SUMCOL(TABLE(),7)','=SUMCOL(TABLE(),8)','=SUMCOL(TABLE(),9)','=SUMCOL(TABLE(),10)','=SUMCOL(TABLE(),11)','=SUMCOL(TABLE(),12)'],''], */
        },

        saveAsDropUpIsOpen : false
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

        window['EXCHANGERATE'] = 0;
  },

  mounted(){
     /*  this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, this.exportTableOptions);
      Object.assign(this, this.spreadsheetexport); */

      
    console.log(this.$route)
  },

  updated(){

  },
  methods: {

    ...mapActions('SapPurchaseOrderModule',['resetData','storeRecord','updateRecord']),
    ...mapMutations('SapPurchaseOrderModule',['setSearchTerm','setEmptyForm']),
    ...mapMutations('ApplyAmountModule',{
       setApplyAmountFormData : 'setFormData'
    }),

    demo(){
      console.log("demo")
      return 0;
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
        
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },

    fnLoadPurchaseOrder(){
        const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.resetData('get-purchase-orders').then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
           
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
    },

    createPopupTable(exportTableOptions){

          console.log("Watch form")
        console.log(this.form.items)


            exportTableOptions.data = this.form.items
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.form.items)

            console.log("Table created")
            console.log(this.spreadsheetexport)
            console.log(this.exportTableOptions)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        fnSave(status){
            const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){

                const tableData = this.spreadsheetexport.getData();



                let itemData = [];

                  if(tableData.length > 0){

                    const fields = [
                      'ItemCode',
                      'Picture',
                      'ItemDescription',
                      'UnitPrice',
                      'Quantity',
                      'DiscountPercent',
                      'RowTotalFC',
                      'U_Line',
                      'ItemDetails',
                      'Currency',
                      'DocEntry',
                      'LineTotal',
                      'Rate',
                      'Volume',
                      'Price',
                      'LineNum',
                      'DocEntry',
                      'Image'
                    ];

                    itemData = tableData.filter((item)=>{
                                  return item[0] !== ''
                                }).map((i)=>{  
                                  const items = {}
                                  i.forEach((value,index) => {
                                        items[fields[index]] = value;
                                    })
                                return items;
                              },[])


                  }


                  


                  if(self.formLoading === false){
                      self.formLoading = true;
                       self.updateRecord({...self.form,items : itemData }).then((res) => {
                              console.log(res);
                              self.formLoading = false;
                              self.$ThatsNotify.success({
                                message : "Record successfully updated"
                              })
                              self.closeDialog();
                              self.$emit('success',true)
                        }).catch((err) => {
                            self.formLoading = false;
                            console.log(err)
                        });

                      /* if(this.formStatus === 'update'){

                        console.log('update')
                        self.updateRecord(self.form).then((res) => {
                              console.log(res);
                              self.formLoading = false;
                              if(status === 'save-export'){
                                
                                console.log(res)
                                self.fnNewWindow('export/'+res.id)
                              }

                              let notifyMessage = 'Record was successfully created'

                              if(self.formStatus === 'update'){
                                  notifyMessage = 'Record was successfully updated'
                              }

                              self.$ThatsNotify.success({
                                message : notifyMessage
                              })
                        }).catch((err) => {
                            self.formLoading = false;
                            console.log(err)
                        });
                      } */

                      
                  }



            }

        },

        fnNewWindow(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          window.open(`${self.$route.path}/${url}`, "_blank");
        },
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },

      


        fnOpen(v){

           const getData = this.spreadsheetexport.getData();
           const DocTotal = getData.map((i) => unformat(i[33]),[]).reduce((a,b) => a +b,0)


           const totalVolume = getData.map((item) => {
                return unformat(item[6]) * item[4];
            },[]).reduce((a,b) => a +b, 0);


            const applyTo = {
                "freightCharge" : "Freight Charge",
                "customDuty" : "Custom Duty",
                "customClearance" : "Custom Clearance",
                "shippingLine" : "Shipping Line"
            }


            // alert(`${v} --- ${applyTo[]}`)
              let option = {
                active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
              }

            if(v === 'freightCharge'){
                  option = this.form.attr.FreightCharge
              }
              if(v === 'customDuty'){
                  option = this.form.attr.CustomDuty
              }
              if(v === 'customClearance'){
                  option = this.form.attr.CustomClearance
              }
              if(v === 'shippingLine'){
                 option = this.form.attr.ShippingLine
              }


            const applyAmountFormData = { TotalVolume : totalVolume, DocTotal : DocTotal,applyTo : {code : v, name : applyTo[v]}, Option: JSON.parse(JSON.stringify(option))}

            console.log("OnOpen")
            console.log(applyAmountFormData)
            this.setApplyAmountFormData(applyAmountFormData);
            this.$refs.applyAmount.openDialog();
        },

        fnApplyAmount(v){
            console.log("onApply")
            console.log(v)
              if(v.applyTo.code === 'freightCharge'){
                  this.form.attr.FreightCharge =  {...this.form.attr.FreightCharge,...v.Option}
              }
              if(v.applyTo.code === 'customDuty'){
                  this.form.attr.CustomDuty = {...this.form.attr.CustomDuty,...v.Option}
              }
              if(v.applyTo.code === 'customClearance'){
                  this.form.attr.CustomClearance = {...this.form.attr.CustomClearance,...v.Option}
              }
              if(v.applyTo.code === 'shippingLine'){
                  this.form.attr.ShippingLine =  {...this.form.attr.ShippingLine,...v.Option}
              }
        },

        fnApplyAmountSetValue(x, y, value){
            // console.log(this.spreadsheetexport)

            this.spreadsheetexport.setValueFromCoords(x,y,value)
        },

        updateAndSave(status){
          this.fnSave(status)
        }

        

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('SapPurchaseOrderModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      },
      title : function(){
          return `PO#${this.form.DocNum}`
      }
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        // this.form = {...v, items : valueData};
        this.form = v;

        
        this.CurrencySymbol = v.CurrencySymbol
        const data = [
          {item_code : "serial" , item_name : "barcode"}
        ]

      
           
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

       
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{

                 console.log("newState")
        console.log(newState)
        console.log(this.form)






        this.exportTableOptions.columns = [

                  {
                    type : 'text',
                    width : '200px',
                    align : 'left',
                    name : 'ItemCode',
                    title : 'ItemCode',
                     readOnly: true
                  },
                  {
                    type : 'html',
                    width : '80px',
                    name : 'Picture',
                    title : 'Picture',
                    readOnly: true,
                    primaryKey: false,
                    align : 'center'
                  },
                  {
                    type : 'html',
                    width : '400px',
                    align : 'left',
                    name : 'ItemDescription',
                    title : 'Description',
                    readOnly: true,
                  },
                    {
                      type : 'text',
                      width : '150px',
                      align : 'right',
                      name : 'UnitPrice',
                      title : 'UnitPrice',
                      readOnly: true,
                      mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                      decimal:'.',
                    },
                  {
                    type : 'text',
                    width : '100px',
                    align : 'center',
                    name : 'Quantity',
                    title : 'Quantity',
                    readOnly: false,
                  },

                  {
                    type : 'text',
                    width : '110px',
                    align : 'center',
                    name : 'DiscountPercent',
                    title : 'DiscountPercent',
                    readOnly: false,
                  },
                /*   {
                    type : 'text',
                    width : '100px',
                    align : 'left',
                    name : 'Price',
                    title : 'Price',
                    readOnly: true,
                     mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                      decimal:'.',
                  }, */
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'RowTotalFC',
                    title : 'RowTotalFC',
                    readOnly: true,
                     mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                      decimal:'.',
                  },
                 
                   {
                    type : 'text',
                    width : '300px',
                    align : 'left',
                    name : 'U_Line',
                    title : 'Remarks'
                  },
                   {
                    type : 'hidden',
                    name : 'ItemDetails',
                    title : 'ItemDetails'
                  },

                   {
                    type : 'hidden',
                    name : 'Currency',
                    title : 'Currency'
                  },

                   {
                    type : 'hidden',
                    name : 'DocEntry',
                    title : 'DocEntry'
                  },
                   {
                    type : 'hidden',
                    name : 'LineTotal',
                    title : 'LineTotal'
                  },
                   {
                    type : 'hidden',
                    name : 'Rate',
                    title : 'Rate'
                  },
                   {
                    type : 'hidden',
                    name : 'Volume',
                    title : 'Volume'
                  },
                   {
                    type : 'hidden',
                    name : 'Price',
                    title : 'Price'
                  },
                   {
                    type : 'hidden',
                    name : 'LineNum',
                    title : 'LineNum'
                  },
                   {
                    type : 'hidden',
                    name : 'DocEntry',
                    title : 'DocEntry'
                  },
                   {
                    type : 'hidden',
                    name : 'Image',
                    title : 'Image'
                  },
                
               

            ];


            // console.log(this.exportTableOptions.columns.map((i) => i.name))
                this.createPopupTable(this.exportTableOptions);

               
              }
        },

        "form.ExchangeRate" : function(v){
           window['EXCHANGERATE'] = v;
            
        }


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>