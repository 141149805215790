<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">

              <el-dialog :title="'Issue Voucher'" :visible.sync="syncModal" :close-on-click-modal="false" width="40%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">


           <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-3">
                <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Issue Date" placeholder="Issue Date" vid="date" name="date" v-model="form.validity_date" :validated="validated"  size="sm"/>
                <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Expiry" v-model="form.expiry" :reduce="label => label.code" :reduceLabel="'label'" :options="expiresDate" :rules="'required'"></ThatsMultiSelect>
              <ThatsAttribute  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Coupons" vid="coupons" name="coupons" v-model="form.coupons" :validated="validated" :rules="'required'" size="sm" :show_error="true"></ThatsAttribute>
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Reference Code #" vid="ref_code" name="ref_code" v-model="form.ref_code"   size="sm" />
              <ThatsInput  labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Store Reference #" vid="ref_store" name="ref_store" v-model="form.ref_store"   size="sm" />


            </mdbContainer>


                   <div class="d-flex">

                       <template v-if="formLoading">
                           <mdb-btn color="elegant" size="sm" disabled>
                               <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                               Loading...
                           </mdb-btn>
                       </template>

                       <template v-else>
                           <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                           <mdb-btn color="grey" size="sm" @click="closeDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                       </template>
                   </div>
           </el-dialog>

          </ValidationObserver>
      <ContactsForm ref="contactForm" :group="3" @success="contactSuccess" @update="contactUpdateSuccess" class="contacts-form-container"></ContactsForm>
      <ContactsFormUpdate ref="contactsFormUpdate" :group="3" @success="contactUpdateSuccess"></ContactsFormUpdate>


  </div>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,  ThatsCheckbox,  ThatsMultiSelect,ThatsDatePicker,ThatsCustomMultiSelect,ThatsAttribute} from '../../components/thats/Thats'
import ContactsForm from "../contacts/ContactsForm.vue";
import ContactsFormUpdate from "../transaction/ContactsFormUpdate.vue";

export default {
    components: {
      ThatsAttribute,
        ContactsFormUpdate,
        ContactsForm,
        ThatsCustomMultiSelect,
        ThatsDatePicker,
        mdbContainer,
        mdbBtn,
        ValidationObserver,
        ThatsInput,
        ThatsCheckbox,
        ThatsMultiSelect,



  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
        syncModal : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      showError : false,
      designation : [],
        expiresDate : [
            { label : "60 Days", code : "60"},
            // { label : "3 Months", code : "3"},
            // { label : "6 Months", code : "6"},
            // { label : "1 Year", code : "12"}
      ],

        isWalkin : false,
        customer : [],
      activeCustomer : {
        series : 0,
        name : null,
        mobile : null,
        reference : '',
        description : null,
        image : [],
        attr : {

          address: null,
          address2 : {
            street : null,
            building : null,
            zone : null,
            area : null,
            city : null,
          },
          email : null,
          origin : 'portal',
          first_name : null,
          middle_name : null,
          last_name : null,
          salutation : null,
          corporate : false,
          contacts : [
            {
              type : 'primary',
              intl : false,
              code : '+974',
              value : null
            },
            {
              type : 'secondary',
              intl : false,
              code : '+974',
              value : null
            },
            {
              type : 'home',
              intl : false,
              code : '+974',
              value : null
            }
          ],
          contact_persons : []
        },
      },
      disabled : false,
      isModal : false,
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      }

       this.optionsHandler();



        this.getRecord().then((res) => {
          console.log(res,"swaraj bhuyan")
            self.tiers = res.coupons;
        })
      /* this.designationFilter().then((response) => {

        self.designation = response
      }) */

     /*  self.GetShowrooms().then((response) =>{
         self.showrooms = response
        }) */


    },
  mounted() {

  },
  methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */

      ...mapActions('TireModule',[
        'asyncGetData'
      ]),
      ...mapActions('LoyaltyMemberIssueCouponModule',[
        'StoreRecord',
        'UpdateRecord',
      ]),
      ...mapMutations('LoyaltyMemberIssueCouponModule',[
        'SetFormStatusCreate',
      ]),
      ...mapActions('ContactsModule',[ 'GetContacts' ]),
    ...mapActions('CouponModule',[ 'getRecord']),

    ...mapActions('ContactsModule',{
      getContact : 'getRecord',
      storeContactRecord : 'StoreRecord',
    }),

      formVar(){
        return {
            customer_id : "",
            ref_code : "",
            ref_store : "",
            expiry : "",
            showroom : "",
            coupons : [],
            attr:{},
            validity_date: new Date().toISOString().substr(0, 10),
        }
      },
        /* start */



        optionsHandler(){
          var self = this;
        },
    openNewDialog(data, formData){
      this.syncModal = true
      if (data === 'issue-coupon') {
        this.isSelectDisabled = true;
      }
      this.form.tier_id = formData.customer.tier.tier_id
      this.form.card_id = formData.card_id
      this.form.card_number = formData.card_number
      this.form.next_tier = formData.next_tier.minimum
      this.form.current = formData.current_tier
      this.form = {
        available: data.available,
        redeem: data.redeemed_count,
        benefit_id: data.benefit_id,
        customer_id : formData.customer_id,
        benefit_type: data.tier_beneficiary.benefit_type,
        coupons:[],
        validity_date: new Date().toISOString().substr(0, 10)
      }
    },

        /* end */
        openDialog(){
            this.syncModal = true


        },
        closeDialog(){
            this.syncModal = false
          this.FormResetter();

        },

    refreshTable(){
      this.SetFilters(this.filterObj)
      this.resetData(this.endpoint);
    },

        closeNewDialog(){
            this.syncModal = false
          console.log("Close Model Issue Coupon")
          this.form.coupons = [];
          this.form.validity_date =  new Date().toISOString().substr(0, 10);
            // this.FormResetter();
        },

        getCustomerSelected(v){

            const self = this;
            const selectedCustomer = this.customer.filter(c => {
                return c.value === v;
            })[0];

            if(selectedCustomer !== null){
                this.form.attr.name = selectedCustomer.label
                this.form.attr.mobile = selectedCustomer.mobile
                this.form.attr.address = selectedCustomer.address
                this.form.attr.cx_series = selectedCustomer.series
                this.form.attr.corporate = selectedCustomer.attr.corporate

                this.activeCustomer = selectedCustomer

                let add = ""
                add += (selectedCustomer.attr.address != "" && selectedCustomer.attr.address !== null) ? selectedCustomer.attr.address  : "";

                add += (selectedCustomer.attr.address2.street != "" && selectedCustomer.attr.address2.street !== null) ? ","+selectedCustomer.attr.address2.street  : "";
                add += (selectedCustomer.attr.address2.building != "" && selectedCustomer.attr.address2.building !== null) ? ","+selectedCustomer.attr.address2.building  : "";
                add += (selectedCustomer.attr.address2.zone != "" && selectedCustomer.attr.address2.zone !== null) ? ","+selectedCustomer.attr.address2.zone  : "";
                add += (selectedCustomer.attr.address2.area != "" && selectedCustomer.attr.address2.area !== null) ? "/"+selectedCustomer.attr.address2.area  : "";
                add += (selectedCustomer.attr.address2.city != "" && selectedCustomer.attr.address2.city !== null) ? "/"+selectedCustomer.attr.address2.city  : "";



                this.displayAddress = add.trim();

            }

        },
        contactUpdateSuccess(v){
            const {mobile, name} = v
            const {address, email, address2, corporate} = v.attr
            this.form.attr = {...this.form.attr, address : address,address2 : address2, mobile : mobile, name : name, email : email, corporate : corporate}


            this.activeCustomer = v
            let ndx = null
            const con = this.customer.filter((e,index) => {
                ndx = index
                return e.value === v.mdbID
            })[0];

            const finalContact = {
                opt : name + " "+ mobile,
                label :name,
                value : con.value,
                mobile : mobile,
                address : address,
                attr : v.attr
            }



            if(ndx !== null){


                this.$set(this.customer,ndx,finalContact)
                let add = v.address

                add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
                add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";

                this.displayAddress = add.trim();

            }

        },
        contactSuccess(v){

            this.activeCustomer = v
            const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.attr.address, series : v.series, address2 : v.attr.address2,attr : v.attr }
            this.customer.unshift(contact);
            this.form.customer_id = v.mdbID
            this.form.mobile = v.mobile
            this.form.attr.name = v.name;
            this.form.attr.mobile = v.mobile;
            this.form.attr.address = v.attr.address;
            this.form.attr.cx_series = v.series;
            this.form.attr.address2 = v.attr.address2;
            this.form.attr.corporate = v.attr.corporate


            let add = v.attr.address

            add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
            add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
            add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
            add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
            add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";



            this.displayAddress = add.trim();


            // alert(v.name)
        },
      searchCustomer(v){
        const self = this;
        if(v !== ""){
          this.fnGetContacts({term : v})
        }
      },
      editCustomer(){
        const self = this;
        const {address,mobile, name,email} = this.form.attr

        const em = (typeof email === 'undefined') ? "" : email
        const addr = (typeof address === 'undefined') ? "" : address
        if(this.form.customer_id !== null){
          this.setFormContactStatusUpdate()
          this.getContact({mdbID : this.form.customer_id,mobile : mobile,address : addr, name : name, email : email}).then((response) => {

            const contactFormUpdate = {
              row : response
            }
            self.setFormRecordUpdate(contactFormUpdate)
          });
          this.$refs.contactForm.openDialog()
        }
      },
      openCustomer(){
        this.$refs.contactForm.openDialog();
      },
      fnGetContacts(params){
        const self = this;
        const contacts = []
        this.GetContacts(params).then((response) => {
          if(response.length > 0){
            response.forEach((element) => {
              contacts.push({
                ...element,
                opt : element.label + " " + element.mobile,
              })
            })
          }

        })

        self.customer = contacts;
      },
    getCustomerSelectedContactPerson(v){
      const self = this;

      console.log("Contact person")
      console.log(v)
      const selectedCustomerContactList = this.activeCustomer.attr.contact_persons.filter(c => {
        return c.mobile === v;
      })[0];

      if(v !== null && selectedCustomerContactList !== null){
        console.log("selectedCustomerContactList")
        console.log(selectedCustomerContactList)
        this.form.attr.contact_person = selectedCustomerContactList
      }else{
        this.form.attr.contact_person = {
          name : null,
          mobile : null,
          attr : {

            address: null,
            address2 : {
              street : null,
              building : null,
              zone : null,
              area : null,
              city : null,
            },
            designation : null,
            email : null,
            first_name : null,
            middle_name : null,
            last_name : null,
            salutation : null
          }
        }
      }
    },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false;

                              if (error.response) {
                                  if (error.response.status === 404) {
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  } else if (error.response.status === 422) {
                                      // Handle coupon not found error
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  }
                              } else if (error.request) {
                                  error.response.data.message = error.request;
                              } else {
                                  error.response.data.message = error.message;
                              }

                              return;
                          });

                      }

                      if(self.FormStatus == 'update'){

                          self.UpdateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();


                            }).catch((error) => {
                              self.formLoading = false;

                              if (error.response) {
                                  if (error.response.status === 404) {
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  } else if (error.response.status === 422) {
                                      // Handle coupon not found error
                                      self.$ThatsNotify.error({
                                          message: error.response.data.error
                                      });
                                  }
                              } else if (error.request) {
                                  error.response.data.message = error.request;
                              } else {
                                  error.response.data.message = error.message;
                              }

                              return;
                          });
                      }
                    }

            }
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false

      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
        }

      },



    },

    computed: {

      ...mapGetters('CustomerCouponModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      ...mapGetters('AuthModule',[ 'user', ]),

      enableEditButton : function(){
        return !["AMTM","AMDFC","TLKD","ECDFC","MPDFC",null,"null"].includes(this.form.showroom.prefix)
      },


    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){



          const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            this.form = {...this.form,...frm}
          this.getContact({mdbID : frm.customers.mdbID}).then((response)=>{
            self.activeCustomer = response
          })
            this.form.attr.name = frm.customers.name;

          const couponResponse = frm.coupons;

          if (couponResponse && couponResponse.length > 0) {
            // Transform coupons

            const transformedCoupons = couponResponse.map((coupon, index) => ({
              index,
              text: coupon.code,
              slug: coupon.code,
            }));

            // Assign the transformed coupons to this.form.coupons
            this.form.coupons = transformedCoupons;
          }
          this.form.ref_code = frm.reference_code;
          this.form.ref_store = frm.store_reference;
          this.form.expiry = frm.expiry_month_difference;
          this.form.id = frm.id;

          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();

        }
      },
        syncModal: function(newDialogIsOpen) {
            const self = this;
            if (!newDialogIsOpen) {
                this.closeNewDialog()
            }
        },

    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
.custom-z-index {
  z-index: 0 !important; /* Set the desired z-index */
}
</style>
