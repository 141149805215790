<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="PHYSICAL COUNT" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="800" :width="1300" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>

              <template v-if="viewLoading || formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="(formStatus == 'update' || formStatus == 'create')">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                    <mdb-btn color="elegant" size="sm" @click="fnPrint(`document/print/${form.id}`)" :disabled="viewLoading">Print</mdb-btn>
                   <!--  <mdb-btn color="elegant" size="sm" @click="fnPrint(`document/export/${form.id}`)" :disabled="viewLoading">Export to Excel</mdb-btn> -->
                  </template>                 
              </template>
              </template>

       
          <div class="mt-1 pl-3 pr-3">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

               <!--  <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">

                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Date" vid="date" name="date" v-model="form.date" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Document" vid="document" name="document" v-model="form.series" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Prepared By" vid="prepared_by" name="prepared_by" v-model="form.author" size="sm" :disabled="true"/>
                      
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsFile tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Attachment" vid="attachment" name="attachment" v-model="form.attachment" size="sm" />
                    </mdb-col>
                  </mdb-row>  -->


                
                <template v-if="form.document_bin.length > 0">

                  <div v-for="bin in form.document_bin" :key="bin.id">
                    <div class="bin-name"><strong>{{bin.name}}</strong></div>
                     <el-table :data="bin.item" :summary-method="getBinItemSummary" show-summary class="bin-table">
                           <el-table-column label="SQ" type="index" width="50"></el-table-column>
                           <el-table-column label="SKU">
                                <template slot-scope="scope">
                                  {{scope.row.sku.code}}
                                </template>
                           </el-table-column>
                           <el-table-column label="QTY" align="center" width="100">
                                <template slot-scope="scope">
                                  {{scope.row.total_qty}}
                                </template>
                           </el-table-column>
                           <el-table-column label="Remarks">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" />
                                </template>
                           </el-table-column>
                         </el-table>

                  </div>
                 
                </template>
              </template>
            </mdb-tabs>
            
            
          </div>
       </ThatsWindow>
          </ValidationObserver>
     
      
  </div>
</template>
<script>
import { mdbClickOutside , mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'



export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  //#region[VueComponent]
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect

  },
  //#endregion
    
    data : () => ({
      active : 1,
      validated : false,
      form : null,
      formOriginal : null,
      formLoading : false,
      
      selected : [],
      tabs : [
                { text: 'Document Details', slot: 'form-slot' }
              ],
              
    }),
    

  created : function() {
      var self = this;
      
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.tabs = [
                { text: 'Document Details', slot: 'form-slot' },
                
              ]
        
      }
       this.optionsHandler();
    
    },

  mounted(){
  },

  updated(){

  },
  //#region[VueMethod]
    methods: {
      ...mapActions('PhysicalCountModule',['updateRecord']),
      ...mapMutations('PhysicalCountModule',['setFormStatusCreate']),
      formVar(){
        return {
          series : null,
          name : "",
          status : "active",
          document_bin : [],
          attachment : [],
          author : null
                   
        }
      },
/* start */

    optionsHandler(){
      const self = this;
      
    },
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      onSubmitFormPurchaseRequest(){
            var self =this
            self.form.create_purchase_order = true;
            self.onSubmitForm();
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false


          
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      
                      if(self.formStatus == 'update'){
                        self.formLoading = true
                        
                          self.updateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                              self.setFormRecord(response)
                              self.formLoading = false

                            }).catch((error) => {

                              // console.log(error)
                              self.formLoading = false
                              // self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },
      FormResetter(){
        this.form = this.formVar()
       
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        
        this.tabs = [
                { text: 'Document Details', slot: 'form-slot' }
              ]
              

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
            this.tabs = [
                { text: 'Document Details', slot: 'form-slot' }
              ]
          
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      getActiveTabIndex(index){
        this.active = index
      },

      binCell({column}){
        console.log(column)
        return column.property
      },
      getBinItemSummary({columns,data}){

        console.log(data)
        const sums = [];
          columns.forEach((col,index) => {
               if (index === 1) {
                sums[index] = 'Total';
                return;
              }

              if (index === 2) {
              const qty = data.map(item => {
                  return parseInt(item.total_qty)
                })

                if (!qty.every(qty => isNaN(qty))) {
                    sums[index] = qty.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                      return prev + curr;
                    } else {
                      return prev;
                    }
                  }, 0);
                } 
              return;
            }
            
          })

          return sums;
      },

      fnPrint(url){
        // alert(s)

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(`https://docs.thatsliving.com/${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()
      }

    },
//#endregion
//#region[VueComputed]
    computed: {

      ...mapGetters('PhysicalCountModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
        'tblKey',
      ]),
     
      
    },
//endregion
    watch: {
     
      formRecord : function(v){
        const self = this;
        this.active = 0;
          this.disabled = false

        if(this.formStatus == 'update'){
                const jsonParse = JSON.parse(JSON.stringify(v));
                
                this.form = jsonParse;
                this.formOriginal = JSON.parse(JSON.stringify(jsonParse));

        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          
        }
      },
      
    }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}
.grid-input{
  padding : 0;height: 30px;border-radius: 0;
}

</style>