<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Sales Return/Exhange" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading || viewLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="isAllowed && active === 3">
                    <template v-if="form.status === 'approved'">
                        <template v-if="formOriginal.status === 'for-approval'">
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Approve Credit Note</mdb-btn>
                        </template>
                        <template v-else>
                          <mdb-btn color="elegant" size="sm" @click="passes(cancelCreditNote)">Cancel</mdb-btn>
                        </template>
                    </template>
                    <template v-if="formOriginal.status === 'for-approval'">
                        <mdb-btn color="elegant" size="sm" @click="passes(cancelCreditNote)">Cancel</mdb-btn>
                    </template>
                    <template v-if="formOriginal.status === 'approved' && formOriginal.action === 'refund' && formOriginal.used <= 0">
                        <mdb-btn color="elegant" size="sm" @click="passes(processRefund)">Process Refund</mdb-btn>
                    </template>
                      <!--   <template v-else>
                          <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                        </template> -->
                  </template>
                  <template v-if="formStatus == 'update' && formOriginal.status == 'approved'">
                    <mdb-btn @click="fnPrint('/sales-return/print/'+form.token_id)" color="elegant" size="sm">Print</mdb-btn>
                  </template>
                </template>

                <!-- <template v-if="selected.length > 0 && isAllowed">
                    <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                  </template> -->
              </template>

            <template #right-button>
              <template v-if="formStatus == 'update'">
                <template v-if="form.transaction.invoice.payments.length> 0">
                    <span class="invoice-balance">Total Balance : {{ $helpers.addSeparatorsNF(form.transaction.invoice.balance)}} QAR</span>
                </template>
              </template>

              
            </template>
          <template v-if="form.transaction.invoice.payments.length> 0">
            <span class="invoice-number">Sales Invoice #: {{form.transaction.invoice.series}}</span>
          </template>
          <mdbContainer class="mt-1">
           
            <mdb-tabs class="table-tabs mb-2 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
                <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">

                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date" vid="date" name="date" v-model="form.transaction.date" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.transaction.attr.name" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.transaction.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.transaction.attr.address" size="sm" :disabled="true"/>
                      
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.transaction.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />                  
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sold By" vid="sold_by" name="sold_by" v-model="form.transaction.sold_by" size="sm" :disabled="true" />
                        
                    </mdb-col>    
                  </mdb-row>

                  <el-table ref="transactionItemsTable" border :data="form.transaction.items" class="input-table mt-2" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" :row-key="getRowKey">
                      <el-table-column label="SKU" :width="150">
                        <template slot-scope="scope">
                         <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Name">
                        <template slot-scope="scope">
                          <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" :disabled="true" />
                        </template>
                      </el-table-column>
                        <el-table-column label="Qty" :width="70">
                          <template slot-scope="scope">
                            <input type="number" min="1" :max="scope.row.max" class="form-control grid-input thats-form-control" v-model="scope.row.qty" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Price" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Amount" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Discount" :width="90" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.discount" :disabled="true" />
                            
                          </template>
                        </el-table-column>
                        <el-table-column label="Total Amount" :width="120" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                          </template>
                        </el-table-column>
                      <el-table-column label="Remarks">
                        <template slot-scope="scope">
                          <div v-html="scope.row.remarks"></div>
                          <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="true" /> -->
                        </template>
                      </el-table-column>
                    </el-table>
                  

              </template>
              <template :slot="'invoice-slot'">
                 
                 <mdb-row class="tab-row">
                    <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.transaction.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.transaction.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.transaction.attr.address" size="sm" :disabled="true"/>
                    </mdb-col>    
                    <mdb-col col="6">
                      
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instruction" name="instruction" v-model="form.transaction.attr.instruction" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Other Details" vid="other_details" name="other_details" v-model="form.transaction.attr.other_details" size="sm" :disabled="true"/>
                    </mdb-col>    
                    
                  </mdb-row>
                  <el-table border :data="form.transaction.invoice.payments" class="input-table mt-2" style="width: 100%" :height="'250px'">
                    <el-table-column label="Series">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.series" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Date">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.date" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Mode of Payment">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.mode_of_payment" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Payment Description" width="160">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.payment_method" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Amount" :width="100">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" :value="scope.row.formatted_amount" :disabled="true" />
                      </template>
                    </el-table-column>
                     <el-table-column :width="100" label="Receipt">
                        <template slot-scope="scope">
                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                        </template>
                    </el-table-column>
                  </el-table>

              </template>
              <template :slot="'form-attachment'">
                   <mdbContainer>
                     <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="sales-transaction-attachment" name="sales-transaction-attachment" v-model="form.attachment" :disabled="false" :showLabel="false" :accept="'*'"></ThatsFile>
                   </mdbContainer>
              </template>
              <template :slot="'credit-notes'">
                <mdb-row class="tab-row">
                 <mdb-col col="6" class="tab-col pl-0">

                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date" vid="date" name="date" v-model="form.transaction.date" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.transaction.attr.name" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.transaction.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.transaction.attr.address" size="sm" :disabled="true"/>
                      
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Credit Note ID" placeholder="Credit Note ID" vid="series" name="series" v-model="form.series" :rules="''" :keycode="false" size="sm" :disabled="true" />                  
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sold By" vid="sold_by" name="sold_by" v-model="form.transaction.sold_by" size="sm" :disabled="true" />
                        <ThatsMultiSelect :multiple="false"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Credit Note Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="credit_note_status" :rules="'required'" :filterable="false" :clearable="false" :disabled="creditNoteStatus"  @select="getCreditNoteStatusSelected($event)"></ThatsMultiSelect>

                      <template v-if="formOriginal.status === 'approved' && formOriginal.hasOwnProperty('approved_by')">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Approved By" vid="approved_by" name="approved_by" v-model="form.approved_by" size="sm" :disabled="true" />
                      </template>
                      
                      <template v-if="formOriginal.status === 'for-approval' && formOriginal.hasOwnProperty('approved_by') && ($helpers.isSystemAdministrator() || $helpers.isAccounting() || $helpers.isGeneralManager())">
                        <ThatsMultiSelect :multiple="false"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Approved By" v-model="form.approved_by" :reduce="label => label.label" :reduceLabel="'label'" :options="showRoomManager" :rules="'required'" :filterable="false" :clearable="false" :disabled="false"></ThatsMultiSelect>
                        <ThatsDatePicker tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Approved Date" placeholder="Approved Date" vid="approved_date" name="approved_date" v-model="form.approved_date" :validated="validated" :rules="'required'" size="sm" :disabled="false"/>
                      </template>
                    
                    </mdb-col>    
                  </mdb-row>

                   <el-table ref="itemsTable" border :data="form.items" class="input-table mt-2" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" :row-key="getRowKey">
                      <!-- <el-table-column type="selection" width="35" :reserve-selection="true"> </el-table-column> -->
                      <el-table-column label="SKU" :width="150">
                        <template slot-scope="scope">
                         <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Name">
                        <template slot-scope="scope">
                          <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" :disabled="true" />
                        </template>
                      </el-table-column>
                        <el-table-column label="Qty" :width="60">
                          <template slot-scope="scope">
                            <input type="number" min="1" class="form-control grid-input thats-form-control" v-model="scope.row.qty" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Price" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Amount" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Discount" :width="90" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.discount" :disabled="true" />
                            
                          </template>
                        </el-table-column>
                        <el-table-column label="Total Amount" :width="120" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Delv." :width="70" align = "right">
                          <template slot-scope="scope">
                            <template v-if="scope.row.attr.hasOwnProperty('delivered') && scope.row.attr.delivered == 'false'">
                              NO
                            </template>
                            <template v-if="scope.row.attr.hasOwnProperty('delivered') && scope.row.attr.delivered == 'true'">
                              YES
                            </template>
                          </template>
                        </el-table-column>
                      <el-table-column label="Remarks">
                        <template slot-scope="scope">
                          <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="true" />
                        </template>
                      </el-table-column>
                    </el-table>
                    <template v-if="form.attr.hasOwnProperty('more_discount')">
                     <table class="el-table">
                            <tr>
                              <template v-if="!disabled">
                              <td></td>
                              </template>

                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style="width:100px">Invoice Discount</td>
                              <td style="width:200px">
                                <template v-if="form.attr.more_discount.hasOwnProperty('percent')">
                                  <input type="text" class="form-control grid-input thats-form-control text-right inv-discount-percent" v-model="form.attr.more_discount.percent" :disabled="true" />
                                  <span class="inv-discount-percent-lbl">%</span>
                                  <input type="text" class="form-control grid-input thats-form-control text-right inv-discount" v-model="form.attr.more_discount.discount" :disabled="true" />
                                </template>
                                </td>
                              <td style="width:120px" class="text-right">
                                  <strong>{{form.total | amount}}</strong>
                              </td>
                               <td style="width:114px"></td>
                            </tr>
                        </table>
                    </template>


                    <table class="el-table">
                <tr>
                  <template v-if="!disabled">
                  <td>&nbsp;</td>
                  </template>

                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td>&nbsp;</td>
                  <td style="width:100px"  class="text-right"></td>

                  <td style="width:200px" class="text-right">
                    {{totalText}}
                  </td>
                  <td style="width:120px" class="text-right">
                      
                      <strong>{{form.total | amount}}</strong>
                  </td>
                  <template v-if="disabled">
                      <td style="width:130px">&nbsp;</td>
                  </template>
                  <template v-else>
                  <td style="width:114px">&nbsp;</td>
                  </template>
                </tr>
            </table>

            
            <template v-if="form.hasOwnProperty('collection') && form.collection.length > 0">
              <label class="mt-1"><strong>PROCESSED REFUND</strong></label>
                <el-table ref="transactionItemsRefundTable" border :data="form.collection" class="input-table mt-2" style="width: 100%">
                    <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                      <el-table-column label="DATE" :width="150">
                        <template slot-scope="scope">
                         {{scope.row.date}}
                        </template>
                      </el-table-column>
                     
                      <el-table-column label="COLLECTED BY">
                        <template slot-scope="scope">
                           {{scope.row.attr.collected_by}} / {{scope.row.attr.mobile_no}} / {{scope.row.date}} 
                        </template>
                      </el-table-column>
                      <el-table-column label="PROCESSED BY">
                        <template slot-scope="scope">
                          {{scope.row.attr.process_by}}
                        </template>
                      </el-table-column>
                      <el-table-column label="AMOUNT" width="200" align="right">
                        <template slot-scope="scope">
                          {{scope.row.amount | amount}}
                        </template>
                      </el-table-column>
                  </el-table>
              </template>
              </template>
            </mdb-tabs>
          </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>


      <ItemRefundCollectionForm ref="refundCollectionForm" :group="(group + 1)" :url="printUrl" @save="refundCollectionSave" @save-print="refunCollectionSavePrints"></ItemRefundCollectionForm>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'


import ItemRefundCollectionForm from '../item-refund/ItemRefundCollectionForm'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
      ItemRefundCollectionForm,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomMultiSelect, 

  },
    
    data : () => ({
      printUrl : '',
      active : 0,
      validated : false,
      formOriginal : {
        status : 'for-approval'
      },
      form : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      tabs : [
                { text: 'Sales Details', slot: 'form-slot' },
                { text: 'Payments', slot: 'invoice-slot' },
                { text: 'Attachments', slot: 'form-attachment' },
                { text: 'Credit Notes', slot: 'credit-notes' }
              ],
      credit_note_status : [],
      showRoomManager : [],
      showError : false,
    }),

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.form.items.push(formItemVar);
        
       /*  this.nextCode()
        this.currentDateSetter(); */

        
      }
       this.optionsHandler();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.credit_note_status = settings.credit_note_status

        // console.log("create")

        /* if(this.formStatus === 'update'){
            if(this.form.attr.process_by === null){
                this.setPreparedBy()
            }
        } */
    },

    mounted(){
      const self = this;
      this.getShowroomManager().then((response) => {
          self.showRoomManager = response
      })
    },
    updated(){
      this.toggleCheck()
    },


    methods: {

      ...mapActions('ReturnExchangeModule',[
        'storeRecord',
        'updateRecord',
        'filterItems',
        'updateStatus',
        'getRecord',
        'cancelRecord'
      ]),
      ...mapActions('TransactionModule',[
        'getShowroomManager'
        
      ]),
      ...mapMutations('ReturnExchangeModule',[
        'setFormStatusCreate',
        'setPaymentForm',
        'setFormRecord',
        'setFormStatusUpdate',
      ]),
      ...mapActions('RefundCollectionModule',{
          refundCollectionStoreRecord : 'storeRecord'
        }),
      ...mapMutations('RefundCollectionModule',{
        setRefundCollectionFormRecord : 'setFormRecord',
        setRefundCollectionFormCreate : 'setFormCreate',
        setRefundCollectionFormUpdate : 'setFormUpdate',
      }),
      

      formVar(){
        return {
          used : 0,
          series : "",
          date : "",
          approved_date : "",
          attr : {
            process_by : "",
            more_discount : {
                enable : false,
                discount : 0,
                percent : 0
            },
          },
          approved_by : null,
          description : null,
          status : "for-approval",
          action : "exchange",
          items : [],
          attachment : [],
          collection : [],
          transaction : {
              series : "",
              date : "",
              status_index : 0,
              status : "no-remarks",
              attr : {
                name : null,
                mobile : null,
                address : null,
                instruction : null,
                other_details : null,
                payment_status : 'no-payment',
              },
              customer : {
                name : null,
                mobile : null,
                address : null,
              },
              user_id : null,
              sold_by : null,
              customer_id : null,
              payment_method : 'cash',
              items : [],
              attachment : [],
              invoice : {
                series : null,
                payments : [],
                balance : 0
              },
              delivery : {
                date : null,
                remarks : null,
                status : 'for-delivery',
              }
          },

        
        }
      },

      formItemVar(){
        return {
                default: "",
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null,
                attr : {}
              }
                      
      },
/* start */
paymentSuccess(response){
  this.setFormRecord(response.data)
  this.$emit('success')
},
updatePaymentSuccess(v){
  const self = this;

  self.getRecord({token_id : self.form.token_id}).then((response) => {
    self.active = 1
  })
},
applyDiscount(v){

  const frm = v.form;
    const qty = frm.qty;
      let price = frm.price;
      let discount = 0;
      let remarks = null;

      if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,itemPromo);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }

      if(frm.discount_type === 'discount'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,frm.custom_discount);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }


   this.$set(this.form.items, v.index, frm)



    /*   if(frm.promotions.length > 0){
        const itemPromo = frm.promotions[0];
        discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          remarks = this.setRemarks(qty,itemPromo);

      }

      this.$set(this.form.items[v.index], 'discount', discount)
      this.$set(this.form.items[v.index], 'remarks', remarks)
      this.$set(this.form.items[v.index], 'total', this.computeTotalAmount(qty, price, discount))
      this.$set(this.form.items[v.index], 'custom_discount', frm.custom_discount) */


},
cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6]
        
        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

/*         if(self.formStatus === 'update'){
            totalCol = 1;
            totalQty= 2;
            totalPrice= 3;
            totalAmount= 4;
            totalDiscount= 5;
            totalTotal= 6;
        } */
        
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }
                return d;
              })
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                //  this.form.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
getActiveTabIndex(index){
  this.active = index
},


optionsHandler(){
  // this.getPurchaseOrder()
  const self = this;
  // this.dispatchFilterItems({transaction_date : this.form.date})

},
customLabel({label , mobile}){
  return `${label} – ${mobile}`
},

async setPreparedBy(){
    var self = this;
    const loggedUser = await userData()

    const frm = this.form
    frm.process_by = loggedUser.user.name
    this.form = frm;
},
async setApprovedBy(){
    const loggedUser = await userData()
    this.form.approved_by = loggedUser.user.name;
},


deleteItem(){

  if(this.selected.length){
      if(this.formStatus === 'update'){
          MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
                this.selected.forEach(element => {
                  var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                  this.form.items.splice(removeIndex, 1);
                });

                if(this.form.items.length <= 0){

                const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }
            })
      }else{
        this.selected.forEach(element => {
                  var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                  this.form.items.splice(removeIndex, 1);
                });

                if(this.form.items.length <= 0){
                  const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }
      }
    

    
  }


// remove object


},

handleSelectionChange(v){
   this.form.items = v

   this.form.delivery_status = 'partial-delivery'
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    console.log(setSelected);
},

statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }

  
},

dispatchFilterItems(params){
  const results = [];
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions})
          });
        }
    })
  this.items = results

},

statusKeyUp(v){
// this.getPurchaseOrder(v)

const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){

        var self =this
       /*  
            this.validated = true */
            const isValid = this.$refs.observer.validate();
            /* this.showError = false */
            if (isValid) {
                    if(self.formLoading === false){
                      
                      if(self.formStatus == 'update'){
                        if(self.form.status == 'approved'){
                            MessageBox.confirm('This record cannot be editable once you save it. Continue?', 'Warning', {
                          confirmButtonText: 'OK',
                          cancelButtonText: 'Cancel',
                          type: 'warning'
                          }).then(() => {

                            self.formLoading = true
                              self.updateRecord(self.form).then((response) => {
                                  self.$ThatsNotify.success({
                                    message : "Record was successfully updated"
                                  })
                                  self.$emit('success')
                                  self.setFormRecord(response)
                                  self.formLoading = false

                              }).catch((error) => {
                                self.formLoading = false
                                self.response.error = 1;
                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                      self.$refs.observer.setErrors(error.response.data.errors);
                                      this.showError = true
                                    }
                                  } else if (error.request) {
                                      self.response.message = error.request
                                  } else {
                                      self.response.message = error.message
                                  }
                                  return;
                              })
                          })
                        }
                          
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
        this.form.approved_date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.formRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

/*         console.log("custom");
        console.log(custom_discount); */
        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        // this.printUrl = url
        // this.$refs.printWindow.openDialog();

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(url, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

      },
      
      getCreditNoteStatusSelected(v){
        console.log(v)
      },

      toggleCheck(){
        const self = this;
        if(this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);

          // alert(getCode.toString())
          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              })

               
                
              }else{
                self.$refs.itemsTable.clearSelection();
              } 

          // const indexZero = this.form.transaction.items[0];
          //     this.$refs.itemsTable.toggleRowSelection(indexZero);

        
        }
      },

      getRowKey(row){
        return row.id
      },

      cancelCreditNote(){
        const self = this;
          if(self.formLoading === false){
                      
                      if(self.formStatus == 'update'){

                        if(self.formOriginal.status == 'approved' && self.formOriginal.used > 0){
                         
                          // self.$ThatsNotify.warning({
                          //           message : "Unable to cancel, Record was use by another document",
                          //           timeOut : 10000
                          //         });


                           MessageBox.confirm('This record cannot be revert once cancelled. You want to continue?', 'Warning', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            type: 'warning'
                            }).then(() => {

                              self.formLoading = true

                              console.log({...self.form, status : 'cancelled'})
                              self.cancelRecord({...self.form, status : 'cancelled'}).then(() => {
                                      self.form.status = 'cancelled';
                                      self.$ThatsNotify.success({
                                        message : "Record was successfully updated"
                                      })
                                      self.$emit('success')
                                      self.setFormRecord(response.data)
                                      self.formLoading = false

                                  }).catch((error) => {
                                    self.formLoading = false
                                    self.response.error = 1;
                                    if (error.response) {
                                        if(error.response.status === 422){
                                            self.$ThatsNotify.error({
                                            message : error.response.data.error,
                                            timeOut: 20000
                                          })
                                      }
                                        if(error.response.data.hasOwnProperty('errors')){
                                          self.$refs.observer.setErrors(error.response.data.errors);
                                          this.showError = true
                                        }
                                      } else if (error.request) {
                                          self.response.message = error.request
                                      } else {
                                          self.response.message = error.message
                                      }
                                      return;
                                  })



                            });

                          return false
                        }

                        if((self.form.status == 'approved' || self.formOriginal.status == 'for-approval') && self.formOriginal.used <= 0){
                            MessageBox.confirm('This record cannot be revert once cancelled. You want to continue?', 'Warning', {
                          confirmButtonText: 'OK',
                          cancelButtonText: 'Cancel',
                          type: 'warning'
                          }).then(() => {

                            self.formLoading = true

                            self.form.status = 'cancelled';
                              self.updateRecord(self.form).then(() => {
                                  self.$ThatsNotify.success({
                                    message : "Record was successfully updated"
                                  })
                                  self.$emit('success')
                                  self.setFormRecord(response.data)
                                  self.formLoading = false

                              }).catch((error) => {
                                self.formLoading = false
                                self.response.error = 1;
                                if (error.response) {
                                    if(error.response.data.hasOwnProperty('errors')){
                                      self.$refs.observer.setErrors(error.response.data.errors);
                                      this.showError = true
                                    }
                                  } else if (error.request) {
                                      self.response.message = error.request
                                  } else {
                                      self.response.message = error.message
                                  }
                                  return;
                              })
                          })
                        }

                        ////
                          
                      }
                    }
      },

      processRefund(){
        const refundCollectionField = JSON.parse(JSON.stringify(this.refundCollectionFormField));

        //set date, tracking no and process by
        var MyDate = new Date();
        refundCollectionField.amount = this.form.total;
        refundCollectionField.transaction_id = this.form.mdbID;
        refundCollectionField.series = "";
        // refundCollectionField.tracking_no = this.form.invoice.series;/
        refundCollectionField.refund_series = this.form.series;
        refundCollectionField.date = MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        const loggedUser = userData();
        refundCollectionField.attr.process_by = loggedUser.user.name
        refundCollectionField.attr.reference = this.form.transaction.invoice.series

        this.setRefundCollectionFormCreate();
        this.setRefundCollectionFormRecord(refundCollectionField);
        this.$refs.refundCollectionForm.openDialog();
      },

      refundCollectionSave(v){
        const self = this;  
          this.refundCollectionStoreRecord(v).then(function(){
                   self.$ThatsNotify.success({
                      message : "Record was successfully updated"
                    })

                     const form  = {series : self.form.refund.series,items : [],attachment : [], invoice : {
                                        series : "",
                                        date : "",
                                        balance : 0,
                                        paid : 0,
                                        attachment : [],
                                        payments : [],
                                    },
                                    activeTab : self.activeTab,
                                    cancel_status : {
                                        status : 'for-approval'
                                    },
                                    cancel : {
                                        series : "",
                                        status : 'for-approval',
                                        items : []
                                    },
                                    refund : {
                                        series : "",
                                        status : 'for-approval',
                                        items : [],
                                        collection : [],
                                    }
                                }           

                    self.getRecord(form);

                    // console.lof(self.$refs.refundCollectionForm)
                self.$refs.refundCollectionForm.closeDialog();
          });
      },

      refunCollectionSavePrints(v){
        const self = this
        this.refundCollectionStoreRecord(v).then(function(){
                self.$ThatsNotify.success({
                      message : "Record was successfully updated"
                    })
                self.$refs.refundCollectionForm.closeDialog();
          });
      }

    },

    computed: {

      ...mapGetters('ReturnExchangeModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
    ...mapGetters('AuthModule',[ 'user', ]),
    ...mapGetters('RefundCollectionModule',{
            refundCollectionFormField : 'formField'
          }),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          /* if(self.formStatus === 'update'){
            if(self.form.invoice !== null && self.form.invoice.series !== null){
              return true;
            }
          } */
          return false;
      },
      invoice : function(){
          let inv = {
          series : "",
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }

        /* if(this.formStatus == 'update'){
            if(this.FormRecord.transactions != null && this.FormRecord.transactions.invoice !== null){
              inv = this.FormRecord.transactions.invoice
            }

        } */
        return inv
      },
      isAllowed : function(){
            if(this.$helpers.isShowroomManager() || this.$helpers.isGeneralManager() || this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;

            /* isGeneralManager
isShowroomManager */
      },
      creditNoteStatus : function(){
            if(this.formOriginal.status == 'for-approval' && (this.$helpers.isShowroomManager() || this.$helpers.isGeneralManager() || this.$helpers.isSystemAdministrator())){
                return false
            }
            return true;
      },

      btnText : function(){
          if(this.form.status === 'approved'){
              if(this.formOriginal.status === 'for-approval'){
                return 'Approve Credit Note';
              }else{
                return 'Save';
              }
          }else{
            return 'Save';
          }
      },
      totalText : function(){
         if(this.formOriginal.status === 'approved'){
                return 'Approved Credit Note Amount';
              }else{
                return 'Credit Note Amount';
              }
      }
    },

    watch: {
      formRecord : function(v){
        const self = this;
        this.active = 0;
        if(this.formStatus == 'update'){
          // console.log(v)
         /*  if(v.transactions.invoice !== null && (v.transactions.invoice.hasOwnProperty('series') && v.transactions.invoice.series !== null)){
              this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' },
                { text: 'Sales Invoice', slot: 'invoice-slot' },
                { text: 'Attachments', slot: 'form-attachment' }
              ]
            }else{
              
             
              this.form.transactions.invoice = {
                  series : null,
                  date : "",
                  balance : 0,
                  paid : 0,
                  attachment : [],
                  payments : [],
              }
            } */

            console.log("lorem")
            console.log(v)
          const jsonParse = JSON.parse(JSON.stringify(v));
          // const jsonParse = JSON.parse(JSON.stringify(this.formRecord));

          // console.log(jsonParse)
          // jsonParse.attr = {...this.form.attr,...jsonParse.attr}

          if(jsonParse.attr.process_by === null){
              const loggedUser = userData()
              jsonParse.attr.process_by = loggedUser.user.name;
          }


        

          if(!jsonParse.attr.hasOwnProperty('more_discount')  || (jsonParse.attr.hasOwnProperty('more_discount') && jsonParse.attr.more_discount === null)){
            jsonParse.attr.more_discount = {
                    enable : false,
                      discount : 0,
                      percent : 0
                  }

          }
            console.log(jsonParse.attr)

            
   

          
          self.form = jsonParse;
          self.formOriginal = JSON.parse(JSON.stringify(jsonParse))

          if(self.formOriginal.status === 'for-approval' && self.formOriginal.hasOwnProperty('approved_by') && (self.$helpers.isSystemAdministrator() || self.$helpers.isAccounting() || self.$helpers.isGeneralManager())){
            this.setApprovedBy();
          }
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

      /* 'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              }).reduce((a,b) => {
                return a + b;
              })
              this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
            }

          },
          deep: true
      } */

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}
</style>