
<template>
    <div class="page-container">
        <TableFabric title="Fabric and Textile" ref="table" :endpoint="endpoint" @openUpdateModal="OpenUpdateModal" :columns="columns" :height="'calc(100vh - 123px)'" :enableFilter="true" :newBtn="enableNew" :enableDelete="false" @print="fnPrint" @print_small="fnPrintSmall" @print_qr="fnPrintQr" @selectionChange="handleSelectionChange">
            <template #top-right>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabel">Print</mdb-btn>
            </template>
            <template #custom-culumns="{columns, getValue,handleClick}">
                <template v-for="col in columns">
                    <template v-if="checkGroup1(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                    </template>
                    <template v-if="col.field === 'thumb'">
                        <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                            <template slot-scope="scope">
                                    <a href="javascript:void(0)" @click="viewImage(scope.$index, scope.row)" >
                                        <img :src="scope.row.thumb" />
                                    </a>
                        </template>
                        </el-table-column>
                    </template>
                    <template v-if="checkGroup2(col.field)">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                    </template>

                </template>
            </template>

            <template #filter="{dispatchFilter}">
                <BarcodeFabricFilter @filter="dispatchFilter"></BarcodeFabricFilter>
            </template>
            

        </TableFabric>
        <FabricForm ref="itemDialogForm" @success="refreshTable" @delete="formDelete" :group="3"></FabricForm>
        <BarcodeFabricForm ref="barcodeFabricForm" @success="refreshTable" @delete="formDelete" :group="3"></BarcodeFabricForm>
        <BarcodeForm ref="transactionSalesOrderForm" @success="refreshTable" @delete="formDelete"  :group="3"></BarcodeForm>
       

        
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import BarcodeForm from './BarcodeForm'
import FabricForm from '../fabric/FabricForm'
import BarcodeFabricFilter from './BarcodeFabricFilter'
import BarcodeFabricForm from './BarcodeFabricForm'
import TableFabric from './TableFabric'
import {mdbTabs, mdbBtn} from 'mdbvue'

export default {
    data(){
        return {
            endpoint : 'fabric/data',    
            activeTab : 'barcode',    
            enableFilter : false,       
            importLoading : false,        
            active : 0,        
            token : null,
            enableNew : false,        
            enableImport : false,
            selection : [],
            group1 : ["series","brand_name","name"],        
            group2 : ['description', 'formatted_price', 'qty',],  
                   
        }
    },
    components:{
        BarcodeFabricForm,
        BarcodeFabricFilter,
        FabricForm,
        BarcodeForm,
        TableFabric,
        mdbTabs,
        mdbBtn,

    },
    created(){
        // this.columns = this.salesColumns;
        this.endpoint = 'fabric/data';
    },   
    methods: {
        // 

        ...mapActions('TableV1Module',[
            'resetData',
        ]),
        ...mapMutations('TableV1Module',[
            'SetTotal',
            'SetPage',
            'SetTerm',
            'SetFilters',
            
        ]),

        ...mapActions('FabricModule',{
            itemGetRecord  : 'getRecord'
        }),
        ...mapMutations('FabricModule',{
            itemSetFormRecord : 'SetFormRecord', itemSetFormStatusUpdate: 'SetFormStatusUpdate'
        }),
        ...mapMutations('BarcodeFabricFormModule',{
            fabricFormSetData : 'SetData'
        }),
        checkGroup1(column){
           return this.group1.includes(column)
        },
        checkGroup2(column){
           return this.group2.includes(column)
        },
        viewImage(index,row){
            this.galleryData = [];
            if(row.images.length){
                row.images.forEach(element => {
                    this.galleryData.push({
                        img: true,
                        src: element.large,
                        alt: element.name,
                        caption: {
                        title: element.name
                        }
                    })
                });

                this.$refs.galleryWindow.openDialog()
            }

        },
        // ...mapActions('BarcodeModule',[ 'deleteRecord','saveImport','getRecord']),
        // ...mapMutations('BarcodeModule',[ 'setFormRecord', 'setFormStatusUpdate','setFormStatusCreate','setTblKey']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){

        },
        OpenUpdateModal(obj){
            const self = this
             const form  = {row : {...obj.row,images : []}}           
            self.itemSetFormStatusUpdate()
            self.itemSetFormRecord(form)
            self.itemGetRecord({token_id : obj.row.token_id, series : obj.row.series})
            self.$refs.itemDialogForm.openDialog()

        },
        openUploadWindow(){           
        },
        openWindow(){
            
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v           
           this.$refs.tableImport.openDialog()
        },

        

        setter(){
            let resetDataEndpoint = 'barcode';
          
            
            this.resetData(resetDataEndpoint);
        },

        getActiveTabIndex(index){


            const tabs = ['sales','sales-quotation','sales-order','online-orders'];
            this.activeTab = tabs[index];
            this.SetPage(1)
            this.SetTotal(0);
            this.SetFilters({});
            this.setter()
            // commit('SetTotal', 0)
            /* SetFilters: (state, payload) => {
            state.filters = payload
        }, */
        },

        fnPrint(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/fabric-textile/print/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintSmall(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/fabric-textile/print-small/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintQr(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/fabric-textile/print-qr/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabel(){

        if(this.selection.length > 0){
            
            this.selection.forEach((element,index) => {
                const newElement = {...element,
                    sizes : [
                        {
                            label : '30x60',
                            value : '30x60',
                            size : '30x60',
                            piping : false,
                            emb : false,
                            piping_emb : false,
                            class : 'n',
                            item : 'cushion',
                            reference : 'cushion',
                            price : 300
                        }
                    ]
                }

                this.$set(this.selection, index,newElement)
            });

            console.log(this.selection)
            
            this.fabricFormSetData(this.selection)
            this.$refs.barcodeFabricForm.openDialog();
        }
      },
        printLabelSmall(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/fabric-textile/multiple-print-small?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelQr(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/fabric-textile/multiple-print-qr?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },

      handleSelectionChange(v){

        //   console.log(v)
       /*  let selectionArray = [];
          if(v.length > 0){
               selectionArray = v.map((item) =>{
                    return item.id
                })
              } */
            this.selection = v
      }

    },
    computed: {
        ...mapGetters('TableV1Module',[ 'tableLoading']),
        ...mapGetters('BarcodeFabricModule',[ 'columns','data', 'page', 'total', 'page', 'length', 'getLoading', ]),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>