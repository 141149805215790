/* eslint-disable no-prototype-builtins */
<template>

  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" @submit.prevent="onSubmitForm">
    <el-dialog title="Item Master" :visible.sync="dialogVisible" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form" ref="window" :show-close="!formLoading">
      <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" :key="eventKeyCounter">
              <template :slot="'form-slot'">
                <div class="filter-dalog-body p-4">
                    <ThatsFieldset>
                        <mdb-row>
                          <mdb-col col="6" class="">
                            <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="(formStatus == 'update' ? true : false)"/>                 
                          </mdb-col>
                          <mdb-col col="6">
                            <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Supplier Code" placeholder="Supplier Code" vid="barcode" name="barcode" v-model="form.attr.barcode" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="disabled" />                  
                          </mdb-col>
                        </mdb-row>      
                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Item Name" placeholder="Item Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                        <ThatsInput tabindex="3" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Dimension" placeholder="WxHxD (cm)" vid="dimension" name="dimension" v-model="form.dimension" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                        <mdb-row>
                          <mdb-col col="6">
                            <ThatsMoney tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="form.sale_price" :rules="'required'" :validated="validated" :disabled="disabled"/> 
                          </mdb-col>
                        </mdb-row>

                        <ThatsMultiSelect :show_error="showError" :multiple="false" :clearable="false"  labelClass="col-md-2" fieldClass="col-md-10  pl-0" label="Category" v-model="form.category" :reduce="label => label.value" :reduceLabel="'label'" :options="categoryOptions" :rules="'required'" :disabled="disabled" :addButton="true" @newWindow="openCategory"></ThatsMultiSelect>
                      </ThatsFieldset>

    
                  </div>  
              </template>
              
             
            </mdb-tabs>


            

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">

                  <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="onSubmitForm"><i class="el-icon-save"></i> Save</mdb-btn>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="dialogVisible = false"><i class="el-icon-close"></i> Close</mdb-btn>
                      </template>

                      
                </div>
            </div>

            <ItemMasterCategoryDialog ref="itemMasterCategoryDialog" @success="categorySuccess"></ItemMasterCategoryDialog>
            

        </el-dialog>
  </ValidationObserver>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'
import { MessageBox } from 'element-ui';
import XWindow from '../../components/thats/XWindow.vue'
import { Loading } from 'element-ui';

import ItemMasterCategoryDialog from './ItemMasterCategoryDialog.vue'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : ['categoryProvider'],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        ThatsSeriesInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        ItemMasterCategoryDialog
  },
    
  data(){

    const self = this;

    return {

        dialogVisible : false,
        isIndeterminate : false,
        checkAllReceived : false,
        showError : true,
        disabled : false,
        onPasteLoading : false,
        isMaximized : false,
        inputValue : 0,
        validated : false,
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 900,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,

        tabs : [
                { text: 'Details', slot: 'form-slot' },
              ],
        gallery : [],
        categoryOptions : [],
        index : 0
       
    }
  },
    

  created : function() {
        
        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.resetForm();
        
  },




  methods: {
    ...mapMutations('ItemMasterModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapActions('ItemMasterModule',['storeRecord','updateRecord','getRecord']),

    onResize({width,height}){  

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      },
    openDialog(){
        // this.$refs.window.openDialog()

        this.dialogVisible = true
    },
    closeDialog(){
      // this.$refs.window.closeDialog()
      this.dialogVisible = false
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(){
       const self = this;
       
            self.$refs.observer.validate().then((isValid) => {

              if(isValid){

                
                  self.formLoading = true
                  
                  if(this.formStatus == 'create'){
                    self.storeRecord(self.form).then((res) => {
                      self.showError = false
                      self.formLoading = false
                      self.$ThatsNotify.success({
                          message : "Record successfully added."
                        })
                        self.closeDialog()
                        self.$emit('success')
                    }).catch((error) => {
                        if(error.response.data.hasOwnProperty('errors')){
                          self.$refs.observer.setErrors(error.response.data.errors);
                          self.showError = true
                          self.formLoading = false
                        }
                    })
                  }

                  if(this.formStatus == 'update'){
                    self.updateRecord(self.form).then((res) => {
                      self.showError = false
                      self.formLoading = false
                      self.$ThatsNotify.success({
                          message : "Record successfully updated."
                        })
                        self.closeDialog()
                        self.$emit('success')
                    }).catch((error) => {
                        if(error.response.data.hasOwnProperty('errors')){
                          self.$refs.observer.setErrors(error.response.data.errors);
                          self.showError = true
                          self.formLoading = false
                        }
                    })
                  }
                }
            });
    },

    cellClassName(){

    },

     

      dialogState(newState){
        if(!newState){
           
            
            this.resetForm();
          return
        }
        
      },

      openCategory(){
        this.$refs.itemMasterCategoryDialog.openDialog();
      },

      categorySuccess(value){
        console.log(value)

        this.categoryOptions.push({
          area : 0,
          brand : 0,
          attributes : [],
          label : value.data.name,
          category : value.data.name,
          code : value.data.id,
          value : value.data.id,
        })

        this.form.category = value.data.id

        this.$nexttick (() => this.$refs.observer.reset ());
      }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('ItemMasterModule',['getLoading','formData','formStatus','initialFormObject']),
      tblKey : function(){
        return 1;
      },
      categoryComputed : function(){
        
        const codeArray = [];
        const categoryObject = [];
        const category = this.categoryProvider();
        
        if(category.length > 0){
          category.forEach(element => {
                if(!codeArray.includes(element.code)){
                  codeArray.push(element.code)
                  categoryObject.push(element)
                }
          });
        }
          return categoryObject.map((item) => {
              return {...item,label : item.category,value : item.code}
          },[])
        },
  },
  watch: {
      'formData' : function(v){
        const self = this
        this.eventKeyCounter++
          this.form = JSON.parse(JSON.stringify({...this.initialFormObject,...v}));
          this.$nextTick(()=>{

          
          })
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },

     /*  dialogVisible : function(v){
        this.$nexttick (() => {
          this.$refs.observer.reset()
          this.$refs.categoryObserver.reset()
        });
      }, */

      categoryComputed : function(v){
          this.categoryOptions = v
      }

  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>