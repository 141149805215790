import { TableService } from '../../services'
export const CustomTableModule = {
    namespaced: true,
    state: {
        data: {
            columns: [],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false
    },
    mutations: {
        setData(state, payload) {
            state.data = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },
    },
    actions : {
        async resetData({dispatch}, endpoint){
            dispatch('asyncGetData', endpoint)
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    TableService.get(endpoint, params).then((response) => {

                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setRows', response.data.data)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
    }
}


