import { ApiHttpService } from '../../services'

import router from '../../router'
import {Unauthorized} from "../../helpers";
export const ShowroomTargetModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Showroom',
                    field: 'name',
                    clickable: true,
                },
                {
                    label: 'Year',
                    field: 'year',

                },
                // {
                //     label: 'Month',
                //     field: 'month',
                //
                // },
                // {
                //     label: 'Daily Target',
                //     field: 'daily_target',
                //
                // },
                {
                    label: 'Monthly Target',
                    field: 'monthly_target',

                },

                ],
            rows: [],
        },
        multipleSelection: [],
        salesTargetShowroomData:[],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},


        initialFormData : {
            showroom : {
                id : ""
            },
            status:"draft",
            items: [],
            location_id : "",
            location_code : "",
            showroom_target : {
                status:"open",
                showroom : {
                    id : ""
                },
                items : []
            }
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',
        formTarget : "",

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setShowroomTargetData: (state, payload) => {
            state.salesTargetShowroomData = payload
        },

        setShowroomTargetFormData : (state, payload)=>{
            state.formTarget = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setNewItemDataObject : (state, payload)=>{
            state.newItemDataObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {

                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "ShowroomTargetModule/req",
                        loadingTrue : "ShowroomTargetModule/loadingTrue",
                        addRequest : "ShowroomTargetModule/addRequest",
                        cancelRequest : "ShowroomTargetModule/cancelRequest",
                        clearOldRequest : "ShowroomTargetModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })

            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}

                    ApiHttpService.getRequest('inventory-transfer-request/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },


        async getShowroomTargetData({ dispatch,commit },params) {
            return await new Promise((resolve) => {

                const configParams = {
                    req : "ShowroomTargetModule/req",
                    loadingTrue : "ShowroomTargetModule/loadingTrue",
                    addRequest : "ShowroomTargetModule/addRequest",
                    cancelRequest : "ShowroomTargetModule/cancelRequest",
                    clearOldRequest : "ShowroomTargetModule/clearOldRequest"
                }
                ApiHttpService.post('get-showrooms-target-record', params,configParams).then((response) => {
                    commit('setShowroomTargetData', response.data)
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },








        async getReceiver() {
            return await new Promise((resolve, reject) => {
                ApiHttpService.getRequest('inventory-transfer-request/receiver',{view : router.app._route.name}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    commit('setGetloadingFalse', false)
                    reject(error)
                })
            })
        },


        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "ShowroomTargetModule/req",
                        loadingTrue : "ShowroomTargetModule/setPostLoadingTrue",
                        addRequest : "ShowroomTargetModule/addRequest",
                        cancelRequest : "ShowroomTargetModule/cancelRequest",
                        clearOldRequest : "ShowroomTargetModule/clearOldRequest"
                    }
                    ApiHttpService.post('showroom-target/store',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`inventory-transfer-request/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        getNewItemDataObject: state => state.newItemDataObject,
        FormTarget: state => state.formTarget,
        FormRecordItems: state => state.form.items,
    }
}



