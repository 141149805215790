import { render, staticRenderFns } from "./CustomerVendor.vue?vue&type=template&id=3419f27e&scoped=true&"
import script from "./CustomerVendor.vue?vue&type=script&lang=js&"
export * from "./CustomerVendor.vue?vue&type=script&lang=js&"
import style0 from "./CustomerVendor.vue?vue&type=style&index=0&id=3419f27e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3419f27e",
  null
  
)

export default component.exports