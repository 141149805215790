import { SurveyService } from '../../services'
export const SurveyModule = {
    namespaced: true,
    state: {
        getRequest : null,
        postRequest : null,
        loading : false,
        postLoading : false
    },
    mutations: {
        addRequest : (state, req) => {
            state.getRequest = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            state.getRequest.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.getRequest.msg = message;
            state.getRequest = null;
        },


        postRequest : (state, req) => {
            state.postRequest = {cancel : req.cancel, msg : "loading"}
        },
        cancelPostRequest : (state) => {
            state.postRequest.cancel()
            state.postLoading = true
        },
        clearOldPostRequest(state, message) {
            state.postRequest.msg = message;
            state.postRequest = null;
        },


        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },

        setPostLoading(state, payload) {
            state.postLoading = payload
        },
        setPostLoadingTrue(state) {
            state.postLoading = true
        },
        setPostLoadingFalse(state) {
            state.postLoading = false
        }
    },
    actions : {
        async resetData({dispatch}, endpoint){
            dispatch('asyncGetData', endpoint)
        },
        async asyncGetData({state,commit},endpoint) {

            return await new Promise((resolve, reject) => {
                const {loading} = state
                if (loading === false) {
                    commit('setLoading', true)
                    const params = { }

                    const configParams = {
                        req : "SurveyModule/req",
                        loadingTrue : "SurveyModule/setPostLoadingFalse",
                        addRequest : "SurveyModule/addRequest",
                        cancelRequest : "SurveyModule/cancelRequest",
                        clearOldRequest : "SurveyModule/clearOldRequest"
                    }

                    SurveyService.get(endpoint, params, configParams).then((response) => {
                        commit('setLoading', false)
                        console.log("response.data")
                        console.log(response.data)
                        resolve(response.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },

        storeSuvey({ state, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = state.postLoading;
                if (!loading) {
                    const configParams = {
                        req : "SurveyModule/reqPost",
                        loadingTrue : "SurveyModule/setPostLoadingTrue",
                        addRequest : "SurveyModule/postRequest",
                        cancelRequest : "SurveyModule/cancelPostRequest",
                        clearOldRequest : "SurveyModule/clearOldPostRequest"
                    }
                    commit('setPostLoading', true)
                    SurveyService.post(payload.endpoint,payload.payload, configParams).then((response) => {
                        commit('setPostLoading', false)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoading', false)
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        loading : state => state.loading,
        postLoading : state => state.postLoading,
        req: state => state.getRequest,
        reqPost: state => state.postRequest,
    }
}



