import {ApiHttpService} from '../../services'

export const LoyaltyCustomerCardUpgradeModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data : {
            rows: [],
        },
        formStatus : 'create',
        endpoint : 'loyalty-program/customer-loyalty-cards/customer-card-update',
        formRecord : {},
        length : 25,
        page : 1,
        total : 1,

    },
    actions : {

        UpdateCustomerCardRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.LoyaltyCustomerCardUpgradeModule;

                if (!loading) {
                    commit('LoadingTrue')
                    console.log('swaraj bhuyan update form')
                    ApiHttpService.update(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateLoyaltyCardRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading } = rootState.LoyaltyCustomerCardUpgradeModule;

                if (!loading) {
                    commit('LoadingTrue')
                    ApiHttpService.update(`loyalty-program/customer-loyalty-cards/loyalty-card-number-update`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },





    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        clearOldRequest(state, message) {
            // state.request.msg = message;
            state.request = null;
        },
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
    }
}


