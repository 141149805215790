<template>
<!-- :left="300" :top="20" -->
<hsc-window-style-metal>
    <hsc-window v-bind:class="$attrs.windowClass" :title="'Print'" :resizable="false" :left="x" :top="y"  :width="width" :height="height" :minWidth="400" :maxWidth="800" :minHeight="minHeight" :maxHeight="maxHeight" :zGroup="group" :closeButton="true" :isOpen.sync="dialogIsOpen">
       <!-- <div v-bind:style="styleObject"><div  ref="print"></div></div> -->
       <!-- <div v-bind:style="styleObject">
           <iframe src="http://portal.thatsliving.com/sales/invoice/0Gz3YaOMmEdxqlW1"></iframe>
       </div> -->
    </hsc-window>
</hsc-window-style-metal>
</template>


<script>
import { isUndefined } from 'lodash';
import { StyleFactory, WindowType } from '@hscmap/vue-window'
import { mapGetters, mapMutations } from 'vuex'
import { MessageBox } from 'element-ui'

import {
  mdbBtn,
  mdbContainer,
  mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle

} from "mdbvue";
export default {
    name : "PrintWindow",
    components: {
        mdbBtn,
        mdbContainer,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },

    props : {
        url : {
            type : String
        },
        width : {
            type : Number,
            default : 900
        },
        height : {
            type : Number,
            default : 600
        },
        minHeight : {
            type : Number,
            default : 350
        },
        maxHeight : {
            type : Number,
            default : 450
        },
        formStatus : {
            type : String,
            default : 'create'
        },
        group : {
            type : Number,
            default : 2
        },
        position : {
            type : String,
            default : 'center'
        }
    },

    data(){
        const h = this.height
        return {
            dialogIsOpen : false,
            x : 0,
            y : 0,
            styleObject :{
                height : '577px'
            }
        }
    },
    mounted: function () {
      /* if (isUndefined(window.PDFObject)) {
        console.error('VuePDFViewer Error: PDFObject not found. ' +
          'Get it here: https://pdfobject.com, please include js file.');
        return;
      }
      if (isUndefined(this.url)) {
        console.error('VuePDFViewer Error: no file url provided');
        return;
      } */
    //  PDFObject.embed(this.url, this.$refs.print);
    },
    methods: {
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        emitDelete(){
            var self = this;
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.$emit('deleteYes')
                    }).catch(() => {
                        self.$emit('deleteNo')  
                    });
        },
        emitStatus(status){
            this.$emit('status',status)
        },
        emitCopy(){
            this.$emit('copy')
        },
        emitSubmit(){
            this.$emit('save')
        },
        emitHistory(){
            this.$emit('history')
        }
       
    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
    
    watch : {
        'dialogIsOpen' : function(newState,old) {
            this.$emit('dialog-state',newState)
        },
        url: function (val) {
            var vm = this;
        //    PDFObject.embed(val, vm.$refs.print);
        //    PDFObject.embed(val, this.$refs.print);
        }
    }
}

</script>

<style>
  .pdfobject-container {
    width: 100%;
    margin: 0;
  }
  /* .pdfobject {
    border: 1px solid #666;
  } */
</style>
