<template>
    <div class="image-grid-container">
      <hsc-window-style-metal>
      <hsc-window title="Image Manager" :resizable="false" :left="250" :top="50" :width="900" :height="450" :minWidth="400" :maxWidth="800" :minHeight="350" :maxHeight="450" :zGroup="group" :closeButton="true" :isOpen.sync="dialogIsOpen">
          <!-- <input type="file" @change="select">
        <progress :value="progress"></progress> -->
      <mdb-form-inline class="image-container-header" tag="div">



                <template v-if="Selected.length">
                    <mdb-btn color="elegant" size="sm" @click="clearSelected">Clear</mdb-btn>
                    <mdb-btn color="elegant" icon="trash" size="sm" @click="deleteSelected">Delete</mdb-btn>
                    <mdb-btn color="elegant" icon="check" size="sm" @click="emitSelectModal">Select</mdb-btn>
                </template>
                <template v-else>
                  <el-pagination        
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      :current-page.sync="current_page"
                      :page-sizes="[10,25,50,100]"
                      :page-size="length"
                      layout="sizes, prev, next, jumper"
                      :total="total">
                  </el-pagination>
                    <input class="form-control mr-sm-2 table-searchterm-input" v-model="term" type="text" placeholder="Search" aria-label="Search"/>
                    <mdb-btn color="elegant" size="sm"  @click="handleSearchTerm">Search</mdb-btn>
                    <slot name="filter"></slot>
                    <mdb-btn color="elegant" icon="refresh" size="sm"  @click="refreshTable">Refresh</mdb-btn>
                    <mdb-btn color="elegant" icon="plus" size="sm" @click="EmitOpenModal">New</mdb-btn>
                </template>
                    
            </mdb-form-inline>

        <div class="scrollbar image-grid">

          

            <div class="image-container flex-container">

            
              <template v-for="img in data">
              <div class="image-item" v-bind:key="img.token_id">
                <div class="custom-control image-checkbox custom-checkbox">
                    <input type="checkbox" class="custom-control-input" @change="onSelect" v-model="Selected" :id="moduleForm+'-imageCheckbox'+img.mdbID" :value="img.mdbID">
                    <label class="custom-control-label image-manager" :for="moduleForm+'-imageCheckbox'+img.mdbID"></label>
                </div>

                <input type="checkbox" class="ordinary-checkbox" @change="onSelect" v-model="Selected" :value="img.mdbID">


                  <mdb-card>
                  <mdb-view class="image-grid-view" hover>
                    <!-- <mdb-card-image :src="img.path" alt="Card image cap"></mdb-card-image> -->

                    <div class="centered">
              
                      <img class="show-img img-responsive" :src="img.sm">
                    </div>

                  </mdb-view>
                <mdb-card-body >
                  <p class="text-muted muted small">{{img.filename}}</p>
                </mdb-card-body>
              </mdb-card>

              </div>
              </template>
            </div>
        </div>     
    </hsc-window>
    </hsc-window-style-metal>
      <ImageUpload ref="uploadForm" @uploadSuccess="refreshTable" :group="(group  + 1)"></ImageUpload>
    </div>
</template>

<script>
import Vue from 'vue'

import { mdbInput,mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon, mdbFormInline } from 'mdbvue';

import {mapGetters,mapActions, mapMutations} from 'vuex'
import { MessageBox } from 'element-ui';
import ImageUpload from './ImageUpload'
export default {
      name : "ImageGrid",
    components : { 

      mdbContainer,
      mdbRow,
      mdbCol,
      mdbCard,
      mdbCardImage,
      mdbCardHeader,
      mdbCardBody,
      mdbCardTitle,
      mdbCardText,
      mdbCardFooter,
      mdbCardUp,
      mdbCardAvatar,
      mdbCardGroup,
      mdbBtn,
      mdbView,
      mdbMask,
      mdbIcon,
      mdbFormInline,
      mdbInput,
      ImageUpload,

    },
    props : {
      multiple : {
        type : Boolean,
        default : true 
      },

      group : {
            type : Number,
            default : 1
        },
      moduleForm : {
        type : String,
        default : 'img-grid'
      }
    
    },
    data () {
      return {
        dialogIsOpen : false,

          Selected : [],
          term : null,

          current_page : 1,
          length : 25,
          total : 100,

          checked : false

      }
    },
    created(){
      // this.asyncGetData();
    },
    methods: {
        ...mapActions('UploadModule',[
          'asyncGetData',
          'deleteRecord',

        ]),
        ...mapMutations('UploadModule',[
          'SetPage'
        ]),
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

        handleSearchTerm() {
            
        },
        clearSelected(){
          this.Selected = [];
        },

        EmitOpenModal(){
            this.$refs.uploadForm.openDialog();
        },handleSizeChange(){

        },
        handleCurrentChange(){

        },

        onSelect(e){
          const v = parseInt(e.target.value);
          if(!this.multiple){
            

              if(e.target.checked){
                

                this.Selected = [];
                this.Selected.push(v)
              }else{
                this.Selected = [];
              }

             
                       
          }else{

            if(e.target.checked){
               if(!this.Selected.includes(v)){
                  this.Selected.push(v)
                }
              }else{
                const myIndex = this.Selected.indexOf(v)
                myIndex > -1 ? this.Selected.splice(myIndex, 1) : false

              }

                      
                       
          }
        },

        deleteSelected(){
          // this.$emit('delete');

           var self = this;
            var token = [];
            self.Selected.forEach((e) => {
                token.push({token_id : e})
            })

            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        self.deleteRecord(token).then((response) => {
                            self.$ThatsNotify.success({
                                message : `(${response.deleted}) record was successfully deleted.`
                            })
                          self.refreshTable()
                        })
                    })
        },
        emitSelectModal(){
          const self = this
          const filtered = self.data.filter((item) => {
            return self.Selected.indexOf(item.mdbID) !== -1;
          })
          this.$emit('select',filtered);
          this.closeDialog();

        },

        refreshTable(){
            this.SetPage(0)
            this.asyncGetData();
        }
       
    },

    computed: {
        ...mapGetters('UploadModule',[
          'data'
        ]),

    },

    watch: {
      'dialogIsOpen' : function(newState,oldState) {
          if(newState === false && oldState === true){
            this.$refs.uploadForm.closeDialog();
          }

          if(newState && !oldState){
            this.Selected = [];
          }

      },

    },

  }
</script>
