export const InsufficientAlertModule = {
    namespaced: true,
    state: {
        loading: false,
        data: {
            columns: [
                {
                    label: 'ETA',
                    field: 'eta',
                    type: 'text',
                },
                {
                    label: 'Description',
                    field: 'currencycountry',
                    type: 'text',
                },
                {
                    label: 'Qty',
                    field: 'qty',
                    type: 'text',
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'text',
                },
                {
                    label: 'Reserve',
                    field: 'status',
                    type: 'input',
                },

            ],
            rows: [],
        },
        formRecord: {},

    },
    actions: {

        
    },
    mutations: {
        setRows: (state, payload) => {
            state.data.rows = payload
        },
    },
    getters: {
        columns: state => state.data.columns,
        rows: state => state.data.rows,
    }
}


