<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">

        <div class="form-group input-group-sm row thats-form">
            <label class="col-form-label" :class="labelClass" >{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0 pb-2">
                <template v-if="$attrs.orientation == 'inline'">
                    <mdb-form-inline tag="div" class="mb-2">
                        <mdb-input type="radio"  v-for="(dt,index) in data" v-bind:key="index" :id="$attrs.vid+'-option-'+index" class="thats-status-radio" :name="$attrs.vid" :radioValue="dt.value" v-model="inputValue" :label="dt.label" :disabled="disabled"/>
                    </mdb-form-inline>
                </template>
                <template v-else>
                    <mdb-input v-for="(dt,index) in data" v-bind:key="index" type="radio" :id="$attrs.vid+'-option-'+index" class="thats-status-radio" :name="$attrs.vid" :radioValue="dt.value" v-model="inputValue" :label="dt.label" :disabled="disabled"/>
                </template>
                <template v-if="show_error">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput, mdbFormInline } from "mdbvue";
export default {
    name : 'ThatsRadio',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null,
            default : true
        },
        validated: {
            type: Boolean,
            default : true
        },

        /* 
        Structure
        [
            {label : "" , value : ""}
        ]
        */

        data: {
            type: Array
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        disabled : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },

    },
    components : {
        ValidationProvider,
        mdbInput,
        mdbFormInline,
    },

    data : ()=>({
        inputValue : null
    }),

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        }
    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
            
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    }
}

</script>

<style scoped>

</style>