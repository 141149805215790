<template>
    <FileUpload :files="inputValue" accept="image/png, image/jpeg" @change="onChangeFileUpload"/>
</template>
<script>
import FileUpload from 'mdb-file-upload';
import { ApiHttpService } from '../../../services'
export default {
    props : {
        files : {
            type : Array,
            default : () => []
        }
    },
    data() {
        return {
            inputValue : [],
            multipleChunks : [],
            selectedFiles : [],
            itr : 0
        }
    },
  components : {
    FileUpload,
  },

  methods : {

    onChangeFileUpload(files) {
      this.selectedFiles = files;
    },

    createMultipleChunks(){
        const self = this;
        this.multipleChunks = [];
        Array.prototype.forEach.call(this.selectedFiles, file => {
            let size = 1024 * 1024, chunks = Math.ceil(file.size / size);
            const c = {name: file.name, progress : 0, size : 100, loading : false, path : "", highlight: 0,chunk : [], mime : file.type}
            for (let i = 0; i < chunks; i++) {
                c.chunk.push(file.slice(
                    i * size, Math.min(i * size + size, file.size), file.type
                ));
            }
            
            let reader = new FileReader()
            reader.onload = (e) => {
                let dataURI = e.target.result
                    if (dataURI) {
                        let path = dataURI;
                        const fileType = self.getFileType(file);
                        if(fileType == 'video'){
                            path = '/assets/video.jpg';
                        }
                        c.path = path;
                    }
            }
            reader.readAsDataURL(file) 
            this.multipleChunks.push(c)
        }); 
    },

    recursive(i){
        const self = this;
        self.itr = i

        const arraySize = self.multipleChunks.length
        
        if(self.multipleChunks[self.itr].chunk.length > 0){
            let formData = new FormData;
            const chunks = self.multipleChunks[self.itr];
            formData.set('mime', chunks.mime);
            formData.set('chunkNumber', chunks.chunk.length);
            formData.set('is_last', chunks.chunk.length === 1);
            formData.set('file', chunks.chunk[0], `${self.selectedFiles[self.itr].name}.part`);

            ApiHttpService.upload('upload-image-2',formData).then(response => {
                    self.multipleChunks[self.itr].progress =  parseInt((self.multipleChunks[self.itr].size / chunks.chunk.length));
                        self.multipleChunks[self.itr].loading = true
                    if(chunks.chunk.length  == 1){
                        self.multipleChunks[self.itr].completed = true
                        self.multipleChunks[self.itr].loading = false
                        self.$ThatsNotify.success({
                        message : `${response.data.name} was successfully uploaded`
                        })
                        self.$emit('uploaded',response)
                    }
                    self.multipleChunks[self.itr].chunk.shift();
                    
                    self.recursive(self.itr)

            }).catch(error => {
                self.multipleChunks[self.itr].error = true

            }); 
            self.itr = i;

            
        }else{
            if(self.itr < (arraySize - 1)){
                self.itr++
                this.recursive(self.itr)
            }
            
            
        }
    },

    getFileType(file) {
        if(file.type.match('image.*')){
            return 'image';
        }
        if(file.type.match('video.*')){
            return 'video';
        }
        return 'other';
    },
  },

  watch: {
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
        files(newVal) {
            this.inputValue = newVal;
        },
        selectedFiles(){
            this.createMultipleChunks()

            if(this.multipleChunks.length > 0){
                this.$nextTick(() => {
                    this.recursive(this.itr)
                })
            }
        }
    },
    created() {
        if (this.files) {
        this.inputValue = this.files;
        }
    },
    
}
</script>