<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observerPayment" v-slot="{passes}" @submit.prevent="onSubmitForm">
       <ThatsCustomWindow ref="window" title="Confirm" windowClass="collection height-auto" scrollClass='collection height-auto' :height="300" :width="400" @dialog-state="dialogState" :group="group">
          <div class="scrollbar p-3">
            <el-radio v-model="form.action" label="exchange">Exchange (can be use to another invoice as payment)</el-radio>
            <el-radio v-model="form.action" label="refund">Refund</el-radio>
        </div>     
        <div class="pl-3 pr-3">
            <div class="d-flex">

              <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                   <mdb-btn color="elegant" class="ml-0" size="sm" @click="passes(onSubmitForm)">Confirm</mdb-btn>
                    <mdb-btn color="grey" class="ml-0" size="sm" @click="closeDialog">Cancel</mdb-btn>
                </template>
            </div>
        </div>

        
       </ThatsCustomWindow>
          </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs} from "mdbvue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters } from 'vuex'
import ContactsForm from '../contacts/ContactsForm'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import unformat from 'accounting-js/lib/unformat.js'
import Fuse from 'fuse.js'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    },
    payment_for : {
      type : String,
      default : 'sales-order'
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,


        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsCustomWindow,
        ThatsMoney,

        ContactsForm, 
        ThatsCustomMultiSelect, 
  },

    data : () => ({
      active : 0,
      disabled : false,
      disabledBtn : false,
      validated : false,
      formOriginal : null,
      form : {
          action : 'exchange'
        },
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      formSavePrintLoading : false,
      formPayBalanceLoading : false,
      showError : false,
      paymentMethod : [],
      items : [],
      selected : [],
      customer : [],
      payment_transactions : [],
      transactions : {

        },
      payment_reference : [],
      overpaid_payment_reference : [],
      


    }),

    created : function() {
      var self = this;

      this.form = this.formVar();
      this.currentDateSetter();

      this.optionsHandler();
 

      const {settings} = JSON.parse(localStorage.getItem('user'));
      this.paymentMethod = settings.payment_method
    },



    methods: {

      ...mapActions('TransactionModule',[
        'getNextCodePayment',
      ]),
      ...mapActions('MakePaymentModule',[
        'addPayment'
      ]),
 

      formVar(){
        return {
          action : 'exchange'
        }
      },
/* start */





optionsHandler(){
  // console.log(this.form)


  // if(this.)
},


/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },



      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observerPayment.validate();


            this.showError = false
            this.disabledBtn = true;
            if (isValid) {

               if(self.formLoading === false){
                      self.formLoading = true
                       this.$emit('action-selected', this.form.action)
                      }  

               
                    
            }        
      },


      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observerPayment.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },

      number_format(number, decimals, dec_point, thousands_sep) {
    
            var n = !isFinite(+number) ? 0 : +number, 
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                toFixedFix = function (n, prec) {
                    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                    var k = Math.pow(10, prec);
                    return Math.round(n * k) / k;
                },
                s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },

        addSeparatorsNF(nStr, inD, outD, sep){

          /* 
          nStr : This is the number to be formatted. This might be a number or a string. No validation is done on this input.
        inD : This is the decimal character for the string. This is usually a dot but might be something else.
        outD : This is what to change the decimal character into.
        sep : This is the separator, which is usually a comma.
        */
        nStr += '';
        var dpos = nStr.indexOf(inD);
        var nStrEnd = '';
        if (dpos != -1) {
          nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
          nStr = nStr.substring(0, dpos);
        }
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(nStr)) {
          nStr = nStr.replace(rgx, '$1' + sep + '$2');
        }
        return nStr + nStrEnd;
        },



    },

    computed: {

      ...mapGetters('MakePaymentModule',
      [
        'paymentForm',
        'payment_references',
        'overpaid_payment_references',
      ]),

      disableAmount : function() {
        return (this.form.mode === 'full-payment') ? true : false
      },
        paymentMode : function() {
          const {settings} = JSON.parse(localStorage.getItem('user'));
          let mode = settings.payment_mode
            if(this.form.invoice != null && this.form.invoice.series != null && this.form.invoice.balance_float > 0){
                // mode = settings.payment_mode.shift();
               mode = settings.payment_mode.filter(item => {
				        return item.code !== 'full-payment'
            })

            }
          return mode;
        },

        paymentReferenceRules : function(){
            if(this.form.method === 'credit-note'){
              return 'required';
            }
          return '';
        },
        isCreditNote : function(){
            if(this.form.method === 'credit-note'){
              return true;
            }
          return false;
        }

      },

    watch: {
      paymentForm : function(v){
          // console.log(v)

          let frm = this.formVar();
          // frm.amount =  parseFloat(v.transaction.total).toFixed(2);
          frm.amount =  unformat(v.transaction.total);
          frm.customer = v.transaction.attr.name
          frm.customer_id = v.transaction.customer_id
          frm.transaction_id = v.transaction.mdbID
          frm.transaction = v.transaction

           if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice.series !== null){

              frm.invoice_series = v.transaction.invoice.series
              frm.invoice = v.transaction.invoice
              frm.invoice.balance_float =  unformat(v.transaction.invoice.balance);
              // frm.amount = parseFloat(v.transaction.invoice.balance).toFixed(2);
              frm.amount = unformat(v.transaction.invoice.balance);
              frm.mode = 'down-payment'
            }
           if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice !== null && v.transaction.invoice.series !== null){
              frm.mode = 'full-payment'
            }
          frm.attr = {...frm.attr,...v.attr}

          var MyDate = new Date();
          frm.date = MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form = frm
          // console.log("payment watch form")
          // this.getCustomerCreditNote();
          
      },

      payment_references : function(v){
        this.payment_reference = v
      },
      overpaid_payment_references : function(v){
        this.overpaid_payment_reference = v
      }

     /*  'form.method' : function(val){
        alert(val)
      } */
    },
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>