
import store from '../store'
export default function auth({ next, router, to }) {


  const currentRouteName = to.name;
  console.log('Current route name auth:', currentRouteName);

  let user = JSON.parse(localStorage.getItem('user'));
  // const tokenLocalStorage = localStorage.getItem('user');
  // console.log("auth")
  // console.log(tokenLocalStorage)

  console.log("how router");
  console.log(user);
  if (user === null || (user.hasOwnProperty('location') && user.location === null)) {
    // return router.push({ name: 'login' });
    

    // return router.push({ name: 'login' }).catch(()=>{});
    return store.dispatch('AuthModule/Logout', null, { root: true });

  }



/*   let actions = [];
  if(router.currentRoute){
    const uri = router.currentRoute.path;
      const path = uri.replace(/\//, '');
      console.log(path)
      user.actions.filter((i) => {
            if(i.hasOwnProperty(path)){
              console.log(i[path])
              return i[path];
            }
        });
  } */

/*   const path = router.currentRoute.path.splice(0,  router.currentRoute.path.length - 1);
  console.log(user.permissions.includes(path)) */

/*   if (localStorage.getItem('user')) {
    return router.push({ name: 'welcome' });
  }
 */
/*   console.log(next)
  console.log(from) */
  store.commit('AppModule/setLayout','authenticated');
  // store.commit('AppModule/setAction',actions);
  // console.log($store)
  return next();
}