/* eslint-disable no-prototype-builtins */
<template>

  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="categoryObserver" v-slot="{}" :disabled="true">
    <el-dialog title="Category" :visible.sync="dialogVisible" :close-on-click-modal="false" width="40%" size="small" class="text-left filter-form" ref="window" :show-close="!formLoading" append-to-body>

      <div class="filter-dalog-body p-4">
                  <ThatsInput  :show_error="showError" tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Category Name" placeholder="Category Name" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                   
                  </div>  

            

            <div class="filter-dalog-footer border-top border-light">
                <div class="d-flex align-items-center pr-4 pl-4">

                  <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="onSubmitForm" type="button"><i class="el-icon-save"></i> Save</mdb-btn>
                        <mdb-btn color="elegant" class="ml-0" size="sm" @click="dialogVisible = false"><i class="el-icon-close"></i> Close</mdb-btn>
                      </template>

                      
                </div>
            </div>

        </el-dialog>
  </ValidationObserver>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput, mdbContainer} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import { ThatsWindow,ThatsInput, ThatsSeriesInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'
import { MessageBox } from 'element-ui';
import XWindow from '../../components/thats/XWindow.vue'
import { Loading } from 'element-ui';
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
  inject : [],
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        ThatsSeriesInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer
  },
    
  data(){

    const self = this;

    return {

        dialogVisible : false,
        isIndeterminate : false,
        checkAllReceived : false,
        showError : false,
        disabled : false,
        onPasteLoading : false,
        isMaximized : false,
        inputValue : 0,
        validated : false,
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        formLoading : false,
        height: 800,
        width: 900,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,

        tabs : [
                { text: 'Details', slot: 'form-slot' },
              ],
        gallery : [],
        index : 0
       
    }
  },
    

  created : function() {
        
        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.resetForm();
        
  },




  methods: {
    ...mapMutations('ItemMasterCategoryModule',['setSearchTerm','setFormData','setEmptyForm','setFormStatusCreate']),
    ...mapActions('ItemMasterCategoryModule',['storeRecord','updateRecord','getRecord']),

    onResize({width,height}){  

       
      },
    resetForm(){
      this.form = JSON.parse(JSON.stringify(this.initialFormObject));
      },
    openDialog(){
        // this.$refs.window.openDialog()

        this.dialogVisible = true
    },
    closeDialog(){
      // this.$refs.window.closeDialog()
      this.dialogVisible = false
    },
    getActiveTabIndex(index){
      this.active = index
    },

    onSubmitForm(){
       const self = this;
            self.$refs.categoryObserver.validate().then((isValid) => {
              if(isValid){

                self.closeDialog()
                
                  self.formLoading = true
                  self.storeRecord(self.form).then((res) => {
                    self.showError = false
                    self.formLoading = false
                    self.$ThatsNotify.success({
                        message : "Record successfully added."
                      })
                      self.closeDialog()
                      self.$emit('success',res)
                  }).catch((error) => {

                     
                      if(error.response.data.hasOwnProperty('errors')){
                        self.$refs.categoryObserver.setErrors(error.response.data.errors);
                        self.showError = true
                        self.formLoading = false
                      }
                  })
                }
            });
    },

    cellClassName(){

    },

     

      dialogState(newState){
        if(!newState){
           
            
            this.resetForm();
          return
        }
        
      },

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('ItemMasterCategoryModule',['getLoading','formData','formStatus','initialFormObject']),
      tblKey : function(){
        return 1;
      },
  },

  watch: {
      'formData' : function(v){
        const self = this
        this.eventKeyCounter++
          this.form = JSON.parse(JSON.stringify({...this.initialFormObject,...v}));
          this.$nextTick(()=>{

          
          })
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      }

  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>