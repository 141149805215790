import { ApiHttpService, ApiService } from '../../services'
export const DeliveryScheduleModule = {
    namespaced: true,
    state: {
        data: {
           
        },
        postLoading : false,
        loading : false,
        request : null,

        searchLoading : false,
        searchRequest : null
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },


        addSearchRequest : (state, req) => {
            state.searchRequest = {cancel : req.cancel, msg : "loading"}
        },
        cancelSearchRequest : (state) => {
            state.searchRequest.cancel()
            state.searchLoading = true
        },
        clearOldSearchRequest(state, message) {
            state.searchRequest.msg = message;
            state.searchRequest = null;
        },


        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setPostLoading(state,payload) {
            state.postLoading = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        searchLoadingTrue(state) {
            state.searchLoading = true
        },
        searchLoadingFalse(state) {
            state.searchLoading = false
        },
        setSearchLoading(state, payload) {
            state.searchLoading = payload
        }
    },
    actions : {

        async getDeliverySchedule({ state, commit},params) {

            return await new Promise((resolve, reject) => {
        
                    commit('setLoading', true)


                    const configParams = {
                        req : "DeliveryScheduleModule/req",
                        loadingTrue : "DeliveryScheduleModule/loadingTrue",
                        addRequest : "DeliveryScheduleModule/addRequest",
                        cancelRequest : "DeliveryScheduleModule/cancelRequest",
                        clearOldRequest : "DeliveryScheduleModule/clearOldRequest"
                    }

                    ApiHttpService.get('delivery-schedule', params, configParams).then((response) => {
                        
                        commit('setData', response.data.data)
                        commit('setLoading', false)


                        const responseData = [];


                        if(response.data.data.length > 0){
                            response.data.data.forEach(element => {
                                const dt = new Date(element.start)
                                responseData.push(element)

                            });
                        }

                        resolve(responseData)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },

        async getSearchInvoice({ commit},params) {
            return await new Promise((resolve, reject) => {
                    const configParams = {
                        req : "DeliveryScheduleModule/searchReq",
                        loadingTrue : "DeliveryScheduleModule/searchLoadingTrue",
                        addRequest : "DeliveryScheduleModule/addSearchRequest",
                        cancelRequest : "DeliveryScheduleModule/cancelSearchRequest",
                        clearOldRequest : "DeliveryScheduleModule/clearOldSearchRequest"
                    }

                    commit('searchLoadingTrue')
                    
                    ApiHttpService.get('delivery-schedule-invoice', params, configParams).then((response) => {
                        commit('searchLoadingFalse')
                        resolve(response.data)
                    }).catch(error => {
                        commit('searchLoadingFalse')
                        reject(error)
                    })
                
            })
        },

        //state
        async storeRecord({ commit }, payload) {
            return await new Promise((resolve, reject) => {
                commit('setPostLoading',true)
                ApiHttpService.store('delivery-schedule-invoice',payload).then((response) => {
                    commit('setPostLoading',false)
                    resolve(response.data)
                }).catch((error) => {
                    commit('setPostLoading',false)
                    reject(error)
                })
            })
        },


        updateRecord({}, payload) {
            return new Promise((resolve, reject) => {
                    ApiService.update(`delivery-schedule-invoice`,payload).then((response) => {
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
                
            })
        },

        async deleteRecord({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.deleteRecord('delivery-schedule-invoice',payload).then((response) => {
                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },


    },
    getters: {
        data: state => state.data,
        loading : state => state.loading,
        searchLoading : state => state.searchLoading,
        req: state => state.request,
        searchReq: state => state.searchRequest
    }
}



