<template>
    <div>
         <ThatsTable :tabs="[{ text: 'Quotations' }]" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" @print="fnPrintDoc"  :filterFormData="filterForm" @clearFilterForm="clearFilterForm" >  
        
            <template #filter="{}">
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Tracking No." placeholder="Tracking No." vid="series" name="series" v-model="filterForm.series" size="sm" />
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="CardCode" placeholder="CardCode" vid="card_code" name="card_code" v-model="filterForm.card_code" size="sm" />
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile No." placeholder="Mobile No." vid="customer_mobile" name="customer_mobile" v-model="filterForm.customer_mobile" size="sm" />
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Customer Name" placeholder="Customer Name" vid="customer_name" name="customer_name" v-model="filterForm.customer_name" size="sm" />
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Code" placeholder="Item Code" vid="item_code" name="item_code" v-model="filterForm.item_code" size="sm" />
                <ThatsInput tabindex="1" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Sold By" placeholder="Sold By" vid="sold_to" name="sold_to" v-model="filterForm.sold_to" size="sm" />
                <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Order Status" v-model="filterForm.status" :reduce="label => label.code" :reduceLabel="'label'" :options="statusOptions" :rules="''"></ThatsMultiSelect>
                <div class="form-group input-group-sm row thats-form">
                    <label class="col-form-label col-sm-3">Date Range</label>
                    <div class="col-sm-9 pl-0">
                        <date-range-picker v-model="filterForm.dateRange" :append-to-body="true" class="filter-date-range w-100"></date-range-picker>

                        {{ filterForm.dateRange }}
                    </div>
                </div>
            </template>
            

        </ThatsTable>
        <TransactionForm ref="transactionForm" @success="refreshTable"></TransactionForm>

    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
/* import DateRangePicker from 'vue2-daterange-picker' */
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import DateRangePicker from '../../components/daterange-picker'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import TransactionForm from './TransactionForm.vue'
// import IntegrationWindow from '../../components/thats/IntegrationWindow.vue'
import Filter from './Filter.vue'
export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsInput,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        TransactionForm,
        mdbSelect,
        mdbInput,
        Filter
    },


    data(){
        return {
            endpoint : 'quotations',
            statusOptions : [
              {
                "code": "no-remarks",
                "label": "Open",
                "seperator": false,
                 "disable" : true
              },
              {
                "code": "closed",
                "label": "Closed",
                "seperator": false,
                "disabled" : true
              },
              {
                "code": "cancel",
                "label": "Cancel",
                "seperator": false
              }
            ],
            tableKey : 0,
            tabIndex : 0,
            filterForm : {
                searchTerm : null,
                dateRange: {},
                series : "",
                sold_to : "",
                customer_mobile : "",
                customer_name : "",
                item_code : "",
                status : "",
                balance : false,
            },
        }
    },


    created(){

    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('InventoryTransferModule',['getRecord','cancelInventoryTransferRecord']),
       ...mapMutations('InventoryTransferModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),
       ...mapMutations('InventoryTransferReceiveModule',{
        setInventoryTransferReceiveFormData : 'setFormData'
        }),

        rowClick(row){
            const self = this;
            this.receiveInventoryTransfer(row,'view')
           /*  const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
            this.getRecord(row).then(() => {
                loading.close();
                
                self.$refs.bringToShowroomForm.openDialog();
            }) */
        },
        openNewModal(){
            this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.transactionForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        fnPrintDoc(url){        
            let session = JSON.parse(localStorage.getItem('user'));
            let token = session.token;
            const locationId = session.location.id
            const windowHeight =  window.innerHeight;
            const innerWidth =  window.innerWidth;
            var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
            const win = window.open(`${url}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
        },

      cancelRecord(row){
        const self = this;
        MessageBox.confirm('Are you sure you want to cancel?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                self.cancelInventoryTransferRecord(row.series).then((res) => {
                    self.$ThatsNotify.success({
                        message : "Record successfully updated."
                    })
                    self.refreshTable();
                }).catch((error) => {

                    if(error.response.status == 422){
                        self.$ThatsNotify.warning({
                            message : error.response.data.message
                        })
                    }
                        console.log(error.response.data.message)
                })
                
              })
      },

      receiveInventoryTransfer(row, action){
            const inventoryTransferData = JSON.parse(JSON.stringify(row))

            /* const inventoryTransferId = inventoryTransferData.id */

            
          /*   const filteredItems = this.form.inventory_transfer_items.filter((item)=>{
              return item.it_id == inventoryTransferId;
            },[])

            console.log("filteredItems");
            console.log(filteredItems);
            console.log(this.form.inventory_transfer_items);
            console.log(this.form.items); */

           /*  inventoryTransferData.items = filteredItems.map((item) => {
              const requestedItem =   this.form.items.filter((innerItem) => {
                    return innerItem.id == item.itr_item_id
                },[])[0]

                return {...item, photo : requestedItem.photo}
            },[]) */

            const mergeFormData = {...this.initialFormObject,...inventoryTransferData, action : action}

            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
                this.getRecord(mergeFormData).then((res) => {

                    console.log("getRecord response")
                    console.log(res)
                    loading.close();
                    self.setInventoryTransferReceiveFormData({...JSON.parse(JSON.stringify(res)),action : action})
                    self.$refs.bringToShowroomForm.openDialog();
                })

            
            console.log(mergeFormData)
           /* this.setInventoryTransferReceiveFormData(JSON.parse(JSON.stringify(mergeFormData)))

           this.$nextTick(()=>{

            this.$refs.inventoryTransferReceivingForm.openDialog()
           }) */
        },
        openIntegrationWindow(){
            this.$refs.integrationWindow.openDialog();
        },
        clearFilterForm(){
            this.filterForm = {
                searchTerm : null,
                supplierCode : null,
                itemName : null,
                ItmGroup : "",
                brand : "",
                brands : "",
                collection : "",
            }
        },

    },
    computed: {
        ...mapGetters('RebuildQuotationModule',['getLoading', 'columns','initialFormObject']),

        statusArrayText : function(){
            return {'draft' : 'Draft','for-approval' : 'For Approval','approved': 'Approved'};
        }
    },
}


</script>