<template>
<ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors}">
  
  <div class="form-group input-group-sm row thats-form">
            <label :for="$attrs.vid" class="col-form-label" :class="labelClass">{{$attrs.label}}</label>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <div
                    class="autocomplete-wrapper"
                    role="combobox"
                    :aria-expanded="`${show}`"
                    :class="{
                    'autocomplete-validated': wasValidated,
                    'autocomplete-valid': isValid && wasValidated,
                    }"
                >
                    <mdb-input
                    class="mb-0 pb-0"
                    inputClass="mb-0"
                    :outline="outline"
                    ref="autocompleteRef"
                    :placeholder="placeholder"
                    type="text"
                    v-model="search"
                    :icon="icon"
                    :far="far"
                    :fad="fad"
                    :fal="fal"
                    :fab="fab"
                    :regular="regular"
                    :brands="brands"
                    :duotone="duotone"
                    :light="light"
                    :iconClass="iconClass"
                    :required="validation"
                    @mousedown.right.native.prevent
                    @input="onChange"
                    @keydown.native="handleKeyDown"
                    @focus="focus"
                    @blur.native="blur"
                    role="textbox"
                    :disabled="disabled"
                    :aria-haspopup="true"
                    :customValidation="wasValidated"
                    :isValid="isValid"
                    :validFeedback="!show && validFeedback"
                    :invalidFeedback="!show && invalidFeedback"
                    autocomplete="off" noWrapper bg
                    >
                    </mdb-input>

                    <transition
                    enter-active-class="animated fadeIn"
                    leave-active-class="animated fadeOut"
                    >
                    <span v-if="search !== '' && clearBtn" class="close-btn">
                        <mdb-btn
                        @click="handleClear"
                        class="p-0 m-0"
                        flat
                        icon="times"
                        :iconClass="`${active && 'text-primary'}`"
                        size="sm"
                        ></mdb-btn>
                    </span>
                    </transition>

                        <div ref="popper" class="dropdown-wrapper" :style="wrapperStyle">

                            <div
                            v-show="show"
                            :style="{ overflowY: 'hidden', height: maxHeight }"
                            ref="options"
                            class="mdb-autocomplete-wrap"
                            >
                            <ul
                                class="dropdown-content list-unstyled"
                                ref="list"
                                :style="{ height: scroll ? maxHeight : '' }"
                                :class="optionsClass+' '+dropdownClass"
                                role="listbox"
                            >
                                <li class="disabled autocomplete-option" v-if="isLoading">
                                {{ loadingText }}
                                </li>
<!--                                 <li
                                role="option"
                                class="autocomplete-option"
                                v-if="!isLoading && results.length === 0"
                                >
                                {{ resultText }} 
                                </li> -->
                                <li
                                tabindex="0"
                                ref="option"
                                class="autocomplete-option"
                                v-for="(result, i) in results"
                                :key="i"
                                @click="setResult(result)"
                                @keydown.prevent="navigateOptions"
                                :class="{ 'is-active': i === arrowCounter }"
                                role="option"
                                :aria-selected="result === search"
                                aria-disabled="false"
                                >
                                {{ result }}
                                </li>
                            </ul>
                            </div>
                    
                        </div>
                    </div>


                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
            </div>
            </div>
        </div>

  
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import {mdbInput, mdbBtn, mdbDropdown,
      mdbIcon} from "mdbvue";
import Popper from "popper.js";

const Autosuggest = {
  name: "ThatsAutosuggest",
  components: {
    mdbInput,
    mdbBtn,
    mdbIcon,
    ValidationProvider
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false
    },
    loadingText: {
      type: String,
      default: "Loading results..."
    },

    value: {
      type: String,
      default : ""
    },
    placeholder: {
      type: String,
      required: false
    },
    iconClass: {
      type: [String, Array],
      required: false
    },
    far: {
      type: Boolean,
      default: false
    },
    regular: {
      type: Boolean,
      default: false
    },
    fal: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    fab: {
      type: Boolean,
      default: false
    },
    brands: {
      type: Boolean,
      default: false
    },
    fad: {
      type: Boolean,
      default: false
    },
    bg: {
      type: Boolean,
      default: false
    },
    duotone: {
      type: Boolean,
      default: false
    },
    display: {
      type: Number,
      default: 4
    },
    flipOnScroll: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    scroll: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      required: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    clearBtn: {
      type: Boolean,
      default: true
    },
    filterFunction: {
      type: Function,
      default: (item, search) => {
        return item.toLowerCase().indexOf(search.toLowerCase()) > -1;
      }
    },
    validation: {
      type: Boolean,
      default: false
    },
    wasValidated: {
      type: Boolean,
      default: false
    },
    invalidFeedback: {
      type: String
    },
    validFeedback: {
      type: String
    },
    resultText: {
      type: String,
      default: "no results found"
    },
    showAll: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },

    rules : {
            type : [Object, String],
            default : ''
        },
validated: {
            type: Boolean,
            default : false
        },
labelClass : {
            type : String,
            default : 'col-md-3'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
show_error: {
            type: Boolean,
            default : false
        },
  },
  data() {
    return {
      search: "",
      results: [],
      show: false,
      isLoading: false,
      active: false,
      arrowCounter: 0,
      maxHeight: 0,
      scrollOffset: 0,
      flipped: false,
      popperJS: null,
      popperOptions: {
        placement: this.top ? "top" : "bottom",
        eventsEnabled: false,
        modifiers: {
          offset: {
            offset: "0, 40"
          },
          flip: {
            boundriesElement: "viewport"
          }
        }
      },

      dropdownClass : null
      
    };
  },
  computed: {
    activeIndex() {
      return this.results.indexOf(this.search) === -1
        ? 0
        : this.results.indexOf(this.search);
    },
    isValid() {
      return this.data.indexOf(this.search) !== -1;
    },
    optionsClass() {
      return this.scroll ? "options-container scrollbar-grey thin" : "";
    },
    wrapperStyle() {
      let marginLeft = this.outline ? "33px" : "40px";
      let width = this.outline ? "calc(100% - 33px)" : "calc(100% - 40px)";

      return this.icon
        ? {
            marginLeft,
            width
          }
        : {};
    },
    visibleItems() {
      return this.display < this.results.length && this.scroll
        ? this.display
        : this.results.length;
    },
    windowHeight() {
      return window.innerHeight;
    }
  },
  methods: {

    blur(e) {
      this.active = false;
      this.$nextTick().then(() => {
        alert(this.results.length)
            if(this.results.length ===0){
              this.setResult(this.search);
            }
        })
      alert
    },
    createPopper() {
      this.$nextTick(() => {
        this.popperJS = new Popper(
          this.$refs.popper,
          this.$refs.options,
          this.popperOptions
        );
      });
    },
    filterResults() {
      this.results = this.data.filter(item => {
        return this.filterFunction(item, this.search);
      });

      this.setHeight();

      this.arrowCounter = 0;

      this.scrollDropdown();

      setTimeout(this.updatePopper, 300);
    },
    focus(e) {
      //prevent text selection
      if (e.target.value && e.target.value !== "") {
        e.target.selectionStart = e.target.value.length;
        e.target.selectionEnd = e.target.value.length;
      }
      // this.filterResults()
      this.active = true;
      this.show = true;
      this.$emit("focus", e);
      this.filterResults();
    },
    handleClear() {
      this.search = "";
      this.scrollDropdown();
      this.show = false;
      this.arrowCounter = 0;
      this.$emit("input", null);
      this.$emit("clear");
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.scrollDropdown();
        this.show = false;
      }
    },
    handleKeyDown(e) {
      if (e.keyCode === 38 && e.altKey && this.show) {
        this.show = false;
        return;
      }

      const keyCodes = [38, 40, 13];
      if (
        (this.search === "" && !this.showAll) ||
        keyCodes.indexOf(e.keyCode) === -1
      )

        return;
/*       if(e.keyCode === 13 && this.search !== "" && this.results.length === 0){
        this.$emit("newWindow",this.search);
      } */

      this.show = true;
      if (this.$refs.option && this.$refs.option[this.arrowCounter])
        this.$refs.option[this.arrowCounter].focus();
    },
    navigateOptions(e) {
      const close =
        this.show && (e.keyCode === 27 || (e.keyCode === 38 && e.altKey));

      if (close) {
        this.scrollDropdown();
        this.show = false;
        this.$refs.autocompleteRef.$refs.input.focus();
        return;
      }

      let next;

      switch (e.keyCode) {
        case 13:

          this.setResult(this.results[this.arrowCounter]);

          break;
        case 38:
          next =
            this.arrowCounter > 0
              ? this.arrowCounter - 1
              : this.results.length - 1;

          this.arrowCounter = next;

          this.$refs.option[this.arrowCounter].focus();

          break;
        case 40:
          next =
            this.arrowCounter < this.results.length - 1
              ? this.arrowCounter + 1
              : 0;

          this.arrowCounter = next;

          this.$refs.option[this.arrowCounter].focus();

          break;
        case 35:
          this.arrowCounter = this.results.length - 1;

          this.$refs.option[this.arrowCounter].focus();

          break;
        case 36: {
          this.arrowCounter = 0;

          this.$refs.option[this.arrowCounter].focus();
        }
      }
    },
    onChange() {
      this.show = true;

      this.$emit("search", this.search);

      if (this.isAsync) {
        this.isLoading = true;
      }

      if (this.search === "") {
        this.scrollDropdown();

        if (this.showAll) this.results = [...this.data];
        else this.show = false;
      } else {
        this.filterResults();
      }
    },
    scrollDropdown() {
      this.arrowCounter = this.activeIndex;

      this.$refs.options.scrollTo({
        top: this.arrowCounter * 44,
        behavior: "smooth"
      });
    },
    setFlipped(data) {
      this.flipped = data.flipped;
      if ((data.flipped && !this.top) || (!data.flipped && this.top)) {
        const offset = this.bg ? "44px" : this.outline ? "0px" : "30px";
        const margin = this.outline ? "-30px" : "0px";
        this.$refs.options.style.top = offset;
        this.$refs.options.style.marginBottom = margin;
      }
    },
    setHeight() {
      // let additional = this.isLoading ? 44 : this.results.length === 0 ? 44 : 0;
      const height = 
       /*  this.results.length === 0 || this.isLoading
          ? 44
          : this.visibleItems * 44; */
        this.results.length === 0 || this.isLoading
          ? `auto`
          : `${this.visibleItems * 30}px`;

          const checkResult =  this.results.length === 0 || this.isLoading
          ? `dropdown-empty`
          : null;


      // this.maxHeight = `${this.visibleItems * 44}px`;

      this.dropdownClass = checkResult
      this.maxHeight = `${height}`;
    },
    setResult(result) {
      this.search = result;
      this.scrollDropdown();
      this.show = false;
      this.$emit("input", result);
      this.$refs.autocompleteRef.value = result
      this.$emit("selected",result)
      this.$refs.autocompleteRef.$refs.input.focus();
    },
    updatePopper() {
      if (this.appendToBody) this.calculatePosition();
      this.popperJS ? this.popperJS.scheduleUpdate() : this.createPopper();
    },
    //transitions
    beforeEnter(el) {
      el.style.opacity = 0;
      const translation =
        (this.flipped && !this.top) || (this.top && !this.flipped) ? 100 : -100;
      this.$refs.list.style.transform = `translateY(${translation}%)`;
    },
    enter(el, done) {
      setTimeout(() => {
        el.style.opacity = 1;
        this.$refs.list.style.transform = "translateY(0%)";
        done();
      }),
        0;
    },
    leave(el) {
      const translation =
        (this.flipped && !this.top) || (this.top && !this.flipped) ? 100 : -100;
      this.$refs.list.style.transform = `translateY(${translation}%)`;
      el.style.opacity = 0;
    },
    calculatePosition() {
      const parent = this.$el.getBoundingClientRect();

      const offsetX = this.icon ? (this.outline ? 33 : 40) : 0;

      this.$refs.popper.style.top = parent.y + window.scrollY + "px";
      this.$refs.popper.style.left = parent.x + window.scrollX + "px";
      this.$refs.popper.style.width = parent.width - offsetX + "px";
    },


  },
  mounted() {
     if (this.value) {
      this.search = this.value;
      this.filterResults();
    }

    document.addEventListener("click", this.handleClickOutside);

    if (!this.outline)
      this.popperOptions.modifiers.offset.offset = this.bg
        ? "0, 48px"
        : "0, 42px";
    this.popperOptions.onUpdate = this.setFlipped;
    this.popperOptions.onCreate = this.setFlipped;
    if (this.flipOnScroll) this.popperOptions.eventsEnabled = true;
    if (this.top) this.setFlipped({ flipped: false });

    if (this.appendToBody) {
      const [body] = document.getElementsByTagName("body");
      body.appendChild(this.$refs.popper);

      this.calculatePosition();

      window.addEventListener("resize", this.calculatePosition);
    }
  },
  beforeDestroy() {
    if (this.popperJS) this.popperJS.destroy();
    document.removeEventListener("click", this.handleClickOutside);

    if (this.appendToBody) {
      window.removeEventListener("resize", this.calculatePosition);
    }
  },
  watch: {
    data(value) {
      // we want to make sure we only do this when it's an async request
      if (this.isAsync) {
        // const prevHeight = this.maxHeight;

        this.isLoading = false;

        this.results = value;

        this.setHeight();

        setTimeout(this.updatePopper, 300);
      }
    },
    show(value) {
      if (value) {
        this.updatePopper();
      }
    },
    value(value) {
      this.search = (value === null) ? "" : value;
      this.filterResults();
    },
    maxHeight() {
      if (this.flipped || this.top) {
        this.show = false;
        setTimeout(() => (this.show = true), 300);
      }
    }
  }
};

export default Autosuggest;
</script>

<style>

.autocomplete-wrapper .md-form .form-control.is-valid {
  background-position: calc(100% - 1.75rem) !important;
}

.autocomplete-validated .prefix,
.autocomplete-validated label {
  color: red !important;
}

.autocomplete-valid .prefix,
.autocomplete-valid label {
  color: #28a745 !important;
}

.autocomplete-wrapper .md-form .form-control.is-invalid {
  background-position: calc(100% - 1.75rem) !important;
}

.autocomplete-wrapper label {
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  vertical-align: middle;
}
</style>
<style scoped>
.autocomplete-wrapper {
  position: relative;
}

.is-active {
  background-color: #eee;
}

.autocomplete-option {
  transition: background-color 0.2s linear;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  vertical-align: middle;

  color: #495057;
}

.autocomplete-option:focus {
  outline: none !important;
  background-color: #f5f5f5;
}

.autocomplete-option:hover {
  background-color: #f5f5f5;
}
.clear {
  position: absolute;
  z-index: 2;
  top: 0.5rem;
  right: 0;
  border: none;
  background: 0 0;
  padding-bottom: 0.2rem;
}
.clear svg {
  fill: #a6a6a6;
}

.dropdown-wrapper {
  top: 0;
  width: 100%;
  position: absolute;
}

.dropdown-content {
  display: block;
  opacity: 1;
  width: 100%;
  overflow-y: hidden;
  transition-property: height, transform;
  transition-timing-function: linear;
  transition-duration: 0.2s;
}

.mdb-autocomplete-wrap {
  transition-property: height, opacity;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  background-color: transparent !important;
}

.scrollbar-grey::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #f5f5f5;
  border-radius: 10px;
}

.scrollbar-grey::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar-grey::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #9e9e9e;
}

.thin::-webkit-scrollbar {
  width: 4px;
}

.options-container {
  overflow-y: scroll;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  transition: all 0.2s ease-out;
}

.close-btn:hover {
  transform: scale(1.2);
}
</style>
