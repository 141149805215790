export const info = () => {
    let user = JSON.parse(localStorage.getItem('fw-user'));
    if (user) {
        return user.user.name;
    } else {
        return {};
    }
}

export const token = () => {
    let user = JSON.parse(localStorage.getItem('fw-user'));
    // console.log(user.token);
    if (user && user.token) {
        return { 'token': user.token };
    } else {
        return {};
    }
}

export const tokenBearer = () => {
    let user = JSON.parse(localStorage.getItem('fw-user'));
    // console.log(user.token);
    return user.token
    /* if (user && user.token) {
        return { 'Authorization': 'Bearer ' +  user.token };
    } else {
        return {};
    } */
}