import Axios from 'axios'
import { bearer } from '../helpers'
import store from '../store';

export const ApiGetServiceVer2 = {
    get(endpoint = '', params,configParams) {
        if(store.getters[configParams.req]) this.cancelRequest(configParams)
        return this.makeRequest(endpoint,params,configParams);
        
    },
     makeRequest(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();
        store.commit(configParams.loadingTrue)
        store.commit(configParams.addRequest,axiosSource)
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}

        // console.log(params)
        return Axios.get(`api/${endpoint}`, { params: {...params }, cancelToken: axiosSource.token, headers : {...bearer()}}).then((response) => {
            self.clearOldRequest(configParams,"Success");
            
            return Promise.resolve(response)
        }).catch((error) => {
            self.logResponseErrors(error)
            return Promise.reject(error)
        })
    },

    post(endpoint,object,configParams){
         
        const self = this;
        const axiosSource = Axios.CancelToken.source();
        store.commit(configParams.loadingTrue)
        store.commit(configParams.addRequest,axiosSource)
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = {...location,...object}

        // console.log(params)
        return Axios.post(`api/${endpoint}`, params, { headers: bearer() }).then((response) => {
            self.clearOldRequest(configParams,"Success");
            
            return Promise.resolve(response)
        }).catch((error) => {
            self.logResponseErrors(error)
            return Promise.reject(error)
        })
    },


    async update(endpoint, object) {
        let session = JSON.parse(localStorage.getItem('user'));
        let location = {
            location: session.location.id,
            prefix: session.location.prefix,
        }
        const params = { ...location, ...object }
        return await Axios.put(`/api/${endpoint}`, params, { headers: bearer() })
            .then(response => Promise.resolve(response))
            .catch(error => Promise.reject(error))
    },

    cancelRequest(configParams){
        store.commit(configParams.cancelRequest)
        store.commit(configParams.loadingTrue)
    },
    
    logResponseErrors(err) {
        if (Axios.isCancel(err)) {
            console.log("Request cancelled");
        }
    },
    clearOldRequest(configParams,msg) {
          store.commit(configParams.clearOldRequest,msg)    
    }
}