import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
/*
const requireContext = require.context('./modules', false, /.*\.js$/)


const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true
        }

        return { ...modules, [name]: module }
    }, {}) */

import { AppModule } from './modules/app.module'
import { AuthModule } from './modules/auth.module'
import { ItemModule } from './modules/item.module'
import { NewItemModule } from './modules/new_item.module'
import { TableModule } from './modules/table.module'
import { DialogModule } from './modules/dialog.module'
import { CustomerVendorModule } from './modules/customer-vendor.module'
import { CategoryModule } from './modules/category.module'
import { CollectionModule } from './modules/collection.module'
import { TypeModule } from './modules/type.module'
import { UploadModule } from './modules/upload.module'
import { AttributeModule } from './modules/attribute.module'
import { BrandModule } from './modules/brand.module'
import { WarehouseModule } from './modules/warehouse.module'
import { ShowroomsModule } from './modules/showrooms.module'
import { ForeigncurrencyModule } from './modules/foreigncurrency.module'
import { ContactsModule } from './modules/contacts.module'
import { VendorsModule } from './modules/vendors.module'
import { EmployeesModule } from './modules/employees.module'
import { OthersModule } from './modules/others.module'
import { UserModule } from './modules/user.module'
import { ColourModule } from './modules/colour.module'
import { EtaModule } from './modules/eta.module'
import { FamilyModule } from './modules/family.module'
import { FinishesModule } from './modules/finishes.module'
import { MaterialModule } from './modules/material.module'
import { AreaModule } from './modules/area.module'
import { PurchaseOrderModule } from './modules/purchase-order.module'
import { CustomerOrderModule } from './modules/customer-order.module'
import { SalesPromoModule } from './modules/sales-promo.module'
import { SalesModule } from './modules/sales.module'
import { SalesOrderModule } from './modules/sales-order.module'
import { QuotationModule } from './modules/quotation.module'
import { FabricModule } from './modules/fabric.module'
import { PatternModule } from './modules/pattern.module'
import { WidthRangeModule } from './modules/width-range.module'
import { UseApplicationModule } from './modules/use-application.module'
import { FabricAttributeModule } from './modules/fabric-attribute.module'
import { OnlineApplicationModule } from './modules/online-application.module'
import { WallpaperModule } from './modules/wallpaper.module'
import { RoleModule } from './modules/role.module'
import { DesignationModule } from './modules/designation.module'
import { UserLogModule } from './modules/user-log.module'
import { DeliveryModule } from './modules/delivery.module'
import { TransactionModule } from './modules/transaction.module'
import { OnlineOrderModule } from './modules/online-order.module'
import { ReturnExchangeModule } from './modules/return-exchange.module'
import { ReceivingModule } from './modules/receiving.module'
import { TableV1Module } from './modules/table-v1.module'
import { InvoiceModule } from './modules/invoice.module'
import { MakePaymentModule } from './modules/make-payment.module'
import { DiscountModule } from './modules/discount.module'
import { BarcodeModule } from './modules/barcode.module'
import { ItemDependencyModule } from './modules/item-dependencies.module'
import { PurchaseOrderV1Module } from './modules/purchase-order-v1.module'
import { BrandV1Module } from './modules/brand-v1.module'
import { PurchaseRequestModule } from './modules/purchase-request.module'
import { InsufficientAlertModule } from './modules/insufficient-alert.module'
import { PullOutModule } from './modules/pull-out.module'
import { DataModule } from './modules/data.module'
import { CustomTableModule } from './modules/custom-table.module'
import { BarcodeFabricModule } from './modules/barcode-fabric.module'
import { BarcodeFabricFormModule } from './modules/barcode-fabric-form.module'
import { ItemCancellationModule } from './modules/item-cancellation.module'
import { UpdateLocationModule } from './modules/update-location.module'
import { ReportModule } from './modules/report.module'
import { ReportByBrandModule } from './modules/report-by-brand.module'
import { ReportByMonthModule } from './modules/report-by-month.module'
import { ItemExchangeModule } from './modules/item-exchange.module'
import { ItemExchangeFormModule } from './modules/item-exchange-form.module'
import { ItemRefundModule } from './modules/item-refund.module'
import { RefundCollectionModule } from './modules/refund-collection.module'
import { ShipmentReceivedModule } from './modules/shipment-received.module'

import { TableV2Module } from './modules/table-v2.module'
import { PhysicalCountModule } from './modules/physical-count.module'
import { HistoryModule } from './modules/history.module';
import { LabelPrintingModule } from './modules/label-printing.module'
import { IncomingShipmentModule } from './modules/incoming-shipment.module'
import { BillOfMaterialModule } from './modules/bill-of-material.module'
import { ItemFilterModule } from './modules/item-filter.module'

import { CodeChangeModule } from './modules/code-change.module'
import { SerialNumberModule } from './modules/serial-number.module'
import { StickerModule } from './modules/sticker.module'
import { DashboardModule } from './modules/dashboard.module'
import PaymentModule from './modules/payment.module'
import {QuotationTableModule} from './modules/quotations-table.module'
import {InvoiceTableModule} from './modules/invoices-table.module'
import {SapInvoicesModule} from './modules/sap-invoices-table.module'
import {SalesReportModule} from './modules/sales-report.module'
import {CostingToolModule} from './modules/costing-tool.module'
import {ApplyAmountModule} from './modules/apply-amount.module'
import {CodeGeneratorModule} from './modules/code-generator.module'
import {CodeGeneratorAreaModule} from './modules/code-generator-area.module'
import {CodeGeneratorCategoryModule} from './modules/code-generator-category.module'
import {CodeGeneratorVariationModule} from './modules/code-generator-variation.module'
import {SapPurchaseOrderModule} from './modules/sap-purchase-order.module'
import {ContactPersonModule} from './modules/contact-person.module'

import {SurveyModule} from './modules/survey.module'
import {SapWarehouseModule} from './modules/sap-warehouse.module'
import {DeliveryScheduleModule} from './modules/delivery-schedule.module'
import {BringToShowroomModule} from './modules/bring-to-showroom.module'
import {InventoryTransferRequestModule} from './modules/inventory-transfer-request.module'

import {InventoryItemMasterModule} from './modules/inventory-item-master.module'

import {LocalPurchaseDeliveryNoteModule} from './modules/local-purchase-delivery-note.module'
import {PurchaseOrderV2Module} from './modules/purchase-order.v2.module'
import {GoodsReceiptNoteModule} from './modules/goods-receipt-note.module'

import {InventoryTransferModule} from './modules/inventory-transfer.module'
import {InventoryTransferReceiveModule} from './modules/inventory-transfer-receive.module'
import {ItemBrandModule} from './modules/item-brand.module'
import {ItemCategoryModule} from './modules/item-category.module'
import {ItemCollectionModule} from './modules/item-collection.module'
import {ItemInventoryReportModule} from './modules/item-inventory-report.module'
import {DataIntegrationModule} from './modules/data-integration.module'
import {ItemPromotionModule} from './modules/item-promotion.module'
import {SalesTargetModule} from "./modules/sales-target.module";
import {ShowroomTargetModule} from "./modules/showroom-target.module";
import {CustomerSegmentModule} from "./modules/customer-segment.module";

import {ItemMasterModule} from "./modules/item-master.module";
import {ItemMasterCategoryModule} from "./modules/item-master-category.module";
import {BusinessPartnerModule} from "./modules/business-partner.module";
import {QuantityAdjustModule} from "./modules/quantity-adjust.module";

import {LoyaltyMemberModule, FindLoyaltyMemberModule} from "./modules/loyalty-member.module";
import { LoyaltyCardModule } from './modules/loyalty-card.module'
import {TireModule} from "./modules/tire.module";
import {LoyaltyTransactionModule} from "./modules/loyalty-transaction.module";
import {PhysicalInventoryCountModule} from "./modules/physical-inventory-count.module";
import {PostedInventoryModule} from "./modules/posted-inventory.module";
import {LoyaltyCustomerModule} from "./modules/loyalty-customer.module";
import {LoyaltyCustomerCardUpgradeModule} from "./modules/loyalty-customer-card-upgrade.module";
import {TierBeneficiaryModule} from "./modules/tier-beneficiary.module";
import {PicklistModule} from "./modules/picklist.module";
import {LoyaltyTransactionReport} from "./modules/loyalty-transaction-report.module";
import {DataTableModule} from "./modules/data-table.module";
import {RebuildQuotationModule} from "./modules/rebuild-quotation.module";
import {LoyaltyReportModule} from "./modules/loyalty-report.module";
import {CouponModule} from "./modules/coupon.module";
import {GenerateNewCouponModule} from "./modules/generate-new-coupon.module";
import {CustomerCouponModule} from "./modules/customer-coupon.module";
import { CategoryMasterModule } from './modules/category-master.module'
import { MaterialMasterModule } from './modules/material-master.module'
import { ColourMasterModule } from './modules/colour-master.module'
import { SizeMasterModule } from './modules/size-master.module'
import {CustomerBenefitModule} from "./modules/customer-benefit.module";
import {LoyaltyMemberIssueCouponModule} from "./modules/loyalty-member-issue-coupon.module";
import {LoyaltyIssueCouponModule} from "./modules/loyalty-issue-coupon.module";
import {LoyaltyMemberServiceModule} from "./modules/loyalty-member-service.module";
import { SMSTemplateModule } from './modules/sms-template.module'
import {VoucherModule } from './modules/voucher.module'
import {IssueVoucherModule} from "./modules/issue-voucher.module";
import {LoyaltyRedeemServiceModule} from "./modules/loyalty-redeem-service.module"
import {LoyaltyMemberIssueGiftModule} from "./modules/loyalty-member-issue-gift.module";
import { OnlinePaymentLinkModule } from './modules/online-payment-link.module'
import {AddCmsModule} from "./modules/add-cms.module";
import {LoyaltyRedeemVoucherModule} from "./modules/loyalty-redeem-voucher.module";
import {CustomerLogsModule} from "./modules/customer-logs.module";
import {LocationSetupModule} from "./modules/location-setup.module";
import {ChatWebhookModule} from "./modules/chat-webhook.module";
import { UserSalesReportModule } from './modules/user-sales-report.module'
import {ContactInvoicesTableModule} from "./modules/contact-invoices-table.module";
import {ContactCustomTableModule} from "./modules/contact-custom-table.module";
import {ContactSalesReportModule} from "./modules/contact-sales-report.module";
import { PaymentMeansModule } from './modules/payment-means.module'
import { InventoryPostingModule } from './modules/inventory-posting.module'
import { CallLogModule } from './modules/call-log.module'
export default new Vuex.Store({
    modules : {

        AppModule,
        AuthModule,
        ItemModule,
        NewItemModule,
        TableModule,
        DialogModule,
        CustomerVendorModule,
        ShowroomsModule,
        CategoryModule,
        CollectionModule,
        TypeModule,
        UploadModule,
        AttributeModule,
        BrandModule,
        WarehouseModule,
        ForeigncurrencyModule,
        ContactsModule,
        VendorsModule,
        EmployeesModule,
        OthersModule,
        UserModule,
        QuantityAdjustModule,

        ColourModule,
        EtaModule,
        FamilyModule,
        FinishesModule,
        MaterialModule,
        AreaModule,
        PurchaseOrderModule,
        CustomerOrderModule,
        SalesPromoModule,
        SalesModule,
        SalesOrderModule,
        QuotationModule,
        FabricModule,
        PatternModule,
        WidthRangeModule,
        UseApplicationModule,
        FabricAttributeModule,
        OnlineApplicationModule,
        WallpaperModule,
        RoleModule,
        DesignationModule,
        UserLogModule,
        DeliveryModule,
        TransactionModule,
        OnlineOrderModule,
        ReturnExchangeModule,
        ReceivingModule,
        TableV1Module,
        InvoiceModule,
        MakePaymentModule,
        DiscountModule,
        BarcodeModule,
        ItemDependencyModule,
        PurchaseOrderV1Module,
        BrandV1Module,
        PurchaseRequestModule,
        InsufficientAlertModule,
        PullOutModule,
        DataModule,
        CustomTableModule,
        ContactCustomTableModule,
        BarcodeFabricModule,
        BarcodeFabricFormModule,
        ItemCancellationModule,
        UpdateLocationModule,
        ReportModule,
        ReportByBrandModule,
        ItemExchangeModule,
        ItemExchangeFormModule,
        ItemRefundModule,
        RefundCollectionModule,
        TableV2Module,
        CustomerBenefitModule,
        LoyaltyIssueCouponModule,
        LoyaltyRedeemServiceModule,
        LoyaltyRedeemVoucherModule,
        PhysicalCountModule,
        HistoryModule,
        ShipmentReceivedModule,
        LabelPrintingModule,
        IncomingShipmentModule,
        BillOfMaterialModule,
        ReportByMonthModule,
        ItemFilterModule,
        CodeChangeModule,
        SerialNumberModule,
        StickerModule,
        PaymentModule,
        DashboardModule,
        QuotationTableModule,
        InvoiceTableModule,
        ContactInvoicesTableModule,
        SapInvoicesModule,
        SalesReportModule,
        CostingToolModule,
        ApplyAmountModule,
        CodeGeneratorModule,
        CodeGeneratorAreaModule,
        CodeGeneratorCategoryModule,
        CodeGeneratorVariationModule,
        SapPurchaseOrderModule,
        ContactPersonModule,
        SurveyModule,
        DeliveryScheduleModule,
        SapWarehouseModule,
        BringToShowroomModule,
        InventoryTransferRequestModule,
        InventoryTransferModule,
        InventoryTransferReceiveModule,
        ItemBrandModule,
        ItemCategoryModule,
        ItemCollectionModule,
        ItemInventoryReportModule,
        DataIntegrationModule,
        ItemPromotionModule,
        SalesTargetModule,
        ShowroomTargetModule,
        // DataIntegrationModule,

        CustomerSegmentModule,
        InventoryItemMasterModule,
        ItemMasterModule,
        ItemMasterCategoryModule,
        BusinessPartnerModule,
        LocalPurchaseDeliveryNoteModule,
        PurchaseOrderV2Module,
        GoodsReceiptNoteModule,
        LoyaltyMemberModule,
        LoyaltyCustomerModule,
        LoyaltyCustomerCardUpgradeModule,
        FindLoyaltyMemberModule,
        LoyaltyCardModule,
        LoyaltyTransactionModule,
        TireModule,
        AddCmsModule,
        PhysicalInventoryCountModule,
        PostedInventoryModule,
        TierBeneficiaryModule,
        CustomerLogsModule,
        PicklistModule,
        LoyaltyTransactionReport,
        DataTableModule,
        RebuildQuotationModule,
        LoyaltyReportModule,
        CouponModule,
        GenerateNewCouponModule,
        CustomerCouponModule,
        LoyaltyMemberIssueCouponModule,
        LoyaltyMemberIssueGiftModule,
        LoyaltyMemberServiceModule,
        CategoryMasterModule,
        MaterialMasterModule,
        ColourMasterModule,
        SizeMasterModule,
        SMSTemplateModule,
        OnlinePaymentLinkModule,
        LocationSetupModule,
        ChatWebhookModule,
        UserSalesReportModule,
        VoucherModule,
        IssueVoucherModule,
        ContactSalesReportModule,
        PaymentMeansModule,
        InventoryPostingModule,
        CallLogModule

    },

    state : {
        isLogin : false,
        user : null
    },
    mutations : {
        setLogin(state, payload){
            state.isLogin = payload
        },
        setUser(state, payload){
            state.user = payload
        }
    },
    getters : {
        isLogin : state => state.isLogin,
        user : state => state.user
    }
})
