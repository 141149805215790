/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" :title="(formStatus === 'create' ? 'New' : title)" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">


            <template #left-button>
              <template v-if="formLoading || getLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template>
            </template>
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="fnSave('save')">
            <div class="mt-1">
              <mdb-row class="tab-row">
                      <mdb-col col="4" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Vendor Code" vid="mobile" name="mobile" v-model="form.CardCode" size="sm" :disabled="true"/>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Name" vid="name" name="name" v-model="form.CardName" size="sm" :disabled="true"/>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="Currency" vid="currency" name="currency" v-model="form.DocCurrency" size="sm" :disabled="true"/>
                        <ThatsMoney :money="{ decimal: '.', thousands: ',', precision: 2, masked: false }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="Exchange Rate" vid="exchangeRate" name="exchangeRate" v-model="form.ExchangeRate" size="sm" :disabled="false" :rules="{required : true}"/>
                      </mdb-col>

                      <mdb-col col="7" class="tab-col pl-0 pr-0">
                          <mdb-row class="tab-row">
                              <mdb-col col="6" class="tab-col">
                                <CostingInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Freight Charge" vid="freightCharge" name="freightCharge" :totalAmount="form.items.map((i) => $helpers.unformat(i.LineTotal),[]).reduce((a,b) => a +b,0)" @open="fnOpen" :reduce="label => label.code" v-model="form.attr.FreightCharge" size="sm" :disabled="true"/>
                                <CostingInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Custom Duty" vid="customDuty" name="customDuty" :totalAmount="form.items.map((i) => $helpers.unformat(i.LineTotal),[]).reduce((a,b) => a +b,0)" @open="fnOpen" :reduce="label => label.code" v-model="form.attr.CustomDuty" size="sm" :disabled="true"/>
                                <AmountInput :money="{ decimal: '.', thousands: ',', precision: 2, masked: false }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="Multiplier" vid="multiplier" name="multiplier" v-model="form.attr.Multiplier" size="sm" :disabled="false"/>
                                <AmountInput :money="{ decimal: '.', thousands: ',', precision: 2, masked: false }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="Standard Discount" vid="standardDiscount" name="standardDiscount" v-model="form.attr.StandardDiscount" size="sm" :disabled="false"/>
                               <!--  <AmountInput :money="{ decimal: '.', thousands: ',', precision: 2, masked: false }" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-4 pl-0" label="StandanDiscount" vid="actialDiscount" name="actialDiscount" v-model="form.attr.StandardDiscount" size="sm" :disabled="false"/>
                               -->  
                             
                                <!-- <mdb-row class="row">
                                  <mdb-col offset="4" col="6" class="tab-col pl-0">

                                    <template v-if="spreadsheetApplyLoading">
                                      <mdb-btn color="elegant" size="sm" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading...
                                      </mdb-btn>
                                    </template>
                                    <template v-else>
                                      <mdb-btn color="elegant" class="ml-0" size="sm" @click="fnApply">Apply</mdb-btn>
                                    </template>

                                    
                                      
                                  </mdb-col>
                                </mdb-row> -->
                                </mdb-col>
                              <mdb-col col="6" class="tab-col">
                                <CostingInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Custom Clearance" vid="customClearance" name="customClearance" :totalAmount="form.items.map((i) => $helpers.unformat(i.LineTotal),[]).reduce((a,b) => a +b,0)" @open="fnOpen" :reduce="label => label.code" v-model="form.attr.CustomClearance" size="sm" :disabled="true"/>
                                <CostingInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Shipping Line" vid="shippingLine" name="shippingLine" :totalAmount="form.items.map((i) => $helpers.unformat(i.LineTotal),[]).reduce((a,b) => a +b,0)" @open="fnOpen" :reduce="label => label.code" v-model="form.attr.ShippingLine" size="sm" :disabled="true"/>
                                <ThatsTextarea labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Description" ref="descriptionEditor" class="mt-3 mb-3" v-model="form.attr.Remarks" :rows="2">{{form.attr.Remarks}}</ThatsTextarea>
                                </mdb-col>
                          </mdb-row>
                        
                      </mdb-col> 
                      <mdb-col>

                        <template v-if="spreadsheetApplyLoading">
                          <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                          </mdb-btn>
                        </template>
                        <template v-else>
                          <mdb-btn color="elegant" class="ml-0" size="sm" @click="fnApply">Apply</mdb-btn>
                        </template>
                        
                      </mdb-col>   
                      
                    </mdb-row>  


                    <!-- {{form.items}} -->

                    <div ref="spreadsheetexport" class="demo-x"></div> 

                    <div class="d-flex">

                      <template v-if="formLoading || getLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>

                              <template v-if="formStatus == 'create'">
                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save'))" :disabled="(form.ExchangeRate <= 0 ? true : false)">As as draft</mdb-btn>
                              </template>
                              <template v-if="formStatus == 'update'">
                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save'))" :disabled="(form.ExchangeRate <= 0 ? true : false)">Save</mdb-btn>

                                  <mdb-dropdown class="ml-0 ui-dropup"> 
                                        <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">Save as...</mdb-dropdown-toggle>
                                        <mdb-dropdown-menu v-bind:style="{transform : 'translate3d(0px, -149px, 0px)!important'}">
                                            <mdb-dropdown-item @click="updateAndSave('for-review')">For Accounts Review</mdb-dropdown-item>
                                            <mdb-dropdown-item @click="updateAndSave('for-approval')">For Approval</mdb-dropdown-item>
                                            <mdb-dropdown-item @click="updateAndSave('approved')">Approved</mdb-dropdown-item>
                                        </mdb-dropdown-menu>
                                    </mdb-dropdown>
                    
                                  <mdb-btn color="elegant" size="sm" class="mt-0" @click="passes(fnSave('save-export'))" :disabled="(form.ExchangeRate <= 0 ? true : false)">Save & Export</mdb-btn>
                              </template>
                              <mdb-btn color="grey" size="sm" class="mt-0" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>

                  </div>
              
              
            </div>
            </ValidationObserver>
        <ApplyAmount ref="applyAmount" :group="(group + 1)" @apply="fnApplyAmount"></ApplyAmount>
        </hsc-window>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import axios from 'axios'
import money from 'v-money'
import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'
import ApplyAmount from './ApplyAmount.vue'
import AmountInput from '../../components/thats/AmountInput.vue'
function demo(){
  return 0
}

const lorem = 50



window['SUMCOL'] = function(instance,columnId) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    }
    return formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['CUSTOMSUM'] = function(instance, row,column) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  /* var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    } */
    return formatMoney(row+column, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}

window['EXCHANGERATE'] = 0;

window['FORMATVALUE'] = function(value) {
  
    return formatMoney(value, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['COSTPRICE'] = function(e,l,m,n,o) {

  let currencySymbol = ''
  let unitPrice = unformat(e)

              if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.TxtLineTotal.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]
              }


      const freightCharge = unformat(l)
      const customDuty = unformat(m)
      const customClearance = unformat(n)
      const shippingLine = unformat(o)
      
    return formatMoney((unitPrice + freightCharge + customDuty + customClearance + shippingLine), { symbol: currencySymbol , precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICE'] = function(c,n,o) {

  

  const costPrice = unformat(n)
    const multiplier = unformat(o)
    let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]

              }
 
    return formatMoney((costPrice * multiplier), { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICEQAR'] = function(n,o) {
  const costPrice = unformat(n)
      const multiplier = unformat(o)
   const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];

   


    return formatMoney((costPrice * multiplier) * exchangeRate, { symbol: "QAR ", precision: 2, thousand: ",", decimal: "." });
}
window['MULTIPLIERWIHOUTCOST'] = function(c,n,o) {

  // const unitPrice = unformat(c)
  //     const freightCharge = unformat(j)
const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];
  const unitPrice = unformat(c)
      const unitCostPrice = unformat(n)
      const multiplier = unformat(o)

      const rp = (unitCostPrice * multiplier) * exchangeRate

      let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
          const stringSegment = c.trim().split(/\s+/);
          currencySymbol = stringSegment[0]
      }
/* 
console.log("MULTIPLIERWIHOUTCOST")
      console.log(unitPrice)
      console.log(unitCostPrice)
      console.log(multiplier)
      console.log(rp)
      console.log((rp / unitPrice/ exchangeRate)) */

    let multiplierWihtoutCost = 0
    if(multiplier > 0 && rp > 0){
      multiplierWihtoutCost = ( rp / unitPrice/ exchangeRate)
    }
  
    return formatMoney(multiplierWihtoutCost, { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}



// console.table(window);

const onChange = function(instance){
  console.log(instance)
}

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ApplyAmount,
        ThatsMoney,
        AmountInput
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        data : [],
        form : {

            CardCode : null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            DocCurrency : null,
            CurrencySymbol : null,
            ExchangeRate : 0,

          attr : {
            FreightCharge : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                CustomDuty : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                CustomClearance : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                ShippingLine : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                Remarks : null,
                Multiplier : {
                    active : false,
                    value : 0
                },
                StandardDiscount : {
                    active : false,
                    value : 0
                }
          },
          items :[]
        },
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 230px)',
            tableOverflow : true,
             data:[],
             raw:[
              {code : 3,name : 'testingx'}
             ],
             minDimensions:[6,20],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 3,
             columns: [],

             /* 
              const PoNumberCol = 0;
            const ItemCodeCol = 1;
            const PictureCol = 2;
            const ItemDescriptionCol = 3;
            const QuantityCol = 4;
            const ItemDimensionsCol = 5;
            const UnitCubicMeterCol = 6;
            const VolumeCol = 7;
            const UnitPriceCol = 8;
            const DiscountPercentCol = 9;
            const UnitPriceAfterActualDiscountCol = 10;
            const TotalPriceAfterDiscountCol = 11;
            const FabricRequirementCol = 12;
            const CostPerLinearMeterCol = 13;
            const TotalFabricCostCol = 14;
            const CurrentCostPriceCol = 15;
            const StandardDiscountCol = 16;
            const UnitPriceAfterStandardDiscountCol = 17;
            const TotalPriceAfterStandardDiscountCol = 18;
            const UnitFreightChargesCol = 19;
            const CustomsDutyCol = 20;
            const ShippingLineChargesCol = 21;
            const CustomClearanceChargesCol = 22;
            const UnitPricewithShippingCustomsDutyCol = 23;
            const MultiplierCol = 24;
            const MultiplierOnLandedCostCol = 25;
            const RetailPriceFCCol = 26;
            const RetailPriceLCCol = 27;
            const CurrentMultiplierOnLandedCostCol = 28;
            const CurrentRetailPriceCol = 29;
            const RemarksCol = 30;
            const LineNumCol = 31;
            const ExchangeRateCol = 32;
            const LineTotalCol = 33;
            const DocEntryCol = 34;
            const IdCol = 35;

            TOTAL','=SUMCOL(TABLE(),3)
             */
             footers: [['','','','','','','','','','','TOTAL','=SUMCOL(TABLE(),11)','','','=SUMCOL(TABLE(),14)','','','','','=SUMCOL(TABLE(),19)','=SUMCOL(TABLE(),20)','=SUMCOL(TABLE(),21)','=SUMCOL(TABLE(),22)','=SUMCOL(TABLE(),23)','','','','','','','','']],

            updateTable:function(instance, cell, col, row, val, label, cellName) {


              // const quantityCol=5; 
              // const txtLineTotalCol=8; 
              // const cbmCol=9; 
              // const volumeCol=10; 
              // const txtFreightChargeCol=11; 
              // const txtCustomDutyCol=12;
              // const txtCustomClearanceCol=13;
              // const txtShippingLineCol=14;
              // const txtUnitCostPriceCol=15;
              // const multiplierCol=17;
              // const retailPriceFCCol=6;
              // const txtRetailPriceLocalCol=18;
              // const txtMultiplierWithoutCostCol=19;
              // const remarksCol=20;


              // const lineTotal = instance.jexcel.options.data[row][txtLineTotalCol]

              // let currencySymbol = '';
              //   if (typeof lineTotal === 'string' || lineTotal instanceof String){
              //       const stringSegment = lineTotal.trim().split(/\s+/);
              //       currencySymbol = stringSegment[0]
              //   }

              // const formForeignCurrencyColumn = [txtFreightChargeCol,txtCustomDutyCol,txtCustomClearanceCol,txtShippingLineCol,txtUnitCostPriceCol] 

              // if (formForeignCurrencyColumn.includes(col)) {
              //    cell.innerHTML = formatMoney(unformat(cell.innerText), { symbol: currencySymbol, precision: 2, thousand: ",", decimal: ".",format: "%s %v" });
              // }


              /* if (col == 2) {
                if (instance.jexcel.options.data[row][col]) {

                  const position = instance.jexcel.options.data[row][col].search("<img src");

                  if(position < 0){
                      cell.innerHTML = '<img src="'+instance.jexcel.options.data[row][col]+'" style="height:50px;">';
                  }else{
                    cell.innerHTML = instance.jexcel.options.data[row][col];
                  }
                    
                }
            } */

              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
               /*  if (cell.innerHTML == 'Total') {
                  }
        
                if (col == 3) {
                  cell.parentNode.style.backgroundColor = '#fffaa3';
                    cell.style.color = 'red';

                    console.log(cell.style)
                } */
            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                          alert(1)
                            obj.download(true);
                        }
                    });
                } */

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });
             }
                return items;
             },

             onblur: function(instance){
              // console.log(instance)
              // console.log("blur")
              // self.fnApplyAmountSetValue(16, 0, 343434)
             },
             onchange: function(instance, cell, x, y, value){
                
                // console.log("change")
             },
             onafterchanges(){
              //  console.log("after change")
              //  self.fnApplyAmountSetValue(16, 0, 343434)
              
             }

            /*  footers: [['','','TOTAL','=SUMCOL(TABLE(),3)','','','=SUMCOL(TABLE(),6)','=SUMCOL(TABLE(),7)','=SUMCOL(TABLE(),8)','=SUMCOL(TABLE(),9)','=SUMCOL(TABLE(),10)','=SUMCOL(TABLE(),11)','=SUMCOL(TABLE(),12)'],''], */
        },

        saveAsDropUpIsOpen : false
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

        window['EXCHANGERATE'] = 0;
  },

  mounted(){
     /*  this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, this.exportTableOptions);
      Object.assign(this, this.spreadsheetexport); */

      
    console.log(this.$route)
  },

  updated(){

  },
  methods: {

    ...mapActions('CostingToolModule',['resetData','storeRecord','updateRecord']),
    ...mapMutations('CostingToolModule',['setSearchTerm','setEmptyForm']),
    ...mapMutations('ApplyAmountModule',{
       setApplyAmountFormData : 'setFormData'
    }),

    demo(){
      console.log("demo")
      return 0;
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
        
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },

    fnLoadPurchaseOrder(){
        const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.resetData('get-purchase-orders').then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
           
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
    },

    createPopupTable(exportTableOptions){

          console.log("Watch form")
        console.log(this.form.items)


            exportTableOptions.data = this.form.items
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.form.items)

            console.log("Table created")
            console.log(this.spreadsheetexport)
            console.log(this.exportTableOptions)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        fnSave(status){
            const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){

                const tableData = this.spreadsheetexport.getData();


                console.log("tableData")
                console.log(tableData)



                let itemData = [];

                  if(tableData.length > 0){

                    const fields = [
                        'PoNumber',
                        'ItemCode',
                        'Picture',
                        'ItemDescription',
                        'Quantity',
                        'ItemDimensions',
                        'UnitCubicMeter',
                        'Volume',
                        'UnitPrice',
                        'DiscountPercent',
                        'UnitPriceAfterActualDiscount',
                        'TotalPriceAfterDiscount',
                        'FabricRequirement',
                        'CostPerLinearMeter',
                        'TotalFabricCost',
                        'CurrentCostPrice',
                        'StandardDiscount',
                        'UnitPriceAfterStandardDiscount',
                        'TotalPriceAfterStandardDiscount',
                        'UnitFreightCharges',
                        'CustomsDuty',
                        'ShippingLineCharges',
                        'CustomClearanceCharges',
                        'UnitPricewithShippingCustomsDuty',
                        'Multiplier',
                        'MultiplierOnLandedCost',
                        'RetailPriceFC',
                        'RetailPriceLC',
                        'CurrentMultiplierOnLandedCost',
                        'CurrentRetailPrice',
                        'Remarks',
                        'LineNum',
                        'ExchangeRate',
                        'LineTotal',
                        'DocEntry',
                        'Id'
                    ];

                    itemData = tableData.map((i)=>{  
                          const items = {}
                          if(i.PoNumber !=="" && i.PoNumber !== null){
                             i.forEach((value,index) => {
                                items[fields[index]] = value;
                            })
                           }
                         return items;
                      })


                      console.log(itemData)
                // 
                  }


                  
    
                  const docTotal = itemData.map((i) => {
                    return i.LineTotal;
                  },[]).reduce((a,b) => a + b,0);
                  const PoDocuments = itemData.map((i) => {
                    return i.PoNumber;
                  },[])
                  
                  const filteredPurchaseOrder = PoDocuments.filter((item,index) => PoDocuments.indexOf(item) === index);


                  let formDataStatus = self.form.status;

                  if(['for-review','for-approval','approved'].includes(status)){
                      formDataStatus = status;
                  }

                  self.form.DocTotal = docTotal;
                  self.form.attr.PoDocuments = filteredPurchaseOrder
                  self.form.items = itemData;
                  self.form.status = formDataStatus;


                  if(self.formLoading === false){
                      self.formLoading = true;
                      if(this.formStatus === 'create'){
                        self.storeRecord(self.form).then((res) => {
                              console.log(res);
                              self.formLoading = false;
                              // self.fnNewWindow('export/'+res.id)
                              self.closeDialog();
                              self.$eventBus.$emit('costingSuccess',true)
                        }).catch((err) => {
                            self.formLoading = false;
                            console.log(err)
                        });
                      }

                      if(this.formStatus === 'update'){

                        console.log('update')
                        self.updateRecord(self.form).then((res) => {
                              console.log(res);
                              self.formLoading = false;
                              if(status === 'save-export'){
                                
                                console.log(res)
                                self.fnNewWindow('export/'+res.id)
                              }

                              let notifyMessage = 'Record was successfully created'

                              if(self.formStatus === 'update'){
                                  notifyMessage = 'Record was successfully updated'
                              }

                              self.$ThatsNotify.success({
                                message : notifyMessage
                              })
                              // self.closeDialog();
                        }).catch((err) => {
                            self.formLoading = false;
                            console.log(err)
                        });
                      }

                      
                  }



            }

        },

        fnNewWindow(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          window.open(`${self.$route.path}/${url}`, "_blank");
        },
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },

        async fnApply(){
            const self = this;
            const getData = this.spreadsheetexport.getData();

            const PoNumberCol = 0;
            const ItemCodeCol = 1;
            const PictureCol = 2;
            const ItemDescriptionCol = 3;
            const QuantityCol = 4;
            const ItemDimensionsCol = 5;
            const UnitCubicMeterCol = 6;
            const VolumeCol = 7;
            const UnitPriceCol = 8;
            const DiscountPercentCol = 9;
            const UnitPriceAfterActualDiscountCol = 10;
            const TotalPriceAfterDiscountCol = 11;
            const FabricRequirementCol = 12;
            const CostPerLinearMeterCol = 13;
            const TotalFabricCostCol = 14;
            const CurrentCostPriceCol = 15;
            const StandardDiscountCol = 16;
            const UnitPriceAfterStandardDiscountCol = 17;
            const TotalPriceAfterStandardDiscountCol = 18;
            const UnitFreightChargesCol = 19;
            const CustomsDutyCol = 20;
            const ShippingLineChargesCol = 21;
            const CustomClearanceChargesCol = 22;
            const UnitPricewithShippingCustomsDutyCol = 23;
            const MultiplierCol = 24;
            const MultiplierOnLandedCostCol = 25;
            const RetailPriceFCCol = 26;
            const RetailPriceLCCol = 27;
            const CurrentMultiplierOnLandedCostCol = 28;
            const CurrentRetailPriceCol = 29;
            const RemarksCol = 30;
            const LineNumCol = 31;
            const ExchangeRateCol = 32;
            const LineTotalCol = 33;
            const DocEntryCol = 34;
            const IdCol = 35;


            console.log("lreom")
            console.log(this.form.rowObject)


            
            const DocTotal = getData.map((rowData) => {
                return  (unformat(rowData[UnitPriceCol])*(1-(unformat(rowData[DiscountPercentCol])/100)))* rowData[QuantityCol];
            },[]).reduce((a,b) => a +b, 0);

            const totalVolume = getData.map((item) => {
                return unformat(item[UnitCubicMeterCol]) * item[QuantityCol];
            },[]).reduce((a,b) => a +b, 0);



            const opt = ['FreightCharge','CustomDuty','CustomClearance','ShippingLine','Multiplier','StandardDiscount'];
            let checkAppliedVolume = 0;
            opt.forEach((element) => {
              if(self.form.attr[element].distribution === 'volume' && self.form.attr[element].active === true){
                   checkAppliedVolume++;
              }
            })


            console.log("Check Volume")
            console.log(totalVolume)
            console.log(checkAppliedVolume)
           
           console.log("DocTotal")
            console.log(DocTotal)



            if(totalVolume <= 0 && checkAppliedVolume > 0){
                MessageBox({
                              message: 'Items volume must be greater than zero.',
                              type: 'error'
                          })
                return false;
            }
            if(unformat(this.form.ExchangeRate) <= 0){
                MessageBox({
                              message: 'Exchange rate can\'t be zero.',
                              type: 'error'
                          })
                return false;
            }
          

            if(getData.length > 0){

              console.log("getData")
              console.log(getData)

                  const newTableData = getData.map((rowData, rowIndex) => {
                         const unitPrice = rowData[UnitPriceCol];
                         const TotalPriceAfterStandardDiscount = rowData[TotalPriceAfterStandardDiscountCol];
                         const StandardDiscount = rowData[StandardDiscountCol];
                         const DiscountPercent = rowData[DiscountPercentCol];
                         const CurrentCostPrice = rowData[CurrentCostPriceCol];
                          const Quantity = rowData[QuantityCol];
                          const LineTotal = (unformat(unitPrice)*(1-(unformat(rowData[DiscountPercentCol])/100)))* rowData[QuantityCol];
                          const unitCBM = unformat(rowData[UnitCubicMeterCol]);
                          const totalCBM = unformat(rowData[UnitCubicMeterCol]) * rowData[QuantityCol];


                          console.log("CurrentCostPrice")
                          console.log(CurrentCostPrice)
                          console.log(TotalPriceAfterStandardDiscount)

                          //=IF(P1>0,IF(Q1>0,P1*(1-(Q1/100)),0),IF(Q1>0,I1*(1-(Q1/100)),0))
                          let rowLineTotal = 0;

                          if(CurrentCostPrice > 0){
                              rowLineTotal = (CurrentCostPrice * (1 - (StandardDiscount/100))) * Quantity
                          }else{
                            rowLineTotal = (unitPrice * (1 - (DiscountPercent/100))) * Quantity
                          }
                            console.log(rowLineTotal)


                          let freightCharge = 0;
                          let customDuty = 0;
                          let customClearance = 0;
                          let shippingLine = 0;
                          let multiplier = 0;
                          let standardDiscount = 0;

                          const exchangeRate = unformat(self.form.ExchangeRate);
                          const opt = ['FreightCharge','CustomDuty','CustomClearance','ShippingLine','Multiplier','StandardDiscount'];

                          opt.forEach((element) => {
                              let charges = 0;
                              const chargeAmount = unformat(self.form.attr[element].value);
                                if(self.form.attr[element].active && chargeAmount <= 0){
                                    self.form.attr[element].active = false
                                }
                                  if(self.form.attr[element].active === true ){
                                      if(self.form.attr[element].distribution === 'volume' && totalCBM > 0){
                                        charges = ((chargeAmount / totalVolume) / exchangeRate) * unitCBM
                                      }
                                      if(self.form.attr[element].distribution === 'value'){

                                        if(CurrentCostPrice > 0){
                                          charges = (((chargeAmount / DocTotal) * rowLineTotal) / Quantity) / exchangeRate

                                        }else{
                                          charges = (((chargeAmount / DocTotal) * rowLineTotal) / Quantity) / exchangeRate
                                        }
                                      }

                                      console.log(element)
                                      console.log(charges)



                                      if(element === 'Multiplier'){
                                        multiplier = chargeAmount
                                      }
                                      if(element === 'StandardDiscount'){
                                        standardDiscount = chargeAmount
                                      }

                                      if(element === 'FreightCharge'){
                                        freightCharge = charges
                                      }
                                      if(element === 'CustomDuty'){
                                        customDuty = charges
                                      }
                                      if(element === 'CustomClearance'){
                                        customClearance = charges
                                      }
                                      if(element === 'ShippingLine'){
                                        shippingLine = charges
                                      }

                                     
                                  }

                                  
                                  
                          });



                          const mapItemData = [...rowData];


                         


                          let parser = new DOMParser();
                          let doc = parser.parseFromString(rowData[3], 'text/html');

                          mapItemData[3] = doc.body.innerText
                          const rowNumber = rowIndex+1

                          mapItemData[ExchangeRateCol] = exchangeRate;
                          mapItemData[UnitFreightChargesCol] = freightCharge;
                          mapItemData[CustomsDutyCol] = customDuty;
                          mapItemData[CustomClearanceChargesCol] = customClearance;
                          mapItemData[ShippingLineChargesCol] = shippingLine;

                          mapItemData[RetailPriceLCCol] = self.form.rowObject.RetailPriceLC.replace(/_row_/g,rowNumber).replace(/_exchange_/g,unformat(self.form.ExchangeRate));
                          mapItemData[CurrentMultiplierOnLandedCostCol] = self.form.rowObject.CurrentMultiplierOnLandedCost.replace(/_row_/g,rowNumber).replace(/_exchange_/g,unformat(self.form.ExchangeRate));

                          /* console.log(self.form.rowObject.RetailPriceLC)
                          console.log(self.form.rowObject.RetailPriceLC.replace(/_row_/g,rowNumber).replace(/_exchange_/g,unformat(self.form.ExchangeRate))) */
                          // mapItemData[txtRetailPriceLocalCol] = 8888888;
                          
                          
                          //mapItemData[txtRetailPriceLocalCol] = '=IF(X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+'>2000,CEILING(X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+',50),IF(X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+'<=2000,CEILING(X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+',10),X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+'))';
                          //mapItemData[currentMultiplierOnLandedCostCol] = '=IF(AND(X'+(rowNumber)+'>0,U'+(rowNumber)+'>0),X'+(rowNumber)+'/U'+(rowNumber)+'/'+(unformat(self.form.ExchangeRate))+',0)';//'=SUM(X'+(rowNumber)+'*'+(unformat(self.form.ExchangeRate))+')';


                          if(self.form.attr.Multiplier.active === true){
                              mapItemData[MultiplierOnLandedCostCol] = multiplier
                            }else{
                              mapItemData[MultiplierOnLandedCostCol] = mapItemData[MultiplierOnLandedCostCol]
                          }
                          if(self.form.attr.StandardDiscount.active === true){
                              mapItemData[StandardDiscountCol] = standardDiscount
                            }else{
                              mapItemData[StandardDiscountCol] = mapItemData[StandardDiscountCol]
                          }
                          mapItemData[RemarksCol] = self.form.attr.Remarks

/* 
                           console.log("mapItemData")
                          console.log(mapItemData) */

                          return mapItemData
                  });


                  console.log("newTableData")
                  console.log(newTableData)

   
                   await self.spreadsheetexport.setData(newTableData) 

                   const dataLength = getData.length

                   const unitFreightChargeStyle = {}


                   const opt = ['FreightCharge','CustomDuty','CustomClearance','ShippingLine','Multiplier','StandardDiscount'];

                          

                          opt.forEach((element) => {

                                  if(self.form.attr[element].active === true){
                                     
                                      if(element === 'FreightCharge'){

                                         for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['T'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }
                                        if(element === 'CustomDuty'){
                                          for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['U'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }
                                        if(element === 'CustomClearance'){
                                          for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['W'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }
                                        if(element === 'ShippingLine'){
                                          for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['V'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }
                                        
                                        if(element === 'Multiplier'){
                                          for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['Z'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }
                                        if(element === 'StandardDiscount'){
                                          for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['Q'+index] = 'animation: pulse 1.5s;'
                                            
                                          }
                                        }

                                        /* for (let index = 1; index <= dataLength; index++) {

                                            unitFreightChargeStyle['P'+index] = 'animation: pulse 1.5s;'
                                            unitFreightChargeStyle['R'+index] = 'animation: pulse 1.5s;'
                                            
                                          } */
                                     
                                  }

                                 
                          });

                        


                    self.spreadsheetexport.setStyle({...unitFreightChargeStyle})
                    self.spreadsheetApplyLoading = false
              
            }
            


          
            
        },


        fnOpen(v){

           const getData = this.spreadsheetexport.getData();
           const DocTotal = getData.map((i) => unformat(i[33]),[]).reduce((a,b) => a +b,0)


           const totalVolume = getData.map((item) => {
                return unformat(item[6]) * item[4];
            },[]).reduce((a,b) => a +b, 0);


            const applyTo = {
                "freightCharge" : "Freight Charge",
                "customDuty" : "Custom Duty",
                "customClearance" : "Custom Clearance",
                "shippingLine" : "Shipping Line"
            }


            // alert(`${v} --- ${applyTo[]}`)
              let option = {
                active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
              }

            if(v === 'freightCharge'){
                  option = this.form.attr.FreightCharge
              }
              if(v === 'customDuty'){
                  option = this.form.attr.CustomDuty
              }
              if(v === 'customClearance'){
                  option = this.form.attr.CustomClearance
              }
              if(v === 'shippingLine'){
                 option = this.form.attr.ShippingLine
              }


            const applyAmountFormData = { TotalVolume : totalVolume, DocTotal : DocTotal,applyTo : {code : v, name : applyTo[v]}, Option: JSON.parse(JSON.stringify(option))}

            console.log("OnOpen")
            console.log(applyAmountFormData)
            this.setApplyAmountFormData(applyAmountFormData);
            this.$refs.applyAmount.openDialog();
        },

        fnApplyAmount(v){
            console.log("onApply")
            console.log(v)
              if(v.applyTo.code === 'freightCharge'){
                  this.form.attr.FreightCharge =  {...this.form.attr.FreightCharge,...v.Option}
              }
              if(v.applyTo.code === 'customDuty'){
                  this.form.attr.CustomDuty = {...this.form.attr.CustomDuty,...v.Option}
              }
              if(v.applyTo.code === 'customClearance'){
                  this.form.attr.CustomClearance = {...this.form.attr.CustomClearance,...v.Option}
              }
              if(v.applyTo.code === 'shippingLine'){
                  this.form.attr.ShippingLine =  {...this.form.attr.ShippingLine,...v.Option}
              }
        },

        fnApplyAmountSetValue(x, y, value){
            // console.log(this.spreadsheetexport)

            this.spreadsheetexport.setValueFromCoords(x,y,value)
        },

        updateAndSave(status){
          this.fnSave(status)
        }

        

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CostingToolModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      },
      title : function(){
          return `${this.form.CardCode}-${this.form.CardName} (${this.form.status})`
      }
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        // this.form = {...v, items : valueData};
        this.form = v;

        
        this.CurrencySymbol = v.CurrencySymbol
        const data = [
          {item_code : "serial" , item_name : "barcode"}
        ]

      
           
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

       
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{

                 console.log("newState")
        console.log(newState)
        console.log(this.form)






        this.exportTableOptions.columns = [

                  {
                    type : 'text',
                    width : '80px',
                    align : 'left',
                    name : 'PoNumber',
                    title : 'A|PoNumber'
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'ItemCode',
                    title : 'B|ItemCode'
                  },
                  {
                    type : 'html',
                    width : '80px',
                    name : 'Picture',
                    title : 'C|Picture',
                    readOnly: false,
                    primaryKey: false,
                    align : 'center'
                  },
                  {
                    type : 'html',
                    width : '250px',
                    align : 'left',
                    name : 'ItemDescription',
                    title : 'D|Description'
                  },
                  {
                    type : 'text',
                    width : '60px',
                    align : 'left',
                    name : 'Quantity',
                    title : 'E|Quantity'
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'left',
                    name : 'ItemDimensions',
                    title : 'F|Dimensions'
                  },
                  {
                    type : 'text',
                    width : '60px',
                    align : 'right',
                    name : 'UnitCubicMeter',
                    title : 'G|CBM'
                  },
                  {
                    type : 'text',
                    width : '60px',
                    align : 'right',
                    name : 'Volume',
                    title : 'H|Volume'
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'UnitPrice',
                    title : 'I|UnitPrice',
                   /*  mask : `_(\$* #,##0.00_);_(\$* (#,##0.00);_(\$* ""-""??_);_(@_)`, */
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'DiscountPercent',
                    title : 'J|ActualDiscount',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '180px',
                    align : 'right',
                    name : 'UnitPriceAfterActualDiscount',
                    title : 'K|UnitPriceAfterActualDiscount',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '170px',
                    align : 'right',
                    name : 'TotalPriceAfterDiscount',
                    title : 'L|TotalPriceAfterDiscount',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '130px',
                    align : 'right',
                    name : 'FabricRequirement',
                    title : 'M|FabricRequirement'
                  },
                  {
                    type : 'text',
                    width : '130px',
                    align : 'right',
                    name : 'CostPerLinearMeter',
                    title : 'N|CostPerLinearMeter',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '130px',
                    name : 'TotalFabricCost',
                    title : 'O|TotalFabricCost',
                    align : 'right',
                    readOnly: false,
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'CurrentCostPrice',
                    title : 'P|CurrentCostPrice',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'StandardDiscount',
                    title : 'Q|StandardDiscount',
                    mask : `#,##0.00`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'UnitPriceAfterStandardDiscount',
                    title : 'R|UnitPriceAfterStandardDiscount',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'TotalPriceAfterStandardDiscount',
                    title : 'S|TotalPriceAfterStandardDiscount',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'UnitFreightCharges',
                    title : 'T|UnitFreightCharges',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'CustomsDuty',
                    title : 'U|CustomsDuty',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'ShippingLineCharges',
                    title : 'V|ShippingLineCharges',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '170px',
                    align : 'right',
                    name : 'CustomClearanceCharges',
                    title : 'W|CustomClearanceCharges',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '190px',
                    align : 'right',
                    name : 'UnitPricewithShippingCustomsDuty',
                    title : 'X|UnitPricewithShippingCustomsDuty',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '80px',
                    align : 'right',
                    name : 'Multiplier',
                    title : 'Y|MultiplierOnCost',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'MultiplierOnLandedCost',
                    title : 'Z|MultiplierOnLandedCost',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'RetailPriceFC',
                    title : 'AA|RetailPriceFC',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'RetailPriceLC',
                    title : 'AA|RetailPriceLC(QAR)',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                  },
                 /*  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'RetailPriceLC',
                    title : 'AB|RetailPriceLC',
                    mask : `QAR #,##0.00;[Red](QAR #,##0.00)`,
                    decimal:'.',
                  }, */
                  {
                    type : 'text',
                    width : '190px',
                    align : 'right',
                    name : 'CurrentMultiplierOnLandedCost',
                    title : 'AC|CurrentMultiplierOnLandedCost',
                    mask : `${this.CurrencySymbol} #,##0.00;[Red](${this.CurrencySymbol} #,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '150px',
                    align : 'right',
                    name : 'CurrentRetailPrice',
                    title : 'AD|CurrentRetailPrice(QAR)',
                    mask : `#,##0.00;[Red](#,##0.00)`,
                    decimal:'.',
                  },
                  {
                    type : 'text',
                    width : '200px',
                    align : 'left',
                    name : 'Remarks',
                    title : 'AE|Remarks'
                  },
                  {

                      type: 'hidden',
                      width:100,
                      title:'LineNum',
                      name:'LineNum',
                      align : 'right',
                      readOnly: false,
                      
                  },
                  {

                      type: 'hidden',
                      width:100,
                      title:'ExchangeRate',
                      name:'ExchangeRate',
                      align : 'right',
                      readOnly: false,
                      
                  },
                  {

                      type: 'hidden',
                      width:100,
                      title:'LineTotal',
                      name:'LineTotal',
                      align : 'right',
                      readOnly: false,
                      
                  },
                  {

                      type: 'hidden',
                      width:100,
                      title:'DocEntry',
                      name:'DocEntry',
                      align : 'right',
                      readOnly: false,
                      
                  },
                  {

                      type: 'hidden',
                      width:100,
                      title:'Id',
                      name:'Id',
                      align : 'right',
                      readOnly: false,
                      
                  }
               

            ];


            // console.log(this.exportTableOptions.columns.map((i) => i.name))
                this.createPopupTable(this.exportTableOptions);

               
              }
        },

        "form.ExchangeRate" : function(v){
           window['EXCHANGERATE'] = v;
            
        }


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>