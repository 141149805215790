<template>
<!--   <div class="quantity-adjust-container"> -->
      <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer"  @submit.prevent="onSubmitForm()">

        <el-dialog :title="'Quantity Adjustment'" :visible.sync="dialogIsOpen" :close-on-click-modal="false" width="40%" size="small" class="text-left item-quantity-adjustment-form">

         <!--  <hsc-window-style-metal>
              <hsc-window title="Quantity Adjust" :resizable="false" :left="250" :top="50" :width="600" :height="350" :minWidth="350" :maxWidth="800" :minHeight="350" :maxHeight="450"  :closeButton="true" :isOpen.sync="dialogIsOpen" style="z-index: 9999;"> -->
                <ThatsInput :show_error="true" type="number" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Quantity" vid="quantity" name="quantity" v-model="form.quantity" :validated="validated" :rules="'required'" size="sm" />

        <ThatsMultiSelect  :show_error="true" :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Reason" v-model="form.reason" :reduce="label => label.value" :reduceLabel="'label'" :options="dataReason" :rules="'required'"></ThatsMultiSelect>

            <ThatsMultiSelect :show_error="true" :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="SHOWROOM" v-model="form.location_id" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'"  ></ThatsMultiSelect>

            <ThatsTextarea  :show_error="true" :rows="2" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="form.description" size="sm" :rules="'required'"/>


            <template slot="footer">
              <div class="p-0">
                  <div class="d-flex">

                    <template v-if="formLoading">
                      <!-- This block is used when formLoading is true  class="footer-items"-->
                      <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                      </mdb-btn>
                  </template>
                  <template v-else>
                      <!-- This block is used when formLoading is false -->
                      <mdb-btn color="elegant" class="ml-0 mt-0 mb-0" size="sm" @click="onSubmitForm"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>
                      <mdb-btn color="grey" class="mt-0 mb-0" size="sm" @click="closeNewDialog"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                  </template>


                  </div>
              </div>
          </template>

<!--
                  </hsc-window>

              </hsc-window-style-metal> -->
            </el-dialog>
          </ValidationObserver>
 <!--  </div> -->
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon,
        mdbCarousel, mdbCard,
    mdbCardImage,} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsFloat,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsMultiSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GalleryManager from '../../components/image/GalleryManager';
import Autocomplete from '../../components/Autocomplete';

import { MessageBox } from 'element-ui';
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardImage,


        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsPriceInput,
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        Autocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsCheckbox,
        ThatsMultiSelect,

        ImageGrid,
        GalleryManager,

        ThatsFloat,


  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },

    inject:['showroomsProvider'],
    data : () => ({
        dialogIsOpen : false,
      invoicePermission : true,
      requiredField : true,
      resetPassword : false,
      checkboxData : [],
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
        showError: false,
        errors: {},
      designation : [],
        filteredShowroom : [],
        dataReason: [
            { label: 'Damage', value: 'damage' },
            { label: 'Not Found', value: 'not_found' },
            { label: 'Adjustment', value: 'adjustment' }
        ],
     /*  showrooms : [], */
      group2 : 3

    }),

    created : function() {
      var self = this;

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
      }

       this.optionsHandler();



    },
    methods: {

     /*  ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),
 */
      ...mapActions('Designation',{
        designationFilter : 'filterItems'
      }),

      ...mapActions('QuantityAdjustModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
          'getQuantityAdjust'
      ]),
      ...mapMutations('QuantityAdjustModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
            quantity : "",
            reason : "",
            description : "",
            user_id : "",
            item_id : "",
            item_code : "",
            location_id : "",
        }
      },
        /* start */

        onSelect(e,r){

          this.form.images.forEach((img,i) => {
              if(r.token_id === img.token_id){
                  this.form.images[i].default = 1
              }else{
                this.form.images[i].default  = 0
              }
          })
        },
        openGalleryManager(){
        this.$refs.gallery.openDialog()
        },
        // openDialog(){
        //     this.$refs.window.openDialog();
        // },
        optionsHandler(){
          var self = this;
        },
        uploadImage(){
          this.$refs.upload.openDialog();
        },
        nextCode(){
          var self = this;
          /* this.GetNextCode().then((response) => {
            self.form.item_code = response.code
          }); */
        },
        fnImageSelect(v){

          if(v.length > 0){
            v.forEach((r,i) => {
              const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
              if(i === 0){
                d.default = 1;
              }
                this.form.images.push(d)
            })

          }
        },

        /* end */
      // openNewDialog(){
      //       this.$refs.window.openDialog()
      // },
        closeNewDialog(){
            this.dialogIsOpen = false
            this.FormResetter();
            self.form = null;
            this.showError = false;
        },

        openNewDialog(formData){
            this.dialogIsOpen = true
            this.form.item_id = formData.mdbID
            this.form.item_code = formData.series
            let user = JSON.parse(localStorage.getItem('user'));
            this.form.user_id = user.user_id;

        },

        // closeDialog(){
        //     this.dialogIsOpen = false
        //     self.FormResetter();
        //     self.form = null;
        //     this.showError = false;
        // },

        onSubmitForm() {
            var self = this;
            this.validated = true;
            const isValid = this.$refs.observer.validate();
            this.showError = false;

            if (isValid) {
                if (self.formLoading === false) {
                    self.formLoading = true;
                //     if (self.FormStatus == 'create') {
                        self.StoreRecord(self.form).then((response) => {
                            self.getQuantityAdjust(self.form.item_id);
                            self.$ThatsNotify.success();
                            self.$emit('success', response);
                            self.closeNewDialog();
                            self.FormResetter();

                            // Handle the response here
                            console.log('Response from StoreRecord:', response);

                        }).catch((error) => {
                            this.formLoading = false;
                            this.response.error = 1;
                            if (error.response && error.response.data && error.response.data.hasOwnProperty('errors')) {
                                this.formErrors = error.response.data.errors;
                                this.$refs.observer.setErrors(error.response.data.errors);
                                this.showError = true;
                                this.errorMessage = 'An error occurred while processing your request.';
                            } else if (error.request) {
                                this.response.message = error.request;
                            } else {
                                this.response.message = error.message;
                            }
                        });
                    // }
                }
            }
        },

        FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.resetPassword = false
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }
        if(this.FormStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      allLocation(){

          const locations = this.showrooms.map((i) => i.value);

          this.form.attr.location = locations
      },
      clearLocation(){

          this.form.attr.location = []
      }

    },

    computed: {

      ...mapGetters('QuantityAdjustModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      editPassword  : function(){
          if(this.FormStatus == 'update' && this.resetPassword !== true){
              return '';
          }
        return 'required';
      },

        showroomLocation : function(){
            const showroom = this.showroomsProvider();
            return showroom.map((item) => {
                return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
            },[])
        },

    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          // this.form = this.FormRecord.row;
           const frm = JSON.parse(JSON.stringify(this.FormRecord.row));

            frm.attr = {...this.form.attr,...frm.attr}

            this.form = {...this.form,...frm}

          //  this.form.website = (this.FormRecord.row.website == 0) ? false : true;
        }

        if(this.FormStatus == 'create'){
          this.FormResetter();
        }
      },


    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}
.quantity-adjust-container .modal-dialog {
    z-index: 9999 !important;
}
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>
