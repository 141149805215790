/* eslint-disable no-prototype-builtins */
<template>
  <div class="customer-segment-form-container">
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{}" @submit.prevent="onSubmitForm('open-updated')">
       <XWindow ref="window" :title="`Customer Segment`" windowClass="height-auto bts-window" scrollClass='collection height-auto' :height="height" :width="width" :formStatus="formStatus" @dialog-state="dialogState" :group="999" :isMaximized="isMaximized" @onResize="onResize">



            <template #right-button>
            </template>
            <mdb-tabs class="table-tabs bts-form-tabs mb-0 form mt-1" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" :key="eventKeyCounter">
              <template :slot="'form-slot'">
                  <div>
                  <mdb-row class="tab-row">
                      <mdb-col col="12" class="tab-col pt-3">

                        <div class="container">


                          <section class="card profile-card mb-4 text-center">
                              <div class="avatar z-depth-1-half grey lighten-5">
                                <!-- <img
                                  src="https://mdbootstrap.com/img/Photos/Avatars/img%20(8).jpg"
                                  alt=""
                                  class="img-fluid"
                                /> -->

                                <mdb-icon class="dark-grey-text" far icon="user" style=" font-size: 42px; margin: 38px 41px; "/>
                              </div>
                              <!--Card content-->
                              <div class="card-body">
                                <!--Title-->
                                <h4 class="card-title"><strong>{{ customerName }}</strong></h4>
                                <h5>{{ customerMobile }}</h5>
                                <p class="dark-grey-text">{{ customerData.address }}</p> 

                              
                              </div>
                            </section>            
                            
                            <mdb-row>
                              
    
                              <!--Grid column-->
                              <mdb-col xl="4" md="4" class="mb-4">
                                <!--Card Success-->
                                <mdb-card color="warning-color" class="classic-admin-card">
                                  <mdb-card-body>
                                    <div class="pull-right">
                                      <i class="fas fa-file"></i>
                                    </div>
                                    <p class="white-text">QUOTATIONS</p>
                                    <h4 class="text-right">{{ quotationsCount.count }}</h4>
                                  </mdb-card-body>
                                  
                                </mdb-card>
                                <!--/.Card Success-->
                              </mdb-col>
                              <!--Grid column-->
    
                              <!--Grid column-->
                              <mdb-col xl="4" md="4" class="mb-4">
                                <!--Card Success-->
                                <mdb-card color="light-blue" class="classic-admin-card lighten-1">
                                  <mdb-card-body>
                                    <div class="pull-right">
                                      <i class="fas fa-file-invoice-dollar"></i>
                                    </div>
                                    <p class="white-text">INVOICES</p>
                                    <h4 class="text-right">{{ transactionCount.sum | amount }}</h4>
                                  </mdb-card-body>
                                
                                </mdb-card>
                                <!--/.Card Success-->
                              </mdb-col>
                              <!--Grid column-->
    
                              <!--Grid column-->
                              <mdb-col xl="4" md="4" class="mb-4">
                                <!--Card Success-->

                                 
                                <mdb-card color="success-color" class="classic-admin-card accent-2">
                                  <mdb-card-body>
                                    <div class="pull-right">
                                      <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <p class="white-text">SPENT</p>
                                    <h4 class="text-right">{{ spent.amount | amount }}</h4>
                                  </mdb-card-body>
                                  
                                </mdb-card>
                                <!--/.Card Success-->
                              </mdb-col>
                              <!--Grid column-->

                              <!--Grid column-->
                             <!--  <mdb-col xl="3" md="3" class="mb-4">
                                <mdb-card color="orange" class="classic-admin-card accent-4">
                                  <mdb-card-body>
                                    <div class="pull-right">
                                      <i class="fas fa-dollar-sign"></i>
                                    </div>
                                    <p class="white-text">BALANCE</p>
                                    <h4 class="text-right">{{ spent.amount | amount }}</h4>
                                  </mdb-card-body>
                                  
                                </mdb-card>
                              </mdb-col> -->
                              <!--Grid column-->
                            </mdb-row>

                            <div class="border-bottom p-2 mb-2">ACTIVITY</div>
                            
                            <Chat :chat="customerTrack" :loggedUserId="userData.user_id"></Chat>

                            <template v-if="customer_total > customer_length">
                              <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page.sync="current_page"
                                :page-sizes="[5,10, 25, 50, 100, 200, 300, 400]"
                                :page-size="customer_length"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="customer_total"
                            ></el-pagination>
                            </template>

                          </div>
               
                        <!--   <hr/>
                          <mdb-row class="tab-row">
                              <div class="btn-group">
                                  <button
                                      @click="toggleDropdown"
                                      class="btn-floating btn-sm black waves-effect waves-light dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                  >
                                      <i class="fas fa-plus"></i>
                                  </button>
                                  <div class="dropdown-menu" :class="{ show: isDropdownOpen }">
                                      <a class="dropdown-item" href="#" @click="openNoteModal('Calling Client')">Calling Client</a>
                                  </div>
                              </div>
                          </mdb-row>
                          <p><br/><br/><br/></p>
                          <mdb-row class="tab-row">
                              <div class="block">
                                  <el-timeline>
                                      <el-timeline-item v-for="trackItem in customerTrack" :key="trackItem.id" :timestamp="trackItem.timestamp" placement="top">
                                          <el-card>
                                              <div slot="header">
                                                  <h5>{{ trackItem.user_name }} - {{ trackItem.contact_source }}</h5>
                                              </div>
                                              <div>
                                                  <p>{{ trackItem.note }}</p>
                                              </div>
                                          </el-card>
                                      </el-timeline-item>
                                  </el-timeline>
                              </div>
                          </mdb-row> -->
                    </mdb-col>
                  </mdb-row>

                  </div>

              <!--     <div class="pagination-footer">
                      
                  </div> -->


                  <btn-fixed
                    :items="items"
                    color="success"
                    icon="pencil-alt"
                    size="lg"
                    :bottom="50"
                    :right="25"
                    trigger="click"
                    @item-click="openNoteModal"
                  >
            <!--   @click="openNoteModal('Calling Client')"   <mdb-btn-fixed-item v-for="(item,index) in item" :key="index" :color="item.color" :icon="item.icon">test</mdb-btn-fixed-item> -->
                </btn-fixed>

              </template>

<!--                Table for Quotations-->
                <template :slot="'form-slot1'">


                  <div class="window-inner-container mt-1">
                      <mdb-row class="tab-row">
                        <mdb-col col="12" class="tab-col">

                          <div class="p-0 pt-2 pb-1 header-panel-container">
                            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">
                                <mdb-form-inline class="ml-auto" tag="div">
    

                                    <el-input
                                    @keydown.enter.prevent="handleSearchTermQuotation"
                                    @clear="clearSearchField"
                                    class="table-searchterm-input"
                                    placeholder="Search"
                                    v-model="termQuotation"
                                    clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                                    </el-input>

                                    <mdb-btn color="elegant" size="sm" @click="handleSearchTermQuotation"><i class="el-icon-search"></i> Search</mdb-btn>
                                </mdb-form-inline>
                            </mdb-navbar>
                          </div>

                          <el-table ref="purchaseRequestTable" border :data="customerQuotation" class="input-table mt-0 custom-element-table" style="width: 100%" >
                          <el-table-column label="#" width="35" align="center">
                              <template slot-scope="scope">
                                  {{scope.$index + 1}}
                              </template>
                          </el-table-column>
                          <el-table-column label="DATE" :row-class-name="''">
                              <template slot-scope="scope">
                                  {{scope.row.transaction.date}}
                              </template>
                          </el-table-column>
                          <el-table-column label="Tracking No" :row-class-name="''">
                              <template slot-scope="scope">
                                  <a href="javascript:void(0)" @click="openQuotation(scope.row.transaction)">{{scope.row.transaction.series}}</a>
                              </template>
                          </el-table-column>
                          <el-table-column label="Customer/Mobile"  width="435">
                              <template slot-scope="scope">
                                  {{scope.row.name}}/{{scope.row.mobile}}
                              </template>
                          </el-table-column>

                          <el-table-column label="Sold By" :row-class-name="''">
                              <template slot-scope="scope">
                                  {{ scope.row.user && scope.row.user.user ? scope.row.user.user.name : '' }}
                              </template>
                          </el-table-column>
                          <el-table-column label="Amount" :row-class-name="''">
                              <template slot-scope="scope">
                                  {{scope.row.transaction.total}}
                              </template>
                          </el-table-column>
                          <el-table-column label="Status" :row-class-name="''">
                              <template slot-scope="scope">
                                  {{scope.row.transaction.status}}
                              </template>
                          </el-table-column>
                          <el-table-column label="Remarks" :row-class-name="''">
                              <template slot-scope="scope" v-if="scope.row.transaction.attr.closed.remarks != null">
                                  {{ scope.row.transaction.attr.closed.remarks.join(', ') }}
                              </template>
                          </el-table-column>
                          <template slot="empty">
                              <template v-if="loadingQuotation">
                                  <mdb-btn color="elegant" size="sm" rounded disabled>
                                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                      Loading data...
                                  </mdb-btn>

                              </template>
                              <template v-else>
                                  No Data
                              </template>
                          </template>
                      </el-table>
                      <template v-if="customer_quotation_total > customer_quotation_length">
                        <el-pagination
                              @size-change="handleSizeQuoteChange"
                              @current-change="handleCurrentQuoteChange"
                              :current-page.sync="current_page_quot"
                              :page-sizes="[ 25, 50, 100, 200, 300, 500]"
                              :page-size="customer_quotation_length"
                              layout="total, sizes, prev, pager, next, jumper"
                              :total="customer_quotation_total"
                          ></el-pagination>
                      </template>

                        </mdb-col>
                      </mdb-row>
                  </div>

                </template>

<!--               Table For Invoice-->
                <template :slot="'form-slot2'">


                  <div class="window-inner-container mt-1">
                      <mdb-row class="tab-row">
                        <mdb-col col="12" class="tab-col">

                          <div class="p-0 pt-2 pb-1 header-panel-container">
                            <mdb-navbar class="z-depth-1 p-0 pl-2 rounded-sm justify-content-between">
                                <mdb-form-inline class="ml-auto" tag="div">
    

                                    <el-input
                                    @keydown.enter.prevent="handleSearchTermInvoice"
                                    @clear="clearSearchFieldInvoice"
                                    class="table-searchterm-input"
                                    placeholder="Search"
                                    v-model="termInvoice"
                                    clearable style=" align-self: flex-start; width: auto; vertical-align: middle; height: calc(1.5em + .58rem + 2px) !important; padding: 0 0.75rem !important; margin-top: 6px;">
                                    </el-input>

                                    <mdb-btn color="elegant" size="sm" @click="handleSearchTermInvoice"><i class="el-icon-search"></i> Search</mdb-btn>
                                </mdb-form-inline>
                            </mdb-navbar>
                          </div>


                          <el-table ref="purchaseRequestTable" border :data="customerInvoice" class="input-table mt-0 custom-element-table" style="width: 100%" >
                            <el-table-column label="#"  width="35">
                                <template slot-scope="scope">
                                    {{scope.$index + 1}}
                                </template>
                            </el-table-column>
                            <el-table-column label="DATE" :row-class-name="''">
                                <template slot-scope="scope">
                                    {{scope.row.invoice.date}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Tracking No" :row-class-name="''">
                                <template slot-scope="scope">
                                  <a href="javascript:void(0)" @click="openInvoice(scope.row.transaction)">{{scope.row.invoice.series}}</a>
                    
                                </template>
                            </el-table-column>
                            <el-table-column label="Customer/Mobile"  width="435">
                                <template slot-scope="scope">
                                    {{scope.row.name}}/{{scope.row.mobile}}
                                </template>
                            </el-table-column>

                            <el-table-column label="Sold By" :row-class-name="''">
                                <template slot-scope="scope">
                                    {{ scope.row.user && scope.row.user.user ? scope.row.user.user.name : '' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Amount" :row-class-name="''">
                                <template slot-scope="scope">
                                    {{scope.row.invoice.total}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Status" :row-class-name="''">
                                <template slot-scope="scope">
                                    {{scope.row.status}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Remarks" :row-class-name="''">
                                <template slot-scope="scope" v-if="scope.row.transaction_attr.closed.remarks != null">
                                    {{ scope.row.transaction_attr.closed.remarks.join(', ') }}
                                </template>
                            </el-table-column>
                            <template slot="empty">
                                <template v-if="loadingInvoice">
                                    <mdb-btn color="elegant" size="sm" rounded disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading data...
                                    </mdb-btn>

                                </template>
                                <template v-else>
                                    No Data
                                </template>
                            </template>
                        </el-table>
                      <template v-if="customer_invoice_total > customer_invoice_length">
                        <el-pagination
                                @size-change="handleSizeInvoiceChange"
                                @current-change="handleCurrentInvoiceChange"
                                :current-page.sync="current_page_invoice"
                                :page-sizes="[5,10, 25, 50, 100, 200, 300, 400]"
                                :page-size="customer_invoice_length"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="customer_invoice_total">
                            </el-pagination>

                      </template>

                        </mdb-col>
                      </mdb-row>
                  </div>
                </template>

            </mdb-tabs>

            
          </XWindow>
  </ValidationObserver>

  <TransactionForm ref="transactionDialogForm" :group="(group + 999)" :windowClass="'collection height-auto customer-segment-transaction-form'" :parentContainerStyle="parentContainerStyle"></TransactionForm>
  <InvoiceForm ref="invoiceDialogForm" :group="(group + 999)" :windowClass="'collection height-auto customer-segment-invoice-form'" :parentContainerStyle="parentContainerStyle"></InvoiceForm>

  <el-dialog
      title="Warning"
      :visible.sync="dialogVisible"
      width="30%" class="warning">
      <div class="text-center"><i class="el-icon-warning text-warning" style="font-size:42px;"></i></div>
      <p>You are about to update the status with a mismatch received quantity.<br />Are you sure you want to continue?</p>
      <div slot="footer" class="dialog-footer text-left">
        <mdb-btn color="elegant" size="sm" @click="onSubmitForm('received-with-discrepancy')">Continue with Discrepancy</mdb-btn>
       <!--  <mdb-btn color="elegant" size="sm" @click="onSubmitForm('open-updated')">Save, Keep it Open</mdb-btn> -->
        <mdb-btn color="grey" size="sm" @click="dialogVisible = false">Cancel</mdb-btn>

      </div>
    </el-dialog>

    <el-dialog
      :title="selectedAction"
      :visible.sync="showModal1"
      width="30%" :close-on-click-modal="false" :close-on-press-escape="false">
      <p>Customer Response:</p>
      <textarea v-model="note" class="form-control"></textarea>
      <p class="text-danger">{{ errorMessage }}</p>

      <div slot="footer" class="dialog-footer text-left">
        <mdb-btn color="elegant" size="sm" @click="saveNote">Save</mdb-btn>
        <mdb-btn color="grey" size="sm" @click="closeModal">Cancel</mdb-btn>

      </div>
    </el-dialog>
<!-- 
      <mdb-modal :show="showModal1" title="Add Note">
          <mdb-modal-body>
              <h5>{{ selectedAction }}</h5>
              <hr/>
              <p>Enter your note here:</p>
              <textarea v-model="note" class="form-control"></textarea>
              <p class="text-danger">{{ errorMessage }}</p>
          </mdb-modal-body>
          <mdb-modal-footer>
              <mdb-btn @click="saveNote" color="black">Save</mdb-btn>
              <mdb-btn @click="closeModal" color="black">Cancel</mdb-btn>
          </mdb-modal-footer>
      </mdb-modal>
 -->


  </div>
</template>
<style>
.pagination-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: flex-start;
}
</style>
<script>

/*eslint no-prototype-builtins: "error"*/

import {
    mdbClickOutside, mdbBtn, mdbRow, mdbCol, mdbIcon, mdbTabs, mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbInput, mdbContainer, mdbTimeline,
    mdbTimelineItem, mdbCard,mdbCardBody,mdbCardText, mdbModal, mdbModalBody, mdbModalFooter, mdbFormInline, mdbNavbarBrand, mdbNavbar,mdbBtnFixed, mdbBtnFixedItem
} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect, ThatsDatePicker} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import XWindow from '../../components/thats/XWindow.vue'

import { Loading } from 'element-ui';
import ThatsTable from "./Table.vue";
import TransactionForm from "../transaction/TransactionForm.vue";
import InvoiceForm from "../transaction/InvoiceForm.vue";
import QuotationTable from "../../components/thats/QuotationTable.vue";
import Chat from '../../components/chat/Chat.vue'
import BtnFixed from '../../components/chat/BtnFixed.vue'
export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
      transactionCount: {
          type: Object,
          required: true
      },
      spent: {
          type: Object,
          required: true
      },
      quotationsCount: {
          type: Object,
          required: true
      },
      filterFormData : {
          type: Object,
          default : () => ({
              searchTerm : null
          })
      },
      filterFormDataQuotation : {
          type: Object,
          default : () => ({
              searchTermQuot : null
          })
      },
      filterFormDataInvoice : {
          type: Object,
          default : () => ({
              searchTermInvo : null
          })
      }
  },
  inject : ['posShowroom','showroomsProvider','userDataProvider','userData','btsApproverProvider'],
    components: {
        mdbNavbar, mdbNavbarBrand, mdbFormInline,
        QuotationTable,
        TransactionForm,
        InvoiceForm,
        ThatsTable,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        ThatsTextarea,
        ThatsMoney,
        ThatsMultiSelect,
        ThatsDatePicker,
        XWindow,
        mdbContainer,
        mdbTimeline,
        mdbTimelineItem,
        mdbCard,
        mdbCardBody,
        mdbCardText,
        mdbModal,
        mdbModalBody,
        mdbModalFooter,
        Chat,
        mdbBtnFixed,
        mdbBtnFixedItem,
        BtnFixed
  },

  data(){

    const self = this;

    return {
        showModal1: false,
        note: "",
        selectedAction: "",
      dialogVisible : false,
        isIndeterminate : false,
        checkAllReceived : false,
        disabled : false,
        onPasteLoading : false,
        isMaximized : true,
        inputValue : 0,
        validated : false,
        active: 0,
        eventKeyCounter: 0,
        filteredShowroom : [],
        selection : [],
        data : [],
        form : {},
        customerDetails : {},
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        isDropdownOpen: false,
        errorMessage: "",

        tabs : [
                { text: 'Details', slot: 'form-slot' },
                { text: 'Quotations', slot: 'form-slot1' },
                { text: 'Invoices', slot: 'form-slot2' },
              ],
        gallery : [],
        index : 0,

        chat: {
        loggedUserId: 3,
        lastId: 4,
        chat: [
          {
            id: 0,
            name: "Brad Pitt",
            img: "https://mdbootstrap.com/img/Photos/Avatars/avatar-6.jpg",
            messages: [
              {
                id: 0,
                date: "2019-04-21 15:00:09",
                content:
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
              },
              {
                id: 1,
                date: "2019-06-26 11:00",
                content:
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
              },
              {
                id: 4,
                date: "2019-06-26 11:20",
                content:
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
              }
            ]
          },
          {
            id: 1,
            name: "Lara Croft",
            img: "https://mdbootstrap.com/img/Photos/Avatars/avatar-5.jpg",
            messages: [
              {
                id: 2,
                date: "2019-06-26 11:15",
                content:
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
              },
              {
                id: 3,
                date: "2019-06-26 11:16",
                content:
                  "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium."
              }
            ]
          }
        ],

       
      },

      items: [
         
          { color: "yellow", icon: "phone", event: "item-click", fas : true , text : "Phone Call"},
          { color: "green", icon: "envelope", event: "item-click", far: true, text : "Email"},
          { color: "blue", icon: "whatsapp", event: "item-click", fab : true, text : "WhatsApp"}
        ],

        parentContainerStyle : 'customer-segment-child-window'

    }
  },


  created : function() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.resetForm();
      this.clearSearchField()
      this.clearSearchFieldInvoice()

  },




  methods: {
    ...mapMutations('CustomerSegmentModule',['setSearchTerm','setTermQuotation','setTermInvoice','setFilters','setSearchTermQuotation','setCustomerPage','setCustomerLength','setCustomerQuotationPage','setCustomerQuotationLength','setCustomerInvoicePage','setCustomerInvoiceLength','setFormData','setCustomerDetails','setEmptyForm','setFormStatusCreate']),
    ...mapActions('CustomerSegmentModule',['storeRecord','resetDataQuotation','resetDataInvoice','updateRecord','getRecord','getCustomerDetails','getCustomerQuotation','getCustomerInvoice','storeCustomerTrack','getCustomerTrack']),
    ...mapActions('TransactionModule',{
      getQuotationRecord : 'getRecord'
    }),
    ...mapMutations('TransactionModule',{
      setQuotationFormStatusUpdate : 'setFormStatusUpdate',
      setQuotationFormRecord : 'setFormRecord'
    }),

    ...mapActions('InvoiceModule',{
            invoiceGetRecord : 'getRecord',
        }),
    ...mapMutations('InvoiceModule',{
        invoiceSetRecord : 'setFormRecord',
        invoiceStatusUpdate : 'setFormStatusUpdate',
        invoiceSetTblKey : 'setTblKey',
    }),

    onResize({width,height}){
        //
      },
      async dispatchHandler(){
          await this.getCustomerTrack(this.customerId)
      },
      handleSizeChange(val) {
          this.setCustomerLength(val)
          this.dispatchHandler()
      },
      handleCurrentChange(val) {
          this.dispatchHandler()
      },

      async dispatchQuoteHandler() {
          await this.getCustomerQuotation(this.customerId);
      },
      handleSizeQuoteChange(val) {
          this.setCustomerQuotationLength(val);
          this.dispatchQuoteHandler();
      },
      handleCurrentQuoteChange(val) {
          this.dispatchQuoteHandler();
      },


      async dispatchInvoiceHandler() {
          await this.getCustomerInvoice(this.customerId);
      },
      handleSizeInvoiceChange(val) {
          this.setCustomerQuotationLength(val);
          this.dispatchInvoiceHandler();
      },
      handleCurrentInvoiceChange(val) {
          this.dispatchInvoiceHandler();
      },

      handleSearchTermQuotation() {
          // this.dispatchHandler()
          this.dispatchFilter({
              ...this.filterFormDataQuotation,searchTermQuot : this.termQuotation
          })
      },
      handleSearchTermInvoice() {
          // this.dispatchHandler()
          this.dispatchFilterInvoice({
              ...this.filterFormDataInvoice,searchTermInvo : this.termInvoice
          })
      },
      clearSearchField() {
          this.termQuotation = '';

          this.handleSearchTermQuotation();
      },
      clearSearchFieldInvoice() {
          this.termInvoice = '';

          this.handleSearchTermInvoice();
      },

      dispatchFilter(filters){
          this.setFilters(filters)
          this.resetDataQuotation(this.customerId);
      },
      dispatchFilterInvoice(filters){
          this.setFilters(filters)
          this.resetDataInvoice(this.customerId);
      },

      toggleDropdown() {
          this.isDropdownOpen = !this.isDropdownOpen;
      },

      openNoteModal(action) {

        console.log("action")
        console.log(action)
          this.showModal1 = true;
          this.selectedAction = action.text;
          this.isDropdownOpen= false;
          this.note = "";
          this.errorMessage = "";
      },
      closeModal() {
          this.selectedAction = "";
          this.showModal1 = false;
          this.note = "";
          this.errorMessage = "";
          this.isDropdownOpen= false;
      },
      saveNote() {
          const requestData = {
              customer_id: this.customerId,
              note: this.note,
              contact_source: this.selectedAction,
          };

          this.storeCustomerTrack(requestData)
              .then((response) => {
                  console.log(response.data);
                  this.dispatchHandler()

                  this.closeModal();
              })
              .catch((error) => {
                  console.error(error);
                  if (error.response && error.response.data && error.response.data.message) {
                      this.errorMessage = error.response.data.message;
                  } else {
                      this.errorMessage = "An error occurred while saving the note.";
                  }
              });
      },

      fetchCustomerDetails() {
          // axios.get(`/api/customer/${customerId}`)
          //     .then(response => {
          //         // Assign fetched customer details to the data property
          //         this.customer = response.data;
          //     })
          //     .catch(error => {
          //         console.error(error);
          //     });
      },

    resetForm(){


      this.form = JSON.parse(JSON.stringify(this.initialFormObject));


      console.log("this.userData")
      console.log(this.userData)
      // this.form.received_by.id = this.userData.user.id
      // this.form.received_by.name = this.userData.user.name

      },
    openDialog(){
        this.$refs.window.openDialog()
    },
    closeDialog(){
      this.$refs.window.closeDialog()
    },
      getActiveTabIndex(index) {
          this.clearSearchField()
          this.clearSearchFieldInvoice()
          this.active = index;
          const activeSlotName = this.tabs[index].slot;
          if (activeSlotName === 'form-slot1') {
              this.setCustomerQuotationPage(1);
              this.getCustomerQuotation(this.customerId);

          } else if (activeSlotName === 'form-slot2') {
              this.setCustomerInvoicePage(1);
              this.getCustomerInvoice(this.customerId);

          }
      },

    onSubmitForm(saveAs = 'open'){
       const self = this;
            self.$refs.observer.validate().then((isValid) => {
              if(isValid){

                  if((saveAs == 'received' && this.form.action == 'receive') || (saveAs == 'adjustment' && this.form.action == 'adjustment')){

                    let totalQuantity = 0;
                      const qty = self.form.items.map(item => {
                            return parseInt(item.quantity)
                          })

                          totalQuantity = qty.reduce((prev, curr) => {
                              const value = Number(curr);
                              if (!isNaN(value)) {
                                return prev + curr;
                              } else {
                                return prev;
                              }
                            }, 0);


                            let totalReceivedQuantity = 0;
                          const receivedQuantity = self.form.items.map(item => {
                                return parseInt(item.received_quantity)
                              })

                          totalReceivedQuantity = receivedQuantity.reduce((prev, curr) => {
                              const value = Number(curr);
                              if (!isNaN(value)) {
                                return prev + curr;
                              } else {
                                return prev;
                              }
                            }, 0);

                      if(totalReceivedQuantity != totalQuantity && this.form.action == 'receive'){
                        self.dialogVisible = true;
                        return false

                      }

                      if(totalReceivedQuantity != totalQuantity && this.form.action == 'adjustment'){
                        MessageBox({
                              title : 'Error',
                              message: 'Quantity and Received Quantity is mismatch.',
                              type: 'error'
                          })
                        return false

                      }

                      if(totalReceivedQuantity == totalQuantity && this.form.action == 'adjustment'){
                        saveAs = 'received'
                      }

                  }
                  self.dialogVisible = false
                  self.updateInventoryTransfer(self.form,saveAs)

                }
            });
            /* const isValid =
             */
    },
    updateInventoryTransfer(obj,status){
      const self = this;
        const data = {...obj, status : status}

        if(this.formLoading == false){
          this.formLoading = true;
          this.updateRecord(data).then((res) => {
            self.formLoading = false
              self.$ThatsNotify.success({
                message : "Record successfully updated."
              })
              self.closeDialog()
              self.$emit('success')
          }).catch((err) => {
            self.formLoading = false
            self.closeDialog()
          })
        }
    },

    cellClassName(){

    },

    createPopupTable(spreadsheetOptions){


    },




    fnPrint(url){
      const self = this;
      console.log(`${self.$route.path}/${url}`)

      var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
      const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
      win.document.title = 'Print';
      win.print()
    },


        renderTable(){
            const self = this;




        },


        currentDateSetter(){
          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form.date = date;
        },

      dialogState(newState){
        if(!newState){


            this.resetForm();
          return
        }

      },



    handleSelectionChange(v){

      console.log("handle selection")
      this.form.inventory_transfer_receiving_form.items = v
    },

    canSelectRow(row, index){
        if(row.remaining <= 0){
           return false
        }
        return true;
    },

    disableInput(row){
        if(row.remaining <= 0){
           return true
        }
        return false;
    },

    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },


      fnPrintDoc(url){
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      receiveInventoryTransfer(){
          let totalQuantity = 0;
          const qty = this.form.items.map(item => {
                return parseInt(item.quantity)
              })

              totalQuantity = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);


                let totalReceivedQuantity = 0;
              const receivedQuantity = this.form.items.map(item => {
                    return parseInt(item.received_quantity)
                  })

              totalReceivedQuantity = receivedQuantity.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);





          if(totalReceivedQuantity != totalQuantity){
            this.dialogVisible = true;
            return false
           /*  MessageBox.confirm('You are about to updated status with mismatch received quantity. Are you sure you want to continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {

            }) */



          }

          alert(`${totalQuantity} != ${this.countReceived}`)
      },

      handleCheckAllChange(val) {
        var self = this;
          this.isIndeterminate = false;
          this.form.items.forEach((row,index) => {
            const isReceivable = (row.received_quantity > 0) ? true : false;
            self.$set(self.form.items,index, {...row,received : isReceivable});
          })


      },
      handleCheckedReceivedChange(value) {
        const self = this;
        const checked = this.form.items.filter(row => {
          return row.received === true
        }).map(e => e.received).length;

        const isIndeterminate = checked > 0 && checked < this.form.items.length;

        const checkAllReceived = checked === this.form.items.length

        this.$nextTick(() => {
            self.isIndeterminate = isIndeterminate
            self.checkAllReceived = checkAllReceived
        });

        /* this.sele
        console.log((checked > 0 && checked < this.form.items.length)) */
      },

      getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];

        columns.forEach((column, index) => {
          if (index === 3) {
            sums[index] = 'Total';
            return;
          }


          if (index === 4) {
            const qty = data.map(item => {
                return parseInt(item.quantity)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            return;
          }

          if (index === 7) {
            const qty = data.map(item => {
                return parseInt(item.received_quantity)
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            return;
          }




          });

          // this.toggleCheck()

        return sums;
      },

      handleClose(){
          this.dialogVisible = false
      },

      openQuotation(obj){
        const self = this
        const form  = {...obj,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    showroom : {
                                location : null,
                                prefix : null
                            },
                    credit_notes : [],
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    },
                    customer : {
                      ...this.customerData,
                      customer_id : this.customerData.id
                    }
                    } 
          self.setQuotationFormStatusUpdate()
          self.setQuotationFormRecord(form)
          this.getQuotationRecord({token_id : obj.token_id})
        this.$refs.transactionDialogForm.openDialog()
      },


      openInvoice(obj){
        const self = this

        const form  = {...obj,items : [],attachment : [],
                        invoice : {
                          series : null,
                          date : "",
                          balance : 0,
                          paid : 0,
                          attachment : [],
                          payments : [],
                        },
                        credit_note : {
                            total : 0,
                            status : null,
                            credit_note_reference : []
                        },
                        creditnotes : [],
                        credit_note_items : [],
                        cancellation : [],
                        cancellation_item : [],
                        refund : [],
                        refund_item : [],
                        exchange : [],
                        credit_memo : [],
                        exchange_item : [],
                        enable_push : false,
                        location : null,
                        customer : {
                          ...this.customerData,
                          customer_id : this.customerData.id
                        }
                    }

                    if(!form.attr.hasOwnProperty('corporate')){
                            form.attr.corporate = false
                    }
                  if(!form.attr.hasOwnProperty('contact_person')){
                      form.attr.contact_person = {
                          name : null,
                          mobile : null,
                          attr : {
            
                            address: null,
                            address2 : {
                                street : null,
                                building : null,
                                zone : null,
                                area : null,
                                city : null,
                            },
                            designation : null,
                            email : null,
                            first_name : null,
                            middle_name : null,
                            last_name : null,
                            salutation : null
                          }
                        }
                  }
              self.invoiceStatusUpdate()
              self.invoiceSetRecord(form)
              self.invoiceGetRecord({token_id : obj.token_id})

              this.invoiceSetTblKey(this.salesTblKey + 1);
              this.$refs.invoiceDialogForm.openDialog();
      }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CustomerSegmentModule',['getLoading','searchTermQuot',
        'searchTermInvo','loadingQuotation',
        'loadingInvoice','customerQuotation',
        'customerInvoice','formData','customer_total',
        'customer_page', 'customer_length','customer_quotation_total',
        'customer_quotation_page', 'customer_quotation_length',
        'customer_invoice_total','customer_invoice_page',
        'customer_invoice_length','formStatus','initialFormObject',
        'customerData','customerTrack','loading']),

      tblKey : function(){
        return 1;
      },

      termQuotation : {
          get : function(){
              return this.searchTermQuot
          },
          set : function(val){
              this.setTermQuotation (val)
          }
      },


      termInvoice : {
          get : function(){
              return this.searchTermInvo
          },
          set : function(val){
              this.setTermInvoice (val)
          }
      },


      current_page : {
          get : function(){
              return this.customer_page
          },
          set : function(val){
              this.setCustomerPage(val);
          }
      },
      current_page_quot: {
          get: function() {
              return parseInt(this.customer_quotation_page);
          },
          set: function(val) {
              this.setCustomerQuotationPage(val);
          }
      },

      current_page_invoice: {
          get: function() {
              return parseInt(this.customer_invoice_page);
          },
          set: function(val) {
              this.setCustomerInvoicePage(val);
          }
      },

      customerName() {
          return this.customerData.name;
      },
      customerMobile() {
          return this.customerData.mobile;
      },
      customerId() {
          return this.customerData.id;
      },

      showroomLocation : function(){
        const showroom = this.showroomsProvider();
        return showroom.map((item) => {
            return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
          },[])
      },

      rowShowroomLocation : function(){

      const showroom = this.showroomsProvider();

      return showroom.map((item) => {
          // return {...item, name :item.prefix,id : item.value}
          return item.prefix
        },[])
      },

      userDatax : function(){
        return this.userDataProvider()
      },
      userData : function(){
        return this.userData()
      },
      documentApprover : function(){
        return this.btsApproverProvider()
      },

      countReceived : function(){
        return this.form.items.filter((item) => item.received == true,[]).length
      }


  },

  watch: {
      'formData' : function(v){

        const self = this

        this.eventKeyCounter++
        
          this.form = JSON.parse(JSON.stringify({...v}));
          this.$nextTick(()=>{


          })

        /*
        if(this.formStatus == 'update'){



          var MyDate = new Date();
          var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);

          this.form = JSON.parse(JSON.stringify({...v, date : date}));
          this.$nextTick(()=>{


          })

          this.active = 0;


        }else{



          this.tabs = [
                { text: 'Request Details', slot: 'form-slot' },
                { text: 'Remarks', slot: 'remarks-slot' }
              ]
          this.resetForm();
          this.active = 0;
        } */

      },





      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheet.setData(this.form.items)
        })


        }
      },

      documentApprover : function(value){
          this.form.approver = JSON.parse(JSON.stringify(value))
      },

      showroomLocation : function(value){

          const allowedLocation = this.userData.location_prefix
        this.filteredShowroom = value.filter((i) =>{
            return allowedLocation.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })
      },





  }

}
</script>

<style lang="scss">



.bts-form-tabs{
    .nav-tabs{
      margin-left: 10px;border-bottom: none!important;
      .nav-item{
        a.active{
          border-bottom: 1px solid #0056b3;
        }
      }
    }

    .tab-content{
      height: auto!important;border-top:1px solid #dee2e6;
    }
}

</style>
<style scoped>
.avatar-sm {
  width: 56px;
  border-radius: 50%;
}
.chat-message-type {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}

.profile-card {
  margin-top: 100px;
}

.profile-card .avatar {
  max-width: 150px;
  max-height: 150px;
  margin: -70px auto 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
}

.btn-floating i {
  color: #4f4f4f !important;
}
</style>
