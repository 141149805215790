import { ApiService, ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const PurchaseRequestModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    width: '150',
                },
                {
                    label: 'Request PO No.',
                    field: 'series',
                    sort: 'asc',
                    clickable: true
                },
                
                {
                    label: 'Customer Order Sales Invoice',
                    field: 'transaction_code',
                    sort: 'asc',
                },
                {
                    label: 'Request By',
                    field: 'request_by',
                    sort: 'asc',
                },
                /* {
                    label: 'Vendor',
                    field: 'vendor_name',
                    sort: 'asc',
                }, */
                {
                    label: 'Status',
                    field: 'status_label',
                    sort: 'asc',
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        Warehouse: [],
        payment_references: [],
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {}

    
    },
    actions: {

        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiV1Service.get('sales/filter-item', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
       
        async getRecord({ rootState, commit, dispatch }, params) {
            const { viewLoading, tblKey } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiV1Service.get(`purchase-request/${params.token_id}`, params).then((response) => {

                        let responseData = {...response.data.data};
                        const incrementTblKey = tblKey + 1
                        commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = rootState.TransactionModule
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('purchase-request', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.TransactionModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    // commit('setFormRecord', payloadConst)
/*                     
                    const payloadConst = payload;
                    payloadConst.items = [];
                    commit('setFormRecord', payloadConst) */

                    ApiService.store('sales',parsePayload).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }
                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ rootState, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = rootState.TransactionModule.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiService.update('purchase-request',updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        commit('setFormRecord', response.data.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateCreditNote({ rootState, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = rootState.TransactionModule.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiService.update('sales/update-credit-note',updatePayload).then((response) => {
                        commit('setPutLoadingFalse')

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }

                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async deleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.TransactionModule.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('sales/invoice',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.TransactionModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/save-import',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`${payload.payment_for}/payment`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updatePayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update('sales/update-payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async storeCreditNote({ rootState, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.TransactionModule.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/credit-note',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = false
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = true
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {

            const form  = {items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    },
                    credit_note_items : []
                }

            state.formRecord = {...form,...payload}
        },
        setWarehouse: (state, payload) => { 
            state.Warehouse = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },
        setPaymentReference: (state, payload) => {
            state.payment_references = payload
        },
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        Warehouse: state => state.Warehouse,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
        payment_references: state => state.payment_references,
        tblKey: state => state.tblKey,
    }
}


