
<template>
    <div class="customervendor-container">
       pdf view
    </div>
</template>
<script>


export default {
    data(){
        return {
            
            data : 'menu',        
        }
    },    
    methods: {
    

    },

}
</script>
