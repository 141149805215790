<template>
<hsc-window-style-metal>
    <hsc-window title="Authorized User Only" :resizable="false" :left="x" :top="y" :width="width" :height="height" :zGroup="group"  :closeButton="true" :isOpen.sync="dialogIsOpen">
        <ValidationObserver ref="observer" v-slot="{ passes}">
          <form class="needs-validation" v-on:submit.prevent="onSubmitForm">
            <mdb-container class="p-0">
              <mdb-alert color="primary" class="small">
                Adding new item requires authorized user email and password.
              </mdb-alert>
              <mdb-container class="pt-0 pl-3 pr-3 pb-3">
                <template v-if="errorMessage !== null">
                  <mdb-alert color="danger" class="small" v-text="errorMessage"></mdb-alert>
                </template>
                <validation-provider rules="required">
                  <mdb-input v-model="form.email" label="Email" autocomplete="off" required @keyup.native.enter="passes(onSubmitForm)" />
                </validation-provider>

                <validation-provider rules="required">
                  <mdb-input v-model="form.password" label="Password" type="password" @keyup.native.enter="passes(onSubmitForm)"/>
                </validation-provider>
                  <mdb-form-inline tag="div">
                    <template v-if="loading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <button class="btn btn-sm btn-elegant" type="button" @click="onSubmitForm">OK</button>
                      </template>
                  </mdb-form-inline>
              </mdb-container>
            </mdb-container>
          </form>
        </ValidationObserver>
    </hsc-window>
    </hsc-window-style-metal>
</template>

<script>
import { ValidationObserver,ValidationProvider } from "vee-validate";
import {mdbBtn,mdbIcon, mdbContainer, mdbFormInline, mdbInput, mdbAlert } from 'mdbvue'
import {mapActions} from 'vuex'
export default {
    name : "FileUpload",
    components : { 
      mdbBtn,mdbIcon, mdbContainer,mdbFormInline,
      ValidationObserver,ValidationProvider,
      mdbInput,mdbAlert 
    },
    props : {
      group : {
            type : Number,
            default : 1
        },
      height : {
        type : Number,
            default : 200
      },
      width : {
        type : Number,
            default : 400
      },
    },
    data () {
      return {
        dialogIsOpen : false,
        loading : false,
        errorMessage : null,
        form : {
          email : "",
          password : "",
        }
      }
    },
/* 
 */
    methods: {
        ...mapActions('UserModule',['authorizedUser']),
        openDialog(){
            this.dialogIsOpen = true
        },

        closeDialog(){
            this.dialogIsOpen = false
        },

       handleFileUpload(){
        this.file = this.$refs.file.files[0];
        },
        onSubmitForm(){
          if(!this.loading){
            this.loading = true
            this.authorizedUser(this.form)
                    .then(response => {
                      this.$emit('isAuthorized',response.authorized);
                      this.loading = false
                      this.closeDialog();
                      return;
                    })
                    .catch((error) => {
                      this.loading = false
                      this.errorMessage = error.response.data.message
                    });
          }
                    
        }, 

    },


    watch: {
      dialogIsOpen : function(state){
        this.errorMessage = null;
        this.form = {
          email : "",
          password : "",
        }

        this.loading = false
        this.$emit('dialog-state',state)
      }
    },

    beforeDestroy() {
        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth + 240) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },
  }
</script>