<template>
    <vue-excel-editor v-model="jsondata">
        <vue-excel-column field="user"   label="User" />
        <vue-excel-column field="name"   label="Name" />
        <vue-excel-column field="phone"  label="Contact" />
        <vue-excel-column field="gender" label="Gender" />
        <vue-excel-column field="age"    label="Age" />
        <vue-excel-column field="birth"  label="Date Of Birth" />
    </vue-excel-editor>
</template>

<script>
export default {
    data(){
        return {
            jsondata : [
                {
                    "field" : "user",
                    "user" : "userx",
                    "name" : "userx",
                    "phone" : "userx",
                    "gender" : "userx",
                    "age" : "userx",
                    "birth" : "userx",
                }
            ]
        }
    }
}
</script>