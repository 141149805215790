<template>
    <ValidationProvider :vid="$attrs.vid" :name="$attrs.vid" :rules="rules" v-slot="{errors,invalid}">
        <div class="form-group input-group-sm row thats-form">
            <template v-if="showLabel">
                <label :for="$attrs.vid" v-bind:class="bindLabelHorizatal +' '+ labelClass">{{$attrs.label}}</label>
            </template>
            <div :class="fieldClass">
            <div class="md-form mt-0">
                <mdb-input :size="$attrs.size" :is-invalid="invalid" :invalidFeedback="errors[0]" :placeholder="$attrs.placeholder" :inputClass="comInputClass+' '+bindInputHorizatal + ' ' +hasError(errors)" v-model="inputValue" :disabled="disabled" :readOnly="readOnly" :label="$attrs.label" :type="type" autocomplete="off" noWrapper bg :maxlength="maxlength"/>
                <template v-if="show_error == true">
                    <span class="errors">{{errors[0]}}</span>
                </template>
                <template v-if="showEditBtn">
                    <a href="javascript:void(0)" @click="editField" class="edit-custom-select">{{(inputValue == null ? 'Add' : 'Edit')}}</a>
                    </template>
            </div>
            </div>
        </div>
    </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { mdbInput } from "mdbvue";
export default {
    name : 'ThatsInput',
    props : {
        rules : {
            type : [Object, String],
            default : ''
        },
        type : {
            type : String,
            default : 'text'
        },
        value: {
            type: null
        },
        validated: {
            type: Boolean,
            default : false
        },
        show_error: {
            type: Boolean,
            default : false
        },

        labelClass : {
            type : String,
            default : 'col-md-3 col-form-label'
        },
        fieldClass : {
            type : String,
            default : 'col-md-9'
        },
        inputclass : {
            type : Array,
            default : function(){
                return ['s'];
            }
        },
        disabled : {
            type : Boolean,
            default : false
        },
        readOnly : {
            type : Boolean,
            default : false
        },
        showLabel: {
            type: Boolean,
            default : true
        },
        horizontal: {
            type: Boolean,
            default : false
        },
        maxlength: {
            type: String,
            default : ""
        },
        showEditBtn: {
            type: Boolean,
            default : false
        },
    },
    components : {
        ValidationProvider,
        mdbInput,
    },

    data : ()=>({
        inputValue : null
    }),

    

    methods : {
        isValid(isInvalid,inpt){
            return ((isInvalid === true && inpt === '') || (inpt === '' && this.validated === true)) ? 'is-invalid' : null
        },
        hasError(error){
            return (error.length > 0) ? 'is-invalid' : null
        },
        editField(){
            this.$emit('open');
        }

    },
    watch: {
    // Handles internal model changes.
        inputValue(newVal) {
            this.$emit("input", newVal);
        },
    // Handles external model changes.
        value(newVal) {
            this.inputValue = newVal;
        }
    },
    created() {
        if (this.value) {
        this.inputValue = this.value;
        }
    },
    computed : {
        bindLabelHorizatal : function(){
            const styleClass = ["col-form-label"];
                if(this.horizontal){
                    styleClass.push("form-label-horizontal");
                }
            return styleClass.join(' ');
        },
        bindInputHorizatal : function(){
            const styleClass = [];
                if(this.horizontal){
                    styleClass.push("input-horizontal");
                }
            return styleClass.join(' ');
        },
        comInputClass : function(){
            return this.inputclass.join(' ');
        }
    },
}

</script>

<style scoped>
.edit-custom-select{
    display: block;
    position: absolute;
    right: 5px;
    z-index: 9;
    top: 3px;
}
</style>