import { ApiHttpService } from '../../services'

import router from '../../router'
export const LocalPurchaseDeliveryNoteModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Doc Date',
                    field: 'date',
                    sort: 'asc',
                    width: '130'
                },
                {
                    label: 'DocNum',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width: '150'
                },
              
                ],
            rows: [],
        },
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},

        initialFormData : {
            series : "",
            date : "",
            status : "open",
            description: null,
            reference: 'local-purchase',
            discount: 0,
            attr : {
                project_ref_number : null
            },
            vendor : {
                id : null,
                series : null,
                mobile : null,
                display : null,
                name : null 
            },
            items: [],
            
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,

        formStatus : 'create',
        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setNewItemDataObject : (state, payload)=>{
            state.newItemDataObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){

        
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
           
            const { length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {
            
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "LocalPurchaseDeliveryNoteModule/req",
                        loadingTrue : "LocalPurchaseDeliveryNoteModule/loadingTrue",
                        addRequest : "LocalPurchaseDeliveryNoteModule/addRequest",
                        cancelRequest : "LocalPurchaseDeliveryNoteModule/cancelRequest",
                        clearOldRequest : "LocalPurchaseDeliveryNoteModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        commit('setLoading', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                
            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj, items : []}
 
                    ApiHttpService.getRequest('local-purchase-delivery-note/'+obj.series,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false) 

                        const documentLines = response.data.document_lines.map((item) => {
                            let dimension = ''
                            let photo = ''
                            if(item.hasOwnProperty('item') && item.item != null){
                                dimension =item.item.dimension
                                photo =item.item.default
                            }
                            return {
                                ...item,
                                series : item.code,
                                quantity : item.qty,
                                discount : item.discount_percent,
                                total : item.line_total,
                                description : item.remarks,
                                dimension : dimension,
                                photo : photo,
                                valid : true
                            }
                        })

                        const data = response.data

                   

                        if(!data.attr.hasOwnProperty('project_ref_number')){
                            data.attr = {
                                ...data.attr,
                                project_ref_number : null
                            }
                        }

                        const responseData = {...initialFormData,...data, items : documentLines, vendor : {
                            ...response.data.vendor,
                            display : `${data.vendor.name}/${data.vendor.mobile}`,
                            value : data.vendor.id,
                            label : data.vendor.series,
                            opt : `${data.vendor.series} - ${data.vendor.name}/${data.vendor.mobile}`
                        }}
                        commit('setFormData',responseData)
                        resolve(responseData)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },
        
       
        
      
        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "LocalPurchaseDeliveryNoteModule/req",
                        loadingTrue : "LocalPurchaseDeliveryNoteModule/setPostLoadingTrue",
                        addRequest : "LocalPurchaseDeliveryNoteModule/addRequest",
                        cancelRequest : "LocalPurchaseDeliveryNoteModule/cancelRequest",
                        clearOldRequest : "LocalPurchaseDeliveryNoteModule/clearOldRequest"
                    }
                    ApiHttpService.post('local-purchase-delivery-note',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`local-purchase-delivery-note/${updatePayload.series}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        getNewItemDataObject: state => state.newItemDataObject,
    }
}



