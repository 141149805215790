import { ApiService, ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
import { post } from 'jquery'
export const InvoiceModule = {
    namespaced: true,
    state: {
        tblKey: 0,
        loading: false,
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Date',
                    field: 'date_human',
                    sort: 'asc',
                    width: '120',
                },
                {
                    label: 'Tracking No.',
                    field: 'series',
                    sort: 'asc',
                    width: '150',
                    clickable: true
                },
                
                {
                    label: 'Item Name',
                    field: 'item_names',
                },
                {
                    label: 'Customer/Mobile',
                    field: 'customer_mobile',
                    property : 'col_customer'
                },
                {
                    label: 'Sold By',
                    field: 'sold_by',
                    width: '200',
                },
                {
                    label: 'Total Amount',
                    field: 'total',
                    align : 'right',
                    width: '150',
                },
                {
                    label: 'Balance',
                    field: 'balance',
                    align : 'right',
                    width: '120',
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc',
                    align: 'right',
                    width: '100',
                },
               /*  {
                    label: 'Instructions',
                    field: 'instructions',
                    sort: 'asc',
                    align: 'left',
                    width: '200',
                }, */
                {
                    label: 'Print',
                    field: 'print',
                    align: 'right',
                    width: '100',
                },

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        Warehouse: [],
        payment_references: [],
        discountForm: {
            index : null,
            form: {
                item_id: null,
                code: null,
                name: null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                    apply: true,
                    discount: 0,
                    off_price: 0,
                    promo: 0,
                },
                total: 0,
                remarks: null
            }
        },

        paymentForm : {},
        invoiceData : [],
        deliveryNoteIntegrationData : [],
        successPayment : {
            success : false,
            data : {}
        }
    
    },
    actions: {
        async getNextCodePayment({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales/payment/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('sales/filter-item', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('sales/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        async postInvoiceToSap({state},params){

        },

        async getCustomerCreditNotes({ state, commit, dispatch }, params) {
            const { viewLoading } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`sales/credit-note/${params.token_id}`).then((response) => {
                        commit('setViewLoadingFalse')
                        commit('setPaymentReference',response.data.data)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        getPartner() {
            return new Promise((resolve,reject) => {
                ApiService.get(`sales/partner`).then((response) => {
                    const resolveData = [];

                    console.log(response)
                    if(response.data.length > 0){
                        response.data.forEach((el) => {
                            resolveData.push({
                                label : el.name,
                                code : el.id,
                                isDesigner : el.isDesigner,
                                isShowMan : el.isShowMan,
                            })
                        })
                    }
                    resolve(resolveData)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        async getRecord({ state, commit, dispatch }, params) {
            const { viewLoading, tblKey } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`invoices/${params.series}`, params).then((response) => {

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...response.data.data,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }

                        if(!responseData.attr.hasOwnProperty('corporate')){
                            responseData.attr.corporate = false
                        }
                        if(!responseData.attr.hasOwnProperty('contact_person')){
                            responseData.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {
                  
                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }

                        const incrementTblKey = tblKey + 1
                        commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },
        async getRecordSeries({ state, commit, dispatch }, params) {

            console.log(params)
            const { viewLoading, tblKey } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`invoices/${params.series}`, params).then((response) => {

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...response.data.data,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }

                        const incrementTblKey = tblKey + 1
                        commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    })
                }
            })
        },

        async asyncGetData({ state, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = state
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('sales/invoice', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    // commit('setFormRecord', payloadConst)
/*                     
                    const payloadConst = payload;
                    payloadConst.items = [];
                    commit('setFormRecord', payloadConst) */

                    ApiService.store('sales',parsePayload).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }
                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiService.update('invoices',updatePayload).then((response) => {
                        commit('setPutLoadingFalse')

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }
                        dispatch('getRecordSeries', response.data.data)
                        // commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateCreditNote({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiService.update('sales/update-credit-note',updatePayload).then((response) => {
                        commit('setPutLoadingFalse')

                        let responseData = {...response.data.data};
                        if(responseData.invoice === null){
                            responseData = {...responseData,invoice : {
                                series : null,
                                date : "",
                                balance : 0,
                                paid : 0,
                                attachment : [],
                                payments : [],
                            }}
                        }

                        commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async deleteRecord({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.deleteLoading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiService.delete('sales/invoice',tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ state, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = state;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/save-import',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPayment({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`${payload.payment_for}/payment`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updatePayment({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update('sales/update-payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async storeCreditNote({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/credit-note',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data.data)
                        dispatch('getRecordSeries',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async storePullOut({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/pull-out',payload).then((response) => {
                        commit('setPostLoadingFalse')

                        resolve(response.data.data)
                        dispatch('getRecordSeries',response.data.data)

                        // resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async storeItemCancellation({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/item-cancellation',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async storeItemExchange({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/item-exchange',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async storeItemReplacement({dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.store('sales/item-replacement',payload).then((response) => {
                    resolve(response.data.data)
                    dispatch('getRecord',response.data.data)
                }).catch((error) => {
       
                    reject(error)
                })
            })
        },
        async storeRefund({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store('sales/refund',payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async sapIntegration({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`sales/invoice-sap-integration/${payload.series}`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async sapCreditNoteIntegration({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`sales/credit-note-sap-integration/${payload.series}`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        sapDeliveryNoteIntegration({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
           
                    commit('setPostLoadingTrue')
                    ApiService.store(`sales/delivery-note-sap-integration/${payload.series}`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                
            })
        },
        async sapPaymentIntegration({ state, commit, dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`sales/payment-sap-integration/${payload.series}`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                        dispatch('getRecord',response.data.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async multipleIntegration({}, payload) {
            return await new Promise((resolve, reject) => {
                     ApiService.store(`sales/multiple-invoice-sap-integration/${payload.series}`,payload).then((response) => {
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
            })
        },

        async multiplePaymentIntegration({}, payload) {
            return await new Promise((resolve, reject) => {
                     ApiService.store(`sales/multiple-payment-sap-integration/${payload.series}`,payload).then((response) => {
                        resolve(response.data)
                    }).catch((error) => {
                        reject(error)
                    })
            })
        },

        async sapMultipleCreditNoteIntegration({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.store(`sales/credit-note-sap-integration/${payload.series}`,payload).then((response) => {

                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async sendDeliveryRequest({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.store(`delivery-send-request`,{
                    form : payload
                }).then((response) => {

                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async createPurchaseRequest({}, payload) {
            return await new Promise((resolve, reject) => {
                ApiService.store(`invoices/make-purchase-request`,payload).then((response) => {

                    resolve(response.data)
                }).catch((error) => {
                    reject(error)
                })
            })
        },
        
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = false
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {

            const form  = {items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    },
                    credit_note_items : []
                }

            state.formRecord = {...form,...payload}
        },
        setWarehouse: (state, payload) => { 
            state.Warehouse = payload
        },
        setDiscountForm: (state, payload) => {
            state.discountForm = payload
        },
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },
        setPaymentReference: (state, payload) => {
            state.payment_references = payload
        },
        setTblKey: (state, payload) => {
            state.tblKey = payload
        },
        setInvoicesData : (state,payload) => state.invoiceData.push(payload),
        setDeliveryNoteIntegrationData : (state,payload) => state.deliveryNoteIntegrationData = payload,
        setSuccessPayment : (state,payload) => state.successPayment = payload

    },
    getters: {

        deliveryNoteIntegrationData: state => state.deliveryNoteIntegrationData,
        loading: state => state.loading,
        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
        Warehouse: state => state.Warehouse,
        discountForm: state => state.discountForm,
        paymentForm: state => state.paymentForm,
        payment_references: state => state.payment_references,
        tblKey: state => state.tblKey,
        invoiceData: state => state.invoiceData,
        successPayment: state => state.successPayment,
    }
}


