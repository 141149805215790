import { ApiHttpService } from '../../services'

import router from '../../router'
export const InventoryTransferV2Module = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Doc Date',
                    field: 'doc_date',
                    sort: 'asc',
                    width : '150px'
                },
                {
                    label: 'Series',
                    field: 'series',
                    sort: 'asc',
                    clickable: true,
                    width : '250px'
                },
                {
                    label: 'Owner',
                    field: 'author',
                    sort: 'asc',
                }
            ],
            rows: [],
        },
        multipleSelection: [],
        newItemDataObject: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},
        requestData : {},


        initialFormData : {
            // series : "",
            // date : "",
            // approved_date : "",
            // status : "draft",
            // location : {
            //     id : null,
            //     name : null,
            // },
            // description: null,
            // attr : {
            // },
            items: [],
        },

        getLoading : false,
        getRecordLoading : false,
        putLoading : false,
        deleteLoading : false,

        formStatus : 'create',

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setDataRequest : (state, payload)=>{

            state.requestData = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setNewItemDataObject : (state, payload)=>{
            state.newItemDataObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setDeleteLoadingTrue(state) {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse(state) {
            state.deleteLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
            });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            // alert(loading)
            return await new Promise((resolve, reject) => {

                commit('setLoading', true)
                commit('setRows', [])
                commit('setFormStatusUpdate')
                const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                const configParams = {
                    req : "InventoryTransferV2Module/req",
                    loadingTrue : "InventoryTransferV2Module/loadingTrue",
                    addRequest : "InventoryTransferV2Module/addRequest",
                    cancelRequest : "InventoryTransferV2Module/cancelRequest",
                    clearOldRequest : "InventoryTransferV2Module/clearOldRequest"
                }

                ApiHttpService.get(endpoint, params, configParams).then((response) => {
                    commit('setRows', response.data.data)
                    commit('setPage',response.data.current_page)
                    commit('setTotal',response.data.total)
                    resolve(response.data.data)
                    commit('setLoading', false)
                }).catch(error => {
                    commit('setLoading', false)
                    reject(error)
                })

            })
        },


        async getRecord({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}

                    ApiHttpService.getRequest('inventory-transfer-v2/document/items/'+obj.id,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data.data})
                        commit('setDataRequest',{...response.data.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },


        async getItemByLocation({ state, commit},obj) {
            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)

                    const initialFormData = {...JSON.parse(JSON.stringify(state.initialFormData)),...obj}

                    ApiHttpService.getRequest('document/location/'+obj.id,{view : router.app._route.name}).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormData',{...initialFormData,...response.data.data})
                        commit('setDataRequest',{...response.data.data})
                        resolve(response.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },


        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))
                    const configParams = {
                        req : "InventoryTransferV2Module/req",
                        loadingTrue : "InventoryTransferV2Module/loadingTrue",
                        addRequest : "InventoryTransferV2Module/addRequest",
                        cancelRequest : "InventoryTransferV2Module/cancelRequest",
                        clearOldRequest : "InventoryTransferV2Module/clearOldRequest"
                    }
                    ApiHttpService.post('inventory-transfer/store',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        commit('setNewItemDataObject',response.data)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    const updatePayload = JSON.parse(JSON.stringify(payload))
                    ApiHttpService.update(`document/status/${updatePayload.id}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        postDocument({}, payload) {
            return new Promise((resolve, reject) => {
                ApiHttpService.update(`document/post/${payload.series}`,payload).then((response) => {
                    let responseData = {...response.data.data};
                    resolve(responseData)
                }).catch((error) => {
                    reject(error)
                })
            })
        },

        async cancelInventoryTransferRecord({ state, commit }, series) {
            return await new Promise((resolve, reject) => {
                const loading = state.deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    ApiHttpService.update(`inventory-transfer/cancel/${series}`,{series : series}).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialFormObject: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        deleteLoading: state => state.deleteLoading,
        getNewItemDataObject: state => state.newItemDataObject,
        requestData: state => state.requestData,
    }
}



