<template>
  <div class="update-payment-form-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observerPayment" @submit.prevent="onSubmitCancelForm('cancel')" v-slot="{passes}" >
       <ThatsCustomWindow ref="window" title="Payment Details" windowClass="collection height-auto" scrollClass='collection height-auto' :height="500" :width="850" @dialog-state="dialogState" :group="group">
            <div style="padding : 15px">
                <mdb-row class="mb-2">
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="isEnabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Customer Name" :placeholder="form.customer" vid="name" name="name" v-model="form.customer" size="sm" :disabled="true" />
                      <!-- <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Payment Description" v-model="form.mode" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMode" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabledField"></ThatsMultiSelect> -->
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Payment method" v-model="form.method" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabledField"></ThatsMultiSelect>
                    
                        <template v-if="$helpers.isSystemAdministrator()">
                      <ThatsMoney tabindex="4" :key="1"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated"  :disabled="disableAmount"></ThatsMoney>
                        </template>
                        <template v-else>
                      <ThatsMoney tabindex="4" :key="2"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated" :disabled="disableAmount"></ThatsMoney>
                        </template>

                    <ThatsTextarea tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Remarks" vid="description" name="description" v-model="form.description" size="sm" :disabled="false" :rules="requiredIfCancel"/>            
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Invoice No." vid="invoice" name="invoice" v-model="form.invoice.series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Receipt ID" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="form.attr.discount_remarks" size="sm" :disabled="disabledField"/>
                     <template v-if="form.method ==='credit-card'">
                            <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Machine ID" vid="mid" name="mid" v-model="form.attr.mid" size="sm" :disabled="true" v-only.number maxlength="6" :rules="{required : false}"/>
                            <ThatsMultiSelect :multiple="false" vid="payment-card-type" name="payment-card-type"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Card Type" v-model="form.attr.card_type" :reduce="label => label.code" :reduceLabel="'label'" :options="[{code : 'naps',label : 'NAPS'},{code : 'visa',label : 'VISA'},{code : 'master',label : 'MASTER'},{code : 'cb',label : 'CB'}]" :rules="{required : false}"  :filterable="false"  :clearable="false" :disabled="true" ></ThatsMultiSelect>
                            </template>


                            <template v-if="form.method ==='wire-transfer' || form.method ==='cheque'">
                              <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Bank Name" vid="payment-card-type" name="payment-card-type" v-model="form.attr.card_type" size="sm" :disabled="true"/>
                              <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Date" vid="mid" name="mid" v-model="form.attr.mid" size="sm" :disabled="true"/>
                               </template>

                            


                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" :label="paymentReferenceLabel" vid="payment_reference" name="payment_reference" v-model="form.attr.payment_reference" size="sm" :disabled="disabledField"/>            
                    <template v-if="form.method ==='cheque' && form.attr.realized == false">
                                <mdb-row class="mb-2">
                                  <mdb-col col="7" offset="5" class="pl-0">
                                    <mdb-alert color="warning"><p class="small m-0 p-0">Subject to cheque realization</p></mdb-alert>
                                  </mdb-col>
                                </mdb-row>
                          </template>
                  </mdb-col>    
                </mdb-row>
            </div>

           <template #winFooter>

                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <template v-if="form.canceled !== 'yes'">

                         <!--  <template v-if="form.hasOwnProperty('diff_days') && form.hasOwnProperty('diff_days') <= 1">
                          </template> -->

                     
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitCancelForm('cancel'))" :disabled="disabledBtn">Cancel Payment</mdb-btn>


                            <template v-if="form.method ==='cheque' && form.attr.realized == false && $helpers.isSystemAdministrator()">
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitCancelForm('realize'))" :disabled="disabledBtn">Realize Payment</mdb-btn>
                            </template>


                        </template>
                        <mdb-btn color="elegant" size="sm" @click="closeDialog" :disabled="disabledBtn">Close</mdb-btn>
                      </template>


             
           </template>
       </ThatsCustomWindow>
    </ValidationObserver>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs,mdbAlert} from "mdbvue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsMoney} from '../../components/thats/Thats'
import { MessageBox } from 'element-ui';
import unformat from 'accounting-js/lib/unformat.js'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    },
    payment_for : {
      type : String,
      default : 'sales-order'
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,


        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsCustomWindow,
        ThatsMoney,
        ThatsCustomMultiSelect, 
        mdbAlert
  },

    data : () => ({
      active : 0,
      disabled : false,
      disabledBtn : false,
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      formSavePrintLoading : false,
      formPayBalanceLoading : false,
      showError : false,
      paymentMethod : [],
      items : [],
      selected : [],
      customer : [],
      payment_transactions : [],
      transactions : {

        },
      payment_reference : [],
      isCanceled : '',
      dateDiffDays : 0
    }),

    created : function() {
        this.form = this.formVar();
        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.paymentMethod = settings.payment_method
    },


    methods: {
      ...mapActions('InvoiceModule',[
        'updatePayment',
      ]),
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      dialogState(state){
        if(!state){
          this.formSetter();
          return
        }
      },

      formSetter(){
        this.form = this.formVar()
              requestAnimationFrame(() => {
              this.$refs.observerPayment.reset();
        });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
      },

      formVar(){
        return {
            series : 0,
            id : null,
            date : "",
            mode : "full-payment",
            method : "cash",
            customer : "",
            attr : {
              cheque_details : null, 
              payment_reference : null,
              discount_remarks : null, 
            },
            description : "",
            canceled : "no",
            amount : 0,
            reference : null,
            transaction_id : null,
            invoice : {
              series : null,
              balance : 0,
              paid : 0,
              amount : 0,
            }
          }
      },

      onSubmitForm(){
           var self =this
            this.validated = true

       

            const isValid = this.$refs.observerPayment.validate();
            this.showError = false
            this.disabledBtn = true;

            console.log(isValid);

      },

      onSubmitCancelForm(action){
           var self =this
            
            this.isCanceled = 'required';
            this.$refs.observerPayment.validate().then((isValid) => {
              if(isValid){
                if(!self.$helpers.isSystemAdministrator() && !self.$helpers.isAccounting()){
                  if(self.dateDiffDays < 0){
                      MessageBox.alert(`Back dated payment cannot be cancelled.`, 'Warning', {})
                      return false
                  }
                }

                if(action == 'cancel'){
                  MessageBox.confirm(`Are you sure want to cancel payment?`, 'Warning', {
                   confirmButtonText: 'OK',
                   cancelButtonText: 'Cancel',
                   type: 'warning'
                   }).then(() => {
  
                    this.showError = false
                     this.disabledBtn = true;
                           self.formLoading = true

                             self.updatePayment({...this.form,action : 'cancel'}).then((response) => {

                              console.log("updatePayment")
                              console.log(self.form)
                              console.log(response)
                               self.$ThatsNotify.success({
                                     message : "Record was successfully updated"
                                   })
                                 self.$emit('success',self.form)
                                 self.formLoading = false
                                 self.disabledBtn = false
                                 self.closeDialog();
                             })
                   })
                }


                if(action == 'realize'){
                    MessageBox.confirm(`Are you sure want to realize payment?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      this.showError = false
                      this.disabledBtn = true;
                            self.formLoading = true

                              self.updatePayment({...this.form,action : 'realize'}).then((response) => {
                                console.log("updatePayment")
                              console.log(self.form)
                              console.log(response)
                                self.$ThatsNotify.success({
                                      message : "Record was successfully updated"
                                    })
                                  self.$emit('success',self.form)
                                  self.formLoading = false
                                  self.disabledBtn = false
                                  self.closeDialog();
                              })
                    })
                }

              }
            });
            
            

            /* if(isValid){
              this.validated = true
              if(self.formLoading === false){

                if(!this.$helpers.isSystemAdministrator() && !this.$helpers.isAccounting()){
                  if(this.dateDiffDays < 0){
                      MessageBox.alert(`Back dated payment cannot be cancelled.`, 'Warning', {})
                      return false
                  }
                }

                if(action == 'cancel'){
                  MessageBox.confirm(`Are you sure want to cancel payment?`, 'Warning', {
                   confirmButtonText: 'OK',
                   cancelButtonText: 'Cancel',
                   type: 'warning'
                   }).then(() => {
  
  
                     this.disabledBtn = true;
                           self.formLoading = true

                             self.updatePayment(this.form).then(() => {
                               self.$ThatsNotify.success({
                                     message : "Record was successfully updated"
                                   })
                                 self.$emit('success',self.form)
                                 self.formLoading = false
                                 self.disabledBtn = false
                                 self.closeDialog();
                             })
                   })
                }

                if(action == 'realize'){
                    MessageBox.confirm(`Are you sure want to realize payment?`, 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
    
    
                      this.disabledBtn = true;
                            self.formLoading = true

                              self.updatePayment(this.form).then(() => {
                                self.$ThatsNotify.success({
                                      message : "Record was successfully updated"
                                    })
                                  self.$emit('success',self.form)
                                  self.formLoading = false
                                  self.disabledBtn = false
                                  self.closeDialog();
                              })
                    })
                }

              }
            } */

      }

    },

    computed: {

      ...mapGetters('PaymentModule',
      [
        'paymentForm',
        'formStatus'
      ]),
      
      requiredIfCancel : function(){
          return (this.formStatus === 'update') ? 'required' : ''
      },

      disableAmount : function() {

//|| (this.$helpers.isSystemAdministrator() && this.formStatus === 'update')
        if((this.formStatus === 'create' && this.form.mode === 'full-payment') ){
                return false;
              }

        return true
          // return (this.form.mode === 'full-payment') ? false : true
        },  
        paymentMode : function() {
          const {settings} = JSON.parse(localStorage.getItem('user'));
          let mode = settings.payment_mode
            if(this.form.invoice.series != null && this.form.invoice.balance_float > 0){
               mode = settings.payment_mode.filter(item => {
				        return item.code !== 'full-payment'
            })

            }
          return mode;
        },

        disabledField : function(){
          //|| (this.$helpers.isSystemAdministrator() && this.formStatus === 'update')
              if((this.formStatus === 'create') ){
                return false;
              }
              return true;
        },

        paymentReferenceLabel : function(){
          if(this.form.method === 'credit-card'){
            return 'Authorization Code';
          }
          if(this.form.method === 'cheque'){
            return 'Cheque No.';
          }
           if(this.form.method === 'credit-note'){
            return 'CN No.';
          }

          if(this.form.method === 'wire-transfer'){
            return 'Transaction No.';
          }

          return 'Payment Ref. No.'
        },
        isEnabled : function(){
            if(this.$helpers.isAccounting() || this.$helpers.isSystemAdministrator()){
                if(this.form.method === 'cheque' && this.form.attr.realized == false){
                  return false;
                }
            }
            return true;
        },
      
    },

    watch: {
      paymentForm : function(v){
          this.form = v


          let date_1 = new Date(this.form.date);
          let date_2 = new Date();

          let difference = date_1.getTime() - date_2.getTime();
          this.dateDiffDays = Math.ceil(difference / (1000 * 3600 * 24));

          this.disabledBtn = false
      },

      formStatus : function(v){

      }
    },
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>