import { ApiHttpService } from '../../services'

import router from '../../router'
export const CustomerLogsModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    clickable: true,
                },
                {
                    label: 'Mobile',
                    field: 'mobile'
                },
                {
                    label: 'Whatsapp Number',
                    field: 'whatsapp_mobile'
                },
                {
                    label: 'Email',
                    field: 'email'
                },
                // {
                //     label: 'Brand',
                //     field: 'brand_names'
                // },
                // {
                //     label: 'Category',
                //     field: 'category_names'
                // },
                // {
                //     label: 'Comment',
                //     field: 'comments'
                // },
                {
                    label: 'Location',
                    field: 'location_name'
                },
                {
                    label: 'Sales Person',
                    field: 'sales_person_name',
                },
                {
                    label: 'Created At',
                    field: 'created_at',
                },
                {
                    label: 'Reminder Date',
                    field: 'date_reminder',
                },
                {
                    label: 'Reminder Time',
                    field: 'reminder_time',
                },
            ],
            rows: [],
        },
        endpoint : 'customer-logs/store',
        request: null,
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {},


        getLoading : false,
        getRecordLoading : false,
        putLoading : false,
        deleteLoading : false,
        formRecord : {},
        formStatus : 'create',

    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },

        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },

        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.form))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setDeleteLoadingTrue(state) {
            state.deleteLoading = true
        },
        setDeleteLoadingFalse(state) {
            state.deleteLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setCards: (state, payload) => {
            state.cards = payload
        },
        setFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setFormStatusView : (state) => state.formStatus = 'view',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){


            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log("reset data")
                    console.log(res)
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {
            const { length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {

                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters, view : router.app._route.name }

                    const configParams = {
                        req : "CustomerLogsModule/req",
                        loadingTrue : "CustomerLogsModule/loadingTrue",
                        addRequest : "CustomerLogsModule/addRequest",
                        cancelRequest : "CustomerLogsModule/cancelRequest",
                        clearOldRequest : "CustomerLogsModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        commit('setRows', response.data.data)
                        commit('setPage',response.data.current_page)
                        commit('setTotal',response.data.total)
                        resolve(response.data.data)
                        commit('setLoading', false)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })

            })
        },
        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = rootState.CustomerLogsModule;
                if (!loading) {
                    commit('setLoading', true)
                    ApiHttpService.store(`${endpoint}`,payload).then((response) => {
                        commit('setLoading', false)
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },



    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,


        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
        postLoading: state => state.postLoading,
        formRecord: state => state.formRecord,
        deleteLoading: state => state.deleteLoading
    }
}

