    import {ApiHttpService} from '../../services'
    import {Unauthorized} from '../../helpers/unauthorized.handler'
    import router from "../../router";
    export const TierBeneficiaryModule = {
        namespaced: true,
        state: {
            loading: false,
            Deleteloading: false,
            data : {
                columns: [
                    {
                        label: 'Name',
                        field: 'name',
                        sort: 'asc',
                        clickable: true,
                    },
                    {
                        label: 'Apply Type',
                        field: 'apply_type'
                    },
                    {
                        label: 'Benefit Type',
                        field: 'benefit_type'
                    },
                    {
                        label: 'Discount %',
                        field: 'discount'
                    },
                    {
                        label: 'Additional Discount %',
                        field: 'additional_discount'
                    },
                    {
                        label: 'No. of Gift Cards',
                        field: 'number_of_gift_card'
                    },
                    {
                        label: 'No. of Vouchers',
                        field: 'number_of_voucher'
                    },
                    {
                        label: 'Tier',
                        field: 'tier_names'
                    },
                    {
                        label: 'Status',
                        field: 'status',
                    },
                ],
                rows: [],
            },
            formStatus : 'create',
            endpoint : 'loyalty-program/tier-beneficiary',
            formRecord : {},
            length : 25,
            page : 0,
            total : 1,

        },
    actions : {




        // async checkToken({rootState}) {
        //     const { endpoint } = rootState.TierBeneficiaryModule
        //     return await new Promise((resolve,reject) => {
        //         ApiService.get(`${endpoint}/check-token`, {}).then((response) => {
        //             resolve(response.data)
        //         }).catch(error => {
        //             console.log("apiservice")
        //             console.log(error)
        //             reject(error);
        //         })
        //     })
        // },

        async asyncGetData({ rootState, commit }) {
            const { loading, length, page, filters, searchTerm, endpoint} = rootState.TierBeneficiaryModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    const configParams = {
                        req: "TierBeneficiaryModule/req",
                        loadingTrue: "TierBeneficiaryModule/loadingTrueQuotation",
                        addRequest: "TierBeneficiaryModule/addRequest",
                        cancelRequest: "TierBeneficiaryModule/cancelRequest",
                        clearOldRequest: "TierBeneficiaryModule/clearOldRequest"
                    };
                    ApiHttpService.get(`${endpoint}`, params,configParams).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        reject(error)
                        // Unauthorized(dispatch,error)
                    })
                }
            })
        },

        // async asyncGetData({ rootState, commit,dispatch }) {
        //     const { loading, length, page, filters, searchTerm, endpoint} = rootState.TierBeneficiaryModule
        //     return await new Promise((resolve) => {
        //         if (loading === false) {
        //             commit('LoadingTrue')
        //             const params = { length: length, page: page, term: searchTerm, filters: filters }
        //             ApiService.get(`${endpoint}`, params).then((response) => {
        //                 commit('SetPage', response.data.current_page)
        //                 commit('SetTotal', response.data.total)
        //                 commit('SetData', response.data.data)
        //                 commit('LoadingFalse')
        //                 resolve(response.data.data)
        //             }).catch(error => {
        //                 //reject(error)
        //                 Unauthorized(dispatch,error)
        //             })
        //         }
        //     })
        // },

        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = rootState.TierBeneficiaryModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiHttpService.store(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.TierBeneficiaryModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiHttpService.update(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },




    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        SetCode: (state, payload)=>{
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
    }
}


