<template>
    <mdbContainer fluid class="pt-2">
        <mdb-row>
            <mdb-col xl="12" md="12" class="mb-12">
                <div class="row">
                    <div class="col-md-12">
                        <mdb-btn @click="updateChart('1m')" class="custom-badge z-depth-0 cursor-pointer mr-0" pill color="primary" size="sm">1M</mdb-btn>
                        <mdb-btn @click="updateChart('6m')" class="custom-badge z-depth-0 cursor-pointer m-0" pill color="secondary" size="sm" >6M</mdb-btn>
                        <mdb-btn @click="updateChart('1y')" class="custom-badge z-depth-0 cursor-pointer m-0" pill color="success" size="sm" > 1Y</mdb-btn>
                    </div>
                    <div class="col-md-12">
                        <mdb-card>
                            <mdb-card-body>
                                <div style="height: 300px;">
                                    <LineGraph :key="1" :chart-data="lineChartData" :toggle="toggleLineChart" :height="250"></LineGraph>
                                </div>
                            </mdb-card-body>
                        </mdb-card>
                    </div>

                </div>
                <br/>

<!--                <mdbRow class="tab-row">-->
<!--                    <mdbCol md="4" lg="4" class="pr-0 mr-0 pl-0 ml-0">-->
<!--                        <mdb-card class="open-quotation-by-sales-person-card m-2 sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">BY BRANDS</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--&lt;!&ndash;                                <PieGraph :key="2" :chartData="pieBrandChartData" :height="400"></PieGraph>&ndash;&gt;-->
<!--                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieBrandChartData" :height="400"></PieGraph>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                    </mdbCol>-->
<!--                    <mdbCol md="4" lg="4" class="pr-0 mr-0">-->
<!--                        <mdb-card class="open-quotation-by-sales-person-card m-2 sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">BY CATEGORY</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--&lt;!&ndash;                                <PieGraph :key="3" :chartData="categoryChartData" :height="400"></PieGraph>&ndash;&gt;-->
<!--                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieCateogryChartData" :height="400"></PieGraph>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                    </mdbCol>-->
<!--                    <mdbCol md="4" lg="4" class="pr-0 mr-0">-->
<!--                        <mdb-card class="open-quotation-by-sales-person-card m-2 sales-report-card">-->
<!--                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">-->
<!--                                <h4 class="dark-grey-text mb-0 card-header-title">BY LOCATION</h4>-->
<!--                            </mdb-card-body>-->
<!--                            <mdb-card-body class="pt-0">-->
<!--                                <PieGraph :key="3" :chartData="locationChartData" :height="400"></PieGraph>-->
<!--                            </mdb-card-body>-->
<!--                        </mdb-card>-->
<!--                    </mdbCol>-->
<!--                </mdbRow>-->



            </mdb-col>
        </mdb-row>
        <mdbRow class="tab-row">
            <mdbCol md="4" lg="4" class="pr-0 mr-0 pl-0 ml-0">
                <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                        <h4 class="dark-grey-text mb-0 card-header-title">BY BRANDS</h4>
                    </mdb-card-body>
                    <mdb-card-body class="p-1">
                        <PieGraph :key="`brand${pieCtr}`" :chartData="pieBrandChartData" :height="400"></PieGraph>
                    </mdb-card-body>
                </mdb-card>
            </mdbCol>
            <mdbCol md="4" lg="4" class="pr-0 mr-0">
                <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                        <h4 class="dark-grey-text mb-0 card-header-title">BY CATEGORY</h4>
                    </mdb-card-body>
                    <mdb-card-body class="p-1">
                        <PieGraph :key="`brand${pieCtr}`" :chartData="categoryChartData" :height="400"></PieGraph>
                    </mdb-card-body>
                </mdb-card>
            </mdbCol>
            <mdbCol md="4" lg="4" class="pr-0 mr-0">
                <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                        <h4 class="dark-grey-text mb-0 card-header-title">BY LOCATION</h4>
                    </mdb-card-body>
                    <mdb-card-body class="p-1">
                        <PieGraph :key="`brand${pieCtr}`" :chartData="locationChartData" :height="400"></PieGraph>
                    </mdb-card-body>
                </mdb-card>
            </mdbCol>
        </mdbRow>

    </mdbContainer>
</template>

<script>
import {
    mdbBtn,
    mdbRow,
    mdbCol,
    mdbBadge,
    mdbFormInline,
    mdbNavbar,
    mdbNavbarBrand,
    mdbTabs,
    mdbDropdown,
    mdbDropdownItem,
    mdbDropdownMenu,
    mdbDropdownToggle,
    mdbCardBody,
    mdbCard,
    mdbCardText,
    mdbContainer
} from "mdbvue";
import { mapActions, mapMutations ,mapGetters  } from 'vuex'
import LineGraph from "./LineGraph.vue";
import PieGraph from "../../components/dashboard/PieGraph.vue";

export default {
    components : {
        mdbContainer,
        mdbCardText, mdbCard, mdbCardBody,
        LineGraph,
        PieGraph,
        mdbRow,
        mdbCol,
        mdbBtn,
        mdbBadge,
        mdbFormInline,
        mdbNavbar,
        mdbNavbarBrand,
        mdbTabs,
        mdbDropdown,
        mdbDropdownItem,
        mdbDropdownMenu,
        mdbDropdownToggle
    },
    props : {
        endpoint : {
            type : String,
            default : 'sales/invoice'
        },
        title : {
            type : String,
            default : 'Transactions'
        },
        formData: {
            type: Object,
            required: true
        },
        columns : {
            type : Array,
            default : () => {
                return [
                    {
                        label: 'Code',
                        field: 'transaction_code',
                    },
                    {
                        label: 'Date',
                        field: 'date',
                    },
                    {
                        label: 'Points',
                        field: 'amount',
                    },
                    {
                        label: 'Type',
                        field: 'type',
                    }
                ]
            }
        },
        refresh : {
            type : Boolean,
            default : false
        },
        isEditable: {
            type : Boolean,
            default : false
        },

    },
    data(){
        return {
            dialogVisibleSync : false,
            filterObj : {
                term : null
            },
            dialogIsOpen: false,
            contactItems: {
                invoiceTransactions: [], // Initialize with actual data
                refundTransactions: []  // Initialize with actual data
            },
            lineChartData: {
                labels: [],
                datasets: [
                    {
                        label: 'Invoice',
                        data: [],
                        borderColor: "#00c851",
                        fill: false,
                        tension: 0.3,
                        borderWidth: 2
                    },
                    {
                        label: 'Refund',
                        data: [],
                        borderColor: "#fb3",
                        fill: false,
                        tension: 0.3,
                        borderWidth: 2
                    }
                ]
            },
            toggleLineChart: false,
            pieBrandChartData: {},
            pieCtr : 0,
            categoryChartData: {},
            locationChartData: {},
            // contactItems:null

        }
    },
    mounted(){
        this.dispatchHandler();
        // this.renderChart();
        this.updateChart('1y');
        // this.renderBrandChart();
        // this.renderCategoryChart();
        // this.renderLocationChart();

        this.getCustomerItemRecord({ series: this.formData.series }).then(response => {
            this.contactItems = response;
            // this.renderChart();
            this.updateChart('1y');
            this.renderBrandChart('1y');
            this.renderCategoryChart('1y');
            this.renderLocationChart('1y');
        }).catch(error => {
            console.error('Error handling dialog click:', error);
        });


    },
    methods : {
        ...mapActions('TableV2Module',[
            'asyncGetData',
            'resetData',
        ]),
        ...mapMutations('TableV2Module',[
            'SetTotal',
            'SetLength',
            'SetPage',
            'SetTerm',
            'SetSelection',
            'SetEmptyData',
            'SetFilters',
        ]),
        ...mapActions('ContactsModule',['getCustomerItemRecord']),
        openDialog(){
            this.dialogVisibleSync = true
        },
        handleSaleTransactionClick(row){
            this.$emit('rowClick',row)
        },
        openNewWindow(data){
            this.$emit('dialogClick', data)
        },

        // updateChart(interval) {
        //     var startDate, endDate;
        //     var currentDate = new Date();
        //
        //     switch (interval) {
        //         case '1m':
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //         case '6m':
        //             startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         case '1y':
        //             startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         default:
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //     }
        //
        //     if (!this.contactItems) {
        //         console.error('contactItems is not available.');
        //         return;
        //     }
        //
        //     var filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
        //     var filteredRefundTransactions = this.filterTransactions(this.contactItems.refundTransactions, startDate, endDate);
        //
        //     this.renderChart(filteredInvoiceTransactions, filteredRefundTransactions, interval);
        //     this.renderBrandChart(interval);
        //     this.renderCategoryChart(interval);
        //     this.renderLocationChart(interval);
        // },
        //
        // filterTransactions(transactions, startDate, endDate) {
        //     return transactions.filter(data => {
        //         var transactionDate = new Date(data.date);
        //         return transactionDate >= startDate && transactionDate <= endDate;
        //     });
        // },
        //
        // renderChart(invoiceTransactions, refundTransactions, interval) {
        //     var ctx = document.getElementById('line-chart').getContext('2d');
        //
        //     // Destroy the existing Chart instance if it exists
        //     if (this.lineChart) {
        //         this.lineChart.destroy();
        //     }
        //
        //     var currentDate = new Date();
        //     var labels = [];
        //     var startDate = new Date();
        //     var startMonth, startYear;
        //
        //     switch (interval) {
        //         case '1m':
        //             // Last 30 days including today
        //             startDate.setDate(currentDate.getDate() - 29);
        //             for (var i = 0; i < 30; i++) {
        //                 var date = new Date(startDate);
        //                 date.setDate(startDate.getDate() + i);
        //                 labels.push(`${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}`);
        //             }
        //             break;
        //         case '6m':
        //             // Last 6 months including the current month
        //             startMonth = (currentDate.getMonth() - 5 + 12) % 12;
        //             startYear = currentDate.getMonth() < 5 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
        //             for (var i = 0; i < 6; i++) {
        //                 var month = (startMonth + i) % 12;
        //                 var year = startYear + Math.floor((startMonth + i) / 12);
        //                 labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
        //             }
        //             break;
        //         case '1y':
        //             // Last 12 months including the current month
        //             startMonth = currentDate.getMonth();
        //             startYear = currentDate.getFullYear() - 1;
        //             for (var i = 0; i < 12; i++) {
        //                 var month = (startMonth + i) % 12;
        //                 var year = startYear + Math.floor((startMonth + i) / 12);
        //                 labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
        //             }
        //             labels.push(`${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`);
        //             break;
        //         default:
        //             // Default to 1-Year interval
        //             startMonth = currentDate.getMonth();
        //             startYear = currentDate.getFullYear() - 1;
        //             for (var i = 0; i < 12; i++) {
        //                 var month = (startMonth + i) % 12;
        //                 var year = startYear + Math.floor((startMonth + i) / 12);
        //                 labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
        //             }
        //             labels.push(`${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`);
        //             break;
        //     }
        //
        //     // Initialize arrays to hold transaction amounts for each month/day
        //     var invoiceData = Array(labels.length).fill(0);
        //     var refundData = Array(labels.length).fill(0);
        //
        //     // Helper function to get the difference in days between two dates
        //     function getDaysDifference(date1, date2) {
        //         return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
        //     }
        //
        //     // Iterate over invoiceTransactions to extract transaction amounts and dates
        //     invoiceTransactions.forEach(data => {
        //         var transactionDate = new Date(data.date);
        //         var index;
        //
        //         if (interval === '1m') {
        //             var daysDifference = getDaysDifference(startDate, transactionDate);
        //             index = daysDifference + 1;
        //             if (index >= 0 && index < labels.length) {
        //                 invoiceData[index] += data.transaction_amount;
        //             }
        //         } else if (interval === '6m') {
        //             var monthDifference = (transactionDate.getFullYear() - startYear) * 12 + transactionDate.getMonth() - startMonth;
        //             index = monthDifference;
        //             if (index >= 0 && index < labels.length) {
        //                 invoiceData[index] += data.transaction_amount;
        //             }
        //         } else if (interval === '1y') {
        //             var yearDifference = transactionDate.getFullYear() - startYear;
        //             var monthDifference = transactionDate.getMonth() - startMonth;
        //             index = yearDifference * 12 + monthDifference;
        //             if (index >= 0 && index < labels.length) {
        //                 invoiceData[index] += data.transaction_amount;
        //             }
        //         }
        //     });
        //
        //     // Iterate over refundTransactions to extract transaction amounts and dates
        //     refundTransactions.forEach(data => {
        //         var transactionDate = new Date(data.date);
        //         var index;
        //
        //         if (interval === '1m') {
        //             var daysDifference = getDaysDifference(startDate, transactionDate);
        //             index = daysDifference + 1;
        //             if (index >= 0 && index < labels.length) {
        //                 refundData[index] += data.transaction_amount;
        //             }
        //         } else if (interval === '6m') {
        //             var monthDifference = (transactionDate.getFullYear() - startYear) * 12 + transactionDate.getMonth() - startMonth;
        //             index = monthDifference;
        //             if (index >= 0 && index < labels.length) {
        //                 refundData[index] += data.transaction_amount;
        //             }
        //         } else if (interval === '1y') {
        //             var yearDifference = transactionDate.getFullYear() - startYear;
        //             var monthDifference = transactionDate.getMonth() - startMonth;
        //             index = yearDifference * 12 + monthDifference;
        //             if (index >= 0 && index < labels.length) {
        //                 refundData[index] += data.transaction_amount;
        //             }
        //         }
        //     });
        //
        //     var data = {
        //         labels: labels,
        //         datasets: [{
        //             label: 'Invoice',
        //             data: invoiceData,
        //             borderColor: "#00c851",
        //             fill: false,
        //             tension : 0.3,
        //             borderWidth : 2
        //         },
        //             {
        //                 label: 'Refund',
        //                 data: refundData,
        //                 borderColor: "#fb3",
        //                 fill: false,
        //                 tension : 0.3,
        //                 borderWidth : 2
        //             }]
        //     };
        //
        //     this.lineChart = new Chart(ctx, {
        //         type: 'line',
        //         data: data,
        //         options: {
        //             responsive: true,
        //             maintainAspectRatio: false,
        //
        //             scales: {
        //                 yAxes: [{
        //                     ticks: {
        //                         beginAtZero: true,
        //                         callback: function(value, index, values) {
        //                             return value.toLocaleString();
        //                         }
        //                     }
        //                 }]
        //             }
        //         }
        //     });
        // },

        updateChart(interval) {
            let startDate, endDate;
            const currentDate = new Date();

            switch (interval) {
                case '1m':
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
                case '6m':
                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                case '1y':
                    startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                default:
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
            }

            if (!this.contactItems) {
                console.error('contactItems is not available.');
                return;
            }

            const filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
            const filteredRefundTransactions = this.filterTransactions(this.contactItems.refundTransactions, startDate, endDate);

            this.renderChart(filteredInvoiceTransactions, filteredRefundTransactions, interval);
            this.renderBrandChart(interval);
            this.renderCategoryChart(interval);
            this.renderLocationChart(interval);
        },
        filterTransactions(transactions, startDate, endDate) {
            return transactions.filter(data => {
                const transactionDate = new Date(data.date);
                return transactionDate >= startDate && transactionDate <= endDate;
            });
        },
        renderChart(invoiceTransactions, refundTransactions, interval) {
            const labels = [];
            const currentDate = new Date();
            let startDate = new Date();
            let startMonth, startYear;

            switch (interval) {
                case '1m':
                    startDate.setDate(currentDate.getDate() - 29);
                    for (let i = 0; i < 30; i++) {
                        const date = new Date(startDate);
                        date.setDate(startDate.getDate() + i);
                        labels.push(`${date.getDate()}-${date.toLocaleString('default', { month: 'short' })}`);
                    }
                    break;
                case '6m':
                    startMonth = (currentDate.getMonth() - 5 + 12) % 12;
                    startYear = currentDate.getMonth() < 5 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
                    for (let i = 0; i < 6; i++) {
                        const month = (startMonth + i) % 12;
                        const year = startYear + Math.floor((startMonth + i) / 12);
                        labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
                    }
                    break;
                case '1y':
                    startMonth = currentDate.getMonth();
                    startYear = currentDate.getFullYear() - 1;
                    for (let i = 0; i < 12; i++) {
                        const month = (startMonth + i) % 12;
                        const year = startYear + Math.floor((startMonth + i) / 12);
                        labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
                    }
                    labels.push(`${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`);
                    break;
                default:
                    startMonth = currentDate.getMonth();
                    startYear = currentDate.getFullYear() - 1;
                    for (let i = 0; i < 12; i++) {
                        const month = (startMonth + i) % 12;
                        const year = startYear + Math.floor((startMonth + i) / 12);
                        labels.push(`${new Date(year, month).toLocaleString('default', { month: 'short' })}-${year}`);
                    }
                    labels.push(`${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`);
                    break;
            }

            const invoiceData = Array(labels.length).fill(0);
            const refundData = Array(labels.length).fill(0);

            const getDaysDifference = (date1, date2) => Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));

            invoiceTransactions.forEach(data => {
                const transactionDate = new Date(data.date);
                let index;

                if (interval === '1m') {
                    const daysDifference = getDaysDifference(startDate, transactionDate);
                    index = daysDifference + 1;
                    if (index >= 0 && index < labels.length) {
                        invoiceData[index] += data.transaction_amount;
                    }
                } else if (interval === '6m') {
                    const monthDifference = (transactionDate.getFullYear() - startYear) * 12 + transactionDate.getMonth() - startMonth;
                    index = monthDifference;
                    if (index >= 0 && index < labels.length) {
                        invoiceData[index] += data.transaction_amount;
                    }
                } else if (interval === '1y') {
                    const yearDifference = transactionDate.getFullYear() - startYear;
                    const monthDifference = transactionDate.getMonth() - startMonth;
                    index = yearDifference * 12 + monthDifference;
                    if (index >= 0 && index < labels.length) {
                        invoiceData[index] += data.transaction_amount;
                    }
                }
            });

            refundTransactions.forEach(data => {
                const transactionDate = new Date(data.date);
                let index;

                if (interval === '1m') {
                    const daysDifference = getDaysDifference(startDate, transactionDate);
                    index = daysDifference + 1;
                    if (index >= 0 && index < labels.length) {
                        refundData[index] += data.transaction_amount;
                    }
                } else if (interval === '6m') {
                    const monthDifference = (transactionDate.getFullYear() - startYear) * 12 + transactionDate.getMonth() - startMonth;
                    index = monthDifference;
                    if (index >= 0 && index < labels.length) {
                        refundData[index] += data.transaction_amount;
                    }
                } else if (interval === '1y') {
                    const yearDifference = transactionDate.getFullYear() - startYear;
                    const monthDifference = transactionDate.getMonth() - startMonth;
                    index = yearDifference * 12 + monthDifference;
                    if (index >= 0 && index < labels.length) {
                        refundData[index] += data.transaction_amount;
                    }
                }
            });

            this.lineChartData = {
                labels,
                datasets: [
                    {
                        label: 'Invoice',
                        data: invoiceData,
                        borderColor: "#00c851",
                        fill: false,
                        tension: 0.3,
                        borderWidth: 2
                    },
                    {
                        label: 'Refund',
                        data: refundData,
                        borderColor: "#fb3",
                        fill: false,
                        tension: 0.3,
                        borderWidth: 2
                    }
                ]
            };

            // this.toggleLineChart = !this.toggleLineChart;
        },



































// Old Code with count
        // renderBrandChart(interval) {
        //     var ctx = document.getElementById('brand-chart').getContext('2d');
        //
        //     if (!this.contactItems || !this.contactItems.invoiceTransactions) {
        //         console.error('contactItems or invoiceTransactions are not available.');
        //         return;
        //     }
        //
        //     console.log(interval,"interval interval interval")
        //     // Filter invoice transactions based on the selected interval
        //     var startDate, endDate;
        //     var currentDate = new Date();
        //
        //     switch (interval) {
        //         case '1m':
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //         case '6m':
        //             startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         case '1y':
        //             startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         default:
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //     }
        //
        //     var filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
        //
        //     // Calculate brand counts for the filtered transactions
        //     var brandCounts = {};
        //
        //     filteredInvoiceTransactions.forEach(function(item) {
        //         var brand = item.brand_name;
        //         brandCounts[brand] = (brandCounts[brand] || 0) + 1;
        //     });
        //
        //     // Update the pie chart data
        //     var brands = Object.keys(brandCounts);
        //     var purchaseCounts = brands.map(function(brand) {
        //         return brandCounts[brand];
        //     });
        //
        //     var backgroundColors = ['#568736', '#68a242', '#90bb7a', '#bed5b4'];
        //
        //     var brandData = {
        //         labels: brands.length > 0 ? brands : ['No Data'],
        //         datasets: [{
        //             data: purchaseCounts.length > 0 ? purchaseCounts : [1],
        //             backgroundColor: brands.length > 0 ? backgroundColors.slice(0, brands.length) : ['#cccccc'] // If no data, use gray color
        //         }]
        //     };
        //
        //     // Destroy the existing chart instance if it exists
        //     if (this.brandChart) {
        //         this.brandChart.destroy();
        //     }
        //
        //     // Render the pie chart with updated data
        //     this.brandChart = new Chart(ctx, {
        //         type: 'pie',
        //         data: brandData
        //     });
        // },

// Brand with qty without refund
//         renderBrandChart(interval) {
//             var ctx = document.getElementById('brand-chart').getContext('2d');
//
//             if (!this.contactItems || !this.contactItems.invoiceTransactions) {
//                 console.error('contactItems or invoiceTransactions are not available.');
//                 return;
//             }
//
//             console.log(interval, "interval interval interval");
//             // Filter invoice transactions based on the selected interval
//             var startDate, endDate;
//             var currentDate = new Date();
//
//             switch (interval) {
//                 case '1m':
//                     startDate = new Date(currentDate);
//                     startDate.setDate(currentDate.getDate() - 29);
//                     endDate = currentDate;
//                     break;
//                 case '6m':
//                     startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
//                     endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
//                     break;
//                 case '1y':
//                     startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
//                     endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
//                     break;
//                 default:
//                     startDate = new Date(currentDate);
//                     startDate.setDate(currentDate.getDate() - 29);
//                     endDate = currentDate;
//                     break;
//             }
//
//             var filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
//
//             // Calculate brand quantities for the filtered transactions
//             var brandCounts = {};
//
//             filteredInvoiceTransactions.forEach(function(item) {
//                 var brand = item.brand_name;
//                 var quantity = item.transaction_item_qty || 0; // Ensure there's a default value if quantity is missing
//                 brandCounts[brand] = (brandCounts[brand] || 0) + quantity;
//             });
//
//             // Update the pie chart data
//             var brands = Object.keys(brandCounts);
//             var purchaseCounts = brands.map(function(brand) {
//                 return brandCounts[brand];
//             });
//
//             var backgroundColors = ['#568736', '#68a242', '#90bb7a', '#bed5b4'];
//
//             var brandData = {
//                 labels: brands.length > 0 ? brands : ['No Data'],
//                 datasets: [{
//                     data: purchaseCounts.length > 0 ? purchaseCounts : [1],
//                     backgroundColor: brands.length > 0 ? backgroundColors.slice(0, brands.length) : ['#cccccc'] // If no data, use gray color
//                 }]
//             };
//
//             // Destroy the existing chart instance if it exists
//             if (this.brandChart) {
//                 this.brandChart.destroy();
//             }
//
//             // Render the pie chart with updated data
//             this.brandChart = new Chart(ctx, {
//                 type: 'pie',
//                 data: brandData
//             });
//         },


        // renderBrandChart(interval) {
        //     var ctx = document.getElementById('brand-chart').getContext('2d');
        //
        //     if (!this.contactItems || !this.contactItems.invoiceTransactions) {
        //         console.error('contactItems or invoiceTransactions are not available.');
        //         return;
        //     }
        //
        //     console.log(interval, "interval interval interval");
        //     // Filter invoice transactions based on the selected interval
        //     var startDate, endDate;
        //     var currentDate = new Date();
        //
        //     switch (interval) {
        //         case '1m':
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //         case '6m':
        //             startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         case '1y':
        //             startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         default:
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //     }
        //
        //     var filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
        //
        //     // Calculate brand amounts for the filtered transactions
        //     var brandCounts = {};
        //
        //     filteredInvoiceTransactions.forEach(function(transaction) {
        //         transaction.items.forEach(function(item) {
        //             var brand = item.brand_name;
        //             var amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
        //             brandCounts[brand] = (brandCounts[brand] || 0) + amount;
        //         });
        //     });
        //
        //     // Deduct amounts for refund transactions within the selected interval
        //     if (this.contactItems.refundTransactions) {
        //         var filteredRefundTransactions = this.filterTransactions(this.contactItems.refundTransactions, startDate, endDate);
        //
        //         filteredRefundTransactions.forEach(function(transaction) {
        //             transaction.items.forEach(function(refundItem) {
        //                 var brand = refundItem.brand_name;
        //                 var refundAmount = refundItem.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
        //                 if (brandCounts[brand]) {
        //                     brandCounts[brand] -= refundAmount;
        //                     if (brandCounts[brand] < 0) brandCounts[brand] = 0; // Ensure no negative amounts
        //                 }
        //             });
        //         });
        //     }
        //
        //     // Update the pie chart data
        //     var brands = Object.keys(brandCounts);
        //     var purchaseAmounts = brands.map(function(brand) {
        //         return brandCounts[brand];
        //     });
        //
        //     var backgroundColors = ['#568736', '#68a242', '#90bb7a', '#bed5b4'];
        //
        //     var brandData = {
        //         labels: brands.length > 0 ? brands : ['No Data'],
        //         datasets: [{
        //             data: purchaseAmounts.length > 0 ? purchaseAmounts : [1],
        //             backgroundColor: brands.length > 0 ? backgroundColors.slice(0, brands.length) : ['#cccccc'] // If no data, use gray color
        //         }]
        //     };
        //
        //     // Destroy the existing chart instance if it exists
        //     if (this.brandChart) {
        //         this.brandChart.destroy();
        //     }
        //
        //     // Render the pie chart with updated data
        //     this.brandChart = new Chart(ctx, {
        //         type: 'pie',
        //         data: brandData
        //     });
        // },


        renderBrandChart(interval) {
            if (!this.contactItems || !this.contactItems.invoiceTransactions) {
                console.error('contactItems or invoiceTransactions are not available.');
                return;
            }

            // Filter invoice transactions based on the selected interval
            let startDate, endDate;
            const currentDate = new Date();

            switch (interval) {
                case '1m':
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
                case '6m':
                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                case '1y':
                    startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                default:
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
            }

            const filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);

            // Calculate brand amounts for the filtered transactions
            const brandCounts = {};

            filteredInvoiceTransactions.forEach(transaction => {
                transaction.items.forEach(item => {
                    const brand = item.brand_name;
                    const amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                    brandCounts[brand] = (brandCounts[brand] || 0) + amount;
                });
            });

            // Deduct amounts for refund transactions within the selected interval
            if (this.contactItems.refundTransactions) {
                const filteredRefundTransactions = this.filterTransactions(this.contactItems.refundTransactions, startDate, endDate);

                filteredRefundTransactions.forEach(transaction => {
                    transaction.items.forEach(refundItem => {
                        const brand = refundItem.brand_name;
                        const refundAmount = refundItem.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                        if (brandCounts[brand]) {
                            brandCounts[brand] -= refundAmount;
                            if (brandCounts[brand] < 0) brandCounts[brand] = 0; // Ensure no negative amounts
                        }
                    });
                });
            }

            // Sort brands by amount and select the top 10
            const sortedBrands = Object.keys(brandCounts).sort((a, b) => brandCounts[b] - brandCounts[a]).slice(0, 10);
            const purchaseAmounts = sortedBrands.map(brand => brandCounts[brand]);

            const backgroundColors = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];

            const brandData = {
                labels: sortedBrands.length > 0 ? sortedBrands : ['No Data'],
                datasets: [{
                    data: purchaseAmounts.length > 0 ? purchaseAmounts : [1],
                    backgroundColor: sortedBrands.length > 0 ? backgroundColors.slice(0, sortedBrands.length) : ['#cccccc'] // If no data, use gray color
                }]
            };

            // Assign brandData to pieBrandChartData
            this.pieBrandChartData = brandData;
        },





        // renderCategoryChart(interval) {
        //     var ctx = document.getElementById('category-chart').getContext('2d');
        //
        //     if (!this.contactItems || !this.contactItems.invoiceTransactions) {
        //         console.error('contactItems or invoiceTransactions are not available.');
        //         return;
        //     }
        //
        //     // Filter invoice transactions based on the selected interval
        //     var startDate, endDate;
        //     var currentDate = new Date();
        //
        //     switch (interval) {
        //         case '1m':
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //         case '6m':
        //             startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         case '1y':
        //             startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
        //             endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        //             break;
        //         default:
        //             startDate = new Date(currentDate);
        //             startDate.setDate(currentDate.getDate() - 29);
        //             endDate = currentDate;
        //             break;
        //     }
        //
        //     var filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);
        //
        //     // Calculate category counts for the filtered transactions
        //     var categoryCounts = {};
        //
        //     filteredInvoiceTransactions.forEach(function(item) {
        //         var category = item.category_name;
        //         // categoryCounts[category] = (categoryCounts[category] || 0) + 1;
        //         var quantity = item.transaction_item_qty || 0; // Ensure there's a default value if quantity is missing
        //         categoryCounts[category] = (categoryCounts[category] || 0) + quantity;
        //     });
        //
        //     // Update the pie chart data
        //     var categories = Object.keys(categoryCounts);
        //     var itemCounts = categories.map(function(category) {
        //         return categoryCounts[category];
        //     });
        //
        //     var backgroundColors = ['#e26700', '#de752d', '#f09971', '#f5c2b1'];
        //
        //     var categoryData = {
        //         labels: categories.length > 0 ? categories : ['No Data'],
        //         datasets: [{
        //             data: itemCounts.length > 0 ? itemCounts : [1],
        //             backgroundColor: categories.length > 0 ? backgroundColors.slice(0, categories.length) : ['#cccccc']
        //         }]
        //     };
        //
        //     // Destroy the existing chart instance if it exists
        //     if (this.categoryChart) {
        //         this.categoryChart.destroy();
        //     }
        //
        //     // Render the pie chart with updated data
        //     this.categoryChart = new Chart(ctx, {
        //         type: 'pie',
        //         data: categoryData
        //     });
        // },


        renderCategoryChart(interval) {
            // var ctx = document.getElementById('category-chart').getContext('2d');

            if (!this.contactItems || !this.contactItems.invoiceTransactions) {
                console.error('contactItems or invoiceTransactions are not available.');
                return;
            }

            console.log(interval, "interval interval interval");
            // Filter invoice transactions based on the selected interval
            let startDate, endDate;
            const currentDate = new Date();

            switch (interval) {
                case '1m':
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
                case '6m':
                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                case '1y':
                    startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                default:
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
            }

            const filteredInvoiceTransactions = this.filterTransactions(this.contactItems.invoiceTransactions, startDate, endDate);

            // Calculate category amounts for the filtered transactions
            const categoryCounts = {};

            filteredInvoiceTransactions.forEach(transaction => {
                transaction.items.forEach(item => {
                    const category = item.category_name;
                    const amount = item.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                    categoryCounts[category] = (categoryCounts[category] || 0) + amount;
                });
            });

            // Deduct amounts for refund transactions within the selected interval
            if (this.contactItems.refundTransactions) {
                const filteredRefundTransactions = this.filterTransactions(this.contactItems.refundTransactions, startDate, endDate);

                filteredRefundTransactions.forEach(transaction => {
                    transaction.items.forEach(refundItem => {
                        const category = refundItem.category_name;
                        const refundAmount = refundItem.transaction_item_amount || 0; // Ensure there's a default value if amount is missing
                        if (categoryCounts[category]) {
                            categoryCounts[category] -= refundAmount;
                            if (categoryCounts[category] < 0) categoryCounts[category] = 0; // Ensure no negative amounts
                        }
                    });
                });
            }

            // Sort categories by amount and select the top 10
            const sortedCategories = Object.keys(categoryCounts).sort((a, b) => categoryCounts[b] - categoryCounts[a]).slice(0, 10);
            const itemAmounts = sortedCategories.map(category => categoryCounts[category]);

            const backgroundColors = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];

            const categoryData = {
                labels: sortedCategories.length > 0 ? sortedCategories : ['No Data'],
                datasets: [{
                    data: itemAmounts.length > 0 ? itemAmounts : [1],
                    backgroundColor: sortedCategories.length > 0 ? backgroundColors.slice(0, sortedCategories.length) : ['#cccccc']
                }]
            };

            // Destroy the existing chart instance if it exists
            if (this.categoryChart) {
                this.categoryChart.destroy();
            }

            // Render the pie chart with updated data
            // this.categoryChart = new Chart(ctx, {
            //     type: 'pie',
            //     data: categoryData
            // });
            this.categoryChartData = categoryData;
        },





        renderLocationChart(interval) {
            // var ctx = document.getElementById('location-chart').getContext('2d');

            if (this.locationChart) {
                this.locationChart.destroy();
            }
            if (!this.contactItems || !this.contactItems.invoiceTransactions) {
                console.error('contactItems or invoiceTransactions are not available.');
                return;
            }

            // Filter invoice transactions based on the selected interval
            var startDate, endDate;
            var currentDate = new Date();

            switch (interval) {
                case '1m':
                    startDate = new Date(currentDate);
                    startDate.setDate(currentDate.getDate() - 29);
                    endDate = currentDate;
                    break;
                case '6m':
                    startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 5, 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                case '1y':
                    startDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), 1);
                    endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                    break;
                default:
                    console.error('Invalid interval provided.');
                    return;
            }

            var colorMapping = {
                'TLDFC': '#4A52E8',
                'TLTM': '#D68A8A',
                'CATM': '#000000',
                'EZTM': '#4A52E8',
                'TADFC': '#800080',
                'TATM': '#4A52E8',
                'BHDFC': '#800020',
                'BHTM': '#4A52E8',
                'MPDFC': '#4A52E8',
                'ECDFC': '#FF6B6B',
                'ECTM': '#E6E6FA',
                'AMDFC': '#CD7F32',
                'AMTM': '#228B22',
                'TLPE': '#DAA520',
                'TLQA': '#1E90FF',
                'TLKD': '#FFD700',
                'AHTM': '#36454F',
                'TLB2B': '#4A52E8',
                'TLID': '#008080',
                'AHDFC': '#DC143C',
                'TLWEB': '#808080',
                'BMDFC': '#D2691E',
                'TCPL': '#4B0082',
                'RLDFC': '#F5F5F5',
                'ELDFC': '#4A52E8',
                'CADFC': '#9932CC'
            };

            var locationAmounts = {};

            // Filter and process transactions based on the selected interval
            this.contactItems.invoiceTransactions.forEach(function(item) {
                var transactionDate = new Date(item.date);
                if (transactionDate >= startDate && transactionDate <= endDate) {
                    var location = item.location_prefix;
                    var amount = item.transaction_amount;
                    locationAmounts[location] = (locationAmounts[location] || 0) + amount;
                }
            });

            // Deduct transaction amounts for refund transactions within the selected interval
            if (this.contactItems.refundTransactions) {
                this.contactItems.refundTransactions.forEach(function(refundItem) {
                    var transactionDate = new Date(refundItem.date);
                    if (transactionDate >= startDate && transactionDate <= endDate) {
                        var refundLocation = refundItem.location_prefix;
                        if (locationAmounts[refundLocation]) {
                            locationAmounts[refundLocation] -= refundItem.transaction_amount;
                        }
                    }
                });
            }

            var locations = Object.keys(locationAmounts);
            var amounts = locations.map(function(location) {
                return locationAmounts[location];
            });

            var locationData = {
                labels: locations.length > 0 ? locations : ['No Data'],
                datasets: [{
                    data: amounts.length > 0 ? amounts : [1],
                    backgroundColor: locations.length > 0 ? locations.map(function(location) {
                        return colorMapping[location] || '#cccccc';
                    }) : ['#cccccc']
                }]
            };

            // this.locationChart = new Chart(ctx, {
            //     type: 'pie',
            //     data: locationData,
            // });
            this.locationChartData = locationData
        },




        triggerGetTransaction(obj){
            const self = this;
            this.SetTotal(0)
            this.filterObj = {item_code : obj.series}
            this.SetFilters(this.filterObj)
            this.resetData(this.endpoint).then(() => {
            });
        },
        async dispatchHandler(){
            this.SetFilters(this.filterObj)
            await this.asyncGetData(this.endpoint);
        },
        handleSizeChange(val) {
            this.SetLength(val)

            this.dispatchHandler()
        },
        handleCurrentChange(val) {
            this.dispatchHandler()
        },
        handleSearchTerm() {
            this.SetTotal(0);
            this.dispatchHandler()
        },
        handleSelectionChange(val){
            this.SetSelection(val)
        },
        refreshTable(){
            this.SetFilters(this.filterObj)
            this.resetData(this.endpoint);
        },
        dispatchFilter(filters){
            this.SetTotal(0)
            this.SetFilters(filters)
            this.resetData(this.endpoint);
        },
        triggerNewEvent(){
        },
    },
    computed: {
        ...mapGetters('TableV2Module',[
            'data',
            'page',
            'total',
            'length',
            'searchTerm',
            'tableLoading',
            'Selection',
            'viewTransactionLoading',
        ]),
        ...mapGetters('ContactsModule',
            [
                'FormStatus',
                'FormRecord',
                'Loading'
            ]),
        term : {
            get : function(){
                return this.searchTerm
            },
            set : function(val){
                this.SetTerm (val)
            }
        },
        current_page : {
            get : function(){
                return this.page
            },
            set : function(val){
                this.SetPage (val)
            }
        },
        hasFilter(){
            return !!this.$scopes.filter;
        },
        hasFilterSlot() {
            console.log(this.$scopedSlots)
            // console.log(('filter' in this.$scopedSlots))
            return ('filter' in this.$scopedSlots);
        },
        hasTabsSlot() {
            return ('tabs' in this.$scopedSlots);
        },
        hasRightSlot() {
            return ('top-right' in this.$scopedSlots);
        },
        hasCustomColumnsSlot() {
            return ('custom-culumns' in this.$scopedSlots);
        },
        statusArrayColor : function(){

            return {'draft' : 'badge-light','for-approval' : 'warning','S' : 'warning','P' : 'warning','R' : 'success','open': 'success','canceled' : 'danger','closed' : 'danger'};
        },
        statusArrayIcon : function(){

            return {'draft' : 'el-icon-more','S' : 'el-icon-ship','P' : 'el-icon-circle-check','open': 'el-icon-success','R': 'el-icon-success','canceled' : 'el-icon-remove','closed' : 'el-icon-remove'};
        },
        documentStatus : function(){
            return {'S' : 'In Shipment','P' : 'In Port','R' :'Received' }
        }
    },
}
</script>
<style>
.recent-orders {
    margin-top: 20px;
}

.order-item {
    margin-bottom: 10px;
}

.order-id {
    font-weight: bold;
}

.order-actions {
    float: right;
    margin-top: 25px;
}

.action-view {
    color: blue;
    cursor: pointer;
}

.order-divider {
    border-top: 1px solid #ccc;
    margin: 10px 0;
}

</style>

<style scoped lang="scss">
.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}
</style>
<style scoped lang="scss">

.sales-report-card.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}


.tab-content{
    height:auto!important;
}

</style>
