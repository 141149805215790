
import store from '../store'
export default function print({ next, router }) {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user === null) {
        return router.push({ name: 'login' });
    }

    store.commit('AppModule/setLayout', 'print');
    return next();
}