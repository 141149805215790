<template>
<div class="address-form-container">
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Delivery Address" windowClass="Contacts height-auto" scrollClass='Contacts height-auto' :width="650" :formStatus="'update'" @dialog-state="dialogState" :group="group">
         <template #left-button>
            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Apply</mdb-btn>
         </template>
         
         <mdbContainer class="mt-1">

        

          <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

                <div class="card card-fieldset">
                  <div class="card-body">
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="formDataProps.attr.address" :validated="validated" :rules="''" size="sm" />

                   <mdb-row >
                      <mdb-col offset="3" col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Bldg" vid="bldg" name="bldg" v-model="formDataProps.attr.address2.building" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                      <mdb-col col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Street" vid="street" name="street" v-model="formDataProps.attr.address2.street" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                      <mdb-col col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Zone" vid="zone" name="zone" v-model="formDataProps.attr.address2.zone" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                   </mdb-row>
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Area" vid="area" name="area" v-model="formDataProps.attr.address2.area" :validated="validated" :rules="'required'" size="sm" />
                  <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="City" v-model="formDataProps.attr.address2.city" :reduce="label => label.code" :reduceLabel="'label'" :options="cities" :rules="'required'"></ThatsMultiSelect>

                 </div>
                </div>

         
                
              </template>

             
          </mdb-tabs>
          
          </mdbContainer>

        
       </ThatsWindow>
  </ValidationObserver>
</div>
</template>
<script>
import { mdbBtn,mdbContainer,mdbIcon,mdbBadge, mdbRow,mdbCol, mdbTabs} from "mdbvue";
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage} from '../../components/thats/Thats'

import InternationalMobile from '../../components/thats/InternationalMobile.vue'

import { MessageBox } from 'element-ui'
export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ValidationProvider,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        mdbBtn,
        mdbRow,
        mdbCol,
        InternationalMobile  ,
        mdbTabs,
        mdbIcon,
        mdbBadge,
        
  },

  props : {
      group : {
            type : Number,
            default : 1
        },
        formDataProps : {
            type : Object,
            default : () => ({
              series : 0,
              name : null,
              mobile : null,
              reference : '',
              description : null,
              image : [],
              attr : {

                address: null,
                address2 : {
                    street : null,
                    building : null,
                    zone : null,
                    area : null,
                    city : null,
                },
                email : null,
                origin : 'portal',
                first_name : null,
                middle_name : null,
                last_name : null,
                salutation : null,
                corporate : false,
                contacts : [
                    {
                        type : 'primary',
                        intl : false,
                        code : '+974',
                        value : null
                    },
                    {
                        type : 'secondary',
                        intl : false,
                        code : '+974',
                        value : null
                    },
                    {
                        type : 'home',
                        intl : false,
                        code : '+974',
                        value : null
                    }
                ],
                contact_persons : []
              },
              status : 'active'
            })
        }
    },
    
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,


      status : [
            { status : "Active", code : "active"},
            { status : "Inactive", code : "inactive"}, 
          ],

      salutation : [
            { label : "Mr.", code : "Mr."},
            { label : "Ms.", code : "Ms."}
          ],

      cities : [
        {label :"Doha",code : "Doha"},
        {label :"Al-Rayyan",code : "Al-Rayyan"},
        {label :"Al Khor",code : "Al Khor"},
        {label :"Al Wakrah",code : "Al Wakrah"},
        {label :"Umm-Salal",code : "Umm-Salal"},
        {label :"Al-Dayian",code : "Al-Dayian"},
        {label :"Ash-Shamal",code : "Ash-Shamal"}
      ],
      tabs : [
                { text: 'Address Details', slot: 'form-slot' },
              ],

      active : 0,
      corportKey : 0,

     
    }),

    created : function() {

      this.form = this.formVar();
    },
    methods: {


      formVar(){
        return {
          attr : {

            address: null,
            address2 : {
                street : null,
                building : null,
                zone : null,
                area : null,
                city : null,
            },
            email : null,
            origin : 'portal',
            first_name : null,
            middle_name : null,
            last_name : null,
            salutation : null,
            corporate : false,
            contacts : [
                {
                    type : 'primary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'secondary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'home',
                    intl : false,
                    code : '+974',
                    value : null
                }
            ],
            contact_persons : []
          },
          status : 'active'
        }
      },
/* start */


      
      uploadImage(){
        this.$refs.upload.openDialog();
      },

      nextCode(){
        var self = this;

      },

      demo(v){
        console.log(v)
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        console.log(v)
        this.form.image = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
    
            if (isValid) {
                this.$emit('update',self.formDataProps);
                self.closeDialog();
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }
      },

      getActiveTabIndex(index){
        this.active = index
      },


     

    },

    computed: {


    },

    watch: {
    


    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>