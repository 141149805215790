<template>
    <div class="itmform-container" @keydown.esc="onEsc">
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <ThatsWindow ref="window" title="Product list" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1200" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">
                <template #left-button>
                    <template v-if="formLoading || ViewLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>

                        <template v-if="disablePrice && isBillMaterialTab && FormStatus ==='update'">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitBomForm)">Save</mdb-btn>
                        </template>
                        <template v-else>
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                            <template v-if="FormStatus === 'update' && isAllowedCopy">
                                <mdb-btn color="elegant" size="sm" @click="openItemForm">Copy</mdb-btn>
                            </template>
                        </template>
                    </template>
                </template>
                <mdbContainer class="mt-1">
                    <mdb-tabs class="table-tabs mb-0 form"  :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
                        <template :slot="'form-slot'">
                            <mdb-row >
                                <mdb-col col="8" class="mt-2">
                                    <thats-fieldset>
                                        <mdb-row>
                                            <mdb-col col="6" class="pr-0">
                                                <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="disabled" />
                                            </mdb-col>
                                            <mdb-col col="6">
                                                <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Supplier Code" placeholder="Supplier Code" vid="barcode" name="barcode" v-model="form.attr.barcode" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="disabled" />
                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row>
                                            <mdb-col col="6" class="pr-0">
                                                <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-7 pl-0" label="Old Code" placeholder="Old Code" vid="old_code" name="old_code" v-model="form.attr.old_code" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="disabled" />
                                            </mdb-col>

                                        </mdb-row>
                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Item Name" placeholder="Item Name" vid="namex" name="namex" v-model="form.name" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                                        <ThatsInput tabindex="3" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Dimension" placeholder="WxHxD (cm)" vid="dimension" name="dimension" v-model="form.dimension" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                                        <mdb-row>
                                            <mdb-col col="2">

                                            </mdb-col>
                                            <mdb-col col="10">
                                                <mdb-row>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Height" placeholder="0" vid="height" name="height" v-model="form.attr.dimension_height" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Width" placeholder="0" vid="width" name="width" v-model="form.attr.dimension_width" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Depth" placeholder="0" vid="depth" name="depth" v-model="form.attr.dimension_depth" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Diameter" placeholder="0" vid="diameter" name="diameter" v-model="form.attr.dimension_diameter" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                </mdb-row>


                                                <mdb-row>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Hole" placeholder="0" vid="hole" name="hole" v-model="form.attr.hole" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Capacity" placeholder="0" vid="capacity" name="capacity" v-model="form.attr.capacity" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Weight" placeholder="0" vid="weight" name="weight" v-model="form.attr.weight" :validated="validated" :rules="''" size="sm" :horizontal="true" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="3">
                                                        <ThatsInput tabindex="11" ref="cube_unit" labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Cubic Feet" v-model="form.attr.cube_unit" :rules="''" size="sm"  :horizontal="true" :disabled="disabled"></ThatsInput>
                                                    </mdb-col>

                                                </mdb-row>


                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row>
                                            <mdb-col col="6">
                                                <!-- <ThatsPriceInput tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="form.sale_price" :rules="'required'" :validated="validated" :disabled="!disablePrice"/>  -->
                                                <ThatsMoney tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="form.sale_price" :rules="'required'" :validated="validated" :disabled="!disablePrice"/>
                                                <ThatsMoney tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Discount Price" placeholder="0" vid="discount_value" name="discount_value" size="sm" v-model="form.attr.discount.value" :rules="'required'" :validated="validated" :disabled="!disablePrice"/>
                                                <!-- <ThatsInput tabindex="4"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Discount Label" placeholder="" vid="discount_label" name="discount_label" size="sm" v-model="form.attr.discount.remarks" :rules="'required'" :validated="validated" :disabled="!disablePrice"/>  -->
                                            </mdb-col>
                                            <mdb-col col="6">

                                            </mdb-col>
                                        </mdb-row>
                                        <mdb-row>
                                            <mdb-col col="6">

                                            </mdb-col>
                                            <mdb-col col="6">
                                                <!-- <ThatsPriceInput tabindex="5"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Promo Price" placeholder="0" vid="promo_price" name="promo_price" size="sm" v-model="form.promo_price" :rules="''" :validated="validated"/> -->
                                            </mdb-col>
                                        </mdb-row>


                                    </thats-fieldset>
                                    <thats-fieldset class="mt-3">
                                        <label class="small">COUNTRY OF ORIGIN</label>
                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="English" placeholder="English" vid="collection_en" name="collection_en" v-model="form.attr.collection.en" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                                        <ThatsInput tabindex="2" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" label="Arabic" placeholder="Arabic" vid="collection_ar" name="collection_ar" v-model="form.attr.collection.ar" :validated="validated" :rules="''" size="sm" :disabled="disabled" :inputclass="['text-right']"/>

                                    </thats-fieldset>


                                    <thats-fieldset class="mt-3">
                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsAutocomplete tabindex="6" @newWindow="openBrandForm" @select="onBrandSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Brand" v-model="form.brand" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="brandComputed" :rules="'required'" :disabled="disabled"></ThatsAutocomplete>
                                                <ThatsAutocomplete tabindex="8" @newWindow="openCategoryForm" @select="onCategorySelect" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Category" v-model="form.category" :reduce="category => category.code" :reduceLabel="'category'" :options="filterCategory" :rules="'required'" :disabled="disabled"></ThatsAutocomplete>


                                            </mdb-col>

                                            <mdb-col col="6">
                                                <ThatsAutocomplete tabindex="7"  @select="onAreaSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Area" v-model="form.area" :reduce="area => area.code" :reduceLabel="'area'" :options="filterArea" :rules="'required'" :disabled="disabled"></ThatsAutocomplete>
                                                <ThatsAutocomplete tabindex="9" :addButton="false" :appendButton="false" :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Attributes" v-model="form.attr.attribute" :reduce="attribute => attribute.code" :reduceLabel="'attribute'" :options="filterAttributes" :rules="''" :disabled="disabled"></ThatsAutocomplete>

                                            </mdb-col>
                                        </mdb-row>

                                        <mdb-row>
                                            <mdb-col col="6">
                                                <ThatsAutocomplete tabindex="10" @newWindow="openCollectionForm" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Collections" v-model="form.collection" :reduce="collection => collection.code" :reduceLabel="'collection'" :options="filterCollections" :rules="'required'" :disabled="disabled"></ThatsAutocomplete>
                                                <mdb-row>
                                                    <mdb-col col="4">
                                                        <label>Materials</label>
                                                    </mdb-col>
                                                    <mdb-col col="8">
                                                        <ThatsVueSelect tabindex="5" @newWindow="openMaterialForm" @select="onSelectMaterial" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.materials" :reduce="material => material.code" :reduceLabel="'material'" :options="materials" :rules="''" :showLabel="false" :clearable="false" :disabled="disabled"></ThatsVueSelect>
                                                        <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="materials1" name="materials1" v-model="form.attr.materials1" :validated="validated" :rules="''" size="sm" :showLabel="false" :disabled="disabled"/>
                                                    </mdb-col>
                                                </mdb-row>
                                                <ThatsInput tabindex="13" ref="autoFinishes" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Finishes" v-model="form.attr.finishes" :rules="''" :disabled="disabled"></ThatsInput>
                                                <ThatsInput tabindex="12" ref="autoFabric" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Fabric" v-model="form.attr.fabric" :rules="''" :disabled="disabled"></ThatsInput>
                                                <mdb-row>
                                                    <mdb-col col="4">
                                                        <label>Colour</label>
                                                    </mdb-col>
                                                    <mdb-col col="8">
                                                        <ThatsVueSelect tabindex="5" @newWindow="openColourForm" @select="onSelectColour" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.colour" :reduce="colour => colour.code" :reduceLabel="'colour'" :options="colour" :rules="''" :showLabel="false" :clearable="false" :disabled="disabled"></ThatsVueSelect>
                                                        <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour1" name="colour1" v-model="form.attr.colour1" :validated="validated" :rules="''" size="sm" :showLabel="false" :disabled="disabled"/>

                                                    </mdb-col>

                                                </mdb-row>
                                                <ThatsRadio tabindex="16" :labelClass="'col-md-4'" :fieldClass="'col-md-8  pl-0'" label="Item Type" vid="item-type" orientation="inline" name="item-type" :data="itemType" v-model="form.item_type" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>

                                                <mdb-row>
                                                    <mdb-col col="6" class="pr-0">
                                                        <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Hide in website'" vid="item-status" orientation="inline" name="item-status" :data="[]" v-model="form.website" :validated="validated" :rules="''" size="sm" :multiple="false" :disabled="disabled"/>
                                                    </mdb-col>
                                                    <mdb-col col="6">
                                                        <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Show in Polish'" vid="visile-polish" orientation="inline" name="visile-polish" :data="[]" v-model="form.attr.visible_polish" :validated="validated" :rules="''" size="sm" :multiple="false" :disabled="disabled"/>
                                                    </mdb-col>
                                                </mdb-row>
                                            </mdb-col>
                                            <mdb-col col="6">
                                                <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="form.description" size="sm" :disabled="disabled"/>
                                                <ThatsRadio tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" label="Status" vid="item-status" orientation="inline" name="item-status" :data="itemStatus" v-model="form.status" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                                            </mdb-col>
                                        </mdb-row>

                                    </thats-fieldset>

                                    <!--   <thats-fieldset class="mt-1">
                                      <label>SEO META</label>

                                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9  pl-0" vid="meta_title" name="meta_title"  label="Title" v-model="form.attr.meta_title" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9  pl-0" vid="meta_keyword" name="meta_keyword"  label="Keywords" v-model="form.attr.meta_keyword" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                                      <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="meta_description" name="meta_description" v-model="form.attr.meta_description" size="sm" :disabled="disabled"/>
                                      </thats-fieldset>
                   -->
                                </mdb-col>

                                <mdb-col col="4" class="mt-2 pl-0">
                                    <thats-fieldset>
                                        <template v-if="form.image.length > 0">
                                            <mdb-carousel
                                                :items="form.image.length"
                                                controlls
                                                :controllIcons="['fas fa-arrow-circle-left fa-2x text-white', 'fas fa-arrow-circle-right fa-2x text-white']"
                                            >
                                                <template #[i+1] v-for="(img, i) in form.image">
                                                    <mdb-card :key="i">

                                                        <template v-if="!disabled">
                                                            <div class="custom-control image-checkbox custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" @change="onSelect($event,img)" :checked="img.default" :id="'imageCheckbox'+img.mdbID" :value="img.medium">
                                                                <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID" title="Set as default">Set as default</label>
                                                            </div>
                                                            <div class="custom-control image-delete" @click="removeImage(i,img.default)">
                                                                <mdb-icon icon="trash"></mdb-icon>
                                                            </div>
                                                        </template>


                                                        <mdb-card-image
                                                            :src="img.md"
                                                            :alt="img.filename"
                                                        ></mdb-card-image>

                                                    </mdb-card>
                                                </template>




                                            </mdb-carousel>
                                        </template>
                                        <template v-else>
                                            <div class="no-image-gallery">
                                                <mdb-icon far icon="images" />
                                            </div>
                                        </template>
                                        <template v-if="!disabled">
                                            <a href="javascript:void(0)" tabindex="17" @click="uploadImage"><mdb-icon icon="image"></mdb-icon> Select Image</a>
                                        </template>
                                    </thats-fieldset>

                                    <thats-fieldset class="mt-2">
                                        <label>Stock Details</label>


                                        <template v-if="FormStatus ==='update'">
                                            <template v-if="warehousesLoading">
                                                <p class="sm text-muted"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    Loading...</p>
                                            </template>
                                            <template v-else>

                                                <ThatsNumber tabindex="18" :labelClass="'col-sm-5'" :fieldClass="'col-sm-7 pl-0'" label="Stock" placeholder="Qty" vid="qty" name="qty" v-model="form.InStock" :validated="validated" :rules="'required'" size="sm" :disabled="true" />

                                                <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Ordered" v-model="form.Ordered" :rules="''" :disabled="true"></ThatsNumber>
                                                <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Committed" v-model="form.Committed" :rules="''" :disabled="true"></ThatsNumber>
                                                <ThatsNumber tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Available" v-model="form.Available" :rules="''" :disabled="true"></ThatsNumber>



                                            </template>
                                        </template>





                                    </thats-fieldset>
                                </mdb-col>
                            </mdb-row>
                        </template>
                        <template :slot="'inventory-slot'">
                            <el-table border :data="(form.warehouses.filter((item) => item.InStock > 0 || item.Committed > 0 || item.Ordered > 0 || item.Available > 0, []))" stripe class="input-table mt-0" :summary-method="getSummary" show-summary>
                                <el-table-column label="Warehouse" width="250">
                                    <template slot-scope="scope">
                                        {{ scope.row.WarehouseName }}

                                    </template>
                                </el-table-column>
                                <el-table-column label="In Stock" width="120">
                                    <template slot-scope="scope">
                                        {{ scope.row.InStock }}

                                    </template>
                                </el-table-column>
                                <el-table-column label="Committed" width="120">
                                    <template slot-scope="scope">
                                        {{ scope.row.Committed }}

                                    </template>
                                </el-table-column>
                                <el-table-column label="Ordered" width="120">
                                    <template slot-scope="scope">
                                        {{ scope.row.Ordered }}

                                    </template>
                                </el-table-column>
                                <el-table-column label="Available" width="120">
                                    <template slot-scope="scope">
                                        {{ scope.row.Available }}

                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>

<!--========================================================          Bill of material tab start            ==================================================================================================================================================-->
                        <template :slot="'bill-material'">
                            <mdb-btn type="button" color="elegant" size="sm" @click="addNewItem">New Item</mdb-btn>
                            <div>
                                <el-table id="itemTable" ref="itemTable" :key="tblKey" border :data="bomItem" class="input-table mt-2 itemTable" style="width: 100%">
                                    <el-table-column label="SQ" :width="50">
                                        <template slot-scope="scope">
                                            {{ scope.$index + 1 }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="SKU" :width="200">
                                        <template slot-scope="scope">
                                            <template v-if="!scope.row.sku">
                                                <GridVueSelect
                                                    class="custom-grid-select"
                                                    v-model="scope.row.selectedSku"
                                                    :reduce="item => item.code"
                                                    :reduceLabel="'code'"
                                                    :options="items"
                                                    :filterable="false"
                                                    :clearable="true"
                                                    @onSearch="statusKeyUp"
                                                    @select="getSelected($event, scope.row)"
                                                    :disabled="disabled"

                                                ></GridVueSelect>
                                            </template>
                                            <template v-else>
                                                {{ scope.row.sku }}
                                            </template>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Item Name" :row-class-name="''">
                                        <template slot-scope="scope">
                                            {{ scope.row.name }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Qty" :width="70" align="right">
                                        <template slot-scope="scope">
                                            <!--                                                {{ scope.row.qty }}-->
                                            <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty"  />
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Price" :width="100" align="right">
                                        <template slot-scope="scope">
                                            {{ scope.row.price }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="Action" :width="100" align="center">
                                        <template slot-scope="scope">
                                            <div class="image-delete" @click="handleDelete(scope.row.item_id)" v-if="!isEmptyRow(scope.row)">
                                                <mdb-icon icon="trash"></mdb-icon>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>

<!--========================================================          Bill of material tab end            ====================================================================================================================================================-->
                    </mdb-tabs>



                </mdbContainer>
            </ThatsWindow>

        </ValidationObserver>

        <NewItem ref="addNewItem" @change="onChangeAddItem" :group="999" @delete="formDelete"></NewItem>
        <GalleryManager ref="gallery" :data="form.image" :group="group2"></GalleryManager>
        <ImageGrid ref="upload" @select="fnImageSelect" :moduleForm="'item-form'" :group="group2"></ImageGrid>

        <CategoryForm ref="categoryForm" :group="group2" @success="categorySuccess"></CategoryForm>
        <BrandForm ref="brandForm" :group="group2" @success="brandSuccess"></BrandForm>
        <CollectionForm ref="collectionForm" :group="group2" @success="collectionSuccess" :show="'product'"></CollectionForm>
        <ColourForm ref="colourForm" :group="group2" @success="colourSuccess"></ColourForm>
        <MaterialForm ref="materialForm" :group="group2" @success="materialSuccess" :show="'product'"></MaterialForm>
        <ItemFormCopy ref="itemForm" :group="group2" @success="itemCopySuccess"></ItemFormCopy>
    </div>
</template>
<script>
import {  mdbClickOutside, mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea,ThatsGridSelect, ThatsVueSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GalleryManager from '../../components/image/GalleryManager';
import Autocomplete from '../../components/Autocomplete';

import Category from '../category/Category'
import CategoryForm from '../category/CategoryForm'
import BrandForm from '../brand/BrandForm'
import CollectionForm from '../collection/CollectionForm'
import { MessageBox } from 'element-ui';
import ColourForm from '../colour/ColourForm'
import MaterialForm from '../material/MaterialForm'
import ItemFormCopy from './ItemFormCopy'
import AutoCompleteNoLabel from '../../components/thats/AutoCompleteNoLabel';
import XWindow from '../../components/thats/XWindow.vue'
import NewItem from "./NewItem";
import GridVueSelect from "./GridVueSelect";
import Sortable from "sortablejs";
export default {
    directives: {
        mdbClickOutside
    },
    components: {
        GridVueSelect, NewItem, mdbTabs, mdbContainer, mdbBtn, mdbRow, mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage, ThatsGridSelect,
        ValidationObserver, ThatsWindow, ThatsInput, ThatsSeriesInput, ThatsPriceInput, ThatsFieldset, ThatsRadio, ThatsAutocomplete, Autocomplete,
        ThatsAutosuggest, ThatsNumber, ThatsTextarea, ThatsCheckbox, ImageGrid, GalleryManager, Category, CategoryForm, BrandForm, CollectionForm,
        ThatsVueSelect, ColourForm, ItemFormCopy, MaterialForm, AutoCompleteNoLabel, ThatsMoney, XWindow
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },
    data : () => ({
        active : 0,
        // tabs : [
        //     { text: 'ITEM DETAILS', slot: 'form-slot' },
        //     { text: 'INVENTORY DATA', slot: 'inventory-slot' },
        //     { text: 'BILL OF MATERIAL', slot: 'bill-material' },
        // ],
        selectedTab: 'ITEM DETAILS',
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        searchSkuItem:'',
        formLoading : false,
        searchResults:[],
        vendors : [],
        brands : [],
        collections : [],
        category : [],
        attributes : [],
        area : [],
        items : [],
        selectedItem: null,
        viewMenu: false,
        itemType : [
            {label : "Stock", value : "stock"},
            {label : "Services", value : "services"},
        ],

        itemStatus : [
            {label : "New Arrival", value : "2"},
            {label : "Active", value : "1"},
            {label : "Discontinued", value : "0"},
        ],

        materials : [],
        finishes : [],
        fabric : [],
        showError : false,
        materials1 : [],
        colour : [],
        colour1 : [],
        locations : [],

        inventoryData : []
    }),

    inject : ['areaProvider','categoryProvider','collectionProvider','colorProvider','brandProvider','warehousesProvider'],

    created : function() {
        var self = this;

        if(this.FormStatus == 'create'){
            this.form = this.formVar();
            this.nextCode()
            // this.form.parent = this.parent
        }

        this.optionsHandler();

        self.GetShowrooms().then((response) =>{
            if(response.length > 0){
                response.forEach((element,index) => {
                    const d = {
                        label : element.prefix,
                        value : element.value
                    }
                    self.locations.push(d)
                })
            }
        })

    },
    methods: {
        ...mapActions('ShowroomsModule',[
            'GetShowrooms'
        ]),
        ...mapActions('ItemFilterModule',[
            'filterItems','filterItemsBom'
        ]),
        ...mapActions('ColourModule',[ 'getColour' ]),
        ...mapActions('MaterialModule',[ 'getMaterials' ]),
        ...mapActions('BrandModule',[ 'GetBrands','GetBrandErp','GetArea']),
        ...mapActions('ItemModule',[
            'StoreRecord',
            'UpdateRecord',
            'GetNextCode',
            'GetBrandRelationship'
        ]),
        ...mapMutations('ItemModule',[
            'SetFormStatusCreate',
            'SetFormRecordCopy',
            'SetFormCopyStatusUpdate',
            'setArea',
            'setBrands',
            'setCategory',
            'setCollections',
            'setColour',
            'setMaterials',
            'setTblKey'
        ]),
        ...mapMutations('NewItemModule',[
            'addNewBomItem','SetFormStatusCreateBOM','addDeletedBomItem','SetDeletedBomItem'
        ]),...mapActions('NewItemModule',[
            'createOrUpdateBomItems','fetchBOMItemsByParentId','deleteRecords','checkItemExist'
        ]),

        formVar(){
            return {
                item_code : 0,
                parent : 0,
                name : null,
                slug : null,
                sale_price : 0,
                promo_price : 0,
                InStock : 0,
                Committed : 0,
                InStock : 0,
                Ordered : 0,
                Available : 0,
                dimension : null,

                brand : null,
                area : null,
                category : null,
                collection : null,

                item_type : 'stock',
                description : "",
                website : false,
                attr : {
                    cube_unit : "",
                    attribute : "",
                    post_views : 0,
                    materials : "",
                    materials1 : "",
                    fabric : "",
                    finishes : "",
                    sub_colour : "",
                    colour : "",
                    colour1 : "",
                    colour2 : "",

                    future_stock : {
                        qty : 0,
                        eta_text : "",
                        eta : []
                    },

                    dimension_height : "",
                    dimension_width : "",
                    dimension_depth : "",
                    dimension_diameter : "",
                    hole : "",
                    capacity : "",
                    weight : "",
                    meta_title : "",
                    meta_keyword : "",
                    meta_description : "",
                    visible_polish : false,
                    barcode : "",
                    old_code : "",
                    discount : {
                        value : 0,
                        remarks : ""
                    },
                    collection : {
                        en : "",
                        ar : ""
                    }
                },

                child: [],
                order_eta: [],

                image: [],
                images: [],
                location: [],
                warehouses: [],
                qty : 0,
                status : '1'

            }
        },

        formItemVar(){
            return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                custom_discount: {
                    apply : true,
                    discount : 0,
                    off_price : 0,
                    promo : 0,
                },
                total: 0,
                tbo: false,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false
            }
        },
        /* start */
        itemCopySuccess(){
            this.closeDialog();
            this.FormResetter();
            this.$emit('success',{})
        },
        onSelectMaterial(v){
            const self = this;
            this.form.attr.material1 = ""
            self.materials1 = [];
            const selected = this.materials.filter(i => {
                return i.code === v
            })[0]

            if(selected){
                selected.attributes.forEach((item, index) => {
                    const c1 = { material1: item.text, code: item.slug,selected : false }
                    self.materials1.push(c1)
                    if(index == 0){
                        self.form.attr.material1 = c1.code
                    }
                })
            }


        },
        onSelectColour(v){
            const self = this;
            this.form.attr.colour1 = ""
            self.colour1 = [];
            const selected = this.colour.filter(i => {
                return i.code === v
            })[0]

            if(selected){
                selected.attributes.forEach((item, index) => {
                    const c1 = { colour1: item.text, code: item.slug,selected : false }
                    self.colour1.push(c1)
                    if(index == 0){
                        self.form.attr.colour1 = c1.code
                    }
                })
            }


        },
        openItemForm(){
            /*    const formRecordCopy = JSON.parse(JSON.stringify(this.FormRecord));
               FormRecord.series = "";
                       formRecordCopy.attr.attribute = "";
                       formRecordCopy.parent = formRecordCopy.mdbID;
                      this.SetFormCopyStatusUpdate()
                      this.SetFormRecordCopy(formRecordCopy)
             */

            this.validated = false
            this.formLoading = false
            this.FormRecord.series = ""
            // this.SetFormStatusCreate();
            this.showError = false



            this.$refs.itemForm.$refs.windowCopy.openDialog()
        },

        openColourForm(){
            this.$refs.colourForm.openDialog()
        },
        openMaterialForm(){
            this.$refs.materialForm.openDialog()
        },
        materialSuccess(response){
            const self = this;
            const v = response.data;
            const itemConst = { material: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
            this.materials.push(itemConst)
            this.setMaterials(this.materials)
            this.form.attr.material = v.slug
            self.materials1 = []
            if(v.attr.attributes.length > 0){
                v.attr.attributes.forEach((item, index) => {
                    const c1 = { material1: item.text, code: item.slug,selected : false }
                    self.materials1.push(c1)
                    if(index == 0){
                        self.form.attr.material1 = c1.code
                    }
                })
            }
        },

        colourSuccess(response){
            const self = this;
            const v = response.data;
            const itemConst = { colour: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
            this.colour.push(itemConst);
            this.setColour(this.colour)
            this.form.attr.colour = v.slug
            self.colour1 = []
            if(v.attr.attributes.length){
                v.attr.attributes.forEach((item, index) => {
                    const c1 = { colour1: item.text, code: item.slug,selected : false }
                    self.colour1.push(c1)
                    if(index == 0){
                        self.form.attr.colour1 = c1.code
                    }
                })
            }
        },


// =====================================================          Bill of material start          ====================================================================================================================================================

        isEmptyRow(row) {
            // Check if the row is empty based on your criteria
            return !row.sku && !row.name && !row.qty && !row.price;
        },
        addNewItem(){
            this.validated = false
            this.formLoading = false
            this.FormRecord.series = ""
            this.SetFormStatusCreateBOM();
            this.showError = false
            this.$refs.addNewItem.openDialog();
        },
        onChangeAddItem(data){
            console.log(data)
            // if(data.hasOwnProperty('index')){
            //     this.form.attr.contact_persons.splice(data.index, 1, data)
            // }else{
            //     this.form.attr.contact_persons.push(data)
            // }
        },


        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },

        searchSkuName() {
            this.filterItemsBom({term:this.searchSkuItem}).then((response) => {
                this.searchResults = response
                console.log(response);
            }).catch((error) => {
                console.error(error);
            });
        },
        selectItem(item) {
            this.selectedItem = item;
        },
        getSelected(v) {
            const selectedItem = this.items.find(item => item.code === v);

            if (selectedItem) {
                const existingItem = this.bomItem.find(item => item.item_id === selectedItem.id);

                if (!existingItem) {
                    if (selectedItem.id !== this.form.id) {
                        const qty = 1;
                        const price = selectedItem.price;

                        const newItemData = {
                            item_id: selectedItem.id,
                            sku: selectedItem.code,
                            name: selectedItem.label,
                            qty: qty,
                            price: price
                        };

                        const emptyItemIndex = this.bomItem.findIndex(item => item.item_id === '');

                        this.bomItem.splice(emptyItemIndex, 0, newItemData);

                        const updatedEmptyItemIndex = emptyItemIndex + 1;
                        this.bomItem.splice(updatedEmptyItemIndex, 1);

                        this.addNewBomItem({
                            item_id: '',
                            sku: '',
                            name: '',
                            qty: '',
                            price: '',
                        });
                    }
                }
            }
        },



        statusKeyUp(v) {
            const params = {
                term: v
            };
            this.dispatchFilterItems(params);
        },
        async handleDelete(id) {
            const confirmed = window.confirm('Are you sure you want to delete this item?');

            if (confirmed) {

                const index = this.bomItem.findIndex(item => item.item_id === id);
                if (index !== -1) {
                    this.bomItem.splice(index, 1);
                    this.addDeletedBomItem(id);
                }


            }
        },
        dispatchFilterItems(params) {
            this.filterItemsBom(params).then((response) => {
                const results = response.map(element => ({
                    label: element.name,
                    code: element.series,
                    formatted_price: element.formatted_price,
                    price: element.sale_price,
                    id: element.mdbID,
                    promotions: element.promotions,
                    qty: element.qty,
                    future: element.future_stock,
                    default: element.default,
                    category: element.category,
                    status: element.status,
                    remaining: element.remaining
                }));


                this.items = results;
            });
        },



























































// =========================================================================         Bill of material end      ========================================================================================================
        categorySuccess(v){
            console.log(v);
            const catregoryRes = v.data;
            if(catregoryRes.brands.length > 0){
                catregoryRes.brands.forEach((id) => {
                    const categoryData = {
                        code : parseInt(catregoryRes.mdbID),
                        category : catregoryRes.name,
                        attributes : catregoryRes.attr.attributes,
                        brand : parseInt(id),
                        area : parseInt(catregoryRes.area),
                    }
                    this.category.push(categoryData);
                })

                this.setCategory(this.category)
            }

        },
        brandSuccess(v){
            const response = v.data;
            const data = {
                code : parseInt(response.mdbID),
                brand : response.name,
            }
            this.brands.push(data);
            self.setBrands(self.brands)


            if(response.relationship.area.length > 0){
                response.relationship.area.forEach((el) => {
                    this.area.push({
                        brand : parseInt(response.mdbID),
                        area : el.name,
                        code : parseInt(el.mdbID)
                    })

                })

                self.setArea(self.area);
            }
        },
        collectionSuccess(v){
            this.collections.push(v.data);
            const c = {...this.collections, ...v.data}
            self.setCollections(c);
        },
        removeImage(i,isDefault){
            var self = this;
            MessageBox.confirm('This will permanently delete the image?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                self.form.image.splice(i,1)
            })
        },

        onSelect(e,r){

            this.form.image.forEach((img,i) => {
                if(r.token_id === img.token_id){
                    this.form.image[i].default = 1
                }else{
                    this.form.image[i].default  = 0
                }
            })
        },

        convertToSlug(Text)
        {
            return Text
                .toLowerCase()
                .replace(/ /g,'-')
                .replace(/[^\w-]+/g,'')
                ;
        },

        openCategoryForm(){
            this.$refs.categoryForm.openDialog()
        },
        openCollectionForm(){
            this.$refs.collectionForm.openDialog()
        },
        openBrandForm(){
            this.$refs.brandForm.openDialog()
        },
        openGalleryManager(){
            this.$refs.gallery.openDialog()
        },

        materialBlur(e){
            console.log(e)
        },
        openDialog(){
            this.$refs.window.openDialog();
        },
        openWindow(reference,window){
            if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();

            }

        },

        onBrandSelect(v){
            this.form.area = null;
            this.form.category = null;
            this.form.attr.attribute = null;
            this.form.collection = null
        },

        onAreaSelect(v){
            this.form.category = null;
            this.form.attr.attribute = null;
        },

        onCategorySelect(v){
            this.form.attr.attribute = null;
        },

        openCategory(){
            this.$refs.category.openDialog();
            this.$refs.categoryTable.refreshTable();
        },

        optionsHandler(){
            var self = this;
            self.GetBrandErp().then(response => {
                const brand = [];
                response.forEach(element => {
                    brand.push({brand : element.label,code : element.value})
                });
                self.brands = brand
                self.setBrands(brand)
            });



            /*  self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'colour'}).then((response) => {
                console.log(response);
             }) */
            self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
                self.area = response
                self.setArea(response)
            })
            self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
                self.category = response
                self.setCategory(response)
            })
            self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
                self.collections = response
                self.setCollections(response)
            })
            self.getColour({'show_in' : 'product'}).then(response => {

                const c = [];

                if(response.length){
                    response.forEach(e => {
                        c.push({
                            colour : e.label,
                            code : e.slug,
                            attributes : e.attributes,
                        })
                    })
                    self.colour = c
                    self.setColour(c)
                }
            })
            self.getMaterials({'show_in' : 'product'}).then(response => {
                const c = [];
                if(response.length){
                    response.forEach(e => {
                        c.push({
                            material : e.label,
                            code : e.slug,
                            attributes : e.attributes,
                        })
                    })
                    self.materials = c
                    self.setMaterials(c)
                }
            })


        },

        uploadImage(){
            this.$refs.upload.openDialog();
        },

        nextCode(){
            var self = this;
            this.GetNextCode().then((response) => {
                self.form.item_code = response.code
            });
        },




        fnImageSelect(v){
            console.log(v)
            if(v.length > 0){
                v.forEach((r,i) => {
                    const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium, md : r.md, sm : r.sm, lg : r.lg}
                    if(i === 0){
                        d.default = 1;
                    }
                    this.form.image.push(d)
                })

            }
        },

        /* end */
        openDialog(){
            this.$refs.window.openDialog()
        },
        closeDialog(){
            this.$refs.window.closeDialog()
        },

        onSubmitForm(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                if(self.formLoading === false){
                    self.formLoading = true
                    if(self.FormStatus == 'create'){
                        self.StoreRecord(self.form).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response)
                            self.closeDialog();
                            self.FormResetter();
                            console.log('swaraj New Item in Create')
                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }

                    if(self.FormStatus == 'update'){
                        self.UpdateRecord(self.form).then((response) => {
                            self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                            })
                            self.closeDialog();
                            self.$emit('success',response)
                            self.FormResetter();


                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }
                }

            }
        },

        onSubmitBomForm() {
            const parentId = this.form.id;


            const filteredBomItemsData = this.bomItem.filter(item =>
                (item.item_id !== null && item.item_id !== '') ||
                (item.qty !== null && item.qty !== '')
            ).map(item => ({
                item_id: item.item_id,
                parent_id: parentId,
                qty: item.qty
            }));


            console.log(filteredBomItemsData);

            if (this.deletedBomItem !== null) {
                this.deleteRecords({parentId, deletedItem:this.deletedBomItem});
            }

            this.createOrUpdateBomItems({ parentId, itemsData: filteredBomItemsData }).then(response => {
                console.error(response);
                // this.$notify({
                //     title: 'Success',
                //     message: response.message,
                //     type: 'success'
                // });
            })
                .catch(error => {
                    console.error(error);
                    // this.$notify({
                    //     title: 'Error',
                    //     message: 'An error occurred while processing your request',
                    //     type: 'error'
                    // });
                });
        },



        getText() {
            let text = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },
        FormResetter(){
            this.form = this.formVar()
            // this.form.parent = this.parent
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
        },

        dialogState(state){

            if(!state){
                this.FormResetter();
                return
            }else{
                this.optionsHandler();
            }

            if(this.FormStatus == 'create'){
                this.FormResetter();
                this.nextCode();
            }



        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },

        onSelectLocation(v){

            const self = this;



            if(this.form.location.length  > 0){

                const filterLocationIds = this.form.location.map((loc) => loc.location_id)

                //  console.log(filterLocationIds);

                filterLocationIds[v.index] = v.value;


                /*   var filteredID = filterLocationIds.filter(function (el) {
                       return el != null;
                     });

                     console.log(filteredID); */




                this.form.location.forEach((el, index) => {

                    if(index === v.index){
                        el.location_id = v.value
                    }


                    const getOtherSelected = filterLocationIds.filter((x) => x != el.location_id);

                    const getDrodownOptions = self.locations.filter((location)=>{
                        return !getOtherSelected.includes(location.value)
                    })

                    el.locations = getDrodownOptions;
                    // console.log(getDrodownOptions);

                    // const selectedAndRemain = [el.location,]





                    // console.log(filterLocationIds)

                    /*  console.log(`${index} - ${v.value}`)
                     if(index === v.index){
                       el.location_id = v.value;
                     }



                     if(index !== v.index){

                         const filterLocations = el.locations.filter((innerEl) => {
                           return innerEl.value != v.value
                         });


                         el.locations = filterLocations;

                     } */
                })





            }
            /* console.log(v)
            console.log(`ix ${v.index}`) */
        },

        addLocation(){
            const self = this;
            const filterLocationIds = this.form.location.map((loc) => loc.location_id);

            if(filterLocationIds.length < self.locations.length){
                const getDrodownOptions = self.locations.filter((location)=>{
                    return !filterLocationIds.includes(location.value)
                })

                if(getDrodownOptions.length > 0){
                    const newArray = getDrodownOptions.slice(0, 1)[0]
                    this.form.location.push({
                        locations : getDrodownOptions,
                        location_id : newArray.value,
                        qty : 0,
                        bin : "",
                    });
                    const filterLocationIdsAfter = this.form.location.map((loc) => loc.location_id);
                    this.form.location.forEach((el) => {
                        const getOtherSelected = filterLocationIdsAfter.filter((x) => x != el.location_id);
                        const getDrodownOptions = self.locations.filter((location)=>{
                            return !getOtherSelected.includes(location.value)
                        })
                        el.locations = getDrodownOptions;
                    })

                }

            }

        },

        removeLocation(i, location){
            var self = this;
            MessageBox.confirm('Are you sure you want to remove location?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {

                self.form.location.splice(i,1)
                const removedLocation = self.locations.filter((row) => {
                    return row.value === location.location_id;
                })

                this.form.location.forEach((el) => {
                    el.locations = [...el.locations,...removedLocation];
                })

            })
        },

        onEsc(){
            alert(1)
        },


        handleTabClick(tab) {
            this.selectedTab = tab.text;
        },
        getActiveTabIndex(index) {
            this.active = index;
            this.selectedTab = this.tabs[index].text;
        },
        getSummary(param){
            const self = this;
            const { columns, data } = param;
            const sums = [];
            // const numberColumns = [2,3,4,5,6,7,8]

            let totalCol = 2;
            let totalQty= 3;
            // let totalPrice= 4;
            let totalAmount= 5;
            // let totalDiscount= 6;
            let totalTotal= 7;

            columns.forEach((_column, index) => {



                if (index === 0) {
                    sums[index] = 'Total';
                    return;
                }
                if (index === 1) {
                    const qty = data.map(item => {
                        return parseInt(item.InStock)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === 2) {
                    const qty = data.map(item => {
                        return parseInt(item.Committed)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === 3) {
                    const qty = data.map(item => {
                        return parseInt(item.Ordered)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }

                if (index === 4) {
                    const qty = data.map(item => {
                        return parseInt(item.Available)
                    })

                    if (!qty.every(qty => isNaN(qty))) {
                        sums[index] = qty.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                    return;
                }





            });

            // this.toggleCheck()

            return sums;
        }

    },

    computed: {

        ...mapGetters('ItemModule',
            [
                'FormStatus',
                'FormRecord',
                'ViewLoading',
                'tblKey',


            ]),
        ...mapGetters('ItemFilterModule',
            [
                'selectedItems',
            ]),
        ...mapGetters('NewItemModule',
            [
                'bomItem','deletedBomItem'
            ]),
        ...mapGetters('SapWarehouseModule',
            [
                'warehousesLoading'

            ]),

        isBillMaterialTab() {
            return this.selectedTab === "BILL OF MATERIAL";
        },
        tabs() {
            if (this.FormStatus === 'create') {
                return [
                    { text: 'ITEM DETAILS', slot: 'form-slot' },
                    { text: 'INVENTORY DATA', slot: 'inventory-slot' },
                ];
            } else {
                return [
                    { text: 'ITEM DETAILS', slot: 'form-slot' },
                    { text: 'INVENTORY DATA', slot: 'inventory-slot' },
                    { text: 'BILL OF MATERIAL', slot: 'bill-material' },
                ];
            }
        },



        group2 : function(){
            return (this.group + 2);
        },
        filterCollections : function() {
            if(this.form.brand === null){
                return []
            }
            const results = this.collections.filter((item, index) => {
                return item.brand_id === this.form.brand
            })


            const response = [];
            if(results.length > 0){

                results.forEach((el, index) => {
                    if(index === 0 && this.FormStatus == 'create'){
                        this.form.collection = el.value
                    }
                    response.push({collection : el.name,code : el.mdbID})
                })
            }
            return response;

        },

        filteredItems() {
            return this.items.filter(item => item.label.includes(this.selectedItems));
        },

        filterArea : function() {
            if(this.form.brand === null){
                return []
            }
            const results = this.area.filter((item, index) => {
                return item.brand === this.form.brand
            })

            if(results.length > 0 && this.FormStatus == 'create'){
                this.form.area = results[0].code
            }
            return results;
        },

        filterCategory : function() {

            if(this.form.brand === null && this.form.area === null){
                return []
            }

            const results = this.category.filter((item, index) => {
                return item.brand === this.form.brand && item.area === this.form.area
            })

            if(results.length > 0 && this.FormStatus == 'create'){
                this.form.category = results[0].code
            }
            return results;
        },

        filterAttributes : function() {
            const response = [];
            if(this.form.brand === null && this.form.area === null && this.form.category === null){
                return response;
            }

            const results = this.category.filter((item, index) => {
                return item.brand === this.form.brand && item.area === this.form.area && item.code === this.form.category
            })

            if(results.length > 0){
                /* if(this.FormStatus == 'create'){
                  this.form.attribute = results[0].attributes[0].code
                } */
                results[0].attributes.forEach((el) => {
                    response.push({attribute : el.text , code : el.slug});
                })
            }
            return response;
        },

        isSystemAdministrator : function(){
            if(this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;
        },
        isAllowed : function(){
            if(this.$helpers.isSalesExecutive() || this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;
        },
        isAllowedCopy : function(){
            if(this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;
        },
        disablePrice : function(){
            // const cat = ['curtain-projects','curtain-project','upholstery-service'];
            const cat = [132,127];
            if((this.$helpers.isSalesExecutive() || this.$helpers.isSystemAdministrator() || this.$helpers.isBackOffice()) && cat.includes(this.form.category)){

                /*  if(this.form.category.includes()){
                   return false
                 } */
                return true
            }else{
                if(this.$helpers.isSystemAdministrator() || this.$helpers.isBackOffice()){
                    return true;
                }
                return false;
            }
        },

        disabled : function(){
            return (this.$helpers.isSystemAdministrator() === true || this.$helpers.isBackOffice() || this.$helpers.itemCreate()) ? false : true;
        },

        warehousesComputed : function(){
            return this.warehousesProvider();
        },

        areaComputed : function(){
            return this.areaProvider();
        },
        categoryComputed : function(){
            return this.categoryProvider();
        },
        collectionComputed : function(){
            return this.collectionProvider();
        },
        colorComputed : function(){
            return this.colorProvider();
        },
        brandComputed : function(){
            return this.brandProvider();
        }


    },

    watch: {
        FormRecord : function(){
            if(this.FormStatus == 'update'){

                const frm = JSON.parse(JSON.stringify(this.FormRecord));

                const newFormUpdate = {...this.form, ...frm}

                newFormUpdate.status = frm.status+""
                if(newFormUpdate.website === 1){
                    newFormUpdate.website = true
                }

                if(newFormUpdate.attr.hasOwnProperty('visible_polish') && newFormUpdate.attr.visible_polish === 1){
                    newFormUpdate.attr.visible_polish = true
                }else{
                    newFormUpdate.attr.visible_polish = false
                }

                if(newFormUpdate.attr.hasOwnProperty('barcode') ){
                    newFormUpdate.attr.visible_polish = true
                }else{
                    newFormUpdate.attr.visible_polish = false
                }

                if(!newFormUpdate.attr.hasOwnProperty('discount') ){
                    newFormUpdate.attr.discount = {
                        value : 0,
                        remarks : ""
                    }
                }
                if(!newFormUpdate.attr.hasOwnProperty('discount') ){
                    newFormUpdate.attr.collection = {
                        en : "",
                        ar : ""
                    }
                }

                if(newFormUpdate.hasOwnProperty('warehouses')){
                    this.inventoryData = []
                }

                this.form = newFormUpdate;


            }

            if(this.FormStatus == 'create'){
                this.FormResetter();
            }
        },
        tblKey : function(){
            this.initSortable();
        },
        "form.name" : function(v){
            if(this.FormStatus === 'create' && v != null){
                this.form.slug = this.convertToSlug(v)
            }
        },
        "form.website" : function(v){

            if(Array.isArray(v)){
                this.form.website = v[0]
            }
        }

    },


}
</script>

<style scoped>
.click-to-add-logo{
    font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
    right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
    padding: 65px;
    text-align: center;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
.el-table .custom-grid-select {
    background-color: #e6f6f0 !important;
}
</style>
