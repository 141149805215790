
<template>
    <div class="module-view-container">
        <ThatsTable title="Users" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :importBtn="false" @import="importProduct">
            <template #customBtns>
                <mdb-btn color="elegant" icon="star-of-life" size="sm" class="mr-auto mt-0" @click="openBulkReset">Bulk Reset</mdb-btn>
            </template>
             <template #custom-culumns="{columns, getValue, handleClick}">
                        <template v-for="col in columns">
                            <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                                <template slot-scope="scope">
                                    <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                        <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                                    </template>
                                    <template v-else>
                                        {{getValue(scope,col.field)}}
                                        <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                                    
                                    </template>
                                </template>
                                
                            </el-table-column>
                        </template>

                        <el-table-column label="Digital Signature">
                            <template slot-scope="scope">
                               <a href="javascript:void(0)" @click="viewSignature(scope.row)">View</a>
                            </template>
                        </el-table-column>
             </template>
        </ThatsTable>
        <UserForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></UserForm>
        <FileUpload @uploadSuccess="fileData" ref="uploadForm" :group="2" :endpoint="'wallpaper/import'"></FileUpload>
        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>
        <ThatsMediaPlayer :group="2" ref="galleryWindow" title="Gallery Manager" :data="galleryData"></ThatsMediaPlayer>
        <ThatsCustomWindow ref="sigatureWindow" :width="600" :height="200" :title="'Signature'">
             <div class="signature-container" ref="signature" v-html="signature"></div>
            <button @click="copySignature" class="btn btn-sm btn-elegant m-1">Copy to Clipboard</button>
        </ThatsCustomWindow>

        <BulkReset ref="bulkReset"></BulkReset>
    </div>
</template>
<script>
import BulkReset from './BulkReset.vue'
import { mapGetters, mapMutations,mapActions } from 'vuex';
import {mdbTabs, mdbBtn} from 'mdbvue'
import UserForm from './UserForm'
import ThatsTable from '../../components/thats/Table'
import ThatsMediaPlayer from '../../components/thats/MediaPlayer'
import ThatsCustomWindow from '../../components/thats/CustomWindow'
import FileUpload from '../../components/filemanager/FileUpload'
import TableImport from '../../components/filemanager/TableImport'
import TableError from '../../components/filemanager/TableError'
export default {
    data(){
        return {
            endpoint : 'user/data',    
            importData : {},        
            errorData : {},        
            importLoading : false,        
            group1 : ["name"],        
            group2 : ['email', 'role',],        
            galleryData : [],        
            active : 0,
            signature : '',
        }
    },
    components: {
        UserForm,
        ThatsTable,
        FileUpload,
        TableImport,
        TableError,
        ThatsCustomWindow,
        ThatsMediaPlayer,
        mdbTabs,
        mdbBtn,
        BulkReset

    },

    methods: {
        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapActions('UserModule',[ 'DeleteRecord','saveImport' ]),
        ...mapMutations('UserModule',[ 'SetFormRecord', 'SetFormStatusUpdate','SetPage']),
        checkGroup1(column){
           return this.group1.includes(column)
        },
        checkGroup2(column){
           return this.group2.includes(column)
        },
        viewImage(index,row){
            this.galleryData = [];
            if(row.images.length){
                row.images.forEach(element => {
                    this.galleryData.push({
                        img: true,
                        src: element.large,
                        alt: element.name,
                        caption: {
                        title: element.name
                        }
                    })
                });

                this.$refs.galleryWindow.openDialog()
            }

        },

        viewEtaHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        viewSoldToHandler(index,eta){
            this.etaData = []
            this.etaData = eta;
            this.$refs.etaWindow.openDialog();
        },
        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            this.openWindow()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        getActiveTabIndex(index){

            const tabs = [1,2,3,0,4,5]
            this.endpoint = 'wallpaper/data?status='+tabs[index];
            this.SetPage(1)
            this.resetData('wallpaper/data?status='+tabs[index]);

        },

        viewSignature(row){
            this.$root.$emit('generate-signature', row);
            /* this.signature = `<table style="border: none;width:487.5pt;">
                    <tbody>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;">
                                <a href="https://www.thatsliving.com/" target="_blank"><img width="234" src="https://media.thatsliving.com/public/uploads/image-png/full-e8ab0a82d701488afeba7abe5a93b024-email-tl.png" alt="Logo"></a>
                            </td>
                            <td style="width: 33pt;padding: 0in;vertical-align: top;">
                                <span style='font-size:12px;font-family:"Arial",sans-serif;color:#222222;'><img src="https://media.thatsliving.com/public/uploads/image-png/full-a7c0b96f0a00e1258ef175463373ae41-sep.png" alt="Logo" style="width: 13px; height: 180px;"></span>
                            </td>
                            <td style="width: 300pt;padding: 0in;vertical-align: top;">
                                <table style="border: none;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:0in 0in 3.75pt 0in;">
                                               <strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;font-weight:bold'>${row.name}</span></strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in 3.75pt 0in;">
                                                <strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;font-weight:bold'>${row.attr.designation} &nbsp;</span></strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>T:&nbsp;</span></strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>+&nbsp;${row.attr.mobile}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>E:&nbsp;</span></strong><span style="color:black;"><a href="mailto:${row.email}"><span style="color:black;">${row.email}</span></a></span><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>&nbsp;| <a href="http://thatsliving.com/" target="_blank"><span style="color:black;">thatsliving.com</span></a></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>Doha | Moscow | Sochi | Krasnodar | Warsaw</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding: 3.75pt 0in 0in;vertical-align: bottom;">
                                                <a href="https://www.instagram.com/thatsliving.qatar/" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-c7f92c7078074631812a134ae84a226c-ig.png" alt="Facebook icon"></a><span style="color:black;">&nbsp;&nbsp;</span><a href="https://www.facebook.com/thatslivingqatar/" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-326c45c2ec9a1564051518d0fdee91fb-fb.png" alt="Instagram icon"></a><span style="color:black;">&nbsp;&nbsp;</span><a href="https://www.youtube.com/channel/UCtVflXeRopCeq1adqRM79AQ" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-2acb727090163613c69c477df30ac1c5-yt.png" alt="Youtbue icon"></a><span style="color:black;">&nbsp;</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>`;
            this.$refs.sigatureWindow.openDialog(); */
        },

         copySignature(){
            this.selectText(this.$refs.signature); // e.g. <div ref="text">
document.execCommand("copy");
        },
        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            },
        openBulkReset(){
            this.$refs.bulkReset.openDialog();
        }

    },
    computed: {
        ...mapGetters('UserModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ])
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>