import { AuthService} from '../../services'
import router from '../../router/index-furnishweb'
const user = JSON.parse(localStorage.getItem('user'))
const initUserState = user ? user : {user_id : null , token : null}

export const AuthModule = {
    namespaced : true,
    state : {
        ...initUserState,
        loading : false
    },
    actions : {
        async login({state,commit},form){
            var self = this
            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                
                if(!loading){
                    commit('LoadingTrue')
                    const params = {
                        email : form.email,
                        password : form.password,
                        location : form.showroom,
                        origin : 'fw'
                    }
                    AuthService.login(params).then((response) => {
                        commit('InitUserData', response.data)
                        commit('LoadingFalse');

                        if(response.data.location !== null){
                            router.push({ name: 'welcome' });
                        }
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async generate({commit},form){
            var self = this
            return await new Promise((resolve,reject) => {
                const loading = loading;
                
                if(!loading){
                    commit('LoadingTrue')
                    const params = {
                        email : form.email,
                        password : form.password,
                        location : form.showroom,
                    }
                    AuthService.login(params).then((response) => {
                        commit('InitUserData', response.data)
                        commit('LoadingFalse');
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async Logout(){
            return await new Promise(() => {
                localStorage.removeItem('user');
                router.push({ name: 'login'}).catch(() => { });
            })
            
        },

        
        
    },
    mutations : {
        InitUserData(state,payload){
            state.user = payload
        },
        LoadingFalse(state){
            state.loading = false
        },
        LoadingTrue(state) {
            state.loading = true
        },
        ClearUserData(state){
            state.user = {}
        }
    },
    getters : {
        user : state => state.user,
        Loading : state => state.loading,
    }
}
