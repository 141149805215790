<template>
  <div class="itmform-container">
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsCustomWindow ref="window" title="Payment Details" windowClass="collection height-auto" scrollClass='collection height-auto' :height="500" :width="850" @dialog-state="dialogState" :group="group">
            <mdbContainer class="mt-3">
                <mdb-row class="mb-2">
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Customer Name" :placeholder="form.customer" vid="name" name="name" v-model="form.customer" size="sm" :disabled="true" />
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Mode of Payment" v-model="form.mode" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMode" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"  @select="getModeSelected($event)"></ThatsMultiSelect>
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Payment Description" v-model="form.method" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"  @select="getMethodSelected($event)"></ThatsMultiSelect>
                      <template v-if="form.method ==='cheque'">
                          <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Cheque Details" :placeholder="form.attr.cheque_details" vid="name" name="name" v-model="form.attr.cheque_details" size="sm"/>
                      </template>
                      <ThatsMoney tabindex="4"  labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="'required'" :validated="validated" :disabled="disableAmount"></ThatsMoney>
                  </mdb-col>    
                  <mdb-col col="6">
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Sales Invoice ID" vid="invoice" name="invoice" v-model="form.invoice_series" size="sm" :disabled="true"/>
                    <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Receipt ID" vid="series" name="series" v-model="form.series" size="sm" :disabled="true"/>
                     <!-- <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="attachment" name="attachment" v-model="form.attachment" :disabled="disabled"></ThatsFile> -->
                      <!-- <template v-if="form.method === 'multi-payment'"> -->
                        <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="form.attr.discount_remarks" size="sm" :disabled="false"/>
                        <template v-if="payment_reference.length > 0 && form.method ==='credit-note'">
                            <ThatsMultiSelect :multiple="false"  labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Payment Ref. No." v-model="form.attr.payment_reference" :reduce="label => label.code" :reduceLabel="'label'" :options="payment_reference" :rules="'required'" :filterable="false" :clearable="false"  @select="getPaymentReferenceSelected"></ThatsMultiSelect>
                        </template>
                        <template v-else>
                          <ThatsInput tabindex="2" labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Payment Ref. No." vid="payment_reference" name="payment_reference" v-model="form.attr.payment_reference" size="sm" :disabled="false" :rules="paymentReferenceRules"/>
                        </template>
                  </mdb-col>    
                </mdb-row>
                <mdb-row >
                  <mdb-col col="9">
                      <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))" :disabled="disabledBtn">Save</mdb-btn>
                      </template>


                      <template v-if="formSavePrintLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save-print'))" :disabled="disabledBtn">Save & Print</mdb-btn>
                      </template>
                     
                      




                      
                  </mdb-col>    
                  <!-- <mdb-col col="3" class="text-right">
                      <template v-if="form.invoice !== null">
                          <template v-if="form.invoice.balance_float > 0">
                            <template v-if="formPayBalanceLoading">
                              <mdb-btn color="elegant" size="sm" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                              </mdb-btn>
                            </template>
                            <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('pay-balance'))">Pay Balance?</mdb-btn>
                            </template>                           
                          </template>
                      </template>
                  </mdb-col> -->    
                </mdb-row>          
              </mdbContainer>
       </ThatsCustomWindow>
          </ValidationObserver>
      
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import ContactsForm from '../contacts/ContactsForm'

import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import unformat from 'accounting-js/lib/unformat.js'
import Fuse from 'fuse.js'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    },
    payment_for : {
      type : String,
      default : 'sales-order'
    }
  },
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,


        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomWindow,
        ThatsMoney,

        ContactsForm, 
        ThatsCustomMultiSelect, 
  },

    data : () => ({
      active : 0,
      disabled : false,
      disabledBtn : false,
      validated : false,
      formOriginal : null,
      form : {
          series : 0,
          date : "",
          mode : "full-payment",
          method : "cash",
          customer : "",
          customer_id : null,
          attr : {
            cheque_details : null, 
            payment_reference : null, 
            discount_remarks : null, 
          },
          credit_notes : [],
          description : null,
          invoice_series : null,
          amount : 0,
          reference : null,
          transaction_id : null,
          transaction: null,
          invoice : {
             series : null,
             balance : 0,
             paid : 0,
             amount : 0,
          },
          
        },
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      formSavePrintLoading : false,
      formPayBalanceLoading : false,
      showError : false,
      paymentMethod : [],
      items : [],
      selected : [],
      customer : [],
      payment_transactions : [],
      transactions : {

        },
      payment_reference : [],
      


    }),

    created : function() {
      var self = this;

      this.form = this.formVar();
      this.currentDateSetter();

      this.optionsHandler();
      this.nextCode()
      //  this.isGeneralManager();

      const {settings} = JSON.parse(localStorage.getItem('user'));
      this.paymentMethod = settings.payment_method
    },



    methods: {

      ...mapActions('TransactionModule',[
        'getNextCodePayment',
      ]),
      ...mapActions('MakePaymentModule',[
        'addPayment'
      ]),
 

      formVar(){
        return {
          series : 0,
          date : "",
          mode : "full-payment",
          method : "cash",
          customer : "",
          customer_id : null,
          attr : {
            cheque_details : null, 
            payment_reference : null,
            discount_remarks : null,  
          },
          credit_notes : [],
          description : null,
          invoice_series : null,
          amount : 0,
          reference : null,
          transaction_id : null,
          transaction : null,
          invoice : {
             series : null,
             balance : 0,
             balance_float : 0,
             paid : 0,
             amount : 0,
          },
          
        }
      },
/* start */

getPaymentReferenceSelected(v){
  /* console.log("payment reference selected")
  console.log(v) */
    const paymentReference = this.payment_reference.filter((e) => {
      return e.code === v;
    })[0];
    
    if(paymentReference && this.form.amount > paymentReference.amount){
        this.form.amount = this.$helpers.formatMoney(paymentReference.amount)
        this.form.mode = 'partial-payment'
    }
    /* unformat(self.form.amount) */

},

getModeSelected(v){
  const {total, invoice} = this.paymentForm.transaction
  this.form.amount = total
  if(invoice !== null && invoice.series !== null){
      this.form.amount = invoice.balance
      }else{
      this.form.amount = total
    }

  /* if(v === 'final-payment'){
    if(invoice !== null && invoice.series !== null){
      this.form.amount = invoice.balance
      }else{
      this.form.amount = invoice.balance
    }
  }else{
    this.form.amount = total
  } */

/* 
 if(v === 'full'){
      if(this.paymentForm.transaction.has_invoice === true && this.paymentForm.transaction.invoice !== null){
        this.form.amount = parseFloat(this.paymentForm.transaction.invoice.balance).toFixed(2)
      }else{
        this.form.amount = parseFloat(this.paymentForm.transaction.total).toFixed(2)
      }
  }else{
    this.form.amount = parseFloat(this.paymentForm.transaction.total).toFixed(2)
  } */
  
},
getMethodSelected(v){
console.log(v)
},
openPaymentWindow(){
  
},
getActiveTabIndex(index){
  console.log(index);
},

fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["mobile", "label"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },


optionsHandler(){
  // console.log(this.form)


  // if(this.)
},

nextCode(){
        var self = this;
        // this.getNextCodePayment().then((response) => {
        //   self.form.series = response.code
        // });
      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      async dispatchPayment(action){
        const self = this;

        self.form.amount = unformat(self.form.amount);
        const params = {...self.form,action : action, payment_for : self.payment_for}


        return new Promise(resolve =>{
          this.addPayment(params).then((response) => {
              self.$ThatsNotify.success({message : "Payment was sucessfully added"});
              self.$emit('success',response)
              
              if(action ==='save-print'){
                self.$emit('print',response.print)
                /* var win = window.open(response.print, '_blank');
                if (win.focus) {
                    win.focus();
                } */
              }
              self.closeDialog();
              self.FormResetter();
              resolve()
          })
        })
      },

      onSubmitForm(action){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            this.disabledBtn = true;
            if (isValid) {


                if(action === 'save'){
                    if(self.formLoading === false){
                      self.formLoading = true
                        self.dispatchPayment(action).then(() => {
                            self.formLoading = false
                            self.disabledBtn = false
                        })
                      }
                }

                if(action ==='save-print'){
                    if(self.formSavePrintLoading === false){
                        self.formSavePrintLoading = true
                        self.dispatchPayment(action).then(() => {
                            self.formSavePrintLoading = false
                            self.disabledBtn = false
                        })
                    }
                }
                
                if(action ==='pay-balance'){
                    if(self.formPayBalanceLoading === false){
                        self.formPayBalanceLoading = true
                        self.dispatchPayment(action).then(() => {
                            self.formPayBalanceLoading = false
                            self.disabledBtn = false
                        })
                    }
                }



                // this.form.description = this.getText();
                    /* if(self.formLoading === false){
                      self.formLoading = true
                      const params = {...self.form,action : action}
                        this.addPayment(params).then((response) => {
                            self.$ThatsNotify.success({message : "Payment was sucessfully added"});
                            self.$emit('success',response)
                            
                            if(action ==='save-print'){
                              var win = window.open(response.print, '_blank');
                              if (win.focus) {
                                  win.focus();
                              }
                            }

                            self.closeDialog();
                            self.FormResetter();
                        })


                    } */
                    
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.showError = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },

      number_format(number, decimals, dec_point, thousands_sep) {
    
            var n = !isFinite(+number) ? 0 : +number, 
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                toFixedFix = function (n, prec) {
                    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
                    var k = Math.pow(10, prec);
                    return Math.round(n * k) / k;
                },
                s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        },

        addSeparatorsNF(nStr, inD, outD, sep){

          /* 
          nStr : This is the number to be formatted. This might be a number or a string. No validation is done on this input.
        inD : This is the decimal character for the string. This is usually a dot but might be something else.
        outD : This is what to change the decimal character into.
        sep : This is the separator, which is usually a comma.
        */
        nStr += '';
        var dpos = nStr.indexOf(inD);
        var nStrEnd = '';
        if (dpos != -1) {
          nStrEnd = outD + nStr.substring(dpos + 1, nStr.length);
          nStr = nStr.substring(0, dpos);
        }
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(nStr)) {
          nStr = nStr.replace(rgx, '$1' + sep + '$2');
        }
        return nStr + nStrEnd;
        },



    },

    computed: {

      ...mapGetters('MakePaymentModule',
      [
        'paymentForm',
        'payment_references',
      ]),

      disableAmount : function() {
        return (this.form.mode === 'full-payment') ? true : false
      },
        paymentMode : function() {
          const {settings} = JSON.parse(localStorage.getItem('user'));
          let mode = settings.payment_mode
            if(this.form.invoice != null && this.form.invoice.series != null && this.form.invoice.balance_float > 0){
                // mode = settings.payment_mode.shift();
               mode = settings.payment_mode.filter(item => {
				        return item.code !== 'full-payment'
            })

            }
          return mode;
        },

        paymentReferenceRules : function(){
            if(this.form.method === 'credit-note'){
              return 'required';
            }
          return '';
        }

      },

    watch: {
      paymentForm : function(v){
          // console.log(v)

          let frm = this.formVar();
          // frm.amount =  parseFloat(v.transaction.total).toFixed(2);
          frm.amount =  unformat(v.transaction.total);
          frm.customer = v.transaction.attr.name
          frm.customer_id = v.transaction.customer_id
          frm.transaction_id = v.transaction.mdbID
          frm.transaction = v.transaction

           if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice.series !== null){

              frm.invoice_series = v.transaction.invoice.series
              frm.invoice = v.transaction.invoice
              frm.invoice.balance_float =  unformat(v.transaction.invoice.balance);
              // frm.amount = parseFloat(v.transaction.invoice.balance).toFixed(2);
              frm.amount = unformat(v.transaction.invoice.balance);
              frm.mode = 'down-payment'
            }
           if(v.transaction.hasOwnProperty('invoice') && v.transaction.invoice !== null && v.transaction.invoice.series !== null){
              frm.mode = 'full-payment'
            }
          frm.attr = {...frm.attr,...v.attr}

          var MyDate = new Date();
          frm.date = MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
          this.form = frm
          // console.log("payment watch form")
          // this.getCustomerCreditNote();
          
      },

      payment_references : function(v){
        this.payment_reference = v
      }

     /*  'form.method' : function(val){
        alert(val)
      } */
    },
}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>