import store from '../../store/furnishweb'
export default function guest({ next, router }) {
  const tokenLocalStorage = JSON.parse(localStorage.getItem('user'));
  
  if (tokenLocalStorage && tokenLocalStorage.location !== null) {
    return router.push({ name: 'welcome' });
  }

  console.log("AppModule");
  store.commit('AppModule/setLayout', 'guest');
  return next();
}