import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const ShipmentReceivedModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        viewLoading: false,
        data: {
            columns: [
                {
                    label: 'DATE',
                    field: 'date',
                    sort: 'asc',
                    width: '200',
                },
                {
                    label: 'DOCUMENT',
                    field: 'series',
                    sort: 'asc',
                    clickable : true
                },
                {
                    label: 'BIN/LOCATION',
                    field: 'bins',
                    sort: 'asc',
                },
                {
                    label: 'PREPARED BY',
                    field: 'author',
                    sort: 'asc',
                },
                {
                    label: 'REMARKS',
                    field: 'remarks',
                    sort: 'asc',
                },

                

            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},
        itemColumns: [
            {
                label: 'SKU/CODE',
                field: 'code',
                sort: 'asc',
                align : 'left'
            },
            {
                label: 'QTY',
                field: 'qty',
                sort: 'asc',
            },
            {
                label: 'BIN',
                field: 'bin_location',
                sort: 'asc',
            },
            {
                label: 'REMARKS',
                field: 'bins',
                sort: 'asc',
            },
           
        ],

    },
    actions: {
        async getRecord({ state, commit, dispatch }, params) {
            const { viewLoading, tblKey } = state
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`document/${params.id}`, params).then((response) => {
                        let responseData = {document_bin : [],...response.data.data};
                        // const incrementTblKey = tblKey + 1
                        // commit('setTblKey', incrementTblKey)
                        commit('setFormRecord', responseData)
                        commit('setViewLoadingFalse')
                        resolve(responseData)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        updateRecord({ state, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const {loading} = state;
                // alert(loading)
                if (!loading) {
                    commit('loadingTrue')
                    ApiService.update(`document/${payload.id}`,payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async deleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.WarehouseModule.Deleteloading;
                if (!loading) {
                    commit('deleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.hash)
                    });
                    ApiService.delete('document',tokens).then((response) => {
                        commit('deleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('deleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        }
    },
    mutations: {
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        deleteLoadingFalse: (state) => {
            state.Deleteloading = false
        },
        deleteLoadingTrue: (state) => {
            state.Deleteloading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setWarehouse: (state, payload) => {
            state.Warehouse = payload
        },

    },
    getters: {
        loading: state => state.loading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        itemColumns: state => state.itemColumns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
    }
}


