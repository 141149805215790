var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"needs-validation",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var passes = ref.passes;
return [_c('Auth',[(_vm.response.error === 0)?[_c('p',{staticClass:"small success-text",domProps:{"textContent":_vm._s(_vm.response.message)}}),_vm._v(" "),_c('router-link',{staticClass:"card-link",attrs:{"to":"/login"}},[_vm._v("Back to Login")])]:_vm._e(),_vm._v(" "),(_vm.response.error === null || _vm.response.error === 1)?[_c('ul',{staticClass:"form-error"},_vm._l((errors),function(err,index){return _c('li',{key:index},[(err.length > 0)?[_vm._v("\n               "+_vm._s(err[0])+"\n            ")]:_vm._e()],2)}),0),_vm._v(" "),_c('ValidationProvider',{attrs:{"vid":"password","name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('mdb-input',{attrs:{"inputClass":_vm.isValid(invalid,_vm.form.new_password),"label":"New Password","type":"password","autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"vid":"confirm_password","name":"confirm_password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('mdb-input',{attrs:{"inputClass":_vm.isValid(invalid,_vm.form.confirm_password),"label":"Confirm Password","type":"password","autocomplete":"off"},model:{value:(_vm.form.confirm_password),callback:function ($$v) {_vm.$set(_vm.form, "confirm_password", $$v)},expression:"form.confirm_password"}})]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[(_vm.Loading)?[_c('mdb-btn',{attrs:{"color":"elegant","size":"sm","rounded":"","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v("\n              Loading...\n            ")])]:[_c('mdb-btn',{attrs:{"color":"elegant","rounded":"","size":"sm"},on:{"click":function($event){return passes(_vm.onSubmitForm)}}},[_vm._v("Change Password")])],_vm._v(" "),_c('router-link',{staticClass:"card-link",attrs:{"to":"/login"}},[_vm._v("Back to Login")])],2)]:_vm._e()],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }