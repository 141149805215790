export const DialogModule = {
    namespaced: true,
    state: {
        dialogIsOpen: false
    },
    mutations: {
        OpenDialog(state) {
            state.dialogIsOpen = true
        },
        CloseDialog(state) {
            state.dialogIsOpen = false
        },
    },
    getters: {
        DialogIsOpen: state => state.dialogIsOpen
    }
}