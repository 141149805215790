<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <XWindow ref="windowCopy" title="Product list copy" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatusCopy" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                </template>
            </template>
            <mdbContainer class="mt-4">
                <mdb-row >
                  <mdb-col col="8">
                    <thats-fieldset>
                       <mdb-row>
                        <mdb-col col="6">
                          <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="SKU/Code" placeholder="SKU/Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" />

                        </mdb-col>
                        <mdb-col col="6">
                          <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0" label="Barcode" placeholder="Barcode" vid="barcode" name="barcode" v-model="form.attr.barcode" :validated="validated" :rules="'required'" :keycode="false" size="sm" />
                        </mdb-col>
                      </mdb-row>
                      <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Item Name" placeholder="Item Name" vid="namex" name="namex" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
                      <ThatsInput tabindex="3" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Dimension" placeholder="WxHxD (cm)" vid="dimension" name="dimension" v-model="form.dimension" :validated="validated" :rules="'required'" size="sm" />
                      <mdb-row>
                        <mdb-col col="3">

                        </mdb-col>
                        <mdb-col col="9">
                            <mdb-row>
                            <mdb-col col="3">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Height" placeholder="0" vid="height" name="height" v-model="form.attr.dimension_height" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                            <mdb-col col="3">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Width" placeholder="0" vid="width" name="width" v-model="form.attr.dimension_width" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                            <mdb-col col="3">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Depth" placeholder="0" vid="depth" name="depth" v-model="form.attr.dimension_depth" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                            <mdb-col col="3">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Diameter" placeholder="0" vid="diameter" name="diameter" v-model="form.attr.dimension_diameter" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                          </mdb-row>


                          <mdb-row>
                            <mdb-col col="4">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Hole" placeholder="0" vid="hole" name="hole" v-model="form.attr.hole" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                            <mdb-col col="4">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Capacity" placeholder="0" vid="capacity" name="capacity" v-model="form.attr.capacity" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>
                            <mdb-col col="4">
                              <ThatsInput labelClass="col-sm-12" fieldClass="col-sm-12 pl-0" label="Weight" placeholder="0" vid="weight" name="weight" v-model="form.attr.weight" :validated="validated" :rules="''" size="sm" :horizontal="true" />
                            </mdb-col>

                          </mdb-row>


                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col col="6">
                          <ThatsPriceInput tabindex="4"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Sales Price" placeholder="0" vid="sale_price" name="sale_price" size="sm" v-model="form.sale_price" :rules="'required'" :validated="validated"/>
                        </mdb-col>
                        <mdb-col col="6">

                        </mdb-col>
                      </mdb-row>
                      <mdb-row>
                        <mdb-col col="6">
                          <ThatsInput tabindex="11" ref="cube_unit" labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Cubic Feet" v-model="form.attr.cube_unit" :rules="''"></ThatsInput>
                        </mdb-col>
                        <mdb-col col="6">
                          <!-- <ThatsPriceInput tabindex="5"  labelClass="col-sm-6" fieldClass="col-sm-6 pl-0" label="Promo Price" placeholder="0" vid="promo_price" name="promo_price" size="sm" v-model="form.promo_price" :rules="''" :validated="validated"/> -->
                        </mdb-col>
                      </mdb-row>


                    </thats-fieldset>

                    <thats-fieldset class="mt-1">
                    <mdb-row>
                      <mdb-col col="6">
                        <ThatsAutocomplete tabindex="6" @newWindow="openBrandForm" @select="onBrandSelect" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Brand" v-model="form.brand" :reduce="brand => brand.code" :reduceLabel="'brand'" :options="brands" :rules="'required'"></ThatsAutocomplete>
                        <ThatsAutocomplete tabindex="8" @newWindow="openCategoryForm" @select="onCategorySelect" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Category" v-model="form.category" :reduce="category => category.code" :reduceLabel="'category'" :options="filterCategory" :rules="'required'"></ThatsAutocomplete>


                      </mdb-col>

                      <mdb-col col="6">
                        <ThatsAutocomplete tabindex="7"  @select="onAreaSelect" :addButton="false" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Area" v-model="form.area" :reduce="area => area.code" :reduceLabel="'area'" :options="filterArea" :rules="'required'"></ThatsAutocomplete>
                        <ThatsAutocomplete tabindex="9" :addButton="false" :appendButton="false" :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Attributes" v-model="form.attr.attribute" :reduce="attribute => attribute.code" :reduceLabel="'attribute'" :options="filterAttributes" :rules="'required'"></ThatsAutocomplete>

                      </mdb-col>
                    </mdb-row>

                    <mdb-row>
                      <mdb-col col="6">
                        <ThatsAutocomplete tabindex="10" @newWindow="openCollectionForm" :addButton="true" :appendButton="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Collections" v-model="form.collection" :reduce="collection => collection.code" :reduceLabel="'collection'" :options="filterCollections" :rules="'required'"></ThatsAutocomplete>
                        <mdb-row>
                          <mdb-col col="4">
                            <label>Materials</label>
                          </mdb-col>
                          <mdb-col col="8">
                            <ThatsVueSelect tabindex="5" @newWindow="openMaterialForm" @select="onSelectMaterial" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.materials" :reduce="material => material.code" :reduceLabel="'material'" :options="materials" :rules="''" :showLabel="false" :clearable="false"></ThatsVueSelect>
                            <ThatsVueSelect tabindex="5" :addButton="false" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.materials1" :reduce="material1 => material1.code" :reduceLabel="'material1'" :options="materials1" :rules="''" :showLabel="false" :clearable="false"></ThatsVueSelect>

                          </mdb-col>
                        </mdb-row>
                        <ThatsInput tabindex="13" ref="autoFinishes" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Finishes" v-model="form.attr.finishes" :rules="''"></ThatsInput>
                        <ThatsInput tabindex="12" ref="autoFabric" labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Fabric" v-model="form.attr.fabric" :rules="''"></ThatsInput>
                        <mdb-row>
                          <mdb-col col="4">
                            <label>Colour</label>
                          </mdb-col>
                          <mdb-col col="8">
                            <ThatsVueSelect tabindex="5" @newWindow="openColourForm" @select="onSelectColour" :addButton="true" :appendButton="false" fieldClass="col-md-12  pl-0" v-model="form.attr.colour" :reduce="colour => colour.code" :reduceLabel="'colour'" :options="colour" :rules="''" :showLabel="false" :clearable="false"></ThatsVueSelect>
                            <ThatsInput tabindex="4" fieldClass="col-sm-12 pl-0" vid="colour1" name="colour1" v-model="form.attr.colour1" :validated="validated" :rules="''" size="sm" :showLabel="false"/>

                          </mdb-col>

                        </mdb-row>
                        <ThatsRadio tabindex="16" :labelClass="'col-md-4'" :fieldClass="'col-md-8  pl-0'" label="Item Type" vid="itemType" orientation="inline" name="itemType" :data="itemType" v-model="form.item_type" :validated="validated" :rules="'required'" size="sm" />
                          <mdb-row>
                            <mdb-col col="6" class="pr-0">
                              <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Hide in website'" vid="itemStatusCopy" orientation="inline" name="itemStatusCopy" :data="[]" v-model="form.website" :validated="validated" :rules="''" size="sm" :multiple="false" />
                                {{form.website}}
                            </mdb-col>
                            <mdb-col col="6">
                              <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'Show in Polish'" vid="visilePolish" orientation="inline" name="visilePolish" :data="[]" v-model="form.attr.visible_polish" :validated="validated" :rules="''" size="sm" :multiple="false" />
                            </mdb-col>
                        </mdb-row>
                      </mdb-col>
                      <mdb-col col="6">
                        <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="description" name="description" v-model="form.description" size="sm" />
                        <ThatsRadio tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" label="Status" vid="itemStatus" orientation="inline" name="itemStatus" :data="itemStatus" v-model="form.status" :validated="validated" :rules="'required'" size="sm" />
                      </mdb-col>
                    </mdb-row>

                    </thats-fieldset>

                 <!--     <thats-fieldset class="mt-1">
                    <label>SEO META</label>

                    <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9  pl-0" vid="meta_title" name="meta_title"  label="Title" v-model="form.attr.meta_title" :validated="validated" :rules="''" size="sm"/>
                    <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9  pl-0" vid="meta_keyword" name="meta_keyword"  label="Keywords" v-model="form.attr.meta_keyword" :validated="validated" :rules="''" size="sm"/>
                    <ThatsTextarea :rows="5" tabindex="15" :labelClass="'col-md-12'" :fieldClass="'col-md-12 item-textarea'" label="Description" placeholder="Description" vid="meta_description" name="meta_description" v-model="form.attr.meta_description" size="sm" />
                    </thats-fieldset> -->

                  </mdb-col>

                  <mdb-col col="4 pl-0">
                    <thats-fieldset>
                      <template v-if="form.images.length > 0">
                    <mdb-carousel
                      :items="form.images.length"
                      controlls
                      :controllIcons="['fas fa-arrow-circle-left fa-2x text-white', 'fas fa-arrow-circle-right fa-2x text-white']"
                    >
                     <template #[i+1] v-for="(img, i) in form.images">
                        <mdb-card :key="i">

                          <div class="custom-control image-checkbox custom-checkbox">
                              <input type="checkbox" class="custom-control-input" @change="onSelect($event,img)" :checked="img.default" :id="'imageCheckbox'+img.mdbID" :value="img.medium">
                              <label class="custom-control-label image-manager" :for="'imageCheckbox'+img.mdbID" title="Set as default">Set as default</label>
                          </div>

                          <div class="custom-control image-delete" @click="removeImage(i,img.default)">
                              <mdb-icon icon="trash"></mdb-icon>
                          </div>


                          <mdb-card-image
                            :src="img.medium"
                            :alt="img.filename"
                          ></mdb-card-image>

                        </mdb-card>
                      </template>




                    </mdb-carousel>
                      </template>
                      <template v-else>
                        <div class="no-image-gallery">
                          <mdb-icon far icon="images" />
                        </div>
                      </template>
                    <a href="javascript:void(0)" tabindex="17" @click="uploadImage"><mdb-icon icon="image"></mdb-icon> Select Image</a>
                 </thats-fieldset>

                 <thats-fieldset class="mt-1">
                    <label>Stock Details</label>
                    <template v-if="formStatusCopy ==='create'">
                    <ThatsNumber tabindex="18" :labelClass="'col-sm-5'" :fieldClass="'col-sm-7 pl-0'" label="Qty" placeholder="Qty" vid="qty" name="qty" v-model="form.qty" :validated="validated" :rules="'required'" size="sm" />
                    </template>

                    <template v-if="formStatusCopy ==='update'">
                    <ThatsNumber tabindex="18" :labelClass="'col-sm-5'" :fieldClass="'col-sm-7 pl-0'" label="Qty" placeholder="Qty" vid="qty" name="qty" v-model="form.stock" :validated="validated" :rules="'required'" size="sm" :disabled="true" />

                        <ThatsInput tabindex="11" labelClass="col-md-5" fieldClass="col-md-7  pl-0" label="Future Stock" v-model="form.future_stock" :rules="''" :disabled="true"></ThatsInput>

                        <div class="form-group input-group-sm row thats-form">

                            <div class="col-md-12">
                                <template v-if="form.order_eta.length > 0">
                                  <table class="el-table">
                                    <tr>
                                        <th>ETA</th>
                                        <th>Qty</th>
                                      </tr>
                                    <template v-for="eta in form.order_eta">
                                      <tr :key="eta.series">
                                        <td>{{eta.eta}}</td>
                                        <td>{{eta.qty}}</td>
                                      </tr>
                                    </template>
                                    </table>
                                </template>
                            </div>
                        </div>

                    </template>


                 </thats-fieldset>
                  </mdb-col>
                </mdb-row>
              </mdbContainer>
       </XWindow>

          </ValidationObserver>

      <GalleryManager ref="gallery" :data="form.images" :group="group2"></GalleryManager>
      <ImageGrid ref="upload" @select="fnImageSelect" :moduleForm="'item-form'" :group="group2"></ImageGrid>

      <CategoryForm ref="categoryForm" :group="group2" @success="categorySuccess"></CategoryForm>
      <BrandForm ref="brandForm" :group="group2" @success="brandSuccess"></BrandForm>
      <CollectionForm ref="collectionForm" :group="group2" @success="collectionSuccess" :show="'product'"></CollectionForm>
      <ColourForm ref="colourForm" :group="group2" @success="colourSuccess"></ColourForm>
      <MaterialForm ref="materialForm" :group="group2" @success="materialSuccess" :show="'product'"></MaterialForm>

  </div>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol, mdbIcon,
        mdbCarousel, mdbCard,
    mdbCardImage,} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid';
import GalleryManager from '../../components/image/GalleryManager';
import Autocomplete from '../../components/Autocomplete';

import Category from '../category/Category'
import CategoryForm from '../category/CategoryForm'
import BrandForm from '../brand/BrandForm'
import CollectionForm from '../collection/CollectionForm'
import { MessageBox } from 'element-ui';

import ColourForm from '../colour/ColourForm'
import MaterialForm from '../material/MaterialForm'
import XWindow from '../../components/thats/XWindow.vue'
export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCarousel,
        mdbCard,
        mdbCardImage,


        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsPriceInput,
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        Autocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsCheckbox,

        ImageGrid,
        GalleryManager,

        Category,
        CategoryForm,
        BrandForm,
        CollectionForm,
        ThatsVueSelect,
        ColourForm,
        MaterialForm,
        XWindow

  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,

      vendors : [],
      brands : [],
      collections : [],
      category : [],
      attributes : [],
      area : [],

      itemType : [
        {label : "Stock", value : "stock"},
        {label : "Services", value : "services"},
      ],

      itemStatus : [
        {label : "New Arrival", value : "2"},
        {label : "Active", value : "1"},
        {label : "Discontinued", value : "0"},
      ],

      materials : [],
      finishes : [],
      fabric : [],
      showError : false,
      materials1 : [],
      colour : [],
      colour1 : [],
    }),

    created : function() {
      var self = this;

      if(this.formStatusCopy == 'create'){
        this.form = this.formVar();
        this.nextCode()
        this.form.parent = this.parent
      }

       this.optionsHandler();


    },
    methods: {

      ...mapActions('ColourModule',[ 'getColour' ]),
            ...mapActions('MaterialModule',[ 'getMaterials' ]),
/*
      ...mapActions('BrandModule',[ 'GetBrands','GetArea' ]), */
      ...mapActions('ItemModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
        'GetBrandRelationship',

      ]),
      ...mapMutations('ItemModule',[
        'SetFormCopyStatusCreate',
        'SetFormRecordCopy',
        'setColour',
        'setMaterials',
      ]),


      formVar(){
        return {
          item_code : 0,
          parent : 0,
          name : null,
          slug : null,
          sale_price : 0,
          promo_price : 0,
          dimension : null,

          brand : null,
          area : null,
          category : null,
          collection : null,

          item_type : 'stock',
          description : "",
          website : false,
          attr : {
              cube_unit : "",
              attribute : "",
              post_views : 0,
              materials : "",
              materials1 : "",
              fabric : "",
              finishes : "",
              sub_colour : "",
              colour : "",
              colour1 : "",
              colour2 : "",

              future_stock : {
                qty : 0,
                eta_text : "",
                eta : []
              },

              dimension_height : "",
              dimension_width : "",
              dimension_depth : "",
              dimension_diameter : "",
              hole : "",
              capacity : "",
              weight : "",
              meta_title : "",
              meta_keyword : "",
              meta_description : "",
              visible_polish : false,
              barcode : "",

          },

          child: [],

          images: [],
          order_eta: [],

          qty : 0,
          status : '1'

        }
      },
/* start */

onSelectMaterial(v){
  const self = this;
  this.form.attr.material1 = ""
  self.materials1 = [];
  const selected = this.materials.filter(i => {
    return i.code === v
  })[0]

  if(selected){
    selected.attributes.forEach((item, index) => {
      const c1 = { material1: item.text, code: item.slug,selected : false }
      self.materials1.push(c1)
      if(index == 0){
          self.form.attr.material1 = c1.code
      }
    })
  }


},
onSelectColour(v){
  const self = this;
  this.form.attr.colour1 = ""
  self.colour1 = [];
  const selected = this.colour.filter(i => {
    return i.code === v
  })[0]

  if(selected){
    selected.attributes.forEach((item, index) => {
      const c1 = { colour1: item.text, code: item.slug,selected : false }
      self.colour1.push(c1)
      if(index == 0){
          self.form.attr.colour1 = c1.code
      }
    })
  }


},
openItemForm(){
this.$refs.itemForm.openDialog()
},
openColourForm(){
this.$refs.colourForm.openDialog()
},
openMaterialForm(){
this.$refs.materialForm.openDialog()
},
materialSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { material: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.materials.push(itemConst)
  this.form.attr.material = v.slug
  self.materials1 = []
  if(v.attr.attributes.length > 0){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { material1: item.text, code: item.slug,selected : false }
      self.materials1.push(c1)
      if(index == 0){
        self.form.attr.material1 = c1.code
      }
    })
  }
},

colourSuccess(response){
  const self = this;
  const v = response.data;
  const itemConst = { colour: v.name, code: v.slug,selected : false, attributes: v.attr.attributes}
  this.colour.push(itemConst);
  this.form.attr.colour = v.slug
  self.colour1 = []
  if(v.attr.attributes.length){
    v.attr.attributes.forEach((item, index) => {
      const c1 = { colour1: item.text, code: item.slug,selected : false }
      self.colour1.push(c1)
      if(index == 0){
        self.form.attr.colour1 = c1.code
      }
    })
  }
},

categorySuccess(v){
    const catregoryRes = v.data;
    if(catregoryRes.brands.length > 0){
      catregoryRes.brands.forEach((id) => {
          const categoryData = {
              code : parseInt(catregoryRes.mdbID),
              category : catregoryRes.name,
              attributes : catregoryRes.attr.attributes,
              brand : parseInt(id),
              area : parseInt(catregoryRes.area),
          }
          this.category.push(categoryData);
      })
    }

},
brandSuccess(v){
    const response = v.data;
    const data = {
              code : parseInt(response.mdbID),
              brand : response.name,
          }
          this.brands.push(data);

          if(response.relationship.area.length > 0){
            response.relationship.area.forEach((el) => {
              this.area.push({
                brand : parseInt(response.mdbID),
                area : el.name,
                code : parseInt(el.mdbID)
              })
            })
          }
},
collectionSuccess(v){
          this.collections.push(v.data);
},
removeImage(i,isDefault){
  var self = this;
 MessageBox.confirm('This will permanently delete the image?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                      self.form.images.splice(i,1)
                    })
},

onSelect(e,r){

  this.form.images.forEach((img,i) => {
      if(r.token_id === img.token_id){
          this.form.images[i].default = 1
      }else{
        this.form.images[i].default  = 0
      }
  })
},

convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
},

openCategoryForm(){
this.$refs.categoryForm.openDialog()
},
openCollectionForm(){
this.$refs.collectionForm.openDialog()
},
openBrandForm(){
this.$refs.brandForm.openDialog()
},
openGalleryManager(){
this.$refs.gallery.openDialog()
},

materialBlur(e){
  console.log(e)
},
 openDialog(){
     alert(1)
        this.$refs.windowCopy.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();

            }

      },

      onBrandSelect(v){
        this.form.area = null;
        this.form.category = null;
        this.form.attr.attribute = null;
        this.form.collection = null
      },

      onAreaSelect(v){
          this.form.category = null;
        this.form.attr.attribute = null;
      },

      onCategorySelect(v){
          this.form.attr.attribute = null;
      },

      openCategory(){
          this.$refs.category.openDialog();
          this.$refs.categoryTable.refreshTable();
      },

      optionsHandler(){
        var self = this;
        self.brands = self.getBrands
        self.area = self.getArea
        self.category = self.getCategory
        self.collections = self.getCollections
/*         self.colour = self.getColour
        self.materials = self.getMaterials */
/*         self.GetBrands().then(response => {
            const brand = [];
            response.forEach(element => {
              brand.push({brand : element.label,code : element.value})
            });
            self.brands = brand
          });
 */
        /* self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
            self.area = response
        })
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
            self.category = response
        })
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
            self.collections = response
        })
          */

        self.getColour({'show_in' : 'product'}).then(response => {

          const c = [];

            if(response.length){
              response.forEach(e => {
                c.push({
                  colour : e.label,
                  code : e.slug,
                  attributes : e.attributes,
                })
              })
              self.colour = c
              self.setColour(c)
            }
        })
        self.getMaterials({'show_in' : 'product'}).then(response => {
           const c = [];
           if(response.length){
              response.forEach(e => {
                c.push({
                  material : e.label,
                  code : e.slug,
                  attributes : e.attributes,
                })
              })
              self.materials = c
              self.setMaterials(c)
           }
        })



      },

      uploadImage(){
        this.$refs.upload.openDialog();
      },

      nextCode(){
        var self = this;
        this.GetNextCode().then((response) => {
          self.form.item_code = response.code
        });
      },




      fnImageSelect(v){

        if(v.length > 0){
          v.forEach((r,i) => {
            const d = {img : true , mdbID : r.mdbID,token_id : r.token_id,filename : r.filename,path : r.path, default : 0, medium : r.medium}
            if(i === 0){
              d.default = 1;
            }
              this.form.images.push(d)
          })

        }
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.windowCopy.closeDialog()
          console.log('swaraj daddy')
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true


                      if(self.formStatusCopy == 'update'){
                         self.StoreRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        this.form.parent = this.parent
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormCopyStatusCreate();
        this.showError = false
      },

      dialogState(state){
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatusCopy == 'create'){
          this.FormResetter();
            this.nextCode();
        }



      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },



    },

    computed: {

      ...mapGetters('ItemModule',
      [
        'formStatusCopy',
        'formRecordCopy',
        'getBrands',
        'getArea',
        'getCategory',
        'getCollections',
/*         'getColour',
        'getMaterials', */
      ]),
      group2 : function(){
        return (this.group + 2);
      },
      filterCollections : function() {
        if(this.form.brand === null){
          return []
        }
        const results = this.collections.filter((item, index) => {
            return item.brand_id === this.form.brand
        })


        const response = [];
        if(results.length > 0){

          results.forEach((el, index) => {
            if(index === 0 && this.formStatusCopy == 'create'){
                this.form.collection = el.value
              }
            response.push({collection : el.name,code : el.mdbID})
          })
        }
        return response;

      },


      filterArea : function() {
        if(this.form.brand === null){
          return []
        }
        const results = this.area.filter((item, index) => {
            return item.brand === this.form.brand
        })

        if(results.length > 0 && this.formStatusCopy == 'create'){
                this.form.area = results[0].code
        }
        return results;
      },

      filterCategory : function() {

        if(this.form.brand === null && this.form.area === null){
          return []
        }

        const results = this.category.filter((item, index) => {
            return item.brand === this.form.brand && item.area === this.form.area
        })

        if(results.length > 0 && this.formStatusCopy == 'create'){
                this.form.category = results[0].code
        }
        return results;
      },

      filterAttributes : function() {
        const response = [];
        if(this.form.brand === null && this.form.area === null && this.form.category === null){
          return response;
        }

        const results = this.category.filter((item, index) => {
            return item.brand === this.form.brand && item.area === this.form.area && item.code === this.form.category
        })

        if(results.length > 0){
            /* if(this.FormStatus == 'create'){
              this.form.attribute = results[0].attributes[0].code
            } */
            results[0].attributes.forEach((el) => {
              response.push({attribute : el.text , code : el.slug});
            })
        }
        return response;
      },


    },

    watch: {
      formRecordCopy : function(){
        const self = this
        if(this.formStatusCopy == 'update'){
          // this.form = this.FormRecord.row;
          /*  this.form = JSON.parse(JSON.stringify(this.formRecordCopy));

           this.form.status = this.form.status.toString() */

           const frm = JSON.parse(JSON.stringify(this.formRecordCopy));
           frm.status = frm.status.toString()

           const newFormUpdate = {...this.form, ...frm}

            if(newFormUpdate.website === 1){
             newFormUpdate.website = true
           }

           if(newFormUpdate.attr.hasOwnProperty('visible_polish') && newFormUpdate.attr.visible_polish === 1){
              newFormUpdate.attr.visible_polish = true
           }else{
             newFormUpdate.attr.visible_polish = false
           }

           if(newFormUpdate.attr.hasOwnProperty('barcode') ){
              newFormUpdate.attr.visible_polish = true
           }else{
             newFormUpdate.attr.visible_polish = false
           }

           this.form = newFormUpdate;

        }

        if(this.formStatusCopy == 'create'){
          this.FormResetter();
        }


      },

      "form.name" : function(v){
        if(this.formStatusCopy === 'create' && v != null){
          this.form.slug = this.convertToSlug(v)
        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}

</style>
