import { ApiHttpService } from '../../services'

import router from '../../router'
import { filter } from 'lodash'
import { param } from 'jquery'
export const UserSalesReportModule = {
    namespaced: true,
    state: {
        formData: null,
        initFormData: {
            id : null,
            name : null,
            mobile : null,
            employee_code : null,
            designation : null,
            location : [],
            ytd : [],
            mtd : [],
            brand_sales : [],
            category_sales : [],
            location_sales : [],
        },
        request: null,
        loading : false,
        filters : {},
        params : {}
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
       
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setFormData : (state, payload)=>{
            state.formData = payload
        }, 
        setFilters : (state, payload) => {
            state.filters = {...state.filters,...payload}
        },
        setParams : (state, payload) => {
            state.params = {...state.params,...payload}
        }
    },
    actions : {
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => {
                dispatch('asyncGetData', endpoint).then((res) => {
                    resolve(res)
                }).catch(error => {

                    console.log("reset error")
                    console.log(error)
                    reject(error)
                })
        });
        },
        async asyncGetData({ commit, state},obj = {}) {
            return await new Promise((resolve, reject) => {
                    commit('setLoading', true)
                    if(obj.hasOwnProperty('filters')){
                        commit('setFilters', obj.filters)
                    }
                    const params = {...obj,filters : state.filters, view : router.app._route.name }
                    const configParams = {
                        req : "UserSalesReportModule/req",
                        loadingTrue : "UserSalesReportModule/loadingTrue",
                        addRequest : "UserSalesReportModule/addRequest",
                        cancelRequest : "UserSalesReportModule/cancelRequest",
                        clearOldRequest : "UserSalesReportModule/clearOldRequest"
                    }
                    const formData = {
                        ...state.initFormData,
                        ...params
                    }
                    commit('setFormData', formData)
                    commit('setParams', params)
                    ApiHttpService.get(`sales-report`, params, configParams).then((response) => {
                        const responseData = {
                            ...formData,
                            ...response.data
                        }
                        commit('setFormData',responseData)
                        resolve(responseData)
                        commit('setLoading', false)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })

            })
        },
    },
    getters: {
        loading : state => state.loading,
        req: state => state.request,
        formData: state => state.formData,
        initFormData: state => state.initFormData,
        filters: state => state.filters,
        params: state => state.params
    }
}
