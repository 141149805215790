<template>
    <div style="max-width:916px;margin:0 auto">
         <mdbCard class="mb-4 mt-5">
            <mdbCardBody>
            <ValidationObserver ref="observer">
                <form class="needs-validation">
                    <validation-provider rules="required">
                        <mdb-input v-model="digitalSignature.name" label="Name" class="mb-1" autocomplete="off" />
                    </validation-provider>

                    <validation-provider rules="required">
                        <mdb-input v-model="digitalSignature.designation" label="Designation" class="mb-1"/>
                    </validation-provider>

                    <validation-provider rules="required">
                        <mdb-input v-model="digitalSignature.phone" label="Mobile" class="mb-1"/>
                    </validation-provider>

                    <validation-provider rules="required">
                        <mdb-input v-model="digitalSignature.email" label="Email" class="mb-1"/>
                    </validation-provider>

                    </form>
                </ValidationObserver>
                
            </mdbCardBody>
         </mdbCard>
        <DigitalSignature :value="digitalSignature"></DigitalSignature>
    </div>
</template>
<script>
import {mdbContainer, mdbInput, mdbCard,mdbCardBody} from 'mdbvue'
import DigitalSignature from '../components/thats/DigitalSignature'
import { ValidationObserver,ValidationProvider } from "vee-validate";
export default {
    components : {
        mdbCard,
        mdbCardBody,
        mdbInput,
        mdbContainer,
        DigitalSignature,
        ValidationObserver,ValidationProvider
    },
    data(){
        return {
            digitalSignature: {
                name: 'Your Name',
                designation: '',
                email: '',
                phone: '',
            }
        }
    },

    mounted() {
        this.digitalSignature = {
                name: 'Your Name',
                designation: 'Designation',
                email: 'your.email@thatsliving.com',
                phone: '+974 0000 0000',
            }
    }
}
</script>