<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Customer Order" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :width="900" :group="group" :formStatus="formStatus" @dialog-state="dialogState">
 <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
              <mdb-btn @click="fnPrint('/customer-order/print/'+form.token_id)" color="elegant" size="sm"  :disabled="viewLoading">Print</mdb-btn>
            </template>
          
            
         </template> 
         
         <mdbContainer class="mt-4">
             <mdb-row >
              <mdb-col col="6">
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Name" vid="name" name="name" v-model="form.customer_details.name" size="sm" :disabled="true" />
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Mobile" vid="mobile" name="mobile" v-model="form.customer_details.mobile" size="sm" :disabled="true" />
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Address" vid="address" name="address" v-model="form.customer_details.address" size="sm" :disabled="true" />
               
              </mdb-col>
              <mdb-col col="6">
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Date" :placeholder="form.date_human" vid="date" name="date" v-model="form.date_human" size="sm" :disabled="true" />
                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="Reference" :placeholder="form.series" vid="series" name="series" v-model="form.series" size="sm" :disabled="true" />
                <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="form.status" :reduce="status => status.code" :reduceLabel="'status'" :options="status" :rules="'required'" :filterable="false" :clearable="false"></ThatsMultiSelect>
                <template v-if="form.status === 'assign-to-sales-personnel'">
                  <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Sales Person" v-model="form.user_id" :reduce="user => user.value" :reduceLabel="'opt'" :options="users" :rules="'required'" :clearable="false" :disabled="false"  @onSearch="searchCustomer" :selected_option="true"></ThatsCustomMultiSelect>
                </template>
              </mdb-col>
             </mdb-row>

             <el-table border :data="form.items" class="input-table mt-2" style="width: 100%" :height="'330px'" :summary-method="getSummaries" show-summary>

                  <el-table-column label="SKU/Code">
                    <template slot-scope="scope">
                      {{scope.row.code}}
                      <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="Item/Description">
                    <template slot-scope="scope">
                      {{scope.row.name}}
                      <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" :disabled="true" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="Qty" :width="80" align="right">
                    <template slot-scope="scope">
                      {{scope.row.qty}}
                      <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.qty" :disabled="true" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="Price" align="right">
                    <template slot-scope="scope">
                      {{scope.row.formatted_price}}
                      <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.price" :disabled="true" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="Total" align="right">
                    <template slot-scope="scope">
                      {{scope.row.formatted_total}}
                      <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.total" :disabled="true" /> -->
                    </template>
                  </el-table-column>
                  
                </el-table>
         
          
          </mdbContainer>
        
       </ThatsWindow>

       <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>


  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer,mdbSelect, mdbRow,mdbCol} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsMultiSelect,ThatsWindow, ThatsImage,ThatsCustomMultiSelect} from '../../components/thats/Thats'
import ImageGrid from '../../components/image/ImageGrid'
// import ImageUpload from '../../components/image/ImageUpload'
import PrintWindow from '../../components/thats/PrintWindow'
export default {
    components: {
        PrintWindow,
        mdbContainer,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsMultiSelect,
        ThatsImage,
        mdbBtn,
        mdbRow,
        ThatsCustomMultiSelect,
        ImageGrid,
        mdbCol  
        
  },
    props : {
      group : {
            type : Number,
            default : 1
        },
    },
    
    data : () => ({
      printUrl : "",
      validated : false,
      activePill : 0,
      getActiveTabIndex : 0,
      form : null,
      file : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      SelectValue : false,

      options : [],
      users : [],
      area : [],
      status : [
            { status : "Processing", code : "for-processing"},
            { status : "Call Client", code : "call-client"},
            { status : "Assign to Sales personnel", code : "assign-to-sales-personnel"},
            { status : "Pending", code : "pending"},
            { status : "Cancel", code : "cancel"},
            { status : "Sales Invoice Issued", code : "sales-invoice-issued"},
      ],
    }),

    created : function() {

      if(this.formStatus == 'create'){
        this.form = this.formVar();
        this.fnGetUsers();
      } 
    },
    methods: {

      ...mapActions('CustomerOrderModule',[
        'storeRecord',
        'updateRecord',
      ]),
      ...mapActions('UserModule',[
        'filterItems',
        'filterEmployees',
      ]),
      ...mapMutations('CustomerOrderModule',[
        'setFormStatusCreate',
      ]),

      formVar(){
        return {
          user_id : null,
          name : "",
          series : null,
          date_human : null,
          items : [],
          status : 'for-processing',
          customer_details : {
            name : null,
            mobile : null,
            address : null
          }
        }
      },
/* start */

    fnGetUsers(){
      const userArray = [];
      this.filterEmployees().then((response) => {
        if(response.length > 0){
          response.forEach((element) => {
              userArray.push({
              opt : element.label + " " + element.mobile,
              label : element.label,
              value : element.code,
              mobile : element.mobile,
            })
          })
        }
      });
      this.users = userArray
      
    },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        this.form.logo = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.FormResetter();

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.updateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                self.FormResetter();
                                

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let [,text] = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.setFormStatusCreate();
      },
      
      dialogState(state){
        this.fnGetUsers();
        if(!state){
          this.FormResetter();
        }
      },
      emitDelete(){
        this.$emit('delete',this.formRecord)
      },

      getSummaries(param) {
        const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [1,2,3,4,5]
        
        let totalCol = 1;
        let totalQty= 2;
        let totalPrice= 3;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 4;
        
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
          
          

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

            

          });

        return sums;
      },

      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },

      fnPrint(url){
        this.printUrl = url
        this.$refs.printWindow.openDialog();
      },

      searchCustomer(){

      }

    },

    computed: {

      ...mapGetters('CustomerOrderModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
    },

    watch: {
      formRecord : function(v){
        if(this.formStatus == 'update'){
          const updateForm = JSON.parse(JSON.stringify(v));
          this.form = {...this.form,...updateForm}
        }
      },

     
    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}
</style>