
<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Delivery Scheduler" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1300" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                 <template v-if="putLoading || viewLoading || deleteLoading">
                   <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                 <template v-if="isAllowed">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('save'))">Save</mdb-btn>
                    <template v-if="form.items.length > 0">
                      <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm('delivery-note'))">Delivery Note</mdb-btn>
                    </template>
                  </template>
                </template>
              </template>

            <template #right-button>
              <template v-if="formStatus == 'update'">
                <template v-if="invoice.payments.length> 0">
                    <span class="invoice-balance">Total Balance : {{ $helpers.addSeparatorsNF(invoice.balance)}} QAR</span>
                </template>
              </template>

              
            </template>
            <span class="invoice-number">Sales Invoice #: {{form.series_display}}</span>

          <div class="mt-1 window-container">
           
            <mdb-tabs class="table-tabs mb-2 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">
                <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">

                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date" vid="date" name="date" v-model="form.transaction.date" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.transaction.attr.name" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.transaction.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.transaction.attr.address" size="sm" :disabled="true"/>
                      
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.transaction.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />                  
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sold By" vid="sold_by" name="sold_by" v-model="form.transaction.sold_by" size="sm" :disabled="true" />
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Order Status" vid="order_status" name="order_status" v-model="form.transaction.status" size="sm" :disabled="true" />
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Payment Status" vid="payment_status" name="payment_status" v-model="form.transaction.attr.payment_status" size="sm" :disabled="true" />
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date Requested" vid="date_request" name="date_request" v-model="form.date" size="sm" :disabled="true" />
                        
                    </mdb-col>    
                  </mdb-row>

                  <el-table ref="transactionItemsTable" border :data="form.transaction.items" class="input-table mt-2" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" :row-key="getRowKey">
                      <el-table-column label="SKU" :width="170">
                        <template slot-scope="scope">
                         <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Name">
                        <template slot-scope="scope">
                          <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" :disabled="true" />
                        </template>
                      </el-table-column>
                        <el-table-column label="Qty" :width="70">
                          <template slot-scope="scope">
                            <input type="number" min="1" :max="scope.row.max" class="form-control grid-input thats-form-control" v-model="scope.row.qty" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Price" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Amount" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Discount" :width="90" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.discount" :disabled="true" />
                            
                          </template>
                        </el-table-column>
                        <el-table-column label="Total Amount" :width="120" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                          </template>
                        </el-table-column>
                      <el-table-column label="Remarks">
                        <template slot-scope="scope">
                          <div v-html="scope.row.status"></div>
                          <!-- <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="true" /> -->
                        </template>
                      </el-table-column>
                    </el-table>
              </template>
              <template :slot="'invoice-slot'">
                 
                 <mdb-row class="tab-row">
                    <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.transaction.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.transaction.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.transaction.attr.address" size="sm" :disabled="true"/>
                    </mdb-col>    
                    <mdb-col col="6">
                      
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instruction" name="instruction" v-model="form.transaction.attr.instructions" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Other Details" vid="other_details" name="other_details" v-model="form.transaction.attr.other_details" size="sm" :disabled="true"/>
                    </mdb-col>    
                    
                  </mdb-row>
                  <el-table border :data="form.transaction.invoice.payments" class="input-table mt-2" style="width: 100%" :height="'250px'">
                    <el-table-column label="Series">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.series" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Date">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.date" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Mode of Payment">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.mode_of_payment" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Payment Description" width="160">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.payment_method" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Amount" :width="100">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" :value="scope.row.formatted_amount" :disabled="true" />
                      </template>
                    </el-table-column>
                     <el-table-column :width="100" label="Receipt">
                        <template slot-scope="scope">
                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                        </template>
                    </el-table-column>
                  </el-table>

              </template>
              <template :slot="'form-attachment'">
                   <mdbContainer>
                     <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="sales-transaction-attachment" name="sales-transaction-attachment" v-model="form.attachment" :disabled="false" :showLabel="false" :accept="'*'"></ThatsFile>
                   </mdbContainer>
              </template>
              <template :slot="'deliveries'">
                   <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">
                      <mdb-row class="tab-row">
                        <mdb-col col="9" class="tab-col pl-0">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7" label="Delivery Date" vid="delivery_date" name="delivery_date" v-model="form.attr.delivery_date" :validated="validated" :rules="'required'" size="sm" :disabled="false"/>
                        </mdb-col>
                        <mdb-col col="3" class="tab-col pr-0">
                            <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-12" label="" :showLabel="false" vid="delivery_time" name="delivery_time" v-model="form.attr.time" size="sm" :disabled="false"/>
                        </mdb-col>
                      </mdb-row>
                      

                      <ThatsMultiSelect :multiple="false"  labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Delivery Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="delivery_status" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"  @select="getDeliveryStatusSelected($event)"></ThatsMultiSelect>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="remarks" name="remarks" v-model="form.description" size="sm" :disabled="false"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Deliver to" vid="delivery_address" name="delivery_address" v-model="form.attr.address" size="sm" :disabled="false"/>
                      
                    </mdb-col>    
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Date Requested" vid="date_request" name="date_request" v-model="form.date" size="sm" :disabled="true" />
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Feedback" vid="customer_feedback" name="customer_feedback" v-model="form.attr.customer_feedback" size="sm" :disabled="false" />
                       <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Process By" vid="process_by" name="process_by" v-model="form.attr.process_by" size="sm" :disabled="true" />
                    </mdb-col>    
                  </mdb-row>

                   <el-table ref="itemsTable" border :data="form.transaction.items" class="input-table mt-2" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getDeliverySummaries" show-summary :cell-class-name="cell" :row-key="getRowKey">
                      <el-table-column type="selection" width="35" :reserve-selection="true" :selectable="canSelectRow"> </el-table-column>
                      <el-table-column label="SKU" :width="150">
                        <template slot-scope="scope">
                         <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.code" :disabled="true" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                            <template slot-scope="scope">
                              <img :src="scope.row.default" style="width:90px" />
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Name">
                        <template slot-scope="scope">
                          {{scope.row.name}}
                        </template>
                      </el-table-column>
                        <el-table-column label="Qty" :width="70">
                          <template slot-scope="scope">
                            <input type="number" min="1" :max="scope.row.max" v-on:change="quantityChange(scope.row,$event)" class="form-control grid-input thats-form-control" v-model="scope.row.remaining" :disabled="disableInput(scope.row)" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Price" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Amount" :width="100" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                          </template>
                        </el-table-column>
                        <el-table-column label="Discount" :width="90" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.discount" :disabled="true" />
                            
                          </template>
                        </el-table-column>
                        <el-table-column label="Total Amount" :width="110" align = "right">
                          <template slot-scope="scope">
                            <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                          </template>
                        </el-table-column>
                      <el-table-column label="Status" :width="110">
                        <template slot-scope="scope">
                          <div v-html="scope.row.status"></div>
                        </template>
                      </el-table-column>

                       <el-table-column label="Location" :width="90" align = "left">
                          <template slot-scope="scope">
                             <template v-if="disableInput(scope.row) !== true">
                            <input type="text" class="form-control grid-input thats-form-control text-left" style="background-color:#fff!important;" v-model="scope.row.dlv_item_location"/>
                            </template>
                            <template v-else>
                              {{scope.row.dlv_item_location}}
                            </template>
                          </template>
                        </el-table-column>
                        <el-table-column label="Remarks" align = "left">
                          <template slot-scope="scope">
                            <template v-if="disableInput(scope.row) !== true">
                            <textarea v-model="scope.row.dlv_item_remarks"></textarea>
                            </template>
                            <template v-else>
                              {{scope.row.dlv_item_remarks}}
                            </template>
                          </template>
                        </el-table-column>

                    </el-table>

                    <label class="mt-1"><strong>DELIVERY NOTES</strong></label>
                    <el-table ref="itemsTable" border :data="form.delivery" class="input-table mt-2" style="width: 100%">
                      
                      <el-table-column label="Series" width="140">
                            <template slot-scope="scope">
                              {{scope.row.series}}
                            </template>
                          </el-table-column>
                      <el-table-column label="Date" width="100">
                            <template slot-scope="scope">
                              {{scope.row.date}}
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Details">
                        <template slot-scope="scope">
                          {{scope.row.delivered_items}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Delivery Remarks">
                        <template slot-scope="scope">
                          {{scope.row.remarks}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Delivery status">
                        <template slot-scope="scope">
                          {{scope.row.status | uppercase}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Delivery Note" width="120" align="center">
                        <template slot-scope="scope">
                          <template v-if="scope.row.status !== 'cancelled'">
                           <mdb-btn @click="fnPrint('/delivery/print-note/'+scope.row.token_id)" color="elegant" size="sm">Print</mdb-btn>
                          </template>
                        </template>
                      </el-table-column>

                      <el-table-column align="center" width="70">
                        <template slot-scope="scope">
                          <template v-if="scope.row.status !== 'cancelled'">
                            <mdb-btn @click="cancelDeliveryNote(scope.row.series)" color="elegant" size="sm" class="m-0">Cancel</mdb-btn>
                          </template>
                        </template>
                      </el-table-column>

                      <template v-if="$helpers.isSystemAdministrator()">
                        <el-table-column align="center" width="70">
                          <template slot-scope="scope">
                            <mdb-btn @click="deleteDeliveryNote(form.series_display,scope.row)" color="elegant" size="sm" class="m-0">Delete</mdb-btn>
                          </template>
                        </el-table-column>
                      </template>


                       
                    </el-table>
              </template>
            </mdb-tabs>
            
          </div>
       </ThatsWindow>
          </ValidationObserver>

      <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-prototype-builtins */

import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect} from '../../components/thats/Thats'
import DatePickerPage from '../../components/thats/DatePickerPage'
import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import PrintWindow from '../../components/thats/PrintWindow'
import Textarea from '../../components/thats/Textarea.vue';
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        DatePickerPage,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCustomMultiSelect, 
        PrintWindow,
        Textarea, 

  },
    
    data : () => ({
      printUrl : '',
      active : 0,
      validated : false,
      formOriginal : null,
      form : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      tabs : [
                { text: 'Sales Details', slot: 'form-slot' },
                { text: 'Payments', slot: 'invoice-slot' },
                { text: 'Attachments', slot: 'form-attachment' },
                { text: 'Deliveries', slot: 'deliveries' },
              ],
      delivery_status : [],
      showError : false,
    }),

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        // this.form.items.push(formItemVar);
        
       /*  this.nextCode()
         */
        this.currentDateSetter();

        
      }
       this.optionsHandler();

        const {settings} = JSON.parse(localStorage.getItem('user'));
        this.delivery_status = settings.delivery_status

        // console.log("create")

        /* if(this.formStatus === 'update'){
            if(this.form.attr.process_by === null){
                this.setPreparedBy()
            }
        } */
    },
    updated(){
      this.toggleCheck()
    },


    methods: {

      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('DeliveryModule',[
        'storeRecord',
        'updateRecord',
        'deleteDeliveryNoteRecord',
        'cancelDeliveryNoteRecord',
        'filterItems',
        'updateStatus',
        'getRecord',
        
      ]),
      ...mapMutations('DeliveryModule',[
        'setFormStatusCreate',
        'setPaymentForm',
        'setFormRecord',
        'setFormStatusUpdate',
      ]),

      formVar(){
        return {
          series : "",
          action : "save",
          date : "",
          attr : {
            process_by : ""
          },
          description : null,
          status : "for-delivery",
          items : [],
          attachment : [],
          transaction : {
            series : "",
            date : "",
            status_index : 0,
            status : "no-remarks",
            attr : {
              name : null,
              mobile : null,
              address : null,
              instruction : null,
              other_details : null,
              payment_status : 'no-payment',
            },
            customer : {
              name : null,
              mobile : null,
              address : null,
            },
            user_id : null,
            sold_by : null,
            customer_id : null,
            payment_method : 'cash',
            items : [],
            attachment : [],
            invoice : {
              series : null
            },
            delivery : {
              address : null,
              time : null,
              date : null,
              remarks : null,
              status : 'for-delivery',
            }
          }
        }
      },

      formItemVar(){
        return {
                default: "",
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                status: null,
                dlv_item_location: "",
                dlv_item_remarks: ""
              }
                      
      },
/* start */
paymentSuccess(response){
  this.setFormRecord(response.data)
  this.$emit('success')
},
updatePaymentSuccess(v){
  const self = this;

  self.getRecord({token_id : self.form.token_id}).then((response) => {
    self.active = 1
  })
},
applyDiscount(v){

  const frm = v.form;
    const qty = frm.qty;
      let price = frm.price;
      let discount = 0;
      let remarks = null;

      if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,itemPromo);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }

      if(frm.discount_type === 'discount'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,frm.custom_discount);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }


   this.$set(this.form.items, v.index, frm)



    /*   if(frm.promotions.length > 0){
        const itemPromo = frm.promotions[0];
        discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          remarks = this.setRemarks(qty,itemPromo);

      }

      this.$set(this.form.items[v.index], 'discount', discount)
      this.$set(this.form.items[v.index], 'remarks', remarks)
      this.$set(this.form.items[v.index], 'total', this.computeTotalAmount(qty, price, discount))
      this.$set(this.form.items[v.index], 'custom_discount', frm.custom_discount) */


},
cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6]
        
        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

       
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }
                return d;
              })
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                 this.form.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
  getDeliverySummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6]
        
        let totalCol = 3;
        let totalQty= 4;
        let totalPrice= 5;
        let totalAmount= 6;
        let totalDiscount= 7;
        let totalTotal= 8;

       
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
         
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }
                return d;
              })
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                
                 this.form.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }

             
            return;
          }

            

          });

          // this.toggleCheck()

        return sums;
      },
getActiveTabIndex(index){
  this.active = index
},


optionsHandler(){
  // this.getPurchaseOrder()
  const self = this;
  // this.dispatchFilterItems({transaction_date : this.form.date})
  // self.fnGetContacts()
},
customLabel({label , mobile}){
  return `${label} – ${mobile}`
},
fnGetContacts(params){
  const self = this;
  const contacts = []
  this.GetContacts(params).then((response) => {
      if(response.length > 0){
        response.forEach((element) => {
           contacts.push({
            opt : element.label + " " + element.mobile,
            label : element.label,
            value : element.value,
            mobile : element.mobile,
            address : element.address,
          })
        })
      }
     
  })

  self.customer = contacts;
},
async setPreparedBy(){
    var self = this;
    const loggedUser = await userData()

    const frm = this.form
    frm.process_by = loggedUser.user.name
    this.form = frm;
},


deleteItem(){

  if(this.selected.length){
      if(this.formStatus === 'update'){
          MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
                this.selected.forEach(element => {
                  var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                  this.form.items.splice(removeIndex, 1);
                });

                if(this.form.items.length <= 0){

                const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }
            })
      }else{
        this.selected.forEach(element => {
                  var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                  this.form.items.splice(removeIndex, 1);
                });

                if(this.form.items.length <= 0){
                  const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }
      }
    

    
  }


// remove object


},

handleSelectionChange(v){
   this.form.items = v
  //  this.form.delivery_status = 'partial-delivery'
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    console.log(getSelected);
},

statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }

  
},

dispatchFilterItems(params){
  const results = [];
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions})
          });
        }
    })
  this.items = results

},

statusKeyUp(v){
// this.getPurchaseOrder(v)

const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(action){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true

                      if(self.formStatus == 'update'){
                        
                        self.form.action = action;

                          self.updateRecord(self.form).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')
                              self.setFormRecord(response.data)
                              self.formLoading = false
                              self.form.items = [];

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.attr.delivery_date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            this.nextCode();
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

/*         console.log("custom");
        console.log(custom_discount); */
        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(url, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },
      
      getDeliveryStatusSelected(v){
        console.log(v)
      },

      toggleCheck(){
        const self = this;


        if(this.form.transaction.hasOwnProperty('items') && this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);
          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              })

               
                
              }else{
                self.$refs.itemsTable.clearSelection();
              } 

          // const indexZero = this.form.transaction.items[0];
          //     this.$refs.itemsTable.toggleRowSelection(indexZero);

        
        }
      },

      getRowKey(row){
        return row.code
      },

      getQtyForDelivery(code){
        // console.log(this.formOriginal)

        const getItemFormOriginal = this.formOriginal.items.filter((i) => {

          // console.log(i)
          return i.code === code
        })[0];


        // const this.formOriginal.items.
        /* if(typeof this.formOriginal.items[index] !== 'undefined'){
            return this.formOriginal.items[index].qty
        }
        return 0; */
/* 
        console.log("qty")
        console.log(code)
        console.log(getItemFormOriginal) */
      },

      quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.remaining);
          if(qty > v.max){
              v.remaining = v.max;
          }
          if(qty < 0){
            v.remaining = 0;
          }
      },

      createDeliveryNote(){

      },

      canSelectRow(row, index){
        if(row.remaining <= 0){
           return false
        }
        return true;
        },
      disableInput(row){
        // console.log(row)
        if(row.remaining <= 0){
           return true
        }
        return false;
        },

        deleteDeliveryNote(series,row){
          const self = this;
            const deliveryNoteRow = [{...row}];
            MessageBox.confirm('This will permanently delete the delivery note. Are you sure you want to continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              self.deleteDeliveryNoteRecord({series : series,rows: deliveryNoteRow}).then((response) => {
                  self.setFormRecord(response.data)
              })
            })

        },
        cancelDeliveryNote(series){
          const self = this;
            // const deliveryNoteRow = [{...row}];
            MessageBox.confirm('Are you sure you want to cancel delivery note?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
              self.cancelDeliveryNoteRecord(series).then((response) => {
                  self.setFormRecord(response.data)
              })
            })

        }

    },

    computed: {

      ...mapGetters('DeliveryModule',
      [
        'putLoading',
        'formStatus',
        'formRecord',
        'viewLoading',
        'deleteLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          /* if(self.formStatus === 'update'){
            if(self.form.invoice !== null && self.form.invoice.series !== null){
              return true;
            }
          } */
          return false;
      },
      invoice : function(){
          let inv = {
          series : "",
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }

        /* if(this.formStatus == 'update'){
            if(this.FormRecord.transactions != null && this.FormRecord.transactions.invoice !== null){
              inv = this.FormRecord.transactions.invoice
            }

        } */
        return inv
      },
      isAllowed : function(){
            if(this.$helpers.isDeliveryTeam() || this.$helpers.isSystemAdministrator()){
                return true
            }
            return false;
        },

      btnText : function(){
          if(this.form.items.length > 0){
              return "Delivery Note";
          }
          return "Save";
      }
    },

    watch: {
      formRecord : function(v){
        const self = this;
        this.active = 0;
        if(this.formStatus == 'update'){
/* 
          console.log("formwatch")
          console.log(v)
           */
         /*  if(v.transactions.invoice !== null && (v.transactions.invoice.hasOwnProperty('series') && v.transactions.invoice.series !== null)){
              this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' },
                { text: 'Sales Invoice', slot: 'invoice-slot' },
                { text: 'Attachments', slot: 'form-attachment' }
              ]
            }else{
              
             
              this.form.transactions.invoice = {
                  series : null,
                  date : "",
                  balance : 0,
                  paid : 0,
                  attachment : [],
                  payments : [],
              }
            } */
          const jsonParse = JSON.parse(JSON.stringify(this.formRecord));
          // const jsonParse = JSON.parse(JSON.stringify(this.formRecord));
          jsonParse.attr = {...this.form.attr,...jsonParse.attr}


          if(Object.prototype.hasOwnProperty.call(jsonParse.attr,"address") && jsonParse.attr.address === null){
              jsonParse.attr.address =  jsonParse.transaction.attr.address;
          }

          if(jsonParse.attr.process_by === null){
              const loggedUser = userData()
              jsonParse.attr.process_by = loggedUser.user.name;
          }

   

          
          this.form = jsonParse;
            // console.log("json parse");
            // console.log(jsonParse);

          if(this.form.transaction.hasOwnProperty('items') && this.form.transaction.items.length){
              // const indexZero = self.form.transaction.items[0];
            // this.$refs.itemsTable.toggleRowSelection(indexZero);
            this.toggleCheck()
          }
          if(this.form.items.length > 0){
              this.selected = this.form.items.map(i => i.code);
          }

          self.formOriginal = JSON.parse(JSON.stringify(jsonParse))
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
        }
      },

      'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              }).reduce((a,b) => {
                return a + b;
              })
              this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
            }

          },
          deep: true
      },

      'form.attachment' : {
        handler : function(v){
            // alert("attachment")
        },
        deep: true
      }

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}
</style>