import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const MakePaymentModule = {
    namespaced: true,
    state: {
        loading: false,
        postLoading: false,
        paymentForm : {},
        payment_references : [],
        overpaid_payment_references : [],
    },
    actions: {
        async getNextCodePayment({ dispatch }) {

            return await new Promise((resolve) => {
                ApiService.get('sales/payment/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async addPayment({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    //`${payload.payment_for}/payment`
                    ApiService.store(`incoming-payments`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addPaymentWithTransaction({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.postLoading;
                if (!loading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(`${payload.payment_for}/payment-with-transaction`,payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updatePayment({ state, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.update('sales/update-payment',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        getCustomerCreditNotes({ commit, dispatch }, params) {
            return new Promise((resolve) => {
                ApiService.get(`sales/credit-note/${params.token_id}`).then((response) => {
                    commit('setPaymentReference',response.data.data)
                    resolve(response.data.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        getCustomerOverPaid({ commit, dispatch }, params) {
            return  new Promise((resolve) => {
                ApiService.get(`sales/overpaid/${params.invoice.series}/${params.attr.cx_series}`).then((response) => {
                    commit('setOverpaidPaymentReference',response.data.data)
                    resolve(response.data.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.loading = false
        },
        setLoadingTrue: (state) => {
            state.loading = true
        },
      
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        
        setPaymentForm: (state, payload) => {
            state.paymentForm = payload
        },
        setPaymentReference: (state, payload) => {
            state.payment_references = payload
        },
        setOverpaidPaymentReference: (state, payload) => {
            state.overpaid_payment_references = payload
        },

    },
    getters: {
        postLoading: state => state.postLoading,
        paymentForm: state => state.paymentForm,
        payment_references: state => state.payment_references,
        overpaid_payment_references: state => state.overpaid_payment_references,
    }
}


