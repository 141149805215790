import { ApiHttpService } from '../../services'
export const CostingToolModule = {
    namespaced: true,
    state: {
        data: {
            columns: [
               /*  {
                    label: 'DocEntry',
                    field: 'DocEntry',
                    sort: 'asc',
                    width: '100'
                }, */
           /*  {
                label: 'DocNum',
                field: 'DocNum',
                sort: 'asc',
                width: '100'
            }, */
            {
                label: 'CardCode',
                field: 'CardCode',
                sort: 'asc',
                width: '200',
                clickable: true
            },
            {
                label: 'Customer',
                field: 'CardName',
                sort: 'asc',
            },
            
            {
                label: 'Currency',
                field: 'DocCurrency',
                sort: 'asc',
                width: '100'
            },
            {
                label: 'ExchangeRate',
                field: 'ExchangeRate',
                sort: 'asc',
                width: '110',
                align : 'right'
            },
            {
                label: 'DateCreated',
                field: 'date_human',
                sort: 'asc',
                width: '200'
            },
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: '150'
            },
            

            ],
            rows: [],
        },
        multipleSelection: [],
        length: 25,
        page: 1,
        total: 1,
        searchTerm: null,
        filters: {},
        loading : false,
        postLoading : false,
        form : {
            CardCode : null,
            CardName : null,
            DocTotal : 0,
            DocNum : null,
            PostingDate : null,
            DocCurrency : null,
            CurrencySymbol : null,
            ExchangeRate : null,
            
            attr : {
                FreightCharge : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                CustomDuty : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                CustomClearance : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                ShippingLine : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,

                },
                Remarks : null,
                Multiplier : {
                    active : false,
                    value : 0
                },
                StandardDiscount : {
                    active : false,
                    value : 0
                }
              },
            items :[],
            rowObject : null,
            status : 'draft',
        },


        initialFormData : {
            CardCode : null,
            CardName : null,
            DocTotal : null,
            DocNum : null,
            PostingDate : null,
            Currency : null,
            ExchangeRate : 0,
            attr : {
                FreightCharge : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                CustomDuty : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                CustomClearance : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                ShippingLine : {
                    active : false,
                    distribution : 'volume',
                    foreign_currency :  'QAR',
                    amount : 0,
                    currency :  'QAR',
                    rate : 1,
                    value : 0,
                },
                Remarks : null,
                Multiplier : {
                    active : false,
                    value : 0
                },
                StandardDiscount : {
                    active : false,
                    value : 0
                }
              },
            items :[],
            rowObject : null,
            status : 'draft',
          },

          getLoading : false,
          getRecordLoading : false,
          putLoading : false,

          formStatus : 'create'

        
    },
    mutations: {
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}
            
        },
        cancelRequest : (state) => {
            state.request.cancel()
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
        setData(state, payload) {
            state.data = payload
        },
        loadingTrue(state) {
            state.loading = true
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setColumns(state, payload) {
            state.data.columns = payload
        },
        setRows(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setSearchTerm: (state, payload) => {
            state.searchTerm = payload
        },
        setSelection: (state, payload) => {
            state.multipleSelection = payload
        },
        setFilters: (state, payload) => {
            state.filters = payload
        },
        setEmptyData: (state) => {
            state.data.rows = []
        },

        setFormData : (state, payload)=>{

            state.form = payload
        },
        setFormDataItems : (state, payload)=>{
            state.form.items = payload
        },
        setFormRowObject : (state, payload)=>{
            state.form.rowObject = payload
        },
        setEmptyForm : (state) => {
            state.form = JSON.parse(JSON.stringify(state.initialFormData))
        },

        setGetloadingTrue(state) {
            state.getLoading = true
        },
        setGetloadingFalse(state) {
            state.getLoading = false
        },
        setTerm: (state, payload) => {
            state.searchTerm = payload
        },

        setPutLoadingTrue : (state) => state.putLoading = true,
        setPutLoadingFalse : (state) => state.putLoading = false,

        setFormStatusCreate : (state) => state.formStatus = 'create',
        setFormStatusUpdate : (state) => state.formStatus = 'update',
        setGetRecordGetLoading : (state,payload) => state.getRecordLoading = payload,
    },
    actions : {
        resetData({dispatch}, endpoint){
            return new Promise((resolve, reject) => { 
                dispatch('asyncGetData', endpoint).then((res) => {

                    console.log(res)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
        });
        },
        async asyncGetData({ state, commit},endpoint) {

            const { loading, length, page, filters, searchTerm } = state
            return await new Promise((resolve, reject) => {
                if (loading === false) {
                    commit('setLoading', true)
                    commit('setRows', [])
                    commit('setFormStatusUpdate')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }

                    const configParams = {
                        req : "CostingToolModule/req",
                        loadingTrue : "CostingToolModule/loadingTrue",
                        addRequest : "CostingToolModule/addRequest",
                        cancelRequest : "CostingToolModule/cancelRequest",
                        clearOldRequest : "CostingToolModule/clearOldRequest"
                    }

                    ApiHttpService.get(endpoint, params, configParams).then((response) => {
                        /* commit('setColumns', response.data.columns)
                        commit('setPage', response.data.data.current_page)
                        commit('setTotal', response.data.data.total) */
                        commit('setRows', response.data.data)
                        commit('setLoading', false)
                        
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoading', false)
                        reject(error)
                    })
                }
            })
        },


        async getArea({},obj) {

            return await new Promise((resolve, reject) => {
                    ApiHttpService.getRequest('new-item/area', obj).then((response) => {
                        resolve(response.data)
                    }).catch(error => {
                        reject(error)
                    })
            })
        },
        async getPuchaseOrderByCode({ state, commit},obj) {

            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue', true)
                    ApiHttpService.getRequest('get-purchase-orders', obj).then((response) => {
                        commit('setGetloadingFalse', false)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },


        async getPuchaseOrder({ state, commit},obj) {
            const { getLoading} = state
            return await new Promise((resolve, reject) => {
                if (getLoading === false) {
                    commit('setGetloadingTrue')
                    ApiHttpService.getRequest('get-purchase-orders-by-vendor', obj).then((response) => {
                        commit('setGetloadingFalse', false)
                        commit('setFormRowObject',response.data.rowObject)
                        const valueData = response.data.data.map((item, index) => {
                            // item.Picture = '<img src="'+item.Image+'" style="height:50px;">' 
                            return {
                                Picture : '<img src="'+item.Image+'" style="height:50px;">',
                                PoNumber : item.PoNumber,
                                ItemCode : item.ItemCode,
                                /* Picture : item.Picture, */
                                ItemDescription : item.ItemDescription,
                                Quantity : item.Quantity,
                                ItemDimensions : item.ItemDimensions,
                                UnitCubicMeter : item.UnitCubicMeter,
                                Volume : item.Volume,
                                UnitPrice : item.UnitPrice,
                                DiscountPercent : item.DiscountPercent,
                                UnitPriceAfterActualDiscount : item.UnitPriceAfterActualDiscount,
                                TotalPriceAfterDiscount : item.TotalPriceAfterDiscount,
                                FabricRequirement : item.FabricRequirement,
                                CostPerLinearMeter : item.CostPerLinearMeter,
                                TotalFabricCost : item.TotalFabricCost,
                                CurrentCostPrice : item.CurrentCostPrice,
                                StandardDiscount : item.StandardDiscount,
                                UnitPriceAfterStandardDiscount : item.UnitPriceAfterStandardDiscount,
                                TotalPriceAfterStandardDiscount : item.TotalPriceAfterStandardDiscount,
                                UnitFreightCharges : item.UnitFreightCharges,
                                CustomsDuty : item.CustomsDuty,
                                ShippingLineCharges : item.ShippingLineCharges,
                                CustomClearanceCharges : item.CustomClearanceCharges,
                                UnitPricewithShippingCustomsDuty : item.UnitPricewithShippingCustomsDuty,
                                Multiplier : item.Multiplier,
                                MultiplierOnLandedCost : item.MultiplierOnLandedCost,
                                RetailPriceFC : item.RetailPriceFC,
                                RetailPriceLC : item.RetailPriceLC,
                                CurrentMultiplierOnLandedCost : item.CurrentMultiplierOnLandedCost,
                                CurrentRetailPrice : item.CurrentRetailPrice,
                                Remarks : item.Remarks,
                                LineNum : item.LineNum,
                                ExchangeRate : item.ExchangeRate,
                                LineTotal : item.LineTotal,
                                DocEntry : item.DocEntry,
                                Id : item.Id,
                            };
                          });

                        commit('setFormDataItems',valueData)
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setGetloadingFalse', false)
                        reject(error)
                    })
                }
            })
        },

        async storeRecord({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const postLoading = state.postLoading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    const parsePayload = JSON.parse(JSON.stringify(payload))


                    const configParams = {
                        req : "CostingToolModule/req",
                        loadingTrue : "CostingToolModule/loadingTrue",
                        addRequest : "CostingToolModule/addRequest",
                        cancelRequest : "CostingToolModule/cancelRequest",
                        clearOldRequest : "CostingToolModule/clearOldRequest"
                    }


                    ApiHttpService.post('costing',parsePayload,configParams).then((response) => {
                        commit('setPostLoadingFalse')
                        let responseData = {...response.data.data};
                        // commit('setFormRecord', responseData)
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        updateRecord({ state, commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                const putLoading = state.putLoading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    
                    const updatePayload = JSON.parse(JSON.stringify(payload))

                    ApiHttpService.update(`costing/${updatePayload.id}`,updatePayload).then((response) => {
                        commit('setPutLoadingFalse')
                        let responseData = {...response.data.data};
                        resolve(responseData)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async getRecord({state,commit},obj){
            return await new Promise((resolve,reject) => {
                const getLoading = state.getRecordLoading;

                if(!getLoading){
                    commit('setGetRecordGetLoading', true);

                    ApiHttpService.getRequest(`get-purchase-orders-by-vendor-from-portal/${obj.id}`).then((response) => {
                        commit('setGetloadingFalse', false)

                        commit('setFormRowObject',response.data.rowObject)

                        const valueData = response.data.data.map((item, index) => {
                            //item.Picture = '<img src="'+item.Image+'" style="height:50px;">'

                            
                            return {
                                    Picture : '<img src="'+item.Image+'" style="height:50px;">',
                                    PoNumber : item.PoNumber,
                                    ItemCode : item.ItemCode,
                                    /* Picture : item.Picture, */
                                    ItemDescription : item.ItemDescription,
                                    Quantity : item.Quantity,
                                    ItemDimensions : item.ItemDimensions,
                                    UnitCubicMeter : item.UnitCubicMeter,
                                    Volume : item.Volume,
                                    UnitPrice : item.UnitPrice,
                                    DiscountPercent : item.DiscountPercent,
                                    UnitPriceAfterActualDiscount : item.UnitPriceAfterActualDiscount,
                                    TotalPriceAfterDiscount : item.TotalPriceAfterDiscount,
                                    FabricRequirement : item.FabricRequirement,
                                    CostPerLinearMeter : item.CostPerLinearMeter,
                                    TotalFabricCost : item.TotalFabricCost,
                                    CurrentCostPrice : item.CurrentCostPrice,
                                    StandardDiscount : item.StandardDiscount,
                                    UnitPriceAfterStandardDiscount : item.UnitPriceAfterStandardDiscount,
                                    TotalPriceAfterStandardDiscount : item.TotalPriceAfterStandardDiscount,
                                    UnitFreightCharges : item.UnitFreightCharges,
                                    CustomsDuty : item.CustomsDuty,
                                    ShippingLineCharges : item.ShippingLineCharges,
                                    CustomClearanceCharges : item.CustomClearanceCharges,
                                    UnitPricewithShippingCustomsDuty : item.UnitPricewithShippingCustomsDuty,
                                    Multiplier : item.Multiplier,
                                    MultiplierOnLandedCost : item.MultiplierOnLandedCost,
                                    RetailPriceFC : item.RetailPriceFC,
                                    RetailPriceLC : item.RetailPriceLC,
                                    CurrentMultiplierOnLandedCost : item.CurrentMultiplierOnLandedCost,
                                    CurrentRetailPrice : item.CurrentRetailPrice,
                                    Remarks : item.Remarks,
                                    LineNum : item.LineNum,
                                    ExchangeRate : item.ExchangeRate,
                                    LineTotal : item.LineTotal,
                                    DocEntry : item.DocEntry,
                                    Id : item.Id,
                                };
                            /* return {
                                PoNumber : item.PoNumber,//a

                                ItemCode : item.ItemCode,//b
                                Picture : '<img src="'+item.Image+'" style="height:50px;">' ,//c
                                ItemDescription : item.ItemDescription,//D
                                TxtUnitPrice : item.TxtUnitPrice,//e
                                Quantity : item.Quantity,//f
                                TxtPrice : item.TxtPrice,//g
                                DiscountPercent : item.DiscountPercent,//h
                                TxtLineTotal : item.TxtLineTotal,//i
                                UnitCubicMeter : item.UnitCubicMeter,//j
                                Volume : item.Volume,//k
                                TxtFreightCharge : item.TxtFreightCharge,//l
                                TxtCustomDuty : item.TxtCustomDuty,//m
                                TxtCustomClearance : item.TxtCustomClearance,//n
                                TxtShippingLine : item.TxtShippingLine,//o
                                Multiplier : item.Multiplier,//p
                                TxtUnitCostPrice : item.TxtUnitCostPrice,
                                TxtRetailPriceFc : item.TxtRetailPriceFc,
                                TxtRetailPriceLocal : item.TxtRetailPriceLocal,
                                TxtMultiplierWithoutCost : item.TxtMultiplierWithoutCost,
                                StandardDiscount : item.StandardDiscount,
                                UnitPriceAfterDiscount : item.UnitPriceAfterDiscount,
                                TotalPriceAfterDiscount : item.TotalPriceAfterDiscount,
                                UnitPriceAfterActualDiscount : item.UnitPriceAfterActualDiscount,
                                CurrentRetailPrice : item.CurrentRetailPrice,
                                Remarks : item.Remarks,
                                LineNum : item.LineNum,
                                ExchangeRate : item.ExchangeRate,
                                CurrentCostPrice : item.CurrentCostPrice,
                                ItemDimensions : item.ItemDimensions,//i
                                UnitPrice : item.UnitPrice,//i
                                CurrentMultiplierOnLandedCost : item.CurrentMultiplierOnLandedCost,//i
                            } */
                          });

                          commit('setFormDataItems',valueData)
                        resolve(response.data.data)
                        commit('setGetRecordGetLoading', false)
                    }).catch(error => {
                        commit('setGetRecordGetLoading', false)
                        reject(error)
                    }) 
                }
            })
        }
    },
    getters: {
        rows: state => state.data.rows,
        data: state => state.data,
        columns: state => state.data.columns,
        selection : state => state.multipleSelection,
        loading : state => state.loading,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        searchTerm: state => state.searchTerm,
        filters: state => state.filters,
        req: state => state.request,
        form: state => state.form,
        initialForm: state => state.initialFormData,
        getLoading: state => state.getLoading,
        formData: state => state.form,
        formStatus: state => state.formStatus,
        putLoading: state => state.putLoading,
    }
}



