<template>
    <el-dialog :zIndex="(7)" :modal="true" :fullscreen="true" :title="'Sales Report'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="false" class="text-left tl-dialog tl-dialog-sm">
        <mdbContainer fluid class="pt-2" v-loading="loading">
            <template v-if="form">
                <mdb-card class="m-2 sales-report-card">
                    <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                        <div>
                            <h4 class="dark-grey-text mb-0">{{form.name}}</h4>
                            <p class="text-sm">{{`${form.designation} - ${form.mobile}`}}</p>
                        </div>
                    </mdb-card-body>
                </mdb-card>

               


                    <mdb-tabs class="m-2" :active="popupWindowActiveTab" default @activeTab="fnPopupWindowActiveTab" :links="tabs">
                            <template :slot="'dashboard-slot'">
                            
                            <mdb-card class="">
                                <mdb-card-body class="pt-1 pb-1 d-flex">
                                    <mdbRow>
                                        <mdbCol md="8" lg="8" class="pr-0 mr-0">
                                                <DateRangePicker label="Filter" labelClass="col-sm-2" fieldClass="col-sm-10 pl-0" vid="date_filter" name="date_filter" :value="dateRangeValue" @input="onSelectDateRange" :limit="limitDateRange"></DateRangePicker>
                                        </mdbCol>
                                        <mdbCol class="ml-0 pl-0">
                                            <mdb-btn color="elegant" size="sm" @click="handleDateRangeFilter" class="m-1"><i class="el-icon-search"></i> Search</mdb-btn>
                                        </mdbCol>
                                    </mdbRow>
                                </mdb-card-body>
                            </mdb-card>


                         
                                <mdbRow class="tab-row">
                                    <mdbCol md="4" lg="4" class="pr-0 mr-0 pl-0 ml-0">
                                    <mdb-card class="open-quotation-card mt-2 sales-report-card">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h5 class="dark-grey-text mb-0 card-header-title">YTD SALES</h5>
                                            </mdb-card-body>
                                            <mdb-card-body class="pt-0">
                                                <h1 class="dark-grey-text mb-4">{{currentYear | thousands}}</h1>
                                            </mdb-card-body>
                                        </mdb-card>
                                        <mdb-card class="open-quotation-card mt-2 sales-report-card mt-4">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h5 class="dark-grey-text mb-0 card-header-title">MTD SALES</h5>
                                            </mdb-card-body>
                                            <mdb-card-body class="pt-0">
                                                <h1 class="dark-grey-text mb-4">{{currentMonth | thousands}}</h1>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </mdbCol>
                                    <mdbCol md="8" lg="8" class="pr-0 mr-0">
                                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h5 class="dark-grey-text mb-0 card-header-title">NET SALES</h5>
                                                <!-- <mdb-dropdown class="card-dropdown"> 
                                                    <mdb-dropdown-toggle slot="toggle" color="grey" size="sm"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></mdb-dropdown-toggle>
                                                    <mdb-dropdown-menu class="inactive-reactive-menu">
                                                        <mdb-dropdown-item @click="toggleLineChart = !toggleLineChart">Show/Hide</mdb-dropdown-item>
                                                    </mdb-dropdown-menu>
                                                </mdb-dropdown> --> 

                                            </mdb-card-body>
                                            
                                            <mdb-card-body class="pt-0 border-bottom border-light">
                                                    <LineGraph :key="1" :chartData="lineChartData" :toggle="toggleLineChart" :height="250"></LineGraph>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </mdbCol>
                                </mdbRow>

                                <mdbRow class="tab-row">
                                    <mdbCol md="4" lg="4" class="pr-0 mr-0 pl-0 ml-0">
                                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h4 class="dark-grey-text mb-0 card-header-title">BY BRANDS</h4>
                                            </mdb-card-body>
                                            <mdb-card-body class="p-1">
                                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieBrandChartData" :height="400"></PieGraph>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </mdbCol>
                                    <mdbCol md="4" lg="4" class="pr-0 mr-0">
                                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h4 class="dark-grey-text mb-0 card-header-title">BY CATEGORY</h4>
                                            </mdb-card-body>
                                            <mdb-card-body class="p-1">
                                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieCateogryChartData" :height="400"></PieGraph>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </mdbCol>
                                    <mdbCol md="4" lg="4" class="pr-0 mr-0">
                                        <mdb-card class="open-quotation-by-sales-person-card mt-2 sales-report-card">
                                            <mdb-card-body class="d-flex flex-row align-items-center justify-content-between">
                                                <h4 class="dark-grey-text mb-0 card-header-title">BY LOCATION</h4>
                                            </mdb-card-body>
                                            <mdb-card-body class="p-1">
                                                <PieGraph :key="`brand${pieCtr}`" :chartData="pieLocationChartData" :height="400"></PieGraph>
                                            </mdb-card-body>
                                        </mdb-card>
                                        </mdbCol>
                                </mdbRow>
                            </template>
                            <template :slot="'quotation-slot'">
                                <QuotationTable :endpoint="'quotations'" @open="fnOpenRecord" @print="fnPrint"></QuotationTable>
                            </template>
                            <template :slot="'invoice-slot'">
                                <InvoiceTable :endpoint="'invoices'" @open="fnOpenRecord" @print="fnPrint"></InvoiceTable>
                            </template>
                        </mdb-tabs> 



                <!-- <mdb-card class="m-2 sales-report-card">
                    <mdb-card-body class=""> -->
                         
                  <!--   </mdb-card-body>
                </mdb-card> -->


                              
            </template>
          </mdbContainer>
          <template slot="footer">
              <div class="pl-3 pr-3">
                  <div class="d-flex">
                    <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>
                  </div>
              </div>
          </template>
      </el-dialog>
</template>

<script>
import {mdbBtn, mdbCard,mdbCardBody, mdbContainer, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle, mdbRow, mdbCol, mdbTabs} from "mdbvue";
import {mapGetters, mapMutations, mapActions} from "vuex";
import LineGraph from '../dashboard/LineGraph.vue'
import PieGraph from '../dashboard/PieGraph.vue'
import QuotationTable from '../thats/QuotationTable.vue'
import InvoiceTable from '../thats/InvoiceTable.vue'
import DateRangePicker from '../thats/DateRangePicker'
export default {
    components : {
        mdbRow, mdbCol,
        mdbBtn,
        mdbCard,
        mdbCardBody,
        mdbContainer,
         mdbDropdown, 
         mdbDropdownItem, 
         mdbDropdownMenu, 
         mdbDropdownToggle,
        LineGraph,
        PieGraph,
        QuotationTable,
        InvoiceTable,
        mdbTabs,
        DateRangePicker
    },

    props : {
        tabs : {
            type : Array,
            default : () => [{ text: 'Dashboard', slot : 'dashboard-slot' },{ text: 'Quotations', slot : 'quotation-slot' },{ text: 'Invoices', slot : 'invoice-slot' }]
        } 
    },
    data() {
        return {
            syncModal: false,
            form: null,
            toggleLineChart : true,
            popupInvoiceEndpoint : 'invoices-popup',
            popupQuotationEndpoint : '',
            lineChartData: {
                    labels : [
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December'
                    ],
                    datasets : [],              
            },
            currentYear : 0,
            currentMonth : 0,
            pieBrandChartData : {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ["#e6e6e6"],
                        data: [1],
                        borderWidth : 1
                    }
                ]
            },
            pieCateogryChartData : {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ["#e6e6e6"], 
                        data: [1],
                        borderWidth : 1
                    }
                ]
            },
            pieLocationChartData : {
                labels: [],
                datasets: [
                    {
                        backgroundColor: ["#e6e6e6"], 
                        data: [1],
                        borderWidth : 1
                    }
                ]
            },

            popupWindowActiveTab : 0,

            pieCtr : 0,

            activeYears : [],
            yearMonthValue : {
                fromMonth : 'January',
                toMonth : 'December',
                year : '2024'
            },
            dateRangeValue : {
                from : '',
                to : ''
            },
            limitDateRange : {
                from : '',
                to : ''
            }
            
        }
    },
    methods: {
        ...mapMutations('UserSalesReportModule', {
            setLoading : 'setLoading',
            cancelRequest : 'cancelRequest',
            setSalesReportFilters : 'setFilters'
        }),
        ...mapActions('UserSalesReportModule',{
          getUserSalesReport : 'asyncGetData',
      }),
        ...mapActions('CustomTableModule',{
            getQuotationData : 'resetData',
        }),
        ...mapMutations('CustomTableModule',{
                setQuotationFilters : 'setFilters',
            }),
        ...mapActions('InvoiceTableModule',{
                getInvoiceData : 'resetData',
        }),
        ...mapMutations('InvoiceTableModule',{
                setInvoiceFilters : 'setFilters',
            }),
        passes(callback) {
            if (typeof callback === 'function') {
                callback();
            }
        },
        openDialog(){
            this.syncModal = true;
        },

        closeDialog(){
            this.syncModal = false
        },

        fnPopupWindowActiveTab(v){
            this.popupWindowActiveTab = v
        },
        fnOpenRecord(record){
            console.log('fnOpenRecord')
            console.log(record)
            this.$emit('open', record);
        },

        fnPrint(obj){
            
            console.log('fnPrint')
            console.log(obj)
            
            var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

            let session = JSON.parse(localStorage.getItem('user'));
            let token = session.token;

            const locationId = session.location.id

            if(obj.origin === 'invoice'){
                const win = window.open(`/sales/invoice/${obj.value.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
                win.document.title = 'Print';
                win.print()
            }
            
            if(obj.origin === 'quotation'){
                const win = window.open(`/sales/transactions-v3/${obj.value.token_id}?location=${locationId}&token=${token}`, "_blank", strWindowFeatures);
                win.document.title = 'Print';
                win.print()
            }

        

            // this.printUrl = url
            // this.$refs.printWindow.openDialog();
        },

        onSelectYearMonth(value){
            // console.log("onSelectYearMonth", value)
        },
        onSelectDateRange(value){
            // console.log("onSelectDateRange", value)
            this.dateRangeValue = value;
        },
        handleDateRangeFilter(){            
            this.getUserSalesReport({
                ...this.params,
                ...this.dateRangeValue,
                trigger : false
            })
        }
       
    },
    computed: {
        ...mapGetters('UserSalesReportModule', ['loading','initFormData','formData','params']),
        months : function(){
            return [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ].map((month) => {
                return {
                    text : month,
                    short : month.slice(0, 3),
                    value : month
                }
            })
        },

        
    },
    watch: {
        formData(val){
            if(val){
                this.form = val;
                this.pieCtr += 1;
   
                const ytdSales = val.ytd.filter((item)=>{
                    return item.net > 0;
                }).map((item) => {
                    return {
                        label : item.year,
                        data : Object.values(item.net_monthly),
                        fill : false,
                        borderColor : item.color,
                        backgroundColor : item.color,
                        tension : 0.3,
                        borderWidth : 2
                    };
                });

                this.activeYears = ytdSales.map((item) => item.label);

                this.yearMonthValue.year = this.activeYears[this.activeYears.length - 1];

                const currentDate = new Date(val.from); 
                this.dateRangeValue = {
                    from : val.from,
                    to : val.to
                }

                if(val.ytd.length > 0){
                    this.limitDateRange = val.date_range_limit;
                }

      


                const currentYear = val.ytd.filter(item => item.is_current_year === true);

                console.log('currentYear', currentYear)

                
                if(currentYear.length > 0){
                    this.currentYear = currentYear[0].net;
                    const currentDate = new Date();
                    const currentMonth = currentDate.toISOString().slice(0, 7);
                    const yearMonth = this.dateRangeValue.to.split('-').slice(0, 2).join('-');
                    console.log('yearMonth', yearMonth);

                    this.currentMonth = currentYear[0].net_monthly[yearMonth];
                    
                }
                
                this.lineChartData.datasets = ytdSales;

                const colorPalette = ["#82C272", "#5BBE80", "#34BA8E", "#00A88F", "#009997", "#00889F", "#0087AC", "#0071AE", "#005FAA", "#323B81"];
                // const colorPalette = ["#0A57A4","#0776A3","#00AE82","#7CBF42","#F1BC1A","#EEE921","#F89949","#EB6E7A","#E41B4B","#4F2C7B"];
                // const colorPalette = ["#6785C2","#4777C0","#A374C6","#4FB3E8","#99CF43","#FDC135","#FD9A47","#EB6E7A","#0698ac","#02af82"];
                const brandColors = colorPalette;
                this.pieBrandChartData.datasets[0].backgroundColor = brandColors.slice(0, 10);
                this.pieBrandChartData.datasets[0].data = [];
                this.pieBrandChartData.labels = [];
                val.brand_sales.forEach((item, index) => {
                    if(index <= 9){
                        this.pieBrandChartData.labels.push(item.brand);
                        this.pieBrandChartData.datasets[0].data.push(item.value);
                    }
                });

                if(val.brand_sales.length  <= 0){
                    this.pieBrandChartData.labels = ["No Data"];
                    this.pieBrandChartData.datasets[0].data = [0];
                }

                const categoryColors = colorPalette;
                this.pieCateogryChartData.datasets[0].backgroundColor = categoryColors.slice(0, 10);
                this.pieCateogryChartData.datasets[0].data = [];
                this.pieCateogryChartData.labels = [];
                val.category_sales.forEach((item, index) => {
                    if(index <= 9){
                        this.pieCateogryChartData.labels.push(item.category);
                        this.pieCateogryChartData.datasets[0].data.push(item.value);
                    }
                });
                
                if(val.category_sales.length <= 0){
                    const categoryColors = ['#FF6384'];
                    this.pieCateogryChartData.datasets[0].backgroundColor = categoryColors.slice(0, val.category_sales.length);
                    this.pieCateogryChartData.datasets[0].data = [0];
                }

                const locationColors = colorPalette;
                this.pieLocationChartData.datasets[0].backgroundColor = locationColors.slice(0, 10);
                this.pieLocationChartData.datasets[0].data = [];
                this.pieLocationChartData.labels = [];

                    
                val.location_sales.forEach((item, index) => {
                    if(index <= 9){
                        this.pieLocationChartData.labels.push(item.location);
                        this.pieLocationChartData.datasets[0].data.push(item.value);
                    }
                });
                if(val.location_sales.length  <= 0){
                    const locationColors = ['#FF6384'];
                    this.pieLocationChartData.datasets[0].backgroundColor = locationColors.slice(0, val.location_sales.length);
                    this.pieLocationChartData.datasets[0].data = [0];
                }

                if(this.form.name !== null && this.params.trigger === true){
                    this.setQuotationFilters({
                        dateRange: {},
                        series : "",
                        sold_to : this.form.name,
                        customer_mobile : "",
                        customer_name : "",
                        item_code : "",
                        status : "",
                        balance : false,
                    })
                    this.getQuotationData('quotations')
                    this.setInvoiceFilters({
                        dateRange: {},
                        series : "",
                        sold_to : this.form.name,
                        customer_mobile : "",
                        customer_name : "",
                        item_code : "",
                        status : "",
                        balance : false,
                    })
                    this.getInvoiceData('invoices')
                }

            }
        },
        syncModal(val){
            if(!val){
                this.passes(() => {
                    this.setLoading(false);
                });

                this.pieBrandChartData = {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#e6e6e6"],
                            data: [1]
                        }
                    ]
                }
                this.pieCateogryChartData = {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#e6e6e6"], 
                            data: [1]
                        }
                    ]
                }
                this.pieLocationChartData = {
                    labels: [],
                    datasets: [
                        {
                            backgroundColor: ["#e6e6e6"], 
                            data: [1]
                        }
                    ]
                }
            }
            this.form = this.initFormData;


            
        }
    }
}
</script>

<style scoped lang="scss">

.sales-report-card.card .card-header-title {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}


.tab-content{
    height:auto!important;
}

</style>