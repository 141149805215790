<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="paymentMeansObserver" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog :visible.sync="dialogVisible" ref="window" title="Payment Means" windowClass="collection height-auto " scrollClass='collection height-auto' width="50%" class="payment-dialog" :close-on-click-modal="false" :append-to-body="true">
            <mdbContainer class="mt-3">

                <mdb-row class="mb-2">
                  <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-5 pl-0" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="payment_date" name="payment_date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-5 pl-0" fieldClass="col-md-7  pl-0" label="Payment Method" v-model="form.method" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethods" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"  @select="getMethodSelected($event)"></ThatsMultiSelect>
                      <ThatsMoney tabindex="4"  labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0" label="Amount" placeholder="0" vid="money" name="money" size="sm" v-model="form.amount" :rules="{ required: true, preventZeroValue : true}" :validated="validated" :show_error="true" :disable="true"></ThatsMoney>


                  </mdb-col>
                  <mdb-col col="6">
                   <template v-if="form.method ==='credit-note'">
                            <ThatsMultiSelect :multiple="false" vid="payment-credit-note" name="payment-credit-note"  labelClass="col-md-5" fieldClass="col-md-7  pl-0 pr-0" label="Payment Ref. No." v-model="form.attr.payment_reference" :reduce="label => label.code" :reduceLabel="'label'" :options="payment_reference" :rules="{required : true}"  :filterable="false"  :clearable="false"  @select="getPaymentReferenceSelected" ></ThatsMultiSelect>
                        </template>
                        <template v-else>

                             <template v-if="form.method ==='credit-card'">

                            <ThatsMultiSelect :key="`merchant${fieldKey}`" :multiple="false" vid="mid" name="mid"  labelClass="col-md-5 pl-0" fieldClass="col-md-7  pl-0 pr-0" label="Merchant ID" v-model="form.attr.mid" :reduce="label => label.code" :reduceLabel="'label'" :options="merchants" :rules="{required : true}"  :filterable="false"  :clearable="false"></ThatsMultiSelect>
                            <ThatsMultiSelect :key="`card${fieldKey}`" :multiple="false" vid="payment-card-type" name="payment-card-type"  labelClass="col-md-5" fieldClass="col-md-7  pl-0 pr-0" label="Card Type" v-model="form.attr.card_type" :reduce="label => label.code" :reduceLabel="'label'" :options="cardTypes" :rules="{required : true}"  :filterable="false"  :clearable="false"></ThatsMultiSelect>
                            </template>
                            <template v-if="form.method ==='wire-transfer'">
                              <ThatsInput :key="`bank${fieldKey}`" tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" label="Bank Name" vid="payment-card-type-bank" name="payment-card-type-bank" v-model="form.attr.card_type" size="sm" :disabled="false" :rules="{required : true}"/>
                              <ThatsDatePicker :key="`paymentDate${fieldKey}`" :show_error="showError" tabindex="1" labelClass="col-md-5 pl-0" fieldClass="col-md-7 pl-0" label="Date" placeholder="Date" vid="mid" name="mid" v-model="form.attr.mid" :validated="validated" :rules="'required'" size="sm"/>
                              <ThatsInput :key="`referenceWireTransfer${fieldKey}`" tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" :label="paymentReferenceLabel" vid="payment_reference_wire_transfer" name="payment_reference_wire_transfer" v-model="form.attr.payment_reference" size="sm"  :rules="paymentReferenceRules"/>
                            </template>

                            <template v-if="form.method ==='cheque'">

                              <ThatsInput :key="`chequeBank${fieldKey}`" tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" label="Bank Name" vid="payment-card-type-cheque" name="payment-card-type-cheque" v-model="form.attr.card_type" size="sm" :disabled="false" :rules="{required : true}"/>
                              <ThatsDatePicker :key="`chequeDate${fieldKey}`" :show_error="showError" tabindex="1" labelClass="col-md-5" fieldClass="col-md-7 pl-0 pr-0" label="Date" placeholder="Date" vid="mid" name="mid" v-model="form.attr.mid" :validated="validated" :rules="'required'" size="sm"/>



                            </template>


                             <template v-if="form.method !=='wire-transfer'">
                                <ThatsInput :key="`wireTransferReference${fieldKey}`" tabindex="2" labelClass="col-sm-5 pl-0" fieldClass="col-sm-7 pl-0 pr-0" :label="paymentReferenceLabel" vid="payment_reference" name="payment_reference" v-model="form.attr.payment_reference" size="sm" :disabled="false" :rules="paymentReferenceRules" v-only.number :maxlength="maxLength"/>
                            </template>


                            <template v-if="form.method ==='cheque' && form.attr.realized == false">
                                <mdb-row class="mb-2">
                                  <mdb-col col="7" offset="5" class="pl-0 pr-0">
                                    <mdb-alert color="warning"><p class="small m-0 p-0">Subject to cheque realization</p></mdb-alert>
                                  </mdb-col>
                                </mdb-row>
                          </template>


                        </template>

                  </mdb-col>
                </mdb-row>

              </mdbContainer>

              <template slot="footer">
                <div class="p-0">
                    <div class="d-flex">
                       <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm())" :disabled="disabledBtn">{{buttonLabel}}</mdb-btn>
                    </div>
                </div>
            </template>
    </el-dialog>
  </ValidationObserver>

<!--   </div> -->
</template>
<script>
import {
    mdbBtn,
    mdbContainer,
    mdbRow,
    mdbCol,
    mdbIcon,
    mdbCard,
    mdbTabs,
    mdbAlert,
    mdbModal,
    mdbModalFooter, mdbModalTitle, mdbModalBody, mdbModalHeader
} from "mdbvue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsCustomMultiSelect, ThatsCustomWindow, ThatsMoney} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import unformat from 'accounting-js/lib/unformat.js';

export default {
  props : {
    setFieldValue : {
      type : Function,
      required: true
    },
  },
  inject : ['posShowroom'],
    components: {
        mdbModalHeader, mdbModalBody, mdbModalTitle, mdbModalFooter, mdbModal,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCard,
        mdbTabs,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsPriceInput,
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsCustomWindow,
        ThatsMoney,
        ThatsCustomMultiSelect,
        mdbAlert
  },

    data : () => ({
      active : 0,
        showError: false,
        dialogVisible : false,
        disabled : false,
        disabledBtn : false,
        validated : false,
        formOriginal : null,
        form : null,
        paymentMethods : [],
        fieldKey : 0,
    }),

    created : function() {
      var self = this;
      const newInitialFormData = Object.assign({}, this.initialFormData);
      this.formOriginal = newInitialFormData;
      this.form = newInitialFormData;

      const {settings} = JSON.parse(localStorage.getItem('user'));
      this.paymentMethods = settings.payment_method.filter((method) => ["credit-card","wire-transfer","cash","cheque","tap"].includes(method.code),[]);
    },

    methods: {
      openDialog(){
        this.dialogVisible = true
      },
      closeDialog(){
          this.dialogVisible = false
      },
      dialogState(state){
            this.$refs.paymentMeansObserver.reset();
            const newInitialFormData = Object.assign({}, this.initialFormData);
            this.formOriginal = newInitialFormData;
            this.form = newInitialFormData;
  
            if(state){
              const newFormData = {...newInitialFormData, ...this.formRecord}
              this.formOriginal = newFormData;
              this.form = newFormData;
            }
        },
      onSubmitForm(){
          this.$refs.paymentMeansObserver.validate().then((valid) => {
            if(valid){
              this.setFieldValue({...this.form, reference : this.form.attr.payment_reference});
              this.closeDialog();
            }
          })
      },   

      getMethodSelected(v){
        this.fieldKey++;

        if(v === 'credit-note'){
          this.form.mode = 'no-payment'
        }
        this.form = {
          ...this.form,
          reference : null,
          attr : {
                cheque_details : null,
                payment_reference : null,
                discount_remarks : null,
                realized : false,
                loyalty_card_number : null
            }
        }
      },
    },

    computed: {

      ...mapGetters('PaymentMeansModule',
      [
        'formStatus',
        'formRecord',
        'initialFormData',
        'merchants',
        'cardTypes'
      ]),

      buttonLabel : function(){
        return this.formStatus === 'update' ? 'Update' : 'Add';
      },

        paymentReferenceRules : function(){
            if(this.form.method === 'credit-note' || this.form.method === 'wire-transfer' || this.form.method === 'cheque'){
              return 'required';
            }

            if(this.form.method === 'credit-card'){
              return 'required|length:6';
            }
          return '';
        },
        isCreditNote : function(){
            if(this.form.method === 'credit-note'){
              return true;
            }
          return false;
        },

        paymentReferenceLabel : function(){
          if(this.form.method === 'credit-card'){
            return 'Authorization Code';
          }

           if(this.form.method === 'cheque'){
            return 'Cheque No.';
          }
           if(this.form.method === 'credit-note'){
            return 'CN No.';
          }

          if(this.form.method === 'wire-transfer'){
            return 'Transaction No.';
          }

          return 'Payment Ref. No.'
        },
        maxLength : function(){
            if(this.form.method === 'wire-transfer' || this.form.method === 'cheque'){
              return '255';
            }
            if(this.form.method === 'credit-card'){
              return '6';
            }
            if(this.form.method === 'credit-note'){
              return '10';
            }
          return '50';
        },

      },

    watch: {
      dialogVisible : function(v){
        this.dialogState(v);
      }
    },
}
</script>
