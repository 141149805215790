<template>
  <div>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog :modal="true" :fullscreen="false" :title="'LOCATION SETUP'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" size="small" class="text-left item-dialog">
          <div>
              <div class="p-1">
                  <mdb-navbar class="z-depth-0 p-0 pl-2 rounded-sm justify-content-between m-1 border border-light" style="background-color: #fff;">
                      <mdb-navbar-brand class="table-header-navbar-brand">
                          <mdb-tabs class="table-header-tabs" :active="activeTabIndex" default :links="mdbTabsData" @activeTab="getActiveTab"/>
                      </mdb-navbar-brand>
                  </mdb-navbar>
              </div>

              <div class="dialog-scroll-container">
                  <template v-if="activeTabSlot == 'main'">
                      <mdb-row class="tab-row">


                          <mdb-col lg="9" md="9" sm="12" class="mt-1 pl-0 pr-0">
                              <thats-fieldset class="m-2">
                                  
                                  <ThatsInput :show_error="true" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Showroom Name" vid="name" name="name" v-model="formData.name" :validated="validated" :rules="'required'" size="sm" />
                                  
                                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Phone/Mobile" vid="mobile" name="mobile" v-model="formData.mobile" :validated="validated" :rules="''" size="sm" />
                                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="formData.attr.address" :validated="validated" :rules="''" size="sm" />
                                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Store Timings" vid="storetimings" name="storetimings" v-model="formData.attr.storetimings" :validated="validated" :rules="''" size="sm" />
                                  <ThatsMultiSelect :show_error="true" labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Brands" v-model="formData.attr.brands" :reduce="label => label.value" :reduceLabel="'label'" :options="filterBrands" :rules="'required'"></ThatsMultiSelect>
                                  
                                  <mdb-row>
                                    <mdb-col lg="9" md="9" sm="12" offset-lg="3" offset-sm="3" class="mt-1 pl-0">

                                        <ImageUpload v-model="formData.image" :size="'image-full'"/>
                                        <!-- <FileUpload :files="formData.image" accept="image/png, image/jpeg" @change="onChangeFileUpload"/> -->
                                    </mdb-col>
                                  </mdb-row>
                                  <mdb-row>
                                    <mdb-col lg="9" md="9" sm="12" offset-lg="3" offset-sm="3" class="mt-1 pl-0">
                                        <ThatsCheckbox tabindex="16" :labelClass="'col-md-3'" :fieldClass="'col-md-9  pl-0'" :label="'ACTIVE'" vid="isActive" orientation="inline" name="isActive" :data="[]" v-model="formData.status" :validated="validated" :rules="''" size="sm" :multiple="false"/>
                                    </mdb-col>
                                  </mdb-row>
                                 </thats-fieldset>
                            </mdb-col>
                            
                            <mdb-col lg="3" md="3" sm="12" class="mt-1 pl-0 pr-0">
                                <thats-fieldset class="m-2">
                                    <ThatsSeriesInput labelClass="col-md-12" fieldClass="col-md-12" label="Showroom ID" placeholder="Showrooms Code" vid="series" name="series" v-model="formData.series" :validated="validated" :rules="'required'" size="sm" :disabled="true" />                  
                                    <ThatsInput :show_error="true" labelClass="col-md-12" fieldClass="col-md-12" label="Prefix" vid="prefix" name="prefix" v-model="formData.attr.prefix" :validated="validated" :rules="'required'" size="sm" />
                                    <ThatsInput :show_error="true" labelClass="col-md-12" fieldClass="col-md-12" label="Warehouse Code" placeholder="Warehouse Code" vid="warehouse_code" name="warehouse_code" v-model="formData.attr.warehouse_code" :validated="validated" :rules="'required'" size="sm"/>                  
                                    <ThatsMultiSelect :multiple="false" labelClass="col-md-12" fieldClass="col-md-12" label="SMS MASK" v-model="formData.attr.mask" :reduce="label => label.code" :reduceLabel="'label'" :options="smsMask" :rules="''"></ThatsMultiSelect>
                                </thats-fieldset>
                          </mdb-col>
                      </mdb-row>
                  </template>
              </div>
          </div>
          <template slot="footer">
              <div class="pl-3 pr-3">
                  <div class="d-flex">

                    <template v-if="formLoading || viewLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer"></i> Save</mdb-btn>

                           
                            </template>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                     

                  </div>
              </div>
          </template>
      </el-dialog>
      </ValidationObserver>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver } from "vee-validate";
import { mdbBtn,mdbBadge,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsMultiSelect,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect, FileUpload, ImageUpload} from '../../components/thats/Thats'

import { MessageBox, Message } from 'element-ui';

export default {
    
  components : {
    ImageUpload,
    FileUpload,
      ThatsMultiSelect,
      mdbBadge,
      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardImage,
      ValidationObserver,

       mdbFormInline,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },
      filterFormDataQuotation : {
          type: Object,
          default : () => ({
              searchTermQuot : null
          })
      },
  },
  inject : [],
  data(){
      return {
         
          syncModal : false,
          activeTabIndex : 0,
          activeTabObject : { text: 'Details', tabSlot : 'main' },
          mdbTabsData : [
              { text: 'Details', tabSlot : 'main' }
          ],
          formData : null,
          formDataOrginal : null,
          formLoading : false,
          smsMask : [
              { label : "THATSLIVING", code : "THATSLIVING"},
              { label : "TL REWARDS", code : "TL REWARDS"},
          ],
          readOnly : true,
          showError : true,
          validated : true,
          filterBrands : [],
          count : 0
      }
  },
  created(){
      this.formData = JSON.parse(JSON.stringify(this.formObject))
      this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))
  },
  mounted(){
      const self = this

      
  },
  methods : {

      ...mapActions('LocationSetupModule',['storeRecord','updateRecord','sendTestSMS']),
      ...mapActions('BrandModule',[
        'GetBrandErp'
      ]),
      openDialog(){
          this.syncModal = true
      },
      closeDialog(){
          this.syncModal = false
      },
      resetDefaultTab(){
          this.activeTabIndex = 0,
          this.activeTabObject = { text: 'Details', tabSlot : 'main' }
      },
      getActiveTab(v){
          this.activeTabIndex = v
          this.activeTabObject = this.mdbTabsData[v];
      },
      insertAtCursor(text) {
          this.$nextTick(() => {
              const textarea = this.$refs.textareaWrapper.querySelector('textarea');
              
              if (textarea) {
                  const startPos = textarea.selectionStart;
                  const endPos = textarea.selectionEnd;
                  const textBefore = this.formData.content.substring(0, startPos);
                  const textAfter = this.formData.content.substring(endPos, this.formData.content.length);
                  this.formData.content = textBefore + text + textAfter;
                  const newPos = startPos + text.length;
                  textarea.focus();
                  textarea.setSelectionRange(newPos, newPos);
              }
          });
      },
      

    onSubmitForm(){
      var self =this
            this.validated = true
           this.$refs.observer.validate().then((isValid) => {

            // console.log(isValid)
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){


                          self.storeRecord(self.formData).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response)
                              self.closeDialog();
                              self.formLoading = false
                              /*
                              self.FormResetter(); */

                            }).catch((error) => {
                              self.formLoading = false
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.updateRecord(self.formData).then((response) => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                self.closeDialog();
                                self.$emit('success',response)
                                /* self.FormResetter(); */
                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })


                      }
                    }

            }


            });


    },

    onChangeFileUpload(v) {
      console.log("onChangeFileUpload")
      console.log(v)
    },

  },

  computed : {
      ...mapGetters('LocationSetupModule',{
          formRecord : 'formData',
          viewLoading : 'getLoading',
          postLoading : 'postLoading',
          formObject : 'initialFormObject',
          formStatus : 'formStatus',
      }),


      activeTabSlot : function(){
          return this.activeTabObject.tabSlot
      },

      status : function(){
          return [
              { label : 'Active', value : "active" },
              { label : 'Inactive', value : "inactive" }
          ]
      },


  },

  watch : {
      syncModal : function(value){
        const self = this;

        this.GetBrandErp().then((response) => {
            self.filterBrands = response;
        })

          if(!value){
              this.resetDefaultTab();
              this.formData = JSON.parse(JSON.stringify(this.formObject))
          }

        if(this.formStatus == 'update'){

            
        }
        this.readOnly = (this.$helpers.isSystemAdministrator() === true || this.$helpers.isBackOffice() || this.$helpers.itemCreate()) ? false : true;
    },
    
    formRecord : function(value){
        console.log("formRecord")
        console.log(value)
        
        const itemData = value;
        
        if(itemData.attr === null){
            itemData.attr = {
                test_destination : null
            }
        }
        

          this.formData = {...itemData}
          this.formDataOrginal = JSON.parse(JSON.stringify({...itemData}))
      },

  }
}
</script>

<style scoped>
.click-to-add-logo{
font-size: 14px;cursor: pointer;
}



.custom-control {
  position: absolute!important;
  margin: 10px!important;
}

.custom-control.image-delete{
right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
  border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
padding: 158px 65px;
text-align: center;
}

.no-image-gallery i.far {
font-size: 42px;
color: #959595;
}

</style>
<style scoped>
.tab-col {
    position: relative;
}

.custom-pagination {
    position: absolute;
    bottom: 2px;
    width: 100%;
    text-align: center;
}
</style>


