<template>
    <mdb-container>
        <mdb-row>
            <mdb-col md="4">
                <mdb-card wide class="custom-card-empty">
                    <mdb-view gradient="none" style="position: relative;">
                        <a class="centered-plus"  @click="openWindow">
                            <i class="fa fa-plus" style="color: black;"></i>
                        </a>
                    </mdb-view>
                </mdb-card>
            </mdb-col>
            <mdb-col md="4" v-for="card in this.cards" :key="card.id">
                <a @click="editCard(card)"> <mdb-card wide class="custom-card">
                    <mdb-view gradient="none" cascade style="position: relative;height: 215px;">
                        <div class="bg-image" :style="'background-image: url(' + getImageUrl(card.attr) + ');'"></div>
                        <div v-if="card.status == 'active'" class="dot-and-active">
                            <div class="green-dot"></div>
                            <span class="active-text">Active</span>
                        </div>
                        <div v-else class="dot-and-inactive">
                            <div class="red-dot"></div>
                            <span class="inactive-text">Inactive</span>
                        </div>
                        <h2 class="card-header-title">{{ card.name }}</h2>
                        <p class="card-description">{{ card.description }}</p>
                    </mdb-view>
                </mdb-card></a>
            </mdb-col>
        </mdb-row>
        <TierForm ref="dialogForm"></TierForm>
    </mdb-container>
</template>

<script>
import { mdbContainer, mdbRow, mdbCol, mdbCard, mdbCardImage, mdbCardHeader, mdbCardBody, mdbCardTitle, mdbCardText, mdbCardFooter, mdbCardUp, mdbCardAvatar, mdbCardGroup, mdbBtn, mdbView, mdbMask, mdbIcon } from 'mdbvue';
import TierForm from "./TierForm.vue";
import ThatsTable from "../../components/thats/Table.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {TireModule} from "../../store/modules/tire.module";
import { Loading } from 'element-ui';
export default {
    name: 'Tier',
    components: {
        ThatsTable,
        TierForm,
        mdbContainer,
        mdbRow,
        mdbCol,
        mdbCard,
        mdbCardImage,
        mdbCardHeader,
        mdbCardBody,
        mdbCardTitle,
        mdbCardText,
        mdbCardFooter,
        mdbCardUp,
        mdbCardAvatar,
        mdbCardGroup,
        mdbBtn,
        mdbView,
        mdbMask,
        mdbIcon
    },
    data() {
        return {
        }
    },
    async created() {

    },
    mounted() {
        const loading = Loading.service({
            fullscreen: true ,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)'
        });
        this.asyncGetData().then((res) => {
            console.log("getRecord response")
            console.log(res)
            loading.close();
        });
    },
    methods: {
        ...mapActions('TireModule',[
            'asyncGetData','getLoyaltyById'
        ]),
        ...mapMutations('TireModule',[
            'SetData','SetFormStatusUpdate','SetFormRecord'
        ]),
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        editCard(card) {
            console.log('Clicked card:', card);
            this.SetFormStatusUpdate();
            this.getLoyaltyById(card.series)
            this.$refs.dialogForm.openDialog();
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        getImageUrl(attr) {
            return attr.images;
        }
    },
    computed: {
        ...mapGetters('TireModule',
            [
                'data','FormStatus', 'FormRecord'
            ]),

        cards() {
            return this.data.rows;
        }
},

}
</script>
<style>
.custom-card {
    margin-top: 15px;
    height: 215px;
}
.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.card-header-title,
.card-description {
    position: relative;
    z-index: 1;
    color: black;
}
.dot-and-active {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.green-dot {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    margin-right: 5px;
}

.active-text {
    color: green;
    font-weight: bold;
    font-size: 16px;
}

.dot-and-inactive {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.red-dot {
    width: 10px;
    height: 10px;
    background-color: red;
    border-radius: 50%;
    margin-right: 5px;
}

.inactive-text {
    color: red;
    font-weight: bold;
    font-size: 16px;
}

.centered-plus {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;

}

.custom-card-empty {
    margin-top: 15px;
    width: 350px;
    height: 215px;
    border: 2px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
}



</style>

