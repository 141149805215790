<template>
    <div class="contact-form-container">
        <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <ThatsWindow ref="window" title="Contacts" windowClass="Contacts height-auto" scrollClass='Contacts height-auto' @deleteYes="emitDelete" :width="650" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">
                <template #left-button>
                    <template v-if="formLoading || Loading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                        <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
                    </template>


                </template>

                <mdbContainer class="mt-1">



                    <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
                        <template :slot="'form-slot'">

                            <div class="card card-fieldset mt-1">
                                <div class="card-body">
                                    <ThatsSeriesInput labelClass="col-md-3" fieldClass="col-md-3 pl-0" label="Contact ID" placeholder="Contacts Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" size="sm" :disabled="true" />


                                    <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><el-checkbox v-model="form.attr.corporate">Corporate Customer</el-checkbox></mdb-col></mdb-row>

                                    <template v-if="form.attr.corporate">
                                        <ValidationProvider vid="name" name="name" :rules="'required'" v-slot="{errors}" :key="'corporateValidationProvider'+corportKey">
                                            <ThatsInput :key="'corportateName'+corportKey" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" />

                                            <template v-if="errors.length > 0">
                                                <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                            </template>
                                        </ValidationProvider>
                                    </template>
                                    <template v-else>
                                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-3  pl-0" label="Salutation" v-model="form.attr.salutation" :reduce="label => label.code" :reduceLabel="'label'" :options="salutation" :rules="''"></ThatsMultiSelect>
                                        <ValidationProvider vid="name" name="name" :rules="'required'" v-slot="{errors}" :key="'customer'+corportKey">
                                            <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="First Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" />
                                            <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Middle Name" vid="middle_name" name="middle_name" v-model="form.attr.middle_name" :validated="validated" :rules="''" size="sm" />
                                            <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Last Name" vid="last_name" name="last_name" v-model="form.attr.last_name" :validated="validated" :rules="'required'" size="sm" />
                                            <template v-if="errors.length > 0">
                                                <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                            </template>
                                        </ValidationProvider>
                                    </template>

                                    <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Email" vid="email" name="email" v-model="form.attr.email" :validated="validated" :rules="''" size="sm" />

                                </div>
                            </div>

                            <div class="card card-fieldset">
                                <div class="card-body">

                                    <ValidationProvider vid="attr.contacts.0.value" name="attr.contacts.0.value" :rules="'required'" v-slot="{errors}">
                                        <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Primary Number" vid="attr.contacts.0.valuex" name="attr.contacts.0.valuex" v-model="form.attr.contacts[0]" :rules="'required'" :show_error="true"></InternationalMobile>
                                        <template v-if="errors.length > 0">
                                            <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                        </template>
                                    </ValidationProvider>
                                    <ValidationProvider vid="attr.contacts.1.value" name="attr.contacts.1.value" :rules="''" v-slot="{errors}">
                                        <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Secondary Number" vid="attr.contacts.1.value" name="attr.contacts.1.value" v-model="form.attr.contacts[1]" :rules="''"></InternationalMobile>

                                        <template v-if="errors.length > 0">
                                            <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                                        </template>
                                    </ValidationProvider>

                                    <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Home Tel Number" vid="attr.contacts.2.value" name="attr.contacts.2.value" v-model="form.attr.contacts[2]" :rules="''"></InternationalMobile>
                                </div>
                            </div>

                            <div class="card card-fieldset">
                                <div class="card-body">
                                    <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="form.attr.address" :validated="validated" :rules="''" size="sm" />

                                    <mdb-row >
                                        <mdb-col offset="3" col="3">
                                            <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Bldg" vid="bldg" name="bldg" v-model="form.attr.address2.building" :validated="validated" :rules="''" size="sm" />
                                        </mdb-col>
                                        <mdb-col col="3">
                                            <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Street" vid="street" name="street" v-model="form.attr.address2.street" :validated="validated" :rules="''" size="sm" />
                                        </mdb-col>
                                        <mdb-col col="3">
                                            <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Zone" vid="zone" name="zone" v-model="form.attr.address2.zone" :validated="validated" :rules="''" size="sm" />
                                        </mdb-col>
                                    </mdb-row>
                                    <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Area" vid="area" name="area" v-model="form.attr.address2.area" :validated="validated" :rules="'required'" size="sm" />


                                    <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="City" v-model="form.attr.address2.city" :reduce="label => label.code" :reduceLabel="'label'" :options="cities" :rules="'required'"></ThatsMultiSelect>

                                </div>
                            </div>

                            <div class="card card-fieldset">
                                <div class="card-body">
                                    <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="form.status" :reduce="status => status.code" :reduceLabel="'status'" :options="status" :rules="'required'"></ThatsMultiSelect>

                                </div>
                            </div>

                        </template>

                        <template :slot="'form-contact-person'">


                            <mdb-btn type="button" color="elegant" size="sm" @click="newContactPerson">New Contact Person</mdb-btn>

                            <el-table ref="multipleTable" lazy class="custom-element-table" :data="form.attr.contact_persons" stripe>
                                <el-table-column prop="mobile" label="Phone/Mobile" width="180"></el-table-column>
                                <el-table-column prop="name" label="Contact Name"></el-table-column>
                                <el-table-column label="" width="80" align="right">
                                    <template slot-scope="scope">
                                        <mdb-icon class="cursor-pointer" far icon="edit" color="warning" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'update')"/>
                                        <mdb-icon class="cursor-pointer" far icon="trash-alt" color="danger" @click.native="fnOpenContactPersonWindow(scope.$index,scope.row,'delete')"/>
                                    </template>
                                </el-table-column>
                                <template slot="empty">
                                    No Data
                                </template>
                            </el-table>
                        </template>
                    </mdb-tabs>

                </mdbContainer>


            </ThatsWindow>
        </ValidationObserver>
        <ContactPerson ref="contactPersionWindow" @change="onChangeContactPerson" :group="999"></ContactPerson>
    </div>
</template>
<script>
import { mdbBtn,mdbContainer,mdbIcon,mdbBadge, mdbRow,mdbCol, mdbTabs} from "mdbvue";
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput,ThatsDatePicker, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage} from '../../components/thats/Thats'

import InternationalMobile from '../../components/thats/InternationalMobile.vue'
import ContactPerson from './ContactPerson.vue'
import { MessageBox } from 'element-ui'
export default {
    components: {
        ThatsDatePicker,
        mdbContainer,
        ValidationObserver,
        ValidationProvider,
        ThatsWindow,
        ThatsInput,
        ThatsTextarea,
        ThatsSeriesInput,
        ThatsCheckbox,
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        mdbBtn,
        mdbRow,
        mdbCol,
        InternationalMobile  ,
        mdbTabs,
        mdbIcon,
        mdbBadge,
        ContactPerson

    },

    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    data : () => ({
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        formLoading : false,


        status : [
            { status : "Active", code : "active"},
            { status : "Inactive", code : "inactive"},
        ],

        salutation : [
            { label : "Mr.", code : "Mr."},
            { label : "Ms.", code : "Ms."}
        ],

        cities : [
            {label :"Doha",code : "Doha"},
            {label :"Al-Rayyan",code : "Al-Rayyan"},
            {label :"Al Khor",code : "Al Khor"},
            {label :"Al Wakrah",code : "Al Wakrah"},
            {label :"Umm-Salal",code : "Umm-Salal"},
            {label :"Al-Dayian",code : "Al-Dayian"},
            {label :"Ash-Shamal",code : "Ash-Shamal"}
        ],
        tabs : [
            { text: 'Contact Details', slot: 'form-slot' },
            { text: 'Contact Persons', slot: 'form-contact-person' },
        ],

        active : 0,
        corportKey : 0,

        contactPersonColumns : [

            {
                label: 'Contact Name',
                field: 'name',
                sort: 'asc',
                width: '200',
                clickable: true
            },
            {
                label: 'Address',
                field: 'address',
                sort: 'asc',
            },

            {
                label: 'Phone/Mobile',
                field: 'mobile',
                sort: 'asc',
            },
            {
                label: 'Email',
                field: 'email',
                sort: 'asc',
            },

        ]
    }),

    created : function() {

        if(this.FormStatus == 'create'){
            this.form = this.formVar();
        }

        this.optionsHandler()
    },
    methods: {

        ...mapActions('ContactsModule',[
            'StoreRecord',
            'UpdateRecord',
            'GetNextCode',
        ]),
        ...mapMutations('ContactsModule',[
            'SetFormStatusCreate',
        ]),
        ...mapMutations('ContactPersonModule',{
            contactPersonCreate : 'SetFormStatusCreate',
            contactPersonUpdate : 'SetFormStatusUpdate'
        }),

        formVar(){
            return {
                series : 0,
                name : null,
                mobile : null,
                reference : '',
                description : null,
                image : [],
                attr : {

                    address: null,
                    address2 : {
                        street : null,
                        building : null,
                        zone : null,
                        area : null,
                        city : null,
                    },
                    email : null,
                    origin : 'portal',
                    first_name : null,
                    middle_name : null,
                    last_name : null,
                    salutation : null,
                    corporate : false,
                    contacts : [
                        {
                            type : 'primary',
                            intl : false,
                            code : '+974',
                            value : null
                        },
                        {
                            type : 'secondary',
                            intl : false,
                            code : '+974',
                            value : null
                        },
                        {
                            type : 'home',
                            intl : false,
                            code : '+974',
                            value : null
                        }
                    ],
                    contact_persons : [],
                    nationality : null,
                    qid : null,
                    dob : null,
                },
                status : 'active'
            }
        },
        /* start */


        optionsHandler(){




        },

        uploadImage(){
            this.$refs.upload.openDialog();
        },

        nextCode(){
            var self = this;
            this.GetNextCode().then((response) => {
                self.form.series = response.code
            });
        },

        demo(v){
            console.log(v)
        },

        /* end */
        openDialog(){
            this.$refs.window.openDialog()
        },
        closeDialog(){
            this.$refs.window.closeDialog()
        },
        fnImageSelect(v){
            console.log(v)
            this.form.image = v
        },
        onSubmitForm(){
            var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();


            console.log(isValid)
            if (isValid) {
                // this.form.description = this.getText();
                if(self.formLoading === false){
                    self.formLoading = true
                    if(self.FormStatus == 'create'){
                        self.StoreRecord(self.form).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response.data)
                            self.closeDialog();
                            self.FormResetter();

                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }

                    if(self.FormStatus == 'update'){
                        self.UpdateRecord(self.form).then((response) => {
                            self.$ThatsNotify.success({
                                message : response.message
                            })
                            self.closeDialog();
                            const successConctaForm = JSON.parse(JSON.stringify(self.form));
                            self.$emit('update',successConctaForm)
                            self.FormResetter();
                        }).catch((error) => {
                            self.formLoading = false
                            self.response.error = 1;
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                }
                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })
                    }
                }

            }
        },

        getText() {
            let text = this.$refs.descriptionEditor.$children;
            text = text.$el.innerHTML;
            return text;
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
        },

        dialogState(state){
            if(!state){
                this.FormResetter();
                this.$refs.contactPersionWindow.closeDialog();
                return
            }

            if(this.FormStatus == 'create'){
                this.nextCode();
            }

            this.optionsHandler();

        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },
        getActiveTabIndex(index){
            this.active = index
        },

        setTabArray(value){
            if(value){

                this.tabs = [
                    { text: 'Contact Details', slot: 'form-slot' },
                    { text: 'Contact Persons', slot: 'form-contact-person' },
                ]
            }else{

                this.tabs = [
                    { text: 'Contact Details', slot: 'form-slot' }
                ]
            }
        },

        newContactPerson(){
            this.contactPersonCreate();
            this.$refs.contactPersionWindow.openDialog();
        },
        fnOpenContactPersonWindow(index,row,status){
            var self = this;
            console.log(row)
            if(status === 'delete'){
                MessageBox.confirm('Are you sure you want to delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    self.form.attr.contact_persons.splice(index, 1);
                    self.$ThatsNotify.success({
                        message : "Item has been successfully removed"
                    })
                })
            }else{
                this.contactPersonUpdate({
                    ...row,
                    index : index
                });

                this.$refs.contactPersionWindow.openDialog();
            }
        },
        onChangeContactPerson(data){
            if(data.hasOwnProperty('index')){
                this.form.attr.contact_persons.splice(data.index, 1, data)
            }else{
                this.form.attr.contact_persons.push(data)
            }
        }

    },

    computed: {

        ...mapGetters('ContactsModule',
            [
                'FormStatus',
                'FormRecord',
                'Loading'
            ]),
    },

    watch: {
        FormRecord : function(){
            if(this.FormStatus == 'update'){

                const formRow = this.FormRecord.row;
                if(!formRow.attr.hasOwnProperty('corporate')){
                    formRow.attr.corporate = false
                }
                if(!formRow.attr.hasOwnProperty('contact_persons')){
                    formRow.attr.contact_persons = []
                }


                formRow.attr = {
                    ...formRow.attr,
                    address2 : {
                        street : null,
                        building : null,
                        zone : null,
                        area : null,
                        city : null,
                        ...formRow.attr.address2
                    },
                }


                this.form = JSON.parse(JSON.stringify(formRow));
            }
        },

        "form.attr.contacts" : {
            handler(v){
                // console.log("watch")
                // console.log(v)

                const firstObject  = v[0];

                if(firstObject){
                    this.form.mobile = firstObject.value
                }
            },
            deep : true
        },

        "form.attr.salutation" : function(v){

            let name = '';
            name += (v != "" && v !== null) ? v : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

            this.form.name = name.trim();

        },
        "form.attr.first_name" : function(v){

            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
            name += (v != "" && v !== null) ? " "+v : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

            this.form.name = name.trim();
        },
        "form.attr.middle_name" : function(v){

            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
            name += (v != "" && " "+v !== null) ? " "+v : '';
            name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

            this.form.name = name.trim();
        },
        "form.attr.last_name" : function(v){
            let name = '';
            name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
            name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
            name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
            name += (v != "" && " "+v !== null) ? " "+v : '';

            this.form.name = name.trim();
        },

        "form.attr.corporate" : function(value){
            this.corportKey = this.corportKey + 1
            this.form.attr.last_name = null
            this.form.attr.salutation = null



            // alert(value);
        }


        /*       "form.name" : function(val) {

                if(val !== null){
                  const res = val.substring(0, 4)
                  this.form.short = res.toUpperCase();
                }
              } */

        /* area : {
          immediate : true,
          handler(){
            this.areaHandle();
          }

        } */
    },


}
</script>

<style scoped>
.click-to-add-logo{
    font-size: 14px;cursor: pointer;
}
</style>
