<template>

    <div id="app-vue">
        <component :is="layout"></component>
        <ThatsCustomWindow :group="2" ref="qrCodeWindowx" title="SCAN TO LOGIN OTHER DEVICE" :width="600" :height="600" class="qr-code-window">
            <template v-if="value !== null">
                <qrcode-vue :value="value" :size="size" level="H" />
            </template>
            <template v-else>
                Loading
            </template>
        </ThatsCustomWindow>
        <ThatsCustomWindow ref="sigatureWindow" :width="600" :height="200" :title="'Signature'">
            <div class="signature-container" ref="signature" v-html="signature"></div>
            <button @click="copySignature" class="btn btn-sm btn-elegant m-1">Copy to Clipboard</button>
        </ThatsCustomWindow>

        
        <DigitalSignatureDialog ref="digitalSignature" :value="digitalSignature"></DigitalSignatureDialog>
        <FurnishwebDialog ref="qrCodeWindow">
        <template v-if="value !== null">
                <qrcode-vue :value="value" :size="300" level="H" />
            </template>
            <template v-else>
                Loading
            </template>
        </FurnishwebDialog>
    </div>
    
</template>
<script>
import { mapGetters } from 'vuex';
const requireContext = require.context('./layouts', false, /.*\.vue$/);
import {ApiService} from './services';
import QrcodeVue from 'qrcode.vue'
import ThatsCustomWindow from './components/thats/CustomWindow'
import DigitalSignatureDialog from './components/thats/DigitalSignatureDialog'
import FurnishwebDialog from './components/thats/FurnishwebDialog'

const layouts = requireContext.keys().map(file =>
    [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
  )
  .reduce((components, [name, component]) => {
    components[name] = component.default || component
    return components
  }, {})
export default {
    data: ()=> ({
        value: null,
        size: 500,
        signature: '',
        digitalSignature: {
            name: '',
            designation: '',
            email: '',
            phone: '',
        }
    }),
    components : {
  
        ...layouts,
        QrcodeVue,
        ThatsCustomWindow,
        DigitalSignatureDialog,
        FurnishwebDialog
    },
    mounted() {
        const self = this;
        this.$root.$on('generate',function() {
            self.$refs.qrCodeWindow.openDialog();
            ApiService.generate().then((res) => {
                // alert(1)
                console.log("response")
                console.log(res)
                const user = JSON.parse(localStorage.getItem('user'));
                self.value = `${res.token}-x-${user.location.token_id}`
            });
        })
        this.$root.$on('generate-signature',function(row) {

           /* 
  
            self.signature = `<table style="border: none;width:487.5pt;">
                    <tbody>
                        <tr>
                            <td style="padding:0in 0in 0in 0in;">
                                <a href="https://www.thatsliving.com/" target="_blank"><img width="234" src="https://media.thatsliving.com/public/uploads/image-png/full-e8ab0a82d701488afeba7abe5a93b024-email-tl.png" alt="Logo"></a>
                            </td>
                            <td style="width: 33pt;padding: 0in;vertical-align: top;">
                                <span style='font-size:12px;font-family:"Arial",sans-serif;color:#222222;'><img src="https://media.thatsliving.com/public/uploads/image-png/full-a7c0b96f0a00e1258ef175463373ae41-sep.png" alt="Logo" style="width: 13px; height: 180px;"></span>
                            </td>
                            <td style="width: 300pt;padding: 0in;vertical-align: top;">
                                <table style="border: none;">
                                    <tbody>
                                        <tr>
                                            <td style="padding:0in 0in 3.75pt 0in;">
                                               <strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;font-weight:bold'>${row.name}</span></strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in 3.75pt 0in;">
                                                <strong><span style='font-size:13px;font-family:"Arial",sans-serif;color:black;font-weight:bold'>${row.attr.designation} &nbsp;</span></strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>T:&nbsp;</span></strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>+&nbsp;${row.attr.mobile}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <strong><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>E:&nbsp;</span></strong><span style="color:black;"><a href="mailto:${row.email}"><span style="color:black;">${row.email}</span></a></span><span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>&nbsp;| <a href="http://thatsliving.com/" target="_blank"><span style="color:black;">thatsliving.com</span></a></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding:0in 0in .75pt 0in;">
                                                <span style='font-size:12px;font-family:"Arial",sans-serif;color:black;'>Doha | Moscow | Sochi | Krasnodar | Warsaw</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="padding: 3.75pt 0in 0in;vertical-align: bottom;">
                                                <a href="https://www.instagram.com/thatsliving.qatar/" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-c7f92c7078074631812a134ae84a226c-ig.png" alt="Facebook icon"></a><span style="color:black;">&nbsp;&nbsp;</span><a href="https://www.facebook.com/thatslivingqatar/" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-326c45c2ec9a1564051518d0fdee91fb-fb.png" alt="Instagram icon"></a><span style="color:black;">&nbsp;&nbsp;</span><a href="https://www.youtube.com/channel/UCtVflXeRopCeq1adqRM79AQ" target="_blank"><img width="20" src="https://media.thatsliving.com/public/uploads/image-png/full-2acb727090163613c69c477df30ac1c5-yt.png" alt="Youtbue icon"></a><span style="color:black;">&nbsp;</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>`; */

            self.digitalSignature = {
                name: (row.name || ''),
                designation: (row.attr.designation || ''),
                email: (row.email || ''),
                phone: (row.mobile || '')
            }
            self.$refs.digitalSignature.openDialog();
        })


       

    },
    methods : {
        copySignature(){
            this.selectText(this.$refs.signature); // e.g. <div ref="text">
            document.execCommand("copy");
        },
        selectText(element) {
            var range;
            if (document.selection) {
                // IE
                range = document.body.createTextRange();
                range.moveToElementText(element);
                range.select();
            } else if (window.getSelection) {
                range = document.createRange();
                range.selectNode(element);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);
            }
            }
    },
    computed: {
        ...mapGetters('AppModule',[
            'layout'
        ])
    },

   

}
</script>