import { ApiV1Service } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const BrandV1Module = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data: {
            columns: [
                {
                    label: 'Brands',
                    field: 'name',
                    sort: 'asc',
                    width: '200',
                    clickable: true
                },
                {
                    label: 'Address',
                    field: 'address',
                    sort: 'asc',

                },
                {
                    label: 'Contact Person',
                    field: 'contact_person',
                    sort: 'asc',

                },
                {
                    label: 'Phone/Mobile',
                    field: 'contact_number',
                    sort: 'asc',

                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',

                },

                {
                    label: 'Website',
                    field: 'website',
                    sort: 'asc',

                },


            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

        brands: [],


    },
    actions: {

        async getBrand({ dispatch,commit },params) {
            return await new Promise((resolve) => {
                ApiV1Service.get('brands', params).then((response) => {
                    const res = []
                    if (response.data.length > 0) {
                        response.data.forEach((element) => {
                            const row = { label: element.name, value: element.mdbID}
                            res.push(row)
                        })
                    }
                    commit('setBrands',res)
                    resolve(res)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue: (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue: (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload) {
            state.data.rows = payload
        },
        SetPage: (state, payload) => {
            state.page = payload
        },
        SetTotal: (state, payload) => {
            state.total = payload
        },
        SetLength: (state, payload) => {
            state.length = payload
        },
        SetCode: (state, payload) => {
            state.form.item_code = payload
        },

        SetFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state, payload) => {
            state.formRecord = payload
        },
        setBrands: (state, payload) => {
            state.brands = payload
        },

    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        brands: state => state.brands,
    }
}


