<template>
    <div :class="parseClass">
        <mdb-dropdown class="" color="elegant"> 
            <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">{{localValue.year}}</mdb-dropdown-toggle>
            <mdb-dropdown-menu class="inactive-reactive-menu">
                <mdb-dropdown-item v-for="year in years" :key="year" @click="localValue.year = year">{{year}}</mdb-dropdown-item>
            </mdb-dropdown-menu>
        </mdb-dropdown> 

        <mdb-dropdown class="" color="elegant"> 
            <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">{{fromActiveMonth.text}}</mdb-dropdown-toggle>
            <mdb-dropdown-menu class="inactive-reactive-menu">
                <mdb-dropdown-item v-for="month in months" :key="month.value" @click="onSelectFrom(month)" v-if="compareMonths(month, toActiveMonth)">{{month.value}}</mdb-dropdown-item>
            </mdb-dropdown-menu>
        </mdb-dropdown> 

        <mdb-dropdown class="" color="elegant"> 
            <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">{{toActiveMonth.text}}</mdb-dropdown-toggle>
            <mdb-dropdown-menu class="inactive-reactive-menu">
                <mdb-dropdown-item v-for="month in months" :key="month.value" @click="onSelectTo(month)" v-if="compareMonths(fromActiveMonth, month)">{{month.value}}</mdb-dropdown-item>
            </mdb-dropdown-menu>
        </mdb-dropdown> 
    </div>
</template>

<script>
import {mdbBtn, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle} from "mdbvue";
export default {
    components : {
        mdbBtn, mdbDropdown, mdbDropdownItem, mdbDropdownMenu, mdbDropdownToggle
    },

    props : {
        years : {
            type : Array,
            default : () => {
                return []
            }
        },
        
        value : {
            type : Object,
            default : () => {
                return {
                    year : '2024',
                    fromMonth : 'January',
                    toMonth : 'December'
                }
            }
        },
        propClass: {
            type: [String, Array],
            default: ''
        },
    },

    mounted(){
        if(this.value.hasOwnProperty('year')){
            this.localValue = this.value
        }
    },
    data() {
        return {

            fromActiveMonth : {
                text : 'January',
                value : 'January',
                short : 'Jan'
            },
            toActiveMonth : {
                text : 'December',
                value : 'December',
                short : 'Dec'
            },

            localValue : {
                year : '2024',
                fromMonth : 'January',
                toMonth : 'December'
            }
        }
    },
    methods: {
        onSelectFrom(month) {
            if (this.compareMonths(month, this.toActiveMonth)) {
                this.fromActiveMonth = month;
            }
        },

        onSelectTo(month) {
            if (this.compareMonths(this.fromActiveMonth, month)) {
                this.toActiveMonth = month;
            }
        },

        compareMonths(fromMonth, toMonth) {
            const months = [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ];
            const fromIndex = months.indexOf(fromMonth.value);
            const toIndex = months.indexOf(toMonth.value);
            return fromIndex <= toIndex;
        }
    

    },

   
    computed: {
        months : function(){
            return [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December'
            ].map((month) => {
                return {
                    text : month,
                    short : month.slice(0, 3),
                    value : month
                }
            })
        },
        parseClass : function() {
            return Array.isArray(this.propClass) ? this.propClass.join(' ') : this.propClass
        }
    },

    watch : {
        "localValue.year" : function(){
            this.$emit('input',this.localValue)
        },
        fromActiveMonth : function(){
            this.localValue.fromMonth = this.fromActiveMonth.text
            this.$emit('input',this.localValue)
        },
        toActiveMonth : function(){
            this.localValue.toMonth = this.toActiveMonth.text
            this.$emit('input',this.localValue)
        },
        value : function(){
            this.localValue = this.value
        },

        
    }
    
}
</script>