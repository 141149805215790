<template>
    <div class="component-container">

       demo


    </div>
</template>

<script>

export default {

    data: ()=> ({
       

    }),
    components : {
  
       
    },
    created() {
        const self = this;
  
    },
    mounted() {
      
    },
    methods : {

        
    },
    computed: {
       
    },

    watch : {

      
    }

}
</script>