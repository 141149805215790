
<template>
    <div class="customervendor-container">
        <ThatsTable title="User log history" ref="table" :multipleSelection="false" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns">
            <template #custom-culumns="{columns, getValue,handleClick}">
                <template v-for="col in columns">
                    <el-table-column v-bind:key="col.field" :sortable="col.sortable" :width="col.width" :prop="col.field" :label="col.label" :align="col.align">
                        <template slot-scope="scope">
                            <template v-if="col.hasOwnProperty('clickable') && col.clickable === true ">
                                <a href="javascript:void(0)" @click="handleClick(scope.$index, scope.row)" v-html="`${scope.row[col.field]}`"></a>
                            </template>
                            <template v-else>
                                {{getValue(scope,col.field)}}
                                <div v-html="(scope.row[col.field] === 'null' || scope.row[col.field] === null ? '-' : scope.row[col.field])"></div>
                            
                            </template>
                        </template>
                        
                    </el-table-column>
                </template>

                <el-table-column :width="80" label="">
                    <template slot-scope="scope">
<!--                         {{scope.row.suspended +" ----" + scope.row.status}} -->
                        <template v-if="scope.row.suspended === 0">

                        <a href="javascript:void(0)" @click="onClickSuspend(scope.$index, scope.row)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Suspend</span></a>
                        </template>
                        <template v-else>
                            <a href="javascript:void(0)" @click="onClickClearSuspend(scope.$index, scope.row)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Clear</span></a>
                        </template>
                    </template>
                </el-table-column>
            </template>
        </ThatsTable>


    </div>
</template>
<script>

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from '../../components/thats/Table'
import { MessageBox } from 'element-ui';

export default {
    data(){
        return {
            endpoint : 'user-log/data',
            
        }
    },
    components: {
        ThatsTable,
       
    },
    
    methods: {

        ...mapActions('UserLogModule',[ 'updateRecord','updateRecordSuspension']),

        onClickSuspend(index,row){
            const self = this;
            MessageBox.confirm('Are you sure you want to suspend?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
                self.updateRecord(row).then((response) => {
                    self.$ThatsNotify.success({
                    message : `${response.message}`
                })
                self.refreshTable();
                });
            })
        },
        onClickClearSuspend(index,row){
            const self = this;
            MessageBox.confirm('Are you sure you want to clear suspension?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
            }).then(() => {
                self.updateRecordSuspension(row).then((response) => {
                    self.$ThatsNotify.success({
                    message : `${response.message}`
                })
                self.refreshTable();
                });
            })
        },

        refreshTable(){
            this.$refs.table.refreshTable()
        },
        

    },
    computed: {
        ...mapGetters('UserLogModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'getloading']),
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>