<template>
    <div class="page-container">
        <ThatsTable title="Daily Sales Report" ref="table" :multipleSelection="false" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" :newBtn="false" :height="'calc(100vh - 185px)'">
            <template #tabs>
                <ValidationObserver ref="observer" v-slot="{ passes}">
                  <div class="col-sm-12 pl-0">
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column" style="width:300px">
                            <mdb-select multiple selectAll @getValue="getSelectValue" :options="filterBrands" label="Choose Brands" class="report-multi-select"/>
                        </div>
                        <div class="d-flex flex-row">
                            <date-range-picker v-model="form.date_range" :append-to-body="false" class="filter-date-range date-range-picker-small"></date-range-picker>
                         <!--    <ThatsDatePicker :showLabel="true" :orientation="'vertical'" tabindex="1" labelClass="col-md-12 date-label" fieldClass="col-md-12 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :rules="'required'" size="sm"/> -->
                        </div>
                        <div class="d-flex flex-row align-items-center">
                            <mdb-btn @click="passes(generateReport)" class="m-0 ml-1" color="elegant" size="sm" :disabled="tableLoading">Generate</mdb-btn>
                            <mdb-btn @click="exportReport" class="m-0 ml-1" color="elegant" size="sm" :disabled="tableLoading">Export to Xls</mdb-btn>
                            <mdb-btn @click="printReport" class="m-0 ml-1" color="elegant" size="sm" :disabled="tableLoading">Print</mdb-btn>
                        </div>
                    </div>
                </div>
                </ValidationObserver>              
            </template>
            <template  #filter="{dispatchFilter}">
                <DeliveryFilter @filter="dispatchFilter" :tabIndex="active"></DeliveryFilter>
            </template>
            
        </ThatsTable>

        <DeliveryForm ref="dialogForm" @success="refreshTable" @delete="formDelete"  :group="3"></DeliveryForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import DeliveryForm from './DeliveryForm'
import ThatsTable from './Table'
import DeliveryFilter from './DeliveryFilter.vue'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import moment from 'moment'
export default {
    data(){
         let endDate = new Date();
        endDate.setDate(endDate.getDate() + 6)

        return {
            // endpoint : 'delivery/data?status=for-delivery',    
            filterBrands : [],        
            importData : {},        
            errorData : {},        
            importLoading : false,        
            active : 0,        
            activeTab : 0,        
            token : null,
            form : {
                date : "",
                date_range : {
                    startDate: null, 
                    endDate: null
                },
                brands : []
            },
            userLocation : []
        }
    },
    components:{
        DeliveryForm,
        ThatsTable,
        DeliveryFilter,
        mdbTabs,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,
        mdbSelect

    },

    created(){
        const self = this
        this.currentDateSetter();

        let session = JSON.parse(localStorage.getItem('user'));

        this.userLocation = session.location_prefix

        this.SetFilters(this.form)

         this.GetShowrooms().then((response) =>{
            if(response.length > 0){
              response.forEach((element,index) => {


                if(session.location_prefix.includes(element.prefix)){
                    const d = {
                        text : element.label,
                        value : element.prefix,
                        selected : true
                        }

                    self.filterBrands.push(d)
                }


                
              })
            }
        });

        

    },

    
    methods: {
        ...mapActions('ShowroomsModule',[
               'GetShowrooms'
             ]),
        // 

        ...mapActions('TableV1Module',[
            'resetData',
        ]),
        ...mapMutations('TableV1Module',[
            'SetPage',
            'SetTerm',
            'SetFilters',
        ]),

        ...mapActions('ReportByBrandModule',[ 'DeleteRecord','saveImport' ,'updateStatus','getRecord','getPullOutRecord']),
        ...mapMutations('ReportByBrandModule',[ 'setFormRecord', 'setFormStatusUpdate','setTabIndex']),
        moment,
        dateFormat (classes, date) {
            if (!classes.disabled) {
            classes.disabled = date.getTime() < new Date()
            }
            return classes
        },

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })
            this.closeWindow()
        },
        OpenUpdateModal(obj){
            var self = this;

             

            const form  = {...obj.row,items : [],attachment : [], invoice : {
                    series : null,
                    date : "",
                    balance : 0,
                    paid : 0,
                    attachment : [],
                    payments : [],
                    }}           
            self.setFormStatusUpdate()
            self.setFormRecord(form)
            

            if(this.activeTab !=='pull-out'){
                self.getRecord({token_id : obj.row.token_id})
                self.openWindow()
            }

        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
            // console.log(v)
           
           this.$refs.tableImport.openDialog()
        },

        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }
            
        },

        getActiveTabIndex(index){


            const tabs = ['for-delivery','scheduled-for-delivery','tomorrow-delivery','pending-delivery','pull-out','cancel-re-schedule','delivered','pull-out','all']

            this.endpoint = 'delivery/data?status='+tabs[index];

            this.activeTab = tabs[index]

            this.setTabIndex(index)
        
            if(tabs[index] === 'for-delivery'){
                this.columns = this.columnOne;
            }
            if(tabs[index] !== 'for-delivery'){
                this.columns = this.columnTwo;
            }

            if(tabs[index] === 'all'){
                this.columns = this.columnAll;
            }

            if(tabs[index] === 'pull-out'){
                this.endpoint = 'delivery/pull-out';
            }
        
            this.SetFilters({})
            this.SetPage(1)
            this.resetData(this.endpoint);
        },


        updatePurchaseOrderStatus(event,v,row){
            var self = this;
            if(event.target.checked === true){
                
                MessageBox.confirm('Are you sure you want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = true

                    })
                return event.target.checked = false
            }

            if(event.target.checked === false){
                
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)

                        //  return event.target.checked = false

                    })
                return event.target.checked = true
            }

            return event.target.checked = false
        /* const self = this;
        console.log(event.target.checked)
        this.$emit("input", false) */
            /* if(v){
                MessageBox.confirm('Record will change status to "For Approval". Are you sure want to continue', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        const formData = { 
                            hash : row.token_id,
                            status : 'approved'
                            }
                        self.fnUpdateStatus(formData)

                        console.log(event.target);
                        this.$emit("input", event.target.checked)

                    })
            }else{
                MessageBox.confirm('Are you sure want to approve this purchase order?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                         const formData = { 
                            hash : row.token_id,
                            status : 'for-approval'
                            }
                        self.fnUpdateStatus(formData)
                    })
            } */
        /* if(self.FormStatus == 'update'){
            MessageBox.confirm('Are you sure you want to change status?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {

                    const formData = { 
                    hash : self.form.token_id,
                    status : status
                    }
                    self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.closeDialog();
                        self.$emit('success')
                        self.FormResetter();
                        
                    }).catch((error) => {
                        self.formLoading = false
                        self.response.error = 1;
                        if (error.response) {
                            if(error.response.data.hasOwnProperty('errors')){
                            self.$refs.observer.setErrors(error.response.data.errors);
                            this.showError = true
                            }
                        } else if (error.request) {
                            self.response.message = error.request
                        } else {
                            self.response.message = error.message
                        }
                        return;
                    })

                })
            
        } */
        },

        fnUpdateStatus(formData){
            var self = this;
            self.updateStatus({endpoint : 'update-status',payload : formData}).then(() => {
                        self.$ThatsNotify.success({
                        message : "Record status was successfully changed"
                        })
                        self.refreshTable();
                    }).catch((error) => {
                        self.$ThatsNotify.success({
                        message : "Something wen't wrong"
                        })
                        self.refreshTable();
                    })
            
        },

        currentDateSetter(){

            var todayDate = new Date().toISOString().slice(0, 10);
/* console.log(todayDate);


            let currentDate = new Date();

             const date1 = currentDate.toLocaleDateString();

            var datearray = date1.split("/");
             console.log(date1)
             console.log(datearray)
            var startDate = datearray[2] + '-' + datearray[1] + '-' + datearray[0];
             console.log(startDate) */
            this.form.date = todayDate

            this.form.date_range = {
                startDate: todayDate, 
                endDate: todayDate
            }

        },

        generateReport(){
            this.SetFilters(this.form)
            this.resetData(this.endpoint);
        },

        exportReport(){

            const startDate = this.moment(this.form.date_range.startDate).format('YYYY-MM-DD')
            const endDate = this.moment(this.form.date_range.endDate).format('YYYY-MM-DD')

            window.open(`reports/sales-report-by-brand/print/${startDate}to${endDate}?brands=${this.form.brands}`,'_blank');
// window.open(url);
        },
        printReport(){
            window.open(`reports/sales-report-by-brand/print-pdf/${this.form.date_range.startDate}to${this.form.date_range.startDate}?brands=${this.form.brands}`,'_blank');
// window.open(url);
        },
        getSelectValue(v){
            // console.log(v)
            this.form.brands = v
        }

    },
    computed: {
        ...mapGetters('TableV1Module',['tableLoading']),
        ...mapGetters('ReportByBrandModule',['getLoading', 'columns']),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        },

        endpoint : function(){
            return `reports/sales-report-by-brand`
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }

  .date-range-picker-small .form-control{
      height: calc(1.5em + .5rem + 2px)!Important;
      line-height: 1.5;
  }
</style>