import { furnishwebHttpService } from '../../services'
export const CustomerModule = {
    namespaced: true,
    state: {

        loading: false,
        cart: [],
        count: 0,
        total: 0,
        request: null,
       
    },
    actions: {

        async findCustomer({ state, commit }, params = {}) {
            const configParams = {
                req : "CustomerModule/req",
                loadingTrue : "CustomerModule/loadingTrue",
                addRequest : "CustomerModule/addRequest",
                cancelRequest : "CustomerModule/cancelRequest",
                clearOldRequest : "CustomerModule/clearOldRequest"
            }
            return await new Promise((resolve) => {
                const { loading } = state
                if (!loading) {
                    commit('loadingTrue')      
                    furnishwebHttpService.get(`contacts/filter`, params, configParams).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data);
                    })
                }
            })
        },

        async placeOrder({state,commit, dispatch}, payload) {
            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    furnishwebHttpService.placeOrder('place-order',payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addToCart({state,commit, dispatch}, payload) {

            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/loadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }

            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')

                    const cart = {
                        id : payload.mdbID,
                        name : payload.name,
                        quantity : 1,
                        price : payload.sale_price,
                        image : payload.image,
                        dimension : payload.dimension,
                        series : payload.series,
                        brand : payload.brand_name,
                    }
                    let user = JSON.parse(localStorage.getItem('user'));          
                    furnishwebHttpService.post(`cart/${user.cartData.cartKey}`,cart, configParams).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async updateCart({state,commit, dispatch}, payload) {

            const configParams = {
                req : "CartModule/req",
                loadingTrue : "CartModule/loadingTrue",
                addRequest : "CartModule/addRequest",
                cancelRequest : "CartModule/cancelRequest",
                clearOldRequest : "CartModule/clearOldRequest"
            }
            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));
                    furnishwebHttpService.post(`cart/${user.cartData.cartKey}`,payload,configParams).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async deleteItem({state,commit, dispatch}, payload) {
            return await new Promise((resolve,reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    let user = JSON.parse(localStorage.getItem('user'));
                    furnishwebHttpService.delete(`cart/${user.cartData.cartKey}`,payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                        dispatch('getCart')
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async createCart({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    furnishwebHttpService.post('create-cart',payload).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async addToCartx({ state, commit }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')

                    const cart = {
                        id : payload.mdbID,
                        name : payload.name,
                        quantity : payload.qty,
                        price : payload.sale_price,
                        image : payload.image,
                        dimension : payload.dimension,
                        series : payload.series,
                        brand : payload.brand_name,
                    }
                    furnishwebHttpService.post('cart',cart).then((response) => {
                        commit('loadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async removeItem({ state, commit,dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    furnishwebHttpService.post('cart-remove-item',payload).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },
        async reduceByOne({ state, commit,dispatch }, payload) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    furnishwebHttpService.post('cart-reduce-one',payload).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async clear({ state, commit,dispatch }) {
            return await new Promise((resolve, reject) => {
                const loading = state.loading;
                if (!loading) {
                    commit('loadingTrue')
                    furnishwebHttpService.post('cart-clear',{}).then((response) => {
                        commit('loadingFalse')
                        dispatch('getCart')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('loadingFalse')
                        reject(error)
                    })
                }
            })
        },




    },
    mutations: {
        loadingFalse: (state) => {
            state.loading = false
        },
        loadingTrue: (state) => {
            state.loading = true
        },
        setCart: (state, payload) => {
            state.cart = payload
        },
        setCartItemCount: (state, payload) => {
            state.count = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },

        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
        cancelRequest : (state) => {

            if(state.request != null){
                state.request.cancel()
            }
            state.loading = true
        },
        clearOldRequest(state, message) {
            state.request.msg = message;
            state.request = null;
        },
       
    },
    getters: {
        req: state => state.request,
        cart: state => state.cart,
        loading: state => state.loading,
        count: state => state.count,
        total: state => state.total,


    }
}


