<template>
    <div>
      <div class="chat-message chat-body">

        <div v-for="message in messages" :key="message.id" class="d-flex mb-2" v-bind:class="[(message.user_id !== loggedUserId ? 'justify-content-start media' : 'justify-content-end' )]">
            <template v-if="message.user_id !== loggedUserId">
               <!--  <img
                    class="mr-3 avatar-sm float-left"
                    src="https://mdbootstrap.com/img/Photos/Avatars/adach.jpg"
                /> -->

                <div class="mr-3 avatar-sm border grey lighten-5 float-left">
                    <mdb-icon class="dark-grey-text" far icon="user" style=" font-size: 18px; margin: 18px 20px; "/>
                </div>

            </template>

            <div class=" rounded p-3" v-bind:class="[(message.user_id !== loggedUserId ? 'grey lighten-3' : 'light-blue lighten-4' )]">
                <template v-if="message.user_id  !== loggedUserId">
                    <h6 class="font-weight-bold">{{ message.user_name }} - {{ message.contact_source }}</h6>
                </template>
                <template v-else>
                    <h6 class="text-right font-weight-bold">{{ message.contact_source }} - {{ message.user_name }}</h6>
                </template>
                <p class="mb-0">
                    {{ message.note }}
                </p>
                <p class="text-muted text-small text-sm mb-0" v-bind:class="[(message.user_id !== loggedUserId ? 'text-left' : 'text-right' )]"><mdb-icon icon="clock"/> {{ message.timestamp }}</p>
            </div>
        
        </div>

  
       <!--  <div>
          <div class="white">
            <div class="form-group basic-textarea">
              <textarea
                class="form-control pl-2 my-0"
                id="exampleFormControlTextarea2"
                rows="3"
                placeholder="Type your message here..."
                v-model="newMessage"
              ></textarea>
            </div>
          </div>
          <mdb-btn :color="outline ? null : color" :outline="color ? null : outline" rounded size="sm" @click="sendMessage">Send</mdb-btn>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>

  import mdbChatMessage from "./ChatMessage.vue";
  import {mdbBtn, mdbAvatar, mdbCard, mdbIcon} from 'mdbvue'
  const Chat = {
    name: "Chat",
    components: {
      mdbAvatar,
      mdbChatMessage,
      mdbBtn,
      mdbCard,
      mdbIcon
    },
    props: {
      chat: {
        type: Array
      },
      loggedUserId: Number,
      avatarWidth: {
        type: Number,
        default: 100
      },
      color: {
        type: String,
      },
      outline: {
        type: String,
      },
      scrollbarClass: {
        type: String
      },
      scroll: {
        type: Boolean,
        default: false
      },
      height: {
        type: Number,
        default: 500
      },
    },
    data() {
      return {
        newMessage: ""
      };
    },
    computed: {
      messages() {
        const messages = [];
        this.chat.forEach(message => {
            messages.push({
              id: message.id,
              name: message.user_name,
              ...message
            });
        });
        return messages.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          } else if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
      },
      receiver() {
        return this.chat.find(user => user.id !== this.loggedUserId);
      },
      className() {
        return [
          "list-unstyled",
          this.scroll ? `chat-1 ${this.scrollbarClass} pr-3` : "chat"
        ];
      },
      scrollStyle() {
        return this.scroll ? `height: ${this.height}px` : '';
      }
    },
    methods: {
      sendMessage() {
        const messageDate = new Date().toString();
        this.$emit("send", {
          date: messageDate,
          content: this.newMessage,
          unread: true
        });
        this.newMessage = "";
      }
    }
  };
  
  export default Chat;
  export { Chat as mdbChat };
  </script>
  
  <style scoped>

.avatar-sm {
  width: 56px;
  border-radius: 50%;
}
.chat-message-type {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 0 auto !important;
  -ms-flex: 1 0 auto !important;
  flex: 1 0 auto !important;
}
  
  </style>