<template>
  <div class="transaction-form-container">
    <ul id="right-click-menu" tabindex="-1" ref="right" v-if="viewMenu" v-on:blur="closeMenu"  v-bind:style="{top:top, left:left}">
        <li  @click="insertRow">Insert</li>
        <template v-if="form.items.length > 2">
          <li @click="moveUpRow">Move Up</li>
          <li @click="moveDownRow">Move Down</li>
        </template>
        <li @click="deleteRow">Delete</li>
    </ul>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Sales Quotation" :windowClass="windowClass" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="800" :width="1300" :formStatus="formStatus" @dialog-state="dialogState" :group="group" @history="openHistory"  :parentContainerStyle="parentContainerStyle">
            <template #left-button>

              <template v-if="viewLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>

                <template v-if="formLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <template v-if="formStatus == 'create'">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                  </template>
                  <template v-if="formStatus == 'update' && !disableForm.includes(formOriginal.status)">
                    <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                    <mdb-btn @click="fnPrint('/sales/transactions-v3/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print Quotation</mdb-btn>
                  <template v-if="$helpers.isSystemAdministrator() || $helpers.isShowroomManager() || $helpers.isInteriorDesigner()">
                    <mdb-btn @click="fnPrintProforma('/sales/transactions-v3/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print Proforma</mdb-btn>
                  </template>
                </template>
                <template v-if="($helpers.isSystemAdministrator() || $helpers.isGeneralManager()) && formOriginal != null && ['closed'].includes(formOriginal.status)">
                  <mdb-btn @click="reopenQuotation(form)" color="elegant" size="sm">Open Quotation</mdb-btn>
                </template>


                <template v-if="selected.length > 0 && modify === true && returmItem === false">
                    <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                  </template>
                  </template>
              </template>

            <template #right-button>
              <template v-if="formStatus == 'update'">

                <template v-if="!disableForm.includes(formOriginal.status)">
                  <template v-if="invoice.series === null || parseFloat(invoice.balance) > 0 && modify === false && returmItem === false && (active === 0  || active === 1)">
                    <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Make Payment</mdb-btn>
                    <template v-if="isLoyaltyMember && ($helpers.isSystemAdministrator() || $helpers.isShowroomManager())">
                      <mdb-btn color="elegant" size="sm" @click="openDiscountCalculatorDialog" :disabled="viewLoading || itemHasChanged || getCustomerLoyaltyMembershipLoading">Loyalty Discount Calculator</mdb-btn>
                    </template>
                  </template>
                  <template v-if="invoice.series !== null && checkSystemSupport && modify === false && returmItem === false && (active === 0  || active === 1)">
                    <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Add Payment</mdb-btn>
                  </template>
                  <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && modify === false && returmItem === false && active === 0">
                    <mdb-btn color="elegant" size="sm" @click="enableModify" :disabled="viewLoading">Modify</mdb-btn>
                  </template>

                  <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && modify === false && returmItem === false && active === 0">
                    <mdb-btn color="elegant" size="sm" @click="enableModify" :disabled="viewLoading">Item Cancellation</mdb-btn>
                  </template>
                  <template v-if="invoice.series !== null && checkSystemSupport && modify === false && returmItem === false && modifyReturmItem === false && active === 3">
                    <mdb-btn color="elegant" size="sm" @click="enableModifyCreditNote" :disabled="viewLoading">Modify Credit Note</mdb-btn>
                  </template>
                  <template v-if="invoice.series !== null && (checkSystemSupport || checkShowroomManager) && (modify === true || modifyReturmItem === true )">
                    <mdb-btn color="elegant" size="sm" @click="cancelModification" :disabled="viewLoading">Cancel</mdb-btn>
                  </template>

                  <template v-if="(invoice.series !== null && ((form.status === 'delivered' || form.status === 'collected' || form.status === 'cancel') && form.attr.payment_status === 'full-payment')) && (checkSystemSupport || checkShowroomManager) && modify === false && returmItem === false && active === 0">
                    <mdb-btn color="elegant" size="sm" :disabled="viewLoading" @click="returnItems">Return</mdb-btn>
                  </template>

                  <template v-if="(invoice.series !== null && ((form.status === 'delivered' || form.status === 'collected' || form.status === 'cancel') && form.attr.payment_status === 'full-payment')) && (checkSystemSupport || checkShowroomManager) && returmItem === true && modify === false  && active === 0">
                    <mdb-btn color="elegant" size="sm" :disabled="hasSelected" @click="requestCreditNote">Request Credit Note</mdb-btn>
                    <mdb-btn color="elegant" size="sm" :disabled="viewLoading" @click="cancelModification">Cancel</mdb-btn>
                  </template>
                </template>

                <template v-if="invoice.payments.length> 0">
                    <span class="invoice-balance">Total Balance : {{ $helpers.addSeparatorsNF(invoice.balance)}} QAR</span>
                </template>

              </template>


             <!--  <template v-if="formStatus == 'create' && form.items.filter((item) => item.code !=='',[]).length > 1 && posShowroom.includes(form.showroom.prefix)">
                <mdb-btn color="elegant" size="sm" @click="openPaymentWindow" :disabled="viewLoading">Make Payment</mdb-btn>
              </template> -->


            </template>

          <template v-if="invoice.payments.length> 0">
            <span class="invoice-number">Sales Invoice #: {{invoice.series}}</span>
          </template>
          <div class="mt-1 pl-3 pr-3">
            <mdb-tabs class="table-tabs mb-0 form" :active="active" default :links="tabs" @activeTab="getActiveTabIndex" >
              <template :slot="'form-slot'">

<!--{{benefits}}-->
                <mdb-row class="tab-row">
                    <mdb-col col="6" class="tab-col pl-0">
                      <template v-if="form.date !== null">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Valid Until" placeholder="Valid Until" vid="valid_until" name="valid_until" v-model="form.attr.valid_until" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      </template>
                      <template v-if="formStatus === 'update' && form.status !== 'no-remarks'">
                      <!-- <template v-if="(formStatus === 'update' && form.status !== 'no-remarks') || ((formStatus === 'create' || formStatus === 'update') && isWalkin === true)"> -->
                          <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer" vid="name" name="name" v-model="form.attr.name" size="sm" :disabled="(isWalkin === true && disabled ==false ?  true : false)"/>
                      </template>
                      <template v-else>
                        <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Customer" v-model="form.customer_id" :reduce="customer => customer.value" :reduceLabel="'opt'" :options="customer" :rules="'required'" :clearable="false" :disabled="disabled" @select="getCustomerSelected($event)" @change="getCustomerSelected($event)" :addButton="true" @newWindow="openCustomer" @onSearch="searchCustomer" :selected_option="true" :editButton="enableEditButton" @edit="editCustomer"></ThatsCustomMultiSelect>
                      </template>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" :maxlength="'8'" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="displayAddress" size="sm" :disabled="true"/>
                       <ThatsCustomMultiSelect :show_error="true" :key="'validation'+validationKey" :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Contact Person" v-model="form.attr.contact_person.mobile" :reduce="contact_person => contact_person.value" :reduceLabel="'opt'" :options="customerContactPersons" :rules="{required : (form.attr.corporate == true ? true : false)}" :clearable="true" :disabled="disabled" @select="getCustomerSelectedContactPerson($event)"  :selected_option="true" :editButton="true" @edit="editCustomer"></ThatsCustomMultiSelect>

                    </mdb-col>
                    <mdb-col col="6" class="tab-col pl-0">
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0 pr-0" label="Transaction ID" placeholder="Transaction ID" vid="series" name="series" v-model="form.series" :validated="validated" :rules="''" :keycode="false" size="sm" :disabled="true" />
                        <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0 pr-0" label="LOCATION" v-model="form.showroom.location" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'" @select="onSelectLocation" :disabled="disabled" :selectable="(option) => filteredShowroom.includes(option.prefix)"></ThatsMultiSelect>

                        <mdb-row>
                            <mdb-col sm="4" class="tab-col">
                                <label v-bind:class="'col-form-label'">Sold By</label>
                            </mdb-col>
                            <mdb-col sm="4" class="tab-col">

                                <template v-if="checkSystemSupport || checkShowroomManager">
                                  <ThatsMultiSelect :clearable="false" :multiple="false" :showLabel="false"  labelClass="col-md-0" fieldClass="col-md-12  pl-0 pr-0" v-model="form.sold_by" :reduce="label => label.code" :reduceLabel="'label'" :options="partnerSalesPerson" :rules="''" :disabled="disabled"></ThatsMultiSelect>
                                </template>
                                <template v-else>
                                  <ThatsInput :showLabel="false" tabindex="2" labelClass="col-sm-6" fieldClass="col-sm-12 pl-0 pr-0" label="Sold By" :placeholder="form.sold_by" vid="sold_by" name="sold_by" v-model="form.sold_by" size="sm" :disabled="true" />
                                </template>
                            </mdb-col>
                            <mdb-col sm="4" class="tab-col">
                                <ThatsMultiSelect :multiple="true"  labelClass="col-md-4" fieldClass="col-md-8  pl-0 pr-0" label="partner" v-model="form.attr.partner" :reduce="label => label.code" :reduceLabel="'label'" :options="partner" :rules="''" :disabled="disabled"></ThatsMultiSelect>
                            </mdb-col>
                          </mdb-row>

                      <template v-if="formStatus !== 'create'">
                       <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0 pr-0" label="Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="['cancel','closed'].includes(formOriginal.status)"></ThatsMultiSelect>

                          <template v-if="['closed'].includes(form.status)">
                            <ValidationProvider vid="form.attr.closed.remarks" name="form.attr.closed.remarks" :rules="'required'" v-slot="{errors}">

                               <mdb-row ><mdb-col offset="4" col="8">
                                 <el-checkbox-group v-model="form.attr.closed.remarks" :disabled="disabled">
                                    <template v-if="form.attr.closed.remarks.includes('Expired')">
                                    <div><el-checkbox label="Expired"></el-checkbox></div>
                                    </template>
                                    <div><el-checkbox label="Availability"></el-checkbox></div>
                                    <div><el-checkbox label="High Price"></el-checkbox></div>
                                    <div><el-checkbox label="Design"></el-checkbox></div>
                                    <div><el-checkbox label="Competitor Purchase" @change="checkCompetitorIfTrue"></el-checkbox></div>

                                    <template v-if="showCompetitor || form.attr.closed.competitor !== null">
                                    <ThatsInput tabindex="2" labelClass="" fieldClass="col-sm-12 pl-0" label="" vid="competitor" name="competitor" :showLabel="false" v-model="form.attr.closed.competitor" size="sm" :disabled="disabled"/>
                                    </template>

                                    <div><el-checkbox label="In house Purchase" @change="checkIfTrue"></el-checkbox></div>

                                    <div><el-checkbox label="Others" @change="checkOtherIfTrue"></el-checkbox></div>

                                    <template v-if="showOther">
                                      <ThatsInput tabindex="2" labelClass="" fieldClass="col-sm-12 pl-0" label="" vid="other" name="other" :show_error="true" :showLabel="false" v-model="form.attr.closed.others" size="sm" :rules="'required'" :disabled="disabled"/>
                                    </template>


                                  </el-checkbox-group>

                                 </mdb-col></mdb-row>


                                    <template v-if="showOtherBrand || form.attr.closed.brand !== null">
                                      <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0 pr-0" label="" v-model="form.attr.closed.brand" :reduce="label => label.value" :reduceLabel="'label'" :options="showroomLocation" :rules="'required'" @select="onSelectLocationClose" :disabled="disabled"></ThatsMultiSelect>
                                    </template>

                                    <template v-if="errors.length > 0">
                                <mdb-row ><mdb-col offset="4" col="8"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                            </ValidationProvider>
                          </template>

                          <template v-if="['cancel','closed'].includes(form.status)">
                           <ThatsTextarea :rows="2" tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0 pr-0" label="Remarks" vid="description" name="description" v-model="form.description" size="sm" :disabled="disabled" :rules="(form.status === 'cancel' ? 'required' : '')"/>
                          </template>
                        <!-- <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Payment Status" v-model="form.attr.payment_status" :reduce="label => label.code" :reduceLabel="'label'" :options="paymentMethod" :rules="''" :filterable="false" :clearable="false" :disabled="noInvoiceDisabled"></ThatsMultiSelect> -->
                      </template>
                      <ThatsMultiSelect  :multiple="false" labelClass="col-md-4" fieldClass="col-md-8  pl-0 pr-0" label="Instructions"  vid="instructions" name="instructions" v-model="form.attr.instructions" :reduce="label => label.code" :reduceLabel="'label'" :options="instructions" :rules="''" :disabled="disabled"></ThatsMultiSelect>
                    </mdb-col>
                  </mdb-row>


                 <el-table id="itemTable" ref="itemTable" :key="tblKey" border :data="form.items" class="input-table mt-2 itemTable" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell" :row-class-name="rowCLassName" @row-contextmenu="rowContectMenu" v-mdb-click-outside="handleOutsideClick">

                     <template v-if="showCheckBox">
                              <el-table-column type="selection" width="35" :selectable="canSelectRow"> </el-table-column>
                            </template>
                            <el-table-column type="index" width="50" label="SQ" align="center"> </el-table-column>
                            <el-table-column label="SKU" :width="150">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                    {{scope.row.code}}
                                </template>
                                <template v-else>
                                  <GridVueSelect :ref="'gridSelect'" v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled" :addButton="false"></GridVueSelect>
                                </template>

                              </template>
                            </el-table-column>
                            <el-table-column label="Image" width="90"  >
                            <template slot-scope="scope">
                                <img :src="scope.row.default" style="width:100%"/>
                            </template>
                          </el-table-column>
                            <el-table-column label="Item Name" :row-class-name="''">
                              <template slot-scope="scope">
                                <template v-if="disabled">
                                    {{scope.row.name}}
                                </template>
                                <template v-else>
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                                </template>
                              </template>
                            </el-table-column>
                            <template v-if="form.invoice.series === null">
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="enableModifyAndCreditNote(scope.row)">

                                    {{scope.row.qty}}
                                </template>
                                <template v-else>
                                  <input type="number" min="1" class="form-control grid-input thats-form-control text-right" style="border: 1px solid rgb(227, 227, 227) !important; background-color: rgb(255, 255, 255) !important; border-radius: .25rem !important;" v-model="scope.row.qty" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableModifyAndCreditNote(scope.row)" />
                                </template>

                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align="right">
                                <template slot-scope="scope">
                                  <template v-if="enablePrice(scope.row)">
                                        <input type="tel" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" @keydown.enter="addNewRow(scope.$index)"  @input="calculatePrice(scope.$index,$event)" @focus="removeFormat(scope.$index)" @blur="formatOnBlur(scope.$index)"/>
<!--                                     <vue-numeric  class="form-control grid-input thats-form-control text-right" currency="" separator="," v-model="scope.row.formatted_price" v-bind:precision="2"  @input="calculate(scope.$index,$event)"></vue-numeric> -->
                                    </template>
                                    <template v-else>
                                        <input type="tel" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" disabled/>
                                      </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount" style="font-size: 1rem;"></span>
                                  <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false && allowDiscount">

                                    
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <template v-else>
                              <el-table-column label="Qty" :width="70" align="right">
                                <template slot-scope="scope">
                                  <template v-if="enableModifyAndCreditNote(scope.row)">

                                    {{scope.row.qty}}
                                </template>
                               <!--  <template v-else><input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableModifyAndCreditNote" /> -->
                                <template v-else>
                                  <input type="number" min="1" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" @keydown.enter="addNewRow(scope.$index)" :disabled="enableModifyAndCreditNote(scope.row)" />
                                </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Price" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" :disabled="enablePrice(scope.row)" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Amount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" :disabled="true" />
                                </template>
                              </el-table-column>
                              <el-table-column label="Discount" :width="100" align = "right">
                                <template slot-scope="scope">
                                  <span v-html="scope.row.discount"></span>
                                 <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                  <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                  </template>
                                </template>
                              </el-table-column>
                              <el-table-column label="Total Amount" :width="120" align = "right">
                                <template slot-scope="scope">
                                  <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" :disabled="true" />
                                </template>
                              </el-table-column>
                            </template>
                            <el-table-column label="Remarks" :width="120">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="enableModifyAndCreditNote(scope.row)" />
                              </template>
                            </el-table-column>
                            <template v-if="form.invoice.series === null">
                              <el-table-column :width="75">
                                <template slot-scope="{}" slot="header">
                                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllTbo" @change="handleCheckAllChange">TBO</el-checkbox>
                                </template>
                                <template slot-scope="scope">
                                  <el-checkbox v-model="scope.row.tbo" style="margin-left:6px" @change="handleCheckedTboChange($event,scope.row)">TBO</el-checkbox>
                                </template>
                              </el-table-column>

                              <el-table-column  :width="70" align="right">
                                <template slot-scope="{}" slot="header">
                                </template>
                                <template slot-scope="scope">
                                    <input type="number" :min="scope.row.tbo_minimum_qty" :max="scope.row.qty" class="form-control grid-input thats-form-control text-right"  @keyup="toBeOrderQuantity(scope.$index,$event)" v-model="scope.row.tbo_qty" :disabled="!scope.row.tbo" style="border: 1px solid #e3e3e3!important;background-color: #ffffff!important;border-radius: .25rem!important;"/>

                                </template>
                              </el-table-column>


                          <!--     <el-table-column :width="110">
                                <template slot-scope="{}" slot="header">
                                  <el-checkbox :indeterminate="isIndeterminateFromDisplay" v-model="checkAllFromDisplay" @change="handleCheckAllFromDisplayChange">Fr. Display</el-checkbox>
                                </template>
                                <template slot-scope="scope">
                                  <el-checkbox v-model="scope.row.from_display" style="margin-left:6px" @change="handleCheckedFromDisplayChange"></el-checkbox>
                                </template>
                              </el-table-column> -->

                            </template>

                          </el-table>




                          <table class="el-table">
                            <tr>
                              <template v-if="!disabled">
                              <td></td>
                              </template>

                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td style="width:100px">
                                <!-- <el-checkbox v-model="form.more_discount.enable" :disabled="disabled">Invoice Discount </el-checkbox> -->
                                Invoice Discount
                              </td>
                              <td style="width:300px">
                                <input type="text" style="width: 100px!important" class="form-control grid-input thats-form-control text-right inv-discount-percent" v-model="form.more_discount.percent" @keyup="invoiceDiscountPercent" :disabled="disabled" />
                                <span class="inv-discount-percent-lbl">%</span>
                                <input type="text" class="form-control grid-input thats-form-control text-right inv-discount" v-model="form.more_discount.discount" @keyup="invoiceDiscount" @focus="removeDiscountFormat()" @blur="formatOnBlurMoreDiscount()" @keydown.enter="addMoreDiscount()" :disabled="disabled" />



                                </td>
                              <td style="width:120px" class="text-right">
                                  <strong>{{form.total}}</strong>
                              </td>
                              <template v-if="disabled">
                                  <td style="width:130px"></td>
                              </template>
                              <template v-else>
                              <td style="width:114px"></td>
                              </template>
                            </tr>
                        </table>

                         <mdb-row class="tab-row">
                            <mdb-col col="6" class="tab-col pl-0">
                            </mdb-col>
                            <mdb-col col="6" class="tab-col">
                                <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0 pr-0 text-right" label="Discount Remarks" vid="discount_remarks" name="discount_remarks" v-model="form.attr.discount_remarks" size="sm" :disabled="disabled"/>
                            </mdb-col>
                         </mdb-row>
              </template>
              <template :slot="'credit-notes'">
                <el-table border :data="form.credit_note_items" class="input-table mt-0" style="width: 100%">

                  <template v-if="showCheckBoxCreditNote">
                              <el-table-column type="selection" width="35" :selectable="canSelectRow"> </el-table-column>
                            </template>

                      <el-table-column label="SKU" width="150">
                        <template slot-scope="scope">
                           {{scope.row.code}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                        <template slot-scope="scope">
                            <img :src="scope.row.default" style="width:90px" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Item Name" :row-class-name="''">
                          <template slot-scope="scope">
                          {{scope.row.name}}
                          </template>
                      </el-table-column>

                      <el-table-column label="Qty" :width="70" align="right">
                      <template slot-scope="scope">
                            <template v-if="checkSystemSupport === true && scope.row.status === 'for-approval' && modifyReturmItem === true">
                            <input type="number" min="1" v-on:change="quantityChange(scope.row,$event)" class="form-control grid-input thats-form-control text-right" v-model="scope.row.qty" :max="scope.row.max" @keyup="calculate(scope.$index,$event)" @change="calculate(scope.$index,$event)" />
                          </template>
                          <template v-else>
                              {{scope.row.qty}}
                          </template>
                          <!-- {{scope.row.qty}} -->
                      </template>
                      </el-table-column>
                      <el-table-column label="Price" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.price}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Amount" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.amount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Discount" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.discount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Total Amount" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.total}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Remarks" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.remarks}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Status" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.status_label}}
                      </template>
                      </el-table-column>
                  </el-table>



              </template>
              <template :slot="'delivery-notes'">
                <el-table ref="itemsTable" border :data="form.delivery_notes" class="input-table mt-0" style="width: 100%">

                      <el-table-column label="Series" width="150">
                            <template slot-scope="scope">
                              {{scope.row.series}}
                            </template>
                          </el-table-column>
                      <el-table-column label="Date" width="100">
                            <template slot-scope="scope">
                              {{scope.row.date}}
                            </template>
                          </el-table-column>
                      <el-table-column label="Item Details">
                        <template slot-scope="scope">
                          {{scope.row.delivered_items}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Delivery Remarks">
                        <template slot-scope="scope">
                          {{scope.row.remarks}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Delivery Note" width="120">
                        <template slot-scope="scope">
                          <mdb-btn @click="fnPrint('/delivery/print-note/'+scope.row.token_id)" color="elegant" size="sm">Print</mdb-btn>
                        </template>
                      </el-table-column>

                    </el-table>
              </template>
              <template :slot="'invoice-slot'">
                 <mdb-row >
                    <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="true"/>
                    </mdb-col>
                    <mdb-col col="6">

                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Instructions" vid="instruction" name="instruction" v-model="form.attr.instruction" size="sm" :disabled="true"/>
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Other Details" vid="other_details" name="other_details" v-model="form.attr.other_details" size="sm" :disabled="true"/>
                    </mdb-col>

                  </mdb-row>

                  <el-table border :data="invoice.payments" class="input-table mt-2" style="width: 100%" :height="'250px'">
                    <el-table-column label="Series">
                      <template slot-scope="scope">
                        <a href="javascript:void(0)" @click="openPayment(scope.row)" v-html="scope.row.series"></a>

                      </template>
                    </el-table-column>
                    <el-table-column label="Date">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.date" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Mode of Payment">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.mode_of_payment" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Payment Description" width="160">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.payment_method" :disabled="true"/>
                      </template>
                    </el-table-column>
                    <el-table-column label="Amount" :width="100">
                      <template slot-scope="scope">
                        <input type="text" class="form-control grid-input thats-form-control text-right" :value="scope.row.formatted_amount" :disabled="true" />
                      </template>
                    </el-table-column>
                     <el-table-column :width="100" label="Receipt">
                        <template slot-scope="scope">
                            <a href="javascript:void(0)" @click="fnPrint('/sales/receipt/'+scope.row.token_id)" color="elegant" size="sm"><span class="badge badge-dark text-uppercase">Print</span></a>
                        </template>
                    </el-table-column>
                  </el-table>

              </template>
              <template :slot="'form-attachment'">
                     <ThatsFile labelClass="col-sm-5" fieldClass="col-sm-7 pl-0" label="Attachment" vid="sales-transaction-attachment" name="sales-transaction-attachment" v-model="form.attachment" :disabled="false" :showLabel="false" :accept="'*'"></ThatsFile>
              </template>

              <template :slot="'purchase-request'">
                <el-table ref="purchaseRequestTable" border :data="form.purchase_request_item" class="input-table mt-0" style="width: 100%" :summary-method="getPurchaseRequestSummaries" show-summary>

                      <el-table-column label="SKU" width="150">
                        <template slot-scope="scope">
                           {{scope.row.code}}
                        </template>
                      </el-table-column>
                      <el-table-column label="Image" width="90">
                        <template slot-scope="scope">
                            <img :src="scope.row.default" style="width:90px" />
                        </template>
                      </el-table-column>
                      <el-table-column label="Item Name" :row-class-name="''">
                          <template slot-scope="scope">
                          {{scope.row.name}}
                          </template>
                      </el-table-column>

                      <el-table-column label="Qty" :width="70" align="right">
                      <template slot-scope="scope">
                           {{scope.row.qty}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Price" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.price|amount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Amount" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.amount|amount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Discount" :width="100" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.discount|amount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Total Amount" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.total|amount}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Remarks" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.remarks}}
                      </template>
                      </el-table-column>
                      <el-table-column label="Status" :width="120" align = "right">
                      <template slot-scope="scope">
                          {{scope.row.status_label}}
                      </template>
                      </el-table-column>

                    </el-table>
              </template>
            </mdb-tabs>
          </div>
       </ThatsWindow>
          </ValidationObserver>
      <SearchFabric ref="searchFabric" :group="4"></SearchFabric>
      <ContactsForm ref="contactForm" :group="3" @success="contactSuccess" @update="contactUpdateSuccess"></ContactsForm>
      <PaymentForm ref="paymentForm" :group="(group + 1)" @success="paymentSuccess" @print="fnPrint"></PaymentForm>
      <UpdatePaymentForm ref="updatePaymentForm" :data="paymentForm" :group="3" @success="updatePaymentSuccess" @print="fnPrint" :formStatus="paymentFormStatus"></UpdatePaymentForm>
      <DiscountForm ref="discount" :group="(group + 1)" @apply="applyDiscount"></DiscountForm>
      <DiscountDialogForm ref="discountDialogForm" :group="(group + 1)" @apply="applyTierDiscount" :data="form"></DiscountDialogForm>
      <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>
      <InsuffecientStock ref="insuffecientStockWindow" :group="3" @saveContinue="saveContinue"></InsuffecientStock>
      <ContactsFormUpdate ref="contactsFormUpdate" :group="3" @success="contactUpdateSuccess"></ContactsFormUpdate>
      <UpdateLocation ref="updateLocationForm" :group="(group + 1)" @success="successUpdateLocation" :endpoint="`sales/${form.series}/change-location`"></UpdateLocation>
      <CustomTableWindow title="Quotations" ref="customTableWindow" :group="(group + 1)" @open="openQuotation" :width="1200" @merge="fnMergeQuotation"></CustomTableWindow>

      <HistoryWindow :group="(group + 1)" ref="historyWindow" title="History" :width="900"></HistoryWindow>

      <PaymentDialogForm ref="paymentDialogForm" @success="paymentSuccess"></PaymentDialogForm>

  </div>
</template>
<script>
import { mdbClickOutside , mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect, ThatsCheckbox, ThatsCustomWindow, HistoryWindow} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'
import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'
import ContactsForm from '../contacts/ContactsForm'
import DiscountForm from './DiscountForm'
import PaymentForm from './PaymentForm'
import PaymentDialogForm from './PaymentDialogForm'
import UpdatePaymentForm from './UpdatePaymentForm'
import PrintWindow from '../../components/thats/PrintWindow'
import GridVueSelect from './GridVueSelect'
import TableFooter from './TableFooter';
import Sortable from 'sortablejs'
import Checkbox from './Checkbox'
import axios from 'axios'
import SearchFabric from './SearchFabric'
import GridFabricSelect from './GridFabricSelect'
import InsuffecientStock from './InsuffecientStock'
import ContactsFormUpdate from './ContactsFormUpdate'

import CustomTableWindow from '../../components/thats/CustomTableWindow'
import UpdateLocation from './UpdateLocation'
import DiscountDialogForm from "./DiscountDialogForm.vue";
extend('min_value', {
  params: ['min'],
  validate: (value, { min }) => parseFloat(value) > parseFloat(min),
  message: 'The {_field_} field must be {min} or more'
});

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    },
    windowClass : {
      type : String,
      default : 'collection height-auto transaction-form'
    },
    parentContainerStyle  : {
      type : String,
      default : ''
    }
  },


  //#region[VueComponent]
    components: {
        UpdateLocation,
        CustomTableWindow,
        ContactsFormUpdate,
        InsuffecientStock,
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow,
        ThatsInput,
        ThatsSeriesInput,
        ThatsPriceInput,
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,
        ThatsCheckbox,
        ThatsCustomMultiSelect,
        PrintWindow,
        GridVueSelect,
        ContactsForm,
        DiscountForm,
        PaymentForm,
        TableFooter,

        Checkbox,
        UpdatePaymentForm,
        SearchFabric,
        GridFabricSelect,

        ThatsCustomWindow,
        HistoryWindow,
        PaymentDialogForm,
        DiscountDialogForm

  },

  inject : ['posShowroom','showroomsProvider'],
  //#endregion

    data : () => ({
      getCustomerLoyaltyMembershipLoading : false,
      testing : false,
      isIndeterminate : false,
      isIndeterminateFromDisplay : false,
      checkAllTbo : false,
      checkAllFromDisplay : false,
      modifyReturmItem : false,
      returmItem : false,
      request : null,
      selectedTbo : [],
      selectedFromDisplay : [],
      requests : [],
      disabled : false,
      modify : false,
      printUrl : '',
      active : 1,
      validated : false,
      form : null,
      formOriginal : null,
      formLoading : false,
      response : {
        error : null,
        message : null
      },
      selected : [],
      tabs : [
                { text: 'Quotation Details', slot: 'form-slot' }
              ],
      delivery_status : [],
      showError : false,

      paymentMethod : [],
      items : [],
      fabrics : [],
      customer : [],
      benefits : [],
      promotional : false,
      customer_change : false,
      activeCustomer : {
          series : 0,
          name : null,
          mobile : null,
          reference : '',
          description : null,
          image : [],
          attr : {

            address: null,
            address2 : {
                street : null,
                building : null,
                zone : null,
                area : null,
                city : null,
            },
            email : null,
            origin : 'portal',
            first_name : null,
            middle_name : null,
            last_name : null,
            salutation : null,
            corporate : false,
            contacts : [
                {
                    type : 'primary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'secondary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'home',
                    intl : false,
                    code : '+974',
                    value : null
                }
            ],
            contact_persons : []
          },
      },
      partnerSalesPerson : [],
      partner : [],
      toBeOrder : [],
     /*  showrooms : [], */
      filteredShowroom : [],

      discountForm : null,
      discountFormIndex : null,
      paymentForm : {
                series : 0,
                date : "",
                mode : "full-payment",
                method : "cash",
                customer : "",
                attr : {},
                description : null,
                invoice_series : null,
                amount : 0,
                reference : null,
                transaction_id : null,
                invoice : {
                  series : null,
                  balance : 0,
                  paid : 0,
                  amount : 0,
                },

              },
      paymentFormStatus : 'update',
      customFooter : {rows : [{name : "demo"},{remarks : "remarks"}], columns : [{
              field : 'name'
          },{
              field : 'remarks'
          }]},
      total : 0,
      context: null,
      viewMenu: false,
      top: '0px',
      left: '0px',
      newArrange : [],
      sortableState : {},
      sortableStateStatus : true,
      history : [],
      instructions : [
        {"label" : "Items by order", "code" : "Items by order"}
      ],

      disableForm : ['cancel','closed'],
      showOtherBrand : false,
      showCompetitor : false,
      showOther : false,
      displayAddress : "",

      isWalkin : false,
      validationKey : 0
    }),


  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();

        this.form.items.push(formItemVar);

        this.nextCode()
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' },

              ]

      }
       this.optionsHandler();
       this.discountForm = this.formItemVar();

        const {location} = JSON.parse(localStorage.getItem('user'));



        // console.log(location);
        // this.paymentMethod = settings.payment_mode
        // this.status = settings.sales_transaction_order_status;

        // console.log(this.$options);


        // new Sortable(tbody);

        /*  */


    },

  mounted(){

      // const arr = [
      //     {name : "test1", code : "test-1"},
      //     {name : "test2", code : "test-2"},
      //     {name : "test3", code : "test-3"},
      //     {name : "test4", code : "test-4"},
      //     {name : "test5", code : "test-5"},
      //     {name : "test6", code : "test-6"},
      //     {name : "test7", code : "test-7"},
      // ];

      // arr.splice(0, 0, arr.splice(2, 1)[0]);
/*       const tbody = this.$refs.itemTable.$el.querySelector(
        ".el-table__body-wrapper tbody"
      );

      console.log("mounted");
      console.log(tbody); */

        const self = this;
      if(self.posShowroom.includes(location.prefix)){
          self.customer = [
                  {
                    opt : "WALK-IN/000000",
                    label : "WALK-IN",
                    value : 10370,
                    mobile : "000000",
                    address : null,
                    series : "CON-10301",
                  }
                ]
                self.form.attr.name = "WALK-IN";
                self.form.attr.cx_series = "CON-10301";
                self.form.attr.mobile = "000000";
                self.form.customer_id = 10370

                self.isWalkin = true
        }

  },

  updated(){
    const tbody = this.$refs.itemTable.$el.querySelector(
        ".el-table__body-wrapper tbody"
      );
  },
  //#region[VueMethod]
    methods: {

      ...mapMutations('HistoryModule',[
        'setModule',
        'setRecordId',
      ]),
      ...mapActions('ContactsModule',{
        getContact : 'getRecord',
        storeContactRecord : 'StoreRecord',
      }),
      ...mapMutations('ContactsModule',{
        setFormContactStatusUpdate : 'SetFormStatusUpdate',
        setFormRecordUpdate : 'setFormRecordUpdate'
      }),
      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('TransactionModule',[
        'getPartner',
        'storeRecord',
        'updateRecord',
        'filterFabric',
        'updateStatus',
        'getRecord',
        'storeCreditNote',
        'getQuotation',
        'openRecord',

      ]),
      ...mapActions('ItemFilterModule',[
        'filterItems'
      ]),
      ...mapActions('LoyaltyMemberModule',[
        'getCustomerLoyaltyMembership','getBenefitsByCardId'
      ]),
      ...mapActions('MakePaymentModule',[
        'getCustomerCreditNotes',
        'getCustomerOverPaid'
      ]),
      ...mapMutations('MakePaymentModule',[
        'setPaymentForm',
      ]),
      ...mapMutations('LoyaltyMemberModule',[
        'setTierBenefits',
      ]),
      ...mapMutations('TransactionModule',[
        'setFormStatusCreate',
        'setFormRecord',
        'setFormStatusUpdate',
        'setTblKey'
      ]),
      ...mapMutations('ItemModule',{
        setItemFormStatusCreate : 'SetFormStatusCreate',
      }),
      ...mapMutations('DiscountModule',[
        'setDiscountForm',
      ]),
      ...mapMutations('TableModule',[
        'SetPage'
      ]),
      ...mapMutations('InsufficientAlertModule',[
        'setRows'
      ]),
      ...mapActions('ShowroomsModule',[
        'GetShowrooms'
      ]),

      ...mapMutations('CustomTableModule',{
        customTableModuleSetData : 'setData',
        setColumns : 'setColumns',
        setPage : 'setPage',
        setTotal : 'setTotal',
        setRows : 'setRows',
      }),
      ...mapMutations('UpdateLocationModule',{
        setUpdateLocationForm : 'setFormRecord'
      }),

      formVar(){
        return {
          series : "",
          date : "",
          status_index : 0,
          status : "no-remarks",
          attr : {
            name : null,
            mobile : null,
            address : null,
            address2 : {
                street : null,
                building : null,
                zone : null,
                area : null,
                city : null,
            },
            corporate : false,
            contact_person : {
              name : null,
              mobile : null,
              attr : {

                address: null,
                address2 : {
                    street : null,
                    building : null,
                    zone : null,
                    area : null,
                    city : null,
                },
                designation : null,
                email : null,
                first_name : null,
                middle_name : null,
                last_name : null,
                salutation : null
              }
            },
            cx_series : null,
            instructions : null,
            other_details : null,
            payment_status : 'no-payment',
            expected_date : null,
            tbo_details : null,
            discount_remarks : null,
            partner : [],
            document : {
              entry : null,
              status : false,
              number : null
            },
            valid_until : null,
            closed : {
              remarks : [],
              brand : null,
              competitor : null,
              others : null,
            },
            printed : {
              quotation : {
                  printed : false,
                  last_printed : null
              },
              invoice : {
                  printed : false,
                  last_printed : null
              }
            },

            more_discount : {
                enable : true,
                discount : 0,
                percent : 0
            },

            loyalty_member :null,
            card_id :"",
            loyalty_member_object :[],
            tier_benefits :[],

            multiple_tier_discount : {
                apply : false,
                payment_amount : 0,
                percent : 0,
                value : 0
            },
          
          },
          customer : {
            name : null,
            mobile : null,
            address : null,
            series : null,
            cx_series : null,
            corporate : false,
            contact_person : null
          },

          showroom : {
            location : null,
            prefix : null,
          },
          user_id : null,
          sold_by : null,
          customer_id : null,
          payment_method : 'cash',
          items : [],
          purchase_request_item : [],
          credit_note_items : [],
          delivery_notes : [],
          attachment : [],
          invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
          delivery_schedule : {
            date : "",
            remarks : null,
            status : 'for-delivery-schedule',
          },
          total : 0,
          more_discount : {
              enable : false,
              discount : 0,
              percent : 0
          },
          document : {
            entry : null,
            number : null
          },
          enableForceTbo : false
        }
      },

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                category : null,
                description: null,
                reference: null,
                qty: null,
                max: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                show_search: false,
                discount_type: null,
                benefit_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                applied_discount: {
                  type : null,
                  percent : 0,
                  value : 0
                },
                total: 0,
                tbo: false,
                tbo_qty: 0,
                stock: null,
                status: null,
                future_stock: null,
                remarks: null,
                from_display: false,
                tbo_minimum_qty: 1
              }
      },
/* start */
paymentSuccess(response){
  // this.setFormRecord(response.data)
  this.$emit('payment',response.data)
  this.closeDialog();

},
updatePaymentSuccess(v){
  const self = this;

  self.getRecord({token_id : self.form.token_id}).then((response) => {
    self.active = 1
  })
},
applyDiscount(v){

    const self = this;
  const applyDiscountType = v.form.custom_discount.apply
  const applyDiscountValue = v.form.custom_discount.discount
  const frm = v.form;
      const qty = frm.qty;
        let price = frm.price;

        if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
          const itemPromo = frm.promotions[0];
          const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);

          const discount = unformat(discountValue);
          frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


          frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          // frm.remarks = this.setRemarks(qty,itemPromo);
          frm.total = this.computeTotalAmount(qty, price, frm.discount)
        }

        if(frm.discount_type === 'discount'){
          const itemPromo = frm.promotions[0];
          const additional_discount = frm.benefits?.additional_discount ?? 0;


          const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          const discount_amount = (frm.price * frm.qty) - discountValue;

          const discount = unformat(discountValue + (discount_amount * additional_discount/100));
          frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });

          // frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          // frm.remarks = this.setRemarks(qty,frm.custom_discount);
          frm.total = this.computeTotalAmount(qty, price, frm.discount)
        }

  // if(frm.discount_type === 'discount'){
  //   const itemPromo = frm.promotions[0];
  //
  //   const discountValue = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
  //
  //   const discount = unformat(discountValue);
  //   frm.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });
  //
  //
  //   // frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
  //   // frm.remarks = this.setRemarks(qty,frm.custom_discount);
  //   frm.total = this.computeTotalAmount(qty, price, frm.discount)
  // }
    if(v.apply_all){
        const items = this.form.items;
        console.log(items)
        if(items.length > 0){
          items.forEach((item, index)=>{


              // const itemForm = JSON.parse(JSON.stringify(item));

              const unFormatPrice = unformat(item.price);
              let unFormatDiscount = unformat(applyDiscountValue);
              item.custom_discount.apply = applyDiscountType
              item.custom_discount.discount = applyDiscountValue
              if(unFormatDiscount !== null){
                  if(parseInt(unFormatDiscount) > 0){
                    item.discount_type = 'discount'
                  }else{
                    item.discount_type = null
                  }

                  if(unFormatDiscount === ""){
                    unFormatDiscount = 0
                  }

                  if(applyDiscountType === true || applyDiscountType === 1){
                    const off = (unFormatDiscount / 100) * unFormatPrice;
                    const promo = unFormatPrice - off;

                    item.custom_discount.promo = self.formatNumber(promo)
                    item.custom_discount.off_price = self.formatNumber(off)
                  }else{

                    if(unFormatDiscount === ""){
                        unFormatDiscount = 0;
                      }
                      const promo = unFormatPrice - unFormatDiscount;
                      item.custom_discount.promo = self.formatNumber(promo)
                      item.custom_discount.off_price = self.formatNumber(unFormatDiscount)
                  }

              }

              const qty = item.qty;
              let price = item.price;

              if(item.promotions.length > 0 && item.discount_type === 'promo'){
                const itemPromo = item.promotions[0];
                const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                const discount = unformat(discountValue);
                item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });


                item.discount = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);
                // item.remarks = self.setRemarks(qty,itemPromo);
                item.total = self.computeTotalAmount(qty, price, item.discount)
              }


                const itemPromo = item.promotions[0];

                const discountValue = self.computeDiscount(qty,price,itemPromo,item.discount_type,item.custom_discount);

                const discount = unformat(discountValue);
                item.discount = formatMoney(discount, { symbol: "", precision: 2, thousand: ",", decimal: "." });

                // item.remarks = self.setRemarks(qty,item.custom_discount);
                item.total = self.computeTotalAmount(qty, price, item.discount)


              self.$set(self.form.items, index, item)

          })
        }

    }else{
      this.$set(this.form.items, v.index, frm)
      this.calculateTable();
    }

},

rowCLassName({row}){

  // console.log(row)
    if(row.qty !== null && row.qty > row.remaining){
        return 'need-to-order';
    }

    return null;

},
cell({row, column, rowIndex, columnIndex}) {

  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }



      return null;
},
openDiscount(index,row){
  this.setDiscountForm({index : index, form: row});
  this.$refs.discount.openDialog();
},

openDiscountCalculatorDialog(){
  this.$refs.discountDialogForm.openDialog();
},
getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6,7,8]

        let totalCol = 3;
        let totalQty= 4;
        let totalPrice= 5;
        let totalAmount= 6;
        let totalDiscount= 7;
        let totalTotal= 8;

        if((self.formStatus === 'create')){
            totalCol = 4;
            totalQty= 5;
            totalPrice= 6;
            totalAmount= 7;
            totalDiscount= 8;
            totalTotal= 9;
        }

        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }


          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            return;
          }

          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
                  // if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                  //   const itemPromo = item.promotions[0];
                  //   d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  //   // alert(d)
                  // }else{
                    d = unformat(item.discount)
                  // }
                return d;
              })




              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;

              d = unformat(item.discount)
              // if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
              //   const itemPromo = item.promotions[0];
              //   d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              // }
              //
              // if(item.discount_type === 'discount'){
              //     const itemPromo = item.promotions[0];
              //     d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              //
              //   }


                return self.computeTotalAmountNumber(item.qty,item.price,d);
              });

              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);


                //  this.form.total =  this.formatNumber(sum)
                 this.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }


            return;
          }



          });

          // this.toggleCheck()
  console.log(columns,"getSummery")
        return sums;
      },
      getCreditNoteSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6,7,8]

        let totalTotal= 6;
        let totalAmount= 7;


        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }




          if (index === totalTotal) {
            const total = data.map(item => {
                return unformat(item.total);
              });

              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);


                //  this.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }


            return;
          }



          });

          // this.toggleCheck()

        return sums;
      },
    getActiveTabIndex(index){
      this.active = index
    },

openPaymentWindow(){

  const self = this;
  const paymentformVar = {
    transaction : this.form,
    payment_reference : [],

  }


  self.getCustomerCreditNotes(self.form)
  self.getCustomerLoyaltyMembership(self.form.attr.cx_series)
  /*  

  if(this.form.enableForceTbo){
      const hasDiscrepancy = this.formOriginal.items.filter((item) => {
        return item.qty > item.remaining && item.code !== null && item.tbo === false;
      },[]);
      const hasSelectedTbo = this.formOriginal.items.filter((item) => {
        return item.qty > item.remaining && item.code !== null && (item.tbo === true && item.tbo_qty <= 0);
      },[]);


      if(hasSelectedTbo.length > 0 || hasDiscrepancy.length > 0){
        MessageBox.alert("Please ensure that you review the item availability to avoid negative inventory quantities. In case of any discrepancy, kindly tick the TBO box as necessary.", 'Info', {
                  type: 'warning'
                  }).then(() => {
                    return false;
                  })
        return false;
      }
  }else{
    const hasItemRemainingZero = this.form.items.filter((item) => {
      return item.hasOwnProperty("remaining") && item.remaining <= 0;
    },[])

    if(hasItemRemainingZero.length > 0){
      MessageBox.confirm("Confirm the item's availability before proceeding", 'Info', {
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                type: 'warning'
                }).then(() => {

                  self.setPaymentForm(paymentformVar);
                  self.$refs.paymentDialogForm.openDialog()
                })
      return false;
    }

  } */


  self.setPaymentForm(paymentformVar);
                  self.$refs.paymentDialogForm.openDialog()




/*
  this.setPaymentForm(paymentformVar);
  this.$refs.paymentDialogForm.openDialog() */
},


array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
},

move(arr, old_index, new_index) {
    while (old_index < 0) {
        old_index += arr.length;
    }
    while (new_index < 0) {
        new_index += arr.length;
    }
    if (new_index >= arr.length) {
        var k = new_index - arr.length;
        while ((k--) + 1) {
            arr.push(undefined);
        }
    }
     arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
   return arr;
},

    optionsHandler(){
      const self = this;
      // this.dispatchFilterItems({transaction_date : this.form.date})
      // this.linksHandler();

      const session = JSON.parse(localStorage.getItem('user'));


      this.getPartner().then((response) => {

          console.log("partner")
          console.log(response)
          self.partner = response.filter((item) => item.isDesigner == 1 || item.isShowMan == 1, []);
          self.partnerSalesPerson = response
      })

    /*   self.GetShowrooms().then((response) =>{
            if(response.length > 0){

               self.showrooms = response


            }
        }) */


    },
    destroySortable(){
      this.sortableStateStatus = false
      this.sortableState = null
    },
    initSortable(){
        const self = this;
        this.disableSortable();

        // if(this.form.items.length > 0){


          const tbody = this.$refs.itemTable.$el.querySelector(
            ".el-table__body-wrapper tbody"
          );

          self.sortableState = Sortable.create(tbody,{
          onEnd: function (evt) {
          //   const oldIndex = evt.oldIndex;
          //   const newIndex = evt.newIndex;
          //   const toArrange = JSON.parse(JSON.stringify(self.form.items));
          //  self.newArrange = self.move(toArrange,oldIndex,newIndex);

            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            const toArrange = JSON.parse(JSON.stringify(self.form.items));
            const targetRow = toArrange.splice(oldIndex, 1)[0];
            toArrange.splice(newIndex, 0, targetRow);
            self.newArrange = toArrange

          },
          sort : true
        });

        // console.log(self.sortableState)

          //  }


       /*  const d = this.$refs.itemTable;

        console.log(d)
      const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{
          onEnd: function (evt) {
          //   const oldIndex = evt.oldIndex;
          //   const newIndex = evt.newIndex;
          //   const toArrange = JSON.parse(JSON.stringify(self.form.items));
          //  self.newArrange = self.move(toArrange,oldIndex,newIndex);

            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            const toArrange = JSON.parse(JSON.stringify(self.form.items));
            const targetRow = toArrange.splice(oldIndex, 1)[0];
            toArrange.splice(newIndex, 0, targetRow);
            self.newArrange = toArrange

            console.log(toArrange)

           alert(1)
          },
          sort : true
        }); */
    },
    disableSortable(){
        const self = this;
        if(Object.keys(self.sortableState).length !== 0){
        const tbody = this.$refs.itemTable.$el.querySelector(
            ".el-table__body-wrapper tbody"
          );

          self.sortableState = Sortable.create(tbody,{
          onEnd: function (evt) {
          //   const oldIndex = evt.oldIndex;
          //   const newIndex = evt.newIndex;
          //   const toArrange = JSON.parse(JSON.stringify(self.form.items));
          //  self.newArrange = self.move(toArrange,oldIndex,newIndex);

            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;
            const toArrange = JSON.parse(JSON.stringify(self.form.items));
            const targetRow = toArrange.splice(oldIndex, 1)[0];
            toArrange.splice(newIndex, 0, targetRow);
            self.form.items = toArrange

          //   console.log(toArrange)

          //  alert(1)
          },
          sort : true
        });
        }


    },
    enableSortable(){
        const self = this;

    /*   const tbody = document.querySelector('#itemTable .el-table__body-wrapper table.el-table__body tbody')
        self.sortableState = Sortable.create(tbody,{
          onEnd: function (evt) {


            const oldIndex = evt.oldIndex;
            const newIndex = evt.newIndex;

            const targetRow = self.form.items.splice(oldIndex, 1)[0];
            self.form.items.splice(newIndex, 0, targetRow);

            const toArrange = JSON.parse(JSON.stringify(self.form.items));
           self.newArrange = self.move(toArrange,oldIndex,newIndex);
          },
          sort : self.sortableStateStatus
        }); */
    },
    customLabel({label , mobile}){
      return `${label} – ${mobile}`
    },
    fnGetContacts(params){
      const self = this;
      const contacts = []
      this.GetContacts(params).then((response) => {
          if(response.length > 0){
            response.forEach((element) => {
              const optValue = element.card_number !== null
                  ? element.label + " " + element.mobile + " " + element.card_number
                  : element.label + " " + element.mobile;
                this.form.attr.found_loyalty = element.found_card
              contacts.push({
                ...element,
                opt: optValue,
              })
            })
          }

      })

      self.customer = contacts;
    },
    async setPreparedBy(){
        var self = this;
        const loggedUser = await userData()

        const frm = this.form
        frm.sold_by = loggedUser.user.name
        this.form = frm;

        this.filteredShowroom = this.showrooms.filter((i) =>{
            return loggedUser.location_prefix.includes(i.prefix)
        }).map((i) => {
          return i.prefix
        })

    },


  deleteItem(){

    if(this.selected.length){
        if(this.formStatus === 'update'){
            MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                  this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){

                  const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
              })
        }else{
          this.selected.forEach(element => {
                    var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                    this.form.items.splice(removeIndex, 1);
                  });

                  if(this.form.items.length <= 0){
                    const formItemVar = this.formItemVar();
                    this.form.items.push(formItemVar)
                  }
        }



    }


// remove object


},

handleSelectionChange(v){

    this.selected = v

},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelectedFabric(v,index, row){
  const self = this;

  console.log(row)
  const getItem = this.fabrics.filter( e => {
      return (e.id === v);
  })[0]

  // console.log(getItem)

  if(getItem){
    const name = `${row.name}/${getItem.code}/${getItem.label}`;
    this.$set(this.form.items[index], 'default', getItem.thumb)
    this.$set(this.form.items[index], 'name', name)
    // this.$set(this.form.items[index], 'remarks', remarks)
  }

  this.handleOutsideClick();
},



getSelected(v, index) {
    const getSelected = this.items.find(item => item.code === v);

    const qty = 1;

    if (getSelected) {
        const formItem = this.form.items[index];
        const {
            category,
            id: item_id,
            label: name,
            default: default_value,
            remaining: max,
            promotions,
            formatted_price,
            stock,
            eta,
            eta_status,
            future: future_stock,
            status,
            price,
            code
        } = getSelected;
        const amount = this.computeAmount(qty, price);
        const total = this.computeTotalAmount(qty, price, 0);

        const applied_discount = {
              type : null,
              percent : 0,
              price : 0,
              value : 0,
              
        }

        let discountType = null;
        if(getSelected.hasOwnProperty("promotions") && getSelected.promotions.length > 0){
          discountType = 'promo';
        }

        Object.assign(formItem, {
            code,
            category,
            item_id,
            name,
            default: default_value,
            qty,
            max,
            remaining: max,
            promotions,
            formatted_price,
            stock,
            eta,
            eta_status,
            future_stock,
            status,
            price,
            amount,
            discount: 0,
            total,
            remarks: "",
            discount_type : discountType,
            applied_discount
        });

        console.log("Selected Item")
        console.log(formItem)

        this.calculateTable();
        this.newArrange = this.move(this.form.items, index, index);

        const dataLength = this.form.items.length - 1;
        if (dataLength === index) {
            this.form.items.push(this.formItemVar());
            this.$nextTick(() => {
                this.$refs.gridSelect.$children[0].$refs.search.focus();
            });
        }
    }

    this.handleOutsideClick();
},


calculateTable(){

  let filterBenefits = this.benefits?.filter(benefit => benefit.benefit_type === 'discount');
  
  const filterBenefit = JSON.parse(JSON.stringify(filterBenefits));
  let applied_tier_discount = null;
  let benefitDiscountPercentage = 0;
  let unitDiscountPercent = 0;
  if(filterBenefit.length > 0){
    applied_tier_discount = filterBenefit[0];
    benefitDiscountPercentage = parseFloat(filterBenefit[0].discount) / 100;
        const tierBenefitAdditionalDiscount = parseFloat(filterBenefit[0].additional_discount) || 0;

        unitDiscountPercent = tierBenefitAdditionalDiscount/100;

        console.log("filterBenefit");
        console.log(filterBenefit);
        console.log("benefitDiscountPercentage");
        console.log(benefitDiscountPercentage);

        console.log("additional_discount");
        console.log(unitDiscountPercent);
  }


    



        
  const itemItems = this.form.items.map((item) => {
    

    let price = (item.price !== null) ? unformat(item.price) : 0;
    let qty = (item.qty !== null) ? parseInt(item.qty) : 0;
    let amount = price * qty;
    let discount = 0;
    let total = amount;
    let discount_type = item.discount_type;
    let applied_discount = { type: item.discount_type, percent: 0, price: 0, value: 0 },
    appliedDiscountObject = { type: null, percent: 0, price: 0, value: 0 };
    let remarks = (item.remarks !== null) ? item.remarks : "";
    let benefit_type = item.benefit_type;
   
    if(item.code !== null){
        
    
        if(item.discount_type !== null){
            switch (item.discount_type) {
              case 'promo':
              case null:
                  if(item.hasOwnProperty('promotions') && item.promotions.length > 0){
                    discount_type = 'promo';
                    console.log("Calculating Promo")
                    const itemPromo = item.promotions[0];
                    price = unformat(itemPromo.price);
                    const discountPercent = unformat(itemPromo.discount)/100;
                    discount = (price * discountPercent) * item.qty;
                    total = amount - discount;
                    applied_discount.percent = unformat(itemPromo.discount)/100;
                    applied_discount.price = itemPromo.price;
                    applied_discount.value = itemPromo.price * discountPercent;
    
                    appliedDiscountObject = {
                      type : 'promo',
                      percent : discountPercent,
                      price : unformat(itemPromo.price),
                      value : unformat((itemPromo.price * discountPercent))
                    }
                    remarks = itemPromo;
                  }
    
                break;
    
              case 'discount':
              console.log("Calculating Discount")
                const itemDiscount = item.custom_discount;
                price = unformat(item.price);
                const discountPercent = unformat(itemDiscount.off_price)/price;
                discount = (price * discountPercent) * item.qty;
                total = amount - discount;
                applied_discount.percent = discountPercent;
                applied_discount.price = item.price;
                applied_discount.value = item.price * discountPercent;
    
                appliedDiscountObject = {
                  type : 'discount',
                  percent : discountPercent,
                  price : unformat(item.price),
                  value : unformat(itemDiscount.off_price)
                }
                console.log("Custom discount")
    
                remarks = itemDiscount;
                // remarks = `${discountPercent} Discount`;
              break;
            }
        }

        
        const applyMultipleTierDiscount = this.form.attr.multiple_tier_discount.apply;
    
        if (filterBenefits.length > 0) {
              
                
    
                if(appliedDiscountObject.value > 0){
                 
                 
                  const itemAppliedDiscount = price - appliedDiscountObject.value
                  
                  console.log("itemDiscountTotal",price,itemAppliedDiscount,unitDiscountPercent, item.code, applied_tier_discount)
    
                  if(unitDiscountPercent > 0){
                    
                    const additionalDiscount = itemAppliedDiscount * unitDiscountPercent;
                    applied_tier_discount.apply = 'additional_discount';
                    applied_tier_discount.value = additionalDiscount;
                    const unitDiscount = (appliedDiscountObject.value + additionalDiscount);
                    discount = unitDiscount * qty;
                    total = (price * qty) - discount;
                    const benefitDiscountPercentage = discount/amount;
                    applied_discount.value = unitDiscount;
                    applied_discount.percent = benefitDiscountPercentage;
    
                    console.log("itemAppliedAdditionalDiscountTotal",item.code, itemAppliedDiscount, unitDiscountPercent, additionalDiscount, unitDiscount, discount, total,applied_discount)
    
                    remarks = `${applied_tier_discount.name}`;
                    applied_discount.type = 'loyalty-additional-discount';
                    benefit_type = 'benefit-additional-discount';
                  }
    
                }else{
                 /*  const benefitDiscountPercentage = parseFloat(filterBenefit[0].discount) / 100; */
                /*   console.log("non promo item", filterBenefit[0]) */
               
                  
                  applied_discount.percent = benefitDiscountPercentage;
                  console.log("benefitDiscountPercentage",benefitDiscountPercentage)
                  let benefitDiscount = price * benefitDiscountPercentage;
                  console.log("benefitDiscount",price,benefitDiscount)
                  discount = qty * benefitDiscount;
                  console.log("discount",discount)
                  total = (price * qty) - discount;
                  remarks = `${applied_tier_discount.name}`;
                  benefit_type = 'benefit-discount';
                  applied_tier_discount.discount = benefitDiscountPercentage;
                  applied_tier_discount.additional_discount = 0;
                  console.log("itemAppliedDiscountTotal",item.code, price,benefitDiscountPercentage,benefitDiscount, discount, total)
                  applied_discount.type = 'loyalty-discount';
                  applied_discount.value = benefitDiscount;
                }
    
                applied_discount.price = price;
                
    
        }
    }





    console.log("return",item.code,discount_type,price,discount,amount,total,remarks,applied_discount,{
      ...item,
      price : price,
      discount_type,
      benefit_type,
      applied_discount,
      discount : this.formatNumber(discount),
      amount : this.formatNumber(amount),
      total : this.formatNumber(total),
      remarks : this.setLineRemarks(remarks),
    })
    return {
      ...item,
      price : price,
      discount_type,
      benefit_type,
      applied_discount,
      attr : {
        ...item.attr,
        applied_tier_discount : {
          apply : applied_tier_discount?.apply,
          name : applied_tier_discount?.name,
          discount : applied_tier_discount?.discount,
          additional_discount : applied_tier_discount?.additional_discount,
          value : applied_tier_discount?.value
        }
      },
      discount : this.formatNumber(discount),
      amount : this.formatNumber(amount),
      total : this.formatNumber(total),
      remarks : this.setLineRemarks(remarks),
    }
  });

  this.form.items = itemItems;
},

getSelectedx(v,index){
  const self = this
  const getSelected = this.items.filter(item => {
        return (item.code === v)
    },[])[0];


    console.log("getSelected")
    console.log(this.items)
    console.log(getSelected)

    if(getSelected){

      const qty = 1;
      let price = getSelected.price;
      let discount = 0;
      let discount_value = 0;
      let remarks = null;
      let discount_type = null;
      let applied_discount = {
                  type : null,
                  percent : 0,
                  price : 0,
                  value : 0
                };
      let benefit_type = null;
      let loyaltyMemberDiscount = null;



      let filterBenefits = this.benefits?.filter(benefit => benefit.benefit_type === 'discount');

      if(getSelected.promotions.length > 0){
        const itemPromo = getSelected.promotions[0];

        const cd = {
          apply: true,
          discount: itemPromo.promo,
          off_price: itemPromo.off_price,
          promo: itemPromo.promo,
        }

        console.log("promo")
        console.log(itemPromo)

        discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
        remarks = this.setRemarks(qty,itemPromo);
        discount_type = 'promo';
        applied_discount.type = 'promo';
        applied_discount.percent = unformat(itemPromo.discount)/100;
        applied_discount.price = itemPromo.price;
        applied_discount.value = itemPromo.off_price;
      }



      if (filterBenefits && filterBenefits.length > 0) {

        let benefit_discount_percentage = filterBenefits[0].discount;
        let tier_name = filterBenefits[0].tier_name;

        let benefitDiscount = price * benefit_discount_percentage / 100;
        discount = qty * benefitDiscount;
        discount_value = discount;
        remarks = tier_name + ' ' + 'Discount';
        benefit_type = 'benefit_discount';
        applied_discount.type = 'loyalty-discount';
        applied_discount.price = price;
        applied_discount.percent = unformat(benefit_discount_percentage)/100;
        applied_discount.value = benefitDiscount;

      }

      // Check if the customer is not a loyalty member
      if (this.form.attr.loyalty_member == true) {
        if (getSelected.promotions.length > 0) {
          const itemPromo = getSelected.promotions[0];

          const cd = {
            apply: true,
            discount: itemPromo.promo,
            off_price: itemPromo.off_price,
            promo: itemPromo.promo,
          };

          // Apply additional discount from filterBenefits
          if (filterBenefits && filterBenefits.length > 0) {
            const additional_discount = filterBenefits[0].additional_discount || 0;
            const promo = this.computeDiscount(qty, price, itemPromo, 'promo', cd);
            const discount_amount = (price*qty) - promo;
            discount_value = discount_amount * additional_discount/100;
            discount = promo + (discount_amount * additional_discount/100);
            let tier_name = filterBenefits[0].tier_name;
            remarks = tier_name + ' ' + 'Discount';
            benefit_type = 'additional_benefit_discount'; 

            applied_discount.type = 'loyalty-additional-discount';
            applied_discount.price = price;
            applied_discount.percent = discount / price;
            applied_discount.value = discount;

          }
        }
      }


      console.log("getSelectedBeforeSet")
      console.log(this.form.items[index])

      

    

      this.$set(this.form.items[index], 'applied_discount', applied_discount);
      this.$set(this.form.items[index], 'discount_type', discount_type);
      this.$set(this.form.items[index], 'benefit_type', benefit_type);
      this.$set(this.form.items[index], 'benefit_value', discount_value);
      this.$set(this.form.items[index], 'benefits', filterBenefits && filterBenefits.length > 0 ? filterBenefits[0] : null);
      this.$set(this.form.items[index], 'benefit_discount', loyaltyMemberDiscount);
      this.$set(this.form.items[index], 'category', getSelected.category)
      this.$set(this.form.items[index], 'item_id', getSelected.id)
      this.$set(this.form.items[index], 'name', getSelected.label)
      this.$set(this.form.items[index], 'default', getSelected.default)
      this.$set(this.form.items[index], 'qty', qty)
      this.$set(this.form.items[index], 'max', getSelected.remaining)
      this.$set(this.form.items[index], 'remaining', getSelected.remaining)
      this.$set(this.form.items[index], 'promotions', getSelected.promotions)
      this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
      this.$set(this.form.items[index], 'stock', getSelected.stock)
      this.$set(this.form.items[index], 'eta', getSelected.eta)
      this.$set(this.form.items[index], 'eta_status', getSelected.eta_status)
      this.$set(this.form.items[index], 'future_stock', getSelected.future)
      this.$set(this.form.items[index], 'status', getSelected.status)
      this.$set(this.form.items[index], 'price', price)
      this.$set(this.form.items[index], 'amount', this.computeAmount(qty, price))
      this.$set(this.form.items[index], 'discount', discount)
      this.$set(this.form.items[index], 'remarks', remarks)
      this.$set(this.form.items[index], 'total', this.computeTotalAmount(qty, price, discount))



      console.log("this.form.items[index]")
      console.log(this.form.items[index])
      this.newArrange = this.move(this.form.items,index,index);

      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

              self.$nextTick(() => {
                  self.$refs.gridSelect.$children[0].$refs.search.focus()
                  });
        }



    }

    this.handleOutsideClick()
},

statusKeyUpFocusFabric(){

  const localItems = this.fabrics;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.fabrics = filterOnFocus
  }


},
statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }


},

dispatchFilterItems(params){
  const results = [];
  console.log("dipspatch filter")
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {

            console.log("Repsonse dispatch filter")
            console.log(element)
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions,eta : element.eta,qty : element.qty,future : element.future_stock, default : element.default,category : element.category, remaining : element.remaining,status : element.status,stock : element.stock,eta_status : element.status_text, max : element.max})
          });
        }
    })
  this.items = results

},

statusKeyUpFabric(v){
// this.getPurchaseOrder(v)

const params = {term : v};
  const results = [];
  this.filterFabric(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({thumb: element.thumb,label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.price, id : element.id, default : element.default, description : element.description})
          });
        }
    })
  this.fabrics = results
},
statusKeyUp(v){
// this.getPurchaseOrder(v)
  if(v != null && v != ''){
    const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
            this.dispatchFilterItems(params)
  }

},
 openDialog(){
        this.$refs.window.openDialog();

      },
      nextCode(){
        var self = this;
      },
/* end */
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      onSubmitFormPurchaseRequest(){
            var self =this
            self.form.create_purchase_order = true;
            self.onSubmitForm();
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false


            console.log(isValid);
            console.log(self.form.items);
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){

                        if(self.newArrange.length > 0){

                          self.form.items = self.newArrange.map(item => {
                            const amount = typeof item.amount === 'string' ? parseFloat(item.amount.replace(/,/g, '')) : parseFloat(item.amount);
                            const total = typeof item.total === 'string' ? parseFloat(item.total.replace(/,/g, '')) : parseFloat(item.total);
                            const discountPrice = amount - total;
                            const discountTotalPercentage = discountPrice / amount * 100;
                            console.log(amount,total,discountPrice,discountTotalPercentage)
                            // Update the item with the new discount_total property
                            return {
                              ...item,
                              discount : unformat(item.discount),
                              attr: {
                                ...item.attr,
                                discount_type: item.discount_type,
                            
                                /* benefit_type: item.benefit_type,
                                
                                // Note: You may want to use the same discountTotalPercentage here
                                discount_total: parseFloat(discountTotalPercentage.toFixed(2)),
                                discount_value: discountPrice,
                                  benefit_value: item.benefit_value */
                              }
                            };
                          });

                        }

                        const f = self.form.items.filter((e) => {
                          return parseInt(e.qty) > e.stock && e.tbo === false && e.status === 0 && e.eta.length > 0;
                        }).map((i) => {
                          i['reserve'] = 0
                          return i
                        });

                        if(f.length > 0){
                            //Todo
                            self.setRows(f)
                            self.$refs.insuffecientStockWindow.openDialog();
                        }

                        console.log(f)

                          self.storeRecord(self.form).then((response) => {

                              console.log("onSave")
                              console.log(response)
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.setFormStatusUpdate()
                              self.setFormRecord(response)
                              self.formLoading = false
                              self.SetPage(1)
                              self.newArrange = []
                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                        self.formLoading = true


                        if(self.newArrange.length > 0){
                          // self.form.items = self.newArrange
                          self.form.items = self.newArrange.map(item => {
                            const amount = typeof item.amount === 'string' ? parseFloat(item.amount.replace(/,/g, '')) : parseFloat(item.amount);
                            const total = typeof item.total === 'string' ? parseFloat(item.total.replace(/,/g, '')) : parseFloat(item.total);
                            const discountPrice = amount - total;
                            const discountTotalPercentage = discountPrice / amount * 100;
                            console.log(amount,total,discountPrice,discountTotalPercentage)
                            // Update the item with the new discount_total property
                            return {
                              ...item,
                              attr: {
                                discount : unformat(item.discount),
                                discount_type: item.discount_type,
                                ...item.attr,
                            
                               /*  benefit_type: item.benefit_type,
                                
                                // Note: You may want to use the same discountTotalPercentage here
                                discount_total: parseFloat(discountTotalPercentage.toFixed(2)),
                                discount_value: discountPrice,
                                  benefit_value: item.benefit_value */
                              }
                            };
                          });

                        }
                        self.form.items = self.form.items.map(item => {
                          // Calculate discount_total based on the formula: (discounted price / original price) * 100

                          const amount = typeof item.amount === 'string' ? parseFloat(item.amount.replace(/,/g, '')) : parseFloat(item.amount);
                          const total = typeof item.total === 'string' ? parseFloat(item.total.replace(/,/g, '')) : parseFloat(item.total);
                          const discountPrice = amount - total;
                          const discountTotalPercentage = discountPrice / amount * 100;
                          console.log(amount,total,discountPrice,discountTotalPercentage)
                          // Update the item with the new discount_total property
                          return {
                            ...item,
                            attr: {
                              ...item.attr,
                            /*   benefit_type: item.benefit_type,
                              benefits: item.benefits,
                              // Note: You may want to use the same discountTotalPercentage here
                              discount_total: parseFloat(discountTotalPercentage.toFixed(2)),
                              discount_value: discountPrice,
                                benefit_value: item.benefit_value */
                            }
                          };
                        });


                        console.log(self.form.items, "swaraj update");

                        self.modify = false
                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.$emit('success')

                              // consol.log(response)
                              // self.setFormRecord(response)
                              self.formLoading = false
                              self.newArrange = []
                              self.selected = []
                              self.returmItem = false
                              self.modify = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.modify = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }

            }
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
              ]
        this.disabled = false
        this.modify = false
        this.returmItem = false
        this.modifyReturmItem = false
        this.newArrange = []


      const session = JSON.parse(localStorage.getItem('user'));

      this.form.showroom.location = session.location.id
      this.form.showroom.prefix = session.location.prefix

      this.isWalkin = false
        this.benefits = [];
        this.customer_change = false;
        this.promotional = false;
      },

      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;


        MyDate.setDate(MyDate.getDate() + 14);

        var date2 =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);

        this.form.attr.valid_until = date2;
      },
      dialogState(state){
        const self = this;
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          // this.disableSortable()
          this.$refs.historyWindow.closeDialog()
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          this.initSortable();
            this.nextCode();
            this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
              ]


              self.$nextTick(() => {

                const {location} = JSON.parse(localStorage.getItem('user'));
                if(self.posShowroom.includes(location.prefix)){

                      self.customer = [
                        {
                          opt : "WALK-IN/000000",
                          label : "WALK-IN",
                          value : 10370,
                          mobile : "000000",
                          address : null,
                          series : "CON-10301",
                        }
                      ]
                      self.form.attr.name = "WALK-IN";
                      self.form.attr.cx_series = "CON-10301";
                      self.form.attr.mobile = "000000";
                      self.form.customer_id = 10370

                      self.isWalkin = true
              }


              })



        }

        // alert("dialog State " + this.formStatus)

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;




            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }
            else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }


            }
           const p = parseFloat(price) * qty;

                  if(apply === true || apply === 1){
                    console.log(`${apply} - Apply | D- ${discount} | DT - ${discount_type} | p- ${p}`)
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount);
                  }

          return discountPrice;
        }
        return 0;
      },

      setLineRemarks(value = null) {
        if (value!== null && typeof value === 'object' && value.apply !== undefined && value.discount !== undefined) {
            if (value.apply && value.discount > 0) {
                return `${value.discount}% Discount`;
            } else {
                return value.discount > 0 ? `${this.formatNumber(value.discount)} QAR Off` : "";
            }
        } else {
            return value;
        }
      },
      setRemarks(qty,promo){

          if(promo.apply === true || promo.apply === 1){
              if(promo.discount > 0){
                return `${promo.discount}% Discount`;
              }else{
                return "";
              }
          }else{
            if(promo.discount > 0){
                return `${this.formatNumber(promo.discount)} QAR Off`;
              }else{
                return "";
              }
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = unformat(discount);
        const amount = qty * unformat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },



      calculate(index) {
        const self = this;
        this.calculateTable();
        this.newArrange = this.move(this.form.items, index, index);

     /*    if (typeof self.form.items[index] !== 'undefined') {
          const item = self.form.items[index];

          let discount = 0;
          let discount_value = 0;
          let remarks = null;


              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)
                 this.$set(self.form.items[index], 'tbo_minimum_qty', ((item.remaining - item.qty) <= 0) ? item.qty : (item.remaining - item.qty))

              }

            if (item.promotions.length > 0) {
              const itemPromo = item.promotions[0];
              discount = this.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);
              console.log("Promo discount", discount)
              remarks = this.setRemarks(item.qty, itemPromo);

            }





          //Added logic to calculate benefits discount
          if (item.benefit_type === 'benefit_discount' && item.benefits) {
            const benefit_discount_percentage = item.benefits.discount;
            const tier_name = item.benefits.tier_name;
            const benefitDiscount = item.price * benefit_discount_percentage / 100;
            discount = item.qty * benefitDiscount;
            discount_value = discount;
            console.log("Benefits discount", discount)
            remarks = tier_name + ' ' + 'Discount';
          }


          if (item.benefit_type === 'additional_benefit_discount' && item.benefits){
            const additional_discount = item.benefits.additional_discount;
            const itemPromo = item.promotions[0];

            const promo = this.computeDiscount(item.qty, item.price, itemPromo, 'promo', item.custom_discount);
            const discount_amount = (item.price*item.qty) - promo;

            discount_value = discount_amount * additional_discount/100;
            discount = promo + (discount_amount * additional_discount/100);
            const tier_name = item.benefits.tier_name;
            remarks = tier_name + ' ' + 'Additional Discount';
          }


          if (item.discount_type === 'discount') {
            const itemPromo = item.promotions[0];

              const additional_discount = item.benefits?.additional_discount ?? 0;

              const promo = this.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);

                const discount_amount = (item.price * item.qty) - promo;

                if (item.custom_discount.apply == false || item.custom_discount.discount == 0 ){
                  console.log("banda promo")
                }else{
                  discount_value = discount_amount * additional_discount/100;
                  discount = promo + (discount_amount * additional_discount/100);
                }




              console.log("custom discount + additional benefits", discount)
              remarks = this.setRemarks(item.qty, item.custom_discount);


          }



          if (item.code !== null && item.name !== null) {
            const amount = this.computeAmount(item.qty, item.price);
            const totalAmount = this.computeTotalAmount(item.qty, item.price, discount);

            this.$set(self.form.items[index], 'remarks', remarks);
            this.$set(self.form.items[index], 'discount', this.formatNumber(discount));
            this.$set(self.form.items[index], 'benefit_value', discount_value);
            this.$set(self.form.items[index], 'amount', amount);
            this.$set(self.form.items[index], 'total', totalAmount);
          }
        } */
      },

      toBeOrderQuantity(index){
        const self = this;
        if (typeof self.form.items[index] !== 'undefined') {
          const item = self.form.items[index];

          console.log(item.tbo_qty,item.tbo_minimum_qty)
            if(item.tbo_qty < item.tbo_minimum_qty){
              this.$set(self.form.items[index], 'tbo_qty', item.qty)
            }

        }
        /*
            const item = self.form.items[index];
            if(item.qty > item.remaining){
              this.$set(self.form.items[index], 'qty', item.remaining)
            }

          }
        } */
      },

      formatNumber(n) {

        return formatMoney(n, { symbol: "", precision: 2, thousand: ",", decimal: "." })
/*         return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        }); */
      },
      fnPrint(url){
        let session = JSON.parse(localStorage.getItem('user'));

        const locationId = session.location.id
        let token = session.token;



        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(url+'?location='+locationId+'&token='+token, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

      },
      fnPrintProforma(url){
        let session = JSON.parse(localStorage.getItem('user'));

        const locationId = session.location.id
        let token = session.token;



        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
        const win = window.open(url+'?location='+locationId+'&as=proforma&token='+token, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

      },


      toggleCheck(){
        const self = this;
        if(this.form.transaction.items.length > 0){
          const getCode = this.form.items.map(i => i.code);

          if(getCode.length > 0){
              self.$nextTick(() => {
                self.form.transaction.items.forEach((item,index) => {
                    if(getCode.includes(item.code)){
                      self.$refs.itemsTable.toggleRowSelection(item,true);
                      // alert(index)
                    }
                })

              })



              }else{
                self.$refs.itemsTable.clearSelection();
              }

        }
      },

      getRowKey(row){
        return row.code
      },
      fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["mobile", "label"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },

    searchCustomer(v){
        const self = this;
        if(v !== ""){
          self.fnGetContacts({term : v})
        }
    },
      getCustomerSelected(v){

        console.log("onSelected Multiselect")
        const self = this;
        const selectedCustomer = this.customer.filter(c => {
            return c.value === v;
        })[0];

        if(selectedCustomer !== null){
            this.form.attr.name = selectedCustomer.label
            this.form.attr.mobile = selectedCustomer.mobile
            this.form.attr.address = selectedCustomer.address
            this.form.attr.cx_series = selectedCustomer.series
            this.form.attr.corporate = selectedCustomer.attr.corporate
            this.form.attr.found_loyalty = selectedCustomer.found_card



            this.activeCustomer = selectedCustomer

               let add = ""
                     add += (selectedCustomer.attr.address != "" && selectedCustomer.attr.address !== null) ? selectedCustomer.attr.address  : "";

                    add += (selectedCustomer.attr.address2.street != "" && selectedCustomer.attr.address2.street !== null) ? ","+selectedCustomer.attr.address2.street  : "";
                    add += (selectedCustomer.attr.address2.building != "" && selectedCustomer.attr.address2.building !== null) ? ","+selectedCustomer.attr.address2.building  : "";
                    add += (selectedCustomer.attr.address2.zone != "" && selectedCustomer.attr.address2.zone !== null) ? ","+selectedCustomer.attr.address2.zone  : "";
                    add += (selectedCustomer.attr.address2.area != "" && selectedCustomer.attr.address2.area !== null) ? "/"+selectedCustomer.attr.address2.area  : "";
                    add += (selectedCustomer.attr.address2.city != "" && selectedCustomer.attr.address2.city !== null) ? "/"+selectedCustomer.attr.address2.city  : "";

              this.displayAddress = add.trim();

            this.getQuotation({mobile : selectedCustomer.mobile}).then((response) => {


                  if(response.data.data.length > 0){
                      self.setColumns(response.columns)
                      self.setPage(response.data.current_page)
                      self.setTotal(response.data.total)
                      self.setRows(response.data.data)

                      self.$refs.customTableWindow.openDialog();
                  }
            });

          // Get benefits for the selected customer
          this.customer_change = true;
           this.checkLoyaltyMembership(selectedCustomer.series, true);
          console.log("This Customer api");

        }

      },

      async checkLoyaltyMembership(cxSeries, calculate  = false){
        this.getCustomerLoyaltyMembershipLoading = true
        const response = await this.getCustomerLoyaltyMembership(cxSeries);
        const attr = JSON.parse(JSON.stringify(this.form.attr));
   
        if(response.length > 0){
          const card_id = response[0].card_id ?? "";
          attr.card_id = card_id;
          attr.loyalty_member = true;
            const benefitsResponse = await this.getBenefitsByCardId(attr.card_id);
              this.benefits = benefitsResponse;
              this.getCustomerLoyaltyMembershipLoading = false
          }else{
            this.benefits = []; 
            attr.loyalty_member = false;
            this.getCustomerLoyaltyMembershipLoading = false
            
          }

          this.form.attr = attr
          this.form.card = {
            points : 0,
            ...response[0]
          }

          if(calculate){
            this.calculateTable();
          }
          
      },
      async checkLoyaltyMembershipPoints(cxSeries){
        const response = await this.getCustomerLoyaltyMembership(cxSeries);
        if(response.length > 0){
          this.form.card = response[0]
          }else{
            this.form.card = {
              points : 0
            }
          }       
          
          this.form.attr.loyalty_member_object = response ?? [];
      },
      async updateBenefits(cxSeries) {
        console.log("This benefit api");
        const response = await this.getCustomerLoyaltyMembership(cxSeries);

        if(this.customer_change === true){
          try {

            if (response && response.length > 0) {
              this.form.attr.loyalty_member = true;

              this.form.attr.loyalty_member_object = response ?? [];
              const card_id = response[0].card_id ?? "";
              this.form.attr.card_id = card_id;
              const benefitsResponse = await this.getBenefitsByCardId(card_id);
              this.benefits = benefitsResponse;
              // this.form.attr.tier_benefits = benefitsResponse;
              // // If there are selected items, update their discounts based on the new benefits
              // if (this.form.items.length > 0) {
              //   this.updateItemDiscounts();
              // }
            } else {
              console.log("Response is null or empty");
              this.form.attr.loyalty_member = false;
              this.benefits = [];
              this.form.attr.card_id = "";
              // this.form.attr.tier_benefits = [];
              this.form.attr.loyalty_member_object = response[0] ?? [];
              this.updateItemDiscounts();
            }
          } catch (error) {
            console.error("Error updating benefits:", error);
          }
        }else{
          if (this.form.attr.card_id == response[0].card_id){
            this.benefits = this.form.attr.tier_benefits;
            // if (this.form.items.length > 0) {
            //   this.updateItemDiscounts();
            // }
          }else {
            console.log("This window");
            MessageBox.confirm('Existing Customer Tier has been changed. So please continue to update', 'Warning', {
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
              showClose:false,
              showCancelButton:false,
              type: 'warning'
            }).then(() => {
              this.form.attr.loyalty_member = true;

              this.form.attr.loyalty_member_object = response ?? [];
              const card_id = response[0].card_id ?? "";

              this.form.attr.card_id = card_id;
              this.getBenefitsByCardId(card_id).then((response) => {
                if (response && response.length > 0) {
                  this.benefits = response;
                  this.form.attr.tier_benefits = response;
                  // If there are selected items, update their discounts based on the new benefits
                  // if (this.form.items.length > 0) {
                  //   this.updateItemDiscounts();
                  // }
                }
              });
            })

          }

        }

      },

      updateItemDiscounts() {
        const self = this
        this.form.items.forEach((item, index) => {
          const qty = item.qty;
          const price = item.price;
          let discount = 0;
          let discount_value = 0;
          let remarks = null;
          let discount_type = null;
          let benefit_type = null;

          if (item.promotions.length > 0) {
            const itemPromo = item.promotions[0];
            const cd = {
              apply: true,
              discount: itemPromo.promo,
              off_price: itemPromo.off_price,
              promo: itemPromo.promo,

            };

            discount = self.computeDiscount(qty, price, itemPromo, 'promo', cd);
            remarks = self.setRemarks(qty, itemPromo);
            discount_type = 'promo';

            console.log("promotional");
          }

          const benefitDiscounts = self.benefits.filter(benefit => benefit.benefit_type === 'discount');

          if (benefitDiscounts.length > 0) {
            const benefit_discount_percentage = benefitDiscounts[0].discount;
            const tier_name = benefitDiscounts[0].tier_name;
            const benefitDiscount = (price * benefit_discount_percentage / 100) * item.qty;
            discount = benefitDiscount;
            discount_value = discount;
            remarks = tier_name + ' ' + 'Discount';
            benefit_type = 'benefit_discount';

          }


          if (self.form.attr.loyalty_member == true) {
            if (item.promotions.length > 0) {
              const itemPromo = item.promotions[0];

              const cd = {
                apply: true,
                discount: itemPromo.promo,
                off_price: itemPromo.off_price,
                promo: itemPromo.promo,
              };

              // Apply additional discount from filterBenefits
              if (benefitDiscounts && benefitDiscounts.length > 0) {
                const additional_discount = benefitDiscounts[0].additional_discount || 0;
                const promo = this.computeDiscount(qty, price, itemPromo, 'promo', cd);
                const discount_amount = (price * qty) - promo;
                discount_value = discount_amount * additional_discount/100;
                discount = promo + (discount_amount * additional_discount/100);
                const tier_name = benefitDiscounts[0].tier_name;
                remarks = tier_name + ' ' + 'Additional Discount';
                benefit_type = 'additional_benefit_discount';
              }

            }
          }



          if (item.discount_type === 'discount') {
            const itemPromo = item.promotions[0];
            // discount = this.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);
            // remarks = this.setRemarks(item.qty, item.custom_discount);

            const additional_discount = item.benefits?.additional_discount ?? 0;
            const promo = self.computeDiscount(item.qty, item.price, itemPromo, item.discount_type, item.custom_discount);
            const discount_amount = (item.price * item.qty) - promo;

            // discount = promo + (discount_amount * additional_discount/100);
            if (item.custom_discount.apply == false || item.custom_discount.discount == 0 ){
              console.log("custom promo 0")
            }else{
              discount_value = discount_amount * additional_discount/100;
              discount = promo + (discount_amount * additional_discount/100);
            }

            console.log("custom discount + additional benefits", discount)
            remarks = self.setRemarks(item.qty, item.custom_discount);
          }


          const updatedItem = {
            ...item,
            discount_type: discount_type,
            benefit_type: benefit_type,
            benefit_value: discount_value,
            benefits: benefitDiscounts.length > 0 ? benefitDiscounts[0] : null,
            discount: discount,
            remarks: remarks,
            qty: qty,
            price: price,
            total: self.computeTotalAmount(qty, price, discount)

          };

          console.log(updatedItem,'swaraj updatedItem')
          self.$set(self.form.items, index, updatedItem);

        });
      },

      openCustomer(){
        this.$refs.contactForm.openDialog();
      },
      contactSuccess(v){

        this.activeCustomer = v
        const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.attr.address, series : v.series, address2 : v.attr.address2,attr : v.attr }
        this.customer.unshift(contact);
        this.form.customer_id = v.mdbID
        this.form.mobile = v.mobile
        this.form.attr.name = v.name;
        this.form.attr.mobile = v.mobile;
        this.form.attr.address = v.attr.address;
        this.form.attr.cx_series = v.series;
        this.form.attr.address2 = v.attr.address2;
        this.form.attr.corporate = v.attr.corporate


        let add = v.attr.address

        add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
        add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
        add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
        add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
        add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";



        this.displayAddress = add.trim();


        // alert(v.name)
      },

      openPayment(paymentRow){
        this.paymentFormStatus = 'update'
        this.paymentForm = paymentRow
        this.paymentForm.invoice_series = this.form.series
        this.paymentForm.customer = this.form.attr.name + " "+ this.form.attr.mobile
        this.$refs.updatePaymentForm.openDialog();
      },
      openUpdatePaymentWindow(){
        this.paymentFormStatus = 'create'
        const pForm = {...this.paymentForm,invoice_series : this.form.seires, customer : this.form.attr.name + " "+ this.form.attr.mobile}
        // this.paymentForm.invoice_series = this.form.series
        this.paymentForm = pForm
        this.$refs.updatePaymentForm.openDialog();
      },
      addMoreDiscount(){

      },

      invoiceDiscountPercent(){
          const percent = parseFloat(this.form.more_discount.percent) / 100;

          const totalItems = this.form.items.map((i) => unformat(i.total),[]).reduce((a,b) => a + b, 0)
          const total  = totalItems * percent;
          if(total > 0){
            this.form.more_discount.enable = true
            this.form.more_discount.discount = this.$helpers.formatMoney(total)
          }else{
              this.form.more_discount.discount = 0;
              this.form.more_discount.enable = false
          }

      },

      invoiceDiscount(){

          const discount = this.$helpers.unformat(this.form.more_discount.discount)


        const totalItems = this.form.items.map((i) => unformat(i.total),[]).reduce((a,b) => a + b, 0)
          const percent = (discount / totalItems) * 100


              console.log("OnDiscount")
              console.log(this.total)
              console.log(percent)


               if(discount > 0){
                this.form.more_discount.enable = true
                  if(Number.isInteger(percent)){
                      this.form.more_discount.percent = percent
                  }else{
                      this.form.more_discount.percent = formatMoney(percent, {
                        symbol: '',
                        format: '%v',
                        thousand: '',
                        decimal: '.',
                        precision: 6
                      })
                  }


              }else{
                this.form.more_discount.enable = false
                this.form.more_discount.percent = 0;
              }

         /*

          const totalItems = this.form.items.map((i) => unformat(i.total),[]).reduce((a,b) => a + b, 0)
          const percent = discount / totalItems * 100

          if(discount > 0){

          if(Number.isInteger(percent)){
              this.form.more_discount.percent = percent
          }else{
              this.form.more_discount.percent = formatMoney(percent, {
                symbol: '',
                format: '%v',
                thousand: '',
                decimal: '.',
                precision: 6
              })
          }


          }else{
            this.form.more_discount.percent = 0;
          } */
      },
      sendRequest(){
        if (this.request) this.cancelRequest();
          this.makeRequest()
      },
      cancelRequest(){
        this.request.cancel();
      this.clearOldRequest("Cancelled");
      },

      makeRequest(){
const axiosSource = axios.CancelToken.source();
          this.request = { cancel: axiosSource.cancel, msg: "Loading..." };
          axios
            .get("/api/user/filter", { cancelToken: axiosSource.token })
            .then(() => {
              this.clearOldRequest("Success");
            })
            .catch(this.logResponseErrors);
      },
      logResponseErrors(err) {
      if (axios.isCancel(err)) {
        // console.log("Request cancelled");
      }
    },
      clearOldRequest(msg) {
        this.request.msg = msg;
        this.requests.push(this.request);
        this.request = null;
      },

      enableModify(){
        this.modify = true
        this.disabled = false
        this.returmItem = false
        if(this.form.items.length === 0){
          const formItemVar = this.formItemVar();
            this.form.items.push(formItemVar)
        }
        this.initSortable();
      },
      enableModifyCreditNote(){
        this.modifyReturmItem = true
        this.modify = false
        this.disabled = false
        this.returmItem = false
      },

       openLink(target) {
        // console.log(target);
      },
      copyLink(target) {
        console.log(target);
      },

      rowContectMenu(row,col,e){
        const self = this
        // console.log(col)
        // console.log(row)
        // console.log(e)
        if((row.code !== null && this.formStatus === 'create') || (this.formStatus === 'update' && row.code !== null && (this.modify === true || this.form.invoice.series === null))){
            this.context = row
            this.viewMenu = true;
              this.$nextTick(function() {
                self.$refs.right.focus();
                self.setMenu(e.y, e.x)
              }.bind(this));
              e.preventDefault();
        }
      },

      setMenu: function(top, left) {

        const largestHeight = window.innerHeight - this.$refs.right.offsetHeight - 75;
            const largestWidth = window.innerWidth - this.$refs.right.offsetWidth - 75;

            if (top > largestHeight) top = largestHeight;

            if (left > largestWidth) left = largestWidth;

            this.top = top + 'px';
            this.left = left + 'px';
        },

        closeMenu: function() {
            this.viewMenu = false;
            this.context = null
        },

        deleteRow(){
          const self = this
            if(this.context != null){

              if(self.context.hasOwnProperty('id')){
                   const matchItem = this.form.items.filter((i) => {
                    return i.id != self.context.id;
                  })
                  self.form.items = matchItem
                   self.newArrange = matchItem
              }
              if(self.context.hasOwnProperty('item_id')){
                   const matchItem = this.form.items.filter((i) => {
                    return i.item_id != self.context.item_id;
                  })
                  self.form.items = matchItem
                  self.newArrange = matchItem
              }
              /* const matchItem = this.form.items.filter((i) => {
                return i.code != self.context.code;
              }) */


/*               console.log(this.context)
              console.log(self.form.items) */


               const lastTblKey = this.tblKey + 1;
                      this.setTblKey(lastTblKey)

              this.closeMenu()
            }

        },
        moveUpRow(){
           const self = this
            if(this.context != null){
              const index = this.form.items.findIndex(e => e.code == self.context.code);

              if (index > 0) {
                const el = this.form.items[index];
                this.$set(this.form.items, index, this.form.items[index - 1]);

                this.$set(this.form.items, index - 1, el);
                this.closeMenu()
              }
            }
        },
        moveDownRow(){
           const self = this
            if(this.context != null){
              const index = this.form.items.findIndex(e => e.code == self.context.code);

                  if (index !== -1 && index < self.form.items.length - 2) {
                    const el = self.form.items[index];
                    this.$set(self.form.items, index, self.form.items[index + 1]);
                    this.$set(self.form.items, index + 1, el);
                    this.closeMenu()
                  }

            }
        },

        cancelModification(){

          const self = this;
            self.modifyReturmItem = false
            self.returmItem = false
            self.modify = false
            self.disabled = true
            self.selected = []
          // self.getRecord({token_id : self.form.token_id})

            const frmOriginal = JSON.parse(JSON.stringify(this.formOriginal));

            self.setFormRecord(frmOriginal)

        },

        insertRow(){
            const self = this
            if(this.context != null){
              if(self.context.hasOwnProperty('id')){
                const index = this.form.items.findIndex(e => e.id == self.context.id);
                const formItemVar = this.formItemVar();
                this.form.items.splice((index + 1), 0, formItemVar);
              }
              if(self.context.hasOwnProperty('item_id')){
                const index = this.form.items.findIndex(e => e.item_id == self.context.item_id);
                const formItemVar = this.formItemVar();
                this.form.items.splice((index + 1), 0, formItemVar);
              }
              this.closeMenu()
            }


        },

        returnItems(){
          this.returmItem = true;
        },
        cancelReturnItems(){
          this.returmItem = false;
          this.selected = [];
        },
        requestCreditNote(){
          const self = this;
          const params = {
            transaction_id : this.form.mdbID,
            items : this.selected,
          }

          self.formLoading = true
          this.storeCreditNote(params).then((response) => {

              self.$ThatsNotify.success({
                                message : response.message
                              })
              self.$emit('success')
              self.setFormRecord(response.data)
              self.formLoading = false
              self.newArrange = []
              self.selected = []
              self.returmItem = false
              self.modify = false
          })
        },
        canSelectRow(row, index){

          if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returmItem === true){
              const creditNotes = this.form.credit_note_items.map(i => i.code);
              if(creditNotes.includes(row.code)){
                return false
              }else{
                return true
              }
          }else{
            return true
          }
        },
        enableRow(row){

          if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returmItem === true){
              const creditNotes = this.form.credit_note_items.map(i => i.code);
              if(creditNotes.includes(row.code)){
                return false
              }else{
                return true
              }
          }else{
            return true
          }
        },

        enableModifyAndCreditNote(row){
          if(this.disabled === false ||
            ((this.invoice.series !== null && ((this.form.status === 'delivered' || this.form.status === 'collected') && this.form.attr.payment_status === 'full-payment')) &&
            (this.checkSystemSupport || this.checkShowroomManager) &&
            (this.returmItem === true || this.modify === true))){
              // return false

              if(this.formStatus === 'update' && (this.form.invoice !== null && this.form.invoice.series !== null) && this.modify === false && this.returmItem === true){
                  const creditNotes = this.form.credit_note_items.map(i => i.code);
                  if(creditNotes.includes(row.code)){
                    return true
                  }else{
                    return false
                  }
              }else{
                return false
              }

          }
          return true
      },

      handleCheckAllChange(val) {
        var self = this;
          this.isIndeterminate = false;
          this.form.items.forEach((row,index) => {
            const itemQty = row.qty;

            let tbo_minimum_qty = (row.remaining - parseInt(itemQty)) <= 0 ? parseInt(itemQty) : (row.remaining - parseInt(itemQty));
          let tboQty = itemQty;
            if(!val){
              tboQty = 0;
            }else{
              if(row.hasOwnProperty('tbo_qty')){
                if(row.tbo_qty > itemQty){
                  tboQty = itemQty;
                }else{
                  if(row.tbo_qty > 0){
                    tboQty = row.tbo_qty;
                  }
                }
              }
            }


            self.$set(self.form.items,index, {...row,tbo : val, tbo_qty : tboQty, tbo_minimum_qty : tbo_minimum_qty});
          })


      },
      handleCheckedTboChange(value,row) {

        console.log("handleCheckedTboChange")
        console.log(value)
        console.log(row)
        const self = this;
        const checked = this.form.items.filter(row => {
          return row.tbo === true
        }).map(e => e.tbo).length;

        const isIndeterminate = checked > 0 && checked < this.form.items.length;

        const checkAllTbo = checked === this.form.items.length
        const itemQty = row.qty
          let tboQty = itemQty;
            if(!value){
              tboQty = 0;
            }else{
              if(row.hasOwnProperty('tbo_qty')){
                if(row.tbo_qty > itemQty){
                  tboQty = itemQty;
                }else{
                  if(row.tbo_qty > 0){
                    tboQty = row.tbo_qty;
                  }
                }
              }
            }

            console.log("tboQty")
            console.log(tboQty)

            row.tbo_qty = tboQty;

        this.$nextTick(() => {



            self.isIndeterminate = isIndeterminate
            self.checkAllTbo = checkAllTbo
        });

        /* this.sele
        console.log((checked > 0 && checked < this.form.items.length)) */
      },

      handleCheckAllFromDisplayChange(val) {
        var self = this;
          this.isIndeterminateFromDisplay = false;
          this.form.items.forEach((row,index) => {
            self.$set(self.form.items,index, {...row,from_display : val});
          })


      },
      handleCheckedFromDisplayChange(value) {
        const self = this;
        const checked = this.form.items.filter(row => {
          return row.from_display === true
        }).map(e => e.from_display).length;

        const isIndeterminate = checked > 0 && checked < this.form.items.length;

        const checkAllFromDisplay = checked === this.form.items.length

        this.$nextTick(() => {
            self.isIndeterminateFromDisplay = isIndeterminate
            self.checkAllFromDisplay = checkAllFromDisplay
        });

        /* this.sele
        console.log((checked > 0 && checked < this.form.items.length)) */
      },

      renderHeader(h,{column}){
      return h(
        'div',
        [
          h('span', column.label),
          h('el-checkbox',{
            style:'display:inline-flex;margin-left:5px;',
            on:{
              Change: ($event, column) => this.select($event, column), // check the event $event, column, where $event=true, column is the input dom when printing in select
            }
          }),
        ],
      )
    },
    select(obj, $event = this.targetEv){
      console.log(obj)
      console.log($event)
    },

    enablePrice(row){

    const cat = [137, 136, 126, 83, 127, 129, 132, 130, 135, 128,142, 143,95, ,133, 87];

      if((this.$helpers.isDeliveryTeam() || this.$helpers.isInteriorDesigner() || this.$helpers.isSalesExecutive() || this.$helpers.isSystemAdministrator() || this.$helpers.isShowroomManager()) && row.category !== null && cat.includes(row.category)){
        return true;
      }
      return false
    },

    calculatePrice(index){
      const self = this
      const item = self.form.items[index];

       this.$set(self.form.items[index], 'price',unformat(item.formatted_price))
       this.calculateTable()
       this.newArrange = this.move(this.form.items, index, index);

      /*  if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];

              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                }


              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          } */


    },

    removeFormat(index){
      // console.log(row)
      const self = this;
       const item = self.form.items[index];
//  console.log("focus")
//        console.log(item)
      let amount = 0
      if (item.price !== 0) {
        amount = formatMoney(item.price, {
          symbol: '',
          format: '%v',
          thousand: '',
          decimal: '.',
          precision: 2
        })
      }

      this.$set(self.form.items[index], 'formatted_price',amount)
    },
    formatOnBlur(index){
      // console.log(row)
      const self = this;
       const item = self.form.items[index];

       console.log("blur")
       console.log(item)

      let amount = 0
      if (item.formatted_price !== 0) {
        amount = formatMoney(item.formatted_price, {
          symbol: '',
          format: '%v',
          thousand: ',',
          decimal: '.',
          precision: 2
        })
      }

      this.$set(self.form.items[index], 'formatted_price',amount)
      this.$set(self.form.items[index], 'price',unformat(amount))

    },

    quantityChange(v){
        // console.log(v)
        const code = v.code;
        const qty = parseInt(v.qty);
          if(qty > v.max){
              v.qty = v.max;
          }
          if(qty < 1){
            v.qty = 1;
          }
      },

      openSearchFabric(){
        this.$refs.searchFabric.openDialog();
      },
      handleOutsideClick(){
        const self = this;
          if(this.form.items.length > 0){
              this.form.items.forEach((item,index) => {
                  self.$set(self.form.items[index], 'show_search', false);
              })
          }
      },

      clickShowSearch(row,index){
        if(row.category === 95 || row.category === 132 || row.category === 127){
          this.$set(this.form.items[index], 'show_search', true);
        }
      },
      closeFabric(index){
        this.$set(this.form.items[index],'show_search',false);
      },
      getPurchaseRequestSummaries(param) {
        const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6,7,8]

        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }


          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              }
            return;
          }

          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }


          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;


              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);
              });

              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);


                //  this.form.total =  this.formatNumber(sum)
                //  this.total =  this.formatNumber(sum)
                sums[index] = this.formatNumber(sum)

              }


            return;
          }



          });

          // this.toggleCheck()

        return sums;
      },

      editCustomer(){
        const self = this;
        const {address,mobile, name,email} = this.form.attr

          const em = (typeof email === 'undefined') ? "" : email
          const addr = (typeof address === 'undefined') ? "" : address
      if(this.form.customer_id !== null){
          this.setFormContactStatusUpdate()
          this.getContact({mdbID : this.form.customer_id,mobile : mobile,address : addr, name : name, email : email}).then((response) => {

            const contactFormUpdate = {
              row : response
            }
              self.setFormRecordUpdate(contactFormUpdate)
          });
          this.$refs.contactForm.openDialog()
        }
      },
      contactUpdateSuccess(v){
        const {mobile, name} = v
        const {address, email, address2, corporate} = v.attr
        this.form.attr = {...this.form.attr, address : address,address2 : address2, mobile : mobile, name : name, email : email, corporate : corporate}


        this.activeCustomer = v
        let ndx = null
        const con = this.customer.filter((e,index) => {
            ndx = index
            return e.value === v.mdbID
        })[0];

        const finalContact = {
                opt : name + " "+ mobile,
                label :name,
                value : con.value,
                mobile : mobile,
                address : address,
                attr : v.attr
            }



        if(ndx !== null){


          this.$set(this.customer,ndx,finalContact)
         let add = v.address

                  add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                  add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
                  add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                  add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                  add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";

                  this.displayAddress = add.trim();

        }

      },

      saveContinue(v){
        // console.log(v)
        const self = this;

/*

        self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success')
                              self.setFormStatusUpdate()
                              self.setFormRecord(response)
                              self.formLoading = false
                              self.SetPage(1)
                              self.newArrange = []
                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            }) */
      },

      openNewItem(){
        this.setItemFormStatusCreate()

        if(this.checkSystemSupport){
          // this.$refs.itemForm.openDialog()
        }else{
          // this.$refs.promptPasswordForm.openDialog()
        }
      },

      isAuthorized(value){
          if(value){
              // this.$refs.itemForm.openDialog()
          }
      },

      itemFormSuccess(v){
          const params = {term : v.name, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
      },
      openQuotation(v){
        const self = this;

        this.getContact({mdbID : v.value.customer_id}).then((response)=>{
          self.activeCustomer = response
        })
        const form  = {...v.value,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    }}
                    self.setFormStatusUpdate()
                    self.setFormRecord(form)
                    self.getRecord({token_id : v.value.token_id})
                    self.$refs.customTableWindow.closeDialog()

      },

      successUpdateLocation(v){

      },
      updateLocation(){
          this.setUpdateLocationForm({
            series : this.form.series,
            origin : this.form.location,
            prefix : "",
            replaces : "",
          })
          this.$refs.updateLocationForm.openDialog();
      },

      openHistory(){
          // console.log(this.form)
          this.setModule('sales-transaction')
          this.setRecordId(this.form.mdbID)
          this.$refs.historyWindow.openDialog()
      },

      fnMergeQuotation(v){
        const self = this;
        let items = [];

          MessageBox.confirm('Are you usre you want to merge quotation?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {
                const loading = self.$loading({
                    lock: true,
                    text: 'Please wait...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });

              self.getQuotation({mobile : self.form.attr.mobile, merge : true}).then((response) => {
                  if(response.data.length > 0){
                      // console.log(response.data)
                      loading.close()
                      const filterItems = response.data.filter((item) => {
                        return v.includes(item.series)
                      }).forEach((forItem) => {
                          if(forItem.items.length > 0){
                              forItem.items.forEach((innerForItem) => {
                                  items.push(innerForItem)
                              })
                          }
                      })


                     self.form.items = JSON.parse(JSON.stringify(items))
                      self.$refs.customTableWindow.closeDialog();

                  }
            });


                  /*  v.map((item) => {
                      return item.itm_names
                    }).forEach((forItem) => {
                        if(forItem.length > 0){
                            forItem.forEach((innerForItem) => {
                                items.push(innerForItem)
                            })
                        }
                    })

                    self.form.items = JSON.parse(JSON.stringify(items)) */

              })
      },

      onSelectLocation(v){
          const self =this;

          const filterShowroom = this.showrooms.filter((item) => {
            return item.value === v
          })[0];

          if(filterShowroom){
              this.form.showroom.prefix = filterShowroom.prefix


              if(self.posShowroom.includes(filterShowroom.prefix)){

                self.customer = [
                  {
                    opt : "WALK-IN/00000000",
                    label : "WALK-IN",
                    value : 10370,
                    mobile : "00000000",
                    address : null,
                    series : "CON-10301",
                    attr : {
                      address: null,
                      address2 : {
                          street : null,
                          building : null,
                          zone : null,
                          area : null,
                          city : null,
                      },
                      email : null,
                      origin : 'portal',
                      first_name : null,
                      middle_name : null,
                      last_name : null,
                      salutation : null,
                      corporate : false,
                      contacts : [
                          {
                              type : 'primary',
                              intl : false,
                              code : '+974',
                              value : '00000000'
                          },
                          {
                              type : 'secondary',
                              intl : false,
                              code : '+974',
                              value : null
                          },
                          {
                              type : 'home',
                              intl : false,
                              code : '+974',
                              value : null
                          }
                      ],
                      contact_persons : []
                    }
                  }
                ]
                self.form.attr.name = "WALK-IN";
                self.form.attr.cx_series = "CON-10301";
                self.form.attr.mobile = "000000";
                self.form.customer_id = 10370

                self.isWalkin = true



                self.$nextTick(() => {
                  self.$refs.gridSelect.$children[0].$refs.search.focus()
                  });
              }else{
                self.form.customer = { name: null, mobile: null, address: null, series: null, cx_series: null }

                self.form.attr.name = null;
                self.form.attr.cx_series = null;
                self.form.attr.mobile = null;
                self.form.attr.address = null;
                self.form.attr.address2 = {
                  street: null,
                  building: null,
                  zone: null,
                  area: null,
                  city: null
                };

                self.form.customer_id = null
                self.isWalkin = false
                self.customer = []
              }
          }




          // console.log(filterShowroom)
      },

      onSelectLocationClose(v){
          const self =this;

          const filterShowroom = this.showrooms.filter((item) => {
            return item.value === v
          })[0];

          if(filterShowroom){
              this.form.attr.closed.brand = filterShowroom.prefix
          }

          // console.log(filterShowroom)
      },

      removeDiscountFormat(){
               this.form.more_discount.discount = this.$helpers.unformat(this.form.more_discount.discount )
          },
          formatOnBlurMoreDiscount(){
               this.form.more_discount.discount = this.$helpers.formatMoney(this.form.more_discount.discount)
          },

          checkIfTrue(v){
              this.showOtherBrand = v
          },
          checkCompetitorIfTrue(v){
              this.showCompetitor = v
          },
          checkOtherIfTrue(v){
              this.showOther = v
          },
          openContactPersonListWindow(){
            // alert(1)
          },

          getCustomerSelectedContactPerson(v){
             const self = this;

             console.log("Contact person")
             console.log(v)
              const selectedCustomerContactList = this.activeCustomer.attr.contact_persons.filter(c => {
                  return c.mobile === v;
              })[0];

              if(v !== null && selectedCustomerContactList !== null){
                console.log("selectedCustomerContactList")
                console.log(selectedCustomerContactList)
                this.form.attr.contact_person = selectedCustomerContactList
              }else{
                this.form.attr.contact_person = {
                    name : null,
                    mobile : null,
                    attr : {

                      address: null,
                      address2 : {
                          street : null,
                          building : null,
                          zone : null,
                          area : null,
                          city : null,
                      },
                      designation : null,
                      email : null,
                      first_name : null,
                      middle_name : null,
                      last_name : null,
                      salutation : null
                    }
                  }
              }
          },

          reopenQuotation(object){
            const self = this;
            MessageBox.confirm('Are you sure you want to reopen quotation?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
              }).then(() => {

                self.openRecord(object).then((res) => {

                    self.form.attr = res.attr;
                    self.form.status = res.status;
                    self.formOriginal.attr = res.attr;
                    self.formOriginal.status = res.status;
                    self.$ThatsNotify.success({
                      message : res.message
                    })

                    self.$emit('success')
                })
            })
          },


          applyTierDiscount(obj){

            console.log("applyTierDiscount")
            console.log(obj)

            const tierDiscounts = obj.tierDiscounts.filter((item) => item.discountValue > 0,[]);
            if(tierDiscounts.length > 0){
                this.form = obj;

                const lastTblKey = this.tblKey + 1;
                this.setTblKey(lastTblKey)
            }
          }

    },
//#endregion
//#region[VueComputed]
    computed: {

      ...mapGetters('InsuffecientAlertModule',
      [
        'rows',
      ]),
      ...mapGetters('TransactionModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
        'tblKey',
      ]),

...mapGetters('AuthModule',[ 'user', ]),
      // ...mapGetters('LoyaltyMemberModule',
      //     [
      //       'benefits',
      //     ]),

      enableEditButton : function(){
          return !["AMTM","AMDFC","TLKD","ECDFC","MPDFC",null,"null"].includes(this.form.showroom.prefix)
      },

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      noInvoiceDisabled : function(){
          if(this.formStatus === 'create' || this.form.invoice === null || (this.form.invoice !== null && this.form.invoice.series === null)){
            return true;
          }
          return false
      },


      invoice : function(){
          let inv = {
          series : null,
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }

        if(this.formStatus == 'update'){
            if(this.formRecord != null && this.formRecord.invoice !== null){
              inv = this.formRecord.invoice
            }

        }

        inv.balance = unformat(inv.balance)
        return inv
      },


      allowDiscount : function(){
          return (this.$helpers.isSystemAdministrator() || this.$helpers.isShowroomManager() || this.$helpers.isInteriorDesigner()) ? true : false
      },
      checkSystemSupport : function(){
          return this.$helpers.isSystemAdministrator();
      },
      checkShowroomManager : function(){
          return this.$helpers.isShowroomManager();
      },
      showCheckBox : function(){
        if(this.formStatus == 'create' || (this.formStatus == 'update' && this.returmItem === true)){
            return true;
        }

        return false;
      },
      showCheckBoxCreditNote : function(){
        if(this.formStatus == 'update' && this.modifyReturmItem === true){
            return true;
        }

        return false;
      },
      compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
          if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
          }

          const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
          const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

          let comparison = 0;
          if (varA > varB) {
            comparison = 1;
          } else if (varA < varB) {
            comparison = -1;
          }
          return (
            (order === 'desc') ? (comparison * -1) : comparison
          );
        };
      },

      hasSelected(){
        if(this.selected.length > 0){
          return false;
        };
        return true
      },



      hasInvoice(){
        if(this.form.invoice !== null && this.form.invoice.series !== null){
          return true;
        }
        return false;
      },

      showroomLocation(){

        if(this.showrooms.length > 0){
          return this.showrooms.map((item) => {
            return {...item, label : `${item.prefix} - ${item.label}`}
          })
        }

        return [];
      },

      status : function(){

        let status= [
              {
                "code": "no-remarks",
                "label": "Open",
                "seperator": false,
                 "disable" : true
              },
              {
                "code": "closed",
                "label": "Closed",
                "seperator": false,
                "disabled" : true
              }
            ];

            if(this.checkSystemSupport || this.checkShowroomManager){
              status.push({
                "code": "cancel",
                "label": "Cancel",
                "seperator": false
              })
            }

            return status;
      },

      customerContactPersons : function(){
        return this.activeCustomer.attr.contact_persons.map((item)=>{
          return {...item,opt : `${item.name}/${item.mobile}`,value : item.mobile,label : `${item.name}/${item.mobile}`}
        })
      },

      showrooms : function(){
        return this.showroomsProvider();
      },

      isLoyaltyMember : function(){
        if(this.form.attr.hasOwnProperty('loyalty_member')){
          return true;
        }

        return false
      },

      itemHasChanged : function(){
        const current = this.form.items.map((item) => {
          return unformat(item.total)
        }).reduce((a,b) => {
          return a + b;
        },0);
        const original = this.formOriginal.items.map((item) => {
          return unformat(item.total)
        }).reduce((a,b) => {
          return a + b;
        },0);

        return current !== original;
      },




      /* toBeOrder(){
        return this.form.items.map(i => t.code !== null && i.tbo === true).lenth;
      } */


    },
//endregion
    watch: {
      tblKey : function(){
          //this.initSortable();
      },


      // viewLoading : function(v){
      //     if(v === false && this.formStatus === 'update'){
      //          if(this.form.invoice !== null  && (v.invoice.hasOwnProperty('series') && v.invoice.series !== null)){
      //            alert("watch viewloading invoiced")

      //          }else{
      //            alert("watch viewloading update quotation")
      //          }
      //     }
      // },
      formRecord : function(v){
        const self = this;
        this.active = 0;
          this.disabled = false
        // alert(this.formStatus);

        if(this.formStatus == 'update'){

          // this.benefits = this.form.attr.tier_benefits;
          
          // console.log("onUpdate")
          // console.log(v)
          // alert("update")
            // if(!this.viewLoading){


               this.getContact({mdbID : v.customer.customer_id}).then((response)=>{
                  self.activeCustomer = response
                })


              if(v.invoice !== null && (v.invoice.hasOwnProperty('series') && v.invoice.series !== null)){
                // alert()
                  if(this.modify === false){
                    this.disableSortable();
                  }
                  this.disabled = true
                      this.tabs = [
                        { text: 'Sales Details', slot: 'form-slot' },
                        { text: 'Sales Invoice', slot: 'invoice-slot' },
                        { text: 'Attachments', slot: 'form-attachment' }
                      ]

                      // alert("invoiced")
                  }else{
                    this.form.invoice = {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    }
//  alert("quotation")
                    // this.initSortable();

              }
            // }


                const jsonParse = JSON.parse(JSON.stringify(this.formRecord));


                if(['cancel','closed'].includes(jsonParse.status)){
                  this.disabled = true
                }

                if(jsonParse.hasOwnProperty('attr')){
                  jsonParse.attr = {...this.form.attr,...jsonParse.attr}


                  if(jsonParse.attr.cx_series === 'CON-10301'){
                    self.isWalkin = true
                  }


                   let add = ""
                     add += (v.attr.address != "" && v.attr.address !== null) ? v.attr.address  : "";

                    add += (v.attr.address2.street != "" && v.attr.address2.street !== null) ? ","+v.attr.address2.street  : "";
                    add += (v.attr.address2.building != "" && v.attr.address2.building !== null) ? ","+v.attr.address2.building  : "";
                    add += (v.attr.address2.zone != "" && v.attr.address2.zone !== null) ? ","+v.attr.address2.zone  : "";
                    add += (v.attr.address2.area != "" && v.attr.address2.area !== null) ? "/"+v.attr.address2.area  : "";
                    add += (v.attr.address2.city != "" && v.attr.address2.city !== null) ? "/"+v.attr.address2.city  : "";

                    self.displayAddress = add.trim();

                }
                jsonParse.more_discount = {...this.form.more_discount,...jsonParse.more_discount}




                // const sortItems =

                // jsonParse.items = jsonParse.items.sort((a, b) => a.sort > b.sort);
                jsonParse.items = jsonParse.items.sort((a, b) => (a.sort > b.sort ? 1 : -1));
                // jsonParse.items = jsonParse.items.sort(self.compareValues('sort','asc'));

                // if(jsonParse.items.length > 0){
                //      const total = jsonParse.items.map((i) => {
                //         return unformat(i.total);
                //       },[]).reduce((a,b) => {
                //         return a + b;
                //       },0)

                //       // this.toBeOrder = v.map((i) => {
                //       //   return i.code !== null && i.tbo === true;
                //       // }).reduce((a,b) => {
                //       //   return a + b;
                //       // })

                //       const formTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
                //       if(jsonParse.more_discount.enable){
                //             const discount = unformat(jsonParse.more_discount.discount);
                //             jsonParse.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
                //         }else{
                //           jsonParse.total = formTotal
                //         }
                // }


                this.form = jsonParse;
                this.formOriginal = JSON.parse(JSON.stringify(jsonParse));


                if(this.form.hasOwnProperty('credit_note_items') && this.form.credit_note_items.length > 0){
                  this.tabs = [...this.tabs,{ text: 'Credit Notes', slot: 'credit-notes' }];
                }
                if(this.form.hasOwnProperty('delivery_notes') && this.form.delivery_notes.length > 0){
                  this.tabs = [...this.tabs,{ text: 'Delivery Notes', slot: 'delivery-notes' }];
                }

                if(this.form.hasOwnProperty('purchase_request_item') && this.form.purchase_request_item.length > 0){
                  const tbs = this.tabs.map(e => e.slot);

                  if(!tbs.includes('purchase-request')){
                    this.tabs = [...this.tabs,{ text: 'Purchase Request', slot: 'purchase-request' }];
                  }

                }

                if(!this.disabled){
                  const formItemVar = this.formItemVar();
                  this.form.items.push(formItemVar)
                }


          if(Object.keys(v).length !== 0 && v.constructor === Object){
            this.customer = [{
                opt : this.form.attr.name + " "+ this.form.attr.mobile,
                label :this.form.attr.name,
                value : this.form.customer_id,
                mobile : this.form.attr.mobile,
                address : this.form.attr.address,
                series : this.form.attr.cx_series,
            }];
          }

          this.checkLoyaltyMembership(jsonParse.attr.cx_series);
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
          // this.initSortable();
          // alert("watch create")
        }

        this.validationKey++
      },

      'form.items' : {
          handler: function(v) {
            if(v.length > 0){
              const total = v.map((i) => {
                return unformat(i.total);
              },[]).reduce((a,b) => {
                return a + b;
              },0)



              const percent = unformat(this.form.more_discount.percent);
              const discount = total * (percent / 100);

              const formTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
                if(this.form.more_discount.enable){
                    this.form.total = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
                }else{
                  this.form.total = formTotal
                }

                 this.form.more_discount.discount = discount;
                 this.form.attr.more_discount.discount = discount;
            }

          },
          deep: true
      },
      "form.more_discount.discount" : function(v){
        if(this.form !== null){
          const discount = unformat(v);
          const total = this.form.items.map((i) => unformat(i.total),[]).reduce((a, b) => a + b,0);
          let formattedTotal = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });

          if(this.form.more_discount.enable === false){
            formattedTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });

          }
          this.form.total = formattedTotal
/*
          if(this.form.more_discount.enable){
                    const unformatedFormTotal = unformat(this.form.total);
                    this.form.total = formatMoney((unformatedFormTotal - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }else{
            this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          } */
        }
      },
      "form.more_discount.enable" : function(v){
        if(this.form !== null){
        const percent = unformat(this.form.more_discount.percent);
          const total = this.form.items.map((i) => unformat(i.total),[]).reduce((a, b) => a + b,0);
          // const total = unformat(this.total);

          const discount = total * (percent/100);

          let formattedTotal = formatMoney((total - discount), { symbol: "", precision: 2, thousand: ",", decimal: "." });


            this.form.more_discount.discount = discount;
            if(this.form.attr.hasOwnProperty("more_discount") && this.form.more_discount !== null){
              this.form.attr.more_discount.discount = discount;
            }
          if(v === false){
            formattedTotal = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          }

          this.form.total = formattedTotal
            }
            // this.form.more_discount = false;
          //   alert(1)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
          // if(this.form.more_discount === false){
          //   alert(false)
          //   this.form.total = formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
          // }
      },

      // formStatus(newStatus) {
      //   if (newStatus === 'update') {
      //     // this.updateBenefits(this.form.attr.cx_series);
      //
      //   }
      // },


    },




}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}
</style>
