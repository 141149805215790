<template>
  <div class="itmform-container">
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Sales Order" windowClass="collection height-auto" scrollClass='collection height-auto' @deleteYes="emitDelete" :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="group">
            <template #left-button>
                <template v-if="formLoading || viewLoading">
                  <mdb-btn color="elegant" size="sm" disabled>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </mdb-btn>
                </template>
                <template v-else>
                  <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)" :disabled="viewLoading">Save</mdb-btn>
                  <template v-if="formStatus == 'update'">
                     <mdb-btn @click="fnPrint('/sales-order/transactions/'+form.token_id)" color="elegant" size="sm" :disabled="viewLoading">Print</mdb-btn>
                   
                     <!--  <template v-if="invoice.payments.length > 0">
                        <mdb-btn tag="a" :href="'/sales/invoice/'+form.token_id" color="elegant" size="sm" target="_blank">Print Invoice</mdb-btn>
                    </template> -->
                   
                  </template>
                </template>

                <template v-if="selected.length > 0">
                    <mdb-btn color="elegant" size="sm" @click="deleteItem">Delete</mdb-btn>
                  </template>
              </template>


          <mdbContainer class="mt-3">
                  <mdb-row >
                    <mdb-col col="6">
                      <ThatsDatePicker :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Date" placeholder="Date" vid="date" name="date" v-model="form.date" :validated="validated" :rules="'required'" size="sm" :disabled="disabled"/>                  
                        <ThatsSeriesInput :show_error="showError" tabindex="1" labelClass="col-md-4" fieldClass="col-md-8 pl-0" label="Sales Qoutation No." placeholder="Sales Qoutation No." vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" :keycode="false" size="sm" :disabled="true" />                  
                     <!--  
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Address" vid="address" name="address" v-model="form.attr.address" size="sm" :disabled="disabled"/> -->
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Sold By" :placeholder="form.sold_by" vid="sold_by" name="sold_by" v-model="form.sold_by" size="sm" :disabled="true" />
                      <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Remarks" vid="description" name="description" v-model="form.description" :validated="validated" :rules="''" size="sm" :disabled="disabled"/>
                    </mdb-col>    
                    <mdb-col col="6">
                      <ThatsCustomMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Customer" v-model="form.customer_id" :reduce="customer => customer.value" :reduceLabel="'opt'" :options="customer" :rules="'required'" :clearable="false" :disabled="disabled" @select="getCustomerSelected($event)" :addButton="true" @newWindow="openCustomer" @onSearch="searchCustomer" :selected_option="true"></ThatsCustomMultiSelect>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Customer Mobile No." vid="mobile" name="mobile" v-model="form.attr.mobile" size="sm" :disabled="disabled"/>
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="Status" v-model="form.status" :reduce="label => label.code" :reduceLabel="'label'" :options="status" :rules="'required'" :filterable="false" :clearable="false" :disabled="disabled"></ThatsMultiSelect>
                        
                    </mdb-col>    
                  </mdb-row>
                        <el-table border :data="form.items" class="input-table mt-2" style="width: 100%" @selection-change="handleSelectionChange" :summary-method="getSummaries" show-summary :cell-class-name="cell">
                            <template v-if="formStatus === 'create' && form.status_index <= 1">
                              <el-table-column type="selection" width="35"> </el-table-column>
                            </template>
                            <el-table-column label="SKU" :width="150">
                              <template slot-scope="scope">
                                <ThatsGridSelect v-model="scope.row.code" :reduce="item => item.code" :reduceLabel="'code'" :options="items" :filterable="false" :clearable="false" @onSearch="statusKeyUp" @onFocus="statusKeyUpFocus" @select="getSelected($event,scope.$index)" :disabled="disabled"></ThatsGridSelect>
                              </template>
                            </el-table-column>
                            <el-table-column label="Item Name">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.name" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                              </template>
                            </el-table-column>
                            <el-table-column label="Qty" :width="70">
                              <template slot-scope="scope">
                                <input type="number" min="1" class="form-control grid-input thats-form-control" v-model="scope.row.qty" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="disabled" />
                              </template>
                            </el-table-column>
                            <el-table-column label="Price" :width="100" align = "right">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.formatted_price" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                              </template>
                            </el-table-column>
                            <el-table-column label="Amount" :width="100" align = "right">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.amount" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                              </template>
                            </el-table-column>
                            <el-table-column label="Discount" :width="90" align = "right">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.discount" @keyup="calculate(scope.$index)" @change="calculate(scope.$index)" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                                <template v-if="scope.row.code !== null && scope.row.name !== null && disabled === false">
                                <a href="javascript:void(0)" class="discount-a" @click="openDiscount(scope.$index,scope.row)"><mdb-icon icon="ellipsis-v" /></a>
                                </template>
                              </template>
                            </el-table-column>
                            <el-table-column label="Total Amount" :width="120" align = "right">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control text-right" v-model="scope.row.total" @keydown.enter="addNewRow(scope.$index)" :disabled="true" />
                              </template>
                            </el-table-column>
                            <el-table-column label="Remarks">
                              <template slot-scope="scope">
                                <input type="text" class="form-control grid-input thats-form-control" v-model="scope.row.remarks" :disabled="disabled" />
                              </template>
                            </el-table-column>
                          </el-table>
              
            
          </mdbContainer>
       </ThatsWindow>
          </ValidationObserver>

      <ContactsForm ref="contactForm" :group="3" @success="contactSuccess"></ContactsForm>
      <PaymentForm ref="paymentForm" :group="3" @success="paymentSuccess"></PaymentForm>
      <DiscountForm ref="discountSalesORder" :group="3" @apply="applyDiscount"></DiscountForm>
      <PrintWindow ref="printWindow" :group="3" :url="printUrl"></PrintWindow>
  </div>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import ContactsForm from '../contacts/ContactsForm'
import DiscountForm from './DiscountForm'
import PaymentForm from './PaymentForm'
import PrintWindow from '../../components/thats/PrintWindow'
import { userData } from '../../helpers/user'
// import { isGeneralManager } from '../../helpers/headers'

import { ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsDatePicker, ThatsMultiSelect, ThatsGridSelect, ThatsFile, ThatsBtn, ThatsCustomMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';
import Fuse from 'fuse.js'


export default {
    components: {
        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,

        ValidationObserver,
        ThatsWindow, 
        ThatsInput,  
        ThatsSeriesInput,
        ThatsPriceInput,  
        ThatsFieldset,
        ThatsRadio,
        ThatsAutocomplete,
        ThatsAutosuggest,
        ThatsNumber,
        ThatsTextarea,
        ThatsDatePicker,
        ThatsMultiSelect,
        ThatsGridSelect,
        ThatsFile,
        ThatsBtn,

        ContactsForm, 
        DiscountForm, 
        PaymentForm, 
        ThatsCustomMultiSelect, 
        PrintWindow, 

  },

  props : {
      group : {
            type : Number,
            default : 1
        },
    },
    
    data : () => ({
      printUrl : '',
      active : 0,
      validated : false,
      formOriginal : null,
      form : null,
      response : {
        error : null,
        message : null
      },
      
      sendLoading : false,
      formLoading : false,
      showError : false,
      status : [
            { label : "Open", code : "open", status : "Open"},
/*             { status : "For Pickup in Showroom", code : "for-pickup"},
            { status : "For Delivery", code : "for-delivery"},
            { status : "Collected in Showroom", code : "collected-in-showroom"},
            { status : "Delivered", code : "delivered"},
            { status : "Pending", code : "pending"},
            { status : "Items to be ordered", code : "to-be-ordered"},
            { status : "Items Arrived", code : "items-arrived"}, */
      ],
      payment : [
            { label : "Cash", code : "cash", payment : "Cash"},
            { label : "Debit/Credit Card", code : "card", payment : "Debit/Credit Card"},
            { label : "Electronnic Transfer", code : "electronic", payment : "Electronnic Transfer"},
      ],
      items : [],
      selected : [],
      customer : [],
      

      tabs : [ { text: 'Sales Details', slot: 'form-slot' }],

      discountForm : null,
      discountFormIndex : null,

      

    }),

  created : function() {
      var self = this;
      const formItemVar = this.formItemVar();
      if(this.formStatus == 'create'){
        this.form = this.formVar();
        
        this.form.items.push(formItemVar);
        
        this.nextCode()
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
              ]
        
      }
       this.optionsHandler();
       this.discountForm = this.formItemVar();
    },



    methods: {

      ...mapActions('ContactsModule',[ 'GetContacts' ]),
      ...mapActions('SalesOrderModule',[
        'storeRecord',
        'updateRecord',
        'getNextCode',
        'filterItems',
        'updateStatus',
        'sendToGeneralManager',
        
      ]),
      ...mapMutations('SalesOrderModule',[
        'setFormStatusCreate',
        'setDiscountForm',
        'setPaymentForm',
        'setFormRecord',
        'setFormStatusUpdate',
      ]),

      formVar(){
        return {
          series : 0,
          date : "",
          status_index : 0,
          status : "open",
          attr : {
            name : null,
            mobile : null,
            address : null,
          },
          user_id : null,
          sold_by : null,
          customer_id : null,
          payment_method : 'cash',
          items : [            
          ],
          attachment : [],
          invoice : {
            series : null
          }
        }
      },

      formItemVar(){
        return {
                item_id: null,
                code: null,
                name : null,
                description: null,
                reference: null,
                qty: null,
                formatted_price: 0,
                price: 0,
                amount: 0,
                discount: 0,
                discount_type: null,
                custom_discount: {
                  apply : true,
                  discount : 0,
                  off_price : 0,
                  promo : 0,
                },
                total: 0,
                remarks: null
              }
                      
      },
/* start */
paymentSuccess(response){
  this.setFormRecord(response.data)
  this.$emit('success')
},
applyDiscount(v){

  const frm = v.form;
    const qty = frm.qty;
      let price = frm.price;
      let discount = 0;
      let remarks = null;

      if(frm.promotions.length > 0 && frm.discount_type === 'promo'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,itemPromo);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }

      if(frm.discount_type === 'discount'){
        const itemPromo = frm.promotions[0];
        frm.discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
        frm.remarks = this.setRemarks(qty,frm.custom_discount);
        frm.total = this.computeTotalAmount(qty, price, frm.discount)
      }


   this.$set(this.form.items, v.index, frm)



    /*   if(frm.promotions.length > 0){
        const itemPromo = frm.promotions[0];
        discount = this.computeDiscount(qty,price,itemPromo,frm.discount_type,frm.custom_discount);
          remarks = this.setRemarks(qty,itemPromo);

      }

      this.$set(this.form.items[v.index], 'discount', discount)
      this.$set(this.form.items[v.index], 'remarks', remarks)
      this.$set(this.form.items[v.index], 'total', this.computeTotalAmount(qty, price, discount))
      this.$set(this.form.items[v.index], 'custom_discount', frm.custom_discount) */


},
cell({row, column, rowIndex, columnIndex}) {
  const self = this;
  if(self.formStatus === 'update'){
    if(columnIndex ===5){
        return 'cell-align-center';
      }
  }

  if(self.formStatus === 'create'){
    if(columnIndex ===6){
        return 'cell-align-center';
      }
  }
      
      return null;
},
openDiscount(index,row){
  console.log(row)
  this.setDiscountForm({index : index, form: row});
  this.$refs.discountSalesORder.openDialog();
},

getSummaries(param) {
  const self = this;
        const { columns, data } = param;
        const sums = [];
        const numberColumns = [2,3,4,5,6]
        
        let totalCol = 2;
        let totalQty= 3;
        let totalPrice= 4;
        let totalAmount= 5;
        let totalDiscount= 6;
        let totalTotal= 7;

        if(self.formStatus === 'update'){
            totalCol = 1;
            totalQty= 2;
            totalPrice= 3;
            totalAmount= 4;
            totalDiscount= 5;
            totalTotal= 6;
        }
        
        columns.forEach((column, index) => {
          if (index === totalCol) {
            sums[index] = 'Total';
            return;
          }

          
          if (index === totalQty) {
            const qty = data.map(item => {
                return (item.code !== null && item.name !== null) ? parseInt(item.qty) : 0
              })

              if (!qty.every(qty => isNaN(qty))) {
                  sums[index] = qty.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);
              } 
            return;
          }
          if (index === totalPrice) {
            const price = data.map(item => {
                return parseFloat(item.price)
              })

              if (!price.every(price => isNaN(price))) {
                  const sum = price.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              } 


              // sums[3] =  this.formatNumber(price)
            return;
          }
          if (index === totalAmount) {
            const amount = data.map(item => {
                return parseInt(item.qty) * parseFloat(item.price);
              });

              if (!amount.every(amount => isNaN(amount))) {
                  const sum = amount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }

            return;
          }
          if (index === totalDiscount) {
            const discount = data.map(item => {
              let d = 0;
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = self.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }
                return d;
              })
              
              if (!discount.every(discount => isNaN(discount))) {
                  const sum = discount.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

          if (index === totalTotal) {
            const total = data.map(item => {
             let d = 0;
           
              
              if(item.hasOwnProperty("promotions") && item.promotions.length > 0){
                const itemPromo = item.promotions[0];
                d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
              }

              if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  d = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);

                }


                return self.computeTotalAmountNumber(item.qty,item.price,d);;
              });
              
              if (!total.every(total => isNaN(total))) {
                  const sum = total.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                    return prev + curr;
                  } else {
                    return prev;
                  }
                }, 0);

                sums[index] = this.formatNumber(sum)

              }
            return;
          }

            

          });

        return sums;
      },

openPaymentWindow(){
  const paymentformVar = {
    transaction : this.form
  }

  this.setPaymentForm(paymentformVar);
  this.$refs.paymentForm.openDialog()
},
getActiveTabIndex(index){
  this.active = index
},

fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ["mobile", "label"],
        shouldSort: true
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },

searchCustomer(v){
  const self = this;
  const searchCustomer = [];
  self.fnGetContacts({term : v})
},
contactSuccess(v){
  const contact = { label: v.name, value: v.mdbID, mobile: v.mobile, address : v.address }
  this.customer.unshift(contact);
  this.form.customer_id = v.mdbID
  this.form.mobile = v.mobile
},
openCustomer(){
  this.$refs.contactForm.openDialog();
},
getCustomerSelected(v){
  // console.log(v)
  const selectedCustomer = this.customer.filter(c => {
    return c.value === v;
  })[0];

  if(selectedCustomer !== null){
    this.form.attr.mobile = selectedCustomer.mobile
    this.form.attr.address = selectedCustomer.address
  }

},
optionsHandler(){
  // this.getPurchaseOrder()
  const self = this;
  this.dispatchFilterItems({transaction_date : this.form.date})
  // self.fnGetContacts()
},
customLabel({label , mobile}){
  return `${label} – ${mobile}`
},
fnGetContacts(params){
  const self = this;
  const contacts = []
  this.GetContacts(params).then((response) => {
      if(response.length > 0){
        response.forEach((element) => {
           contacts.push({
            opt : element.label + " " + element.mobile,
            label : element.label,
            value : element.value,
            mobile : element.mobile,
            address : element.address,
          })
        })
      }
     
  })

  self.customer = contacts;
},
async setPreparedBy(){
    var self = this;
    const loggedUser = await userData()

    const frm = this.form
    frm.sold_by = loggedUser.user.name
    this.form = frm;
},


deleteItem(){

if(this.selected.length){
    if(this.formStatus === 'update'){
        MessageBox.confirm('This will permanently delete the file. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
          }).then(() => {
              this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){

              const formItemVar = this.formItemVar();
                this.form.items.push(formItemVar)
              }
          })
    }else{
      this.selected.forEach(element => {
                var removeIndex = this.form.items.map(function(item) { return item.code; }).indexOf(element.code);
                this.form.items.splice(removeIndex, 1);
              });

              if(this.form.items.length <= 0){
                const formItemVar = this.formItemVar();
                this.form.items.push(formItemVar)
              }
    }
  

  
}


// remove object


},

handleSelectionChange(v){
  this.selected = v
},
addNewRow(index){
   /*   */

    var dataLength = this.form.items.length - 1;

    if(dataLength === index){
        const item = this.form.items[index];
        if(item.code !== null && item.description !== null){
              const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)

            }
    }

},

getSelected(v,index){
  const getSelected = this.items.filter(item => { 
        return (item.code === v)
    })[0];

    if(getSelected){

      const qty = 1;
      let price = getSelected.price;
      let discount = 0;
      let remarks = null;

      if(getSelected.promotions.length > 0){
        const itemPromo = getSelected.promotions[0];

        const cd = {
          apply: true,
          discount: 0,
          off_price: 0,
          promo: 0,
        }

        discount = this.computeDiscount(qty,price,itemPromo,'promo',cd);
          remarks = this.setRemarks(qty,itemPromo);
           this.$set(this.form.items[index], 'discount_type','promo');
      }


      this.$set(this.form.items[index], 'item_id', getSelected.id)
      this.$set(this.form.items[index], 'name', getSelected.label)
      this.$set(this.form.items[index], 'qty', qty)
      this.$set(this.form.items[index], 'promotions', getSelected.promotions)
      this.$set(this.form.items[index], 'formatted_price', getSelected.formatted_price)
      this.$set(this.form.items[index], 'price', price)
      this.$set(this.form.items[index], 'amount', this.computeAmount(qty, price))
      this.$set(this.form.items[index], 'discount', discount)
      this.$set(this.form.items[index], 'remarks', remarks)
      this.$set(this.form.items[index], 'total', this.computeTotalAmount(qty, price, discount))
      // this.addNewRow(index)

      // console.log(this.form.items[index])
      var dataLength = this.form.items.length - 1;
        if(dataLength === index){
            const formItemVar = this.formItemVar();
              this.form.items.push(formItemVar)
        }
    }
},

statusKeyUpFocus(){

  const localItems = this.items;
  const filterOnFocus = [];
  if(localItems.length > 0){
    localItems.forEach(item => {
      if(this.itemSeries.indexOf(item.code) < 0){
        filterOnFocus.push(item)
      }
    });

    this.items = filterOnFocus
  }

  
},

dispatchFilterItems(params){
  const results = [];
  this.filterItems(params).then((response) =>{
        if(response.length > 0){
          response.forEach(element => {
            results.push({label : element.name, code : element.series,formatted_price : element.formatted_price,price : element.sale_price, id : element.mdbID,promotions : element.promotions})
          });
        }
    })
  this.items = results

},

statusKeyUp(v){
// this.getPurchaseOrder(v)

const params = {term : v, items : this.itemSeries,transaction_date : this.form.date};
        this.dispatchFilterItems(params)
},
 openDialog(){
        this.$refs.window.openDialog();
      },
 openWindow(reference,window){
        if(reference === 'attribute'){
                this.$refs.attribute[0].$refs.window.openDialog();
              
            }

      },
      nextCode(){
        var self = this;
        this.getNextCode().then((response) => {
          self.form.series = response.code
        });
      },
/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },

      

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            this.showError = false
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                      if(self.formStatus == 'create'){
                          self.storeRecord(self.form).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success')


                              self.setFormStatusUpdate()
                              self.setFormRecord(response.data)
                              self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.formStatus == 'update'){
                          self.updateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                                // self.$ThatsNotify.success()
                              self.$emit('success')
                                // self.FormResetter();

                                self.setFormRecord(response.data)
                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        const formItemVar = this.formItemVar()
        this.form.items.push(formItemVar)
        this.setPreparedBy()
        requestAnimationFrame(() => {
              this.$refs.observer.reset();
            });
        this.validated = false
        this.formLoading = false
        this.sendLoading = false
        this.setFormStatusCreate();
        this.showError = false
        this.currentDateSetter();
        this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
              ]

      },
      
      currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.date = date;
      },
      dialogState(state){
        this.active = 0;
        this.optionsHandler();
        if(!state){
          this.FormResetter();
          return
        }

        if(this.formStatus == 'create'){
          this.FormResetter();
            // this.nextCode();
            this.tabs = [
                { text: 'Sales Details', slot: 'form-slot' }
              ]
        }
        
      },
      emitDelete(){
        this.$emit('delete',this.formRecord)
      },

      computeDiscount(qty,price,promo,discount_type,custom_discount){

        if(discount_type !== null){
          let discountPrice = 0
          let apply = false;
          let discount = 0;
            if(discount_type === 'promo'){
                apply = promo.apply
                discount = promo.discount
              }else{
                if(custom_discount !== null){
                  if(custom_discount.hasOwnProperty("apply")){
                    apply = custom_discount.apply;
                  }
                  if(custom_discount.hasOwnProperty("discount")){
                    discount = custom_discount.discount;
                  }
                }
              
              
            }
           const p = parseFloat(price) * qty;
                
                  if(apply === true){
                    discountPrice = parseFloat(p) * (discount / 100);
                  }else{
                    discountPrice = parseFloat(discount) * qty;
                  }
          return discountPrice;
        }
        return 0;
      },
     

      setRemarks(qty,promo){
          if(promo.apply === true){
              return `${promo.discount}% Discount`;
          }else{
            return `${this.formatNumber(((promo.discount) * qty))} QAR Off`;
          }
      },
      computeAmount(qty,price){
          return this.formatNumber((qty * parseFloat(price)));
      },
      computeTotalAmountNumber(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return ((d > 0) ? totalAmount : amount);
      },
      computeTotalAmount(qty,price,discount){
        const d = parseFloat(discount);
        const amount = qty * parseFloat(price);
        const totalAmount = amount - d;
          return this.formatNumber(((d > 0) ? totalAmount : amount));
      },

      calculate(index){
        const self = this;
          if(typeof self.form.items[index] !== 'undefined') {
              const item = self.form.items[index];
              
              let discount = 0;
              let remarks = null;

              if(item.promotions.length > 0){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,itemPromo)
                }

                if(item.discount_type === 'discount'){
                  const itemPromo = item.promotions[0];
                  discount = this.computeDiscount(item.qty,item.price,itemPromo,item.discount_type,item.custom_discount);
                  remarks = this.setRemarks(item.qty,item.custom_discount);
                  // frm.total = this.computeTotalAmount(qty, price, frm.discount)
                }

                
              if(item.code !== null && item.name !== null){
                 const amount = this.computeAmount(item.qty,item.price);
                 const totalAmount = this.computeTotalAmount(item.qty,item.price,discount);
                 this.$set(self.form.items[index], 'remarks',remarks)
                 this.$set(self.form.items[index], 'discount', this.formatNumber(discount))
                 this.$set(self.form.items[index], 'amount', amount)
                 this.$set(self.form.items[index], 'total', totalAmount)

              }
          }
      },

      formatNumber(n) {
        return parseFloat(n).toFixed(2).replace(/./g, function(c, i, a) {
          return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        });
      },
      fnPrint(url){
        this.printUrl = url
        this.$refs.printWindow.openDialog();
      },

    },

    computed: {

      ...mapGetters('SalesOrderModule',
      [
        'formStatus',
        'formRecord',
        'viewLoading',
      ]),
      
...mapGetters('AuthModule',[ 'user', ]),

      itemSeries : function(){
          var self = this;
          const filteredSeries = self.form.items.filter((item) => {
              return (item.code !== "" && item.code !== null)
          }).map(function(key){
                return key.code
          })


          return filteredSeries;
      },

      disabled : function(){
          var self = this;
          if(self.formStatus === 'update'){
            return true;
           /*  if(self.form.invoice !== null && self.form.invoice.series !== null){
            } */
          }
          return false;
      },
      invoice : function(){
          let inv = {
          series : null,
          date : "",
          balance : 0,
          paid : 0,
          attachment : [],
          payments : [],
        }

       /*  if(this.FormStatus == 'update'){
            if(this.FormRecord != null && this.FormRecord.invoice !== null){
              inv = this.FormRecord.invoice
            }
        } */
        return inv
      }
    },

    watch: {
      formRecord : function(v){

        console.log(v)
        this.active = 0;
        if(this.formStatus == 'update'){
          
          this.form = JSON.parse(JSON.stringify(this.formRecord));
          const formItemVar = this.formItemVar();
          this.form.items.push(formItemVar)
          this.formOriginal = JSON.parse(JSON.stringify(this.formRecord));
          this.customer = [{
            opt : v.customer.name + " "+ v.customer.mobile,
            label :v.customer.name,
            value : v.customer.mdbID,
            mobile : v.customer.mobile,
            address : v.customer.address,
          }];
        }

        if(this.formStatus == 'create'){
          this.FormResetter();


        }
      },

    },


}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}
</style>