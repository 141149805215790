
<template>
    <div class="page-container">
        <TableShowroom title="Item Sticker" ref="table" :endpoint="endpoint" @openUpdateModal="OpenUpdateModal" :columns="columns" :height="'calc(100vh - 123px)'" :enableFilter="false" :newBtn="enableNew" :enableDelete="false" @print="fnPrint" @print_small="fnPrintSmall" @print_qr="fnPrintQr" @selectionChange="handleSelectionChange">
            <template #top-right>
                <mdb-btn color="elegant" icon="print" size="sm" class="mr-auto mt-0" @click="printLabelDiscount">Print</mdb-btn>
            </template>

        </TableShowroom>
        
    </div>
</template>
<script>


import { mapGetters, mapMutations,mapActions } from 'vuex';
import BarcodeForm from './BarcodeForm'
import ItemForm from '../item/ItemForm'
import BarcodeFilter from './BarcodeFilter'
import TableShowroom from './TableShowroom'
import {mdbTabs, mdbBtn} from 'mdbvue'

export default {
    data(){
        return {
            endpoint : 'sticker',    
            activeTab : 'barcode',    
            enableFilter : false,       
            importLoading : false,        
            active : 0,        
            token : null,
            enableNew : false,        
            enableImport : false,
            selection : [],
                   
        }
    },
    components:{
        BarcodeFilter,
        ItemForm,
        BarcodeForm,
        TableShowroom,
        mdbTabs,
        mdbBtn,

    },
    created(){
        // this.columns = this.salesColumns;
        this.endpoint = 'sticker';
    },   
    methods: {
        // 

        ...mapActions('TableModule',[
            'resetData',
        ]),
        ...mapMutations('TableModule',[
            'SetTotal',
            'SetPage',
            'SetTerm',
            'SetFilters',
            
        ]),

        ...mapActions('ItemModule',{
            itemGetRecord  : 'getRecord'
        }),
        ...mapMutations('ItemModule',{
            itemSetFormRecord : 'SetFormRecord', itemSetFormStatusUpdate: 'SetFormStatusUpdate'
        }),

        ...mapActions('BarcodeModule',[ 'deleteRecord','saveImport','getRecord']),
        ...mapMutations('BarcodeModule',[ 'setFormRecord', 'setFormStatusUpdate','setFormStatusCreate','setTblKey']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){

        },
        OpenUpdateModal(obj){
            const self = this
             const form  = {...obj.row,images : []}           
            self.itemSetFormStatusUpdate()
            self.itemSetFormRecord(form)
            self.itemGetRecord({token_id : obj.row.token_id})
            self.$refs.itemDialogForm.openDialog()

        },
        openUploadWindow(){           
        },
        openWindow(){
            
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            
            this.setter()
            this.$refs.table.refreshTable()
        },
        importProduct(){
           this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v           
           this.$refs.tableImport.openDialog()
        },

        

        setter(){
            let resetDataEndpoint = 'sticker';
          
            
            this.resetData(resetDataEndpoint);
        },

        getActiveTabIndex(index){


            const tabs = ['sales','sales-quotation','sales-order','online-orders'];
            this.activeTab = tabs[index];
            this.SetPage(1)
            this.SetTotal(0);
            this.SetFilters({});
            this.setter()
            // commit('SetTotal', 0)
            /* SetFilters: (state, payload) => {
            state.filters = payload
        }, */
        },

        fnPrint(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintSmall(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print-small/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        fnPrintQr(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/print-qr/${obj.row.token_id}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabel(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelDiscount(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/showroom/sticker?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelSmall(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-small?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelQr(obj){
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-qr?items=${this.selection}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },

      handleSelectionChange(v){
        let selectionArray = [];
          if(v.length > 0){
               selectionArray = v.map((item) =>{
                    return item.id
                })
              }
            this.selection = selectionArray
      }

    },
    computed: {
        ...mapGetters('TableModule',[ 'tableLoading']),
        ...mapGetters('StickerModule',[ 'columns','data', 'page', 'total', 'page', 'length', 'getLoading', ]),
        checkSystemSupport : function(){
            const systemSupport = this.$helpers.isSystemAdministrator();
            return systemSupport
        }
    },
}


</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }

  .display-block{
      opacity: 1!important;pointer-events : initial!important
  }
</style>