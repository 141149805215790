export const DataModule = {
    namespaced: true,
    state: {
        loading: false,
        viewLoading: false,
        deleteLoading: false,
        data : {
            columns: [],
            rows: [],
        },

        tabData : [
            {label : "Data", slug : "data", columns : [], rows : [], errors : []}
        ],
        length : 25,
        page : 0,
        total : 1,
        to_update : 0,
        tblKey : 1
    },
    actions : {
        
    },
    mutations: {
       
        setData(state, payload){
            state.data.rows = payload
        },
        setDataRow(state, payload){
            state.data.rows = payload
        },
        setDataColumn(state, payload){
            state.data.columns = payload
        },
        setPage: (state, payload)=>{
            state.page = payload
        },
        setTotal: (state, payload)=>{
            state.total = payload
        },
        setLength: (state, payload)=>{
            state.length = payload
        },
        setTblKey: (state, payload)=>{
            state.tblKey = payload
        },
        setTabData: (state, payload)=>{
            state.tabData = payload
        },
        setToUpdateCount: (state, payload)=>{
            state.to_update = payload
        },
        
    },
    getters: {
        toUpdate: state => state.to_update,
        tabData: state => state.tabData,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        rows: state => state.data.rows,
        tblKey: state => state.tblKey
    }
}


