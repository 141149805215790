/* eslint-disable no-prototype-builtins */
<template>
      <hsc-window-style-metal>
          <hsc-window v-bind:class="'costing-form height-auto'" title="ITEM CODE GENERATOR" :resizable="false" :left="x" :top="y" :width="width" :height="height" :minWidth="minWidth" :maxWidth="width" :minHeight="minHeight" :maxHeight="maxHeight" :isOpen.sync="dialogIsOpen" :closeButton="true" :zGroup="(group + 1)">


            <template #left-button>
              <template v-if="formLoading || getLoading">
                <mdb-btn color="elegant" size="sm" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </mdb-btn>
              </template>
            </template>
          <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
            <div class="mt-1">
              <mdb-row class="tab-row">
                      <mdb-col col="4" class="tab-col">
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="Document Name" vid="doc_name" name="doc_name" v-model="form.doc_name" size="sm" :disabled="false" :rules="'required'"/>
                      </mdb-col>   
                      <mdb-col col="4" class="tab-col">
                        <ThatsMultiSelect :multiple="false"  labelClass="col-md-4" fieldClass="col-md-8  pl-0" label="ORIGIN" v-model="form.origin_code" :reduce="name => name.id" :reduceLabel="'name'" :options="countryData" :rules="'required'" :filterable="true" :clearable="false" :disabled="false" ></ThatsMultiSelect>
                        <ThatsInput tabindex="2" labelClass="col-sm-4" fieldClass="col-sm-8 pl-0" label="SUPLIER CODE" vid="vendor_code" name="vendor_code" v-model="form.vendor_code" size="sm" :disabled="false" :rules="'required'"/>
                       </mdb-col>
                    </mdb-row>  
                    <div ref="spreadsheetexport" class="demo-x"></div> 

                    <div class="d-flex">

                      <template v-if="formLoading || getLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>
                      <template v-else>
                              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Generate</mdb-btn>
                              <mdb-btn color="grey" size="sm" @click="dialogIsOpen = false">Close</mdb-btn>
                      </template>


                  </div>
              
              
            </div>
            </ValidationObserver>
        </hsc-window>

          <CodeGeneratorAreaForm ref="codeGenerateAreaForm" :group="5" @success="updateAreaData"></CodeGeneratorAreaForm>
          <CodeGeneratorCategoryForm ref="codeGenerateCategoryForm" :group="5" @success="categorySuccess"></CodeGeneratorCategoryForm>
          <CodeGeneratorVariationForm ref="codeGenerateMaterialForm" type="material" :group="5" @success="variationSuccess"></CodeGeneratorVariationForm>
          <CodeGeneratorVariationForm ref="codeGenerateColorForm" type="color" :group="5" @success="variationSuccess"></CodeGeneratorVariationForm>
          <CodeGeneratorVariationForm ref="codeGenerateFabricForm" type="fabric" :group="5" @success="variationSuccess"></CodeGeneratorVariationForm>
          <CodeGeneratorPreview ref="codeGeneratePreview" type="fabric" :group="5" @success="createdSuccess"></CodeGeneratorPreview>
          <CodeGeneratorPartForm ref="codeGeneratePartForm" :group="5" @success="codeGeneratePartFormSuccess"></CodeGeneratorPartForm>
          <CodeGeneratorTableForm ref="codeGeneratorTableForm" :group="5" @success="codeGenerateTableFormSuccess"></CodeGeneratorTableForm>
          <CodeGeneratorVariationTableForm ref="codeGeneratorMaterialTableForm" :title="'Material'" :type="'material'" :group="5" @success="allVariationSuccess"></CodeGeneratorVariationTableForm>
          <CodeGeneratorVariationTableForm ref="codeGeneratorColorTableForm" :title="'Color'" :type="'color'" :group="5" @success="allVariationSuccess"></CodeGeneratorVariationTableForm>
          <CodeGeneratorVariationTableForm ref="codeGeneratorFabricTableForm" :title="'Fabric'" :type="'fabric'" :group="5" @success="allVariationSuccess"></CodeGeneratorVariationTableForm>
          <CodeGeneratorCategoryTableForm ref="codeGeneratorCategoryTableForm" :group="5" @success="codeGenerateCategoryFormSuccess"></CodeGeneratorCategoryTableForm>
       </hsc-window-style-metal>
</template>
<script>

/*eslint no-prototype-builtins: "error"*/

import { mdbClickOutside,mdbBtn, mdbRow,mdbCol, mdbIcon, mdbTabs,   mdbDropdown, 
  mdbDropdownItem, 
  mdbDropdownMenu, 
  mdbDropdownToggle,
  mdbInput} from "mdbvue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'



import { ThatsWindow,ThatsInput, ThatsFieldset, ThatsTextarea, ThatsMoney, ThatsMultiSelect} from '../../components/thats/Thats'

import { MessageBox } from 'element-ui';

import unformat from 'accounting-js/lib/unformat.js'
import formatMoney from 'accounting-js/lib/formatMoney.js'

import jspreadsheet from '../../jsexcel/jexcel'
import CostingInput from '../../components/thats/CostingInput.vue'

import AmountInput from '../../components/thats/AmountInput.vue'
import country from '../generate-serial/country.js'

import CodeGeneratorAreaForm from './CodeGeneratorAreaForm.vue'
import CodeGeneratorCategoryForm from './CodeGeneratorCategoryForm.vue'
import CodeGeneratorVariationForm from './CodeGeneratorVariationForm.vue'
import CodeGeneratorPreview from './CodeGeneratorPreview.vue'
import CodeGeneratorPartForm from './CodeGeneratorPartForm.vue'
import CodeGeneratorTableForm from './CodeGeneratorTableForm.vue'
import CodeGeneratorCategoryTableForm from './CodeGeneratorCategoryTableForm.vue'
import CodeGeneratorVariationTableForm from './CodeGeneratorVariationTableForm.vue'
window['SUMCOL'] = function(instance,columnId) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    }
    return formatMoney(total, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['CUSTOMSUM'] = function(instance, row,column) {

  // console.log(Number(instance.records[j][columnId].innerHTML))
  /* var total = 0;
    for (var j = 0; j < instance.options.data.length; j++) {
         const cellValue = Number(instance.records[j][columnId].innerHTML.replace(/[^0-9\.-]+/g,""));

        if (cellValue) {
            total += cellValue;
        }
    } */
    return formatMoney(row+column, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}

window['EXCHANGERATE'] = 0;

window['FORMATVALUE'] = function(value) {
  
    return formatMoney(value, { symbol: "", precision: 2, thousand: ",", decimal: "." });
}
window['COSTPRICE'] = function(e,l,m,n,o) {

  let currencySymbol = ''
  let unitPrice = unformat(e)

              if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.TxtLineTotal.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]
              }


      const freightCharge = unformat(l)
      const customDuty = unformat(m)
      const customClearance = unformat(n)
      const shippingLine = unformat(o)
      
    return formatMoney((unitPrice + freightCharge + customDuty + customClearance + shippingLine), { symbol: currencySymbol , precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICE'] = function(c,n,o) {

  

  const costPrice = unformat(n)
    const multiplier = unformat(o)
    let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
                  const stringSegment = c.trim().split(/\s+/);
                  currencySymbol = stringSegment[0]

              }
 
    return formatMoney((costPrice * multiplier), { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}
window['RETAILPRICEQAR'] = function(n,o) {
  const costPrice = unformat(n)
      const multiplier = unformat(o)
   const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];

   


    return formatMoney((costPrice * multiplier) * exchangeRate, { symbol: "QAR ", precision: 2, thousand: ",", decimal: "." });
}
window['MULTIPLIERWIHOUTCOST'] = function(c,n,o) {

  // const unitPrice = unformat(c)
  //     const freightCharge = unformat(j)
const exchangeRate =( typeof window['EXCHANGERATE'] === 'undefined' ) ? 0 : window['EXCHANGERATE'];
  const unitPrice = unformat(c)
      const unitCostPrice = unformat(n)
      const multiplier = unformat(o)

      const rp = (unitCostPrice * multiplier) * exchangeRate

      let currencySymbol = ''
      if (typeof c === 'string' || c instanceof String){
          const stringSegment = c.trim().split(/\s+/);
          currencySymbol = stringSegment[0]
      }
/* 
console.log("MULTIPLIERWIHOUTCOST")
      console.log(unitPrice)
      console.log(unitCostPrice)
      console.log(multiplier)
      console.log(rp)
      console.log((rp / unitPrice/ exchangeRate)) */

    let multiplierWihtoutCost = 0
    if(multiplier > 0 && rp > 0){
      multiplierWihtoutCost = ( rp / unitPrice/ exchangeRate)
    }
  
    return formatMoney(multiplierWihtoutCost, { symbol: currencySymbol, precision: 2, thousand: ",", decimal: "." });
}



// console.table(window);

const onChange = function(instance){
  console.log(instance)
}



var customColumn = {
    // Methods
    closeEditor : function(cell, save) {
        var value = cell.children[0].value;
        cell.innerHTML = value;
        return value;
    },
    openEditor : function(cell) {
        // Create input
        var element = document.createElement('thatsMultiSelect');
        element.value = cell.innerHTML;
        // // Update cell
        cell.classList.add('editor');
        cell.innerHTML = '';
        cell.appendChild(element);
        // $(element).clockpicker({
        //     afterHide:function() {
        //         setTimeout(function() {
        //             // To avoid double call
        //             if (cell.children[0]) {
        //                 myTable.closeEditor(cell, true);
        //             }
        //         });
        //     }
        // });
        // // Focus on the element
        // element.focus();
    },
    getValue : function(cell) {
        return cell.innerHTML;
    },
    setValue : function(cell, value) {
        cell.innerHTML = value;
    }
}


const dropdownFilter = function(instance, cell, c, r, source) {
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
          return item.area_id == value
        },[]);

      console.log("Filter")
      console.log(value)
      console.log(filterResult)
      console.log(source)
        return filterResult
}

const dropdownSource = function(instance, cell, c, r, source) {
      var value = instance.jexcel.getValueFromCoords(c - 1, r);
      console.log("source")
      console.log(value)
      console.log(source) 
    /* 
    const filterResult = source.filter((item)=>{
          return item.area_id == value
        },[]);

      */
        return []
}
 

export default {
  directives: {
      mdbClickOutside
    },
  props : {
    group : {
      type : Number,
      default : 2
    }
  },
    components: {
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbTabs,
        mdbDropdown, 
        mdbDropdownItem, 
        mdbDropdownMenu, 
        mdbDropdownToggle,
        ValidationProvider,
        ValidationObserver,
        ThatsWindow, 
        ThatsInput,
        mdbInput ,
        ThatsFieldset,
        CostingInput,
        ThatsTextarea,
        ThatsMoney,
        AmountInput,
        ThatsMultiSelect,
        CodeGeneratorAreaForm,
        CodeGeneratorCategoryForm,
        CodeGeneratorVariationForm,
        CodeGeneratorPreview,
        CodeGeneratorPartForm,
        CodeGeneratorTableForm,
        CodeGeneratorCategoryTableForm,
        CodeGeneratorVariationTableForm
  },
    
  data(){

    const self = this;
    const test = function(){
        console.log("test")
    }

    /* 
    
     {"name": "Afghanistan", "id": "AF", "area" : "f"},
            {"name": "Aland Islands", "id": "AX", "area" : "c"},
            {"name": "Albania", "id": "AL", "area" : "c"},
            {"name": "Algeria", "id": "DZ", "area" : "f"}
            
            */
    return {

        inputValue : 0,
        validated : false,
        CurrencySymbolLocal : 'QAR',
        active: 0,
        tabs : [
          { text: 'PO Details', slot: 'form-slot' }
        ],
        data : [],
        countryData : [],
        areaData : [],
        catObjectArray : [],
        variationObjectArray : [],
        finishObjectArray : [],
        colorObjectArray : [],
        fabricObjectArray : [],
        categoryDropDown : [],
        finishDropDown : [],
        colorDropDown : [],
        fabricDropDown : [],
        templateDropDown : [],
        form : {

            doc_name: null,
            origin_code: null,
            vendor_code : null,
            attr : {
              
            },
          items :[]
        },
        loadPurchaseOrderLoading : false,
        formLoading : false,
        height: 800,
        width: 1300,
        minHeight : 350,
        maxHeight : 800,
        minWidth : 600,
        x : 0,
        y : 0,
        dialogIsOpen : false,
        spreadsheetApplyLoading: false,
        spreadsheetexport: null,
        exportTableOptions : {
          copyCompatibility: false,
            download : false,
            csvFileName : 'costing',
            allowDeleteRow : true,
            allowInsertRow : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            allowExport : false,
            csvHeader:true,
            tableWidth : '1650px',
            tableHeight : 'calc(100vh - 165px)',
            tableOverflow : true,
             data:[],
             raw:[
              {code : 3,name : 'testingx'}
             ],
             minDimensions:[6,50],
             onCopy : null,
             selectionCopy : true,
             allowCopy : true,
             freezeColumns: 2,
             columns: [],

            
             //footers: [['','','','','','','','','','','TOTAL','=SUMCOL(TABLE(),11)','','','=SUMCOL(TABLE(),14)','','','','','=SUMCOL(TABLE(),19)','=SUMCOL(TABLE(),20)','=SUMCOL(TABLE(),21)','=SUMCOL(TABLE(),22)','=SUMCOL(TABLE(),23)','','','','','','','','']],

            updateTable:function(instance, cell, col, row, val, label, cellName) {

              if((row % 2) == 1){
                  cell.parentNode.style.backgroundColor = '#FAFAFA';
              }
            },

             contextMenu: function(obj, x, y, e) {
                var items = [];

                    if(x !== null){
                      if(x == 2 ){
                            items.push({
                                title: 'New Area',
                                onclick:function() {
                                    self.$refs.codeGenerateAreaForm.openDialog()
                                }
                            });
                            items.push({
                                title: 'View all Area',
                                onclick:function() {
                                    self.openCodeGeneratorTableForm()
                                }
                            });
                            items.push({ type:'line' });
                      }
                      if(x == 3 ){
                          items.push({
                                title: 'New Category',
                                onclick:function() {
                                    self.openNewCategoryWindow()
                                }
                            });
                            items.push({
                                title: 'View all Category',
                                onclick:function() {
                                    self.$refs.codeGeneratorCategoryTableForm.openDialog()
                                }
                            });
                            items.push({ type:'line' });
                      }
                      if(x == 4 ){
                          items.push({
                                title: 'New Color Variation',
                                onclick:function() {
                                    self.$refs.codeGenerateColorForm.openDialog()
                                }
                            });
                            items.push({
                                title: 'View all Color Variation',
                                onclick:function() {
                                    self.$refs.codeGeneratorColorTableForm.openDialog()
                                }
                            });
                            items.push({ type:'line' });
                      }
                      if(x == 5 ){
                          items.push({
                                title: 'New Material Variation',
                                onclick:function() {
                                    self.$refs.codeGenerateMaterialForm.openDialog()
                                }
                            });
                            items.push({
                                title: 'View all Material Variation',
                                onclick:function() {
                                    self.$refs.codeGeneratorMaterialTableForm.openDialog()
                                }
                            });
                            items.push({ type:'line' });
                      }
                      if(x == 6 ){
                          items.push({
                                title: 'New Fabric Variation',
                                onclick:function() {
                                    self.$refs.codeGenerateFabricForm.openDialog()
                                }
                            });

                            items.push({
                                title: 'View all Fabric Variation',
                                onclick:function() {
                                    self.$refs.codeGeneratorFabricTableForm.openDialog()
                                }
                            });
                            items.push({ type:'line' });
                      }
                      if(x == 7){
                          items.push({
                                title: 'New Parts template',
                                onclick:function() {
                                    self.$refs.codeGeneratePartForm.openDialog()
                                }
                            });
                          items.push({ type:'line' });
                      }


                    }
                    if(y !== null){
                        
                        if (obj.options.allowInsertRow == true) {
                            items.push({
                                title:obj.options.text.insertANewRowBefore,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y), 1);
                                }
                            });
                            
                            items.push({
                                title:obj.options.text.insertANewRowAfter,
                                onclick:function() {
                                    obj.insertRow(1, parseInt(y));
                                }
                            });
                        }
                    }

                if (obj.options.allowDeleteRow == true) {
                 items.push({
                     title:obj.options.text.deleteSelectedRows,
                     onclick:function() {
                         obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                     }
                 });

             }
                return items;
             },

             onblur: function(instance){

             },
             onchange: function(instance, cell, column, row, value){
                
              /*   console.log("change")
                console.log("instance")
                console.log(instance)
                console.log("cell")
                console.log(cell)
                console.log("x")
                console.log(x)
                console.log("y")
                console.log(y)
                console.log("value")
                console.log(value)
                console.log("end change") */

                // if()
             },
            /*  onafterchanges(){
             },
 */
            /*  onbeforepaste(instance,data,column,row){

                // var value = instance.jexcel.getValueFromCoords(c - 1, r);

                // if(column == 2){
                    
                // }

                

                // return data
                console.log("before paste")
                console.log("instance")
                console.log(instance)
                console.log("data")
                console.log(data)
                console.log("x")
                console.log(x)
                console.log("y")
                console.log(y)
                console.log("end before paste")
             } */
       }
    }
  },
    

  created : function() {

        this.height = window.innerHeight;
        this.maxHeight = window.innerHeight;
        this.width = window.innerWidth;
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`

        window['EXCHANGERATE'] = 0;
  },

  mounted(){
        const self = this
        this.countryData = country

        this.getArea().then((res) => {
          self.areaData = res
        })
      this.getCategoryRecords().then((res) => {
          self.catObjectArray = res
        })
      this.getVariations().then((res) => {
          self.variationObjectArray = res

          console.log("variation")
          console.log(res)

          self.finishDropDown = res.filter((item) => {
            return item.type === "material"
          },[])

          self.colorDropDown = res.filter((item) => {
            return item.type === 'color'
          },[])
          self.fabricDropDown = res.filter((item) => {
            return item.type === 'fabric'
          },[])
          self.templateDropDown = res.filter((item) => {
            return item.type === 'template'
          },[])

          // console.log("varitions")
          // console.log(res)
          // self.catObjectArray = res
        })
  },

  updated(){

  },
  methods: {

    ...mapActions('CodeGeneratorAreaModule',['getArea']),
    ...mapActions('CodeGeneratorCategoryModule',{
        getCategoryRecords : 'getRecords'
    }),
    ...mapActions('CodeGeneratorVariationModule',{
        getVariations : 'getRecords'
    }),
    ...mapActions('CodeGeneratorModule',['generateData']),
    ...mapMutations('CodeGeneratorModule',['setFormStatusCreate']),
    ...mapMutations('ApplyAmountModule',{
       setApplyAmountFormData : 'setFormData'
    }),

    demo(){
      console.log("demo")
      return 0;
    },
    resetForm(){
      
      },
    openDialog(){
      this.dialogIsOpen = true;
    },
    closeDialog(){
      this.dialogIsOpen = false
    },
    getActiveTabIndex(){

    },
    onSubmitForm(){
       const self = this;
            const isValid = self.$refs.observer.validate();
            if(isValid){
                
              //  const getData = this.spreadsheetexport.getData();

                      const columns = [
                                      'supplier_item_code',
                                      'name',
                                      'area_id',
                                      'category_code',
                                      'color_code',
                                      'material_code',
                                      'fabric_code',
                                      'template_id'
                                    ];

                                    console.log(this.form.items)

                        const newRow = this.form.items.filter((item) => {
                                          return item[2] !== '' && item[1] !== ''&& item[3] !== '' && item[4] !== ''
                                        },[]).map((item) => {
                                          const rowArray = {};
                                          columns.forEach((col,index) => {
                                                if(['area_id','category_code','color_code','material_code','fabric_code','template_id'].includes(col)){
                                                    if(isNaN(parseInt(item[index]))){
                                                      rowArray[col] = null
                                                    }else{

                                                     rowArray[col] = parseInt(item[index]);
                                                    }
                                                }else{
                                                  rowArray[col] = item[index]
                                                }
                                          })

                                          return { origin_code : self.form.origin_code,vendor_code : self.form.vendor_code,...rowArray}
                                      },[]);


                        if(self.formLoading === false){
                                 const form = {
                                    doc_name : this.form.doc_name,
                                    origin_code : this.form.origin_code,
                                    vendor_code : this.form.vendor_code,
                                   items : JSON.parse(JSON.stringify(newRow))
                                  }

                                  if(newRow.length > 0){
                                    self.formLoading = true;
                                      self.generateData(form).then((res) => {
                                              console.log(res);
                                              self.formLoading = false;
                                              self.setFormStatusCreate()
                                              self.$refs.codeGeneratePreview.openDialog();
                                        }).catch((err) => {
                                            self.formLoading = false;
                                            console.log(err)
                                        });
                                  }else{
                                    MessageBox.alert('No valid data to generate.', 'Error', {
                                      type : 'error'
                                    })
                                  }
                        }
            }

            
    },
    openHistory(){

    },
    handleSelectionChange(){

    },
    cellClassName(){

    },

    fnLoadPurchaseOrder(){
        const self = this;

        const isValid = self.$refs.observer.validate();

        if(isValid && this.form.vendor_code !== null && this.form.vendor_code !== ''){
          self.loadPurchaseOrderLoading = true;
          self.setSearchTerm(this.form.vendor_code)
          self.resetData('get-purchase-orders').then((res) => {
            self.loadPurchaseOrderLoading = false
              self.data = res
           
          }).catch((err) => {
              self.loadPurchaseOrderLoading = false
              
          })
        }
    },

    createPopupTable(exportTableOptions){

            exportTableOptions.data = this.form.items
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
           
        },

        fnNewWindow(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          window.open(`${self.$route.path}/${url}`, "_blank");
        },
        fnPrint(url){
          const self = this;
          console.log(`${self.$route.path}/${url}`)
          
          var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
          const win = window.open(`${self.$route.path}/${url}`, "_blank", strWindowFeatures);
          win.document.title = 'Print';
          win.print()
        },

        fnExportXls(){
            this.spreadsheetexport.download(this.$refs.spreadsheetexport)
        },

        async fnApply(){

        },

        changeSource(){
            this.categoryDropDown = [
            {"name": "Albania", "id": "AL", "area" : "c"},
            ]
        },

        filterCategoryDropdown(){

            console.log("dropdown fileter")
            const f = this.categoryDropDown.filter((item) => {
                return item.area === 'f'
            })

            console.log(f)

            return f
        },

        renderTable(){
            const self = this;
            this.exportTableOptions.columns = [
                  {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'supplier_item_code',
                    title : 'SupplierItemCode'
                  },
                  {
                    type : 'text',
                    width : '350px',
                    align : 'left',
                    name : 'name',
                    title : 'Item Name'
                  },

                  {
                    type: 'dropdown',
                    width: '200px',
                    title:'Area',
                    name:'area_id',
                    autocomplete:true,
                    source: self.areaData,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                    },
                  {
                    type: 'dropdown',
                    width: '300px',
                    title:'Category',
                    name:'category_code',
                    autocomplete:true,
                    source: self.catObjectArray,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
                    filter: dropdownFilter
               
                    },
                    {
                      type: 'dropdown',
                      width: '250px',
                      title:'Color',
                      name:'color_code',
                      autocomplete:true,
                      source: self.colorDropDown,
                      multiple:false,
                      readOnly: false,
                      newOptions: true,
                 
                    },
                  {
                    type: 'dropdown',
                    width: '250px',
                    title:'Material',
                    name:'material_code',
                    autocomplete:true,
                    source: self.finishDropDown,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                  },
                  {
                    type: 'dropdown',
                    width: '250px',
                    title:'Fabric',
                    name:'fabric_code',
                    autocomplete:true,
                    source: self.fabricDropDown,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                  },
                  {
                    type: 'dropdown',
                    width: '250px',
                    title:'Parts',
                    name:'template_id',
                    autocomplete:true,
                    source: self.templateDropDown,
                    multiple:false,
                    readOnly: false,
                    newOptions: true,
               
                  },
                  /* {
                    type : 'text',
                    width : '250px',
                    align : 'left',
                    name : 'ItemCode',
                    title : 'ItemCode',
                    
                  }, */
                 
            ];


            // console.log(this.exportTableOptions.columns.map((i) => i.name))
                this.createPopupTable(this.exportTableOptions);
        },

        openNewAreaWindow(){
            this.$refs.codeGenerateAreaForm.openDialog()
        },
        openNewCategoryWindow(){
            this.$refs.codeGenerateCategoryForm.openDialog()
        },

        updateAreaData(){
          const self = this;
          this.getArea().then((res) => {
            self.areaData = res;
             self.spreadsheetexport.destroy();
             self.renderTable();
          })
        },

        async selectAreaCode(v){

          const self = this;
/* 
          console.log("this.category")
          console.log(v)
          console.log(this) */

          this.categoryDropDown = this.catObjectArray.filter((item) => {
              return item.area_id === v
          },[])

          this.spreadsheetexport.destroy();
            await this.renderTable();

             const tableData = this.spreadsheetexport.getData();

            const columnData = []

            tableData.forEach(element => {
                columnData.push('')
            },[]);
            
            // this.spreadsheetexport.setColumnData(2,columnData)
            /* this.spreadsheetexport.setColumnData(3,columnData)
            this.spreadsheetexport.setColumnData(4,columnData)
            this.spreadsheetexport.setColumnData(5,columnData) */

        },

        async categorySuccess(row){
            const self = this;
            this.catObjectArray.push(row)

            this.categoryDropDown = this.catObjectArray.filter((item) => {
                return item.area_id === self.form.area_id
            },[])

            this.spreadsheetexport.destroy();
            await this.renderTable();

        },

        async variationSuccess(row){
            this.variationObjectArray.push(row)
            if(row.type === "material"){
                this.finishDropDown.push(row)
            }
            if(row.type === 'color'){
                this.colorDropDown.push(row)
            }
            if(row.type === 'fabric'){
                this.fabricDropDown.push(row)
            }
            if(row.type === 'template'){
                this.templateDropDown.push(row)
            }
             this.spreadsheetexport.destroy();
            await this.renderTable();
        },
        async allVariationSuccess(data){

         
            this.variationObjectArray = data

            this.finishDropDown = data.filter((item) => item.type === 'material')
            this.colorDropDown = data.filter((item) => item.type === 'color')
            this.templateDropDown = data.filter((item) => item.type === 'template')
            this.fabricDropDown = data.filter((item) => item.type === 'fabric')

            /* if(row.type === "material"){
                this.finishDropDown.push(row)
            }
            if(row.type === 'color'){
                this.colorDropDown.push(row)
            }
            if(row.type === 'fabric'){
                this.fabricDropDown.push(row)
            }
            if(row.type === 'template'){
                this.templateDropDown.push(row)
            } */
             this.spreadsheetexport.destroy();
            await this.renderTable();
        },
        createdSuccess(){
          this.closeDialog();
          this.$emit('success')
        },
        currentDateSetter(){
        var MyDate = new Date();
        var date =MyDate.getFullYear()  + '-' + ('0' + (MyDate.getMonth()+1)).slice(-2) +'-'+ ('0' + MyDate.getDate()).slice(-2);
        this.form.doc_name = `Doc.${date}`;
      },

      async codeGeneratePartFormSuccess(value){
        const self = this;
        self.templateDropDown = value

         this.spreadsheetexport.destroy();
            await this.renderTable();

        
      },
      async codeGenerateTableFormSuccess(value){
        const self = this;

        self.areaData = value

         this.spreadsheetexport.destroy();
            await this.renderTable();

        
      },
      async codeGenerateCategoryFormSuccess(value){
        const self = this;

        self.catObjectArray = value

         this.spreadsheetexport.destroy();
            await this.renderTable();

        
      },
      openCodeGeneratorTableForm(){
        this.$refs.codeGeneratorTableForm.openDialog()
      }

  },


  beforeDestroy() {
        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2
    },
    beforeMount() {

        this.x = ((window.innerWidth) - this.width) / 2
        this.y = (window.innerHeight - this.height) / 2

    },

  computed: {
    ...mapGetters('CostingToolModule',['getLoading','formData','formStatus','putLoading']),
      tblKey : function(){
        return 1;
      }
    
  },

  watch: {
      'formData' : function(v){

        const self = this
        // this.form = {...v, items : valueData};
        this.form = v;

        
        this.CurrencySymbol = v.CurrencySymbol
        const data = [
          {item_code : "serial" , item_name : "barcode"}
        ]

      
           
      },

      getLoading : function(v){
        const self = this;
        if(v === false){


          this.$nextTick(()=>{

          // self.spreadsheetexport.setData(this.form.items)
        })


        }
      },


      'dialogIsOpen' : function(newState,old) {

       const self = this;
              if(!newState){
               this.spreadsheetexport.destroy();
                this.resetForm();
                return
              }else{
                this.renderTable();
                this.currentDateSetter();
              }
        },

        "form.ExchangeRate" : function(v){
           window['EXCHANGERATE'] = v;
            
        }


  }

}
</script>

<style scoped>
.click-to-add-logo{
  font-size: 14px;cursor: pointer;
}



.custom-control {
    position: absolute!important;
    margin: 10px!important;
}

.custom-control.image-delete{
  right: 0;cursor: pointer;
}

.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery{
  padding: 65px;
  text-align: center;
}

.no-image-gallery i.far {
  font-size: 42px;
  color: #959595;
}
a.discount-a{
  margin : 5px;
}

.tab-content{
  height: auto!important;
}

.search-fabric{
    position: absolute;
    width: 250px;
    z-index: 999;
    top: 0;
    left: 0;
    padding: 5px;
    background: #ddd;
    border-radius: 3px;
    margin: 0px 0 0 20px;
}


</style>