<template>
    <div class="component-container">

        <div class="toolbar-container">

            <template v-if="processing">
                <button class="btn btn-sm btn-elegant" :disabled="true"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
            </template>
            <template v-else>
                <button class="btn btn-sm btn-elegant" @click="childClick">Import</button>
                <button class="btn btn-sm btn-elegant" @click="clearData">Clear</button>
            </template>
        </div>

        <div id="app-vue-spreadsheet" :style="appVueSpreadSheet">
            <div ref="spreadsheetx"></div>
        </div>


        <hsc-window-style-metal>
            <hsc-window title="Price Update" :resizable="false" :left="0" :top="0" :height="height" style="z-index : 999!important;width:100%" class="export-window" :closeButton="true" :isOpen.sync="dialogIsOpen">
                <div class="toolbar-container">

                    <template v-if="exporDataSaving">
                        <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </template>
                    <template v-else>
                        <button class="btn btn-sm btn-elegant" @click="persistData" :disabled="isSave">Save</button>
                    </template>

                    <template v-if="isSave">
                        <button class="btn btn-sm btn-elegant" @click="downloadCsv">Download CSV</button>
                        <button class="btn btn-sm btn-elegant" @click="copyTable">Copy</button>
                    </template>
                    
                </div>
                <div class="window-export-container" :style="appVueSpreadSheet">

                   
                    <div ref="spreadsheetexport" class="demo-x"></div>
                </div>
            </hsc-window>
        </hsc-window-style-metal>

    </div>
</template>

<script>

import moment from 'moment';
import jspreadsheet from '../../jsexcel/jexcel'


import country from './country'
import brands from './brand'
import category from './category'
import collection from './collection'
import area from './area'


const dropdown = function(instance, cell, c, r, source) {
    // Get a value from the same row but previous column
    // var value = $(instance).jspreadsheet('getValue', c-1 + '-' + r);
   /*  const value = instance.getValue();

    console.log(value)
    console.log(cell)
    console.log(source) */

    const a = instance.jspreadsheet('getValue', c-1 + '-' + r);

    console.log(a)

    console.log(instance)
    console.log(cell)
    console.log(c)
    console.log(r)
    console.log(source)

    return ['Apples','Bananas','Oranges'];

  /*   // Return the values will be part in your current column
    if (value == 1) {
        return ['Apples','Bananas','Oranges'];
    } else if (value == 2) {
        return ['Carrots'];
    } else {
        return source;
    } */
}

const onChanged = function(v){
    console.log(v)
}

/* const filterArea = function(){
    instance, cell, c, r, source
}
 */

import {mapActions} from 'vuex'
export default {

    data: ()=> ({
        isSave : false,
        appVueSpreadSheet : {
            width : '1300px'
        },
        width : 1300,
        height : 1024,
        dialogIsOpen : false,
        spreadsheet: null,
        spreadsheetexport: null,
        processing: false,
        exporData : [],
        exporDataSaving : false,
        options : {
            tableOverflow: true,
            tableWidth: "400px",
            tableHeight: "400px",
            wordWrap : true,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            autoIncrement : true,
             data:[[]],
             minDimensions:[2,2],
             
             columns: [

                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'series',
                },

                {
                    type: 'text',
                    width: '150px',
                    title: 'LineNum',
                    name: 'line_num',
                },

                {
                    type: 'text',
                    width: '150px',
                    title: 'PriceList',
                    name: 'price_list',
                },

                 {
                   

                    type: 'numeric',
                    title:'Price',
                    mask:'#,##.00',
                    width:100,
                    decimal:'.',
                    name:'sale_price'
                },
                 {
                   
                    title:'Currency',
                    width:100,
                    name:'currency'
                },
               
            ],

            contextMenu: function(obj, x, y, e) {
                var items = [];
                return items;
             }
        },
        exportTableOptions : {
            download : true,
            csvFileName : 'serial-number',
            allowDeleteColumn : false,
            allowRenameColumn : false,
            csvHeader:true,
            tableWidth : '1300px',
            tableHeight : 'calc(100vh - 92px)',
            tableOverflow : true,
             data:[[]],
             minDimensions:[6,2],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                
                {
                    type: 'text',
                    width: '150px',
                    title: 'ItemCode',
                    name: 'series',
                    readOnly: true,
                    primaryKey: true
                },
               
                {
                    type: 'text',
                    width: '250px',
                    title:'Price',
                    name:'sale_price',
                    readOnly: true,
                },

                {
                    type: 'text',
                    width: '150px',
                    title: 'LineNum',
                    name: 'line_num',
                    readOnly: true,
                },

                {
                    type: 'text',
                    width: '150px',
                    title: 'PriceList',
                    name: 'price_list',
                    readOnly: true,
                },
                {
                    type: 'text',
                    width: '150px',
                    title: 'Currency',
                    name: 'currency',
                    readOnly: true,
                },
                 {
                    type: 'text',
                    width: '250px',
                    title:'Status',
                    name:'message',
                    readOnly: true,
                },
            ],

             contextMenu: function(obj, x, y, e) {
                // var items = [];

                /* if (obj.options.allowExport) {
                    items.push({
                        title: "Download",
                        shortcut: 'Ctrl + S',
                        onclick: function () {
                            obj.download(true);
                        }
                    });
                } */
                return false;
             }
        },

        itemForm : {
          item_code : 0,
          parent : 0,
          name : null,
          slug : null,
          sale_price : 0,
          promo_price : 0,
          dimension : null,

          brand : null,
          area : null,
          category : null,
          collection : null,

          item_type : 'stock',
          description : "",
          website : 1,
          attr : {
              cube_unit : "",
              attribute : "",
              post_views : 0,
              materials : "",
              materials1 : "",
              fabric : "",
              finishes : "",
              sub_colour : "",
              colour : "",
              colour1 : "",
              colour2 : "",

              future_stock : {
                qty : 0,
                eta_text : "",
                eta : []
              },

              dimension_height : "",
              dimension_width : "",
              dimension_depth : "",
              dimension_diameter : "",
              hole : "",
              capacity : "",
              weight : "",
              meta_title : "",
              meta_keyword : "",
              meta_description : "",
              visible_polish : false,
              barcode : "",
              old_code : "",
          },

          child: [],
          order_eta: [],

          image: [],
          images: [],
          location: [],

          qty : 0,
          status : '1'
          
        },
        brands : []
    }),
    components : {
  
       
    },
    mounted() {
        // this.options.tableWidth = `${window.innerHeight}px`
        const self =this;
        this.exportTableOptions.tableWidth = `${window.innerWidth}px`
        this.appVueSpreadSheet.width = `100%`
        this.options.tableWidth = `100%`
        this.options.tableHeight = `calc(100vh - 92.43px)`
        // this.options.onChange = onChanged
        this.spreadsheet = jspreadsheet(this.$refs.spreadsheetx, this.options);

        Object.assign(this, this.spreadsheet);

     /*    this.spreadsheet.onchange(function(v){
            alert(v)
        }) */

        
        
        this.height = window.innerHeight;
      /*   self.GetBrandErp().then(response => {
            const brand = [];
            response.forEach(element => {
              brand.push({"name": element.label, "id": element.value});

            });

            
            self.brands = brand
          });
        console.log("area")
           self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'area'}).then((response) => {
           console.log(JSON.parse(JSON.stringify(response)))
        })
        console.log("category")
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'category'}).then((response) => {
             console.log(JSON.parse(JSON.stringify(response)))
        }) */
       /*   console.log("collections")
        self.GetBrandRelationship({'show_in' : 'product', 'endpoint' : 'collections'}).then((response) => {
             console.log(JSON.parse(JSON.stringify(response)))
        }) */

    },
    methods : {
        /* ...mapActions('ItemModule',[
        'StoreRecord',
        'UpdateRecord',
        'GetNextCode',
        'GetBrandRelationship'
        ]),
        ...mapActions('BrandModule',['GetBrandErp','GetArea']), */
        ...mapActions('ItemModule',['PriceImport']),
        onCellChanged(v){
            console.log(v)
        },
       async childClick(){
            
            const self = this;

            if(this.processing === false){
                
                const tableData = this.spreadsheet.getJson();
                if(tableData.length > 0){

                    const filterData = tableData.filter(function(rowElement){
                        return (rowElement.series != "" && rowElement.sale_price != "");
                    })

                    if(filterData.length > 0){
                        self.processing = true
                            self.PriceImport({item : filterData}).then((response) => {
                                 self.processing = false
                                    console.log(response.data)

                                    self.exporData = response.data

                                    self.dialogIsOpen = true


                            });

                    }
                    
                }
    
                
            }
        },

        persistData(){
            const self =this;
            if(this.exporDataSaving == false){
                this.exporDataSaving = true;
                
                const jsonData = this.spreadsheetexport.getJson();

                // console.log(jsonData)

                const filterData = jsonData.filter(function(rowElement){
                        return (rowElement.item_code != "" && rowElement.item_name != "" && rowElement.origin != "" && rowElement.quantity != "" && rowElement.received_date != "" &&rowElement.vendor_code != "");
                    })
                
                this.storeRecord({"serial" : filterData}).then((response) => {
                              self.$ThatsNotify.success()
                              self.$emit('success',response);
                              self.exporDataSaving = false;
                              self.isSave = true;
                            }).catch((error) => {
                                self.exporDataSaving = false;
                                self.$emit('success',error.message);
                       });
            }
        },

        downloadCsv(){
            this.spreadsheetexport.download(true);
        },
        destroyx(){
            // this.spreadsheetexport.destroy();
            //this.exportTableOptions.allowCopy = true
            /*  this.spreadsheetexport = jspreadsheetx(this.$refs.spreadsheetexport, this.exportTableOptions);
    
            Object.assign(this, this.spreadsheetexport);
             this.spreadsheetexport.setWidth(window.innerWidth) */

             this.recreateSpreadsheet();
             this.exportTableOptions.allowCopy = true
             this.createPopupTable(this.exportTableOptions)
             
        },

        copyTable(){
            this.spreadsheetexport.copy()
        },

        createPopupTable(exportTableOptions){
            this.spreadsheetexport = jspreadsheet(this.$refs.spreadsheetexport, exportTableOptions);
            Object.assign(this, this.spreadsheetexport);
            this.spreadsheetexport.setWidth(window.innerWidth)
            this.spreadsheetexport.setData(this.exporData)
        },

        async recreateSpreadsheet(){
            await this.spreadsheetexport.destroy();
        },

        clearData(){
            this.spreadsheet.destroy();
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheetx, this.options);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)
            this.spreadsheet.setData([])
        }
    },
    computed: {
       
    },

    watch : {

        dialogIsOpen : function(v){
            if(v === false){
                // this.spreadsheetexport.setData([[]]);
                // this.spreadsheetexport = null;

                this.spreadsheetexport.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }
        }
    }

}
</script>