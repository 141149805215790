
<template>
  <div class="itmform-container">
      <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
        <XWindow ref="window" title="COUNTRY OF ORIGIN" windowClass="height-auto" scrollClass='import-invoice-form height-auto' :height="600" :width="1024" :formStatus="formStatus" @dialog-state="dialogState" :group="(group + 10)">
              <template #left-button>

                    <template v-if="postCounter < dataLength">
                        <button class="btn btn-sm btn-elegant" :disabled="true"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...</button>
                    </template>
                    <template v-else>
                 
                        <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm())" :disabled="processing">UPDATE</mdb-btn>
                      </template>


                      <mdb-dropdown multiLevel class="bulk-update-country-of-origin" end dropup>
                        <mdb-dropdown-toggle slot="toggle" color="elegant" size="sm">Print</mdb-dropdown-toggle>
                        <mdb-dropdown-menu >
                        <div class="px-2 py-2">
                            <label class="mt-1 mb-1 text-muted small">QR Code website redirection layout</label>
                            
                        </div>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(true,false)">Print 58x39</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(true,false)">Print 58x39 Discount</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(true,false)">Print 38x25</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(true,false)">Print 38x25 Discount</a>
                      
                        <div class="dropdown-divider"></div>

                        <div class="px-2 py-2">
                            <label class="mt-1 mb-1 text-muted small">QR Code Item Code</label>
                        </div>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(false,false)">Print 58x39</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(false,false)">Print 58x39 Discount</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(false,false)">Print 38x25</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(false,false)">Print 38x25 Discount</a>
                        <div class="dropdown-divider"></div>

                        <div class="px-2 py-2">
                            <label class="mt-1 mb-1 text-muted small">Logo</label>
                        </div>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabel(false,true)">Print 58x39</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelDiscount(false,true)">Print 58x39 Discount</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmall(false,true)">Print 38x25</a>
                        <a class="dropdown-item" href="javascript:void(0)" @click="printLabelSmallDiscount(false,true)">Print 38x25 Discount</a>
                        </mdb-dropdown-menu>
                    </mdb-dropdown>


                      <mdb-btn color="elegant" size="sm" @click="clearForm" :disabled="(postCounter < dataLength)">CLEAR</mdb-btn>



              </template>
            <div class="p-0 m-0 w-100">
              <div ref="spreadsheet"></div>
            </div>
        </XWindow>
      </ValidationObserver>

  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-prototype-builtins */

import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbIcon, mdbCard, mdbTabs, mdbTab, mdbTabItem, mdbTabContent, mdbTabPane, mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'


import { MessageBox } from 'element-ui';
import XWindow from '../../components/thats/XWindow.vue'
import jspreadsheet from '../../jsexcel/jexcel'
export default {
  props : {
    group : {
      type : Number,
      default : 2
    }
  },

    components: {

        mdbContainer,
        mdbBtn,
        mdbRow,
        mdbCol,  
        mdbIcon,
        mdbCard,
        mdbTabs,
        mdbTab,
        mdbTabItem,
        mdbTabContent,
        mdbTabPane,
        mdbDropdown,mdbDropdownToggle,mdbDropdownMenu,mdbDropdownItem,
        ValidationObserver,
  
        XWindow
  },
    
    data : () => ({
      postCounter : 0,
      dataLength : 0,
      validated : false,
      processing : false,
      formStatus : 'create',
      spreadsheet: null,
      exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'auto',
            tableOverflow : true,
             data:[[]],
             minDimensions:[3,20],
             onCopy : null,
             selectionCopy : false,
             allowCopy : true,
             columns: [
                
                {
                    type: 'text',
                    width: '200px',
                    title: 'Series',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'English',
                    name: 'english',
                    readOnly: false,
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Arabic',
                    name: 'arabic',
                    readOnly: false,
                },

                {
                    type: 'text',
                    width: '200px',
                    title: 'Status',
                    name: 'status',
                    
                },
                {
                    type: 'text',
                    width: '340px',
                    title: 'Response',
                    name: 'message',
                },
                
              
            ],

             contextMenu: function(obj, x, y, e) {

                return false;
             }
        },
    }),

    created : function() {
     
    },
    updated(){
      this.toggleCheck()
    },


    methods: {
      ...mapActions('BarcodeModule',['updateCountryOfOrigin']),

      formResetter(){

      },

      onSubmitForm(){
        const self = this;
        if(this.processing === false){
            const tableData = this.spreadsheet.getJson();


            MessageBox.confirm('Existing Country of Origin will be overwrite. Do you want to continue?', 'Warning', {
                  confirmButtonText: 'OK',
                  cancelButtonText: 'Cancel',
                  type: 'warning'
                  }).then(() => {
                    if(tableData.length > 0){
              
                        this.postCounter = 0
                        const filterStatus = [];
                        this.dataLength = tableData.filter((item) => item.series !== '' && !filterStatus.includes(item.message),[]).length;

                        if(this.dataLength > 0){

                          self.processing = true
                              tableData.forEach((element, forIndex) => {
                                    const row = 1 + forIndex
                                    console.log("tableData" + row)
                                    if(element.series !== '' && !filterStatus.includes(element.message)){
                                      self.processing = true;
                                      self.spreadsheet.setValue(`D${row}`,'Loading...');
                                    
                                      this.updateCountryOfOrigin({series : element.series, index : row, attr : {collection : {en : element.english,ar : element.arabic}}}).then((response) => {
                
                                                const i = parseInt(response.index);
                
                                                self.spreadsheet.setStyle(`A${i}`,'background-color','#00c851');
                                                self.spreadsheet.setStyle(`B${i}`,'background-color','#00c851');
                                                self.spreadsheet.setStyle(`C${i}`,'background-color','#00c851');
                                                self.spreadsheet.setStyle(`D${i}`,'background-color','#00c851');
                                                self.spreadsheet.setStyle(`E${i}`,'background-color','#00c851');
                                                self.spreadsheet.setValue(`D${i}`,'Success...');
                                                self.spreadsheet.setValue(`E${i}`,response.message);
                                                self.processing = false
                                                this.postCounter++
                                              }).catch((error) => {
                
                                                  const i = parseInt(error.response.data.index)
                                                  self.spreadsheet.setValue(`E${i}`,'Failed...');
                                                  self.spreadsheet.setValue(`D${i}`,error.response.data.message);
                                                  self.spreadsheet.setStyle(`A${i}`,'background-color','#fb3');
                                                  self.spreadsheet.setStyle(`B${i}`,'background-color','#fb3');
                                                  self.spreadsheet.setStyle(`C${i}`,'background-color','#fb3');
                                                  self.spreadsheet.setStyle(`D${i}`,'background-color','#fb3');
                                                  self.spreadsheet.setStyle(`E${i}`,'background-color','#fb3');
                                                  self.processing = false
                                                  this.postCounter++
                
                                              });
                                            }
                                          
                                  });
                        


                                }

                          
                        }
                  })


       
            






               /*  const filterData = tableData.filter(function(rowElement){
                    return rowElement.series != "";
                })

                if(filterData.length > 0){
                    console.log(filterData)
                } */
            
        }
      },

      openDialog(){
        this.$refs.window.openDialog();
      },

      createPopupTable(exportTableOptions){
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setWidth(window.innerWidth)
            this.spreadsheet.setData([])
        },

        async recreateSpreadsheet(){
            await this.spreadsheet.destroy();
        },

        dialogState(state){
        this.active = 0;

        if(!state){
          this.formResetter();
          this.spreadsheet.destroy();
          return
        }

        if(this.formStatus == 'create'){
          this.formResetter();
          this.createPopupTable(this.exportTableOptions)   
        }

        this.processing = false
        
      },

      clearForm(){
          this.recreateSpreadsheet();
          this.createPopupTable(this.exportTableOptions);
      },

      printLabel(obj = false,logo = false){

        const tableData = this.spreadsheet.getJson();
        const tableContent = tableData.filter((item) => item.series !== '',[]).map(item => item.series);


        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print?items=${tableContent}&redirection=${obj}&from=bulk&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelDiscount(obj = false,logo = false){

          const tableData = this.spreadsheet.getJson();
        const tableContent = tableData.filter((item) => item.series !== '',[]).map(item => item.series);
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-discount?items=${tableContent}&redirection=${obj}&from=bulk&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelSmallDiscount(obj = false,logo = false){

          const tableData = this.spreadsheet.getJson();
        const tableContent = tableData.filter((item) => item.series !== '',[]).map(item => item.series);

        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-small-discount?items=${tableContent}&redirection=${obj}&from=bulk&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },
        printLabelSmall(obj = false,logo = false){

          const tableData = this.spreadsheet.getJson();
        const tableContent = tableData.filter((item) => item.series !== '',[]).map(item => item.series);
        var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";

        const win = window.open(`/barcode/multiple-print-small?items=${tableContent}&redirection=${obj}&from=bulk&logo=${logo}`, "_blank", strWindowFeatures);
            win.document.title = 'Print';
            win.print()
      },

    },

    computed: {

      
    },

    watch : {

        dialogIsOpen : function(v){
            if(v === false){
  
                this.spreadsheet.destroy();
            }else{
                this.createPopupTable(this.exportTableOptions)                 
            }

            
        }

        
    }


}
</script>
<style lang="scss" scoped>

</style>