
<template>
    <div class="customervendor-container">
        <ThatsTable title="Contacts Details" ref="table" :endpoint="endpoint" @openModal="openWindow" @deleteYes="DispatchDelete" @openUpdateModal="OpenUpdateModal" :columns="columns" ></ThatsTable>
        <ContactsForm ref="dialogForm" @success="refreshTable" @delete="formDelete"></ContactsForm>
        <ContactsUpdate ref="dashboardForm" @success="refreshTable" @delete="formDelete" @open="openDocument"></ContactsUpdate>

        <TableImport @import="importNow" :data="importData" ref="tableImport" :group="2" :loading="importLoading"></TableImport>
        <TableError :data="errorData" ref="tableError" :group="2"></TableError>
        <TransactionForm ref="transactionForm" :group="(20000)"></TransactionForm>
        <InvoiceForm ref="invoiceForm" :group="(20000)" ></InvoiceForm>
    </div>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from 'vuex';
import ContactsForm from './ContactsForm'
import TableImport from '../../components/filemanager/TableImport'
import TableError from '../../components/filemanager/TableError'
import ThatsTable from '../../components/thats/Table'
import ContactsUpdate from "./ContactsUpdate";
import TransactionForm from '../transaction/TransactionForm.vue'
import InvoiceForm from '../transaction/InvoiceForm.vue'
export default {
    data(){
        return {
            endpoint : 'contacts/data',
            importData : {},
            errorData : {},
            importLoading : false,
        }
    },
    components: {
        ContactsUpdate,
        ContactsForm,
        ThatsTable,
        TableImport,
        TableError,
        TransactionForm,
        InvoiceForm
    },

    methods: {
        ...mapMutations('InvoiceModule',{
            invoiceSetRecord : 'setFormRecord',
            invoiceStatusUpdate : 'setFormStatusUpdate',
            invoiceSetTblKey : 'setTblKey'
        }),
        ...mapActions('InvoiceModule',{
            invoiceGetRecordSeries : 'getRecordSeries',
        }),
        ...mapActions('TransactionModule',{
            quotationGetRecord : 'getRecord'
        }),
        
        ...mapMutations('TransactionModule',{
            quotationSetFormRecord : 'setFormRecord', 
            quotationSetFormStatusUpdate : 'setFormStatusUpdate',
            quotationSetTblKey : 'setTblKey'
        }), 
        ...mapActions('ContactsModule',[ 'DeleteRecord','saveImport']),
        ...mapMutations('ContactsModule',[ 'SetFormRecord', 'SetFormStatusUpdate']),

        formDelete(record){
            const items = [];
            items.push(record.row);
            this.DispatchDelete(items);
        },
        DispatchDelete(items){
            var self = this;
            self.DeleteRecord(items).then((response) => {
                self.$ThatsNotify.success({
                    message : `(${response.deleted}) record was successfully deleted.`
                })
               self.refreshTable();
            })

        },
        OpenUpdateModal(obj){
            this.SetFormStatusUpdate()
            this.SetFormRecord(obj)
            // this.openWindow()
            this.$refs.dashboardForm.openDialog()
        },
        openWindow(){
            this.$refs.dialogForm.openDialog()
        },
        closeWindow(){
            this.$refs.dialogForm.closeDialog()
        },
        refreshTable(){
            this.$refs.table.refreshTable()
        },
        openUploadWindow(){
            this.$refs.uploadForm.openDialog()
        },
        fileData(v){
            this.importData = v
           this.$refs.tableImport.openDialog()
        },
        importNow(v){
            if(!this.importLoading){
                this.importLoading = true
                this.saveImport({ row : v}).then((response) => {

                    this.importLoading = false,
                    this.$ThatsNotify.success({
                        message : response.message,
                        timeOut : 10000
                    })
                    this.$refs.tableImport.closeDialog();
                    this.$refs.table.refreshTable()

                    if(response.error.rows.length > 0){
                        this.errorData = response.error
                        this.$refs.tableError.openDialog()
                    }

                    return;

                }).catch((error) => {
                    this.importLoading = false,
                    this.$ThatsNotify.warning({
                        message : `Please check file might some column of record has invalid value.`,
                        timeOut : 10000
                    })
                    this.$refs.table.refreshTable()
                })
            }

        },

        openDocument(v){
            console.log("openDocument")
            console.log(v)

            const regex = /^[A-Z]+-\d{6}-\d{3}$/;
            if(regex.test(v.value.series)){
                this.openQuotation(v)
            }else{
                this.openInvoice(v.value)
            }
        },
      openQuotation(v){
        //   console.log(v)
        const self = this;
        const form  = {...v.value,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    showroom : {
                                location : null,
                                prefix : null
                            },
                    credit_notes : [],
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    }
                    } 
                    self.quotationSetFormStatusUpdate()
                    self.quotationSetFormRecord(form)
                    self.quotationGetRecord({token_id : v.value.token_id})
                    self.quotationSetTblKey(this.transactionTblKey + 1);
                    self.$refs.transactionForm.openDialog();
      },
      


      openInvoice(obj){
        const self = this;
       const form  = {...obj,items : [],attachment : [],invoice : {
                        series : null,
                        date : "",
                        balance : 0,
                        paid : 0,
                        attachment : [],
                        payments : [],
                    },
                    credit_note : {
                        total : 0,
                        status : null,
                        credit_note_reference : []
                    },
                    creditnotes : [],
                    credit_note_items : [],
                    cancellation : [],
                    cancellation_item : [],
                    refund : [],
                    refund_item : [],
                    exchange : [],
                    credit_memo : [],
                    exchange_item : [],
                    enable_push : false,
                    location : null,
                    } 


                    if(!form.attr.hasOwnProperty('corporate')){
                            form.attr.corporate = false
                        }
                        if(!form.attr.hasOwnProperty('contact_person')){
                            form.attr.contact_person = {
                                name : null,
                                mobile : null,
                                attr : {
                  
                                  address: null,
                                  address2 : {
                                      street : null,
                                      building : null,
                                      zone : null,
                                      area : null,
                                      city : null,
                                  },
                                  designation : null,
                                  email : null,
                                  first_name : null,
                                  middle_name : null,
                                  last_name : null,
                                  salutation : null
                                }
                              }
                        }
                    self.invoiceStatusUpdate()
                    self.invoiceSetRecord(form)
                    self.invoiceGetRecordSeries({token_id : obj.token_id, series : obj.series})
                    this.invoiceSetTblKey(this.salesTblKey + 1);
                    this.$refs.invoiceForm.openDialog();
      },

    },
    computed: {
        ...mapGetters('ContactsModule',[ 'data', 'page', 'total', 'page', 'length', 'columns', 'Loading', ]),
    },
}
</script>
<style scoped>
.container {
    position: relative;
    min-height: 340px;
  }
  .placement {
    position: absolute;
    right: 0;
  }
</style>
