<template>
    <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
        <el-dialog title="Issue Loyalty Card" :visible.sync="dialogIsOpen" :close-on-click-modal="false" width="30%" height="40%" size="small" class="text-left loyalty-transaction-form" :show-close="!formLoading">
            <div class="dialog-scroll-container p-1 pt-0">

                            <mdb-row>
                                <mdb-col md="12">
                                    <div class="mt-2">
                                        <!-- Display Card Details -->
                                        <mdb-card class="classic-admin-card mt-1">
                                            <mdb-card-body class="d-flex justify-content-start align-items-center p-2 pl-3">
                                                <div>
                                                    <img v-if="filteredCards && filteredCards.attr && filteredCards.attr.images" :src="filteredCards.attr.images" class="loyalty-card-image pl-3"/>
                                                </div>
                                                <div class="text-left flex-grow-1 pr-3 pl-3 pt-2 pb-2 ">
                                                    <strong>{{ filteredCards.name }}</strong> <br />
                                                    <strong>{{ filteredCards.description }}</strong>
                                                </div>
                                            </mdb-card-body>
                                        </mdb-card>
                                    </div>
                                    <!-- Edit Card Information -->
                                    <mdb-card class="mt-3">
                                        <mdb-card-body>
                                            <ThatsInput labelClass="col-md-3" label="Card Number" placeholder="Card Number" fieldClass="col-md-9"  vid="card.card_number" name="card.card_number" :show_error="true" :showLabel="true" v-model="form.card.card_number" :rules="'required'" size="sm" />
                                            <ThatsInput labelClass="col-md-3" label="Display Name" placeholder="Display Name" fieldClass="col-md-9"  vid="display_name" name="display_name" :show_error="true" :showLabel="true" v-model="form.card.display_name" :rules="'required'" size="sm" />
                                        </mdb-card-body>
                                    </mdb-card>
                                    <!-- Buttons -->
                                    <mdb-form-inline class="d-flex justify-content-between align-items-center mt-2" tag="div">
                                        <template>
                                            <mdb-btn color="elegant" size="sm" @click="closeEditDialog">Close</mdb-btn>
                                            <template>
                                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
                                            </template>
                                        </template>
                                    </mdb-form-inline>
                                </mdb-col>
                            </mdb-row>

            </div>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import {mdbBtn, mdbIcon, mdbCard, mdbView, mdbCardBody, mdbCol, mdbRow, mdbFormInline} from "mdbvue";
import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import { ThatsInput,ThatsTextarea,ThatsMultiSelect,ThatsDatePicker } from '../../components/thats/Thats'



export default {
    components: {
        mdbFormInline, mdbRow, mdbCol, mdbCardBody,
        ThatsDatePicker,
        ThatsMultiSelect,
        mdbBtn,
        mdbIcon,mdbCard,mdbView,
        ValidationObserver,
        ThatsInput,
        ThatsTextarea,
    },
    props : {
        group : {
            type : Number,
            default : 1
        },



    },

    data : () => ({
        syncModal : false,
        tableKey : 0,
        group2 : 5,
        formData : null,
        formLoading : false,
        readOnly : false,
        showError : true,
        currentStep : 0,
        searchCustomer : null,
        filteredCards : [],
        cards : [],
        dialogIsOpen : false,
        validated : false,
        form : null,
        response : {
            error : null,
            message : null
        },
        required:true
    }),

    created : function() {
        var self = this;

        if(this.FormStatus == 'create'){
            this.form = this.formVar();
        }

    },
    methods: {
        ...mapActions('LoyaltyCustomerCardUpgradeModule',[
            'UpdateLoyaltyCardRecord'
        ]),
        ...mapMutations('LoyaltyCustomerCardUpgradeModule',[
            'SetFormStatusCreate','SetFormRecord'
        ]),
        ...mapMutations('LoyaltyMemberModule',['cancelRequest','setFormStatusCreate']),

        ...mapActions('LoyaltyCardModule',['getLoyaltyCards']),
        ...mapActions('TireModule',['getTiers']),

        ...mapMutations('HistoryModule',[
            'setModule',
            'setRecordId',
        ]),

        formVar(){
            return {
                card:{
                    card_number:"",
                    previous_card_number:"",
                    display_name:"",
                    customer_id:"",
                    card_id:"",
                    expiration_date:"",
                    issued_date:"",
                    attr:Object,
                    id:""
                },

            }
        },
        /* start */
        closeEditDialog(){
            this.dialogIsOpen = false
            this.form = this.formVar()
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
        },


        onSearch(v){
            this.$emit('onSearch',v)
        },

        resetDefaultTab(){
            this.activeTabIndex = 0,
                this.activeTabObject = { text: 'Details', tabSlot : 'main' }
        },
        getActiveTab(v){
            this.activeTabIndex = v
            this.activeTabObject = this.mdbTabsData[v];
        },

        /* end */
        openUpdateDialog(formData){
            this.dialogIsOpen = true
            console.log(formData)
            this.form.card.display_name = formData.card.display_name
            this.form.card.attr = formData.current_tier.card.attr
            this.form.card.previous_card_number = formData.card_number
            this.form.card.customer_id = formData.card.customer_id
            this.form.card.card_id = formData.current_tier.card_id
            this.form.card.expiration_date = formData.card.expiration_date
            this.form.card.issued_date = formData.card.issued_date
            this.form.card.id = formData.card.id

            this.filteredCards = formData.current_tier.card

        },
        closeDialog(){
            this.dialogIsOpen = false;
        },
        onSubmitForm(){
            const self = this;
            this.$refs.observer.validate().then((isValid) => {

                if(isValid){
                        const storeData = {...self.form.card}
                        self.UpdateLoyaltyCardRecord(storeData).then((response) => {
                            self.$ThatsNotify.success()
                            self.$emit('success',response)
                            self.closeDialog();
                            self.formLoading = false
                            self.FormResetter();

                        }).catch((error) => {
                            self.formLoading = false
                            if (error.response) {
                                if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                    this.showError = true
                                }
                                if(error.response.data.hasOwnProperty('message')){
                                    self.$ThatsNotify.error({
                                        message : `${error.response.data.message}`
                                    })
                                }



                            } else if (error.request) {
                                self.response.message = error.request
                            } else {
                                self.response.message = error.message
                            }
                            return;
                        })



                }
            })
        },







        openHistoryDialog(){
            this.setModule('item')
            this.setRecordId(this.formData.mdbID)
            this.$refs.historyDialog.openDialog();
        },

        backToPrevious(){
            this.currentStep--
        },
        FormResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.SetFormStatusCreate();
            this.showError = false
        },

        dialogState(state){
            // this.optionsHandler();
            if(!state){
                this.FormResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.FormResetter();
            }
        },
        emitDelete(){
            this.$emit('delete',this.FormRecord)
        },

        clearLocation(){
            this.form.attr.location = []
        }

    },

    computed: {
        ...mapGetters('LoyaltyCustomerCardUpgradeModule',
            [
                'FormStatus',
                'FormRecord',
            ]),
        ...mapGetters('LoyaltyMemberModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
            formStatus : 'formStatus',
        }),

        activeTabSlot : function(){
            return this.activeTabObject.tabSlot
        },
    },

    watch: {
        FormRecord: function() {
            if (this.FormStatus == 'update') {
                const frm = JSON.parse(JSON.stringify(this.FormRecord));

                frm.attr = {...this.form.attr, ...frm.attr}
                this.getImage = frm.attr.images;
                this.form = {...this.form, ...frm}
            }
            if (this.FormStatus == 'create') {
                this.FormResetter();
            }

        },
        dialogIsOpen: function(newDialogIsOpen) {
            if (!newDialogIsOpen) {
                this.closeEditDialog()
            }
        },
        syncModal : function(value){
            const self = this;
            if(!value){
                this.cancelRequest();
                this.resetDefaultTab();
                this.formData = JSON.parse(JSON.stringify(this.formObject))
                this.tableKey++
            }

            if(value){
                this.getLoyaltyCards().then((res) => {
                    self.cards = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
                this.getTiers().then((res) => {
                    self.tiers = res.map((item) => {
                        return {...item,selected : false}
                    })
                })
            }
            self.currentStep = 0;
            self.customers = [];
        },

        formRecord : function(value){
            this.formData = value

        },

    },
}
</script>

<style lang="scss">

.bts-form-tabs{
    .nav-tabs{
        margin-left: 10px;border-bottom: none!important;
        .nav-item{
            a.active{
                border-bottom: 1px solid #0056b3;
            }
        }
    }

    .tab-content{
        height: auto!important;border-top:1px solid #dee2e6;
    }
}



.avatar-sm {
    width: 56px;
    border-radius: 50%;
}
.chat-message-type {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 0 auto !important;
    -ms-flex: 1 0 auto !important;
    flex: 1 0 auto !important;
}

.profile-card {
    margin-top: 100px;
}

.profile-card .avatar {
    max-width: 150px;
    max-height: 150px;
    margin: -70px auto 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}

.btn-floating i {
    color: #4f4f4f !important;
}

.loyalty-card-image{
    width: 120px;
}
</style>
