<template>
    <div>
         <ThatsTable title="Local Purchase Delivery Note" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="true" @open="openNewModal" @row-click="rowClick" :key="tableKey" :filterFormData="filterForm" @clearFilterForm="clearFilterForm" @onSearchTerm="onSearchTerm">  
            <template #filter="{}">
                
                
              

                <ThatsInput tabindex="2" labelClass="col-sm-3" fieldClass="col-sm-9 pl-0" label="kerwords" vid="searchTerm" name="searchTerm" v-model="filterForm.searchTerm" size="sm" :disabled="false" :rules="''"/>
                <ThatsDatePicker :show_error="false" tabindex="1" labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Doc Date" placeholder="Date" vid="date" name="date" v-model="filterForm.date" :rules="''" size="sm" :disabled="false"/>                  
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Shipment Status" v-model="filterForm.shipment_status" :reduce="label => label.value" :reduceLabel="'label'" :options="shipmentDocStatus" :rules="''"></ThatsMultiSelect>
                <ThatsMultiSelect :show_error="true" :multiple="true" :clearable="true"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Status" v-model="filterForm.status" :reduce="label => label.value" :reduceLabel="'label'" :options="docStatus" :rules="''"></ThatsMultiSelect>

     
            </template> 

            <template #custom-right-culumns="{}">

                <el-table-column :sortable="false" :prop="'id'" label="Vendor">
                    <template slot-scope="scope">
                        
                      {{ scope.row.vendor.series }} 
                    
                    </template>
                </el-table-column>

                <el-table-column :sortable="false" :prop="'description'" label="CONTAINER NUMBER"  width="200">
                    <template slot-scope="scope">
                        
                      {{ scope.row.container_number }}
                    </template>
                </el-table-column>
            
                <el-table-column :sortable="false" :prop="'description'" label="ETA"  width="150">
                    <template slot-scope="scope">
                        
                      {{ scope.row.attr.shipment_eta }}
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" :prop="'description'" label="Shipment Status"  width="200">
                    <template slot-scope="scope">
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.attr.shipment_status]"><i :class="statusArrayIcon[scope.row.attr.shipment_status]"></i> {{ documentStatus[scope.row.attr.shipment_status] }}</mdb-badge>
                      
                    </template>
                </el-table-column>
                <el-table-column :sortable="false" :prop="'id'" label="Status" width="150">
                    <template slot-scope="scope">
                        
                        <mdb-badge class="custom-badge z-depth-0" pill :color="statusArrayColor[scope.row.status]"><i :class="statusArrayIcon[scope.row.status]"></i> {{ scope.row.status_text}}</mdb-badge>
                        
                    </template>
                </el-table-column>
             <!--    <el-table-column :sortable="false" width="120" :prop="'id'" label="PRINT">
                        <template slot-scope="scope">
                            <el-button @click="fnPrintDoc('/inventory-transfer-request/print/'+scope.row.series)" size="small" class="text-info"><i class="el-icon-printer text-info cursor-pointer"></i> PRINT</el-button>
                        </template>
                    </el-table-column> -->

            </template>

        </ThatsTable>
        <GoodsReceiptNoteForm ref="goodsReceiptNoteForm" @success="refreshTable"></GoodsReceiptNoteForm>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput,mdbBadge} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect, ThatsInput} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";

import { Loading } from 'element-ui';
import GoodsReceiptNoteForm from './GoodsReceiptNoteForm.vue'

export default {
        components:{
        ThatsTable,
        mdbTabs,
        mdbBadge,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,GoodsReceiptNoteForm,
        mdbSelect,
        mdbInput,
        ThatsInput
    },
    inject : ['showroomsProvider'],
    provide(){
        return {
            btsApproverProvider : () => this.approverData,
            btsReceiverProvider : () => this.receiverData,
        }
    },
    data(){
        return {
            endpoint : 'goods-receipt-note/data',
            approver : [],
            receiver : [],
            tableKey : 0,
            filterForm : {
                date : '',
                searchTerm : null,
                shipment_status : null,
                status : null,
            },
            shipmentDocStatus : [
                { label: 'In Shipment', value: 'S' },
                { label: 'In Port', value: 'P' },
                { label: 'In Warehouse', value: 'W' },
                { label: 'Received', value: 'R' },
              ],
            docStatus : [
                {value : 'open', label : 'Open'},
                {value : 'closed', label : 'Closed'},
                {value : 'canceled', label : 'Canceled'},
            ]
        }
    },


    created(){

      const self = this;
 
    },

    mounted(){
        const self = this;

        // console.log("onmounted")
        this.$eventBus.$on('reloadTable',function(){
            self.tableKey++
            self.setFilters();
        })
    },
    methods: {

       ...mapActions('GoodsReceiptNoteModule',['getRecord','findGoodsReceiptNote']),
       ...mapMutations('GoodsReceiptNoteModule',['setFormStatusUpdate','setFormStatusCreate','setEmptyForm','setFilters']),

        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                self.setFormStatusUpdate(); 
            this.getRecord(row).then(() => {
                loading.close();
                
                self.$refs.goodsReceiptNoteForm.openDialog();
            })
        },
        openNewModal(){
           /*  this.setFormStatusCreate();
            this.setEmptyForm();
           this.$refs.goodsReceiptNoteForm.openDialog(); */


           MessageBox.prompt('Enter Goods Receipt Note Document number', 'Tip', {
            title : "New Document",
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            closeOnClickModal : false,
            inputValidator : function(value){
                if(value === null || value == ""){
                    return false
                }
                return true
            },
            inputErrorMessage: 'Document number is required'
            }).then(({ value }) => {
                // alert(value)
                this.getGoodsReceiptNote(value)
            })

            
        },

        getGoodsReceiptNote(DocNum){
            const self = this
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                }); 
                this.setFormStatusCreate();
                this.setEmptyForm();
                this.findGoodsReceiptNote({docNum : DocNum}).then((res) => {
                    // console.log(res)
                    loading.close();
                    self.$refs.goodsReceiptNoteForm.openDialog();
                }).catch((error) => {
                    console.log(error.response)
                    loading.close();
                    if (error.response) {
                        if(error.response.data.hasOwnProperty('message')){
                            self.$ThatsNotify.error({
                                message : error.response.data.message,
                                timeOut: 30000
                              })
                        }
                    } 
                })
        },

        refreshTable(){
            // //alert(1)
            this.tableKey++
            this.$refs.table.refreshTable()
           
        },

        fnPrintDoc(url){
        // this.printUrl = url
          const windowHeight =  window.innerHeight;
          const innerWidth =  window.innerWidth;

         var strWindowFeatures = "location=yes,height="+windowHeight+",width="+innerWidth+",scrollbars=yes,status=yes";
        const win = window.open(`${url}`, "_blank", strWindowFeatures);
        win.document.title = 'Print';
        win.print()

        // this.$refs.printWindow.openDialog();
      },

      clearFilterForm(){
        this.filterForm = JSON.parse(JSON.stringify(
            {
                date : '',
                searchTerm : null,
                shipment_status : null,
                status : null,
            }
        ))
      },

      onSearchTerm(searchTerm){
        this.filterForm.searchTerm = searchTerm
      }


      
    },
    computed: {
        ...mapGetters('GoodsReceiptNoteModule',['getLoading', 'columns','initialForm']),
        approverData : function(){
            return this.approver
        },
        receiverData : function(){
            return this.receiver
        },

        statusArrayText : function(){
        
            return {'draft' : 'Draft','open' : 'Open','closed': 'Closed'};
        },
        statusArrayColor : function(){
        
            return {'draft' : 'badge-light','for-approval' : 'warning','S' : 'info','P' : 'warning','W' : 'info','R' : 'success','open': 'success','canceled' : 'danger','closed' : 'danger'};
        },
        statusArrayIcon : function(){
        
            return {'draft' : 'el-icon-more','S' : 'el-icon-ship','P' : 'el-icon-circle-check','open': 'el-icon-success','W': 'el-icon-s-home','R': 'el-icon-success','canceled' : 'el-icon-remove','closed' : 'el-icon-remove'};
        },

        showroomLocation : function(){

            const showroom = this.showroomsProvider();

            return showroom.map((item) => {
                return {...item, label : `${item.prefix} - ${item.label}`,prefix : item.prefix}
            },[])
        },
        documentApprover : function(){

            return this.approver.map((item) => {
                return {...item, label : `${item.name}`,value : item.id}
            },[])
        },
        documentReceiver : function(){
            return this.receiver.filter((user) => {
                return ['warehouse','delivery-team','showroom-manager','interior-designers','back-office','administrator'].some(item => user.role_slugs.includes(item)) 
            },[]).map((item) => {
                return {...item, label : `${item.name}`,value : item.id}
                },[])
        },

        documentStatus : function(){
            return {'S' : 'In Shipment','P' : 'In Port','R' :'Received', 'W' : 'In Warehouse' }
        }
    },
}


</script>