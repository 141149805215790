import {ApiHttpService} from '../../services'
import {Unauthorized} from '../../helpers/unauthorized.handler'
import router from "../../router";
export const TireModule = {
    namespaced: true,
    state: {
        loading: false,
        Deleteloading: false,
        data : {
            rows: [],
        },
        formStatus : 'create',
        endpoint : 'loyalty-program/tiers',
        formRecord : {},
        length : 25,
        page : 0,
        total : 1,

    },
    actions : {
        async asyncGetData({ rootState, commit }) {
            const { loading, length, page, filters, searchTerm, endpoint} = rootState.TireModule
            return await new Promise((resolve) => {
                if (loading === false) {
                    commit('LoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    const configParams = {
                        req: "TireModule/req",
                        loadingTrue: "TireModule/loadingTrueQuotation",
                        addRequest: "TireModule/addRequest",
                        cancelRequest: "TireModule/cancelRequest",
                        clearOldRequest: "TireModule/clearOldRequest"
                    };
                    ApiHttpService.get(`${endpoint}`, params,configParams).then((response) => {
                        commit('SetPage', response.data.current_page)
                        commit('SetTotal', response.data.total)
                        commit('SetData', response.data.data)
                        commit('LoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        reject(error)
                        // Unauthorized(dispatch,error)
                    })
                }
            })
        },

        // async getTiers({state}) {
        //     const { endpoint} = state
        //     return await new Promise((resolve,reject) => {
        //         ApiHttpService.getRequest(`${endpoint}/filter`).then((response) => {
        //             resolve(response.data)
        //         }).catch(error => {
        //             reject(error)
        //         })
        //     })
        // },

        async getTiers({ rootState, commit }) {
            const { endpoint} = rootState.TireModule

            return await new Promise((resolve, reject) => {
                commit('LoadingTrue')
                // commit('SetFormStatusUpdate');

                const params = {}
                const configParams = {
                    req: "TireModule/req",
                    loadingTrue: "TireModule/loadingTrueQuotation",
                    addRequest: "TireModule/addRequest",
                    cancelRequest: "TireModule/cancelRequest",
                    clearOldRequest: "TireModule/clearOldRequest"
                };

                ApiHttpService.get(`${endpoint}/filter`, params,configParams)
                    .then((response) => {
                        const data = response.data;
                        commit('SetFormRecord', data);
                        commit('LoadingFalse');
                        resolve(response.data);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        commit('LoadingFalse');
                        reject(error);
                    });
            });
        },

        async getLoyaltyById({ rootState, commit }, obj) {
            const { endpoint} = rootState.TireModule

            return await new Promise((resolve, reject) => {
                commit('LoadingTrue')
                // commit('SetFormStatusUpdate');

                const params = {}
                const configParams = {
                    req: "TireModule/req",
                    loadingTrue: "TireModule/loadingTrueQuotation",
                    addRequest: "TireModule/addRequest",
                    cancelRequest: "TireModule/cancelRequest",
                    clearOldRequest: "TireModule/clearOldRequest"
                };

                ApiHttpService.get(`${endpoint}/` + obj, params,configParams)
                    .then((response) => {
                            const data = response.data;
                            commit('SetFormRecord', data);
                            commit('LoadingFalse');
                            resolve(response.data);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        commit('LoadingFalse');
                        reject(error);
                    });
            });
        },

        StoreRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint} = rootState.TireModule;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiHttpService.store(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        UpdateRecord({ rootState,commit},payload){
            var self = this
            return new Promise((resolve, reject) => {
                const { loading, endpoint } = rootState.TireModule;

                if (!loading) {
                    commit('LoadingTrue')
                    console.log('swaraj bhuyan update form')
                    ApiHttpService.update(`${endpoint}`,payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },

        async DeleteRecord({ rootState,commit},payload){
            var self = this
            return await new Promise((resolve, reject) => {
                const { endpoint, Deleteloading } = rootState.TireModule;
                if (!Deleteloading) {
                    commit('DeleteloadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    ApiHttpService.delete(`${endpoint}`,tokens).then((response) => {
                        commit('DeleteloadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('DeleteloadingFalse')
                        reject(error)
                    })
                }
            })
        },



    },
    mutations: {
        LoadingFalse: (state) => {
            state.loading = false
        },
        LoadingTrue : (state) => {
            state.loading = true
        },
        DeleteloadingFalse: (state) => {
            state.Deleteloading = false
        },
        DeleteloadingTrue : (state) => {
            state.Deleteloading = true
        },
        SetData(state, payload){
            state.data.rows = payload
        },

        SetFormStatusCreate: (state)=>{
            state.formStatus = 'create'
        },

        SetFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        SetFormRecord: (state,payload) => {
            state.formRecord = payload
        },
        SetPage: (state, payload)=>{
            state.page = payload
        },
        SetTotal: (state, payload)=>{
            state.total = payload
        },
        SetLength: (state, payload)=>{
            state.length = payload
        },
        clearOldRequest(state, message) {
            // state.request.msg = message;
            state.request = null;
        },
        addRequest : (state, req) => {
            state.request = {cancel : req.cancel, msg : "loading"}

        },
    },
    getters: {
        Loading: state => state.loading,
        Deleteloading: state => state.Deleteloading,
        dialogIsOpen: state => state.dialogIsOpen,
        data: state => state.data,
        FormStatus: state => state.formStatus,
        FormRecord: state => state.formRecord,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
    }
}


