<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observerContactPerson" v-slot="{passes}" @submit.prevent="onSubmitForm()">
       <ThatsWindow ref="window" title="Contacts" windowClass="Contacts height-auto" scrollClass='Contacts height-auto' @deleteYes="emitDelete" :width="650" :formStatus="FormStatus" @dialog-state="dialogState" :group="group">
         <template #left-button>
            <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
            </template>
          
            
         </template>
         
         <mdbContainer class="mt-1">

                <div class="card card-fieldset mt-1">
                  <div class="card-body">
                      <ThatsMultiSelect :multiple="false"  labelClass="col-md-3" fieldClass="col-md-3  pl-0" label="Salutation" v-model="form.attr.salutation" :reduce="label => label.code" :reduceLabel="'label'" :options="salutation" :rules="''"></ThatsMultiSelect>
                      <ValidationProvider vid="name" name="name" :rules="'required'" v-slot="{errors}" :key="'customer'+corportKey">
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="First Name" vid="first_name" name="first_name" v-model="form.attr.first_name" :validated="validated" :rules="'required'" size="sm" />
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Middle Name" vid="middle_name" name="middle_name" v-model="form.attr.middle_name" :validated="validated" :rules="''" size="sm" />
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Last Name" vid="last_name" name="last_name" v-model="form.attr.last_name" :validated="validated" :rules="'required'" size="sm" />
                      <template v-if="errors.length > 0">
                                <mdb-row ><mdb-col offset="3" col="9" class="pl-0"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                      </ValidationProvider>
                     

                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Email" vid="email" name="email" v-model="form.attr.email" :validated="validated" :rules="''" size="sm" />
                      <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Desgination" vid="designation" name="designation" v-model="form.attr.designation" :validated="validated" :rules="''" size="sm" />
                  
                  </div>
                </div>

                <div class="card card-fieldset">
                  <div class="card-body">

                      <ValidationProvider vid="attr.contacts.0.value" name="attr.contacts.0.value" :rules="'required'" v-slot="{errors}">
                          <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Primary Number" vid="attr.contacts.0.valuex" name="attr.contacts.0.valuex" v-model="form.attr.contacts[0]" :rules="'required'" :show_error="true"></InternationalMobile>
                            <template v-if="errors.length > 0">
                              <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                        </ValidationProvider>
                      <ValidationProvider vid="attr.contacts.1.value" name="attr.contacts.1.value" :rules="''" v-slot="{errors}">
                          <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Secondary Number" vid="attr.contacts.1.value" name="attr.contacts.1.value" v-model="form.attr.contacts[1]" :rules="''"></InternationalMobile>
                             
                            <template v-if="errors.length > 0">
                                <mdb-row ><mdb-col offset="3" col="9"><span class="errors">{{errors[0]}}</span></mdb-col></mdb-row>
                            </template>
                        </ValidationProvider>
                        
                    <InternationalMobile labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="Home Tel Number" vid="attr.contacts.2.value" name="attr.contacts.2.value" v-model="form.attr.contacts[2]" :rules="''"></InternationalMobile>
                  </div>
                </div>

                <div class="card card-fieldset">
                  <div class="card-body">
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Address" vid="address" name="address" v-model="form.attr.address" :validated="validated" :rules="''" size="sm" />

                   <mdb-row >
                      <mdb-col offset="3" col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Bldg" vid="bldg" name="bldg" v-model="form.attr.address2.building" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                      <mdb-col col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Street" vid="street" name="street" v-model="form.attr.address2.street" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                      <mdb-col col="3">
                        <ThatsInput  labelClass="col-md-4 pl-0 pr-0" fieldClass="col-md-8 pl-0" label="Zone" vid="zone" name="zone" v-model="form.attr.address2.zone" :validated="validated" :rules="''" size="sm" />
                      </mdb-col>
                   </mdb-row>
                  <ThatsInput  labelClass="col-md-3" fieldClass="col-md-9 pl-0" label="Area" vid="area" name="area" v-model="form.attr.address2.area" :validated="validated" :rules="''" size="sm" />
                  <ThatsMultiSelect :multiple="false" :clearable="false"  labelClass="col-md-3" fieldClass="col-md-9  pl-0" label="City" v-model="form.attr.address2.city" :reduce="label => label.code" :reduceLabel="'label'" :options="cities" :rules="''"></ThatsMultiSelect>

                 </div>
                </div>


               
         
         
          
          </mdbContainer>
        
       </ThatsWindow>


  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer, mdbRow,mdbCol, mdbTabs} from "mdbvue";
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsCheckbox,ThatsFieldset, ThatsWindow, ThatsRadio, ThatsMultiSelect, ThatsAutocomplete,ThatsImage} from '../../components/thats/Thats'

import InternationalMobile from '../../components/thats/InternationalMobile.vue'
export default {
    components: {
        mdbContainer,
        ValidationObserver,
        ValidationProvider,
        ThatsWindow, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsSeriesInput,  
        ThatsCheckbox,  
        ThatsFieldset,
        ThatsRadio,
        ThatsMultiSelect,
        ThatsAutocomplete,
        ThatsImage,
        mdbBtn,
        mdbRow,
        mdbCol,
        InternationalMobile  ,
        mdbTabs
        
  },

  props : {
      group : {
            type : Number,
            default : 1
        },

        
    },
    
    data : () => ({
      validated : false,
      form : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,
      salutation : [
            { label : "Mr.", code : "Mr."},
            { label : "Ms.", code : "Ms."}
          ],

      cities : [
        {label :"Doha",code : "Doha"},
        {label :"Al-Rayyan",code : "Al-Rayyan"},
        {label :"Al Khor",code : "Al Khor"},
        {label :"Al Wakrah",code : "Al Wakrah"},
        {label :"Umm-Salal",code : "Umm-Salal"},
        {label :"Al-Dayian",code : "Al-Dayian"},
        {label :"Ash-Shamal",code : "Ash-Shamal"}
      ],
      tabs : [
                { text: 'Contact Details', slot: 'form-slot' },
                { text: 'Contact Persons', slot: 'form-contact-person' },
              ],

      active : 0,
      corportKey : 0
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
      } 
      
      this.optionsHandler()
    },
    methods: {

      ...mapMutations('ContactPersonModule',[
        'SetFormStatusCreate',
      ]),

      formVar(){
        return {
          name : null,
          mobile : null,
          attr : {

            address: null,
            address2 : {
                street : null,
                building : null,
                zone : null,
                area : null,
                city : null,
            },
            designation : null,
            email : null,
            first_name : null,
            middle_name : null,
            last_name : null,
            salutation : null,
            contacts : [
                {
                    type : 'primary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'secondary',
                    intl : false,
                    code : '+974',
                    value : null
                },
                {
                    type : 'home',
                    intl : false,
                    code : '+974',
                    value : null
                }
            ]
          }
        }
      },
/* start */

      optionsHandler(){



      },


      nextCode(){
        var self = this;

      },

      demo(v){
        console.log(v)
      },

/* end */
      openDialog(){
            this.$refs.window.openDialog()
      },
      closeDialog(){
          this.$refs.window.closeDialog()
      },
      fnImageSelect(v){
        console.log(v)
        this.form.image = v
      },
      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observerContactPerson.validate();
            if (isValid) {
                // this.form.description = this.getText();
                    if(self.formLoading === false){
                      self.formLoading = true
                       self.$emit('change',JSON.parse(JSON.stringify(self.form)));
                      if(self.FormStatus == 'create'){
                          self.$ThatsNotify.success()                          
                      }

                      if(self.FormStatus == 'update'){
                          self.$ThatsNotify.success({
                            message : "Record was successfully updated"
                          })
                         
                      }
                        self.closeDialog();
                        self.FormResetter();
                    }
                    
            }        
      },

      getText() {
        let text = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },
      FormResetter(){
        this.form = this.formVar()
        requestAnimationFrame(() => {
              this.$refs.observerContactPerson.reset();
            });
        this.validated = false
        this.formLoading = false
        this.SetFormStatusCreate();
      },
      
      dialogState(state){
        if(!state){
          this.FormResetter();
          return
        }

        if(this.FormStatus == 'create'){
            this.nextCode();
        }

        this.optionsHandler();

      },
      emitDelete(){
        this.$emit('delete',this.FormRecord)
      },
      getActiveTabIndex(index){
        this.active = index
      },

      setTabArray(value){
        if(value){
            
          this.tabs = [
                { text: 'Contact Details', slot: 'form-slot' },
                { text: 'Contact Persons', slot: 'form-contact-person' },
              ]
        }else{

          this.tabs = [
                { text: 'Contact Details', slot: 'form-slot' }
              ]
        }
      }

    },

    computed: {

      ...mapGetters('ContactPersonModule',
      [
        'FormStatus',
        'FormRecord'
      ]),
    },

    watch: {
      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord));
        }
      },

      "form.attr.contacts" : {
          handler(v){
          // console.log("watch")
          // console.log(v)

            const firstObject  = v[0];

            if(firstObject){
                this.form.mobile = firstObject.value
            }
        },
        deep : true
      },

      "form.attr.salutation" : function(v){
        
          let name = '';
          name += (v != "" && v !== null) ? v : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
        
      },
      "form.attr.first_name" : function(v){

        let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (v != "" && v !== null) ? " "+v : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
      },
      "form.attr.middle_name" : function(v){

         let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (v != "" && " "+v !== null) ? " "+v : '';
          name += (this.form.attr.last_name != "" && this.form.attr.last_name !== null) ? " "+this.form.attr.last_name : '';

          this.form.name = name.trim();
      },
      "form.attr.last_name" : function(v){
         let name = '';
          name += (this.form.attr.salutation != "" && this.form.attr.salutation!== null) ? this.form.attr.salutation : '';
          name += (this.form.attr.first_name != "" && this.form.attr.first_name !== null) ? " "+this.form.attr.first_name : '';
          name += (this.form.attr.middle_name != "" && this.form.attr.middle_name !== null) ? " "+this.form.attr.middle_name : '';
          name += (v != "" && " "+v !== null) ? " "+v : '';

          this.form.name = name.trim();
      },

      "form.attr.corporate" : function(value){
        this.corportKey = this.corportKey + 1
        this.form.attr.last_name = null
        this.form.attr.salutation = null

        
 
      }
      

    },


}
</script>
