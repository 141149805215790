<template>
  <ValidationObserver class="needs-validation form-custom-format " v-bind:class="{'was-validated' : validated}" tag="form" ref="observer" v-slot="{errors,passes}" @submit.prevent="onSubmitForm()">
       <Dialog title="Customer/Vendor" windowClass="customer-vendor height-auto" scrollClass='customer-vendor height-auto' :width="600">
         <mdbContainer class="mt-4">
           <ThatsSeriesInput label="Code" placeholder="Code" vid="series" name="series" v-model="form.series" :validated="validated" :rules="'required'" size="sm" />
           <ThatsInput label="Company" placeholder="Company" vid="name" name="name" v-model="form.name" :validated="validated" :rules="'required'" size="sm" />
           <ThatsInput label="Mobile" placeholder="Mobile" vid="mobile" name="mobile" v-model="form.mobile" :validated="validated" :rules="'required'" size="sm" />
           <ThatsRadio vid="reference" orientation="inline" name="reference" :data="type" v-model="form.reference" :validated="validated" :rules="'required'" size="sm" />
           <ThatsTextarea label="Address" placeholder="Address" vid="address" name="address" v-model="form.other_details.address" :validated="validated" size="sm"></ThatsTextarea>
           <ThatsInput label="Phone" placeholder="Pone" vid="phone" name="phone" v-model="form.other_details.phone" :validated="validated" size="sm" />
           <ThatsInput label="Email" placeholder="Email" vid="email" name="email" v-model="form.other_details.email" :validated="validated" :rules="'email'" size="sm" />
           <ThatsStatus vid="status" name="status" v-model="form.status" :validated="validated" size="sm" />
          </mdbContainer>
         <template v-slot:footer>
           <template v-if="formLoading">
              <mdb-btn color="elegant" size="sm" disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </mdb-btn>
            </template>
            <template v-else>
              <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">Save</mdb-btn>
              <!-- <mdb-btn color="elegant" class="ml-0"  size="sm">Save & New</mdb-btn> -->
            </template>
        </template>
       </Dialog>
  </ValidationObserver>
</template>
<script>
import { mdbBtn,mdbContainer} from "mdbvue";

import { ValidationObserver } from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'
import Dialog from '../../components/dialog/Dialog.vue'
import {ThatsInput, ThatsTextarea, ThatsSeriesInput, ThatsStatus, ThatsRadio} from '../../components/thats/Thats'

export default {
    components: {
        mdbContainer,
        ValidationObserver,
        Dialog, 
        ThatsInput,  
        ThatsTextarea,  
        ThatsStatus,  
        ThatsSeriesInput,  
        ThatsRadio,  
        mdbBtn,  
        
  },
    
    data : () => ({
      validated : false,
      activePill : 0,
      getActiveTabIndex : 0,
      form : null,
      file : null,
      response : {
        error : null,
        message : null
      },
      formLoading : false,

      type : [
        {label : "Customer",value : "customer"},
        {label : "Vendor",value : "vendor"},
      ]
    }),

    created : function() {

      if(this.FormStatus == 'create'){
        this.form = this.formVar();
        this.nextCode()
      }

      
      
    },
    methods: {
      ...mapActions('TableModule',[
        'resetData',
      ]),
      ...mapActions('CustomerVendorModule',[
        'GetNextCode',
        'StoreRecord',
        'UpdateRecord',
      ]),
      ...mapMutations('CustomerVendorModule',[
        'SetFormStatusCreate',
      ]),
      ...mapMutations('DialogModule',[
        'CloseDialog'
      ]),
      nextCode(){
        var self = this;
        this.GetNextCode().then((response) => {
          self.form.series = response.code
        });
      },

      onSubmitForm(){
        var self =this
            this.validated = true
            const isValid = this.$refs.observer.validate();
            if (isValid) {
                    if(self.formLoading === false){
                      self.formLoading = true

                      if(self.FormStatus == 'create'){
                          self.StoreRecord(self.form).then(() => {
                              self.$ThatsNotify.success()
                              self.resetData('customer-vendor/data');
                                self.CloseDialog();
                                self.form = self.formVar()
                                self.nextCode()
                                self.validated = false
                                  requestAnimationFrame(() => {
                                  self.$refs.observer.reset();
                                });

                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.$refs.observer.setErrors(error.response.data.errors);
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }

                      if(self.FormStatus == 'update'){
                          self.UpdateRecord(self.form).then(() => {
                              self.$ThatsNotify.success({
                                message : "Record was successfully updated"
                              })
                              self.resetData('customer-vendor/data');
                                self.CloseDialog();
                                self.form = self.formVar()
                                self.nextCode()
                                self.validated = false
                                  requestAnimationFrame(() => {
                                  self.$refs.observer.reset();
                                });

                                self.formLoading = false

                            }).catch((error) => {
                              self.formLoading = false
                              self.response.error = 1;
                              if (error.response) {
                                  if(error.response.data.hasOwnProperty('errors')){
                                    self.response.message = error.response.data.errors.email[0]
                                  }
                                } else if (error.request) {
                                    self.response.message = error.request
                                } else {
                                    self.response.message = error.message
                                }
                                return;
                            })
                      }
                    }
                    
            }        
      },

      getText() {
        let [,text] = this.$refs.descriptionEditor.$children;
        text = text.$el.innerHTML;
        return text;
      },

      formVar(){
        return {
          series : 0,
          name : null,
          mobile : null,
          description : null,
          other_details : {
            address : null,
            phone : null,
            email : null,
          },
          reference : 'customer',
          status : 'true',
        }
      },

      FormResetter(){
        this.SetFormStatusCreate();
        this.form = this.formVar()
      }

    },

    computed: {

      ...mapGetters('CustomerVendorModule',
      [
        'FormStatus',
        'FormRecord',
      ]),
      ...mapGetters('DialogModule',
      [
        'DialogIsOpen'
      ])
    },

    watch: {
      DialogIsOpen : function(v){   
        //Always set form status to 'create' once dialog is closed
        if(v === false){
          this.FormResetter()
        }
      },

      FormRecord : function(){
        if(this.FormStatus == 'update'){
          this.form = JSON.parse(JSON.stringify(this.FormRecord.row));
          this.form.other_details = JSON.parse(this.form.other_details);
        }
      }
    },


}
</script>

