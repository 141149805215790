<template>
    <ValidationObserver
        class="needs-validation form-custom-format"
        v-bind:class="{'was-validated': validated}"
        tag="form"
        ref="observer"
        v-slot="{passes}"
        @submit.prevent="onSubmitForm"
    >
        <el-dialog
            :modal="true"
            ref="window"
            :fullscreen="true"
            :title="'Print Catalog'"
            :visible.sync="syncModal"
            :close-on-click-modal="false"
            :modal-append-to-body="true"
            :append-to-body="true"
            class="text-left bulk-update-qty-dialog"
            :formStatus="formStatus"
            @dialog-state="dialogState"
            :group="group"
            :showClose="!processing"
        >

            
            <ThatsInput :showLabel="false" tabindex="2" fieldClass="col-sm-12" label="" placeholder="Catalog Name" vid="namex" name="namex" v-model="catalogName" :validated="false" :rules="''" size="sm"/>

            <div ref="spreadsheet"></div>
            <template #footer>
                <div class="d-flex">
                    <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </mdb-btn>
                    </template>
                    <template v-else>
                        <template v-if="formStatus == 'create'">
                            <template v-if="processing == true">
                                <mdb-btn color="elegant" size="sm" disabled>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Processing...
                                </mdb-btn>
                            </template>
                            <template v-else>
                                <mdb-btn color="elegant" size="sm" @click="passes(onSubmitForm)">
                                    <i class="el-icon-check text-white cursor-pointer"></i> Create
                                </mdb-btn>
                            </template>
                            <mdb-btn color="grey" size="sm" @click="passes(clearForm)">
                                <i class="el-icon-copy-document text-white cursor-pointer"></i> Clear
                            </mdb-btn>
                        </template>
                        <mdb-btn color="grey" size="sm" @click="closeDialog">
                            <i class="el-icon-close text-white cursor-pointer"></i> Close
                        </mdb-btn>
                    </template>
                </div>
            </template>
        </el-dialog>
    </ValidationObserver>
</template>
<script>
import { mdbBtn} from "mdbvue";

import {ValidationObserver, ValidationProvider} from "vee-validate";
import { mapActions,mapGetters, mapMutations } from 'vuex'

import {  mdbRow,mdbCol } from 'mdbvue';
import jspreadsheet from '../../jsexcel/jexcel'
import { drop } from "lodash";

import { MessageBox, Message } from "element-ui";

import  {ThatsDialog, ThatsInput} from '../../components/thats/Thats'

const dropdownBrandFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.parent == value
    },[]);
    return filterResult
}


const dropdownCategoryFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

const dropdownCollectionFilter = function(instance, cell, c, r, source) {

    console.log('source',source)
    var value = instance.jexcel.getValueFromCoords(c - 1, r);
    const filterResult = source.filter((item)=>{
        return item.area == value
    },[]);
    return filterResult
}

export default {
    components: { ValidationProvider,
        mdbBtn,
        ValidationObserver,
        mdbRow,
        mdbCol,
        ThatsDialog,
        ThatsInput
    },
    props : {
        group : {
            type : Number,
            default : 1
        },
    },

    data(){

    const self = this;
    return {
        formStatus : 'create',
        syncModal : false,
        validated: false,
        form : null,
        formData:null,
        formDataOrginal : null,
        formLoading : false,
        processing : false,
        showError : false,
        group2 : 3,
        spreadsheet: null,
        catalogName: null,
        exportTableOptions : {
            download : false,
            allowDeleteColumn : false,
            allowRenameColumn : false,
            defaultColAlign : 'left',
            csvHeader:true,
            tableWidth : '100%',
            tableHeight : 'calc(100vh - 125px)',
            tableOverflow : true,
            data:[[]],
            minDimensions:[3,50],
            onCopy : null,
            selectionCopy : false,
            allowCopy : true,
            columns: [

                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Code',
                    name: 'series',
                    readOnly: false,
                    primaryKey: false
                },
                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Code 2',
                    name: 'series_2',
                    readOnly: false
                },
               
                {
                    type: 'text',
                    width: '200px',
                    title: 'Item Name',
                    name: 'item_name',
                    readOnly: false
                },
                
                {
                    type: 'text',
                    width: '340px',
                    title: 'Response',
                    name: 'message',
                },
                {
                    type: 'hidden',
                    name: 'item_id',
                },


            ],

            contextMenu: function(obj, x, y, e) {

                return false;
            },
            onchange: function (domElement, domElmentCell, column, row, value, oldValue) {
                if (column == 0) {
             
                    if ((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)) {
                        self.fnGetItem(value,row, column)
                    }
                }
                if (column == 1) {
             
                    if ((value != null && value != '' && value != oldValue) || (oldValue == '' && oldValue == null)) {
                        self.fnGetItem(value,row,column )
                    }
                }
            },
        },

        isIndeterminate: false,
        checkAll: true,
        checkedOptions: [
            // 'opening_qty',
            // 'stock_qty',
            // 'sold_qty',
            // 'in_transit_qty',
            // 'under_production_qty',
            'group',
            'brand',
            'area',
            'category',
            'collection',
        ],
        checkboxOptions : [
            // { label: 'Opening Qty', value: 'opening_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'Stock', value: 'stock_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'Sold', value: 'sold_qty', width : '100px', dropdown : false, type : 'text'},
            // { label: 'In Transit', value: 'in_transit_qty', width : '100px', dropdown : false, type : 'text' },
            // { label: 'Under Production', value: 'under_production_qty', width : '100px', dropdown : false, type : 'text' },
            { label: 'Group', value: 'group', width : '100px', dropdown: true, type : 'dropdown', source : 'groupComputed'},
            { label: 'Brand', value: 'brand', width : '100px', dropdown: true, type : 'dropdown', source : 'brandComputed'},
            { label: 'Area', value: 'area', width : '100px', dropdown: true, type : 'dropdown', source : 'areaComputed'},
            { label: 'Category', value: 'category', width : '100px', dropdown: true, type : 'dropdown', source : 'categoryComputed'},
            { label: 'Collection', value: 'collection', width : '100px', dropdown: true, type : 'dropdown', source : 'collectionComputed'},
            { label: 'Item Status', value: 'status', width : '100px', dropdown: true, type : 'dropdown', source : [
                    {name : "Archive", id : "Archive"},
                    {name : "Active", id : "Active"},
                    {name : "Discontinued", id : "Discontinued"},
                ]},
            { label: 'Item Type', value: 'item_type', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Stock", id : "Stock"},
                    {name : "Service", id : "Service"},
                ]},
            { label: 'Web Live Status', value: 'website', width : '150px', dropdown: true, type : 'dropdown', source : [
                    {name : "Yes", id : "Yes"},
                    {name : "No", id : "No"},
                ]},
            { label: 'Price', value: 'sale_price', width : '150px', dropdown: false, type : 'numeric'},
        ],

        postedData : [],
        }
    },
    inject : ['brandProvider','categoryProvider','areaProvider','collectionProvider'],
    created(){
        this.formData = JSON.parse(JSON.stringify(this.formObject))
        this.formDataOrginal = JSON.parse(JSON.stringify(this.formObject))
    },
    mounted() {

    },
    methods: {
        ...mapActions('ItemModule',['fetchItem']),
        ...mapActions('InventoryItemMasterModule',['storeRecord']),
        createPopupTable(exportTableOptions){
            this.spreadsheet = jspreadsheet(this.$refs.spreadsheet, exportTableOptions);
            Object.assign(this, this.spreadsheet);
            this.spreadsheet.setData([])
        },

        async recreateSpreadsheet(){
            await this.spreadsheet.destroy();
        },
        /* start */



        optionsHandler(){
            var self = this;
        },

async fnGetItem(itemCode, index, col){
        const self = this;
        await this.fetchItem({item : itemCode, index : index}).then((res)=> {
            const row = parseInt((index + 1))


            console.log(res)
              const itemId = self.spreadsheet.getValueFromCoords(6,index)
 console.log("existing id",itemId)
            let itemIdString = res.id.toString()
            if(col == 0){
                let color = '';

                const itemAttr = res.attr;

                if(itemAttr.hasOwnProperty('colour') && itemAttr.colour != ''){
                    color = itemAttr.colour;
                }

                if(itemAttr.hasOwnProperty('colour') && itemAttr.colour == '' && itemAttr.hasOwnProperty('colour1') && itemAttr.colour1 != '' ){
                    color = itemAttr.colour;
                }
                self.spreadsheet.setValueFromCoords(0,index,res.itemCode,true)
                self.spreadsheet.setValueFromCoords(2,index,res.name,true)
                self.spreadsheet.setValueFromCoords(3,index,res.dimension,true)
                self.spreadsheet.setValueFromCoords(4,index,color,true)
                self.spreadsheet.setValueFromCoords(5,index,"Item found",true)
                if(itemId){

                    const splitId = itemId.split('-');
    
                    console.log("splitId",splitId)
                    if(splitId.length > 1){
                        itemIdString = `${itemIdString}-${splitId[1]}`
                    }
                }

                console.log("itemIdString",itemIdString)
                
                self.spreadsheet.setValueFromCoords(6,index,itemIdString)
            }

            if(col == 1){

                    const splitId = itemId.split('-');
    
                    console.log("splitId",splitId)
                    if(splitId.length > 0){
                        itemIdString = `${splitId[0]}-${res.id}`
                    }

                    console.log("itemIdString",itemIdString)

                    

                    self.spreadsheet.setValueFromCoords(6,index,itemIdString)
                // console.log(itemId)
                /* self.spreadsheet.setValueFromCoords(0,index,res.itemCode,true)
                self.spreadsheet.setValueFromCoords(2,index,res.name,true)
                self.spreadsheet.setValueFromCoords(3,index,"Item found",true) */
            }
          /*  
           
            
           


           
 */
 const parseIntIndex = parseInt(index)

            const backgroundColor = 'transparent'
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)
        }).catch((error) => {
            console.log("error")
            console.log(error)
          const parseIntIndex = parseInt(index)
          let backgroundColor = 'rgb(255 200 200)';
          if(( parseIntIndex % 2) == 1){
            backgroundColor = 'rgb(233 167 167)';
          }
            self.spreadsheet.setStyle(`A${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`B${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`C${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`D${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`E${parseIntIndex+1}`, 'background-color', backgroundColor)
            self.spreadsheet.setStyle(`F${parseIntIndex+1}`, 'background-color', backgroundColor)

        })
    },

        /* end */

        openDialog(){
            this.syncModal = true
            this.formResetter();
            this.formStatus = 'select'
            /* */
        },
        closeDialog(){
            this.syncModal = false
            this.formResetter();
        },

        onSubmitCopyTemplate(){
            const self = this;

            const selectedColumns = this.checkboxOptions.filter((item) => {
                return self.checkedOptions.includes(item.value)
            },[])

            var txt = "Series\t";
            if(selectedColumns.length > 0){
                selectedColumns.forEach(element => {
                    txt += element.label+"\t"
                });
                txt += "\n"
            }

            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.setAttribute("id", "dummy_id");
            document.getElementById("dummy_id").value=txt;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);

            Message.success({
                message: "Copied!"
            });
        },

        onSubmitSelectForm() {
            const self = this;

            this.formResetter();

            this.formStatus = 'create';

            const finalColumns = [
                { type: 'text', width: '200px', title: 'Item Code', name: 'series', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Item Code 2', name: 'series_2', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Item Name', name: 'name', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Dimension', name: 'dimension', readOnly: false, primaryKey: false },
                { type: 'text', width: '200px', title: 'Color', name: 'color', readOnly: false, primaryKey: false },
               
                { type: 'text', width: '340px', title: 'Response', name: 'message', readOnly: false, primaryKey: false },
                { type: 'hidden', name: 'item_id' },

            ];

            this.$nextTick(() => {
                const width = this.$refs.window.width;
                this.exportTableOptions.tableWidth = "100%";
                this.exportTableOptions.columns = finalColumns;
                this.createPopupTable(this.exportTableOptions);
            });
        },

        onSubmitBack(){
            const self = this;
            MessageBox.confirm('Are you sure you want to go back?', 'Warning', {
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                type: 'warning'
            }).then(() => {

                self.formStatus = 'select';
                self.form = self.formVar()
                requestAnimationFrame(() => {
                    self.$refs.observer.reset();
                });
                self.validated = false
                self.formLoading = false
                if(self.spreadsheet){
                    self.spreadsheet.destroy();
                }

            }).catch(() => {
                return
            });
        },

        onSubmitForm() {
            const self = this;

            if (this.processing) {
                return false;
            }

            const tableData = this.spreadsheet.getJson();

            MessageBox.confirm('Warning: Are you sure you want to submit the data?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                if (tableData.length > 0) {
                    this.postCounter = 0;
                    this.dataLength = tableData.filter(item => item.series !== '').length;

                    if (this.dataLength > 0) {
                        const validData = tableData.filter(item => item.series !== '').map(item => {
                            return { ...item, completed: false };
                        });

                        if (validData.length === 0) {
                            self.$ThatsNotify.warning({
                                message: `Please fill up the series column.`,
                                timeOut: 10000
                            });
                            return false;
                        }

                        console.log('dataLength', validData);
                        const columns = self.iterateAlphabetByNumber((self.checkedOptions.length + 14));
                        self.postedData = JSON.parse(JSON.stringify(validData));

                        // Process each element sequentially using a promise chain
                        let chain = Promise.resolve();

                        const itemId = [];

                        validData.forEach((element, forIndex) => {

                            // Mapping function
                            function mapElementToFormData(element) {
                                return element.item_id
                            }

                            const mappedData = mapElementToFormData(element);

                            // Chain the promises

                            console.log("mappedData",mappedData)
                            chain = chain.then(() => {
                                itemId.push(mappedData)
                            });
                        });

                        chain.finally(() => {
                            console.log('All records processed.',itemId);
                            const itemIds = itemId.toString();
                            /* var strWindowFeatures = "location=yes,height=600,width=900,scrollbars=yes,status=yes";
                            const win = window.open(`/carpet-index?items=${itemIds}`, "_blank", strWindowFeatures);
                            win.document.title = 'Print'; */

                            const win = window.open(`/carpet-index?items=${itemIds}&title=${this.catalogName}`, "_blank");
                            win.document.title = 'Print';
                            // win.print()

                            this.processing = false;
                        });
                    }
                }
            }).catch(() => {
                // Handle the case where the user cancels the confirmation dialog
                console.log('Confirmation dialog was cancelled.');
            });
        },


        formResetter(){
            this.form = this.formVar()
            requestAnimationFrame(() => {
                this.$refs.observer.reset();
            });
            this.validated = false
            this.formLoading = false
            this.processing = false
            this.setFormStatusCreate();

            if(this.spreadsheet){
                this.spreadsheet.destroy();
            }
        },

        dialogState(state){
            this.optionsHandler();
            if(!state){
                this.formResetter();
                return
            }
            if(this.FormStatus == 'create'){
                this.formResetter();
            }

        },

        formVar(){
            return {
                items : [],
            }
        },
        setFormStatusCreate(){
            this.formStatus = 'create'
        },

        handleCheckAllChange(val) {
            this.checkedOptions = val ? this.checkboxOptions.map(i => i.value,[]) : [];
            this.isIndeterminate = false;
        },
        handleCheckedOptionsChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkboxOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkboxOptions.length;
        },

        iterateAlphabetByNumber(numColumns) {
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            const columns = [];

            for (let i = 0; i < numColumns; i++) {
                let label = '';
                let index = i;
                while (index >= 0) {
                    label = alphabet[index % 26] + label;
                    index = Math.floor(index / 26) - 1;
                }

                columns.push(label);
            }
            return columns;
        },
        clearForm(){
            this.recreateSpreadsheet();
            this.createPopupTable(this.exportTableOptions);
        },
    },

    computed: {
        ...mapGetters('InventoryItemMasterModule',{
            formRecord : 'formData',
            viewLoading : 'getLoading',
            postLoading : 'postLoading',
            formObject : 'initialFormObject',
        }),

        checkedOptionsComputed : function(){
            return (this.checkedOptions.length > 0) ? true : false;
        },

        brandComputed : function(){
            return [];
        },
        groupComputed : function(){
            return [];
        },

        categoryComputed : function(){
            return [];
        },
        areaComputed : function(){
            return [];
        },

        collectionComputed : function(){
            return [];
        },

        enableButton : function(){
            return (this.areaComputed.length > 0 && this.brandComputed.length > 0 && this.categoryComputed.length > 0) ? false : true;
        },

        isAllCompleted : function(){
            return (this.postedData.length > 0) ? this.postedData.every((item) => item.completed == true) : false;
        }
    },

    watch: {

        brandComputed : function(v){
            this.filteredBrands = v
        },
        syncModal(val) {
            if (val && this.formStatus === 'select') {
                this.onSubmitSelectForm();
            }
        },
        isAllCompleted : function(value){
            if(value == true){
                this.processing = false;
                this.$emit('success');
            }
        }
    },


}
</script>

<style scoped>
.custom-checkbox .custom-control-label::before {
    border-radius: .50rem!important;cursor: pointer;
}

.no-image-gallery i.far {
    font-size: 42px;
    color: #959595;
}
.mdb-time-picker .clockpicker-span-hours,
.mdb-time-picker .clockpicker-span-minutes {
    color: #4285f4 !important;
}


</style>
