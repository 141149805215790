<template>
  <div>
    <ValidationObserver class="needs-validation form-custom-format " tag="form" ref="observer" v-slot="{passes}" @submit.prevent="onSubmitForm()">
      <el-dialog :modal="true" :fullscreen="false" :title="'Online Payment Link'" :visible.sync="syncModal" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" class="text-left discount-calculator">
          <div>
             

              <div class="dialog-scroll-container">


    
                 <!--  <ThatsInput labelClass="col-sm-6" fieldClass="col-sm-6" label="Document Total" placeholder="0" vid="document_total" name="document_total" v-model="documentTotal" :rules="''" size="sm" :horizontal="true" :readOnly="true"/> -->
                <mdb-row class="tab-row">
                          <mdb-col lg="12" md="12" sm="12" class="mt-2 pb-2">    

                    <!--         {{ documentTotal }} -->
                            <el-card shadow="never">
                                <mdb-row>
                                    <mdb-col col="6">Customer</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ data.attr.name }}</strong></mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6">Mobile</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{data.attr.mobile}}</strong></mdb-col>
                                </mdb-row>
                                <mdb-row>
                                    <mdb-col col="6">Document Total</mdb-col>
                                    <mdb-col col="6" class="text-right"><strong>{{ formatNumber(data.total) }}</strong></mdb-col>
                                </mdb-row>

                                <hr>
                                <template v-if="!hasLink">
                                    <mdb-row>
                                    <mdb-col col="6">Amount ({{ linkForm.currency }})</mdb-col>
                                    <mdb-col col="6" class="text-right">
                                        <ValidationProvider :rules="{ required: true, preventOverpaid : {otherValue: linkForm.maximum_amount, msg : `Amount cannot be more than ${$helpers.formatMoney(linkForm.maximum_amount)}`, validate : true}  }" v-slot="{errors}">
                                           <input type="text" v-model="linkForm.amount" class="form-control text-right percent-" min="0" placeholder="Document Total" @input="amountChanged($event.target.value)"/>
                                           <span class="errors">{{errors[0]}}</span>
                                        </ValidationProvider>
                                        </mdb-col>
                                    </mdb-row>

                               
                                    <mdb-row>
                                        <mdb-col col="6">Balance Amount</mdb-col>
                                        <mdb-col col="6" class="text-right">
                                            <strong>{{ formatNumber(linkForm.balance_amount) }}</strong>
                                        </mdb-col>
                                    </mdb-row>
                                </template>

                                <template v-if="loading">
                                    Loading...
                                </template>

                                <template v-if="hasLink && !loading">
                                    <mdb-row>
                                            <mdb-col col="6">Amount</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                <strong>{{ formatNumber(data.link.amount) }}</strong>
                                            </mdb-col>
                                        </mdb-row>
                                    <mdb-row>
                                            <mdb-col col="6">Status</mdb-col>
                                            <mdb-col col="6" class="text-right">
                                                
                                                <strong>{{ data.link.status.toUpperCase() }}</strong>
                                            </mdb-col>
                                        </mdb-row>
                                    <mdb-row>
                                            <mdb-col col="2">Link</mdb-col>
                                            <mdb-col col="10" class="text-right">
                                                
                                                <el-input placeholder="Please input" v-model="data.link.link" class="input-with-select">
                                                    <el-button slot="append" icon="el-icon-copy-document" @click="copyLink(data.link.link)"></el-button>
                                                </el-input>
                                            </mdb-col>
                                        </mdb-row>
                                   
                                </template>


                                </el-card>
                          </mdb-col>
                      </mdb-row>
                </div>

          </div>
          <template slot="footer">
              
            <template v-if="formLoading">
                        <mdb-btn color="elegant" size="sm" disabled class="mt-0 mb-0">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Loading...
                        </mdb-btn>
                      </template>

                      <template v-else>

                        <template v-if="!readOnly">
                            <template v-if="!hasLink"> 
                                <mdb-btn class="mt-0 mb-0" color="elegant" size="sm" @click="passes(onSubmitForm)"><i class="el-icon-save text-white cursor-pointer" ></i> Create Link</mdb-btn>
                            </template>

                            <template v-if="hasLink && ['paid'].includes(data.link.status) && !loading">
                                <mdb-btn class="mt-0 mb-0" color="elegant" size="sm" @click="makePayment"><i class="el-icon-close text-white cursor-pointer"></i> Make Payment</mdb-btn>
                            </template>
                        </template>
                        <mdb-btn class="mt-0 mb-0" color="grey" size="sm" @click="syncModal = false"><i class="el-icon-close text-white cursor-pointer"></i> Close</mdb-btn>

                      </template>
          </template>
      </el-dialog>
      </ValidationObserver>

  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import { mdbBtn,mdbContainer,mdbSelect,mdbFormInline, mdbRow,mdbCol, mdbIcon, mdbCarousel, mdbCard, mdbCardImage,mdbTabs, mdbNavbar, mdbNavbarBrand} from "mdbvue";
import { ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect} from '../../components/thats/Thats'


import { MessageBox, Message } from 'element-ui';
import formatMoney from 'accounting-js/lib/formatMoney.js'
import unformat from 'accounting-js/lib/unformat.js'
import VueSlider from 'vue-slider-component'
export default {
  components : {

      mdbBtn,
      mdbTabs,
      mdbNavbar,
      mdbNavbarBrand,
      mdbContainer,
      mdbSelect,
      mdbRow,
      mdbCol,
      mdbIcon,
      mdbCarousel,
      mdbCard,
      mdbCardImage,
      ValidationObserver,
      ValidationProvider,
      VueSlider,
       mdbFormInline,
      ThatsMoney,ThatsWindow,ThatsInput, ThatsSeriesInput,ThatsPriceInput,ThatsFieldset, ThatsRadio, ThatsCheckbox, ThatsAutocomplete,ThatsNumber, ThatsAutosuggest,ThatsTextarea, ThatsVueSelect
  },
  props : {
    group : {
          type : Number,
          default : 1
      },
        data : {
          type: Object,
          default : () => ({
              total : 0,
              attr : {
                    name : "",
                    mobile : "",
              },
              link : null
          })
      },
  },
  inject : [],
  data(){
      return {
        syncModal : false,
        formLoading : false,
        readOnly : false,
        linkForm : {
            transaction_id : "",
            series : "",
            amount : 0,
            mobile : "",
            currency : "QAR",
            country_code : "974",
            name : "",
            balance_amount : 0,
            maximum_amount : 50000,
            cx_series : "",
        },
      }
  },
  created(){

  },
  mounted(){
      const self = this
     

  },
  methods : {
        ...mapActions('OnlinePaymentLinkModule',['createLink']),

      openDialog(){
          this.syncModal = true
      },
      closeDialog(){
          this.syncModal = false
      },    


      formatNumber(n) {
        return formatMoney(unformat(n), { symbol: "", precision: 2, thousand: ",", decimal: "." })
    },

    onSubmitForm(){

        const self = this;

        MessageBox.confirm('Are you sure you want to create a link? This action will send an SMS notification to the customer\'s mobile number.', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
        }).then(() => {
            /* self.$emit('apply',self.linkForm)
            self.syncModal() */

            self.createLink(self.linkForm).then(response => {
    
                self.$emit('linkCreated',response.data)
            }).catch(error => {
                self.syncModal = false;
            })
            
        })
    },

    amountChanged(value){
       console.log("amountChanged")
       console.log(value)

       const total = unformat(this.data.total);
       let amount = unformat(value) || 0;

       if(amount < 0){
            amount = 0;
       }

       if(amount > 50000){
            amount = 50000;
       }
         this.linkForm.balance_amount = total - amount;
    
    },
    copyLink(link){
        this.$helpers.copyStringToClipboard(link);
              
                Message.success({
                message: "Copied!"
                });

    },
    makePayment(){

        

        const invData = this.data.link.attr;
        

        
        if (invData.InvoiceTransactions && invData.InvoiceTransactions.length) {
            this.data.link.reference = invData.InvoiceTransactions[0].AuthorizationId;
            this.data.link.amount = unformat(invData.InvoiceTransactions[0].TransationValue);
            } 

      
            
        this.$emit('makePayment',this.data.link)
    }
  },

  computed : {
    ...mapGetters('OnlinePaymentLinkModule',['loading']),
    hasLink(){
        // return this.data.hasOwnProperty('link') && Object.keys(this.data.link).length > 0
        return this.data.hasOwnProperty('link') && this.data.link != null
    }

  },

  watch : {
      syncModal : function(value){
        const self = this;

        this.linkForm.transaction_id = this.data.mdbID;
        this.linkForm.series = this.data.series;

        let amount = unformat(this.data.total) || 0;
        if(amount < 0){
            amount = 0;
       }

       if(amount < 50000){
        this.linkForm.maximum_amount = amount;
       }

       if(amount > 50000){
            amount = 50000;
            this.linkForm.maximum_amount = 50000;
       }

       


        this.linkForm.series = this.data.series;
        this.linkForm.amount = amount;
        this.linkForm.balance_amount = unformat(this.data.total) - amount;
        this.linkForm.mobile = this.data.attr.mobile;
        this.linkForm.name = this.data.attr.name;
        this.linkForm.cx_series = this.data.attr.cx_series;
        if(this.data.attr.hasOwnProperty('contacts')){
           const contact = this.data.attr.contacts.find(e => e.type == "primary");
           if(contact){
            this.linkForm.mobile = contact.value;
            this.linkForm.country_code = contact.code;
           }
        }
      },
  
  }
}
</script>