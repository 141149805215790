import { ApiService } from '../../services'
import { Unauthorized } from '../../helpers/unauthorized.handler'
export const UserLogModule = {
    namespaced: true,
    state: {
        getLoading: false,
        viewLoading: false,
        deleteLoading: false,
        postLoading: false,
        putLoading: false,
        data: {
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Date/Time',
                    field: 'date',
                },
                {
                    label: 'IP Address',
                    field: 'ip',
                },
                {
                    label: 'Description',
                    field: 'description',
                },
                {
                    label: 'Location',
                    field: 'location',
                },
                {
                    label: 'Action',
                    field: 'action_taken',
                },
            ],
            rows: [],
        },
        length: 25,
        page: 0,
        total: 1,
        searchTerm: null,
        filters: {},

        formStatus: 'create',
        formRecord: {},

    
    },
    actions: {
        async filterItems({ dispatch },params) {
            return await new Promise((resolve) => {
                ApiService.get('/designation/filter', params).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },
        
        async getNextCode({ dispatch }) {
            return await new Promise((resolve) => {
                ApiService.get('designation/code', {}).then((response) => {
                    resolve(response.data)
                }).catch(error => {
                    Unauthorized(dispatch, error)
                })
            })
        },

        async getRecord({ rootState, commit }, params) {
            const { viewLoading } = rootState.DesignationModule
            return await new Promise((resolve) => {
                if (!viewLoading) {
                    commit('setViewLoadingTrue');
                    ApiService.get(`designation/item/${params.token_id}`, params).then((response) => {
                        commit('setFormRecord', response.data.data)
                        commit('setViewLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setViewLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async asyncGetData({ rootState, commit, dispatch }) {
            const { getLoading, length, page, filters, searchTerm } = rootState.SalesModule
            return await new Promise((resolve) => {
                if (getLoading === false) {
                    commit('setLoadingTrue')
                    const params = { length: length, page: page, term: searchTerm, filters: filters }
                    ApiService.get('user-log/data', params).then((response) => {
                        commit('setPage', response.data.current_page)
                        commit('setTotal', response.data.total)
                        commit('setData', response.data.data)
                        commit('setLoadingFalse')
                        resolve(response.data.data)
                    }).catch(error => {
                        commit('setLoadingFalse')
                        Unauthorized(dispatch, error)
                    })
                }
            })
        },

        async storeRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const postLoading = rootState.SalesModule.loading;
                if (!postLoading) {
                    commit('setPostLoadingTrue')
                    ApiService.store(payload).then((response) => {
                        commit('setPostLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPostLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecord({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const putLoading = rootState.SalesModule.loading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update('user-log',payload).then((response) => {
                        commit('setPutLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        updateRecordSuspension({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const putLoading = rootState.SalesModule.loading;
                if (!putLoading) {
                    commit('setPutLoadingTrue')
                    ApiService.update('user-log/clear-suspension',payload).then((response) => {
                        commit('setPutLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setPutLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
        async DeleteRecord({ rootState, commit }, payload) {
            var self = this
            return await new Promise((resolve, reject) => {
                const loading = rootState.SalesModule.Deleteloading;
                if (!loading) {
                    commit('setDeleteLoadingTrue')
                    const tokens = [];
                    payload.forEach(element => {
                        tokens.push(element.token_id)
                    });
                    SalesService.deleteSelected(tokens).then((response) => {
                        commit('setDeleteLoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('setDeleteLoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        saveImport({ rootState, commit }, payload) {
            var self = this
            return new Promise((resolve, reject) => {
                const loading = rootState.EmployeesModule.loading;
                if (!loading) {
                    commit('LoadingTrue')
                    ApiService.store('designation/save-import',payload).then((response) => {
                        commit('LoadingFalse')
                        resolve(response.data)
                    }).catch((error) => {
                        commit('LoadingFalse')
                        reject(error)
                    })
                }
            })
        },
        
    },
    mutations: {
        setLoadingFalse: (state) => {
            state.getItemLoading = false
        },
        setLoadingTrue: (state) => {
            state.getItemLoading = true
        },
        setViewLoadingTrue: (state) => {
            state.viewLoading = true
        },
        setViewLoadingFalse: (state) => {
            state.viewLoading = false
        },
        setPostLoadingTrue: (state) => {
            state.postLoading = true
        },
        setPostLoadingFalse: (state) => {
            state.postLoading = false
        },
        setPutLoadingTrue: (state) => {
            state.putLoading = true
        },
        setPutLoadingFalse: (state) => {
            state.putLoading = false
        },
        setDeleteLoadingTrue: (state) => {
            state.deleteLoading = false
        },
        setDeleteLoadingFalse: (state) => {
            state.deleteLoading = true
        },
        setData(state, payload) {
            state.data.rows = payload
        },
        setPage: (state, payload) => {
            state.page = payload
        },
        setTotal: (state, payload) => {
            state.total = payload
        },
        setLength: (state, payload) => {
            state.length = payload
        },
        setCode: (state, payload) => {
            state.form.item_code = payload
        },

        setFormStatusCreate: (state) => {
            state.formStatus = 'create'
        },
        setFormStatusUpdate: (state) => {
            state.formStatus = 'update'
        },
        setFormRecord: (state, payload) => {
            state.formRecord = payload
        },
       

    },
    getters: {

        getLoading: state => state.getLoading,
        viewLoading: state => state.viewLoading,
        deleteLoading: state => state.deleteLoading,
        postLoading: state => state.postLoading,
        putLoading: state => state.putLoading,
        data: state => state.data,
        page: state => state.page,
        total: state => state.total,
        length: state => state.length,
        form: state => state.form,
        columns: state => state.data.columns,
        formStatus: state => state.formStatus,
        formRecord: state => state.formRecord,
    }
}


