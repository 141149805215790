<template>
    <div>
         <ThatsTable title="Items" ref="table" :multipleSelection="false" :endpoint="endpoint" :columns="columns" :newBtn="false" :height="'calc(100vh - 125px)'" @open="openNewModal" @row-click="rowClick">  
        </ThatsTable>
        <CodeGeneratorForm ref="codeGeneratorForm" @success="refreshTable"></CodeGeneratorForm>
        <CodeGeneratorPreview ref="codeGeneratePreview" :group="5" @success="refreshTable"></CodeGeneratorPreview>
    </div>
</template>
<script>
/* eslint-disable no-unused-vars */

import { mapGetters, mapMutations,mapActions } from 'vuex';
import ThatsTable from './Table'
import {mdbTabs, mdbRow, mdbCol, mdbBtn,mdbSelect, mdbInput} from 'mdbvue'
import { MessageBox } from 'element-ui';
import DateRangePicker from 'vue2-daterange-picker'
import { ThatsDatePicker, ThatsMultiSelect} from '../../components/thats/Thats'
import ThatsMultiVueSelect from '../../components/thats/MultiVueSelect'
import { ValidationObserver,ValidationProvider } from "vee-validate";
import moment from 'moment'
import { Loading } from 'element-ui';
import CodeGeneratorForm from './CodeGeneratorForm.vue'
import CodeGeneratorPreview from './CodeGeneratorPreview.vue'

export default {
        components:{
        ThatsTable,
        mdbTabs,
        DateRangePicker, mdbRow, mdbCol,
        ThatsDatePicker,
        mdbBtn,
        ThatsMultiSelect,
        ValidationObserver,ValidationProvider,ThatsMultiVueSelect,CodeGeneratorForm,
        mdbSelect,
        mdbInput,
        CodeGeneratorPreview
    },data(){
        return {
            endpoint : 'new-item',
            exportTableOptions : {
                copyCompatibility: false,
                download : false,
                csvFileName : 'costing',
                allowDeleteRow : true,
                allowDeleteColumn : false,
                allowRenameColumn : false,
                allowExport : false,
                csvHeader:true,
                tableWidth : '1650px',
                tableHeight : 'calc(100vh - 236px)',
                tableOverflow : true,
                data:[],
                minDimensions:[6,20],
                onCopy : null,
                selectionCopy : true,
                allowCopy : true,
                freezeColumns: 3,
                columns: [],
                updateTable:function(instance, cell, col, row, val, label, cellName) {
                    if((row % 2) == 1){
                        cell.parentNode.style.backgroundColor = '#FAFAFA';
                    }
                },

                contextMenu: function(obj, x, y, e) {
                var items = [];

                if (obj.options.allowDeleteRow == true) {
                    items.push({
                        title:obj.options.text.deleteSelectedRows,
                        onclick:function() {
                            obj.deleteRow(obj.getSelectedRows().length ? undefined : parseInt(y));
                        }
                    });
                }
                return items;
                },

                onblur: function(instance){

                },
                onchange: function(instance, cell, x, y, value){

                },
                onafterchanges(){

                },
                

            }
        }
    },
    created(){
        this.exportTableOptions.tableWidth = `${window.innerWidth - 3}px`
    },
    methods: {
       ...mapActions('CodeGeneratorModule',['getRecord']),
       ...mapMutations('CodeGeneratorModule',['setFormStatusUpdate']),
       ...mapActions('CodeGeneratorAreaModule',['getArea']),
        ...mapActions('CodeGeneratorCategoryModule',{
            getCategoryRecords : 'getRecords'
        }),
        ...mapActions('CodeGeneratorVariationModule',{
            getVariations : 'getRecords'
        }),
        rowClick(row){
            const self = this;
            const loading = Loading.service({
                fullscreen: true ,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(255, 255, 255, 0.7)'
                });  
                
                this.getArea()
                this.getCategoryRecords()
                this.getVariations()

                this.getRecord(row).then(() => {
                    loading.close();
                    self.setFormStatusUpdate();
                    self.$refs.codeGeneratePreview.openDialog();
                })
        },
        openNewModal(){
           this.$refs.codeGeneratorForm.openDialog();
        },
        refreshTable(){
            // //alert(1)
            this.$refs.table.refreshTable()
        }
    },
    computed: {
        ...mapGetters('CodeGeneratorModule',['getLoading', 'columns','initialForm']),
    },
}


</script>